
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import mainService from '../../Services/main.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { refreshTokenMiddleware } from '../customMiddelware';
import transfer from '../../Images/transfer.png';
import PrintPdfCsvExcelDownload from '../Utilies/PrintPdfCsvExcelDownload';
import dropdownarrow from '../../Images/dropdown-arrow.svg';
import { changeticket, changetotal } from '../../Global/site';
import Multiselect from "multiselect-react-dropdown";
import Pagination from '../../utile/pagination';


function pad2(n) {
	return (n < 10 ? '0' : '') + n;
}

const PaymentReconciliationList = () => {
	const dispatch = useDispatch()
    const changeTotal = changetotal();
	const [dataList, setDataList] = useState([])
	const [isLoader, setLoader] = useState(false);
	const [filterDate, setFilterDate] = useState({
		citation_from_date: '',
		citation_to_date: '',
		payment_from_date: new Date().setHours(0, 0, 0),
		payment_to_date: new Date().setDate(new Date().getDate() + 1),
		payment_source: ''
	});
	const [sorting, setSorting] = useState({ field: 'xyz', ascending: true })
	const [totalcount, setTotalCount] = useState(0);

	//pagination states
	const [pageNumberLimit, setPageNumberLimit] = useState(200);
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPageLimit, setMaxPageLimit] = useState(5);
	const [minPageLimit, setMinPageLimit] = useState(0);
	const [list,setList]=useState([])
	const [source,setSource]=useState('')

	const changeName = changeticket();



	useEffect(() => {
		const currentDataCopy = [...dataList];
		if (sorting.key === 'location') {
			const sortedCurrentdata = currentDataCopy.sort((a, b) => {
				return (a[sorting.key].block + a[sorting.key].street + a[sorting.key].city + a[sorting.key].state + a[sorting.key].zipCode).localeCompare(b[sorting.key].block + b[sorting.key].street + b[sorting.key].city + b[sorting.key].state + b[sorting.key].zipCode);
			});

			setDataList(sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse());
		} else {
			const sortedCurrentdata = currentDataCopy.sort((a, b) => {
				return a[sorting.key]?.toString()?.localeCompare(b[sorting.key]?.toString());
			});

			setDataList(sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse());
		}
	}, [sorting]);

	const applySorting = (key, ascending) => {
		setSorting({ key: key, ascending: ascending });
	}

	useEffect(() => {
    let query_string = ''
    query_string = `page=${currentPage}&limit=${pageNumberLimit}`
		if (filterDate.citation_from_date) {
			query_string += `${query_string === "" ? "" : "&"}citation_from_date=` + convertFilterFormatData(filterDate.citation_from_date)
		}
		if (filterDate.citation_to_date) {
			query_string += `${query_string === "" ? "" : "&"}citation_to_date=` + convertFilterFormatData(filterDate.citation_to_date)
		}
		if (filterDate.payment_from_date) {
			query_string += `${query_string === "" ? "" : "&"}payment_from_date=` + convertFilterFormatData(filterDate.payment_from_date)
		}
		if (filterDate.payment_to_date) {
			query_string += `${query_string === "" ? "" : "&"}payment_to_date=` + convertFilterFormatData(filterDate.payment_to_date)
		}
		if (filterDate.payment_source) {
			query_string += source
		}
		paymentdatagetFun(query_string)
	}, [currentPage,pageNumberLimit])

	async function paymentdatagetFun(query_string) {
		setLoader(true)
		try {
      const result = await mainService.paymentreconciliationReportAPI(query_string)
			if (result.data.status) {
				setLoader(false)
				setDataList(result.data.data.payment_data)
				setTotalCount(result.data.data.length)
			}
			setLoader(false)
		} catch (err) {
			if (err.response.status === 401) {
				refreshTokenMiddleware(dispatch)
				paymentdatagetFun(query_string)
			}
		}
	}
	function convertFilterFormatData(dt) {
		var date = new Date(dt);
		var year = date.getFullYear();
		var month = pad2(date.getMonth() + 1);
		var todayDate = pad2(date.getDate());

		var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000";

		return currentTimeInSeconds
	}

  const onChangeFilterDate = (date, name) => {
    const newFilterDate = { ...filterDate };
    if (!date) {
      if (name === 'citation_to_date' || name==="payment_to_date") {
        newFilterDate['citation_to_date'] = "";
      } else if (name === 'payment_to_date') {
        newFilterDate['payment_to_date'] = "";
      }
      setFilterDate(newFilterDate);
      return;
    }
  
    newFilterDate[name] = date;
    const timeValue = new Date(date);
    const hours = timeValue.getHours();
    const minutes = timeValue.getMinutes();
  
    if (name === 'citation_to_date') {
      const isDateChange = !filterDate?.citation_to_date || (hours === 0 && minutes === 0) ||
        date.getDate() !== filterDate.citation_to_date?.getDate();
  
      if (isDateChange) {
        let formattedDate = new Date(date);
        formattedDate.setHours(23, 59, 59, 999);
        newFilterDate['citation_to_date'] = formattedDate;
      }
    } else if (name === 'payment_to_date') {
      const currentFilterToDate = new Date(filterDate.payment_to_date);
      const isDateChange = !filterDate?.payment_to_date ||
      (hours === 0 && minutes === 0) ||
        date.getDate() !== currentFilterToDate.getDate();
  
      if (isDateChange) {
        let formattedDate = new Date(date);
        formattedDate.setHours(23, 59, 59, 999);
        newFilterDate['payment_to_date'] = formattedDate;
      }
    }
  
    setFilterDate(newFilterDate);
  };

	const onchangeFilterInput = (e) => {
		setFilterDate({ ...filterDate, [e.target.name]: e.target.value })
	}
	const handleOptionChange = (selectedList,type) => {
		const statusValues = selectedList.map(item => item.value);
		const uniqueStatusValues = [...new Set(statusValues)];
		let categoryString = ""
		if(uniqueStatusValues.length > 0) {
		 categoryString = "&payment_type=" + uniqueStatusValues.join(',');
		}
		setFilterDate({ ...filterDate, [type]: selectedList });
		setSource(categoryString)
	};
	const handleFilterSubmit = (e) => {
		e.preventDefault()
		setLoader(true)
		setDataList([])
    let query_string = ''
    query_string = `page=${currentPage}&limit=${pageNumberLimit}` 
		if (filterDate.citation_from_date) {
			query_string += `${query_string === "" ? "" : "&"}citation_from_date=` + convertFilterFormatData(filterDate.citation_from_date)
		}
		if (filterDate.citation_to_date) {
			query_string += `${query_string === "" ? "" : "&"}citation_to_date=` + convertFilterFormatData(filterDate.citation_to_date)
		}
		if (filterDate.payment_from_date) {
			query_string += `${query_string === "" ? "" : "&"}payment_from_date=` + convertFilterFormatData(filterDate.payment_from_date)
		}
		if (filterDate.payment_to_date) {
			query_string += `${query_string === "" ? "" : "&"}payment_to_date=` + convertFilterFormatData(filterDate.payment_to_date)
		}
		if (filterDate.payment_source) {
			query_string += source
		}
		paymentdatagetFun(query_string)

	}

	function convertDecimal(num) {
		return (Math.round(num * 100) / 100).toFixed(2)
	}

	//pagination methods
  const onPageChange = (pageNumber) => {
    console.log(pageNumber,'page number')
    setCurrentPage(pageNumber.currentPage);
    setPageNumberLimit(pageNumber.pageLimit)
  }
  
	const indexOfLastPost = currentPage * pageNumberLimit;
	const indexOfFirstPost = indexOfLastPost - pageNumberLimit;
	const currentPosts =  dataList;

	const onPrevClick = () => {
		if ((currentPage - 1) % pageNumberLimit === 0) {
			setMaxPageLimit(maxPageLimit - pageNumberLimit);
			setMinPageLimit(minPageLimit - pageNumberLimit);
		}
		setCurrentPage(prev => prev - 1);
	}

	const onNextClick = () => {
		if (currentPage + 1 > maxPageLimit) {
			setMaxPageLimit(maxPageLimit + pageNumberLimit);
			setMinPageLimit(minPageLimit + pageNumberLimit);
		}
		setCurrentPage(prev => prev + 1);
	}

	const paginationAttributes = {
		currentPage,
		maxPageLimit,
		minPageLimit,
		totalPages: totalcount,
		pageNumberLimit
	};

	const onPaginationSelection = (event) => {
		setPageNumberLimit(event.target.value);
		setCurrentPage(1);
	}
	const getPaymentList = () => {
		var payload3 = {
		  "type": "PaymentSourceData",
		  "shard": 1
		}
	
		mainService.violationCode(payload3).then(result => {
		  if (result.data.status) {
			let filteredList = result.data.data[0].response
			setList(filteredList)
		  }
		}).catch((err) => {
		  if (err.response.status === 401) {
			refreshTokenMiddleware(dispatch)
			getPaymentList()
		  }
		});
	  };
	  useEffect(() => { getPaymentList() }, [])

	return (
    <>
      <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
        <div className="cstm-ticket-manage-sec">
          <div className="cstm-ticket-manage-heading">
            <h3 className="cstm-all-pg-ttl operate-ttl">
              Payment Reconciliation Reports
            </h3>
          </div>

          <PrintPdfCsvExcelDownload
            tableId="tbl-main-download"
            fileName="payment-reconciliation"
            pageSize="a4"
          />
        </div>
        <div className="report-searchbar  mb-2">
          <form>
            <div className="cstm-ctdt">
              <label>{changeTotal} datetime</label>
              <div className="report-date report-mr">
                <DatePicker
                  showTimeSelect
                  timeIntervals={1}
                  dateFormat="MM/dd/yyyy h:mmaa"
                  timeFormat="HH:mm"
                  placeholderText="MM/DD/YYYY HH:MM"
                  autoComplete="off"
                  className="cstm-pull-details schedule-date-input"
                  name="citation_from_date"
                  selected={filterDate.citation_from_date}
                  onChange={(date) =>
                    onChangeFilterDate(date, "citation_from_date")
                  }
                />
                <img src={transfer} alt="transfer" className="date-transfer" />
                <DatePicker
                  showTimeSelect
                  timeIntervals={1}
                  placeholderText="MM/DD/YYYY HH:MM"
                  autoComplete="off"
                  dateFormat="MM/dd/yyyy h:mmaa"
                  timeFormat="HH:mm"
                  className="cstm-pull-details schedule-date-input"
                  name="citation_to_date"
                  selected={filterDate.citation_to_date}
                  onChange={(date) =>
                    onChangeFilterDate(date, "citation_to_date")
                  }
                />
              </div>
            </div>

            <div className="cstm-ctdt">
              <label>Payment datetime</label>
              <div className="report-date report-mr">
                <DatePicker
                  showTimeSelect
                  timeIntervals={1}
                  dateFormat="MM/dd/yyyy h:mmaa"
                  timeFormat="HH:mm"
                  placeholderText="MM/DD/YYYY HH:MM"
                  autoComplete="off"
                  className="cstm-pull-details schedule-date-input"
                  name="payment_from_date"
                  selected={filterDate.payment_from_date}
                  onChange={(date) =>
                    onChangeFilterDate(date, "payment_from_date")
                  }
                />
                <img src={transfer} alt="transfer" className="date-transfer" />
                <DatePicker
                  showTimeSelect
                  timeIntervals={1}
                  placeholderText="MM/DD/YYYY HH:MM"
                  autoComplete="off"
                  dateFormat="MM/dd/yyyy h:mmaa"
                  timeFormat="HH:mm"
                  className="cstm-pull-details schedule-date-input"
                  name="payment_to_date"
                  selected={filterDate.payment_to_date}
                  onChange={(date) =>
                    onChangeFilterDate(date, "payment_to_date")
                  }
                />
              </div>
            </div>
            <div className="report-licence select-drop-arrow report-mr	  mt-4 w-8">
              <div className="select-drop-arrow filter_cstm1">
                <Multiselect
                  selectedValues={filterDate.payment_source}
                  name="payment_source"
                  displayValue="label"
                  placeholder="Select Payment Source"
                  onRemove={(selectedItem) =>
                    handleOptionChange(selectedItem, "payment_source")
                  }
                  onSearch={() => {}}
                  onSelect={(selectedItem) =>
                    handleOptionChange(selectedItem, "payment_source")
                  }
                  options={list.map((it) => ({
                    value: it.source,
                    label: it.source,
                  }))}
                  showCheckbox
                />
              </div>
            </div>
            <button
              className="licence-print report-mr  mt-4"
              onClick={handleFilterSubmit}
            >
              Submit
            </button>
          </form>
        </div>

        <div id="Dashboard" className="tab-pane active">
          <div className="text-center">
            {isLoader ? (
              <div className="text-center spin-load-main">
                <div className="spinner-border"></div>
              </div>
            ) : currentPosts === null || currentPosts?.length === 0 ? (
              <div className="no-record-found-main">No Data Found</div>
            ) : (
              <div className="ctsm-table-scroll">
                <div className="table-overflow" id="tbl-main-download">
                  <table className="table-heading table table-bordered">
                    <thead>
                      <tr>
                        <th
                          className={`srt-tab ${
                            sorting.key === "ticket_number" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("ticket_number", !sorting.ascending)
                          }
                        >
                          {changeName} Number
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "ticket_issue_date" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting(
                              "ticket_issue_date",
                              !sorting.ascending
                            )
                          }
                        >
                          {changeName} Issue Date
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "payment_date" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("payment_date", !sorting.ascending)
                          }
                        >
                          Payment Date
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "amount_paid" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("amount_paid", !sorting.ascending)
                          }
                        >
                          Amount Paid
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "card_number" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("card_number", !sorting.ascending)
                          }
                        >
                          Credit Card Number
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "location" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("location", !sorting.ascending)
                          }
                        >
                          Location
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "payment_source" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("payment_source", !sorting.ascending)
                          }
                        >
                              Payment Source
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(currentPosts || []).map((item, i) => (
                        <tr key={i}>
                          <td>{item?.ticket_number}</td>
                          {item?.ticket_issue_date.substring(0, 10) !==
                          "0001-01-01" ? (
                            <td>
                              {moment(
                                item?.ticket_issue_date,
                                "YYYY-MM-DD"
                              ).format("MM/DD/YYYY") +
                                " " +
                                (item?.ticket_issue_date).substring(11, 19)}
                            </td>
                          ) : (
                            <td>-</td>
                          )}

                          {item?.payment_date.substring(0, 10) !==
                          "0001-01-01" ? (
                            <td>
                              {moment(item?.payment_date, "YYYY-MM-DD").format(
                                "MM/DD/YYYY"
                              ) +
                                " " +
                                (item?.payment_date).substring(11, 19)}
                            </td>
                          ) : (
                            <td>-</td>
                          )}

                          <td>${convertDecimal(item?.amount_paid || 0)}</td>
                          <td>{item?.card_number || "-"}</td>
                          <td>
                            {item?.location ? item?.location?.address ||
                              " " + " " + item?.location?.city ||
                              " " + " " + item?.location?.state ||
                              " " + " " + " " + item?.location?.zipCode ||
                              " " : "-"}
                          </td>
                          <td>{item?.payment_source || "-"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Pagination */}
                <div className="cstm-permit-trf esc-select">
                  <Pagination
                        totalRecords={totalcount}
                        pageLimit={pageNumberLimit}
                        pageNeighbours={1}
                        handlePaginationChange={onPageChange}
                        currentPage={currentPage}
                        dropdownLimit={false}
                  />

                </div>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentReconciliationList