import React, { Component } from 'react';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import DownloadImg from '../../../../Images/download_img_btn.svg';
import Pagination from './Pagination';
import ProductTable from './Sorting';
import Sitesetup from "../../../../Services/dashboard.service";

import { connect } from "react-redux";
import { clearoutAuthUserData } from '../../../../actions/auth'
import { refreshtokenforClassComp } from '../../../../actions/auth';

class SiteSetupList extends Component {

  state = {
    allDataIntigration: [],
    dataIntigrationLog: [],
    currentPage: null,
    setPagination: false,
    setPagination1: false,
    classActiveZone: false,
    classActiveOfficer: false,
    showAuthToken: false,
    totalPages: null,
    setDownloadOption: false,
    TabBasedDisplayName: false
  }

  // When click on Download button
  onClickDonwloadButton = (e) => {
    e.preventDefault();
    this.setState({ setDownloadOption: !this.state.setDownloadOption });
  }

  // Download PDF file 
  donwloadPDFFile = (e) => {
    e.preventDefault();

    var doc = new jsPDF('l', 'pt', 'a4');
    doc.autoTable({ html: '#cstm-dashboard-tb-display' })
    doc.save('Issuance-summary.pdf')
  }

  //  Donwload CSV file
  donwloadCsvFile = (e) => {
    e.preventDefault();
    var csv = [];

    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++)
        row.push(cols[j].innerText);
      csv.push(row.join(","));

    }
    this.downloadCSV(csv.join("\n"), "Issuance-summary.csv");

  }
  downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  }

  componentDidMount() {
    this.setState({ classActiveOfficer: true })
    this.setState({ classActiveZone: false })

    const Issuancefun = () => {
      Sitesetup.IssuanceOfficerLogs()
        .then(allDataIntigration => {
          if (allDataIntigration.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ TabBasedDisplayName: true });
            this.setState({ setPagination: true });
            this.setState({ setPagination1: false });
            this.setState({ allDataIntigration: allDataIntigration.data.data[0].metadata });
          }
        }
        ).catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp()
            Issuancefun()
          }
        });
    }
    Issuancefun()
  }


  officerClick() {
    this.setState({ classActiveOfficer: true })
    this.setState({ classActiveZone: false })

    const IssuanceOfficerfun = () => {
      Sitesetup.IssuanceOfficerLogs()
        .then(allDataIntigration => {
          if (allDataIntigration.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ TabBasedDisplayName: true });
            this.setState({ allDataIntigration: allDataIntigration.data.data[0].metadata });
            this.setState({ setPagination: true });
            this.setState({ setPagination1: false });
  
          }
        }
        ).catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp()
            IssuanceOfficerfun()
          }
        });
    }
    IssuanceOfficerfun()

  }

  zoneClick() {

    this.setState({ classActiveOfficer: false })
    this.setState({ classActiveZone: true })

    const issuancezonefun = () => {
      Sitesetup.IssuanceZoneLogs()
        .then(allDataIntigration => {
          if (allDataIntigration.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ TabBasedDisplayName: false });
            this.setState({ allDataIntigration: allDataIntigration.data.data[0].metadata });
            this.setState({ setPagination: false });
            this.setState({ setPagination1: true });
          }
        }
        ).catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp()
            issuancezonefun()
          }
        });
    }
    issuancezonefun()

  }

  onPageChanged = data => {
    const { allDataIntigration } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const dataIntigrationLog = allDataIntigration.slice(offset, offset + pageLimit);

    this.setState({ currentPage, dataIntigrationLog, totalPages });
  }

  render() {
    const { showAuthToken, setDownloadOption, TabBasedDisplayName, setPagination1, setPagination, classActiveOfficer, classActiveZone, allDataIntigration, dataIntigrationLog, currentPage, totalPages } = this.state;
    const totalCountries = allDataIntigration.length;

    if (totalCountries === 0) return null;

    const headerClass = ['text-dark py-2 pr-4 m-0', currentPage ? 'border-gray border-right' : ''].join(' ').trim();

    return (

      <>

        <div className="cstm_main_issuance_productive">
          <div className="cstm_left_issurce">
            <div className="cstm-aos1_1">Issuance by :-</div>
            <div className="cstm-officer-zone">
              <button clasname="main-cstm-officer-zone" className={` ${classActiveOfficer ? "active-oz" : ""}`} onClick={this.officerClick.bind(this)}>Officer</button>
              <button className={` ${classActiveZone ? "active-oz" : ""}`} onClick={this.zoneClick.bind(this)}>Zone</button>
            </div>
          </div>
          <div className="cstm_right_issurce">
            <button onClick={this.onClickDonwloadButton.bind(this)} className="btn cstm_rev_down_btn">Download <img src={DownloadImg} alt="download" />
              {setDownloadOption ?
                <>
                  <div className="cstm-download-icon">
                    <button onClick={this.donwloadPDFFile.bind(this)} className="cstm-download-btn-sec">
                      Pdf
                    </button>

                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="cstm-download-btn-sec"
                      table="cstm-dashboard-tb-display"
                      filename="Issuance-summary"
                      sheet="Issuance-summary"
                      buttonText="Excel"
                    />
                    <button onClick={this.donwloadCsvFile.bind(this)} className="cstm-download-btn-sec">
                      CSV
                    </button>
                  </div>
                </>
                :
                ""
              }
            </button>
          </div>
        </div>
        <div className="">
          <ProductTable
            products={dataIntigrationLog}
            TabBasedDisplayName={TabBasedDisplayName}
          />
          {setPagination ?
            <div className="table-pagination">
              <Pagination totalRecords={totalCountries} pageLimit={20} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            </div>
            :
            ""
          }
          {setPagination1 ?
            <div className="table-pagination">
              <Pagination totalRecords={totalCountries} pageLimit={20} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            </div>
            :
            ""
          }
        </div>
      </>
    );
  }

}


export default connect(null, { refreshtokenforClassComp })(SiteSetupList)
