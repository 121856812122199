const ConfirmationModalComponent = ({handleCloseModel,displayMsg,handleConfirm,confirmText="Yes, Continue"}) => {
    return (
        <div className="main-body-modal cstm-confirmation-popup">
        <div className="main-modal">
          <div className="cstm-close">
            <button onClick={handleCloseModel}>×</button>
          </div>
          <div className="cstm-confirmation-popup-body">
                <p className="cstm-confirmation-text">{displayMsg}</p>
                <div className="cstm-confirmation-popup-body-main">
                        <button className="confirmation-btn-popup" onClick={handleCloseModel}>Cancel</button>
                        <button className="confirmation-btn-popup" onClick={handleConfirm}>{confirmText}</button>
                </div>
          </div>
        </div>
      </div>
    )
}
export default ConfirmationModalComponent;