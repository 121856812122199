import {Link, useNavigate} from 'react-router-dom';
import ArrowPermitLeft from '../../../../Images/arrow-permit-left.svg';
import ArrowPermitRight from '../../../../Images/arrow-permit-right.svg';
import {useForm, Controller} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {permitStepFormHandler,permitUserInfo} from '../../../../actions/permits';
import DatePicker from 'react-datepicker';
import {useEffect, useState} from 'react';
import PermitStepPlateDetailsForm from './permitStepPlateDetailsForm';
import SignaturePad from 'react-signature-canvas';
import PermitUserRegister from './permitUserRegister';
import moment from 'moment/moment';
import PermitCheckoutContainer from '../permitCheckout/permitCheckoutContainer';
import PermitStepAddressDetails from './permitStepAddressDetails';
import PermitStepUserDetails from './permitStepUserDetails';
import PermitDocumentUpload from './permitDocumentUpload';
import dashboardService from '../../../../Services/dashboard.service';
import Cookies from "js-cookie";
import PermitHandTagField from './permitHandTagField';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PermitFormComponent = ({
  formFields,
  stepNo,
  onClickNavigate,
  setShowStepDetails,
  totalSteps,
  permitData,
  permitName,
  residentType
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permitStepData = useSelector(state => state.permitReducer.stepFormData);
  const [quantityKey, setQuantityKey] = useState('');
  const [plateObjectKey, setPlateObjectKey] = useState('');
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [checkboxKey, setCheckboxKey] = useState();
  const [checkError, setCheckError] = useState();
  const [currentComponent,setCurrentComponent] = useState("")
  const [stepLoader,setStepLoader] = useState(false)
  const [currentValidation,setCurrentValidation] = useState("")

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    getValues,
    setError,
    formState: {errors},
  } = useForm();

  const permitUserData = useSelector(
    state => state.permitReducer.permitUserInfo,
  );

  useEffect(() => {
    if (permitStepData) {
      reset(permitStepData);
    }
  }, [permitStepData, stepNo]);

  useEffect(() => {
    if (formFields && formFields?.fields[0].key_name) {
      if (formFields?.component === 'DatePicker') {
        setValue(formFields?.fields[0].key_name, new Date());
      } else {
        setValue(formFields?.fields[0].key_name, '');
      }
    } else {
      if (formFields && formFields?.component === 'DatePicker') {
        setValue(formFields?.fields[0].repr, new Date());
      } else {
        setValue(formFields?.fields[0]?.repr, '');
      }
    }
    if (formFields && formFields?.component === 'PermitQuantity') {
      setQuantityKey(formFields?.fields[0].key_name);
    }
    if (formFields && formFields?.component === 'CheckBox') {
      setCheckboxKey(formFields?.fields[0].key_name);
      setCheckboxOptions(formFields?.fields[0].options);
    }
  }, [formFields]);

  const verifyPermitAddress = async (payload, formData) => {
    setStepLoader(true)
    try {
      const response = await dashboardService.verifyPermitAddress(payload,Cookies.get("siteinfo"));
      if (response.data.data.is_available) {
        toast.success(response.data.message);
        setTimeout(() => {
          stepSubmitHandler(formData)
        },1000)
      } else {
        toast.error(response.data.message || 'Something Went Wrong.');
      }
    } catch (err) {
      toast.error(err.response.data.message || 'Something Went Wrong.');
    }
    setStepLoader(false)
  }

  const formSubmitHandler = (payload) => {

    if (currentComponent === "AddressDetails") {
      if (currentValidation.hasOwnProperty('is_address_validation_required') && !currentValidation.is_address_validation_required) {
          stepSubmitHandler(payload)
      } else {
        verifyPermitAddress({
          permit_config_id: permitData.id,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          zip_code: payload.zip_code,
        },payload)
      }
    } else {
      stepSubmitHandler(payload)
    }
  };

  const stepSubmitHandler = (payload) => {
    if (checkboxKey && checkboxOptions && checkboxOptions.length > 0) {
      const anyTrue = Object.values(payload[checkboxKey]).some(
        value => value === true,
      );
      if (anyTrue) {
        dispatch(permitStepFormHandler({ ...permitStepData, ...payload }));
        setValue(formFields?.fields[0].key_name, '');
        if (residentType !== "VISITOR" && Number(stepNo) === Number(totalSteps - 1)) {
          onClickNavigate(`#step${Number(stepNo) + 1}`);
          navigate(`#step${Number(stepNo) + 1}`, {
            state: { permitStepKey: plateObjectKey },
          });
        } else if (residentType === "VISITOR" && Number(stepNo) === Number(totalSteps - 1)) {
          handleUpdateProfile(payload)
        } else {
          navigate(`#step${Number(stepNo) + 1}`);
          onClickNavigate(`step${Number(stepNo) + 1}`);
        }
        setCheckError('');
      } else {
        setCheckError(`${checkboxKey} is required`);
      }
    } else {
      dispatch(permitStepFormHandler({ ...permitStepData, ...payload }));
      setValue(formFields?.fields[0].key_name, '');
      if (residentType !== "VISITOR" && Number(stepNo) === Number(totalSteps - 1)) {
        navigate(`#step${Number(stepNo) + 1}`, {
          state: { permitStepKey: plateObjectKey },
        });
        onClickNavigate(`#step${Number(stepNo) + 1}`);
      } else if (residentType === "VISITOR" && Number(stepNo) === Number(totalSteps - 1)) {
        handleUpdateProfile(payload)
      } else {
        navigate(`#step${Number(stepNo) + 1}`);
        onClickNavigate(`step${Number(stepNo) + 1}`);
      }
      setCheckError('');
    }
  }

  const plateDetails = permitStepData?.plate_details?.map(item => ({
    vehicle_details: {
      lp_number: item.lp_number,
      make: item.make,
      model: item.model,
      year: `${item.year}`,
      color: item.color,
    },
    state: item.state,
    start_date: moment(item.start_date).format('YYYY-MM-DDTHH:mm:ssZ'),
  }));

  const handleUpdateProfile = async payload => {
    // e.preventDefault();

    let isValid = false;

    if (!isValid) {

      const payloadData = {
        plate_details: plateDetails,
        permit_config_id: permitData.id,
        user_address: {
          address: payload.address,
          city: payload.city,
          email: payload.email,
          first_name: payload.first_name,
          last_name: payload.last_name,
          phone_number: '+1' + payload.phone_number,
          state: payload.state,
          user_name: payload.user_name,
          zip_code: payload.zip_code,
          phone_code: "+1",
          company: "",
          country: "",
          postal_code: "",
        },
        duration: permitStepData.duration.toUpperCase(),
        permit_quantity: Number(permitStepData.permit_quantity),
        permit_notify_updates:
          permitStepData.permit_notify_updates === 'Yes, i want to sign up'
            ? true
            : false,
        tier_number: 1,
        documents: [],
      };

      if (permitStepData?.hand_tag_id && permitStepData?.hand_tag_id.length > 0) {
        payloadData.hand_tag_id = permitStepData?.hand_tag_id.map(itm => itm.value)
      }

      const userInfo = {...payloadData};
      dispatch(permitUserInfo({...permitUserData, ...userInfo}));

      doCheckout(payloadData);
    }
  };

  const doCheckout = async payloadData => {
    setStepLoader(true);
    try {
      const response = await dashboardService.buyPermitWithRegister(
        payloadData,
        Cookies.get("siteinfo"),
      );
      if (response.data.data.reference_id !== '') {
        navigate(`#step${Number(stepNo) + 2}`, {
          state: {
            refId: response.data.data.reference_id,
          },
        });
        onClickNavigate(`step${Number(stepNo) + 2}`);
      }
    } catch (err) {
      console.log(err,'err')
    } finally {
      setStepLoader(false);
    }
  };

  const [signature, setSignature] = useState(null);

  const clearPad = () => {
    if (signature) {
      signature.clear();
    }
  };

  const savePad = fieldName => {
    // Get the signature data
    const signatureData = signature.toDataURL();
    // Update the form field value
    setValue(fieldName, signatureData);
  };

  const constructValidationRules = (fieldName, validation) => {
    const rules = {
      required:
        validation && validation?.required
          ? `${fieldName} field is required`
          : false,
    };

    if (validation && validation.numerical_range) {
      if (validation?.numerical_range?.maximum !== undefined) {
        rules.max = {
          value: validation?.numerical_range?.maximum,
          message: `Maximum quantity allowed is ${validation?.numerical_range?.maximum}`,
        };
      }

      if (validation?.numerical_range?.minimum !== undefined) {
        rules.min = {
          value: validation?.numerical_range?.minimum,
          message: `Minimum quantity allowed is ${validation?.numerical_range?.minimum}`,
        };
      }
    }

    return rules;
  };

  useEffect(() => {
    if (formFields && formFields?.component !== "") {
      setCurrentComponent(formFields?.component)
      setCurrentValidation(formFields?.validation)
    }
  }, [formFields])
  
  useEffect(() => {
    if (formFields && formFields?.fields[0]?.key_name && permitStepData) {
      if (permitStepData[formFields.fields[0]?.key_name]) {
        setValue(formFields.fields[0]?.key_name, permitStepData[formFields.fields[0]?.key_name], );
      }
    }
  },[permitStepData,formFields])

  const renderFormFields = (component, stepField, validation) => {
    
    if (component === 'TextBox') {
      return (
        <>
          <Controller
            name={stepField[0].key_name || stepField[0].repr}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => (
              <>
                <div className={formFields?.validation.input_type === 'number' ? "permit-step-quantity": "permit-step-fl"}>
                  {formFields?.validation.input_type === 'number' && <button
                    type="button"
                    className={`permit-step-quantity-btn ${Number(field.value) <= 1 && 'disable'}`}
                    onClick={() => field.onChange(Math.max(1, (parseFloat(field.value) || 0) - 1))}
                    disabled={!stepField[0].is_editable}
                  >
                    -
                  </button>}
                  <input
                    type={`${
                      stepField[0].data_type_validation &&
                      stepField[0].data_type_validation.toLowerCase() ===
                        'number'
                        ? 'number'
                        : 'text'
                    }`}
                    className={formFields?.validation.input_type === 'number' ? 'permit-step-quantity-input' : 'permit-field-que'}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={!stepField[0].is_editable}
                  />
                  {formFields?.validation.input_type === 'number' &&
                    <button
                      type="button"
                      className={`permit-step-quantity-btn 
                        ${Number(field.value) == validation.numerical_range.maximum && 'disable'}`}
                      onClick={() => field.onChange((parseFloat(field.value) || 0) + 1)}
                      disabled={!stepField[0].is_editable}
                    >
                      +
                    </button>
                  }
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
        </>
      );
    }
    if (component === 'Dropdown') {
      return (
        <>
          <Controller
            name={stepField[0].key_name || stepField[0].repr}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => (
              <>
                <div className="permit-step-fl">
                  <select
                    name={stepField[0].key_name || stepField[0].repr}
                    onChange={field.onChange}
                    value={field.value}
                    disabled={!stepField[0].is_editable}>
                    <option> select {stepField[0].repr} </option>
                    {stepField[0].options.map(itm => (
                      <option value={itm.title}>{itm.title}</option>
                    ))}
                  </select>
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
        </>
      );
    }
    if (component === 'CheckBox') {
      return (
        <>
          {stepField[0].options.map(
            itm => (
              (
                <Controller
                  name={`${stepField[0].key_name}.${itm.title}`}
                  control={control}
                  rules={constructValidationRules(
                    stepField[0].repr,
                    validation,
                  )}
                  render={({field, fieldState}) => (
                    <>
                      <label className="permit-question-box-ls">
                        <input
                          type="checkbox"
                          onChange={e => (
                            field.onChange(e.target.checked), setCheckError('')
                          )}
                          value={field.value}
                          checked={field.value}
                          disabled={!stepField[0].is_editable}
                        />
                        <option value={itm.title}>{itm.title}</option>
                      </label>
                    </>
                  )}
                />
              )
            ),
          )}
          {checkError && checkError !== '' && (
            <span className="errormessage">{checkError}</span>
          )}
        </>
      );
    }
    if (component === 'Radio Button') {
      return (
        <>
          {stepField[0].options.map((itm, index) => (
            <Controller
              key={index}
              name={stepField[0].key_name || stepField[0].repr}
              control={control}
              rules={constructValidationRules(stepField[0].repr, validation)}
              render={({field}) => (
                <>
                  <label className="permit-question-box-ls">
                    <input
                      type="radio"
                      value={itm.title}
                      onChange={field.onChange}
                      checked={field.value === itm.title}
                      disabled={!itm.isEditable}
                    />
                    {itm.title}
                  </label>
                </>
              )}
            />
          ))}

          {errors[stepField[0].key_name] && (
            <span className="errormessage">
              {errors[stepField[0].key_name].message}
            </span>
          )}
        </>
      );
    }
    if (component === 'Textarea') {
      return (
        <>
          <Controller
            name={stepField[0].key_name || stepField[0].repr}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => (
              <>
                <div className="permit-step-fl">
                  <textarea
                    rows={3}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={!stepField[0].is_editable}></textarea>
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
        </>
      );
    }
    if (component === 'DatePicker') {
      return (
        <>
          <Controller
            name={stepField[0].key_name}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => {
              return (
                <>
                  <div className="permit-step-fl">
                    <DatePicker
                      showTimeSelect
                      timeIntervals={1}
                      placeholderText="MM/DD/YYYY HH:MM"
                      dateFormat="MM/dd/yyyy h:mmaa"
                      timeFormat="HH:mm"
                      className={`permit-field-que`}
                      autoComplete="off"
                      selected={
                        field.value === '' ? new Date() : new Date(field.value)
                      }
                      onChange={field.onChange}
                      disabled={!stepField[0].is_editable}
                      minDate={
                        validation &&
                        validation.date_range &&
                        validation.date_range.minimum &&
                        moment(validation.date_range.minimum).toDate()
                      }
                      maxDate={
                        validation &&
                        validation.date_range &&
                        validation.date_range.minimum &&
                        moment(validation.date_range.maximum).toDate()
                      }
                    />
                    {fieldState.error && (
                      <span className="errormessage">
                        {fieldState.error.message}
                      </span>
                    )}
                  </div>
                </>
              );
            }}
          />
        </>
      );
    }
    if (component === 'PermitDuration') {
      return (
        <>
          {stepField[0].options.map(itm => (
            <Controller
              name={stepField[0].key_name || stepField[0].repr}
              control={control}
              rules={constructValidationRules(stepField[0].repr, validation)}
              render={({field}) => (
                <>
                  <label className="permit-question-box-ls">
                    <input
                      name={stepField[0].key_name || stepField[0].repr}
                      type="radio"
                      value={itm.title}
                      onChange={field.onChange}
                      checked={field.value === itm.title}
                      disabled={!stepField[0].is_editable}
                    />
                    {itm.title}
                  </label>
                </>
              )}
            />
          ))}
          {errors[stepField[0].key_name || stepField[0].repr] && (
            <span className="errormessage ">
              {errors[stepField[0].key_name || stepField[0].repr].message}
            </span>
          )}
        </>
      );
    }
    if (component === 'PermitQuantity') {
      return (
        <>
          <Controller
            name={stepField[0].key_name || stepField[0].repr}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => (
              <>
                <div className={formFields?.validation?.input_type === 'number' ? "permit-step-quantity": "permit-step-fl"}>
                  {formFields?.validation?.input_type === 'number' && <button
                    type="button"
                    className={`permit-step-quantity-btn ${Number(field.value) <= 1 && 'disable'}`}
                    onClick={() => field.onChange(Math.max(1, (parseFloat(field.value) || 0) - 1))}
                    disabled={!stepField[0].is_editable}
                  >
                    -
                  </button>}
                  <input
                    type={`${
                      stepField[0]?.data_type_validation &&
                      stepField[0]?.data_type_validation.toLowerCase() ===
                        'number'
                        ? 'number'
                        : 'text'
                    }`}
                    className={formFields?.validation?.input_type === 'number' ? 'permit-step-quantity-input' : 'permit-field-que'}
                    value={field.value}
                    onChange={field.onChange}
                    onKeyPress={event => {
                      if (!/^\d{0,4}(\.\d{0,4})?$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    disabled={!stepField[0].is_editable}
                  />
                  {formFields?.validation?.input_type === 'number' &&
                    <button
                      type="button"
                      className={`permit-step-quantity-btn 
                        ${Number(field.value) == validation?.numerical_range?.maximum && 'disable'}`}
                      onClick={() => field.onChange((parseFloat(field.value) || 0) + 1)}
                      disabled={!stepField[0].is_editable}
                    >
                      +
                    </button>
                  }
                </div>
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
              </>
            )}
          />
        </>
      );
    }
    if (component === 'RecurringPayment') {
      return (
        <>
          {stepField[0].options.map(itm => (
            <Controller
              name={stepField[0].key_name || stepField[0].repr}
              control={control}
              rules={constructValidationRules(stepField[0].repr, validation)}
              render={({field, fieldState}) => (
                <>
                  <label className="permit-question-box-ls">
                    <input
                      type="radio"
                      value={itm.title}
                      onChange={field.onChange}
                      checked={field.value === itm.title}
                      disabled={!stepField[0].is_editable}
                    />
                    {itm.title}
                  </label>
                </>
              )}
            />
          ))}
          {errors[stepField[0].key_name || stepField[0].repr] && (
            <span className="errormessage ">
              {errors[stepField[0].key_name || stepField[0].repr].message}
            </span>
          )}
        </>
      );
    }
    if (component === 'PermitNotification') {
      return (
        <>
          {stepField[0].options.map(itm => (
            <Controller
              name={stepField[0].key_name || stepField[0].repr}
              control={control}
              rules={constructValidationRules(stepField[0].repr, validation)}
              render={({field, fieldState}) => (
                <>
                  <label className="permit-question-box-ls">
                    <input
                      type="radio"
                      value={itm.title}
                      onChange={field.onChange}
                      checked={field.value === itm.title}
                      disabled={!stepField[0].is_editable}
                    />
                    {itm.title}
                  </label>
                </>
              )}
            />
          ))}
          {errors[stepField[0].key_name || stepField[0].repr] && (
            <span className="errormessage ">
              {errors[stepField[0].key_name || stepField[0].repr].message}
            </span>
          )}
        </>
      );
    }
    if (component === 'File') {
      return (
        <>
          <Controller
            name={stepField[0].key_name || stepField[0].repr}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => (
              <>
                <div className="permit-step-fl">
                  <input
                    type="file"
                    className="permit-field-que"
                    disabled={!stepField[0].is_editable}
                  />
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
        </>
      );
    }
    if (component === 'Signature') {
      return (
        <>
          <Controller
            name={stepField[0].key_name}
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({field, fieldState}) => (
              <>
                <div className="permit-step-fl" id="canvas">
                  <button onClick={clearPad} type="button">
                    Clear
                  </button>
                  <button
                    onClick={() => savePad(stepField[0].key_name)}
                    type="button">
                    Save
                  </button>
                  <SignaturePad
                    ref={ref => setSignature(ref)}
                    canvasProps={{width: 500, height: 200}} // Set fixed dimensions
                  />
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
        </>
      );
    }
    if (component === 'AddressDetails') {
      return (
        <>
          <PermitStepAddressDetails
            stepField={stepField}
            constructValidationRules={constructValidationRules}
            control={control}
            Controller={Controller}
            validation={validation}
            clearErrors={clearErrors}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </>
      )
    }
    if (component === 'UserDetails') {
      return (
        <>
          <PermitStepUserDetails
            stepField={stepField}
            constructValidationRules={constructValidationRules}
            control={control}
            Controller={Controller}
            validation={validation}
          />
        </>
      )
    }
  };

  return (
    <>
      <ToastContainer />
      <section>
        <div className="container-fluid">
          <div className="row">
            {/* Content starts */}
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
      {Number(stepNo) <= Number(totalSteps) ? (
        <div className="wrapper question-bg-olny">
          <div className="container-fluid searchResult-section">
                    <div className="permit-question-main permit-step-main">
                      <h3>{permitName}</h3>
              {Number(stepNo) < Number(totalSteps) && (
                <>
                  {formFields?.validation &&
                    formFields?.validation.bulk_upload && (
                      <div className="permit-bulk-btn">
                        {formFields?.validation?.bulk_upload?.button_download && (
                          <button className="cstm-permit-btn">
                            Download format
                          </button>
                        )}
                        {formFields?.validation?.bulk_upload?.button_text && (
                          <button className="cstm-permit-btn">
                            Upload CSV
                          </button>
                        )}
                      </div>
                    )}
                </>
              )}
              <div className="permit-question-main-cont">
              <div className="counter-tabination">
            <ul className="nav nav-tabs2 permit-pagination-content">
              {new Array(Number(totalSteps) + (residentType !== "VISITOR" && 1)).fill(0).map((step, index) => (
                <li
                  key={index}
                  className="nav-item2"
                >
                  <div
                    className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${
                     index + 1 === Number(stepNo) + 1  ? "active" : ""
                    } `}
                  >
                    <span className="main-round-border">{`0${index + 1}`}</span>
                    <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                    </p>
                  </div>
                </li>
              ))}
            </ul>
      </div>
                
                {Number(stepNo) < Number(totalSteps) && (
                  <h6>{formFields?.question_name}</h6>
                )}
              </div>
              {formFields && formFields?.component === 'HandTagId' ? ( 
                <PermitHandTagField
                  stepField={formFields?.fields}
                  constructValidationRules={constructValidationRules}
                  validation={formFields?.validation}
                  formSubmitHandler={formSubmitHandler}
                  stepNo={stepNo}
                  totalSteps={totalSteps}
                  onClickNavigate={onClickNavigate}
                  setShowStepDetails={setShowStepDetails}
                />
              ) : formFields && formFields?.component === 'PermitPlateDetails' ? (
                <PermitStepPlateDetailsForm
                  permitQuantity={permitStepData[quantityKey]}
                  objectKey={formFields?.object_key}
                  keyNames={formFields?.fields}
                  onClickNavigate={onClickNavigate}
                  formSubmitHandler={formSubmitHandler}
                  stepNo={stepNo}
                  totalSteps={totalSteps}
                  setPlateObjectKey={setPlateObjectKey}
                  validations={formFields?.validation}
                />
              ) : Number(stepNo) < Number(totalSteps) ? (
                <form onSubmit={handleSubmit(formSubmitHandler)}>
                {formFields.component !== "AddressDetails" && formFields.component !== "UserDetails" &&
                    <h6
                      className="permit-field-box-label"
                      style={{ textAlign: formFields.fields[0].aligned }}>
                      {formFields.fields[0].repr}{' '}
                      {formFields.validation &&
                        formFields.validation.required &&
                        '*'}
                    </h6>}
                  <div className="permit-question-box-ls-main">
                    {renderFormFields(
                      formFields?.component,
                      formFields?.fields,
                      formFields?.validation,
                      formFields?.object_key,
                      stepNo,
                      totalSteps,
                      )}
                  </div>

                  <div className="permit-question-btn">
                    <button
                      className="permit-question-btn-left"
                      type="button"
                      onClick={() => (
                        Number(stepNo) === 0
                          ? (navigate(-1), setShowStepDetails(false))
                          : navigate(`#step${Number(stepNo) - 1}`),
                        onClickNavigate(`step${Number(stepNo) - 1}`)
                      )}>
                      <img src={ArrowPermitLeft} alt="back" />
                      Back
                    </button>
                    <button
                      className="permit-question-btn-right cstm-permit-btn"
                      type="submit">
                        {stepLoader && (
                          <div className="ptc-ld">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                          </div>
                        )}
                      Next
                      <img src={ArrowPermitRight} alt="next" />
                    </button>
                  </div>
                </form>
              ) : (
                  residentType !== "VISITOR" && Number(stepNo) === Number(totalSteps) && (
                    <PermitDocumentUpload
                      permitData={permitData}
                      permitStepData={permitStepData}
                      stepNo={stepNo}
                      onClickNavigate={onClickNavigate}
                      setShowStepDetails={setShowStepDetails}
                    />
                  // <PermitUserRegister
                  //   permitData={permitData}
                  //   permitStepData={permitStepData}
                  //   stepNo={stepNo}
                  //   onClickNavigate={onClickNavigate}
                  //   setShowStepDetails={setShowStepDetails}
                  // />
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <PermitCheckoutContainer
          stepNo={stepNo}
          onClickNavigate={onClickNavigate}
          setShowStepDetails={setShowStepDetails}
          totalSteps={totalSteps}
          permitData={permitData}
        />
      )}
            </div>
          </div>
        </div>
        </section>
    </>
  );
};

export default PermitFormComponent;
