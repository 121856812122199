import axios from "axios";
import Cookies from "js-cookie";
import CookiesRemove from "../Cookiesremove";


const pathname = window.location.hostname;
let API_URL = '';
if (pathname === "parkmystic-processing-staging.netlify.app" || pathname === "bellingham-processing-staging.parkloyalty.com" || pathname === "greenvillesc-processing-staging.netlify.app" || pathname === "mansfield-processing-staging.netlify.app" || pathname === "ace-ticketprocessing-staging.netlify.app" || pathname === "duncan-ticketprocessing-staging.netlify.app" || pathname === "spplus-ticketprocessing-staging.netlify.app" || pathname === "leavenworth-processing-staging.netlify.app" || pathname === "follybeach-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkocmd.com" || pathname === "lazstaging-processing.netlify.app" || pathname === "staging-processing.parkcountryclubplaza.com" || pathname === "staging-processing.parkkalamazoo.com" || pathname === "staging-processing-parkkalamazoo.netlify.app" || pathname === "staging-processing.parkbismarcknd.com" || pathname === "staging-processing-parkbismarck.netlify.app" || pathname === "staging-processing-parkiop.netlify.app" || pathname === "staging-processing.parkiop.com" || pathname === "staging-processing-parkbangor.netlify.app" || pathname === "staging-processing.parkbangor.org" || pathname === "follybeach-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkashlandoregon.com" || pathname === "staging-processing-parkashlandoregon.netlify.app" || pathname === "staging-processing-parkriveroakssc.netlify.app" || pathname === "staging-processing.parkriveroakssc.com" || pathname === "staging-processing.parkbeaufortnc.com" || pathname === "staging-processing-parkbeaufortnc.netlify.app" ||
pathname === "staging-processing-hpa2pay.netlify.app" ||
pathname === "staging-processing.hpa2pay.com" || pathname === 'staging-processing-parkcohasset.netlify.app' ||
pathname === 'staging-processing.parkcohasset.com' || pathname === 'staging-processing.parkingenforcementco.com' ||
pathname === 'staging-processing-parkingenforcementco.netlify.app' || pathname === 'staging-processing.parksepta.com' ||
pathname === 'staging-processing-parksepta.netlify.app' || pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
pathname === 'staging-processing.parkcharleston-sc.com' || pathname === 'staging-processing-parkkcmo.netlify.app' ||
pathname === 'staging-processing.parkkcmo.com') {
    API_URL = "https://devapi.parkloyalty.com/";
} else if (pathname === "parkmystic-processing.netlify.app" || pathname === "bellingham-ticketprocessing.parkloyalty.com" || pathname === "mansfield-processing.netlify.app" || pathname === "ace-ticketprocessing.netlify.app" || pathname === "duncan-ticketprocessing.netlify.app" || pathname === "spplus-ticketprocessing.netlify.app" || pathname === "leavenworth-processing.netlify.app" || pathname === "follybeach-processing.netlify.app" || pathname === "follybeach-processing.parkloyalty.com" || pathname === "laz-processing.netlify.app" || pathname === "processing.parkcountryclubplaza.com" || pathname === "processing-parkkalamazoo.netlify.app" || pathname === "processing.parkkalamazoo.com" || pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com" || pathname === "processing-bismarck.netlify.app" || pathname === "processing.parkbismarcknd.com" || pathname === "processing.parkocmd.com" || pathname === "processing-parkocmd.netlify.app" || pathname === "processing-parkbangor.netlify.app" || pathname === "processing.parkbangor.org" || pathname === "processing.parkashlandoregon.com" || pathname === "processing-parkashlandoregon.netlify.app" || pathname === "processing-parkriveroakssc.netlify.app" || pathname === "processing.parkriveroakssc.com" || pathname === "processing.parkbeaufortnc.com" || pathname === "processing-parkbeaufortnc.netlify.app" || pathname === "processing.hpa2pay.com" ||
pathname === "processing-hpa2pay.netlify.app" || pathname === 'processing-parkcohasset.netlify.app' ||
pathname === 'processing.parkcohasset.com' || pathname === 'processing-parkingenforcementco.netlify.app' ||
pathname === 'processing.parkingenforcementco.com' || pathname === 'processing.parksepta.com' ||
pathname === 'processing-parksepta.netlify.app' ||pathname === 'processing-parkcharleston-sc.netlify.app' ||
pathname === 'processing.parkcharleston-sc.com' || pathname === 'processing-parkkcmo.netlify.app' ||
pathname === 'processing.parkkcmo.com') {
    API_URL = "https://api.parkloyalty.com/";
} else if (pathname === "localhost" || pathname === "ticketprocessing-parkloyalty.netlify.app") {
    API_URL = "https://devapi.parkloyalty.com/";
}
 else {
    API_URL = "https://devapi.parkloyalty.com/";
}

const requestGuestLoginOTP = (data) => {
    return axios.post(API_URL + "user/request-login-otp", data)
};

const requestGuestVerifyOTP = (data) => {
    return axios
        .post(API_URL + "user/cp/verify-otp", data)
        .then((response) => {
            if (response.data.status) {
                if (response.data.data.otp_verified) {
                    Cookies.set("token", response.data.data.access_token_data.token);
                    Cookies.set("guestuser", JSON.stringify(response.data.data));
                }
            }
            return response.data;
        });
};


const logoutHelicalUser = () => {
    return axios.get('https://reports.parkloyalty.com/hi-ee/j_spring_security_logout');
};

const logout = () => {
    Cookies.remove("user");
};

const clearoutAuthUserData = () => {
    CookiesRemove();
};

const authService = {
    requestGuestLoginOTP,
    requestGuestVerifyOTP,
    logout,
    logoutHelicalUser,
    clearoutAuthUserData
};

export default authService;