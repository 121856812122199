import {
  DatePickerComponent,
  FilterComponent,
  ReportEditComponent,
} from "./index";
import PrintPdfCsvExcelDownload from "../../../Utilies/PrintPdfCsvExcelDownload";
import { useState, useRef } from "react";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";
import $ from "jquery";
import dropdownarrow from "../../../../Images/dropdown-arrow.svg";
import Reportingsetup from "../../../../Services/main.service";
import moment from "moment";
import { changetotal, siteid, changeticket } from "../../../../Global/site";
import ImageSlider from "../../../../utile/imageSlider/imageSlider";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import SlideImages from "../../../../utile/slideImages/slideImages";
import SearchLicensePlate from "../../../Citation/SearchLicensePlate";
import EditCitationModal from "../../../Citation/EditCitationModal";
import Pagination from "../../../../utile/pagination";

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

const ReportTableComponent = ({
  reports,
  tableHeadings,
  keySequence,
  fetchData,
  loading,
  editable,
  isImages
}) => {
  const location = useLocation().search;
  const navigate = useNavigate()
  const changeTotal = changetotal();
  const changeTicket = changeticket();
  const showSideId = siteid();
  const siteInfo = JSON.parse(Cookies.get("auth"));
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const [endDate, setEndDate] = useState(new Date().setHours(0, 0, 0));
  const [lpNumber, setLpNumber] = useState("");
  const [ticketNo, setTicketNo] = useState("");
  const [filterIconToggle, setFilterIconToggle] = useState(false);
  const [stateFilter, setStateFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDateSearch, setStartDateSearch] = useState();
  const [endDateSearch, setEndDateSearch] = useState();
  const [street, setStreet] = useState("");
  const [block, setBlock] = useState("");
  const [groupByOfficer, setGroupByOfficer] = useState();
  const [violationCodeFilter, setViolationCodeFilter] = useState([]);
  const [violationDescFilter, setViolationDescFilter] = useState([]);
  const [beatFilter, setBeatFilter] = useState([]);
  const [zoneFilter, setZoneFilter] = useState([]);
  const [status, setStatus] = useState("");
  const [roStatus, setRoStatus] = useState("")
  const [officerNameFilter, setOfficerNameFilter] = useState([]);
  const [peoNameFilter, setPeoNameFilter] = useState([]);
  const [badgeIDFilter, setBadgeIDFilter] = useState([]);
  const [stateFilterRemarks, setStateFilterRemarks] = useState([]);
  const [shiftFilter, setShiftFilter] = useState([]);
  const [agencyFilter, setAgencyFilter] = useState([]);
  const [blockTo, setBlockTo] = useState();
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [itemsPerPage, setItemsPerPage] = useState(200);
  const [changeFieldIndex, setChangeFieldIndex] = useState("");
  const [inputValue, setInputValue] = useState();
  const [citeid, setCiteId] = useState("");
  const [streetList, setStreetList] = useState([]);
  const [imageId, setimageId] = useState(0);
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const [slideImages, setSlideImages] = useState([]);
  const [checked, setChecked] = useState(false);
  const [showSelectedId, setShowSelectedId] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [showEditCitationModal, setShowEditCitationModal] = useState(false);
  const [editCitationFormChangeData, setEditCitationFormChangeData] = useState({
    citation_start_timestamp: "",
    ticket_no: "",
    block: "",
    street: "",
    state: "",
    lp_number: "",
    citation_id: "",
    remarks: "",
    remark_1: "",
    remark_2: "",
    note_1: "",
    note_2: "",
    description: "",
    code: "",
  });
  const [formData, setFormData] = useState([]);
  const [showEditPage, setShowEditpage] = useState(false);
  const [formImageData, setFormImageData] = useState([]);
  const [violationCode, setViolationCode] = useState([]);
  const [showError, setShowError] = useState(false);
  const [fieldError, setFieldError] = useState({ remarks: "" });
  const [remarks, setRemarks] = useState([]); 
  const [roSource,setRoSource] = useState("")
  const [driveOff,setDriveOff] = useState("")
  const [ticketType,setTicketType] = useState("")
  const [reportImages, setReportImages] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [isLoader,setIsLoader]=useState(false)
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(1);

  // initial fetch api call with date default values

  const exportsLogsData = () => {
    var date = new Date();
    var date1 = new Date(endDate);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var nextMonth = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
    }).format(startDate);
    var todayDate = pad2(date.getDate());
    var tomorrowDate = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(startDate);

    setStartDateSearch(
      year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z"
    );

    setEndDateSearch(
      date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T" +
        (date1.getHours() < 10 ? "0" : "") +
        date1.getHours() +
        ":" +
        (date1.getMinutes() < 10 ? "0" : "") +
        date1.getMinutes() +
        ":00.000Z"
    );

    if (
      Cookies.get("ticket_no_search") !== undefined &&
      Cookies.get("ticket_no_search") !== "" &&
      Cookies.get("ticket_no_search") !== null
    ) {
      setTicketNo(Cookies.get("ticket_no_search"));
      var passData = "ticket_no=" + Cookies.get("ticket_no_search");
    } else {
      var passData =
        "issue_ts_from=" +
        date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T00:00:00.000Z&issue_ts_to=" +
        year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z";
    }

    fetchData(passData);
  };

  useEffect(() => {
    if(location) {
      setShowEditpage(true)
    }
    exportsLogsData();
  }, []);

  // datepicker change handler

const onChangeSearchDate = (filterDate, type) => {
  const newDate = new Date(filterDate);
  const year = newDate.getFullYear();
  const month = pad2(newDate.getMonth() + 1);
  const todayDate = pad2(newDate.getDate());
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  var currentTimeInSeconds =
  year +
  "-" +
  month +
  "-" +
  todayDate +
  "T" +
  (newDate.getHours() < 10 ? "0" : "") +
  newDate.getHours() +
  ":" +
  (newDate.getMinutes() < 10 ? "0" : "") +
  newDate.getMinutes() +
  ":00.000Z";

    if (type === null || type === "" || type === "start"){
    if (!filterDate) {
      setStartDate("");
      return;
    }
    const isDateChange = !(startDate instanceof Date) || filterDate?.getDate() !== new Date(startDate).getDate()|| (hours == 0 && minutes == 0);
    if(isDateChange){
      let formattedDate = new Date(newDate);
      formattedDate.setHours(23, 59, 59, 999);
      setStartDate(formattedDate);
    }else{
      setStartDate(newDate);
    }
    setStartDateSearch(currentTimeInSeconds);
  } else if (type === "end") {
    if (!filterDate) {
      setEndDate("");
      return;
    }
    setEndDate(newDate);
    setEndDateSearch(currentTimeInSeconds);
  }
};

  // filter toggle handler
  const onClickFilter = (e) => {
    e.preventDefault();
    setFilterIconToggle(!filterIconToggle);
  };

  // filter change handler for input fields
  const onChangeSearchFilter = (e) => {
    if (e.target.name !== "group_by_officer" && e.target.name !== "drive_off") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (name === "lp_number") {
      let newValue = value;
      newValue = SearchLicensePlate(value);
      setLpNumber(newValue);
    } else if (name === "ticket_no") {
      setTicketNo(value);
    } else if (name === "street") {
      setStreet(value);
    } else if (name === "block") {
      setBlock(value);
    } else if (name === "group_by_officer") {
      setGroupByOfficer(e.target.checked);
    } else if (name === "status") {
      setStatus(value);
    } else if (name === "rostatus") {
      setRoStatus(value);
    } else if (name === "rosource") {
      setRoSource(value);
    } else if (name === "drive_off") {
        setDriveOff(e.target.checked ? e.target.checked : "");
    } else if (name === "ticket_type") {
      setTicketType(value);
    }
  };

  // filter change handler for select box fields starts
  const onClickSearchLicenseState = (selectedList) => {
    var arrayState = [];
    selectedList.map((item) => {
      const finalStateData = "&lp_state=" + item.state;
      arrayState.push(finalStateData);
    });
    setStateFilter(arrayState);
  };

  const onClickSearchViolationCode = (selectedList) => {
    var arrayCode = [];
    selectedList.map((item) => {
      const finalData = "&violation_code=" + item.code;
      arrayCode.push(finalData);
    });
    setViolationCodeFilter(arrayCode);
  };

  const onClickSearchViolationDescriprion = (selectedList) => {
    var arrayCode = [];
    selectedList.map((item) => {
      const finalData = "&violation_desc=" + item.description;
      arrayCode.push(finalData);
    });
    setViolationDescFilter(arrayCode);
  };

  const onClickSearchOfficerName = (selectedList) => {
    var arrayOfficer = [];
    selectedList.map((item) => {
      const finalData = "&site_officer_id=" + item.officer_id;
      arrayOfficer.push(finalData);
    });
    setOfficerNameFilter(arrayOfficer);
  };

  const onClickSearchPeoName = (selectedList) => {
    var arrayOfficer = [];
    selectedList.map((item) => {
      const finalData = "&site_officer_id=" + item.officer_id;
      arrayOfficer.push(finalData);
    });
    setPeoNameFilter(arrayOfficer);
  };

  const onClickSearchShift = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&shift=" + item.shift;
      arrayShift.push(finalData);
    });
    setShiftFilter(arrayShift);
  };

  const onClickSearchAgency = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&agency=" + item.agency;
      arrayShift.push(finalData);
    });
    setAgencyFilter(arrayShift);
  };

  const onClickSearchBeat = (selectedList) => {
    var arrayBeat = [];
    selectedList.map((item) => {
      const finalBeatData = "&beat=" + item.beat;
      arrayBeat.push(finalBeatData);
    });
    setBeatFilter(arrayBeat);
  };

  const onClickSearchZone = (selectedList) => {
    var arrayZone = [];
    selectedList.map((item) => {
      const finalZoneData = "&zone=" + item.zone;
      arrayZone.push(finalZoneData);
    });
    setZoneFilter(arrayZone);
  };

  const onClickRemark = (selectedList) => {
    var arrayStateRemarks = [];
    selectedList.map((item) => {
      const finalStateRemarks = "&remark_1=" + item.remark;
      arrayStateRemarks.push(finalStateRemarks);
    });
    setStateFilterRemarks(arrayStateRemarks);
  };
  const handlePaginationChange = (newPage) => {
    setCurrentPage1(newPage.currentPage);
    onSubmitButtonClick(newPage.currentPage,newPage.pageLimit)
    setItemsPerPage(newPage.pageLimit)
  };

  // filtered fetch api submit handler for get new table data

  const onSubmitButtonClick = (passData,limit) => {
  let pageNumber = typeof passData === 'number' ? passData : 1;
  let pageLimit= limit ? limit :200
    let licensePlate;
    let ticketNum;
    let streetValue;
    let blockValue;
    let groupByofficer;
    let violationCode;
    let finalBeatData;
    let finalZoneData;
    let finalStateData;
    let peoName;
    let badgeId;
    let remarks;
    let agencyName;
    let shiftName;
    let violationDesc;

    if (lpNumber !== "") {
      licensePlate = "&lp_number=" + lpNumber;
    } else {
      licensePlate = "";
    }
    if (ticketNo !== "") {
      ticketNum = "&ticket_no=" + ticketNo;
    } else {
      ticketNum = "";
    }
    if (street !== "") {
      streetValue = "&street=" + street;
    } else {
      streetValue = "";
    }
    if (block !== "") {
      blockValue = "&block=" + block;
    } else {
      blockValue = "";
    }
    if (groupByOfficer !== "") {
      groupByofficer = "&group_by_officer=" + groupByOfficer;
    } else {
      groupByofficer = "";
    }
    if (violationCodeFilter !== "") {
      violationCode = violationCodeFilter.join("");
    } else {
      violationCode = "";
    }
    if (violationDescFilter !== "") {
      violationDesc = violationDescFilter.join("");
    } else {
      violationDesc = "";
    }
    if (beatFilter !== "") {
      var beat = beatFilter.join("");
      finalBeatData = beat.split(" ").join("%20");
    } else {
      finalBeatData = "";
    }
    if (zoneFilter !== "") {
      var zone = zoneFilter.join("");
      finalZoneData = zone.split(" ").join("%20");
    } else {
      finalZoneData = "";
    }
    if (stateFilter !== "") {
      var state = stateFilter.join("");
      finalStateData = state.split(" ").join("%20");
    } else {
      finalStateData = "";
    }
    if (status !== "") {
      var searchStatus = "&status=" + status;
    } else {
      searchStatus = "";
    }
    if (roStatus !== "") {
      var searchroStatus = "&ro_request_status=" + roStatus;
    } else {
      searchroStatus = "";
    }
    if (roSource !== "") {
      var searchroSource = "&ro_source=" + roSource;
    } else {
      searchroSource = "";
    }
    if (driveOff !== "") {
      var searchDriveOff = "&drive_off=" + driveOff;
    } else {
      searchDriveOff = "";
    }
    if (ticketType !== "") {
      var searchTicktType = "&type=" + ticketType;
    } else {
      searchTicktType = "";
    }
    if (setStartDateSearch !== "") {
      var startDate = "&issue_ts_to=" + startDateSearch;
    } else {
      startDate = "";
    }
    if (setEndDateSearch !== "") {
      var endDate = "&issue_ts_from=" + endDateSearch;
    } else {
      endDate = "";
    }
    if (officerNameFilter !== "") {
      let name = officerNameFilter.join("");
      var officerName = name.split(" ").join("%20");
    } else {
      officerName = "";
    }
    if (peoNameFilter !== "") {
      let name = peoNameFilter.join("");
      peoName = name.split(" ").join("%20");
    } else {
      peoName = "";
    }

    if (badgeIDFilter !== "") {
      var badge = badgeIDFilter.join("");
      badgeId = badge.split(" ").join("%20");
    } else {
      badgeId = "";
    }
    if (stateFilterRemarks !== "") {
      var remark = stateFilterRemarks.join("");
      remarks = remark.split(" ").join("%20");
    } else {
      remarks = "";
    }
    if (shiftFilter !== "") {
      var shift = shiftFilter.join("");
      shiftName = shift.split(" ").join("%20");
    } else {
      shiftName = "";
    }
    if (agencyFilter !== "") {
      var agencys = agencyFilter.join("");
      agencyName = agencys.split(" ").join("%20");
    } else {
      agencyName = "";
    }
    if (groupByOfficer === true) {
      const finalDataSearchString =
        endDate +
        startDate +
        licensePlate +
        ticketNum +
        streetValue +
        blockValue +
        violationCode +
        violationDesc +
        finalBeatData +
        finalZoneData +
        officerName +
        groupByofficer +
        finalStateData +
        searchStatus +
        badgeId +
        agencyName +
        peoName +
        shiftName +
        remarks +
        searchroStatus +
        searchroSource +
        searchDriveOff +
        searchTicktType;
      fetchData(finalDataSearchString);
    } else {
const finalDataSearch = `page=${pageNumber}&limit=${pageLimit}` +
        endDate +
        startDate +
        licensePlate +
        ticketNum +
        streetValue +
        blockValue +
        violationCode +
        violationDesc +
        finalBeatData +
        finalZoneData +
        officerName +
        finalStateData +
        searchStatus +
        badgeId +
        peoName +
        agencyName +
        shiftName +
        remarks +
        searchroStatus +
        searchroSource +
        searchDriveOff +
        searchTicktType;

      if (finalDataSearch !== "") {
        fetchData(finalDataSearch);
      } else {
        exportsLogsData();
      }
    }
  };

  // pagination class handler
  useEffect(() => {
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + currentPage).addClass("active-paginated");
  }, [currentPage]);

  // state list select box api call

  useEffect(() => {
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setStreetList(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    let passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataViolation)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setViolationCode(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    const initialremarkfun = () => {
      Reportingsetup.violationCode(passDataRemarks)
        .then((Response) => {
          if (Response.data.status === 300) {
          } else {
            setRemarks(Response.data.data[0].response);
          }
        })
        .catch((err) => {});
    };
    initialremarkfun();
  }, []);


  const renderPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedReports.slice(startIndex, endIndex).map((item) => (
      <tr key={item?.id} className="cstm-rec-table-tr">
        {renderData(item)}
      </tr>
    ));
  };

  /* Pagination Handlers function Ends */

  /* Sort Handlers Functions Starts */

  const sortedReports = useMemo(() => {
    let sortableReports = [...reports];
    if (sortConfig.key !== "id" && sortConfig.key !== "" && sortConfig.key) {
      sortableReports.sort((a, b) => {
        let aValue = a;
        let bValue = b;
        const keyPartsA = sortConfig.key.split(".");
        const keyPartsB = sortConfig.key.split(".");

        for (const keyPartA of keyPartsA) {
          aValue = aValue[keyPartA];
        }
        for (const keyPartB of keyPartsB) {
          bValue = bValue[keyPartB];
        }

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableReports;
  }, [reports, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  /* Sort Handlers Functions Ends */

  /* render table heading handlers */

  const renderHeadings = () => {
    const headingElements = [];

    // Loop through the tableHeadings array
    for (let index = 0; index < tableHeadings.length; index++) {
      const element = tableHeadings[index];
      const key = keySequence[index];
      // Create a <th> element for each heading and add it to the headingElements array
      headingElements.push(
        <th
          key={index}
          className={`table-main-heading-list ac0${index}`}
          onClick={() => element !== "checkbox" && requestSort(key)}
        >
          {element === "checkbox" ? (
            <input
              className="comman-pl-csmt"
              type="checkbox"
              checked={checked}
              onChange={onClickAllCheckBox}
            />
          ) : (
            element
          )}{" "}
          {/* Adds a space between the heading and the sort icon*/}
          {sortConfig.key === keySequence[index] ? (
            sortConfig.direction === "asc" ? (
              <i className="fa fa-sort-asc" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-sort-desc" aria-hidden="true"></i>
            )
          ) : null}
        </th>
      );
    }

    // Return the headingElements array
    return headingElements;
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [sortedReports]);

  // Edit Citation handler for grid edit

  const editCitationHandler = (e) => {
    e.preventDefault();
    if (inputValue) {
      Reportingsetup.updateCitation([inputValue]).then((response) => {
        if (response.status === 200) {
          onSubmitButtonClick();
          setChangeFieldIndex("");
        }
      });
    }
  };

  // image url change handler and return new api

  const imagedataget = async (data) => {
    try {
      let newcitedata = data;
      var imagesLink = newcitedata;
      var imageData = {
        download_type: "CitationImages",
        links: Object.assign({}, imagesLink),
        site_id: showSideId.siteinfo,
      };

      const responseImage = await Reportingsetup.downloadImage(imageData);
      newcitedata = responseImage.data.metadata;
      return newcitedata;
    } catch (err) {}
  };

  const getImages = async (value) => {
    const imageData = await imagedataget(value);
    return imageData;
  };
  const batchSize = 100;
  async function handleGetImage1(data) {
    setIsLoader(true);
    try {
      let newcitedata = { ...data };
      const totalCitations = newcitedata.citations.length;

      for (let i = 0; i < totalCitations; i += batchSize) {
        const batchCitations = newcitedata.citations.slice(i, i + batchSize);

        const imageData = {
          download_type: "CitationImages",
          links: {},
          site_id: showSideId.siteinfo,
        };

        batchCitations.forEach((citation) => {
          const ticketNumber = citation.header_details.citation_number;
          const imageLinks = citation.images.map((image, imageIndex) => ({
            ticketNumber,
            index: imageIndex,
            link: image,
          }));
          imageLinks.forEach(({ ticketNumber, index, link }) => {
            imageData.links[`${ticketNumber}_${index}`] = link;
          });
        });

        const responseImage = await Reportingsetup.downloadImage(imageData);
        batchCitations.forEach((citation) => {
          const ticketNumbers = citation.header_details.citation_number;
          const ticketImages = responseImage.data.metadata.filter(
            (ele) => ele.image_spec.split("_")[0] === ticketNumbers
          );
          citation.new_images = ticketImages;
        });
      }
      return newcitedata;
    } catch (err) {
      setIsLoader(false);
      console.log(err);
    }
  }

  const getImages1 = async (value) => {
    const imageData = await handleGetImage1(value);
    return imageData;
  };

  useEffect(() => {
    if (isImages && isImages.citations && isImages.citations.length > 0) {
      const fetchImages = async () => {
        setIsLoader(true);
        const data = await getImages1(isImages);
        setReportImages(data);
        setIsLoader(false);
        };
      fetchImages();
    }
  }, [isImages]);
  
     

  // Render table data based on fetched data and key array data
  const renderData = (element) => {
    const tableElements = [];
    keySequence.map((key, index) => {
      // split the key by the "." character to handle nested keys
      const keyParts = key.split(".");

      // initialize a variable to hold the current value
      let value = element;


      // iterate over the key parts and get the value for each nested key
      for (const keyPart of keyParts) {
        value = value[keyPart];

        // return null if the value is undefined or null
        if (value === undefined || value === null) {
          return null;
        }

        if (typeof value === "boolean") {
          value = String(value);
          value = value.charAt(0).toUpperCase() + value.slice(1);
        }
      }

      const changeDate = (date) => {
        const dateObject = new Date(date);
        const formattedString = dateObject.toISOString().slice(0, 16);
        return formattedString
      }
      key === "citation_id"
      ? tableElements.push(
        <td>
              <Link
                onClick={() => (
                  setShowEditCitationModal(true),
                  setEditCitationFormChangeData({
                    citation_id: element?.id,
                    ticket_no: element?.ticket_no,
                    block: element?.location.block,
                    lp_number: element?.lp_number,
                    street: element?.location?.street,
                    state: element?.vehicle_details?.state,
                    citation_start_timestamp: changeDate(element?.citation_start_timestamp),
                    remarks: "",
                    remark_1: element?.comment_details?.remark_1,
                    remark_2: element?.comment_details?.remark_2,
                    note_1: element?.comment_details?.note_1,
                    note_2: element?.comment_details?.note_2,
                    description: element?.violation_details?.description,
                    code: element?.violation_details?.code,
                  }),
                  getImages(element?.images).then((item) => (setSlideImages(item)))
                )}
              >
                Edit
              </Link>
            </td>
          )
        : key === "id"
        ? tableElements.push(
            <td>
              <input
                className="comman-pl-csmt"
                type="checkbox"
                onChange={(e) =>
                  onChangeCheckEvents(
                    e,
                    element,
                    element.images
                  )
                }
                checked={showSelectedId.find((data) => data.id === value)}
              />
            </td>
          )
        : key === "images"
        ? tableElements.push(
            <td>
              {!value ? (
                "_"
              ) : value.length === 1 ? (
                <SlideImages
                  isImages={isImages}
                  setShowImagesModalSlider={setShowImagesModalSlider}
                  setimageId={setimageId}
                  setSlideImages={setSlideImages}
                  imageId={imageId}
                  id={index}
                  ticket_no={element.ticket_no}
                  reportImages={reportImages}
                  isLoader={isLoader}
                />
              ) : (
                <SlideImages
                  isImages={isImages}
                  setShowImagesModalSlider={setShowImagesModalSlider}
                  setimageId={setimageId}
                  setSlideImages={setSlideImages}
                  imageId={imageId}
                  id={index}
                  ticket_no={element.ticket_no}
                  reportImages={reportImages}
                  isLoader={isLoader}
                />
              )}
            </td>
          )
        : key === "location.block" ||
          key === "location.street" ||
          key === "lp_number"
        ? tableElements.push(
            <td
              key={index}
              onDoubleClick={(e) => (
                setChangeFieldIndex(index),
                setCiteId(element.id),
                key === "location.block"
                  ? setInputValue({
                      citation_id: element.id,
                      block: value,
                      remarks: `block edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${value}`,
                    })
                  : key === "location.street"
                  ? setInputValue({
                      citation_id: element.id,
                      street: value,
                      remarks: `street edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${value}`,
                    })
                  : setInputValue({
                      citation_id: element.id,
                      lp_number: value,
                      remarks: `lp_number edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${value}`,
                    })
              )}
            >
              {changeFieldIndex === index && citeid === element.id ? (
                <form onSubmit={editCitationHandler}>
                  <input
                    className="block_input_field"
                    value={
                      key === "location.block"
                        ? inputValue.block
                        : key === "location.street"
                        ? inputValue.street
                        : inputValue.lp_number
                    }
                    type="text"
                    name={
                      key === "location.block"
                        ? "block"
                        : key === "location.street"
                        ? "street"
                        : "lp_number"
                    }
                    onChange={(e) => {
                      key === "location.block"
                        ? setInputValue({
                            citation_id: element.id,
                            block: e.target.value,
                            remarks: `block edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${e.target.value}`,
                          })
                        : key === "location.street"
                        ? setInputValue({
                            citation_id: element.id,
                            street: e.target.value,
                            remarks: `street edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${e.target.value}`,
                          })
                        : setInputValue({
                            citation_id: element.id,
                            lp_number: e.target.value,
                            remarks: `lp_number edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${e.target.value}`,
                          });
                    }}
                  />
                </form>
              ) : value ? (
                value
              ) : (
                "-"
              )}
            </td>
          )
        : key === "vehicle_details.state"
        ? tableElements.push(
            <td
              key={index}
              onDoubleClick={(e) => (
                setChangeFieldIndex(index),
                setCiteId(element.id),
                setInputValue({
                  citation_id: element.id,
                  state: value,
                  remarks: `state edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${value}`,
                })
              )}
            >
              {changeFieldIndex === index && citeid === element.id ? (
                <form>
                  <select
                    name="state"
                    onChange={(e) => {
                      setInputValue({
                        citation_id: element.id,
                        state: e.target.value,
                        remarks: `state edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${e.target.value}`,
                      });
                      Reportingsetup.updateCitation([
                        {
                          citation_id: element.id,
                          state: e.target.value,
                          remarks: `state edited by ${siteInfo.first_name} ${siteInfo.last_name} with value ${e.target.value}`,
                        },
                      ]).finally(
                        () => onSubmitButtonClick(),
                        setChangeFieldIndex("")
                      );
                    }}
                  >
                    {streetList.map((item) => (
                      <option
                        selected={item.state_abbreviated === value}
                        value={item.state_abbreviated}
                      >
                        {item.state_name}
                      </option>
                    ))}
                  </select>
                </form>
              ) : (
                value
              )}
            </td>
          )
        : tableElements.push(
            value ? (
              <td key={index}>
                {key === "citation_start_timestamp"
                  ? moment(value.split("T")[0]).format("MM/DD/YYYY") +
                    " " +
                    value.substring(11, 19)
                  : value}
              </td>
            ) : (
              <td>-</td>
            )
          );

      return value;
    });
    return tableElements;
  };

  // complete table checkbox handler to use bulk edit
  const onClickAllCheckBox = (e) => {
    if (e.target.checked) {
      setChecked(true);
      let selectedID = [];
      let selectedTicket = [];
      sortedReports.map((data) => selectedTicket.push(data.ticket_no))
      sortedReports.map((data) => selectedID.push(data));
      setShowSelectedId(selectedID);
      setSelectedTicket(selectedTicket);
    } else {
      setChecked(false);
      setShowSelectedId([]);
      setSelectedTicket([]);
    }
  };

  // table rows data checkbox handler to use bulk edit
  const onChangeCheckEvents = (e, value, image) => {
    setChecked(false);

    const selectedId = showSelectedId;
    const formDataImage = formImageData;

    if (e.target.checked) {
      selectedId.push(value);
      getImages(image).then((item) => formDataImage.push(item))

    } else {
      const carIndex = showSelectedId.indexOf(value);
      selectedId.splice(carIndex, 1);
      formDataImage.splice(carIndex, 1);
    }

    setShowSelectedId(selectedId);
    setFormImageData(formDataImage);
  };

  const sliderPopupRef = useRef(null);

  useEffect(() => {
    if (sliderPopupRef) {
      sliderPopupRef?.current?.focus();
    }
  }, [showImagesModalSlider]);

  // bulk edit handler for passing data to edit page
  const handleBulkEdit = (e) => {
    // navigate(`/amendcitationlist?page=${currentPage}&limit=${itemsPerPage}&ticket_no=${selectedTicket.toString()}`)
    setFormData(showSelectedId);
  };

  // form edit submission handler
  const handleEditCitation = (e) => {
    e.preventDefault();
    if (editCitationFormChangeData.remarks === "") {
      setShowError(true);
      setFieldError({ ...fieldError, remarks: "Field is required" });
    } else {
      setShowError(false);
      Reportingsetup.updateCitation([editCitationFormChangeData]).then(
        (response) => {
          if (response.status === 200) {
            onSubmitButtonClick();
            setShowEditCitationModal(false);
          }
        }
      );
    }
  };

  const handleBackButtonClick = () => {
    setChecked(false);
    setShowEditpage(false);
    onSubmitButtonClick();
    setShowSelectedId([]);
    setFormData([]);
    setFormImageData([]);
    setSelectedTicket([])
  };

  return (
    <>
      {!showEditPage ? (
        <>
          <section>
            <div className="container-fluid">
              <div className="row">
                {/* Content starts */}
                <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                  <div
                    className="cstm-ticket-manage-sec"
                    onClick={(e) => {
                      if (changeFieldIndex !== "") {
                        editCitationHandler(e);
                      }
                    }}
                  >
                    <div className="cstm-ticket-manage-heading">
                      <h3 className="cstm-all-pg-ttl operate-ttl">
                        Edit {changeTotal}
                      </h3>
                    </div>
                  </div>
                  <div
                    className="report-searchbar"
                    onClick={(e) => {
                      if (changeFieldIndex !== "") {
                        editCitationHandler(e);
                      }
                    }}
                  >
                    <div className="cstm-rtc-only">
                      <form>
                        <DatePickerComponent
                          setEndDate={endDate}
                          setStartDate={startDate}
                          lpNumber={lpNumber}
                          onChangeSearchDate={onChangeSearchDate}
                          onSubmitButtonClick={onSubmitButtonClick}
                          onClickFilter={onClickFilter}
                          onChangeSearchFilter={onChangeSearchFilter}
                          roStatus={roStatus}
                          placeholderText="MM/DD/YYYY HH:MM"
                        />
                      </form>
                      {editable && sortedReports.length !== 0 && (
                        <button
                          className="edit-citation-btn"
                          onClick={() =>
                            showSelectedId.length > 0 &&
                            (handleBulkEdit(), setShowEditpage(true))
                          }
                        >
                          Edit {changeTotal}
                        </button>
                      )}
                    </div>

                    {filterIconToggle ? (
                      <FilterComponent
                        block={block}
                        status={status}
                        street={street}
                        blockTo={blockTo}
                        onClickRemark={onClickRemark}
                        onClickSearchBeat={onClickSearchBeat}
                        onClickSearchZone={onClickSearchZone}
                        onClickSearchShift={onClickSearchShift}
                        onClickSearchAgency={onClickSearchAgency}
                        onChangeSearchFilter={onChangeSearchFilter}
                        onClickSearchPeoName={onClickSearchPeoName}
                        onClickSearchOfficerName={onClickSearchOfficerName}
                        onClickSearchViolationCode={onClickSearchViolationCode}
                        onClickSearchViolationDescriprion={
                          onClickSearchViolationDescriprion
                        }
                        ticketNo={ticketNo}
                        onClickSearchLicenseState={onClickSearchLicenseState}
                        ticketType={ticketType}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {loading ?
                  <div className='text-center spin-load-main'>
                  <div className="spinner-border"></div>
                </div>
                  :
                  sortedReports.length === 0 ? (
                    <div className="no-record-found-main">No Record Found</div>
                  ) : (
                    <div className="ctsm-table-scroll">
                      <div className="table-responsive">
                        <div
                          className="report_tbl_scroll fix-page-scroll-only"
                          id="tbl-main-download"
                        >
                          <table
                            className="table table-heading table-bordered"
                            id="cstm-report-tb-display"
                          >
                            <thead>
                              <tr>{renderHeadings()}</tr>
                            </thead>
                            {<tbody>{renderPaginatedData()}</tbody>}
                          </table>
                        </div>
                        <div
                          onClick={(e) => {
                            if (changeFieldIndex !== "") {
                              editCitationHandler(e);
                            }
                          }}
                        >
                            <Pagination
                        handlePaginationChange={handlePaginationChange}
                        totalRecords={isImages.total_count}
                        pageLimit={itemsPerPage}
                        pageNeighbours={1}
                        currentPage={currentPage1}
                        dropdownLimit={false}
                      />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          {showEditCitationModal && (
            <EditCitationModal  onSubmitButtonClick={onSubmitButtonClick} selectedCitation={editCitationFormChangeData} slideImages={slideImages} setShowEditCitationModal={setShowEditCitationModal}/>
          )}
          {showImagesModalSlider && (
            <div
              className="main-body-modal cstm-ticket-details-pop"
              onKeyDown={(e) => {
                if (e.key === "ArrowLeft") {
                  imageId === 0
                    ? setimageId(slideImages.length - 1)
                    : setimageId(imageId - 1);
                } else if (e.key === "ArrowRight") {
                  imageId + 1 === slideImages.length
                    ? setimageId(0)
                    : setimageId(imageId + 1);
                }
              }}
              tabIndex={0}
              ref={sliderPopupRef}
            >
              <div className="main-modal">
                <div className="cstm-close">
                  <button onClick={() => setShowImagesModalSlider(false)}>
                    ×
                  </button>
                </div>
                <div className="cstm-modal-body">
                  <div className="modal-body">
                    <div className="text-center cstm-ticket-details-slider">
                      <ImageSlider slides={slideImages} index={imageId} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <ReportEditComponent
          backbuttonHandler={handleBackButtonClick}
          getImages={getImages}
          formImageData={formImageData}
          formData={formData}
        />
      )}
    </>
  );
};

export default ReportTableComponent;
