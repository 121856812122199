import React, { Component } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import $ from 'jquery';
import transfer from '../../../Images/transfer.png';
import ErrorImage from '../../../Images/pp3.png';
import filter from '../../../Images/filter-yellow.svg';
import downloadarrow from "../../../Images/down-arrow.svg";
import dropdownarrow from '../../../Images/dropdown-arrow.svg';
import close from '../../../Images/close.svg';
import ProductTable from './Sorting';
import Reportingsetup from "../../../Services/dashboard.service";
import DownloadImg from "../../../Images/download_img_btn.svg";

import { connect } from "react-redux";
import { refreshtokenforClassComp } from '../../../actions/auth'
import PrintPdfCsvExcelDownload from '../../Utilies/PrintPdfCsvExcelDownload';
import Cookiesremove from '../../../Cookiesremove';
import SearchLicensePlate from '../../Citation/SearchLicensePlate';


function pad2(n) {
  return (n < 10 ? '0' : '') + n;
}

var columnsFetch = [
  "Amount",
  "Latency",
  "Receipt",
  "Meter",
  "Space",
  "Zone",
  "Transaction Time",
  "Start Time",
  "Expiry Time",
  "License #",
  "License State",
  "Received Time",
  "User",
  "VendorName"
];

class PaymentDataView extends Component {

  state = {
    lp_number: '',
    ticket_no: '',
    receipt: '',
    amount: '',
    meter: '',
    zone: '',
    meterFilter: [],
    filterData: [],
    violationCodeFilter: [],
    tableColumnName: [],
    officerNameFilter: [],
    group_by_officer: '',
    status: '',
    allDataIntigration: [],
    setStreetList: [],
    badgeIDFilter: [],
    meterList: [],
    officerName: [],
    setBeatTypes: [],
    setRemark: [],
    setZone: [],
    zoneFilter: [],
    stateFilterRemarks: [],
    beatFilter: [],
    setBadgeID: [],
    dataGroupOfficerLog: {},
    stateFilter: [],
    noRecordFound: false,
    setOfficerName: [],
    setPagination: true,
    mainRecordDisplay: true,
    setPagination1: false,
    setFilterIconToggle: false,
    groupByOfficerLogs: false,
    setDownloadOption: false,
    dataIntigrationLog: [],
    currentPage: null,
    showAuthToken: false,
    errorHandling: false,
    filterDateerrorHandling: false,
    totalPages: null,
    passDataValue: "",
    setStartDate: new Date().setDate(new Date().getDate() + 1),
    setStartDateSearch: "",
    setEndDate: new Date().setHours(0, 0, 0),
    setEndDateSearch: "",
    totalDataLengthCount: 0,
    setDataLoadLoader: true,
    currentPage: 1,
    todosPerPage: 200,
    upperPageBound: 5,
    lowerPageBound: 0,
    isPrevBtnActive: 'disabled',
    isNextBtnActive: '',
    pageBound: 3,
    setPaginationSelectVaue: 200,
    setShowDetailPage: false,
    filterDateerrorMessage: ''
  }


  exportsLogsData = () => {

    var date = new Date();
    var date1 = new Date(this.state.setEndDate);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var nextMonth = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(this.state.setStartDate);
    var todayDate = pad2(date.getDate());
    var tomorrowDate = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(this.state.setStartDate);


    this.setState({ setStartDateSearch: year + '-' + nextMonth + '-' + tomorrowDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z" });

    this.setState({ setEndDateSearch: date1.getFullYear() + '-' + pad2(date1.getMonth() + 1) + '-' + pad2(date1.getDate()) + "T" + (date1.getHours() < 10 ? '0' : '') + date1.getHours() + ":" + (date1.getMinutes() < 10 ? '0' : '') + date1.getMinutes() + ":00.000Z" });

    var passDateAndTime = date1.getFullYear() + '-' + pad2(date1.getMonth() + 1) + '-' + pad2(date1.getDate()) + "T00:00:00.000Z," + year + '-' + nextMonth + '-' + tomorrowDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z";

    var filterDateArray = [{
      field: "Transaction Time",
      operator: "BETWEEN",
      value: passDateAndTime
    }];

    this.setState({ filterData: filterDateArray });

    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": [
        {
          "field": "Transaction Time",
          "operator": "BETWEEN",
          "value": passDateAndTime
        }
      ],
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": 0
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {

          if (Response.data.data.data.records === null || Response.data.data.pagination.total_records === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ setPagination1: false });
            this.setState({ allDataIntigration: Response.data.data.data.records });
            this.setState({ tableColumnName: Response.data.data.data.columns })
            this.setState({ totalDataLengthCount: Response.data.data.pagination.total_records });
            let totalPage = Math.ceil(Response.data.data.pagination.total_records / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (error) => {
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()
  }


  // When Page reload at that time this function called
  componentDidMount() {
    this.exportsLogsData();

    // Get and Display Violation List option for the search drop-down
    var passMeterData = {
      "type": "MeterList",
      "shard": 1
    }

    const vilationcodefun1 = () => {
      Reportingsetup.violationCode(passMeterData)
        .then(Response => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ meterList: Response.data.data[0].response });

          }
        }
        ).catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            vilationcodefun1()
          }
        });
    }
    vilationcodefun1()
    // Get and Display State List option for the search drop-down
    var passDataStateList = {
      "type": "StateList",
      "shard": 1
    }

    const vilationcodefun2 = () => {
      Reportingsetup.violationCode(passDataStateList)
        .then(Response => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setStreetList: Response.data.data[0].response });
          }
        }
        ).catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            vilationcodefun2()
          }
        });
    }
    vilationcodefun2()
    // Get and Display Zone List option for the search drop-down
    var passDataZone = {
      "type": "ZoneList",
      "shard": 1
    }

    const vilationcodefun3 = () => {
      Reportingsetup.violationCode(passDataZone)
        .then(Response => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setZone: Response.data.data[0].response });

          }
        }
        ).catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            vilationcodefun3()
          }
        });
    }
    vilationcodefun3()
  }



  // Pagination Events
  componentDidUpdate() {
    $("ul li.active-paginated").removeClass('active-paginated');
    $('ul li#' + this.state.currentPage).addClass('active-paginated');
  }

  handleClick = (event) => {
    this.setState({ setDataLoadLoader: true });
    let listid = Number(event.target.id);

    this.setState({
      currentPage: listid
    });
    $("ul li.active-paginated").removeClass('active-paginated');
    $('ul li#' + listid).addClass('active-paginated');
    this.setPrevAndNextBtnClass(listid);

    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": this.state.filterData,
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": listid - 1
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {
          this.setState({ setDataLoadLoader: false });
          this.setState({ allDataIntigration: Response.data.data.data.records });
        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()

  }

  setPrevAndNextBtnClass = (listid) => {
    let totalPage = Math.ceil(this.state.totalDataLengthCount / this.state.todosPerPage);
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  }

  btnIncrementClick = () => {
    this.setState({ setDataLoadLoader: true });
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": this.state.filterData,
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": listid - 1
      }
    }

    function datapaymentfun() {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {
          this.setState({ setDataLoadLoader: false });
          this.setState({ allDataIntigration: Response.data.data.data.records });
        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              datapaymentfun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    datapaymentfun()

  }
  btnDecrementClick = () => {
    this.setState({ setDataLoadLoader: true });
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": this.state.filterData,
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": listid - 1
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {
          this.setState({ setDataLoadLoader: false });
          this.setState({ allDataIntigration: Response.data.data.data.records });
        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()
  }

  btnPrevClick = () => {
    this.setState({ setDataLoadLoader: true });
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": this.state.filterData,
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": listid - 1
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {
          this.setState({ setDataLoadLoader: false });
          this.setState({ allDataIntigration: Response.data.data.data.records });
        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()
  }


  btnNextClick = () => {
    this.setState({ setDataLoadLoader: true });
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": this.state.filterData,
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": listid - 1
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {
          this.setState({ setDataLoadLoader: false });
          this.setState({ allDataIntigration: Response.data.data.data.records });
        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()
  }

  // When click on filter icon
  onClickFilter = (e) => {
    e.preventDefault();
    this.setState({ setFilterIconToggle: !this.state.setFilterIconToggle });
  }

  // When click on Download button
  onClickDonwloadButton = (e) => {
    e.preventDefault();
    this.setState({ setDownloadOption: !this.state.setDownloadOption });
  }

  //  When click on End date picker in search section
  onChangeSearchEndDate = (endDate,e) => {
    if (!endDate) {
      this.setState({ setStartDate: "", setStartDateSearch: "" });
      return;
  }
  let date = new Date(endDate);
    // Extracting hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // const currentFilterToDate = new Date(filterDate.created_ts_to);
    const isDateChange = !(this.state.setStartDate instanceof Date) || endDate?.getDate() !== new Date(this.state.setStartDate).getDate()|| (hours == 0 && minutes == 0);
    if (isDateChange) {
      date.setHours(23, 59, 59, 999);
    }
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z";

    this.setState({ setStartDate: date });
    this.setState({ setStartDateSearch: currentTimeInSeconds });
        this.state.filterData.push({
      field: "Transaction Time",
      operator: "BETWEEN",
      value: this.state.setEndDateSearch + "," + currentTimeInSeconds
    });

    this.state.filterData.map((items, index) => {
      if (index >= 1 && items.field !== undefined && items.field === "Transaction Time") {
        this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "Transaction Time"), 1);

        //  var filterDateArray = [{
        //   field: "Transaction Time",
        //   operator: "BETWEEN",
        //   value: this.state.setEndDateSearch +","+ currentTimeInSeconds
        //  }];
        //  this.setState({ filterData:filterDateArray});

      }
    })
  };

  // On Click Details Page open
  onClickDetailsPage = (value) => {
    this.setState({ setShowDetailPage: value });
  }

  // On click back to reporting page
  backButtonClick = () => {
    this.setState({ setShowDetailPage: false });
  }


  //  When click on Start date picker in search section
  onChangeSearchStartDate = (date) => {

    var date = new Date(date);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z";

    this.setState({ setEndDate: date });
    this.setState({ setEndDateSearch: currentTimeInSeconds });

    this.state.filterData.push({
      field: "Transaction Time",
      operator: "BETWEEN",
      value: currentTimeInSeconds + "," + this.state.setStartDateSearch
    });

    this.state.filterData.map((items, index) => {
      if (index >= 1 && items.field !== undefined && items.field === "Transaction Time") {
        this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "Transaction Time"), 1);

        // var filterDateArray = [{
        //     field: "Transaction Time",
        //     operator: "BETWEEN",
        //     value: currentTimeInSeconds +","+ this.state.setStartDateSearch
        //   }];

        //   this.setState({ filterData:filterDateArray});

      }
    })
  }

  // Pagination count records will be display based on selected value from the drop-down
  onPaginationSelection = (e) => {
    this.setState({ setDataLoadLoader: true });
    this.setState({ setPaginationSelectVaue: e.target.value })
    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": this.state.filterData,
      "limit": {
        "count": Number(e.target.value),
        "page": 0
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {
          this.setState({ setDataLoadLoader: false });
          this.setState({ allDataIntigration: Response.data.data.data.records });

          this.setState({
            currentPage: 1,
            todosPerPage: e.target.value,
            upperPageBound: 5,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3
          })

          let totalPage = Math.ceil(Response.data.data.pagination.total_records / e.target.value);

          if (totalPage === 1) {
            this.setState({ isNextBtnActive: 'disabled' });
          }

        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()
  }


  onChangeSearchFilter = (e) => {

    e.preventDefault();

    const { name, value } = e.target;

    console.log(value,'value')
    if (name === "lp_number") {
      // var finalData = newValue.split(" ").join("%20");
      let newValue = value;
      newValue = SearchLicensePlate(value);
      this.setState({ lp_number: newValue });
      var commanArray = this.state.filterData;
      if (value !== "") {
        var receiptExit = false;
        this.state.filterData.map((items, index) => {
          if (items.field === "License #") {
            receiptExit = true
          }
        })

        if (!receiptExit) {
          this.state.filterData.push({
            field: "License #",
            operator: "EQUAL",
            value: newValue,
          });
        } else {
          this.state.filterData.forEach((items, index) => {
            if (items.field === "License #") {
              commanArray[index] = {
                field: "License #",
                operator: "EQUAL",
                value: newValue,
              }
            } else {
              commanArray[index] = items;
            }
          })

          this.setState({ filterData: commanArray });
        }

      } else {
        this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "License #"), 1);
      }
    }

    if (name === "receipt") {
      var finalData = value.split(" ").join("%20");
      this.setState({ receipt: finalData });

      var commanArray = this.state.filterData;

      if (value !== "") {
        var receiptExit = false;
        this.state.filterData.map((items, index) => {
          if (items.field === "Receipt") {
            receiptExit = true
          }
        })

        if (!receiptExit) {
          this.state.filterData.push({
            field: "Receipt",
            operator: "EQUAL",
            value: finalData,
          });
        } else {
          this.state.filterData.forEach((items, index) => {
            if (items.field === "Receipt") {
              commanArray[index] = {
                field: "Receipt",
                operator: "EQUAL",
                value: finalData,
              }
            } else {
              commanArray[index] = items;
            }
          })

          this.setState({ filterData: commanArray });
        }

      } else {
        this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "Receipt"), 1);
      }


    }

    if (name === "amount") {
      var finalData = value.split(" ").join("%20");
      this.setState({ amount: finalData });

      var commanArray = this.state.filterData;

      if (value !== "") {
        var receiptExit = false;
        this.state.filterData.map((items, index) => {
          if (items.field === "Amount") {
            receiptExit = true
          }
        })

        if (!receiptExit) {
          this.state.filterData.push({
            field: "Amount",
            operator: "EQUAL",
            value: finalData,
          });
        } else {
          this.state.filterData.forEach((items, index) => {
            if (items.field === "Amount") {
              commanArray[index] = {
                field: "Amount",
                operator: "EQUAL",
                value: finalData,
              }
            } else {
              commanArray[index] = items;
            }
          })

          this.setState({ filterData: commanArray });
        }

      } else {
        this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "Amount"), 1);
      }

    }
  }

  //  When click on Submit button in search section
  onSubmitButtonClick = () => {

    // return false;
    // var filterData = [];

    const { filterData, lp_number, zone, meter, receipt, meterFilter, amount, zoneFilter, stateFilter, setStartDateSearch, setEndDateSearch } = this.state;

    this.setState({ setDataLoadLoader: true });

    this.setState({
      currentPage: 1,
      todosPerPage: 200,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 3
    });

    this.setState({ setPaginationSelectVaue: 200 })

    var passData = {
      "data_set": "PAYMENT",
      "columns": columnsFetch,
      "filters": filterData,
      "limit": {
        "count": Number(this.state.setPaginationSelectVaue),
        "page": 0
      }
    }

    const paymentdatafun = () => {
      Reportingsetup.paymentDataview(passData)
        .then(Response => {

          if (Response.data.data.data.records === null || Response.data.data.pagination.total_records === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ setPagination1: false });
            this.setState({ allDataIntigration: Response.data.data.data.records });
            this.setState({ tableColumnName: Response.data.data.data.columns })
            this.setState({ totalDataLengthCount: Response.data.data.pagination.total_records });
            let totalPage = Math.ceil(Response.data.data.pagination.total_records / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (error) => {
            if (error.response.status === 417) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ filterDateerrorMessage: error.response.data.description });
              this.setState({ filterDateerrorHandling: true });
            }
            if (error.response.status === 401) {
              this.props.refreshtokenforClassComp();
              paymentdatafun()
            }
            if (error.response.status === 500) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ errorHandling: true });
            }
          }
        );
    }
    paymentdatafun()
  }

  // Print button click event
  onClickPrintButton = () => {
    window.print();
  }

  // Download PDF file 
  donwloadPDFFile = (e) => {
    e.preventDefault();
    this.setState({
      todosPerPage: this.state.totalDataLengthCount,
      setPaginationSelectVaue: this.state.totalDataLengthCount
    });
    setTimeout(function () {
      var doc = new jsPDF('l', 'pt', 'a4');
      doc.autoTable({ html: '#cstm-report-tb-display' })
      doc.save('Payment.pdf')
    }, 10);

    setTimeout(() => {
      this.setState({
        todosPerPage: 200,
        setPaginationSelectVaue: 200
      });
    }, 50);

  }

  // Download Excel file 
  donwloadExcelFile = (e) => {

    this.setState({
      todosPerPage: this.state.totalDataLengthCount,
      setPaginationSelectVaue: this.state.totalDataLengthCount
    });

    setTimeout(() => {
      var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)))
        },
        format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
          })
        }
      var toExcel = document.getElementById("cstm-report-tb-display").innerHTML;
      var ctx = {
        worksheet: 'Payment',
        table: toExcel
      };
      var link = document.createElement("a");
      link.download = "Payment.xls";
      link.href = uri + base64(format(template, ctx))
      link.click();
    }, 10);

    setTimeout(() => {
      this.setState({
        todosPerPage: 200,
        setPaginationSelectVaue: 200
      });
    }, 50);

  }

  //  Donwload CSV file
  donwloadCsvFile = (e) => {
    e.preventDefault();


    this.setState({
      todosPerPage: this.state.totalDataLengthCount,
      setPaginationSelectVaue: this.state.totalDataLengthCount
    });

    setTimeout(() => {
      var csv = [];
      var rows = document.querySelectorAll("table tr");
      for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++)
          row.push(cols[j].innerText);
        csv.push(row.join(","));
      }
      this.downloadCSV(csv.join("\n"), "Payment.csv");
    }, 10);

    setTimeout(() => {
      this.setState({
        todosPerPage: 200,
        setPaginationSelectVaue: 200
      });
    }, 50);

  }

  downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  }


  closeErrorClick = () => {
    this.setState({ errorHandling: false });
    this.setState({ filterDateerrorHandling: false });
  }

  onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  }

  // Search Zone List
  onClickSearchZone = (selectedList) => {

    var arrayZone = [];
    var keyName = "";
    (selectedList.map((item) => {
      arrayZone.push(item.zone)
      keyName = Object.keys(item)
    }))
    this.setState({ zoneFilter: arrayZone });

    var commanArray = this.state.filterData;

    if (selectedList.length !== 0) {
      var exitData = false;
      this.state.filterData.map((items, index) => {
        if (items.field === "Zone") {
          exitData = true
        }
      })

      if (!exitData) {
        this.state.filterData.push({
          field: "Zone",
          operator: "IN",
          value: arrayZone,
        });
      } else {
        this.state.filterData.forEach((items, index) => {
          if (items.field === "Zone") {
            commanArray[index] = {
              field: "Zone",
              operator: "IN",
              value: arrayZone,
            }
          } else {
            commanArray[index] = items;
          }
        })
        this.setState({ filterData: commanArray });
      }

    } else {
      this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "Zone"), 1);
    }
  }

  onClickSearchMeter = (selectedList) => {

    var arrayZone = [];
    var keyName = "";

    (selectedList.map((item) => {
      arrayZone.push(item.meter)
      keyName = Object.keys(item)
    }))
    this.setState({ meterFilter: arrayZone });

    var commanArray = this.state.filterData;

    if (selectedList.length !== 0) {
      var exitData = false;
      this.state.filterData.map((items, index) => {
        if (items.field === "Meter") {
          exitData = true
        }
      })

      if (!exitData) {
        this.state.filterData.push({
          field: "Meter",
          operator: "IN",
          value: arrayZone,
        });
      } else {
        this.state.filterData.forEach((items, index) => {
          if (items.field === "Meter") {
            commanArray[index] = {
              field: "Meter",
              operator: "IN",
              value: arrayZone,
            }
          } else {
            commanArray[index] = items;
          }
        })
        this.setState({ filterData: commanArray });
      }

    } else {
      this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "Meter"), 1);
    }

  }

  onClickSearchState = (selectedList) => {
    var arrayZone = [];
    var keyName = "";

    (selectedList.map((item) => {
      arrayZone.push(item.state)
      keyName = Object.keys(item)
    }))
    this.setState({ stateFilter: arrayZone });

    var commanArray = this.state.filterData;

    if (selectedList.length !== 0) {
      var exitData = false;
      this.state.filterData.map((items, index) => {
        if (items.field === "License State") {
          exitData = true
        }
      })

      if (!exitData) {
        this.state.filterData.push({
          field: "License State",
          operator: "IN",
          value: arrayZone,
        });
      } else {
        this.state.filterData.forEach((items, index) => {
          if (items.field === "License State") {
            commanArray[index] = {
              field: "License State",
              operator: "IN",
              value: arrayZone,
            }
          } else {
            commanArray[index] = items;
          }
        })
        this.setState({ filterData: commanArray });
      }

    } else {
      this.state.filterData.splice(this.state.filterData.findIndex(v => v.field === "License State"), 1);
    }
  }


  render() {
    const { currentPage, tableColumnName, filterDateerrorMessage, todosPerPage, meterList, upperPageBound, totalDataLengthCount, lowerPageBound, isPrevBtnActive, isNextBtnActive, showAuthToken, setDataLoadLoader, setDownloadOption, groupByOfficerLogs, errorHandling, filterDateerrorHandling, setStartDate, setEndDate, setStreetList, setZone, noRecordFound, setFilterIconToggle, allDataIntigration, setPaginationSelectVaue } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = allDataIntigration.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalDataLengthCount / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className='active-paginated' id={number}><button id={number} onClick={this.handleClick.bind(this)}>{number}</button></li>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <li key={number} id={number}><button id={number} onClick={this.handleClick.bind(this)}>{number}</button></li>
        )
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <li className=''><button onClick={this.btnIncrementClick.bind(this)}> &hellip; </button></li>
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = <li className=''><button onClick={this.btnDecrementClick.bind(this)}> &hellip; </button></li>
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
      renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev"> Prev </span></li>
    }
    else {
      renderPrevBtn = <li className={isPrevBtnActive}><button id="btnPrev" onClick={this.btnPrevClick.bind(this)}> Prev </button></li>
    }
    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
      renderNextBtn = <li className={isNextBtnActive}><span id="btnNext"> Next </span></li>
    }
    else {
      renderNextBtn = <li className={isNextBtnActive}><button id="btnNext" onClick={this.btnNextClick.bind(this)}> Next </button></li>
    }

    return (

      <>
        <section>
          <div className="container-fluid">
            <div className="row" >
              {/* Content starts */}
              <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                <div className="cstm-ticket-manage-sec">
                  <div className="cstm-ticket-manage-heading">
                    <h3 className="cstm-all-pg-ttl operate-ttl">Payment</h3>
                  </div>
                  <PrintPdfCsvExcelDownload tableId='tbl-main-download' fileName='payment-report' pageSize='a2' />
                </div>

                <div className="report-searchbar">
                  <form>
                    <div className="report-date report-mr">

                      <DatePicker
                        // selectsStart
                        showTimeSelect
                        // timeIntervals={15}
                        dateFormat="MM/dd/yyyy h:mmaa"
                        timeFormat="HH:mm"
                        className="cstm-pull-details schedule-date-input"
                        name="end_date"
                        selected={setEndDate}
                        onChange={date => this.onChangeSearchStartDate(date)}
                        placeholderText="MM/DD/YYYY HH:MM"
                      />
                      <img src={transfer} alt="transfer" className="date-transfer" />
                      <DatePicker
                        showTimeSelect
                        dateFormat="MM/dd/yyyy h:mmaa"
                        timeFormat="HH:mm"
                        className="cstm-pull-details schedule-date-input"
                        name="start_date"
                        selected={setStartDate}
                        onChange={date => this.onChangeSearchEndDate(date)}
                        placeholderText="MM/DD/YYYY HH:MM"
                      />

                    </div>

                    <div className="report-licence filter_cstm1 select-drop-arrow report-mr">

                      <Multiselect
                        name="zone"
                        displayValue="zone"
                        placeholder="Select PBCZone"
                        onRemove={this.onClickSearchZone.bind(this)}
                        onSearch={function noRefCheck() { }}
                        onSelect={this.onClickSearchZone.bind(this)}
                        options={
                          (setZone.map((item, index) => (
                            {
                              zone: (item.zone_name)
                            }
                          )))
                        }
                        showCheckbox
                      />
                      <img src={dropdownarrow} alt="dropdownarrow" />
                    </div>

                    <div className="report-licence filter_cstm1 select-drop-arrow report-mr">

                      <Multiselect
                        name="meter"
                        displayValue="meter"
                        placeholder="Select Meter"
                        onRemove={this.onClickSearchMeter.bind(this)}
                        onSearch={function noRefCheck() { }}
                        onSelect={this.onClickSearchMeter.bind(this)}
                        options={
                          (meterList.map((item, index) => (
                            {
                              meter: (item.name)
                            }
                          )))
                        }
                        showCheckbox
                      />
                      <img src={dropdownarrow} alt="dropdownarrow" />
                    </div>

                    <button onClick={this.onClickFilter.bind(this)} className="filter-box report-mr">
                      <img src={filter} alt="filter" />
                    </button>
                    <button className="licence-print report-mr" type="button" onClick={this.onSubmitButtonClick.bind(this)}>Submit</button>
                  </form>

                  {setFilterIconToggle ?

                    <div className="filter-main-section">
                      <div className="row">

                        <div className="select-drop-arrow filter_cstm4">
                          <input className="license-plate" name="receipt" value={this.receipt} onChange={this.onChangeSearchFilter.bind(this)} type="text" placeholder="Search Receipt" />
                        </div>

                        <div className="report-licence report-mr">
                          <input className="license-plate" id="lp_numebr_ids" name="lp_number" value={this.state.lp_number} onChange={this.onChangeSearchFilter} type="text" placeholder="License Plate" />
                        </div>


                        <div className="select-drop-arrow filter_cstm5">

                          <Multiselect
                            name="license_state"
                            displayValue="stateName"
                            placeholder="Select License State"
                            onRemove={this.onClickSearchState.bind(this)}
                            onSearch={function noRefCheck() { }}
                            onSelect={this.onClickSearchState.bind(this)}
                            options={
                              (setStreetList.map((item, index) => (
                                {
                                  stateName: (item.state_name),
                                  state: (item.state_abbreviated)
                                }
                              )))
                            }
                            showCheckbox
                          />
                        </div>

                        {/* <select onChange= {this.onChangeSearchFilter.bind(this)} name="license_state">
                          <option value="">Select License State</option>
                          {setStreetList.map((item, index) => ( 
                          <option value={item.state_name}>{item.state_name}</option>
                          ))}
                        </select>  */}

                        <div className="select-drop-arrow filter_cstm5">
                          <input className="license-plate" name="amount" value={this.amount} onChange={this.onChangeSearchFilter.bind(this)} type="number" placeholder="Amount" />
                        </div>
                      </div>
                    </div>

                    :
                    ""
                  }

                </div>

                {setDataLoadLoader ?
                  <div className='text-center spin-load-main'>
                    <div className="spinner-border"></div>
                  </div>
                  :
                  (noRecordFound ?
                    <div className='no-record-found-main'>No Record Found</div>
                    :
                    <div className='ctsm-table-scroll'>
                      <div className='table-overflow'>
                        {groupByOfficerLogs ?
                          <div className="">
                            {/* <GroupTableLogs onClickDetailsPage={this.onClickDetailsPage} products={dataGroupOfficerLog} /> */}
                          </div>
                          :
                          <>
                            <div className="">
                              <ProductTable tableColumnName={tableColumnName} products={allDataIntigration} />
                              <ul className="cstm-pagination-added-bottom">
                                {renderPrevBtn}
                                {pageDecrementBtn}
                                {renderPageNumbers}
                                {pageIncrementBtn}
                                {renderNextBtn}
                              </ul>
                            </div>
                            <div className="cstm-permit-trf">
                              <div className="total-count-records"> Total <b> {totalDataLengthCount} </b> Records Found  </div>
                              <div className="cstm-select-pagination-val-main">

                                <div className="cstm-left-lable-pn-val"> Number of records per page </div>

                                <div className="cstm-right-lable-pn-val">
                                  <select className="select-pagination-val ar-olny" value={setPaginationSelectVaue} onChange={this.onPaginationSelection.bind(this)}>
                                    <option value={totalDataLengthCount}>All</option>
                                    {/* <option value="20">20</option> */}
                                    {/* <option value="50">50</option> */}
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>

                                  </select>
                                  <img src={dropdownarrow} alt="dropdownarrow" />
                                </div>

                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  )}


              </div>
            </div>
          </div>
        </section>


        {filterDateerrorHandling ?
          <>
            <div className="cstm-site-model">
              <div className="cstm-site-model-block fixed z-20">
                <div className="cstm-site-popup-bg">
                  <div className="cstm-site-model-wrapper auth-error-cstm-model-wrapper">
                    <button onClick={this.closeErrorClick.bind(this)} className="cstm-close-btn-db-is">
                      <img src={close} alt="close" />
                    </button>
                    <div>
                      <img src={ErrorImage} alt="success" className="cstm-popup-img" />
                      <p className="error-handling">{filterDateerrorMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          ""
        }
      </>
    );
  }

}

export default connect(null, { refreshtokenforClassComp })(PaymentDataView)