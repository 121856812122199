import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';

const PermitSkeleton = ({isDrawer}) => {
  const skeletonList = [1, 2, 3, 4, 5, 6];
  return (
    <>
      <div className={isDrawer && 'drawer'}>
        {isDrawer && (
          <div className={'drawer-top'}>
            <div className="header-ps">
              <Skeleton width={'250px'} height={'40px'} />
            </div>
          </div>
        )}

        <div className="drawer-bottom">
          <div className={isDrawer ? '' : 'pt-5'}>
            <div
              className={
                isDrawer ? 'permit-content-list2' : 'mt-5 permit-content-list'
              }>
              {skeletonList.map(itm => (
                <div className="permit-content-list-row">
                  <div className="permit-content-list-row-title">
                    <Skeleton width={'100px'} height={'30px'} />
                    <Skeleton width={'270px'} count={2} />
                  </div>

                  <div className="permit-content-list-row-billing-type">
                    <div className="pclrb-type-in">
                      <Skeleton width={'200px'} />
                    </div>
                  </div>
                  <div className="permit-content-list-row-billing-type">
                    <div className="pclrb-type-in">
                      <Skeleton width={'200px'} />
                    </div>
                  </div>
                  <div className="permit-content-list-row-btn">
                    <Skeleton width={'150px'} height={'50px'} />
                    <Skeleton width={'150px'} height={'50px'} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermitSkeleton;
