import { useEffect, useRef, useState } from 'react';
import mainService from '../../../Services/main.service';
import { refreshTokenMiddleware } from '../../customMiddelware';
import { useDispatch } from 'react-redux';
import HelicalReportComponent from './HelicalReportComponent';

const HelicalReport = ({ reportName, reportUrlPath }) => {
  const dispatch = useDispatch();
  const tokenRef = useRef('');
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  useEffect(() => {
    const getHelicalToken = () => {
      mainService.getHelicalToken()
        .then((response) => {
          if (response.status === 200) {
            const token = response.data.data.token;
            tokenRef.current = token;
            setIsTokenLoaded(true);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            getHelicalToken();
          }
        });
    };
    getHelicalToken();
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "scroll");
  }, [dispatch]);

  return (
    <>
      {isTokenLoaded && (
        <HelicalReportComponent reportName={reportName} url={reportUrlPath} token={tokenRef.current} />
      )}
    </>
  );
};

export default HelicalReport;