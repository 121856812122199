import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReportingSetup from "../../../Services/main.service";
import BackbuttinIMag from "../../../Images/back.png";
import Erroricon from "../../../Images/error-icon.png";
import SuccessIcon from "../../../Images/success-icon.png";

const ResendNotice = ({ noticeData, backButtonClick, citeId, ticketNo }) => {
  const [checkResend, setCheckResend] = useState([]);
  const [resendData, setResendData] = useState([]);
  const [showResndError, setShowResendError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showResendSuccess,setShowResendSuccess] = useState(false)

  // add fields for fields to present
  useEffect(() => {
    if (noticeData && noticeData.length > 0) {
      const newArr = noticeData.filter((item) => item.status !== "");
      setResendData(
        newArr.map((item) => ({
          citation_id: citeId,
          notice_name: item.notice_name,
          remark: "",
          checked:false
        }))
      );
    }
  }, [noticeData]);

  // check box event for checking which notice is being edit

  const handleSelectNoticeEvent = (e) => {
    let resndCheck = checkResend;
    if (e.target.checked) {
      resndCheck.push(e.target.value);
      setShowEdit(true);
      resendData[e.target.value]['checked'] = true;
      setResendData([...resendData])
    } else {
      const resendIndex = resndCheck.indexOf(e.target.value);
      resndCheck.splice(resendIndex, 1);
      resendData[e.target.value]['checked'] = false;
      setResendData([...resendData])
      if (resndCheck.length === 0) {
        setShowEdit(false);
      }
    }
    setCheckResend(resndCheck);
  };

  // Input field change event
  const handleChangeEvent = (e, i) => {
    const { name, value } = e.target;
    resendData[i][name] = value;
    setResendData([...resendData]);
  };

  // Resend Notice Submit handler call resend api 
  const resendNoticeHandler = () => {
    const payloadData = resendData.filter(item => item.checked).map(data => ({
      citation_id: data.citation_id,
      notice_name: data.notice_name,
      remark: data.remark}))
    ReportingSetup.resendNotice(payloadData)
      .then((res) => setShowResendSuccess(true))
      .catch(
        (err) => {
          setShowResendError(true); setErrorMessage(err.response.data.error)
        }
      );
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid searchResult-section right-panel cstm-right-panel">
          <div className="">
            <div className="col-12 back-link cstm-back-citationsearchresult">
              <Link onClick={backButtonClick}>
                <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                Back
              </Link>
            </div>
          </div>
          <div className="cstm-citation-main-header-row search-content-main">
            <div className="col-6 search-content padding-col1">
              <h3>Resend Notice</h3>
            </div>
          </div>
          <div>
            {resendData.map((item, index) => (
              <div className="cstm-citation-data-main-row row-align details-searchCitation cstm-resend-notice-only">
                <div className="col-xl-1 col-12 right-panel cstm-panel-checkbox">
                  <div className="form-group-checkbox2">
                    <input
                      for={"pl" + index}
                      type="checkbox"
                      onChange={handleSelectNoticeEvent}
                      id={`checkbox4-${index}`}
                      value={index}
                    />
                    <label htmlFor={`checkbox4-${index}`}></label>
                  </div>
                </div>
                <div className="col-xl-11 col-12 search-discription">
                  <form>
                    <div className="cstm-citation-data">
                      <div className="cstm-data-col">
                        <p>TICKET Number</p>
                        <h5>{ticketNo}</h5>
                      </div>
                      <div className="cstm-data-col">
                        <p>Notice Name</p>
                        <h5>{item.notice_name}</h5>
                      </div>
                      {item.checked ? (
                        <div className="cstm-data-col form-group formgroup-main">
                          <label>Remark</label>
                          <input
                            className="form-control"
                            value={item.remark}
                            placeholder="Write Remark"
                            name="remark"
                            onChange={(e) => handleChangeEvent(e, index)}
                          />
                        </div>
                      ): (<div className="cstm-data-col"></div>)}
                    </div>
                  </form>
                </div>
              </div>
            ))}
            {showEdit && (
              <div className="row cstm-citationsearchresult-img-button">
                <div className="col-12 result-button cstm-viewdetsils-button-citationsearchresult">
                  <button onClick={() => resendNoticeHandler()}>Submit</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showResndError && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowResendError(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body cstm-modal-body">
                <div className="text-center cstm-paymnet-img">
                  <img src={Erroricon} alt="img" className="img-fluid" />
                </div>
                <p className="text-center mb-4">{errorMessage}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {showResendSuccess && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => {setShowResendSuccess(false); backButtonClick()}}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body cstm-modal-body">
                <div className="text-center cstm-paymnet-img">
                  <img src={SuccessIcon} alt="img" className="img-fluid" />
                </div>
                <p className="text-center mb-4">Notice Resend Successfully!</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResendNotice;
