import React from 'react'; 
import RevenueReport from './RevenueReport';
import RevenueSummaryOfficer from "./OfficerLogs/TableLogs";
import RevenueSummaryViolation from "./SummaryViolation";

function RevenueDashboard(){     

    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row" > 
                        {/* Content starts */}
                        <div className="col-lg-12 col-md-9 col-sm-12 cstm-ctn cstm-operate-top">
                        <div className="row">
                            <div className='col-12'>
                                <h3 className="cstm-all-pg-ttl operate-ttl">Revenue Report</h3>
                            </div>
                        </div>
                       {/* Revenue Reports Logs */}
                         <RevenueReport /> 
                       
                        {/* Display Revenue Summary By Violations information */}
                        <RevenueSummaryViolation />

                       {/* Display Revenue Summary By Officer Logs */}
                        {/* <RevenueSummaryOfficer /> */}

                        </div>
                        
                    </div>
                </div>
            </section> 
        </>
    );
}

export default RevenueDashboard;
