import { useForm, Controller } from "react-hook-form";
import ArrowLeft from "../../../../Images/arrow-left.png";
import ImageUpload from "../../../../Images/image-upload.png";
import { useEffect } from "react";

const PermitNotificationTab = ({ HandlePermitSubmit, handleTabChange, permitTitle, loading,payloadData,isEditPermit }) => {
  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: {
      enable_all_alerts: false,
      status: "NOTIFICATION_STATUS"
    },
  });

  useEffect(() => {
    if (isEditPermit) {
      reset(payloadData);
      if (payloadData.status !== "ACTIVE") {
        setValue("status", "NOTIFICATION_STATUS")
      } else {
        setValue("status", "ACTIVE")
      }
    }
  },[])
  const notificationSubmitHandler = (data) => {
    console.log(data)
    HandlePermitSubmit(data);
  };

  return (
    <>
      <div className={`permit-notification`}>
        <form onSubmit={handleSubmit(notificationSubmitHandler)}>
          <div className="permin-form-main">
            <h3 className="permin-form-title">{permitTitle}</h3>
            <div className="permin-form-main-in">
              <Controller
                name="enable_all_alerts"
                control={control}
                render={({ field }) => (
                  <div className="permit-notifications-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                      />
                      Enable all alerts
                    </label>
                  </div>
                )}
              />
              {watch("enable_all_alerts") && (
                <>
                  <div className="enable-all-alerts">
                    <div className="enable-all-alerts-row">
                      <div className="enable-all-alerts-row1">
                        <Controller
                          name="upcoming_expiration_permit"
                          control={control}
                          render={({ field }) => (
                            <label>
                              <input
                                className="permit-field-checkbox"
                                type="checkbox"
                                value={field.value}
                                onChange={field.onChange}
                              />
                              Upcoming expiration permit purchased by motorist
                            </label>
                          )}
                        />
                      </div>
                      {watch("upcoming_expiration_permit") && (
                        <Controller
                          name="upcoming_expiration_permit_template"
                          control={control}
                          render={({ field }) => (
                            <>
                              <div className="enable-all-alerts-row2">
                                <input
                                  className="permit-field-rules  haa-fiels"
                                  type="text"
                                  value={field.value}
                                  onChange={field.onChange}
                                  placeholder="Template Name"
                                />{" "}
                              </div>
                              <div className="enable-all-alerts-row3">
                                <a href="#" className="add-geofence-link">
                                  View
                                </a>
                                <a href="#" className="add-geofence-link">
                                  Edit
                                </a>
                              </div>
                            </>
                          )}
                        />
                      )}
                    </div>
                    <div className="enable-all-alerts-add-more">
                      <button className="add-mobe-button" type="button">
                        + Add more
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div className="upload-logo-notification">
                <h4 class="permit-title-h4 mb-2">Upload logo</h4>
                <Controller
                  name="logo"
                  control={control}
                  render={({ field }) => (
                    <div className="upload-logo-notification-box">
                      <div className="upload-logo-notification-box-img">
                        <label>
                          <img src={ImageUpload} alt="upload" />
                          <input
                            type="file"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </label>
                      </div>
                      <div className="upload-logo-notification-box-text">
                        Image only of 1500*846 px
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="permin-form-btn">
            <div className="permin-form-btn-left">
              <button className="permin-btn" type="button">
                <img src={ArrowLeft} alt="back" />
                Back
              </button>
            </div>
            <div className="ermin-form-btn-right">
              <button className="permin-btn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PermitNotificationTab;
