import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { changetotal, siteid } from "../../../Global/site";
import dashboardService from "../../../Services/dashboard.service";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { Link } from "react-router-dom";
import BackbuttinIMag from "../../../Images/back.png";
import Eororicon from "../../../Images/info-icon.png";
import CitationInfoTab from "../../Utilies/CitationInfo";
import AuditTrialTabDetail from "./AuditTrialTabDetail";
import ManagerReviewTab from "./ManagerReviewTab";
import NoteTab from "./NoteTab";
import TicketReportDetails from "../../../Services/main.service";
import TicketDetailHeader from "../../ReportingModule/TicketReporting/TicketDetailHeader";
import NoticeTabComponent from "./NoticeTabComponent";

const HearingDetail = (props) => {
  const dispatch = useDispatch();
  const changeTotal = changetotal();
  const [activeTab, setActiveTab] = useState(2);

  const [citationNumber, setCitationNumber] = useState("");

  const [showStepStatus, setStepStatus] = useState([]);
  const [appealStatus, setAppealStatus] = useState([]);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [imageForCitationDownload, setImageForCitationDownload] = useState([]);
  const [paymentBreakdown, setPaymentBreakdown] = useState([]);
  const [registeredOwnerDetails, setRegisteredOwnerDetails] = useState([]);

  const showSideId = siteid();

  const [remarkInputs, setRemarkInputs] = useState({
    remark: "",
    additional_remark: "",
    hearing_status: "",
  });
  const [remarkErrors, setRemarkErrors] = useState({
    remark: "",
  });

  const [remarkInputs1, setRemarkInputs1] = useState({
    from: "",
    to: "",
    subject: "",
    body: "",
    attachments: [],
  });

  const [messageSuc, setMessageSuc] = useState("");

  // notification form state
  const [notiInput, setNotiInput] = useState({
    notification_name: "",
    site_id: showSideId.siteinfo,
    email_recipients: [],
    metadata: {
      template_id: "",
      from: "",
      subject: "",
      body: "",
      frequency: "Event Driven",
      notification_type: "",
      status: "Activated",
      logo: "",
    },
  });

  // custom error handing state
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalMsg, setModalMsg] = useState("");
  const [appealid, setappealid] = useState("");

  const [citationAllData, setCitationAllData] = useState({});

  const location = useLocation().search;
  const citation_Id = new URLSearchParams(location).get("id");
  const citation_Number = new URLSearchParams(location).get("citation_no");

  useEffect(() => {
    getCitationDetailsAPI(citation_Number)
  },[citation_Id, citation_Number]);

  async function getCitationDetailsAPI(id) {
    try {
      const result = await TicketReportDetails.citationDetails(
        "ticket_number=" + id
      );

      const { detail } = result.data.data;
      if (detail && detail !== null) {
        setCitationAllData(detail);

        const imgobj = await imageDataGet(detail.images);
        setImageForCitationDownload(imgobj);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getCitationDetailsAPI(id);
      }
    }
  }
  async function imageDataGet(imgdata) {
    var imageData = {
      download_type: "CitationImages",
      links: Object.assign({}, imgdata),
      site_id: showSideId.siteinfo,
    };
    try {
      const result = await dashboardService.downloadImage(imageData);
      return result.data.metadata;
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        imageDataGet(imgdata);
      }
    }
  }
  async function getAppealStatusData() {
    try {
      const result = await dashboardService.appealStatusListAPI();
      setAppealStatus(result.data.data);
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealStatusData();
      }
    }
  }
  const pageLoadData = () => {
    const no_t = citation_Id;
    setappealid(no_t);
    getAppealDetailedInfo();
    getAppealStatusData();
    dynamicstatusfun();
    function dynamicstatusfun() {
      setBtnLoader(true)
      dashboardService
        .dynemicStatus()
        .then((result) => {
          if (result.data.status === true && result.data.data !== null) {
            var SortData = result.data.data;

            SortData.sort(function (a, b) {
              var textA = a["id"];
              var textB = b["id"];
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });

            setStepStatus(SortData);

          }
          setBtnLoader(false)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            dynamicstatusfun();
          }
          setBtnLoader(false)
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setMessageSuc("");
    }, 2000);
  }, [messageSuc]);

  useEffect(() => {
    pageLoadData();
  }, [citation_Id, citation_Number]);
  const handleCommon = async (e, step, tab) => {

    setActiveTab(tab);

    setRemarkInputs1({
      ...remarkInputs1,
      body: "",
      subject: "",
      attachments: [],
    });
    setBtnLoader(false);
    setMessageSuc("");
  };

  const getAppealDetailedInfo = async () => {
    try {
      const appeal_id = citation_Id;

      const result = await dashboardService.getAppealDetailedByIdInfo(
        appeal_id
      );
      if (result.data.status) {
        const data = result.data.data;
        setCitationNumber(data.citation_number);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealDetailedInfo();
      }
    }
  };

  const closeModal = () => {
    setNotiInput({
      ...notiInput,
      notification_name: "",
      site_id: showSideId.siteinfo,
      email_recipients: [],
      metadata: {
        template_id: "",
        from: "",
        subject: "",
        body: "",
        frequency: "Event Driven",
        notification_type: "",
        status: "Activated",
        logo: "",
      },
    });
    setModalOpen(false);
    setModalMsg("");
  };

  useEffect(() => {
    setRemarkInputs({ ...remarkInputs, hearing_status: citationAllData?.hearing_details?.status })
  }, [citationAllData, citationAllData?.hearing_details])
  

  const getPaymentDetails = async (ticket_no) => {
    try {
      const result = await dashboardService.getPaymentDetails(ticket_no);
      const paymentBreakdownArray = [];
      if (Object.keys(result.data?.data?.paymentBreakdown).length !== 0) {
        paymentBreakdownArray.push(result.data.data.paymentBreakdown);
        setPaymentBreakdown(result.data.data.paymentBreakdown);
      }
      if (result.data.status) {
        setRegisteredOwnerDetails(result.data?.data?.registered_owner_details);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getPaymentDetails(ticket_no);
      }
    }
  };

  useEffect(() => {
    getPaymentDetails(citation_Number)
  },[])

  return (
    <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
      {isBtnLoader ? 
        <div className="col-12 text-center">
          <div className="spinner-border"></div>
        </div> : 
      <div >
        <div className="row">
          <div className="col-12 back-button mb-2">
            <Link
              to={"/hearing-report"}
            >
              <img src={BackbuttinIMag} alt="img" className="img-fluid" />
              Back
            </Link>
          </div>
        </div>
        <div className="rightpanale-box2 cstm-rightpanale-box2 cstm-ad-only">
          <div className="cstm-ap-man">
            <div className="cstm-ap-man-left">
              <ul className="cstm-ap-list-main">
                <li className="" onClick={(e) => handleCommon(e, "Citation", 7)}>
                  <a className={`${activeTab === 7 ? "active" : ""}`}>
                    {changeTotal} Info
                  </a>
                </li>
                <li className="" onClick={(e) => handleCommon(e, "Notice", 8)}>
                  <a className={`${activeTab === 8 ? "active" : ""}`}>
                    Notice
                  </a>
                </li>
                <li className="" onClick={(e) => handleCommon(e, "Appeal", 6)}>
                  <a className={`${activeTab === 6 ? "active" : ""}`}>
                    Audit trial
                  </a>
                </li>
              </ul>
            </div>
            <div className="cstm-ap-man-right">
              <div className="cstm-appeal-reports-title">Hearing Workflow</div>
              <div className="cstm-ar-tp-tab">
                <div className="counter-tabination">
                  <ul
                    className="nav nav-tabs2 pagination-content"
                    style={{
                      display: "grid",
                      gridGap: "36px",
                      gridTemplateColumns: "repeat(2, minmax(150px, 20%))",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {showStepStatus
                      .filter(
                        (step) =>
                          step.title === "Notes" ||
                          step.title === "Closed"
                      )
                      .map((step, index) => (
                        <li
                          key={index}
                          className="nav-item2"
                          onClick={(e) => (handleCommon(e, step.title, index + 1)) }
                        >
                          <a
                            className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${
                              activeTab === index + 1 ? "active" : ""
                            }`}
                          >
                            <span className="main-round-border">{index + 1}</span>
                            <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                              {step.title}
                            </p>
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="cstm-af-only-main">
                <div className="tab-content">
                  <div
                    id="tab_1"
                    className={`tab-contain-add-main-cstm tab-pane fade ${
                      activeTab === 1 ? "active show" : ""
                    }`}
                  >
                    <div className="col-6 cstm-hearing-remark">
                      <div className="">
                        <NoteTab Workflow={citationAllData?.hearing_workflow} />
                      </div>
                    </div>
                  </div>
                  <div
                    id="tab_2"
                    className={`tab-contain-add-main-cstm tab-pane fade ${
                      activeTab === 2 ? "active show" : ""
                    }`}
                  >
                    {messageSuc && (
                              <div className="alert alert-success" role="alert">
                                {messageSuc}
                              </div>
                            )}
                    <ManagerReviewTab 
                      isBtnLoader={isBtnLoader} 
                      setBtnLoader={setBtnLoader}
                      setModalMsg={setModalMsg}
                      setModalOpen={setModalOpen}
                      workFlow_status="Closed"
                      workFlowPage="Hearing Management"
                      citationAllData={citationAllData}
                      getCitationDetailsAPI={getCitationDetailsAPI}
                      remarkErrors={remarkErrors}
                      remarkInputs={remarkInputs}
                      setRemarkErrors={setRemarkErrors}
                      setRemarkInputs={setRemarkInputs}
                      setMessageSuc={setMessageSuc}
                    />
                </div>
                  <div
                    id="citation-info"
                    className={`tab-pane fade ${
                      activeTab === 7 ? "active show" : ""
                    }`}
                  >
                    <TicketDetailHeader
                      citationData={citationAllData}
                      paymentBreakdownData={paymentBreakdown}
                      registeredOwnerDetails={registeredOwnerDetails}
                    />
                    <br />
                    <CitationInfoTab isAppeal={false} citationAllData={citationAllData} imageForCitationDownload={imageForCitationDownload}/>
                  </div>
                  {/* audit trial */}
                  <div
                    id="audit-trial"
                    className={`tab-pane fade ${
                      activeTab === 6 ? "active show" : ""
                    }`}
                  >
                    <AuditTrialTabDetail/>
                  </div>
                    
                  {/* Notice tab starts */}
                    
                  <div
                    id="notice"
                    className={`tab-pane fade ${activeTab === 8 ? "active show" : ""}`}
                  >
                      <NoticeTabComponent citationAllData={citationAllData} setCitationAllData={setCitationAllData} />
                  </div>

                  {/* Notice tab ends */}
                  {isModalOpen && (
                    <div className="main-body-modal">
                      <div className="main-modal">
                        <div className="cstm-close">
                          <button onClick={closeModal}>×</button>
                        </div>
                        <div className="cstm-modal-body">
                          <div className="modal-body cstm-modal-body">
                            <div className="text-center cstm-paymnet-img">
                              <img
                                src={Eororicon}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <p className="text-center mb-4">{isModalMsg}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default HearingDetail;
