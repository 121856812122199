import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import PurchasePermitTable from './purchasePermitTable';
import transfer from '../../../Images/transfer.png';
import Pagination from '../../../utile/pagination';
import dropdownarrow from '../../../Images/dropdown-arrow.svg';
import filter from "../../../Images/filter-yellow.svg";
import PurchasedPermitDetails from './PurchasedPermitDetail';
import EditPermit from './EditPermit';
import { getFormattedDate } from '../../Common/utils';
import { useLocation } from 'react-router';

function convertDecimal(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

function pad2(n) {
  return (n < 10 ? '0' : '') + n;
}

function convertFilterFormatData(dt) {
  var date = new Date(dt);
  var year = date.getFullYear();
  var month = pad2(date.getMonth() + 1);
  var todayDate = pad2(date.getDate());

  var currentTimeInSeconds =
    year +
    '-' +
    month +
    '-' +
    todayDate +
    'T' +
    (date.getHours() < 10 ? '0' : '') +
    date.getHours() +
    ':' +
    (date.getMinutes() < 10 ? '0' : '') +
    date.getMinutes() +
    ':00.000';

  return currentTimeInSeconds;
}

const PurchasedPermitComponent = ({
  reportData,
  noRecordFound,
  loading,
  totalRecords,
  tableHeading,
  keySequence,
  PermitListHandler,
  handlePaginationChange,
  itemsPerPage,
  currentPage,
  statusFilterList,
}) => {
  const location = useLocation().search;
  const [filterDate, setFilterDate] = useState({
    created_ts_from: '',
    created_ts_to: '',
  });
  const [showDetailPage, setDetailPage] = useState(false);
  const [detailData, setDetailData] = useState();
  const [showPlateDetails, setShowPlateDetails] = useState(false);
  const [plateData, setPlateData] = useState();
  const [permitStatus, setPermitStatus] = useState('');
  const [editPermit, setEditPermit] = useState(false);
  const [permitFilters, setPermitFilters] = useState({
    status: "",
    permit_name: "",
    lp_number: "",
    first_name: "",
    last_name: ""
  })
  const [filterToggle,setFilterToggle] = useState(false)

  
  useEffect(() => {
    if (location) {
      setDetailPage(true);
    }
  }, [location]);

  const handleFilterSubmit = e => {
    e.preventDefault();
    console.log(permitFilters,'permit filters')
    let query_string = '';
    query_string += 'page=' + 1;
    query_string += '&limit=' + itemsPerPage;
    if (filterDate.created_ts_from) {
      query_string +=
        '&created_at_from=' +
        convertFilterFormatData(filterDate.created_ts_from);
    }
    if (filterDate.created_ts_to) {
      query_string +=
        '&created_at_to=' + convertFilterFormatData(filterDate.created_ts_to);
    }
    if (permitFilters.status !== "") {
      query_string += '&status=' + permitFilters.status;
    }
    if (permitFilters.lp_number !== "") {
      query_string += '&lp_number=' + permitFilters.lp_number;
    }
    if (permitFilters.first_name !== "") {
      query_string += '&first_name=' + permitFilters.first_name;
    }
    if (permitFilters.last_name !== "") {
      query_string += '&last_name=' + permitFilters.last_name;
    }
    if (permitFilters.permit_name !== "") {
      query_string += '&permit_name=' + permitFilters.permit_name;
    }
    // if (permitStatus) {
    //   query_string += '&status=' + permitStatus;
    // }

    PermitListHandler(query_string);
  };

  const onChangeFilterDate = (date, name) => {
    setFilterDate({ ...filterDate, [name]: date });
  };

  const backButtonClick = e => {
    setDetailPage(false);
    handleFilterSubmit(e);
  };

  const showPlatesHandler = plates => {
    setShowPlateDetails(true);
    setPlateData(plates);
  };

  const onFilterChange = e => {
    const { name, value } = e.target
    setPermitFilters({...permitFilters,[name]:value})
    // setPermitStatus(e.target.value);
  };

  const handleEditPermit = data => {
    setDetailData(data);
    setEditPermit(!editPermit);
  };

  const changeFilterToggle = (e) => {
    e.preventDefault();
    setFilterToggle(!filterToggle)
  }

  return (
    <>
      {!showDetailPage ? (
        <section>
          <div className="container-fluid">
            <div className="row">
              {/* Content starts */}
              <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                <div className="cstm-ticket-manage-sec">
                  <div className="cstm-ticket-manage-heading">
                    <h3 className="cstm-all-pg-ttl operate-ttl">
                      Purchased Permit List
                    </h3>
                  </div>
                </div>
                {editPermit ? (
                  <EditPermit permitData={detailData} closePermitEdit={handleEditPermit} />
                ) : (
                  <>
                      <div className="report-searchbar">
                        <form onSubmit={handleFilterSubmit}>
                          <div className="report-date report-mr d-flex">
                            <DatePicker
                              showTimeSelect
                              placeholderText="MM/DD/YYYY HH:MM"
                              dateFormat="MM/dd/yyyy h:mmaa"
                              timeFormat="HH:mm"
                              className="cstm-pull-details schedule-date-input"
                              autoComplete="off"
                              name="created_ts_from"
                              selected={filterDate.created_ts_from}
                              onChange={date =>
                                onChangeFilterDate(date, 'created_ts_from')
                              }
                            />
                            <img
                              src={transfer}
                              alt="transfer"
                              className="date-transfer"
                            />
                            <DatePicker
                              showTimeSelect
                              dateFormat="MM/dd/yyyy h:mmaa"
                              timeFormat="HH:mm"
                              placeholderText="MM/DD/YYYY HH:MM"
                              className="cstm-pull-details schedule-date-input"
                              autoComplete="off"
                              name="created_ts_to"
                              selected={filterDate.created_ts_to}
                              onChange={date =>
                                onChangeFilterDate(date, 'created_ts_to')
                              }
                            />
                          </div>
                          <div className="report-licence report-mr">
                            <input
                              className="license-plate"
                              name="lp_number"
                              value={permitFilters.lp_number}
                              onChange={onFilterChange}
                              type="text"
                              placeholder="License Plate"
                            />
                          </div>
                          <div className="report-licence report-mr">
                            <input
                              className="license-plate"
                              name="permit_name"
                              value={permitFilters.permit_name}
                              onChange={onFilterChange}
                              type="text"
                              placeholder="Permit name"
                            />
                          </div>
                          <div className="report-licence select-drop-arrow report-mr">
                            <select
                              className="p-2"
                              name="permit_status"
                              onChange={onFilterChange}
                              value={permitFilters.status}>
                              <option value={''}>Select Status</option>
                              {statusFilterList &&
                                statusFilterList.map(item => (
                                  <option value={item.permit_status}>
                                    {item.permit_status}
                                  </option>
                                ))}
                            </select>
                            <img src={dropdownarrow} alt="dropdownarrow" />
                          </div>
                          <button
                            onClick={(e) => changeFilterToggle(e)}
                            className="filter-box report-mr"
                            type='button'
                          >
                            <img src={filter} alt="filter" />
                          </button>
                          <button className="licence-print report-mr" type="submit">
                            Submit
                          </button>
                        </form>
                        {filterToggle && (
                          <div className="filter-main-section">
                            <div className="row">
                             
                            <div className="select-drop-arrow filter_cstm3">
                                  <input
                                    className="license-plate"
                                    name="first_name"
                                    value={permitFilters.first_name}
                                    onChange={onFilterChange}
                                    type="text"
                                    placeholder="First name"
                                  />
                            </div>
                            <div className="select-drop-arrow filter_cstm3">
                                  <input
                                    className="license-plate"
                                    name="last_name"
                                    value={permitFilters.last_name}
                                    onChange={onFilterChange}
                                    type="text"
                                    placeholder="Last name"
                                  />
                            </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {loading ? (
                        <div className="text-center spin-load-main">
                          <div className="spinner-border"></div>
                        </div>
                      ) : noRecordFound ? (
                        <div className="no-record-found-main">No Record Found</div>
                      ) : (
                            <>
                              <div className="ctsm-table-scroll">
                                <div className="table-responsive">
                                  <div className="">
                                    <PurchasePermitTable
                                      reportData={reportData}
                                      tableHeading={tableHeading}
                                      keySequence={keySequence}
                                      setDetailPage={setDetailPage}
                                      setDetailData={setDetailData}
                                      showPlatesHandler={showPlatesHandler}
                                      handleEditPermit={handleEditPermit}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* )} */}

                              {noRecordFound !== true && (
                                <>
                                  <Pagination
                                    handlePaginationChange={handlePaginationChange}
                                    totalRecords={totalRecords}
                                    pageLimit={itemsPerPage}
                                    pageNeighbours={1}
                                    currentPage={currentPage}
                                    dropdownLimit={false}
                                  />
                                </>
                              )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <PurchasedPermitDetails
          backButtonClick={backButtonClick}
          detailData={detailData}
        />
      )}

      {showPlateDetails && (
        <div className="main-body-modal cstm-plate-popup">
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowPlateDetails(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-plate-body">
                  <table class="table table-heading table-bordered cstm-plate-table">
                    <thead>
                      <tr>
                        <th className="plate01">Start Date</th>
                        <th className="plate02">End Date</th>
                        <th className="plate03">Status</th>
                        <th className="plate04">Plate No.</th>
                        <th className="plate05">State</th>
                        <th className="plate06">Permit Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plateData &&
                        plateData.length > 0 &&
                        plateData.map(item => (
                          <tr>
                            <td>{getFormattedDate(item.start_date) || '-'}</td>
                            <td>{getFormattedDate(item.end_date) || '-'}</td>
                            <td>{item.status || '-'}</td>
                            <td>{item.vehicle_details.lp_number || '-'}</td>
                            <td>{item.state || '-'}</td>
                            <td>
                              {item.permit_fee >= 0
                                ? `$${convertDecimal(item.permit_fee)}`
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchasedPermitComponent;
