import moment from "moment"
import { Link } from "react-router-dom"
import mainService from "../../../Services/main.service";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { siteid } from "../../../Global/site";

const NoticeTabComponent = ({ citationAllData, setCitationAllData }) => {
  
  console.log(citationAllData,'citation all data')
  const [rightClick, setRightClick] = useState(-1);
  const [clipBoardText, setClipBoardText] = useState("");
  const [sorting, setSorting] = useState({
    field: "xyz",
    ascending: true,
    type: "",
  });

  const showSideId = siteid();
  const dispatch = useDispatch();

  useEffect(() => {

    const currentDataCopy = { ...citationAllData };
    if (sorting.type === "notice") {
      const sortedCurrentdata = (
        currentDataCopy?.notification_audit_trail || []
      ).sort((a, b) => {
        return a[sorting.key].localeCompare(b[sorting.key]);
      });
      setCitationAllData({
        ...currentDataCopy,
        notification_audit_trail: sorting.ascending
          ? sortedCurrentdata
          : sortedCurrentdata.reverse(),
      });
    }
  }, [sorting]);

  const applySorting = (key, ascending, type) => {
    setSorting({ key: key, ascending: ascending, type: type });
  };
  
  const downloadAttachment = async (e, i) => {
    e.preventDefault();
    let url = "";
    var imageData = {
      links: Object.assign({}, { 0: e.target.dataset.id }),
      download_type: "CitationImages",
      site_id: showSideId.siteinfo,
    };
    await downloadFun();
    async function downloadFun() {
      try {
        const responseImage = await mainService.downloadImage(imageData);
        if (responseImage.data) {
          url = responseImage.data.metadata[0].url;

          setClipBoardText(responseImage.data.metadata[0].url);
        }
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          downloadFun();
        }
      }
    }
    if (e.nativeEvent.button === 2) {
      setRightClick(i);
    } else {
      fileDownload(url);
      setRightClick(-1);
    }
  };

  function fileDownload(url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = "download";
    document.body.appendChild(a);
    a.click();
    return a;
  }

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <h4>Notice Details</h4>
        </div>
      </div>

      <div className="row date-row">
        <div className="col-12 col-xl-3 filter-button2 m-0">
          <label>Filter Email History</label>
          <input
            type="text"
            className="date-time-input w-100"
            placeholder="Start Date and Time End Date and Time"
          />
        </div>
        <div className="col-12 col-xl-2 submit-btn submitbutton-align">
          <a href="#">
            <button>Submit</button>
          </a>
        </div>
      </div>
      <div className="row table-overflow">
        <div className="col-12">
          <table className="table-heading table-bordered">
            <thead>
              <tr>
                <th
                    className={`srt-tab ${sorting.key === "CreatedAt" &&
                    (sorting.ascending
                      ? "srt-ace"
                      : "srt-desc")
                      }`}
                  onClick={() =>
                    applySorting(
                      "CreatedAt",
                      !sorting.ascending,
                      "notice"
                    )
                  }
                >
                  date
                </th>
                <th
                    className={`srt-tab ${sorting.key === "Email" &&
                    (sorting.ascending
                      ? "srt-ace"
                      : "srt-desc")
                      }`}
                  onClick={() =>
                    applySorting(
                      "Email",
                      !sorting.ascending,
                      "notice"
                    )
                  }
                >
                  Email sent to
                </th>
                <th
                    className={`srt-tab ${sorting.key === "SentBy" &&
                    (sorting.ascending
                      ? "srt-ace"
                      : "srt-desc")
                      }`}
                  onClick={() =>
                    applySorting(
                      "SentBy",
                      !sorting.ascending,
                      "notice"
                    )
                  }
                >
                  Sent By
                </th>
                <th
                  className={`srt-tab ${sorting.key === "NotificationType" &&
                  (sorting.ascending
                    ? "srt-ace"
                    : "srt-desc")
                  }`}
                  onClick={() =>
                    applySorting(
                      "NotificationType",
                      !sorting.ascending,
                      "notice"
                    )
                  }
                >
                  Notification Type
                </th>
                <th
                    className={`srt-tab ${sorting.key === "Address" &&
                    (sorting.ascending
                      ? "srt-ace"
                      : "srt-desc")
                      }`}
                  onClick={() =>
                    applySorting(
                      "Address",
                      !sorting.ascending,
                      "notice"
                    )
                  }
                >
                  Address
                </th>
                <th>Attachments</th>
              </tr>
            </thead>
            <tbody>
              {(
                citationAllData?.notification_audit_trail ||
                []
              ).map((item, i) => (
                <tr key={i}>
                  <td>
                    {moment(
                      item.CreatedAtLocal !== null &&
                      item.CreatedAtLocal.split("T")[0]
                    ).format("MM/DD/YYYY") +
                      " " +
                      item.CreatedAtLocal !==
                      null &&
                      item.CreatedAtLocal.substring(11, 19)}
                  </td>
                  <td>{item.Email || "-"}</td>
                  <td>{item.SentBy || "-"}</td>
                  <td>{item.NotificationType || "-"}</td>
                  <td>{item.Address || "-"}</td>
                  <td>
                    <Link
                      data-id={item.DocLocation}
                      onContextMenu={(e) =>
                        downloadAttachment(e, i)
                      }
                      onClick={(e) =>
                        downloadAttachment(e, i)
                      }
                    >
                      Download
                    </Link>
                    {rightClick === i && (
                      <div className="right-copy-menu">
                        <CopyToClipboard
                          text={clipBoardText}
                          onCopy={() => setRightClick(-1)}
                        >
                          <span>Copy Link</span>
                        </CopyToClipboard>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {citationAllData?.notification_audit_trail
            ?.length === 0 ||
            (citationAllData?.notification_audit_trail ===
              null && (
                  <div className="text-center cstm-no-record-found cstm-nrf ">
                    No Record Found
                  </div>
                ))}
        </div>
      </div>
    </div>
  )
}

export default NoticeTabComponent