import React, { useState, useRef, createRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate, Link, useLocation } from 'react-router-dom'

import Sitesetup from "../../Services/main.service";
import { assignsiteid } from "../../Global/site";
import Cookies from 'js-cookie';
import mainService from '../../Services/main.service';
import { updatesiteinfo } from '../../actions/auth';
import { refreshTokenMiddleware } from '../customMiddelware';

const OtpVerification = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const idsite = assignsiteid()

	const { isLoggedIn, isAgency } = useSelector(state => state.auth)
	const otpLenght = 6
	const [forms, setForms] = useState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" })
	const [error, setError] = useState('')
	const [messageCtm, setMessageCtm] = useState('');
	const [showAddedMessage, setAddedMessage] = useState("");
	const [isBtnLoader, setBtnLoader] = useState(false);

	// const inputsRef = useRef([...Array(otpLenght)].map(() => createRef()));
		const { agencyS } = useSelector(state => state.auth)
		const [datas, setDatas] = useState([])
		const [loader, setLoader] = useState(false)
			function agencieslistfun() {
				mainService.agencieslistAPI().then(res => {
					if (res.data.status) {
						console.log("response",res.data.data.agency_map)
						console.log("agency_id",res.data.data.agency_map.agency_id)
						setLoader(false)
						var newArray = res.data.data.agency_map.filter(function (el) {
							return el.site_data.map(site => site.site_id).includes(idsite);
						})	
						var agency_data=newArray[0].site_data.filter((ele)=>ele.site_id===idsite)					
						if(newArray[0].site_data.length === 1) {
							agency_data.map((ele)=>{
								chooseAnySite(ele)
							})
							navigate('/dashboard')
						  } else {
							agency_data.map((ele)=>{
								chooseAnySite(ele)
							})
						  }
										}
				}).catch(err => {
					if (err.status === 401) {
						refreshTokenMiddleware(dispatch)
						agencieslistfun()
					}
				})
			}
	const handleChange = (e) => {
		const { name, value, tabIndex } = e.target

		setForms({ ...forms, [name]: value });
		if (tabIndex === 6) {
			let flag = 1
			Object.entries(forms).forEach(([key, value]) => {
				if (value) {
					flag += 1
				}
			});
			if (flag === tabIndex) {
				const otp = forms.otp1 + forms.otp2 + forms.otp3 + forms.otp4 + forms.otp5 + value
				varifyOTPFun(otp)
			}
		}
	}

	const inputfocus = (e) => {
		const re = /^[0-9\b]+$/;
		const { value, tabIndex } = e.target

		if (e.key === "Delete" || e.key === "Backspace") {
			const next = tabIndex - 2;
			if (next > -1) {
				// inputsRef?.current[next]?.current?.focus();
				e.target.form.elements[next].focus()
			}
		} else if (re.test(value)) {
			const next = tabIndex;
			if (next < 6) {
				// inputsRef?.current[next]?.current?.focus();
				e.target.form.elements[next].focus()
			}
		}
		setAddedMessage('')
		setMessageCtm('')
	}

	const handlePaste = (e) => {
		const re = /^[0-9]+$/;
		if (re.test(e.clipboardData.getData('text')) && e.clipboardData.getData("text").length === 6) {
			setForms({ otp1: e.clipboardData.getData("text").charAt(0), otp2: e.clipboardData.getData("text").charAt(1), otp3: e.clipboardData.getData("text").charAt(2), otp4: e.clipboardData.getData("text").charAt(3), otp5: e.clipboardData.getData("text").charAt(4), otp6: e.clipboardData.getData("text").charAt(5) })
			// inputsRef?.current[5]?.current?.focus();
			e.target.form.elements[5].focus()
			const otp = e.clipboardData.getData("text")
			varifyOTPFun(otp)

		} else {
			setForms({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" })
		}
	}

	const handleSubmit = (event) => {
		agencieslistfun()
		setAddedMessage('')
		setMessageCtm('')
		const data = new FormData(event.target);
		const otp = data.get('otp1') + data.get('otp2') + data.get('otp3') + data.get('otp4') + data.get('otp5') + data.get('otp6')
		let isValid = false
		if (otp === '') {
			isValid = true
			setError("Enter OTP")
		} else if (otp.length != 6) {
			isValid = true
			setError("OTP must be 6 digit long.")
		}
		event.preventDefault();
		if (!isValid) {
			setError('')
			varifyOTPFun(otp)
		}
	}
	const chooseAnySite = (data) => {
        dispatch(updatesiteinfo(data))
        navigate('/dashboard')
    }
	function varifyOTPFun(otp) {
		setBtnLoader(true)
		var payload = {
			"mobile_otp_nonce": Cookies.get('mobile_otp_nonce'),
			"otp": Number(otp),
			"site_id": idsite
		}
		Sitesetup.VeifyOtp(payload).then(
			(result) => {
				setBtnLoader(false)
				if (result.data.status) {
					if (result.data.data.otp_verified) {
						
						Cookies.set('auth', JSON.stringify(result.data.data));
						Cookies.set('token', result.data.data.access_token_data.token);
						Cookies.set('isLoggedIn', true);
						Cookies.set('isAgency', JSON.stringify(true));
						dispatch({ type: 'LOGIN_SUCCESS', payload: result.data.data })
							agencieslistfun()
					} else {
						setAddedMessage(result.data.message)
					}
				} else {
					setAddedMessage(result.data.message)
				}
			}
		);
	}

	const handleResendOTP = (event) => {
		event.preventDefault();
		setAddedMessage('')
		setMessageCtm('')
		if (location.state) {
			Sitesetup.userLogin(location.state).then(
				(result) => {
					if (result.data.status) {
						if (!result.data.data.invalid_user && result.data.data.pwd_verified) {
							
							Cookies.set('mobile_otp_nonce', result.data.data.mobile_otp_nonce)
							setMessageCtm("OTP re-sent to your registered phone number.")
						} else {
							setAddedMessage(result.data.message)
						}
					} else {
						setAddedMessage(result.data.message)
					}
				}
			);
		} else {
			navigate('/')
		}
	}

	if (isLoggedIn || isAgency) {
		return <Navigate to={'/dashboard'} />
	}

	// if (isLoggedIn && !isAgency) {
	// 	return <Navigate to={'/agency'} />
	// }

	return (
		<>
			<div className="wrapper citycitiation">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-6 col-12 login-align">
							<div className="login-section otp-login-sec-cstm">
								{showAddedMessage && (
									<div className="text-left form-group">
										<div className="alert alert-danger" role="alert">
											{showAddedMessage}
										</div>
									</div>
								)}
								{messageCtm && (
									<div className="text-left form-group">
										<div className="alert alert-success" role="alert">
											{messageCtm}
										</div>
									</div>
								)}
								<h3>OTP Verification</h3>
								<p>We have sent a code to your registered email and phone number.</p>
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-12 form-group formgroup-main otp">
											{Array.from({ length: otpLenght }, (_, i) => (
												<input
													key={i}
													onKeyPress={(event) => {
														if (!/[0-9]/.test(event.key)) {
															event.preventDefault();
														}
													}}
													name={`otp${i + 1}`}
													type="text"
													autoComplete="off"
													className="form-control otpInput"
													placeholder="-"
													value={forms['otp' + (i + 1)]}
													maxLength="1"
													tabIndex={i + 1}
													// ref={inputsRef.current[i]}
													onChange={(e) => handleChange(e)}
													onKeyUp={(e) => inputfocus(e)}
													onPaste={(e) => handlePaste(e)}
												/>
											))}
											<span className="errormessage">{error}</span>
										</div>
									</div>
									<div className="row">
										<div className="col-12 signUp-text">
											<p>
												Didn’t get a code?{' '}
												<Link to='#' onClick={handleResendOTP}>
													Click to resend.
												</Link>
											</p>
										</div>

										<div className="col-12 button2">
											<button className="btn" type="submit" disabled={isBtnLoader}>
												{isBtnLoader &&
													<div className="cstm-loader">
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
													</div>
												}
												Verify
											</button>
										</div>

									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-6 col-12 login-bgImage">
							<div className="login-cont-main">
								<div className="login-cont-main-sub">Nice to see you again</div>
								<div className="login-cont-main-title">Welcome Back</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>)
}
export default OtpVerification