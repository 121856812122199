import React from 'react'; 
import moment from 'moment';
import Cookies from 'js-cookie';
import { changetotal } from "../../../../Global/site";


const GroupTableLogs = (props) => {
   const { items, requestSort, sortConfig } = props.products;
   const changeTotal = changetotal();


  const onClickDetailsPage = (e) =>{
    Cookies.set('reporting_ticket_id', e.target.dataset.id);
    return props.onClickDetailsPage(true);
  } 

  function convertDecimal(num) {
		return (Math.round(num * 100) / 100).toFixed(2)
	}

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  }; 

   return (

      <>  
    <div className="reporting_filter_main_sect">

    {Object.keys(props.products).map((officer_name,index) => {
        return ( 
     <> <table className="table table-bordered" id="cstm-report-tb-display"> 
            {/* <div className="reporting_filter_main_box_flex"> */}
         
            <thead className="report-grup-div">
              <tr >
                    <th>Officer Name</th>
                    <th></th>
                    <th>Badge ID</th>
                    <th></th>
                    <th>Squad</th>
                    <th></th>
                    <th>Beat</th>
                    <th></th>
                    <th>Supervisor Name</th>
                <th></th>
                <th></th>
                <th></th>
                    </tr>
                <tr>
                    <td>{officer_name}</td>
                    <td></td>
                    <td>-</td>
                    <td></td>
                    <td>-</td>
                    <td></td>
                    <td>-</td>
                    <td></td>
                <td>-</td>
                <td></td>
                <td></td>
                <td></td>
                    </tr>
            </thead>
            {/* </div>  */}


            {/* <div className="report_tbl_scroll"> */}
            
                <thead>
                <tr> 
                    <th className="table-main-heading-list">  
                    Payment Datetime
                    </th>
                    <th className="table-main-heading-list">  
                    Mode
                    </th>
                    <th className="table-main-heading-list"> 
                    Receipt ID
                    </th>
                    <th className="table-main-heading-list">  
                    {changeTotal} Datetime  
                    </th>
                    <th className="table-main-heading-list"> 
                    {changeTotal} Number
                    </th>
                    <th className="table-main-heading-list">  
                    Plate      
                    </th>
                    <th className="table-main-heading-list">  
                    State  
                    </th>
                    <th className="table-main-heading-list">  
                    Cite Amount   
                    </th>
                    <th className="table-main-heading-list">  
                    Paid amount   
                    </th>
                    <th className="table-main-heading-list">  
                    Block   
                    </th>
                    <th className="table-main-heading-list">  
                    Street
                    </th>
                    <th className="table-main-heading-list"> 
                    Zone
                    </th>
                    <th className="table-main-heading-list"> 
                    Vio Code 
                    </th>
                    <th className="table-main-heading-list"> 
                    Description
                    </th>
                    
                </tr>
                
        
      </thead>
      <tbody>
      {props.products[officer_name].map((item, i) => (
         <tr key={item.id}> 

          <td><a data-id={item.id} onClick={onClickDetailsPage}>{moment(item.payment_details.payment_datetime.split('T')[0]).format('MM/DD/YYYY') + " " + (item.payment_details.payment_datetime).substring(11, 19)}</a></td> 
       
     
      {item.payment_details.payment_mode !== "" && item.payment_details.payment_mode !== null && item.payment_details.payment_mode !== "null" && item.payment_details.payment_mode !== undefined ?
      <td>{item.payment_details.payment_mode}</td>
      :
      <td>-</td>  
      }
      {item.payment_details.receipt_id !== "" && item.payment_details.receipt_id !== null && item.payment_details.receipt_id !== "null" && item.payment_details.receipt_id !== undefined ?
      <td>{item.payment_details.receipt_id}</td>
      :
      <td>-</td>  
      } 

      <td>{moment(item.citation_start_timestamp.split('T')[0]).format('MM/DD/YYYY') + " " + (item.citation_start_timestamp).substring(11, 19)}</td> 
        
      {item.header_details.citation_number !== "" && item.header_details.citation_number !== null && item.header_details.citation_number !== "null" && item.header_details.citation_number !== undefined ?
      <td>{item.header_details.citation_number}</td>
      :
      <td>-</td>  
      } 
      {item.lp_number !== "" && item.lp_number !== null && item.lp_number !== "null" && item.lp_number !== undefined ?
      <td>{item.lp_number}</td>
      :
      <td>-</td>  
      } 

  {item.vehicle_details.state !== "" && item.vehicle_details.state !== null && item.vehicle_details.state !== "null" && item.vehicle_details.state !== undefined ?
      <td>{item.vehicle_details.state}</td>
      :
      <td>-</td>  
      } 

{item.fine_amount !== "" && item.fine_amount !== null && item.fine_amount !== "null" && item.fine_amount !== undefined ?
          <td>${convertDecimal(item.fine_amount)}</td>
          :
          <td>-</td>  
          }  

        {item.payment_details.amount !== "" && item.payment_details.amount !== null && item.payment_details.amount !== "null" && item.payment_details.amount !== undefined ?
          <td>${convertDecimal(item.payment_details.amount)}</td>
          :
          <td>-</td>  
          }  

    {item.location.block !== "" && item.location.block !== null && item.location.block !== "null" && item.location.block !== undefined ?
      <td>{item.location.block}</td>
      :
      <td>-</td>  
      } 
      {item.location.street !== "" && item.location.street !== null && item.location.street !== "null" && item.location.street !== undefined ?
      <td>{item.location.street}</td>
      :
      <td>-</td>  
      } 
      {item.officer_details.zone !== "" && item.officer_details.zone !== null && item.officer_details.zone !== "null" && item.officer_details.zone !== undefined ?
      <td>{item.officer_details.zone}</td>
      :
      <td>-</td>  
      } 
       {item.violation_details.code !== "" && item.violation_details.code !== null && item.violation_details.code !== "null" && item.violation_details.code !== undefined ?
      <td>{item.violation_details.code}</td>
      :
      <td>-</td>  
      } 
       {item.violation_details.description !== "" && item.violation_details.description !== null && item.violation_details.description !== "null" && item.violation_details.description !== undefined ?
      <td>{item.violation_details.description}</td>
      :
      <td>-</td>  
      } 
   
   </tr> 
         ))} 
      </tbody>
            {/* </div>   */}
    </table>  
    <div className="total-count-records-left">Total <b>{props.products[officer_name].length}</b> Records Found</div> 
    </>
       )})
      }
</div>
                   
</>

  );
};

export default GroupTableLogs;