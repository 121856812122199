import DatePicker from "react-datepicker";
import transfer from "../../../../Images/transfer.png";
import Multiselect from "multiselect-react-dropdown";
import dropdownarrow from '../../../../Images/dropdown-arrow.svg';
import filter from '../../../../Images/filter-yellow.svg';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useEffect } from "react";
import Reportingsetup from "../../../../Services/main.service";

const DatePickerComponent = ({
  setEndDate,
  setStartDate,
  onChangeSearchDate,
  onSubmitButtonClick,
  lpNumber,
  onChangeSearchFilter,
  onClickFilter,
  roStatus,
  roSource,
  ticketNo,
}) => {

  const [roStatusFilter, setRoStatusFilter] = useState([]);
  const [roSourceFilter, setRoSourceFilter] = useState([]);

  useEffect(() => {
    let passDataStateList = { type: "RoStatusData", shard: 1 };
    Reportingsetup.violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setRoStatusFilter(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    let passDataSourceList = { type: "RoSourceData", shard: 1 };
    Reportingsetup.violationCode(passDataSourceList)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setRoSourceFilter(Response.data.data[0].response);
        }
      })
      .catch((err) => {});
  }, []);


  return (
    <>
      <div className="report-date report-mr">
        <DatePicker
          // selectsStart
          showTimeSelect
          timeIntervals={1}
          dateFormat="MM/dd/yyyy h:mmaa"
          timeFormat="HH:mm"
          className="cstm-pull-details schedule-date-input"
          name="end_date"
          selected={setEndDate}
          onChange={(date) => onChangeSearchDate(date, "end")}
          placeholderText='MM/DD/YYYY HH:MM'
          autoComplete="off"
        />
        <img src={transfer} alt="transfer" className="date-transfer" />
        <DatePicker
          showTimeSelect
          timeIntervals={1}
          dateFormat="MM/dd/yyyy h:mmaa"
          timeFormat="HH:mm"
          className="cstm-pull-details schedule-date-input"
          name="start_date"
          selected={setStartDate}
          onChange={(date) => onChangeSearchDate(date, "start")}
          placeholderText='MM/DD/YYYY HH:MM'
          autoComplete="off"
        />
      </div>
      <div className="report-licence report-mr">
        <input
          className="license-plate"
          name="lp_number"
          value={lpNumber}
          onChange={(e) => onChangeSearchFilter(e)}
          type="text"
          placeholder="License Plate"
        />
      </div>
      <div className="report-licence report-mr select-drop-arrow select-ro-status">
        <select
          value={roStatus}
          onChange={onChangeSearchFilter}
          name="rostatus"
          className="ar-olny cstm-select-srs"
        >
          <option value="">Select RO Status</option>
          {roStatusFilter.map((item) => (
            <option value={item.title}>{item.description}</option>
          ))}
        </select>
      </div>
      <div className="report-licence report-mr select-drop-arrow select-ro-status">
        <select
          value={roSource}
          onChange={onChangeSearchFilter}
          name="rosource"
          className="ar-olny cstm-select-srs"
        >
          <option value="">Select RO Source</option>
          {roSourceFilter.map((item) => (
            <option value={item.title}>{item.description}</option>
          ))}
        </select>
      </div>

      <button
        onClick={onClickFilter}
        className="filter-box report-mr"
      >
        <img src={filter} alt="filter" />
      </button>
      <button
        className="licence-print report-mr"
        type="button"
        onClick={onSubmitButtonClick}
      >
        Submit
      </button>
    </>
  );
};

export default DatePickerComponent;
