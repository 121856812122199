import Cookies from 'js-cookie';
import {
  USER_OTP_SUCCESS,
  USER_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REQUEST_OTP,
  CLEAROUT_USER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AGENCY_STATE,
  SITE_UPDATE_INFO
} from '../actions/types';

const auth = JSON.parse(Cookies.get('auth') || null);
const isLoggedIn = JSON.parse(Cookies.get('isLoggedIn') || null)
const isAgency = JSON.parse(Cookies.get('isAgency') || null)
const agencyS = Cookies.get('agency')
const siteinfo = Cookies.get('siteinfo')

let initialState = {
  isLoggedIn: isLoggedIn || false,
  auth: auth,
  agencyS: agencyS,
  isAgency: isAgency || false,
  siteinfo: siteinfo
}


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        auth: payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        auth: null,
      };
    case USER_OTP_FAIL:
      return {
        ...state,
        auth: null,
        isLoggedIn: false
      };
    case AGENCY_STATE:
      return {
        ...state,
        agencyS: payload,
        isAgency: true
      };
    case SITE_UPDATE_INFO:
      return {
        ...state,
        siteinfo: payload,
        isAgency: true
      };
    default:
      return state;
  }
}
