import React, { useState } from "react";
import dashboardService from "../../Services/dashboard.service";
import Cookies from "js-cookie";
import QuillEditor from "../Common/QuillEditor";
import pdf from "../../Images/pdf-dummy.png";
import doc from "../../Images/doc-dummy.png";
import { v4 as uuidv4 } from "uuid";
import { refreshTokenMiddleware } from "../customMiddelware";
import ImageSlider from "../../utile/imageSlider/imageSlider";
import { useDispatch } from "react-redux";

const SupportNoteModal = ({
  closeFn = () => null,
  open,
  showCitationDetails,
  getCitationAlldetails,
  supportModal,
  citation_ids,
  data
}) => {
  const [formData, setFormData] = useState({
    note: "",
  });
  const [message, setMessage] = useState("");
  const [hearing, setHearing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filesUpload, setFilesUpload] = useState([]);
  const [filesPreview, setFilesPreview] = useState([]);
  const [fileError, setFileError] = useState("");
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const [imageId, setimageId] = useState();

  const dispatch = useDispatch();
  const handleUploadMedia = (e) => {
    const targetFile = e.target.files[0];
    const targetFileSize = targetFile?.size;

    if (targetFileSize > 10 * 1024 * 1024) {
      setFileError("File size exceeds 10MB.");
      return;
    } else {
      setFileError("");
    }

    const url = URL.createObjectURL(targetFile);
    const fileExtension = targetFile.name.split(".").pop().toLowerCase();
    const imageExtensions = ["png", "jpg", "jpeg", "apng", "webp"];
    const docExtensions = ["doc", "docx"];
    const pdfExtensions = ["pdf"];

    let type = "";

    if (imageExtensions.includes(fileExtension)) {
      type = "image";
    } else if (docExtensions.includes(fileExtension)) {
      type = "doc";
    } else if (pdfExtensions.includes(fileExtension)) {
      type = "pdf";
    } else {
      setFileError("Unsupported file format.");
      return;
    }

    const selectedFile = {
      url: url,
      type: type,
      name: targetFile.name,
    };
    setFilesUpload([targetFile]);
    setFilesPreview([selectedFile]);
  };

  const handleNoteChange = (content, editor) => {
    setFormData({ ...formData, note: content });
  };
  const handleImageDlt = (e, index) => {
    e.preventDefault();

    filesUpload.splice(index, 1);
    setFilesUpload([...filesUpload]);

    filesPreview.splice(index, 1);
    setFilesPreview([...filesPreview]);

    if (filesPreview.length === 0) {
      setFileError("");
    }
  };

  const closeModal = () => {
    setHearing(false);
    setFormData({
      note: "",
    });
    closeFn();
  };

  const noteSubmitHandler = async (e) => {
    e.preventDefault();
    const noteContent = formData.note;

    if (!noteContent || noteContent === "" || noteContent === "\n" ) {
      setFileError("Field is required.");
      return;
    }

    let allLinks = [];
    if (filesUpload.length > 0) {
      const formData = new FormData();
      let data_u = [];

      for (let i = 0; i < filesUpload.length; i++) {
        data_u.push(`${uuidv4()}`);
        formData.append("files", filesUpload[i]);
      }
      formData.append("data", data_u);
      formData.append("upload_type", "CitationSupportNotes");
      try {
        setIsLoading(true);
        const uploadResult = await dashboardService.uploadAppealFiles(formData);
        if (uploadResult.data && uploadResult.data.status) {
          allLinks = uploadResult.data.data[0].response?.links?.map(
            (ele) => ele.link
          );
        }

        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
        }
      }
    }
    const citationIds = citation_ids?.join(',');

    var Data = JSON.stringify({ note: noteContent, images: allLinks,citation_id:citationIds });
    try {
      setIsLoading(true);
      const response = await dashboardService.supportNote(Data);
      const tikno = Cookies.get("tikno");
      if (response.data.success) {
        getCitationAlldetails && getCitationAlldetails(tikno);
      }
      setMessage(response.data.message);
      setHearing(true);
      setIsLoading(false);
      setFilesPreview([])
      setFilesUpload([])
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
      }
    }
  };

  const slideImages = filesPreview.map((data) => ({ url: data.url }));

  return (
    <>
      {open && (
        <div className="main-body-modal support-modal">
          {open === true && hearing === false && (
            <div className="main-modal">
              <div className="ctsm-modal-header">
                <div className="cstm-modal-title">
                  <h3>Support Note</h3>
                </div>
                <div className="cstm-close">
                  <button onClick={closeFn}>×</button>
                </div>
              </div>
              <div className="">
                <form onSubmit={noteSubmitHandler}>
                {
            supportModal &&
            <><span className="cancel-title-main">
              Selected Ticket Numbers
            </span><textarea
                type="text"
                className="cstm-snm"
                value={data}
                disabled /><br /></>
          }
                  <div>
                    <span>Enter Note*</span>
                    <br />
                    <QuillEditor
                      value={formData.note}
                      onChange={handleNoteChange}
                      />
                    <span className="errormessage">{fileError}</span>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4">
                      <div className="choose-file-upload" htmlFor="">
                        <span className="choose-file-upload-title">
                          Choose File
                        </span>
                        <input
                          type="file"
                          name="attachments_1"
                          id="attachments"
                          accept=".doc,.docx,application/pdf,.jpeg,.png,.jpg,.pjp,.webp,.apng"
                          onChange={handleUploadMedia}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="cstm-appeal-preview-images cstm-chooose-file-img">
                        {filesPreview.length > 0 && (
                          <div className="appeal-preview-img">
                            <div
                              onClick={() => (
                                setimageId(0),
                                setShowImagesModalSlider(true)
                              )}
                              style={{ cursor: "pointer" }}
                            >
                              {filesPreview[0].type === "image" ? (
                                <img
                                  src={filesPreview[0].url}
                                  alt={`preview-0`}
                                />
                              ) : filesPreview[0].type === "doc" ? (
                                <img src={doc} alt={`preview-0`} />
                              ) : (
                                <img src={pdf} alt={`preview-0`} />
                              )}
                            </div>
                            <button
                              className="cstm-appeal-close"
                              onClick={(e) => handleImageDlt(e, 0)}
                            >
                              ×
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="cstm-submit-btn">
                    <button
                      type="submit"
                      onClick={noteSubmitHandler}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                          style={{margin:"0px"}}
                                        ></span>
                                    ):"Submit"}
                      
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {showImagesModalSlider && (
            <div className="main-body-modal cstm-ticket-details-pop">
              <div className="main-modal">
                <div className="cstm-close">
                  <button onClick={() => setShowImagesModalSlider(false)}>
                    ×
                  </button>
                </div>
                <div className="cstm-modal-body">
                  <div className="modal-body">
                    <div className="text-center cstm-ticket-details-slider">
                      <ImageSlider
                        slides={slideImages}
                        index={imageId}
                        arrow={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {hearing && (
            <div className="main-modal appeal-detail-popup-main">
              <div className="cstm-close">
                <button onClick={closeModal}>×</button>
              </div>
              <div className="cstm-modal-body">
                <div className="modal-body cstm-modal-body">
                  <div className="Success-Message-Hearing">{message}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SupportNoteModal;
