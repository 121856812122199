import React from 'react';
import IntegrationCounts from './IntegrationCount';
import WeeklyAverageSummary from "./WeeklySummary";

function IntegrationDashboard(){     

    return (
        <> 
            <section>
                <div className="container-fluid">
                    <div className="row" > 
                        {/* Content starts */}
                        <div className="col-lg-12 col-md-9 col-sm-12 cstm-ctn cstm-operate-top">
                        <div className="row">
                            <div className='col-12'>
                            <h3 className="cstm-all-pg-ttl operate-ttl">Integration</h3>
                            </div>
                        </div>
                       
                       {/* Integration Counts Logs */}
                          <IntegrationCounts /> 
                       
                        {/* Display Revenue Summary By Violations information */}
                          <WeeklyAverageSummary />

                            </div>
                        </div>
                    </div>
                </section> 
            </>
        );
    }

export default IntegrationDashboard;
