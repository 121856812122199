import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import mainService from "../../../Services/main.service";
import { siteid } from "../../../Global/site";
import { refreshTokenMiddlewareAsync } from "../../customMiddelware";
import { uploadFiles } from "../../Common/utils";
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from "react-toastify";

const EditPermit = ({ permitData, closePermitEdit }) => {
  const [documentImages, setDocumentImages] = useState(permitData?.documents || []);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [carMakeDropDownData, setCarMakeDropDownData] = useState({});
  const [carColorDropDownData, setCarColorDropDownData] = useState({});
  const [stateListData, setStateListData] = useState({});
  const [selectedState, setSelectedState] = useState(permitData?.permit_detail[0]?.state || '');
  const [filteredModels, setFilteredModels] = useState([]);
  const [userDetails, setUserDetails] = useState({
    first_name: permitData?.user_detail.first_name,
    last_name: permitData?.user_detail.last_name,
    address: permitData?.user_detail.address,
    city: permitData?.user_detail.city,
    state: permitData?.user_detail.state,
    zip_code: permitData?.user_detail.zip_code,
    phone_number: permitData?.user_detail.phone_number,
    email: permitData?.user_detail.email,
    user_name: permitData?.user_detail.user_name,
  });
  const [vehicleDetails, setVehicleDetails] = useState({
    lp_number: permitData?.permit_detail[0]?.vehicle_details?.lp_number,
    make: permitData?.permit_detail[0]?.vehicle_details?.make,
    model: permitData?.permit_detail[0]?.vehicle_details?.model,
    year: permitData?.permit_detail[0]?.vehicle_details?.year,
    color: permitData?.permit_detail[0]?.vehicle_details?.color,
  });

  const toastId = useRef(null);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch()

  const permitDataUpdate = {
    "permit_buy_id": permitData?.id,
    "plate_details": [{
      "permit_no": permitData?.permit_detail[0]?.permit_no,
      "vehicle_details": vehicleDetails,
      "state": selectedState
    }],
    "auto_renewal": permitData?.auto_renewal,
    "user_address": userDetails,
    "documents": documentImages,
  };

  useEffect(() => {
    const downloadDocumentImages = async (documents) => {
      const imagesLink = {};
      documents?.forEach(doc => {
        imagesLink[doc.name] = doc.url;
      });
      const imageData = {
        links: Object.assign({}, imagesLink),
        download_type: 'CitationImages',
        site_id: siteid().siteinfo,
      };
      await downloadImage(imageData);
    };

    const downloadImage = async (payload) => {
      try {
        const response = await mainService.downloadImage(payload);
        const newDocumentImages = response.data.metadata;
        setImageUrls(newDocumentImages);
      } catch (error) {
        if (error?.response?.status === 401) {
          await refreshTokenMiddlewareAsync(dispatch);
          await downloadImage(payload);
        }
      }
    };
    if (permitDataUpdate?.documents.length > 0) {
      downloadDocumentImages(permitDataUpdate?.documents);
    }

    const getDropDownData = async () => {
      try {
        const paramsColorList = {
          type: "CarColorList",
          shard: 1,
        };
        const paramsMakeList = {
          type: "MakeModelList",
          shard: 1
        };

        const paramsStateList = {
          type: "StateList",
          shard: 1,
        };

        const promisedRequestArray = [];
        promisedRequestArray.push(
          mainService.getDataForDropDown(paramsColorList),
          mainService.getDataForDropDown(paramsMakeList),
          mainService.getDataForDropDown(paramsStateList),
        );

        Promise.allSettled(promisedRequestArray.map(item => item))
          .then((responseArray) => {
            const dropDownData = {};
            responseArray.forEach((response) => {
              if (response?.status === 'fulfilled' && response?.value?.status === 200 && response?.value?.data?.status) {
                dropDownData[response?.value?.data?.data[0]?.metadata?.type] = response?.value?.data?.data[0]?.response;
              }
            })

            const makeModeList = dropDownData?.MakeModelList;
            const makeList = makeModeList?.map((item) => item.make_full);
            const makeListUnique = [...new Set(makeList)];

            const makeModelGrouped = makeListUnique.map((make) => {
              const models = makeModeList.filter((item) => item.make_full === make);
              return {
                make,
                models
              }
            });
            setCarMakeDropDownData(makeModelGrouped);
            setCarColorDropDownData(dropDownData?.CarColorList);
            setStateListData(dropDownData?.StateList);
          })
          .catch((error) => {
            throw error;
          })
      } catch (error) {
        if (error?.response?.status === 401) {
          await refreshTokenMiddlewareAsync(dispatch)
          await getDropDownData()
        } else {
          setIsLoading(false);
          console.log(error);
        }
      }
    }
    getDropDownData();
  }, [dispatch, documentImages, permitDataUpdate?.documents]);

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (vehicleDetails.make && Object.keys(carMakeDropDownData).length > 0) {
      const selectedMakeData = carMakeDropDownData?.find(make => make.make === vehicleDetails.make);
      setFilteredModels(selectedMakeData ? selectedMakeData.models : []);
    } else {
      setFilteredModels([]);
    }
  }, [carMakeDropDownData, vehicleDetails.make]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const data_array = [];
    const formData = new FormData();
    data_array.push(`${uuidv4()}`);
    formData.append("files", file);
    formData.append("data", data_array);
    formData.append("upload_type", "PermitDocument");

    try {
      // Upload files and show a toast during the upload process
      const upload_result = await uploadFiles(formData, toastId);

      if (upload_result.data?.status) {
        const image_url = upload_result.data?.data?.[0]?.response?.links?.[0]?.link;

        const newDocumentImage = {
          name: file.name,
          type: "Edit Permit Document",
          url: image_url,
          image_spec: data_array[0],
        };

        // Update the document images state
        setDocumentImages((prevImages) => {
          return [...prevImages, newDocumentImage];
        });

        fileInputRef.current.value = "";
      }
    } catch (err) {
      if (err.response.status === 401) {
        await refreshTokenMiddlewareAsync(dispatch);
        await handleFileChange(e); // Retry the upload process
      } else {
        toast.error("Error uploading file. Please try again.");
        console.error(err);
      }
    }
  };

  const handlePermitUpdate = async () => {
    try {
      const permitUpdateResponse = await mainService.updatePermitStatus(permitDataUpdate);
      if (permitUpdateResponse.status === 200 && permitUpdateResponse?.data?.status) {
        toast.success("Permit updated successfully", {
          onClose: closePermitEdit
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        await refreshTokenMiddlewareAsync(dispatch)
        await handlePermitUpdate()
      } else {
        toast.error("Something went wrong")
      }
    }
  }

  return (
    <>
      <ToastContainer />
      {!isLoading ? (
        <section>
          <div className="ctsm-commercial-balance-info">
            <div className="row">
              <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
                <h4>Vehicle Details</h4>
              </div>
            </div>
            <div className="row cstm-row-class">
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>License Plate Number</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='lp_number'
                      defaultValue={vehicleDetails?.lp_number}
                      onChange={(e) => setVehicleDetails({ ...vehicleDetails, lp_number: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Make</label>
                  </div>
                  <div className='c-input'>
                    <select
                      className='form-control'
                      name='make'
                      value={vehicleDetails?.make}
                      onChange={(e) => {
                        setVehicleDetails({ ...vehicleDetails, make: e.target.value })
                      }}
                    >
                      <option value=''>Select Make</option>
                      {Object.keys(carMakeDropDownData).length > 0 && carMakeDropDownData?.map((make, index) => (
                        <option key={index} value={make.make}>{make.make}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Model</label>
                  </div>
                  <div className='c-input'>
                    <select
                      className='form-control'
                      name='model'
                      value={vehicleDetails.model}
                      onChange={(e) => {
                        setVehicleDetails({ ...vehicleDetails, model: e.target.value })
                      }}
                    >
                      <option value=''>Select Model</option>
                      {Object.keys(filteredModels).length > 0 && filteredModels?.map((make, index) => (
                        <option key={index} value={make.model}>{make.model}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Color</label>
                  </div>
                  <div className='c-input'>
                    <select
                      className='form-control'
                      name='color'
                      value={vehicleDetails.model}
                      onChange={(e) => {
                        setVehicleDetails({ ...vehicleDetails, color: e.target.value })
                      }}
                    >
                      <option value=''>Select Color</option>
                      {Object.keys(carColorDropDownData).length > 0 && carColorDropDownData?.map((color, index) => (
                        <option key={index} value={color.description}>{color.description}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>State</label>
                  </div>
                  <div className='c-input'>
                    <select
                      className='form-control'
                      name='state'
                      value={selectedState}
                      onChange={(e) => {
                        setSelectedState(e.target.value)
                      }}
                    >
                      <option value=''>Select State</option>
                      {Object.keys(stateListData).length > 0 && stateListData?.map((state, index) => (
                        <option key={index} value={state.state_abbreviated}>{state.state_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ctsm-commercial-balance-info">
            <div className="row">
              <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
                <h4>User Details</h4>
              </div>
            </div>

            <div className="row cstm-row-class">
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>First Name</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='first_name'
                      defaultValue={userDetails?.first_name}
                      onChange={(e) => setUserDetails({ ...userDetails, first_name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Last Name</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='last_name'
                      defaultValue={userDetails?.last_name}
                      onChange={(e) => setUserDetails({ ...userDetails, last_name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Address</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='address'
                      defaultValue={userDetails.address}
                      onChange={(e) => setUserDetails({ ...userDetails, address: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>City</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='city'
                      defaultValue={userDetails.city}
                      onChange={(e) => setUserDetails({ ...userDetails, city: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>State</label>
                  </div>
                  <div className='c-input'>
                    <select
                      className='form-control'
                      name='state'
                      value={userDetails?.state}
                      onChange={(e) => {
                        setUserDetails({ ...userDetails, state: e.target.value })
                      }}
                    >
                      <option value=''>Select State</option>
                      {Object.keys(stateListData).length > 0 && stateListData?.map((state, index) => (
                        <option key={index} value={state.state_abbreviated}>{state.state_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Zip Code</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='zip_code'
                      defaultValue={userDetails?.zip_code}
                      onChange={(e) => setUserDetails({ ...userDetails, zip_code: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Phone Number</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='phone_number'
                      defaultValue={userDetails?.phone_number}
                      onChange={(e) => setUserDetails({ ...userDetails, phone_number: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>Email</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='email'
                      defaultValue={userDetails?.email}
                      onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='cstm-c-1 form-group formgroup-main'>
                  <div className='c-header'>
                    <label>User Name</label>
                  </div>
                  <div className='c-input'>
                    <input
                      className='form-control'
                      type='text'
                      name='user_name'
                      defaultValue={userDetails?.user_name}
                      onChange={(e) => setUserDetails({ ...userDetails, user_name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ctsm-commercial-balance-info">
            <div className="row">
              <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
                <h4>Documents</h4>
              </div>
            </div>

            <div className="cstm-appeal-status-main-info">
              <div className="cstm-attactment-main image-citation-detail-cstm">
                {(imageUrls || []).map((img, i) => (
                  <div
                    key={i}
                    className="ctsm-attachment-img user-img">
                    <img
                      src={img?.url}
                      alt={`images ${i}`}
                    />
                  </div>
                ))}
              </div>
            </div>

            <button className="auth-cstm-submit-btn" type="button" onClick={handleImageUpload}>
              Upload More Documents
            </button>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>

          <div style={{ margin: '15px' }}>
            <button className="auth-cstm-submit-btn" type="button" onClick={() => closePermitEdit()} style={{ margin: '30px', marginLeft: '350px' }}>
              Close Without Saving
            </button>
            <button className="auth-cstm-submit-btn" type="button" onClick={handlePermitUpdate}>
              Save Changes
            </button>
          </div>
        </section >
      ) : (
        <div className="text-center spin-load-main">
          <div className="spinner-border"></div>
        </div>
      )}
    </>
  );
};

export default EditPermit;