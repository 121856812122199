import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../customMiddelware";
import Multiselect from "multiselect-react-dropdown";

const WarrantStatusModal = ({
  closeFn = () => null,
  open = false,
  cite_id,
}) => {
  const [statusFilter, setStatusFilter] = useState([]);
  var passDataStateList = {
    type: "UpdateCitationStatusData",
    shard: 1,
  };
  const Statelistfun = () => {
    mainService
      .violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status) {
          setStatusFilter(Response.data.data[0].response);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          Statelistfun();
        }
      });
  };
  useEffect(() => {
    Statelistfun();
  }, []);
  const dispatch = useDispatch();

  const [isBtnLoader, setBtnLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [inputs, setInputes] = useState({
    citation_id: cite_id,
    status: "",
    remarks: "",
  });
  const [errors, setErrors] = useState({
    status: "",
    remarks: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputes({ ...inputs, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({ ...errors, [name]: "Enter " + name });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.remarks && inputs.status) {
      setBtnLoader(true);
      ticketstatuschangefun();
      async function ticketstatuschangefun() {
        try {
          const result = await mainService.updateTicketStatusAPI([inputs]);
          if (result.data.response[0].Status) {
            setMessage(result.data.response[0].Message);
            setMessageType(true)
            setInputes({ ...inputs, ["remarks"]: "" });
            setTimeout(() => {
              setMessage("");
            }, 2000);
            closeFn();
          } else {
            setMessage(result.data.response[0].Message);
            setMessageType(false)
          }
          setBtnLoader(false);
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            ticketstatuschangefun();
          } else {
            setErrors({
              ...errors,
              ["remarks"]: err.response.data.message || err.message,
            });
          }
          setBtnLoader(false)
        }
      }
    } else {
      if (inputs.remarks === '') {
        setErrors({ ...errors, ["remarks"]: "Enter remarks" });
      }
      if (inputs.status === '') {
        setErrors({ ...errors, ["status"]: "Select status" });
      }
    }
  };
  return (
    <>
      {open && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className="ctsm-modal-header">
              <div className="cstm-modal-title">
                <h3>Update Status</h3>
              </div>
              <div className="cstm-close">
                <button onClick={closeFn}>×</button>
              </div>
            </div>
            <div className="cstm-modal-body">
              {message && (
                <div className={`alert ${messageType ? 'alert-success' : 'alert-danger'}`} role="alert">
                  {message}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="cstm-inputfield">
                  <span>Status *</span>
                  <select
                    name="status"
                    id="status"
                    className="form-control ar-olny edit-citationonly-select"
                    value={inputs.status}
                    onChange={handleOnChange}
                  >
                    <option value="">Select Status</option>
                    {statusFilter.map((item) => (
                      <option key={item.status} value={item.status}>
                        {item.status}
                      </option>
                    ))}
                  </select>

                  <span className="errormessage">{errors.status}</span>
                </div>
                <div className="cstm-inputfield">
                  <span>Remark*</span>
                  <br />
                  <textarea
                    name="remarks"
                    cols="30"
                    rows="4"
                    value={inputs.remarks}
                    onChange={handleOnChange}
                    placeholder="Write remark"
                  ></textarea>
                  <span className="errormessage">{errors.remarks}</span>
                </div>
                <div className="cstm-submit-btn">
                  {/* <a href="#">
                    <button type="submit">submit</button>
                  </a> */}
                  <button className="btn" type="submit" disabled={isBtnLoader}>
                    {isBtnLoader && (
                      <div className="cstm-loader">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WarrantStatusModal;
