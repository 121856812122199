import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PermitStepComponent from "./buyPermitSteps/permitStepComponent";
import { useNavigate } from "react-router";

const BuyPermitStep = ({setShowStepDetails}) => {
  const navigate = useNavigate()
  const permitDetails = useSelector(state => state.permitReducer);
  const [permitData,setPermitData] = useState()
  const [permitLayoutData,setPermitLayoutData] = useState()

  useEffect(() => {
    if (permitDetails.permitData && permitDetails.permitLayoutData) {
      
      if (permitDetails.permitData) {
        setPermitData(permitDetails.permitData)
      }
      if (permitDetails.permitLayoutData) {
        setPermitLayoutData(permitDetails.permitLayoutData.filter(itm => itm?.validation?.visible === true))
      }
    } else {
      navigate('/permits-option')
    }
  }, [permitDetails])

  return (
    <>
      {permitLayoutData &&
        permitLayoutData.length > 0 && (
          <PermitStepComponent
            permitData={permitData}
            permitLayout={permitLayoutData}
            setShowStepDetails={setShowStepDetails}
            permitName={permitData.name}
            residentType={permitData.resident_type}
          />
        )}
    </>
  )
}

export default BuyPermitStep;