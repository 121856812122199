import moment from "moment";
import { useState } from "react";
import { formatCommercialAmount } from "../../Common/utils";
import Cookies from "js-cookie";

const CommercialTab = ({ messageSuc, commercialData, commercialUpdateData, onChangeCommercialUpdateDatesEvent, onChangeCommercialInput, isBtnLoader, onChangeDateOfCommercial, onUpdateCommercial,showCitationDetails, handleCommercialLateFeeChange,commercialUpdateError,setCommercialUpdateError, showNsfOptions, setShowNsfOptions, showChargeBackOptions, setShowChargeBackOptions}) => {

  const renderLabelAndDateInput = (label, data, onChangeHandler, readOnly, name, className = "col-md-6") => {
    return <div className={className}>
      <div className="cstm-c-1">
        <div className="c-header">
          <label>{label}</label>
        </div>
        <div className="cstm-mail-due-date">
          {/* <input
            type="datetime-local"
            value={commercialUpdateData[name] || data}
            name={name}
            onChange={onChangeHandler}
            readOnly={readOnly}
          /> */}

          <input
            type="datetime-local"
            value={commercialUpdateData[name] || data}
            name={name}
            onChange={onChangeHandler}
            readOnly={readOnly}
            placeholder={commercialUpdateData[name] || data ? "" : "mm/dd/yyyy"} // Placeholder for Safari
          />
        </div>
      </div>
    </div>;
  }

  return (
    <>
      {messageSuc && (
        <div className="alert alert-success" role="alert">
          {messageSuc}
        </div>
      )}

      {/* <div className="box1 cstm-box1-divider-commercial cstm-commercial-devider"></div> */}
      <div className="cstm-commercial-balance-info">
        <div className="row">
          <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
            <h4>Amounts</h4>
          </div>
        </div>

        <div className="row cstm-row-class">
          {renderLabelAndInput("Original Fine", commercialData?.original_amount, onChangeCommercialInput, showCitationDetails && showCitationDetails?.category === "invoice", 'original_amount', commercialUpdateError.original_amount, "text", "col-md-4")}
          {renderLabelAndDateInput("Due Date", commercialData?.due_date, (e) => onChangeCommercialUpdateDatesEvent(e), false, "due_date", "col-md-4")}
          {renderLabelAndDateInput("Paid Date", commercialData?.paid_date, (e) => onChangeCommercialUpdateDatesEvent(e), false, "paid_date", "col-md-4")}

          {renderLabelAndInput("Service Fee", commercialData?.service_fee, null, true)}
          <div className="col-md-8"></div>

        {commercialData?.escalation_detail?.length > 0 && (
        <div className="commercial-escalation">
          <div className="box1 cstm-box1-divider-commercial"></div>
          <div>
            <div className="row">
              <div className="col-12 userdetail-txt1">
                <h4>Escalation Details</h4>
                  </div>
                  </div>
              <div className="row">
                {(
                  commercialData.escalation_detail || []
                ).map((item, i) => (
                  <div
                    key={i}
                    className="col-12 cstm-mail-due-date mb-3"
                  >
                    <div className="row">
                    {item.escalation_status &&
                      <div className="days-col col-4">
                        <label>
                          Late Fee {i + 1}{" "}
                        </label>
                        <br />
                        <div className="c-input">
                        <span className="c-input-span">$</span>
                          <input
                          type="text"
                          defaultValue={formatCommercialAmount(
                            item.late_fee
                          )}
                          onClick={(e) => e.target.value}
                          onKeyPress={(e) => {
                            if (!/^\d*\.?\d*$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => e.preventDefault()}
                          onChange={(e) => handleCommercialLateFeeChange(e,i,item,commercialData.escalation_detail )}
                          />
                          </div>
                        </div>
                    }
                    <div className="days-col col-4">
                      <label>
                        Amount due after{" "}
                        {moment(item.date) ? moment(item.date)
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss") : "-"}
                      </label>
                      <br />
                      <div className="c-input">
                      <span className="c-input-span">$</span>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={formatCommercialAmount(
                            item.amount
                          )}
                          readOnly
                        />
                        </div>
                    </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </div>
      )}

      {commercialUpdateData?.notice_date &&
        Object.keys(commercialUpdateData?.notice_date)
          .length !== 0 && (
          <div className="commercial-escalation">
            <div className="box1 cstm-box1-divider-commercial"></div>
            <div>
              <div className="row">
                <div className="col-12 userdetail-txt1">
                  <h4>Notice Date</h4>
                </div>
                {Object.keys(
                  commercialUpdateData.notice_date
                ).map((key, i) => (
                  <div
                    key={i}
                    className="col-3 cstm-mail-due-date"
                  >
                    <div className="days-col">
                      <label>Notice {i + 1}</label>
                      <br />
                      <input
                        type="datetime-local"
                        value={commercialUpdateData.notice_date[
                          key
                        ].substring(0, 16)}
                        onChange={(e) =>
                          onChangeDateOfCommercial(
                            e,
                            i,
                            key
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
          
          <div className="box1 cstm-box1-divider-commercial"></div>

          {renderLabelAndInput("Boot/Tow Fee", commercialData?.boot_tow_fee, onChangeCommercialInput, false, "boot_tow_fee")}
          {renderLabelAndDateInput("Boot Applied Date", commercialData?.boot_applied_date, onChangeCommercialUpdateDatesEvent, false, "boot_applied_date")}

          {renderLabelAndInput("Collections Fee", commercialData?.collection_fee, onChangeCommercialInput, false, "collection_amount")}
          {renderLabelAndDateInput("Collection Date", commercialData.collection_date, onChangeCommercialUpdateDatesEvent, false, "collection_date")}


          {/* {renderLabelAndInput("Adjust Balance Due", commercialData?.payments, onChangeCommercialInput, false, "balance_due")}
          {renderLabelAndDateInput("Adjusted Balance Date", commercialData.adjusted_balance_date, onChangeCommercialUpdateDatesEvent, false, "adjusted_balance_date")} */}

          {showCitationDetails && showCitationDetails?.category === "invoice" && renderLabelAndInput("Parking Fee", commercialData?.parking_fee, onChangeCommercialInput, false, 'parking_fee')}
          {showCitationDetails && showCitationDetails?.category === "invoice" && renderLabelAndDateInput("Parking Fee Date", commercialData.parking_fee_applied_date, onChangeCommercialUpdateDatesEvent, false, "parking_fee_applied_date")}

          {showCitationDetails && showCitationDetails?.category === "invoice" && renderLabelAndInput("Admin Fee", commercialData?.admin_fee, onChangeCommercialInput, false, 'admin_fee')}
          {showCitationDetails && showCitationDetails?.category === "invoice" && renderLabelAndDateInput("Admin Fee Date", commercialData.admin_fee_applied_date, onChangeCommercialUpdateDatesEvent, false, "admin_fee_applied_date")}

          <div className="col-md-12">
            <input type="checkbox"
              checked={showNsfOptions}
              onChange={() => {
                setShowNsfOptions(!showNsfOptions)
                if (!showNsfOptions) {
                  onChangeCommercialInput({ target: { value: commercialData?.default_nsf_fee, name: "nsf_amount" } })
                } else {
                  onChangeCommercialInput({ target: { value: commercialData?.nsf_fee, name: "nsf_amount" } })
                }
                setCommercialUpdateError({...commercialUpdateError, nsf_amount:""})
              }}
            />
            Add NSF Fees
          </div>

          {showNsfOptions && (
            <>
              {renderLabelAndInput("NSF Fees", commercialData?.nsf_fee === 0 ? commercialData?.default_nsf_fee : commercialData?.nsf_fee, onChangeCommercialInput, false, "nsf_amount",commercialUpdateError.nsf_amount)}
              {renderLabelAndDateInput("NSF Applied Date", commercialData?.nsf_applied_date, onChangeCommercialUpdateDatesEvent, false, "nsf_applied_date")}
            </>
          )}

          <div className="col-md-12">
            <input type="checkbox"
              checked={showChargeBackOptions}
              onChange={() => {
              setShowChargeBackOptions(!showChargeBackOptions);  
              if (!showChargeBackOptions) {
                onChangeCommercialInput({ target: { value: commercialData?.charge_back_fee, name: "charge_back_fee" } })
              } else {
                onChangeCommercialInput({ target: { value: null, name: "charge_back_fee" } })
              }
              setCommercialUpdateError({ ...commercialUpdateError, charge_back_fee: "" })
            }} /> Add Charge Back Fees
          </div>

          {showChargeBackOptions && (
            <>
              {renderLabelAndInput("Charge Back Fees", Number(commercialData?.charge_back_fee) === 0 ? Number(commercialData?.default_charge_back_fee) : Number(commercialData?.charge_back_fee), onChangeCommercialInput, false, "charge_back_fee",commercialUpdateError.charge_back_fee)}
              {renderLabelAndDateInput("Charge Back Applied Date", commercialData?.charge_back_applied_date, onChangeCommercialUpdateDatesEvent, false, "charge_back_applied_date")}
            </>
          )}

          <div className="col-md-12"></div>


          {renderLabelAndInput("Total Due", commercialData?.total_due, null, true)}
        </div >
      </div >
    

      <div className="box1 cstm-box1-divider-commercial"></div>

      <div className="row">
        <div className="col-3 cstm-mail-due-date">
          <div className="days-col">
            <label>DMV hold case no</label>
            <br />
            <input
              type="text"
              name="dmv_case_number"
              value={commercialUpdateData.dmv_case_number}
              onChange={onChangeCommercialInput}
            />
          </div>
        </div>
      </div>

      <div className="box1 cstm-box1-divider-commercial"></div>

      <div className="row">
        <div className="col-md-6 form-group formgroup-main f-main">
          <label htmlFor="">Remark</label>
          <br />
          <textarea
            rows="4"
            cols="50"
            name="remark"
            value={commercialUpdateData.remark}
            onChange={onChangeCommercialInput}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 make-payment-button">
          <button
            className="btn"
            onClick={onUpdateCommercial}
            disabled={isBtnLoader}
          >
            {isBtnLoader && (
              <div className="cstm-loader">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            )}
            Change Amount
          </button>
        </div>
      </div>
    </>
  );
};

export default CommercialTab;

const renderLabelAndInput = (label, data, onChangeHandler, readOnly, name, errorMessage, type = "text", className = "col-md-6") => {
  return <div className={className}>
    <div className="cstm-c-1 form-group formgroup-main">
      <div className="c-header">
        <label>{label}</label>
      </div>
      <div className="c-input">
      <span className="c-input-span">$</span>
        <input
          className="form-control"
          type={type}
          name={name}
          defaultValue={data ? formatCommercialAmount(data) : formatCommercialAmount(0)}
          onChange={(e) => onChangeHandler({ ...e, target: { ...e.target, value: Number(e.target.value), name: e.target.name } })}
          readOnly={readOnly}
          onClick={(e) => readOnly ? e.target.value : e.target.value}
          onKeyPress={(e) => {
            if (!/^\d*\.?\d*$/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onPaste={(e) => e.preventDefault()}
        />
        {errorMessage && errorMessage !== "" && <span className="errormessage">{errorMessage}</span>}
      </div>
    </div>
  </div>;
}


