import { useEffect, useState } from "react";
import BackbuttinIMag from "../../../Images/back.png";

const ResendRo = ({ selectedCitation, ResendRoHandler, backbuttonHandler }) => {
  const [inputValue, setInputValue] = useState([]);

  // set input value for form

  useEffect(() => {
    setInputValue(
      selectedCitation.map((item) => ({
        citation_id: item.id,
        remarks: "",
        ticket_no: item.ticket_no,
      }))
    );
  }, [selectedCitation]);

  // form field edit handler

  const handleFormInputEvent = (e, i) => {
    const { name, value } = e.target;
    inputValue[i][name] = value;

    setInputValue([...inputValue]);
  };

  // form submit handler
  const handleResendROHandler = () => {
    const payload = inputValue.map((item) => ({
      citation_id: item.citation_id,
      remarks: item.remarks,
    }));
    ResendRoHandler(payload);
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
              <div className="search-cstm-main-add">
                <div className="back-button back-button-citationdetails">
                  <a onClick={backbuttonHandler}>
                    <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                    Back
                  </a>
                </div>
                <h3>Resend RO</h3>
                {inputValue.map((item, i) => (
                  <form>
                    <div class="row">
                      <div class="col-12 form-group formgroup-main">
                        <label for="">Ticket No</label>
                        <input
                          class="form-control"
                          value={item.ticket_no}
                          name="ticket_no"
                          readOnly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 form-group formgroup-main">
                        <label for="">Remarks*</label>
                        <textarea
                          class="form-control"
                          placeholder="Write Remark"
                          value={item.remarks}
                          name="remarks"
                          onChange={(e) => handleFormInputEvent(e, i)}
                        />
                      </div>
                    </div>
                    {i !== inputValue.length - 1 && (
                      <div class="row">
                        <div class="col-12">
                          <hr />
                        </div>
                      </div>
                    )}
                  </form>
                ))}
                <div className="cstm-report-edit-component-btn">
                  <button
                    className="edit-citationbtn-btn"
                    type="submit"
                    onClick={() => handleResendROHandler()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResendRo;
