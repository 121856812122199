import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PermitCheckoutComponent from './permitCheckoutComponent';
import dashboardService from '../../../../Services/dashboard.service';
import Cookies from "js-cookie"

const cardInput = {
  card_number: '',
  expiry: '',
  cvc: '',
};

const PermitCheckoutContainer = ({
  stepNo,
  onClickNavigate,
  setShowStepDetails,
  totalSteps,
  permitData,
}) => {
  const dispatch = useDispatch();
  const permitUserData = useSelector(
    state => state.permitReducer.permitUserInfo,
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [refId, setRefId] = useState('');
  const [cardError, setCardError] = useState(cardInput);
  const [cardData, setCardData] = useState(cardInput);
  const [permitInfo, setPermitInfo] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentType, setPaymentType] = useState("OTC");
  const [sourceValue, setSourceValue] = useState({
    amount: "",
    source: "Cash"
  })
  const [sourceError, setSourceError] = useState({
    amount: "",
    source: ""
  })

  useEffect(() => {
    if (location.state) {
      setRefId(location.state.refId);
    }
  }, [location]);

  const getPermitBuId = async refID => {
    setLoader(true);
    try {
      const response = await dashboardService.getBuyPermit(Cookies.get("siteinfo"), refID);
      if (response.data.status) {
        setPermitInfo(response.data.data.permit_buy_detail[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setCardError({
      card_number: '',
      cvc: '',
      expiry: '',
    });
    setCardData({
      card_number: '',
      cvc: '',
      expiry: '',
    });
  }, []);

  useEffect(() => {
    if (refId) {
      getPermitBuId(refId);
    }
  }, [refId]);

  const backHandler = () => {
    Number(stepNo) === 0 ? navigate(-1) : navigate(`#step${Number(stepNo) - 1}`)
    onClickNavigate(`step${Number(stepNo) - 1}`)
  };

  const makePaymentHandler = () => {
    let isValid = true;
    if (paymentType === 'CARD') {
      Object.entries(cardData).forEach(([key, value]) => {
        if (value === '') {
          isValid = false;
          cardError[key] = `Enter ${key.replace(/_/g, ' ')}`;
        }
      });
      setCardError({...cardError});
    } else {
      Object.entries(sourceValue).forEach(([key, value]) => {
        if (value === '') {
          isValid = false;
          sourceError[key] = `Enter ${key.replace(/_/g, ' ')}`;
        }
      });
      setSourceError({...sourceError});
    }

    if (isValid) {
      permitPaymentHandler();
    }
  };

  const permitPaymentHandler = async () => {
    setPaymentLoader(true);

    let payload = '';
    if (paymentType === 'CARD') {
      payload = {
        permit_buy_id: refId,
        payment_method: "CARD",
        card_details: {
          card_number: cardData.card_number.replace(/[ ]+/g, ''),
          expiry: cardData.expiry,
          cvv: cardData.cvc,
        },
      };
    } else {
      payload = {
        permit_buy_id: refId,
        payment_method: "OTC",
        otc_payment_details: {
          source: sourceValue.source,
          amount: Number(sourceValue.amount),
        }
      }
    }

    try {
      const response = await dashboardService.permitPayment(payload);
      if (response.data.status) {
        navigate('/permit-thankyou', {
          state: {
            permit: permitInfo.permit_detail,
            response: response.data.data,
            total_permit_fee: permitInfo?.buy_permit_fee_structure?.total_amount,
            is_approval: permitData?.permit_approval?.auto_approval,
          },
        });
        setPaymentLoader(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err.response.data.message || 'Something Went Wrong.');
    }
    setPaymentLoader(false);
  };

  const SourceChangeHandler = (e) => {
    const { name, value } = e.target;
    if (value !== '') {
      setSourceValue({
        ...sourceValue,
        [name]: value
      });
      setSourceError({
        ...sourceError,
        [name]: ''
      })
    } else {
      setSourceValue({
        ...sourceValue,
        [name]: ''
      });
      setSourceError({
        ...sourceError,
        [name]: `Enter OTC ${name}`
      })
    }
  }

  return (
    <>
      <ToastContainer />
      {loader ? (
        <div className="text-center spin-load-main">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <>
          <PermitCheckoutComponent
            cardError={cardError}
            setCardError={setCardError}
            setCardData={setCardData}
            permitInfo={permitInfo}
            backHandler={backHandler}
            stepNo={stepNo}
            makePaymentHandler={makePaymentHandler}
            paymentLoader={paymentLoader}
            totalSteps={totalSteps}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              SourceChangeHandler={SourceChangeHandler}
              sourceError={sourceError}
              sourceValue={sourceValue}
          />
        </>
      )}
    </>
  );
};

export default PermitCheckoutContainer;
