/* global google */
import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";
import { googlemapsiteid } from "../../../Global/site";
import Cookiesremove from "../../../Cookiesremove";

function Map() {
  const googleid = googlemapsiteid();
  const [activeMarker, setActiveMarker] = useState(null);
  const [count, setCount] = React.useState(0);
  const [showActiveOfficer, setActiveOfficer] = React.useState([]);
  const [showAuthToken, setAuthToken] = useState(false);
  const [showErrorHandling, setErrorHandling] = useState(false)
  const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
  const [showCenterLocation, setCenterLocation] = useState({})

  const closeError = () => {
    setErrorHandling(false)
  }
  const onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  }

  const markers = showActiveOfficer;

  useEffect(() => {
 
    Dashboard.getOfficerRoutes().then(
        (response) => {
            if(response.data.status === 300) {
                setAuthToken(true) 
             }else{
              if(response.data.status === false){
                setErrorHandling(true)
                setErrorHandleMessage(response.data.message)
            }else{
              if(response.data.data[0].metadata.active_officers_lat_lng_list.length !== 0){
               setActiveOfficer(response.data.data[0].metadata.active_officers_lat_lng_list)
               setCenterLocation(response.data.data[0].metadata.active_officers_lat_lng_list[0].location)
                }
              }
            }
          }
    )
    setCount(100);
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  var centerMarker = {
    lat: 40.116386,
    lng: -30.299591
  }

  const handleOnLoad = (map) => {

    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ location }) => bounds.extend(location));
    // map.fitBounds(bounds);

  };

  return (
    <>
      <GoogleMap
        zoom={15}
        center={googleid}
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: "50vw", height: "70vh" }}
      >
        {markers.map((item, index) => (

          <Marker
            key={item.site_officer_id}
            position={item.location}
            onClick={() => handleActiveMarker(item.site_officer_id)}
          >
            {activeMarker === item.site_officer_id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div className="officer-cstm-dt">
                  <div className="map_tbl_ttl">
                    <h4>{item.site_officer_first_name + " " + item.site_officer_last_name}</h4>
                  </div>
                  <div className="location-off-lable">
                    <span className="office_locate_left">
                      Badge ID:
                    </span>
                    <span className="off-loc-name">{item.badge_id} </span>
                  </div>
                  <div className="location-off-lable">
                    <span className="office_locate_left">
                      Squad:
                    </span>
                    <span className="off-loc-name">{item.squad}</span>
                  </div>
                  <div className="location-off-lable">
                    <span className="office_locate_left">
                      Supervisor:
                    </span>
                    <span className="off-loc-name">{item.supervisor}</span>
                  </div>
                  <div className="location-off-lable">
                    <span className="office_locate_left">
                      Last Seen:
                    </span>
                    <span className="off-loc-name">{new Date(item.timestamp).toLocaleString()}</span>
                  </div>

                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>

    

    </>
  );
}

export default Map;
