import { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ArrowLeft from "../../../../Images/arrow-left.png";
import Paperclip from "../../../../Images/paperclip.svg";
import { Link } from "react-router-dom";
import DeleteIcon from "../../../../Images/delete-icon.png";

const PermitRulesTab = ({
  HandlePermitSubmit,
  handleTabChange,
  permitTitle,
  loading,
  payloadData,
  isEditPermit,
}) => {
  const { control, handleSubmit, watch, resetField, reset, setValue } = useForm(
    {
      defaultValues: {
        count_restriction: false,
        count_name: "",
        area_restriction: false,
        zones: "",
        has_guest_pass: false,
        // role_restriction: false,
        role: [],
        permit_approval_restriction: false,
        permit_approval: {
          auto_approval: "",
          notification: {
            name: "",
            enable: false,
          },
          allow_approval_work_flow: false,
          approval_work_flow: false,
          no_renewal_for_open_citation: false,
          allow_transafer: false,
          // no_acceptance_for_open_citation: false,
          allow_quick_renew: false,
          allow_renew: {
            monthly: false,
            daily: false,
            annually: false,
            quarterly: false,
            event: false,
          },
        },
        wait_list_message: "",
        has_wait_list: false,
        allow_cancel: false,
        status: "RULES_STATUS",
        document_required: [""],
        property_manager: "",
        fleet_manager: "",
        motorist: "",
        allow_wait_list_message: false,
      },
    }
  );

  const { fields, append, remove } = useFieldArray({
    name: "document_required",
    control,
  });

  useEffect(() => {
    append("");
    if (isEditPermit) {
      reset(payloadData);

      if (payloadData.status !== "ACTIVE") {
        setValue("status", "RULES_STATUS")
      }

      if (payloadData.permit_counts.per_address !== 0) {
        setValue("count_name", "per_address");
      } else if (payloadData.permit_counts.per_user !== 0) {
        setValue("count_name", "per_user");
      } else if (payloadData.permit_counts.per_agency !== 0) {
        setValue("count_name", "per_agency");
      } else if (payloadData.permit_counts.per_plate !== 0) {
        setValue("count_name", "per_plate");
      } else if (payloadData.permit_counts.per_lot !== 0) {
        setValue("count_name", "per_lot");
      } else if (payloadData.permit_counts.per_district !== 0) {
        setValue("count_name", "per_district");
      }

      setValue('permit_approval_restriction', payloadData.permit_approval_restriction)
      if (payloadData.permit_approval_restriction && payloadData.permit_approval.auto_approval) {
        setValue("permit_approval.auto_approval", "Has auto approve");
      } else if(payloadData.permit_approval_restriction && !payloadData.permit_approval.auto_approval) {
        setValue("permit_approval.auto_approval", "Approval workflow");
      }


      payloadData.role.map((itm) =>
        itm === "Property Manager"
          ? setValue("property_manager", "Property Manager")
          : itm === "Motorist"
          ? setValue("motorist", "Motorist")
          : itm === "Fleet Manager"
          ? setValue("fleet_manager", "Fleet Manager")
          : null
      );

      payloadData.permit_approval.approval_work_flow &&
        setValue("permit_approval.allow_approval_work_flow", []);
      if (
        Object.keys(payloadData.permit_approval.allow_renew).find(
          (key) => payloadData.permit_approval.allow_renew[key] === true
        )
      ) {
        setValue("permit_approval.allow_quick_renew", true);
      }
      if (
        payloadData.wait_list_message &&
        payloadData.wait_list_message !== ""
      ) {
        setValue("allow_wait_list_message", true);
      }
    }
  }, [payloadData]);

  const RuleSubmitHandler = (data) => {
    data.permit_approval.approval_work_flow = [];
    data.role = [];
    if (data.property_manager) {
      data.role.push(data.property_manager);
    }
    if (data.fleet_manager) {
      data.role.push(data.fleet_manager);
    }
    if (data.motorist) {
      data.role.push(data.motorist);
    }

    if (data.permit_approval_restriction && data.permit_approval.auto_approval === "Has auto approve") {
      data.permit_approval.auto_approval = true
      data.permit_approval.approval_work_flow = []
    } else if (data.permit_approval_restriction && data.permit_approval.auto_approval === "Approval workflow") {
      data.permit_approval.approval_work_flow = [];
      data.permit_approval.auto_approval = false
    } else {
      data.permit_approval.auto_approval = false
      data.permit_approval.approval_work_flow = []
    }
    delete data.permit_approval.allow_approval_work_flow;
    delete data.permit_approval.allow_quick_renew;
    delete data.count_name;
    delete data.property_manager;
    delete data.fleet_manager;
    delete data.motorist;
    delete data.allow_wait_list_message;

    HandlePermitSubmit(data);
  };

  const resetCountField = () => {
    if (isEditPermit) {
      setValue("permit_counts.per_address", 0);
      setValue("permit_counts.per_agency", 0);
      setValue("permit_counts.per_plate", 0);
      setValue("permit_counts.per_lot", 0);
      setValue("permit_counts.per_district", 0);
      setValue("permit_counts.per_user", 0);
    } else {
      resetField("permit_counts.per_address");
      resetField("permit_counts.per_agency");
      resetField("permit_counts.per_plate");
      resetField("permit_counts.per_lot");
      resetField("permit_counts.per_district");
      resetField("permit_counts.per_user");
    }
  };

  useEffect(() => {
    if (watch("has_guest_pass") === false) {
      resetField("guest_pass", 0);
    }
  }, [watch("has_guest_pass")]);

  return (
    <>
      <div className={`permit-rules`}>
        <form onSubmit={handleSubmit(RuleSubmitHandler)}>
          <div className="permin-form-main">
            <h3 class="permin-form-title">{permitTitle}</h3>
            <div className="permin-form-main-in">
              <Controller
                name="count_restriction"
                control={control}
                rules={{
                  required:"count restriction is required"
                }}
                render={({ field, fieldState }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Has count restrictions 
                    </label>
                    {fieldState.error && (
                      <span className="errormessage">
                        {fieldState.error.message}
                      </span>
                    )}
                  </div>
                )}
              />
              {watch("count_restriction") && (
                <>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="count_name"
                        control={control}
                        rules={{
                          required:"count name is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label>
                              <input
                                className="permit-field-radio"
                                type="radio"
                                value={"per_user"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  resetCountField();
                                }}
                                checked={field.value === "per_user"}
                              />
                              Per user
                            </label>
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {watch("count_name") === "per_user" && (
                      <div className="permit-rules-list-in-field">
                        <Controller
                          name="permit_counts.per_user"
                          control={control}
                          rules={{
                            required:"count value is required",
                            min: {
                              value: 1,
                              message: "Negative count is not allow",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className="permit-field-rules"
                                type="number"
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(parseInt(e.target.value, 10))
                                }
                                placeholder="Enter Count"
                              />
                              {fieldState.error && (
                                <span className="errormessage">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="count_name"
                        control={control}
                        rules={{
                          required:"count name is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-radio"
                                type="radio"
                                value={"per_address"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  resetCountField();
                                }}
                                checked={field.value === "per_address"}
                              />
                              Per address
                            </label>
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {watch("count_name") === "per_address" && (
                      <div className="permit-rules-list-in-field">
                        <Controller
                          name="permit_counts.per_address"
                          control={control}
                          rules={{
                            required:"count value is required",
                            min: {
                              value: 1,
                              message: "Negative count is not allow",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className="permit-field-rules"
                                type="number"
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(parseInt(e.target.value, 10))
                                }
                                placeholder="Enter Count"
                              />
                              {fieldState.error && (
                                <span className="errormessage">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="count_name"
                        control={control}
                        rules={{
                          required:"count name is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-radio"
                                type="radio"
                                value={"per_agency"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  resetCountField();
                                }}
                                checked={field.value === "per_agency"}
                              />
                              Per agency
                            </label>
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {watch("count_name") === "per_agency" && (
                      <>
                        <div className="permit-rules-list-in-field">
                          <Controller
                            name="permit_counts.per_agency"
                            control={control}
                            rules={{
                              required:"count value is required",
                              min: {
                                value: 1,
                                message: "Negative count is not allow",
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  className="permit-field-rules"
                                  type="number"
                                  value={field.value}
                                  onChange={(e) =>
                                    field.onChange(parseInt(e.target.value, 10))
                                  }
                                  placeholder="Enter Count"
                                />
                                {fieldState.error && (
                                  <span className="errormessage">
                                    {fieldState.error.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="permit-rules-list-in-field2">
                          <Controller
                            name="permit_counts.manager_email"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="permit-field-rules"
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="Manager's Email"
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="count_name"
                        control={control}
                        rules={{
                          required:"count name is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-radio"
                                type="radio"
                                value={"per_plate"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  resetCountField();
                                }}
                                checked={field.value === "per_plate"}
                              />
                              Per plate
                            </label>
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {watch("count_name") === "per_plate" && (
                      <div className="permit-rules-list-in-field">
                        <Controller
                          name="permit_counts.per_plate"
                          control={control}
                          rules={{
                            required:"count value is required",
                            min: {
                              value: 1,
                              message: "Negative count is not allow",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className="permit-field-rules"
                                type="number"
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(parseInt(e.target.value, 10))
                                }
                                placeholder="Enter Count"
                              />
                              {fieldState.error && (
                                <span className="errormessage">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="count_name"
                        control={control}
                        rules={{
                          required:"count name is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-radio"
                                type="radio"
                                value={"per_lot"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  resetCountField();
                                }}
                                checked={field.value === "per_lot"}
                              />
                              Per lot
                            </label>
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {watch("count_name") === "per_lot" && (
                      <div className="permit-rules-list-in-field">
                        <Controller
                          name="permit_counts.per_lot"
                          control={control}
                          rules={{
                            required:"count value is required",
                            min: {
                              value: 1,
                              message: "Negative count is not allow",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className="permit-field-rules"
                                type="number"
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(parseInt(e.target.value, 10))
                                }
                                placeholder="Enter Count"
                              />
                              {fieldState.error && (
                                <span className="errormessage">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="count_name"
                        control={control}
                        rules={{
                          required:"count name is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            {" "}
                            <label>
                              <input
                                class="permit-field-radio"
                                type="radio"
                                value={"per_district"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  resetCountField();
                                }}
                                checked={field.value === "per_district"}
                              />
                              Per district
                            </label>
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {watch("count_name") === "per_district" && (
                      <div className="permit-rules-list-in-field">
                        <Controller
                          name="permit_counts.per_district"
                          control={control}
                          rules={{
                            required:"count value is required",
                            min: {
                              value: 1,
                              message: "Negative count is not allow",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <>
                              <input
                                className="permit-field-rules"
                                type="number"
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(parseInt(e.target.value, 10))
                                }
                                placeholder="Enter Count"
                              />
                              {fieldState.error && (
                                <span className="errormessage">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="permit-rules-list2">
                <div className="permit-rules-list2-label">
                  <Controller
                    name="area_restriction"
                    control={control}
                    render={({ field }) => (
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Has area restrictions 
                      </label>
                    )}
                  />
                </div>
                {watch("area_restriction") && (
                  <>
                    <div className="permit-rules-list2-label-field">
                      <Controller
                        name="zones"
                        control={control}
                        render={({ field }) => (
                          <select
                            className="permit-field-rules"
                            onChange={field.onChange}
                          >
                            <option value="">Select Zone</option>
                            <option value="zone 1">Zone 1</option>
                          </select>
                        )}
                      />
                    </div>
                    <div className="permit-rules-list2-link">
                      <Link className="add-geofence-link">Add geofence</Link>
                    </div>
                  </>
                )}
              </div>

              <div className="permit-rules-list2">
                <div className="permit-rules-list2-label">
                  <Controller
                    name="has_guest_pass"
                    control={control}
                    render={({ field }) => (
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Has guest pass 
                      </label>
                    )}
                  />
                </div>
                <div className="permit-rules-list2-label-field">
                  {watch("has_guest_pass") && (
                    <Controller
                      name="guest_pass"
                      control={control}
                      rules={{
                        min: {
                          value: 1,
                          message: "Negative count is not allow",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <input
                            className="permit-field-rules"
                            type="number"
                            value={field.value}
                            onChange={(e) =>
                              field.onChange(parseInt(e.target.value, 10))
                            }
                            placeholder="Enter Count"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              {/* <Controller
                name="role_restriction"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Role restrictions ?
                    </label>
                  </div>
                )}
              />

              {watch("role_restriction") && (
                <>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="property_manager"
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-checkbox"
                                type="checkbox"
                                value={"Property Manager"}
                                onChange={(e) => field.onChange(e.target.value)}
                                checked={field.value}
                              />
                              Property manager
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="fleet_manager"
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-checkbox"
                                type="checkbox"
                                value={"Fleet Manager"}
                                onChange={(e) => field.onChange(e.target.value)}
                                checked={field.value}
                              />
                              Fleet manager
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="motorist"
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                class="permit-field-checkbox"
                                type="checkbox"
                                value={"Motorist"}
                                onChange={(e) => field.onChange(e.target.value)}
                                checked={field.value}
                              />
                              Motorist
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </>
              )} */}

              <hr className="permit-line" />
              <Controller
                name="permit_approval_restriction"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        class="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Permit approval 
                    </label>
                  </div>
                )}
              />

              {watch("permit_approval_restriction") && (
                <>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-label">
                      <Controller
                        name="permit_approval.auto_approval"
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                className="permit-field-checkbox"
                                type="radio"
                                value={"Has auto approve"}
                                onChange={field.onChange}
                                checked={field.value === "Has auto approve"}
                              />
                              Has auto approve ?
                            </label>
                          </>
                        )}
                      />
                    </div>
                    {watch("permit_approval.auto_approval") === "Has auto approve" && (
                      <>
                        <div class="permit-rules-list-in-field">
                          <Controller
                            name="permit_approval.notification.name"
                            control={control}
                            render={({ field }) => (
                              <>
                                <input
                                  class="permit-field-rules haa-fiels"
                                  type="text"
                                  placeholder="template Name"
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              </>
                            )}
                          />
                        </div>

                        <div class="permit-rules-list-in-field2">
                          <Link className="add-geofence-link" href="#">
                            View
                          </Link>
                          <Link className="add-geofence-link" href="#">
                            Edit
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-labe-full">
                      <Controller
                        name="permit_approval.auto_approval"
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                className="permit-field-checkbox"
                                type="radio"
                                value={"Approval workflow"}
                                onChange={field.onChange}
                                checked={field.value === "Approval workflow"}
                              />
                              Approval workflow
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {watch("permit_approval.auto_approval") === "Approval workflow" && (
                    <div className="permit-rules-list-row3">
                      <div className="permit-rules-list-row3-box">
                        <div className="permit-rules-list-in-labe-full">
                          <Controller
                            name="permit_approval.allow_quick_renew"
                            control={control}
                            render={({ field }) => (
                              <>
                                <label>
                                  <input
                                    className="permit-field-checkbox"
                                    type="checkbox"
                                    value={field.value}
                                    onChange={field.onChange}
                                    checked={field.value}
                                  />
                                  Allow quick renew
                                </label>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {watch("permit_approval.allow_quick_renew") && (
                        <>
                          <div className="permit-rules-list-row3">
                            <div className="permit-rules-list-row3-box">
                              <Controller
                                name="permit_approval.allow_renew.monthly"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <label>
                                      <input
                                        className="permit-field-checkbox"
                                        type="checkbox"
                                        onChange={field.onChange}
                                        checked={field.value}
                                      />
                                      Monthly
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="permit-rules-list-row3">
                            <div className="permit-rules-list-row3-box">
                              <Controller
                                name="permit_approval.allow_renew.annually"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <label>
                                      <input
                                        className="permit-field-checkbox"
                                        type="checkbox"
                                        onChange={field.onChange}
                                        checked={field.value}
                                      />
                                      Annually
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="permit-rules-list-row3-box">
                        <h4 className="permit-title-h4">Documents required </h4>
                        {fields.map((field, index) => (
                          <>
                            <Controller
                              name={`document_required.${index}`}
                              control={control}
                              key={field.id}
                              render={({ field }) => (
                                <div className="documents-required-box">
                                  <div className="documents-required-box-file">
                                    <img
                                      src={Paperclip}
                                      className="permit-link-icon"
                                      alt="documnets"
                                    />
                                    <input
                                      className="permit-field-file"
                                      type="text"
                                      value={field.value}
                                      onChange={field.onChange}
                                    />
                                  </div>
                                  <div className="has-tired-pricing-box-remove">
                                    {index > 0 && (
                                      <button
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <img src={DeleteIcon} alt="remove" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}
                            />
                          </>
                        ))}
                        <div className="document-box-add-more">
                          <button
                            className="add-mobe-button"
                            type="button"
                            onClick={() => append("")}
                          >
                            + Add more
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <hr className="permit-line" />
              <div className="permit-rules-list">
                      <Controller
                        name="permit_approval.no_renewal_for_open_citation"
                        control={control}
                        render={({ field }) => (
                          <>
                            {" "}
                            <label>
                              <input
                                className="permit-field-checkbox"
                                type="checkbox"
                                value={field.value}
                                onChange={field.onChange}
                                checked={field.value}
                              />
                              No renewal if open citation 
                            </label>
                          </>
                        )}
                      />
                  </div>
                  {/* <div className="permit-rules-list-in">
                    <div className="permit-rules-list-in-labe-full">
                      <Controller
                        name="permit_approval.no_acceptance_for_open_citation"
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                className="permit-field-checkbox"
                                type="checkbox"
                                value={field.value}
                                onChange={field.onChange}
                                checked={field.value}
                              />
                              No acceptance if open citation ?
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div> */}
                  
              <Controller
                name="has_wait_list"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Has wait list 
                    </label>
                  </div>
                )}
              />
              {watch("has_wait_list") && (
                <div className="permit-rules-list-in">
                  <div className="permit-rules-list-in-labe-full">
                    <Controller
                      name="allow_motorist_to_view"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <label>
                            <input
                              className="permit-field-checkbox2"
                              type="checkbox"
                              value={field.value}
                              onChange={field.onChange}
                              checked={field.value}
                            />
                            Allow motorist to view
                          </label>
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              )}
              <Controller
                name="allow_wait_list_message"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Has wait list message
                    </label>
                  </div>
                )}
              />
              {watch("allow_wait_list_message") && (
                <>
                  <div className="permit-rules-textarea">
                    <Controller
                      name="wait_list_message"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <textarea
                            className="permit-field-textarea"
                            rows={4}
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Type Message here."
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
              <Controller
                name="allow_cancel"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Allow cancels 
                    </label>
                  </div>
                )}
              />
              <Controller
                name="is_notification_enabled"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Allow opt in and out of notification 
                    </label>
                  </div>
                )}
              />
              <Controller
                name="has_physical_hand_tag"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Has physical hand tag
                    </label>
                  </div>
                )}
              />
              {watch("has_physical_hand_tag") && (
                <div className="permit-rules-list-in">
                  <div className="permit-rules-list-in-labe-full">
                    <Controller
                      name="hand_tag_shipping"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <label>
                            <input
                              className="permit-field-checkbox2"
                              type="checkbox"
                              value={field.value}
                              onChange={field.onChange}
                              checked={field.value}
                            />
                            Optional hand tag shipping
                          </label>
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="permin-form-btn">
            <div className="permin-form-btn-left">
              <button
                className="permin-btn"
                onClick={() => handleTabChange(2)}
                type="button"
              >
                <img src={ArrowLeft} alt="Back" />
                Back
              </button>
            </div>
            <div className="ermin-form-btn-right">
              <button className="permin-btn" type="submit">
                {loading ? <div className="spinner-border"></div> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PermitRulesTab;
