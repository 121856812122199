import ReportTableComponent from "./ReportTableComponent";

export default function ReportComponent({
  loading,
  reports,
  fetchData,
  tableHeadings,
  keySequence,
  editable,
  editPageUrl,
  isImages,
}) {
  return (
    <>
      {
        <ReportTableComponent
          reports={reports}
          fetchData={fetchData}
          tableHeadings={tableHeadings}
          keySequence={keySequence}
          loading={loading}
          editable={editable}
          editPageUrl={editPageUrl}
          isImages={isImages}
        />
      }
    </>
  );
}
