import React, { useState, useEffect } from 'react';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import Image1 from "../../../Images/w1.png";
import Image2 from "../../../Images/w2.png";
import Image3 from "../../../Images/w3.png";
import Image4 from "../../../Images/w4.png";
import close from '../../../Images/close.svg';
import DownloadImg from "../../../Images/download_img_btn.svg";
import Dashboard from "../../../Services/dashboard.service";
import { clearoutAuthUserData } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { refreshTokenMiddleware } from '../../customMiddelware'
import Cookiesremove from '../../../Cookiesremove';
import PrintPdfCsvExcelDownload from '../../Utilies/PrintPdfCsvExcelDownload';


function WeeklyAverageSummary() {
    const dispatch = useDispatch()
    const [count, setCount] = React.useState(0);
    const [showAuthToken, setAuthToken] = useState(false);
    const [showCountValue, setCountValue] = useState([]);
    const [showAverageValues, setAverageValues] = useState([]);
    const [showWeek, setWeek] = useState([]);
    const [showMonthValue, setMonthValue] = React.useState("");
    const [showYearValue, setYearValue] = React.useState("");
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
    const [showDayLable, setDayLable] = React.useState("")
    const [showDownloadOption, setDownloadOption] = useState(false)
    const [showTodayButton, setTodayButton] = useState(true)
    const [showMonthButton, setMonthButton] = useState(false)
    const [showYearButton, setYearButton] = useState(false)

    // When click on Download button
    const onClickDonwloadButton = (e) => {
        e.preventDefault();
        setDownloadOption(!showDownloadOption);
    }

    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    // Download PDF file 
    const donwloadPDFFile = (e) => {
        e.preventDefault();

        var doc = new jsPDF('l', 'pt', 'a4');
        doc.autoTable({ html: '#cstm-dashboard-tb-display' })
        doc.save('Officer-summary.pdf')
    }

    //  Donwload CSV file
    const donwloadCsvFile = (e) => {
        e.preventDefault();
        var csv = [];
        var rows = document.querySelectorAll("table tr");
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++)
                row.push(cols[j].innerText);
            csv.push(row.join(","));
        }
        downloadCSV(csv.join("\n"), "Officer-summary.csv");
    }

    const downloadCSV = (csv, filename) => {
        var csvFile;
        var downloadLink;
        csvFile = new Blob([csv], { type: "text/csv" });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        // Click download link
        downloadLink.click();
    }


    const closeError = () => {
        setErrorHandling(false)
    }

    const onChangeYear = (e) => {

        setTodayButton(false)
        setMonthButton(false)
        setYearButton(true)

        const retriveData = {
            "array_timeline": {
                "type": "annually"
            }
        }
        integrationdatafun()
        function integrationdatafun() {
            Dashboard.getIntegrationSummaryLogs(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setDayLable("Months")
                            setCountValue(response.data.data[0].metadata.table_values)
                            setAverageValues(response.data.data[0].metadata.total_values)
                            setWeek(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    integrationdatafun()
                }
            });
        }
    }

    const onChangeMonth = (e) => {

        setTodayButton(false)
        setMonthButton(true)
        setYearButton(false)

        const retriveData = {
            "array_timeline": {
                "type": "monthly"
            }
        }
        integrationsummaryfun()
        function integrationsummaryfun() {
            Dashboard.getIntegrationSummaryLogs(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setDayLable("Date")
                            setCountValue(response.data.data[0].metadata.table_values)
                            setAverageValues(response.data.data[0].metadata.total_values)
                            setWeek(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'])
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    integrationsummaryfun()
                }
            });
        }

    }

    const OnTodayChart = () => {

        setTodayButton(true)
        setMonthButton(false)
        setYearButton(false)

        const retriveData = {
            "array_timeline": {
                "type": "weekly"
            }
        }
        inegrationlogfun()
        function inegrationlogfun() {
            Dashboard.getIntegrationSummaryLogs(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setDayLable("Days")
                            setCountValue(response.data.data[0].metadata.table_values)
                            setAverageValues(response.data.data[0].metadata.total_values)
                            setWeek(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    inegrationlogfun()
                }
            });
        }
    }

    useEffect(() => {


        const retriveData = {
            "array_timeline": {
                "type": "weekly"
            }
        }
        integrationlog()
        function integrationlog() {
            Dashboard.getIntegrationSummaryLogs(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {

                            setDayLable("Days")
                            setCountValue(response.data.data[0].metadata.table_values)
                            setAverageValues(response.data.data[0].metadata.total_values)
                            setWeek(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    integrationlog()
                }
            });
        }
        setCount(100);
    }, []);

    const getmiliSecondToSecMinHours = function (milliseconds) {

        let hours = milliseconds / (60 * 60);
        let absoluteHours = Math.floor(hours);
        let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        let minutes = (hours - absoluteHours) * 60;
        let absoluteMinutes = Math.floor(minutes);
        let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        let seconds = (minutes - absoluteMinutes) * 60;
        let absoluteSeconds = Math.floor(seconds);
        let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        return h + ':' + m + ':' + s;
    }

    return (
        <>

            <div className="cstm-ave-sun cstm_avg_summary_sect">
                <div className="cstm-ave-sun-top">
                    <div className="cstm-od-rtp-left">Average Summary</div>
                    <div className="cstm-od-rtp-right">
                        <div className="cstm-os-in1"> <button className={`cstm-om-today ${showTodayButton ? 'active' : ''}`} name="today" onClick={OnTodayChart}>Weekly</button>
                        </div>

                        <div className="cstm-os-in1">
                            <button className={`cstm-om-today ${showMonthButton ? 'active' : ''}`} name="monthly" onClick={onChangeMonth}>Monthly</button>
                        </div>

                        <div className="cstm-os-in1">
                            <button className={`cstm-om-today ${showYearButton ? 'active' : ''}`} name="yearly" onClick={onChangeYear}>Yearly</button>
                        </div>

                    </div>
                </div>
                <div className="cstm-tra-sum-weekly">{showDayLable} Average</div>
                <div className="cstm-weekly-ave">
                    <div className="cstm-inte3-in">
                        <div className="cstm-inte3-in-left">
                            <div className="cstm-inte3-in-left-name">Payment</div>
                            {showAverageValues.payment !== "" && showAverageValues.payment !== undefined ?
                                <div className="cstm-inte3-in-leftcont">{showAverageValues.payment}</div>
                                :
                                <div className="cstm-inte3-in-leftcont">-</div>
                            }
                        </div>
                        <div className="cstm-inte3-in-right"><img src={Image1} /></div>
                    </div>
                    <div className="cstm-inte3-in">
                        <div className="cstm-inte3-in-left">
                            <div className="cstm-inte3-in-left-name">Permit</div>
                            {showAverageValues.permit !== "" && showAverageValues.permit !== undefined ?
                                <div className="cstm-inte3-in-leftcont">{showAverageValues.permit}</div>
                                :
                                <div className="cstm-inte3-in-leftcont">-</div>
                            }
                        </div>
                        <div className="cstm-inte3-in-right"><img src={Image2} /></div>
                    </div>
                    <div className="cstm-inte3-in">
                        <div className="cstm-inte3-in-left">
                            <div className="cstm-inte3-in-left-name">Scofflaw</div>
                            {showAverageValues.scofflaw !== "" && showAverageValues.scofflaw !== undefined ?
                                <div className="cstm-inte3-in-leftcont">{showAverageValues.scofflaw}</div>
                                :
                                <div className="cstm-inte3-in-leftcont">-</div>
                            }
                        </div>
                        <div className="cstm-inte3-in-right"><img src={Image3} /></div>
                    </div>
                    <div className="cstm-inte3-in">
                        <div className="cstm-inte3-in-left">
                            <div className="cstm-inte3-in-left-name">Latency</div>
                            {showAverageValues.latency !== "" && showAverageValues.latency !== undefined ?
                                <div className="cstm-inte3-in-leftcont">{getmiliSecondToSecMinHours(showAverageValues.latency)}</div>
                                :
                                <div className="cstm-inte3-in-leftcont">-</div>
                            }
                        </div>
                        <div className="cstm-inte3-in-right"><img src={Image4} /></div>
                    </div>
                </div>

                <div className="data_table_box">
                    <div className="data_tbl_ttl">
                        <h2>Data Table</h2>
                    </div>
                    <div className="data_tbl_btn">

                        {/* <button onClick={onClickDonwloadButton} className="btn cstm_rev_down_btn">Download <img src={DownloadImg} alt="download" />
                            {showDownloadOption ?
                                <>
                                    <div className="cstm-download-icon">
                                        <button onClick={donwloadPDFFile} className="cstm-download-btn-sec">
                                            Pdf
                                        </button>

                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="cstm-download-btn-sec"
                                            table="cstm-dashboard-tb-display"
                                            filename="Officer-summary"
                                            sheet="Officer-summary"
                                            buttonText="Excel"
                                        />
                                        <button onClick={donwloadCsvFile} className="cstm-download-btn-sec">
                                            CSV
                                        </button>
                                    </div>
                                </>
                                :
                                ""
                            }
                        </button> */}
                        <PrintPdfCsvExcelDownload
                          tableId="tbl-main-download"
                          fileName="Officer-summary"
                          pageSize="a2"
                          showPrint={false}
                        />
                    </div>
                </div>

                <div 
                    className="report_tbl_scroll"
                    id="tbl-main-download"
                >
                    <table
                        className="table table-heading table-bordered"
                        id="cstm-report-tb-display"
                    >
                        <thead>
                            <tr>
                                <th className="srt-tab w-2"> Days </th>
                                <th className="srt-tab w-2"> Payment </th>
                                <th className="srt-tab w-2"> Permit </th>
                                <th className="srt-tab w-2"> Scofflaw </th>
                                <th className="srt-tab w-2"> Latency </th>
                            </tr>
                        </thead>
                    <tbody>
                    {showCountValue.map((item, index) => (
                        <tr>
                            <td>{showWeek[index] ?? "-"}</td>
                            <td>{item.payment ?? "-"}</td>
                            <td>{item.permit ?? "-"}</td>
                            <td>{showCountValue[index].scofflaw ?? "-"}</td>
                            <td>{getmiliSecondToSecMinHours(item.latency) ?? "-"}</td>
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
            </div>



        </>
    );
}

export default WeeklyAverageSummary;
