import React, { useEffect, useState } from "react";
import OperationTotalCount from "../DashboardModule/MainDashboard/TodayOperationCount";
import { useDispatch, useSelector } from "react-redux";
import { refreshTokenMiddleware } from '../customMiddelware'
import mainService from "../../Services/main.service";
import { Link,useNavigate } from "react-router-dom";
import { updatesiteinfo } from "../../actions/auth";


const SiteList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { agencyS } = useSelector(state => state.auth)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        agencieslistfun()
        setLoader(true)
        function agencieslistfun() {
            mainService.agencieslistAPI().then(res => {
                if (res.data.status) {
                    setLoader(false)
                    var newArray = res.data.data.agency_map.filter(function (el) {
                      return el.agency_data.agency_id === agencyS.agency_id
                    });
                    if(newArray[0].site_data.length === 1) {
                      dispatch(updatesiteinfo(newArray[0].site_data[0]))
                      navigate('/dashboard')
                    } else {
                      setData(newArray[0].site_data)
                    }
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    agencieslistfun()
                }
            })
        }
    }, [])

    const chooseAnySite = (e, data) => {
        e.preventDefault()
        dispatch(updatesiteinfo(data))
        navigate('/dashboard')
    }

    return (
      <>
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 left-panel-side right-panel cstm-right-panel cstm-site-listing-sec">
          <div className="appeal-report-heading">
            <h4>All Sites</h4>
          </div>
          {loader ? (
            <div className="site-list-spinner">
              <div className="spinner-border"></div>
            </div>
          ) : data === null || data?.length === 0 ? (
            <div className="cstm-no-record-found">{"No Record Found"}</div>
          ) : (
            (data || []).map((item, i) => (
              <div key={i}>
                <div
                  className="cstm-all-site-heading"
                  onClick={(e) => chooseAnySite(e, item)}
                >
                  {item.site_name}
                </div>
                <OperationTotalCount siteid={item.site_id} />
              </div>
            ))
          )}
        </div>
      </>
    );
}

export default SiteList