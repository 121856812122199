
import { useEffect } from "react";
import { useState, Fragment } from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageSlider = ({ slides, index, arrow }) => {
    const [currentIndex, setCurrentIndex] = useState(index);
    const goToPrevious = () => {
        if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1)
        } else {
            setCurrentIndex(slides.length - 1)
        }
    }
    
    const goToNext = () => {
        if (currentIndex + 1 === slides.length) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(currentIndex + 1)
        }
    }

    useEffect(() => {
        setCurrentIndex(index)
    }, [index])

    return (
        <div >
            {!arrow && <><button className="cstm-td-cmn-arrow cstm-td-arrow-left " onClick={() => goToPrevious()}>{'<'}</button>
                <button className="cstm-td-cmn-arrow cstm-td-arrow-right" onClick={() => goToNext()}>{'>'}</button></>}
            <div>
                <TransformWrapper>
                    {({ zoomIn, zoomOut }) => (
                        <Fragment>
                            <div className="tools cstm-mines-plus-tools">
                                <button className="cstm-td-tools-arrow cstm-td-tools-left" onClick={() => zoomIn()} >+</button>
                                <button className="cstm-td-tools-arrow cstm-td-tools-right" onClick={() => zoomOut()}>-</button>
                            </div>
                            <TransformComponent>
                                <img src={slides[currentIndex]?.url} alt="citation" />
                            </TransformComponent>
                        </Fragment>
                    )}
                </TransformWrapper>
            </div>
        </div>
    )
}

export default ImageSlider