import React, { useState } from 'react';
import dropdownarrow from '../Images/dropdown-arrow.svg';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

// in component file put below pagination

const Pagination = ({ handlePaginationChange, totalRecords, pageLimit, currentPage, pageNeighbours = 1, dropdownLimit = true }) => {
    const [pageLimitP, setPageLimitP] = useState(pageLimit)
    const totalRecordsP = typeof totalRecords === 'number' ? totalRecords : 0;

    const pageNeighboursP = typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    const totalPagesP = Math.ceil(totalRecords / pageLimit);
    const [currentPageP, setCurrentPageP] = useState(currentPage)


    const goToPageHandle = (page, limit = pageLimitP) => {

        const t_page = Math.ceil(totalRecords / limit);
        const currentPage = Math.max(0, Math.min(page, t_page));
        const skip_pr = (page - 1) * limit

        setPageLimitP(limit)
        setCurrentPageP(currentPage)

        const data = {
            currentPage: page,
            pageLimit: limit,
            skip: skip_pr,
            totalPages: t_page
        }
        handlePaginationChange(data)
    }

    const handlePerPageLimit = (e) => {
        e.preventDefault()
        goToPageHandle(1, e.target.value)
    }
    const handlePageChange = (e, page) => {
        e.preventDefault()
        goToPageHandle(page)
    }
    const handleMoveLeft = (e) => {
        e.preventDefault()
        goToPageHandle(currentPageP - (pageNeighboursP * 2) - 1)
    }
    const handleMoveRight = (e) => {
        e.preventDefault()
        goToPageHandle(currentPageP + (pageNeighboursP * 2) + 1)
    }
    const handlePreBtn = e => {
        e.preventDefault();
        goToPageHandle(currentPageP - 1);
    }
    const handleNextBtn = e => {
        e.preventDefault();
        goToPageHandle(currentPageP + 1);
    }



    const fetchPageNumbers = () => {

        const totalPages = totalPagesP;
        const currentPage = currentPageP;
        const pageNeighbours = pageNeighboursP;

        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {

            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    }

    const pages = fetchPageNumbers()

    return (
        <>
            <div className='pg-pagination-main'>
                <div>
                    <p>Total <b>{totalRecordsP}</b> Records Found</p>
                </div>
                {dropdownLimit ?
                    <div className="cstm-right-lable-pn-val">
                        Number of records per page
                        <select className="select-pagination-val" value={pageLimitP} onChange={handlePerPageLimit}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <img src={dropdownarrow} alt="dropdownarrow" />
                    </div>
                    :
                    <div className="cstm-right-lable-pn-val">
                        Number of records per page
                        <select className="select-pagination-val" value={pageLimitP} onChange={handlePerPageLimit}>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={500}>500</option>
                            <option value={1000}>1000</option>
                        </select>
                        <img src={dropdownarrow} alt="dropdownarrow" />
                    </div>
                }
                {(!totalRecords) ?
                    ""
                    :
                    <nav aria-label="Countries Pagination">
                        <ul className="pagination">
                            <li className={`page-item ${currentPageP === 1 ? 'disabled' : ''}`}>
                                <button className="page-link cstm-link" onClick={handlePreBtn}>{"Prev"}</button>
                            </li>
                            {pages.map((page, index) => {
                                if (page === LEFT_PAGE) return (
                                    <li key={index} className="page-item">
                                        <button className="page-link cstm-link" aria-label="Previous" onClick={(e) => handleMoveLeft(e)}>
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </button>
                                    </li>
                                );
                                if (page === RIGHT_PAGE) return (
                                    <li key={index} className="page-item">
                                        <button className="page-link cstm-link" aria-label="Next" onClick={(e) => handleMoveRight(e)}>
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </button>
                                    </li>
                                );
                                return (
                                    <li key={index} className={`page-item${currentPageP === page ? ' active disable' : ''}`}>
                                        <button className="page-link cstm-link" onClick={(e) => handlePageChange(e, page)}>{page}</button>
                                    </li>
                                );
                            })}
                            <li className={`page-item ${currentPageP === totalPagesP ? 'disabled' : ''}`}>
                                <button className="page-link cstm-link" onClick={handleNextBtn}>{"Next"}</button>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
        </>
    )
}

export default Pagination;
