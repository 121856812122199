import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import transfer from '../../../Images/transfer.png';
import HearingReportTable from './ReportTable';
import Pagination from '../../../utile/pagination';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import dropdownarrow from '../../../Images/dropdown-arrow.svg';
import { changetotal } from '../../../Global/site';

const HearingReporting = ({
  reportData,
  tableHeading,
  keySequence,
  totalRecords,
  itemsPerPage,
  handlePaginationChange,
  currentPage,
  noRecordFound,
  getHearing,
  loading,
  appealStatus,
}) => {
  const [hearingStatus, setHearingStatus] = useState({ hearing_status: [] });
  const [ticketNo, setTicketNo] = useState('');
  const changeTotal = changetotal();
  const [filterDate, setFilterDate] = useState({
    created_ts_from: '',
    created_ts_to: '',
    hearing_ts_from: '',
    hearing_ts_to: '',
  });


  const onChangeFilterDate = (date, name) => {
    const newFilterDate = { ...filterDate };
    if (!date) {
      if (name === 'hearing_ts_to') {
        newFilterDate['hearing_ts_to'] = "";
      } else if (name === 'created_ts_to') {
        newFilterDate['created_ts_to'] = "";
      }
      setFilterDate(newFilterDate);
      return;
    }

    newFilterDate[name] = date;
    const timeValue = new Date(date);
    const hours = timeValue.getHours();
    const minutes = timeValue.getMinutes();

    if (name === 'hearing_ts_to') {
      const isDateChange = !filterDate?.hearing_ts_to || (hours === 0 && minutes === 0) ||
        date.getDate() !== filterDate.hearing_ts_to?.getDate();

      if (isDateChange) {
        let formattedDate = new Date(date);
        formattedDate.setHours(23, 59, 59, 999);
        newFilterDate['hearing_ts_to'] = formattedDate;
      }
    } else if (name === 'created_ts_to') {
      const isDateChange = !filterDate?.created_ts_to ||
        (hours === 0 && minutes === 0) ||
        date.getDate() !== filterDate.created_ts_to?.getDate();

      if (isDateChange) {
        let formattedDate = new Date(date);
        formattedDate.setHours(23, 59, 59, 999);
        newFilterDate['created_ts_to'] = formattedDate;
      }
    }

    setFilterDate(newFilterDate);
  };
  const onChangeSearchFilter = e => {
    const { name, value } = e.target;

    if (name === 'ticket_no') {
      setTicketNo(value);
    }
  };
  const onChangeStateSelect = (selectedList, name) => {
    setHearingStatus({ ...hearingStatus, [name]: selectedList });
  };

  const handleFilterSubmit = e => {
    e.preventDefault();
    const page = 'page=' + currentPage;
    const limit = '&limit=' + itemsPerPage;
    if (filterDate.created_ts_to !== '') {
      // const formattedDate = moment(inputDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      var startDate =
        '&created_ts_to=' +
        moment(filterDate.created_ts_to).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      startDate = '';
    }
    if (filterDate.created_ts_from !== '') {
      var endDate =
        '&created_ts_from=' +
        moment(filterDate.created_ts_from).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      endDate = '';
    }
    if (filterDate.hearing_ts_from !== '') {
      var hearingStartDate =
        '&hearing_ts_from=' +
        moment(filterDate.hearing_ts_from).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      hearingStartDate = '';
    }
    if (filterDate.hearing_ts_to !== '') {
      var hearingEndDate =
        '&hearing_ts_to=' +
        moment(filterDate.hearing_ts_to).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      hearingEndDate = '';
    }
    if (ticketNo !== '') {
      var ticket_no = '&ticket_no=' + ticketNo;
    } else {
      ticket_no = '';
    }
    if (hearingStatus.hearing_status.length > 0) {
      var hearing =
        '&hearing_status=' +
        hearingStatus.hearing_status
          .map(item => item.hearing_status)
          .toString();
    } else {
      hearing = [];
    }
    const finalDataSearchString =
      page +
      limit +
      startDate +
      endDate +
      hearing +
      hearingStartDate +
      hearingEndDate +
      ticket_no;

    getHearing(finalDataSearchString);
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
              <div className="cstm-ticket-manage-sec">
                <div className="cstm-ticket-manage-heading">
                  <h3 className="cstm-all-pg-ttl operate-ttl">
                    Hearing Management
                  </h3>
                </div>
              </div>
              <div className="report-searchbar">
                <form onSubmit={handleFilterSubmit}>
                  {/* test */}
                  <div>
                    <span>Hearing Created Date</span>
                    <div className="report-date report-mr">
                      <DatePicker
                        showTimeSelect
                        timeIntervals={1}
                        placeholderText="MM/DD/YYYY HH:MM"
                        dateFormat="MM/dd/yyyy h:mmaa"
                        timeFormat="HH:mm"
                        className="cstm-pull-details schedule-date-input"
                        name="hearing_ts_from"
                        selected={filterDate.hearing_ts_from}
                        autoComplete="off"
                        onChange={date =>
                          onChangeFilterDate(date, 'hearing_ts_from')
                        }
                      />
                      <img
                        src={transfer}
                        alt="transfer"
                        className="date-transfer"
                      />
                      <DatePicker
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy h:mmaa"
                        timeFormat="HH:mm"
                        placeholderText="MM/DD/YYYY HH:MM"
                        className="cstm-pull-details schedule-date-input"
                        name="hearing_ts_to"
                        selected={filterDate.hearing_ts_to}
                        autoComplete="off"
                        onChange={date =>
                          onChangeFilterDate(date, 'hearing_ts_to')
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <span>{changeTotal} Created Date</span>
                    <div className="report-date report-mr">
                      <DatePicker
                        // selectsStart
                        showTimeSelect
                        timeIntervals={1}
                        placeholderText="MM/DD/YYYY HH:MM"
                        dateFormat="MM/dd/yyyy h:mmaa"
                        timeFormat="HH:mm"
                        className="cstm-pull-details schedule-date-input"
                        name="created_ts_from"
                        selected={filterDate.created_ts_from}
                        autoComplete="off"
                        onChange={date =>
                          onChangeFilterDate(date, 'created_ts_from')
                        }
                      />
                      <img
                        src={transfer}
                        alt="transfer"
                        className="date-transfer"
                      />
                      <DatePicker
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy h:mmaa"
                        timeFormat="HH:mm"
                        placeholderText="MM/DD/YYYY HH:MM"
                        className="cstm-pull-details schedule-date-input"
                        name="created_ts_to"
                        selected={filterDate.created_ts_to}
                        autoComplete="off"
                        onChange={date =>
                          onChangeFilterDate(date, 'created_ts_to')
                        }
                      />
                    </div>
                  </div>

                  <div className="report-licence select-drop-arrow report-mr mt-4">
                    <Multiselect
                      name="hearing_status"
                      selectedValues={filterDate.hearing_status}
                      displayValue="hearing_status"
                      placeholder="Select status"
                      onRemove={e => onChangeStateSelect(e, 'hearing_status')}
                      onSelect={e => onChangeStateSelect(e, 'hearing_status')}
                      options={
                        appealStatus &&
                        appealStatus
                          .filter(item => item.type === 'HEARING_STATUS')
                          .map((item, index) => ({
                            hearing_status: item.title,
                            code: item.id,
                          }))
                      }
                      showCheckbox
                    />
                    <img src={dropdownarrow} alt="dropdownarrow" />
                  </div>
                  <div className="report-licence report-mr mt-4">
                    <div>
                      <input
                        className="license-plate"
                        name="ticket_no"
                        value={ticketNo}
                        onChange={e => onChangeSearchFilter(e)}
                        type="text"
                        placeholder={`Search ${changeTotal}`}
                      />
                    </div>
                  </div>
                  <button
                    className="licence-print report-mr mt-4"
                    type="submit">
                    Submit
                  </button>
                </form>
              </div>
              {loading ? (
                <div className="text-center spin-load-main">
                  <div className="spinner-border"></div>
                </div>
              ) : noRecordFound ? (
                <div className="no-record-found-main">No Record Found</div>
              ) : (
                <>
                  <div className="ctsm-table-scroll">
                    <div className="table-responsive">
                      <div className="">
                        <HearingReportTable
                          reportData={reportData}
                          tableHeading={tableHeading}
                          keySequence={keySequence}
                        />
                      </div>
                    </div>
                  </div>
                  {noRecordFound !== true && (
                    <>
                      <Pagination
                        handlePaginationChange={handlePaginationChange}
                        totalRecords={totalRecords}
                        pageLimit={itemsPerPage}
                        pageNeighbours={1}
                        currentPage={currentPage}
                        dropdownLimit={false}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HearingReporting;
