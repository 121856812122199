import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import dashboardService from "../../../Services/dashboard.service";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useLocation } from "react-router";
import ConfirmationModalComponent from "../../../utile/modals/confirmationModal";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const ManagerReviewTab = ({
  isBtnLoader,
  setModalOpen,
  setModalMsg,
  setBtnLoader,
  workFlowPage,
  getCitationDetailsAPI,
  remarkInputs,
  remarkErrors,
  setRemarkInputs,
  setRemarkErrors,
  workflowstatus,
  appealid,
  setMessageSuc,
  setwysiwygTextMR,
  getAppealDetailedInfo,
}) => {

  const isMansField = () => {
    if (Cookies.get('siteinfo') === 'c293c922-fa4c-11ed-b4ca-ae854bfaee9a' || Cookies.get('siteinfo') === '98ccd4bc-fa4c-11ed-8b64-665980bd7c45') {
      return true
    } else {
      return false
    }
  }

  const isHPA = () => {
    if (
      Cookies.get('siteinfo') === '0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d' ||
      Cookies.get('siteinfo') === '5bd20490-dc2f-11ee-bf9e-72127457a419'
    ) {
      return true
    } else {
      return false
    }
  }
  const isRemarkReasonAllow = (appeal_status, hearing_status) => {
    if (
      appeal_status === "Appeal Rejected" || appeal_status === "Appeal Upheld" || hearing_status === "Hearing Rejected (Ticket Valid)" || hearing_status === "Hearing Upheld (Ticket Cancelled)" || 
      appeal_status === "Appeal Upheld (Ticket Dismissed)"
    ) {
      return true
    } else {
      return false
    }
  }


  const location = useLocation().search;
  const citation_Number = new URLSearchParams(location).get("citation_no");
  const dispatch = useDispatch();
  const refM = useRef();

  const [appealStatus, setAppealStatus] = useState([]);
  const [showStatusWarning, setShowStatusWarning] = useState(false);
  const [isSelected, setIsSelected] = useState(false)
  const [reasons, setReasons] = useState([]);
  const [reasonsVal, setReasonsVal] = useState("");
  const [reasonIndex,setReasonIndex] = useState()
  const [changeReasonsVal, setChangeReasonsVal] = useState("");


  const handleCloseConfirmationModal = () => {
    setShowStatusWarning(false);
  };
  const handleConfirm = () => {
    setShowStatusWarning(false);
    if (workFlowPage === "Appeal management" && workflowstatus !== undefined && (workflowstatus === "Manager Review" || workflowstatus === "Closed" || workflowstatus === "Board Review" || workflowstatus === "Commercial")) {
      let isError = false;
      if (reasonsVal === "" && !isMansField() && remarkInputs.remark === "") {
        isError = true;
        remarkErrors.remark = "Enter remark.";
      }
      if (reasonsVal !== "") {
        remarkInputs.remark = reasonsVal
      }
      if (remarkInputs.appeal_status === "" && !remarkInputs.is_internal) {
        isError = true;
        remarkErrors.appeal_status = "Select appeal status.";
      }
      setRemarkErrors({ ...remarkErrors });
      if (!isError) {
        appealsearveyupdateremark1();
        async function appealsearveyupdateremark1() {
          setBtnLoader(true);
          try {
            let payload = { ...remarkInputs };
            payload.workflow_status = workflowstatus;

            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextMR("");
              getAppealDetailedInfo();

              setRemarkInputs({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
              setBtnLoader(false);
            }
          } catch (err) {
            if (err.data.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark1();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
            setBtnLoader(false);
          }
        }
      }
    }
  }

  async function getAppealStatusData() {
    try {
      const result = await dashboardService.appealStatusListAPI();
      setAppealStatus(result.data.data);
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealStatusData();
      }
    }
  }

  useEffect(() => {
    getAppealStatusData();
  }, []);

  const onChangeReviewInputs = (e, pl) => {
    const { name, value } = e.target;
    if (pl === "tab_1") {
      if (name !== "is_internal") {
        setReasonsVal("")
      }
      if (isMansField()) {
        switch (name) {
          case "hearing_status":
            remarkErrors.hearing_status = value ? "" : "Select appeal status";
            break;
          default:
            break;
        }
      } else {
        switch (name) {
          case "remark":
            remarkErrors.remark = value ? "" : "Enter remark";
            break;
          case "hearing_status":
            remarkErrors.hearing_status = value ? "" : "Select appeal status";
            break;
          default:
            break;
        }
      }
      setRemarkErrors({ ...remarkErrors });
      if (name === "is_internal") {
        setRemarkInputs({ ...remarkInputs, [name]: e.target.checked });
      } else {
        if (name === "hearing_status") {
          setRemarkInputs({
            ...remarkInputs,
            [name]: value,
          });
        } else {
          setRemarkInputs({ ...remarkInputs, [name]: value });
        }
      }
    }
  };

  const handleReviewSubmit = async (e, workFlowPage) => {
    e.preventDefault();
    if (workFlowPage === "Hearing Management") {
      let isError = false;
      if (reasonsVal === "" && !isMansField() && remarkInputs.remark === "") {
        isError = true;
        remarkErrors.remark = "Enter Remark.";
      }
      setRemarkErrors({ ...remarkErrors });
      if (reasonsVal !== "") {
        remarkInputs.remark = reasonsVal
      }

      if (!isError) {
        if (
          remarkInputs.hearing_status === "Appeal Upheld" ||
          remarkInputs.hearing_status === "Appeal Partially Upheld" ||
          remarkInputs.hearing_status === "Appeal Upheld (Ticket Dismissed)" ||
          remarkInputs.hearing_status === "Appeal Rejected" ||
          remarkInputs.hearing_status === "Closed"
        ) {
          setShowStatusWarning(true);
          isError = true;
        }
      }

      if (!isError) {
        appealsearveyupdateremark1();
        async function appealsearveyupdateremark1() {
          setBtnLoader(true);
          try {
            let payload = { ...remarkInputs };
            const result =
              await dashboardService.updateHearingSurveyorAddRemarkAPI(
                citation_Number,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setRemarkInputs({
                hearing_status: "",
                remark: "",
                additional_remark: "",
              });
              getCitationDetailsAPI(citation_Number);
              setBtnLoader(false);
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark1();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
            setBtnLoader(false);
          }
        }
      }
    }
    else if (workFlowPage === "Appeal management") {
      
      let isError = false;
      if (reasonsVal === "" && !isMansField() && remarkInputs.remark === "") {
        isError = true;
        remarkErrors.remark = "Enter Remark.";
      }
      setRemarkErrors({ ...remarkErrors });
      if (reasonsVal !== "") {
        remarkInputs.remark = reasonsVal
      }
      if (remarkInputs.appeal_status === "" && !remarkInputs.is_internal) {
        isError = true;
        remarkErrors.appeal_status = "Select appeal status.";
      }
      setRemarkErrors({ ...remarkErrors });

      if (!isError) {
        if (remarkInputs.appeal_status === "Appeal Upheld" ||
          remarkInputs.appeal_status ===
          "Appeal Partially Upheld" ||
          remarkInputs.appeal_status === "Appeal Rejected" ||
          remarkInputs.appeal_status === "Appeal Upheld (Ticket Dismissed)" ||
          remarkInputs.appeal_status === "Closed") {
          setShowStatusWarning(true);
          isError = true
        }
      }

      if (!isError) {
        appealsearveyupdateremark1();
        async function appealsearveyupdateremark1() {
          setBtnLoader(true);
          try {
            let payload = { ...remarkInputs };
            payload.workflow_status = workflowstatus;

            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextMR("");
              getAppealDetailedInfo();

              setRemarkInputs({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
              setBtnLoader(false);
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark1();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
            setBtnLoader(false);
          }
        }
      }
    }
  };
  const payload = remarkInputs.appeal_status === "Appeal Upheld" || remarkInputs.appeal_status === "Appeal Rejected" || 
  remarkInputs.appeal_status === "Appeal Upheld (Ticket Dismissed)";

  const payloadHearing = remarkInputs.hearing_status === "Hearing Rejected (Ticket Valid)" || remarkInputs.hearing_status === "Hearing Upheld (Ticket Cancelled)";

  const SelectedReasons = async () => {
    console.log("called")
    try {
      const response = await dashboardService.selectReason(`type=${payload ? remarkInputs.appeal_status : payloadHearing ? remarkInputs.hearing_status : ""}`)
      setReasons(response.data.data.remarks)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if(payload || payloadHearing){
      SelectedReasons()
    }
  }, [remarkInputs])

  const handleCloseModel = () => {
    setIsSelected(false)
  }
  const handleOpeneModel = (e) => {
    e.preventDefault();
    setIsSelected(true)
  }
  const handleChange = (e, index) => {
    const {name,value}=e.target
    setChangeReasonsVal(value)
    setReasonIndex(index)
  }
  const handleSubmit=()=>{
    setReasonsVal(changeReasonsVal)
    setIsSelected(false)
  }

  return (
    <>
      <div className="cstm-manager-review-main2 cstm-manager-review-main-inner cstm-manager-review-xs">
        <div className={`cstm-manager-review-main2-lefts ${workFlowPage === "Appeal management" ? "" : "cstm-hearing-remark"}`}>
          <form
            ref={refM}
            onSubmit={(e) => handleReviewSubmit(e, workFlowPage)}
          >
            <div className="cstm-manager-review-main2-title">Remark</div>
            <div className="col-12 form-group formgroup-main f-main2">
              <label htmlFor="">{workFlowPage === "Appeal management" ? "Appeal " : "Hearing "} Status *</label>
              <br />
              <select
                className="state-droupdwon ar-olny"
                value={workFlowPage === "Appeal management" ? remarkInputs.appeal_status : remarkInputs.hearing_status}
                name={workFlowPage === "Appeal management" ? "appeal_status" : "hearing_status"}
                onChange={(e) => onChangeReviewInputs(e, "tab_1")}
              >
                <option value={""}>Select status</option>
                {workFlowPage === "Appeal management" ? (appealStatus || []).filter((item) => item.type !== "HEARING_STATUS").map((item, i) => (
                  <option
                    key={i}
                    value={item.title}
                    selected={remarkInputs.appeal_status === item.title}
                  >
                    {item.title}
                  </option>
                )) : (appealStatus || []).filter((item) => item.type === "HEARING_STATUS")
                  .map((item, i) => (
                    <option
                      key={i}
                      value={item.title}
                      selected={remarkInputs.hearing_status === item.title}
                    >
                      {item.title}
                    </option>
                  ))}
              </select>
              <span className="errormessage">
                {remarkErrors.appeal_status}
              </span>
            </div>
            {isHPA() && isRemarkReasonAllow(remarkInputs.appeal_status, remarkInputs.hearing_status) ?
              reasonsVal === "" ? <div className="col-12 form-group formgroup-main f-main2">
                <label >Reason</label>
                <br/>
                <Link onClick={(e) => handleOpeneModel(e)}>Select Reason</Link>
              </div> :
                reasonsVal !== "" &&
              (
                  <div className="col-12 form-group formgroup-main f-main2">
                    <label>Reason</label>
                    <div className="reason-text-box">
                      {reasonsVal}
                    </div>
                    <br/>
                    <Link onClick={(e) => handleOpeneModel(e)}>Change Reason</Link>
                  </div>
              )
              : <div className="col-12 form-group formgroup-main f-main2  f-main-cstm">
                <label htmlFor="">Remark {!isMansField() ? '*' : ''}</label>
                <textarea
                  rows="2"
                  cols="50"
                  value={remarkInputs.remark}
                  name="remark"
                  onChange={(e) => onChangeReviewInputs(e, "tab_1")}
                  maxLength={700}
                />
                {workFlowPage === "Appeal management" ? (<>
                  <span className="errormessage">{remarkErrors.remark}</span>
                  <span className="cstm_appeal_character_warning">{"700"} characters limit.</span></>
                ) : (
                  <span className="remark_warning">
                    <p className="errormessage">{remarkErrors.remark}</p>
                    <p className="cstm_character_warning">{"700"} characters limit.</p>
                  </span>
                )}
              </div>}
              <div>
                  {workFlowPage === "Appeal management" && (
                    <div className="col-12 form-group formgroup-main f-main2  f-main-cstm">
                      <label>
                        <div className="in-cstm-sub-notification-only">
                          <input
                            type="checkbox"
                            name="is_internal"
                            onChange={(e) => onChangeReviewInputs(e, "tab_1")}
                            checked={
                              remarkInputs.is_internal
                                ? remarkInputs.is_internal
                                : false
                            }
                          />
                          Is an Internal Remark
                        </div>
                      </label>
                    </div>
                  )}
                </div>

            <div className="row">
              <div className="col-12 imagebutton-1 counterbtn-hover text-center">
                <button className="btn" type="submit" disabled={isBtnLoader}>
                  {isBtnLoader && (
                    <div className="cstm-loader">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
      {showStatusWarning && (
        <ConfirmationModalComponent
          displayMsg={
            "This status change is permanent and can’t be changed again."
          }
          handleCloseModel={handleCloseConfirmationModal}
          handleConfirm={handleConfirm}
        />
      )}
      {isSelected &&
        <div className="main-body-modal select-reason-popup">
          <div className="main-modal">
            <div className="select-reason-popup-header">
              <h5 className="srh-title">Select reason for {remarkInputs.appeal_status ? remarkInputs.appeal_status : remarkInputs.hearing_status}</h5>
              <div class="cstm-close" onClick={handleCloseModel}><button>×</button></div>
            </div>

            <div className="select-reason-popup-body">
              {reasons && reasons.map((ele, index) => {
                const isChecked = reasonsVal === ele;
                return (
                    <label className={`srp-in ${isChecked ? 'srp-select' : ''}`}>
                      <input
                        type="radio"
                        value={ele}
                        name="reasons"
                        onChange={(e) => { handleChange(e,index) }}
                        checked={index === reasonIndex}
                      />
                      <h6>{ele}</h6>
                    </label>
                )
              })}
            </div>

            <div className="select-reason-popup-footer">
              <button className="srh-footer-btn" onClick={handleSubmit}>Submit</button>
            </div>

          </div>

        </div>
      }

    </>
  );
};

export default ManagerReviewTab;
