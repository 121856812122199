import React, { useState } from "react";
import TicketDetailHeader from "./TicketDetailHeader";
import FlipbookPage from "../../Flipbook/Page";
import { getFormattedDate } from "../../Common/utils";
import SupportNotesTable from "../../Utilies/SupportNotesTable";

const FlipbookModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <FlipbookPage
        key={isOpen}
        ticketId={isOpen}
        handleBackButtonClick={onClose}
      />
    </div>
  );
};
const CitationSummaryTab = ({
  summary,
  isLoading,
  showData,
  citationData,
  paymentBreakdownData,
  downloadAttechment,
  registeredOwnerData,
}) => {
  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      {isLoading ? (
        <div className="col-12 text-center">
          <div className="spinner-border"></div>
        </div>
      ) : (
        showData && (
          <div className="tab-content">
            <div id="Dashboard" className="tab-pane active">
              <div className="">
                <div className="cstm-suss">
                  <div className="cstm-sus-left">
                    <div className="cstm-rro">
                      <button data-modal="changeStatus-modal">
                        Add Account Notes
                      </button>
                      <button data-modal="changeAmount-modal">
                        Unlink Plates
                      </button>
                    </div>
                  </div>
                </div>
                {/* row 1 */}
                <div className="md-4">
                    <div className="row">
                      <TicketDetailHeader
                        citationData={citationData}
                        paymentBreakdownData={paymentBreakdownData}
                        registeredOwnerDetails={registeredOwnerData}
                      />
                    </div>
                  </div>
                  <br />
                {/* row 2 */}
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h5
                            className="card-title"
                            style={{ fontSize: "1em", marginBottom: "0px" }}
                          >
                            Last Communication Sent
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <table>
                                    <tr>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          Address:
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          {summary && summary?.last_communication_sent ? (
                                            <h5>
                                              {
                                                summary?.last_communication_sent
                                                  .address
                                              }
                                            </h5>
                                          ) : (
                                            <h5>-</h5>
                                          )}
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          Created At:
                                        </h6>
                                      </td>
                                      <td>
                                          <h6 className="card-subtitle md-2">
                                          {summary && summary.last_communication_sent && summary.last_communication_sent?.created_at
                                              ?
                                            <h5>{getFormattedDate(summary.last_communication_sent?.created_at)}</h5>
                                            : "-"}
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          Email:
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          {" "}
                                          {summary && summary.last_communication_sent && summary.last_communication_sent
                                            ?.email ? (
                                            <h5>
                                              {
                                                summary.last_communication_sent
                                                  ?.email
                                              }
                                            </h5>
                                          ) : (
                                            <h5>-</h5>
                                          )}
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          Notification Type:
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          {summary && summary.last_communication_sent && summary.last_communication_sent
                                            ?.notification_type ? (
                                            <h5>
                                              {
                                                summary.last_communication_sent
                                                  ?.notification_type
                                              }
                                            </h5>
                                          ) : (
                                            <h5>-</h5>
                                          )}
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          Sent By:
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          {" "}
                                            {summary && summary.last_communication_sent && summary.last_communication_sent
                                            ?.sent_by ? (
                                            <h5>
                                              {
                                                summary.last_communication_sent
                                                  ?.sent_by
                                              }
                                            </h5>
                                          ) : (
                                            <h5>-</h5>
                                          )}
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          Attachment:
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="card-subtitle md-2 text-muted">
                                          {summary && summary.last_communication_sent ? (
                                            <a
                                              data-id={
                                                summary?.last_communication_sent
                                                  ?.doc_location
                                              }
                                              onContextMenu={(e) =>
                                                downloadAttechment(
                                                  e,
                                                  summary
                                                    ?.last_communication_sent
                                                    ?.doc_location
                                                )
                                              }
                                              onClick={(e) =>
                                                downloadAttechment(
                                                  e,
                                                  summary
                                                    ?.last_communication_sent
                                                    ?.doc_location
                                                )
                                              }
                                              href={
                                                summary?.last_communication_sent
                                                  ?.doc_location
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              download
                                            >
                                              Download
                                            </a>
                                          ) : (
                                            "-"
                                          )}
                                        </h6>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* row 3 */}
                <div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Account Information
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <table>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle md-4 text-muted">
                                            Name:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle md-4 text-muted">
                                            {summary &&
                                                summary.account_information &&
                                                summary.account_information
                                                  ?.name ? (
                                              <h5>
                                                {
                                                  summary.account_information
                                                    ?.name
                                                }
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle md-2 text-muted">
                                            Phone:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle md-2 text-muted">
                                            {summary && summary.account_information &&
                                                summary.account_information
                                                  .phone ? (
                                              <h5>
                                                {
                                                  summary.account_information
                                                    .phone
                                                }
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-2 text-muted">
                                            Email:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-2 text-muted">
                                            {summary && summary.account_information &&
                                                summary.account_information
                                                  .email ? (
                                              <h5>
                                                {
                                                  summary.account_information
                                                    .email
                                                }
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle md-2 text-muted">
                                            City:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle md-2 text-muted">
                                            {summary && summary.account_information &&
                                                summary.account_information.city ? (
                                              <h5>
                                                {
                                                  summary.account_information
                                                    .city
                                                }
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle md-2 text-muted">
                                            Zip Code:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle md-2 text-muted">
                                            {summary && summary.account_information &&
                                                summary.account_information
                                                  .zip_code ? (
                                              <h5>
                                                {
                                                  summary.account_information
                                                    .zip_code
                                                }
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div className="col-12">
                                    <div className="result1 hide-col">
                                      <h5
                                        className="card-title"
                                        style={{
                                          fontSize: "1em",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Vehical Details
                                      </h5>
                                      <div id="notice">
                                        <div className="row table-overflow">
                                          <div className="col-12">
                                            {summary.account_information ===
                                                null ? (
                                              <>No data Found</>
                                            ) : (
                                              <table className="table-heading table-bordered">
                                                <thead>
                                                  <tr>
                                                    <th>State</th>
                                                    <th>Plate Number</th>
                                                    <th>Vin Number</th>
                                                    <th>Color</th>
                                                    <th>Make</th>
                                                    <th>Model</th>
                                                    <th>Email Notification</th>
                                                    <th>SMS Notification</th>
                                                    <th>Body Style</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {summary.account_information &&
                                                    summary.account_information
                                                      .vehicle_detail &&
                                                    summary.account_information
                                                      .vehicle_detail.length >
                                                        0 &&
                                                    summary.account_information.vehicle_detail.map(
                                                      (ele, index) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              {ele.state
                                                                ? ele.state
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.plate_number
                                                                ? ele.plate_number
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.vin_number
                                                                ? ele.vin_number
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.color
                                                                ? ele.color
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.make
                                                                ? ele.make
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.model
                                                                ? ele.model
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.email_notification
                                                                ? ele.email_notification
                                                                  ? "true"
                                                                  : "false"
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.sms_notification
                                                                ? ele.sms_notification
                                                                  ? "true"
                                                                  : "false"
                                                                : "-"}
                                                            </td>
                                                            <td>
                                                              {ele.body_style
                                                                ? ele.body_style
                                                                : "-"}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                </tbody>
                                              </table>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* notes */}
                  <div>
                    <div>
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-header">
                              <h5
                                className="card-title"
                                style={{
                                  fontSize: "1em",
                                  marginBottom: "0px",
                                }}
                              >
                                Support Notes
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-md-12">
                                      {summary && summary.support_notes ? (
                                          <SupportNotesTable citationAllData={summary.support_notes} />
                                      ) : (
                                        <h6 className="card-subtitle text-muted">
                                              No data Found
                                            </h6>

                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Linked Plates
                            </h5>
                          </div>
                          <div className="card-body ">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h6 className="card-subtitle text-muted">
                                      {summary.linked_plates !== null &&
                                          summary.linked_plates.length > 0
                                        ? summary.linked_plates
                                            .map((ele, index) => {
                                              return ele;
                                            })
                                            .join(", ")
                                        : "-"}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Total Outstanding Balance
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    {summary && summary.total_outstanding ? (
                                      <h6 className="card-subtitle text-muted">
                                          $
                                        {convertDecimal(
                                          summary.total_outstanding
                                        )}
                                      </h6>
                                    ) : (
                                      <h6 className="card-subtitle text-muted">$0.00</h6>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* row 5 */}
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Total Amount Per Plate
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    {summary.total_amount_due_per_plate ===
                                    null ? (
                                        <h6 className="card-subtitle text-muted">
                                      No data Found</h6>
                                    ) : (
                                      <table className="table-heading table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Plate Number</th>
                                            <th>Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {summary &&
                                            summary.total_amount_due_per_plate &&
                                            summary.total_amount_due_per_plate
                                              .length > 0 &&
                                            summary.total_amount_due_per_plate.map(
                                              (ele, index) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {ele.plate_number
                                                        ? ele.plate_number
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      ${ele.amount
                                                        ? convertDecimal(ele.amount)
                                                        : "0.00"}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  {/* row 8 */}
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Bootable & Towable
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <table>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Date Sent:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.bootable_towable_data &&
                                                summary.bootable_towable_data
                                                  .date_sent ? (
                                              <h5>
                                                {getFormattedDate(
                                                  summary.bootable_towable_data
                                                    .date_sent
                                                )}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Eligible:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.bootable_towable_data &&
                                                summary.bootable_towable_data
                                                  .eligible ? (
                                              <h5>
                                                {summary.bootable_towable_data
                                                  .eligible
                                                  ? "true"
                                                  : "false"}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* row 9 */}

                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Registration Suspend
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    {summary.registration_suspend === null ? (
                                      <h6 className="card-subtitle text-muted">
                                        No data Found
                                      </h6>
                                    ) : (
                                      <table className="table-heading table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Date Sent</th>
                                            <th>Available</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {summary &&
                                            summary.registration_suspend &&
                                            summary.registration_suspend
                                              .length > 0 &&
                                            summary.registration_suspend.map(
                                              (ele, index) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {ele.date_sent
                                                        ? getFormattedDate(
                                                          ele.date_sent
                                                        )
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.available
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {ele.data
                                                        ? ele.data
                                                        : "-"}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Driver's Licence Suspend
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    {summary.drivers_license_suspend ===
                                        null ? (
                                      <h6 className="card-subtitle text-muted">
                                            No data Found</h6>
                                    ) : (
                                      <table className="table-heading table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Date Sent</th>
                                            <th>Available</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {summary &&
                                            summary.drivers_license_suspend &&
                                            summary.drivers_license_suspend
                                              .length > 0 &&
                                            summary.drivers_license_suspend.map(
                                              (ele, index) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {ele.date_sent
                                                        ? getFormattedDate(
                                                          ele.date_sent
                                                        )
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.available
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {ele.data
                                                        ? ele.data
                                                        : "-"}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              In Collection
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <table>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Date Sent:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.collections_data &&
                                                summary.collections_data
                                                  .date_sent ? (
                                              <h5>
                                                {getFormattedDate(
                                                  summary.collections_data
                                                    .date_sent
                                                )}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Is Collection:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.collections_data &&
                                                summary.collections_data
                                                  .is_collection ? (
                                              <h5>
                                                {summary.collections_data
                                                  .is_collection
                                                  ? "true"
                                                  : "false"}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Appeal Status
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <table>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Appeal Date:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.appeal_data &&
                                                summary.appeal_data.appeal_date ? (
                                              <h5>
                                                {getFormattedDate(
                                                  summary.appeal_data
                                                    .appeal_date
                                                )}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Current Date:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.appeal_data &&
                                                summary.appeal_data
                                                  .current_status ? (
                                              <h5>
                                                {
                                                  summary.appeal_data
                                                    .current_status
                                                }
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Last PDF:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.appeal_data ? (
                                              <a
                                                data-id={
                                                  summary?.appeal_data
                                                    ?.last_pdf_link
                                                }
                                                onContextMenu={(e) =>
                                                  downloadAttechment(
                                                    e,
                                                    summary?.appeal_data
                                                      ?.last_pdf_link
                                                  )
                                                }
                                                onClick={(e) =>
                                                  downloadAttechment(
                                                    e,
                                                    summary?.appeal_data
                                                      ?.last_pdf_link
                                                  )
                                                }
                                                href={
                                                  summary?.appeal_data
                                                    ?.last_pdf_link
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                download
                                              >
                                                Download
                                              </a>
                                            ) : (
                                              "-"
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Hearing Status
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <table>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Status:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.hearing_data &&
                                                summary.hearing_data.status ? (
                                              <h5>
                                                {summary.hearing_data.status}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Comment:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.hearing_data &&
                                                summary.hearing_data.comment ? (
                                              <h5>
                                                {summary.hearing_data.comment}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Date Sent:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            {summary.hearing_data &&
                                                summary.hearing_data.date_sent ? (
                                              <h5>
                                                {getFormattedDate(
                                                  summary.hearing_data.date_sent
                                                )}
                                              </h5>
                                            ) : (
                                              <h5>-</h5>
                                            )}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            Docket Link:
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="card-subtitle mb-4 text-muted">
                                            <button
                                              onClick={() =>
                                                setModalOpen(
                                                  citationData.ticket_no
                                                )
                                              }
                                              className="p-0"
                                            >
                                              Open
                                            </button>
                                          </h6>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header table-overflow">
                            <h5
                              className="card-title"
                              style={{
                                fontSize: "1em",
                                marginBottom: "0px",
                              }}
                            >
                              Current Address
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    {summary.drivers_license_suspend ===
                                        null ? (
                                      <h6 className="card-subtitle text-muted">
                                            No data Found</h6>
                                    ) : (
                                      <table className="table-heading table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Zip</th>
                                            <th>Received Date</th>
                                            <th>Invalid Address</th>
                                            <th>Source</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {summary &&
                                            summary.current_address &&
                                            summary.current_address.length >
                                                0 &&
                                            summary.current_address.map(
                                              (ele, index) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {ele.address.name
                                                        ? ele.address.name
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address.address
                                                        ? ele.address.address
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address.city
                                                        ? ele.address.city
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address.state
                                                        ? ele.address.state
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address.zip
                                                        ? ele.address.zip
                                                        : "-"}
                                                    </td>

                                                    <td>
                                                      {ele.address.received_date
                                                        ? getFormattedDate(
                                                          ele.address
                                                            .received_date
                                                        )
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address
                                                        .invalid_address
                                                        ? "true"
                                                        : "false"}
                                                    </td>
                                                    <td>
                                                      {ele.address.source
                                                        ? ele.address.source
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address.phone
                                                        ? ele.address.phone
                                                        : "-"}
                                                    </td>
                                                    <td>
                                                      {ele.address.email
                                                        ? ele.address.email
                                                        : "-"}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      {isModalOpen && (
        <FlipbookModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          ticketDetails={isModalOpen} //isModalOpen has the ticket number as there was no other way to pass it. TODO
        />
      )}
    </>
  );
};

export default CitationSummaryTab;
