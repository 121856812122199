import React, { useState, useEffect } from 'react';
import Image1 from "../../../Images/p-1.png";
import Image2 from "../../../Images/p-2.png";
import Image3 from "../../../Images/p-3.png";
import Image4 from "../../../Images/p-4.png";
import Image5 from "../../../Images/p-5.png";
import Image6 from "../../../Images/p-6.png";
import Image7 from "../../../Images/p-7.png";
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";
import { changetotal } from "../../../Global/site";

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';


function ProductivityAvgCount() {
    const dispatch = useDispatch()
    const changeTotal = changetotal();

    const [count, setCount] = React.useState(0);
    const [showAvgTimeIssueCitation, setAvgTimeIssueCitation] = React.useState("");
    const [showAvgScanPerHour, setAvgScanPerHour] = React.useState("");
    const [showAvgCitationPerHour, setAvgCitationPerHour] = React.useState("");
    const [showAvgScanOfficer, setAvgScanOfficer] = React.useState("");
    const [showAvgIssuanceOfficer, setAvgIssuanceOfficer] = React.useState("");
    const [showIssuanceRation, setIssuanceRation] = React.useState("");
    const [showMarksRatio, setMarksRatio] = React.useState("");
    const [showAuthToken, setAuthToken] = useState(false);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () => {
        setErrorHandling(false)
    }
    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    useEffect(() => {
        const retriveData = {
            "count_array_timeline": {
                "count_type": "daily"
            }
        }
        intialproductivityfun()
        function intialproductivityfun() {
            Dashboard.productivityGetCounts(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setAvgTimeIssueCitation(response.data.data[0].metadata.counts.avg_time_to_issue_citation)
                            setAvgScanPerHour(response.data.data[0].metadata.counts.avg_scans_per_hour)
                            setAvgCitationPerHour(response.data.data[0].metadata.counts.avg_citations_per_hour)
                            setAvgScanOfficer(response.data.data[0].metadata.counts.avg_scans_per_officer)
                            setAvgIssuanceOfficer(response.data.data[0].metadata.counts.avg_citations_per_officer)
                            setIssuanceRation(response.data.data[0].metadata.counts.issuance_to_scan_ratio)
                            setMarksRatio(response.data.data[0].metadata.counts.marks_to_issuance_ratio)
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    intialproductivityfun()
                }
            });
        }
        setCount(100);
    }, []);

    return (
        <>
            <div className="row cstm-productivity-mn cstm_productiv_ttl">
                <div className='col-12'>
                    <div className="cstm-dashboard-mn-all-left">Productivity</div>
                </div>
                {/* <div className="cstm-dashboard-mn-all-right"><a href="#">View Details <i className="fa fa-angle-right"></i></a></div> */}
            </div>
            <div className="row cstm-prod">
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image1} /></div>
    <div className="cstm-prod-box-name">Average time to issue {changeTotal}</div>
                    <div className="cstm-prod-box-cont">{showAvgTimeIssueCitation}s</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image2} /></div>
                    <div className="cstm-prod-box-name">Average Scans per hour</div>
                    <div className="cstm-prod-box-cont">{showAvgScanPerHour}/h</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image3} /></div>
                    <div className="cstm-prod-box-name">Average {changeTotal} per hour</div>
                    <div className="cstm-prod-box-cont">{showAvgCitationPerHour}/h</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image4} /></div>
                    <div className="cstm-prod-box-name">Avg Scans by Officer</div>
                    <div className="cstm-prod-box-cont">{showAvgScanOfficer}/h</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image5} /></div>
                    <div className="cstm-prod-box-name">Avg Issuance by Officer</div>
                    <div className="cstm-prod-box-cont">{showAvgIssuanceOfficer}/h</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image6} /></div>
                    <div className="cstm-prod-box-name">Issuance to Scan ratio</div>
                    <div className="cstm-prod-box-cont">{showIssuanceRation}/h</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image7} /></div>
                    <div className="cstm-prod-box-name">Marks to issuance ratio</div>
                    <div className="cstm-prod-box-cont">{showMarksRatio}</div>
                </div>
            </div>


        </>
    );
}

export default ProductivityAvgCount;
