import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { siteid } from '../../../Global/site';
import dashboardService from '../../../Services/dashboard.service';
import { refreshTokenMiddleware } from '../../customMiddelware';
import CommercialTab from '../../ReportingModule/TicketReporting/CommercialTab';
import ErrorModalComponent from '../../../utile/modals/errorModal';

const ClosedTab = ({ citationNumber }) => {
  const dispatch = useDispatch();
  const showSideId = siteid();
  const [isOpen, setOpen] = useState(false);
  const [messageSuc, setMessageSuc] = useState("");
  const [commercialData, setCommercialData] = useState({});
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [commercialUpdateData, setCommercialUpdateData] = useState({
    ticket_number: "",
    site_id: showSideId.siteinfo,
    fine_amount: null,
    nsf_amount: null,
    boot_tow_fee: null,
    collection_amount: null,
    balance_due: null,
    notice_date: {},
    dmv_case_number: "",
    remark: "",
    paid_date: null,
    fine_increase_date: null,
    nsf_applied_date: null,
    boot_applied_date: null,
    collection_date: null,
    adjusted_balance_date: null,
    due_date: null,
  });

  const [showCitationDetails, setCitationDetails] = useState({});
  const [commercialUpdateError, setCommercialUpdateError] = useState({
    nsf_amount: "",
    charge_back_fee: ""
  });
  const [changedCommercialFields, setChangedCommercialFields] = useState({
    ticket_number: "",
    site_id: showSideId.siteinfo,
    escalation_detail:[]
  })
  const [showNsfOptions, setShowNsfOptions] = useState(false);
  const [showChargeBackOptions, setShowChargeBackOptions] = useState(false);
  const [isEligibleOpen, setEligibleOpen] = useState(false);
  const [isEligibleMsg, setEligibleMsg] = useState("");
  
  const handleCommercialLateFeeChange = (e, index, escalated) => {
    const { value } = e.target;

    const new_escalated = {...escalated,late_fee: Number(value)}
    setChangedCommercialFields(prevData => {
      const updatedEscalationDetails = [...prevData.escalation_detail];
      updatedEscalationDetails[index] = {
        ...updatedEscalationDetails[index],
        ...new_escalated,
        late_fee: Number(value)
      };
      return {
        ...prevData,
        escalation_detail: updatedEscalationDetails
      };
    });
  };

  const onChangeDateOfCommercial = (e, i, key) => {
    changedCommercialFields.notice_date[key] = e.target.value;
    setChangedCommercialFields({ ...changedCommercialFields });
  };

  const onChangeCommercialUpdateDatesEvent = (e) => {
    const { name, value } = e.target;
      setChangedCommercialFields({ ...changedCommercialFields, [name]: value });
  };

  const onChangeCommercialInput = (e) => {
    const { name, value } = e.target
    setChangedCommercialFields({ ...changedCommercialFields, [name]: value });
    if (!isNaN(value) && value > 0) {
      setCommercialUpdateData({
        ...commercialUpdateData,
        [name]: value,
      });
      setCommercialUpdateError({
        ...commercialUpdateError,
        [name]: ``
      })
    } else {
      
      setCommercialUpdateData({
        ...commercialUpdateData,
        [name]: value,
      });
      if (name === "nsf_amount" || name === "charge_back_fee") {
        if (showChargeBackOptions || showNsfOptions) {
          setCommercialUpdateError({
            ...commercialUpdateError,
            [name]: `${name} Fee must be greater than zero.`
          })
        }
      }
    }
  };

  const allStringsAreBlank = (obj) => {
    for (const key in obj) {
      if (obj[key] !== "") {
        return false; // Return false if any value is not blank
      }
    }
    return true; // Return true only if all values are blank
  };

  //   change amount button
  const onUpdateCommercial = async (e) => {
    e.preventDefault();
    let payload_data = { ...changedCommercialFields };

    if (changedCommercialFields?.notice_date) {
      Object.keys(changedCommercialFields?.notice_date).map((key, i) => {
       let nd = payload_data.notice_date[key].substring(0, 16);
       payload_data.notice_date[key] = nd + ":00.000+00:00";
     });
    }
    if(changedCommercialFields?.fine_amount) {
      payload_data.fine_amount = Number(changedCommercialFields?.fine_amount);
    }
    if (changedCommercialFields?.nsf_amount) {
      payload_data.nsf_amount = Number(changedCommercialFields?.nsf_amount);
    }
    if (changedCommercialFields?.boot_tow_fee) {
      payload_data.boot_tow_fee = Number(changedCommercialFields?.boot_tow_fee);
    }
    if (changedCommercialFields?.collection_amount) {
      payload_data.collection_amount = Number(
        changedCommercialFields?.collection_amount
      );
    }
    if (changedCommercialFields.balance_due) {
      payload_data.balance_due = Number(changedCommercialFields.balance_due);
    }

    if (changedCommercialFields.paid_date) {
      payload_data.paid_date = changedCommercialFields.paid_date
        ? changedCommercialFields.paid_date + ":00.00"
        : changedCommercialFields.paid_date;
    }
    if (changedCommercialFields.due_date) {
      payload_data.due_date = changedCommercialFields.due_date
        ? changedCommercialFields.due_date + ":00.00"
        : '';
    }
    if (changedCommercialFields.fine_increase_date) {
      payload_data.fine_increase_date = changedCommercialFields.fine_increase_date
        ? changedCommercialFields.fine_increase_date + ":00.00"
        : changedCommercialFields.fine_increase_date;
    }
    if (changedCommercialFields.nsf_applied_date) {
      payload_data.nsf_applied_date = changedCommercialFields.nsf_applied_date
        ? changedCommercialFields.nsf_applied_date + ":00.00"
        : changedCommercialFields.nsf_applied_date;
    }
    if (changedCommercialFields.boot_applied_date) {
      payload_data.boot_applied_date = changedCommercialFields.boot_applied_date
        ? changedCommercialFields.boot_applied_date + ":00.00"
        : changedCommercialFields.boot_applied_date;
    }
    if (changedCommercialFields.collection_date) {
      payload_data.collection_date = changedCommercialFields.collection_date
        ? changedCommercialFields.collection_date + ":00.00"
        : changedCommercialFields.collection_date;
    }
    if (changedCommercialFields.adjusted_balance_date) {
      payload_data.adjusted_balance_date =
        changedCommercialFields.adjusted_balance_date
          ? changedCommercialFields.adjusted_balance_date + ":00.00"
          : payload_data.adjusted_balance_date;
    }
    if (changedCommercialFields.admin_fee_applied_date) {
      payload_data.admin_fee_applied_date =
        changedCommercialFields.admin_fee_applied_date
          ? changedCommercialFields.admin_fee_applied_date + ":00.00"
          : payload_data.admin_fee_applied_date;
    }
    if (changedCommercialFields.parking_fee_applied_date) {
      payload_data.parking_fee_applied_date =
        changedCommercialFields.parking_fee_applied_date
          ? changedCommercialFields.parking_fee_applied_date + ":00.00"
          : payload_data.parking_fee_applied_date;
    }
    if (changedCommercialFields.charge_back_applied_date) {
      payload_data.charge_back_applied_date =
        changedCommercialFields.charge_back_applied_date
          ? changedCommercialFields.charge_back_applied_date + ":00.00"
          : payload_data.charge_back_applied_date;
    }
    
    let isValid = allStringsAreBlank(commercialUpdateError)

    if (showChargeBackOptions && changedCommercialFields.charge_back_fee === 0) { 
       isValid = false;
       setCommercialUpdateError({
        ...commercialUpdateError,
        charge_back_fee: `charge_back Fee must be greater than zero.`
      })
    }
    if (showNsfOptions && changedCommercialFields.nsf_amount === 0) { 
       isValid = false;
       setCommercialUpdateError({
        ...commercialUpdateError,
        nsf_amount: `nsf_amount Fee must be greater than zero.`
      })
    }

    if (!showChargeBackOptions) {
      delete payload_data.charge_back_fee
    }
    if (!showNsfOptions) {
      delete payload_data.nsf_amount
    }

    if (changedCommercialFields.escalation_detail && changedCommercialFields.escalation_detail.length <= 0) {
      delete payload_data.escalation_detail
    }

    if (isValid) {
      commercialUpdateFun();
    }

    async function commercialUpdateFun() {
      try {
        const result = await dashboardService.updateCommercialAPI(
          payload_data
        );
        if (result.data.success) {
          setMessageSuc("Commercial updated!");
          pageLoadData();
          setBtnLoader(false);
          window.scrollTo(0, 0);
          setTimeout(() => {
            comercialdatafetch();
          }, 1500);
        } else {
          setEligibleOpen(true);
          setEligibleMsg(result.data.message)
        }
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          commercialUpdateFun();
        }
      }
    }
  };

  const pageLoadData = () => {
    dynamicstatusfun();
    function dynamicstatusfun() {
      dashboardService
        .dynemicStatus()
        .then((result) => {
          console.log("result", result);
          if (result.data.status === true && result.data.data !== null) {
            var SortData = result.data.data;

            SortData.sort(function (a, b) {
              var textA = a["id"];
              var textB = b["id"];
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            dynamicstatusfun();
          }
        });
    }
  };
  useEffect(() => {
    pageLoadData();
    comercialdatafetch()
  }, [citationNumber]);

  async function comercialdatafetch() {
    setOpen(true);
    try {
      // static data
      const result = await dashboardService.getCommercialDataAPI(
        citationNumber
      );
      setCitationDetails(result.data.data)
      setCommercialData({ ...result.data.data });
      commercialUpdateData.ticket_number = result.data.data.citation_number;
      commercialUpdateData.notice_date = result.data.data.notice_date;
      changedCommercialFields.ticket_number = result.data.data.citation_number;

      commercialUpdateData.paid_date = result.data.data.paid_date
        ? result.data.data.paid_date.substring(0, 16)
        : null;
      commercialUpdateData.due_date = result.data.data.due_date
        ? result.data.data.due_date.substring(0, 16)
        : null;
      commercialUpdateData.fine_increase_date = result.data.data
        .fine_increase_date
        ? result.data.data.fine_increase_date.substring(0, 16)
        : null;
      commercialUpdateData.nsf_applied_date = result.data.data.nsf_applied_date
        ? result.data.data.nsf_applied_date.substring(0, 16)
        : null;
      commercialUpdateData.boot_applied_date = result.data.data
        .boot_applied_date
        ? result.data.data.boot_applied_date.substring(0, 16)
        : null;
      commercialUpdateData.collection_date = result.data.data.collection_date
        ? result.data.data.collection_date.substring(0, 16)
        : null;
      commercialUpdateData.adjusted_balance_date = result.data.data
        .adjusted_balance_date
        ? result.data.data.adjusted_balance_date.substring(0, 16)
        : null;

      setCommercialUpdateData({ ...commercialUpdateData });
      setOpen(false);
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        comercialdatafetch();
      }
    }
  }
  // closed tab api
  function datetimeLocal() {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16);
  }

  const closeModal = () => {
    setEligibleOpen(false)
  }

  return (
    <>
      {isOpen ? (
        <div className="col-12 text-center cstm-ap-ld">
          <div className="spinner-border"></div>
        </div>
      ) : (
          <CommercialTab
            messageSuc={messageSuc}
            commercialData={commercialData}
            commercialUpdateData={commercialUpdateData}
            onChangeCommercialUpdateDatesEvent={onChangeCommercialUpdateDatesEvent}
            onChangeCommercialInput={onChangeCommercialInput}
            isBtnLoader={isBtnLoader}
            onChangeDateOfCommercial={onChangeDateOfCommercial}
            onUpdateCommercial={onUpdateCommercial}
            commercialUpdateError={commercialUpdateError}
            handleCommercialLateFeeChange={handleCommercialLateFeeChange}
            setCommercialUpdateError={setCommercialUpdateError}
            setShowChargeBackOptions={setShowChargeBackOptions}
            setShowNsfOptions={setShowNsfOptions}
            showChargeBackOptions={showChargeBackOptions}
            showCitationDetails={showCitationDetails}
            showNsfOptions={showNsfOptions}
          />
      )}
      {isEligibleOpen && (
              <ErrorModalComponent handleCloseModel={closeModal} displayMsg={isEligibleMsg} />
            )}
    </>
  )
}

export default ClosedTab