import { useState, useEffect, useCallback, useContext } from 'react';
import FlipbookService from '../../Services/flipbook.service';
import HTMLFlipBook from 'react-pageflip';
import DatePickerComponent from '../Common/DatePickerComponent';
import { refreshTokenMiddleware } from "../customMiddelware";
import { BrandingContext } from '../../Global/Branding';
import { connect, useDispatch } from 'react-redux';
import { getDateRange } from '../Common/utils';
import { clearoutAuthUserData } from '../../actions/auth';
import './FlipBook.css';
import FlipbookPage from './Page';
import { changeticket } from '../../Global/site';

const FlipBook = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)))
  const [endDate, setEndDate] = useState(new Date(new Date().setHours(0, 0, 0)))
  const [isLoading, setIsLoading] = useState(false)
  const [showDetailsPage, setShowDetailPage] = useState(false)
  const [ticketId, setTicketId] = useState('')
  const [searchTicketId, setSearchTicketId] = useState('')
  const changeName = changeticket();
  const { logo } = useContext(BrandingContext);

  const fetchData = useCallback(({ time_from, time_to }) => {
    FlipbookService.getCitations({ time_from, time_to })
      .then(response => {
        if (response.data?.data?.citations === null) {
          setData([])
        } else {
          setData(response.data?.data?.citations)
        }
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false);
        if (error?.response?.status === 401) {
          refreshTokenMiddleware(dispatch)
        }
      })
  }, [dispatch]);

  useEffect(() => {

    const { time_from, time_to } = getDateRange(2);
    fetchData({ time_from, time_to });
  }, [fetchData])

  const handleBackButtonClick = () => setShowDetailPage(false)

  const handleFlip = (details) => () => {
    setShowDetailPage(true);
    setTicketId(details)
  }

  const onChangeSearchDate = (date, type = 'start') => {
    if (type === null || type === '' || type === 'start') {
      setStartDate(date)
    } else if (type === 'end') {
      setEndDate(date)
    }
  }

  const onSubmitButtonClick = () => {
    setIsLoading(true);
    const dateObject = {
      time_from: (startDate).toISOString(),
      time_to: (endDate).toISOString()
    }
    fetchData(dateObject)
    setIsLoading(false)
  }

  const onLicenseChange = (event) => {
    const ticket_number = event.target.value;
    setSearchTicketId(ticket_number);

    if (ticket_number.length > 0) {
      const filteredData = data.find((item) => {
        return item?.ticket_no === ticket_number;
      });
      if (filteredData) {
        setData([filteredData]);
      }
    }
  }

  const PageCover = (props) => {
    const { lp, total_due, state } = props;
    return (
      <div className="page page-cover page-cover-top" data-density="hard">
        <div className="cover-content">
          <img
            src={logo}
            alt="logo"
            className='cover-image'
          />
          <h4>License: {lp}</h4>
          <h4>Fine Amount: ${total_due}</h4>
          <h4>{changeName}: {state}</h4>
        </div>
      </div>
    );
  };

  const renderBooks = (data) => {
    const bookElements = [];
    const dataLength = data.length;
    const itemsPerGroup = 4;
    const numberOfGroups = Math.ceil(dataLength / itemsPerGroup);

    for (let index = 0; index < numberOfGroups; index++) {
      const elements = [];
      for (let i = 0; i < itemsPerGroup; i++) {
        const dataIndex = index * itemsPerGroup + i;
        if (dataIndex < dataLength) {
          elements.push(
            <button
              key={data[dataIndex].id}
              onClick={handleFlip(data[dataIndex].ticket_no)}
              data-id={data[dataIndex].id}
            >
              <HTMLFlipBook
                width={100}
                height={300}
                className="flipbook"
                size="fixed"
              >
                <PageCover
                  lp={data[dataIndex].lp_number}
                  total_due={data[dataIndex].fine_amount}
                  state={data[dataIndex].ticket_no}
                />
              </HTMLFlipBook>
            </button>
          );
        }
      }

      bookElements.push(
        <ul className="list-grid-new">
          {elements}
        </ul>
      );
    }

    return bookElements;
  }

  return (
    <>
      {!showDetailsPage && !isLoading ?
        <>
          <section>
            <div className="container-fluid">
              <div className="row" >
                <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
                  <div className="report-searchbar">
                    <form>
                      <DatePickerComponent setEndDate={endDate} setStartDate={startDate} onChangeSearchDate={onChangeSearchDate} onSubmitButtonClick={onSubmitButtonClick} />
                      <div className="report-licence report-mr">
                        <div>
                          <input className="license-plate" name="ticket_no" value={searchTicketId} onChange={onLicenseChange} type="text" placeholder="Search Tickets" />
                        </div>
                      </div>
                      <button
                        className="licence-print report-mr"
                        type="button"
                        onClick={onSubmitButtonClick}
                      // disabled={isButtonDisabled}
                      >
                        Submit
                      </button>
                      {/* <button onClick={''} className="filter-box report-mr">
                        <img src={filter} alt="filter" />
                      </button> */}
                      {/* <button className="licence-print report-mr" type="button" onClick={''}>Submit</button> */}
                    </form>
                    <div>
                      {data.length === 0 ?
                        <div className="no-data-found-cstm">No records found. </div>
                        :
                        <>
                          <div className='row table-overflow'>

                            {renderBooks(data)}
                          </div>
                          <div className="total-count-records">Total <b> {data.length} </b> Records Found</div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
        :
        <HTMLFlipBook
          key={ticketId}
          showCover={false}
        >
          <FlipbookPage key={ticketId} ticketId={ticketId} handleBackButtonClick={handleBackButtonClick} logo={logo} />
        </HTMLFlipBook>
      }
    </>
  )
}

export default connect(null, { clearoutAuthUserData })(FlipBook)
