
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import mainService from '../../../Services/main.service';
import { refreshTokenMiddleware } from '../../customMiddelware';
import { ENV } from '../../../Services/main.service';

const TicketAnalyticsReport = ({ plateNumber }) => {
  const dispatch = useDispatch();
  const tokenRef = useRef('');
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);
  const HELICAL_ENV_ID = ENV === "production" ? "1689665466882" : "Staging";

  const REPORT_BASE_URL_PATH = `https://reports.parkloyalty.com/hi-ee/hi.html`;

  let REPORT_BASE_URL = `${REPORT_BASE_URL_PATH}?dir=${HELICAL_ENV_ID}/1689683959686/1692557831268&file=44890e29-0184-40d0-9e1a-6773d5981659.efw&License%20No.=${plateNumber}&authToken=${tokenRef.current}`

  useEffect(() => {
    const getHelicalToken = () => {
      mainService.getHelicalToken()
        .then((response) => {
          if (response.status === 200) {
            const token = response.data.data.token;
            tokenRef.current = token;
            setIsTokenLoaded(true);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshTokenMiddleware(dispatch)
          }
        });
    };
    getHelicalToken();
  }, [dispatch]);

  return (
    <>
      {isTokenLoaded && (
        <div className="row" style={{ height: '900px' }}>
          <iframe
            title="report"
            style={{ height: "100%", width: "100%", border: "0px" }}
            id="helicalinsight-report-frame"
            src={REPORT_BASE_URL}
          >
          </iframe>
        </div>
      )}
    </>
  );
};

export default TicketAnalyticsReport;
