import React, { useEffect, useState } from 'react';
import close from '../../../../Images/close.svg';
import TicketReportDetails from '../../../../Services/dashboard.service';
import moment from 'moment';
import { changetotal } from "../../../../Global/site";

import $ from 'jquery';
import Cookies from 'js-cookie';

const ProductTable = (props) => {
  const changeTotal = changetotal();

  const [count, setCount] = React.useState(0);
  const [showCancelCitation, setCancelCitation] = useState(false)
  const [showModifyAmount, setModifyAmount] = useState(false)
  const [showSelectedId, setSelectedId] = React.useState([]);
  const [showSelectedIdModifyAmount, setSelectedIdModifyAmount] = React.useState([]);
  const [showSelectedTicketNumber, setSelectedTicketNumber] = React.useState([]);
  const [showCancelCitationReason, setCancelCitationReason] = useState("CUSTOMER SUPPORT CANCEL")
  const [showCancelCitationComment, setCancelCitationComment] = useState("")
  const [showCommentEmptyMessage, setCommentEmptyMessage] = useState("");
  const [showErrorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setSuccessMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const [showModifyNewAmount, setModifyNewAmount] = useState("");
  const [showModifyAmountReason, setModifyAmountReason] = useState("");
  const [showModifyAmountComments, setModifyAmountComments] = useState("");
  const [showAmountEmptyMessage, setAmountEmptyMessage] = useState("");
  const [showReasonEmptyMessage, setReasonEmptyMessage] = useState("");

  const onClickDetailsPage = (e) => {
    Cookies.set('tikno', e.target.dataset.id);
    return props.onClickDetailsPage(true);
  }

  const closeError = () => {
    setCancelCitation(false);
    setModifyAmount(false)
  }


  const validateData = (data) => {
    if (data !== "" && data !== null && data !== "null" && data !== undefined && data !== " ") {
      return true;
    }
  }

  const commanPageLoadFunction = () => {
    $(function () {
      $('table')
        .on('click', '.srt-tab', function () {

          var index = $(this).index(),
            rows = [],
            thClass = $(this).hasClass('srt-ace') ? 'srt-desc' : 'srt-ace';

          $('#cstm-report-tb-display th').removeClass('srt-ace srt-desc');
          $(this).addClass(thClass);

          $('#cstm-report-tb-display tbody tr').each(function (index, row) {
            rows.push($(row).detach());
          });

          rows.sort(function (a, b) {
            var aValue = $(a).find('td').eq(index).text(),
              bValue = $(b).find('td').eq(index).text();

            return aValue > bValue
              ? 1
              : aValue < bValue
                ? -1
                : 0;
          });

          if ($(this).hasClass('srt-desc')) {
            rows.reverse();
          }

          $.each(rows, function (index, row) {
            $('#cstm-report-tb-display tbody').append(row);
          });
        });
    });

  }

  useEffect(() => {

    commanPageLoadFunction();

    setCount(100);
  }, []);

  const OnClickAllCheckbox = (e) => {

    if (e.target.checked) {
      setChecked(true)
      var selectedId = [];
      var selectedTicketNumber = [];

      var allSelectedBtn = props.products.filter((data) => {

        if (data.status === "Valid") {
          selectedId.push(data.id);
          selectedTicketNumber.push(" " + data.ticket_no);
        }

      });


      setSelectedTicketNumber(selectedTicketNumber)
      setSelectedId(selectedId)
    } else {
      setChecked(false);
      setSelectedId([]);
      setSelectedTicketNumber([])
    }

    $('.comman-pl-csmt').prop('checked', !$('.comman-pl-csmt').prop('checked'));


  }

  const onChangeCheckEvents = (e) => {
    setChecked(false)
    var selectedId = showSelectedId;
    var selectedIdAll = showSelectedIdModifyAmount;
    var selectedTicket = showSelectedTicketNumber;


    if (e.target.checked) {

      if (e.target.value === "Valid") {
        selectedId.push(e.target.id);
        selectedTicket.push(" " + e.target.dataset.ticket_no);
      }
    } else {
      if (e.target.value === "Valid") {

        var carIndex = showSelectedId.indexOf(e.target.id);
        selectedId.splice(carIndex, 1);

        var ticketIndex = showSelectedTicketNumber.indexOf(e.target.dataset.ticket_no);
        selectedTicket.splice(ticketIndex, 1);
      }

    }

    setSelectedId(selectedId)
    setSelectedTicketNumber(selectedTicket)

  }

  const OnClickCancelCitation = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("")
      setErrorMessage("")
      setCancelCitationComment("")
      setCancelCitation(true);
    }
  }

  const OnClickModifyAmount = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("");
      setErrorMessage("");
      setModifyNewAmount("");
      setModifyAmountReason("");
      setModifyAmountComments("");
      setModifyAmount(true);
    }
  }

  function convertDecimal(num) {
		return (Math.round(num * 100) / 100).toFixed(2)
	}



  return (

    <>
      {/* <div className="pl-cancel-citation-btns">
        <button className="licence-print report-mr" type="button" >Void {changeTotal}</button>
        <button className="licence-print report-mr" type="button" >Modify Amount</button>
      </div> */}
      <div className="report_tbl_scroll" id="tbl-main-download">
        <table className="table table-heading table-bordered" id="cstm-report-tb-display">
          <thead>
            <tr>

              <th className="srt-tab">
                Payment Datetime
              </th>
              <th className="srt-tab">
                Mode
              </th>
              <th className="srt-tab">Payment Source</th>
              <th className="srt-tab">
                Receipt ID
              </th>
              <th className="srt-tab">
                {changeTotal} Datetime
              </th>
              <th className="srt-tab">
                {changeTotal} Number
              </th>
              <th className="srt-tab">
                Plate
              </th>
              <th className="srt-tab">
                State
              </th>
              <th className="srt-tab">
                Cite Amount
              </th>
              <th className="srt-tab">
                Paid amount
              </th>
              <th className="srt-tab">
              Balance Due
              </th>
              <th className="srt-tab">
                Block
              </th>
              <th className="srt-tab">
                Street
              </th>
              <th className="srt-tab">
                Zone
              </th>
              <th className="srt-tab">
                Vio Code
              </th>
              <th className="srt-tab">
                Description
              </th>
            </tr>

          </thead>
          <tbody>
            {props.products.map((item, index) => (
              console.log(item,'item'),
              <tr key={item?.id}>


                {/* <td><a data-id={item.header_details.citation_number} onClick={onClickDetailsPage}>{(new Date(item.payment_details.payment_datetime).toLocaleString()).substring(0, 10) + " " + (item.payment_details.payment_datetime).substring(11, 19)}</a></td>  */}
                  <td><a data-id={item?.header_details?.citation_number} onClick={onClickDetailsPage}>
                    {item?.payment_data && item?.payment_data.length > 0 && moment(item?.payment_data[0]?.payment_datetime.split('T')[0]).format('MM/DD/YYYY') + " " + (item?.payment_data[0]?.payment_datetime).substring(11, 19)}
                  </a></td>

                {item?.payment_details?.payment_mode !== "" && item?.payment_details?.payment_mode !== null && item?.payment_details?.payment_mode !== "null" && item?.payment_details?.payment_mode !== undefined ?
                  <td>{item?.payment_details?.payment_mode}</td>
                  :
                  <td>-</td>
                }
                 {item?.payment_data && item?.payment_data.length > 0 && item?.payment_data[0].payment_category ? (
                  <td>{item?.payment_data[0].payment_category}</td>
                ) : (
                  <td>-</td>
                )}
                {item?.payment_details?.receipt_id !== "" && item?.payment_details?.receipt_id !== null && item?.payment_details?.receipt_id !== "null" && item?.payment_details?.receipt_id !== undefined ?
                  <td>{item?.payment_details?.receipt_id}</td>
                  :
                  <td>-</td>
                }

                {/* <td>{(new Date(item.citation_start_timestamp).toLocaleString()).substring(0, 10) + " " + (item.citation_start_timestamp).substring(11, 19)}</td>  */}
                <td>{moment(item?.citation_start_timestamp?.split('T')[0]).format('MM/DD/YYYY') + " " + (item?.citation_start_timestamp).substring(11, 19)}</td>

                {validateData(item?.ticket_no) ?
                  <td>{item?.ticket_no}</td>
                  :
                  <td>-</td>
                }

                {validateData(item?.lp_number) ?
                  <td>{item?.lp_number}</td>
                  :
                  <td>-</td>
                }

                {validateData(item?.vehicle_details?.state) ?
                  <td>{item?.vehicle_details.state}</td>
                  :
                  <td>-</td>
                }


                {validateData(item?.fine_amount) ?
                  <td>${convertDecimal(item?.fine_amount)}</td>
                  :
                  <td>-</td>
                }

                {item?.payment_data && item?.payment_data.length > 0 && validateData(item?.payment_data[0].paid_amount) ?
                  <td>${convertDecimal(item?.payment_data[0].paid_amount)}</td>
                  :
                  <td>-</td>
                }
                {<td>${convertDecimal(item.balance_due)}</td>}

                {validateData(item?.location?.block) ?
                  <td>{item?.location?.block}</td>
                  :
                  <td>-</td>
                }
                {validateData(item?.location?.street) ?
                  <td>{item?.location?.street}</td>
                  :
                  <td>-</td>
                }
                {validateData(item?.officer_details?.zone) ?
                  <td>{item?.officer_details?.zone}</td>
                  :
                  <td>-</td>
                }
                {validateData(item?.violation_details?.code) ?
                  <td>{item?.violation_details?.code}</td>
                  :
                  <td>-</td>
                }
                {validateData(item?.violation_details?.description) ?
                  <td>{item?.violation_details?.description}</td>
                  :
                  <td>-</td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>

  );
};

export default ProductTable;
