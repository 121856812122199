import React, { useRef, useState } from 'react';
import Editor from './QuillHandlers';

const QuillEditor = ({ value, onChange }) => {
  const [readOnly, setReadOnly] = useState(false);
  const editorRef = useRef(null);

  const handleTextChange = () => {
    const note = editorRef.current.getText();
    onChange(note);
  };

  const handleSelectionChange = (range) => {
    setReadOnly(range === null);
  };

  return (
    <Editor
      ref={editorRef}
      readOnly={readOnly}
      defaultValue={value}
      onTextChange={handleTextChange}
      onSelectionChange={handleSelectionChange}
    />
  );
};

export default QuillEditor;