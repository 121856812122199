const ImagePreview = ({url, closeModel}) => {
  return (
    <>
      <div
        className={`cstm-choose-pmt-model popup-image-pre modal fade modal-backdrop show`}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button className="cstm-payment-close" onClick={closeModel}>
                ×
              </button>
              <div className="popup-image-pre-body">
                <img src={url} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImagePreview;
