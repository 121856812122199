import { useState } from "react";
import ReportComponent from "../components/ReportComponent";
import ReportingSetup from "../../../../Services/main.service";
import { changetotal } from "../../../../Global/site";

const AmendCitationComponent = () => {
  const changeTotal = changetotal();
  const [reports, setReports] = useState([]);
  const [showAuthToken, setShowAuthToken] = useState(false);
  const [errorHandling, setErrorHandling] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isImages, setImages] = useState([]);

  // fetch data for table based on filter and pagination 
  
  const fetchData = (payload) => {
    setLoading(true)
    ReportingSetup.reportTicket(payload)
      .then((response) => {
        if (
          response.data.data.citations === null ||
          response.data.data.citations?.length === 0
        ) {
          setLoading(false);
          setReports([])
        }else {
          setLoading(false);
          setReports(response.data.data.citations);
          setImages(response.data.data)
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        if (error?.response?.status === 401) {
          setErrorMessage(error.response?.data?.message);
          setShowAuthToken(true);
        } else {
          setErrorHandling(true);
          setErrorMessage(error?.response?.data?.message);
        }
      });
  };

  // this array render the table headers

  const tableHeadings = [
    "checkbox",
    "Action",
    "Images",
    `${changeTotal} Issue Date Time`,
    `${changeTotal} #`,
    "Block",
    "Street",
    "State",
    "RO Status",
    "License Plate",
    "Violation Description",
    "Violation Code",
    "Remark 1",
    "Remark 2",
    "Note 1",
    "Note 2",
  ];

  // this arrray should be created after looking at the response for each API to define how data is rendered in table
  const keySequence = [
    "id",
    "citation_id",
    "images",
    "citation_start_timestamp",
    "ticket_no",
    "location.block",
    "location.street",
    "vehicle_details.state",
    "ro_request_status",
    "lp_number",
    "violation_details.description",
    "violation_details.code",
    "comment_details.remark_1",
    "comment_details.remark_2",
    "comment_details.note_1",
    "comment_details.note_2"
  ];
  return (
    <ReportComponent
      loading={loading}
      error={error}
      errorHandling={errorHandling}
      errorMessage={errorMessage}
      reports={reports}
      showAuthToken={showAuthToken}
      fetchData={fetchData}
      tableHeadings={tableHeadings}
      keySequence={keySequence}
      editable={true}
      editPageUrl={"/editCitation"}
      isImages={isImages}
    />
  );
};

export default AmendCitationComponent;
