import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// utils components
import { state } from "../Common/dropDownOptions";
// library
import Select from "react-select";
//images
import Eororicon from "../../Images/error-icon.png";
import BackbuttinIMag from "../../Images/back.png";
import pdf from "../../Images/pdf-dummy.png";
import doc from "../../Images/doc-dummy.png";
//services
import dashboardService from "../../Services/dashboard.service";

import { changetotal, siteid, regexEmail } from "../../Global/site";
import { refreshTokenMiddleware } from "../customMiddelware";
import VehicelDetailForm from "../../utile/userForm/vehicelDetailForm";
import ErrorModalComponent from "../../utile/modals/errorModal";
import ContactInfoForm from "../../utile/userForm/contactInfoForm";

const idsite = siteid();
const changeTotal = changetotal();

let id_site = idsite;
let ValueData = "";
let LabelData = "";
if (
  id_site.siteinfo === "46b8a5e5-c1d0-420e-b825-c09712ee8665" ||
  id_site.siteinfo === "267ccc30-1bdd-11ed-8edb-e6f96eaf3014"
) {
  ValueData = "WA";
  LabelData = "WASHINGTON";
} else if (
  id_site.siteinfo === "a98dcd6a-cfc2-11ed-ad98-66070e279278" ||
  id_site.siteinfo === "33370b0e-e5fc-11ed-8b64-665980bd7c45" ||
  id_site.siteinfo === "68ea3b5c-172f-4f33-8d55-e76c301ad212" ||
  id_site.siteinfo === "cdb0262e-22ed-11ee-bd8f-42803422fd7d" ||
  id_site.siteinfo === "7b9fdc62-22ed-11ee-b4ca-ae854bfaee9a"
) {
  ValueData = "SC";
  LabelData = "SOUTH CAROLINA";
} else if (
  id_site.siteinfo === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" ||
  id_site.siteinfo === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45"
) {
  ValueData = "CT";
  LabelData = "CONNECTICUT";
} else if(
  id_site.siteinfo === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3" ||
  id_site.siteinfo === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8"
) {
    ValueData = "MI";
    LabelData = "MICHIGAN";
} else if(
  id_site.siteinfo === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
  id_site.siteinfo === "da3c1178-a07e-11ee-8147-56d1ee6b2413") {
    ValueData = "MD";
    LabelData = 'MARYLAND';
} else {
  ValueData = "CA";
  LabelData = "CALIFORNIA";
}

const initialvalues = {
  site_id: "",
  address: "",
  citation_number: "",
  citation_issue_date: "",
  violation_code: "",
  vehicle_reg_state: "",
  vin_number: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  city: "",
  state: { value: ValueData, label: LabelData },
  zip: "",
  telephone: "",
  email: "",
  appeal_reason: "",
  appeal_additional_reason: "",
  attachments: [],
  termCondition: false,
  ownership_type: "",
  owned_till: "",
  owned_from: "",
  sms_notification: false,
  email_notification: false,
  plate_number: ""
};
const errorvalues = {
  site_id: "",
  address: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  city: "",
  state: "",
  zip: "",
  telephone: "",
  email: "",
  appeal_reason: "",
  appeal_additional_reason: "",
  attachments: "",
  termCondition: false,
};

const AppealCreate = () => {
  const { byId } = useSelector((state) => state.other);
  const { auth } = useSelector((state) => state.auth);
  const location = useLocation();
  const showSideId = siteid();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputValues, setInputValues] = useState(initialvalues);
  const [inputErrors, setInputErrors] = useState(errorvalues);
  const [reason, setReason] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [displayMsg, setDisplayMsg] = React.useState("");

  const [filesPreview, setFilesPreview] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const showDropdownReason = () => {
    if (showSideId.siteinfo === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c" || showSideId.siteinfo === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2") {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    const byId = location?.state || null;
    if (byId) {
      inputValues.site_id = showSideId.siteinfo;
      inputValues.citation_number = byId.citation_number;
      inputValues.citation_issue_date = byId.issue_date;
      inputValues.violation_code = byId.violation_description;
      inputValues.vehicle_reg_state = byId.state;
      inputValues.vin_number = byId?.vin_number;
      inputValues.plate_number = byId.plate;

      inputValues.first_name = auth.first_name;
      inputValues.last_name = auth.last_name;
      inputValues.telephone = auth.phone_number;
      inputValues.email = auth.email;

      inputValues.city = auth.city;
      inputValues.zip = auth.zip_code;
      inputValues.address = auth?.address

      setInputValues({ ...inputValues });

      getAppealReasonlist();
    } else {
      navigate("/search-result");
    }
  }, [byId]);

  const getAppealReasonlist = () => {
    dashboardService
      .getAppealResonList()
      .then(async (response) => {
        if (response.data.status) {
          const resonlist = response.data.data.map((item) => {
            return { value: item.title, label: item.title };
          });
          setReason(resonlist);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          getAppealReasonlist();
        } else {
          setOpen(true);
          setDisplayMsg("Error: " + err.response.data.error || err.message);
        }
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputValues({ ...inputValues, [name]: value });
    if (name === "telephone") {
      if (value.length < 10) {
        setInputErrors({
          ...inputErrors,
          [name]: "Telephone should be of 10 Digits.",
        });
      } else {
        setInputErrors({ ...inputErrors, [name]: "" });
      }
    } else if (name === "email") {
      if (!regexEmail.test(value)) {
        setInputErrors({ ...inputErrors, [name]: "Email is not valid." });
      } else {
        setInputErrors({ ...inputErrors, [name]: "" });
      }
    } else {
      if (value === "" && name !== "middle_name") {
        setInputErrors({
          ...inputErrors,
          [name]: `Enter ${name.replace(/_/g, " ")}`,
        });
      } else {
        setInputErrors({ ...inputErrors, [name]: "" });
      }
    }
  };

  const handleSelectChanges = (option, name) => {
    setInputValues({ ...inputValues, [name]: option });

    if (option) {
      setInputErrors({ ...inputErrors, [name]: "" });
    } else {
      setInputErrors({
        ...inputErrors,
        [name]: `Select ${name.replace(/_/g, " ")}`,
      });
    }
  };

  const onChangeTermCondition = ({ name, checked }) => {
    setSelectedCheckBox((prev) => (checked ? prev + 1 : prev - 1));
  };

  const handleAppealSubmit = async (e) => {
    e.preventDefault();
    let isValid = false;
    Object.entries(inputValues).forEach(([key, value]) => {
      if (
        key !== "termCondition" &&
        key !== "attachments" &&
        key !== "middle_name" &&
        key !== "ownership_type" &&
        key !== "owned_till" &&
        key !== "owned_from" &&
        key !== "email_notification" &&
        key !== "sms_notification" && 
        key !== "vin_number" && 
        key !== "vehicle_reg_state" && 
        key !== "appeal_reason"
      ) {
        if (value === "" || !value || value.length === 0) {
          isValid = true;
          if (key === "appeal_reason" || key === "state") {
            inputErrors[key] = `Select ${key.replace(/_/g, " ")}`;
          } else if (key === "site_id") {
            inputErrors[key] = `Site id is empty`;
          } else {
            inputErrors[key] = `Enter ${key.replace(/_/g, " ")}`;
          }
        }
      } else if (key === "appeal_reason") {
        if (showDropdownReason()) {
          if (value === '' || !value) {
            inputErrors[key] = `Select Appeal reason`;
            isValid = true;
          }
        }
      }

      if (key === "violation_code") {
        isValid = false;
      }
    });

    if (selectedCheckBox !== 4) {
      inputErrors.termCondition = "Must select all terms and conditions.";
    } else {
      inputErrors.termCondition = "";
    }
    setInputErrors({ ...inputErrors });

    if (!isValid) {
      setIsLoading(true);

      let allLinks = [];
      if (filesUpload.length > 0) {
        // for uploading media
        const formData = new FormData();
        let data_u = [];
        for (let i = 0; i < filesUpload.length; i++) {
          data_u.push(`${inputValues.citation_number}_${i + 1}`);
          formData.append("files", filesUpload[i]);
        }
        formData.append("data", data_u);

        formData.append("upload_type", "AppealImage");

        // call api for upload all media
        const upload_result = await dashboardService.uploadAppealFiles(
          formData
        );

        if (upload_result.data.status) {
          allLinks = upload_result.data.data[0].response.links;
        }
      }

      let payload = { ...inputValues };
      payload.appeal_reason = inputValues.appeal_reason["value"];
      payload.state = inputValues.state["value"];
      // payload.citation_number = byId.citation_id
      payload.citation_issue_date = inputValues.citation_issue_date;
      payload.attachments = allLinks;
      payload.user_consent = true;
      payload.plate_number = inputValues.plate_number;
      payload.address = inputValues.address;

      delete payload.termCondition;

      if (
        inputValues.ownership_type === "" ||
        inputValues.ownership_type === "Other" ||
        inputValues.ownership_type === "Disputing Party"
      ) {
        delete payload.owned_from;
        delete payload.owned_till;
      }
      if (
        !inputValues.owned_from ||
        inputValues.owned_from === ""
      ) {
        delete payload.owned_from;
      }
      if (
        !inputValues.owned_till ||
        inputValues.owned_till === ""
      ) {
        delete payload.owned_till;
      }

      // return
      // call api for create appeal
      appealcreatefun();
      function appealcreatefun() {
        dashboardService
          .createAppeal(payload)
          .then((res) => {
            if (res.data.status) {
              dispatch({ type: "CITATION_SEARCH_DATA", payload: null });
              setIsLoading(false);
              navigate("/appeal-report");
            } else {
              setIsLoading(false);
              setDisplayMsg(res.data.message || "Something went wrong.");
              setOpen(true);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealcreatefun();
            } else {
              setIsLoading(false);
              setDisplayMsg(
                err?.response?.data?.message || "Something went wrong."
              );
              setOpen(true);
            }
          });
      }
    } else {
      // for scroll to error field and focus for that error field
      let namerr = []
      Object.entries(inputErrors).forEach(([key, value]) => {
        if (value !== '') {
          namerr.push(key)
        }
      })
      if (namerr.length) {
        const input = document.querySelector(
          `input[name=${namerr[0]}]`
        );
        input.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        input.focus({ preventScroll: true });
      }
      setIsLoading(false);
      // end
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }

  const handleUploadMedia = (e) => {
    const targetFiles = e.target.files;
    let imgObj = [];
    let selectedFIles = [];
    for (var f = 0; f < targetFiles.length; f++) {
      const url = URL.createObjectURL(targetFiles[f]);
      const filesplit = targetFiles[f].name.split(".").pop();
      var imageExtension = [
        "png",
        "PNG",
        "jpg",
        "JPG",
        "jpeg",
        "apng",
        "webp",
        "doc",
        "docx",
        "pdf",
        "JPEG",
      ];
      if (imageExtension.includes(filesplit)) {
        const name = [
          "png",
          "PNG",
          "JPG",
          "jpg",
          "jpeg",
          "apng",
          "JPEG",
          "webp",
        ].includes(filesplit)
          ? "image"
          : ["doc", "docx"].includes(filesplit)
          ? "doc"
          : "pdf";
        selectedFIles.push({
          url: url,
          type: name,
          name: targetFiles[f].name,
        });
        imgObj.push(targetFiles[f]);
        inputErrors.attachments = "";
      } else {
        inputErrors.attachments = "Upload only PDF, Image Files, Word File";
      }
      // imageExtension.includes(filesplit) && selectedFIles.push(url) && imgObj.push(targetFiles[f]) ? inputErrors.attachments = "" : inputErrors.attachments = "Upload only PDF, Image Files, Word File"
      setInputErrors({ ...inputErrors });
    }
    setFilesUpload([...filesUpload, ...imgObj]);

    setFilesPreview([...filesPreview, ...selectedFIles]);
  };

  const handleImageDlt = (e, index) => {
    e.preventDefault();

    filesUpload.splice(index, 1);
    setFilesUpload([...filesUpload]);
    filesPreview.splice(index, 1);
    setFilesPreview([...filesPreview]);
  };

  const handleCloseModel = () => {
    setOpen(false);
  };

  function pad2(n) {
    return (n < 10 ? "0" : "") + n;
  }

  const dateFormatUTC = (dates) => {
    var date = new Date(dates);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";
    return currentTimeInSeconds;
  };

  const handleMoreChange = (type, event) => {
    if (type === "owned_till" || type === "owned_from") {
      let newFormValues = { ...inputValues };
      newFormValues[type] = dateFormatUTC(event);
      setInputValues(newFormValues);
    } else if (type === "email_notification" || type === "sms_notification") {
      let newFormValues = { ...inputValues };
      newFormValues[event.target.name] = event.target.checked;
      setInputValues(newFormValues);
    } else if (type === "ownership_type") {
      let newFormValues = { ...inputValues };
      newFormValues[type] = event.target.value;
      setInputValues(newFormValues);
    } else {
      const { name, value } = event.target;
      let newFormValues = { ...inputValues };
      newFormValues[name] = value;
      setInputValues(newFormValues);
    }
  };

  return (
    <>
      <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
        <div className="container-fluid back-link1 cstm-appealcitation-back1">
          <div className="row">
            <div className="col-12 back-link">
              <a onClick={() => navigate(-1)}>
                <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                Back
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-12 login-align">
              <div className="appeal-create-section main-login-sec cstm-appealcitationform-login-section">
                <h3>Appeal {changeTotal}</h3>
                <p></p>
                <p></p>
                <div className="">
                  <form onSubmit={handleAppealSubmit}>
                    <div>
                      <div>
                        <h4>{changeTotal} Information</h4>
                      </div>
                      <span className="errormessage">
                        {inputErrors.site_id}
                      </span>
                      <div className="row">
                        <div className="col-4 form-group formgroup-main">
                          <label htmlFor="">{changeTotal} number</label>
                          <input
                            type="text"
                            name="citation_number"
                            className="form-control"
                            value={inputValues.citation_number}
                            readOnly
                          />
                        </div>
                        <div className="col-4 form-group formgroup-main">
                          <label htmlFor="">Date {changeTotal} Issued</label>
                          <input
                            type="text"
                            name="citation_issue_date"
                            className="form-control"
                            value={formatDate(inputValues.citation_issue_date)}
                            readOnly
                          />
                        </div>
                        <div className="col-4 form-group formgroup-main">
                          <label htmlFor="">Violation Description</label>
                          <input
                            type="text"
                            name="violation_code"
                            className="form-control"
                            value={inputValues.violation_code}
                            readOnly
                          />
                        </div>
                        <div className="col-4 form-group formgroup-main">
                          <label htmlFor="">Vehicle license plate</label>
                          <input
                            type="text"
                            name="plate_number"
                            className="form-control"
                            value={inputValues.plate_number}
                            readOnly
                          />
                        </div>
                        {inputValues.vin_number && <div className="col-4 form-group formgroup-main">
                          <label htmlFor="">Vin Number</label>
                          <input
                            type="text"
                            name="vin_number"
                            className="form-control"
                            value={inputValues.vin_number}
                            readOnly
                          />
                        </div>}
                        <div className="col-4 form-group formgroup-main">
                          <label htmlFor="">Vehicle License plate state</label>
                          <input
                            type="text"
                            name="vehicle_reg_state"
                            className="form-control"
                            value={inputValues.vehicle_reg_state}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h4>Personal Information</h4>
                      <ContactInfoForm
                        handleInputChange={handleInputChange}
                        handleOptionChange={handleSelectChanges}
                        inputError={inputErrors}
                        inputValue={inputValues}
                        isAppeal={true}
                      />
                      {/* <div>
                        <h4>Ownership Information</h4>
                        <VehicelDetailForm
                          element={inputValues}
                          handleMoreChange={handleMoreChange}
                        />
                      </div> */}

                      <div>
                        <h4>Reason for Request</h4>
                        <div className="row">
                          <div className="col-12 form-group formgroup-main">
                            <label htmlFor="">Additional Reason *</label>
                            <textarea
                              name="appeal_additional_reason"
                              className="form-control"
                              placeholder="appeal additional reason"
                              value={inputValues.appeal_additional_reason}
                              onChange={handleInputChange}
                            />
                            <span className="errormessage">
                              {inputErrors.appeal_additional_reason}
                            </span>
                          </div>
                          {showDropdownReason() && <div className="col-12 form-group formgroup-main">
                            <label htmlFor="">Choose Reason *</label>
                            <Select
                              name="appeal_reason"
                              options={reason}
                              selectedOption="Active"
                              onChange={(e) =>
                                handleSelectChanges(e, "appeal_reason")
                              }
                              searchable
                              value={inputValues.appeal_reason || ""}
                            />
                            <span className="errormessage">
                              {inputErrors.appeal_reason}
                            </span>
                          </div>}
                          <div className="col-12 form-group formgroup-main">
                            <label htmlFor="attachments">Choose File </label>
                            <input
                              type="file"
                              name="attachments"
                              id="attachments"
                              onChange={handleUploadMedia}
                              multiple
                              accept=".doc,.docx,application/pdf,.jpeg,.png,.jpg,.pjp,.webp,.apng"
                            />
                            <span className="errormessage">
                              {inputErrors.attachments}
                            </span>
                            <div className="cstm-appeal-preview-images">
                              {filesPreview?.map((item, nm) => (
                                <div key={nm} className={`appeal-preview-img`}>
                                  {item.type === "image" ? (
                                    <img
                                      src={`${item.url}`}
                                      alt={`preview-${nm}`}
                                    />
                                  ) : item.type === "doc" ? (
                                    <img src={`${doc}`} alt={`preview-${nm}`} />
                                  ) : (
                                    <img src={`${pdf}`} alt={`preview-${nm}`} />
                                  )}
                                  {/* <span>{item.name}</span> */}
                                  <button
                                    className="cstm-appeal-close"
                                    onClick={(e) => handleImageDlt(e, nm)}
                                  >
                                    ×
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="errormessage">
                        {inputErrors.site_id}
                      </span>
                      <div className="cstm-tc">
                        <h4>Terms and Conditions</h4>
                        <div className="cstm-cb-lt">
                          <div className="cstm-checkbox-col">
                            <div className="cstm-input-checkbox">
                              <input
                                type="checkbox"
                                name="term1"
                                onChange={(e) =>
                                  onChangeTermCondition(e.target)
                                }
                              />
                              I understand that only the registered owner or
                              driver of the vehicle receiving this {changeTotal}{" "}
                              may appeal the {changeTotal} and confirm that I am
                              the registered owner or driver of the vehicle
                              issued this {changeTotal}.
                            </div>
                          </div>
                          <div className="cstm-checkbox-col">
                            <div className="cstm-input-checkbox">
                              <input
                                type="checkbox"
                                name="term2"
                                onChange={(e) =>
                                  onChangeTermCondition(e.target)
                                }
                              />
                              I understand that if I do not fully complete
                              appeal form with correct information including
                              address and/or email address. I will not receive
                              any notifications regarding the status of this
                              appeal.
                            </div>
                          </div>
                          <div className="cstm-checkbox-col">
                            <div className="cstm-input-checkbox">
                              <input
                                type="checkbox"
                                name="term3"
                                onChange={(e) =>
                                  onChangeTermCondition(e.target)
                                }
                              />
                              I have read the terms and conditions completely
                              and agree .
                            </div>
                          </div>
                          <div className="cstm-checkbox-col">
                            <div className="cstm-input-checkbox">
                              <input
                                type="checkbox"
                                name="term4"
                                onChange={(e) =>
                                  onChangeTermCondition(e.target)
                                }
                              />
                              Under the penalty of perjury, I declare that the
                              information being submitted is true to the best of
                              my knowledge.
                            </div>
                          </div>
                        </div>
                        <span className="errormessage cstm-tm-error mb-2">
                          {inputErrors.termCondition}
                        </span>
                        <br />
                      </div>
                    </div>

                    <div className="row cstm-row-register appealciation-submit-button">
                      <div className="col-2 button2">
                        <button
                          className="btn"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <div className="cstm-loader">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          )}
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <ErrorModalComponent
          handleCloseModel={handleCloseModel}
          displayMsg={displayMsg}
        />
      )}
    </>
  );
};

export default AppealCreate;
