function PageNotFound() {

    return (
        <>
            <div className="wrapper citycitiation">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 col-12 login-align login-align-search right-panel cstm-right-panel  search-tit-only">
                            <div className="login-section main-login-sec">
                                <h1>404</h1>
                                <h6>Page not found</h6>
                                <p>You are not allowed to access this page</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default PageNotFound;