import React, { useState } from "react";
import close from "../../Images/close.svg";
import TicketReportDetails from "../../Services/dashboard.service";
import { changetotal } from "../../Global/site";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { siteid } from "../../Global/site";

const VoidCitationModal = ({
	showSelectedId,
	showSelectedTicketNumber,
	closeError,
	setCancelCitation,
	updateData,
	setSelectedId,
	backButtonClick,
}) => {
	const changeTotal = changetotal();
	const [showSuccessMessage, setSuccessMessage] = useState("");
	const [showCancelCitationComment, setCancelCitationComment] = useState("");
	const [showCommentEmptyMessage, setCommentEmptyMessage] =
		useState("");
	const [showErrorMessage, setErrorMessage] = useState("");
	const [showCancelCitationReason, setCancelCitationReason] = useState("CUSTOMER SUPPORT CANCEL");
	const [isLoading, setIsLoading] = useState(false);
	const siteId = siteid().siteinfo;

	const getOptionsBySiteId = (siteId) => {
		const commonOptions = [
			{ value: 'CUSTOMER SUPPORT CANCEL', label: 'CUSTOMER SUPPORT CANCEL' },
			{ value: 'MANAGER CANCEL', label: 'MANAGER CANCEL' },
			{ value: 'SUPERVISOR CANCEL', label: 'SUPERVISOR CANCEL' },
			{ value: 'PLATE NUMBER TRANSPOSED', label: 'PLATE NUMBER TRANSPOSED' },
			{ value: 'CLIENT APPROVED', label: 'CLIENT APPROVED' },
			{ value: 'OFFICER ERROR', label: 'OFFICER ERROR' },
			{ value: 'SYSTEM ERROR', label: 'SYSTEM ERROR' },
		];

		const kalamazooOptions = [
			{ value: 'COURTESY VOID', label: 'COURTESY VOID' },
			{ value: 'HANDICAP VOID', label: 'HANDICAP VOID' },
		];

		const parkWhizOptions = [
			{ value: 'PARK WHIZ', label: 'PARK WHIZ' },
		];

		const hartfordOptions = [
			{ value: 'CUSTOMER ERROR - PAID FOR PARKING WITH INCORRECT INFORMATION', label: 'CUSTOMER ERROR - PAID FOR PARKING WITH INCORRECT INFORMATION' },
			{ value: 'FIVE MINUTE GRACE PERIOD', label: 'FIVE MINUTE GRACE PERIOD' },
			{ value: 'LAW ENFORCEMENT - VERIFIED OFFICIAL DUTY', label: 'LAW ENFORCEMENT - VERIFIED OFFICIAL DUTY' },
			{ value: 'ONE TIME COURTESY', label: 'ONE TIME COURTESY' },
			{ value: 'PAYING AT TIME OF CITATION ISSUANCE', label: 'PAYING AT TIME OF CITATION ISSUANCE' },
			{ value: 'PEA ERROR - CORRECTED BEFORE ISSUED', label: 'PEA ERROR - CORRECTED BEFORE ISSUED' },
			{ value: 'PEA ERROR - DUPLICATE CITATION', label: 'PEA ERROR - DUPLICATE CITATION' },
			{ value: 'PEA ERROR - INCORRECT CITATION INFORMATION', label: 'PEA ERROR - INCORRECT CITATION INFORMATION' },
			{ value: 'PEA ERROR - PAID FOR PARKING', label: 'PEA ERROR - PAID FOR PARKING' },
			{ value: 'TEST TICKET', label: 'TEST TICKET' },
			{ value: 'OTHER - SEE NOTES', label: 'OTHER - SEE NOTES' },
			{ value: 'REVERSE VOID', label: 'REVERSE VOID' },
			{ value: 'PEA ERROR', label: 'PEA ERROR' },
		];

		if (siteId === 'e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8' || siteId === '183db4ec-a15e-11ee-b39a-bebc79a4d1a3') {
			return [...commonOptions, ...kalamazooOptions];
		} else if (siteId === '0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d' || siteId === '5bd20490-dc2f-11ee-bf9e-72127457a419') {
			return hartfordOptions;
		} else {
			return [...commonOptions, ...parkWhizOptions];
		}
	};

	const optionsList = getOptionsBySiteId(siteId);

	const onChangeCancelCitation = (e) => {
		if (e.target.name === "select_reason") {
			setCancelCitationReason(e.target.value);
		} else if (e.target.name === "comment") {
			setCancelCitationComment(e.target.value);
			setCommentEmptyMessage("");
		}
	};
	// While Cancel Citation
	const OnClickCancelButton = () => {
		if (showCancelCitationComment === "") {
			setCommentEmptyMessage("Enter comments");
		} else {
			setCommentEmptyMessage("");
			var data = {
				citation_ids: showSelectedId,
				status: "Cancelled",
				status_reason: showCancelCitationReason,
				status_comments: showCancelCitationComment,
			};

			setIsLoading(true);
			TicketReportDetails.updateStatusCitationArrayBulk(
				data
			).then(
				(response) => {
					if (response.data.success === true) {
						// setSuccessMessage(response.data.message);
						toast.success(response.data.message);
						setErrorMessage("");
						backButtonClick();
						setTimeout(() => {
							setCancelCitation(false);
							setSelectedId([]);
							//   setSelectedTicketNumber([]);

							return (
								updateData && updateData.updateValue(false)
							);
						}, 2000);
					}
				},
				(error) => {
					if (error.response.status === 404) {
						toast.error(error.response.data.message);
						setTimeout(() => {
							setCancelCitation(false);
						}, 2000);
					}
					setIsLoading(false);
				}
			);
		}
	};

	return (
		<>
			<ToastContainer />
			<div className='cstm-site-model'>
				<div className='cstm-site-model-block fixed z-20'>
					<div className='cstm-site-popup-bg'>
						<div className='cstm-site-model-wrapper auth-taken-cstm-model-wrapper'>
							<button
								onClick={closeError}
								className='cstm-close-btn-db-is citation-cls'
							>
								<img
									src={close}
									alt='close'
								/>
							</button>
							<div>
								<p className='cancel-citation-ttl-main'>
									Cancel {changeTotal}
								</p>
								<span className='auth-error-msg-success'>
									{showSuccessMessage}
								</span>
								<span className='auth-error-msg'>
									{showErrorMessage}
								</span>
								<span className='cancel-title-main'>
									Selected {changeTotal} Numbers
								</span>
								<textarea
									type='text'
									className='commet-citation-cancel'
									value={showSelectedTicketNumber}
									disabled
								/>
								<br />
								<span className='cancel-title-main'>
									Cancel {changeTotal} Reason
								</span>
								<select
									className='form-select-site'
									name='select_reason'
									value={showCancelCitationReason}
									onChange={onChangeCancelCitation}
								>
									{optionsList.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
								<br />
								<br />
								<span className='cancel-title-main'>
									Cancel {changeTotal} Comments
								</span>
								<textarea
									name='comment'
									placeholder='Enter Comments'
									value={showCancelCitationComment}
									onChange={onChangeCancelCitation}
									className='commet-citation-cancel'
								/>
								<span className='auth-error-msg auth-error-comment-msg'>
									{showCommentEmptyMessage}
								</span>
								<div className='make-payment-button justify-content-between'>
									<button
										className='btn'
										onClick={OnClickCancelButton}
									>
										{isLoading && (
											<div className='void-loading-main'>
												<span
													className='spinner-border'
													role='status'
													aria-hidden='true'
												></span>
											</div>
										)}
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*    )} */}
		</>
	);
};

export default VoidCitationModal;
