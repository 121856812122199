import React, { useState, useEffect } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';


const options = {
  cutout: 100,
  hover: {
    mode: 'index',
    intersect: false,
  },
}
const options1 = {
  cutout: 40,
  hover: {
    mode: 'index',
    intersect: false,
  },
}

const styles = {
  graphContainer: {
    padding: '20px'
  }
}

function IssuanceCountSummary() {
  const dispatch = useDispatch()

  const [count, setCount] = React.useState(0);
  const [showType, setType] = React.useState([]);
  const [showCount, setTotalCount] = React.useState([]);
  const [showColour, setColour] = React.useState([]);
  const [showMain, setMain] = React.useState([]);
  const [showAuthToken, setAuthToken] = useState(false);
  const [showErrorHandling, setErrorHandling] = useState(false)
  const [showNoDataFound, setNoDataFound] = useState(false)
  const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

  const closeError = () => {
    setErrorHandling(false)
  }

  const onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  }

  const data = {
    // labels: ['Red'],
    datasets: [
      {
        label: '# of Votes',
        data: showCount,
        backgroundColor: showColour,
        borderWidth: 1,
      },
    ],
  };

  const data1 = {
    datasets: [
      {
        label: '# of Votes',
        data: showCount,
        backgroundColor: showColour,
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    initialissurancefun()
    function initialissurancefun() {
      Dashboard.getIssuanceSummary().then(
        (response) => {
          if (response.data.status === 300) {
            setAuthToken(true)
          } else {
            if (response.data.status === false) {
              setErrorHandling(true)
              setErrorHandleMessage(response.data.message)
            } else {
              if (response.data.data[0].metadata.consolidated_counts.length !== 0) {

                setMain(response.data.data[0].metadata.consolidated_counts)
                setType(response.data.data[0].metadata.codes)
                setTotalCount(response.data.data[0].metadata.counts)
                setColour(response.data.data[0].metadata.colors)
                setNoDataFound(false)
              } else {
                setNoDataFound(true)
              }
            }
          }
        }
      ).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          initialissurancefun()
        }
      });
    }
    setCount(100);
  }, []);

  return (
    <>

      <div className="row cstm-ics">
        <div className="cstm-ics-tile">Issuance Counts Summary</div>
        <div className="cstm-ics-mn cstm_issuance_summary">

          {!showNoDataFound ?

            <div className="cstm-ics-mn-left">
              {showMain.map((item, index) => (

                <div className="cstm-ics-mn-lf-box">
                  <span className="cstm-ics-color" style={{ background: item.color }}></span>
                  <span className="cstm-ics-name">{item.code}</span>
                  <span className="cstm-ics-number">{item.count}</span>
                </div>

              ))}
            </div>
            :
            <div className="cstm-no-data-found-msg">No Data Found</div>
          }

          <div className="cstm-ics-mn-right revenue_cstm_graph"><Doughnut options={{ options, responsive: true, }} data={data} />
            {/* <Doughnut style={styles.graphContainer}  options={options1} data={data1} /> */}

          </div>
        </div>
      </div>

    </>
  );
}

export default IssuanceCountSummary;