import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { assignsiteid } from "../../../Global/site";
import dashboardService from "../../../Services/dashboard.service";
import { refreshTokenMiddleware } from "../../customMiddelware";

const handleResourceDownload = async (url, dispatch) => {
  try {
    const site_id = assignsiteid();
    const resourceData = {
      download_type: "CitationImages",
      links: { 0: url },
      site_id
    }
    const result = await dashboardService.downloadPdfNewService(resourceData)
    return result.data.metadata
  } catch (error) {
    if (error.response.status === 401) {
      refreshTokenMiddleware(dispatch);
      handleResourceDownload(url);
    } else {
      console.error("Error downloading resource", error);
    }
  }
}

export const PdfDownloader = ({ pdfUrl }) => {
  const dispatch = useDispatch();
  const [downloadedPdf, setDownloadedPdf] = useState(null);

  useEffect(() => {
    const downloadPdf = async () => {
      const pdf = await handleResourceDownload(pdfUrl, dispatch);
      setDownloadedPdf(pdf[0].url);
    };

    if (pdfUrl) {
      downloadPdf();
    }
  }, [dispatch, pdfUrl]);

  const handleOpenPdf = () => {
    window.open(downloadedPdf, '_blank');
  };

  return downloadedPdf ? <button onClick={handleOpenPdf}>Download</button> : null;
};

export const ImageDownloader = ({ imageUrl }) => {
  const dispatch = useDispatch();
  const [downloadedImage, setDownloadedImage] = useState(null);

  useEffect(() => {
    const downloadImage = async () => {
      const image = await handleResourceDownload(imageUrl, dispatch);
      setDownloadedImage(image[0].url);
    };

    if (imageUrl) {
      downloadImage();
    }
  }, [dispatch, imageUrl]);


  const handleOpenImage = () => {
    window.open(downloadedImage, '_blank');
  };

  return downloadedImage ? <button onClick={handleOpenImage}>View Image</button> : null;
};