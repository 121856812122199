import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PermitTypeTab from "./configurationTabs/PermitTypeTab";
import PermitDetailTab from "./configurationTabs/permitDetailTab";
import PermitRulesTab from "./configurationTabs/permitRulesTab";
import PermitPaymentTab from "./configurationTabs/permitPaymentTab";
import PermitNotificationTab from "./configurationTabs/permitNotificationTab";
import PermitGeofencingTab from "./configurationTabs/permitGeofencingTab";
import PermitFormBuilderContainer from "./PermitFormBuilder/permitFormBuilderContainer";
import ErrorModalComponent from "../../../utile/modals/errorModal";
import ConfirmationModalComponent from "../../../utile/modals/confirmationModal";

const PermitConfigurationComponent = ({
  permit,
  HandlePermitSubmit,
  currentTab,
  setCurrentTab,
  payloadData,
  loading,
  responseMessage,
  responseStatus,
  isEditPermit,
  formResetHandler
}) => {
  const [showStepStatus, setStepStatus] = useState([
    "Permit Type",
    "Details",
    "Rules",
    "Payments",
    "Notifications",
    "Geofencing",
    "Configure Question",
  ]);
  const [activeTab, setActiveTab] = useState(currentTab);
  const [activePermitTitle, setActivePermitTitle] = useState("");
  const [showInactiveModal,setShowInactiveModal] = useState(false);
  const [displayMsg,setDisplayMsg] = useState('')
  const [showResetWarning,setShowResetWarning] = useState(false)
  const [residentType,setResidentType] = useState(false)

  const handleCommon = async (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  const changedPermit = (title) => {
    setActivePermitTitle(title);
  };

  const residentPermitType = (title) => {
    setResidentType(title);
  };

  useEffect(() => {
    if (isEditPermit) {
      setActivePermitTitle(payloadData.name);
      setResidentType(payloadData.resident_type);
    }
  }, [isEditPermit]);

  return (
    <>
      <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel permits-configuration-page-only">
        <div className="configure-permit-menu-main">
          <div className="cstm-ticket-manage-sec">
            <div className="cstm-ticket-manage-heading">
              <h3 className="cstm-all-pg-ttl operate-ttl">Configure Permit</h3>
            </div>
          </div>
          <div className="counter-tabination">
            <ul className="nav nav-tabs2 permit-pagination-content">
              {showStepStatus.map((step, index) => (
                <li
                  key={index}
                  className="nav-item2"
                  onClick={(e) => {
                    if (index !== 0 || !isEditPermit) {
                      if (index === 0) {
                        handleCommon(index + 1);
                      } else if (
                        payloadData.name !== "" &&
                        payloadData.description !== ""
                      ) {
                        
                        if (index === 6) {
                          if (payloadData.status === "GEO_STATUS" || payloadData.status === "ACTIVE") {
                            handleCommon(index + 1);
                          } else {
                            handleCommon(currentTab);
                            setShowInactiveModal(true);
                          }
                        } else {
                          handleCommon(index + 1);
                        }
                        // if (index === 6 && (payloadData.status !== "GEO_STATUS" ||  payloadData.status !== "ACTIVE")) {
                        //   handleCommon(currentTab);
                        //   setShowInactiveModal(true)
                        // } else {
                        //   handleCommon(index + 1);
                        // }
                      }
                    }else {
                      setShowResetWarning(true)
                      setDisplayMsg("Currently you are editing the permit, Are you sure you want to exit the page?")
                    }
                    // handleCommon(index + 1);
                  }}
                >
                  <Link
                    className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${
                      activeTab >= index + 1 ? "active" : ""
                    }`}
                  >
                    <span className="main-round-border">{`0${index + 1}`}</span>
                    <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                      {step}
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={`permit-error-un ${
            responseStatus === 200 ? "permit-error-su" : ""
          }`}
        >
          {responseMessage}
        </div>
        <div className="permits-configutation-main">
          <div className="tab-content">
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 1 ? "active show" : ""
              }`}
            >
              <PermitTypeTab
                permit={permit}
                handleTabChange={handleCommon}
                changedPermit={changedPermit}
              />
            </div>
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 2 ? "active show" : ""
              }`}
            >
              <PermitDetailTab
                HandlePermitSubmit={HandlePermitSubmit}
                handleTabChange={handleCommon}
                permitTitle={activePermitTitle}
                loading={loading}
                payloadData={payloadData}
                isEditPermit={isEditPermit}
                setDisplayMsg={setDisplayMsg}
                setShowResetWarning={setShowResetWarning}
              />
            </div>
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 3 ? "active show" : ""
              }`}
            >
              <PermitRulesTab
                HandlePermitSubmit={HandlePermitSubmit}
                handleTabChange={handleCommon}
                permitTitle={activePermitTitle}
                loading={loading}
                payloadData={payloadData}
                isEditPermit={isEditPermit}
              />
            </div>
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 4 ? "active show" : ""
              }`}
            >
              <PermitPaymentTab
                HandlePermitSubmit={HandlePermitSubmit}
                handleTabChange={handleCommon}
                permitTitle={activePermitTitle}
                loading={loading}
                payloadData={payloadData}
                isEditPermit={isEditPermit}
                permitTime={payloadData.permit_time_availability}
              />
            </div>
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 5 ? "active show" : ""
              }`}
            >
              <PermitNotificationTab
                HandlePermitSubmit={HandlePermitSubmit}
                handleTabChange={handleCommon}
                permitTitle={activePermitTitle}
                loading={loading}
                payloadData={payloadData}
                isEditPermit={isEditPermit}
              />
            </div>
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 6 ? "active show" : ""
              }`}
            >
              <PermitGeofencingTab
                HandlePermitSubmit={HandlePermitSubmit}
                handleTabChange={handleCommon}
                permitTitle={activePermitTitle}
                loading={loading}
                payloadData={payloadData}
                isEditPermit={isEditPermit}
              />
            </div>
            <div
              id="tab_0"
              className={`tab-pane fade ${
                activeTab === 7 ? "active show" : ""
              }`}
            >
              <PermitFormBuilderContainer
                permitName={payloadData.name}
                isEditPermit={isEditPermit}
                permitTime={payloadData.permit_time_availability}
                permitTitle={activePermitTitle}
                residentType={residentType}
              />
            </div>
          </div>
        </div>
      </div>
      {showInactiveModal && (
        <div>
          <ErrorModalComponent handleCloseModel={() => setShowInactiveModal(false)} displayMsg={"You can not configure the Questions for InActive Permit. To Activated the permit, First you need to configure all the 6 steps."} />
        </div>
      )}
      {showResetWarning && (
        <div>
          <ConfirmationModalComponent displayMsg={displayMsg} handleCloseModel={() => setShowResetWarning(false)} handleConfirm={() => (handleCommon(1),setShowResetWarning(false),formResetHandler())} confirmText={"Yes, I want"} />
        </div>
      )}
    </>
  );
};

export default PermitConfigurationComponent;
