import { useEffect, useState } from "react";
import { getFormattedDate, validateData } from "../../Common/utils";
import { toast } from "react-toastify";
import Service from "../../../Services/main.service";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useSelector} from 'react-redux';

const UserDetailsTab = ({ props, openModal, dispatch, ticketNo }) => {
  const [userAddress, setUserAddress] = useState([]);
  const [badAddresses, setBadAddress] = useState([]);
  const [latestAddress, setLatestAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const response = useSelector(state => state.other.response);

  const addressAttributes = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "address", label: "Address" },
    { key: "city", label: "City" },
    { key: "state", label: "State" },
    { key: "zip", label: "Zip" },
    { key: "source", label: "RO Source" },
    { key: "received_date", label: "Address Received at" },
    { key: "phone", label: "Phone"}
  ];

  useEffect(() => {
    const getUserAddress = (tikno) => {
      setLoading(true); 
      Service.getAddressesList(tikno)
        .then((res) => {
          if (validateData(res)) {
            const newBadAddresses = [];
            const newUserAddresses = [];

            res.forEach((address) => {
              if (address?.invalid_address) {
                newBadAddresses.push(address);
              } else {
                newUserAddresses.push(address);
              }

              if (address?.latest_address) {
                setLatestAddress(true);
              }
            });

            if (newBadAddresses.length > 0) {
              setBadAddress((prevArray) => [...prevArray, ...newBadAddresses]);
            }
            const uniqueNewUserAddresses = newUserAddresses.filter(address => !userAddress.find(existingAddress => existingAddress.id === address.id));

            if (uniqueNewUserAddresses.length > 0) {
              setUserAddress((prevArray) => [...prevArray, ...uniqueNewUserAddresses]);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
          }
        });
    };

    getUserAddress(ticketNo);
  }, [ticketNo, dispatch,response]);

  const handleBadAddress = async (id, isBadAddress) => {
    try {
      const data = await Service.markBadAddress(id, ticketNo, isBadAddress);
      if (data.status) {
        const message = isBadAddress ? "User address marked as bad address." : "User address removed from bad address.";
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const updatedAddress = isBadAddress ? userAddress.find((address) => address.id === id) : badAddresses.find((address) => address.id === id);
        if (isBadAddress) {
          setBadAddress((prevArray) => [...prevArray, updatedAddress]);
          setUserAddress((prevArray) => prevArray.filter((item) => item.id !== id));
        } else {
          setBadAddress((prevArray) => prevArray.filter((item) => item.id !== id));
          setUserAddress((prevArray) => [...prevArray, updatedAddress]);
        }
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
      }
      toast.error("Error occurred in processing this request", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleLatestAddress = async (id, isLatestAddress) => {
    try {
      const data = await Service.markLatestAddress(id, ticketNo, isLatestAddress);
      if (data.status) {
        const message = "User address marked as latest address.";
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const updatedAddress = userAddress.find((address) => address.id === id);
        if (updatedAddress) {
          updatedAddress.latest_address = true;
          setLatestAddress(updatedAddress);
          setUserAddress((prevArray) => [updatedAddress, ...prevArray.filter((item) => item.id !== id)]);
        } else {
          console.error(`No address found with id ${id}`);
        }
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
      }
      toast.error("Error occurred in processing this request", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const renderUserCurrentAddress = () => {
    return userAddress.map((address, index) => {
      const elements = addressAttributes.map((attr) => (
        <div
          className={`col-xl-3 col-6 result1 ${attr.key === "city" || attr.key === "state" ? "hide-col" : ""
            }`}
          key={`${attr.key}-${index}`}
        >
          <p>{attr.label}</p>
          <h5>{attr.key === "received_date" ? getFormattedDate(address?.[attr.key]) : address?.[attr.key] || "-"}</h5>
        </div>
      ));

      return (
        <>
          <div key={`address-container-${index}`} className="row col-12 mt-4">
            {elements}
          </div>
          <div
            className="cstm-submit-btn"
            key={`edit-btn-${index}`}
            style={{ textAlign: "left" }}
          >
            {props.uiElements.includes(
              "BUTTON_TICKET_MANAGEMENT_CHANGE_ADDRESS_BUTTON"
            ) && (
                <button
                  onClick={openModal}
                  data-modal="ro-modal"
                  className="btn"
                  style={{ marginRight: "10px" }}
                >
                  Change Address
                </button>
              )}
            {props.uiElements.includes(
              "BUTTON_TICKET_MANAGEMENT_MARK_AS_A_BAD_ADDRESS_BUTTON"
            ) && (
                <button
                  onClick={() => handleBadAddress(address.id, true)}
                  data-modal="ro-modal"
                  className="btn"
                >
                  Mark as bad address
                </button>
              )}

            {props.uiElements.includes("BUTTON_TICKET_MANAGEMENT_MARK_AS_A_BAD_ADDRESS_BUTTON") && !latestAddress && (
              <button
                onClick={() => handleLatestAddress(address.id, true)}
                data-modal="ro-modal"
                className="btn"
                style={{ marginLeft: "10px" }}
              >
                Mark as latest address
              </button>
            )}
          </div>
        </>
      );
    });
  };

  const renderUserBadAddress = () => {
    return badAddresses.map((address, index) => {
      const elements = addressAttributes.map((attr) => (
        <div
          className={`col-xl-3 col-6 result1 ${attr.key === "city" || attr.key === "state" ? "hide-col" : ""
            }`}
          key={`${attr.key}-${index}`}
        >
          <p>{attr.label}</p>
          <h5>{address?.[attr.key] || "-"}</h5>
        </div>
      ));

      return (
        <div key={`address-container-${index}`} className="row col-12">
          {elements}
          <div
            className="cstm-submit-btn"
            key={`edit-btn-${index}`}
            style={{ textAlign: "left" }}
          >
            <button
              onClick={() => handleBadAddress(address.id, false)}
              className="btn"
              data-modal="ro-modal"
            >
              Mark as valid address
            </button>
          </div>
        </div>
      );
    });
  };

  return (
    <>
     <div className="cstm-submit-btn m-0">
        <button
          onClick={openModal}
          data-modal="ro-modal"
          className="btn m-0"
          style={{ marginRight: "10px", float: "right" }}
        >
          Add Address
        </button>
      </div>

    {loading ? (
      <div className="col-12 text-center">
        <div className="spinner-border"></div>
      </div>
    ) : (
      <>
        {userAddress && userAddress.length > 0 &&
        <div className="row">
          <div className="col-12">
            <h4>Registered Address</h4>
            <hr />
          </div>
          {renderUserCurrentAddress()}
        </div>
}
        {badAddresses && badAddresses.length > 0 && (
          <>
            <hr />
            <div className="row">
              <div className="col-12">
                <h4>Invalid Address</h4>
              </div>
              {renderUserBadAddress()}
            </div>
          </>
        )}
      </>
    )}
  </>
  );
};

export default UserDetailsTab;