import React, { useState, useEffect } from 'react';
import OperationTotalCount from './TodayOperationCount';
import ActiveOfficerCounts from './ActiveOfficerCount';
import ProductivityAvgCount from './ProductivityAvgCount';
import IssuanceCountSummary from './IssuanceCountSummary';
import AveragePaymentMixCount from './MixAvgPayCount';
import RevenueIntegration from './RevenueIntegration';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { siteid } from '../../../Global/site';

function MainDashboard() {     
    const showSideId = siteid();
    const { siteinfo } = useSelector(state => state.auth)
    if(!siteinfo){
        return <Navigate  to='/site-list' />
    }

    return (
        <> 
            <section>
                <div className="container-fluid">
                    <div className="row" > 
                        {/* Content starts */}
                        <div className="col-lg-12 col-md-9 col-sm-12 cstm-ctn cstm-operate-top">
                        <div className="row">
                            <div className='col-12'>
                                <h3 className="cstm-all-pg-ttl operate-ttl">Dashboard</h3>
                            </div>
                        </div>
                       {/* Operation Total Count Display */}
                       <OperationTotalCount  siteid={showSideId.siteinfo} />

                         {/* <ActiveOfficer />  */}
                        <ActiveOfficerCounts/> 

                        {/* Display Productivity Average time info  */}
                        <ProductivityAvgCount /> 

                        <div className="row cstm-icspl">
                        {/* Display Issuance Count Summary  */}
                        <IssuanceCountSummary /> 

                        {/* Display Average payment latency, Active Payment count and Average Permit Details */}
                        <AveragePaymentMixCount />
                        </div>

                        {/* Display Revenue Reports and Integrations Count Information */}
                        <RevenueIntegration />

                        </div>
                        
                    </div>
            </div>
    </section> 

        </>
    );
}

export default MainDashboard;
