import React, { useState, useEffect } from 'react';
import  TotalCount from './TodayTotalCount';
import  ActiveOfficer from './ActiveOfficerCounts';
import ActiveOfficerLogs from "./OfficersLogs/TableLogs";


function OperationDashboard()
{     

    return (
        <> 
            <section>
                <div className="container-fluid">
                    <div className="row" > 
                        {/* Content starts */}
                        <div className="col-lg-12 col-md-9 col-sm-12 cstm-ctn cstm-operate-top">
                        <div className="row">
                           <div className='col-12'>
                           <h3 className="cstm-all-pg-ttl operate-ttl">Operation</h3>
                           </div>
                        </div>
                       {/* Total Count Display */}
                        <TotalCount />

                        <ActiveOfficer /> 
                        
                        {/* <ActiveOfficerLogs/> */}
                       
                        </div>
                        
                    </div>
            </div>
    </section> 

        </>
    );
}

export default OperationDashboard;
