import { useState } from "react";
import PermitConfigurationComponent from "./permitConfigurationComponent";
import mainService from "../../../Services/main.service";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useDispatch } from "react-redux";

const initialPayload = {
  name: "",
  description: "",
  type: "",
  status: "",
  is_public: false,
  comment: "",
  created_by: "",
  admin_email: "",
  support_resource_name: "",
  support_resource_email: "",
  permit_start_date: "",
  permit_end_date: "",
  permit_time_availability: {
    monthly: false,
    daily: false,
    annually: false,
    quarterly: false,
    event: false,
    start_date: "",
    end_date: "",
  },
  count_restriction: false,
  permit_counts: {
    per_user: 0,
    per_address: 0,
    per_agency: 0,
    per_plate: 0,
    per_lot: 0,
    per_district: 0,
  },
  area_restriction: false,
  zones: "",
  role: [],
  role_restriction: false,
  guest_pass: 0,
  has_guest_pass: false,
  permit_approval_restriction: false,
  permit_approval: {
    auto_approval: false,
    notification: {
      name: "",
      enable: false,
    },
    approval_work_flow: [],
    no_renewal_for_open_citation: false,
    allow_transafer: false,
    no_acceptance_for_open_citation: false,
    allow_renew: {
      monthly: false,
      daily: false,
      annually: false,
      quarterly: false,
      event: false,
    },
  },
  allow_motorist_to_view: false,
  allow_cancel: false,
  wait_list_message: "",
  has_wait_list: false,
  is_notification_enabled: false,
  has_physical_hand_tag: false,
  hand_tag_shipping: false,
  document_required: [""],
  service_fee:0,
  application_fee: 0,
  is_prorated: {
    prorated: false,
    notification: {
      name: "",
      enable: false,
    },
  },
  has_tier_price: false,
  has_auto_renewal: false,
  permit_fee: [
    {
      tier_number: 1,
      permit_fee: {
        daily: false,
        daily_permit_fee: 0,
        monthly: false,
        monthly_permit_fee: 0,
        annually: false,
        annual_permit_fee: 0,
        quarterly: false,
        quarterly_permit_fee: 0,
        event: false,
        event_permit_fee: 0,
        // "start_date":
        //  "end_date":
      },
    },
  ],
  guest_permit_fee: [
    {
      tier_number: 1,
      permit_fee: {
        daily: false,
        daily_permit_fee: 0,
        monthly: false,
        monthly_permit_fee: 0,
        annually: false,
        annual_permit_fee: 0,
        quarterly: false,
        quarterly_permit_fee: 0,
        event: false,
        event_permit_fee: 0,
        // "start_date":
        // "end_date":
      },
    },
  ],
  plate_update_fee: 0,
  change_address_fee: 0,
  hand_tag_replacement_fee: 0,
  is_prorated_refund_enable: false,
  payment_type: [],
  notifications: [
    {
      name: "Payment Declined",
      enable: true,
    },
    {
      name: "Password reset",
      enable: true,
    },
  ],
}

const PermitConfigurationContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // default form data for multiple tabs 
  const [payloadData, setPayloadData] = useState(initialPayload);

  const [currentTab, setCurrentTab] = useState(1);
  const [loading,setLoading] = useState(false);
  const [responseMessage,setResponseMessage] = useState("");
  const [responseStatus,setResponseStatus] = useState();
  const [isEditPermit,setIsEditPermit] = useState(false)

  // default permit type data 
  const permitTypeData = [
    {
      id: 1,
      title: "Residential Permit",
      description: "description one",
    },
    {
      id: 2,
      title: "Visitor Permit",
      description: "description two",
    },
    {
      id: 3,
      title: "Construction Permit",
      description: "description three",
    },
    {
      id: 4,
      title: "Nanny Permit",
      description: "description four",
    },
    {
      id: 5,
      title: "Landlords Permit",
      description: "description five",
    },
    {
      id: 6,
      title: "Medical Permit",
      description: "description five",
    },
    {
      id: 7,
      title: "Employee Permit",
      description: "description five",
    },
    {
      id: 8,
      title: "Business Permit",
      description: "description five",
    },
    {
      id: 9,
      title: "EV Permit",
      description: "description five",
    },
    {
      id: 10,
      title: "Temporary Permit",
      description: "description five",
    },
    {
      id: 11,
      title: "Monthly Lot Leases Permit",
      description: "description five",
    },
  ];

  useEffect(() => {
    setResponseMessage("")
  },[currentTab])

  useEffect(() => {
    if(location?.state?.permit){
      setPayloadData(location?.state?.permit)
      setCurrentTab(2);
      setIsEditPermit(true)
    }
  },[])

  // handle perm,it configuration form submitting

  const HandlePermitSubmit = async (payload) => {
    setLoading(true)
    try {
      const dataPayload = { ...payloadData, ...payload };
      setPayloadData(dataPayload);
      let response;
      if(isEditPermit && dataPayload.status === "ACTIVE"){
         response = await mainService.updatePermitConfiguration(dataPayload);
      }else {
         response = await mainService.permitConfiguration(dataPayload);
      }
      setResponseMessage(response.data.message)
      setResponseStatus(response.status)
      if (response.status === 200) {
        setCurrentTab(currentTab + 1);
      }
    } catch (error) {
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
      } else {
        setResponseStatus(error.response.status)
        setResponseMessage(error.response.data.message)
      }
    }
    setLoading(false)
  };

  const formResetHandler = () => {
    setPayloadData(initialPayload)
  }

  return (
    <>
      <PermitConfigurationComponent
        permit={permitTypeData}
        HandlePermitSubmit={HandlePermitSubmit}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        payloadData={payloadData}
        loading={loading}
        responseMessage={responseMessage}
        responseStatus={responseStatus}
        isEditPermit={isEditPermit}
        formResetHandler={formResetHandler}
      />
    </>
  );
};

export default PermitConfigurationContainer;
