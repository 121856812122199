import { Field, useFormikContext } from "formik";

const LabelComponent = ({ htmlFor, label, is_required }) => {
  return (
    <label htmlFor={htmlFor}>
      {label}
      {is_required && <span className="required">*</span>}
    </label>
  );
};

const EditViewField = ({ field }) => {
  const { setFieldValue } = useFormikContext();

  const handleLicenseInput = (event) => {
    // only allow uppercase letters and numbers
    const uppercaseValue = event.target.value.replace(/[^A-Z0-9]/gi, '').toUpperCase();
    setFieldValue('lp_number', uppercaseValue);
  };

  if (field.name === "lp_number") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          onChange={handleLicenseInput}
          required={field.is_required}
        />
      </div>
    );
  } else {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          required={field.is_required}
        />
      </div>
    );
  }
};

const TextViewField = ({ field, handleOfficerNameChange, officerUpdateDataUnique }) => {
  if (field.name === "timestamp") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          type="datetime-local"
          className="cstm-pull-details schedule-date-input"
          required={field.is_required}
        />
      </div>
    );
  } else if (field.name === "Hearing_Date") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          type="datetime-local"
          className="cstm-pull-details schedule-date-input"
          required={field.is_required}
        />
      </div>
    );
  } else if (field.name === "citation_number") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          required={field.is_required}
        />
      </div>
    );
  } else if (field.name === "agency") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          required={field.is_required}
        />
      </div>
    );
  } else if (field.name === "officer_name") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          onChange={handleOfficerNameChange}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {officerUpdateDataUnique?.map((item) => (
            <option key={item.officer_badge_id} value={`${item.officer_first_name} ${item.officer_last_name}`} />
          ))}
        </datalist>
      </div>
    );
  } else {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          required={field.is_required}
        />
      </div>
    );
  }
};

const RadioButtonField = ({ field }) => {
  const options = [];
  options.push({ value: field?.name, label: field?.repr });

  return (
    <div>
      {options.map((option, index) => (
        <label key={index}>
          <Field
            id={`${field.name}_${index}`}
            name="type"
            type="radio"
            value={option.value}
            required={field.is_required}
            className="radio-buttons"
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const DropDownField = ({
  field,
  dropDownData,
  handleBadgeIdChange,
  handleViolationCodeChange,
  handleCarMakeChange,
  selectedMake,
  makeModelGrouped,
  officerUpdateDataUnique,
  violationListUnique,
  handleLocationChange,
  locationData
}) => {
  if (field.name === "state") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {dropDownData?.StateList?.sort((a, b) =>
            a.state_name.localeCompare(b.state_name),
          ).map((item) => (
            <option key={item.state_abbreviated} value={item.state_abbreviated}>
              {item.state_name}
            </option>
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "make") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          onChange={handleCarMakeChange}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {makeModelGrouped?.map((item) => (
            <option key={item.make} value={item.make} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "model") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {makeModelGrouped?.map((item) => {
            if (item.make === selectedMake) {
              return item.models.map((model) => (
                <option key={model} value={model} />
              ));
            }
            return null;
          })}
        </datalist>
      </div>
    );
  } else if (field.name === "color") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {dropDownData?.CarColorList?.map((item) => (
            <option key={item.color_code} value={item.description} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "badge_id") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          onChange={handleBadgeIdChange}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {officerUpdateDataUnique?.map((item) => (
            <option key={item.officer_badge_id} value={item.officer_badge_id} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "street") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          value={locationData?.street}
          placeholder={`Enter ${field.repr}`}
          onChange={handleLocationChange}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {dropDownData?.StreetList?.map((item) => (
            <option key={item.street_name} value={item.street_name} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "lot") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          value={locationData?.lot}
          onChange={handleLocationChange}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {dropDownData?.LotList?.map((item) => (
            <option key={item.lot} value={item.lot} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "block") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          value={locationData?.block}
          onChange={handleLocationChange}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {dropDownData?.LotList?.map((item) => (
            <option key={item.block} value={item.block} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "remark_1") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          id={field.name}
          name={field.name}
          placeholder={`Enter ${field.repr}`}
          list={`${field.name}List`}
          required={field.is_required}
        />
        <datalist id={`${field.name}List`}>
          {dropDownData?.RemarksList?.map((item) => (
            <option key={item.remark} value={item.remark} />
          ))}
        </datalist>
      </div>
    );
  } else if (field.name === "code") {
    return (
      <div className="form-group">
        <LabelComponent
          htmlFor={field.name}
          label={field.repr}
          is_required={field.is_required}
        />
        <Field
          as="select"
          className="state-droupdwon ar-olny"
          style={{ width: "65%" }}
          id={field.name}
          name={field.name}
          onChange={handleViolationCodeChange}
          required={field.is_required}>
          <option value="">Select {field.repr}</option>
          {violationListUnique?.map((item) => (
            <option key={item.code} value={item.code}>{item.code}</option>
          ))}
        </Field>
      </div>
    );
  } else return null;
};

export { EditViewField, TextViewField, RadioButtonField, DropDownField };