import DatePicker from "react-datepicker";
import transfer from "../../../Images/transfer.png";
import PermitOptionTable from "./PermitOptionTable";
import Pagination from "../../../utile/pagination";
import { useState } from "react";
import moment from "moment";

const PermitOptionComponent = ({
  reportData,
  noRecordFound,
  loading,
  totalRecords,
  tableHeading,
  keySequence,
  PermitListHandler,
  EditHandler,
  handlePaginationChange,
  itemsPerPage,
  currentPage,
  buyPermitHandler
}) => {
  const [filterDate, setFilterDate] = useState({
    created_ts_from: "",
    created_ts_to: "",
  });
  const [allSelectedFilterOption,setAllSelectedFilterOption] = useState();
  const [toDosPerPage,settoDosPerPage] = useState(10)

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    let query_string = "";
    query_string += "page=" + 1;
    query_string += "&limit=" + toDosPerPage;
    let created_ts_from = "";
    let created_ts_to = "";
    if (filterDate.created_ts_from) {
      created_ts_from = "&created_ts_from=" + moment(filterDate.created_ts_from).format();
      query_string +=
        "&created_ts_from=" +
        moment(filterDate.created_ts_from).format();
    }
    if (filterDate.created_ts_to) {
      created_ts_to = "&created_ts_from=" + moment(filterDate.created_ts_from).format();
      query_string +=
        "&created_ts_to=" + moment(filterDate.created_ts_to).format();
    }

    setAllSelectedFilterOption(
      created_ts_to + 
      created_ts_from
    )

    PermitListHandler(query_string);
  };

  const onChangeFilterDate = (date, name) => {
    // const d = new Date(date)
    setFilterDate({ ...filterDate, [name]: date });
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            {/* Content starts */}
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
              <div className="cstm-ticket-manage-sec">
                <div className="cstm-ticket-manage-heading">
                  <h3 className="cstm-all-pg-ttl operate-ttl">
                    Permit Configuration List
                  </h3>
                </div>
              </div>
              <div className="report-searchbar">
                <form onSubmit={handleFilterSubmit}>
                  <div className="report-date report-mr">
                    <DatePicker
                      showTimeSelect
                      timeIntervals={1}
                      placeholderText="MM/DD/YYYY HH:MM"
                      dateFormat="MM/dd/yyyy h:mmaa"
                      timeFormat="HH:mm"
                      className="cstm-pull-details schedule-date-input"
                      autoComplete="off"
                      name="created_ts_from"
                  selected={filterDate.created_ts_from}
                  onChange={(date) =>
                    onChangeFilterDate(date, "created_ts_from")
                  }
                    />
                    <img
                      src={transfer}
                      alt="transfer"
                      className="date-transfer"
                    />
                    <DatePicker
                      showTimeSelect
                      timeIntervals={1}
                      dateFormat="MM/dd/yyyy h:mmaa"
                      timeFormat="HH:mm"
                      placeholderText="MM/DD/YYYY HH:MM"
                      className="cstm-pull-details schedule-date-input"
                      autoComplete="off"
                      name="created_ts_to"
                  selected={filterDate.created_ts_to}
                  onChange={(date) => onChangeFilterDate(date, "created_ts_to")}
                    />
                  </div>
                  <button className="licence-print report-mr" type="submit">
                    Submit
                  </button>
                </form>
              </div>
              {loading ? (
                <div className="text-center spin-load-main">
                  <div className="spinner-border"></div>
                </div>
              ) : noRecordFound ? (
                <div className="no-record-found-main">No Record Found</div>
              ) : (
                <>
                  <div className="ctsm-table-scroll">
                    <div className="table-responsive">
                      <div className="">
                        <PermitOptionTable reportData={reportData} tableHeading={tableHeading} keySequence={keySequence} EditHandler={EditHandler} buyPermitHandler={buyPermitHandler} />
                      </div>
                    </div>
                  </div>

                  {noRecordFound !== true && (
                    <>
                      <Pagination handlePaginationChange={handlePaginationChange} totalRecords={totalRecords} pageLimit={itemsPerPage} pageNeighbours={1} currentPage={currentPage} dropdownLimit={false}  />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PermitOptionComponent;
