import React, { useState, useEffect } from 'react';
import PurchasedPermitComponent from './purchasedPermitComponent';
import dashboardService from '../../../Services/dashboard.service';
import mainService from '../../../Services/main.service';
import { refreshTokenMiddleware } from '../../customMiddelware';
import { useDispatch } from "react-redux";


const PurchasedPermitContainer = () => {
  const dispatch = useDispatch();

  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevAndNextBtnClass, setPrevAndNextBtnClass] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [statusFilterList, setStatusFilterList] = useState();

  const PurchasedPermitList = async query => {
    setLoading(true);
    try {
      const response = await dashboardService.getPurchasedPermit(query);
      if (
        response.data.status &&
        response.data.data.permit_buy_detail &&
        response.data.data.permit_buy_detail.length > 0
      ) {
        setNoRecordFound(false);
        setReportData(response.data.data.permit_buy_detail);
        setTotalRecords(response.data.data.permit_buy_detail.length);
      } else {
        setReportData([]);
        setNoRecordFound(true);
        setTotalRecords(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        PurchasedPermitList({
          page: currentPage,
          limit: itemsPerPage,
        });
      }
    }
  };

  var passDataStateList = {
    type: 'PermitStatuses',
    shard: 1,
  };

  const getStatusFilterHandler = async () => {
    try {
      const response = await mainService.violationCode(passDataStateList);
      setStatusFilterList(response.data.data[0].response);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    let query = `page=${currentPage}&limit=${itemsPerPage}`;
    PurchasedPermitList(query);
    getStatusFilterHandler();
  }, []);

  const tableHeading = [
    'Submitted At',
    'Permit Name',
    'Permit Type',
    'Plate Counts',
    'Status',
    'Total Amount',
    'Submitted by',
    'Edit Permit'
  ];

  const keySequence = [
    'created_at',
    'permit_name',
    'permit_type',
    'permit_detail',
    'status',
    'buy_permit_fee_structure.total_amount',
    'user_detail.first_name',
    'edit_permit'
  ];

  const handlePaginationChange = newPage => {
    let query = `page=${newPage.currentPage}&limit=${newPage.pageLimit}`;
    PurchasedPermitList(query);
    setCurrentPage(newPage.currentPage);
    setPrevAndNextBtnClass(newPage.pageLimit);
    setItemsPerPage(newPage.pageLimit);
  };

  return (
    <>
      <PurchasedPermitComponent
        reportData={reportData}
        loading={loading}
        noRecordFound={noRecordFound}
        tableHeading={tableHeading}
        keySequence={keySequence}
        PermitListHandler={PurchasedPermitList}
        handlePaginationChange={handlePaginationChange}
        totalRecords={totalRecords}
        itemsPerPage={itemsPerPage}
        prevAndNextBtnClass={prevAndNextBtnClass}
        currentPage={currentPage}
        statusFilterList={statusFilterList}
      />
    </>
  );
};

export default PurchasedPermitContainer;
