import React from "react";
import DOMPurify from "dompurify";

export default function NoticeViewHtml({
  isNotiTemplateViewHTML,
  setNotiTemplateViewHTML,
}) {
  const closeModal = () => {
    return setNotiTemplateViewHTML({ html: "", state: false });
  };
  return (
    <div className="main-body-modal">
      <div className="main-modal  appeal-detail-popup-main">
        <div className="cstm-close">
          <button onClick={closeModal}>×</button>
        </div>
        <div className="cstm-modal-body">
          <div className="modal-body cstm-modal-body">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(isNotiTemplateViewHTML.html),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
