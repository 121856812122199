import React, { useEffect, useState, } from "react";
import HTMLFlipBook from "react-pageflip";
import FlipbookService from '../../Services/flipbook.service';
import { renderDiv, renderDivWithClass, validateData } from "../Common/utils";
import ImageDownloadService from '../../Services/main.service';
import { useDispatch } from "react-redux";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { changeticket, changetotal } from "../../Global/site";
import ReactToPrint from 'react-to-print';
import { refreshTokenMiddleware } from "../customMiddelware";

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const imageWidth = 80;
const imageHeight = 200;

const pageStyle = `@page { size: ${imageWidth}mm ${imageHeight}mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }`

const FlipbookPage = ({ ticketId, handleBackButtonClick, logo }) => {
  const dispatch = useDispatch();
  const changeTotal = changetotal();
  const [selectedData, setSelectedData] = useState();
  const [images, setImages] = useState([]);
  const [showImagesEmptyMessage, setImagesEmptyMessage] = useState(true)
  const [appealData, setAppealData] = useState({});
  const [commercialData, setCommercialData] = useState({});
  const [appealAttachment, setAppealAttachment] = useState([]);
  const [notificationAuditTrail, setNotificationAuditTrail] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const changeName = changeticket();

  useEffect(() => {
    const getTicketDetails = async () => {
      FlipbookService.getTicketDetails(ticketId)
        .then(response => {
          setSelectedData(response.data?.data);
          if (response.data?.data.detail.images.length !== 0) {
            const imagesLink = response.data?.data.detail.images;

            const imageData = {
              "links": (Object.assign({}, imagesLink)),
              "download_type": "CitationImages",
              "site_id": process.env.REACT_APP_SITEID
            }

            ImageDownloadService.downloadImage(imageData).then(
              (responseImage) => {
                setImages(responseImage.data.metadata)
              }
            )
          } else {
            setImagesEmptyMessage(false)
          }

          if (response.data?.data?.detail?.notification_audit_trail.length !== 0) {
            setNotificationAuditTrail(response.data?.data.detail.notification_audit_trail);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            refreshTokenMiddleware(dispatch)
          }
        })

      try {
        const [appealDetails, commercialDetails] = await Promise.all([FlipbookService.getAppealDetails(ticketId), FlipbookService.getCommercialDetails(ticketId)]);
        setAppealData(appealDetails.data?.data);
        setCommercialData(commercialDetails.data?.data);

        if (appealDetails.data?.data?.attachments.length !== 0) {
          const imagesLink = appealDetails.data?.data?.attachments;
          const imageData = {
            "links": (Object.assign({}, imagesLink)),
            "download_type": "CitationImages",
            "site_id": process.env.REACT_APP_SITEID
          }

          ImageDownloadService.downloadImage(imageData).then(
            (responseImage) => {
              setAppealAttachment(responseImage.data.metadata)
            }
          )
        }
      } catch (error) {
        if (error.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          getTicketDetails()
        }
      }
    };
    getTicketDetails();
  }, [dispatch, ticketId]);

  useEffect(() => {
    if (notificationAuditTrail && notificationAuditTrail.length > 0) {
      const links = [];
      notificationAuditTrail.map((item) => {
        if (item?.DocLocation) {
          links.push(item.DocLocation);
        }
        return null;
      })

      // const imagesLink = appealDetails.data?.data?.attachments;
      const imageData = {
        "links": (Object.assign({}, links)),
        "download_type": "CitationImages",
        "site_id": process.env.REACT_APP_SITEID
      }

      const pdfUrls = [];
      ImageDownloadService.downloadImage(imageData).then(
        (responseImage) => {
          if (responseImage?.data?.metadata?.length) {
            responseImage?.data?.metadata?.forEach(element => {
              pdfUrls.push(element?.url)
            });
          }
          if (pdfUrls.length) {
            setPdfData(prev => [...prev, ...pdfUrls])
          }
        }
      )
    }
  }, [notificationAuditTrail])

  const formatDate = (dateString) => {
    if (validateData(dateString)) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } else return null;
  }

  const auditTrailRender = data => {
    const trail = [];
    const trailLength = data?.length;
    const entriesPerPage = 4;
    const numberOfPages = Math.ceil(trailLength / entriesPerPage);

    for (let index = 0; index < numberOfPages; index++) {
      const elements = [];
      for (let i = 0; i < entriesPerPage; i++) {
        const dataIndex = index * entriesPerPage + i;
        const item = data[dataIndex];
        if (dataIndex < trailLength) {
          elements.push(
            <div className="row">
              <div className="col-lg-12">
                <h3>Reason</h3>
                {renderDiv(item.Reason, 'p')}
              </div>
              <div className="row col-lg-12">
                {renderDivWithClass(formatDate(item?.Timestamp), 'Date', 'p', 'col-lg-3')}
                {renderDivWithClass(item?.NewValue, 'New Value', 'p', 'col-lg-3')}
                {renderDivWithClass(item?.OldValue, 'Old Value', 'p', 'col-lg-3')}
                {renderDivWithClass(item?.UpdateType, 'Update Type', 'p', 'col-lg-3')}
                {renderDivWithClass('', '', 'p', 'col-lg-3')}
              </div>
            </div>
          )
        }
      }

      trail.push(
        <div className="page ticket-detail">
          <div className="ticket-border">
            {/* <img
              src={logo}
              alt="logo"
              className='page-header logo-image-top' /> */}
            <h1>Audit Trail</h1>
          </div>
          <div className="ticket-border">
            {elements}
          </div>
          {/* <div className="page-footer">
            {
              <p>{index + 9}</p>
            }
          </div> */}
        </div>
      )
    }
    return trail;
  }

  const PrintContent = () => (
    <div className="flipbook-print">
      <div className="page" style={{ height: '80%' }}>
        {/* Page 1 - Images */}
        <div className="page ticket-image">
          <div className="ticket-border">
            <h2>Images</h2>
            {showImagesEmptyMessage ?
              <div className="ticket-img-section">
                {images.map((image) => (
                  <a href={image.url} target="_blank" rel="noopener noreferrer">
                    <img src={image.url} alt={"ticket-img-" + image.image_spec} />
                  </a>
                ))}
              </div>
              :
              <p>-</p>
            }
          </div>
          {/* <div className="page-footer">
          {
            <p>1</p>
          }
        </div> */}
        </div>

        {/* Page 2 */}
        <div className="page ticket-detail">
          <div className="ticket-border">
            <h1 className='table-detail'>{changeTotal} Details</h1>
          </div>


          {/* Ticket Details */}
          <div className="ticket-border">
            <h2>{changeName} Details</h2>
            <div className="row">
              {validateData(selectedData?.detail?.ticket_no) ?
                <div className="col-lg-3">
                  <h3>{changeName} Number</h3>
                  {renderDiv(selectedData?.detail?.ticket_no, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.citation_issue_timestamp) ?
                <div className="col-lg-3">
                  <h3>{changeName} Issue Date</h3>
                  {renderDiv(formatDate(selectedData?.detail?.citation_issue_timestamp), 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.status) ?
                <div className="col-lg-3">
                  <h3>Status</h3>
                  {renderDiv(selectedData?.detail?.status, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_code) ?
                <div className="col-lg-3">
                  <h3>Drive Off</h3>
                  {renderDiv(selectedData?.detail?.drive_off?.toString(), 'p')}
                </div>
                : null
              }
            </div>
          </div>


          {/* Violation Details */}
          < div className="ticket-border" >
            <h2>Violation Details</h2>
            <div className="row">
              {validateData(selectedData?.detail?.violation_details?.code) ?
                <div className="col-lg-4">
                  <h3>Violation Code</h3>
                  {renderDiv(selectedData?.detail?.violation_details?.code, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.description) ?
                <div className="col-lg-4">
                  <h3>Violation Description</h3>
                  {renderDiv(selectedData?.detail?.violation_details?.description, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.violation) ?
                <div className="col-lg-4">
                  <h3>Violation</h3>
                  {renderDiv(selectedData?.detail?.violation_details?.violation, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.fine) ?
                <div className="col-lg-4">
                  <h3>Violation Amount</h3>
                  {renderDiv(`$${selectedData?.detail?.violation_details?.fine}`, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.late_fine) ?
                <div className="col-lg-4">
                  <h3>Violation Late Fee</h3>
                  {renderDiv(`$${selectedData?.detail?.violation_details?.late_fine}`, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.due_15_days) ?
                <div className="col-lg-4">
                  <h3>Violation Due 15 Days</h3>
                  {renderDiv(`$${selectedData?.detail?.violation_details?.due_15_days}`, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.due_30_days) ?
                <div className="col-lg-4">
                  <h3>Violation Due 30 Days</h3>
                  {renderDiv(`$${selectedData?.detail?.violation_details?.due_30_days}`, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.violation_details?.due_45_days) ?
                <div className="col-lg-4">
                  <h3>Violation Due 45 Days</h3>
                  {renderDiv(`$${selectedData?.detail?.violation_details?.due_45_days}`, 'p')}
                </div>
                : null
              }
            </div>
          </div>

          {/* Vehicle Details */}
          <div className="ticket-border">
            <h2>Vehicle Details:</h2>
            <div className="row">
              {validateData(selectedData?.detail?.vehicle_details?.lp_number) ?
                <div className="col-lg-4">
                  <h3>License Plate Number</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.lp_number, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.license_expiry) ?
                <div className="col-lg-4">
                  <h3>License Plate Expiry</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.license_expiry, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.state) ?
                <div className="col-lg-4">
                  <h3>License Plate State</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.state, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.make) ?
                <div className="col-lg-4">
                  <h3>Vehicle Make</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.make, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.model) ?
                <div className="col-lg-4">
                  <h3>Vehicle Model</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.model, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.color) ?
                <div className="col-lg-4">
                  <h3>Vehicle Color</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.color, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.body_style) ?
                <div className="col-lg-4">
                  <h3>Vehicle Body Style</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.body_style, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.vehicle_details?.vin_number) ?
                <div className="col-lg-4">
                  <h3>VIN</h3>
                  {renderDiv(selectedData?.detail?.vehicle_details?.vin_number, 'p')}
                </div>
                : null
              }
            </div>
          </div>
        </div>

        {/* Page 3 */}
        <div className="page ticket-detail">
          {/* Location Details */}
          <div className="ticket-border">
            <h2>Location Details</h2>
            <div className="row">
              {validateData(selectedData?.detail?.location?.block) ?
                <div className="col-lg-3">
                  <h3>Block</h3>
                  {renderDiv(selectedData?.detail?.location?.block, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.location?.street) ?
                <div className="col-lg-3">
                  <h3>Street</h3>
                  {renderDiv(selectedData?.detail?.location?.street, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.location?.lot) ?
                <div className="col-lg-3">
                  <h3>Lot</h3>
                  {renderDiv(selectedData?.detail?.location?.lot, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.location?.meter) ?
                <div className="col-lg-3">
                  <h3>Meter</h3>
                  {renderDiv(selectedData?.detail?.location?.meter, 'p')}
                </div>
                : null
              }
            </div>
          </div>

          {/* Comment Details */}
          <div className="ticket-border">
            <h2>Comment Details</h2>
            <div className="row">
              {validateData(selectedData?.detail?.comment_details?.note_1) ?
                <div className="col-lg-3">
                  <h3>note 1</h3>
                  {renderDiv(selectedData?.detail?.comment_details?.note_1, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.comment_details?.note_2) ?
                <div className="col-lg-3">
                  <h3>Note 2</h3>
                  {renderDiv(formatDate(selectedData?.detail?.comment_details?.note_2), 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.comment_details?.remark_1) ?
                <div className="col-lg-3">
                  <h3>Remark 1</h3>
                  {renderDiv(selectedData?.detail?.comment_details?.remark_1, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.comment_details?.remark_2) ?
                <div className="col-lg-3">
                  <h3>Remark 2</h3>
                  {renderDiv(selectedData?.detail?.comment_details?.remark_2, 'p')}
                </div>
                : null
              }
            </div>
          </div>

          {/* Officer Details */}
          <div className="ticket-border">
            <h2>Officer Details</h2>
            <div className="row">
              {validateData(selectedData?.detail?.officer_details?.officer_name) ?
                <div className="col-lg-4">
                  <h3>Officer Name</h3>
                  {renderDiv(selectedData?.detail?.officer_details?.officer_name, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.officer_details?.badge_id) ?
                <div className="col-lg-4">
                  <h3>Badge ID</h3>
                  {renderDiv(selectedData?.detail?.officer_details?.badge_id, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.officer_details?.device_friendly_name) ?
                <div className="col-lg-4">
                  <h3>Device Name</h3>
                  {renderDiv(selectedData?.detail?.officer_details?.device_friendly_name, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.officer_details?.shift) ?
                <div className="col-lg-4">
                  <h3>Shift</h3>
                  {renderDiv(selectedData?.detail?.officer_details?.shift, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.officer_details?.zone) ?
                <div className="col-lg-4">
                  <h3>Zone</h3>
                  {renderDiv(selectedData?.detail?.officer_details?.zone, 'p')}
                </div>
                : null
              }
              {validateData(selectedData?.detail?.officer_details?.beat) ?
                <div className="col-lg-4">
                  <h3>Beat</h3>
                  {renderDiv(selectedData?.detail?.officer_details?.beat, 'p')}
                </div>
                : null
              }
            </div>
          </div>

          {/* Payment Details */}
          <div className="ticket-border">
            <h2>Payment Details</h2>
            <div className="row">
              {renderDivWithClass(selectedData?.detail?.payment_details?.Amount, 'Payment Amount')}
              {renderDivWithClass(selectedData?.detail?.payment_details?.PaymentMode, 'Payment Mode')}
              {renderDivWithClass(selectedData?.detail?.payment_details?.PaymentStatus, 'Payment Status')}
              {renderDivWithClass(selectedData?.detail?.payment_details?.ReceiptID, 'Receipt ID')}
              {renderDivWithClass(formatDate(selectedData?.detail?.payment_details?.PaymentDatetime), 'Payment Date')}
            </div>
            {/* <div className="page-footer">
            {
              <p>3</p>
            }
          </div> */}
          </div>
        </div>

        {/* Page 4 */}
        <div className="page ticket-detail">
          <div className="ticket-border">
            {/* <img
            src={logo}
            alt="logo"
            className='page-header logo-image-top'
          /> */}
            <h1>Commercial Page</h1>
          </div>

          {/* Commercial Details */}
          <div className="ticket-border">
            <h2>Commercial Details</h2>
            <div className="row">
              {validateData(commercialData?.fine) ?
                <div className="col-lg-4">
                  <h3>Fine</h3>
                  {renderDiv(`$${commercialData?.fine}`, 'p')}
                </div>
                : null
              }
              {validateData(commercialData?.total_due) ?
                <div className="col-lg-4">
                  <h3>Total Due</h3>
                  {renderDiv(`$${commercialData?.total_due}`, 'p')}
                </div>
                : null
              }
              {validateData(commercialData?.service_fee) ?
                <div className="col-lg-4">
                  <h3>Service Fee</h3>
                  {renderDiv(`$${commercialData?.service_fee}`, 'p')}
                </div>
                : null
              }
              {validateData(commercialData?.fine_increase) ?
                <div className="col-lg-4">
                  <h3>Fine Increase</h3>
                  {renderDiv(`$${commercialData?.fine_increase}`, 'p')}
                </div>
                : null
              }
              {validateData(commercialData?.nsf_fee) ?
                <div className="col-lg-4">
                  <h3>NSF Fee</h3>
                  {renderDiv(`$${commercialData?.nsf_fee}`, 'p')}
                </div>
                : null
              }
              {validateData(commercialData?.boot_tow_fee) ?
                <div className="col-lg-4">
                  <h3>Boot Tow Fee</h3>
                  {renderDiv(`$${commercialData?.boot_tow_fee}`, 'p')}
                </div>
                : null
              }
              {renderDivWithClass(commercialData?.collection_fee, 'Collection Fee')}
              {renderDivWithClass(commercialData?.payments, 'Payments')}
              {renderDivWithClass(commercialData?.remark, 'Remark')}
              {renderDivWithClass(commercialData?.dmv_case_number, 'DMV Case Number')}
              {renderDivWithClass(formatDate(commercialData?.paid_date), 'Paid Date')}
              {renderDivWithClass(formatDate(commercialData?.fine_increase_date), 'Fine Increase Date')}
              {renderDivWithClass(formatDate(commercialData?.nsf_applied_date), 'NSF Applied Date')}
              {renderDivWithClass(formatDate(commercialData?.boot_applied_date), 'Boot Tow Applied Date')}
              {renderDivWithClass(formatDate(commercialData?.adjusted_balance_date), 'Adjusted Balance Date')}
              {validateData(commercialData?.dmv_case_number) ?
                <div className="col-lg-4">
                  <h3>DMV Case Number</h3>
                  {renderDiv(commercialData?.dmv_case_number, 'p')}
                </div>
                : null
              }
            </div>
          </div>

          {/* Escalation Details */}
          <div className="ticket-border">
            <h2>Escalation Details</h2>
            <div className="row">
              {commercialData.escalation_detail?.map((item) => {
                return (
                  <>
                    {renderDivWithClass(item?.order_number, 'Order Number')}
                    {renderDivWithClass(formatDate(item?.date), 'Escalation Date')}
                    {renderDivWithClass(`$${item?.amount}`, 'Escalation Amount')}
                  </>
                )
              }
              )}
            </div>
          </div>
          {/* <div className="page-footer">
          {
            <p>4</p>
          }
        </div> */}
        </div>

        {/* Page 5 */}
        <div className="page ticket-detail">
          <div className="ticket-border">
            <h1>Appeal Details</h1>
          </div>

          {/* Appeal User Details */}
          <div className="ticket-border">
            <h2>Appeal User Details</h2>
            <div className="row">
              {renderDivWithClass(appealData?.first_name, 'First Name')}
              {renderDivWithClass(appealData?.last_name, 'Last Name')}
              {renderDivWithClass(appealData?.block, 'Block')}
              {renderDivWithClass(appealData?.street, 'Street')}
              {renderDivWithClass(appealData?.state, 'State')}
              {renderDivWithClass(appealData?.zip, 'Zip Code')}
              {renderDivWithClass(appealData?.email, 'Email')}
              {renderDivWithClass(appealData?.telephone, 'Phone')}
            </div>
          </div>
          {/* Reason For Appeal */}
          <div className="ticket-border">
            <h2>Reason For Appeal</h2>
            <div className="row">
              {renderDivWithClass(formatDate(appealData?.created_at), 'Appeal Create Time')}
              {renderDivWithClass(appealData?.appeal_reason, 'Appeal Reason')}
              {renderDivWithClass(appealData?.appeal_additional_reason, 'Comment')}
              {renderDivWithClass(appealData?.appeal_status, 'Appeal Outcome')}
              {renderDivWithClass(formatDate(appealData?.updated_at), 'Last Updated At')}
              {renderDivWithClass(appealData?.appeal_workflow_status, 'Appeal Workflow Status')}
              {renderDivWithClass(formatDate(selectedData?.detail?.citation_issue_timestamp), 'Violation Date')}
              {renderDivWithClass(appealData?.violation_code, 'Violation Code')}
              {renderDivWithClass(selectedData?.detail?.violation_details?.description, 'Violation Description')}
              {renderDivWithClass(selectedData?.detail?.vehicle_details?.lp_number, 'License Plate Number')}
              {renderDivWithClass(selectedData?.detail?.vehicle_details?.state, 'State')}
            </div>
          </div>
        </div>

        {/* Page 6 */}
        {
          appealAttachment.length > 0 ?
            <div className="page ticket-image">
              <div className="ticket-border">
                <h2>Attachments</h2>
                <div className="ticket-attachment-img">
                  {appealAttachment?.map((image) => (
                    // opne the image file
                    <a href={image.url} target="_blank" rel="noopener noreferrer" >
                      <img className="ticket-attachment-img" src={image.url} alt={"attachment" + image.image_spec} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            : null
        }

        {/* Page 7 */}
        <div className="page ticket-detail">
          <div className="page ticket-detail">
            <div className="ticket-border ticket-attachment-pdf">
              <h2>PDF Attachments</h2>
              {pdfData.length ?
                <div className="ticket-attachment-container">
                  {pdfData.map((pdfUrl, index) => (
                    <div className="single-pdf" key={index}>
                      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                        <Document file={pdfUrl}>
                          <Page pageNumber={1} height={350} scale={1} />
                        </Document>
                      </a>
                    </div>
                  ))}
                </div>
                :
                <p>No Attachments Available</p>
              }
            </div>
          </div>
        </div>
        {/* Page 8 */}
        <div className="page ticket-detail">
          {/* Appeal Notice */}
          <div className="ticket-border">
            <h2>Appeal Notice</h2>
            <div className="row">
              {selectedData?.detail?.notification_audit_trail?.map((item) => {
                return <>
                  {renderDivWithClass(formatDate(item?.CreatedAt), 'Date')}
                  {renderDivWithClass(item?.Email, 'Email Sent To')}
                  {renderDivWithClass(item?.SentBy, 'Sent By')}
                  {renderDivWithClass(item?.NotificationType, 'Notification Type')}
                  {renderDivWithClass(item?.Address, 'Address')}
                </>
              }
              )}
            </div>
          </div>
          {/* Appeal Remarks */}
          <div className="ticket-border">
            <h2>Appeal Remarks</h2>
            {appealData?.workflow?.map((workflow) => (
              <div className="row">
                {renderDivWithClass(workflow?.remark, 'Remark')}
                {renderDivWithClass(workflow?.additional_remark, 'Additional Remark')}
                {renderDivWithClass(workflow?.created_by, 'Created By')}
                {renderDivWithClass(formatDate(workflow?.created_at_local), 'Created At')}
              </div>
            ))}
          </div>
        </div>

        {/* Page 9 */}
        {auditTrailRender(selectedData?.detail?.audit_trail)}
      </div >
    </div>
  )


  return (
    <>
      <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
        {/* back */}
        <div className="back-btn">
          <button className="cstm-allsite-btn" onClick={handleBackButtonClick}>
            Back
          </button>
        </div>
        {/* print */}
        <div className="cstm-ticket-manage-sec">
          <div className="cstm-ticket-manage-download" style={{ width: '100%' }}>
            <ReactToPrint
              pageStyle={pageStyle}
              trigger={() => {
                return <button className="licence-download cstm-licene-download">Print</button>;
              }}
              content={() => document.getElementsByClassName('flipbook-print')[0]}
            />
          </div>
        </div>
        {selectedData &&
          <div className="ticket-detail-card">
            {/* Flipbook print section */}
            {/* This hidden div is exact copy of the flipbook because printing is not possible from HTMLFlipBook so creating a copy to be passed to ReactToPrint */}
            {/* The PrintContent should be updated everytime HTMLFlipBook is being updated */}
            <div style={{ display: 'none' }}>
              <PrintContent selectedData={selectedData} />
            </div>
            <HTMLFlipBook
              width={700}
              height={900}
              showCover={false}
              key={ticketId}
              autoSize={true}
              size="stretch"
              drawShadow={true}
              mobileScrollSupport={true}
            >
              {/* Page 1 - Images */}
              <div className="page ticket-image">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                </div>
                <div className="ticket-border">
                  <h2>Images</h2>
                  {showImagesEmptyMessage ?
                    <div className="ticket-img-section">
                      {images.map((image) => (
                        <a href={image.url} target="_blank" rel="noopener noreferrer">
                          <img src={image.url} alt={"ticket-img-" + image.image_spec} />
                        </a>
                      ))}
                    </div>
                    :
                    <p>-</p>
                  }
                </div>
                <div className="page-footer">
                  {
                    <p>1</p>
                  }
                </div>
              </div>

              {/* Page 2 */}
              <div className="page ticket-detail">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                  <div>
                    <h1 className='table-detail'>{changeTotal} Details</h1>
                  </div>
                </div>

                {/* Ticket Details */}
                <div className="ticket-border">
                  <h2>{changeName} Details</h2>
                  <div className="row">
                    {validateData(selectedData?.detail?.ticket_no) ?
                      <div className="col-lg-3">
                        <h3>{changeName} Number</h3>
                        {renderDiv(selectedData?.detail?.ticket_no, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.citation_issue_timestamp) ?
                      <div className="col-lg-3">
                        <h3>{changeName} Issue Date</h3>
                        {renderDiv(formatDate(selectedData?.detail?.citation_issue_timestamp), 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.status) ?
                      <div className="col-lg-3">
                        <h3>Status</h3>
                        {renderDiv(selectedData?.detail?.status, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_code) ?
                      <div className="col-lg-3">
                        <h3>Drive Off</h3>
                        {renderDiv(selectedData?.detail?.drive_off?.toString(), 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>

                {/* Violation Details */}
                < div className="ticket-border" >
                  <h2>Violation Details</h2>
                  <div className="row">
                    {validateData(selectedData?.detail?.violation_details?.code) ?
                      <div className="col-lg-4">
                        <h3>Violation Code</h3>
                        {renderDiv(selectedData?.detail?.violation_details?.code, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.description) ?
                      <div className="col-lg-4">
                        <h3>Violation Description</h3>
                        {renderDiv(selectedData?.detail?.violation_details?.description, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.violation) ?
                      <div className="col-lg-4">
                        <h3>Violation</h3>
                        {renderDiv(selectedData?.detail?.violation_details?.violation, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.fine) ?
                      <div className="col-lg-4">
                        <h3>Violation Amount</h3>
                        {renderDiv(`$${selectedData?.detail?.violation_details?.fine}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.late_fine) ?
                      <div className="col-lg-4">
                        <h3>Violation Late Fee</h3>
                        {renderDiv(`$${selectedData?.detail?.violation_details?.late_fine}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.due_15_days) ?
                      <div className="col-lg-4">
                        <h3>Violation Due 15 Days</h3>
                        {renderDiv(`$${selectedData?.detail?.violation_details?.due_15_days}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.due_30_days) ?
                      <div className="col-lg-4">
                        <h3>Violation Due 30 Days</h3>
                        {renderDiv(`$${selectedData?.detail?.violation_details?.due_30_days}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.violation_details?.due_45_days) ?
                      <div className="col-lg-4">
                        <h3>Violation Due 45 Days</h3>
                        {renderDiv(`$${selectedData?.detail?.violation_details?.due_45_days}`, 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>

                {/* Vehicle Details */}
                <div className="ticket-border">
                  <h2>Vehicle Details:</h2>
                  <div className="row">
                    {validateData(selectedData?.detail?.vehicle_details?.lp_number) ?
                      <div className="col-lg-4">
                        <h3>License Plate Number</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.lp_number, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.license_expiry) ?
                      <div className="col-lg-4">
                        <h3>License Plate Expiry</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.license_expiry, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.state) ?
                      <div className="col-lg-4">
                        <h3>License Plate State</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.state, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.make) ?
                      <div className="col-lg-4">
                        <h3>Vehicle Make</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.make, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.model) ?
                      <div className="col-lg-4">
                        <h3>Vehicle Model</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.model, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.color) ?
                      <div className="col-lg-4">
                        <h3>Vehicle Color</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.color, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.body_style) ?
                      <div className="col-lg-4">
                        <h3>Vehicle Body Style</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.body_style, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.vehicle_details?.vin_number) ?
                      <div className="col-lg-4">
                        <h3>VIN</h3>
                        {renderDiv(selectedData?.detail?.vehicle_details?.vin_number, 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>
                <div className="page-footer">
                  {
                    <p>2</p>
                  }
                </div>
              </div>

              {/* Page 3 */}
              <div className="page ticket-detail">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                </div>

                {/* Location Details */}
                <div className="ticket-border">
                  <h2>Location Details</h2>
                  <div className="row">
                    {validateData(selectedData?.detail?.location?.block) ?
                      <div className="col-lg-3">
                        <h3>Block</h3>
                        {renderDiv(selectedData?.detail?.location?.block, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.location?.street) ?
                      <div className="col-lg-3">
                        <h3>Street</h3>
                        {renderDiv(selectedData?.detail?.location?.street, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.location?.lot) ?
                      <div className="col-lg-3">
                        <h3>Lot</h3>
                        {renderDiv(selectedData?.detail?.location?.lot, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.location?.meter) ?
                      <div className="col-lg-3">
                        <h3>Meter</h3>
                        {renderDiv(selectedData?.detail?.location?.meter, 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>

                {/* Comment Details */}
                <div className="ticket-border">
                  <h2>Comment Details</h2>
                  <div className="row">
                    {validateData(selectedData?.detail?.comment_details?.note_1) ?
                      <div className="col-lg-3">
                        <h3>note 1</h3>
                        {renderDiv(selectedData?.detail?.comment_details?.note_1, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.comment_details?.note_2) ?
                      <div className="col-lg-3">
                        <h3>Note 2</h3>
                        {renderDiv(formatDate(selectedData?.detail?.comment_details?.note_2), 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.comment_details?.remark_1) ?
                      <div className="col-lg-3">
                        <h3>Remark 1</h3>
                        {renderDiv(selectedData?.detail?.comment_details?.remark_1, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.comment_details?.remark_2) ?
                      <div className="col-lg-3">
                        <h3>Remark 2</h3>
                        {renderDiv(selectedData?.detail?.comment_details?.remark_2, 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>

                {/* Officer Details */}
                <div className="ticket-border">
                  <h2>Officer Details</h2>
                  <div className="row">
                    {validateData(selectedData?.detail?.officer_details?.officer_name) ?
                      <div className="col-lg-4">
                        <h3>Officer Name</h3>
                        {renderDiv(selectedData?.detail?.officer_details?.officer_name, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.officer_details?.badge_id) ?
                      <div className="col-lg-4">
                        <h3>Badge ID</h3>
                        {renderDiv(selectedData?.detail?.officer_details?.badge_id, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.officer_details?.device_friendly_name) ?
                      <div className="col-lg-4">
                        <h3>Device Name</h3>
                        {renderDiv(selectedData?.detail?.officer_details?.device_friendly_name, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.officer_details?.shift) ?
                      <div className="col-lg-4">
                        <h3>Shift</h3>
                        {renderDiv(selectedData?.detail?.officer_details?.shift, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.officer_details?.zone) ?
                      <div className="col-lg-4">
                        <h3>Zone</h3>
                        {renderDiv(selectedData?.detail?.officer_details?.zone, 'p')}
                      </div>
                      : null
                    }
                    {validateData(selectedData?.detail?.officer_details?.beat) ?
                      <div className="col-lg-4">
                        <h3>Beat</h3>
                        {renderDiv(selectedData?.detail?.officer_details?.beat, 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>

                {/* Payment Details */}
                <div className="ticket-border">
                  <h2>Payment Details</h2>
                  <div className="row">
                    {renderDivWithClass(selectedData?.detail?.payment_details?.Amount, 'Payment Amount')}
                    {renderDivWithClass(selectedData?.detail?.payment_details?.PaymentMode, 'Payment Mode')}
                    {renderDivWithClass(selectedData?.detail?.payment_details?.PaymentStatus, 'Payment Status')}
                    {renderDivWithClass(selectedData?.detail?.payment_details?.ReceiptID, 'Receipt ID')}
                    {renderDivWithClass(formatDate(selectedData?.detail?.payment_details?.PaymentDatetime), 'Payment Date')}
                  </div>
                  <div className="page-footer">
                    {
                      <p>3</p>
                    }
                  </div>
                </div>
              </div>

              {/* Page 4 */}
              <div className="page ticket-detail">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                  <h1>Commercial Page</h1>
                </div>

                {/* Commercial Details */}
                <div className="ticket-border">
                  <h2>Commercial Details</h2>
                  <div className="row">
                    {validateData(commercialData?.fine) ?
                      <div className="col-lg-4">
                        <h3>Fine</h3>
                        {renderDiv(`$${commercialData?.fine}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(commercialData?.total_due) ?
                      <div className="col-lg-4">
                        <h3>Total Due</h3>
                        {renderDiv(`$${commercialData?.total_due}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(commercialData?.service_fee) ?
                      <div className="col-lg-4">
                        <h3>Service Fee</h3>
                        {renderDiv(`$${commercialData?.service_fee}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(commercialData?.fine_increase) ?
                      <div className="col-lg-4">
                        <h3>Fine Increase</h3>
                        {renderDiv(`$${commercialData?.fine_increase}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(commercialData?.nsf_fee) ?
                      <div className="col-lg-4">
                        <h3>NSF Fee</h3>
                        {renderDiv(`$${commercialData?.nsf_fee}`, 'p')}
                      </div>
                      : null
                    }
                    {validateData(commercialData?.boot_tow_fee) ?
                      <div className="col-lg-4">
                        <h3>Boot Tow Fee</h3>
                        {renderDiv(`$${commercialData?.boot_tow_fee}`, 'p')}
                      </div>
                      : null
                    }
                    {renderDivWithClass(commercialData?.collection_fee, 'Collection Fee')}
                    {renderDivWithClass(commercialData?.payments, 'Payments')}
                    {renderDivWithClass(commercialData?.remark, 'Remark')}
                    {renderDivWithClass(commercialData?.dmv_case_number, 'DMV Case Number')}
                    {renderDivWithClass(formatDate(commercialData?.paid_date), 'Paid Date')}
                    {renderDivWithClass(formatDate(commercialData?.fine_increase_date), 'Fine Increase Date')}
                    {renderDivWithClass(formatDate(commercialData?.nsf_applied_date), 'NSF Applied Date')}
                    {renderDivWithClass(formatDate(commercialData?.boot_applied_date), 'Boot Tow Applied Date')}
                    {renderDivWithClass(formatDate(commercialData?.adjusted_balance_date), 'Adjusted Balance Date')}
                    {validateData(commercialData?.dmv_case_number) ?
                      <div className="col-lg-4">
                        <h3>DMV Case Number</h3>
                        {renderDiv(commercialData?.dmv_case_number, 'p')}
                      </div>
                      : null
                    }
                  </div>
                </div>

                {/* Escalation Details */}
                <div className="ticket-border">
                  <h2>Escalation Details</h2>
                  <div className="row">
                    {commercialData.escalation_detail?.map((item) => {
                      return (
                        <>
                          {renderDivWithClass(item?.order_number, 'Order Number')}
                          {renderDivWithClass(formatDate(item?.date), 'Escalation Date')}
                          {renderDivWithClass(`$${item?.amount}`, 'Escalation Amount')}
                        </>
                      )
                    }
                    )}
                  </div>
                </div>
                <div className="page-footer">
                  {
                    <p>4</p>
                  }
                </div>
              </div>

              {/* Page 5 */}
              <div className="page ticket-detail">

                {/* Appeal Details */}
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                  <h1>Appeal Details</h1>
                </div>

                {/* Appeal User Details */}
                <div className="ticket-border">
                  <h2>Appeal User Details</h2>
                  <div className="row">
                    {renderDivWithClass(appealData?.first_name, 'First Name')}
                    {renderDivWithClass(appealData?.last_name, 'Last Name')}
                    {renderDivWithClass(appealData?.block, 'Block')}
                    {renderDivWithClass(appealData?.street, 'Street')}
                    {renderDivWithClass(appealData?.state, 'State')}
                    {renderDivWithClass(appealData?.zip, 'Zip Code')}
                    {renderDivWithClass(appealData?.email, 'Email')}
                    {renderDivWithClass(appealData?.telephone, 'Phone')}
                  </div>
                </div>
                {/* Reason For Appeal */}
                <div className="ticket-border">
                  <h2>Reason For Appeal</h2>
                  <div className="row">
                    {renderDivWithClass(formatDate(appealData?.created_at), 'Appeal Create Time')}
                    {renderDivWithClass(appealData?.appeal_reason, 'Appeal Reason')}
                  </div>
                  <div>
                    <h3>User Comment</h3>
                    {renderDiv(`${appealData?.appeal_additional_reason}`, 'p')}
                  </div>
                </div>
                <div className="page-footer">
                  {
                    <p>5</p>
                  }
                </div>
              </div>

              {/* Page 6 */}
              <div className="page ticket-image">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                </div>
                <div className="ticket-border">
                  <h2>Attachments</h2>
                  {appealAttachment.length > 0 ?
                    <div className="ticket-attachment-img">
                      {appealAttachment?.map((image) => (
                        // opne the image file
                        <a href={image.url} target="_blank" rel="noopener noreferrer" >
                          <img className="ticket-attachment-img" src={image.url} alt={"attachment" + image.image_spec} />
                        </a>
                      ))}
                    </div>
                    : null
                  }
                </div>
                <div className="page-footer">
                  {
                    <p>6</p>
                  }
                </div>
              </div>

              {/* Page 7 */}
              <div className="page ticket-detail">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                </div>
                <div className="ticket-border ticket-attachment-pdf">
                  <h2>Attachments</h2>
                  {pdfData.length ?
                    <div className="ticket-attachment-container">
                      {pdfData.map((pdfUrl, index) => (
                        <div className="single-pdf" key={index}>
                          <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                            <Document file={pdfUrl}>
                              <Page pageNumber={1} height={350} scale={1} />
                            </Document>
                          </a>
                        </div>
                      ))}
                    </div>
                    :
                    <p>No Attachments Available</p>
                  }
                </div>
              </div>

              {/* Page 8 */}
              <div className="page ticket-detail">
                <div className="ticket-border">
                  <img
                    src={logo}
                    alt="logo"
                    className='page-header logo-image-top'
                  />
                </div>

                {/* Appeal Notice */}
                <div className="ticket-border">
                  <h2>Appeal Notice</h2>
                  <div className="row">
                    {selectedData?.detail?.notification_audit_trail?.map((item) => {
                      return <>
                        {renderDivWithClass(formatDate(item?.CreatedAt), 'Date')}
                        {renderDivWithClass(item?.Email, 'Email Sent To')}
                        {renderDivWithClass(item?.SentBy, 'Sent By')}
                        {renderDivWithClass(item?.NotificationType, 'Notification Type')}
                        {renderDivWithClass(item?.Address, 'Address')}
                      </>
                    }
                    )}
                  </div>
                </div>
                {/* Appeal Remarks */}
                <div className="ticket-border">
                  <h2>Appeal Remarks</h2>
                  {appealData?.workflow?.map((workflow) => (
                    <div className="row">
                      {renderDivWithClass(workflow?.remark, 'Remark')}
                      {renderDivWithClass(workflow?.additional_remark, 'Additional Remark')}
                      {renderDivWithClass(workflow?.created_by, 'Created By')}
                      {renderDivWithClass(formatDate(workflow?.created_at_local), 'Created At')}
                    </div>
                  ))}
                </div>

                <div className="page-footer">
                  {
                    <p>8</p>
                  }
                </div>
              </div>

              {/* Page 9 */}
              {auditTrailRender(selectedData?.detail?.audit_trail)}
            </HTMLFlipBook>
          </div >
        }
      </div >
    </>
  )
}

export default FlipbookPage;