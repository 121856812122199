import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import mainService from '../../Services/main.service'

//images
import transfer from '../../Images/transfer.png';
import { refreshTokenMiddleware } from '../customMiddelware';
import PrintPdfCsvExcelDownload from '../Utilies/PrintPdfCsvExcelDownload';
import { changeticket } from '../../Global/site';


function pad2(n) {
    return (n < 10 ? '0' : '') + n;
}

const ROCountReport = () => {

    const dispatch = useDispatch()
    const [roCount, setRoCount] = useState({})
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [filterDate, setFilterDate] = useState({
        received_ts_from: new Date().setHours(0, 0, 0),
        received_ts_to: new Date().setDate(new Date().getDate() + 1)
    });

    const changeName = changeticket();

    const [sorting, setSorting] = useState({ field: 'xyz', ascending: true })

    useEffect(() => {
        const currentDataCopy = { ...roCount }
        if (currentDataCopy?.table_data) {
            const sortedCurrentdata = currentDataCopy?.table_data.sort((a, b) => {
                return a[sorting.key]?.toString().localeCompare(b[sorting.key].toString());
            });
            setRoCount({ ...roCount, ['table_data']: sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse() });
        }
    }, [sorting]);

    const applySorting = (key, ascending) => {
        setSorting({ key: key, ascending: ascending });
    }


    useEffect(() => {
        let query_string = ''
        if (filterDate.received_ts_from) {
            query_string += 'received_ts_from=' + convertFilterFormatData(filterDate.received_ts_from)
        }
        if (filterDate.received_ts_to) {
            query_string += '&received_ts_to=' + convertFilterFormatData(filterDate.received_ts_to)
        }

        getROcountofReports(query_string)

    }, [])



    const getROcountofReports = async (query) => {
        setLoader(true)
        try {
            const result = await mainService.getROCountAPIReport(query)


            if (result.data.status) {
                setRoCount(result.data.data)
                setLoader(false)
            } else {
                setMessage('No Record Found')
            }
            setLoader(false)
        } catch (err) {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                getROcountofReports(query)
            } else {
                setMessage('No Record Found')
                setLoader(false)
            }
        }
    }

    function convertFilterFormatData(dt) {
        var date = new Date(dt);
        var year = date.getFullYear();
        var month = pad2(date.getMonth() + 1);
        var todayDate = pad2(date.getDate());

        var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z";

        return currentTimeInSeconds
    }

    const onChangeFilterDate = (date, name) => {
        const newFilterDate = { ...filterDate };
        if (!date) {
          newFilterDate['received_ts_to'] = "";
          setFilterDate(newFilterDate);
          return;
        }
        newFilterDate[name] = date;
      const timeValue = new Date(date);
      const hours = timeValue.getHours();
      const minutes = timeValue.getMinutes();
        if (name === 'received_ts_to') {
            const currentFilterToDate = new Date(filterDate.received_ts_to);
          const isDateChange = !filterDate?.received_ts_to || (hours == 0 && minutes == 0) || date?.getDate() !== currentFilterToDate?.getDate();
          if(isDateChange){
            let formattedDate = new Date(date);
            formattedDate.setHours(23, 59, 59, 999);
            newFilterDate['received_ts_to'] = formattedDate;
          }
          setFilterDate(newFilterDate);
        } else {
          setFilterDate(newFilterDate);
        }
      };

    const handleFilterSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        setRoCount([])
        let query_string = ''
        if (filterDate.received_ts_from) {
            query_string += 'received_ts_from=' + convertFilterFormatData(filterDate.received_ts_from)
        }
        if (filterDate.received_ts_to) {
            query_string += '&received_ts_to=' + convertFilterFormatData(filterDate.received_ts_to)
        }

        getROcountofReports(query_string)
    }

    const barGraphData = {
        labels: ['Failed Plate Validation', 'Found', 'Ineligible State Code', 'Ineligible Sub Client', 'Invalid State Code', 'Not Found'],
        datasets: [{
            label: '',
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 8,
            data: [roCount.failed_plate_validation, roCount.found, roCount.ineligible_state_code, roCount.ineligible_sub_client, roCount.invalid_state_code, roCount.not_found],
            backgroundColor: [
                '#FFB34D',
                '#FF0000',
                '#029279',
                '#8d6f35',
                '#044271',
                '#d24eff'
            ],
            borderWidth: 1
        }]
    };

    const barGraphOptions = {
        responsive: true,
        title: { text: "THICCNESS SCALE", display: true },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }],
            xAxes: [{
                categorySpacing: 0
            }]
        },
        plugins: {
            legend: {
                display: false
            },
        },
    }

    return (
        <>
            <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>

                <div className="cstm-ticket-manage-sec">
                    <div className="cstm-ticket-manage-heading">
                        <h3 className="cstm-all-pg-ttl operate-ttl">RO Count Report</h3>
                    </div>

                    <PrintPdfCsvExcelDownload tableId='tbl-main-download' fileName='ro-count-report' pageSize='a2' />

                </div>

                <div className="report-searchbar">
                    <form>
                        <div className="report-date report-mr">
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                autoComplete='off'
                                className="cstm-pull-details schedule-date-input"
                                name="received_ts_from"
                                selected={filterDate.received_ts_from}
                                onChange={date => onChangeFilterDate(date, 'received_ts_from')}
                            />
                            <img src={transfer} alt="transfer" className="date-transfer" />
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                placeholderText='MM/DD/YYYY HH:MM'
                                autoComplete='off'
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                className="cstm-pull-details schedule-date-input"
                                name="received_ts_to"
                                selected={filterDate.received_ts_to}
                                onChange={date => onChangeFilterDate(date, 'received_ts_to')}
                            />
                        </div>
                        <button className="licence-print report-mr" onClick={handleFilterSubmit}>Submit</button>
                    </form>
                </div>

                <br />


                {loader ?
                    <div className='text-center spin-load-main'>
                        <div className="spinner-border"></div>
                    </div>
                    :
                    (roCount.table_data === null || roCount.table_data?.length === 0 ?
                        <div className='no-record-found-main'>
                            {message || 'No Record Found'}
                        </div>
                        :
                        <div>
                            <div className="col-md-12">
                                <div className="ticket-graph-box">
                                    <div className="ticket-graph-subttl">
                                        <h4>Bar Graph</h4>
                                        <div className="ticket-graph-img">
                                            <Bar
                                                width={250}
                                                height={70}
                                                data={barGraphData}
                                                options={barGraphOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div id="Dashboard" className="tab-pane active">

                                <div className="ctsm-table-scroll">
                                    <div className="table-overflow" id='tbl-main-download'>
                                        <table className="table-heading table table-bordered" id='cstm-report-tb-display'>
                                            <thead>
                                                <tr>
                                                    <th className={`srt-tab ${sorting.key === 'date' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('date', !sorting.ascending)}>Date</th>
                                                    <th className={`srt-tab ${sorting.key === 'found' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('found', !sorting.ascending)}>Found</th>
                                                    <th className={`srt-tab ${sorting.key === 'not_found' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('not_found', !sorting.ascending)}>Not Found</th>
                                                    <th className={`srt-tab ${sorting.key === 'ineligible_state_code' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('ineligible_state_code', !sorting.ascending)}>Ineligible State Code</th>
                                                    <th className={`srt-tab ${sorting.key === 'invalid_state_code' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('invalid_state_code', !sorting.ascending)}>Invalid State Code</th>
                                                    <th className={`srt-tab ${sorting.key === 'failed_plate_validation' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('failed_plate_validation', !sorting.ascending)}>Failed Plate validation</th>
                                                    <th className={`srt-tab ${sorting.key === 'ticket_return_for_same_client' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('ticket_return_for_same_client', !sorting.ascending)}>{changeName} return for same client</th>
                                                    <th className={`srt-tab ${sorting.key === 'ticket_return_for_a_different_client' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('ticket_return_for_a_different_client', !sorting.ascending)}>{changeName} return for a different client</th>
                                                    <th className={`srt-tab ${sorting.key === 'ineligible_sub_client' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('ineligible_sub_client', !sorting.ascending)}>Ineligible Sub Client</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!loader &&
                                                    <>
                                                        {(roCount.table_data || []).map((item, i) => (
                                                            <tr key={i}>
                                                                <td>{item.date}</td>
                                                                <td>{item.found}</td>
                                                                <td>{item.not_found}</td>
                                                                <td>{item.ineligible_state_code}</td>
                                                                <td>{item.invalid_state_code}</td>
                                                                <td>{item.failed_plate_validation}</td>
                                                                <td>{item.ticket_return_for_same_client}</td>
                                                                <td>{item.ticket_return_for_a_different_client}</td>
                                                                <td> {item.ineligible_sub_client} </td>
                                                            </tr>
                                                        ))}
                                                        <tr className='not-srt'>
                                                            <td>Total</td>
                                                            <td>{roCount.found}</td>
                                                            <td>{roCount.not_found}</td>
                                                            <td>{roCount.ineligible_state_code}</td>
                                                            <td>{roCount.invalid_state_code}</td>
                                                            <td>{roCount.failed_plate_validation}</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>{roCount.ineligible_sub_client}</td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }

            </div>
        </>
    )
}

export default ROCountReport;