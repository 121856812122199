import React, { useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { changetotal, changeticket } from '../../../Global/site';

const ProductTable = (props) => {

  const [count, setCount] = React.useState(0);
  const changeName = changeticket();
  const changeTotal = changetotal();


  const onClickDetailsPage = (e, id) => {
    return props.onClickDetailsPage(true);
  }

  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2)
}
 

  const commanPageLoadFunction = () => {
    $(function () {
      $('table')
        .on('click', '.srt-tab', function () {


          var index = $(this).index(),
            rows = [],
            thClass = $(this).hasClass('srt-ace') ? 'srt-desc' : 'srt-ace';

          $('#cstm-report-tb-display th').removeClass('srt-ace srt-desc');
          $(this).addClass(thClass);

          $('#cstm-report-tb-display tbody tr').each(function (index, row) {
            rows.push($(row).detach());
          });

          rows.sort(function (a, b) {
            var aValue = $(a).find('td').eq(index).text(),
              bValue = $(b).find('td').eq(index).text();

            return aValue > bValue
              ? 1
              : aValue < bValue
                ? -1
                : 0;
          });

          if ($(this).hasClass('srt-desc')) {
            rows.reverse();
          }

          $.each(rows, function (index, row) {
            $('#cstm-report-tb-display tbody').append(row);
          });
        });
    });

  }

  useEffect(() => { 
    commanPageLoadFunction();  
    setCount(100);
  }, []); 

  console.log(props.products, "table data ")

  return (

    <>

      <div className="report_tbl_scroll" id="tbl-main-download">
        <table className="table table-heading table-bordered" id="cstm-report-tb-display">
          <thead>
            <tr> 
              <th className="srt-tab">
              Issue Date Time
              </th>
              <th className="srt-tab">
              {changeName} Number
              </th>
              <th className="srt-tab">
              Requested Date
              </th>
              <th className="srt-tab">
              Received Date
              </th>
              <th className="srt-tab">
              DMV Status
              </th>
              <th className="srt-tab">
              RO Source
              </th>
              <th className="srt-tab">
              Violation Code
              </th>
              <th className="srt-tab">
              Violation Description
              </th>
              <th className="srt-tab">
              {changeTotal} Status
              </th>
              <th className="srt-tab">
              Fine Amount
              </th> 
            </tr>

          </thead>
          <tbody>
            {props.products.map((item, index) => (

              <tr key={item.id}>  
              {item.citation_issue_timestamp !== "" && item.citation_issue_timestamp !== null && item.citation_issue_timestamp !== "null" && item.citation_issue_timestamp !== undefined && item.citation_issue_timestamp.substring(0, 4) !== '0000' && item.citation_issue_timestamp.substring(0, 4) !== '0001' ?
                <td>
                  <Link onClick={(e) => onClickDetailsPage(e)} state={{ id: item.ticket_no }}>{moment(item.citation_issue_timestamp.split('T')[0]).format('MM/DD/YYYY') + " " + (item.citation_issue_timestamp).substring(11, 19)}</Link> 
                </td>
                :
                <td>-</td>
              }

                {item.ticket_no !== "" && item.ticket_no !== null && item.ticket_no !== "null" && item.ticket_no !== undefined ?
                  <td>{item.ticket_no}</td>
                  :
                  <td>-</td>
                }
                {item.ro_info_requested_at !== "" && item.ro_info_requested_at !== null && item.ro_info_requested_at !== "null" && item.ro_info_requested_at !== undefined && item.ro_info_requested_at.substring(0, 4) !== '0000' && item.ro_info_requested_at.substring(0, 4) !== '0001' ?
                  <td>{moment(item.ro_info_requested_at.split('T')[0]).format('MM/DD/YYYY') + " " + (item.ro_info_requested_at).substring(11, 19)}
                </td>
                  :
                  <td>-</td>
                }
                 {item.ro_info_received_at !== "" && item.ro_info_received_at !== null && item.ro_info_received_at !== "null" && item.ro_info_received_at !== undefined  && item.ro_info_received_at.substring(0, 4) !== '0000' && item.ro_info_received_at.substring(0, 4) !== '0001' ?
                  <td>{moment(item.ro_info_received_at.split('T')[0]).format('MM/DD/YYYY') + " " + (item.ro_info_received_at).substring(11, 19)}
                </td>
                  :
                  <td>-</td>
                }
                {item.ro_request_status !== "" && item.ro_request_status !== null && item.ro_request_status !== "null" && item.ro_request_status !== undefined ?
                  <td>{item.ro_request_status}</td>
                  :
                  <td>-</td>
                }
                 {item?.registered_ro_detail?.ro_source !== "" && item?.registered_ro_detail?.ro_source !== null && item?.registered_ro_detail?.ro_source !== "null" && item?.registered_ro_detail?.ro_source !== undefined ?
                  <td>{item?.registered_ro_detail?.ro_source}</td>
                  :
                  <td>-</td>
                }
                {item.violation_details.code !== "" && item.violation_details.code !== "null" && item.violation_details.code !== null && item.violation_details.code !== undefined ?
                  <td>{item.violation_details.code}</td>
                  :
                  <td>-</td>
                }
                {item.violation_details.description !== "" && item.violation_details.description !== "null" && item.violation_details.description !== null && item.violation_details.description !== undefined ?
                  <td>{item.violation_details.description}</td>
                  :
                  <td>-</td>
                } 

                {item.citation_status !== "" && item.citation_status !== null && item.citation_status !== "null" && item.citation_status !== undefined ?
                  <td>{item.citation_status}</td>
                  :
                  <td>-</td>
                }
                {item.fine_amount !== "" && item.fine_amount !== null && item.fine_amount !== "null" && item.fine_amount !== undefined ?
                  <td>${convertDecimal(item.fine_amount)}</td>
                  :
                  <td>$0.00</td>
                } 
              </tr>
            ))}
          </tbody>
        </table>
      </div> 

    </>

  );
};

export default ProductTable;
