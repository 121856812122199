import React, { useState, useEffect } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import Cookiesremove from '../../../Cookiesremove';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

const options = {
    scaleShowGridLines: true,
    scaleGridLineColor: 'rgba(0,0,0,.05)',
    scaleGridLineWidth: 1,
    scaleShowHorizontalLines: true,
    scaleShowVerticalLines: true,
    bezierCurve: true,
    bezierCurveTension: 0.4,
    pointDot: true,
    pointDotRadius: 4,
    pointDotStrokeWidth: 1,
    pointHitDetectionRadius: 20,
    datasetStroke: true,
    datasetStrokeWidth: 2,
    datasetFill: true,
    legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',
}

const styles = {
    graphContainer: {
        // border: '1px solid black',
        // padding: '15%'
    }
}

function LineChartRevenue() {

    const [count, setCount] = React.useState(0);
    const [showXaxisValue, setXaxisValue] = React.useState([]);
    const [showAuthToken, setAuthToken] = useState(false);
    const [showTotalRevenueFromTime, setTotalRevenueFromTime] = React.useState([]);
    const [showScofflawRevenue, setScofflawRevenue] = React.useState([]);
    const [showRevenueZone, setRevenueZone] = React.useState([]);
    const [showRevenueSquad, setRevenueSquad] = React.useState([]);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
    const [showTodayButton, setTodayButton] = useState(true)
    const [showMonthButton, setMonthButton] = useState(false)
    const [showYearButton, setYearButton] = useState(false)

    const closeError = () => {
        setErrorHandling(false)
    }

    var chartData = {
        labels: showXaxisValue,
        datasets: [
            {
                label: 'Total Revenues',
                fillColor: 'rgba(220,220,220,0.2)',
                borderColor: "#FFB34D",
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                radius: 2,
                pointHighlightStroke: 'rgba(220,220,220,1)',
                data: showTotalRevenueFromTime,
            },
            {
                label: 'Average Revenue by Officer',
                radius: 2,
                borderColor: "#029279",
                pointColor: 'rgba(151,187,205,1)',
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: showScofflawRevenue,
            },
            {
                label: 'Average Revenue by Zone',
                borderColor: "#FA5944",
                pointColor: 'red',
                radius: 2,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: showRevenueZone,
            },
            {
                label: 'Average Revenue by Squad',
                borderColor: "#044271",
                radius: 2,
                pointColor: 'rgba(151,187,205,1)',
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: showRevenueSquad,
            },
        ]
    }

    const onChangeYear = (e) => {

        setTodayButton(false)
        setMonthButton(false)
        setYearButton(true)

        Dashboard.getRevenueReport().then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalRevenueFromTime(response.data.data[0].metadata.array.total_revenue)
                        setScofflawRevenue(response.data.data[0].metadata.array.avg_revenue_by_officer)
                        setRevenueZone(response.data.data[0].metadata.array.avg_revenue_by_zone)
                        setRevenueSquad(response.data.data[0].metadata.array.avg_revenue_by_squad)
                        setXaxisValue(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'])
                    }
                }
            }
        )
    }

    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    const onChangeMonth = (e) => {

        setTodayButton(false)
        setMonthButton(true)
        setYearButton(false)

        Dashboard.getRevenueReport().then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalRevenueFromTime(response.data.data[0].metadata.array.total_revenue)
                        setScofflawRevenue(response.data.data[0].metadata.array.avg_revenue_by_officer)
                        setRevenueZone(response.data.data[0].metadata.array.avg_revenue_by_zone)
                        setRevenueSquad(response.data.data[0].metadata.array.avg_revenue_by_squad)
                        setXaxisValue(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'])
                    }
                }
            }
        )
    }

    const OnTodayChart = () => {
        setTodayButton(true)
        setMonthButton(false)
        setYearButton(false)

        Dashboard.getRevenueReport().then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalRevenueFromTime(response.data.data[0].metadata.array.total_revenue)
                        setScofflawRevenue(response.data.data[0].metadata.array.avg_revenue_by_officer)
                        setRevenueZone(response.data.data[0].metadata.array.avg_revenue_by_zone)
                        setRevenueSquad(response.data.data[0].metadata.array.avg_revenue_by_squad)
                        setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                    }
                }
            }
        )
    }

    useEffect(() => {

        Dashboard.getRevenueReport().then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        const totalRevenue = response.data.data[0].metadata.array.total_revenue;
                        let labels = [];
                        totalRevenue.forEach(element => {
                            labels.push("$" + element);
                        });
                        setTotalRevenueFromTime(totalRevenue);
                        setScofflawRevenue(response.data.data[0].metadata.array.avg_revenue_by_officer)
                        setRevenueZone(response.data.data[0].metadata.array.avg_revenue_by_zone)
                        setRevenueSquad(response.data.data[0].metadata.array.avg_revenue_by_squad)
                        setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                    }
                }
            }
        )
        setCount(100);
    }, []);


    return (
        <>
         <div className='col-12'>
         <div className="cstm-operation-summary-top">
                <div className="cstm-operation-summary-01 cstm_hour_sect_ttl">Hour of the day Revenue Trend</div>


                <div className="cstm-operation-summary-03">
                    <div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showTodayButton ? 'active' : ''}`} name="today" onClick={OnTodayChart}>Today</button>
                    </div>

                    <div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showMonthButton ? 'active' : ''}`} name="monthly" onClick={onChangeMonth}>Monthly</button>
                    </div>

                    <div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showYearButton ? 'active' : ''}`} name="yearly" onClick={onChangeYear}>Yearly</button>
                    </div>
                </div>

                <div className="cstm-operation-summary-02 cstm-only-ps cstm_below_line_sect">
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#FFB34D" }}></span>
                        <span className="cstm-os-name">Total Revenues</span>
                    </div>
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#029279" }}></span>
                        <span className="cstm-os-name">Average Revenue by Officer</span>
                    </div>
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#FA5944" }}></span>
                        <span className="cstm-os-name">Average Revenue by Zone</span>
                    </div>
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#044271" }}></span>
                        <span className="cstm-os-name">Average Revenue by Squad</span>
                    </div>
                </div>

            </div>
         </div>

     <div className='col-12'>
     <div className="cstm-operation-summary-bottom">
                <div style={styles.graphContainer}>
                    <LineChart data={chartData}
                        options={options}
                        width="500" height="150" />
                </div>
            </div>
     </div>
          
        </>

    )

}

export default LineChartRevenue;