import React, { useRef, useState } from "react";
import moment from "moment";
import { changeticket } from "../../Global/site";
import { getFormattedDate, getFormattedDueDate } from "../Common/utils";
import SupportNotesTable from "./SupportNotesTable";
import ImageSlider from "../../utile/imageSlider/imageSlider";

const CitationInfoTab = ({
  citationAllData,
  imageForCitationDownload,
}) => {
  const changeName = changeticket();
  const [imageId, setImageId] = useState();
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);

  const durationInViolation = citationAllData?.violation_details?.duration_in_violation;
  const duration = moment.duration(durationInViolation, 'minutes');
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.minutes());

  const slideImages = imageForCitationDownload.map((item) => ({
    url: item.url,
  }));

  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const sliderPopupRef = useRef(null);

  return (
    <>
      <div className="box1">
        <div className="col-12 mb-3">
          <h6>Images</h6>
        </div>

        <div className="cstm-appeal-status-main-info">
          <div className="cstm-attactment-main image-citation-detail-cstm">
            {(imageForCitationDownload || []).map((img, i) => (
              <div key={i} className="ctsm-attachment-img user-img">
                <img src={img.url} alt={`images ${i}`}
                  onClick={() => (
                    setImageId(Number(img.image_spec)),
                    setShowImagesModalSlider(true)
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {citationAllData?.category === 'invoice' && <div className="box1">
        <div className="row">
          <div className="col-12 userdetail-txt1">
            <h4>Invoice Timing Details</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-3 result1 hide-col">
            <p>Vehicle In Time</p>
            <h5>{getFormattedDate(citationAllData?.vehicle_in_time)}</h5>
          </div>
          <div className="col-3 result1 hide-col">
            <p>Vehicle Out Time</p>
            <h5>{getFormattedDate(citationAllData?.vehicle_out_time)}</h5>
          </div>
          <div className="col-3 result1 hide-col">
            <p>Duration In Violation</p>
            <h5>{hours} Hrs {minutes} Min</h5>
          </div>
        </div>
      </div>}

      <div className="box1">
        <div className="row">
          <div className="col-12 userdetail-txt1">
            <h4>{changeName} Info</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-3 result1 hide-col">
            <p>{changeName} Due Date</p>
            {citationAllData && citationAllData.current_due_date ? (
              <h5>{getFormattedDueDate(citationAllData?.current_due_date)}</h5>
            ) : (
              <h5>-</h5>
            )}
          </div>
          <div className="col-3 result1 hide-col">
            <p>Drive Off</p>
            {citationAllData.drive_off ? <h5>True</h5> : <h5>False</h5>}
          </div>
          <div className="col-3 result1 hide-col">
            <p>TVR</p>
            {citationAllData.tvr ? <h5>True</h5> : <h5>False</h5>}
          </div>
          <div className="col-3 result1 hide-col">
            <p>{changeName} Type</p>
            {citationAllData.type ? (
              <h5>{citationAllData.type}</h5>
            ) : (
              <h5>-</h5>
            )}
          </div>
        </div>
        <hr />
        {citationAllData && citationAllData.officer_details && (
          <>
            <h4>Officer Details</h4>
            <div className="row">
              <div className="col-xl-3 col-6 result1">
                <p>Officer Name</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.officer_name ? (
                  <h5>{citationAllData.officer_details.officer_name}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-xl-3 col-6 result1">
                <p>Peo Name</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.peo_lname ? (
                  <h5>{citationAllData.officer_details.peo_lname}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-3 result1 hide-col">
                <p>Badge ID</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.badge_id ? (
                  <h5>{citationAllData.officer_details.badge_id}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-3 result1 hide-col">
                <p>Squad</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.squad ? (
                  <h5>{citationAllData.officer_details.squad}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-6 result1">
                <p>Agency</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.agency ? (
                  <h5>{citationAllData.officer_details.agency}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-xl-3 col-6 result1">
                <p>Beat</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.beat ? (
                  <h5>{citationAllData.officer_details.beat}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-3 result1 hide-col">
                <p>Zone</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.zone ? (
                  <h5>{citationAllData.officer_details.zone}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-3 result1 hide-col">
                <p>Shift</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.shift ? (
                  <h5>{citationAllData.officer_details.shift}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-6 result1">
                <p>Device ID</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.device_id ? (
                  <h5>{citationAllData.officer_details.device_id}</h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
              <div className="col-xl-3 col-6 result1">
                <p>Device Friendly Name</p>
                {citationAllData &&
                  citationAllData.officer_details &&
                  citationAllData.officer_details.device_friendly_name ? (
                  <h5>
                    {citationAllData.officer_details.device_friendly_name}
                  </h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="box1">
        <div className="row">
          <div className="col-12 userdetail-txt1">
            <h4>Vehicle Details</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Vehicle Make</p>
            <h5>{citationAllData?.vehicle_details?.make || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Vehicle Model</p>
            <h5>{citationAllData?.vehicle_details?.model || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Vehicle Color</p>
            <h5>{citationAllData?.vehicle_details?.color || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Licence Plate Number</p>
            <h5>{citationAllData?.vehicle_details?.lp_number || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Licence State</p>
            <h5>{citationAllData?.vehicle_details?.state || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>exp Year</p>
            <h5>{citationAllData?.vehicle_details?.license_expiry || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Body Style</p>
            <h5>{citationAllData?.vehicle_details?.body_style || "-"}</h5>
          </div>
        </div>
      </div>
      <div className="box1">
        <div className="row">
          <div className="col-12 userdetail-txt1">
            <h4>Location Details</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Street</p>
            <h5>{citationAllData?.location?.street || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Block</p>
            <h5>{citationAllData?.location?.block || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Side</p>
            <h5>{citationAllData?.location?.side || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Direction</p>
            <h5>{citationAllData?.location?.direction || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Meter No</p>
            <h5>{citationAllData?.location?.meter || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Lot No</p>
            <h5>{citationAllData?.location?.lot || "-"}</h5>
          </div>
        </div>
      </div>
      <div className="box1">
        <div className="row">
          <div className="col-12 userdetail-txt1">
            <h4>Violation Details</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Code</p>
            <h5>{citationAllData?.violation_details?.code || "-"}</h5>
          </div>
          <div className="col-3 user-info">
            <p>Description</p>
            <h5>{citationAllData?.violation_details?.description || "-"}</h5>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
            <p>Late Fine</p>
            <h5>
              $
              {convertDecimal(
                citationAllData?.violation_details?.late_fine || "0.00"
              )}
            </h5>
          </div>
        </div>
      </div>
      <div className="box1">
        <div className="row">
          <div className="col-12 userdetail-txt1">
            <h4>Comment Details</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6 user-info">
            <p>Note 1</p>
            <h5>{citationAllData?.comment_details?.note_1 || "-"}</h5>
          </div>
          <div className="col-6 user-info">
            <p>Note 2</p>
            <h5>{citationAllData?.comment_details?.note_2 || "-"}</h5>
          </div>
          <div className="col-6 user-info">
            <p>Remark 1</p>
            <h5>{citationAllData?.comment_details?.remark_1 || "-"}</h5>
          </div>
          <div className="col-6 user-info">
            <p>Remark 2</p>
            <h5>{citationAllData?.comment_details?.remark_2 || "-"}</h5>
          </div>
        </div>
        <hr />

        <h4>Fine Adjustments</h4>
        <div className="row">
          <div className="col-xl-3 col-6 result1">
            <p>Increase amount</p>
            {citationAllData && citationAllData.increase_amount && (
              <h5>
                ${convertDecimal(citationAllData.increase_amount || "0.00")}
              </h5>
            )}
          </div>
          <div className="col-3 result1 hide-col">
            <p>NSF fees</p>
            {citationAllData && citationAllData.nsf_fee && (
              <h5>${convertDecimal(citationAllData.nsf_fee || "0.00")}</h5>
            )}
          </div>
          <div className="col-3 result1 hide-col">
            <p>Corrected</p>
            <h5>-</h5>
          </div>
          <div className="col-xl-3 col-6 result1">
            <p>Dismissed</p>
            <h5>-</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-3 result1 hide-col">
            <p>Balance due</p>
            <h5>-</h5>
          </div>
          <div className="col-xl-3 col-6 result1">
            <p>Boot Tow Fee</p>
            {citationAllData && citationAllData.boot_tow_fee && (
              <h5>${convertDecimal(citationAllData.boot_tow_fee || "0.00")}</h5>
            )}
          </div>
        </div>

        {citationAllData.escalation_detail === null ? (
          ""
        ) : (
          <>
            <hr />
            <h4>Escalation details</h4>
            <div className="row">
              {(citationAllData.escalation_detail || []).map((item, i) => (
                <div key={i} className="col-3 result1 hide-col">
                  <p>
                    Amount due after{" "}
                    {moment(item.date.split("T")[0]).format("MM/DD/YYYY") +
                      " " +
                      item.date.substring(11, 19)}
                  </p>
                  <h5>${convertDecimal(item.amount || "0")}</h5>
                </div>
              ))}
            </div>
          </>
        )}
        <hr />
        <h4>{changeName} status</h4>
        <div className="row">
          {citationAllData &&
            citationAllData.late_fee_detail &&
            Object.keys(citationAllData.late_fee_detail).map((item, index) => (
              <div key={index} className="col-xl-3 col-6 result1">
                <p>{item}</p>
                <h5>{citationAllData.late_fee_detail[item]}</h5>
              </div>
            ))}
          <div className="col-3 result1 hide-col">
            <p>Boot date</p>
            <h5>-</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-6 result1">
            <p>Tow date</p>
            <h5>-</h5>
          </div>
          {(citationAllData.notice_detail || []).map((item, i) => (
            <div key={i} className="col-xl-3 col-6 result1">
              <p>{i + 1 === 1 ? '1st' : i + 1 === 2 ? '2nd' : i + 1 === 3 ? '3rd' : `${i + 1}th`} notice date</p>
              <h5>
                {moment(item.notice_date.split("T")[0]).format("MM/DD/YYYY") +
                  " " +
                  item.notice_date.substring(11, 19)}
              </h5>
            </div>
          ))}
        </div>
        {citationAllData && citationAllData.notes && (
          <>
            <hr />
            <h4>Support Notes</h4>
            <SupportNotesTable citationAllData={citationAllData} />
          </>
        )}
      </div>
      {showImagesModalSlider && (
        <div
          className="main-body-modal cstm-ticket-details-pop"
          onKeyDown={(e) => {
            if (e.key === "ArrowLeft") {
              imageId === 0
                ? setImageId(slideImages.length - 1)
                : setImageId(imageId - 1);
            } else if (e.key === "ArrowRight") {
              imageId + 1 === slideImages.length
                ? setImageId(0)
                : setImageId(imageId + 1);
            }
          }}
          tabIndex={0}
          ref={sliderPopupRef}
        >
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider slides={slideImages} index={imageId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CitationInfoTab;
