import {useEffect, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import ArrowPermitLeft from '../../../../Images/arrow-permit-left.svg';
import ArrowPermitRight from '../../../../Images/arrow-permit-right.svg';
import {useNavigate} from 'react-router-dom';
import ImagePreview from '../../../../Global/imagePreview';
import {permitStepFormHandler, permitUserInfo} from '../../../../actions/permits';
import dashboardService from '../../../../Services/dashboard.service';
import Cookies from 'js-cookie'

const PermitDocumentUpload = ({
  permitData,
  permitStepData,
  stepNo,
  onClickNavigate,
  setShowStepDetails,
}) => {


  console.log(permitStepData,'step data')

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permitUserData = useSelector(
    state => state.permitReducer.permitUserInfo,
  );
  const [documentData, setDocumentData] = useState([]);
  const [filesPreview, setFilesPreview] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);
  const [allLinks, setAllLinks] = useState([]);
  const [fileError, setFileError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPreviewModel, setShowPreviewModel] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(false);

  const maxSizeInMB = 5;

  const validFileTypes = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  useEffect(() => {
    if (permitData.documents && permitData.documents.length > 0) {
      setDocumentData(
        permitData.documents.map((doc, docId) => ({
          docId,
          docName: doc.name,
          selectedOption: '',
        })),
      );
      setFileError(
        permitData.documents.map((doc, docId) => ({
          index: docId,
          message: ""
        }))
      )
    }
  }, []);

  // useEffect(() => {
  //   if (permitUserData) {
  //     setDocumentData(
  //       permitUserData.documents.map((itm, index) => ({
  //         docId: index,
  //         docName: itm.name,
  //         selectedOption: itm.type,
  //       })),
  //     );
  //     setFilesPreview(permitUserData.filesPreview);
  //   }
  // }, [permitUserData]);

  const handleSelectChange = (docId, docName, selectedOption) => {
    if (selectedOption !== '' && validationErrors.length > 0) {
      let selectError = [...validationErrors];
      if (validationErrors && validationErrors.length > 0) {
        const errorInd = validationErrors.findIndex(itm => itm.index === docId);
        selectError.splice(errorInd, 1);

        setValidationErrors(selectError);
      }
    }
    setDocumentData(prevData => {
      const existingDocIndex = prevData.findIndex(doc => doc.docId === docId);

      if (existingDocIndex >= 0) {
        const updatedData = [...prevData];
        updatedData[existingDocIndex] = {
          ...updatedData[existingDocIndex],
          selectedOption,
          docName,
        };
        return updatedData;
      } else {
        return [
          ...prevData,
          {
            docId,
            docName,
            selectedOption,
          },
        ];
      }
    });
  };
  const handleFileChange = (docId, docName, files) => {
    const validFiles = [];
    const errors = [];

    let selectError = [...fileError];
    if (fileError && fileError.length > 0) {
      const errorInd = fileError.findIndex(itm => itm.index === docId);
      if (errorInd !== -1) {
        selectError.splice(docId, 1);
        setFileError(selectError);
      }
    }

    Array.from(files).forEach(file => {
      if (
        validFileTypes.includes(file.type) &&
        file.size <= maxSizeInMB * 1024 * 1024
      ) {
        validFiles.push(file);

        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFilesPreview(prevFiles => [
              ...prevFiles,
              {
                docId,
                docName,
                name: file.name,
                url: reader.result,
                type: 'image',
              },
            ]);
          };
          reader.readAsDataURL(file);
        } else {
          setFilesPreview(prevFiles => [
            ...prevFiles,
            {
              docId,
              docName,
              name: file.name,
              type: file.type.includes('pdf') ? 'pdf' : 'doc',
            },
          ]);
        }
      } else {
        errors.push({
          name: file.name,
          message: `Invalid file type or size. Allowed types: ${validFileTypes.join(
            ', ',
          )}. Max size: ${maxSizeInMB}MB.`,
        });
      }
    });

    setFilesUpload(prevFiles => [...prevFiles, ...validFiles]);

    document.getElementById(`file-input-${docId}`).value = ''; // Use the specific ID for each file input
  };

  const handleImageDlt = (e, index, docId) => {
    e.preventDefault();

    const fileIndex = filesPreview.findIndex(itm => itm.docId === docId);

    filesPreview.splice(fileIndex, 1);
    setFilesPreview([...filesPreview]);

    filesUpload.splice(fileIndex, 1);
    setFilesUpload([...filesUpload]);

    document.getElementById(`file-input-${docId}`).value = ''; // Use the specific ID for each file input
  };

  const plateDetails = permitStepData?.plate_details?.map(item => ({
    vehicle_details: {
      lp_number: item.lp_number,
      make: item.make,
      model: item.model,
      year: `${item.year}`,
      color: item.color,
    },
    state: item.state,
    start_date: moment(item.start_date).format('YYYY-MM-DDTHH:mm:ssZ'),
  }));

  const handleUpdateProfile = async e => {
    e.preventDefault();

    let isValid = false;

    let documents = [];

      setValidationErrors(documentData.map((itm, index) => {
        if (itm.selectedOption === '') {
          isValid = true;
          return {
            index,
            message: 'Select Document Type',
          };
        } else {
          return {
            index,
            message: '',
          }
        }
      }))
    
      // console.log(docErrors.map(itm => itm.message !== "" && itm.message),'file error')
    
    // if (docErrors && docErrors.length > 0) {
    //     console.log()
    //     isValid = true;
    //     setValidationErrors(docErrors);
    //   } else {
    //     setValidationErrors([]);
    //   }
    

    setFileError(permitData.documents.map((itm, index) => {
      if (!filesPreview.find(item => item.docName === itm.name)) {
        isValid = true;
        return {
          index,
          message: 'Please Upload File.',
        };
      } else {
        return {
          index,
          message: '',
        }
      }
    })
    );
      

    if (!isValid) {
        if (filesUpload.length === permitData.documents.length) {
          const formData = new FormData();
          let data_u = [];

          for (let i = 0; i < filesUpload.length; i++) {
            data_u.push(`${i + 1}`);
            formData.append('files', filesUpload[i]);
          }

          formData.append('data', data_u);
          formData.append('upload_type', 'PermitDocument');

          setLoading(true);
          try {
            const upload_result = await dashboardService.uploadPermitFiles(
              formData,
              Cookies.get("siteinfo"),
            );

            if (upload_result.data.status) {
              const allLinks = upload_result.data.data[0].response.links.map(
                itm => ({id: itm.filename.split('_')[1], link: itm.link}),
              );
              setAllLinks(allLinks);
              documents = permitData.documents.map((doc, index) => ({
                name: doc.name,
                type: documentData[index].selectedOption,
                url: allLinks[index].link,
              }));
            }
          } catch (error) {
            console.error('Upload failed:', error);
          }
        } else {
          if (permitUserData.documents && permitUserData.documents.length > 0) {
            documents = permitUserData.documents;
          }
        }

      const payloadData = {
        plate_details: plateDetails,
        permit_config_id: permitData.id,
        hand_tag_id: permitStepData.hand_tag_id.map(itm => itm.value),
        user_address: {
          address: permitStepData.address,
          city: permitStepData.city,
          email: permitStepData.email,
          first_name: permitStepData.first_name,
          last_name: permitStepData.last_name,
          phone_number: '+1' + permitStepData.phone_number,
          state: permitStepData.state,
          user_name: permitStepData.user_name,
          zip_code: permitStepData.zip_code,
          phone_code: "+1",
          company: "",
          country: "",
          postal_code: "",
        },
        duration: permitStepData.duration.toUpperCase(),
        permit_quantity: Number(permitStepData.permit_quantity),
        permit_notify_updates:
          permitStepData.permit_notify_updates === 'Yes, i want to sign up'
            ? true
            : false,
        tier_number: 1,
        documents: documents,
      };

      const userInfo = {...payloadData, filesPreview};
      dispatch(permitUserInfo({...permitUserData, ...userInfo}));

      doCheckout(payloadData);
    }
  };

  const doCheckout = async payloadData => {
    setLoading(true);
    try {
      const response = await dashboardService.buyPermitWithRegister(
        payloadData,
        Cookies.get("siteinfo"),
      );
      if (response.data.data.reference_id !== '') {
        navigate(`#step${Number(stepNo) + 1}`, {
          state: {
            refId: response.data.data.reference_id,
          },
        });
        onClickNavigate(`step${Number(stepNo) + 1}`);
      }
    } catch (err) {
      console.log(err,'err')
    } finally {
      setLoading(false);
    }
  };

  const imagePreviewHandler = url => {
    setShowPreviewModel(true);
    setPreviewUrl(url);
  };

  const closePreviewModel = () => {
    setShowPreviewModel(false);
  };

  return (
    <>
      <form onSubmit={handleUpdateProfile}>
      <div className="permit-user-reg">
        <h5 className="mb-4">Upload Documents</h5>
        <div className="permit-user-reg-main">
          {permitData.documents.map((item, index) => (
            <div className="permit-user-reg-box">
              <p>{item.name}</p>
              <select
                className="form-control"
                onChange={e =>
                  handleSelectChange(index, item.name, e.target.value)
                }
                value={documentData[index]?.selectedOption || ''}>
                <option value={''}>Select Document Type</option>
                {item.options.map((doc, idx) => (
                  <option key={idx} value={doc}>
                    {doc}
                  </option>
                ))}
              </select>
              {validationErrors &&
                validationErrors.length > 0 &&
                validationErrors.find(itm => itm.index === index) && (
                  <p className="errormessage">
                    {
                      validationErrors.find(itm => itm.index === index).message
                    }
                  </p>
                )}
              <div className=" mt-3 mb-3">
                <div className="col-12 form-group formgroup-main cstm-choose-file mt-3 p-0">
                  <div class="choose-file-upload" for="">
                    <span class="choose-file-upload-title">
                      Choose Files{' '}
                    </span>
                    <input
                      type="file"
                      id={`file-input-${index}`}
                      onChange={e =>
                        handleFileChange(index, item.name, e.target.files)
                      }
                    />
                  </div>
                </div>
                {fileError &&
                fileError.length > 0 &&
                  fileError.map(itm => itm.message !== "" && itm.index === index &&
                    <p className="errormessage">{itm.message}</p>
                  )
                }

                <div className="cstm-appeal-preview-images">
                  {filesPreview
                    .filter(file => file.docId === index)
                    .map((file, nm) => (
                      <div key={nm} className={`appeal-preview-img`}>
                        {file.type === 'image' && (
                          <img
                            src={file.url}
                            alt={file.name}
                            style={{maxWidth: '100%'}}
                            onClick={() => imagePreviewHandler(file.url)}
                          />
                        )}
                        {file.type === 'doc' && (
                          <p>{file.name} (Document)</p>
                        )}
                        {file.type === 'pdf' && <p>{file.name} (PDF)</p>}
                          <button
                            className="cstm-appeal-close"
                            onClick={e =>
                            handleImageDlt(e, nm, file.docId)
                          }>
                            ×
                          </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="permit-question-btn">
          <button
            className="permit-question-btn-left"
            type="button"
            onClick={() => (
              Number(stepNo) === 0
                ? (navigate(-1), setShowStepDetails(false))
                : navigate(`#step${Number(stepNo) - 1}`),
              onClickNavigate(`step${Number(stepNo) - 1}`)
            )}>
            <img src={ArrowPermitLeft} alt="back" />
            Back
          </button>
          <button
            className="permit-question-btn-right cstm-permit-btn"
            type="submit">
            {loading && (
              <div className="ptc-ld">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
              </div>
            )}
            Proceed to checkout
            <img src={ArrowPermitRight} alt="next" />
          </button>
        </div>
      </form>

      {showPreviewModel && (
        <ImagePreview url={previewUrl} closeModel={closePreviewModel} />
      )}
    </>
  )
}

export default PermitDocumentUpload;