import React, { useState, useRef, createRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dashboardService from '../../Services/dashboard.service';

import { assignsiteid } from "../../Global/site";
import Cookies from 'js-cookie';

const OTPVerifyForResetPassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const idsite = assignsiteid()
    const { message } = useSelector(state => state.message);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [isBtnLoader, setBtnLoader] = useState(false);
    const otpLenght = 6;
    const [forms, setForms] = useState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" })
    const [error, setError] = useState('');
    // const inputsRef = useRef([...Array(otpLenght)].map(() => createRef()));

    const handleChange = (e) => {
        dispatch({ type: 'SET_MESSAGE', payload: '' })
        setError('');
        const { name, value, tabIndex } = e.target

        setForms({ ...forms, [name]: value });
        if (tabIndex === 6) {
            let flag = 1
            Object.entries(forms).forEach(([key, value]) => {
                if (value) {
                    flag += 1
                }
            });
            if (flag === tabIndex) {
                const otp = forms.otp1 + forms.otp2 + forms.otp3 + forms.otp4 + forms.otp5 + value
                varifyOTPFun(otp)
            }
        }
    };

    const inputfocus = (e) => {
        const re = /^[0-9\b]+$/;
        const { value, tabIndex } = e.target

        if (e.key === "Delete" || e.key === "Backspace") {
            const next = tabIndex - 2;
            if (next > -1) {
                // inputsRef?.current[next]?.current?.focus();
                e.target.form.elements[next].focus()
            }
        } else if (re.test(value)) {
            const next = tabIndex;
            if (next < 6) {
                // inputsRef?.current[next]?.current?.focus();
                e.target.form.elements[next].focus()
            }
        }
    }

    const handlePaste = (e) => {
        const re = /^[0-9]+$/;
        if (re.test(e.clipboardData.getData('text')) && e.clipboardData.getData("text").length === 6) {
            setForms({ otp1: e.clipboardData.getData("text").charAt(0), otp2: e.clipboardData.getData("text").charAt(1), otp3: e.clipboardData.getData("text").charAt(2), otp4: e.clipboardData.getData("text").charAt(3), otp5: e.clipboardData.getData("text").charAt(4), otp6: e.clipboardData.getData("text").charAt(5) })
            // inputsRef?.current[5]?.current?.focus();
            e.target.form.elements[5].focus()
            const otp = e.clipboardData.getData("text")
            varifyOTPFun(otp)

        } else {
            setForms({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" })
        }
    }

    const handleSubmit = (event) => {

        const data = new FormData(event.target);
        const otp = data.get('otp1') + data.get('otp2') + data.get('otp3') + data.get('otp4') + data.get('otp5') + data.get('otp6')
        let isValid = false
        if (otp === '') {
            isValid = true
            setError("Enter OTP")
        } else if (otp.length != 6) {
            isValid = true
            setError("OTP must be 6 digit long.")
        }
        event.preventDefault();

        if (!isValid) {
            varifyOTPFun(otp)
        }
    };

    async function varifyOTPFun(otp) {
        setBtnLoader(true)
        const m_once = Cookies.get('f_m_once')
        let payload = {
            mobile_otp_nonce: m_once,
            otp: Number(otp),
            'site_id': idsite
        };

        const result = await dashboardService.verfyOTPForgotPassword(payload)
        if (result.data.status) {

            if (result.data.data.otp_verified) {
                Cookies.set("f_m_once", result.data.data.access_token_data.token);
                navigate('/reset-password')
            } else {
                dispatch({ type: 'SET_MESSAGE', payload: result.data.message })
                setBtnLoader(false)
                setError('')
            }
        } else {
            dispatch({ type: 'SET_MESSAGE', payload: result.data.message })
            setBtnLoader(false)
            setError('')
        }
    }


    if (isLoggedIn) {
        return <Navigate to={'/'} />
    }

    return (
        <>
            <div className="wrapper citycitiation">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-12 login-align">
                            <div className="login-section otp-login-sec-cstm">
                                {message && (
                                    <div className="text-left form-group">
                                        <div className="alert alert-danger" role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}
                                <h3>OTP Verification</h3>
                                <p>We have sent a code to your registered email and phone number. </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 form-group formgroup-main otp">
                                            {Array.from({ length: otpLenght }, (_, i) => (
                                                <input
                                                    key={i}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    name={`otp${i + 1}`}
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control otpInput"
                                                    placeholder="-"
                                                    value={forms['otp' + (i + 1)]}
                                                    maxLength="1"
                                                    tabIndex={i + 1}
                                                    // ref={inputsRef.current[i]}
                                                    onChange={(e) => handleChange(e)}
                                                    onKeyUp={(e) => inputfocus(e)}
                                                    onPaste={(e) => handlePaste(e)}
                                                />
                                            ))}
                                            <span className="errormessage">{error}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 button2">
                                            <button className="btn" type="submit" disabled={isBtnLoader}>
                                                {isBtnLoader &&
                                                    <div className="cstm-loader">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </div>
                                                }
                                                Verify
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 login-bgImage">
                            <div className="login-cont-main">
                                <div className="login-cont-main-sub">Nice to see you again</div>
                                <div className="login-cont-main-title">Welcome Back</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OTPVerifyForResetPassword