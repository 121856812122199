import React, { useState, useEffect } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import Cookiesremove from '../../../Cookiesremove';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";
import { changetotal } from "../../../Global/site";

const styles = {
    graphContainer: {
        // border: '1px solid black',
        // padding: '15%'
    }
}

const options = {
    responsive: true,
    showTooltips: true,
    height: '600px',
    width: "600px",
    tooltips: { mode: 'index' },
}

function LineChartOperation() {
    const changeTotal = changetotal();
    const [count, setCount] = React.useState(0);
    const [showTotalCitation, setTotalCitation] = React.useState([]);
    const [showTotalScan, setTotalScan] = React.useState([]);
    const [showAvgScanOfficer, setAvgScanOfficers] = React.useState([]);
    const [showAvgIssuanceOfficer, setAvgIssuaceOfficers] = React.useState([]);
    const [showXaxisValue, setXaxisValue] = React.useState([]);
    const [showMonthValue, setMonthValue] = React.useState("");
    const [showYearValue, setYearValue] = React.useState("");
    const [showAuthToken, setAuthToken] = useState(false);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
    const [showTodayButton, setTodayButton] = useState(true)
    const [showMonthButton, setMonthButton] = useState(false)
    const [showYearButton, setYearButton] = useState(false)

    const closeError = () => {
        setErrorHandling(false)
    }

    var chartData = {
        labels: showXaxisValue,
        type: 'line',
        datasets: [
            {
                label: changeTotal,
                fillColor: 'rgba(220,220,220,0.2)',
                borderColor: "#FFB34D",
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                radius: 2,
                fill: false,
                pointHighlightStroke: 'rgba(220,220,220,1)',
                data: showTotalCitation,
            },
            {
                label: 'Scans',
                radius: 2,
                borderColor: "#9A0F73",
                pointColor: 'rgba(151,187,205,1)',
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                fill: false,
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: showTotalScan,
            },
            {
                label: 'Avg Scans by Officer',
                borderColor: "#044271",
                pointColor: 'red',
                radius: 2,
                fill: false,
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: showAvgScanOfficer,
            },
            {
                label: 'Avg Citations by Officer',
                borderColor: "#029279",
                radius: 2,
                fill: false,
                pointColor: 'rgba(151,187,205,1)',
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: showAvgIssuanceOfficer,
            },
        ]
    }

    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    const onChangeYear = (e) => {

        setTodayButton(false)
        setMonthButton(false)
        setYearButton(true)

        const retriveData = {
            "count_array_timeline": {
                "count_type": "annually"
            }
        }

        Dashboard.productivityGetCounts(retriveData).then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalCitation(response.data.data[0].metadata.graph_arrays.citations)
                        setTotalScan(response.data.data[0].metadata.graph_arrays.scans)
                        setAvgScanOfficers(response.data.data[0].metadata.graph_arrays.avg_scans_by_officer)
                        setAvgIssuaceOfficers(response.data.data[0].metadata.graph_arrays.avg_citations_by_officer)
                        setXaxisValue(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'])
                    }
                }
            }
        )

    }
    const onChangeMonth = (e) => {

        setTodayButton(false)
        setMonthButton(true)
        setYearButton(false)

        const retriveData = {
            "count_array_timeline": {
                "count_type": "monthly"
            }
        }

        Dashboard.productivityGetCounts(retriveData).then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalCitation(response.data.data[0].metadata.graph_arrays.citations)
                        setTotalScan(response.data.data[0].metadata.graph_arrays.scans)
                        setAvgScanOfficers(response.data.data[0].metadata.graph_arrays.avg_scans_by_officer)
                        setAvgIssuaceOfficers(response.data.data[0].metadata.graph_arrays.avg_citations_by_officer)
                        setXaxisValue(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'])
                    }
                }
            }
        )
    }

    const OnTodayChart = () => {

        setTodayButton(true)
        setMonthButton(false)
        setYearButton(false)

        const retriveData = {
            "count_array_timeline": {
                "count_type": "daily"
            }
        }

        Dashboard.productivityGetCounts(retriveData).then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalCitation(response.data.data[0].metadata.graph_arrays.citations)
                        setTotalScan(response.data.data[0].metadata.graph_arrays.scans)
                        setAvgScanOfficers(response.data.data[0].metadata.graph_arrays.avg_scans_by_officer)
                        setAvgIssuaceOfficers(response.data.data[0].metadata.graph_arrays.avg_citations_by_officer)
                        setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                    }
                }
            }
        )
    }

    useEffect(() => {

        const retriveData = {
            "count_array_timeline": {
                "count_type": "daily"
            }
        }
        // productivityGetArrayCounts
        Dashboard.productivityGetCounts(retriveData).then(
            (response) => {
                if (response.data.status === 300) {
                    setAuthToken(true)
                } else {
                    if (response.data.status === false) {
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    } else {
                        setTotalCitation(response.data.data[0].metadata.graph_arrays.citations)
                        setTotalScan(response.data.data[0].metadata.graph_arrays.scans)
                        setAvgScanOfficers(response.data.data[0].metadata.graph_arrays.avg_scans_by_officer)
                        setAvgIssuaceOfficers(response.data.data[0].metadata.graph_arrays.avg_citations_by_officer)

                        setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                    }
                }
            }
        )
        setCount(100);
    }, []);


    return (
        <>
          <div className="col-12">
          <div className="cstm-operation-summary-top">
                <div className="cstm-operation-summary-01"><span>Productivity Summary</span></div>
                <div className="cstm-operation-summary-02 cstm-only-ps">
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#FFB34D" }}></span>
    <span className="cstm-os-name">{changetotal}</span>
                    </div>
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#044271" }}></span>
                        <span className="cstm-os-name">Scans</span>
                    </div>
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#9A0F73" }}></span>
                        <span className="cstm-os-name">Avg Scans by Officer</span>
                    </div>
                    <div className="cstm-operation-summary-02-box">
                        <span className="cstm-os-color" style={{ background: "#029279" }}></span>
                        <span className="cstm-os-name">Avg {changetotal} by Officer</span>
                    </div>
                </div>

                <div className="cstm-operation-summary-03">
                    <div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showTodayButton ? 'active' : ''}`} name="today" onClick={OnTodayChart}>Today</button>
                    </div>

                    <div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showMonthButton ? 'active' : ''}`} name="monthly" onClick={onChangeMonth}>Monthly</button>
                    </div>

                    <div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showYearButton ? 'active' : ''}`} name="yearly" onClick={onChangeYear}>Yearly</button>
                    </div>
                </div>
            </div>
          </div>
           

           <div className="col-12">
           <div className="cstm-operation-summary-bottom">
                <div style={styles.graphContainer}>
                    <LineChart data={chartData}
                        options={options}
                        width="500" height="150" />
                </div>
            </div>
           </div>

          

        </>

    )

}

export default LineChartOperation;