import React from "react";
import MaskedInput from "react-text-mask";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON,
  LASTFORDIGIT
} from "./constant";
import {
  stripeCardNumberValidation,
  stripeCardExpirValidation,
  minLength,
  cardMinLength
} from "./validations";
import Cookies from "js-cookie"

const isOCMD = () => {
  if (Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" || Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" || Cookies.get("siteinfo") === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" || Cookies.get("siteinfo") === "ead64c86-3681-11ee-9c41-6ef2fb394a83") {
    return true
  } else {
    return false
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "card_number":
      return { ...state, card_number: action.data };
    case "expiry":
      return { ...state, expiry: action.data };
    case "cvc":
      return { ...state, cvc: action.data };
    case "cleanState":
      return { ...action.data };
    default:
      return state;
  }
};

function findDebitCardType(cardNumber) {
  const regexPattern = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) return card;
  }
  return "";
}

const Card = (props) => {
  const [error, setError] = React.useState(props.cardError);
  const [cardType, setCardType] = React.useState();
  const [state, dispatch] = React.useReducer(reducer, {
    card_number: "",
    expiry: "",
    cvc: "",
  });

  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  const handleValidations = (type, value) => {
    let errorText;
    if (isOCMD()) {
      switch (type) {
        case "card_number":
          setCardType(findDebitCardType(value));
          errorText = cardMinLength(4)(value);
          setError({ ...error, card_number: errorText });
          props.setCardError({ ...error, card_number: errorText });
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case "card_number":
          setCardType(findDebitCardType(value));
          errorText = stripeCardNumberValidation(value);
          setError({ ...error, card_number: errorText });
          props.setCardError({ ...error, card_number: errorText });
          break;
        case "expiry":
          errorText =
            value === ""
              ? "Field is required."
              : stripeCardExpirValidation(value);
          setError({ ...error, expiry: errorText });
          props.setCardError({ ...error, expiry: errorText });
          break;
        case "cvc":
          errorText = value === "" ? "Field is required." : minLength(3)(value);
          setError({ ...error, cvc: errorText });
          props.setCardError({ ...error, cvc: errorText });
          break;
        default:
          break;
      }
    }
  };

  const handleInputData = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, data: value });
    props.setCardData({ ...state, [name]: value });
  };

  return (
    <>
      {props.allowTerms && <div className="row">
        <div className="col-12 make-payment">
          <h5>Make Payment</h5>
        </div>
      </div>}
      <div className="row">
        <div className="col-4 form-group formgroup-main">
          <label htmlFor="">Card number {isOCMD() && '(Only last 4 digit)'} *</label>
          <MaskedInput
            className="form-control"
            mask={
              ["37", "34"].includes(
                state && state.card_number.split("").splice(0, 2).join("")
              )
                ? (isOCMD() ? LASTFORDIGIT : AMERICANEXPRESS)
                : (isOCMD() ? LASTFORDIGIT : OTHERCARDS)
            }
            guide={false}
            placeholderChar={"\u2000"}
            placeholder={isOCMD() ? "XXXX" : "XXXX XXXX XXXX XXXX"}
            name="card_number"
            // required
            value={state.card_number}
            onChange={handleInputData}
            onBlur={handleBlur}
          />
          {(!error || !error.card_number) && CARDARR.includes(cardType) && (
            <img
              style={{
                float: "right",
                // position: "relative",
                // top: "-35px"
              }}
              src={CARDICON[cardType]}
              alt="card"
              width="50px"
              height="33px"
            />
          )}
          {props.cardError &&
            props.cardError.card_number &&
            props.cardError.card_number.length > 1 && (
              <span className="errormessage">{props.cardError.card_number}</span>
            )}
        </div>
        {!isOCMD() &&
          <>
            <div className="col-4 form-group formgroup-main">
              <label htmlFor="">Expiry*</label>
              <MaskedInput
                className="form-control"
                mask={EXPIRYDATE}
                guide={false}
                name="expiry"
                // required
                placeholderChar={"\u2000"}
                placeholder="MM/YYYY"
                value={state.expiry}
                onChange={handleInputData}
                onBlur={handleBlur}
              />
              {props.cardError &&
                props.cardError.expiry &&
                props.cardError.expiry.length > 1 && (
                  <span className="errormessage">{props.cardError.expiry}</span>
                )}
            </div>
            <div className="col-4 form-group formgroup-main">
              <label htmlFor="">CVV*</label>
              <MaskedInput
                className="form-control"
                mask={CVC}
                guide={false}
                name="cvc"
                // required
                placeholderChar={"\u2000"}
                placeholder="CVV"
                value={state.cvc}
                onChange={handleInputData}
                onBlur={handleBlur}
              />
              {props.cardError &&
                props.cardError.cvc &&
                props.cardError.cvc.length > 1 && (
                  <span className="errormessage">{props.cardError.cvc}</span>
                )}
            </div>

          {props.allowTerms &&
            <div className="row">
              <div className="col-12">
                <div className="cstm-main-team-payment">
                  <div className="team-of-payment-main-left">
                    <input
                      type="checkbox"
                      name="termofpayment"
                      onChange={(e) => props.onChangeTermCondition(e.target)}
                    />
                  </div>
                  <div className="form-group formgroup-main team-of-payment-main-right">
                    <div className="terms-of-payment">
                      <h5>
                        <b>Terms of payment</b>
                      </h5>
                      <p>
                        <b>Payments:</b>
                      </p>
                      <p className="cstm-sp-p">
                        All payments are processed in U.S. dollars.
                      </p>
                      <p>
                        <b>Refund Policy:</b>
                      </p>
                      <p>
                        No refunds are issued via this payment site. If you believe
                        you have submitted a payment in error please contact us at the
                        number that is listed on your statement. If you believe a
                        charge has appeared on your credit card statement by an act of
                        fraud, please contact your issuing bank. Your issuing bank
                        will then contact us on your behalf. Do not contact the
                        merchant directly in this instance. If you believe there was a
                        duplicated charge to your credit card, please contact us at
                        the number that is listed on your statement.
                      </p>
                    </div>
                    <span className="errormessage">
                      {props.inputError.termofpayment && props.inputError.termofpayment !== "" && "Please accept Terms and Conditions."}
                    </span>
                  </div>
                </div>
              </div>
              </div>
            }
          </>
        }
      </div>
    </>
  );
};

export default Card;
