import { useEffect, useState } from "react";

const SelectField = ({ field, index, changeState, removeField,toolboxClick }) => {
  const [display, setDisplay] = useState(false);
  const [citationState, setCitationState] = useState(field);

  useEffect(() => {
    return changeState(citationState, index);
  }, [citationState]);

  useEffect(() => {
    if(toolboxClick !== index && display) {
      setDisplay(false)
    }
  },[toolboxClick])

  const changeValue = (stateFor, value) => {
    switch (stateFor) {
      case "QUESTION":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, question_name: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "TITLE":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, repr: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "ALIGNED":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, aligned: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "IS_Editable":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, is_editable: value === "true" ? true : false }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Is_Required":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, is_required: value === "true" ? true : false }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "TYPE":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, type: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "PRINT":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, print: value === "true" ? true : false }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Removable":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, removable: value === "true" ? true : false }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Max_Length":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, max_length: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Min_Length":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, min_length: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Collection_Name":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, collection_name: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Field_Name":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, field_name: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Data_Type_Validation":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, data_type_validation: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Calculated_Field":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, calculated_field: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "form_layout_order":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, form_layout_order: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "print_layout_order":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, print_layout_order: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "Default_Value":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, default_value: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      case "key_name":
        setCitationState((prevState) => ({
          ...prevState,
          fields: [
            ...citationState.fields.map((my_state) =>
              my_state.name === "dropdown"
                ? { ...my_state, key_name: value }
                : { ...my_state }
            ),
          ],
        }));
        break;
      default:
        return;
    }
  };

  const changeOptionValue = (index, value, state) => {
    let options = citationState.fields[0].options;
    let option = {};

    if (state === "TITLE") {
      option = {
        ...options[index],
        title: value,
      };
    }
    options[index] = option;
    setCitationState((prevState) => ({
      ...prevState,
      fields: [
        ...citationState.fields.map((my_state) =>
          my_state.name === "checkbox"
            ? { ...my_state, options: options }
            : { ...my_state }
        ),
      ],
    }));
  };

  const removeOption = (index) => {
    let options = citationState.fields[0].options;
    citationState.fields[0].options.splice(index, 1);
    setCitationState((prevState) => ({
      ...prevState,
      fields: [
        ...citationState.fields.map((my_state) =>
          my_state.name === "dropdown"
            ? { ...my_state, options: options }
            : { ...my_state }
        ),
      ],
    }));
  };

  const addOption = () => {
    let option = {
      title: "",
    };
    let options = citationState.fields[0].options;
    options.push(option);
    setCitationState((prevState) => ({
      ...prevState,
      fields: [
        ...citationState.fields.map((my_state) =>
          my_state.name === "dropdown"
            ? { ...my_state, options: options }
            : { ...my_state }
        ),
      ],
    }));
  };

  const changeParentValue = (stateFor,value) => {
    switch (stateFor) {
      case "QUESTION":
        setCitationState((prevState) => ({
          ...prevState,
          question_name:value
        }));
        break;
      default:
        return;
    }
  }

  return (
    <>
      <div className="configure-question-add-dg">
        <span
          className="configure-question-box-delete"
          onClick={() => removeField(index)}
        > 
          <i class="fa fa-trash"></i>
        </span>
        <form>
        <div>
            <p>{citationState.question_name}</p>
          </div>
          <div
            className=""
            style={{ textAlign: citationState.fields[0].aligned }}
          >
            <label htmlFor="defaultFormLoginEmailEx" className="col-form-label">
              {citationState.fields[0].repr}
            </label>
            <span style={{ color: "red" }}>
              {citationState.fields[0].is_required === true ? "*" : ""}
            </span>
          </div>
          <div className="form-group frm_sel_drop_arrow">
            <select
              id="demo_default_select"
              className="form-control"
              data-role="select-dropdown"
              disabled={citationState.fields[0].is_editable === false}
              autoFocus={true}
            >
              {citationState.fields[0].options.map((checkbox, index) => {
                return (
                  <option
                    name={checkbox.title}
                    className="form-check-input"
                    disabled={citationState.fields[0].is_editable === false}
                  >
                    {checkbox.title}
                  </option>
                );
              })}
            </select>
          </div>
        </form>
      </div>
      {toolboxClick === index && (
      <div className="configure-question-add-box">
        <div className="configure-question-add-main">
          <div className="configure-question-add-main-head">
            <span className="configure-question-add-main-head-title">
              PROPERTY
            </span>
          </div>
          <div className="configure-question-add-main-body-cont">
            <div className="row" id={`${citationState.fields[0].name}`}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="QUESTION">Question</label>
                  <textarea
                    type="text"
                    value={citationState.question_name}
                    onChange={(e) => changeParentValue("QUESTION", e.target.value)}
                    placeholder="Field question"
                    className="form-control"
                    autoFocus={true}
                    rows={3}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="title">Title Label</label>
                  <input
                    type="text"
                    value={citationState.fields[0].repr}
                    onChange={(e) => changeValue("TITLE", e.target.value)}
                    placeholder="Field Label Title"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="title">Default Value</label>
                  <input
                    type="text"
                    value={citationState.fields[0].default_value || ""}
                    onChange={(e) =>
                      changeValue("Default_Value", e.target.value)
                    }
                    placeholder="Enter Default Value"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 lbl_left_form_right">
                <label htmlFor="labelAlign" className="lbl_align_name_cdstm">
                  Label Alignment
                </label>

                <div className="radioGroup lbl_radio_grp">
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="labelAlign"
                      value="left"
                      onChange={(e) => changeValue("ALIGNED", e.target.value)}
                      checked={citationState.fields[0].aligned === "left"}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn ${
                        citationState.fields[0].aligned === "left"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="fa fa-align-left"></i>
                    </span>
                  </label>
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="labelAlign"
                      value="right"
                      onChange={(e) => changeValue("ALIGNED", e.target.value)}
                      checked={citationState.fields[0].aligned === "right"}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn ${
                        citationState.fields[0].aligned === "right"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="fa fa-align-right"></i>
                    </span>
                  </label>
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="labelAlign"
                      value="center"
                      onChange={(e) => changeValue("ALIGNED", e.target.value)}
                      checked={citationState.fields[0].aligned === "center"}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn ${
                        citationState.fields[0].aligned === "center"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="fa fa-align-center"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 lbl_left_form_right cstm-yes-no">
                <label htmlFor="labelAlign" className="lbl_align_name_cdstm">
                  Is Editable
                </label>
                <div className="radioGroup lbl_radio_grp">
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="iseditable"
                      value={true}
                      onChange={(e) =>
                        changeValue("IS_Editable", e.target.value)
                      }
                      checked={citationState.fields[0].is_editable === true}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn btn_yes ${
                        citationState.fields[0].is_editable === true
                          ? "active"
                          : ""
                      }`}
                    >
                      Yes
                    </span>
                  </label>
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="iseditable"
                      value={false}
                      onChange={(e) =>
                        changeValue("IS_Editable", e.target.value)
                      }
                      checked={citationState.fields[0].is_editable === false}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn btn_no ${
                        citationState.fields[0].is_editable === false
                          ? "active"
                          : ""
                      }`}
                    >
                      No
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-md-6 lbl_left_form_right cstm-yes-no">
                <label htmlFor="labelAlign" className="lbl_align_name_cdstm">
                  Is Required
                </label>
                <div className="radioGroup lbl_radio_grp">
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="isrequire"
                      value={true}
                      onChange={(e) =>
                        changeValue("Is_Required", e.target.value)
                      }
                      checked={citationState.fields[0].is_required === true}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn btn_yes ${
                        citationState.fields[0].is_required === true
                          ? "active"
                          : ""
                      }`}
                    >
                      Yes
                    </span>
                  </label>
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="isrequire"
                      value={false}
                      onChange={(e) =>
                        changeValue("Is_Required", e.target.value)
                      }
                      checked={citationState.fields[0].is_required === false}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn btn_no ${
                        citationState.fields[0].is_required === false
                          ? "active"
                          : ""
                      }`}
                    >
                      No
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 lbl_left_form_right cstm-yes-no">
                <label htmlFor="labelAlign" className="lbl_align_name_cdstm">
                  Is Removable
                </label>
                <div className="radioGroup lbl_radio_grp">
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="remove"
                      value={true}
                      onChange={(e) => changeValue("Removable", e.target.value)}
                      checked={citationState.fields[0].removable === true}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn btn_yes ${
                        citationState.fields[0].removable === true
                          ? "active"
                          : ""
                      }`}
                    >
                      Yes
                    </span>
                  </label>
                  <label className="eachRadio">
                    <input
                      type="radio"
                      name="remove"
                      value={false}
                      onChange={(e) => changeValue("Removable", e.target.value)}
                      checked={citationState.fields[0].removable === false}
                      style={{ display: "none" }}
                    />
                    <span
                      className={`eachRadio-label btn btn_no  ${
                        citationState.fields[0].removable === false
                          ? "active"
                          : ""
                      }`}
                    >
                      No
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 lbl_left_form_right">
                <div className="form-group">
                  <label htmlFor="title">Collection Name</label>
                  <input
                    type="text"
                    value={citationState.fields[0].collection_name}
                    onChange={(e) =>
                      changeValue("Collection_Name", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 lbl_left_form_right">
                <div className="form-group">
                  <label htmlFor="title">Field Name</label>
                  <input
                    type="text"
                    value={citationState.fields[0].field_name}
                    onChange={(e) => changeValue("Field_Name", e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 lbl_left_form_right">
                <div className="form-group">
                  <label htmlFor="title">Data Type Validation</label>
                  <input
                    type="text"
                    value={citationState.fields[0].data_type_validation}
                    onChange={(e) =>
                      changeValue("Data_Type_Validation", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 lbl_left_form_right">
                <div className="form-group">
                  <label htmlFor="title">Calculated Field</label>
                  <input
                    type="text"
                    value={citationState.fields[0].calculated_field}
                    onChange={(e) =>
                      changeValue("Calculated_Field", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 lbl_left_form_right">
                <div className="form-group">
                  <label htmlFor="title">Collection Name</label>
                  <input
                    type="text"
                    value={citationState.fields[0].collection_name}
                    onChange={(e) =>
                      changeValue("collection_name", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 lbl_left_form_right">
                <div className="form-group">
                  <label htmlFor="title">Key Name</label>
                  <input
                    type="text"
                    value={citationState.fields[0].key_name}
                    onChange={(e) =>
                      changeValue("key_name", e.target.value)
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 lbl_left_form_right form-group">
              <label htmlFor="title">Options</label>
                {citationState.fields[0].options ? (
                  <table className="table tbl_inp_trash">
                    <tbody>
                      {citationState.fields[0].options.map((option, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="inp_ttl_cross_delete">
                                <input
                                  placeholder="Title"
                                  value={
                                    citationState.fields[0].options[index].title
                                  }
                                  onChange={(e) =>
                                    changeOptionValue(
                                      index,
                                      e.target.value,
                                      "TITLE"
                                    )
                                  }
                                  id={option.title}
                                  type="text"
                                  className="form-control"
                                />
                                <span
                                  onClick={() => removeOption(index)}
                                  className="delete_icon_right"
                                >
                                  <i className="fa fa-trash"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <span></span>
                )}
                <div className="text-center">
                  <button onClick={addOption} className="btn btn_add_frm_drop">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default SelectField;
