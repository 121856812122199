import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import Multiselect from "multiselect-react-dropdown";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import QuillEditor from "../Common/QuillEditor";

// service
import dashboardService from "../../Services/dashboard.service";
import mainServices from "../../Services/main.service";

//images
import transfer from "../../Images/transfer.png";
import FilterExportImg from "../../Images/export.png";
import dropdownarrow from "../../Images/dropdown-arrow.svg";

import { changetotal } from "../../Global/site";
import CitationDetail from "../ReportingModule/TicketReporting/TicketDetails";
import { refreshTokenMiddleware } from "../customMiddelware";
import PrintPdfCsvExcelDownload from "../Utilies/PrintPdfCsvExcelDownload";
import AppealDetail from "./AppealDetail";
import Cookies from "js-cookie";
import {useLocation} from "react-router";
import $ from "jquery";
import ErrorModalComponent from "../../utile/modals/errorModal";
import ConfirmationModalComponent from "../../utile/modals/confirmationModal";
import SearchLicensePlate from "../Citation/SearchLicensePlate";
import { hasHelicalReports } from "../../NavComponent/Sidebar";

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

const AppealReport = (uiElements) => {
  const isMansField = () => {
    if (Cookies.get('siteinfo') === 'c293c922-fa4c-11ed-b4ca-ae854bfaee9a' || Cookies.get('siteinfo') === '98ccd4bc-fa4c-11ed-8b64-665980bd7c45') {
      return true
    } else {
      return false
    }
  }
  const payload = useLocation().search;
  const changeTotal = changetotal();
  const dispatch = useDispatch();
  const [showStepStatus, setStepStatus] = React.useState([]);
  const [isOpen, setOpen] = useState(false);
  const [showDetailPage, setDetailPage] = useState(false);
  const [showCitationDetailPage, setCitationDetailPage] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [appealList, setAppealList] = useState([]);
  const [showCitationNumber, setCitationNumber] = useState("");
  const [appealid, setappealid] = useState("");
  const [manageReviewError, setManageReviewError] = useState({
    appeal_status: "",
    remark: "",
    additional_remark: "",
    workflow_status: "",
  });
  const [messageSuc, setMessageSuc] = React.useState("");
  const [wysiwygTextC, setwysiwygTextC] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const [manageReviewState, setManageReviewState] = React.useState({
    appeal_status: "",
    remark: "",
    additional_remark: "",
    created_ts_to: "",
    created_ts_from: "",
    workflow_status: "",
  });

  const [filterDate, setFilterDate] = useState({
    created_ts_from: "",
    created_ts_to: "",
  });
  const [filterOption, setFilterOption] = useState({
    citation_number: "",
    vin_number: "",
    state: [],
    // appeal_status:[],
    appeal_status: [{appeal_status:"Open"},{appeal_status:"Waiting for the Information"},{appeal_status:"In Review"}],
    violation_code: [],
    city: "",
    zip: "",
  });

  const [stateArr, setStateArr] = useState([]);
  const [violationDescriptionArr, setViolationDescriptionArr] = useState([]);
  const [appealStatus, setAppealStatus] = useState([]);
  const [sorting, setSorting] = useState({ field: "xyz", ascending: true });
  const [paginationSelectVaue,setPaginationSelectVaue] = useState(200);
  const [currentPage,setCurrentPage] = useState(1);
  const [todosPerPage,setTodosPerPage] = useState(200);
  const [upperPageBound,setUpperPageBound] = useState(3);
  const [lowerPageBound,setLowerPageBound] = useState(0);
  const [isPrevBtnActive,setIsPrevBtnActive] = useState("disabled");
  const [isNextBtnActive,setIsNextBtnActive] = useState("");
  const [pageBound,setPageBound] = useState(3);
  const [totalDataLengthCount,setTotalDataLengthCount] = useState(0)
  const [noRecordFound,setNoRecordFound] = useState(true);
  const [allSelectedFilterOption,setAllSelectedFilterOption] = useState("");
  const [selectedAppealStatus,setSelectedAppealStatus] = useState("");
  const [errorOpen,setErrorOpen] = useState(false);
  const [showWarning,setShowWarning] = useState(false)
  useEffect(() => {
    if(payload){
      setDetailPage(true) 
   }
  },[payload])
  useEffect(() => {
    const currentDataCopy = [...appealList];

    const sortedCurrentdata = currentDataCopy.sort((a, b) => {
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });

    setAppealList(
      sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse()
    );
  }, [sorting]);

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending });
  };

  useEffect(() => {
    const appeal = [];
    filterOption.appeal_status.map(itm => (
      appeal.push(itm.appeal_status)
    ))
    let query_string = "";
    query_string += "page=" + currentPage
    query_string += "&limit=" + todosPerPage
    query_string += "&appeal_status=" + appeal.toString()
    if(isMansField()) {
      query_string += "&order=asc"
    }
    dashboardService
      .dynemicStatus()
      .then((result) => {
        if (result.data.status === true && result.data.data !== null) {
          setStepStatus(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
        }
      });
    getAppealListDaata(query_string);
    getStateList();
  }, []);

  function convertFilterFormatData(dt) {
    var date = new Date(dt);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    return currentTimeInSeconds;
  }

  const getAppealListDaata = async (query) => {
    setLoader(true);
    try {
      const result = await dashboardService.getAppealListData(query);
      if (result.data.status) {
        const data = result.data.data;
        setAppealList(data);
        setLoader(false);
        setTotalDataLengthCount(result.data.length)
        setNoRecordFound(false)
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealListDaata(query);
        setNoRecordFound(true)
      }
    }
  };

  async function getAppealStatusData() {
    try {
      const result = await dashboardService.appealStatusListAPI();
      if (result.data.status) {
        setAppealStatus(result.data.data);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealStatusData();
      }
    }
  }

  async function getStateList() {
    try {
      const result = await mainServices.violationCode({
        type: "StateList",
        shard: 1,
      });
      if (result.data.status) {
        setStateArr(result.data.data[0].response);
        getViolationDescritpionList();
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getStateList();
      }
    }
  }

  async function getViolationDescritpionList() {
    try {
      const result = await mainServices.violationCode({
        type: "ViolationList",
        shard: 1,
      });
      if (result.data.status) {
        setViolationDescriptionArr(result.data.data[0].response);
        getAppealStatusData();
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getViolationDescritpionList();
      }
    }
  }
  const onChangeFilterDate = (date, name) => {
    const newFilterDate = { ...filterDate };
    if (!date) {
      newFilterDate['created_ts_to'] = "";
      setFilterDate(newFilterDate);
      return;
    }
    newFilterDate[name] = date;
  const timeValue = new Date(date);
  const hours = timeValue.getHours();
  const minutes = timeValue.getMinutes();
    if (name === 'created_ts_to') {
      const isDateChange = !filterDate?.created_ts_to || (hours == 0 && minutes == 0) || date?.getDate() !== filterDate.created_ts_to?.getDate();
      if(isDateChange){
        let formattedDate = new Date(date);
        formattedDate.setHours(23, 59, 59, 999);
        newFilterDate['created_ts_to'] = formattedDate;
      }
      setFilterDate(newFilterDate);
    } else {
      setFilterDate(newFilterDate);
    }
  };
  

  const handleFilterChange = (e) => {
    e.preventDefault();
    setFilterOpen(!isFilterOpen);
  };

  const onchangeFilterInput = (e) => {
    const { name, value } = e.target;
    if (name === "vin_number") {
      let newValue=value
      newValue = SearchLicensePlate(value);
      setFilterOption({ ...filterOption, [name]: newValue });
    }else{
      setFilterOption({ ...filterOption, [name]: value });
    }

  };

  const onChangeStateSelect = (selectedList, name) => {
    setFilterOption({ ...filterOption, [name]: selectedList });
  };
  const onChangeStatusSelect = (selectedList, name) => {
    setFilterOption({ ...filterOption, [name]: selectedList });
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    setAppealList([]);
    let query_string = "";
    query_string += "page=" + 1;
    query_string += "&limit=" + todosPerPage;
    let citation_no = "";
    let vin_no = "";
    let city = "";
    let zip = "";
    let appeal_status = "";
    let violation_code = "";
    let state = "";
    let created_ts_from = "";
    let created_ts_to = "";
    if (filterOption.citation_number) {
      citation_no = "&citation_number=" + filterOption.citation_number;
      query_string += "&citation_number=" + filterOption.citation_number;
    }
    if (filterOption.vin_number) {
      vin_no = "&vin_number=" + filterOption.vin_number;
      query_string += "&vin_number=" + filterOption.vin_number;
    }
    if (filterOption.city) {
      city = "&city=" + filterOption.city;
      query_string += "&city=" + filterOption.city;
    }
    if (filterOption.zip) {
      zip = "&zip=" + filterOption.zip;
      query_string += "&zip=" + filterOption.zip;
    }
    if (filterOption.appeal_status.length > 0) {
      const appeal = [];
      filterOption.appeal_status.map((item) => {
        appeal.push(item.appeal_status)
    })
      query_string += "&appeal_status=" + appeal.toString();
    }
    if (filterOption.violation_code.length > 0) {
      filterOption.violation_code.map((item) => {
        violation_code = "&violation_code=" + item.description;
        query_string += "&violation_code=" + item.description;
      });
    } else {
      filterOption.violation_code.map((item) => {
        query_string += item.description;
      });
    }
    if (filterOption.state.length > 0) {
      filterOption.state.map((item) => {
        state = "&state=" + item.code;
        query_string += "&state=" + item.code;
      });
    }
    if (filterDate.created_ts_from) {
      created_ts_from = "&created_ts_from=" + convertFilterFormatData(filterDate.created_ts_from);
      query_string +=
        "&created_ts_from=" +
        convertFilterFormatData(filterDate.created_ts_from);
    }
    if (filterDate.created_ts_to) {
      created_ts_to = "&created_ts_from=" + convertFilterFormatData(filterDate.created_ts_from);
      query_string +=
        "&created_ts_to=" + convertFilterFormatData(filterDate.created_ts_to);
    }
    setAllSelectedFilterOption(
      created_ts_to + 
      created_ts_from +
      state +
      violation_code +
      appeal_status +
      zip +
      city +
      vin_no +
      citation_no
    )
    getAppealListDaata(query_string);
    setIsPrevBtnActive("disabled");
    setIsNextBtnActive("")
    setCurrentPage(1);
    setLowerPageBound(0);
    setUpperPageBound(3);
  };

  const backButtonClick = () => {
    setDetailPage(false);
  };

  const handleStateDetailpage = (id, tikno) => {
    Cookies.set("appealid", id);
    Cookies.set("tikno", tikno);
    setDetailPage(true);
    setCitationDetailPage(false);
  };

  const handleRadioButtonClick = (tikno,status) => {
    setCitationNumber(tikno);
    setappealid(tikno);
    setSelectedAppealStatus(status)
  };

  const handleCitationDetailpage = (ticket) => {
    Cookies.set("tikno", ticket);
    setDetailPage(true);
    setCitationDetailPage(true);
  };

  const onChangeReviewInputs = (e, pl) => {
    const { name, value } = e.target;
    if (pl === "tab_1") {
      switch (name) {
        case "remark":
          manageReviewError.remark = !isMansField() && value ? "" : "Enter remark";
          break;
        case "workflow_status":
          manageReviewError.workflow_status = value
            ? ""
            : "Select workflow status";
          break;
        case "appeal_status":
          manageReviewError.appeal_status = value ? "" : "Select appeal status";
          break;
        default:
          break;
      }
      setManageReviewError({ ...manageReviewError });
    if (name === "is_internal") {
      setManageReviewState({ ...manageReviewState, [name]: e.target.checked });
    } else {
      setManageReviewState({ ...manageReviewState, [name]: value });
    }
    }
  };  
  const onChangeTextEditorForC = (editorState2) => {
    setwysiwygTextC(editorState2);
    setManageReviewState({
      ...manageReviewState,
      additional_remark: editorState2,
    });
  };

  const AppealStatusChangeClick = (e) => {
    e.preventDefault();
    let isError = false;
    if (!isMansField() && manageReviewState.remark === "") {
      isError = true;
      manageReviewError.remark = "Enter remark.";
    }
    if (manageReviewState.workflow_status === "") {
      isError = true;
      manageReviewError.workflow_status = "Select workflow status.";
    }
    if (manageReviewState.appeal_status === "") {
      isError = true;
      manageReviewError.appeal_status = "Select appeal status.";
    }
    setManageReviewError({ ...manageReviewError });

    if(!isError){
      if(manageReviewState.appeal_status === "Appeal Upheld" ||
        manageReviewState.appeal_status ===
        "Appeal Partially Upheld" ||
        manageReviewState.appeal_status ===
        "Appeal Upheld (Ticket Dismissed)" ||
        manageReviewState.appeal_status === "Appeal Rejected" || manageReviewState.appeal_status === "Closed"){
          setShowWarning(true);
          isError = true
      }
    }
      
    if (!isError) {
      appealsearveyupdateremark1();
      async function appealsearveyupdateremark1() {
        try {
          let payload = { ...manageReviewState };

          const result =
            await dashboardService.updateAppealSurveyorAddRemarkAPI(
              appealid,
              payload
            );
          if (result.data.status) {
            setMessageSuc(result.data.message);
            setManageReviewState({
              appeal_status: "",
              remark: "",
              additional_remark: "",
              created_ts_to: "",
              created_ts_from: "",
              workflow_status: "",
            });

            setTimeout(() => {
              setMessageSuc("");
              setwysiwygTextC("");
              setOpen(false);
            }, 2000);

            // getNotificationDetail()
          }
        } catch (err) {
          setMessageErr(err.response.data.message);
          setTimeout(() => {
            setMessageErr("");
          }, 2000);
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            appealsearveyupdateremark1();
          }
        }
      }
    }
  };

  useEffect(() => {
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + currentPage).addClass("active-paginated");
  },[])

  const onPaginationSelection = (e) => {
    setPaginationSelectVaue(e.target.value)
    setTodosPerPage(e.target.value)
    setCurrentPage(1)
    let query_string = ""
    query_string += "page=" + 1;
    query_string += "&limit=" + e.target.value
    if (filterOption.citation_number) {
      query_string += "&citation_number=" + filterOption.citation_number;
    }
    if (filterOption.vin_number) {
      query_string += "&vin_number=" + filterOption.vin_number;
    }
    if (filterOption.city) {
      query_string += "&city=" + filterOption.city;
    }
    if (filterOption.zip) {
      query_string += "&zip=" + filterOption.zip;
    }
    if (filterOption.appeal_status.length > 0) {
      const appeal = [];
      filterOption.appeal_status.map((item) => {
        appeal.push(item.appeal_status)
    })
      query_string += "&appeal_status=" + appeal.toString();
    }
    if (filterOption.violation_code.length > 0) {
      filterOption.violation_code.map((item) => {
        query_string += "&violation_code=" + item.description;
      });
    } else {
      filterOption.violation_code.map((item) => {
        query_string += item.description;
      });
    }
    if (filterOption.state.length > 0) {
      filterOption.state.map((item) => {
        query_string += "&state=" + item.code;
      });
    }
    if (filterDate.created_ts_from) {
      query_string +=
        "&created_ts_from=" +
        convertFilterFormatData(filterDate.created_ts_from);
    }
    if (filterDate.created_ts_to) {
      query_string +=
        "&created_ts_to=" + convertFilterFormatData(filterDate.created_ts_to);
    }
    getAppealListDaata(query_string)
    setPrevAndNextBtnClass(1)
    setLowerPageBound(0);
    setUpperPageBound(3);
  }

  const handleClick = (e) => {
    let listid = Number(e.target.id);
    setCurrentPage(listid);
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + listid).addClass("active-paginated");
    setPrevAndNextBtnClass(listid);

    let query_string = ""
    query_string += "page=" + listid;
    query_string += "&limit=" + todosPerPage;
    query_string += allSelectedFilterOption;
    getAppealListDaata(query_string)
  }

  const setPrevAndNextBtnClass = (listid) => {
    let totalPage = Math.ceil(
      totalDataLengthCount / todosPerPage
    );
    setIsNextBtnActive("disabled");
    setIsPrevBtnActive("disabled");
    if (totalPage === listid && totalPage > 1) {
      setIsPrevBtnActive("");
    } else if (listid === 1 && totalPage > 1) {
      setIsNextBtnActive("");
    } else if (totalPage > 1) {
      setIsNextBtnActive("");
      setIsPrevBtnActive("");
    }
  };

  const btnIncrementClick = () => {
    setUpperPageBound(upperPageBound + pageBound);
    setLowerPageBound(lowerPageBound + pageBound);
    let listid = upperPageBound + 1;
    setCurrentPage(listid);
    setPrevAndNextBtnClass(listid);
    let query_string = ""
    query_string += "page=" + listid;
    query_string += "&limit=" + todosPerPage
    query_string += allSelectedFilterOption
    getAppealListDaata(query_string)
  }

  const btnDecrementClick = () => {
    setUpperPageBound(upperPageBound - pageBound);
    setLowerPageBound(lowerPageBound - pageBound);
    let listid = upperPageBound - pageBound;
    setCurrentPage(listid);
    setPrevAndNextBtnClass(listid);
    let query_string = ""
    query_string += "page=" + listid;
    query_string += "&limit=" + todosPerPage
    query_string += allSelectedFilterOption
    getAppealListDaata(query_string)
  }

  const btnPrevClick = () => {
    if ((currentPage - 1) % pageBound === 0) {
      setUpperPageBound(upperPageBound - pageBound);
      setLowerPageBound(lowerPageBound - pageBound);
    }
    let listid = currentPage - 1;
    setCurrentPage(listid);
    setPrevAndNextBtnClass(listid);

    let query_string = ""
    query_string += "page=" + listid;
    query_string += "&limit=" + todosPerPage
    query_string += allSelectedFilterOption
    getAppealListDaata(query_string)
  }

  const btnNextClick = () => {
    if (currentPage + 1 > upperPageBound) {
      setUpperPageBound(upperPageBound + pageBound);
      setLowerPageBound(lowerPageBound + pageBound);
    }
    let listid = currentPage + 1;
    setCurrentPage(listid);
    setPrevAndNextBtnClass(listid);

    let query_string = ""
    query_string += "page=" + listid;
    query_string += "&limit=" + todosPerPage
    query_string += allSelectedFilterOption
    getAppealListDaata(query_string)
  }

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalDataLengthCount / todosPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if(pageNumbers.length === 1){
      setIsNextBtnActive("disabled")
    }
  },[pageNumbers])

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number === currentPage) {
      return (
        <li key={number} className="active-paginated" id={number}>
          <button id={number} onClick={handleClick}>
            {number}
          </button>
        </li>
      );
    } else if (number < upperPageBound + 1 && number > lowerPageBound) {
      return (
        <li key={number} id={number}>
          <button id={number} onClick={handleClick}>
            {number}
          </button>
        </li>
      );
    }
  });
  let pageIncrementBtn = null;
  if (pageNumbers.length > upperPageBound) {
    pageIncrementBtn = (
      <li className="">
        <button onClick={btnIncrementClick}>
          {" "}
          &hellip;{" "}
        </button>
      </li>
    );
  }
  let pageDecrementBtn = null;
  if (lowerPageBound >= 1) {
    pageDecrementBtn = (
      <li className="">
        <button onClick={btnDecrementClick}>
          {" "}
          &hellip;{" "}
        </button>
      </li>
    );
  }
  let renderPrevBtn = null;
  if (isPrevBtnActive === "disabled") {
    renderPrevBtn = (
      <li className={isPrevBtnActive}>
        <span id="btnPrev"> Prev </span>
      </li>
    );
  } else {
    renderPrevBtn = (
      <li className={isPrevBtnActive}>
        <button id="btnPrev" onClick={btnPrevClick}>
          {" "}
          Prev{" "}
        </button>
      </li>
    );
  }
  let renderNextBtn = null;
  if (isNextBtnActive === "disabled") {
    renderNextBtn = (
      <li className={isNextBtnActive}>
        <span id="btnNext"> Next </span>
      </li>
    );
  } else {
    renderNextBtn = (
      <li className={isNextBtnActive}>
        <button id="btnNext" onClick={btnNextClick}>
          {" "}
          Next{" "}
        </button>
      </li>
    );
  }

  const handleCloseErrorModal = () => {
    setErrorOpen(false)
  }

  const handleCloseconfirmationModal = () => {
    setShowWarning(false);
    setManageReviewState({
      appeal_status: "",
      remark: "",
      additional_remark: "",
      created_ts_to: "",
      created_ts_from: "",
      workflow_status: "",
    });
  }

  const handleConfirm = () => {
    setShowWarning(false);
    let isError = false;
    if (!isMansField() && manageReviewState.remark === "") {
      isError = true;
      manageReviewError.remark = "Enter remark.";
    }
    if (manageReviewState.workflow_status === "") {
      isError = true;
      manageReviewError.workflow_status = "Select workflow status.";
    }
    if (manageReviewState.appeal_status === "") {
      isError = true;
      manageReviewError.appeal_status = "Select appeal status.";
    }
    setManageReviewError({ ...manageReviewError });

    if (!isError) {
      appealsearveyupdateremark1();
      async function appealsearveyupdateremark1() {
        try {
          let payload = { ...manageReviewState };

          const result =
            await dashboardService.updateAppealSurveyorAddRemarkAPI(
              appealid,
              payload
            );
          if (result.data.status) {
            setMessageSuc(result.data.message);
            setManageReviewState({
              appeal_status: "",
              remark: "",
              additional_remark: "",
              created_ts_to: "",
              created_ts_from: "",
              workflow_status: "",
            });

            setTimeout(() => {
              setMessageSuc("");
              setwysiwygTextC("");
              setOpen(false);
            }, 2000);

            // getNotificationDetail()
          }
        } catch (err) {
          setMessageErr(err.response.data.message);
          setTimeout(() => {
            setMessageErr("");
          }, 2000);
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            appealsearveyupdateremark1();
          }
        }
      }
    }
  }

  return (
    <>
      {!showDetailPage ? (
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
          <div className="cstm-ticket-manage-sec">
            <div className="cstm-ticket-manage-heading">
              <h3 className="cstm-all-pg-ttl operate-ttl">Appeal Reports</h3>
            </div>
            <PrintPdfCsvExcelDownload
              tableId="tbl-main-download"
              fileName="appeal-report"
              pageSize="a2"
            />
          </div>

          <div className="report-searchbar">
            <form>
              <div className="report-date report-mr">
                <DatePicker
                  showTimeSelect
                  timeIntervals={1}
                  dateFormat="MM/dd/yyyy h:mmaa"
                  timeFormat="HH:mm"
                  placeholderText="MM/DD/YYYY HH:MM"
                  autoComplete="off"
                  className="cstm-pull-details schedule-date-input"
                  name="created_ts_from"
                  selected={filterDate.created_ts_from}
                  onChange={(date) =>
                    onChangeFilterDate(date, "created_ts_from")
                  }
                />
                <img src={transfer} alt="transfer" className="date-transfer" />
                <DatePicker
                  showTimeSelect
                  timeIntervals={1}
                  placeholderText="MM/DD/YYYY HH:MM"
                  autoComplete="off"
                  dateFormat="MM/dd/yyyy h:mmaa"
                  timeFormat="HH:mm"
                  className="cstm-pull-details schedule-date-input"
                  name="created_ts_to"
                  selected={filterDate.created_ts_to}
                  onChange={(date) => onChangeFilterDate(date, "created_ts_to")}
                />
              </div>
              <div className="report-licence report-mr">
                <input
                  name="citation_number"
                  type="text"
                  value={filterOption.citation_number}
                  onChange={onchangeFilterInput}
                  placeholder={changeTotal + " Number"}
                />
              </div>
              <div className="report-licence report-mr">
                <input
                  name="vin_number"
                  type="text"
                  value={filterOption.vin_number}
                  onChange={onchangeFilterInput}
                  placeholder="Plate Number"
                />
              </div>
              <div className="report-licence select-drop-arrow report-mr">
              <Multiselect
                  name="appeal_status"
                  selectedValues={filterOption.appeal_status}
                  displayValue="appeal_status"
                  placeholder="Select State"
                  onRemove={(e) => onChangeStatusSelect(e,"appeal_status")}
                  onSelect={(e) => onChangeStatusSelect(e,"appeal_status")}
                  options={appealStatus&&appealStatus
                    .filter(
                      (item) => item.type === "APPEAl_STATUS"  
                    )
                    .map((item, index) => ({
                    appeal_status:
                      item.title,
                  }))}
                  showCheckbox
                />
                <img src={dropdownarrow} alt="dropdownarrow" />
                {/* <select
                  className="state-droupdwon ar-olny"
                  name="appeal_status"
                  value={filterOption.appeal_status}
                  onChange={onchangeFilterInput}
                >
                  <option value="">Select appeal status</option>
                  {(appealStatus || []).map((item, i) => (
                    <option key={i} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select> */}
              </div>
              <button
                className="filter-box report-mr"
                onClick={handleFilterChange}
              >
                <img
                  src={FilterExportImg}
                  alt="FilterExportImg"
                  className="img-fluid"
                />
              </button>
              <button
                className="licence-print report-mr"
                onClick={handleFilterSubmit}
              >
                Submit
              </button>
            </form>
          </div>
          {isFilterOpen && (
            <div className="block-main">
              <div className="report-licence select-drop-arrow report-mr">
                <Multiselect
                  name="state"
                  selectedValues={filterOption.state}
                  displayValue="state"
                  placeholder="Select State"
                  onRemove={(e) => onChangeStateSelect(e, "state")}
                  onSelect={(e) => onChangeStateSelect(e, "state")}
                  options={stateArr.map((item, index) => ({
                    state:
                      item.state_name + " (" + item.state_abbreviated + ")",
                    code: item.state_abbreviated,
                  }))}
                  showCheckbox
                />
                <img src={dropdownarrow} alt="dropdownarrow" />
              </div>
              <div className="report-licence select-drop-arrow report-mr violation-des-cstm-main">
                <Multiselect
                  selectedValues={filterOption.violation_code}
                  name="violation_code"
                  displayValue="description"
                  onRemove={(e) => onChangeStateSelect(e, "violation_code")}
                  onSelect={(e) => onChangeStateSelect(e, "violation_code")}
                  placeholder="Select Violation Description"
                  options={violationDescriptionArr.map((item, index) => ({
                    description: item.violation_description,
                    code: item.code,
                  }))}
                  showCheckbox
                />
                <img src={dropdownarrow} alt="dropdownarrow" />
              </div>
              <div className="report-licence">
                <input
                  type="text"
                  className="block-button cstm-ct-zc"
                  name="city"
                  value={filterOption.city}
                  onChange={onchangeFilterInput}
                  placeholder="City"
                />
              </div>
              <div className="report-licence">
                <input
                  type="text"
                  className="block-button cstm-ct-zc"
                  name="zip"
                  value={filterOption.zip}
                  onChange={onchangeFilterInput}
                  placeholder="Zipcode"
                />
              </div>
            </div>
          )}

          <div id="Dashboard" className="tab-pane active">
            <div className="text-center spin-load-main">
              {isLoader ? (
                <div className="spinner-border"></div>
              ) : appealList === null || appealList?.length === 0 ? (
                <div className="no-record-found-main">No Record Found</div>
              ) : (
                <>
                  {uiElements.uiElements.includes("BUTTON_APPEALS_MANAGEMENT_CHANGE__APPEAL_STATUS_BUTTON") && <div className="void-modify-main">
                    <button
                      className="modify-btn"
                      onClick={() => {
                        if(selectedAppealStatus === "Appeal Upheld" ||
                            selectedAppealStatus ===
                            "Appeal Partially Upheld" ||
                            selectedAppealStatus === "Appeal Rejected" || 
                            selectedAppealStatus === "Appeal Upheld (Ticket Dismissed)" || 
                            selectedAppealStatus === "Closed"){
                              setErrorOpen(true)
                          }else if (showCitationNumber !== "") {

                            
                          setOpen(true);
                        }
                      }}
                    >
                      Change Appeal Status
                    </button>
                  </div>}
                  <div className="ctsm-table-scroll">
                    <div className="report_tbl_scroll fix-page-scroll-only" id="tbl-main-download">
                      <table className="table-heading table table-bordered">
                        <thead>
                          <tr>
                            <th
                              className={`srt-tab ${
                                sorting.key === "created_at_local" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "created_at_local",
                                  !sorting.ascending
                                )
                              }
                            >
                              Appeal Date
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "citation_number" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "citation_number",
                                  !sorting.ascending
                                )
                              }
                            >
                              {changeTotal} Number
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "hearing_date" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "hearing_date",
                                  !sorting.ascending
                                )
                              }
                            >
                              Hearing Date
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "vin_number" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting("vin_number", !sorting.ascending)
                              }
                            >
                              Plate number
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "city" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting("city", !sorting.ascending)
                              }
                            >
                              City
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "state" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting("state", !sorting.ascending)
                              }
                            >
                              State
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "zip" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting("zip", !sorting.ascending)
                              }
                            >
                              Zip
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "citation_status" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "citation_status",
                                  !sorting.ascending
                                )
                              }
                            >
                              {changeTotal} Status
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "appeal_status" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "appeal_status",
                                  !sorting.ascending
                                )
                              }
                            >
                              Appeal Status
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "violation_code" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "violation_code",
                                  !sorting.ascending
                                )
                              }
                            >
                              Vio Description
                            </th>
                            <th
                              className={`srt-tab ${
                                sorting.key === "appeal_reason" &&
                                (sorting.ascending ? "srt-ace" : "srt-desc")
                              }`}
                              onClick={() =>
                                applySorting(
                                  "appeal_reason",
                                  !sorting.ascending
                                )
                              }
                            >
                              Appeal Reason
                            </th>
                            {hasHelicalReports() && <th>Appeal Analytics</th>}
                                
                          </tr>
                        </thead>
                        <tbody>
                          {(appealList || [])?.map((item, i) => (
                            <tr
                              key={i}
                              class={`${
                                item?.appeal_status === "Appeal Upheld" ||
                                item?.appeal_status ===
                                  "Appeal Partially Upheld" ||
                                item?.appeal_status === "Closed" ||
                                item?.appeal_status === "Appeal Upheld (Ticket Dismissed)" ||
                                item?.appeal_status === "Appeal Rejected"
                                  ? "cstm-green-cls"
                                  : ""
                              }`}
                            >
                              
                              <td className="cstm-checkbox" >
                                <input
                                  type="radio"
                                  name="citation_select"
                                  onClick={() =>
                                    handleRadioButtonClick(
                                      item?.id,
                                      item?.appeal_status
                                    )
                                  }
                                />
                                <Link
                                  to={`/appeal-report?id=${item?.id}&citation_no=${item?.citation_number}`}
                                  onClick={() =>
                                    handleStateDetailpage(
                                      item?.id,
                                      item?.citation_number
                                    )
                                  }
                                >
                                  {item?.created_at_local ? moment(
                                    item?.created_at_local?.split("T")[0]
                                  ).format("MM/DD/YYYY") +
                                    " " +
                                    item?.created_at_local?.substring(11, 19) : ""}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/ticketreporting?&ticket_no=${item.citation_number}`}
                                >
                                  {/* TEST UD */}
                                  {item.citation_number}
                                </Link>
                              </td>
                              <td className="cstm-checkbox" >
                                  {item?.hearing_date && item?.hearing_date !== "0001-01-01T00:00:00Z" ? moment(
                                    item?.hearing_date?.split("T")[0]
                                  ).format("MM/DD/YYYY") +
                                    " " +
                                    item?.hearing_date?.substring(11, 19) : "-"}
                              </td>
                               <td>{item.plate_number ? item.plate_number : "-"}</td>
                              <td>{item.city ? item.city : "-"}</td>
                              <td>{item.state ? item.state : "-"}</td>
                              <td>{item.zip ? item.zip : "-"}</td>
                              <td>{item.citation_status ? item.citation_status : "-"}</td>
                              <td>{item.appeal_status ? item.appeal_status : "-"}</td>
                              <td>{item.violation_code ? item.violation_code : "-"}</td>
                              <td>{item.appeal_reason ? item.appeal_reason : "-"}</td>
                              {hasHelicalReports() && <td>
                                <Link to={`/ticketAnalyticsReport/${item.plate_number}`}>Appeal Analysis</Link>
                              </td>}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {appealList.length !== 0 &&
                      noRecordFound !== true &&(
                      <>
                      <div className="cstm-permit-trf">
                        <div className="col-12">
                          <div className="total-count-records">
                            Total <b> {totalDataLengthCount} </b>{" "}
                            Records Found{" "}
                          </div>
                        </div>
                        <ul className="cstm-pagination-added-bottom">
                          {renderPrevBtn}
                          {pageDecrementBtn}
                          {renderPageNumbers}
                          {pageIncrementBtn}
                          {renderNextBtn}
                        </ul>
                      </div>
                      <div className="cstm-permit-trf esc-select">
                        <div className="cstm-left-lable-pn-val">
                          Number of records per page
                        </div>
                        <div className="cstm-right-lable-pn-val">
                          <select
                            className="select-pagination-val"
                            value={paginationSelectVaue}
                            onChange={(e) => onPaginationSelection(e)}
                          >
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                          <img
                            src={dropdownarrow}
                            alt="dropdownarrow"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : showCitationDetailPage ? (
        <CitationDetail backButtonClick={backButtonClick} setCitationDetailPage={setCitationDetailPage} showCitationDetailPage={showCitationDetailPage} />
      ) : (
        <AppealDetail backButtonClick={backButtonClick} showDetailPage={showDetailPage} setDetailPage={setDetailPage} />
      )}
      {isOpen && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className=" cstm-hearing-modal cstm-appeal-status-changes-main">
              <div className="ctsm-modal-header">
                <div className="cstm-modal-title">
                  <h3></h3>
                </div>
                <div className="cstm-close">
                  <button
                    onClick={() => {
                      setOpen(false);
                      setManageReviewState({
                        appeal_status: "",
                        remark: "",
                        additional_remark: "",
                        created_ts_to: "",
                        created_ts_from: "",
                        workflow_status: "",
                      });
                    }}
                  >
                    ×
                  </button>
                </div>
              </div>
              <div className="cstm-modal-body">
                <form>
                  {messageSuc && (
                    <div className="alert alert-success" role="alert">
                      {messageSuc}
                    </div>
                  )}
                      {messageErr && (
                    <div className="alert alert-danger" role="alert">
                      {messageErr}
                    </div>
                  )}
                  <div className="cstm-inputfield cstm-inputfield-hearing">
                    <label htmlFor="">Workflow Status *</label>
                    <br />
                    <select
                      onChange={(e) => onChangeReviewInputs(e, "tab_1")}
                      className="state-droupdwon  ar-olny"
                      name="workflow_status"
                    >
                      <option value="">Select Status</option>
                      {(showStepStatus || []).map((item, i) => (
                        <option key={i} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    <span className="errormessage">
                      {manageReviewError.workflow_status}
                    </span>
                  </div>

                  <div className="cstm-inputfield cstm-inputfield-hearing">
                    <label htmlFor="">Appeal Status *</label>
                    <br />
                    <select
                      onChange={(e) => onChangeReviewInputs(e, "tab_1")}
                      className="state-droupdwon  ar-olny"
                      name="appeal_status"
                    >
                      <option value="">Select Status</option>
                      {(appealStatus || [])
                      .filter((item) => item.type !== "HEARING_STATUS")
                      .map((item, i) => (
                        <option key={i} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    <span className="errormessage">
                      {manageReviewError.appeal_status}
                    </span>
                  </div>
                  <div className="cstm-inputfield">
                    <label>Remark {!isMansField() ? '*' : ''}</label>
                    <br />
                    <textarea
                      onChange={(e) => onChangeReviewInputs(e, "tab_1")}
                      name="remark"
                      id=""
                      cols="30"
                      rows="4"
                      placeholder="Write remark"
                      maxLength={700}
                      className="mb-0"
                    ></textarea>
                    <span className="errormessage">
                      {manageReviewError.remark}
                     </span>
                     <span className="cstm_appeal_character_warning">{"700"} characters limit.</span>
                  </div>
                  <div className="cstm-inputfield">
                    <label htmlFor="">Note to the motorist</label>
                    <br />
                    <QuillEditor
                      onChange={(e) => onChangeTextEditorForC(e)}
                      value={wysiwygTextC}
                    />
                  </div>
                  <div className="col-12 form-group formgroup-main f-main2  f-main-cstm pl-0">
                <label>
                  <div className="in-cstm-sub-notification-only">
                    <input
                      type="checkbox"
                      name="is_internal"
                      onChange={(e) => onChangeReviewInputs(e, "tab_1")}
                    />
                    Is an Internal Remark
                  </div>
                </label>
              </div>
                  <div className="cstm-submit-btn">
                    <button onClick={AppealStatusChangeClick} type="button">
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {errorOpen && (
        <ErrorModalComponent displayMsg={"Appeal already has a final outcome and can not be updated now."} handleCloseModel={handleCloseErrorModal} />
      )}
      {showWarning && (
        <ConfirmationModalComponent displayMsg={"This status change is permanent and can’t be changed again."} handleCloseModel={handleCloseconfirmationModal} handleConfirm={handleConfirm} />
      )

      }
    </>
  );
};

export default AppealReport;
