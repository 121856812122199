import Cookies from 'js-cookie'

export default function CookiesRemove() {
    Cookies.remove("");
    Cookies.remove('isLoggedIn');
    Cookies.remove('mobile_otp_nonce');
    Cookies.remove('token');
    Cookies.remove('auth');
    Cookies.remove('isAgency');
    Cookies.remove('agency');
    Cookies.remove('siteinfo');
    Cookies.remove('ticket_no_search');
    Cookies.remove('RO');
    Cookies.remove('appealid')
    Cookies.remove('tikno')
    Cookies.remove('siteName')
  return (
    <></>
  )
}
