import "react-image-gallery/styles/css/image-gallery.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Service from "../../../Services/main.service";
import dashboardService from "../../../Services/dashboard.service";

import SuspendModal from "../../Citation/SuspendModal";
import ChangeAmountModal from "../../Citation/ChangeAmountModal";
import HearingModal from "../../Citation/HearingModal";

import BackbuttinIMag from "../../../Images/back.png";
import box1 from "../../../Images/ticket.png";
import PayImg from "../../../Images/pay.png";
import CitationDetailsData from "../../AppealManagement/AppealDetail";
import { changetotal, changeticket, siteid } from "../../../Global/site";
import ROInformationModal from "../../Citation/ROInformationModal";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

import ImageSlider from "../../../utile/imageSlider/imageSlider";
import ResendNotice from "./ResendNotice";
import TicketAnalyticsReport from "./TicketAnalyticsReport";

import AuditTrailTab from "./AuditTrailTab";
import RefundModal from "./RefundModal";
import ConfirmationModalComponent from "../../../utile/modals/confirmationModal";
import CommercialTab from "./CommercialTab";
import LinkedTab from "./LinkedTab";
import PaymentDetailsTab from "./PaymentDetailsTab";
import TicketDetailHeader from "./TicketDetailHeader";
import SupportNoteModal from "../../Citation/SupportNoteModal";
import ROAddressTab from "./ROAddressTab";
import CitationSummaryTab from "./CitationSummaryTab";
import CitationInfoTab from "../../Utilies/CitationInfo";
import UserDetailsTab from "./UserDetailsTab";
import EditCitationModal from "../../Citation/EditCitationModal";
import VoidCitationModal from "../../Utilies/VoidCitationModal";
import ErrorModalComponent from "../../../utile/modals/errorModal";
import WarrantStatusModal from "../../Citation/warrantStatus";

// check if site is Laz Parking
const isLazParking = () => {
  if (
    Cookies.get("siteinfo") === "8e9bc50c-95ab-11ee-9a11-c20b99673619" ||
    Cookies.get("siteinfo") === "9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e"
  ) {
    return true;
  } else return false;
};
const isOCMD = () => {
  if (Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" || Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
  Cookies.get("siteinfo") === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" ||
  Cookies.get("siteinfo") === "ead64c86-3681-11ee-9c41-6ef2fb394a83") {
    return true
  } else {
    return false
  }
}
const isOCMDValidation = () => {
  if (
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476"
  ) {
    return true
  } else {
    return false
  }
}
// check if site is allow collection status citation to pay
const isCollectionPayable = () => {
  if (
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
    Cookies.get("siteinfo") === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45" ||
    Cookies.get("siteinfo") === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" ||
    Cookies.get("siteinfo") === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
    Cookies.get("siteinfo") === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c" ||
    Cookies.get("siteinfo") === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
    Cookies.get("siteinfo") === "5bd20490-dc2f-11ee-bf9e-72127457a419"
  ) {
    return true
  } else {
    return false
  }
}

const TicketDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().search;
  const changeTotal = changetotal();

  const [rightClick, setRightClick] = React.useState(-1);
  const [showCitationDetails, setCitationDetails] = useState({});
  const [showCitationInformation, setCitationInformation] = useState(false);
  const [showCitationDataDisplay, setCitationDataDisplay] = useState(false);
  const [showImages, setImages] = React.useState([]);
  const showSideId = siteid();
  const [isEligibleOpen, setEligibleOpen] = useState(false);
  const [isEligibleMsg, setEligibleMsg] = useState("");
  const [citePayload, setCitePayload] = useState({});
  const [ticketNumber, setTicketNumber] = useState("");
  const [isEligible, setIsEligible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [summary, setSummary] = useState([])
  const [showData, setShowData] = useState(false)
  const [selectedValue, setSelectedValue] = useState(false);
  const [showPayloadCitationAmount, setPayloadCitationAmount] = useState({});
  const [modalOpen, setModal] = useState(false);
  const [isOpenPyC, setOpenPyC] = React.useState(false);
  const [messageSuc, setMessageSuc] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [commercialData, setCommercialData] = React.useState({});

  const [commercialUpdateData, setCommercialUpdateData] = React.useState({
    ticket_number: "",
    site_id: showSideId.siteinfo,
    fine_amount: null,
    nsf_amount: null,
    boot_tow_fee: null,
    collection_amount: null,
    balance_due: null,
    notice_date: {},
    dmv_case_number: "",
    remark: "",
    paid_date: null,
    due_date: null,
    fine_increase_date: null,
    nsf_applied_date: null,
    boot_applied_date: null,
    collection_date: null,
    adjusted_balance_date: null,
    charge_back_fee: null,
    charge_back_applied_date: null,
    admin_fee:null,
    admin_fee_applied_date: null,
    parking_fee:null,
    parking_fee_applied_date: null,
    escalation_detail: []
  });

  const [payloadCitation, setPayloadCitation] = useState({});
  const [clipboardtext, setClipboardText] = useState("");
  const [imageId, setImageId] = useState();
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const [showResendNotice, setShowResendNotice] = useState(false);
  const [paymentBreakdown, setPaymentBreakdown] = useState([]);
  const [paymentDetail, setPaymentDetail] = useState([]);
  const [refundDetails, setRefundDetails] = useState([]);
  const [registeredOwnerDetails, setRegisteredOwnerDetails] = useState([]);
  // linked tab
  const [plateData, setPlateData] = useState([]);
  const [showNulldata, setShowNullData] = useState(false);
  const [noData, setNoData] = useState("");

  // linked tab states start
  const [totalOutStanding, setTotalOutStanding] = useState(0);
  const [citationList, setCitationList] = useState([]);
  const [selectCitationAmount, setSelectCitationAmount] = useState(0)
  const [showEditCitationModal, setShowEditCitationModal] = useState(false);
  const [editCitationFormChangeData, setEditCitationFormChangeData] = useState({
    citation_start_timestamp: "",
    ticket_no: "",
    block: "",
    street: "",
    state: "",
    lp_number: "",
    citation_id: "",
    remarks: "",
    remark_1: "",
    remark_2: "",
    note_1: "",
    note_2: "",
    description: "",
    code: "",
  });
  const [showCancelCitation, setCancelCitation] = useState(false)
  const [changedCommercialFields, setChangedCommercialFields] = useState({
    ticket_number: "",
    site_id: showSideId.siteinfo,
    escalation_detail:[]
  })
  const [errorMessage, setErrorMessage] = useState("")
  const [commercialUpdateError, setCommercialUpdateError] = useState({
    nsf_amount: "",
    charge_back_fee: "",
    original_amount: "",
  })

  const [showNsfOptions, setShowNsfOptions] = useState(false);
  const [showChargeBackOptions, setShowChargeBackOptions] = useState(false);

  const changeName = changeticket();

  const [sorting, setSorting] = useState({
    field: "xyz",
    ascending: true,
    type: "",
  });


  const handleEscalationRollback = (e) => {
    const data = {
      site_id: showSideId.siteinfo,
      ticket_no: ticketNumber,
    }

    const escalationRollback = async () => {
      try {
        const result = await dashboardService.rollbackEscalation(data);
        if (result.data.success) {
          toast.success("Escalation rollback successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getCitationAllDetails(ticketNumber);
        } else {
          toast.warn(result?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          escalationRollback();
        } else {
          toast.error("Error occurred in processing this request", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }

    escalationRollback();
  };

  const getPaymentDetails = async (ticket_no) => {
    try {
      const result = await dashboardService.getPaymentDetails(ticket_no);
      const paymentBreakdownArray = [];
      if (Object.keys(result.data?.data?.paymentBreakdown).length !== 0) {
        paymentBreakdownArray.push(result.data.data.paymentBreakdown);
        setPaymentBreakdown(result.data.data.paymentBreakdown);
      }
      if (result.data.status) {
        setPaymentDetail(result.data?.data?.payment_detail);
        setRefundDetails(result.data?.data?.refund_detail);
        setRegisteredOwnerDetails(result.data?.data?.registered_owner_details);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getPaymentDetails(ticket_no);
      }
    }
  };

  useEffect(() => {
    Cookies.set("tikno", location.split("=")[1]);
  }, [location]);

  useEffect(() => {
    const currentDataCopy = { ...showCitationDetails };
    if (sorting.type === "audit-trail") {
      const sortedCurrentdata = (currentDataCopy?.audit_trail || []).sort(
        (a, b) => {
          return a[sorting.key].localeCompare(b[sorting.key]);
        }
      );
      setCitationDetails({
        ...currentDataCopy,
        audit_trail: sorting.ascending
          ? sortedCurrentdata
          : sortedCurrentdata.reverse(),
      });
    } else if (sorting.type === "notice") {
      const sortedCurrentdata = (
        currentDataCopy?.notification_audit_trail || []
      ).sort((a, b) => {
        return a[sorting.key].localeCompare(b[sorting.key]);
      });
      setCitationDetails({
        ...currentDataCopy,
        notification_audit_trail: sorting.ascending
          ? sortedCurrentdata
          : sortedCurrentdata.reverse(),
      });
    }
  }, [sorting]);

  const applySorting = (key, ascending, type) => {
    setSorting({ key: key, ascending: ascending, type: type });
  };

  useEffect(() => {
    const tikno = Cookies.get("tikno");
    setTicketNumber(tikno);
    if (tikno) {
      getCitationAllDetails(tikno);
      getPaymentDetails(tikno);
      pullCitationFun();
    } else {
      navigate("/ticketreporting");
    }
  }, []);

  const pullCitationFun = async () => {
    try {
      const tikno = Cookies.get("tikno");
      let payload_string = "&ticket_number=" + tikno;
      const result = await dashboardService.getTickitNumberApi(
        showSideId.siteinfo + payload_string
      );
      if (result.data.status && result.data.data.total_citation !== 0) {
        let newCiteData = result.data.data;
        const allcitedata = await imageDataGet(newCiteData);

        dispatch({ type: "CITATION_SEARCH_DATA", payload: allcitedata });
        // filterCitationdataFun(allcitedata);
      }
    } catch (err) {
      // navigate('/search')
    }
  };
  async function imageDataGet(data) {
    try {
      let newCiteData = { ...data };
      for (let i = 0; i < newCiteData.citation_detail.length; i++) {
        var imagesLink = newCiteData.citation_detail[i].images;
        var imageData = {
          download_type: "CitationImages",
          links: Object.assign({}, imagesLink),
          site_id: showSideId.siteinfo,
        };
        var imageData1 = {
          links: Object.assign({}, imagesLink),
          download_type: "CitationImages",
          site_id: showSideId.siteinfo,
        };

        if (newCiteData.citation_detail[i].images && newCiteData.citation_detail[i].images.length) {
          const responseImage = await Service.downloadImage(imageData);
          const responseImage1 = await Service.downloadImageCheck(imageData1);
          
          newCiteData.citation_detail[i].new_images = responseImage.data.metadata;
          newCiteData.citation_detail[i].new_images = responseImage1.data.metadata;
        }

        if (
          newCiteData.citation_detail[i].status.toLowerCase() !==
          "voidandreissue" &&
          newCiteData.citation_detail[i].status.toLowerCase() !==
          "rescind" &&
          newCiteData.citation_detail[i].status.toLowerCase() !== "cancelled" && 
          newCiteData.citation_detail[i].status.toLowerCase() !==
        "uncollectable" &&
        newCiteData.citation_detail[i].status.toLowerCase() !==
        "excused"
        ) {
          if (
            newCiteData.citation_detail[i].ticket_type.toLowerCase() !==
            "warning" &&
            newCiteData.citation_detail[i].amount > 0
          ) {
            newCiteData.citation_detail[i].checked = true;
          } else {
            newCiteData.citation_detail[i].checked = false;
          }
        } else {
          newCiteData.citation_detail[i].checked = false;
        }
      }

      return newCiteData;
    } catch (err) { }
  }

  const downloadAttechment = async (e, i) => {
    e.preventDefault();
    let url = "";

    if (e.target.dataset.id) {
      const imageData = {
        links: Object.assign({}, { 0: e.target.dataset.id }),
        download_type: "CitationImages",
        site_id: showSideId.siteinfo,
      };
  
      try {
        const responseImage = await Service.downloadImage(imageData);
        if (responseImage.data) {
          url = responseImage.data.metadata[0].url;
          setClipboardText(url);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          // Retry download if token was refreshed
          return downloadAttechment(e, i);
        }
      }
  
      if (e.nativeEvent.button === 2) {
        setRightClick(i);
      } else {
        // Check if the url is available before triggering the download
        if (url) {
          // Create an anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "attachment"; // You can set a default download filename here
  
          // Trigger a click event on the anchor element
          document.body.appendChild(anchor);
          anchor.click();
  
          // Remove the anchor element after the click event
          document.body.removeChild(anchor);
  
          setRightClick(-1);
        } else {
          console.error("Download URL not available.");
        }
      }
    }
  };

  const backButtonClick = () => {
    setCitationInformation(true);
    getCitationAllDetails(ticketNumber);
  };

  function getCitationAllDetails(tikno) {
    const ticketNumber = "ticket_number=" + tikno;

    initialcitationfun();
    function initialcitationfun() {
      Service.citationDetails(ticketNumber)
        .then(async (response) => {
          if (
            response.data.status === true &&
            response.data.data.detail !== null
          ) {
            setCitationDetails(response.data.data.detail);
            setCitationInformation(true);
            const details_d = response.data.data.detail;
            const citation_d = response.data.data;
            let service_amount = details_d.amount_due + response.data.data.service_fee;
            let without_service_amount = details_d.amount_due;
            let payloadCitation = {
              lp_number: details_d.lp_number,
              state: details_d.vehicle_details.state,
              total_citation: 1,
              total_due_amount: isOCMD() ? without_service_amount : service_amount,
              select_amount: isOCMD() ? without_service_amount : service_amount,
              select_citation: 1,
              service_fee: response.data.data.service_fee,
              citation_detail: [
                {
                  checked: true,
                  status: details_d.status,
                  category: details_d.category,
                  type: details_d.type,
                  amount: details_d.fine_amount,
                  amount_due: details_d.amount_due,
                  citation_number: details_d.ticket_no,
                  plate: details_d.lp_number,
                  citation_id: details_d.id,
                  issue_date: details_d.citation_issue_timestamp,
                  state: details_d.vehicle_details.state,
                  violation_description:
                    details_d.violation_details.description,
                  escalated_amount: citation_d.escalated_amount,
                  boot_tow_fee: citation_d.boot_tow_fee,
                  administrative_fee: citation_d.administrative_fee,
                  collection_fee: citation_d.collection_fee,
                  parking_fee: citation_d.parking_fee,
                  nsf_fee: citation_d.nsf_fee,
                  charge_back_fee: citation_d.charge_back_fee,
                  vin_number: details_d.vehicle_details.vin_number,
                  appeal_rejected_fee: citation_d.appeal_rejected_fee,
                  hearing_rejected_fee: citation_d.hearing_rejected_fee,
                  discount_fee: citation_d.discount_fee
                },
              ],
            };


            setPayloadCitationAmount({
              lp_number: details_d.lp_number,
              state: details_d.vehicle_details.state,
              total_citation: 1,
              total_due_amount: without_service_amount,
              select_amount: without_service_amount,
              select_citation: 1,
              citation_detail: [
                {
                  checked: true,
                  status: details_d.status,
                  category: details_d.category,
                  type: details_d.type,
                  amount: details_d.fine_amount,
                  amount_due: details_d.amount_due,
                  citation_number: details_d.ticket_no,
                  plate: details_d.lp_number,
                  citation_id: details_d.id,
                  issue_date: details_d.citation_issue_timestamp,
                  state: details_d.vehicle_details.state,
                  violation_description:
                    details_d.violation_details.description,
                  violation:
                    details_d.violation_details.code,
                  escalated_amount: citation_d.escalated_amount,
                  boot_tow_fee: citation_d.boot_tow_fee,
                  administrative_fee: citation_d.administrative_fee,
                  collection_fee: citation_d.collection_fee,
                  parking_fee: citation_d.parking_fee,
                  nsf_fee: citation_d.nsf_fee,
                  charge_back_fee: citation_d.charge_back_fee,
                  vin_number: details_d.vehicle_details.vin_number,
                  appeal_rejected_fee: citation_d.appeal_rejected_fee,
                  hearing_rejected_fee: citation_d.hearing_rejected_fee,
                  discount_fee: citation_d.discount_fee
                },
              ],
            });
            setCitePayload(payloadCitation);
            setPayloadCitation(payloadCitation);

            var imagesLink = response.data.data.detail.images;
            if (imagesLink && imagesLink.length > 0) {
              var imageData = {
                links: Object.assign({}, imagesLink),
                download_type: "CitationImages",
                site_id: showSideId.siteinfo,
              };
              initialimgdownloadfun(imageData);
            }
            // initialimgdownloadfun();
            function initialimgdownloadfun(payload) {
              Service.downloadImage(payload)
                .then((responseImage) => {
                  setImages(responseImage.data.metadata);
                })
                .catch((err) => {
                  if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch);
                    initialimgdownloadfun(payload);
                  }
                });
            }
          }
          else {
            navigate("/ticketreporting");
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            refreshTokenMiddleware(dispatch);
            initialcitationfun();
          }
        });
    }
  }

  const openModal = async (event) => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal },
      },
    } = event;

    if (modal === "appeal-modal") {
      initialAppealFun();
      async function initialAppealFun() {
        try {
          const result = await dashboardService.isEligibleForAppeal(
            showCitationDetails.header_details.citation_number
          );
          if (result.data.status) {
            navigate("/appeal-create", {
              state: citePayload.citation_detail[0],
            });
          } else {
            setEligibleOpen(true);
            setEligibleMsg(result.data.message);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            initialAppealFun();
          } else {
            setEligibleOpen(true);
            setEligibleMsg(err.response.data.message || err.message);
          }
        }
      }
    } else if (modal === "support-modal") {
      if (modal) setModal(modal);
    } else if (modal === "hearing-modal") {
      initialHearingFun();
      async function initialHearingFun() {
        try {
          const result = await dashboardService.isEligibleForHearingAPI(
            showCitationDetails.header_details.citation_number
          );
          if (result.data.status) {
            if (modal) setModal(modal);
          } else {
            setEligibleOpen(true);
            setEligibleMsg(result.data.message);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            initialHearingFun();
          } else {
            setEligibleOpen(true);
            setEligibleMsg(err.response.data.message || err.message);
          }
        }
      }
    } else {
      if (modal) setModal(modal);
    }
  };

  const closeModal = () => {
    if (modalOpen === "ro-modal") {
      getCitationAllDetails(ticketNumber);
    }
    setModal("");
    setOpenPyC(false);
    setEligibleOpen(false);
    setEligibleMsg("");
    getPaymentDetails(ticketNumber)
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setOpenPyC(true);
  };

  const selectPamentMethod = (type) => {
    if (type === "card") {
      navigate("/card-payment", {
        state: { payloadCitation: payloadCitation, path: "ticket-details" },
      });
    } else if (type === "cash") {
      navigate("/cash-payment", {
        state: {
          showPayloadCitationAmount: showPayloadCitationAmount,
          path: "ticket-details",
        },
      });
    } else {
      navigate("/cheque-payment", {
        state: {
          showPayloadCitationAmount: showPayloadCitationAmount,
          path: "ticket-details",
        },
      });
    }
  };

  const handleTabChange = async (tab) => {
    if (tab === "citation-info") {
      getCitationAllDetails(ticketNumber);
    } else if (tab === "commercial") {
      setOpen(true);
      initialCommercialFun();
      async function initialCommercialFun() {
        try {
          const result = await dashboardService.getCommercialDataAPI(
            showCitationDetails.header_details.citation_number
          );
          getCitationAllDetails(ticketNumber);

          setCommercialData(result.data.data);
          commercialUpdateData.ticket_number = result.data.data.citation_number;
          commercialUpdateData.notice_date = result.data.data.notice_date;
          changedCommercialFields.ticket_number = result.data.data.citation_number;

          commercialUpdateData.paid_date = result.data.data.paid_date && result.data.data.paid_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.paid_date.substring(0, 16)
            : null;
          commercialUpdateData.due_date = result.data.data.due_date && result.data.data.due_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.due_date.substring(0, 16)
            : null;
          commercialUpdateData.fine_increase_date = result.data.data
            .fine_increase_date && result.data.data.fine_increase_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.fine_increase_date.substring(0, 16)
            : null;
          commercialUpdateData.nsf_applied_date = result.data.data
            .nsf_applied_date && result.data.data.nsf_applied_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.nsf_applied_date.substring(0, 16)
            : null;
          commercialUpdateData.boot_applied_date = result.data.data
            .boot_applied_date && result.data.data.boot_applied_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.boot_applied_date.substring(0, 16)
            : null;
          commercialUpdateData.collection_date = result.data.data
            .collection_date && result.data.data.collection_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.collection_date.substring(0, 16)
            : null;
          commercialUpdateData.adjusted_balance_date = result.data.data
            .adjusted_balance_date && result.data.data.adjusted_balance_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.adjusted_balance_date.substring(0, 16)
            : null;
          
          commercialUpdateData.admin_fee_applied_date = result.data.data
            .admin_fee_applied_date && result.data.data.admin_fee_applied_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.admin_fee_applied_date.substring(0, 16)
            : null;
            
          commercialUpdateData.parking_fee_applied_date = result.data.data
            .parking_fee_applied_date && result.data.data.parking_fee_applied_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.parking_fee_applied_date.substring(0, 16)
            : null;
          commercialUpdateData.charge_back_applied_date = result.data.data
            .charge_back_applied_date && result.data.data.charge_back_applied_date !== "0001-01-01T00:00:00Z"
            ? result.data.data.charge_back_applied_date.substring(0, 16)
            : null;

          setCommercialUpdateData({
            ...commercialUpdateData,
            fine_amount: null,
            nsf_amount: null,
            boot_tow_fee: null,
            collection_amount: null,
            balance_due: null,
            dmv_case_number: "",
            remark: "",
          });
          setOpen(false);

          setTimeout(() => {
            setMessageSuc("");
          }, 3000);
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            initialCommercialFun();
          }
        }
      }
    } else if (tab === "linked") {
      setIsLoading(true);
      setTotalOutStanding(0);
      setCitationList([]);
      setSelectCitationAmount(0)
      LinkedData();
      async function LinkedData() {
        try {
          const res = await Service.linkedCitations(ticketNumber);
          if (res.data.success) {
            setPlateData(res.data.data && res.data.data);
            if (res.data.data === null) {
              setShowNullData(true);
              setNoData(res.data.message);
            }
            setIsLoading(false);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            LinkedData();
          }
        }
      }
    } else if (tab === "citation-summary") {
      setIsLoading(true);
      setShowData(true);
      CitationSummary();
      async function CitationSummary() {
        try {
          const res = await Service.citationSum(ticketNumber);
          if (res.data.success) {
            setSummary(res.data.data && res.data.data);
            setIsLoading(false);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            CitationSummary();
          }
        }
      }
    }
  };

  const onChangeDateOfCommercial = (e, i, key) => {
    commercialUpdateData.notice_date[key] = e.target.value;
    setCommercialUpdateData({ ...commercialUpdateData });
  };

  const onChangeCommercialInput = (e) => {
    const { name, value } = e.target
    setChangedCommercialFields({ ...changedCommercialFields, [name]: value });
    if (!isNaN(value) && value > 0) {
      setCommercialUpdateData({
        ...commercialUpdateData,
        [name]: value,
      });
      setCommercialUpdateError({
        ...commercialUpdateError,
        [name]: ``
      })
    } else {
      if (name === "charge_back_fee" && isOCMDValidation() && !isNaN(value) && value >= 0) {
        setCommercialUpdateData({
          ...commercialUpdateData,
          [name]: value,
        });
        setCommercialUpdateError({
          ...commercialUpdateError,
          [name]: ``
        })
      } else {
        setCommercialUpdateData({
          ...commercialUpdateData,
          [name]: value,
        });
        if (name === "nsf_amount" || name === "charge_back_fee") {
          if (showChargeBackOptions || showNsfOptions) {
            setCommercialUpdateError({
              ...commercialUpdateError,
              [name]: `${name} Fee must be ${isOCMDValidation() ? "zero or greater than zero" : "greater than zero"
            }.`
            })
          }
        } else if (name === "original_amount") {
          setCommercialUpdateError({
            ...commercialUpdateError,
            [name]: `Original Amount must be greater than zero.`
          })
        }
      }
    }
  };

  const onChangeCommercialUpdateDatesEvent = (e) => {
    const { name, value } = e.target;
      setChangedCommercialFields({ ...changedCommercialFields, [name]: value });
    setCommercialUpdateData({ ...commercialUpdateData, [name]: value });
  };


  const handleCommercialLateFeeChange = (e, index, escalated, escalated_amount) => {

    const { value } = e.target;

    const escalatedData = escalated_amount;

    escalatedData[index].late_fee = Number(value)

    setChangedCommercialFields(prevData => {
      
      return {
        ...prevData,
        escalation_detail: escalatedData
      };
    });

  };

  const allStringsAreBlank = (obj) => {
    for (const key in obj) {
      if (obj[key] !== "") {
        return false; // Return false if any value is not blank
      }
    }
    return true; // Return true only if all values are blank
  };

  const onUpdateCommercial = async (e) => {
    e.preventDefault();
    let payload_data = { ...changedCommercialFields };

    if (changedCommercialFields?.notice_date) {
      Object.keys(changedCommercialFields?.notice_date).map((key, i) => {
       let nd = payload_data.notice_date[key].substring(0, 16);
       payload_data.notice_date[key] = nd + ":00.000+00:00";
     });
    }
    if(changedCommercialFields?.fine_amount) {
      payload_data.fine_amount = Number(changedCommercialFields?.fine_amount);
    }
    if (changedCommercialFields?.nsf_amount) {
      payload_data.nsf_amount = Number(changedCommercialFields?.nsf_amount);
    }
    if (changedCommercialFields?.boot_tow_fee) {
      payload_data.boot_tow_fee = Number(changedCommercialFields?.boot_tow_fee);
    }
    if (changedCommercialFields?.collection_amount) {
      payload_data.collection_amount = Number(
        changedCommercialFields?.collection_amount
      );
    }
    if (changedCommercialFields.balance_due) {
      payload_data.balance_due = Number(changedCommercialFields.balance_due);
    }

    if (changedCommercialFields.paid_date) {
      payload_data.paid_date = changedCommercialFields.paid_date
        ? changedCommercialFields.paid_date + ":00.00"
        : changedCommercialFields.paid_date;
    }
    if (changedCommercialFields.due_date) {
      payload_data.due_date = changedCommercialFields.due_date
        ? changedCommercialFields.due_date + ":00.00"
        : '';
    }
    if (changedCommercialFields.fine_increase_date) {
      payload_data.fine_increase_date = changedCommercialFields.fine_increase_date
        ? changedCommercialFields.fine_increase_date + ":00.00"
        : changedCommercialFields.fine_increase_date;
    }
    if (changedCommercialFields.nsf_applied_date) {
      payload_data.nsf_applied_date = changedCommercialFields.nsf_applied_date
        ? changedCommercialFields.nsf_applied_date + ":00.00"
        : changedCommercialFields.nsf_applied_date;
    }
    if (changedCommercialFields.boot_applied_date) {
      payload_data.boot_applied_date = changedCommercialFields.boot_applied_date
        ? changedCommercialFields.boot_applied_date + ":00.00"
        : changedCommercialFields.boot_applied_date;
    }
    if (changedCommercialFields.collection_date) {
      payload_data.collection_date = changedCommercialFields.collection_date
        ? changedCommercialFields.collection_date + ":00.00"
        : changedCommercialFields.collection_date;
    }
    if (changedCommercialFields.adjusted_balance_date) {
      payload_data.adjusted_balance_date =
        changedCommercialFields.adjusted_balance_date
          ? changedCommercialFields.adjusted_balance_date + ":00.00"
          : payload_data.adjusted_balance_date;
    }
    if (changedCommercialFields.admin_fee_applied_date) {
      payload_data.admin_fee_applied_date =
        changedCommercialFields.admin_fee_applied_date
          ? changedCommercialFields.admin_fee_applied_date + ":00.00"
          : payload_data.admin_fee_applied_date;
    }
    if (changedCommercialFields.parking_fee_applied_date) {
      payload_data.parking_fee_applied_date =
        changedCommercialFields.parking_fee_applied_date
          ? changedCommercialFields.parking_fee_applied_date + ":00.00"
          : payload_data.parking_fee_applied_date;
    }
    if (changedCommercialFields.charge_back_applied_date) {
      payload_data.charge_back_applied_date =
        changedCommercialFields.charge_back_applied_date
          ? changedCommercialFields.charge_back_applied_date + ":00.00"
          : payload_data.charge_back_applied_date;
    }
    
    let isValid = allStringsAreBlank(commercialUpdateError)

    if (!isOCMDValidation() && showChargeBackOptions && changedCommercialFields.charge_back_fee === 0) { 
       isValid = false;
       setCommercialUpdateError({
        ...commercialUpdateError,
        charge_back_fee: `charge_back Fee must be greater than zero.`
      })
    }
    if (showNsfOptions && changedCommercialFields.nsf_amount === 0) { 
       isValid = false;
       setCommercialUpdateError({
        ...commercialUpdateError,
        nsf_amount: `nsf_amount Fee must be greater than zero.`
      })
    }
    if (changedCommercialFields.original_amount === 0) { 
       isValid = false;
       setCommercialUpdateError({
        ...commercialUpdateError,
        original_amount: `Original Amount must be greater than zero.`
      })
    }

    if (!showChargeBackOptions) {
      delete payload_data.charge_back_fee
    }
    if (!showNsfOptions) {
      delete payload_data.nsf_amount
    }

    if (changedCommercialFields.escalation_detail && changedCommercialFields.escalation_detail.length <= 0) {
      delete payload_data.escalation_detail
    }

    if (isValid) {
      initialUpdateCommercial();
    }
    async function initialUpdateCommercial() {
    setBtnLoader(true);
      try {
        const result = await dashboardService.updateCommercialAPI(payload_data);

        if (result.data.success) {
          showCitationDetails.fine_amount = payload_data.fine_amount;
          setCitationDetails({ ...showCitationDetails });
          setChangedCommercialFields({
            ticket_number: payload_data.ticket_number,
            site_id: showSideId.siteinfo,
            escalation_detail: []
          })
          handleTabChange("commercial");
          // handleTabChange("ro-address");
          setMessageSuc("Commercial updated!");
          getCitationAllDetails(ticketNumber);
          getPaymentDetails(ticketNumber);
        } else {
          setEligibleOpen(true);
          setEligibleMsg(result.data.message)
        }
        setBtnLoader(false);
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          initialUpdateCommercial();
        }
        setBtnLoader(false);
      }
    }
  };

  const slideImages = showImages && showImages.map((item) => ({
    url: item.url,
  }));

  const sliderPopupRef = useRef(null);

  useEffect(() => {
    if (sliderPopupRef) {
      sliderPopupRef?.current?.focus();
    }
  }, [showImagesModalSlider]);
  Cookies.set("filter", true)

  const handleEligible = async () => {
    try {
      setIsLoading(true);
      const res = await dashboardService.isEligibleForRefund(ticketNumber);
      let isEligibleRefund = res.data.data
      if (isEligibleRefund.is_eligible) {
        setIsEligible(false);
        setIsConfirm(true);
        setIsLoading(false);
        document.body.classList.add("modal-open");
      } else if (!isEligibleRefund.is_eligible) {
        setIsEligible(true);
        setIsLoading(false)
        document.body.classList.add("modal-open");
      }
      setData(isEligibleRefund);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleCloseconfirmationModal = () => {
    setIsEligible(false);
    document.body.classList.remove("modal-open");
  };
  const handleConfirm = () => {
    setIsEligible(false);
    setIsConfirm(true);
  };
  const handleCancelConfirm = () => {
    setIsConfirm(false)
    document.body.classList.remove("modal-open");

  }

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const editCitationHandler = (citation) => {
    const dateObject = new Date(citation?.citation_start_timestamp);
    const formattedString = dateObject.toISOString().slice(0, 16);
    setEditCitationFormChangeData({
      citation_id: citation?.id,
      ticket_no: citation?.ticket_no,
      block: citation?.location.block,
      lp_number: citation?.lp_number,
      street: citation?.location?.street,
      state: citation?.vehicle_details?.state,
      citation_start_timestamp: formattedString,
      remarks: "",
      remark_1: citation?.comment_details?.remark_1,
      remark_2: citation?.comment_details?.remark_2,
      note_1: citation?.comment_details?.note_1,
      note_2: citation?.comment_details?.note_2,
      description: citation?.violation_details?.description,
      code: citation?.violation_details.code,
    })
    setShowEditCitationModal(true)
  }
  const OnClickCancelCitation = () => {
    if (showCitationDetails.status === "Valid") {
      setCancelCitation(true);
    } else {
      setEligibleOpen(true);
      setEligibleMsg(`${changeName} is not eligible for Void`);
    }
  };
  const closeError = () => {
    setCancelCitation(false);
  };
  return (
    <>
      <ToastContainer />
      {showCitationInformation ? (
        <>
          <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
            <div className="row">
              <div className="col-12 back-button back-button-citationdetails">
                <Link
                  // to={"/ticketreporting"}
                  onClick={() => {
                    Cookies.remove("RO");
                    if (props.backButtonClick) {
                      props.backButtonClick(false);
                    }

                    navigate(-1);
                  }}
                >
                  <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                  Back
                </Link>
              </div>
            </div>

            <div className="rightpanel-box">
              <div className="row text-center">
                <div className="col-12">
                  <ul className="nav nav-tabs cstm-head-tkt">
                    <li
                      className="nav-item"
                      onClick={() => handleTabChange("citation-info")}
                    >
                      <a
                        className={
                          Cookies.get("RO") === "RO_Info"
                            ? "nav-link bg-box"
                            : "nav-link bg-box active"
                        }
                        data-toggle="tab"
                        href="#citation-info"
                      >
                        {changeTotal} Info
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#notice"
                      >
                        Notice
                      </a>
                    </li>
                    <li className="nav-item align-box">
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#audit-trail"
                      >
                        Audit Trail
                      </a>
                    </li>
                    <li
                      className="nav-item align-box"
                      onClick={() => handleTabChange("commercial")}
                    >
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#commercial"
                      >
                        Financial
                      </a>
                    </li>
                    {/* {showCitationDetails.ro_request_status !== "" && ( */}
                    <li
                      className="nav-item align-box"
                      onClick={() => handleTabChange("user-detail")}
                    >
                      <a
                        className={
                          Cookies.get("RO") === "RO_Info"
                            ? "nav-link bg-box active"
                            : "nav-link bg-box"
                        }
                        data-toggle="tab"
                        href="#ro-information"
                      >
                        User Details
                      </a>
                    </li>
                    <li
                      className="nav-item align-box"
                      onClick={() => handleTabChange("payment-detail")}
                    >
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#payment-detail"
                      >
                        Payment Detail
                      </a>
                    </li>

                    <li className="nav-item align-box">
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#plate-analysis"
                      >
                        Plate Analysis
                      </a>
                    </li>

                    <li
                      className="nav-item align-box"
                      onClick={() => handleTabChange("linked")}
                    >
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#linked"
                      >
                        Linked {changeName}
                      </a>
                    </li>
                    <li
                      className="nav-item align-box"
                      onClick={() => handleTabChange("ro-detail")}
                    >
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#ro-detail"
                      >
                        RO Detail
                      </a>

                    </li>
                    <li
                      className="nav-item align-box"
                      onClick={() => handleTabChange("citation-summary")}
                    >
                      <a
                        className="nav-link bg-box"
                        data-toggle="tab"
                        href="#citation-summary"
                      >
                        {changeName} Summary
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="rightpanale-box2 cstm-rightpanale-box2">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content">
                      <div
                        id="citation-info"
                        className={
                          Cookies.get("RO") === "RO_Info"
                            ? "tab-pane fade"
                            : "tab-pane active show"
                        }
                      >
                        <div className="tab-content">
                          <div id="Dashboard" className="tab-pane active">
                            <div className="">

                              <TicketDetailHeader
                                citationData={showCitationDetails}
                                paymentBreakdownData={paymentBreakdown}
                                registeredOwnerDetails={registeredOwnerDetails}
                              />
                              <br />

                              <div className="cstm-sus">
                                <div className="cstm-sus-left">
                                  <div className="cstm-rro col-12">

                                    {props.uiElements.includes(
                                      "BUTTON_TICKET_MANAGEMENT_SUSPEND_BUTTON"
                                    ) && (
                                        <button
                                          onClick={openModal}
                                          data-modal="suspend-modal"
                                        >
                                          {showCitationDetails.status.toLowerCase() ===
                                            "suspended"
                                            ? "Unsuspend"
                                            : "Suspend"}
                                        </button>
                                      )}
                                    {showCitationDetails.status.toLowerCase() ===
                                      "voidandreissue" ||
                                    showCitationDetails.status.toLowerCase() ===
                                      "rescind" ||
                                      showCitationDetails.status.toLowerCase() ===
                                      "cancelled" ||
                                      showCitationDetails.type.toLowerCase() ===
                                      "warning" ||
                                      showCitationDetails.status.toLowerCase() ===
                                    "uncollectable" ||
                                      showCitationDetails.status.toLowerCase() ===
                                    "excused" ||
                                      showCitationDetails.amount_due === 0
                                      ? ""
                                      : props.uiElements.includes(
                                        "BUTTON_TICKET_MANAGEMENT_APPEAL_BUTTON"
                                      ) && (
                                        <>
                                          <button
                                            onClick={openModal}
                                            data-modal="appeal-modal"
                                          >
                                            Appeal
                                          </button>
                                          {/* <button onClick={handlePayment} data-modal="pay-modal">Pay</button> */}
                                        </>
                                      )}
                                    {showCitationDetails.category !==
                                      "invoice" &&
                                      props.uiElements.includes(
                                        "BUTTON_TICKET_MANAGEMENT_HEARING_BUTTON"
                                      ) && (
                                        <button
                                          onClick={openModal}
                                          data-modal="hearing-modal"
                                        >
                                          Hearing
                                        </button>
                                      )}
                                    {props.uiElements.includes(
                                      "BUTTON_TICKET_MANAGEMENT_SUPPORT_NOTES_BUTTON"
                                    ) && (
                                        <button
                                          onClick={openModal}
                                          data-modal="support-modal"
                                        >
                                          Support Note
                                        </button>
                                      )}
                                    {showCitationDetails?.notice_detail &&
                                      showCitationDetails?.notice_detail
                                        .length > 0 &&
                                      showCitationDetails?.notice_detail.filter(
                                        (item) => item.status !== ""
                                      ).length > 0 &&
                                      props.uiElements.includes(
                                        "BUTTON_TICKET_MANAGEMENT_RESEND_NOTICE_BUTTON"
                                      ) && (
                                        <button
                                          onClick={() => {
                                            setShowResendNotice(true);
                                            setCitationInformation(false);
                                          }}
                                        >
                                          Resend Notice
                                        </button>
                                      )}
                                    <button
                                      onClick={() =>
                                        editCitationHandler(
                                          showCitationDetails
                                        )
                                      }
                                    >
                                      Edit Citation
                                    </button>
                                    {props.uiElements.includes(
                                      "BUTTON_TICKET_MANAGEMENT_VOID_TICKET_BUTTON"
                                    ) && (
                                        <button
                                          type="button"
                                          onClick={OnClickCancelCitation}
                                        >
                                          Void {changeTotal}
                                        </button>
                                      )}
                                    {props.uiElements.includes(
                                      "BUTTON_TICKET_MANAGEMENT_UPDATE_STATUS_BUTTON"
                                    ) && (<button
                                      onClick={openModal}
                                      data-modal="warrant-modal"
                                    >
                                      Update Status
                                    </button>)}
                                    {showCancelCitation && (
                                      <VoidCitationModal
                                        showSelectedId={
                                          [showCitationDetails.id]
                                        }
                                        showSelectedTicketNumber={
                                          showCitationDetails.ticket_no
                                        }
                                        closeError={closeError}
                                        setCancelCitation={setCancelCitation}
                                        backButtonClick={backButtonClick}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <CitationInfoTab
                                citationAllData={showCitationDetails}
                                imageForCitationDownload={showImages}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="notice" className="tab-pane fade">
                        <div className="row">
                          <div className="col-10 userdetail-txt1"></div>
                          <div className="col-2 text-right">
                            <button className="pay-btn">Notice</button>
                          </div>
                        </div>
                        <div className="row table-overflow">
                          <div className="col-12">
                            <table className="table-heading table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    className={`srt-tab ${sorting.key === "CreatedAt" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                      }`}
                                    onClick={() =>
                                      applySorting(
                                        "CreatedAt",
                                        !sorting.ascending,
                                        "notice"
                                      )
                                    }
                                  >
                                    date
                                  </th>
                                  <th
                                    className={`srt-tab ${sorting.key === "Email" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                      }`}
                                    onClick={() =>
                                      applySorting(
                                        "Email",
                                        !sorting.ascending,
                                        "notice"
                                      )
                                    }
                                  >
                                    Email sent to
                                  </th>
                                  <th
                                    className={`srt-tab ${sorting.key === "SentBy" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                      }`}
                                    onClick={() =>
                                      applySorting(
                                        "SentBy",
                                        !sorting.ascending,
                                        "notice"
                                      )
                                    }
                                  >
                                    Sent By
                                  </th>
                                  <th
                                    className={`srt-tab ${sorting.key === "NotificationType" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                      }`}
                                    onClick={() =>
                                      applySorting(
                                        "NotificationType",
                                        !sorting.ascending,
                                        "notice"
                                      )
                                    }
                                  >
                                    Notification Type
                                  </th>
                                  <th
                                    className={`srt-tab ${sorting.key === "Address" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                      }`}
                                    onClick={() =>
                                      applySorting(
                                        "Address",
                                        !sorting.ascending,
                                        "notice"
                                      )
                                    }
                                  >
                                    Address
                                  </th>
                                  <th>Attachments</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(
                                  showCitationDetails?.notification_audit_trail ||
                                  []
                                ).map((item, i) => (
                                  <tr key={i}>
                                    {item.CreatedAt !== "" &&
                                      item.CreatedAt !== null &&
                                      item.CreatedAt !== "null" &&
                                      item.CreatedAt !== undefined &&
                                      item.CreatedAt.substring(0, 4) !== "0000" &&
                                      item.CreatedAt.substring(0, 4) !==
                                      "0001" ? (
                                      <td>
                                        {moment(item.CreatedAt)
                                          .utc()
                                          .format("DD MMM, YYYY HH:MM:SS") ||
                                          "-"}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    )}
                                    <td>{item.Email || "-"}</td>
                                    <td>{item.SentBy || "-"}</td>
                                    <td>{item.NotificationType || "-"}</td>
                                    <td>{item.Address || "-"}</td>
                                    <td>
                                      <a
                                        data-id={item.DocLocation}
                                        onClick={(e) =>
                                          downloadAttechment(
                                            e,
                                            item.DocLocation
                                          )
                                        }
                                        href={item.DocLocation}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        Download
                                      </a>
                                      {rightClick === i && (
                                        <div className="right-copy-menu">
                                          <CopyToClipboard
                                            text={clipboardtext}
                                            onCopy={() => setRightClick(-1)}
                                          >
                                            <span>Copy Link</span>
                                          </CopyToClipboard>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {showCitationDetails?.notification_audit_trail
                              ?.length === 0 ||
                              (showCitationDetails?.notification_audit_trail ===
                                null && (
                                  <div className="text-center cstm-no-record-found">
                                    No Record Found
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade col-12"
                        id="audit-trail"
                        role="tabpanel"
                        aria-labelledby="audit-trail-tab"
                      >
                        <div className="row">
                          <TicketDetailHeader
                            citationData={showCitationDetails}
                            paymentBreakdownData={paymentBreakdown}
                            registeredOwnerDetails={registeredOwnerDetails}
                          />
                        </div>
                        <br />
                        <AuditTrailTab
                          auditTrail={showCitationDetails?.audit_trail}
                        />
                      </div>

                      <div id="commercial" className="tab-pane fade col-12">
                        <div className="row">
                          <TicketDetailHeader
                            citationData={showCitationDetails}
                            paymentBreakdownData={paymentBreakdown}
                            registeredOwnerDetails={registeredOwnerDetails}
                          />
                        </div>
                        {isOpen ? (
                          <div className="col-12 text-center">
                            <div className="spinner-border"></div>
                          </div>
                        ) : (
                          <>
                              <div className="cd-bt-only">
                                {props.uiElements.includes(
                                  "BUTTON_TICKET_MANAGEMENT_REFUND_BUTTON"
                                ) &&
                                    showCitationDetails.status.toLowerCase() ===
                                  "paid" ? (
                                  <button
                                    className="pay-btn refund-loading-only"
                                    onClick={handleEligible}
                                    // onClick={handlePayment}
                                    data-modal="pay-modal"
                                    disabled={isLoading}
                                  >
                                    {isLoading && (
                                      <div className="refund-loading-main">
                                        <span
                                          className="spinner-border"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      </div>
                                    )}
                                    Refund
                                  </button>
                                 ) : (
                                  ""
                                )} 
                                {showCitationDetails.status.toLowerCase() ===
                                  "voidandreissue" ||
                                    showCitationDetails.status.toLowerCase() ===
                                  "rescind" ||
                                    showCitationDetails.status.toLowerCase() ===
                                  "hearing" ||
                                    showCitationDetails.status.toLowerCase() ===
                                  "cancelled" ||
                                    showCitationDetails.status.toLowerCase() ===
                                  "uncollectable" ||
                                    showCitationDetails.status.toLowerCase() ===
                                  "excused"
                                  ? ""
                                  : showCitationDetails.type.toLowerCase() ===
                                      "warning" ||
                                    showCitationDetails.amount_due === 0
                                      ? ""
                                      : !isCollectionPayable() && showCitationDetails.status.toLowerCase() === 'collection' ? "" : props.uiElements.includes(
                                      "BUTTON_TICKET_MANAGEMENT_PAY_BUTTON"
                                    ) && (
                                          <button
                                            className="pay-btn"
                                            onClick={handlePayment}
                                            data-modal="pay-modal"
                                          >
                                            Pay
                                          </button>
                                        )}
                                  {commercialData?.rollback_escalation && props?.uiElements?.includes("BUTTON_TICKET_MANAGEMENT_ROLLBACK_ESCALATION") && showCitationDetails.status.toLowerCase() !==
                                  "paid" &&  (
                                  <button
                                    className="pay-btn"
                                    onClick={handleEscalationRollback}
                                  >
                                    Rollback Escalation
                                  </button>
                                )}
                              </div>
                            <CommercialTab
                              messageSuc={messageSuc}
                              commercialData={commercialData}
                              commercialUpdateData={commercialUpdateData}
                              onChangeCommercialUpdateDatesEvent={
                                onChangeCommercialUpdateDatesEvent
                              }
                              onChangeCommercialInput={onChangeCommercialInput}
                              isBtnLoader={isBtnLoader}
                              onChangeDateOfCommercial={
                                onChangeDateOfCommercial
                              }
                                onUpdateCommercial={onUpdateCommercial}
                                showCitationDetails={showCitationDetails}
                                handleCommercialLateFeeChange={handleCommercialLateFeeChange}
                                errorMessage={errorMessage}
                                commercialUpdateError={commercialUpdateError}
                                setCommercialUpdateError={setCommercialUpdateError}
                                setShowChargeBackOptions={setShowChargeBackOptions}
                                setShowNsfOptions={setShowNsfOptions}
                                showChargeBackOptions={showChargeBackOptions}
                                showNsfOptions={showNsfOptions}
                            />
                          </>
                        )}
                      </div>

                      <div
                        id="ro-information"
                        className={
                          Cookies.get("RO") === "RO_Info"
                            ? "tab-pane show active"
                            : "tab-pane fade"
                        }
                      >
                        <UserDetailsTab
                          props={props}
                          openModal={openModal}
                          dispatch={dispatch}
                          ticketNo={ticketNumber}
                        />
                      </div>

                      <div id="payment-detail" className="tab-pane fade">
                        <div className="row">
                          <TicketDetailHeader
                            citationData={showCitationDetails}
                            paymentBreakdownData={paymentBreakdown}
                            registeredOwnerDetails={registeredOwnerDetails}
                          />
                        </div>
                        <br />
                        <PaymentDetailsTab
                          paymentBreakdown={paymentBreakdown}
                          payment_detail={paymentDetail}
                          refund_detail={refundDetails}
                          citationData={showCitationDetails}
                        />
                      </div>

                      <div id="plate-analysis" className="tab-pane fade">
                        <TicketAnalyticsReport
                          plateNumber={
                            showCitationDetails?.vehicle_details?.lp_number
                          }
                          key={ticketNumber}
                        />
                      </div>

                      <div id="linked" className="tab-pane fade">
                        <div className="row">
                          <LinkedTab
                            plateData={plateData}
                            isLoading={isLoading}
                            changeName={changeName}
                            showNulldata={showNulldata}
                            noData={noData}
                            handlePayment={handlePayment}
                            setPayloadCitationAmount={setPayloadCitationAmount}
                            setPayloadCitation={setPayloadCitation}
                            totalOutStanding={totalOutStanding}
                            setTotalOutStanding={setTotalOutStanding}
                            citationList={citationList}
                            setCitationList={setCitationList}
                            selectCitationAmount={selectCitationAmount}
                            setSelectCitationAmount={setSelectCitationAmount}
                          />
                        </div>
                      </div>
                      <div id="ro-detail" className="tab-pane fade">
                        <ROAddressTab
                          ticketNo={showCitationDetails.ticket_no}
                        />
                      </div>
                      <div id="citation-summary" className="tab-pane fade">
                        <CitationSummaryTab
                          summary={summary}
                          isLoading={isLoading}
                          changeName={changeName}
                          showNulldata={showNulldata}
                          noData={noData}
                          showData={showData}
                          citationData={showCitationDetails}
                          paymentBreakdownData={paymentBreakdown}
                          downloadAttechment={downloadAttechment}
                          registeredOwnerData={registeredOwnerDetails}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SuspendModal
              closeFn={closeModal}
              open={modalOpen === "suspend-modal"}
              cite_id={showCitationDetails.id}
              status={
                showCitationDetails.status === "Suspended"
                  ? "Valid"
                  : "Suspended"
              }
            />
            <WarrantStatusModal
              closeFn={closeModal}
              open={modalOpen === "warrant-modal"}
              cite_id={showCitationDetails.id}
            />
            <ChangeAmountModal
              closeFn={closeModal}
              open={modalOpen === "changeAmount-modal"}
            />
            <HearingModal
              closeFn={closeModal}
              open={modalOpen === "hearing-modal"}
              showCitationDetails={showCitationDetails.ticket_no}
            />
            <SupportNoteModal
              closeFn={closeModal}
              open={modalOpen === "support-modal"}
              citation_ids={[showCitationDetails.id]}
              getCitationAlldetails={getCitationAllDetails}
            />
            <ROInformationModal
              closeFn={closeModal}
              open={modalOpen === "ro-modal"}
              citeId={showCitationDetails.id}
            />
            {modalOpen === "pay-modal" && (
              <div className="main-body-modal">
                <div className="main-modal">
                  <div className="cstm-close">
                    <button onClick={closeModal}>×</button>
                  </div>
                  <div className="cstm-modal-body">
                    <div className="modal-body cstm-modal-body">
                      <div className="text-center cstm-paymnet-img">
                        <img src={box1} alt="img" className="img-fluid" />
                      </div>
                      <h3 className="text-center">
                        Choose your payment method
                      </h3>
                      <p className="text-center mb-4">
                        The City Of Encinitas offers three ways to pay parking
                        {changeName.charAt(0).toLowerCase() +
                          changeName.slice(1) +
                          "s"}{" "}
                        so that you can choose the method that is most
                        convenient for you.
                      </p>
                      <div className="row">
                        <div
                          className={`${!isLazParking() ? "col-4" : "col-6"}`}
                        >
                          <button className="payment-button">Cash</button>
                        </div>
                        {!isLazParking() && (
                          <div className="col-4">
                            <button className="payment-button">Check</button>
                          </div>
                        )}
                        <div
                          className={`${!isLazParking() ? "col-4" : "col-6"}`}
                        >
                          <button className="payment-button">Cards</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isOpenPyC && (
              <div className="main-body-modal cstm-csr-pg">
                <div className="main-modal">
                  <div className="modal-content">
                    <div className="modal-body cstm-modal-body">
                      <button
                        className="cstm-payment-close"
                        onClick={closeModal}
                      >
                        ×
                      </button>
                      <img src={PayImg} alt="img" className="img-fluid" />
                      <h3>Choose your payment method</h3>
                      <div className="row">
                        <div
                          className={`${!isLazParking() ? "col-4" : "col-6"}`}
                        >
                          <button
                            className="payment-button"
                            onClick={(e) => selectPamentMethod("cash", e)}
                          >
                            Cash
                          </button>
                        </div>
                        {!isLazParking() && (
                          <div className="col-4">
                            <button
                              className="payment-button"
                              onClick={(e) => selectPamentMethod("cheque", e)}
                            >
                              Check
                            </button>
                          </div>
                        )}
                        <div
                          className={`${!isLazParking()  ? "col-4" : "col-6"}`}
                        >
                          <button
                            className="payment-button"
                            onClick={(e) => selectPamentMethod("card", e)}
                          >
                            Cards
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isEligibleOpen && (
              <ErrorModalComponent handleCloseModel={closeModal} displayMsg={isEligibleMsg} />
            )}
          </div>
        </>
      ) : showResendNotice ? (
        <ResendNotice
          noticeData={showCitationDetails?.notice_detail}
          backButtonClick={backButtonClick}
          citeId={showCitationDetails.id}
          ticketNo={showCitationDetails.ticket_no}
        />
      ) : (
        showCitationDataDisplay && (
          <CitationDetailsData backButtonClick={backButtonClick} />
        )
      )}
      {showImagesModalSlider && (
        <div
          className="main-body-modal cstm-ticket-details-pop"
          onKeyDown={(e) => {
            if (e.key === "ArrowLeft") {
              imageId === 0
                ? setImageId(slideImages.length - 1)
                : setImageId(imageId - 1);
            } else if (e.key === "ArrowRight") {
              imageId + 1 === slideImages.length
                ? setImageId(0)
                : setImageId(imageId + 1);
            }
          }}
          tabIndex={0}
          ref={sliderPopupRef}
        >
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider slides={slideImages} index={imageId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isEligible && (
        <ConfirmationModalComponent
          displayMsg={
            "Your citation is not eligible for Refund, Do you still want to apply for it?"
          }
          handleCloseModel={handleCloseconfirmationModal}
          handleConfirm={handleConfirm}
        />
      )}
      {isConfirm && <RefundModal
        ticketNumber={ticketNumber}
        handleCancelConfirm={handleCancelConfirm}
        handleSelectChange={handleSelectChange}
        selectedValue={selectedValue}
        showCitationDetails={showCitationDetails}
        data={data}
      />}
      {showEditCitationModal && <EditCitationModal setShowEditCitationModal={setShowEditCitationModal} selectedCitation={editCitationFormChangeData} slideImages={showImages} onSubmitButtonClick={backButtonClick} />}
    </>
  );
};
export default TicketDetails;
