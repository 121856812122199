
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import mainService from '../../Services/main.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';
import { refreshTokenMiddleware } from '../customMiddelware';
import PrintPdfCsvExcelDownload from '../Utilies/PrintPdfCsvExcelDownload';
//images
import transfer from '../../Images/transfer.png';
import FilterExportImg from '../../Images/export.png'
import dropdownarrow from '../../Images/dropdown-arrow.svg';
import { siteid } from '../../Global/site';


function pad2(n) {
	return (n < 10 ? '0' : '') + n;
}

const UserAccountsList = () => {
	const dispatch = useDispatch()
	const site = siteid();
	const [dataList, setDataList] = useState([])
	const [isLoader, setLoader] = useState(false);
	const [filterOption, setFilterOption] = useState({
		created_from_date: '',
		created_to_date: '',
		state: '',
		plate: '',
		email: '',
		phone_number: ''
	});
	const [stateList, setStateList] = useState([]);
	const [isFilterOpen, setFilterOpen] = useState(false);
	const [sorting, setSorting] = useState({ field: 'xyz', ascending: true })

	useEffect(() => {
		const currentDataCopy = [...dataList];

		const sortedCurrentdata = currentDataCopy.sort((a, b) => {
			return a[sorting.key].toString().localeCompare(b[sorting.key].toString());
		});

		setDataList(sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse());
	}, [sorting]);

	const applySorting = (key, ascending) => {
		setSorting({ key: key, ascending: ascending });
	}

	useEffect(() => {
		let query_string = 'site_id=' + site.siteinfo
		useracountslistfun(query_string)
		getStateList()
	}, [])

	async function useracountslistfun(query_string) {
		setLoader(true)
		try {
			const result = await mainService.useaccountsreportsListAPI(query_string)

			if (result.data.status) {
				setLoader(false)
				setDataList(result.data.data.user_plates)
			}
		} catch (err) {
			if (err.response.status === 401) {
				refreshTokenMiddleware(dispatch)
				useracountslistfun(query_string)
			}
		}
	}

	async function getStateList() {
		try {
			const result = await mainService.violationCode({ "type": "StateList", "shard": 1 })
			if (result.data.status) {
				setStateList(result.data.data[0].response)

			}

		} catch (err) {
			if (err.response.status === 401) {
				refreshTokenMiddleware(dispatch)
				getStateList()
			}
		}
	}

	function convertFilterFormatData(dt) {
		var date = new Date(dt);
		var year = date.getFullYear();
		var month = pad2(date.getMonth() + 1);
		var todayDate = pad2(date.getDate());

		var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000";

		return currentTimeInSeconds
	}
	const handleFilterChange = (e) => {
        e.preventDefault()
        setFilterOpen(!isFilterOpen)
    }
	const onChangeFilterDate = (date, name) => {
		const newFilterDate = { ...filterOption };
		if (!date) {
		  newFilterDate['created_to_date'] = "";
		  setFilterOption(newFilterDate);
		  return;
		}
		newFilterDate[name] = date;
	  const timeValue = new Date(date);
	  const hours = timeValue.getHours();
	  const minutes = timeValue.getMinutes();
		if (name === 'created_to_date') {
		  const isDateChange = !filterOption?.created_to_date || (hours == 0 && minutes == 0) || date?.getDate() !== filterOption.created_to_date?.getDate();
		  if(isDateChange){
			let formattedDate = new Date(date);
			formattedDate.setHours(23, 59, 59, 999);
			newFilterDate['created_to_date'] = formattedDate;
		  }
		  setFilterOption(newFilterDate);
		} else {
		  setFilterOption(newFilterDate);
		}
	  };

	const onchangeFilterInput = (e) => {
		setFilterOption({ ...filterOption, [e.target.name]: e.target.value })
	}
	const onChangeStateSelect = (selectedList, name) => {
		setFilterOption({ ...filterOption, [name]: selectedList })
	}

	const handleFilterSubmit = (e) => {
		e.preventDefault()
		setLoader(true)
		setDataList([])
		let query_string = 'site_id=' + site.siteinfo
		if (filterOption.created_from_date) {
			query_string += '&created_from_date=' + convertFilterFormatData(filterOption.created_from_date)
		}
		if (filterOption.created_to_date) {
			query_string += '&created_to_date=' + convertFilterFormatData(filterOption.created_to_date)
		}
		if (filterOption.state.length > 0) {
			filterOption.state.map((el) => {
				query_string += '&state=' + el.code
			})
		}
		if (filterOption.plate) {
			query_string += '&plate=' + filterOption.plate
		}
		if (filterOption.email) {
			query_string += '&email=' + filterOption.email
		}
		if (filterOption.phone_number) {
			query_string += '&phone_number=' + filterOption.phone_number
		}
		useracountslistfun(query_string)

	}

	return (
		<>
			<div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
				<div className="cstm-ticket-manage-sec">
					<div className="cstm-ticket-manage-heading">
						<h3 className="cstm-all-pg-ttl operate-ttl">User Accounts Report</h3>
					</div>

					<PrintPdfCsvExcelDownload tableId='tbl-main-download' fileName='user-accounts' pageSize='a4' />

				</div>
				<div className="report-searchbar  mb-2">
					<form>
						<div className="report-date report-mr">
							<DatePicker
								showTimeSelect
								timeIntervals={1}
								dateFormat="MM/dd/yyyy h:mmaa"
								timeFormat="HH:mm"
								placeholderText='MM/DD/YYYY HH:MM'
								autoComplete='off'
								className="cstm-pull-details schedule-date-input"
								name="created_from_date"
								selected={filterOption.created_from_date}
								onChange={date => onChangeFilterDate(date, 'created_from_date')}
							/>
							<img src={transfer} alt="transfer" className="date-transfer" />
							<DatePicker
								showTimeSelect
								timeIntervals={1}
								placeholderText='MM/DD/YYYY HH:MM'
								autoComplete='off'
								dateFormat="MM/dd/yyyy h:mmaa"
								timeFormat="HH:mm"
								className="cstm-pull-details schedule-date-input"
								name="created_to_date"
								selected={filterOption.created_to_date}
								onChange={date => onChangeFilterDate(date, 'created_to_date')}
							/>
						</div>
						<div className="report-licence select-drop-arrow report-mr">
							<Multiselect
								name="state"
								selectedValues={filterOption.state}
								displayValue="state"
								placeholder="Select State"
								onRemove={(e) => onChangeStateSelect(e, 'state')}
								onSelect={(e) => onChangeStateSelect(e, 'state')}
								options={
									(stateList.map((el) => (
										{
											state: (el.state_name) + " (" + (el.state_abbreviated) + ")",
											code: (el.state_abbreviated)
										}
									)))
								}
								showCheckbox
							/>
							<img src={dropdownarrow} alt="dropdownarrow" />
						</div>
						<div className="report-licence report-mr">
							<input name="plate" type="text" value={filterOption.plate} onChange={onchangeFilterInput} placeholder="Plate Number" />
						</div>
						<button className="filter-box report-mr" onClick={handleFilterChange}><img src={FilterExportImg} alt="FilterExportImg" className="img-fluid" /></button>
						<button className="licence-print report-mr" onClick={handleFilterSubmit}>Submit</button>
					</form>

				{isFilterOpen &&
					<div className="block-main">

						<div className="report-licence">
							<input type="text" className="block-button cstm-ct-zc" name='email' value={filterOption.email} onChange={onchangeFilterInput} placeholder='Email' />
						</div>
						<div className="report-licence">
							<input type="text" className="block-button cstm-ct-zc" name='phone_number' value={filterOption.phone_number} onChange={onchangeFilterInput} placeholder='Phone Number' />
						</div>
					</div>
				}
				</div>
				<div id="Dashboard" className="tab-pane active">
					<div className='text-center'>
						{isLoader ?
							 <div className='text-center spin-load-main'>
							 <div className="spinner-border"></div>
						   </div>
							:
							(dataList === null || dataList?.length === 0 ?
								<div className='no-record-found-main'>No Data Found</div>
								:
								<div className="ctsm-table-scroll">
									<div className="table-overflow" id="tbl-main-download">
										<table className="table-heading table table-bordered">
											<thead>
												<tr>
													<th className={`srt-tab ${sorting.key === 'created_time' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('created_time', !sorting.ascending)}>DateTime</th>
													<th className={`srt-tab ${sorting.key === 'plate' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('plate', !sorting.ascending)}>Plate</th>
													<th className={`srt-tab ${sorting.key === 'state' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('state', !sorting.ascending)}>State</th>
													<th className={`srt-tab ${sorting.key === 'phone_number' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('phone_number', !sorting.ascending)}>Phone Number</th>
													<th className={`srt-tab ${sorting.key === 'email' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('email', !sorting.ascending)}>Email</th>
													<th className={`srt-tab ${sorting.key === 'account_type' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('account_type', !sorting.ascending)}>Account Type</th>
												</tr>
											</thead>
											<tbody>
												{(dataList || []).map((item, i) => (
													<tr key={i}>
														{item.created_time.substring(0, 10) !== "0001-01-01" ?
														<td>{moment(item.created_time, 'YYYY-MM-DD').format('MM/DD/YYYY') + " " + (item.created_time).substring(11, 19)}</td>
														:
														<td>-</td>
														}
														<td>{item.plate || '-'}</td>
														<td>{item.state || '-'}</td>
														<td>{item.phone_number || '-'}</td>
														<td>{item.email || '-'}</td>
														<td>{item.account_type || '-'}</td>
													</tr>
												))
												}
											</tbody>
										</table>
									</div>
								</div>
							)
						}
					</div>

				</div>
			</div>

		</>
	)
}

export default UserAccountsList