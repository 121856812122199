import ErrorIcon from "../../Images/error-icon.png"

const ErrorModalComponent = ({handleCloseModel,displayMsg,status="error"}) => {
    return (
        <div className="main-body-modal">
        <div className="main-modal">
          <div className="cstm-close">
            <button onClick={handleCloseModel}>×</button>
          </div>
          <div className="cstm-modal-body">
            <div className="modal-body cstm-modal-body">
              {status === "error" && <div className="text-center cstm-paymnet-img">
                <img src={ErrorIcon} alt="img" className="img-fluid" />
              </div>}
              <p className="text-center mb-4">{displayMsg}</p>
            </div>
          </div>
        </div>
      </div>
    )
}
export default ErrorModalComponent;