import { useLoadScript } from "@react-google-maps/api";
import Map from "./GoogleMap";

export default function ActiveOfficerLocation() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY  
  });

  return isLoaded ? <Map /> : null;
}
