import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { refreshtokenforClassComp } from '../../actions/auth';
import DatePicker from 'react-datepicker';
import transfer from '../../Images/transfer.png';
import dropdownarrow from '../../Images/dropdown-arrow.svg';
import moment from 'moment';
import SiteService from '../../Services/flipbook.service';
import { changeticket } from '../../Global/site';
import SearchLicensePlate from '../Citation/SearchLicensePlate';
import { getFormattedDate } from '../Common/utils';

const HearingEligibleCitations = (uiElements) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [formData, setFormData] = useState({
    lp_number: '',
    citation_number: '',
    start_date: moment().subtract(30, 'days').toDate(),
    end_date: moment().toDate(),
  });
  const [isLoaded, setIsLoaded] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [data, setData] = useState([]);
  const [totalDataLengthCount, setTotalDataLengthCount] = useState(0);
  const [paginationSelectVaue, setPaginationSelectVaue] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (requestParams) => {
    try {
      const data = await SiteService.reportTicket(requestParams);
      if (data.data.length === 0) {
        setNoRecordsFound(true);
      }

      setData(data.data.data);
      setTotalDataLengthCount(data.data.length);
      setIsLoaded(true);
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        setIsLoaded(true);
      }
    }
  };

  useEffect(() => {
    const requestParams = `page=${currentPage}&limit=${paginationSelectVaue}&status=Valid&issue_ts_from=${moment(formData.start_date).toISOString()}&issue_ts_to=${moment(formData.end_date).toISOString()}`;
    fetchData(requestParams);
  }, []);

  const handleStartDateChange = (date) => {
    setFormData({ ...formData, start_date: date });
  }

  const handleEndDateChange = (date) => {
    setFormData({ ...formData, end_date: date });
  }

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      const selectedTicketNumber = data.map((item) => item.ticket_no);
      const newData = data.map((item) => ({ ...item, isChecked: true }));
      setData(newData);
      setSelectedItems(selectedTicketNumber);
    } else {
      const newData = data.map((item) => ({ ...item, isChecked: false }));
      setData(newData);
      setChecked(false);
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (ticket_no) => {
    const newData = data.map((item) => {
      if (item.ticket_no === ticket_no) {
        const isChecked = !item.isChecked;
        return { ...item, isChecked };
      }
      return item;
    });

    setData(newData);

    const selectedItems = newData.filter((item) => item.isChecked).map((item) => item.ticket_no);
    setSelectedItems(selectedItems);
    setChecked(selectedItems.length === data.length);
  };

  const handleHearingButtonClick = () => {
    navigate('/calendar', { state: { selectedItems } });
  }

  const handleSubmitButtonClick = async () => {
    setIsLoaded(false);
    setNoRecordsFound(false);
    let requestParams = `page=${currentPage}&limit=${paginationSelectVaue}&status=Valid&issue_ts_from=${moment(formData.start_date).toISOString()}&issue_ts_to=${moment(formData.end_date).toISOString()}`;

    if (formData.lp_number) {
      requestParams += `&lp_number=${formData.lp_number}`;
    }

    if (formData.citation_number) {
      requestParams += `&ticket_no=${formData.citation_number}`;
    }

    await fetchData(requestParams);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name==="lp_number"){
      newValue = SearchLicensePlate(newValue);
    }
    setFormData({ ...formData, [name]: newValue })
  }

  const handlePaginationSelect = (e) => {
    setPaginationSelectVaue(e.target.value);
  }

  return (
    <section>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
            <div className='cstm-ticket-manage-sec'>
              <div className="cstm-ticket-manage-heading">
                <h3 className="cstm-all-pg-ttl operate-ttl">Hearing Eligible Citations</h3>
              </div>
            </div>
            <div className='report-searchbar'>
              <form>
                <div className='report-date report-mr'>
                  <DatePicker
                    showTimeSelect
                    timeIntervals={1}
                    autoComplete='off'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    placeholderText='MM/DD/YYYY HH:MM'
                    timeFormat='HH:mm'
                    className='cstm-pull-details schedule-date-input'
                    name='start_date'
                    selected={formData.start_date}
                    onChange={handleStartDateChange}
                    minDate={moment().subtract(30, 'days').toDate()}
                  />
                  <img src={transfer} alt="transfer" className="date-transfer" />
                  <DatePicker
                    showTimeSelect
                    timeIntervals={1}
                    autoComplete='off'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    placeholderText='MM/DD/YYYY HH:MM'
                    timeFormat='HH:mm'
                    className='cstm-pull-details schedule-date-input'
                    name='end_date'
                    selected={formData.end_date}
                    onChange={handleEndDateChange}
                    minDate={moment().subtract(30, 'days').toDate()}
                  />
                </div>
                <div className='report-licence report-mr'>
                  <input className='licence-plate' name='lp_number' value={formData.lp_number} onChange={handleInputChange} placeholder='Licence Plate' type='text' />
                </div>
                <div className='report-licence report-mr'>
                  <input className='licence-plate' name='citation_number' value={formData.citation_number} onChange={handleInputChange} placeholder={`${changeticket()} Number`} type='text' />
                </div>
                <button onClick={handleSubmitButtonClick} className='licence-print report-mr' type='button'>Submit</button>
              </form>

              {uiElements.uiElements.includes("PAGE_HEARING_MANAGEMENT_CREATE_HEARING") && <div className='pl-cancel-citation-btns'>
                <button className="licence-print report-mr" type="button" onClick={handleHearingButtonClick}>Create Hearing</button>
              </div>}

              {!isLoaded ? <div className='text-center spin-load-main'>
                <div className="spinner-border"></div>
              </div>
                : (
                  noRecordsFound ? <div className='no-record-found-main'>No records found</div>
                    :
                    (
                      <>
                        <div className="ctsm-table-scroll">
                          <div className="table-responsive">
                            <table className="table table-heading table-bordered" id="cstm-report-tb-display">
                              <thead>
                                <tr>
                                  <th className="w-1 row-rmv">
                                    <input type="checkbox" onChange={handleSelectAll} checked={checked} id='all' />
                                  </th>
                                  <th>Ticket Issue Date Time</th>
                                  <th>Hearing Date</th>
                                  <th>Citation/Invoice Number</th>
                                  <th>Status</th>
                                  <th>Licence Plate Number</th>
                                  <th>Badge ID</th>
                                  <th>Officer Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((item, index) => (
                                  <tr key={index}>
                                    <td className="text-center">
                                      <input type="checkbox" onChange={() => handleSelectItem(item.ticket_no)} checked={item.isChecked} />
                                    </td>
                                    <td>{moment(item.citation_issue_timestamp).format('MM/DD/YYYY HH:mm')}</td>
                                    <td>{getFormattedDate(item?.hearing_date)}</td>
                                    <td>{item?.ticket_no}</td>
                                    <td>{item?.status}</td>
                                    <td>{item?.lp_number}</td>
                                    <td>{item?.officer_details?.badge_id}</td>
                                    <td>{item?.officer_details?.officer_name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {totalDataLengthCount !== 0 && noRecordsFound === false && (
                          <>
                            <div className="cstm-permit-trf">
                              <div className="col-12">
                                <div className="total-count-records">
                                  Total <b> {totalDataLengthCount} </b>{" "}
                                  Records Found{" "}
                                </div>
                              </div>
                              <ul className="cstm-pagination-added-bottom">
                                {/* {renderPrevBtn}
                                    {pageDecrementBtn}
                                    {renderPageNumbers}
                                    {pageIncrementBtn}
                                    {renderNextBtn} */}
                              </ul>
                            </div>

                            <div className="cstm-permit-trf esc-select">
                              <div className="cstm-left-lable-pn-val">Number of records per page</div>
                              <div className="cstm-right-lable-pn-val">
                                <select className="select-pagination-val" value={paginationSelectVaue} onChange={handlePaginationSelect}>
                                  <option value="100">100</option>
                                  <option value="200">200</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                </select>
                                <img src={dropdownarrow} alt="dropdownarrow" />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default connect(null, { refreshtokenforClassComp })(HearingEligibleCitations)