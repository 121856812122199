import { useEffect, useState } from "react";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useDispatch } from "react-redux";
import HearingReporting from "./HearingReporting";
import dashboardService from "../../../Services/dashboard.service";
import { changetotal } from "../../../Global/site";

const HearingReportContainer = () => {
  const dispatch = useDispatch();

  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevAndNextBtnClass, setPrevAndNextBtnClass] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [appealStatus, setAppealStatus] = useState([]);
  const changeTotal = changetotal();

  // Get Table List Api Handler
  const getHearing = async (query) => {
    setLoading(true);
    try {
      const response = await dashboardService.getHearingReport(query);
      console.log("Response data:", response.data);
      console.log("Keys of response data data:", Object.keys(response.data.data));
      console.log("Response data data:", response.data.data);

      if (response.data.status ||(response.data.data && Object.keys(response.data.data).length !== 0)) {
        setReportData(response.data.data);
        setNoRecordFound(false);
        setTotalRecords(response.data.length)
      }else {
        console.log("data")
        setNoRecordFound(true);
        // setReportData([]);
      }
      setLoading(false);
    } catch (error) {
      setNoRecordFound(true);
      console.log("data123")
      // if (error.response.status === 401) {
      //   refreshTokenMiddleware(dispatch);
      //   const passData = "page=" + currentPage + "&limit=" + itemsPerPage 
      //   getHearing(passData)
      // }
    }
    setLoading(false);
  };

  useEffect(() => {
    const passData = "page=" + currentPage + "&limit=" + itemsPerPage 
    getHearing(passData)
    getAppealStatusData()
  }, []);

  const tableHeading = [
    "Hearing Date",
    `${changeTotal} Number`,
    `${changeTotal} Issue Date`,
    "Fine Amount",
    "Ticket Status",
    "Hearing Status",
    "Plate Number",
    // "Hearing Received At ",
    "Hearing Comment",
    "Code",
    "Payment Status",
    `${changeTotal} Category`,

  ];

  const keySequence = [
    "hearing_details.date",
    "ticket_no",
    "citation_start_timestamp",
    "fine_amount",
    "status",
    "hearing_details.status",
    "lp_number",
    // "hearing_details.received_at",
    "hearing_details.comment",
    "code",
    "payment_done",
    "category",
  ];

  const handlePaginationChange = (newPage) => {
    const passData = "page=" + newPage.currentPage + "&limit=" + newPage.pageLimit 
    getHearing(passData)
    setCurrentPage(newPage.currentPage);
    setPrevAndNextBtnClass(newPage.pageLimit);
    setItemsPerPage(newPage.pageLimit);
  };
  async function getAppealStatusData() {
    try {
      const result = await dashboardService.appealStatusListAPI();
      if (result.data.status) {
        setAppealStatus(result.data.data);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealStatusData();
      }
    }
  }

  return (
    <>
      <HearingReporting
        reportData={reportData}
        loading={loading} 
        noRecordFound={noRecordFound}
        tableHeading={tableHeading}
        keySequence={keySequence}
        getHearing={getHearing}
        handlePaginationChange={handlePaginationChange}
        totalRecords={totalRecords}
        itemsPerPage={itemsPerPage}
        prevAndNextBtnClass={prevAndNextBtnClass}
        currentPage={currentPage}
        appealStatus={appealStatus}
      />
    </> 
  );
};

export default HearingReportContainer;
