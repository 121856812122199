import { useEffect, useState, createContext } from "react";
import DatePicker from "react-datepicker";
import Services from '../../Services/main.service';
import { siteid } from "../../Global/site";
import { formatDate } from "../Common/utils";
import CitationForm from './CitationForm'
import transfer from "../../Images/transfer.png";
import moment from "moment";
import Select from "react-select";
import PopUpComponent from "../Common/PopupModalComponent";
import "./citation.css";
import { connect, useDispatch } from "react-redux";
import { clearoutAuthUserData } from "../../actions/auth";
import { refreshTokenMiddleware, refreshTokenMiddlewareAsync } from "../customMiddelware";

export const ImageDataContext = createContext();

const CitationImageList = () => {
  const [images, setImages] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState([]);
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState(null);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [dropDownData, setDropDownData] = useState({});
  const [formTemplate, setFormTemplate] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [status, setStatus] = useState([]);
  const [invalidImagesData, setInvalidImagesData] = useState([]);
  const [invalidImages, setInvalidImages] = useState([]);

  const dispatch = useDispatch()
  const handleBackButton = () => {
    setShowDetailPage(false);
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const markImageInvalid = async (index) => {
    try {
      const response = await Services.markImageInvalid(images[index].originalData._id);
      if (response?.data?.success) {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);

        const newInvalidImages = [...invalidImages];
        newInvalidImages.push(images[index]);
        setInvalidImages(newInvalidImages);
      }
      setPopupData("Image marked as invalid.");
      setShowPopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        refreshTokenMiddleware(dispatch);
        markImageInvalid()
        // setPopupData(error.response?.data?.message);
      } else {
        setShowPopup(true)
        setPopupData(error?.message)
      }
    }
  }

  const fetchImages = async (params) => {
    try {
      setIsLoading(true);
      const response = await Services.getImages(params);

      if ((response?.data?.data?.length === 0 || response?.data?.data === null) && response?.data?.success) {
        setNoRecordFound(true);
        setIsLoading(false);
      }
      else {
        const validImages = response?.data?.data.filter(image => !image.is_deleted);
        const invalidImages = response?.data?.data.filter(image => image.is_deleted);
        setImagesData(validImages);
        setInvalidImagesData(invalidImages);
        setNoRecordFound(false);

        const promisedRequestArray = [];
        const invalidPromisedRequestArray = [];
        const { siteinfo } = siteid();

        validImages.forEach((image) => {
          // image.image_url = image.links[0].url
          const imageData = {
            'links': { 'url': image.imageUrl },
            'download_type': 'CitationImages',
            'site_id': siteinfo
          }

          promisedRequestArray.push({
            originalData: image,
            promise: Services.downloadImage(imageData)
          })
        })

        invalidImages.forEach((image) => {
          const imageData = {
            'links': { 'url': image.imageUrl },
            'download_type': 'CitationImages',
            'site_id': siteinfo
          }

          invalidPromisedRequestArray.push({
            originalData: image,
            promise: Services.downloadImage(imageData)
          })
        })

        const imageLinkArray = [];
        const invalidImageLinkArray = []; // Array for invalid image links

        Promise.allSettled(promisedRequestArray.map(item => item.promise))
          .then((responseArray) => {
            responseArray.forEach((response, index) => {
              if (response?.status === 'fulfilled' && response?.value?.status === 200 && response?.value?.data?.status) {
                imageLinkArray.push({
                  url: response.value.data?.metadata[0]?.url,
                  originalData: promisedRequestArray[index].originalData // include original image data
                });
              }
            })
            setImages(imageLinkArray)
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshTokenMiddleware(dispatch);
              fetchImages()
            } else {
              setShowPopup(true)
              setPopupData(error?.message)
            }
          })

        Promise.allSettled(invalidPromisedRequestArray.map(item => item.promise)) // Promise for invalid images
          .then((responseArray) => {
            responseArray.forEach((response, index) => {
              if (response?.status === 'fulfilled' && response?.value?.status === 200 && response?.value?.data?.status) {
                invalidImageLinkArray.push({ // Push to invalid image links array
                  url: response.value.data?.metadata[0]?.url,
                  originalData: invalidPromisedRequestArray[index].originalData // include original image data
                });
              }
            })
            setInvalidImages(invalidImageLinkArray)
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshTokenMiddleware(dispatch);
              fetchImages()
            } else {
              setShowPopup(true)
              setPopupData(error?.message)
            }
          })
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        refreshTokenMiddleware(dispatch);
        fetchImages();
        // setPopupData(error.response?.data?.message);
      } else {
        setShowPopup(true)
        setPopupData(error?.message)
      }
    }
  }

  const onChangeSearchStartDate = (date) => {
    setStartDate(date);
  };

  const onChangeSearchEndDate = (date) => {
    setEndDate(date);
  }

  const onClickSearchFilter = (data) => {
    const statusArray = data?.map((item) => item.value);
    setStatus(statusArray);
  }

  const onClickSearch = async () => {
    const params = {
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString(),
      status: status,
    };

    try {
      await fetchImages(params);
    } catch (error) {
      setIsLoading(false);
      setPopupData(error?.message)
    }
  }

  const onClickFormSubmit = async (data) => {
    try {
      const citationData = {
        citation_issue_timestamp: moment(data?.timestamp).toISOString(),
        citation_start_timestamp: moment(data?.timestamp).toISOString(),
        code: data?.code ?? "",
        comment_details: {
          note_1: "",
          note_2: "",
          remark_1: data?.remark_1 ?? "",
          remark_2: "",
        },
        header_details: {
          citation_number: Number(data?.citation_number) ?? "",
          timestamp: moment(data?.timestamp).toISOString(),
        },
        image_urls: [selectedImage?.originalData?.imageUrl],
        reissue: false,
        time_limit_enforcement: false,
        location_details: {
          block: data?.block ?? "",
          direction: "",
          lot: data?.lot ?? "",
          space_id: data?.space ?? "",
          meter: "",
          side: "",
          street: data?.street ?? "",
        },
        lp_number: data?.lp_number ?? "",
        notes: "",
        officer_details: {
          badge_id: data?.badge_id ?? "",
          officer_name: data?.officer_name ?? "",
          agency: data?.agency ?? "",
        },
        status: "Valid",
        ticket_no: (data?.citation_number).toString() ?? "",
        time_limit_enforcement_id: "",
        time_limit_enforcement_observed_time: "",
        type: data?.type ?? "",
        category: 'manual_ticket',
        vehicle_details: {
          color: data?.color ?? "",
          make: data?.make ?? "",
          model: data?.model ?? "",
          state: data?.state ?? "",
          lp_number: data?.lp_number ?? "",
        },
        violation_details: {
          code: data?.code ?? "",
          description: data?.description ?? "",
          violation_type: "",
          due_15_days: 0.0,
          due_30_days: 0.0,
          due_45_days: 0.0,
          fine: parseFloat(data?.fine) ?? 0.0,
        },
        hearing_details: data?.Hearing_Date ? {
          date: data.Hearing_Date,
          comment: "Hearing scheduled by admin"
        } : null,
        hearing_date: data?.Hearing_Date ? moment(data.Hearing_Date).format("MM/DD/YYYY h:mmA") : null
      };

      const resp = await Services.issueTicket(citationData);
      if (resp?.data?.success) {
        await Services.markImageProcessed(selectedImage.originalData._id, "closed");
        const newImages = [...images];
        const index = newImages.findIndex((image) => image.originalData._id === selectedImage.originalData._id);
        newImages.splice(index, 1);
        setImages(newImages);
      }
      setShowDetailPage(false);
      setPopupData("Citation created successfully!");
      setShowPopup(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        await refreshTokenMiddlewareAsync(dispatch);
        await onClickFormSubmit(data)
      }else {
        setPopupData(error?.response?.data?.error);
        setShowPopup(true);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDropDownData = async () => {
      try {
        const paramsStateList = {
          type: "StateList",
          shard: 1,
        };
        const paramsColorList = {
          type: "CarColorList",
          shard: 1,
        };
        const paramsMakeList = {
          type: "MakeModelList",
          shard: 1
        };

        const offcerParams = {
          type: "officers",
          shard: 1
        };

        const violationParams = {
          type: "ViolationList",
          shard: 1
        };

        const remarksParams = {
          type: "RemarksList",
          shard: 1,
        };

        const streetParams = {
          type: "StreetList",
          shard: 1,
        };

        const AgencyParams = {
          type: "AgencyList",
          shard: 1,
        }

        const LotParams = {
          type: "LotList",
          shard: 1,
        }

        const promisedRequestArray = [];
        promisedRequestArray.push(
          Services.getDataForDropDown(paramsStateList),
          Services.getDataForDropDown(paramsColorList),
          Services.getDataForDropDown(paramsMakeList),
          Services.getDataForDropDown(offcerParams),
          Services.getDataForDropDown(violationParams),
          Services.getDataForDropDown(remarksParams),
          Services.getDataForDropDown(streetParams),
          Services.getDataForDropDown(AgencyParams),
          Services.getDataForDropDown(LotParams)
        );

        Promise.allSettled(promisedRequestArray.map(item => item))
          .then((responseArray) => {
            const dropDownData = {};
            responseArray.forEach((response) => {
              if (response?.status === 'fulfilled' && response?.value?.status === 200 && response?.value?.data?.status) {
                dropDownData[response?.value?.data?.data[0]?.metadata?.type] = response?.value?.data?.data[0]?.response;
              }
            }
            )
            setDropDownData(dropDownData)
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshTokenMiddleware(dispatch);
              getDropDownData()
            } else {
              setPopupData(error?.message)
              setShowPopup(true)
            }
          })
      } catch (error) {
        if (error?.response?.status === 401) {
          refreshTokenMiddleware(dispatch);
          getDropDownData()
        }
        setIsLoading(false);
        console.log(error);
      }
    }

    const getFormTemplate = async () => {
      try {
        setIsLoading(true);
        const response = await Services.getTemplateData();
        if (response?.data?.status) {
          setFormTemplate(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          refreshTokenMiddleware(dispatch);
          getFormTemplate()
        } else {
          setIsLoading(false);
          setPopupData(error?.message)
          setShowPopup(true)
        }
      }
    }

    const fetchData = async () => {
      await fetchImages({});
      getFormTemplate();
      getDropDownData();
    };

    fetchData();
  }, [dispatch]);

  const handleMouseMove = (e) => {
    const { currentTarget } = e;
    currentTarget.style.transform = `scale(1.1)`;
    currentTarget.style.boxShadow = `0px 0px 20px rgba(0,0,0,0.5)`;
  }

  const handleMouseLeave = (e) => {
    const { currentTarget } = e;
    currentTarget.style.transform = `rotateY(0deg) rotateX(0deg)`;
  }

  return (
    <>
      {!showDetailPage ?
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                <div className="row cstm-ticket-manage-sec">
                  <div className="cstm-ticket-manage-heading">
                    <h3 className="cstm-all-pg-ttl operate-ttl">Citation Image List</h3>
                  </div>
                </div>
                <div className="report-searchbar">
                  <form>
                    <div className="report-date report-mr">
                      <DatePicker
                        showTimeSelect
                        autoComplete='off'
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy h:mmaa"
                        placeholderText='MM/DD/YYYY HH:MM'
                        timeFormat="HH:mm"
                        className="cstm-pull-details schedule-date-input"
                        name="start_date"
                        selected={startDate}
                        onChange={date => onChangeSearchStartDate(date)}
                      />
                      <img src={transfer} alt="transfer" className="date-transfer" />

                      <DatePicker
                        showTimeSelect
                        timeIntervals={1}
                        autoComplete='off'
                        dateFormat="MM/dd/yyyy h:mmaa"
                        placeholderText='MM/DD/YYYY HH:MM'
                        timeFormat="HH:mm"
                        className="cstm-pull-details schedule-date-input"
                        name="end_date"
                        selected={endDate}
                        onChange={date => onChangeSearchEndDate(date)}
                      />
                    </div>
                    <div className="report-licence select-drop-arrow report-mr cstm-state-only" style={{ width: '30%' }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select Status"
                        isClearable={true}
                        isSearchable={true}
                        isMulti={true}
                        name="status"
                        options={[
                          { value: "open", label: "Open" },
                          { value: "closed", label: "Closed" },
                        ]}
                        onChange={(e) => onClickSearchFilter(e)}
                      />
                    </div>
                    <button className="licence-print report-mr" type="button" onClick={() => onClickSearch()}>Search</button>
                  </form>
                </div>
                {isLoading ?
                  <div className="text-center spin-load-main">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  : (noRecordFound ?
                    <div className="no-record-found-main">No Record Found</div>
                    :
                    <div className="report-table cstm-report-table-ticketreporting">
                      <div className="row-icons">
                        {images.map((image, index) => (
                          // display the images in a grid with 4 images in a row
                          <div className="column-icons" key={index}>
                            <div className="cstm-img-sec">
                              <div className="cstm-img-sec-block">
                                <div className="img-container" >
                                  <img src={image.url} alt="img" className="img-fluid image-icon " onClick={() => {
                                    setSelectedImage(image)
                                    setShowDetailPage(true)
                                    const selectedImageData = imagesData.find((item) => item._id === image.originalData._id);
                                    setSelectedImageData(selectedImageData);
                                  }}
                                    onMouseMove={e => handleMouseMove(e)}
                                    onMouseLeave={e => handleMouseLeave(e)}
                                    style={{ transition: 'transform 0.5s ease-out, box-shadow 0.5s ease-out' }}
                                  />
                                </div>
                                <div className="upload-date">Upload date: {formatDate(image.originalData.upload_timestamp)}</div>
                                <button className="cstm-allsite-btn" onClick={() => markImageInvalid(index)}>Mark Invalid</button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <h4 className="cstm-invalid-heading">Invalid Images</h4>
                      <div className="row-icons">
                        {invalidImages.map((image, index) => (
                          // display the images in a grid with 4 images in a row
                          <div className="column-icons" key={index}>
                            <div className="cstm-img-sec">
                              <div className="cstm-img-sec-block">
                                <div className="img-container" >
                                  <img src={image.url} alt="img" className="img-fluid image-icon" onClick={() => {
                                    setSelectedImage(image)
                                    setShowDetailPage(true)
                                    const selectedImageData = invalidImagesData.find((item) => item._id === image.originalData._id);
                                    setSelectedImageData(selectedImageData);
                                  }}
                                    onMouseMove={e => handleMouseMove(e)}
                                    onMouseLeave={e => handleMouseLeave(e)}
                                    style={{ transition: 'transform 0.5s ease-out, box-shadow 0.5s ease-out' }}
                                  />
                                </div>
                                <div className="upload-date">Upload date: {formatDate(image.upload_timestamp)}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </section>
        :
        (Object.keys(dropDownData).length > 0) && <ImageDataContext.Provider value={selectedImageData}>
          <CitationForm image={selectedImage} imageData={selectedImageData} handleBackButton={handleBackButton} dropDownData={dropDownData} formTemplateData={formTemplate} onClickFormSubmit={onClickFormSubmit} showBackButton={true} className={true} />
        </ImageDataContext.Provider>
      }

      {
        showPopup ?
          <>
            <PopUpComponent message={popupData} handleModalClose={closePopup} />
          </>
          :
          ""
      }
    </>
  );
};

export default connect(null, { clearoutAuthUserData })(CitationImageList);