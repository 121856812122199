import {getFormattedDate} from '../../Common/utils';

const PurchasePermitAuditTrailTab = ({auditTrail}) => {
  return !auditTrail || auditTrail.length === 0 ? (
    <div className="text-center">No Audit Trail Available</div>
  ) : (
    auditTrail?.map((trail, index) => (
      <div key={index}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12">
                    <div className="text-left">
                      <h6 className="card-subtitle mb-2 text-muted pt-3">
                        {getFormattedDate(trail?.timestamp)}
                      </h6>
                      <h6 className="card-subtitle mb-2 text-muted">
                        {trail?.initiator_name} ({trail?.initiator_role})
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body mb-2">
                <div className="row">
                  <div className="col-6">
                    <h6 className="card-subtitle mb-4 text-muted">
                      <strong>Remarks:</strong> <p>{trail?.reason}</p>
                    </h6>
                  </div>
                  <div className="col-6">
                    <h6 className="card-subtitle mb-4 text-muted">
                      <strong>Old Value:</strong> <p>{trail?.old_value}</p>
                    </h6>
                    <h6 className="card-subtitle mb-2 text-muted">
                      <strong>New Value:</strong>
                      <p>{trail?.new_value}</p>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    ))
  );
};

export default PurchasePermitAuditTrailTab;
