export async function encryptData(data) {
  try {
    const publicKey = 'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA4dUBJZO5g+M3R4Kg/Xm+BOR5yCeUiD6ceUfNOyT9Ottl6926FNkzEyqMSAc0EV10Ux8QrnS/YfBTFjN/4K14FPMcgtLUBPMwkhNlJMIG5VYwZ3FmBM+e4UCVGxh3Ry/0EFb2HsXB5f+wvj2AGutOA1Ju6GYzk6mmDmlImf6+cwaq6baeo3dkZvuaTz2vfz9GgLBoMQNiK8QgsGgRg3TlS8cnLl89pz55HYcjlGferOHNA/53aBpNFxFnb2EIia6xmm3EUhUPvps3+AloVvr7wwWbk/gNATsiQZ+Jxq2Ng0NBBVlGHMSMeO9FHdd4MpdiRGiypsEV/r5WyNsV+ui/FiS6/33S8VmYPvM8+WxQmpTN4G6RFeQtZSCoO5b8PqGodlSONonzo8zmDi9MxftYWPZHcNU+wvII3Sj3evvQKifplJMUaizjAA3OEJGA3SDsQXu9WScOkPJBGEuG1/oS4P0+kgLqV0Ut+iYpuOrAMjiS0vaN4WagRJdbjnW8zZUz4R3IDoF9jmmUVV5YVn6i9Rv3tickBFAA9lYMNJSOzXcnTIq9bYz75Rp3rVxjlbOY3pCHCg/pxetTtF8mooLeMV3YS+NkxfmHXzVxzImkOSEOCRLLTjRVqzYaiO0cnneSBOonT4FkuE3OS81o1/9l22uWWKHyhx4idQUjfLl5pG8CAwEAAQ=='

    const binaryDer = Uint8Array.from(atob(publicKey), (c) => c.charCodeAt(0));
    // Generate a random AES-GCM key
    const aesKey = await window.crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );

    // Convert data to ArrayBuffer
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(JSON.stringify(data));

    // Generate a random initialization vector
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const ivStr = arrayBufferToBase64(iv);

    // Encrypt the data with AES-GCM
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      aesKey,
      dataBuffer
    );

    let encryptedDataStr = arrayBufferToBase64(encryptedData);

    // Export the AES key to raw format
    const rawKeyNew = await window.crypto.subtle.exportKey("raw", aesKey);

    const keyAndIvObject = {
      key: arrayBufferToBase64(rawKeyNew),
      iv: ivStr,
    };

    const keyAndIvStr = JSON.stringify(keyAndIvObject);
    const keyAndIv = new TextEncoder().encode(keyAndIvStr);

    // Import the RSA public key
    const rsaKey = await window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-512" },
      },
      false,
      ["encrypt"]
    );
    // Encrypt the AES key with RSA-OAEP
    const encryptedKey = await window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      rsaKey,
      keyAndIv
    );


    let encryptedKeyStr = arrayBufferToBase64(encryptedKey);

    return {
      encryptedData: encryptedDataStr,
      encryptedKey: encryptedKeyStr,
    };
  } catch (error) {
    console.error('Error encrypting data:', error);
    return null;
  }

}

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// a card masking function that takes a card number and returns a masked card number with last 4 digits visible
export function maskCardNumber(cardNumber) {
  const maskedCardNumber = cardNumber.replace(/\d(?=\d{4})/g, "X");
  return maskedCardNumber;
}