import React, { useState } from 'react'
import { getFormattedDate } from '../Common/utils';
import { siteid } from '../../Global/site';
import { refreshTokenMiddleware } from '../customMiddelware';
import { useDispatch } from 'react-redux';
import dashboardService from '../../Services/dashboard.service';
import CopyToClipboard from 'react-copy-to-clipboard';

const SupportNotesTable = ({citationAllData}) => {
    const [clipboardtext, setClipboardText] = useState("");
    const [rightClick, setRightClick] =useState(-1);
    const showSideId = siteid();
    const dispatch=useDispatch()

    const downloadAttechment = async (e, i) => {
        e.preventDefault();
        let url = "";
    
        const imageData = {
          links: Object.assign({}, { 0: e.target.dataset.id }),
          download_type: "CitationImages",
          site_id: showSideId.siteinfo,
        };
    
        try {
          const responseImage = await dashboardService.downloadImage(imageData);
          if (responseImage.data) {
            url = responseImage.data.metadata[0].url;
            setClipboardText(url);
          }
        } catch (err) {
          if (err.response && err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            return downloadAttechment(e, i);
          }
        }
    
        if (e.nativeEvent.button === 2) {
          setRightClick(i);
        } else {
          if (url) {
            // Create an anchor element
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.target = "_blank"
            anchor.download = "attachment";
    
            // Trigger a click event on the anchor element
            document.body.appendChild(anchor);
            anchor.click();
                document.body.removeChild(anchor);
    
            setRightClick(-1);
          } else {
            console.error("Download URL not available.");
          }
        }
      };
  return (
    <div>
            <>
              <div>
              <table className="table-heading table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Username</th>
                      <th style={{ width: "20%" }}>Created At</th>
                      <th>Note</th>
                      <th>Attachment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {citationAllData &&
                      citationAllData.notes &&
                      citationAllData.notes.map((ele, i) => {
                        return (
                          <tr>
                            <td>
                              {ele.user_name && ele.user_name.trim() !== ""
                                ? ele.user_name
                                : "-"}
                            </td>
                            <td>
                              {ele.created_at
                                ? getFormattedDate(ele.created_at)
                                : "-"}
                            </td>
                            <td
                              style={{
                                whiteSpace: "wrap",
                              }}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    ele && ele.note ?
                                    ele.note: "-",
                                }}
                              />
                            </td>
                            <td>
                              { ele.images && ele.images.length > 0 ? <a
                                        data-id={ele.images}
                                        onContextMenu={(e) =>
                                          downloadAttechment(e, i)
                                        }
                                        onClick={(e) =>
                                          downloadAttechment(e, i)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={ele.images}
                                      >
                                        Download
                                      </a>:"-"}
                                      {rightClick === i && (
                                        <div className="right-copy-menu">
                                          <CopyToClipboard
                                            text={clipboardtext}
                                            onCopy={() => setRightClick(-1)}
                                          >
                                            <span>Copy Link</span>
                                          </CopyToClipboard>
                                        </div>
                                      )}
                                    </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
    </div>
  )
}

export default SupportNotesTable
