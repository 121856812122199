import React, { useState, useEffect } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import Cookiesremove from '../../../Cookiesremove';
import { changetotal, changeticket } from '../../../Global/site';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

const options = {
  scaleShowGridLines: true,
  scaleGridLineColor: 'rgba(0,0,0,.05)',
  scaleGridLineWidth: 1,
  scaleShowHorizontalLines: true,
  scaleShowVerticalLines: true,
  bezierCurve: true,
  bezierCurveTension: 0.4,
  pointDot: true,
  pointDotRadius: 4,
  pointDotStrokeWidth: 1,
  pointHitDetectionRadius: 20,
  datasetStroke: true,
  datasetStrokeWidth: 2,
  datasetFill: true,
  legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',
}

const styles = {
  graphContainer: {
    // border: '1px solid black',
    // padding: '15%'
  }
}


function LineChartOperation()   {
    const changeTotal = changetotal();

    const [count, setCount] = React.useState(0);
    const [showTotalCitation, setTotalCitation] = React.useState([]);
    const [showTotalScan, setTotalScan] = React.useState([]);
    const [showTotalCancel, setTotalCancel] = React.useState([]);
    const [showTotalReissue, setTotalReissue] = React.useState([]);
    const [showTimingRecord, setTimingRecord] = React.useState([]);
    const [showTotalScofflaw, setTotalScofflaw] = React.useState([]);
    const [showXaxisValue, setXaxisValue] = React.useState([]); 
    const [showAuthToken, setAuthToken] = useState(false);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
    const [showTodayButton, setTodayButton] = useState(true)
    const [showMonthButton, setMonthButton] = useState(false)
    const [showYearButton, setYearButton] = useState(false)
    const changeName = changeticket();

    const closeError = () =>{
        setErrorHandling(false)
      }
    
    var chartData = {
        labels:  showXaxisValue,
        datasets: [
          {
            label: changeTotal,
            fillColor: 'rgba(220,220,220,0.2)',
            borderColor:  "#FFB34D", 
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            radius: 2,
            pointHighlightStroke: 'rgba(220,220,220,1)', 
            data: showTotalCitation,
          },
          {
            label: 'Scans',
            radius: 2, 
            borderColor:  "#044271",
            pointColor: 'rgba(151,187,205,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(151,187,205,1)',
            data: showTotalScan,
          },
          {
            label: 'Cancel', 
            borderColor:  "#FA5944",
            pointColor: 'red',
            radius: 2,
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(151,187,205,1)',
            data:  showTotalCancel,
          },
          {
            label: 'Reissue', 
            borderColor:  "#029279",
            radius: 2,
            pointColor: 'rgba(151,187,205,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(151,187,205,1)',
            data: showTotalReissue,
          },
          {
            label: 'Timing', 
            borderColor:  "#629BDD",
            radius: 2,
            pointColor: 'rgba(151,187,205,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(151,187,205,1)',
            data: showTimingRecord,
          },
          {
            label: 'Scofflaw', 
            borderColor:  "#9A0F73",
            radius: 2,
            pointColor: 'rgba(151,187,205,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(151,187,205,1)',
            data: showTotalScofflaw,
          },
        ]
      } 

      const onClickLogin = (e) =>
    {
      e.preventDefault();
    Cookiesremove();
      window.location.href = "/";
    }

      const onChangeYear = (e) => {

        setTodayButton(false)
        setMonthButton(false)
        setYearButton(true)
        
        const retriveData = {
            "count_array_timeline": {
                "count_type": "annually"
            }
        }
         
        Dashboard.getTodayCount(retriveData).then(
            (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                    if(response.data.status === false){
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    }else{
                        setTotalCitation(response.data.data[0].metadata.graphs.CitationData)
                        setTotalScan(response.data.data[0].metadata.graphs.ScanData)
                        setTotalCancel(response.data.data[0].metadata.graphs.CancelData)
                        setTotalReissue(response.data.data[0].metadata.graphs.ReissueData)
                        setTimingRecord(response.data.data[0].metadata.graphs.TimingData)
                        setTotalScofflaw(response.data.data[0].metadata.graphs.ScofflawData)
                        setXaxisValue(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug', 'Sept', 'Oct', 'Nov', 'Dec'])
                    }
                }
            }
        )
    }
      const onChangeMonth = (e) => { 

        setTodayButton(false)
        setMonthButton(true)
        setYearButton(false)

        const retriveData =  {
            "count_array_timeline": {
                "count_type": "monthly"
            }
        }
         
        Dashboard.getTodayCount(retriveData).then(
            (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                    if(response.data.status === false){
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    }else{
                        
                        setTotalCitation(response.data.data[0].metadata.graphs.CitationData)
                        setTotalScan(response.data.data[0].metadata.graphs.ScanData)
                        setTotalCancel(response.data.data[0].metadata.graphs.CancelData)
                        setTotalReissue(response.data.data[0].metadata.graphs.ReissueData)
                        setTimingRecord(response.data.data[0].metadata.graphs.TimingData)
                        setTotalScofflaw(response.data.data[0].metadata.graphs.ScofflawData)
                        setXaxisValue(['01', '02', '03', '04', '05', '06', '07','08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24','25', '26', '27', '28', '29', '30', '31'])
                    }
                }
            }
        )
    }

    const OnTodayChart = () => {

        setTodayButton(true)
        setMonthButton(false)
        setYearButton(false)

        const retriveData = {
            "count_array_timeline": {
                "count_type": "daily"
            }
        }
         
        Dashboard.getTodayCount(retriveData).then(
            (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                    if(response.data.status === false){
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    }else{
                        setTotalCitation(response.data.data[0].metadata.graphs.CitationData)
                        setTotalScan(response.data.data[0].metadata.graphs.ScanData)
                        setTotalCancel(response.data.data[0].metadata.graphs.CancelData)
                        setTotalReissue(response.data.data[0].metadata.graphs.ReissueData)
                        setTimingRecord(response.data.data[0].metadata.graphs.TimingData)
                        setTotalScofflaw(response.data.data[0].metadata.graphs.ScofflawData)
                        setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                    }
                }
            }
        ) 
    }
  
        useEffect(() => {  
       
            const retriveData = {
                "count_array_timeline": {
                    "count_type": "daily"
                }
            }
             
            Dashboard.getTodayCount(retriveData).then(
                (response) => {
                    if(response.data.status === 300) {
                        setAuthToken(true) 
                     }else{
                        if(response.data.status === false){
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        }else{
                            setTotalCitation(response.data.data[0].metadata.graphs.CitationData)
                            setTotalScan(response.data.data[0].metadata.graphs.ScanData)
                            setTotalCancel(response.data.data[0].metadata.graphs.CancelData)
                            setTotalReissue(response.data.data[0].metadata.graphs.ReissueData)
                            setTimingRecord(response.data.data[0].metadata.graphs.TimingData)
                            setTotalScofflaw(response.data.data[0].metadata.graphs.ScofflawData)
                            setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                        }
                    }
                }
            )
            setCount(100);
           }, []);

       
    return (
        <>
     <div className='col-12'>
     <div className="cstm-operation-summary-top">
			      <div className="cstm-operation-summary-01"><a href="#">Operation Summary</a></div>
				  <div className="cstm-operation-summary-02">
				        <div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#FFB34D"}}></span>
							<span className="cstm-os-name">{changeName + "s"}</span>
						</div>
						 <div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#044271"}}></span>
							<span className="cstm-os-name">Scans</span>
						</div>
						 <div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#FA5944"}}></span>
							<span className="cstm-os-name">Cancel</span>
						</div>
						 <div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#029279"}}></span>
							<span className="cstm-os-name">Reissue</span>
						</div>
						<div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#629BDD"}}></span>
							<span className="cstm-os-name">Timing</span>
						</div>
						 <div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#9A0F73"}}></span>
							<span className="cstm-os-name">Scofflaw</span>
						</div>
						 <div className="cstm-operation-summary-02-box"> 
						    <span className="cstm-os-color" style={{background: "#5D9A0F"}}></span>
							<span className="cstm-os-name">Payment</span>
						</div>
				  </div>
				  
				  <div className="cstm-operation-summary-03">  
					<div className="cstm-os-in1">
                        <button className={`cstm-om-today ${showTodayButton ? 'active' : ''}`} name="today" onClick={OnTodayChart}>Today</button>
                        </div>

                    <div className="cstm-os-in1">
					  <button className={`cstm-om-today ${showMonthButton ? 'active' : ''}`} name="monthly" onClick={onChangeMonth}>Monthly</button>
					</div>

					<div className="cstm-os-in1">
					  <button className={`cstm-om-today ${showYearButton ? 'active' : ''}`} name="yearly" onClick={onChangeYear}>Yearly</button>
					</div>  
				  </div>
				  
				  
			   </div>
     </div>
     <div className='col-12'>
     <div className="cstm-operation-summary-bottom">
        <div style={styles.graphContainer}>
            <LineChart data={chartData}
            options={options}
            width="500" height="150"/>
        </div>
        </div>
     </div>
       

        </>
        
        )
 
}

export default LineChartOperation;