import { useState } from "react";
import ConfirmationModalComponent from "../modals/confirmationModal";
import PaymentContactInfoForm from "./paymentContactInfoFomr";
import VehicelDetailForm from "./vehicelDetailForm";
import Cookies from "js-cookie"
import ErrorModalComponent from "../modals/errorModal";

const PaymentContactFormContainer = ({
  isBtnLoader,
  inputError,
  inputValue,
  doPayment,
  setInputError,
  setInputValue,
  children,
  setCardError,
  cardData,
  cardError,
  list,
  card,
  isModify = false,
  modifyAmountHandler,
  isModifyBtnLoader,
  uiElements
}) => {

  const [displayMsg, setDisplayMsg] = useState("");
  const [showPartialAmountConfirm, setShowPartialAmountConfirm] = useState(false);
  const [isModified, setIsModified] = useState(false)
  const [showModifyAmount, setShowModifyAmount] = useState(false)

  const reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
  const handlePayment = (e, isModify) => {
    e.preventDefault();

    let isValid = false;
    Object.entries(inputValue).forEach(([key, value]) => {
      if (cardData) {
          if (
            key !== "country" &&
            key !== "amount" &&
            key !== "citation_detail" &&
            key !== "card_number" &&
            key !== "expiry" &&
            key !== "cvc" &&
            key !== "ownership_type" &&
            key !== "owned_till" &&
            key !== "owned_from" &&
            key !== "email_notification" &&
            key !== "sms_notification" && 
            key !== "email"
          ) {
             if (!value) {
              isValid = true;
              inputError[key] = `Enter ${key.replace(/_/g, " ")}`;
            }
          }
        
      }  else {
        
          if (
            key !== "ownership_type" &&
            key !== "owned_till" &&
            key !== "owned_from" &&
            key !== "email_notification" &&
            key !== "sms_notification" &&
            key !== "email" &&
            key !== "phone_number" &&
            key !== "payment_date"
          ) {
          if (!value) {
              isValid = true;
              inputError[key] = `Enter ${key.replace(/_/g, " ")}`;
            }
          }
        }
        
    });

    if (inputValue.phone_number && inputValue.phone_number.length !== 10) {
      isValid = true;
      inputError.phone_number = "Phone Number should be of 10 Digits.";
    }
    if (inputValue.email && !reg.test(inputValue.email)) {
      isValid = true;
      inputError.email = "Invalid email.";
    }
    if (inputValue.payment_date==null && inputValue.source.value === "Historical Payment") {
      isValid = true;
      inputError.payment_date = "Enter payment date";
    }
    setInputError({ ...inputError });

    if (cardData) {
      Object.entries(cardData).forEach(([key, value]) => {
          if (value === "") {
            isValid = true;
            cardError[key] = `Enter ${key.replace(/_/g, " ")}`;
          }
      });
      setCardError({ ...cardError });

      Object.entries(cardError).forEach(([key, value]) => {
        if (
          (typeof value === "string" || value instanceof String) &&
          value !== ""
        ) {
          isValid = true;
        }
      });
    }

    const modifiedData = inputValue.citation_detail.filter(item => item.amount_due !== item.original_amount)


    if (!isValid) {
      if (isModified && modifiedData && modifiedData.length > 0) {
        if (isModify === 'modify') {
          setShowModifyAmount(true);
          setDisplayMsg(<>
            You are making a partial payment for citation number. {" "}
            {modifiedData.map(itm => itm.citation_id).join(', ')} <br /> <br />

            Please note that discounts are not available for partial payments.
          </>)
        } else {
          setShowPartialAmountConfirm(true);
          setDisplayMsg(<>
            You have modified the amount for following citations. {" "}
            {modifiedData.map(itm => itm.citation_id).join(', ')} <br /> <br />
  
            Are you sure you want to continue?
          </>)
        }
      } else {
        if (isModify === 'modify') {
              modifyAmountHandler()
            } else {
              doPayment();
            }
      }
      }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputError({ ...inputError, [name]: "" });

    setInputValue({ ...inputValue, [name]: value });
  };

  const handleOptionChange = (option, type) => {
    setInputValue({ ...inputValue, [type]: option });
    if (!option) {
      setInputError({ ...inputError, [type]: "Select state." });
    } else {
      setInputError({ ...inputError, [type]: "" });
    }
  };
  const onChangeDate = (date, name) => {
    setInputError({ ...inputError, [name]: "" });
    setInputValue({ ...inputValue, [name]: date });
  };

  function pad2(n) {
    return (n < 10 ? "0" : "") + n;
  }

  const dateFormatUTC = (dates) => {
    var date = new Date(dates);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";
    return currentTimeInSeconds;
  };

  // handle change function
  const handleMoreChange = (type, event) => {
    if (type === "owned_till" || type === "owned_from") {
      let newFormValues = { ...inputValue };
      newFormValues[type] = dateFormatUTC(event);
      setInputValue(newFormValues);
    } else if (type === "email_notification" || type === "sms_notification") {
      let newFormValues = { ...inputValue };
      newFormValues[event.target.name] = event.target.checked;
      setInputValue(newFormValues);
    } else if (type === "ownership_type") {
      let newFormValues = { ...inputValue };
      newFormValues[type] = event.target.value;
      setInputValue(newFormValues);
    } else {
      const { name, value } = event.target;
      let newFormValues = { ...inputValue };
      newFormValues[name] = value;
      setInputValue(newFormValues);
    }
  };

  const handleCloseModel = () => {
    setShowPartialAmountConfirm(false);
    setShowModifyAmount(false)
  };

  
  const handleConfirm = () => {
    setShowPartialAmountConfirm(false);
    doPayment();
  }

  return (
    <>
      <PaymentContactInfoForm
        isBtnLoader={isBtnLoader}
        handleInputChange={handleInputChange}
        handleOptionChange={handleOptionChange}
        handlePayment={handlePayment}
        inputError={inputError}
        inputValue={inputValue}
        payComponent={children}
        list={list}
        card={card}
        cardData={cardData}
        onChangeDate={onChangeDate}
        setInputValue={setInputValue}
        isModify={isModify}
        isModifyBtnLoader={isModifyBtnLoader}
        uiElements={uiElements}
        setIsModified={setIsModified}
    >
      <VehicelDetailForm
        handleMoreChange={handleMoreChange}
        element={inputValue}
        index={0}
      />
      </PaymentContactInfoForm>
      
      {showPartialAmountConfirm && <ConfirmationModalComponent handleCloseModel={handleCloseModel} handleConfirm={handleConfirm} displayMsg={displayMsg} />}

      {showModifyAmount && <ErrorModalComponent
            handleCloseModel={handleCloseModel}
            displayMsg={displayMsg}
          />}
    </>
    
  );
};

export default PaymentContactFormContainer;
