import React, { useState } from 'react'; 
import { changeticket } from '../../../../Global/site';
 
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const ProductTable = (props) => {

    const { items, requestSort, sortConfig } = useSortableData(props.products); 
    const changeName = changeticket();
 
    // const officerOnClickHandle = (e) => {
    // localStorage.setItem('officer_id', e.target.dataset.id);
    // // history.push("/officerproductivity");
    // window.location.href = "/officerproductivity";
    // }
   
    const { data } = {
        "data":  items
      }; 

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
      <div> 
    <div className="tbl_scroll">
    <table className="table" id="cstm-dashboard-tb-display">
      <thead>
      <tr className="table-main-heading">
            <th className="table-main-heading-list">
               
                    Sr No.
                
            </th>

            {props.TabBasedDisplayName ?
            <th className="table-main-heading-list">
                <button
                    type="button"
                    onClick={() => requestSort('site_officer_name')}
                    className={getClassNamesFor('site_officer_name')}
                    >
                   Officer name
                </button>
            </th>
            :
            <th className="table-main-heading-list">
                <button
                    type="button"
                    onClick={() => requestSort('zone_name')}
                    className={getClassNamesFor('zone_name')}
                    >
                   Zone
                </button>
            </th>
            }
            <th className="table-main-heading-list">
                <button
                    type="button"
                    onClick={() => requestSort('avg_scans_per_hour')}
                    className={getClassNamesFor('avg_scans_per_hour')}
                    >
                    Avg Scan/hr
                </button>
            </th>
            <th className="table-main-heading-list">
                <button
                    type="button"
                    onClick={() => requestSort('avg_marks_per_hour')}
                    className={getClassNamesFor('avg_marks_per_hour')}
                    >
                    Avg Marks/hr
                </button>
            </th>
            <th className="table-main-heading-list">
                <button
                    type="button"
                    onClick={() => requestSort('time_to_issue_date')}
                    className={getClassNamesFor('time_to_issue_date')}
                    >
                  Avg Time to issue {changeName.charAt(0).toLowerCase()+ changeName.slice(1)}
                </button>
            </th> 
        </tr>
        
      </thead>
      <tbody>
        {items.map((item, index) => (
        
           <tr className="table-main-content" key={item._id}>
           <td className="table-main-content-list" scope="row" key={item._id}>
               {index + 1}
           </td> 
           {item.site_officer_name !== undefined &&
           ( item.site_officer_name !== " " ?
           <td className="table-main-content-list"><button className="cstm-officer-id-get" data-id={item.site_officer_id}>{item.site_officer_name}</button></td>
           :
           <td>-</td>
           )
            }
          {item.zone_name !== undefined &&
              <td className="table-main-content-list">{item.zone_name}</td>
            }
           <td className="table-main-content-list">{parseFloat(item.avg_scans_per_hour).toFixed(2)}</td>
           <td className="table-main-content-list">{parseFloat(item.avg_marks_per_hour).toFixed(2)}</td>
           <td className="table-main-content-list">{item.time_to_issue_date}</td> 
       </tr>
        ))}
      </tbody>
    </table> 
    </div>
                
</div>


  );
};

export default ProductTable;