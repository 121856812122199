import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import CookiesRemove from '../Cookiesremove';
import logoutHelicalUser from '../Services/auth.service';
import { BrandingContext } from '../Global/Branding';
import { siteid } from '../Global/site';

const Header = () => {

  const { auth, isLoggedIn } = useSelector(state => state.auth)
  const siteName = siteid();
  const { logo } = useContext(BrandingContext);

  const handlelogout = () => {
    CookiesRemove();
    const url = window.location.protocol + "//" + window.location.host
    window.location.href = url
    logoutHelicalUser();
  }

  return (
    <div
      className={`container-fluid user-top-header ${isLoggedIn ? "" : "cstm-header-ad"
        }`}
    >
      <div className="row">
        <div className="col-6">
          <Link to="/">
            <img
              src={logo}
              alt="img"
              className="img-fluid cstm-logo"
            />
          </Link>
        </div>
        <div className="col-6">
          <div className="cstm-park-nav-header">
            <div className="cstm-header-mn-user">
              <p className="cstm-header-site-name">{siteName.siteName}</p>
            </div>

            {isLoggedIn && (
              <div className="cstm-header-mn-user">
                <div className="cstm-header-rt">
                  <span className="cstm-header-name">
                    {auth?.first_name || ""} {auth?.last_name || ""}
                  </span>
                  <span className="cstm-header-btn">
                    <button onClick={handlelogout}>Logout</button>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
