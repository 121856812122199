import { formatAmount } from "../../Common/utils";

const PermitFinancial = ({ buy_permit_fee_structure, total_amount }) => {
  return (typeof buy_permit_fee_structure === 'object' && buy_permit_fee_structure !== null && total_amount) && (!total_amount || total_amount === null) ? (
    <div className="text-center">Permit Payment Info Not Available</div>
  ) : (
    <>
      <div className="ctsm-commercial-balance-info">
        <div className="row">
          <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
            <h4>Amounts</h4>
          </div>
        </div>

          <div className="row cstm-row-class">
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Total Amount</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="total_amount"
                    defaultValue={formatAmount(total_amount)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Application Fee</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="application_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.application_fee)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Change Address Fee</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="change_address_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.change_address_fee)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Fixed Tax</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="change_address_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.fixed_tax)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Hand Tag Replacement Fee</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="change_address_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.hand_tag_replacement_fee)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Tax Percentage</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="change_address_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.percentage_tax)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Plate Update Fee</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="change_address_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.plate_update_fee)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cstm-c-1 form-group formgroup-main">
                <div className="c-header">
                  <label>Service Fee</label>
                </div>
                <div className="c-input">
                  <input
                    className="form-control"
                    type="text"
                    name="change_address_fee"
                    defaultValue={formatAmount(buy_permit_fee_structure?.service_fee)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cstm-commercial-balance-info">
          <div className="row">
            <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
              <h4>Additional Fees</h4>
            </div>
          </div>

          <div className="row cstm-row-class">
            {buy_permit_fee_structure?.additional_fees?.map((fee, index) => (
              <div className="col-md-4" key={index}>
                <div className="cstm-c-1 form-group formgroup-main">
                  <div className="c-header">
                    <label>{fee.name}</label>
                  </div>
                  <div className="c-input">
                    {Object.entries(fee)
                      .filter(([key]) => key !== 'name')
                      .map(([key, value], i) => (
                        <input
                          key={i}
                          className="form-control"
                          type="text"
                          name={key}
                          defaultValue={formatAmount(value)}
                          readOnly
                        />
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="cstm-commercial-balance-info">
          <div className="row">
            <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
              <h4>Individual Vehicle Fees</h4>
            </div>
          </div>

          <div className="row cstm-row-class">
            {buy_permit_fee_structure?.individual_permits_fee?.map((fee, index) => (
              <div className="col-md-4" key={index}>
                <div className="cstm-c-1 form-group formgroup-main">
                  <div className="c-header">
                    <label>License Plate {fee?.lp_number}</label>
                  </div>
                  <div className="c-input">
                    <input
                      className="form-control"
                      type="text"
                      name="license_amount"
                      defaultValue={formatAmount(fee?.amount)}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
  );
};

export default PermitFinancial;