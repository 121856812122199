import React from 'react';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ArrowPermitLeft from '../../../../Images/arrow-permit-left.svg';
import ArrowPermitRight from '../../../../Images/arrow-permit-right.svg';
import close from '../../../../Images/close.svg';
import { useNavigate } from 'react-router';

const PermitHandTagField = ({
  stepNo,
  stepField,
  validation,
  onClickNavigate,
  formSubmitHandler,
  setShowStepDetails,
  constructValidationRules,
}) => {

  const { control, handleSubmit } = useForm({
    defaultValues: {
      [stepField[0].key_name]: [{ value: "" }] // Setting default values for the field array
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: stepField[0].key_name
  });

  const navigate = useNavigate();

  console.log(stepField[0],'step field')

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      {fields.map((item, index) => (
        <div key={item.id} className='col-6'> {/* Using key here for each field */}
          <Controller
            name={`${stepField[0].key_name}.${index}.value`} // Ensure unique name for each input
            control={control}
            rules={constructValidationRules(stepField[0].repr, validation)}
            render={({ field, fieldState }) => (
              <>
                <div className={"permit-step-hand-tag"}>
                  <select
                    className={'permit-field-que'}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={!stepField[0].is_editable}
                  >
                    <option value=""> Select {stepField[0].repr} </option>
                    {stepField[0].options && stepField[0].options.length > 0 &&
                        stepField[0].options.map(itm => (
                          <option value={itm.title}>
                            {itm.title}
                          </option>
                        ))
                    }
                  </select>
                  {/* <input
                    type={
                      stepField[0].data_type_validation &&
                      stepField[0].data_type_validation.toLowerCase() ===
                      'number'
                        ? 'number'
                        : 'text'
                    }
                    className={'permit-field-que'}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={!stepField[0].is_editable}
                  /> */}
                  {fieldState.error && (
                    <span className="errormessage">
                      {fieldState.error.message}
                    </span>
                  )}
                  {/* {index > 0 && <button className='hand-tag-remove-btn' onClick={() => remove(index)} type="button">
                    <img src={close} />
                  </button>} */}
                </div>
              </>
            )}
          />        
        </div>
      ))}
      {/* <div className='col-6 add-more-link'>
        <button onClick={() => append({ value: "" })} type="button">
          Add More+
        </button>
      </div> */}
      <div className="permit-question-btn">
        <button
          className="permit-question-btn-left"
          type="button"
          onClick={() => (
            Number(stepNo) === 0
              ? (navigate(-1), setShowStepDetails(false))
              : navigate(`#step${Number(stepNo) - 1}`),
            onClickNavigate(`step${Number(stepNo) - 1}`)
          )}>
          <img src={ArrowPermitLeft} alt="back" />
          Back
        </button>
        <button
          className="permit-question-btn-right cstm-permit-btn"
          type="submit">
          Next
          <img src={ArrowPermitRight} alt="next" />
        </button>
      </div>
    </form>
  );
};

export default PermitHandTagField;
