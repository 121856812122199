import React, { useState, useEffect } from 'react';
import TotalCitation from '../../../Images/tc.png';
import TotalScan from '../../../Images/ts.png';
import TotalCancel from '../../../Images/tcc.png';
import TotalReissue from '../../../Images/tr.png';
import TimingRecords from '../../../Images/trr.png';
import TotalScofflaw from '../../../Images/tss.png';
import close from '../../../Images/close.svg';
import LineChartOperation from './OperationSummaryChart';
import Dashboard from "../../../Services/dashboard.service";

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';
import { changetotal } from '../../../Global/site';


function TotalCount() {
    const dispatch = useDispatch()
    const changeTotal = changetotal();
    const [count, setCount] = React.useState(0);
    const [showTotalCitation, setTotalCitation] = React.useState("");
    const [showTotalScan, setTotalScan] = React.useState("");
    const [showTotalCancel, setTotalCancel] = React.useState("");
    const [showTotalReissue, setTotalReissue] = React.useState("");
    const [showTimingRecord, setTimingRecord] = React.useState("");
    const [showTotalScofflaw, setTotalScofflaw] = React.useState("");
    const [showAuthToken, setAuthToken] = useState(false);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () => {
        setErrorHandling(false)
    }
    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    useEffect(() => {

        const retriveData = {
            "count_array_timeline": {
                "count_type": "daily"
            }
        }
        initialtodaycountfun()
        function initialtodaycountfun () {
            Dashboard.getTodayCount(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setTotalCitation(response.data.data[0].metadata.counts.CitationData)
                            setTotalScan(response.data.data[0].metadata.counts.ScanData)
                            setTotalCancel(response.data.data[0].metadata.counts.CancelData)
                            setTotalReissue(response.data.data[0].metadata.counts.ReissueData)
                            setTimingRecord(response.data.data[0].metadata.counts.TimingData)
                            setTotalScofflaw(response.data.data[0].metadata.counts.ScofflawData)
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    initialtodaycountfun()
                }
            });
        }
        setCount(100);
    }, []);

    return (
        <>

            <div className="row cstm-or-box">
                <div className="cstm-or-box-in">
                    <div className="cstm-or-box-in-top">
                        <div className="cstm-or-box-in-name">Total {changeTotal}</div>
                        <div className="cstm-or-box-in-icon"><img src={TotalCitation} /></div>
                    </div>
                    <div className="cstm-or-box-in-bottom">{showTotalCitation}</div>
                </div>
                <div className="cstm-or-box-in">
                    <div className="cstm-or-box-in-top">
                        <div className="cstm-or-box-in-name">Total Scan</div>
                        <div className="cstm-or-box-in-icon"><img src={TotalScan} /></div>
                    </div>
                    <div className="cstm-or-box-in-bottom">{showTotalScan}</div>
                </div>
                <div className="cstm-or-box-in">
                    <div className="cstm-or-box-in-top">
                        <div className="cstm-or-box-in-name">Total Cancel</div>
                        <div className="cstm-or-box-in-icon"><img src={TotalCancel} /></div>
                    </div>
                    <div className="cstm-or-box-in-bottom">{showTotalCancel}</div>
                </div>
                <div className="cstm-or-box-in">
                    <div className="cstm-or-box-in-top">
                        <div className="cstm-or-box-in-name">Total Reissue</div>
                        <div className="cstm-or-box-in-icon"><img src={TotalReissue} /></div>
                    </div>
                    <div className="cstm-or-box-in-bottom">{showTotalReissue}</div>
                </div>
                <div className="cstm-or-box-in">
                    <div className="cstm-or-box-in-top">
                        <div className="cstm-or-box-in-name">Timing Records</div>
                        <div className="cstm-or-box-in-icon"><img src={TimingRecords} /></div>
                    </div>
                    <div className="cstm-or-box-in-bottom">{showTimingRecord}</div>
                </div>
                <div className="cstm-or-box-in">
                    <div className="cstm-or-box-in-top">
                        <div className="cstm-or-box-in-name">Total Scofflaw</div>
                        <div className="cstm-or-box-in-icon"><img src={TotalScofflaw} /></div>
                    </div>
                    <div className="cstm-or-box-in-bottom">{showTotalScofflaw}</div>
                </div>

            </div>

            <div className="row cstm-operation-summary">

                <LineChartOperation />

            </div>

        </>
    );
}

export default TotalCount;
