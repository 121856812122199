import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const VehicelDetailForm = ({ handleMoreChange, element }) => {

  const ownershipType = [
    "Registered Owner",
    "Lessee",
    "Renter/Operator",
    "Disputing Party",
    "Other",
  ];

  return (
    <>
      <div className="row">
        <div className="col-4 form-group formgroup-main mb-2">
          <label htmlFor="">Ownership</label>
          {ownershipType.map((types) => (
            <div className="in-cstm-sub-agency-only">
              <label>
                <input
                  type="radio"
                  value={types}
                  name={`ownership_type`}
                  checked={element.ownership_type === types}
                  onChange={(e) => handleMoreChange("ownership_type", e)}
                />
                {types}
              </label>
            </div>
          ))}
        </div>
        {element.ownership_type &&
          element.ownership_type !== "" &&
          element.ownership_type !== "Other" &&
          element.ownership_type !== "Disputing Party" && (
            <div className="col-8">
              <div className="row">
                <div className="col-6">
                  <div className="vehicle-datepicker form-group">
                  <label htmlFor="">Owned From</label>
                  <DatePicker
                    showTimeSelect
                    timeIntervals={1}
                    placeholderText="MM/DD/YYYY HH:MM"
                    dateFormat="MM/dd/yyyy h:mmaa"
                    timeFormat="HH:mm"
                    className="cstm-pull-details schedule-date-input form-control cstm-datepik"
                    name="owned_from"
                    selected={
                      element.owned_from === ""
                        ? element.owned_from
                        : new Date(element.owned_from).setHours(
                            element.owned_from.slice(11, 13),
                            element.owned_from.slice(14, 16),
                            0
                          )
                    }
                    onChange={(e) => handleMoreChange("owned_from", e)}
                    autoComplete="off"
                  />
                  </div>
                  </div>
                  <div className="col-6">
                  <div className="vehicle-datepicker form-group ">
                  <label htmlFor="">Owned Till</label>
                  <DatePicker
                    showTimeSelect
                    timeIntervals={1}
                    dateFormat="MM/dd/yyyy h:mmaa"
                    timeFormat="HH:mm"
                    placeholderText="MM/DD/YYYY HH:MM"
                    className="cstm-pull-details schedule-date-input form-control cstm-datepik"
                    name="owned_till"
                    selected={
                      element.owned_till === ""
                        ? element.owned_till
                        : new Date(element.owned_till).setHours(
                            element.owned_till.slice(11, 13),
                            element.owned_till.slice(14, 16),
                            0
                          )
                    }
                    onChange={(e) => handleMoreChange("owned_till", e)}
                    autoComplete="off"
                  />
                  </div>
                  </div>
                  </div>
            </div>
          )}

        <div className="col-12 form-group formgroup-main cstm-nofify-via mb-2">
          <div className="in-cstm-sub-notification-only">
              <input
                type="checkbox"
                name="sms_notification"
                onChange={(e) => handleMoreChange("sms_notification", e)}
                checked={
                  element.sms_notification ? element.sms_notification : false
                }
              />
              Notify via SMS about Citation, Boot and Tow information.
          </div>
          <div className="in-cstm-sub-notification-only">
              <input
                type="checkbox"
                name="email_notification"
                onChange={(e) => handleMoreChange("email_notification", e)}
                checked={
                  element.email_notification
                    ? element.email_notification
                    : false
                }
              />
              Notify via Email all Citation, Infraction, Notices for the
              registered plate.
          </div>
        </div>
        <div className="col-12">
          <p>
            Note: If your plate state DMV record matches the address provides,
            notification will be sent. If the DMV records does not match,
            additional proof of owner will be required.
          </p>
        </div>
      </div>
    </>
  );
};

export default VehicelDetailForm;
