import React, { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';

import close from '../../../Images/close.svg';
import { Link } from 'react-router-dom';
import validator from 'validator'
import Cookies from 'js-cookie';

const ProductTable = (props) => {

    const [count, setCount] = React.useState(0);
    const [showCancelCitation, setCancelCitation] = useState(false)
    const [showModifyAmount, setModifyAmount] = useState(false)

    const onClickDetailsPage = (e, id) => {
        Cookies.set('tikno', id);
        return props.onClickDetailsPage(true);
    }

    const closeError = () => {
        setCancelCitation(false);
        setModifyAmount(false)
    }
    function convertDecimal(num) {
        return (Math.round(num * 100) / 100).toFixed(2)
    }


    const commanPageLoadFunction = () => {
        $(function () {
            $('table')
                .on('click', '.srt-tab', function () {


                    var index = $(this).index(),
                        rows = [],
                        thClass = $(this).hasClass('srt-ace') ? 'srt-desc' : 'srt-ace';

                    $('#cstm-report-tb-display th').removeClass('srt-ace srt-desc');
                    $(this).addClass(thClass);

                    $('#cstm-report-tb-display tbody tr').each(function (index, row) {
                        rows.push($(row).detach());
                    });

                    rows.sort(function (a, b) {
                        var aValue = $(a).find('td').eq(index).text(),
                            bValue = $(b).find('td').eq(index).text();

                        return aValue > bValue
                            ? 1
                            : aValue < bValue
                                ? -1
                                : 0;
                    });

                    if ($(this).hasClass('srt-desc')) {
                        rows.reverse();
                    }

                    $.each(rows, function (index, row) {
                        $('#cstm-report-tb-display tbody').append(row);
                    });
                });
        });

    }

    useEffect(() => {
        commanPageLoadFunction();
        setCount(100);
    }, []);

    return (

        <>

            <div className="report_tbl_scroll" id="tbl-main-download">
                <table className="table table-heading table-bordered" id="cstm-report-tb-display">
                    <thead>
                        <tr>
                            <th className="srt-tab">
                                Payment Date
                            </th>
                            <th className="srt-tab">
                                Number
                            </th>
                            <th className="srt-tab">
                                Plate
                            </th>
                            <th className="srt-tab">
                                Amount
                            </th>
                            <th className="srt-tab">
                                Status
                            </th>
                            <th className="srt-tab">
                                Description
                            </th>
                            <th className="srt-tab">
                                First Name
                            </th>
                            <th className="srt-tab">
                                Last Name
                            </th>
                            <th className="srt-tab">
                                Email
                            </th>
                            <th className="srt-tab">
                                Phone Number
                            </th>
                            
                            {/* <th className="srt-tab">
                                Message
                            </th> */}
                        </tr>

                    </thead>
                    <tbody>
                        {props.products.map((item, index) => (

                            <tr key={item.id}>
                                {item.payment_date !== undefined && item.payment_date !== null && item.payment_date !== "null" && item.payment_date !== "" && item.payment_date.substring(0, 4) !== '0000' && item.payment_date.substring(0, 4) !== '0001' ?
                                    <td> {moment(item.payment_date, "YYYY/MM/DD").format('MM/DD/YYYY') + " " + (item.payment_date).substring(11, 19)} </td>
                                    :
                                    <td>-</td>
                                }

                                {item.citation_id !== undefined && item.citation_id !== null && item.citation_id !== "null" && item.citation_id !== "" ?
                                    <td>{item.citation_id}</td>
                                    :
                                    <td>-</td>
                                }
                                {item.plate !== undefined && item.plate !== null && item.plate !== "null" && item.plate !== "" ?
                                    <td>{item.plate}</td>
                                    :
                                    <td>-</td>
                                }

                                {item.amount !== undefined && item.amount !== null && item.amount !== "null" && item.amount !== "" ?
                                    <td>${convertDecimal(item.amount)}</td>
                                    :
                                    <td>$0.00</td>
                                }

                                {item.status !== undefined && item.status !== null && item.status !== "null" && item.status !== "" ?
                                    <td>{item.status}</td>
                                    :
                                    <td>-</td>
                                }

                                {item.message_description !== undefined && item.message_description !== null && item.message_description !== "null" && item.message_description !== "" ?
                                    <td><div className='cstm-description'>{item.message_description}</div></td>
                                    :
                                    <td>-</td>
                                }

                                {item.first_name !== undefined && item.first_name !== "null" && item.first_name !== null && item.first_name !== "" ?
                                    <td>{item.first_name}</td>
                                    :
                                    <td>-</td>
                                }

                                {item.last_name !== undefined && item.last_name !== "null" && item.last_name !== null && item.last_name !== "" ?
                                    <td>{item.last_name}</td>
                                    :
                                    <td>-</td>
                                }

                                {item.email !== undefined && item.email !== null && item.email !== "null" && item.email !== "" ?
                                    <td>{item.email}</td>
                                    :
                                    <td>-</td>
                                }
                                {item.phone_number !== undefined && item.phone_number !== null && item.phone_number !== "null" && item.phone_number !== "" ?
                                    <td>{item.phone_number}</td>
                                    :
                                    <td>-</td>
                                }
                                
                                {/* {item.message_description !== "" && item.message_description !== null && item.message_description !== "null" && item.message_description !== undefined ?
                                    <td>{item.message_description}</td>
                                    :
                                    <td>-</td>
                                } */}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </>

    );
};

export default ProductTable;
