import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import close from '../../../Images/close.svg';
import ActiveOfficerLocation from "./ActiveOfficerLocation";
import PermitUtilizationCount from "./PermitCounts";
import Dashboard from "../../../Services/dashboard.service";

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';


function ActiveOfficerCounts() {
    const dispatch = useDispatch()

    const [count, setCount] = React.useState(0);
    const [showActiveOfficer, setActiveOfficer] = React.useState("");
    const [showTotalOfficer, setTotalOfficer] = React.useState("");
    const [showActualOfficer, setActualOfficer] = React.useState("");
    const [showAuthToken, setAuthToken] = useState(false);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () => {
        setErrorHandling(false)
    }
    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    const data = {
        datasets: [
            {
                data: [showActiveOfficer, showTotalOfficer - showActiveOfficer],

                backgroundColor: [
                    '#FA5944',
                    '#F1F1F1',
                ],

                borderWidth: 1,
            },
        ],
    };

    const options = {
        cutout: 80
    }

    useEffect(() => {
        initialfun()
        function initialfun() {
            Dashboard.getOfficerRoutes()
                .then((response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setActiveOfficer(response.data.data[0].metadata.active_officers)
                            setTotalOfficer(response.data.data[0].metadata.total_officers)
                            setActualOfficer(response.data.data[0].metadata.total_officers - response.data.data[0].metadata.active_officers)
                        }
                    }
                }).catch((err) => {
                    if (err.response.status === 401) {
                        refreshTokenMiddleware(dispatch)
                        initialfun()
                    }
                });

            setCount(100);
        }
    }, []);



    return (

        <>


            <div className="row cstm-dashboard-aopu">
                <div className="cstm-dashboard-aopu01">
                    <div className="cstm-dashboard-mn-all">
                        <div className="cstm-dashboard-mn-all-left">Active Officers</div>
                        {/* <div className="cstm-dashboard-mn-all-right">
                        <a href="#">View Details <i className="fa fa-angle-right"></i></a>
                        </div> */}
                    </div>
                    <div className="cstm-ao-map-left-img">
                        <div className="cstm_grph_padd">
                            <Doughnut options={options} data={data} />
                        </div>
                    </div>
                    <div className="cstm-ao-map-left-to">
                        <div className="cstm-ao-map-left-to-in">
                            <div className="cstm-ao-map-left-to-in-top">Total Officers</div>
                            <div className="cstm-ao-map-left-to-in-bottom">{showTotalOfficer}</div>
                        </div>
                        <div className="cstm-ao-map-left-to-in">
                            <div className="cstm-ao-map-left-to-in-top">Active Officers</div>
                            <div className="cstm-ao-map-left-to-in-bottom cstm-ac-clr">{showActiveOfficer}</div>
                        </div>
                    </div>
                </div>
                <div className="cstm-dashboard-aopu02 main_cstm_dashbrd_map">
                    <ActiveOfficerLocation />
                </div>

                {/* Display Permit Utilization Count */}
                <PermitUtilizationCount />

            </div>



        </>
    )
}

export default ActiveOfficerCounts;