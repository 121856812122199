import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import mainService from "../../Services/main.service";
import dashboardService from "../../Services/dashboard.service";

import Select from "react-select";

// utils components
import { state } from "../Common/dropDownOptions";
import { changetotal, changeticket, siteid } from "../../Global/site";
import SearchLicensePlate from "./SearchLicensePlate";
import {updateTicketSearchQuery } from "../../actions/other";

const SearchCitation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSideId = siteid();
  const changeName = changeticket();
  const changeTotal = changetotal();
  const lastTicketSearchQuery = useSelector((state) => state.other.ticsearchquery
  );



  const element = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [errorInput, setErrorInput] = useState({
    ticketNumber: "",
    licPlate: "",
    state: "",
    userName: "",
    city: "",
    stateCity: "",
    address: "",
    zip: "",
    email: "",
    phone: "",
    vin_number:""
  });

  const [inputdata, setinputdata] = useState({
    commonErr: "",
    ticketNumber: lastTicketSearchQuery.ticketNumber || "",
    licPlate:lastTicketSearchQuery.licPlate||"",
    state: { value: "WA", label: "WASHINGTON" },
    userName:lastTicketSearchQuery.userName ||"",
    city: lastTicketSearchQuery.city||"",
    stateCity: lastTicketSearchQuery.stateCity||"",
    address:lastTicketSearchQuery.address||"",
    zip: lastTicketSearchQuery.zip||"",
    email: lastTicketSearchQuery.email||"",
    phone: lastTicketSearchQuery.phone||"",
    vin_number:lastTicketSearchQuery.vin_number||""
  });
  const [isBtnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    let id_site = showSideId;
    console.log(id_site, "id_site");
    {
      (id_site.siteinfo === "46b8a5e5-c1d0-420e-b825-c09712ee8665" ||
        id_site.siteinfo === "267ccc30-1bdd-11ed-8edb-e6f96eaf3014") &&
        setinputdata({
          ...inputdata,
          state: { value: "WA", label: "WASHINGTON" },
        });
      
        (id_site.siteinfo === "a98dcd6a-cfc2-11ed-ad98-66070e279278" ||
          id_site.siteinfo === "33370b0e-e5fc-11ed-8b64-665980bd7c45" ||
          id_site.siteinfo === "68ea3b5c-172f-4f33-8d55-e76c301ad212" ||
          id_site.siteinfo === "7fc01b7c-c982-11ee-be9c-72ab48aba21d" ||
          id_site.siteinfo === "c6443f42-c982-11ee-b39a-bebc79a4d1a3" ||
          id_site.siteinfo === "cdb0262e-22ed-11ee-bd8f-42803422fd7d" ||
          id_site.siteinfo === "7b9fdc62-22ed-11ee-b4ca-ae854bfaee9a") &&
          setinputdata({
            ...inputdata,
            state: { value: "SC", label: "SOUTH CAROLINA" },
          });
      
        (id_site.siteinfo === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" ||
        id_site.siteinfo === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45" ||
        id_site.siteinfo === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
        id_site.siteinfo === "5bd20490-dc2f-11ee-bf9e-72127457a419") &&
          setinputdata({
            ...inputdata,
            state: { value: "CT", label: "CONNECTICUT" },
          });
      
        (id_site.siteinfo === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3" ||
          id_site.siteinfo === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8") &&
          setinputdata({
            ...inputdata,
            state: { value: "MI", label: "MICHIGAN" },
          });
      (
        id_site.siteinfo === "b87d467a-dbd3-11ee-be9c-72ab48aba21d" || id_site.siteinfo === "3aba432e-dbd7-11ee-845b-0602968307f0") &&
        setinputdata({
            ...inputdata,
            state: { value: "ME", label: "MAINE" },
          });
      (
        id_site.siteinfo === "7b4bc424-a7dc-11ed-a1e8-42e281654476" || 
        id_site.siteinfo === "da3c1178-a07e-11ee-8147-56d1ee6b2413") && 
        setinputdata({
          ...inputdata,
          state: { value: "MD", label: "MARYLAND" },
        });
        (
          id_site.siteinfo === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" ||
          id_site.siteinfo === "ead64c86-3681-11ee-9c41-6ef2fb394a83") &&
          setinputdata({
            ...inputdata,
          state: { value: "ND", label: "NORTH DAKOTA" },
          });
          (
            id_site.siteinfo === "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4" || id_site.siteinfo === "28022506-fe2f-11ee-aa58-82967aaeaa80") &&
            setinputdata({
              ...inputdata,
              state: { value: "OR", label: "OREGON" },
            });
          (
            id_site.siteinfo === "f3062dac-fe2e-11ee-ae3f-92040ec4c6a4" || id_site.siteinfo === "46dd302e-fe2f-11ee-aa58-82967aaeaa80" || id_site.siteinfo === "fdbcf868-3ec3-11ef-8e44-76f8a1a040d2" || id_site.siteinfo === "23697ca0-3e04-11ef-85dc-e665cc09bf86") &&
            setinputdata({
              ...inputdata,
              state: { value: "TX", label: "TEXAS" },
            });
          (
            id_site.siteinfo === "3e2116e8-0d26-11ef-9818-ee2c79e2244c" || id_site.siteinfo === "2b6d81e0-0d25-11ef-8e44-76f8a1a040d2") &&
            setinputdata({
              ...inputdata,
              state: { value: "NC", label: "NORTH CAROLINA" },
            });
      
            (
              id_site.siteinfo === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
              id_site.siteinfo === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c") && 
                setinputdata({
                ...inputdata,
                state: { value: "MA", label: "MASSACHUSETTS" },
                });
      
                (
                  id_site.siteinfo === "cd666208-44ae-11ef-b128-de29d549d608" ||
                  id_site.siteinfo === "4fa2e26e-44af-11ef-ba31-ca0a5add8b0c") &&
                  setinputdata({
                    ...inputdata,
                    state: { value: "PA", label: "PENNSYLVANIA" },
                  });
                (
                  id_site.siteinfo === "70f9c966-4985-11ef-8e44-76f8a1a040d2" ||
                  id_site.siteinfo === "a95c3734-4986-11ef-98e9-2a370f00fe52") &&
                  setinputdata({
                    ...inputdata,
                    state: { value: "MO", label: "MISSOURI" },
                  });
            
            
          
    }
    dispatch({ type: "CITATION_SEARCH_DATA", payload: null });
  }, []);

  const handleInputes = (selectlist, optionsState) => {
    const value = (selectlist?.value && selectlist) || selectlist?.target.value;
    const name =
      selectlist?.target?.name ||
      (optionsState === "stateCity" ? "stateCity" : "state");

    setinputdata({ ...inputdata, [name]: value });

    let newValue = value;
    if (name === "licPlate") {
      newValue = SearchLicensePlate(value);
    }

    setinputdata({ ...inputdata, [name]: newValue });
    switch (name) {
      case "ticketNumber":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "licPlate":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "state":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "userName":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "city":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "stateCity":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "zip":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "address":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "email":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "phone":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
      case "vin_number":
        if (value) {
          errorInput.commonErr = "";
        }
        break;
    }
    setErrorInput({ ...errorInput });
    if (errorInput.commonErr&&element.current) {
      element.current.scrollIntoView({ behavior: 'smooth' });
  }
  };

  const handlesearchbutton = (e) => {
    e.preventDefault();
    dispatch(updateTicketSearchQuery(inputdata));
    // let exist_error = false;
    // if (
    //   inputdata.ticketNumber === ""
    //     ? inputdata.licPlate === "" || inputdata.state === ""
    //       ? inputdata.userName === "" ||
    //         inputdata.city === "" ||
    //         inputdata.stateCity === "" ||
    //         inputdata.zip === "" ||
    //         inputdata.email === "" ||
    //         inputdata.address === "" ||
    //         inputdata.phone === ""
    //         ? (exist_error = false)
    //         : (exist_error = false)
    //       : (exist_error = false)
    //     : (exist_error = false)
    // ) {
    //   exist_error = true;
    //   errorInput.commonErr =
    //     "Enter ticket number or Licence Plate or Motorist detail";
    // } else if (
    //   inputdata.ticketNumber === ""
    //     ? inputdata.state !== "" && inputdata.licPlate === ""
    //       ? (exist_error = true)
    //       : inputdata.userName === "" ||
    //         inputdata.city === "" ||
    //         inputdata.stateCity === "" ||
    //         inputdata.zip === "" ||
    //         inputdata.email === "" ||
    //         inputdata.address === "" ||
    //         inputdata.phone === ""
    //       ? (exist_error = false)
    //       : (exist_error = false)
    //     : (exist_error = false)
    // ) {
    //   exist_error = true;
    //   errorInput.commonErr =
    //     "Enter ticket number or Licence Plate or Motorist detail";
    // } else {
    //   exist_error = false;
    // }
    // setErrorInput({ ...errorInput });

    if (
      inputdata.userName !== "" ||
      inputdata.city !== "" ||
      inputdata.stateCity !== "" ||
      inputdata.zip !== "" ||
      inputdata.email !== "" ||
      inputdata.address !== "" ||
      inputdata.phone !== "" ||
      inputdata.ticketNumber !== "" ||
      inputdata.licPlate !== "" ||
      inputdata.vin_number !== ""
    ) {
      if(inputdata.licPlate !== "" && inputdata.state.value !== "select") {
      setBtnLoader(true);
      let payload = "";

      if (inputdata.licPlate !== "") {
        payload += "&lp_number=" + inputdata.licPlate;
      }
      if (inputdata.state !== "") {
        if(inputdata.state.value !== "select"){
          payload += "&state=" + inputdata.state.value;
        }
      }
      dashboardService
      .getTickitNumberApi(showSideId.siteinfo + payload)
      .then(async (res) => {
          if (res.data.data.total_citation === 0) {
              setBtnLoader(true);
              errorInput.commonErr = res.data.message;
              setErrorInput({ ...errorInput });
              if (errorInput.commonErr&&element.current) {
                element.current.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
              setBtnLoader(true);
              navigate(`/search-result?${payload}`);
          }
          setBtnLoader(false);
      })
      .catch((e) => {
          errorInput.commonErr = "Something went wrong.";
          setErrorInput({ ...errorInput });
          setBtnLoader(false);
      });
      } else if (inputdata.ticketNumber !== "") {
        console.log("call else if")
  setBtnLoader(true);
  let payload = "";
  if (
    inputdata.ticketNumber !== "" &&
    inputdata.ticketNumber.startsWith("00")
  ) {
    var updateValue = inputdata.ticketNumber.substring(2);
    payload += "&ticket_number=" + updateValue;
  } else if (inputdata.ticketNumber !== "") {
    var updateValue = inputdata.ticketNumber;
    payload += "&ticket_number=" + updateValue;
  }

  dashboardService
  .getTickitNumberApi(showSideId.siteinfo + payload)
  .then(async (res) => {
      if (res.data.data.total_citation === 0) {
          setBtnLoader(true);
          errorInput.commonErr = res.data.message;
          setErrorInput({ ...errorInput });
          if (errorInput.commonErr&&element.current) {
            element.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
          setBtnLoader(true);
          navigate(`/search-result?${payload}`);
      }
      setBtnLoader(false);
  })
  .catch((e) => {
      errorInput.commonErr = "Something went wrong.";
      setErrorInput({ ...errorInput });
      setBtnLoader(false);
  });
      } else if (inputdata.vin_number !== "") {
        setBtnLoader(true);
        let payload = "";
        if (inputdata.vin_number !== "") {
          payload += "&vin_number=" + inputdata.vin_number;
        }
        dashboardService
        .getTickitNumberApi(showSideId.siteinfo + payload)
        .then(async (res) => {
            if (res.data.data.total_citation === 0) {
                setBtnLoader(true);
                errorInput.commonErr = res.data.message;
                setErrorInput({ ...errorInput });
                if (errorInput.commonErr&&element.current) {
                  element.current.scrollIntoView({ behavior: 'smooth' });
              }
            } else {
                setBtnLoader(true);
                navigate(`/search-result?${payload}`);
            }
            setBtnLoader(false);
        })
        .catch((e) => {
            errorInput.commonErr = "Something went wrong.";
            setErrorInput({ ...errorInput });
            setBtnLoader(false);
        });
      } else if (inputdata.userName !== "" ||
      inputdata.city !== "" ||
      inputdata.stateCity !== "" ||
      inputdata.zip !== "" ||
      inputdata.email !== "" ||
      inputdata.address !== "" ||
      inputdata.phone !== "") {
        setBtnLoader(true);
      let payload = "";
      if (inputdata.userName !== "") {
        payload += "&user_name=" + inputdata.userName;
      }
      if (inputdata.address !== "") {
        payload += "&user_address=" + inputdata.address;
      }
      if (inputdata.city !== "") {
        payload += "&user_city=" + inputdata.city;
      }
      if (inputdata.stateCity !== "") {
        if(inputdata.stateCity.value !== "select") {
          payload += "&user_state=" + inputdata.stateCity.value;
        }
      }
      if (inputdata.zip !== "") {
        payload += "&user_zip=" + inputdata.zip;
      }

      if (inputdata.email !== "") {
        payload += "&email=" + inputdata.email;
      }
      if (inputdata.phone !== "") {
        payload += "&telephone=" + inputdata.phone;
      }
      dashboardService
      .getTickitNumberApi(showSideId.siteinfo + payload)
      .then(async (res) => {
          if (res.data.data.total_citation === 0) {
              setBtnLoader(true);
              errorInput.commonErr = res.data.message;
              setErrorInput({ ...errorInput });
              if (errorInput.commonErr&&element.current) {
                element.current.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
              setBtnLoader(true);
              navigate(`/search-result?${payload}`);
          }
          setBtnLoader(false);
      })
      .catch((e) => {
          errorInput.commonErr = "Something went wrong.";
          setErrorInput({ ...errorInput });
          setBtnLoader(false);
      });
      } else {
        errorInput.commonErr =
            "Enter ticket number or Licence Plate or Motorist detail or Vin number";
        setErrorInput({ ...errorInput });
                    // Scroll to the top of the page
                    if (errorInput.commonErr&&element.current) {
                      element.current.scrollIntoView({ behavior: 'smooth' });
                  }
      }} else {
        errorInput.commonErr =
            "Enter ticket number or Licence Plate or Motorist detail or Vin number";
        setErrorInput({ ...errorInput });
                    // Scroll to the top of the page
                    if (errorInput.commonErr&&element.current) {
                      element.current.scrollIntoView({ behavior: 'smooth' });
                  }
      }
  };

  async function imagedataget(newcitedata) {
    for (let i = 0; i < newcitedata.citation_detail.length; i++) {
      var imagesLink = newcitedata.citation_detail[i].images;
      var imageData = {
        download_type: "CitationImages",
        links: Object.assign({}, imagesLink),
        site_id: showSideId.siteinfo,
      };
      mainService.downloadImage(imageData).then((responseImage) => {
        newcitedata.citation_detail[i].new_images = responseImage.data.metadata;
        if (
          newcitedata.citation_detail[i].status.toLowerCase() !==
            "voidandreissue" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "cancelled" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "paid"
        ) {
          if (
            newcitedata.citation_detail[i].ticket_type.toLowerCase() !==
              "warning" &&
            newcitedata.citation_detail[i].amount > 0
          ) {
            newcitedata.citation_detail[i].checked = true;
          } else {
            newcitedata.citation_detail[i].checked = false;
          }
        } else {
          newcitedata.citation_detail[i].checked = false;
        }
      });
    }
    return newcitedata;
  }

  return (
    <>
      <div className="wrapper"  ref={element}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 col-12 login-align login-align-search right-panel cstm-right-panel  search-tit-only">
              <div className="login-section main-login-sec cstm-mrg-auto">
                <div className="cstm-clsdf search-cstm-main-add">
                  <div className="cstm-clsdf2">
                    <h3>Search For {changeTotal}s</h3>
                    <p>
                      Search by {changeName} Number, License plate and state.
                    </p>
                    <span className="errormessage">{errorInput.commonErr}</span>

                    <form action="">
                      <div className="row">
                        <div className="col-12 form-group formgroup-main">
                          <label htmlFor="">{changeName} number*</label>
                          <input
                            type="text"
                            name="ticketNumber"
                            id="ticketNumber"
                            className="form-control"
                            placeholder={`Enter ${changeName} number`}
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.ticketNumber}
                          />
                          <span className="errormessage">
                            {errorInput.ticketNumber}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 or-btn">
                          <label>or</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">License Plate*</label>
                          <input
                            type="text"
                            name="licPlate"
                            id="licPlate"
                            className="form-control"
                            placeholder="Enter license plate"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.licPlate}
                          />
                          <span className="errormessage">
                            {errorInput.licPlate}
                          </span>
                        </div>

                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">License State*</label>
                          <Select
                            ref={element}
                            name="state"
                            id="state"
                            options={state}
                            selectedOption="Active"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            searchable
                            value={inputdata.state}
                          />
                          <span className="errormessage">
                            {errorInput.state}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 or-btn">
                          <label>or</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">Name</label>
                          <input
                            type="text"
                            name="userName"
                            id="userName"
                            className="form-control"
                            placeholder="Enter username"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.userName}
                          />
                          <span className="errormessage">
                            {errorInput.userName}
                          </span>
                        </div>
                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">Address</label>
                          <input
                            type="text"
                            name="address"
                            id="address"
                            className="form-control"
                            placeholder="Enter address"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.address}
                          />
                          <span className="errormessage">
                            {errorInput.address}
                          </span>
                        </div>

                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">City</label>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className="form-control"
                            placeholder="Enter city"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.city}
                          />
                          <span className="errormessage">
                            {errorInput.city}
                          </span>
                        </div>

                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="State">State</label>
                          <Select
                            name="stateCity"
                            id="stateCity"
                            options={state}
                            selectedOption="Active"
                            onChange={(e) => {
                              handleInputes(e, "stateCity");
                            }}
                            searchable
                            value={inputdata.stateCity}
                          />
                          <span className="errormessage">
                            {errorInput.stateCity}
                          </span>
                        </div>

                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">Zip</label>
                          <input
                            type="text"
                            name="zip"
                            id="zip"
                            className="form-control"
                            placeholder="Enter zip"
                            pattern="[0-9]{5}"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.zip}
                          />
                          <span className="errormessage">{errorInput.zip}</span>
                        </div>

                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Enter email"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.email}
                          />
                          <span className="errormessage">
                            {errorInput.email}
                          </span>
                        </div>

                        <div className="col-6 form-group formgroup-main">
                          <label htmlFor="">Phone</label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            className="form-control"
                            placeholder="Enter phone number"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.phone}
                          />
                          <span className="errormessage">
                            {errorInput.phone}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 or-btn">
                          <label>or</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 form-group formgroup-main">
                          <label htmlFor="">Vin number</label>
                          <input
                            type="text"
                            name="vin_number"
                            id="vin_number"
                            className="form-control"
                            placeholder="Enter vin number"
                            onChange={(e) => {
                              handleInputes(e);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handlesearchbutton(e);
                              }
                            }}
                            value={inputdata.vin_number}
                          />
                          <span className="errormessage">
                            {errorInput.vin_number}
                          </span>
                        </div>
                      </div>
                    </form>

                    <div className="search-main cstm-search-main ">
                      <div className="search-btn">
                        <button
                          onClick={handlesearchbutton}
                          className="btn"
                          type="button"
                          disabled={isBtnLoader}
                        >
                          {isBtnLoader && (
                            <div className="cstm-loader">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          )}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCitation;
