import { state } from "../../Component/Common/dropDownOptions";
import Select from "react-select";
import { useEffect, useState } from "react";
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../../Component/customMiddelware";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie"
import DatePicker from "react-datepicker";


const isOCMD = () => {
  if (
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
    Cookies.get("siteinfo") === "46b8a5e5-c1d0-420e-b825-c09712ee8665" ||
    Cookies.get("siteinfo") === "267ccc30-1bdd-11ed-8edb-e6f96eaf3014" ||
    Cookies.get("siteinfo") === "28022506-fe2f-11ee-aa58-82967aaeaa80" ||
    Cookies.get("siteinfo") === "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4" ||
    Cookies.get("siteinfo") === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" ||
    Cookies.get("siteinfo") === "ead64c86-3681-11ee-9c41-6ef2fb394a83" ||
    Cookies.get("siteinfo") ===   "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
    Cookies.get("siteinfo") ===  "5bd20490-dc2f-11ee-bf9e-72127457a419"
  ) {
    return true;
  } else {
    return false;
  }
};
const isFollyBeach = () => {
  if (Cookies.get('siteinfo') === 'a98dcd6a-cfc2-11ed-ad98-66070e279278' || Cookies.get('siteinfo') === '33370b0e-e5fc-11ed-8b64-665980bd7c45' || Cookies.get('siteinfo') === 'c6443f42-c982-11ee-b39a-bebc79a4d1a3' || Cookies.get('siteinfo') === '7fc01b7c-c982-11ee-be9c-72ab48aba21d') {
    return true
  } else return false;
}
const PaymentContactForm = ({
  handleInputChange,
  inputValue,
  inputError,
  handleOptionChange,
  isAppeal,
  list,
  card,
  cardData,
  onChangeDate
}) => {
  return (
    <>
      <div className="row">
        <div
          className={`${
            isAppeal ? "col-4" : "col-6"
          } form-group formgroup-main`}
        >
          First name {!isOCMD() && cardData && "*"}
          <input
            type="text"
            name="first_name"
            className="form-control"
            value={inputValue.first_name}
            placeholder="First Name"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.first_name}</span>
        </div>
        <div
          className={`${
            isAppeal ? "col-4" : "col-6"
          } form-group formgroup-main`}
        >
          Last name {!isOCMD() && cardData && "*"}
          <input
            type="text"
            name="last_name"
            className="form-control"
            value={inputValue.last_name}
            placeholder="Last Name"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.last_name}</span>
        </div>
      </div>
      <div className="row">
        <div
          className={`${
            isAppeal ? "col-12" : "col-8"
          } form-group formgroup-main`}
        >
          Address {!isOCMD() && cardData && "*"}
          <input
            type="text"
            name="address"
            className="form-control"
            value={inputValue.address}
            placeholder="Enter Address"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.address}</span>
        </div>
        <div className="col-4 form-group formgroup-main">
          <label htmlFor="">Payment Source *</label>
          <Select
            name="source"
            value={inputValue.source}
            options={list.map((it) => ({
              value: it.source,
              label: it.source,
            }))}
            onChange={(e) => handleOptionChange(e, "source")}
          />
          <span className="errormessage">{inputError.source}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-4 form-group formgroup-main">
          City {!isOCMD() && cardData && "*"}
          <input
            type="text"
            name="city"
            className="form-control"
            value={inputValue.city}
            placeholder="City"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.city}</span>
        </div>
        <div className="col-4 form-group formgroup-main">
          State {!isOCMD() && cardData && "*"}
          <Select
            name="state"
            options={state}
            selectedOption="Active"
            onChange={(e) => handleOptionChange(e, "state")}
            searchable
            value={inputValue.state || ""}
          />
          <span className="errormessage">{inputError.state}</span>
        </div>
        <div className="col-4 form-group formgroup-main">
          Zipcode {!isOCMD() && cardData && "*"}
          <input
            type="text"
            maxLength={9}
            name={`${isAppeal ? "zip" : "zip_code"}`}
            className="form-control"
            value={isAppeal ? inputValue.zip : inputValue.zip_code}
            placeholder="zip code"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.zip_code}</span>
        </div>
      </div>
      <div className="row">
        {isAppeal ? (
          <div className="col-6 form-group formgroup-main">
            Telephone {!isOCMD() && cardData && "*"}
            <input
              type="text"
              name="telephone"
              className="form-control"
              value={inputValue.telephone}
              onChange={handleInputChange}
              placeholder="Telephone"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength="10"
            />
            <span className="errormessage">{inputError.telephone}</span>
          </div>
        ) : (
          <div className="col-6 form-group formgroup-main">
            Phone number {!isOCMD() && cardData && "*"}
            <input
              type="text"
              name="phone_number"
              className="form-control"
              maxLength={10}
              value={inputValue.phone_number}
              placeholder="Phone Number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={handleInputChange}
            />
            <span className="errormessage">{inputError.phone_number}</span>
          </div>
        )}

        <div className="col-6 form-group formgroup-main">
          Email address
          <input
            type="text"
            name="email"
            className="form-control"
            value={inputValue.email}
            placeholder="Email Address"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.email}</span>
        </div>
      </div>
      {isFollyBeach && inputValue.source.value === "Historical Payment" && !card &&
         <div className="row">
        <div className="col-6 form-group formgroup-main">
          <label htmlFor="">Payment Date</label>
          <DatePicker
            showDateSelect
            timeIntervals={1}
            dateFormat="MM/dd/yyyy"
            className="form-control cstm-cheque-datetime-picker"
            name="payment_date"
            placeholderText="mm/dd/yyyy"
            selected={inputValue.payment_date}
            onChange={(date) => onChangeDate(date, "payment_date")}
            autoComplete="off"
          />
          <span className="errormessage">{inputError.payment_date}</span>
        </div>
      </div>}
    </>
  );
};

export default PaymentContactForm;
