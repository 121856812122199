import { useState } from 'react';
import PaymentContactInfoForm from './paymentContactInfoFomr';
import ConfirmationModalComponent from '../modals/confirmationModal';
import ErrorModalComponent from '../modals/errorModal';

const OcmdPaymentContactFormContainer = ({
  isBtnLoader,
  inputError,
  inputValue,
  doPayment,
  setInputError,
  setInputValue,
  children,
  setCardError,
  cardData,
  cardError,
  list,
  card,
  isModify = false,
  modifyAmountHandler,
  isModifyBtnLoader,
  uiElements
}) => {
  const [displayMsg, setDisplayMsg] = useState("");
  const [showPartialAmountConfirm, setShowPartialAmountConfirm] = useState(false);
  const [isModified, setIsModified] = useState(false)
  const [showModifyAmount, setShowModifyAmount] = useState(false)

  const reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
  const handlePayment = (e,isModifyAmount) => {
    console.log('testing');
    e.preventDefault();

    let isValid = false;
    Object.entries(inputValue).forEach(([key, value]) => {
      if (cardData) {
        if (
          key !== 'country' &&
          key !== 'amount' &&
          key !== 'citation_detail' &&
          key !== 'card_number' &&
          key !== 'expiry' &&
          key !== 'cvc' &&
          key !== 'ownership_type' &&
          key !== 'owned_till' &&
          key !== 'owned_from' &&
          key !== 'email_notification' &&
          key !== 'sms_notification' &&
          key !== 'email' &&
          key !== 'phone_number' &&
          key !== 'first_name' &&
          key !== 'last_name' &&
          key !== 'address' &&
          key !== 'city' &&
          key !== 'state' &&
          key !== 'zip_code' &&
          key !== 'termofpayment' &&
          key !== 'payment_date'
        ) {
          if (!value) {
            console.log(value, key);
            isValid = true;
            inputError[key] = `Enter ${key.replace(/_/g, ' ')}`;
          }
        }
      } else {
        if (
          key !== 'ownership_type' &&
          key !== 'owned_till' &&
          key !== 'owned_from' &&
          key !== 'email_notification' &&
          key !== 'sms_notification' &&
          key !== 'email' &&
          key !== 'phone_number' &&
          key !== 'first_name' &&
          key !== 'last_name' &&
          key !== 'address' &&
          key !== 'city' &&
          key !== 'state' &&
          key !== 'zip_code' &&
          key !== 'payment_date'
        ) {
          if (!value) {
            isValid = true;
            inputError[key] = `Enter ${key.replace(/_/g, ' ')}`;
          }
        }
      }
    });

    if (inputValue.phone_number && inputValue.phone_number.length !== 10) {
      isValid = true;
      inputError.phone_number = 'Phone Number should be of 10 Digits.';
    }
    if (inputValue.email && !reg.test(inputValue.email)) {
      isValid = true;
      inputError.email = 'Invalid email.';
    }
    setInputError({...inputError});

    if (cardData) {
      Object.entries(cardData).forEach(([key, value]) => {
        if (key !== 'expiry' && key !== 'cvc') {
          if (value === '') {
            isValid = true;
            cardError[key] = `Enter ${key.replace(/_/g, ' ')}`;
          }
        }
      });
      setCardError({...cardError});

      Object.entries(cardError).forEach(([key, value]) => {
        if (
          (typeof value === 'string' || value instanceof String) &&
          value !== ''
        ) {
          isValid = true;
        }
      });
    }

    const modifiedData = inputValue.citation_detail.filter(item => item.amount_due !== item.original_amount)


    if (!isValid) {
      if (isModified && modifiedData && modifiedData.length > 0) {
        if (isModifyAmount === 'modify') {
          setShowModifyAmount(true);
          setDisplayMsg(<>
            You are making a partial payment for citation number. {" "}
            {modifiedData.map(itm => itm.citation_id).join(', ')} <br /> <br />

            Please note that discounts are not available for partial payments.
          </>)
        } else {
          setShowPartialAmountConfirm(true);
          setDisplayMsg(<>
            You have modified the amount for following citations. {" "}
            {modifiedData.map(itm => itm.citation_id).join(', ')} <br /> <br />
  
            Are you sure you want to continue?
          </>)
        }
      } else {
        if (isModifyAmount === 'modify') {
              modifyAmountHandler()
            } else {
              doPayment();
            }
      }
      }

  };

  const handleInputChange = e => {
    const {name, value} = e.target;

    setInputError({...inputError, [name]: ''});

    setInputValue({...inputValue, [name]: value});
  };

  const handleOptionChange = (option, type) => {
    setInputValue({...inputValue, [type]: option});
    if (!option) {
      setInputError({...inputError, [type]: 'Select state.'});
    } else {
      setInputError({...inputError, [type]: ''});
    }
  };

  function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  const dateFormatUTC = dates => {
    var date = new Date(dates);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      '-' +
      month +
      '-' +
      todayDate +
      'T' +
      (date.getHours() < 10 ? '0' : '') +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes() +
      ':00.000Z';
    return currentTimeInSeconds;
  };

  const onChangeDate = (date, name) => {
    setInputError({ ...inputError, [name]: "" });
    setInputValue({ ...inputValue, [name]: date });
  };

  const handleCloseModel = () => {
    setShowPartialAmountConfirm(false);
    setShowModifyAmount(false)
  };

  
  const handleConfirm = () => {
    setShowPartialAmountConfirm(false);
    doPayment();
  }

  return (
    <>
    <PaymentContactInfoForm
      isBtnLoader={isBtnLoader}
      handleInputChange={handleInputChange}
      handleOptionChange={handleOptionChange}
      handlePayment={handlePayment}
      inputError={inputError}
      inputValue={inputValue}
      payComponent={children}
      list={list}
      card={card}
      setInputValue={setInputValue}
      cardData={cardData}
      onChangeDate={onChangeDate}
      setIsModified={setIsModified}
      isModify={isModify}
      isModifyBtnLoader={isModifyBtnLoader}
      uiElements={uiElements}
    >
    </PaymentContactInfoForm>
      {showPartialAmountConfirm && <ConfirmationModalComponent handleCloseModel={handleCloseModel} handleConfirm={handleConfirm} displayMsg={displayMsg} />}
      {showModifyAmount && <ErrorModalComponent
        handleCloseModel={handleCloseModel}
        displayMsg={displayMsg}
      />}
    </>
  );
};

export default OcmdPaymentContactFormContainer;
