import React, { Component } from "react";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";

import Reportingsetup from "../../../Services/main.service";

import { refreshtokenforClassComp } from "../../../actions/auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";

import transfer from "../../../Images/transfer.png";
import filter from "../../../Images/filter-yellow.svg";

import ProductTable from "./Sorting";
import PrintPdfCsvExcelDownload from "../../Utilies/PrintPdfCsvExcelDownload";
import dropdownarrow from '../../../Images/dropdown-arrow.svg';
import EscalationDetails from "./EscalationDetails";
import Cookies from "js-cookie";
import Cookiesremove from "../../../Cookiesremove";

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

class EscalationReport extends Component {
  state = {
    lp_number: "",
    ticket_no: "",
    street: "",
    block: "",
    violationCodeFilter: [],
    officerNameFilter: [],
    peoNameFilter: [],
    group_by_officer: "",
    status: "",
    allIntigrationData: [],
    setStreetList: [],
    badgeIDFilter: [],
    violationCode: [],
    officerName: [],
    setBeatTypes: [],
    setRemark: [],
    setZone: [],
    setShift: [],
    shiftFilter: [],
    setAgency: [],
    setStatusFilter: [],
    agencyFilter: [],
    zoneFilter: [],
    stateFilterRemarks: [],
    beatFilter: [],
    setBadgeID: [],
    dataGroupOfficerLog: {},
    stateFilter: [],
    noRecordFound: true,
    setOfficerName: [],
    setPagination: true,
    mainRecordDisplay: true,
    setFilterIconToggle: false,
    groupByOfficerLogs: false,
    setDownloadOption: false,
    dataIntigrationLog: [],
    showAuthToken: false,
    errorHandling: false,
    totalPages: null,
    passDataValue: "",

    setFromDate: '',
    setFromDateSearch: "",
    setToDate: '',
    setToDateSearch: "",

    setStartDate: new Date().setDate(new Date().getDate() + 1),
    setStartDateSearch: "",
    setEndDate: new Date().setHours(0, 0, 0),
    setEndDateSearch: "",
    totalDataLengthCount: 0,
    setDataLoadLoader: true,
    currentPage: 1,
    todosPerPage: 200,
    upperPageBound: 5,
    lowerPageBound: 0,
    isPrevBtnActive: "disabled",
    isNextBtnActive: "",
    pageBound: 3,
    setPaginationSelectVaue: 200,
    setShowDetailPage: false,
    stateFilterSelectedArray: [],
    officerFilterSelectedArray: [],
    peoFilterSelectedArray: [],
    badgeFilterSelectedArray: [],
    codeFilterSelectedArray: [],
    descriptionFilterSelectedArray: [],
    zoneFilterSelectedArray: [],
    remarkFilterSelectedArray: [],
    beatFilterSelectedArray: [],
    setAllSelectedFilterOption: "",
    searchPayload: "",
  };

  exportsLogsData = () => {
    var date = new Date();
    var date1 = new Date(this.state.setEndDate);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var nextMonth = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
    }).format(this.state.setStartDate);
    var todayDate = pad2(date.getDate());
    var tomorrowDate = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(this.state.setStartDate);

    this.setState({
      setStartDateSearch:
        year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z",
    });

    this.setState({
      setEndDateSearch:
        date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T" +
        (date1.getHours() < 10 ? "0" : "") +
        date1.getHours() +
        ":" +
        (date1.getMinutes() < 10 ? "0" : "") +
        date1.getMinutes() +
        ":00.000Z",
    });

    if (
      Cookies.get("ticket_no_search") !== undefined &&
      Cookies.get("ticket_no_search") !== "" &&
      Cookies.get("ticket_no_search") !== null
    ) {
      this.setState({ ticket_no: Cookies.get("ticket_no_search") });
      var passData = "ticket_no=" + Cookies.get("ticket_no_search");
    } else {
      var passData =
        "issue_ts_from=" +
        date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T00:00:00.000Z&issue_ts_to=" +
        year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z";
    }
    this.setState({
      setAllSelectedFilterOption:
        "&issue_ts_from=" +
        date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T00:00:00.000Z&issue_ts_to=" +
        year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z",
    });

    const initialticketfun = () => {
      Reportingsetup.reportTicket(passData)
        .then((Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialticketfun();
          }
        });
    };
    initialticketfun();
  };

  // When Page reload at that time this function called
  componentDidMount() {
    this.exportsLogsData();

    // Get and Display Violation List option for the search drop-down
    var passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    const initialtickviolationfun = () => {
      Reportingsetup.violationCode(passDataViolation)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ violationCode: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickviolationfun();
          }
        });
    };
    initialtickviolationfun();

    // Get and Display Officer name option for the search drop-down
    Reportingsetup.getOfficerName().then(
      (Response) => {
        if (Response.data.status === 300) {
          this.setState({ showAuthToken: true });
        } else {
          this.setState({ setOfficerName: Response.data.officer_names });
          this.setState({ setBadgeID: Response.data.officer_badge_ids });
        }
      },
      (err) => {
        // if (err.response.status === 401) {
        //   this.props.clearoutAuthUserData();
        //   window.location.href = '/'
        // }
      }
    );

    // Get and Display State List option for the search drop-down
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    const initialtickstatefun = () => {
      Reportingsetup.violationCode(passDataStateList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setStreetList: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickstatefun();
          }
        });
    };
    initialtickstatefun();

    // Get and Display Status List option for the search drop-down
    var passDataStateList = {
      type: "CitationStatusData",
      shard: 1,
    };
    const statelistfun = () => {
      Reportingsetup.violationCode(passDataStateList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setStatusFilter: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            statelistfun();
          }
        });
    };
    statelistfun();

    // Get and Display Zone List option for the search drop-down
    var passDataZone = {
      type: "ZoneList",
      shard: 1,
    };
    const initialzonefun = () => {
      Reportingsetup.violationCode(passDataZone)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setZone: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialzonefun();
          }
        });
    };
    initialzonefun();

    // Get and Display Shift List option for the search drop-down
    var passShiftList = {
      type: "ShiftList",
      shard: 1,
    };
    const initialviolationshift = () => {
      Reportingsetup.violationCode(passShiftList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setShift: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialviolationshift();
          }
        });
    };
    initialviolationshift();

    // Get and Display Agency List option for the search drop-down
    var passShiftList = {
      type: "AgencyList",
      shard: 1,
    };
    const violationshift = () => {
      Reportingsetup.violationCode(passShiftList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setAgency: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            violationshift();
          }
        });
    };
    violationshift();
    // Get and Display Remark List option for the search drop-down
    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    const initialremarkfun = () => {
      Reportingsetup.violationCode(passDataRemarks)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setRemark: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialremarkfun();
          }
        });
    };
    initialremarkfun();

    // Get and Display Beat List option for the search drop-down
    var passDataBeat = {
      type: "BeatList",
      shard: 1,
    };
    const initialbeatfun = () => {
      Reportingsetup.violationCode(passDataBeat)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setBeatTypes: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialbeatfun();
          }
        });
    };
    initialbeatfun();
  }

  // Pagination Events
  componentDidUpdate() {
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + this.state.currentPage).addClass("active-paginated");
  }

  handleClick = (event) => {
    this.setState({ setDataLoadLoader: true });
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    $("ul li.active-paginated").removeClass('active-paginated');
    $('ul li#' + listid).addClass('active-paginated');
    this.setPrevAndNextBtnClass(listid);

    var passData = "page=" + listid + "&limit=" + this.state.todosPerPage + this.state.setAllSelectedFilterOption;
    const tickreportfun = () => {
      Reportingsetup.reportTicket(passData)
        .then(Response => {
          if (Response.data.data.citations === null || Response.data.data.citations?.length === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({ totalDataLengthCount: Response.data.data.total_count });
            let totalPage = Math.ceil(Response.data.data.total_count / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp()
              tickreportfun()
            }
          }
        );
    }
    tickreportfun()
  };

  setPrevAndNextBtnClass = (listid) => {
    let totalPage = Math.ceil(this.state.totalDataLengthCount / this.state.todosPerPage);
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  };

  btnIncrementClick = () => {
    this.setState({ setDataLoadLoader: true });
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData = "page=" + listid + "&limit=" + this.state.todosPerPage + this.state.setAllSelectedFilterOption;
    const initialtickdata = () => {
      Reportingsetup.reportTicket(passData)
        .then(Response => {
          if (Response.data.data.citations === null || Response.data.data.citations?.length === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({ totalDataLengthCount: Response.data.data.total_count });
            let totalPage = Math.ceil(Response.data.data.total_count / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp()
              initialtickdata()
            }
          }
        );
    }
    initialtickdata()
  };
  btnDecrementClick = () => {
    this.setState({ setDataLoadLoader: true });
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData = "page=" + listid + "&limit=" + this.state.todosPerPage + this.state.setAllSelectedFilterOption;
    const initialtickreport = () => {
      Reportingsetup.reportTicket(passData)
        .then(Response => {
          if (Response.data.data.citations === null || Response.data.data.citations?.length === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({ totalDataLengthCount: Response.data.data.total_count });
            let totalPage = Math.ceil(Response.data.data.total_count / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp()
              initialtickreport()
            }
          }
        );
    }
    initialtickreport()
  };
  btnPrevClick = () => {
    this.setState({ setDataLoadLoader: true });
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData = "page=" + listid + "&limit=" + this.state.todosPerPage + this.state.setAllSelectedFilterOption;
    const initialtickdatafun = () => {
      Reportingsetup.reportTicket(passData)
        .then(Response => {
          if (Response.data.data.citations === null || Response.data.data.citations?.length === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({ totalDataLengthCount: Response.data.data.total_count });
            let totalPage = Math.ceil(Response.data.data.total_count / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp()
              initialtickdatafun()
            }
          }
        );
    }
    initialtickdatafun()
  };
  btnNextClick = () => {
    this.setState({ setDataLoadLoader: true });
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData = "page=" + listid + "&limit=" + this.state.todosPerPage + this.state.setAllSelectedFilterOption;
    const tickreportdatafun = () => {
      Reportingsetup.reportTicket(passData)
        .then(Response => {
          if (Response.data.data.citations === null || Response.data.data.citations?.length === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({ totalDataLengthCount: Response.data.data.total_count });
            let totalPage = Math.ceil(Response.data.data.total_count / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp()
              tickreportdatafun()
            }
          }
        );
    }
    tickreportdatafun()
  };

  // When click on filter icon
  onClickFilter = (e) => {
    e.preventDefault();
    this.setState({ setFilterIconToggle: !this.state.setFilterIconToggle });
  };

  // When click on Download button
  onClickDonwloadButton = (e) => {
    e.preventDefault();
    this.setState({ setDownloadOption: !this.state.setDownloadOption });
  };

  //  When click on End date picker in search section
  onChangeSearchEndDate = (date) => {
    var date = new Date(date);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({ setStartDate: date });
    this.setState({ setStartDateSearch: currentTimeInSeconds });
  };

  onChangeEscalationtimeto = (date) => {
    var date = new Date(date);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({ setFromDate: date });
    this.setState({ setFromDateSearch: currentTimeInSeconds });
  };

  // On Click Details Page open
  onClickDetailsPage = (value) => {
    this.setState({ setShowDetailPage: value });
  };

  // On click back to reporting page
  backButtonClick = () => {
    this.setState({ setShowDetailPage: false });
  };

  //  When click on Start date picker in search section
  onChangeSearchStartDate = (date) => {
    var date = new Date(date);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({ setEndDate: date });
    this.setState({ setEndDateSearch: currentTimeInSeconds });
  };

  onChangeEscalationtimefrom = (date) => {
    var date = new Date(date);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({ setToDate: date });
    this.setState({ setToDateSearch: currentTimeInSeconds });
  };

  // Pagination count records will be display based on selected value from the drop-down
  onPaginationSelection = (e) => {
    this.setState({ setPaginationSelectVaue: e.target.value })
    this.setState({ setDataLoadLoader: true })
    this.setState({
      currentPage: 1,
      todosPerPage: e.target.value,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 3
    })


    var passData = "page=1" + "&limit=" + e.target.value + this.state.setAllSelectedFilterOption;
    const tickdatafun = () => {
      Reportingsetup.reportTicket(passData)
        .then(Response => {
          if (Response.data.data.citations === null || Response.data.data.citations?.length === 0) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({ totalDataLengthCount: Response.data.data.total_count });
            let totalPage = Math.ceil(Response.data.data.total_count / this.state.todosPerPage);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: 'disabled' });
            }

          }
        },
          (err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp()
              tickdatafun()
            }
          }
        );
    }
    tickdatafun()

    // let totalPage = Math.ceil(this.state.totalDataLengthCount / e.target.value);

    // if (totalPage === 1) {
    //   this.setState({ isNextBtnActive: 'disabled' });
    // }
  };

  onChangeSearchFilter = (e) => {
    if (e.target.name !== "group_by_officer") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (name === "lp_number") {
      var finalData = value.split(" ").join("%20");
      this.setState({ lp_number: value });
    } else if (name === "ticket_no") {
      var finalData = value.split(" ").join("%20");
      this.setState({ ticket_no: value });
    } else if (name === "street") {
      var finalData = value.split(" ").join("%20");
      this.setState({ street: value });
    } else if (name === "block") {
      var finalData = value.split(" ").join("%20");
      this.setState({ block: value });
    } else if (name === "group_by_officer") {
      this.setState({ group_by_officer: e.target.checked });
    } else if (name === "status") {
      this.setState({ status: value });
    }
  };

  //  When click on Submit button in search section
  onSubmitButtonClick = () => {
    const {
      lp_number,
      ticket_no,
      street,
      status,
      block,
      group_by_officer,
      violationCodeFilter,
      beatFilter,
      zoneFilter,
      stateFilter,
      agencyFilter,
      shiftFilter,
      setStartDateSearch,
      setEndDateSearch,
      setFromDateSearch,
      setToDateSearch,
      officerNameFilter,
      peoNameFilter,
      badgeIDFilter,
      stateFilterRemarks,
    } = this.state;

    if (lp_number !== "") {
      var licensePlate = "&lp_number=" + lp_number;
    } else {
      var licensePlate = "";
    }
    if (ticket_no !== "") {
      var ticketNo = "&ticket_no=" + ticket_no;
    } else {
      var ticketNo = "";
    }
    if (street !== "") {
      var streetValue = "&street=" + street;
    } else {
      var streetValue = "";
    }
    if (block !== "") {
      var blockValue = "&block=" + block;
    } else {
      var blockValue = "";
    }
    if (group_by_officer !== "") {
      var groupByofficer = "&group_by_officer=" + group_by_officer;
    } else {
      var groupByofficer = "";
    }
    if (violationCodeFilter !== "") {
      var violationCode = violationCodeFilter.join("");
    } else {
      var violationCode = "";
    }
    if (beatFilter !== "") {
      var beat = beatFilter.join("");
      var finalBeatData = beat.split(" ").join("%20");
    } else {
      var finalBeatData = "";
    }
    if (zoneFilter !== "") {
      var zone = zoneFilter.join("");
      var finalZoneData = zone.split(" ").join("%20");
    } else {
      var finalZoneData = "";
    }
    if (stateFilter !== "") {
      var state = stateFilter.join("");
      var finalStateData = state.split(" ").join("%20");
    } else {
      var finalStateData = "";
    }
    if (status !== "") {
      var searchStatus = "&status=" + status;
    } else {
      var searchStatus = "";
    }
    if (setStartDateSearch !== "") {
      var startDate = "&issue_ts_to=" + setStartDateSearch;
    } else {
      var startDate = "";
    }
    if (setEndDateSearch !== "") {
      var endDate = "&issue_ts_from=" + setEndDateSearch;
    } else {
      var endDate = "";
    }
    if (setFromDateSearch !== "") {
      var fromDate = "&escalation_time_to=" + setFromDateSearch;
    } else {
      var fromDate = "";
    }
    if (setToDateSearch !== "") {
      var toDate = "&escalation_time_from=" + setToDateSearch;
    } else {
      var toDate = "";
    }
    if (officerNameFilter !== "") {
      var name = officerNameFilter.join("");
      var officerName = name.split(" ").join("%20");
    } else {
      var officerName = "";
    }
    if (peoNameFilter !== "") {
      var name = peoNameFilter.join("");
      var peoName = name.split(" ").join("%20");
    } else {
      var peoName = "";
    }

    if (badgeIDFilter !== "") {
      var badge = badgeIDFilter.join("");
      var badgeId = badge.split(" ").join("%20");
    } else {
      var badgeId = "";
    }
    if (stateFilterRemarks !== "") {
      var remark = stateFilterRemarks.join("");
      var remarks = remark.split(" ").join("%20");
    } else {
      var remarks = "";
    }
    if (shiftFilter !== "") {
      var shift = shiftFilter.join("");
      var shiftName = shift.split(" ").join("%20");
    } else {
      var shiftName = "";
    }
    if (agencyFilter !== "") {
      var agencys = agencyFilter.join("");
      var agencyName = agencys.split(" ").join("%20");
    } else {
      var agencyName = "";
    }

    this.setState({ setDataLoadLoader: true });

    if (group_by_officer === true) {
      const finalDataSearchString =
        endDate +
        startDate +
        fromDate +
        toDate +
        licensePlate +
        ticketNo +
        streetValue +
        blockValue +
        violationCode +
        finalBeatData +
        finalZoneData +
        officerName +
        groupByofficer +
        finalStateData +
        searchStatus +
        badgeId +
        agencyName +
        peoName +
        shiftName +
        remarks;

      this.setState({ searchPayload: finalDataSearchString });
      const tickreportdatafun = () => {
        Reportingsetup.reportTicket(finalDataSearchString)
          .then((Response) => {
            if (
              Response.data.data.citations === null ||
              Response.data.data.citations?.length === 0
            ) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: true });
              this.setState({ totalDataLengthCount: 0 });
            } else {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: false });
              this.setState({ groupByOfficerLogs: false });
              this.setState({ setPagination: true });
              this.setState({ allIntigrationData: Response.data.data.citations });
              this.setState({
                totalDataLengthCount: Response.data.data.total_count,
              });
              let totalPage = Math.ceil(
                Response.data.data.total_count / this.state.todosPerPage
              );

              if (totalPage === 1) {
                this.setState({ isNextBtnActive: "disabled" });
              }
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp();
              tickreportdatafun();
            }
          });
      };
      tickreportdatafun();
    } else {
      this.setState({
        currentPage: 1,
        todosPerPage: 200,
        upperPageBound: 5,
        lowerPageBound: 0,
        isPrevBtnActive: "disabled",
        isNextBtnActive: "",
        pageBound: 3,
      });

      this.setState({ setPaginationSelectVaue: 200 });

      const finalDataSearch =
        "page=1&limit=" +
        this.state.todosPerPage +
        endDate +
        startDate +
        fromDate +
        toDate +
        licensePlate +
        ticketNo +
        streetValue +
        blockValue +
        violationCode +
        finalBeatData +
        finalZoneData +
        officerName +
        finalStateData +
        searchStatus +
        badgeId +
        peoName +
        agencyName +
        shiftName +
        remarks;

      this.setState({
        setAllSelectedFilterOption:
          endDate +
          startDate +
          fromDate +
          toDate +
          licensePlate +
          ticketNo +
          streetValue +
          blockValue +
          violationCode +
          finalBeatData +
          finalZoneData +
          officerName +
          finalStateData +
          searchStatus +
          badgeId +
          agencyName +
          shiftName +
          remarks,
      });

      if (finalDataSearch !== "") {
        const ticketreportdatafun = () => {
          Reportingsetup.reportTicket(finalDataSearch)
            .then((Response) => {
              if (
                Response.data.data.citations === null ||
                Response.data.data.citations?.length === 0
              ) {
                this.setState({ setDataLoadLoader: false });
                this.setState({ noRecordFound: true });
                this.setState({ totalDataLengthCount: 0 });
              } else {
                this.setState({ setDataLoadLoader: false });
                this.setState({ noRecordFound: false });
                this.setState({ groupByOfficerLogs: false });
                this.setState({ setPagination: true });
                this.setState({
                  allIntigrationData: Response.data.data.citations,
                });
                this.setState({
                  totalDataLengthCount: Response.data.data.total_count,
                });
                let totalPage = Math.ceil(
                  Response.data.data.total_count / this.state.todosPerPage
                );

                if (totalPage === 1) {
                  this.setState({ isNextBtnActive: "disabled" });
                }
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                this.props.refreshtokenforClassComp();
                ticketreportdatafun();
              }
            });
        };
        ticketreportdatafun();
      } else {
        this.exportsLogsData();
      }
    }
  };

  // Print button click event
  onClickPrintButton = () => {
    window.print();
  };



  // After Download this function will work for get Pagination count records will be display based on selected value from the drop-down
  onRecordsDisplayAfterDownloads = (value) => {
    this.setState({ setPaginationSelectVaue: value });
    this.setState({
      currentPage: 1,
      todosPerPage: value,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
    });

    var passData =
      "page=1" + "&limit=" + value + this.state.setAllSelectedFilterOption;
    const ticketdatafun = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (Response.data.data === null) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data });
            this.setState({ totalDataLengthCount: Response.data.length });
            let totalPage = Math.ceil(Response.data.length / value);

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            ticketdatafun();
          }
        }
      );
    };
    ticketdatafun();
  };




  onClickSearchViolationCode = (selectedList) => {
    this.setState({ codeFilterSelectedArray: selectedList });
    var arrayCode = [];
    selectedList.map((item) => {
      const finalData = "&code=" + item.code;
      arrayCode.push(finalData);
    });
    this.setState({ violationCodeFilter: arrayCode });
  };

  onClickSearchViolationDescriprion = (selectedList) => {
    this.setState({ descriptionFilterSelectedArray: selectedList });
    var arrayCode = [];
    selectedList.map((item) => {
      const finalData = "&code=" + item.code;
      arrayCode.push(finalData);
    });
    this.setState({ violationCodeFilter: arrayCode });
  };

  onClickSearchOfficerName = (selectedList) => {
    this.setState({ officerFilterSelectedArray: selectedList });

    var arrayOfficer = [];
    selectedList.map((item) => {
      const finalData = "&site_officer_id=" + item.officer_id;
      arrayOfficer.push(finalData);
    });
    this.setState({ officerNameFilter: arrayOfficer });
  };

  onClickSearchPeoName = (selectedList) => {
    this.setState({ peoFilterSelectedArray: selectedList });

    var arrayOfficer = [];
    selectedList.map((item) => {
      const finalData = "&site_officer_id=" + item.officer_id;
      arrayOfficer.push(finalData);
    });
    this.setState({ peoNameFilter: arrayOfficer });
  };

  onClickSearchShift = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&shift=" + item.shift;
      arrayShift.push(finalData);
    });
    this.setState({ shiftFilter: arrayShift });
  };

  onClickSearchAgency = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&agency=" + item.agency;
      arrayShift.push(finalData);
    });
    this.setState({ agencyFilter: arrayShift });
  };

  onClickSearchBadgeId = (selectedList) => {
    this.setState({ badgeFilterSelectedArray: selectedList });

    var arrayBadge = [];
    selectedList.map((item) => {
      const finalData = "&badge_id=" + item.badge;
      arrayBadge.push(finalData);
    });
    this.setState({ badgeIDFilter: arrayBadge });
  };

  onClickSearchBeat = (selectedList) => {
    this.setState({ beatFilterSelectedArray: selectedList });

    var arrayBeat = [];
    selectedList.map((item) => {
      const finalBeatData = "&beat=" + item.beat;
      arrayBeat.push(finalBeatData);
    });
    this.setState({ beatFilter: arrayBeat });
  };

  onClickSearchZone = (selectedList) => {
    this.setState({ zoneFilterSelectedArray: selectedList });

    var arrayZone = [];
    selectedList.map((item) => {
      const finalZoneData = "&zone=" + item.zone;
      arrayZone.push(finalZoneData);
    });
    this.setState({ zoneFilter: arrayZone });
  };
  onClickSearchLicenseState = (selectedList) => {
    this.setState({ stateFilterSelectedArray: selectedList });

    var arrayState = [];
    selectedList.map((item) => {
      const finalStateData = "&lp_state=" + item.state;
      arrayState.push(finalStateData);
    });
    this.setState({ stateFilter: arrayState });
  };
  onClickRemark = (selectedList) => {
    this.setState({ remarkFilterSelectedArray: selectedList });

    var arrayStateRemarks = [];
    selectedList.map((item) => {
      const finalStateRemarks = "&remark_1=" + item.remark;
      arrayStateRemarks.push(finalStateRemarks);
    });
    this.setState({ stateFilterRemarks: arrayStateRemarks });
  };

  closeErrorClick = () => {
    this.setState({ errorHandling: false });
  };

  onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  };

  updateValue = (val) => {
    if (!val) {
      this.onSubmitButtonClick();
    }
  };

  render() {
    const {
      currentPage,
      setShowDetailPage,
      todosPerPage,
      setRemark,
      upperPageBound,
      totalDataLengthCount,
      lowerPageBound,
      isPrevBtnActive,
      isNextBtnActive,
      showAuthToken,
      setDataLoadLoader,
      setDownloadOption,
      groupByOfficerLogs,
      errorHandling,
      setStartDate,
      setEndDate,

      setFromDate,
      setToDate,

      setStreetList,
      setBadgeID,
      setOfficerName,
      setBeatTypes,
      violationCode,
      setZone,
      noRecordFound,
      setFilterIconToggle,
      allIntigrationData,
      dataGroupOfficerLog,
      setPaginationSelectVaue,
      stateFilterSelectedArray,
      officerFilterSelectedArray,
      peoFilterSelectedArray,
      badgeFilterSelectedArray,
      codeFilterSelectedArray,
      remarkFilterSelectedArray,
      beatFilterSelectedArray,
      zoneFilterSelectedArray,
      descriptionFilterSelectedArray,
      setShift,
      setAgency,
      setStatusFilter,
    } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = allIntigrationData.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalDataLengthCount / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className="active-paginated" id={number}>
            <button id={number} onClick={this.handleClick.bind(this)}>
              {number}
            </button>
          </li>
        );
      } else if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <li key={number} id={number}>
            <button id={number} onClick={this.handleClick.bind(this)}>
              {number}
            </button>
          </li>
        );
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = (
        <li className="">
          <button onClick={this.btnIncrementClick.bind(this)}>
            {" "}
            &hellip;{" "}
          </button>
        </li>
      );
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = (
        <li className="">
          <button onClick={this.btnDecrementClick.bind(this)}>
            {" "}
            &hellip;{" "}
          </button>
        </li>
      );
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <span id="btnPrev"> Prev </span>
        </li>
      );
    } else {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <button id="btnPrev" onClick={this.btnPrevClick.bind(this)}>
            {" "}
            Prev{" "}
          </button>
        </li>
      );
    }
    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <span id="btnNext"> Next </span>
        </li>
      );
    } else {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <button id="btnNext" onClick={this.btnNextClick.bind(this)}>
            {" "}
            Next{" "}
          </button>
        </li>
      );
    }

    return (
      <>
        {
          !setShowDetailPage ? (
            <>
              <section>
                <div className="container-fluid">
                  <div className="row">
                    {/* Content starts */}
                    <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                      <div className="cstm-ticket-manage-sec">
                        <div className="cstm-ticket-manage-heading">
                          <h3 className="cstm-all-pg-ttl operate-ttl">
                            Escalation Report
                          </h3>
                        </div>
                        <PrintPdfCsvExcelDownload
                          tableId="tbl-main-download"
                          fileName="ticket-summary-report"
                          pageSize="a2"
                        />
                      </div>
                      <div className="report-searchbar">
                        <form>
                          <div className='cstm-ctdt'>
                            <label>Issue datetime</label>
                            <div className="report-date report-mr">
                              <DatePicker
                                // selectsStart
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                className="cstm-pull-details schedule-date-input"
                                name="end_date"
                                selected={setEndDate}
                                onChange={(date) =>
                                  this.onChangeSearchStartDate(date)
                                }
                              />
                              <img
                                src={transfer}
                                alt="transfer"
                                className="date-transfer"
                              />
                              <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                className="cstm-pull-details schedule-date-input"
                                name="start_date"
                                selected={setStartDate}
                                onChange={(date) =>
                                  this.onChangeSearchEndDate(date)
                                }
                              />
                            </div>
                          </div>
                          <div className='cstm-ctdt'>
                            <label>Escalation datetime</label>
                            <div className="report-date report-mr">
                              <DatePicker
                                // selectsStart
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                className="cstm-pull-details schedule-date-input"
                                name="to_date"
                                selected={setToDate}
                                onChange={(date) =>
                                  this.onChangeEscalationtimefrom(date)
                                }
                              />
                              <img
                                src={transfer}
                                alt="transfer"
                                className="date-transfer"
                              />
                              <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                className="cstm-pull-details schedule-date-input"
                                name="from_date"
                                selected={setFromDate}
                                onChange={(date) =>
                                  this.onChangeEscalationtimeto(date)
                                }
                              />
                            </div>
                          </div>
                          <div className="report-licence report-mr mt-4">
                            <input
                              className="license-plate"
                              name="lp_number"
                              value={this.state.lp_number}
                              onChange={this.onChangeSearchFilter.bind(this)}
                              type="text"
                              placeholder="License Plate"
                            />
                          </div>

                          <div className="report-licence report-mr mt-4">
                            <div>
                              <input
                                className="license-plate"
                                name="ticket_no"
                                value={this.state.ticket_no}
                                onChange={this.onChangeSearchFilter.bind(this)}
                                type="text"
                                placeholder="Search Tickets"
                              />
                            </div>
                          </div>
                          <button
                            onClick={this.onClickFilter.bind(this)}
                            className="filter-box report-mr mt-4"
                          >
                            <img src={filter} alt="filter" />
                          </button>
                          <button
                            className="licence-print report-mr mt-4"
                            type="button"
                            onClick={this.onSubmitButtonClick.bind(this)}
                          >
                            Submit
                          </button>
                        </form>

                        {setFilterIconToggle ? (
                          <div className="filter-main-section">
                            <div className="row">
                              <div className="select-drop-arrow filter_cstm1">
                                <select
                                  value={this.state.status}
                                  onChange={this.onChangeSearchFilter.bind(
                                    this
                                  )}
                                  name="status"
                                  className="ar-olny"
                                >
                                  <option value="">Select Status</option>
                                  {setStatusFilter.map((item) => (
                                    <option value={item.status}>
                                      {item.status}
                                    </option>
                                  ))}
                                  {/* <option value="Valid">Valid</option>
                                  <option value="Cancelled">Cancelled</option>
                                  <option value="Warning">Warning</option> */}
                                </select>
                              </div>

                              <div className="report-licence select-drop-arrow report-mr">
                                <Multiselect
                                  name="state"
                                  selectedValues={stateFilterSelectedArray}
                                  displayValue="stateName"
                                  placeholder="License State"
                                  onRemove={this.onClickSearchLicenseState.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchLicenseState.bind(
                                    this
                                  )}
                                  options={setStreetList.map((item, index) => ({
                                    stateName: item.state_name,
                                    state: item.state_abbreviated,
                                  }))}
                                  showCheckbox
                                />
                                {/* <img src={dropdownarrow} alt="dropdownarrow" /> */}
                              </div>

                              <div className="select-drop-arrow filter_cstm2">
                                <Multiselect
                                  name="officer"
                                  selectedValues={officerFilterSelectedArray}
                                  displayValue="officer"
                                  placeholder="Officer Name"
                                  onRemove={this.onClickSearchOfficerName.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchOfficerName.bind(
                                    this
                                  )}
                                  options={setOfficerName
                                    .sort((a, b) =>
                                      a.officer_badge_id > b.officer_badge_id
                                        ? 1
                                        : -1
                                    )
                                    .map((item, index) => ({
                                      officer:
                                        item.officer_badge_id +
                                        " - " +
                                        item.officer_first_name +
                                        " " +
                                        item.officer_last_name,
                                      officer_id: item.site_officer_id,
                                    }))}
                                  showCheckbox
                                />
                              </div>
                              <div className="select-drop-arrow filter_cstm2">
                                <Multiselect
                                  name="officer"
                                  selectedValues={peoFilterSelectedArray}
                                  displayValue="officer"
                                  placeholder="Peo Name"
                                  onRemove={this.onClickSearchPeoName.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchPeoName.bind(
                                    this
                                  )}
                                  options={setOfficerName
                                    .sort((a, b) =>
                                      a.officer_badge_id > b.officer_badge_id
                                        ? 1
                                        : -1
                                    )
                                    .map((item, index) => ({
                                      officer:
                                        item.officer_badge_id +
                                        " - " +
                                        item.officer_name_concat,
                                      officer_id: item.site_officer_id,
                                    }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm3">
                                <input
                                  className="license-plate"
                                  name="street"
                                  value={this.state.street}
                                  onChange={this.onChangeSearchFilter.bind(
                                    this
                                  )}
                                  type="text"
                                  placeholder="Search Street"
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm5">
                                <Multiselect
                                  selectedValues={codeFilterSelectedArray}
                                  name="code"
                                  displayValue="code"
                                  placeholder="Violation code"
                                  onRemove={this.onClickSearchViolationCode.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchViolationCode.bind(
                                    this
                                  )}
                                  options={violationCode.map((item, index) => ({
                                    code: item.code,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm7">
                                <Multiselect
                                  selectedValues={
                                    descriptionFilterSelectedArray
                                  }
                                  name="description"
                                  displayValue="description"
                                  placeholder="Select Violation Description"
                                  onRemove={this.onClickSearchViolationDescriprion.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchViolationDescriprion.bind(
                                    this
                                  )}
                                  options={violationCode.map((item, index) => ({
                                    description: item.violation_description,
                                    code: item.code,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm7">
                                <Multiselect
                                  selectedValues={remarkFilterSelectedArray}
                                  name="remark"
                                  displayValue="remark"
                                  placeholder="Remarks"
                                  onRemove={this.onClickRemark.bind(this)}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickRemark.bind(this)}
                                  options={setRemark.map((item, index) => ({
                                    remark: item.remark,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm6">
                                <Multiselect
                                  name="beat"
                                  selectedValues={beatFilterSelectedArray}
                                  displayValue="beat"
                                  placeholder="Select Beat"
                                  onRemove={this.onClickSearchBeat.bind(this)}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchBeat.bind(this)}
                                  options={setBeatTypes.map((item, index) => ({
                                    beat: item.beat_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm9">
                                <Multiselect
                                  name="zone"
                                  selectedValues={zoneFilterSelectedArray}
                                  displayValue="zone"
                                  placeholder="Select Zone"
                                  onRemove={this.onClickSearchZone.bind(this)}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchZone.bind(this)}
                                  options={setZone.map((item, index) => ({
                                    zone: item.zone_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm10">
                                <Multiselect
                                  name="shift"
                                  displayValue="shift"
                                  placeholder="Shift"
                                  onRemove={this.onClickSearchShift.bind(this)}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchShift.bind(this)}
                                  options={setShift.map((item, index) => ({
                                    shift: item.shift_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm7">
                                <Multiselect
                                  name="agency"
                                  displayValue="agency"
                                  placeholder="agency"
                                  onRemove={this.onClickSearchAgency.bind(this)}
                                  onSearch={function noRefCheck() { }}
                                  onSelect={this.onClickSearchAgency.bind(this)}
                                  options={setAgency.map((item, index) => ({
                                    agency: item.agency_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="ticket-search-radio-btn-main">
                                <div className="ticket-search-radio-btn">
                                  <span className="search-block-filter-pl">
                                    Block Range:{" "}
                                  </span>
                                </div>

                                <div className="radio-ticket-serach-input">
                                  <input
                                    className="license-plate"
                                    name="block"
                                    value={this.state.block}
                                    onChange={this.onChangeSearchFilter.bind(
                                      this
                                    )}
                                    type="text"
                                    placeholder="From"
                                  />

                                  <input
                                    className="license-plate"
                                    name="block_to"
                                    value={this.state.block_to}
                                    onChange={this.onChangeSearchFilter.bind(
                                      this
                                    )}
                                    type="text"
                                    placeholder="To"
                                  />
                                </div>

                                <div className="check-input-filter filter_cstm12 ticket-group-by-officer-ttl">
                                  <div className="checkbox-inline">
                                    <input
                                      onChange={this.onChangeSearchFilter.bind(
                                        this
                                      )}
                                      type="checkbox"
                                      name="group_by_officer"
                                      id="check-officer"
                                    />
                                    <label htmlFor="check-officer">
                                      Group By Officer
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {setDataLoadLoader ?
                        <div className='text-center spin-load-main'>
                          <div className="spinner-border"></div>
                        </div>
                        :
                        (noRecordFound ?
                          <div className='no-record-found-main'>No Record Found</div>
                          :
                          <>
                            <div className="pl-cancel-citation-btns report-searchbar">
                              {/* <button className="licence-print report-mr" type="button" >
                                Void Citation
                              </button>
                              <button className="licence-print report-mr" type="button" >
                                Modify Amount
                              </button> */}
                            </div>
                            <div className="ctsm-table-scroll">
                              <div className="table-overflow">
                                <div className="table-responsive">
                                  {groupByOfficerLogs ? (
                                    <div className="">
                                      {/* <GroupTableLogs onClickDetailsPage={this.onClickDetailsPage} products={dataGroupOfficerLog} /> */}
                                    </div>
                                  ) :
                                    <ProductTable onClickDetailsPage={this.onClickDetailsPage} updateValue={this.updateValue} products={allIntigrationData} />}
                                </div>
                              </div>

                              {allIntigrationData.length !== 0 &&
                                noRecordFound !== true && (
                                  <div className="cstm-permit-trf esc-select">
                                    <div className="col-12">
                                      <div className="total-count-records">
                                        Total <b> {totalDataLengthCount} </b> Records
                                        Found{" "}
                                      </div>
                                    </div>
                                    <ul className="cstm-pagination-added-bottom">
                                      {renderPrevBtn}
                                      {pageDecrementBtn}
                                      {renderPageNumbers}
                                      {pageIncrementBtn}
                                      {renderNextBtn}
                                    </ul>

                                    <div className="cstm-left-lable-pn-val"> Number of records per page </div>
                                    <div className="cstm-right-lable-pn-val">
                                      <select className="select-pagination-val" value={setPaginationSelectVaue} onChange={this.onPaginationSelection.bind(this)}>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                      </select>
                                      <img src={dropdownarrow} alt="dropdownarrow" />
                                    </div>

                                  </div>
                                )}
                            </div>
                          </>
                        )
                      }

                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <EscalationDetails backButtonClick={this.backButtonClick} />
          )
          // <ReportingDetailPage backButtonClick={this.backButtonClick} />
        }
      </>
    );
  }
}

// export default EscalationReport;
export default connect(null, { refreshtokenforClassComp })(EscalationReport);
