import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Sitesetup from "../../Services/main.service";
import { useSelector, useDispatch } from 'react-redux';
import { assignsiteid } from "../../Global/site";
import Cookies from 'js-cookie';


const Login = () => {
    const { isLoggedIn, isAgency } = useSelector(state => state.auth)

    const navigate = useNavigate()
    const idsite = assignsiteid()

    const [isBtnLoader, setBtnLoader] = useState(false);

    const [showAddedMessage, setAddedMessage] = useState("");

    const validationSchema = Yup.object().shape({
        user_name: Yup.string().required('Enter username'),
        password: Yup.string().required('Enter password')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onchange',
        resolver: yupResolver(validationSchema)
    });


    const onSubmit = (data, e) => {
        setBtnLoader(true)
        var loginDetails = {
            "user_name": data.user_name,
            "password": data.password,
            "site_id": idsite
        }

        Sitesetup.userLogin(loginDetails).then(
            (result) => {    
                setBtnLoader(false)
                if (result.data.status) {
                    if (!result.data.data.invalid_user && result.data.data.pwd_verified) {
                        Cookies.set('mobile_otp_nonce', result.data.data.mobile_otp_nonce)
                        navigate('/otpverification', { state: loginDetails })
                    } else {
                        setAddedMessage(result.data.message)
                    }
                } else {
                    setAddedMessage(result.data.message)
                }
            }
        ).catch((err) => {
            if (err.response.status === 401) {
                setAddedMessage(err.response.data.message)
                setBtnLoader(false)
            }
        });
    }

    // if (isLoggedIn || !isAgency) {<Navigate to={'/dashboard'} />
    // }
    // if (isLoggedIn || isAgency) {
    //     return <Navigate to={'/dashboard'} />
    // }
    if (isLoggedIn && !isAgency) {
        return <Navigate to={'/agency'} />
    }
    if (isLoggedIn && isAgency) {
        return <Navigate to={'/dashboard'} />
    }


    return (<>
        <div className="wrapper citycitiation">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-12 login-align">
                        <div className="login-section  main-login-sec">
                            <h2>Login</h2>
                            <br />
                            {showAddedMessage && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {showAddedMessage}
                                    </div>
                                </div>
                            )}
                            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 form-group formgroup-main">
                                        <label htmlFor="">User name*</label>
                                        <input type="user_name" {...register('user_name', { onChange: (e) => { setAddedMessage('') } })} name="user_name" className="form-control" placeholder="Email or username" />
                                        <span className="errormessage">{errors.user_name?.message}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 form-group formgroup-main">
                                        <label htmlFor="">Password*</label>
                                        <input type="password" className="form-control" {...register('password', { onChange: (e) => { setAddedMessage('') } })} placeholder="Password" name="password" />
                                        <span className="errormessage">{errors.password?.message}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 cstm-forget-id-or-password">
                                        <Link to={'/forgot-password'}>Forgot Password?</Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 button2">
                                        <button className="btn" type="submit" disabled={isBtnLoader}>
                                            {isBtnLoader &&
                                                <div className="cstm-loader">
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </div>
                                            }
                                            Sign In
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 login-bgImage">
                        <div className="login-cont-main">
                            <div className="login-cont-main-sub">Nice to see you again</div>
                            <div className="login-cont-main-title">Welcome Back</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Login