import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../../../Services/dashboard.service";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import moment from "moment";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { changeticket } from "../../../Global/site";
import mainService from "../../../Services/main.service";

const RefundModal = ({
  ticketNumber,
  handleCancelConfirm,
  showCitationDetails,
  data,
}) => {
  const { auth } = useSelector((state) => state.auth);
  const [transferFundValue, setTransferFundValue] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState([]);
  const [refundAmountError, setRefundAmountError] = useState("");
  const [citationType,setCitationType] = useState(false)
  const dispatch = useDispatch();
  const changeName = changeticket();
  const [list, setList] = useState([]);

  useEffect(() => {
    const getPaymentList = () => {
      var payload3 = {
        type: "PaymentSourceData",
        shard: 1,
      };
      mainService
        .violationCode(payload3)
        .then((result) => {
          if (result.data.status) {
            setList(result.data.data[0].response);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            getPaymentList();
          }
        });
    };
    getPaymentList();
  }, []);

  // test
  const citationAmount = data.amount_due;
  const paidAmount = data.total_paid_amount;
  let refundDefaultValue;

  if (paidAmount - citationAmount > 0) {
    refundDefaultValue = (paidAmount - citationAmount)?.toString();
  } else {
    refundDefaultValue = paidAmount?.toString();
  }

  const validationSchema = Yup.object().shape({
    transfer_detail: transferFundValue && !citationType && Yup.array().of(
      Yup.object().shape({
        ticket_number: Yup.string().required("Citation no is required"),
        amount: Yup.string().required("Amount is required")
      })
    ),
    citations:
      transferFundValue && citationType &&
      selectedCitations.length === 0 &&
      Yup.string().required("Select at least one citation for fund transfer."),
    cheque_amount: Yup.string()
      .default(refundDefaultValue)
      .test(
        "Is not negative?",
        "Enter only positive number for amount.",
        function (value) {
          if (value && value.trim() !== "") {
            const parsedValue = parseFloat(value);
            return parsedValue >= 0 || (parsedValue >= 0 && !isNaN(parsedValue));
          } else {
            return true; // Skip validation when the field is empty
          }
        }
      )
      .test(
        "maxAmount",
        "The amount should be less than refund amount.",
        function (value) {
          if (value && value.trim() !== "") {
            return parseFloat(value) <= refundDefaultValue;
          } else {
            return true; // Skip validation when the field is empty
          }
        }
      ),
    refund_amount: Yup.string()
      .test("has-value", "Enter citation refund amount", function (value) {
        return value !== "";
      })
      .nullable(),
    refundAmount: Yup.string()
      .required("Enter refund amount.")
      .test(
        "Is not negative?",
        "Enter only positive number for amount.",
        function (value) {
          if (
            parseFloat(value) === refundDefaultValue ||
            parseFloat(value) === 0
          ) {
            return false;
          }
          return parseFloat(value) > 0;
        }
      )
      .test(
        "maxAmount",
        "The amount should be less than total paid amount.",
        function (value) {
          const fineAmount = data.total_paid_amount || 0;
          return parseFloat(value) <= fineAmount;
        }
      )
      .default(refundDefaultValue),
    workflow_status: Yup.string().required("Select refund source."),
    reason: Yup.string().required("Enter reason."),
  });
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "transfer_detail",
    control,
  });

  const handleCheckboxChange = (index, isChecked, citation) => {
    const newTicket = [...selectedCitations];

    if (isChecked) {
      newTicket.push({
        ticket_number: data.tickets_detail[index].ticket_no,
        amount: 0,
      });
    } else {
      let statusIndex = selectedCitations.findIndex(
        (id) => id.ticket_number === citation
      );
      newTicket.splice(statusIndex, 1);
    }

    setSelectedCitations(newTicket);
  };

  const handleRefundAmount = (ticket, amount, fineAmount) => {
    const index = selectedCitations.findIndex(
      (itm) => itm.ticket_number === ticket
    );

    // Check if the ticket exists in the array
    if (index !== -1) {
      const updatedCitations = [...selectedCitations]; // Create a shallow copy
      const newVal = { ticket_number: ticket, amount: Number(amount) };

      // Check if fineAmount is greater than amount
      if (fineAmount < amount) {
        setRefundAmountError(
          "Refund amount is greater than the fine amount. Cannot proceed."
        );
        return; // Stop further execution
      }
      setRefundAmountError("");

      // Update the specific element in the copied array
      updatedCitations[index] = newVal;

      // Set the state with the updated array
      setSelectedCitations(updatedCitations);
    } else {
      // If the ticket doesn't exist, you might want to add it to the array
      const newVal = { ticket_number: ticket, amount: Number(amount) };

      // Check if fineAmount is greater than amount
      if (fineAmount < amount) {
        setRefundAmountError(
          "Refund amount is greater than the fine amount. Cannot proceed."
        );
        return; // Stop further execution
      }
      setRefundAmountError("");
      setSelectedCitations([...selectedCitations, newVal]);
    }
  };

  const onSubmit = (data) => {
    const formData = {
      refund_reason: data.reason,
      refund_source: data.workflow_status,
      cheque_number: data.cheque_number || "",
      transfer_funds: Boolean(data.transferFund),
      ticket_number: ticketNumber,
      transfer_detail: selectedCitations.length > 0 ? selectedCitations : data.transfer_detail,
      refund_amount: Number(data.refundAmount),
      cheque_amount: data.cheque_amount ? Number(data.cheque_amount) : 0,
    };
    let citationTotalAmount = 0;
    formData.transfer_detail = formData.transfer_detail.map(itm => ({
      ticket_number: itm.ticket_number,
      amount: Number(itm.amount)
    }))
    if (refundAmountError === "") {
      if (Boolean(data.transferFund)) {
        if (formData.transfer_detail.length > 0) {
          if (formData.transfer_detail.length > 1) {
            citationTotalAmount = formData.transfer_detail.reduce(
              (accumulator, citation) => {
                const amountToAdd = Number(citation.amount);

                // Check if amountToAdd is a valid number
                if (!isNaN(amountToAdd)) {
                  return accumulator + amountToAdd;
                }

                return accumulator;
              },
              0
            );
          } else {
            citationTotalAmount = formData.transfer_detail[0].amount;
          }
          if (
            Number(data.refundAmount) <
            Number(formData.cheque_amount) + citationTotalAmount
          ) {
            setRefundAmountError(
              "The Total amount should be less than refund amount."
            );
          } else {
            setRefundAmountError("");
            // api call
            formData.refund_amount =
              Number(formData.cheque_amount) + citationTotalAmount;
            Dashboard.refundAmount(formData)
              .then((res) => {
                if (res.data.status) {
                  handleCancelConfirm();
                }
                if(res.data.status) {
                  toast.success(res.data.message);
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((err) => {
                if (err.response.data.status === 401) {
                  refreshTokenMiddleware(dispatch);
                  onSubmit(data);
                }
                toast.error(err.response.data.message);
              });
          }
        }
      } else {
        setRefundAmountError("");
        // api call
        formData.refund_amount = Number(formData.cheque_amount);
        Dashboard.refundAmount(formData)
          .then((res) => {
            if (res.data.status) {
              handleCancelConfirm();
            }
            if(res.data.status) {
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            if (err.response.data.status === 401) {
              refreshTokenMiddleware(dispatch);
              onSubmit(data);
            }
            toast.error(err.response.data.message);
          });
      }
    }
  };

  const convertDecimal = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const citationTable = (
    <table className="table table-heading" id="cstm-report-tb-display">
      <thead>
        <tr>
          <th className="cstm-rm-action">Action</th>
          <th>{changeName}#</th>
          <th>Issue Date</th>
          <th>Amount Due</th>
          <th>Fine Amount</th>
          <th>Status</th>
          <th>Plate Number</th>
          <th className="refund-amount-field-wd">Refund Amount</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.tickets_detail &&
          data.tickets_detail.length > 0 &&
          data.tickets_detail.map((citation, index) => (
            <tr key={citation.id}>
              <td>
                <Controller
                  control={control}
                  name={"citations"}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      {...field}
                      defaultChecked={selectedCitations[index] ? true : false}
                      onChange={(e) => {
                        handleCheckboxChange(
                          index,
                          e.target.checked,
                          citation.ticket_no
                        );
                      }}
                    />
                  )}
                />
              </td>
              <td>{citation.ticket_no}</td>
              <td>
                {moment(citation.ticket_date).utc().format("DD MMM, YYYY")}
              </td>
              <td>{`$${convertDecimal(citation.amount_due) || "0.00"}`}</td>
              <td>{`$${convertDecimal(citation.fine_amount) || "0.00"}`}</td>
              <td>{citation.status}</td>
              <td>
                {showCitationDetails &&
                showCitationDetails.vehicle_details.lp_number
                  ? showCitationDetails.vehicle_details.lp_number
                  : "-"}
              </td>
              <td>
                {selectedCitations &&
                selectedCitations?.length > 0 &&
                selectedCitations?.find(
                  (itm) => itm.ticket_number === citation?.ticket_no
                ) ? (
                  <Controller
                    control={control}
                    name={`refund_amount`}
                    rules={{
                      required: "refund amount is required",
                    }}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="refund-amount-field"
                        defaultValue={
                          selectedCitations[index]
                            ? convertDecimal(
                                selectedCitations.find(
                                  (itm) =>
                                    itm.ticket_number === citation.ticket_no
                                ).amount
                              )
                            : 0.0
                        }
                        value={field.value}
                        onChange={(e) => (
                          handleRefundAmount(
                            citation.ticket_no,
                            e.target.value,
                            citation.amount_due
                          ),
                          trigger("refund_amount")
                        )}
                        onKeyPress={(e) => {
                          if (!/[0-9]|\.|Backspace/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  useEffect(() => {
    if (selectedCitations.find((itm) => itm.amount === 0)) {
      setRefundAmountError("Enter refund amount for all selected citation.");
    } else if (selectedCitations.find((itm) => itm.amount <= 0)) {
      setRefundAmountError("Enter only positive number for amount.");
    } else if (
      selectedCitations.map((itm) => isNaN(itm.amount)).find((it) => it)
    ) {
      setRefundAmountError("Enter only positive number for amount.");
    } else {
      setRefundAmountError("");
    }
  }, [selectedCitations]);

  const initialCheckCitation = () => {
    let restRefundAmount = getValues("refundAmount")
      ? getValues("refundAmount")
      : refundDefaultValue;
    const selectedCitation = data.tickets_detail.map((itm, index) => {
      if (index > 0) {
        let remainingAmount;
        if (restRefundAmount <= data.tickets_detail[index - 1].amount_due) {
          remainingAmount = restRefundAmount;
        } else {
          remainingAmount =
            restRefundAmount - data.tickets_detail[index - 1].amount_due;
        }

        if (remainingAmount >= itm.amount_due) {
          restRefundAmount = remainingAmount;
          return { ticket_number: itm.ticket_no, amount: itm.amount_due };
        } else if (remainingAmount > 0) {
          restRefundAmount = 0; // Reset remaining amount
          return { ticket_number: itm.ticket_no, amount: remainingAmount };
        } else {
          return null; // No refund for this ticket
        }
      } else {
        const initialAmount = Math.min(restRefundAmount, itm.amount_due);
        if (restRefundAmount - initialAmount > 0) {
          restRefundAmount = restRefundAmount;
        } else {
          restRefundAmount = 0;
        }
        return { ticket_number: itm.ticket_no, amount: initialAmount };
      }
    });

    setSelectedCitations(selectedCitation.filter((itm) => itm));
  };


  return (
    <>
      <div className="main-body-modal cstm-td-popup-only">
        <div className="main-modal">
          <div className="cstm-close">
            <button onClick={handleCancelConfirm}>×</button>
          </div>

          <div className="ticketreporting-content">
            <h3>{changeName} Refund</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ticketreporting-content-box">
                  <div className="cstm-inputfield">
                    <label>{changeName} number</label>
                    <br />
                    <input
                      type="text"
                      name="citationNumber"
                      placeholder="Enter citation number"
                      value={ticketNumber}
                      style={{ backgroundColor: "aliceblue" }}
                      disabled
                    />
                  </div>
                  <div className="cstm-inputfield">
                    <label>User name</label>
                    <br />
                    <input
                      type="text"
                      name="userName"
                      placeholder="Enter user name"
                      value={`${auth?.first_name || ""} ${
                        auth?.last_name || ""
                      }`}
                      style={{ backgroundColor: "aliceblue" }}
                      disabled
                    />
                  </div>
                </div>
                <div className="ticketreporting-content-box">
                  <div className="cstm-inputfield">
                    <label>Amount due</label>
                    <br />
                    <input
                      type="text"
                      name="fineAmount"
                      defaultValue={`$${convertDecimal(
                        data.amount_due ? data.amount_due : "0.00"
                      )}`}
                      style={{ backgroundColor: "aliceblue" }}
                      disabled
                    />
                  </div>
                  <div className="cstm-inputfield">
                    <label>Total paid amount</label>
                    <br />
                    <input
                      type="text"
                      name="paidAmount"
                      placeholder="Paid amount"
                      defaultValue={`$${convertDecimal(
                        data.total_paid_amount ? data.total_paid_amount : "0.00"
                      )}`}
                      style={{ backgroundColor: "aliceblue" }}
                      disabled
                    />
                  </div>
                </div>
                <div className="ticketreporting-content-box cstm-rm-smg-main">
                  <div className="cstm-inputfield">
                    <label>Refund amount*</label>
                    <br />
                    <Controller
                      control={control}
                      name="refundAmount"
                      render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Enter refund amount"
                            {...field}
                            defaultValue={convertDecimal(refundDefaultValue)}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("refundAmount");
                              setValue("refundAmount", e.target.value);
                              if (!transferFundValue) {
                                setValue("cheque_amount", e.target.value);
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/[0-9]|\.|Backspace/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                      )}
                    />
                    <span className="cstm-rm-smg">
                      {errors.refundAmount?.message}
                    </span>
                  </div>
                  <div className="cstm-inputfield width-td-slc">
                    <label>Refund source*</label>
                    <br />
                    <Controller
                      control={control}
                      name="workflow_status"
                      render={({ field }) => (
                        <select
                          className="state-droupdwon ar-olny"
                          {...field}
                          name="drinksDropdown">
                            <option value="" disabled selected id="myselect">
                              Select source
                            </option>
                            {list.map((it) => (
                              <option value={it.source}>{it.source}</option>
                            ))}
                        </select>
                      )}
                    />
                    <span className="cstm-rm-smg">
                      {errors.workflow_status?.message}
                    </span>
                  </div>
                </div>
                <div className="ticketreporting-content-box">
                  <div className="cstm-inputfield">
                    <label>Reason*</label>
                    <br />
                    <Controller
                      control={control}
                      name="reason"
                      render={({ field }) => (
                        <>
                          <textarea
                            type="text"
                            placeholder="Enter reason"
                            {...field}
                          />
                          <span
                            className="cstm-rm-smg cstm-enter-refund"
                            style={{ marginBottom: "5px" }}
                          >
                            {errors.reason?.message}
                          </span>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="ticketreporting-content-box">
                  <div className="cstm-inputfield">
                    <label>User account balance</label>
                    <br />
                    <input
                      type="text"
                      name="fineAmount"
                      defaultValue={`$${convertDecimal(
                        data.account_balance ? data.account_balance : "0.00"
                      )}`}
                      style={{ backgroundColor: "aliceblue" }}
                      disabled
                    />
                  </div>
                </div>
                
                      <div className="cstm-inputfield">
                        <label>
                          Do you want to transfer fund for other {changeName}?
                        </label>
                        <br />
                        <Controller
                          control={control}
                          name="transferFund"
                          render={({ field }) => (
                            <select
                              className="state-droupdwon ar-olny"
                              onChange={(e) => {
                                setTransferFundValue(e.target.value === "true");
                                if(e.target.value === "true") {
                                  if(data &&
                                    data.tickets_detail &&
                                    data.tickets_detail.length > 0){
                                      setCitationType(true)
                                    }
                                  append("");
                                  setValue("cheque_amount", 0);
                                } 
                                else {
                                  setValue("transfer_detail", [])
                                }
                                field.onChange(e)
                              }}
                              value={transferFundValue.toString()}
                            >
                              <option value={false}>No</option>
                              <option value={true}>Yes</option>
                            </select>
                          )}
                        />
                      </div>
                      {data &&
                        data.tickets_detail &&
                        data.tickets_detail.length > 0 && transferFundValue && <div>
                      <div className="cstm-inputfield">
                        <label>
                        Select existing citations or enter the citation number.
                        </label>
                        <br />
                        <Controller
                          control={control}
                          name="citationType"
                          render={({ field }) => (
                          <select
                            className="state-droupdwon ar-olny"
                            onChange={(e) => {
                              setCitationType(e.target.value === "true");
                              field.onChange(e);
                              setValue(
                                "transferFund",
                                e.target.value === "true"
                              );
                             
                            }}
                          >
                            <option value={true}>Select the existing citation </option>
                            <option value={false}>Enter the citation number</option>
                          </select>
                        )}
                      />
                    </div>
                      </div>}
                  
                      {transferFundValue && citationType && (
                        <div className="cstm-rm-sct">
                          <p className="cstn-tde-box-title">
                            Select citation to transfer the fund
                          </p>
                          {selectedCitations.length === 0 && (
                            <span className="cstm-rm-sct-msg">
                              {errors.citations?.message}
                            </span>
                          )}
                          <span className="cstm-rm-sct-msg">
                            {errors.refund_amount?.message}
                          </span>
                          {refundAmountError !== "" && (
                            <span className="cstm-rm-sct-msg">
                              {refundAmountError}
                            </span>
                          )}

                          <div className="cstm-tde-box-list ticketreporting-content-table">
                            {citationTable}
                          </div>
                        </div>
                      )}

                      {transferFundValue && !citationType && (
                          <div>
                            <p className="cstm-refund-citation-title">
                              Enter citation number and amount
                            </p>
                            {fields.map((field, index) =>
                             <div className="ticketreporting-content-box" key={index}>
                              <div className="cstm-inputfield">
                                <label>Citation No *</label>
                                <Controller
                                  control={control}
                                  name={`transfer_detail.${index}.ticket_number`}
                                  rules={{
                                    required: "citation no is required"
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <input
                                        type="text"
                                        {...field}
                                        placeholder="Enter citation no"
                                        onKeyPress={(e) => {
                                          // Allow numeric digits, period, and backspace
                                          if (!/[0-9a-zA-Z\.]|Backspace/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      {fieldState.error && 
                                        <span className="cstm-rm-smg cstm-enter-refund">
                                          {fieldState.error.message}
                                        </span>
                                      }
                                    </>
                                  )}
                                />
                                </div>
                                <div className="cstm-inputfield">
                                <label>Amount *</label>
                                <Controller
                                  control={control}
                                  name={`transfer_detail.${index}.amount`}
                                  rules={{
                                    required:"Amount is required"
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                    <input
                                      type="text"
                                      {...field}
                                      placeholder="Enter amount"
                                      onChange={(e) => {
                                        field.onChange(e)
                                        setRefundAmountError("")
                                      }}
                                      onKeyPress={(e) => {
                                        if (!/[0-9]|\.|Backspace/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      
                                    />
                                    {fieldState.error && 
                                      <span className="cstm-rm-smg cstm-enter-refund">
                                        {fieldState.error.message}
                                      </span>
                                    }
                                    {refundAmountError !== "" && (
                                      <span className="cstm-rm-smg cstm-enter-refund">
                                        {refundAmountError}
                                      </span>
                                    )}
                                    </>
                                  )}
                                />
                                </div>
                                <div className="refund-delete">
                                  <button type="button" onClick={() => remove(index)} >
                                    ×
                                  </button>
                                </div>
                              </div>
                            )}
                            <div className="refund-add-more" >
                              <button type="button" onClick={() => append("citations_amount")}>
                                + Add More
                              </button>
                            </div>
                          </div>
                      )}
                  
                <div className="ticketreporting-content-box">
                  <div className="cstm-inputfield">
                    <label>Check number</label>
                    <br />
                    <Controller
                      control={control}
                      name="cheque_number"
                      render={({ field }) => (
                        <input
                          type="text"
                          placeholder="Enter check number"
                          {...field}
                          onKeyPress={(e) => {
                            if (!/[0-9]|\.|Backspace/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    {!transferFundValue && errors.cheque_number && (
                      <span className="cstm-rm-smg cstm-enter-refund">
                        {errors.cheque_number.message}
                      </span>
                    )}
                  </div>
                  <div className="cstm-inputfield">
                    <label>Check amount</label>
                    <br />
                    <Controller
                      name="cheque_amount"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            type="text"
                            placeholder="Enter check amount"
                            {...field}
                            onChange={field.onChange}
                            onKeyPress={(e) => {
                              if (!/[0-9]|\.|Backspace/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            defaultValue={convertDecimal(refundDefaultValue)}
                          />
                          {errors.cheque_amount && (
                            <span className="cstm-rm-smg">
                              {errors.cheque_amount.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="cstm-submit-btn">
                  <button type="submit">Submit Refund</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundModal;
