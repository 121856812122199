import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../customMiddelware";
import PrintPdfCsvExcelDownload from "../Utilies/PrintPdfCsvExcelDownload";
import NoticeTemplateTinyMCE from "./NoticeTemplateForm";
import NoticeViewHtml from "./NoticeViewHtml";

const NoticeTemplateManagement = () => {
  const dispatch = useDispatch();
  const [notifiTemplatesList, setNotifiTemplatesList] = useState([]);
  const [isNotiTemplateViewHTML, setNotiTemplateViewHTML] = useState({
    html: "",
    state: false,
  });
  const [showCreateForm, setCreateForm] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isNotiTemplEditValue, setIsNotiTemplEditValue] = useState();
  const [sorting, setSorting] = useState({ field: "xyz", ascending: true });

  useEffect(() => {
    const sortingNotificationData = [...notifiTemplatesList];

    const sortedCurrentdata = sortingNotificationData.sort((a, b) => {
      return a[sorting.key].toString().localeCompare(b[sorting.key].toString());
    });

    setNotifiTemplatesList(
      sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse()
    );
  }, [sorting]);

  // apply sorting on table
  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending });
  };

  useEffect(() => {
    getnotificationTemplateData();
  }, [showCreateForm]);

  // getting all data from API
  async function getnotificationTemplateData() {
    setLoader(true);
    try {
      const result = await mainService.getNotificationTemplatesAPI();
      setNotifiTemplatesList(result.data.data);
      setLoader(false);
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getnotificationTemplateData();
      }
    }
  }

  // onClick Edit Notice Template
  const editNotifiTemplate = (e, templ) => {
    e.preventDefault();
    setIsNotiTemplEditValue(templ);
    setCreateForm(true);
  };

  // onClick Create Notice Template
  const createNoticeTemplateTinyMCE = () => {
    setCreateForm(true);
    setIsNotiTemplEditValue();
  };

  return (
    <>
      {!showCreateForm && (
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
          <div className="cstm-ticket-manage-sec">
            <div className="cstm-ticket-manage-heading">
              <h3 className="cstm-all-pg-ttl operate-ttl">
                Notice Template Management
              </h3>
            </div>
            <PrintPdfCsvExcelDownload
              tableId="tbl-main-download"
              fileName="notice-template"
              pageSize="a4"
            />
          </div>

          <div className="cstm-ticket-manage-sec">
            <div className="cstm-ticket-manage-heading"></div>
            <div className="cstm-ticket-manage-download">
              <button
                className="add-role-btn"
                onClick={() => createNoticeTemplateTinyMCE()}
              >
                Create Notice Template
              </button>
            </div>
          </div>
          <div className="text-center">
            {isLoader ? (
              <div className="text-center spin-load-main">
                <div className="spinner-border"></div>
              </div>
            ) : notifiTemplatesList === null ||
              notifiTemplatesList?.length === 0 ? (
              <div className="no-record-found-main">No Data Found</div>
            ) : (
              <div className="row table-overflow">
                <div className="col-12" id="tbl-main-download">
                  <table className="table-heading table-bordered">
                    <thead>
                      <tr>
                        <th
                          className={`srt-tab ${
                            sorting.key === "notification_name" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting(
                              "notification_name",
                              !sorting.ascending
                            )
                          }
                        >
                          Name
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "template_id" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("template_id", !sorting.ascending)
                          }
                        >
                          Template Id
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "notification_type" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting(
                              "notification_type",
                              !sorting.ascending
                            )
                          }
                        >
                          Type
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "frequency" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("frequency", !sorting.ascending)
                          }
                        >
                          Frequency
                        </th>
                        <th
                          className={`srt-tab ${
                            sorting.key === "status" &&
                            (sorting.ascending ? "srt-ace" : "srt-desc")
                          }`}
                          onClick={() =>
                            applySorting("status", !sorting.ascending)
                          }
                        >
                          Status
                        </th>
                        <th className="cstm-130 row-rmv">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(notifiTemplatesList || []).map((item, i) => (
                        <tr key={i}>
                          <td>{item.notification_name || "-"}</td>
                          <td>{item.template_id || "-"}</td>
                          <td>{item.notification_type || "-"}</td>
                          <td>{item.frequency || "-"}</td>
                          <td>{item.status || "-"}</td>
                          <td className="cstm-card-edit-viewhtml-link row-rmv">
                            <a
                              href="#"
                              className="use-template"
                              onClick={(e) => editNotifiTemplate(e, item)}
                            >
                              Edit
                            </a>
                            <a
                              href="#"
                              className="use-template"
                              onClick={() =>
                                setNotiTemplateViewHTML({
                                  html: item.body,
                                  state: true,
                                })
                              }
                            >
                              View HTML
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {notifiTemplatesList?.length === 0 ||
                    (notifiTemplatesList === null && (
                      <div className="text-center cstm-no-record-found">
                        No Record Found
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showCreateForm && (
        <NoticeTemplateTinyMCE
          setCreateForm={setCreateForm}
          noticeTemplateData={isNotiTemplEditValue}
        />
      )}

      {isNotiTemplateViewHTML.state && (
        <NoticeViewHtml
          isNotiTemplateViewHTML={isNotiTemplateViewHTML}
          setNotiTemplateViewHTML={setNotiTemplateViewHTML}
        />
      )}
    </>
  );
};

export default NoticeTemplateManagement;
