import React, { useState, useEffect } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

import { clearoutAuthUserData } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';

const options = {
   cutout: 100,
   hover: {
      mode: 'index',
      intersect: false,
   },
}

const styles = {
   graphContainer: {
      padding: '20px'
   }
}

function RevenueSummaryViolation() {
   const dispatch = useDispatch()
   const [count, setCount] = React.useState(0);
   const [showAuthToken, setAuthToken] = useState(false);
   const [showType, setType] = React.useState([]);
   const [showCount, setTotalCount] = React.useState([]);
   const [showColour, setColour] = React.useState([]);
   const [showMain, setMain] = React.useState([]);
   const [showErrorHandling, setErrorHandling] = useState(false)
   const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

   const closeError = () => {
      setErrorHandling(false)
   }

   const onClickLogin = (e) => {
      e.preventDefault();
      Cookiesremove();
      window.location.href = "/";
   }

   const data = {
      datasets: [
         {
            label: '# of Votes',
            data: showCount,
            backgroundColor: showColour,
            borderWidth: 1,
         },
      ],
   };


   useEffect(() => {

      initialrevenuefun()
      function initialrevenuefun() {
         Dashboard.getRevenueIssuanceSummary().then(
            (response) => {
               if (response.data.status === 300) {
                  setAuthToken(true)
               } else {
                  if (response.data.status === false) {
                     setErrorHandling(true)
                     setErrorHandleMessage(response.data.message)
                  } else {
                     setMain(response.data.data[0].metadata.consolidated_revenue)
                     setType(response.data.data[0].metadata.codes)
                     setTotalCount(response.data.data[0].metadata.revenue)
                     setColour(response.data.data[0].metadata.colors)
                  }
               }
            }
         ).catch((err) => {
            if (err.response.status === 401) {
               refreshTokenMiddleware(dispatch)
               initialrevenuefun()
            }
         });
      }
      setCount(100);
   }, []);



   return (
      <>

         <div className="row cstm-rsv">
            <div className='col-12'>   <div className="cstm-aos1_1">Revenue Summary By Violations</div></div>
            <div className=" cstm-rsv-mn cstm-rsv-mn2">
               <div className="cstm-rsv-mn-tbl">
                  <div className="cstm-rsv-mn-tbl-mn cstm-rsv-head-clr">
                     <div className="cstm-rsv-mn-tbl-mn-left">Violation Type</div>
                     <div className="cstm-rsv-mn-tbl-mn-right">Revenue</div>
                  </div>
                  {showMain.length !== 0 ?
                     (showMain.map((item, index) => (
                        <div className="cstm-rsv-mn-tbl-mn">
                           <div className="cstm-rsv-mn-tbl-mn-left">{item._id}</div>
                           <div className="cstm-rsv-mn-tbl-mn-right">${item.count}</div>
                        </div>
                     )))
                     :
                     <div className="cstm-no-data-found-revenue">No Data Found</div>
                  }

               </div>
               <div className="cstm-rsv-mn-color">

                  {showMain.map((item, index) => (
                     <div className="cstm-rsv-mn-color-mn">
                        <span className="cstm-rsv-mn-color-mn1" style={{ background: item.color }}></span>
                        <span className="cstm-rsv-mn-color-mn2">{item._id}</span>
                     </div>
                  ))}

               </div>
               <div className="cstm-rsv-mn-map">
                  <div className="revenue_cstm_graph">
                     <Doughnut options={options} data={data} />
                  </div>
               </div>
            </div>
         </div>



      </>
   );
}

export default RevenueSummaryViolation;
