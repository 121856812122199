import {useEffect, useState} from 'react';
import PermitMapListsComponent from './permitMapListsComponent';
import {useDispatch, useSelector} from 'react-redux';
import { permitUserInfo } from '../../../actions/permits';
import dashboardService from '../../../Services/dashboard.service';
import Cookies from "js-cookie"

const PermitMapListsContainer = () => {
  const dispatch = useDispatch();
  const {userAuth} = useSelector(state => state.auth);

  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLocationHandler = async () => {
    setLoading(true);
    try {
      const response = await dashboardService.getLocationList(
        '50000',
        process.env.PERMIT_MAP_LAT || 41.7637111,
        process.env.PERMIT_MAP_LONG || -72.6850932,
        Cookies.get("siteinfo"),
      );
      if (response.data.status) {
        setLocationList(
          response.data.locations.filter(loc => loc.applicable_permit_types),
        );
      }
    } catch (err) {
      if (err.response.status === 401) {
        const payload = {
          token: userAuth.refresh_token_data.token,
          site_id: Cookies.get("siteinfo"),
        };
        getLocationHandler();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocationHandler();
    dispatch(permitUserInfo(null));
  }, []);

  return (
    <>
      {loading ? (
        <div className="cstm-loader">
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        </div>
      ) : (
        <PermitMapListsComponent locationList={locationList} />
      )}
    </>
  );
};

export default PermitMapListsContainer;
