import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const Editor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    const isHtml = (string) => /<\/?[a-z][\s\S]*>/i.test(string);

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      editorContainer.style.minHeight = '300px';
      const quill = new Quill(editorContainer, {
        theme: "snow",
        placeholder: "Start typing...",
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        // quill.setContents(defaultValueRef.current);
        if (isHtml(defaultValueRef.current)) {
          // If it's HTML, use dangerouslyPasteHTML
          quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
        } else {
          // If it's plain text, use setText
          quill.setText(defaultValueRef.current);
        }
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      quill.focus();

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div ref={containerRef}></div>;
  },
);

Editor.displayName = 'Editor';

export default Editor;