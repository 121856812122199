import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Dashboard.css';
// import './Reporting.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store";
import { Branding } from './Global/Branding';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
     <Provider store={store}>
      <Branding>
        <App />
      </Branding>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
