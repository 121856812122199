import "react-image-gallery/styles/css/image-gallery.css";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ModalImage from 'react-modal-image';

import Service from '../../Services/main.service';
import dashboardService from '../../Services/dashboard.service';


import SuspendModal from './SuspendModal';
import ChangeAmountModal from './ChangeAmountModal';
import HearingModel from './HearingModal';

import BackbuttinIMag from '../../Images/back.png';
import box1 from '../../Images/ticket.png';
import Eororicon from '../../Images/error-icon.png';
import PayImg from '../../Images/pay.png';
import { state } from '../Common/dropDownOptions';
import ROInformationModal from './ROInformationModal';
import AppealDetail from '../AppealManagement/AppealDetail';
import { changetotal, changeticket, siteid } from "../../Global/site";
import { refreshTokenMiddleware } from '../customMiddelware'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cookies from 'js-cookie';
import ImageGallery from "react-image-gallery";
import ImageSlider from "../../utile/imageSlider/imageSlider";
import ResendNotice from "../ReportingModule/TicketReporting/ResendNotice";

// check if site is Laz Parking
const isLazParking = () => {
  if (Cookies.get('siteinfo') === '8e9bc50c-95ab-11ee-9a11-c20b99673619' || Cookies.get('siteinfo') === '9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e') {
    return true
  } else return false;
}

const CitationDetails = ({uiElements}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changeTotal = changetotal();
  const location = useLocation();
  const [rightClick, setRightClick] = React.useState(-1);
  const [showCount, setCount] = useState(0);
  const [showCitationDetails, setCitationDetails] = useState({});
  const [showUserDetails, setUserDetails] = useState({});
  const [showAppealDetails, setAppealDetails] = useState({});
  const [showCitationInformation, setCitationInformation] = useState(false);
  const [showCitationDataDisplay, setCitationDataDisplay] = useState(false);
  const [showImages, setImages] = React.useState([]);
  const [showImagesEmptyMessage, setImagesEmptyMessage] = useState(true);
  const showSideId = siteid();
  const changeName = changeticket();

  const [isEligibleOpen, setEligibleOpen] = useState(false);
  const [isEligibleMsg, setEligibleMsg] = useState('');
  const [citePayload, setCitePayload] = useState({});

  const [modalOpen, setModal] = useState(false);
  const [isOpenPyC, setOpenPyC] = React.useState(false);
  const [messageSuc, setMessageSuc] = React.useState('');
  const [messageErr, setMessageErr] = React.useState('');
  const [isOpen, setOpen] = React.useState(false);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [commercialData, setCommercialData] = React.useState({});
  const [commercialUpdateData, setCommercialUpdateData] = React.useState({
    ticket_number: '',
    site_id: showSideId.siteinfo,
    fine_amount: null,
    nsf_amount: null,
    boot_tow_fee: null,
    collection_amount: null,
    balance_due: null,
    notice_date: {},
    dmv_case_number: '',
    remark: '',
    paid_date: null,
    fine_increase_date: null,
    nsf_applied_date: null,
    boot_applied_date: null,
    collection_date: null,
    adjusted_balance_date: null,
    due_date:null
  });
  const [clipboardtext, setClipboardText] = useState('');
  const [pageLoader, setPageLoader] = useState(true);
  const [sorting, setSorting] = useState({ field: 'xyz', ascending: true, type: '' })
  const [service_fee, setService_fee] = useState(0);
  const [imageId,setImageId] = useState();
  const [showImagesModalSlider,setShowImagesModalSlider] = useState(false)
  const [showResendNotice, setShowResendNotice] = useState(false);

  useEffect(() => {
    const currentDataCopy = { ...showCitationDetails };
    if (sorting.type === 'audit-trail') {
      const sortedCurrentdata = (currentDataCopy?.audit_trail || []).sort((a, b) => {
        return a[sorting.key].localeCompare(b[sorting.key]);
      });
      setCitationDetails({ ...currentDataCopy, ['audit_trail']: sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse() });
    } else if (sorting.type === 'notice') {
      const sortedCurrentdata = (currentDataCopy?.notification_audit_trail || []).sort((a, b) => {
        return a[sorting.key].localeCompare(b[sorting.key]);
      });
      setCitationDetails({ ...currentDataCopy, ['notification_audit_trail']: sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse() });
    }

  }, [sorting]);

  const applySorting = (key, ascending, type) => {
    setSorting({ key: key, ascending: ascending, type: type });
  }

  useEffect(() => {

    setPageLoader(true)
    if (location.state) {
      getCitationnAlldetails();
    } else {
      navigate('/search');
    }
  }, []);

  const handleCitationDetailpage = (id) => {

    Cookies.set("appealid", id)
    setCitationInformation(false)
    setCitationDataDisplay(true)
  }
  const backButtonClick = () => {
    setCitationInformation(true);
    setShowResendNotice(false)
  }

  function getCitationnAlldetails() {
    let loca_state = location.state;

    Service.singleAppealByTicket(loca_state.citation_number)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data !== null && response.data.data !== undefined) {
            setAppealDetails(response.data.data)
          }
        }

      }).catch((err) => {
        if (err.response.status === 404) {
          console.log(err)
        }
      });

    var ticketNumber = 'ticket_number=' + loca_state.citation_number;

    dataofcitationfun()
    function dataofcitationfun() {
      Service.citationDetails(ticketNumber)
        .then((response) => {
          const details_d = response.data.data.detail;
          const details_u = response.data.data.user_detail;
          setUserDetails(details_u);
          if (response.data.status === true && details_d !== null) {
            setService_fee(response.data.data.service_fee)
            setCitationDetails(details_d);
            setCitationInformation(true);
            setCitePayload(loca_state);
            setPageLoader(false)

            if (details_d.images.length !== 0 && details_d.images !== null) {
              var imagesLink = details_d.images;

              var imageData = {
                links: Object.assign({}, imagesLink),
                download_type: 'CitationImages',
                site_id: showSideId.siteinfo,
              };
              imagedownloadfun()
              function imagedownloadfun() {
                Service.downloadImage(imageData)
                  .then((responseImage) => {
                    setImages(responseImage.data.metadata);
                    setPageLoader(false)
                  })
                  .catch((err) => {
                    if (err.response.status === 401) {
                      refreshTokenMiddleware(dispatch)
                      imagedownloadfun()
                    }
                  });
              }
              // setPageLoader(false)
            } else {
              setImagesEmptyMessage(false);
            }
          } else {
            navigate('/search-result');
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            dataofcitationfun()
          }
        });
    }

    setCount(100);
  }

  const openModal = async (event) => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal },
      },
    } = event;

    if (modal === 'appeal-modal') {
      appealeligiblefun()
      async function appealeligiblefun() {
        try {
          const result = await dashboardService.isEligibleForAppeal(
            showCitationDetails.header_details.citation_number
          );
          if (result.data.status) {
            navigate('/appeal-create', { state: citePayload.citation_detail[0] });
          } else {
            setEligibleOpen(true);
            setEligibleMsg(result.data.message);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            appealeligiblefun()
          } else {

            setEligibleOpen(true);
            setEligibleMsg(err.response.data.message || err.message);
          }
        }
      }
    } else if (modal === 'hearing-modal') {
      hearingeligiblefun()
      async function hearingeligiblefun() {
        try {
          const result = await dashboardService.isEligibleForHearingAPI(
            showCitationDetails.header_details.citation_number
          );
          if (result.data.status) {
            if (modal) setModal(modal);
          } else {
            setEligibleOpen(true);
            setEligibleMsg(result.data.message);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            hearingeligiblefun()
          } else {
            setEligibleOpen(true);
            setEligibleMsg(err.response.data.message || err.message);
          }
        }
      }
    } else {
      if (modal) setModal(modal);
    }
  };


  const closeModal = () => {
    if (modalOpen === 'ro-modal') {
      getCitationnAlldetails();
    }
    setModal('');
    setOpenPyC(false);
    setEligibleOpen(false);
    setEligibleMsg('');
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setOpenPyC(true);
  };

  const selectPamentMethod = (type) => {

    if (type === 'card') {
      navigate('/card-payment', {
        state: { payloadCitation: citePayload, path: 'search-ticket-detail' },
      });
    } else if (type === 'cash') {
      navigate('/cash-payment', {
        state: { payloadCitation: citePayload, path: 'search-ticket-detail' },
      });
    } else {
      navigate('/cheque-payment', {
        state: { payloadCitation: citePayload, path: 'search-ticket-detail' },
      });
    }
  };

  function datetimeLocal() {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16);
  }

  const handleTabChange = async (tab) => {
    if (tab === 'citation-info') {
      getCitationnAlldetails();
    } else if (tab === 'commercial') {
      setOpen(true);
      commercialdatafetchfun()
      async function commercialdatafetchfun() {
        try {
          const result = await dashboardService.getCommercialDataAPI(showCitationDetails.header_details.citation_number);

          setCommercialData(result.data.data);
          commercialUpdateData.ticket_number = result.data.data.citation_number;
          commercialUpdateData.notice_date = result.data.data.notice_date;

          commercialUpdateData.paid_date = result.data.data.paid_date ? result.data.data.paid_date.substring(0, 16) : datetimeLocal()
          commercialUpdateData.due_date = result.data.data.due_date ? result.data.data.due_date.substring(0, 16) : datetimeLocal()
          commercialUpdateData.fine_increase_date = result.data.data.fine_increase_date ? result.data.data.fine_increase_date.substring(0, 16) : datetimeLocal()
          commercialUpdateData.nsf_applied_date = result.data.data.nsf_applied_date ? result.data.data.nsf_applied_date.substring(0, 16) : datetimeLocal()
          commercialUpdateData.boot_applied_date = result.data.data.boot_applied_date ? result.data.data.boot_applied_date.substring(0, 16) : datetimeLocal()
          commercialUpdateData.collection_date = result.data.data.collection_date ? result.data.data.collection_date.substring(0, 16) : datetimeLocal()
          commercialUpdateData.adjusted_balance_date = result.data.data.adjusted_balance_date ? result.data.data.adjusted_balance_date.substring(0, 16) : datetimeLocal()

          setCommercialUpdateData({
            ...commercialUpdateData,
            fine_amount: null,
            nsf_amount: null,
            boot_tow_fee: null,
            collection_amount: null,
            balance_due: null,
            notice_date: {},
            dmv_case_number: '',
            remark: ''
          });
          setOpen(false);

          setTimeout(() => {
            setMessageSuc('');
            setMessageErr('');
          }, 3000);
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            commercialdatafetchfun()
          }
        }
      }
    }
  };

  const onChangeDateOfCommercial = (e, i, key) => {
    commercialUpdateData.notice_date[key] = e.target.value;
    setCommercialUpdateData({ ...commercialUpdateData });
  };

  const onChangeCommercialUpdateDatesEvent = (e) => {
    const { name, value } = e.target

    setCommercialUpdateData({ ...commercialUpdateData, [name]: value });
  }

  const onChangeCommercialInput = (e) => {
    setCommercialUpdateData({
      ...commercialUpdateData,
      [e.target.name]: e.target.value,
    });
  };

  const onUpdateCommercial = async (e) => {
    setBtnLoader(true);
    e.preventDefault();

    Object.keys(commercialUpdateData.notice_date).map((key, i) => {
      let nd = commercialUpdateData.notice_date[key].substring(0, 16);
      commercialUpdateData.notice_date[key] = nd + ':00.000+00:00';
    });
    commercialUpdateData.fine_amount = Number(commercialUpdateData.fine_amount);
    commercialUpdateData.nsf_amount = Number(commercialUpdateData.nsf_amount);
    commercialUpdateData.boot_tow_fee = Number(
      commercialUpdateData.boot_tow_fee
    );
    commercialUpdateData.collection_amount = Number(
      commercialUpdateData.collection_amount
    );
    commercialUpdateData.balance_due = Number(commercialUpdateData.balance_due);

    commercialUpdateData.paid_date = commercialUpdateData.paid_date ? commercialUpdateData.paid_date + ':00.00' : commercialUpdateData.paid_date
    commercialUpdateData.due_date = commercialUpdateData.due_date ? commercialUpdateData.due_date + ':00.00' : commercialUpdateData.due_date
    commercialUpdateData.fine_increase_date = commercialUpdateData.fine_increase_date ? commercialUpdateData.fine_increase_date + ':00.00' : commercialUpdateData.fine_increase_date
    commercialUpdateData.nsf_applied_date = commercialUpdateData.nsf_applied_date ? commercialUpdateData.nsf_applied_date + ':00.00' : commercialUpdateData.nsf_applied_date
    commercialUpdateData.boot_applied_date = commercialUpdateData.boot_applied_date ? commercialUpdateData.boot_applied_date + ':00.00' : commercialUpdateData.boot_applied_date
    commercialUpdateData.collection_date = commercialUpdateData.collection_date ? commercialUpdateData.collection_date + ':00.00' : commercialUpdateData.collection_date
    commercialUpdateData.adjusted_balance_date = commercialUpdateData.adjusted_balance_date ? commercialUpdateData.adjusted_balance_date + ':00.00' : commercialUpdateData.adjusted_balance_date

    commercialupdatefun()
    async function commercialupdatefun() {
      try {
        const result = await dashboardService.updateCommercialAPI(
          commercialUpdateData
        );
        if (result.data.success) {
          showCitationDetails.fine_amount = commercialUpdateData.fine_amount;
          setCitationDetails({ ...showCitationDetails });
          handleTabChange('commercial');
          setMessageSuc('Commercial updated!');
          getCitationnAlldetails();
        } else {
          setMessageErr('Something went wrong.');
        }
        setBtnLoader(false);
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          commercialupdatefun()
        } else {
          setMessageErr('Error while update commercial');
        }
        setBtnLoader(false);
      }
    }
  };

  const downloadAttechment = async (e, i) => {
    e.preventDefault();
    let url = "";
  
    const imageData = {
      links: Object.assign({}, { 0: e.target.dataset.id }),
      download_type: "CitationImages",
      site_id: showSideId.siteinfo,
    };
  
    try {
      const responseImage = await Service.downloadImage(imageData);
      if (responseImage.data) {
        url = responseImage.data.metadata[0].url;
        setClipboardText(url);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        // Retry download if token was refreshed
        return downloadAttechment(e, i);
      }
    }
  
    if (e.nativeEvent.button === 2) {
      setRightClick(i);
    } else {
      // Check if the url is available before triggering the download
      if (url) {
        // Create an anchor element
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.target = "_blank"; // Open in a new tab
        anchor.download = "attachment"; // You can set a default download filename here
  
        // Trigger a click event on the anchor element
        document.body.appendChild(anchor);
        anchor.click();
  
        // Remove the anchor element after the click event
        document.body.removeChild(anchor);
  
        setRightClick(-1);
      } else {
        console.error("Download URL not available.");
      }
    }
  };

  // const downloadAttechment = async (e, i) => {

  //   e.preventDefault();
  //   let url = ''
  //   var imageData = {
  //     links: Object.assign({}, { 0: e.target.dataset.id }),
  //     download_type: 'CitationImages',
  //     site_id: showSideId.siteinfo,
  //   };
  //   await downloadfun()
  //   async function downloadfun() {
  //     try {
  //       const responseImage = await Service.downloadImage(imageData)
  //       if (responseImage.data) {
  //         url = responseImage.data.metadata[0].url

  //         setClipboardText(responseImage.data.metadata[0].url)
  //       }
  //     } catch (err) {
  //       if (err.response.status === 401) {
  //         refreshTokenMiddleware(dispatch)
  //         downloadfun()
  //       }
  //     }
  //   }
  //   if (e.nativeEvent.button === 2) {
  //     setRightClick(i)
  //   } else {
  //     filedownload(url)
  //     setRightClick(-1)
  //   }
  // }

  function filedownload(url) {

    const a = document.createElement('a')
    a.href = url
    a.download = 'download'
    document.body.appendChild(a)
    a.click()
    return a
  }


  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }


  const slideImages = showImages.map(item => ({
      url: item.url,
  }))

  const sliderPopupRef = useRef(null);

  useEffect(() => {
    if (sliderPopupRef) {
      sliderPopupRef?.current?.focus();
    }
  }, [showImagesModalSlider]);

  return (
    <>
      {showCitationInformation ?
        <>
          <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
            <div className="row">
              <div className="col-12 back-button back-button-citationdetails">
                <a onClick={() => { navigate(-1) }}>
                  <img src={BackbuttinIMag} alt="img" className="img-fluid" /> Back
                </a>
              </div>
            </div>

            <div className="rightpanel-box">
              {pageLoader ?
                <div className='text-center'>
                  <div className="spinner-border"></div>
                </div>
                :
                <>
                  <div className="row text-center">
                    <div className="col-12 mb-3">
                      <ul className="nav nav-tabs">
                        <li
                          className="nav-item"
                          onClick={() => handleTabChange('citation-info')}
                        >
                          <a
                            className="nav-link bg-box active"
                            data-toggle="tab"
                            href="#citation-info"
                          >
                            {changeTotal} Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link bg-box"
                            data-toggle="tab"
                            href="#notice"
                          >
                            Notice
                          </a>
                        </li>
                        <li className="nav-item align-box">
                          <a
                            className="nav-link bg-box"
                            data-toggle="tab"
                            href="#audit-trail"
                          >
                            Audit Trail
                          </a>
                        </li>
                        <li
                          className="nav-item align-box"
                          onClick={() => handleTabChange('commercial')}
                        >
                          <a
                            className="nav-link bg-box"
                            data-toggle="tab"
                            href="#commercial"
                          >
                            Commercial
                          </a>
                        </li>

                        {showCitationDetails.ro_request_status !== "" &&
                          <li
                            className="nav-item align-box"
                            onClick={() => handleTabChange('ro-information')}
                          >
                            <a
                              className="nav-link bg-box"
                              data-toggle="tab"
                              href="#ro-information"
                            >
                              RO Information
                            </a>
                          </li>
                        }

                        <li
                          className="nav-item align-box"
                          onClick={() => handleTabChange('user-detail')}
                        >
                          <a
                            className="nav-link bg-box"
                            data-toggle="tab"
                            href="#user-detail"
                          >
                            User Detail
                          </a>
                        </li>
                        <li
                          className="nav-item align-box"
                          onClick={() => handleTabChange('payment-detail')}
                        >
                          <a
                            className="nav-link bg-box"
                            data-toggle="tab"
                            href="#payment-detail"
                          >
                            Payment Detail
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="rightpanale-box2">
                    <div className="row">
                      <div className="col-12">
                        <div className="tab-content">
                          <div id="citation-info" className="tab-pane active show">
                            <div className="tab-content">
                              <div id="Dashboard" className="tab-pane active">
                                <div className="">
                                  <div className='cstm-sus'>
                                    <div className='cstm-sus-left'>
                                      <div className="cstm-rro">
                                        {/* <button onClick={openModal} data-modal="changeStatus-modal">Change status</button> */}
                                        {/* <button onClick={openModal} data-modal="changeAmount-modal">Change amount</button> */}
                                        {
                                        // uiElements.includes('BUTTON_TICKET_MANAGEMENT_SUSPEND_BUTTON') && 
                                        <button
                                          onClick={openModal}
                                          data-modal="suspend-modal"
                                        >
                                     {showCitationDetails.status.toLowerCase() ===
                                      "suspended"
                                        ? "Unsuspend"
                                        : "Suspend"}
                                        </button>}
                                        {showCitationDetails.status.toLowerCase() ===
                                          'voidandreissue' ||
                                          showCitationDetails.status.toLowerCase() ===
                                          'cancelled' ||
                                          showCitationDetails.status.toLowerCase() ===
                                          'paid' ? (
                                          ''
                                        ) : showCitationDetails.type.toLowerCase() ===
                                          'warning' ||
                                          showCitationDetails.fine_amount === 0 ? (
                                          ''
                                        ) : (
                                          <>
                                            <button
                                              onClick={openModal}
                                              data-modal="appeal-modal"
                                            >
                                              Appeal
                                            </button>
                                            {/* <button onClick={handlePayment} data-modal="pay-modal">Pay</button> */}
                                          </>
                                        )}
                                        <button
                                          onClick={openModal}
                                          data-modal="hearing-modal"
                                        >
                                          Hearing
                                        </button>
                                        <button>
                                          Support Note
                                        </button>
                                        {showCitationDetails?.notice_detail &&
                                      showCitationDetails?.notice_detail
                                        .length > 0 &&
                                      showCitationDetails?.notice_detail.filter(
                                        (item) => item.status !== ""
                                      ).length > 0 && (
                                        // uiElements.includes('BUTTON_TICKET_MANAGEMENT_RESEND_NOTICE_BUTTON') && 
                                        <button
                                          onClick={() => {
                                            setShowResendNotice(true);
                                            setCitationInformation(false);
                                          }}
                                        >
                                          Resend Notice
                                        </button>
                                      )}
                                      </div>
                                    </div>
                                    <div className='cstm-sus-right'>

                                      {showCitationDetails.status === 'Suspended' &&
                                        <>
                                          <h4>Appeal status</h4>

                                          <Link onClick={() => handleCitationDetailpage(showAppealDetails.id)}>{showAppealDetails.appeal_status}</Link>
                                        </>
                                      }
                                    </div>
                                  </div>
                                  <h4>Images</h4>
                                  {showImagesEmptyMessage && (
                                    <div className="">
                                      <div className="cstm-cdp-img image-citation-detail-cstm">
                                        {showImages.map((images, ix) => (
                                          <img src={images.url} className="img-fluid" onClick={() => (setImageId(Number(images.image_spec)),setShowImagesModalSlider(true))} />
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                  <hr />
                                  <h4>{changeName} Info</h4>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>{changeName} Number</p>
                                      <h5>
                                        {
                                          showCitationDetails.header_details
                                            .citation_number
                                        }
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>{changeName} Issue Date</p>
                                      <h5>
                                        {showCitationDetails.citation_issue_timestamp !==
                                          undefined &&
                                          showCitationDetails.citation_issue_timestamp !==
                                          null &&
                                          showCitationDetails.citation_issue_timestamp.substring(0, 4) !== '0000' && showCitationDetails.citation_issue_timestamp.substring(0, 4) !== '0001' &&
                                          showCitationDetails.citation_issue_timestamp !==
                                          ''
                                          ? moment(showCitationDetails.citation_issue_timestamp.split('T')[0]).format('MM/DD/YYYY') + " " + (showCitationDetails.citation_issue_timestamp).substring(11, 19)
                                          : '-'}
                                      </h5>
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>{changeName} Due Date</p>
                                      <h5>
                                        {showCitationDetails.current_due_date !==
                                          undefined &&
                                          showCitationDetails.current_due_date !==
                                          null &&
                                          showCitationDetails.current_due_date.substring(0, 4) !== '0000' && showCitationDetails.current_due_date.substring(0, 4) !== '0001' &&
                                          showCitationDetails.current_due_date !== ''
                                          ? moment(showCitationDetails.current_due_date.split('T')[0]).format('MM/DD/YYYY') + " " + (showCitationDetails.current_due_date).substring(11, 19)
                                          : '-'}
                                      </h5>
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Service fee</p>
                                      {
                                        service_fee !== undefined && service_fee !== null && service_fee !== "" ?
                                        <h5>{service_fee}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                      {/* {showSideId.siteinfo === "a98dcd6a-cfc2-11ed-ad98-66070e279278" ?
                                        <h5>$3.95</h5>
                                        :         
                                        <h5>$3.50</h5>
                                      }  */}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-3 result1 hide-col">
                                      <p>Status</p>
                                      <h5>
                                        {showCitationDetails.status !== '' &&
                                          showCitationDetails.status !== ' ' &&
                                          showCitationDetails.status !== undefined
                                          ? showCitationDetails.status : '-'}
                                      </h5>
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Drive Off</p>
                                      {showCitationDetails.drive_off !== "" && showCitationDetails.drive_off !== "null" && showCitationDetails.drive_off !== null && showCitationDetails.drive_off !== undefined ?
                                        (showCitationDetails.drive_off ?
                                          <h5>True</h5>
                                          :
                                          <h5>False</h5>
                                        )
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>TVR</p>
                                      {showCitationDetails.tvr !== "" && showCitationDetails.tvr !== "null" && showCitationDetails.tvr !== null && showCitationDetails.tvr !== undefined ?
                                        (showCitationDetails.tvr ?
                                          <h5>True</h5>
                                          :
                                          <h5>False</h5>
                                        )
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>{changeName} Type</p>
                                      {showCitationDetails.type !== "" && showCitationDetails.type !== "null" && showCitationDetails.type !== null && showCitationDetails.type !== undefined ?
                                        <h5>{showCitationDetails.type}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    {showCitationDetails?.category ? <div className="col-3 result1 hide-col">
                                      <p>{changeName} Category</p>
                                      <h5>{showCitationDetails.category === 'ticket' ? 'Ticket' : 'Invoice'}</h5>
                                    </div> : null}
                                  </div>
                                  <hr />
                                  <h4>Officer Details</h4>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Officer Name</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.officer_name !== null && showCitationDetails.officer_details.officer_name !== "" && showCitationDetails.officer_details.officer_name !== "null" && showCitationDetails.officer_details.officer_name !== undefined ?
                                        <h5>{showCitationDetails.officer_details.officer_name}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Peo Name</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.peo_lname !== "" && showCitationDetails.officer_details.peo_lname !== "null" && showCitationDetails.officer_details.peo_lname !== null && showCitationDetails.officer_details.peo_lname !== undefined ?
                                        <h5>{showCitationDetails.officer_details.peo_lname}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Badge ID</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.badge_id !== "" && showCitationDetails.officer_details.badge_id !== "null" && showCitationDetails.officer_details.badge_id !== null && showCitationDetails.officer_details.badge_id !== undefined ?
                                        <h5>{showCitationDetails.officer_details.badge_id}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Squad</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.squad !== "" && showCitationDetails.officer_details.squad !== "null" && showCitationDetails.officer_details.squad !== null && showCitationDetails.officer_details.squad !== undefined ?
                                        <h5>{showCitationDetails.officer_details.squad}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Agency</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.agency !== "" && showCitationDetails.officer_details.agency !== "null" && showCitationDetails.officer_details.agency !== null && showCitationDetails.officer_details.agency !== undefined ?
                                        <h5>{showCitationDetails.officer_details.agency}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Beat</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.beat !== "" && showCitationDetails.officer_details.beat !== "null" && showCitationDetails.officer_details.beat !== null && showCitationDetails.officer_details.beat !== undefined ?
                                        <h5>{showCitationDetails.officer_details.beat}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Zone</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.zone !== "" && showCitationDetails.officer_details.zone !== "null" && showCitationDetails.officer_details.zone !== null && showCitationDetails.officer_details.zone !== undefined ?
                                        <h5>{showCitationDetails.officer_details.zone}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Shift</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.shift !== "" && showCitationDetails.officer_details.shift !== "null" && showCitationDetails.officer_details.shift !== null && showCitationDetails.officer_details.shift !== undefined ?
                                        <h5>{showCitationDetails.officer_details.shift}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Device ID</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.device_id !== "" && showCitationDetails.officer_details.device_id !== "null" && showCitationDetails.officer_details.device_id !== null && showCitationDetails.officer_details.device_id !== undefined ?
                                        <h5>{showCitationDetails.officer_details.device_id}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Device Friendly Name</p>
                                      {showCitationDetails.officer_details !== null && showCitationDetails.officer_details.device_friendly_name !== "" && showCitationDetails.officer_details.device_friendly_name !== "null" && showCitationDetails.officer_details.device_friendly_name !== null && showCitationDetails.officer_details.device_friendly_name !== undefined ?
                                        <h5>{showCitationDetails.officer_details.device_friendly_name}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>

                                  <hr />

                                  <h4>Vehicle Details</h4>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Vehicle Make</p>
                                      {showCitationDetails.vehicle_details.make !== "" && showCitationDetails.vehicle_details.make !== "null" && showCitationDetails.vehicle_details.make !== null && showCitationDetails.vehicle_details.make !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.make}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Vehicle Model</p>
                                      {showCitationDetails.vehicle_details.model !== "" && showCitationDetails.vehicle_details.model !== "null" && showCitationDetails.vehicle_details.model !== null && showCitationDetails.vehicle_details.model !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.model}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Vehicle Color</p>
                                      {showCitationDetails.vehicle_details.color !== "" && showCitationDetails.vehicle_details.color !== "null" && showCitationDetails.vehicle_details.color !== null && showCitationDetails.vehicle_details.color !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.color}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Licence Plate Number</p>
                                      {showCitationDetails.vehicle_details.lp_number !== "" && showCitationDetails.vehicle_details.lp_number !== "null" && showCitationDetails.vehicle_details.lp_number !== null && showCitationDetails.vehicle_details.lp_number !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.lp_number}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Licence State</p>
                                      {showCitationDetails.vehicle_details.state !== "" && showCitationDetails.vehicle_details.state !== "null" && showCitationDetails.vehicle_details.state !== null && showCitationDetails.vehicle_details.state !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.state}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>License Expiry</p>
                                      {showCitationDetails.vehicle_details.license_expiry !== "" && showCitationDetails.vehicle_details.license_expiry !== "null" && showCitationDetails.vehicle_details.license_expiry !== null && showCitationDetails.vehicle_details.license_expiry !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.license_expiry}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Body Style</p>
                                      {showCitationDetails.vehicle_details.body_style !== "" && showCitationDetails.vehicle_details.body_style !== "null" && showCitationDetails.vehicle_details.body_style !== null && showCitationDetails.vehicle_details.body_style !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.body_style}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Vin Number</p>
                                      {showCitationDetails.vehicle_details.vin_number !== "" && showCitationDetails.vehicle_details.vin_number !== "null" && showCitationDetails.vehicle_details.vin_number !== null && showCitationDetails.vehicle_details.vin_number !== undefined ?
                                        <h5>{showCitationDetails.vehicle_details.vin_number}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>
                                  <hr />
                                  <h4>Location Details</h4>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Block</p>
                                      {showCitationDetails.location.block !== "" && showCitationDetails.location.block !== "null" && showCitationDetails.location.block !== null && showCitationDetails.location.block !== undefined ?
                                        <h5>{showCitationDetails.location.block}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Street</p>
                                      {showCitationDetails.location.street !== "" && showCitationDetails.location.street !== "null" && showCitationDetails.location.street !== null && showCitationDetails.location.street !== undefined ?
                                        <h5>{showCitationDetails.location.street}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Side</p>
                                      {showCitationDetails.location.side !== "" && showCitationDetails.location.side !== "null" && showCitationDetails.location.side !== null && showCitationDetails.location.side !== undefined ?
                                        <h5>{showCitationDetails.location.side}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Direction</p>
                                      {showCitationDetails.location.direction !== "" && showCitationDetails.location.direction !== "null" && showCitationDetails.location.direction !== null && showCitationDetails.location.direction !== undefined ?
                                        <h5>{showCitationDetails.location.direction}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Meter No</p>
                                      {showCitationDetails.location.meter !== "" && showCitationDetails.location.meter !== "null" && showCitationDetails.location.meter !== null && showCitationDetails.location.meter !== undefined ?
                                        <h5>{showCitationDetails.location.meter}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Lot No</p>
                                      {showCitationDetails.location.lot !== "" && showCitationDetails.location.lot !== "null" && showCitationDetails.location.lot !== null && showCitationDetails.location.lot !== undefined ?
                                        <h5>{showCitationDetails.location.lot}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Space Number</p>
                                      {showCitationDetails.location.space_id !== "" && showCitationDetails.location.space_id !== "null" && showCitationDetails.location.space_id !== null && showCitationDetails.location.space_id !== undefined ?
                                        <h5>{showCitationDetails.location.space_id}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>
                                  <hr />

                                  <h4>Violation Details</h4>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Code</p>
                                      {showCitationDetails.violation_details.code !== "" && showCitationDetails.violation_details.code !== "null" && showCitationDetails.violation_details.code !== null && showCitationDetails.violation_details.code !== undefined ?
                                        <h5>{showCitationDetails.violation_details.code}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Description</p>
                                      {showCitationDetails.violation_details.description !== "" && showCitationDetails.violation_details.description !== "null" && showCitationDetails.violation_details.description !== null && showCitationDetails.violation_details.description !== undefined ?
                                        <h5>{showCitationDetails.violation_details.description}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Fine Amount</p>
                                      {showCitationDetails.violation_details.fine !== "" && showCitationDetails.violation_details.fine !== "null" && showCitationDetails.violation_details.fine !== null && showCitationDetails.violation_details.fine !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.violation_details.fine)}</h5>
                                        :
                                        <h5>$0.00</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Original Amount</p>
                                      {showCitationDetails.violation_details.original_amount !== "" && showCitationDetails.violation_details.original_amount !== "null" && showCitationDetails.violation_details.original_amount !== null && showCitationDetails.violation_details.original_amount !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.violation_details.original_amount)}</h5>
                                        :
                                        <h5>$0.00</h5>
                                      }
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Amount Due</p>
                                      {showCitationDetails.violation_details.amount_due !== "" && showCitationDetails.violation_details.amount_due !== "null" && showCitationDetails.violation_details.amount_due !== null && showCitationDetails.violation_details.amount_due !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.violation_details.amount_due)}</h5>
                                        :
                                        <h5>$0.00</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Due After 30 Days</p>
                                      {showCitationDetails.violation_details.due_30_days !== "" && showCitationDetails.violation_details.due_30_days !== "null" && showCitationDetails.violation_details.due_30_days !== null && showCitationDetails.violation_details.due_30_days !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.violation_details.due_30_days)}</h5>
                                        :
                                        <h5>0.00</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Due After 15 Days</p>
                                      {showCitationDetails.violation_details.due_15_days !== "" && showCitationDetails.violation_details.due_15_days !== "null" && showCitationDetails.violation_details.due_15_days !== null && showCitationDetails.violation_details.due_15_days !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.violation_details.due_15_days)}</h5>
                                        :
                                        <h5>0.00</h5>
                                      }
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Due After 45 Days</p>
                                      {showCitationDetails.violation_details.due_45_days !== "" && showCitationDetails.violation_details.due_45_days !== "null" && showCitationDetails.violation_details.due_45_days !== null && showCitationDetails.violation_details.due_45_days !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.violation_details.due_45_days)}</h5>
                                        :
                                        <h5>0.00</h5>
                                      }
                                    </div>
                                  </div>
                                  <hr />

                                  <h4>Comment Details</h4>
                                  <div className="row">
                                    <div className="col-xl-6 col-12 result1">
                                      <p>Note 1</p>
                                      {showCitationDetails.comment_details.note_1 !== "" && showCitationDetails.comment_details.note_1 !== "null" && showCitationDetails.comment_details.note_1 !== null && showCitationDetails.comment_details.note_1 !== undefined ?
                                        <h5>{showCitationDetails.comment_details.note_1}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-6 col-12 result1">
                                      <p>Note 2</p>
                                      {showCitationDetails.comment_details.note_2 !== "" && showCitationDetails.comment_details.note_2 !== "null" && showCitationDetails.comment_details.note_2 !== null && showCitationDetails.comment_details.note_2 !== undefined ?
                                        <h5>{showCitationDetails.comment_details.note_2}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-6 col-12 result1">
                                      <p>Remark 1</p>
                                      {showCitationDetails.comment_details.remark_1 !== "" && showCitationDetails.comment_details.remark_1 !== "null" && showCitationDetails.comment_details.remark_1 !== null && showCitationDetails.comment_details.remark_1 !== undefined ?
                                        <h5>{showCitationDetails.comment_details.remark_1}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                    <div className="col-xl-6 col-12 result1">
                                      <p>Remark 2</p>
                                      {showCitationDetails.comment_details.remark_2 !== "" && showCitationDetails.comment_details.remark_2 !== "null" && showCitationDetails.comment_details.remark_2 !== null && showCitationDetails.comment_details.remark_2 !== undefined ?
                                        <h5>{showCitationDetails.comment_details.remark_2}</h5>
                                        :
                                        <h5>-</h5>
                                      }
                                    </div>
                                  </div>

                                  <hr />
                                  <h4>Fine Adjustments</h4>
                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Increase amount</p>
                                      {showCitationDetails.increase_amount !== "" && showCitationDetails.increase_amount !== "null" && showCitationDetails.increase_amount !== null && showCitationDetails.increase_amount !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.increase_amount)}</h5>
                                        :
                                        <h5>$0.00</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>NSF fees</p>
                                      {showCitationDetails.nsf_fee !== "" && showCitationDetails.nsf_fee !== "null" && showCitationDetails.nsf_fee !== null && showCitationDetails.nsf_fee !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.nsf_fee)}</h5>
                                        :
                                        <h5>$0.00</h5>
                                      }
                                    </div>
                                    <div className="col-3 result1 hide-col">
                                      <p>Corrected</p>
                                      <h5>-</h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Dismissed</p>
                                      <h5>-</h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-3 result1 hide-col">
                                      <p>Balance due</p>
                                      <h5>-</h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Boot Tow Fee</p>
                                      {showCitationDetails.boot_tow_fee !== "" && showCitationDetails.boot_tow_fee !== "null" && showCitationDetails.boot_tow_fee !== null && showCitationDetails.boot_tow_fee !== undefined ?
                                        <h5>${convertDecimal(showCitationDetails.boot_tow_fee)}</h5>
                                        :
                                        <h5>$0.00</h5>
                                      }
                                    </div>
                                  </div>

                                  {showCitationDetails.escalation_detail === null ?
                                    ''
                                    :
                                    <>
                                      <hr />
                                      <h4>Escalation details</h4>
                                      <div className="row">
                                        {(showCitationDetails.escalation_detail || []).map((item, i) => (
                                          <div key={i} className='col-3 result1 hide-col'>
                                            <p>Amount due after {moment(item.date.split('T')[0]).format('MM/DD/YYYY') + " " + (item.date).substring(11, 19)}</p>
                                            <h5>${convertDecimal(item.amount || '0')}</h5>
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  }
                                  <hr />
                                  <h4>Citation status</h4>
                                  <div className="row">
                                    {showCitationDetails.late_fee_detail !== null &&
                                      showCitationDetails.late_fee_detail !==
                                      undefined &&
                                      Object.keys(
                                        showCitationDetails.late_fee_detail
                                      ).map((item, index) => (
                                        <div
                                          key={index}
                                          className="col-xl-3 col-6 result1"
                                        >
                                          <p>{item}</p>
                                          <h5>
                                            {
                                              showCitationDetails.late_fee_detail[
                                              item
                                              ]
                                            }
                                          </h5>
                                        </div>
                                      ))}
                                    <div className="col-3 result1 hide-col">
                                      <p>Boot date</p>
                                      <h5>-</h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Tow date</p>
                                      <h5>-</h5>
                                    </div>
                                    {(showCitationDetails.notice_detail || []).map((item, i) => (
                                      <div key={i} className="col-xl-3 col-6 result1">
                                        <p>{i + 1}st notice date</p>
                                        <h5>{moment(item.notice_date.split('T')[0]).format('MM/DD/YYYY') + " " + (item.notice_date).substring(11, 19)}</h5>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div id="notice" className="tab-pane fade">
                            <div className="row">
                              <div className="col-10 userdetail-txt1"></div>
                              <div className="col-2 text-right">
                                <button className="pay-btn">
                                  Notice
                                </button>
                              </div>
                            </div>
                            <div className="row table-overflow">
                              <div className="col-12">
                                <table className="table-heading table-bordered">
                                  <thead>
                                    <tr>
                                      <th className={`srt-tab ${sorting.key === 'CreatedAt' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('CreatedAt', !sorting.ascending, 'notice')}>date</th>
                                      <th className={`srt-tab ${sorting.key === 'Email' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('Email', !sorting.ascending, 'notice')}>Email sent to</th>
                                      <th className={`srt-tab ${sorting.key === 'SentBy' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('SentBy', !sorting.ascending, 'notice')}>Sent By</th>
                                      <th className={`srt-tab ${sorting.key === 'NotificationType' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('NotificationType', !sorting.ascending, 'notice')}>Notification Type</th>
                                      <th className={`srt-tab ${sorting.key === 'Address' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('Address', !sorting.ascending, 'notice')}>Address</th>
                                      <th>Attachments</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(
                                      showCitationDetails?.notification_audit_trail ||
                                      []
                                    ).map((item, i) => (
                                      <tr key={i}>
                                        {item.CreatedAt !== "" && item.CreatedAt !== null && item.CreatedAt !== "null" && item.CreatedAt !== undefined && item.CreatedAt.substring(0, 4) !== '0000' && item.CreatedAt.substring(0, 4) !== '0001' ?
                                          <td >
                                            {moment(item.CreatedAt)
                                              .utc()
                                              .format('DD MMM, YYYY HH:MM:SS') || '-'}
                                          </td>
                                          :
                                          <td>-</td>
                                        }
                                        <td>{item.Email || '-'}</td>
                                        <td>{item.SentBy || '-'}</td>
                                        <td>{item.NotificationType || '-'}</td>
                                        <td>{item.Address || '-'}</td>
                                        <td><a data-id={item.DocLocation} href={item.DocLocation} onContextMenu={(e) => downloadAttechment(e, i)} onClick={(e) => downloadAttechment(e, i)} target="_blank" rel="noopener noreferrer">Download</a>

                                          {rightClick === i &&
                                            <div className='right-copy-menu'>
                                              <CopyToClipboard text={clipboardtext} onCopy={() => setRightClick(-1)}>
                                                <span>Copy Link</span>
                                              </CopyToClipboard>
                                            </div>
                                          }
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                {showCitationDetails?.notification_audit_trail
                                  ?.length === 0 ||
                                  (showCitationDetails?.notification_audit_trail ===
                                    null && (
                                      <div className="text-center cstm-no-record-found">
                                        No Record Found
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>

                          <div id="audit-trail" className="tab-pane fade">
                            <div className="ctsm-table-scroll">
                              <div className="table-overflow">
                                <table className="table-heading table table-bordered">
                                  <thead>
                                    <tr>
                                      <th className={`srt-tab ${sorting.key === 'Timestamp' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('Timestamp', !sorting.ascending, 'audit-trail')}>Date & Time</th>
                                      <th className={`srt-tab ${sorting.key === 'InitiatorName' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('InitiatorName', !sorting.ascending, 'audit-trail')}>Initiator Name</th>
                                      <th className={`srt-tab ${sorting.key === 'InitiatorRole' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('InitiatorRole', !sorting.ascending, 'audit-trail')}>Initiator Role</th>
                                      <th className={`srt-tab ${sorting.key === 'NewValue' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('NewValue', !sorting.ascending, 'audit-trail')} >New Value</th>
                                      <th className={`srt-tab ${sorting.key === 'OldValue' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('OldValue', !sorting.ascending, 'audit-trail')}>Old Value</th>
                                      <th className={`srt-tab ${sorting.key === 'UpdateType' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('UpdateType', !sorting.ascending, 'audit-trail')}>Update Type</th>
                                      <th className={`srt-tab ${sorting.key === 'Reason' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('Reason', !sorting.ascending, 'audit-trail')} >Reason</th>
                                      <th className={`srt-tab ${sorting.key === 'Comment' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('Comment', !sorting.ascending, 'audit-trail')} >Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(showCitationDetails?.audit_trail || []).map(
                                      (item, i) => (
                                        <tr key={i}>
                                          {item.Timestamp !== "" && item.Timestamp !== null && item.Timestamp !== "null" && item.Timestamp !== undefined && item.Timestamp.substring(0, 4) !== '0000' && item.Timestamp.substring(0, 4) !== '0001' ?
                                            <td>
                                              {moment(item.Timestamp)
                                                .utc()
                                                .format('DD MMM, YYYY HH:MM:SS') || '-'}
                                            </td>
                                            :
                                            <td>-</td>
                                          }
                                          <td>{item.InitiatorName || '-'}</td>
                                          <td>{item.InitiatorRole || '-'}</td>
                                          <td>{item.NewValue || '-'}</td>
                                          <td>{item.OldValue || '-'}</td>
                                          <td>{item.UpdateType || '-'}</td>
                                          <td>{item.Reason || '-'}</td>
                                          <td>{item.Comment || '-'}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                                {showCitationDetails?.audit_trail
                                  ?.length === 0 ||
                                  (showCitationDetails?.audit_trail ===
                                    null && (
                                      <div className="text-center cstm-no-record-found">
                                        No Record Found
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>

                          <div id="commercial" className="tab-pane fade">
                            {isOpen ? (
                              <div className="col-12 text-center">
                                <div className="spinner-border"></div>
                              </div>
                            ) : (
                              <form>
                                {messageSuc && (
                                  <div className="alert alert-success" role="alert">
                                    {messageSuc}
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-10 userdetail-txt1">
                                    <h4>{changeTotal} Details</h4>
                                  </div>
                                  <div className="col-1 text-right">
                                    <button className="pay-btn">
                                      Refund
                                    </button>
                                  </div>
                                  <div className="col-1 text-right">
                                    {showCitationDetails.status.toLowerCase() ===
                                      'voidandreissue' ||
                                      showCitationDetails.status.toLowerCase() ===
                                      'cancelled' ||
                                      showCitationDetails.status.toLowerCase() ===
                                      'paid' ? (
                                      ''
                                    ) : showCitationDetails.type.toLowerCase() ===
                                      'warning' ||
                                      showCitationDetails.fine_amount === 0 ? (
                                      ''
                                    ) : (
                                      <>
                                        <button
                                          className="pay-btn"
                                          onClick={handlePayment}
                                          data-modal="pay-modal"
                                        >
                                          Pay
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="cstm-tab-commercial row">
                                  <div className="cstm-commercial-input col-xl-3 col-12">
                                    <label>{changeTotal} no</label> <br />
                                    <p>{commercialData?.citation_number || '-'}</p>
                                  </div>
                                  <div className="cstm-commercial-input col-xl-2 col-12">
                                    <label>License</label> <br />
                                    <p>{commercialData?.license || '-'}</p>
                                  </div>
                                  <div className="cstm-commercial-input col-xl-2 col-12">
                                    <label>State</label> <br />
                                    <p>{commercialData?.state || '-'}</p>
                                  </div>
                                  <div className="cstm-commercial-input col-xl-2 col-12">
                                    <label>Plate type</label> <br />
                                    <p>{commercialData?.plates_type || '-'}</p>
                                  </div>
                                  <div className="cstm-commercial-input col-xl-3 col-12">
                                    <label>DateTime</label> <br />
                                    {commercialData.date !== "" && commercialData.date !== null && commercialData.date !== "null" && commercialData.date !== undefined && commercialData.date.substring(0, 4) !== '0000' && commercialData.date.substring(0, 4) !== '0001' ?
                                      <p>
                                        {moment(commercialData.date)
                                          .utc()
                                          .format('DD MMM, YYYY HH:mm:ss') || '-'}
                                      </p>
                                      :
                                      <p>-</p>
                                    }
                                  </div>
                                </div>
                                <div className="box1 cstm-box1-divider"></div>
                                <div className="cstm-commercial-balance-info">
                                  <div className="row">
                                    <div className="col-12 userdetail-txt1 cstm-userdetail-txt1">
                                      <h4>Amounts</h4>
                                    </div>
                                  </div>

                                  <div className="row cstm-row-class">
                                    {/* 1 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Fine</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.fine
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Due Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="due_date"
                                            value={commercialUpdateData.due_date}
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Paid Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="paid_date"
                                            value={commercialUpdateData.paid_date}
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* 2 */}

                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Service Fee</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.service_fee
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    {/* 3 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Fine Increase</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.fine_increase
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Adjusted Fine increase</label>
                                        </div>
                                        <div className="c-input2">
                                          <span>$</span>
                                          <input
                                            type="text"
                                            name="fine_amount"
                                            placeholder="0.00"
                                            value={commercialUpdateData.fine_amount}
                                            onChange={onChangeCommercialInput}
                                            onKeyPress={(event) => {
                                              if (
                                                !/^\d{0,4}(\.\d{0,4})?$/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Fine Increase Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="fine_increase_date"
                                            value={
                                              commercialUpdateData.fine_increase_date
                                            }
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* 4 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>NSF Fees</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.nsf_fee
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      {" "}
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Adjusted NSF fees</label>
                                        </div>
                                        <div className="c-input2">
                                          <span>$</span>
                                          <input
                                            type="text"
                                            name="nsf_amount"
                                            placeholder="0.00"
                                            value={commercialUpdateData.nsf_amount}
                                            onChange={onChangeCommercialInput}
                                            onKeyPress={(event) => {
                                              if (
                                                !/^\d{0,4}(\.\d{0,4})?$/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>NSF Applied Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="nsf_applied_date"
                                            value={
                                              commercialUpdateData.nsf_applied_date
                                            }
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* 5 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Boot/Tow Fee</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.boot_tow_fee
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Adjusted Boot Tow Fee</label>
                                        </div>
                                        <div className="c-input2">
                                          <span>$</span>
                                          <input
                                            type="text"
                                            name="boot_tow_fee"
                                            placeholder="0.00"
                                            value={
                                              commercialUpdateData.boot_tow_fee
                                            }
                                            onChange={onChangeCommercialInput}
                                            onKeyPress={(event) => {
                                              if (
                                                !/^\d{0,4}(\.\d{0,4})?$/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Boot Applied Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="boot_applied_date"
                                            value={
                                              commercialUpdateData.boot_applied_date
                                            }
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* 6 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Collections Fee</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.collection_fee
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Adjusted Collection Fee</label>
                                        </div>
                                        <div className="c-input2">
                                          <span>$</span>
                                          <input
                                            type="text"
                                            name="collection_amount"
                                            placeholder="0.00"
                                            value={
                                              commercialUpdateData.collection_amount
                                            }
                                            onChange={onChangeCommercialInput}
                                            onKeyPress={(event) => {
                                              if (
                                                !/^\d{0,4}(\.\d{0,4})?$/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Collection Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="collection_date"
                                            value={
                                              commercialUpdateData.collection_date
                                            }
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* 7 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Payments</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.payments
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Adjusted Balance Due</label>
                                        </div>
                                        <div className="c-input2">
                                          <span>$</span>
                                          <input
                                            type="text"
                                            name="balance_due"
                                            placeholder="0.00"
                                            value={commercialUpdateData.balance_due}
                                            onChange={onChangeCommercialInput}
                                            onKeyPress={(event) => {
                                              if (
                                                !/^\d{0,4}(\.\d{0,4})?$/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Adjusted Balance Date</label>
                                        </div>
                                        <div className="cstm-mail-due-date">
                                          <input
                                            type="datetime-local"
                                            name="adjusted_balance_date"
                                            value={
                                              commercialUpdateData.adjusted_balance_date
                                            }
                                            onChange={(e) =>
                                              onChangeCommercialUpdateDatesEvent(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* 8 */}
                                    <div className="col-md-4">
                                      <div className="cstm-c-1">
                                        <div className="c-header">
                                          <label>Total Due</label>
                                        </div>
                                        <div className="c-input">
                                          <input
                                            type="text"
                                            defaultValue={`$${convertDecimal(
                                              commercialData?.total_due
                                            ) || "0.00"
                                              }`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                {commercialData?.escalation_detail?.length > 0 && (
                                  <div>
                                    <div className="box1 cstm-box1-divider"></div>
                                    <div>
                                      <div className="row">
                                        <div className="col-12 userdetail-txt1">
                                          <h4>Escalation Details</h4>
                                        </div>
                                        {(commercialData.escalation_detail || []).map(
                                          (item, i) => (
                                            <div
                                              key={i}
                                              className="col-3 cstm-mail-due-date"
                                            >
                                              <div className="days-col">
                                                <label>
                                                  Amount due after{' '}
                                                  {moment(item.date)
                                                    .utc()
                                                    .format('DD MMM, YYYY HH:mm:ss')}
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  defaultValue={convertDecimal(
                                                    item.amount
                                                  )}
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {commercialUpdateData?.notice_date &&
                                  Object.keys(commercialUpdateData?.notice_date)
                                    .length !== 0 && (
                                    <div>
                                      <div className="box1 cstm-box1-divider"></div>
                                      <div>
                                        <div className="row">
                                          <div className="col-12 userdetail-txt1">
                                            <h4>Notice Date</h4>
                                          </div>
                                          {Object.keys(
                                            commercialUpdateData.notice_date
                                          ).map((key, i) => (
                                            <div
                                              key={i}
                                              className="col-3 cstm-mail-due-date"
                                            >
                                              <div className="days-col">
                                                <label>Notice {i + 1}</label>
                                                <br />
                                                <input
                                                  type="datetime-local"
                                                  value={commercialUpdateData.notice_date[
                                                    key
                                                  ].substring(0, 16)}
                                                  onChange={(e) =>
                                                    onChangeDateOfCommercial(
                                                      e,
                                                      i,
                                                      key
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                <div className="box1 cstm-box1-divider"></div>

                                <div className="row">
                                  <div className="col-3 cstm-mail-due-date">
                                    <div className="days-col">
                                      <label>DMV hold case no</label>
                                      <br />
                                      <input
                                        type="text"
                                        name="dmv_case_number"
                                        value={commercialUpdateData.dmv_case_number}
                                        onChange={onChangeCommercialInput}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="box1 cstm-box1-divider"></div>

                                <div className="row">
                                  <div className="col-md-6 form-group formgroup-main f-main">
                                    <label htmlFor="">Remark</label>
                                    <br />
                                    <textarea
                                      rows="4"
                                      cols="50"
                                      name="remark"
                                      value={commercialUpdateData.remark}
                                      onChange={onChangeCommercialInput}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 make-payment-button">
                                    <button
                                      className="btn"
                                      onClick={onUpdateCommercial}
                                      disabled={isBtnLoader}
                                    >
                                      {isBtnLoader && (
                                        <div className="cstm-loader">
                                          <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        </div>
                                      )}
                                      Change Amount
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>

                          <div id="ro-information" className="tab-pane fade">
                            <div className="row">
                              <div className="col-12">
                                <h4>DMV Info</h4>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Mail retured date</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.mail_returned_date !== undefined && showCitationDetails.registered_ro_detail.mail_returned_date !== "" && showCitationDetails.registered_ro_detail?.mail_returned_date.substring(0, 4) !== '0000' && showCitationDetails.registered_ro_detail?.mail_returned_date.substring(0, 4) !== '0001' ?
                                    moment(showCitationDetails.registered_ro_detail?.mail_returned_date.split('T')[0]).format('MM/DD/YYYY') + " " + (showCitationDetails.registered_ro_detail?.mail_returned_date).substring(11, 19)
                                    : '-'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Mail retured remark</p>
                                <h5>
                                  { showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail
                                    .mail_returned_remark || '-'}{' '}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Request date</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.request_date !== undefined && showCitationDetails.registered_ro_detail?.request_date.substring(0, 4) !== '0000' && showCitationDetails.registered_ro_detail?.request_date.substring(0, 4) !== '0001' ?
                                    moment(showCitationDetails.registered_ro_detail?.request_date.split('T')[0]).format('MM/DD/YYYY') + " " + (showCitationDetails.registered_ro_detail?.request_date).substring(11, 19)
                                    : '-'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Response date</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.response_date !== undefined && showCitationDetails.registered_ro_detail?.response_date.substring(0, 4) !== '0000' && showCitationDetails.registered_ro_detail?.response_date.substring(0, 4) !== '0001' ?
                                    moment(showCitationDetails.registered_ro_detail?.response_date.split('T')[0]).format('MM/DD/YYYY') + " " + (showCitationDetails.registered_ro_detail?.response_date).substring(11, 19)
                                    : '-'}
                                </h5>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-8">
                                <h4>Current Address</h4>
                              </div>
                              <div className="col-4 cstm-ro-edit-btn">
                                <a href="#">
                                  {' '}
                                  <button onClick={openModal} data-modal="ro-modal">
                                    Change Address
                                  </button>
                                </a>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Name</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail
                                    .current_address.name || '-'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Address</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail
                                    .current_address.address || '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>City</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail
                                    .current_address.city || '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>State</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail
                                    .current_address.state || '-'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Zip</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail
                                    .current_address.zip || '-'}
                                </h5>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-12">
                                <h4>Old Address</h4>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Name</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.old_address
                                    .name || '-'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Address</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.old_address
                                    .address || '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>City</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.old_address
                                    .city || '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>State</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.old_address
                                    .state || '-'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Zip</p>
                                <h5>
                                  {showCitationDetails.registered_ro_detail !== undefined && showCitationDetails.registered_ro_detail !== null && showCitationDetails.registered_ro_detail.old_address
                                    .zip || '-'}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div id="user-detail" className="tab-pane fade">
                            {showUserDetails.paid_user_detail !== null ?
                              <>
                                <div className="row">
                                  <div className="col-12">
                                    <h4>Paid User Details</h4>
                                  </div>

                                  <div className="col-xl-3 col-6 result1">
                                    <p>First Name</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.first_name ||
                                        '-'}{' '}
                                    </h5>
                                  </div>
                                  <div className="col-xl-3 col-6 result1">
                                    <p>Last Name</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.last_name ||
                                        '-'}{' '}
                                    </h5>
                                  </div>

                                  <div className="col-xl-3 col-6 result1">
                                    <p>Email</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.email || '-'}{' '}
                                    </h5>
                                  </div>
                                  <div className="col-xl-3 col-6 result1">
                                    <p>Phone Number</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.phone_number ||
                                        '-'}{' '}
                                    </h5>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-xl-3 col-6 result1">
                                    <p>Company</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.company ||
                                        '-'}{' '}
                                    </h5>
                                  </div>
                                  <div className="col-xl-3 col-6 result1">
                                    <p>Block</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.block ||
                                        '-'}{' '}
                                    </h5>
                                  </div>
                                  <div className="col-xl-3 col-6 result1">
                                    <p>Street</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.street ||
                                        '-'}{' '}
                                    </h5>
                                  </div>

                                  <div className="col-xl-3 col-6 result1">
                                    <p>City</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.city || '-'}{' '}
                                    </h5>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-xl-3 col-6 result1">
                                    <p>State</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.state ||
                                        '-'}{' '}
                                    </h5>
                                  </div>
                                  <div className="col-xl-3 col-6 result1">
                                    <p>Zip Code</p>
                                    <h5>
                                      {showUserDetails.paid_user_detail?.zip_code ||
                                        '-'}{' '}
                                    </h5>
                                  </div>
                                </div>
                              </>
                              :
                              (showUserDetails.user_detail !== null &&
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <h4>User Details</h4>
                                    </div>

                                    <div className="col-xl-3 col-6 result1">
                                      <p>First Name</p>
                                      <h5>
                                        {showUserDetails.user_detail?.first_name ||
                                          '-'}{' '}
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Last Name</p>
                                      <h5>
                                        {showUserDetails.user_detail?.last_name ||
                                          '-'}{' '}
                                      </h5>
                                    </div>

                                    <div className="col-xl-3 col-6 result1">
                                      <p>Email</p>
                                      <h5>
                                        {showUserDetails.user_detail?.email || '-'}{' '}
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Phone Number</p>
                                      <h5>
                                        {showUserDetails.user_detail?.phone_number ||
                                          '-'}{' '}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Block</p>
                                      <h5>
                                        {showUserDetails.user_detail?.block ||
                                          '-'}{' '}
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Street</p>
                                      <h5>
                                        {showUserDetails.user_detail?.street ||
                                          '-'}{' '}
                                      </h5>
                                    </div>

                                    <div className="col-xl-3 col-6 result1">
                                      <p>City</p>
                                      <h5>
                                        {showUserDetails.user_detail?.city || '-'}{' '}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-3 col-6 result1">
                                      <p>State</p>
                                      <h5>
                                        {showUserDetails.user_detail?.state ||
                                          '-'}{' '}
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-6 result1">
                                      <p>Zip Code</p>
                                      <h5>
                                        {showUserDetails.user_detail?.zip_code ||
                                          '-'}{' '}
                                      </h5>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          </div>
                          <div id="payment-detail" className="tab-pane fade">
                            <div className="row">
                              <div className="col-xl-3 col-6 result1">
                                <p>Amount</p>
                                <h5>
                                  $
                                  {convertDecimal(
                                    showCitationDetails.payment_details?.Amount || '0'
                                  ) || '0.00'}
                                </h5>
                              </div>
                              <div className="col-xl-3 col-6 result1">
                                <p>Payment Date & Time</p>
                                <h5>
                                  {showCitationDetails.payment_details !== undefined && showCitationDetails.payment_details !== null && showCitationDetails.payment_details.PaymentDatetime !== undefined && showCitationDetails.payment_details.PaymentDatetime.substring(0, 4) !== '0000' && showCitationDetails.payment_details.PaymentDatetime.substring(0, 4) !== '0001' ?
                                    moment(showCitationDetails.payment_details?.PaymentDatetime.split('T')[0]).format('MM/DD/YYYY') + " " + (showCitationDetails.payment_details?.PaymentDatetime).substring(11, 19)

                                    : '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>Payment Mode</p>
                                <h5>
                                  {showCitationDetails.payment_details?.PaymentMode ||
                                    '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>Payment Status</p>
                                <h5>
                                  {showCitationDetails.payment_details?.PaymentStatus ||
                                    '-'}
                                </h5>
                              </div>
                              <div className="col-3 result1 hide-col">
                                <p>Receipt ID</p>
                                <h5>
                                  {showCitationDetails.payment_details?.ReceiptID ||
                                    '-'}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>

          
            <SuspendModal
              closeFn={closeModal}
              open={modalOpen === 'suspend-modal'}
              cite_id={showCitationDetails?.id}
              status={showCitationDetails.status === "Suspended" ? "Valid" : "Suspended"}
            />
            <ChangeAmountModal
              closeFn={closeModal}
              open={modalOpen === 'changeAmount-modal'}
            />
            <HearingModel
              closeFn={closeModal}
              open={modalOpen === 'hearing-modal'}
              showCitationDetails={showCitationDetails.header_details.citation_number}
            />
            <ROInformationModal
              closeFn={closeModal}
              open={modalOpen === 'ro-modal'}
              citeId={showCitationDetails.id}
            />
            {/* <ChangeStatusModel closeFn={closeModal} open={modalOpen === 'changeStatus-modal'} /> */}
            {/* <AppealModel closeFn={closeModal} open={modalOpen === 'appeal-modal'} citePayload={citePayload} /> */}

            {modalOpen === 'pay-modal' && (
              <div className="main-body-modal">
                <div className="main-modal">
                  <div className="cstm-close">
                    <button onClick={closeModal}>×</button>
                  </div>
                  <div className="cstm-modal-body">
                    <div className="modal-body cstm-modal-body">
                      <div className="text-center cstm-paymnet-img">
                        <img src={box1} alt="img" className="img-fluid" />
                      </div>
                      <h3 className="text-center">Choose your payment method</h3>
                      <p className="text-center mb-4">
                        The City Of Encinitas offers three ways to pay parking
                        {changeName.charAt(0).toLowerCase()+ changeName.slice(1) + 's'} so that you can choose the method that is most
                        convenient for you.
                      </p>
                      <div className="row">
                        <div className={`${!isLazParking() ? "col-4":"col-6"}`}>
                          <button className="payment-button">Cash</button>
                        </div>
                        {!isLazParking() && <div className="col-4">
                          <button className="payment-button">Check</button>
                        </div>}
                        <div className={`${!isLazParking() ? "col-4":"col-6"}`}>
                          <button className="payment-button">Cards</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isOpenPyC && (
              <div className="main-body-modal cstm-csr-pg">
                <div className="main-modal">
                  <div className="modal-content">
                    <div className="modal-body cstm-modal-body">
                      <button className="cstm-payment-close" onClick={closeModal}>
                        ×
                      </button>
                      <img src={PayImg} alt="img" className="img-fluid" />
                      <h3>Choose your payment method</h3> 
                      <div className="row">
                        <div className={`${!isLazParking() ? "col-4":"col-6"}`}>
                          <button
                            className="payment-button"
                            onClick={(e) => selectPamentMethod("cash", e)}
                          >
                            Cash
                          </button>
                        </div>
                        {!isLazParking() && <div className="col-4">
                          <button
                            className="payment-button"
                            onClick={(e) => selectPamentMethod("cheque", e)}
                          >
                            Check
                          </button>
                        </div>}
                        <div className={`${!isLazParking() ? "col-4":"col-6"}`}>
                          <button
                            className="payment-button"
                            onClick={(e) => selectPamentMethod("card", e)}
                          >
                            Cards
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isEligibleOpen && (
              <div className="main-body-modal">
                <div className="main-modal">
                  <div className="cstm-close">
                    <button onClick={closeModal}>×</button>
                  </div>
                  <div className="cstm-modal-body">
                    <div className="modal-body cstm-modal-body">
                      <div className="text-center cstm-paymnet-img">
                        <img src={Eororicon} alt="img" className="img-fluid" />
                      </div>
                      <p className="text-center mb-4">{isEligibleMsg}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
        :
        (showCitationDataDisplay &&
          <AppealDetail backButtonClick={backButtonClick} />
        )
      }
      {showImagesModalSlider && (
        <div className="main-body-modal cstm-ticket-details-pop" onKeyDown={(e) => {
            if( e.key === "ArrowLeft" ){
              imageId === 0 ? setImageId(slideImages.length -1) : setImageId(imageId - 1)
            } 
            else if(e.key === "ArrowRight") {
              imageId + 1 === slideImages.length ? setImageId(0) : setImageId(imageId + 1)
            }
          }}
          tabIndex={0}
          ref={sliderPopupRef}>
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                <ImageSlider slides={slideImages} index={imageId}/>
                  {/* <ImageGallery 
                    items={slideImages}
                    showThumbnails={false}
                    showIndex={true}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    startIndex={imageId}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showResendNotice && (
            <ResendNotice
              noticeData={showCitationDetails?.notice_detail}
              backButtonClick={backButtonClick}
              citeId={showCitationDetails.id}
              ticketNo={showCitationDetails.ticket_no}
            />
          ) }
    </>
  );
};
export default CitationDetails;
