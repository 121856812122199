import { useForm, Controller, useFieldArray } from "react-hook-form";
import ArrowLeft from "../../../../Images/arrow-left.png";
import DeleteIcon from "../../../../Images/delete-icon.png";
import { useEffect, useState } from "react";

const PermitPaymentTab = ({
  HandlePermitSubmit,
  handleTabChange,
  permitTitle,
  loading,
  payloadData,
  isEditPermit,
  permitTime,
}) => {
  const [timeError, setTimeError] = useState("");
  const [isError, setIsError] = useState([])
  const [fees, setFees] = useState([]);
  const [inputList, setInputList] = useState([{ feeName: "", feeAmount: "" }]);

  // const addFee = () => {
  //   setFees([...fees, { key: '', value: '' }]);
  // };

  const addFee = () => {
    setInputList([...inputList, { feeName: "", feeAmount: "" }]);
  };

  const onSubmit = (data) => {
    const additional_fee = fees.map(fee => ({
      name: fee.key.toLowerCase().split(' ').join('_'),
      value: fee.value
    }));

    const payload = {
      ...data,
      permit_fee_structure: {
        ...data.permit_fee_structure,
        additional_fee
      }
    };

    HandlePermitSubmit(payload);
  };

  const { control, handleSubmit, watch, setValue, resetField, reset } = useForm(
    {
      defaultValues: {
        service_fee: 0,
        is_prorated: {
          prorated: false,
        },
        // has_auto_renewal: false,
        is_prorated_refund_enable: false,
        has_tier_price: false,
        permit_fee: [
          {
            tier_number: 1,
            permit_fee: {
              daily: false,
              monthly: false,
              annually: false,
              quarterly: false,
              event: false,
            },
          },
        ],
        guest_permit_fee: [
          {
            tier_number: 1,
            permit_fee: {
              daily: false,
              monthly: false,
              annually: false,
              quarterly: false,
              event: false,
            },
          },
        ],
        payment_type: [],
        master_card: "",
        cash: "",
        check: "",
        ACH: "",
        discover: "",
        american_express: "",
        visa: "",
        application_fee_check: false,
        status: "PAYMENT_STATUS",
        allow_transfer: false,
        permit_fee_structure: {}
      },
      onSubmit
    }
  );

  useEffect(() => {
    if (isEditPermit) {
      reset(payloadData);
      if (payloadData.status !== "ACTIVE") {
        setValue("status", "PAYMENT_STATUS")
      }
      if (payloadData.payment_type) {
        payloadData.payment_type.map((itm) => {
          if (itm === "Master Card") {
            setValue("master_card", itm);
          }
          if (itm === "Cash") {
            setValue("cash", itm);
          }
          if (itm === "American Express") {
            setValue("american_express", itm);
          }
          if (itm === "Discover") {
            setValue("discover", itm);
          }
          if (itm === "VISA") {
            setValue("visa", itm);
          }
          if (itm === "ACH") {
            setValue("ACH", itm);
          }
          if (itm === "Check") {
            setValue("check", itm);
          }
        });
      }
      if (payloadData.application_fee) {
        setValue("application_fee_check", true);
      }
      payloadData.plate_update_fee !== 0 &&
        setValue("plate_update_fee_allow", true);
      payloadData.change_address_fee !== 0 &&
        setValue("change_address_fee_allow", true);
      payloadData.hand_tag_replacement_fee !== 0 &&
        setValue("hand_tag_replacement_fee_allow", true);
    }
  }, [payloadData]);

  const {
    fields: fieldsPermitFee,
    remove: removePermitFee,
    append: appendPermitFee,
  } = useFieldArray({ control, name: "permit_fee" });

  const { remove: removeGuestPermitFee, append: appendGuestPermitFee } =
    useFieldArray({ control, name: "guest_permit_fee" });

  const PaymentSubmitHandler = (data) => {
    data.payment_type = [];
    if (data.master_card) {
      data.payment_type.push(data.master_card);
    }
    if (data.cash) {
      data.payment_type.push(data.cash);
    }
    if (data.american_express) {
      data.payment_type.push(data.american_express);
    }
    if (data.discover) {
      data.payment_type.push(data.discover);
    }
    if (data.ACH) {
      data.payment_type.push(data.ACH);
    }
    if (data.visa) {
      data.payment_type.push(data.visa);
    }
    if (data.check) {
      data.payment_type.push(data.check);
    }

    delete data.master_card;
    delete data.cash;
    delete data.american_express;
    delete data.discover;
    delete data.ACH;
    delete data.check;
    delete data.visa;
    delete data.application_fee_check;
    delete data.plate_update_fee_allow;
    delete data.change_address_fee_allow;
    delete data.hand_tag_replacement_fee_allow;

    const selectedPermitTimes = [];

    if (permitTime.monthly) {
      selectedPermitTimes.push("Monthly");
    }

    if (permitTime.quarterly) {
      selectedPermitTimes.push("Quarterly");
    }

    if (permitTime.annually) {
      selectedPermitTimes.push("Annually");
    }

    const permitFeeError = data.permit_fee.map(
      (ele) =>
        ele.permit_fee.monthly === false &&
        ele.permit_fee.daily === false &&
        ele.permit_fee.annually === false &&
        ele.permit_fee.quarterly === false
    );
    const somePermitFeeErrorIsTrue = permitFeeError.some((error) => error);
    setIsError(permitFeeError)
    if (selectedPermitTimes.length === 0 || somePermitFeeErrorIsTrue) {
      const timeError = `Please select ${selectedPermitTimes.length > 0
        ? selectedPermitTimes.join(", ") + " tier fee"
        : ""
        }`

      setTimeError(timeError);
    }
    else {
      setTimeError("");
      HandlePermitSubmit(data);
    }
  };
  const handleDailyCheckboxChange = (e, index) => {
    const checked = e.target.checked;
    if (checked && isError[index] && timeError !== "") {
      setIsError((prevIsError) => {
        const updatedIsError = [...prevIsError];
        updatedIsError[index] = false;
        return updatedIsError;
      });
    }
  };

  useEffect(() => {
    if (watch("has_tier_price") === false) {
      resetField("permit_fee");
      resetField("guest_permit_fee");
    }
  }, [watch("has_tier_price")]);
  return (
    <>
      <div className={`permit-payment`}>
        <form onSubmit={handleSubmit(PaymentSubmitHandler)}>
          <div className="permin-form-main">
            <h3 class="permin-form-title">{permitTitle}</h3>
            <div className="permin-form-main-in">
              <div className="permit-rules-list2">
                <Controller
                  name="service_fee"
                  control={control}
                  render={({ field }) => (
                    <label>
                      Service Fee
                      <div>
                        <span className="cstm-pp-drr">$</span>
                        <input
                          className="permit-field-rules cstm-pp-drr-input"
                          type="number"
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(
                              e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                            )
                          }}
                          step="any"
                          placeholder="Enter Amount"
                          onKeyPress={(e) => {
                            if (!/^\d*\.?\d*$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </label>
                  )}
                />
              </div>

              <div className="permit-rules-list2">
                <Controller
                  name="application_fee_check"
                  control={control}
                  rules={{
                    required: "Permit application fee is required",
                  }}
                  render={({ field, fieldState }) => (
                    <div className="permint-payment-row">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Has permit application fee
                      </label>
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />
                {watch("application_fee_check") && (
                  <>
                    <div className="permint-payment-row2 ">
                      <span className="cstm-pp-drr">$</span>
                      <Controller
                        name="application_fee"
                        control={control}
                        rules={{
                          required: "Application fee is required",
                          min: {
                            value: 1,
                            message: "Negative amount is not allow",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <input
                              className="permit-field-rules cstm-pp-drr-input"
                              type="number"
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(
                                  e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                )
                              }}
                              step="any"
                              placeholder="Enter Amount"
                              onKeyPress={(e) => {
                                if (!/^\d*\.?\d*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {fieldState.error && (
                              <span className="errormessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="permit-rules-list">
                <Controller
                  name=""
                  control={control}
                  render={({ field }) => (
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Does the application fee apply for every renewal
                    </label>
                  )}
                />

              </div>
              {/* {fees.map((fee, index) => (
                <div key={index} className="permit-fee-input-container">
                  <input
                    className="permit-field-rules cstm-pp-drr-input"
                    placeholder="Fee Name"
                    value={fee.key}
                    onChange={(e) => {
                      const newFees = [...fees];
                      newFees[index].key = e.target.value;
                      setFees(newFees);
                    }}
                  />
                  <input
                    className="permit-field-rules cstm-pp-drr-input "
                    placeholder="Fee Amount"
                    type="number"
                    value={fee.value}
                    onChange={(e) => {
                      const newFees = [...fees];
                      newFees[index].value = e.target.value;
                      setFees(newFees);
                    }}
                  />
                  <span className="cstm-pp-drr">$</span>

                </div>
              ))}
              <div className="permit-rules-list">
                <button onClick={addFee} className="add-mobe-button">Add more fees</button>
              </div> */}

              <div className="permit-rules-list">
                <Controller
                  name="additional_fee"
                  control={control}
                  render={({ field }) => (
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Additional fee
                    </label>
                  )}
                />
              </div>

              {watch("additional_fee") && (
                <>
                  {/* <div className="permit-fee-input-container">
                    <input
                      className="permit-field-rules cstm-pp-drr-input"
                      placeholder="Fee Name"
                    />
                    <input
                      className="permit-field-rules cstm-pp-drr-input"
                      placeholder="Fee Amount"
                      type="number"
                    />
                  </div>
                  <button
                    className="add-mobe-button"
                    onClick={addFee}
                  >Add more fees</button> */}
                  {inputList.map((input, index) => {
                    return (
                      <div key={index} className="permit-fee-input-container">
                        <input
                          className="permit-field-rules cstm-pp-drr-input"
                          placeholder="Fee Name"
                          value={input.feeName}
                          onChange={(e) => {
                            const list = [...inputList];
                            list[index].feeName = e.target.value;
                            setInputList(list);
                          }}
                        />
                        <input
                          className="permit-field-rules cstm-pp-drr-input"
                          placeholder="Fee Amount"
                          type="number"
                          value={input.feeAmount}
                          onChange={(e) => {
                            const list = [...inputList];
                            list[index].feeAmount = e.target.value;
                            setInputList(list);
                          }}
                        />
                        {/* <span className="cstm-pp-drr">$</span> */}
                      </div>
                    );
                  })}
                  <button
                    className="add-mobe-button"
                    onClick={addFee}
                  >Add more fees</button>
                </>
              )}

              <hr className="permit-line" />

              <div className="permit-rules-list">
                <Controller
                  name="has_tier_price"
                  control={control}
                  rules={{
                    required: " Tired pricing is required",
                    min: {
                      value: 1,
                      message: "Negative amount is not allow",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Has tired pricing (escalated pricing)
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </label>
                  )}
                />
              </div>

              {watch("has_tier_price") && (
                <>
                  <div className="has-tired-pricing">
                    {fieldsPermitFee.map((field, index) => (
                      <div className="has-tired-pricing-box" key={field.id}>
                        <div className="permit-row-box2-title">
                          <h5>{"Tier " + Number(index + 1)}</h5>
                          <div className="has-tired-pricing-box-remove">
                            {index > 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  setValue(
                                    `permit_fee.${index}.tier_number`,
                                    index + 1
                                  );
                                  setValue(
                                    `guest_permit_fee.${index}.tier_number`,
                                    index + 1
                                  );
                                  removePermitFee(index);
                                  removeGuestPermitFee(index);
                                }}
                              >

                                <img src={DeleteIcon} alt="remove" />
                              </button>
                            )}
                          </div>
                          {isError[index] && timeError !== "" && (
                            <span className="errormessage">
                              {timeError}
                            </span>
                          )}
                        </div>
                        <div className="tm-permit-row-box2">
                          <div className="permit-row-box2">
                            <div className="permit-rules-list-in">
                              <div className="permit-rules-list-in-labe-full">
                                <label>Permit fee</label>
                              </div>
                            </div>

                            <div className="permit-payment-list3">
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`permit_fee.${index}.permit_fee.daily`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={(e) => { field.onChange(e.target.checked); handleDailyCheckboxChange(e, index) }}
                                          checked={field.value}
                                        />
                                        {"Daily"}
                                        {fieldState.error && (
                                          <span className="errormessage">
                                            {fieldState.error.message}
                                          </span>
                                        )}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `permit_fee.${index}.permit_fee.daily`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`permit_fee.${index}.permit_fee.daily_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`permit_fee.${index}.permit_fee.monthly`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={(e) => { field.onChange(e.target.checked); handleDailyCheckboxChange(e, index) }} checked={field.value}
                                        />
                                        {"Monthly"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `permit_fee.${index}.permit_fee.monthly`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`permit_fee.${index}.permit_fee.monthly_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`permit_fee.${index}.permit_fee.quarterly`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={(e) => { field.onChange(e.target.checked); handleDailyCheckboxChange(e, index) }}
                                          checked={field.value}
                                        />
                                        {"Quarterly"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `permit_fee.${index}.permit_fee.quarterly`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`permit_fee.${index}.permit_fee.quarterly_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`permit_fee.${index}.permit_fee.annually`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={(e) => { field.onChange(e.target.checked); handleDailyCheckboxChange(e, index) }}
                                          checked={field.value}
                                        />
                                        {"Annually"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `permit_fee.${index}.permit_fee.annually`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`permit_fee.${index}.permit_fee.annual_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`permit_fee.${index}.permit_fee.event`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={field.onChange}
                                          checked={field.value}
                                        />
                                        {"Events"}
                                      </label>
                                    )}
                                  />
                                </div>

                                {watch(
                                  `permit_fee.${index}.permit_fee.event`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`permit_fee.${index}.permit_fee.event_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                            {/* {timeError !== "" && (
                              <span className="errormessage">{timeError}</span>
                            )} */}
                          </div>
                          <div className="permit-row-box2">
                            <div className="permit-rules-list-in">
                              <div className="permit-rules-list-in-labe-full">
                                <label>Guest permit fee</label>
                              </div>
                            </div>

                            <div className="permit-payment-list3">
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`guest_permit_fee.${index}.permit_fee.daily`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={field.onChange}
                                          checked={field.value}
                                        />
                                        {"Daily"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `guest_permit_fee.${index}.permit_fee.daily`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`guest_permit_fee.${index}.permit_fee.daily_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`guest_permit_fee.${index}.permit_fee.monthly`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={field.onChange}
                                          checked={field.value}
                                        />
                                        {"Monthly"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `guest_permit_fee.${index}.permit_fee.monthly`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`guest_permit_fee.${index}.permit_fee.monthly_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`guest_permit_fee.${index}.permit_fee.quarterly`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={field.onChange}
                                          checked={field.value}
                                        />
                                        {"Quarterly"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `guest_permit_fee.${index}.permit_fee.quarterly`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`guest_permit_fee.${index}.permit_fee.quarterly_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`guest_permit_fee.${index}.permit_fee.annually`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={field.onChange}
                                          checked={field.value}
                                        />
                                        {"Annually"}
                                      </label>
                                    )}
                                  />
                                </div>
                                {watch(
                                  `guest_permit_fee.${index}.permit_fee.annually`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`guest_permit_fee.${index}.permit_fee.annual_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="permit-payment-list3-box">
                                <div className="permit-payment-list3-box-row1">
                                  <Controller
                                    name={`guest_permit_fee.${index}.permit_fee.event`}
                                    control={control}
                                    render={({ field }) => (
                                      <label>
                                        <input
                                          className="permit-field-checkbox"
                                          type="checkbox"
                                          value={field.value}
                                          onChange={field.onChange}
                                          checked={field.value}
                                        />
                                        {"Events"}
                                      </label>
                                    )}
                                  />
                                </div>

                                {watch(
                                  `guest_permit_fee.${index}.permit_fee.event`
                                ) && (
                                  <div className="permit-payment-list3-box-row2">
                                    <span className="cstm-pp-drr">$</span>
                                    <Controller
                                      name={`guest_permit_fee.${index}.permit_fee.event_permit_fee`}
                                      control={control}
                                      rules={{
                                        required: "Amount is required",
                                        min: {
                                          value: 1,
                                          message:
                                            "Negative amount is not allow",
                                        },
                                      }}
                                      render={({ field, fieldState }) => (
                                        <>
                                          <input
                                            className="permit-field-rules cstm-pp-drr-input"
                                            type="number"
                                            value={field.value}
                                            onChange={(e) => {
                                              field.onChange(
                                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                                              )
                                            }}
                                            step="any"
                                            placeholder="Enter Amount"
                                            onKeyPress={(e) => {
                                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                            {fieldState.error && (
                                              <span className="errormessage">
                                                {fieldState.error.message}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="has-tired-pricing-box-add-more">
                    <button
                      className="add-mobe-button"
                      type="button"
                      onClick={() => {
                        // if (timeError === "") {
                        appendPermitFee({
                          tier_number: fieldsPermitFee.length + 1,
                          permit_fee: {
                            daily: false,
                            monthly: false,
                            annually: false,
                            quarterly: false,
                            event: false,
                          },
                        });
                        appendGuestPermitFee({
                          tier_number: fieldsPermitFee.length + 1,
                          permit_fee: {
                            daily: false,
                            monthly: false,
                            annually: false,
                            quarterly: false,
                            event: false,
                          },
                        });
                        // }
                      }}
                    >
                      + Add More
                    </button>
                  </div>
                </>
              )}

              <hr className="permit-line" />

              {/* <Controller
                name="has_auto_renewal"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list">
                    <label>
                      <input
                        className="permit-field-checkbox"
                        type="checkbox"
                        value={field.value}
                        onChange={field.onChange}
                        checked={field.value}
                      />
                      Auto renewal ?
                    </label>
                  </div>
                )}
              /> */}

              <div className="permit-rules-list2">
                <div className="permint-payment-row">
                  <Controller
                    name="is_prorated.prorated"
                    control={control}
                    render={({ field }) => (
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Is prorated
                      </label>
                    )}
                  />
                </div>
              </div>
              <Controller
                name="allow_transfer"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list2">
                    <div className="permint-payment-row">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Allow transfers
                      </label>
                    </div>
                  </div>
                )}
              />
              <div className="permit-rules-list2">
                <Controller
                  name="plate_update_fee_allow"
                  control={control}
                  render={({ field }) => (
                    <div className="permint-payment-row">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Plate update fee
                      </label>
                    </div>
                  )}
                />
                {watch("plate_update_fee_allow") && (
                  <div>
                    <span className="cstm-pp-drr">$</span>
                    <Controller
                      name="plate_update_fee"
                      control={control}
                      rules={{
                        required: "Plate update fee is required",
                        min: {
                          value: 1,
                          message: "Negative amount is not allow",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            className="permit-field-rules cstm-pp-drr-input"
                            type="number"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(
                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                              )
                            }}
                            step="any"
                            placeholder="Enter Amount"
                            onKeyPress={(e) => {
                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="permit-rules-list2">
                <Controller
                  name="change_address_fee_allow"
                  control={control}
                  render={({ field }) => (
                    <div className="permint-payment-row">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Address update fee
                      </label>
                    </div>
                  )}
                />
                {watch("change_address_fee_allow") && (
                  <div>
                    <span className="cstm-pp-drr">$</span>
                    <Controller
                      name="change_address_fee"
                      control={control}
                      rules={{
                        required: "Address Update fee is required",
                        min: {
                          value: 1,
                          message: "Negative amount is not allow",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            className="permit-field-rules cstm-pp-drr-input"
                            type="number"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(
                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                              )
                            }}
                            step="any"
                            placeholder="Enter Amount"
                            onKeyPress={(e) => {
                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="permit-rules-list2">
                <Controller
                  name="hand_tag_replacement_fee_allow"
                  control={control}
                  render={({ field }) => (
                    <div className="permint-payment-row">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Hand tag replacement fee
                      </label>
                    </div>
                  )}
                />
                {watch("hand_tag_replacement_fee_allow") && (
                  <div>
                    <span className="cstm-pp-drr">$</span>
                    <Controller
                      name="hand_tag_replacement_fee"
                      control={control}
                      rules={{
                        required: "Hand tag replacement fee is required",
                        min: {
                          value: 1,
                          message: "Negative amount is not allow",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            className="permit-field-rules cstm-pp-drr-input"
                            type="number"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(
                                e.target.value === '' ? '' : parseFloat(e.target.value, 10)
                              )
                            }}
                            step="any"
                            placeholder="Enter Amount"
                            onKeyPress={(e) => {
                              if (!/^\d*\.?\d*$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <Controller
                name="is_prorated_refund_enable"
                control={control}
                render={({ field }) => (
                  <div className="permit-rules-list2">
                    <div className="permint-payment-row">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={field.value}
                          onChange={field.onChange}
                          checked={field.value}
                        />
                        Allow prorated refund
                      </label>
                    </div>
                  </div>
                )}
              />

              <hr className="permit-line" />

              <div className="pyament-options">
                <h4 className="permit-title-h4 md-1">Payment options</h4>

                <Controller
                  name="master_card"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"Master Card"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        Master card
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="visa"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"VISA"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        VISA
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="american_express"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"American Express"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        American express
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="discover"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"Discover"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        Discover
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="ACH"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"ACH"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        ACH
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="check"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"Check"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        Check
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="cash"
                  control={control}
                  render={({ field }) => (
                    <div className="permit-rules-list">
                      <label>
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          value={"Cash"}
                          onChange={(e) => field.onChange(e.target.value)}
                          checked={field.value}
                        />
                        Cash
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="permin-form-btn">
            <div className="permin-form-btn-left">
              <button
                className="permin-btn"
                onClick={() => handleTabChange(3)}
                type="button"
              >
                <img src={ArrowLeft} alt="Back" />
                Back
              </button>
            </div>
            <div className="ermin-form-btn-right">
              <button className="permin-btn" type="submit">
                {loading ? <div className="spinner-border"></div> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PermitPaymentTab;
