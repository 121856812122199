import moment from "moment";

const NoteTab = ({ Workflow }) => {
  return (
    <>
      {Workflow?.length ? (
        (Workflow || []).map((rmrk, r) => (
          <div key={r} className={`box1`}>
            <div className="row">
              <div className="col-12 remark-text">
                <span style={{display:"flex"}}>
                 <h4 className="pr-2">Remark {r + 1}</h4> {" "}
                 <h4 style={{color: '#F7C64B'}}>{rmrk.is_internal ? "(Internal Remark)" : ""}</h4>
                </span>
                <h6>Notes</h6>
                <p>{rmrk.remark}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-6 user-info">
                <p>Reviewer name</p>
                <h5>{rmrk.created_by}</h5>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-6 user-info">
                <p>Review Date</p>
                {rmrk.created_at_local !== undefined &&
                rmrk.created_at_local !== null &&
                rmrk.created_at_local !== "" &&
                rmrk.created_at_local.substring(0, 4) !== "0000" &&
                rmrk.created_at_local.substring(0, 4) !== "0001" ? (
                  <h5>
                    {moment(rmrk.created_at_local)
                      .utc()
                      .format("DD MMM, YYYY HH:mm:ss")}
                  </h5>
                ) : (
                  <h5>-</h5>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-record-found-main">No Record Found</div>
      )}
    </>
  );
};

export default NoteTab;
