import React from 'react';
import { changetotal } from "../../Global/site";


const ChangeAmountModal = ({ closeFn = () => null, open = false }) => {
    const changeTotal = changetotal();

    return (
        <>
            {open &&
                <div className="main-body-modal">
                    <div className="main-modal2 cstm-change-amount-model">
                        <div className="ctsm-modal-header">
                            <div className="cstm-modal-title">
                                <h3>Change Amount</h3>
                            </div>
                            <div className="cstm-close">
                                <button onClick={closeFn}>×</button>
                            </div>

                        </div>
                        <div className="cstm-modal-body">
                            <form>
                                <div className="userdetail-txt1">
                                    <h4>{changeTotal} Details</h4>
                                </div>
                                <div className='cstm-modal-row'>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>{changeTotal} no*</label> <br />
                                        <input type="text" placeholder='845123506' />
                                    </div>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>License*</label> <br />
                                        <input type="text" placeholder='65741D' />
                                    </div>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>Sate*</label> <br />
                                        <select className="state-droupdwon ar-olny">
                                            <option>WI</option>
                                            <option>WI</option>
                                            <option>WI</option>
                                        </select>
                                    </div>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>Plate type*</label> <br />
                                        <input type="text" placeholder='DIS' />
                                    </div>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>Date*</label> <br />
                                        <input type="text" placeholder='11/13/2021' />
                                    </div>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>Time*</label> <br />
                                        <input type="text" placeholder='12:22 AM' />
                                    </div>
                                </div>
                                <div className='box1 cstm-box1-divider'></div>
                                <div className='cstm-commercial-balance-info'>
                                    <div className="row">
                                        <div className="col-12 userdetail-txt1">
                                            <h4>Amounts</h4>
                                        </div>
                                    </div>
                                    <div className='cstm-commercial-info-row'>
                                        <div className='cstm-commerical-col'>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>Fine</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>Fine Increase</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>NSF Fees</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>Boot/Tow Fee</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>Collections Fee</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>Payments</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header'>
                                                    <label>Total Due</label>
                                                </div>
                                                <div className='c-input'>
                                                    <input type="text" placeholder='$20.00' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cstm-commercial-col  cstm-ard2'>
                                            <div className='cstm-c-1'>
                                                <div className='c-header2'>
                                                    <label>Adjusted Fine increase</label>
                                                </div>
                                                <div className='c-input2'>
                                                    <span>$</span><input type="text" placeholder='20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header2'>
                                                    <label>Adjusted NSF fees</label>
                                                </div>
                                                <div className='c-input2'>
                                                    <span>$</span><input type="text" placeholder='20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header2'>
                                                    <label>Adjusted Boot Tow Fee</label>
                                                </div>
                                                <div className='c-input2'>
                                                    <span>$</span><input type="text" placeholder='20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header2'>
                                                    <label>Adjusted Collection Fee</label>
                                                </div>
                                                <div className='c-input2'>
                                                    <span>$</span><input type="text" placeholder='20.00' />
                                                </div>
                                            </div>
                                            <div className='cstm-c-1'>
                                                <div className='c-header2'>
                                                    <label>Adjusted Balance Due</label>
                                                </div>
                                                <div className='c-input2'>
                                                    <span>$</span><input type="text" placeholder='20.00' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div className='box1 cstm-box1-divider'></div>
                                    <div>
                                        <div className="row">
                                            <div className="col-12 userdetail-txt1">
                                                <h4>Escalation Details</h4>
                                            </div>
                                            <div className='col-3 cstm-mail-due-date'>
                                                <div className='days-col'>
                                                    <label>Amount due after 01 Dec, 2022 02:09:36</label><br />
                                                    <input type="text" placeholder='$0.00' />
                                                </div>
                                            </div>
                                            <div className='col-3 cstm-mail-due-date'>
                                                <div className='days-col'>
                                                    <label>Amount due after 01 Dec, 2022 02:09:36</label><br />
                                                    <input type="text" placeholder='$0.00' />
                                                </div>
                                            </div>
                                            <div className='col-3 cstm-mail-due-date'>
                                                <div className='days-col'>
                                                    <label>Amount due after 01 Dec, 2022 02:09:36</label><br />
                                                    <input type="text" placeholder='$0.00' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='box1 cstm-box1-divider'></div>
                                    <div>
                                        <div className="row">
                                            <div className="col-12 userdetail-txt1">
                                                <h4>Notice Date</h4>
                                            </div>
                                            <div className='col-3 cstm-mail-due-date'>
                                                <div className='days-col'>
                                                    <label>Notice 1</label><br />
                                                    <input type="text" placeholder='$0.00' />
                                                </div>
                                            </div>
                                            <div className='col-3 cstm-mail-due-date'>
                                                <div className='days-col'>
                                                    <label>Notice 1</label><br />
                                                    <input type="text" placeholder='$0.00' />
                                                </div>
                                            </div>
                                            <div className='col-3 cstm-mail-due-date'>
                                                <div className='days-col'>
                                                    <label>Notice 1</label><br />
                                                    <input type="text" placeholder='$0.00' />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='box1 cstm-box1-divider'></div>
                                <div className='cstm-modal-row'>
                                    <div className='cstm--modal-col cstm-commercial-input'>
                                        <label>DMV hold case no*</label> <br />
                                        <input type="text" placeholder='8494684' />
                                    </div>

                                </div>
                                <div className='box1 cstm-box1-divider'></div>
                                <div className='cstm-modal-row-1'>
                                    <div className="cstm-modal-textare cstm-commercial-input">
                                        <label>Remark*</label> <br />
                                        <textarea name="" id="" cols="30" rows="8"></textarea>
                                    </div>
                                </div>

                                <div className="cstm-submit-btn change-amount-submit-btn">
                                    <a href="#"><button>submit</button></a>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ChangeAmountModal;