const initialState = {
  stepFormData: null,
  permitData: null,
  quantity: null,
  permitLayoutData: null,
  permitUserInfo: null,
  permitLocationData: null,
};

const permitReducer = function (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case 'PERMIT_STEP_FORM':
      return {
        ...state,
        stepFormData: payload,
      };
    case 'PERMIT_DATA':
      return {
        ...state,
        permitData: payload,
      };
    case 'PERMIT_QUANTITY_DATA':
      return {
        ...state,
        quantity: payload,
      };
    case 'PERMIT_LAYOUT_DATA':
      return {
        ...state,
        permitLayoutData: payload,
      };
    case 'PERMIT_USER_INFO':
      return {
        ...state,
        permitUserInfo: payload,
      };
    case 'PERMIT_LOCATION_DATA':
      return {
        ...state,
        permitLocationData: payload,
      };
    default:
      return state;
  }
};

export default permitReducer;
