import { Link } from "react-router-dom";
import Cookies from "js-cookie";

// check if site has access to Helical reports
export const hasHelicalReports = () => {
  const currentSite = Cookies.get('siteinfo');
  const siteArray = [
    'ead64c86-3681-11ee-9c41-6ef2fb394a83', // Bismarck prod
    '9db6bd50-3681-11ee-b4ca-ae854bfaee9a', // Bismarck staging
  ];

  return siteArray.includes(currentSite)
}

const OtherReports = (uiElements) => {
  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-6 right-panel cstm-right-panel cstm-tab-links">
              <ul className="nav nav-tabs cstm-nav-tabs" id="otherreportsParent">
                <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                  <div
                    className={`nav-link bg-box accordion-heading-report tab-label check-cstm-open active`}
                    data-toggle="collapse"
                    data-target="#otherreportsChildContent"
                  >
                    Reconcilliation Reports
                  </div>

                  <div id="otherreportsChildContent">
                    <ul className="nav nav-list cstm-sub-item cstm-sub-item-main" data-parent="#otherreportsParent" id="otherreportsChild">
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_OTHER_REPORTS_->_HIGH_VALUE_PLATE_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/citationFineThresholdReport" className="nav-link2 bg-box">
                            High Value Plate Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_BY_CARD") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/otcCardPayments" className="nav-link2 bg-box">
                            OTC Card
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_BY_CASH") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/otcCashPayments" className="nav-link2 bg-box">
                            OTC Cash
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_BY_CHECK") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/otcCheckPayments" className="nav-link2 bg-box">
                            OTC Check
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_PAYMENTS_BY_USER_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/paymentsByUserReport" className="nav-link2 bg-box">
                            OTC User Payment Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_PAYMENTS_BY_TYPE") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/paymentsByType" className="nav-link2 bg-box">
                            Payments By Type
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_WEB_PAYMENTS_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/webPaymentsReport" className="nav-link2 bg-box">
                            Web Payments Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_IVR_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/ivrReport" className="nav-link2 bg-box">
                            IVR Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_LOCKBOX_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/lockboxReport" className="nav-link2 bg-box">
                            Lockbox Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_REFUNDS_OTC") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/refundsOTC" className="nav-link2 bg-box">
                            Refunds Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_AFTER_FIRST_NOTICE") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/paymentAfterFirstNotice" className="nav-link2 bg-box">
                            Payments After First Notice
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_AFTER_SECOND_NOTICE") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/secondNoticePayment" className="nav-link2 bg-box">
                            Payments After Second Notice
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_COLLECTIONS_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/collectionReport" className="nav-link2 bg-box">
                            Collections Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_REFUND_ELIGIBLE_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/refundEligibleReport" className="nav-link2 bg-box">
                            Refund Eligible Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_SUMMONS_PAYMENT_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/summonsPaymentReport" className="nav-link2 bg-box">
                            Summons Payment Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_INVOICE_PAYMENT_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/invoicePaymentReport" className="nav-link2 bg-box">
                            Invoice Payment Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_PAID_AFTER_COLLECTION_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/paidAfterCollectionReport" className="nav-link2 bg-box">
                            Paid After Collection
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_SKELETAL_PAYMENT_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/skeletalPaymentReport" className="nav-link2 bg-box">
                            Skeletal Payment Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_PARTIAL_PAYMENT_SOURCE") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/partialPaymentsSource" className="nav-link2 bg-box">
                            Partial Payment Source
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_NFL_AND_CHARGEBACK_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/nflAndChargebackReport" className="nav-link2 bg-box">
                            NFL And Chargeback Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_HEARTLAND_CSV_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/heartlandCSVReport" className="nav-link2 bg-box">
                            Heartland CSV Report
                          </Link>
                        </li>
                      }

                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_TICKET_PAYMENT_REPORT") &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/ticketPaymentReport" className="nav-link2 bg-box">
                            Ticket Payment Report
                          </Link>
                        </li>
                      }



                      {/**************** Bismarck Reports ************************/}
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_BISMARCK_DAILY_PARKING_WARRANTS") && hasHelicalReports() &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/bismarckDailyParkingWarrants" className="nav-link2 bg-box">
                            Daily Parking Warrants
                          </Link>
                        </li>
                      }
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_BISMARCK_DAILY_PAID_PARKING_TICKETS") && hasHelicalReports() &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/bismarckDailyPaidParkingTickets" className="nav-link2 bg-box">
                            Daily Paid Parking Tickets
                          </Link>
                        </li>
                      }
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_BISMARCK_DAILY_PARKING_TICKETS") && hasHelicalReports() &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/bismarckDailyParkingTickets" className="nav-link2 bg-box">
                            Daily Parking Tickets
                          </Link>
                        </li>
                      }
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_BISMARCK_DAILY_CLEARED_PARKING") && hasHelicalReports() &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/bismarckDailyClearedParkingTickets" className="nav-link2 bg-box">
                            Daily Cleared Parking Tickets
                          </Link>
                        </li>
                      }
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_BISMARCK_PARKING_TICKETS_ENTERED") && hasHelicalReports() &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/bismarckParkingTicketsEntered" className="nav-link2 bg-box">
                            Parking Tickets Entered
                          </Link>
                        </li>
                      }
                      {uiElements.uiElements.includes("PAGE_REVENUE_REPORT_PARKING_TICKETS_STATUS_CHANGE_REPORT") && hasHelicalReports() &&
                        <li className="nav-item cstm-nav-item">
                          <Link to="/parkingTicketsStatusChangeReport" className="nav-link2 bg-box">
                            Parking Tickets Status Change Report
                          </Link>
                        </li>
                      }
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherReports;
