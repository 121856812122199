import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, useFormikContext } from "formik";
import { ImageDataContext } from "./CitationWithImage";
import "./citation.css";
import dashboardService from "../../Services/dashboard.service";
import authHeader from "../../Services/auth.header";
import axios from "axios";
import { refreshTokenMiddleware } from "../customMiddelware";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { siteid } from "../../Global/site";
import { EditViewField, TextViewField, RadioButtonField, DropDownField } from "./FormFields";

const CreateCitationForm = ({
  image,
  handleBackButton,
  dropDownData,
  formTemplateData,
  onClickFormSubmit,
  showBackButton,
  className,
  shouldResetForm,
}) => {
  let hearingDateComponent = formTemplateData.find((item) => item.component === "SINGLE_FIELD" && item.fields.find((field) => field.name === "Hearing_Date"));
  if (hearingDateComponent) {
    hearingDateComponent = { ...hearingDateComponent, component: "Hearing Date" };
  }

  formTemplateData = formTemplateData.filter((item) => item.component !== "SINGLE_FIELD");

  if (hearingDateComponent) {
    formTemplateData.push(hearingDateComponent);
  }
  //sequence on form
  const componentSequence = ['Header', 'Hearing Date', 'Officer', 'Citation Type', 'Vehicle', 'Location', 'Violation', 'Comments'];
  formTemplateData = formTemplateData.sort((a, b) => componentSequence.indexOf(a.component) - componentSequence.indexOf(b.component));

  const [selectedMake, setSelectedMake] = useState("");
  const [imageInfo, setImageInfo] = useState(image);
  const [locationData, setLocationData] = useState({
    street: "",
    block: "",
    lot: "",
  });
  const imageData = useContext(ImageDataContext);

  // group and map make and model
  const makeModelList = dropDownData?.MakeModelList;
  const makeList = makeModelList?.map((item) => item.make_full);
  const makeListUnique = [...new Set(makeList)];

  const makeModelGrouped = makeListUnique?.map((make) => {
    return {
      make: make,
      models: makeModelList
        ?.filter((item) => item.make_full === make)
        .map((item) => item.model),
    };
  });

  //OfficerUpdateData has duplicate badge ids, so remove duplicates and sort by badge id
  const officerUpdateData = dropDownData?.officers;
  const officerUpdateDataUnique = officerUpdateData
    ?.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.officer_badge_id === item.officer_badge_id),
    )
    .sort((a, b) => a.officer_badge_id - b.officer_badge_id);

  //create unique list of ViolationList
  const violationList = dropDownData?.ViolationList;
  const violationListUnique = violationList
    ?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.code === item.code),
    )
    .map((item) => {
      return {
        code: item.code,
        description: item.violation_description,
        fine: item.amount
      };
    });

  const filterOfficerById = (id) => {
    const selectedOfficer = officerUpdateDataUnique?.find(
      (item) => item.officer_badge_id === parseInt(id),
    )

    if (selectedOfficer) {
      return `${selectedOfficer.officer_last_name} ${selectedOfficer.officer_first_name}`;
    } else {
      return "";
    }
  }

  return (
    <section>
      <div
        className={
          className
            ? "col-lg-10 col-md-9 col-sm-12 cstm-ctn cstm-operate-top"
            : ""
        }
      >
        <div className="content-main">
          {showBackButton && (
            <button className="cstm-allsite-btn" onClick={handleBackButton}>
              <div className="back-btn">Back</div>
            </button>
          )}
          <div className="container-fluid">
            <div className="row">
              <div className="form-container ">
                <Formik
                  initialValues={{
                    agency: dropDownData?.AgencyList?.[0]?.agency_name ?? "",
                    citation_number: imageData?.ticket_number ?? "",
                    timestamp: (imageData?.ticket_issue_date_time)?.slice(0, -1) ?? "",
                    badge_id: imageData?.officer_id ?? "",
                    officer_name: imageData?.officer_id ? filterOfficerById(imageData?.officer_id) ?? "" : "",
                    color: imageData?.colour ?? "",
                    make: imageData?.make ?? "",
                    model: imageData?.make ? imageData?.model ?? "" : "",
                    state: imageData?.plate_state ?? "",
                    street: imageData?.location_2 ? locationData?.street ?? "" : "",
                    lot: imageData?.lot ? locationData?.lot ?? "" : "",
                    block: imageData?.location_1 ? locationData?.block ?? "" : "",
                    remark_1: imageData?.remark_1 ?? "",
                    code: imageData?.vio_code ?? "",
                    lp_number: imageData?.plate_number ?? "",
                    fine: imageData?.vio_code ? violationListUnique?.find(
                      (item) => `${item.code}` === imageData?.vio_code,
                    )?.fine ?? "" : "",
                    description: imageData?.vio_code ? violationListUnique?.find(
                      (item) => (`${item.code}`) === (imageData?.vio_code),
                    )?.description ?? "" : "",
                    type: "",
                    image_urls: imageInfo?.url ?? "",
                    Hearing_Date: null,
                  }}
                  onSubmit={(values) => {
                    onClickFormSubmit(values);
                  }}
                >
                  <FormikForm
                    dropDownData={dropDownData}
                    setSelectedMake={setSelectedMake}
                    selectedMake={selectedMake}
                    makeModelGrouped={makeModelGrouped}
                    officerUpdateDataUnique={officerUpdateDataUnique}
                    formTemplateData={formTemplateData}
                    violationListUnique={violationListUnique}
                    shouldResetForm={shouldResetForm}
                    imageInfo={imageInfo}
                    setImageInfo={setImageInfo}
                    locationData={locationData}
                    setLocationData={setLocationData}
                  />
                </Formik>
              </div>
              {imageInfo && (
                <div className="col-md-6 image-container">
                  <img
                    src={imageInfo.url}
                    alt="Citation"
                    style={{ position: "sticky", top: 120 }}
                    className="image-icon-large"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FormikForm = ({
  dropDownData,
  setSelectedMake,
  selectedMake,
  makeModelGrouped,
  officerUpdateDataUnique,
  formTemplateData,
  violationListUnique,
  shouldResetForm,
  imageInfo,
  setImageInfo,
  locationData,
  setLocationData,
}) => {

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const { setFieldValue, resetForm } = useFormikContext();
  const fileInputRef = useRef(null);
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const site_id = siteid()
  const [isUploading, setIsUploading] = useState(false);

  const fetchImage = async (imageUrl) => {
    try {
      const imageData = {
        'links': { 'url': imageUrl },
        'download_type': 'CitationImages',
        'site_id': site_id.siteinfo
      }
      const response = await dashboardService.downloadImage(imageData);
      return response.data
    } catch (error) {
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        fetchImage(imageUrl);
      } else {
        throw error;
      }
    }
  };

  const uploadFiles = async (formData) => {
    try {
      setIsUploading(true);
      const response = await axios.request({
        method: 'post',
        url: `${dashboardService.API_URL}static_file/cp/upload_files`,
        headers: authHeader(),
        data: formData,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (toastId.current === null) {
            toastId.current = toast('Please wait while your files are uploaded', { progress: `${progress}%` });
          } else {
            toast.update(toastId.current, { progress: `${progress}%` });
          }
        },
      })
      if (response.data?.status) {
        toast.dismiss(toastId.current)
        toast.success('File Upload successful!');
        //reset toastId so that if user uploads another file without refreshing the page, the upload progree can be shown
        toastId.current = null;
      }
      setIsUploading(false);
      return response;
    } catch (error) {
      toast.dismiss(toastId.current)
      toast.error('File upload failed. Please try again.');
      toastId.current = null;
      setIsUploading(false);
      throw error
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const data_array = []
    const formData = new FormData();
    data_array.push(`${uuidv4()}`)
    formData.append("files", file);
    formData.append("data", data_array);
    formData.append("upload_type", "AppealImage");

    try {
      const upload_result = await uploadFiles(formData)

      if (upload_result.data?.status) {
        const image_url = upload_result.data?.data?.[0]?.response?.links?.[0]
        const fetchImageResult = await fetchImage(image_url)
        setImageInfo({
          name: file.name,
          url: fetchImageResult?.metadata?.[0]?.url,
        });
        setFieldValue("image_urls", image_url);
      }
    } catch (err) {
      if (err.response.status === 401) {
        await refreshTokenMiddleware(dispatch);
        await uploadFiles(formData);
      } else {
        throw err;
      }
    }
  };


  useEffect(() => {
    if (shouldResetForm) {
      resetForm();
      setImageInfo(null)
    }
  }, [resetForm, setImageInfo, shouldResetForm]);

  const handleBadgeIdChange = (e) => {
    const selectedBadgeId = e.target.value;

    setFieldValue("badge_id", selectedBadgeId);

    const selectedOfficer = officerUpdateDataUnique?.find(
      (item) => item.officer_badge_id === parseInt(selectedBadgeId),
    );

    if (selectedOfficer) {
      setFieldValue(
        "officer_name",
        `${selectedOfficer.officer_last_name} ${selectedOfficer.officer_first_name}`,
      );
    }
  };

  const handleOfficerNameChange = (e) => {
    const selectedOfficerName = e.target.value;
    const selectedOfficer = officerUpdateDataUnique?.find(
      (item) => `${item.officer_first_name} ${item.officer_last_name}` === selectedOfficerName,
    );

    setFieldValue("officer_name", `${selectedOfficerName}`);
    if (selectedOfficer) {
      setFieldValue(
        "badge_id",
        `${selectedOfficer.officer_badge_id}`,
      );
    }
  };

  const handleViolationCodeChange = (e) => {
    const selectedViolationCode = e.target.value;

    setFieldValue("code", selectedViolationCode);

    const selectedViolation = violationListUnique?.find(
      (item) => `${item.code}` === selectedViolationCode
    );

    if (selectedViolation) {
      setFieldValue("description", selectedViolation.description);
      setFieldValue("fine", selectedViolation.fine);
    } else {
      setFieldValue("description", "");
      setFieldValue("fine", "");
    }
  };

  const handleCarMakeChange = (e) => {
    setSelectedMake(e.target.value);
    setFieldValue("make", e.target.value);
    setFieldValue("model", "")
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    const newData = { ...locationData, [name]: value };

    // Find the corresponding data based on the changed field
    const correspondingData = dropDownData?.LotList.find(item => {
      if (name === 'lot' && item.lot === value) return true;
      if (name === 'block' && item.block === value) return true;
      if (name === 'street' && item.street === value) return true;
      return false;
    });

    // If corresponding data is found, update the state to autofill
    if (correspondingData) {
      newData.lot = correspondingData.lot;
      newData.block = correspondingData.block;
      newData.street = correspondingData.street;
    }

    setLocationData(newData);
    setFieldValue("lot", newData.lot);
    setFieldValue("block", newData.block);
    setFieldValue("street", newData.street);
  }

  return (
    <Form>
      {formTemplateData.map((component, index) => {
        return (
          <>
            <ToastContainer />
            <div key={index}>
              <h4 className="text-center">{component.component}</h4>
              {component.fields.map((field, index) => {
                if (field.tag === "editview") {
                  return <EditViewField key={index} field={field} />;
                } else if (field.tag === "checkbox") {
                  return <RadioButtonField key={index} field={field} />;
                } else if (field.tag === "textview" || field.tag === "textarea") {
                  return <TextViewField key={index} field={field} handleOfficerNameChange={handleOfficerNameChange} officerUpdateDataUnique={officerUpdateDataUnique} />;
                } else if (field.tag === "dropdown") {
                  return (
                    <DropDownField
                      key={index}
                      field={field}
                      dropDownData={dropDownData}
                      handleBadgeIdChange={handleBadgeIdChange}
                      handleOfficerNameChange={handleOfficerNameChange}
                      handleViolationCodeChange={handleViolationCodeChange}
                      handleCarMakeChange={handleCarMakeChange}
                      selectedMake={selectedMake}
                      makeModelGrouped={makeModelGrouped}
                      officerUpdateDataUnique={officerUpdateDataUnique}
                      violationListUnique={violationListUnique}
                      handleLocationChange={handleLocationChange}
                      locationData={locationData}
                    />
                  );
                } else return null;
              })}
            </div></>
        );
      })}
      <div>
        {!isUploading && <button
          className="auth-cstm-submit-btn"
          type="submit"
          style={{ marginRight: '10px' }}
          disabled={isUploading}
        >
          Submit
        </button>}
        {!imageInfo && (
          <>
            <button className="auth-cstm-submit-btn" type="button" onClick={handleImageUpload}>
              Upload Image
            </button>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange} />
          </>
        )}
      </div>
    </Form>
  );
};

export default CreateCitationForm;