import React from 'react';
import TransferImg from '../Images/transfer.png'
import FilterExportImg from '../Images/export.png'
import { changetotal } from "../Global/site";


const DMVLockup = () => {
    const changeTotal = changetotal();

    return (<>
        <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
            <div className="appeal-report-heading">
                <h4>DMV Lookup</h4>
            </div> 
            
            <div className="filter-button2">
                <input type="text" className='date-time-input' placeholder='Start Date and Time End Date and Time' />
                <input type="text" placeholder={changeTotal + ' Number' } />
                <input type="text" placeholder='Plate Number' />
                <input type="text" placeholder='State' />
                <a href="#"><button className="export-button"><img src={FilterExportImg} alt="FilterExportImg" className="img-fluid" /></button></a>
                <a href="#"><button className="submit-button">Submit</button></a>

            </div>

                <div className="ctsm-table-scroll">
                    <div className="">
                        <table className="table-heading table table-bordered">
                            <tr>
                                <th className="form-group-checkbox">
                                    <input type="checkbox" id="2" />
                                    <label htmlFor="2"></label>
                                    Date of issue
                                </th>
                                <th>{changeTotal} #</th>
                                <th>DMV Status</th>
                                <th>Officer Name</th>
                                <th>Plate number</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Address</th>
                               
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                           
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                           
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                           
                            <tr>
                                <td className="form-group-checkbox">
                                    <input type="checkbox" id="3" />
                                    <label htmlFor="3"></label>
                                    22 Jun, 2021 20:26:00
                                </td>
                                <td> <a className='underlineanchor'>123456</a></td>
                                <td>Open</td>
                                <td>123456</td>
                                <td>Wade Warren</td>
                                <td>(201) 555-0124</td>
                                <td>michelle.rivera@example.com</td>
                                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
                                
                            </tr>
                           

                        </table>
                    </div>
                </div>
      
        </div>
    </>)
}
export default DMVLockup