import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { state, make, model } from "../Common/dropDownOptions";
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../customMiddelware";
import { useDispatch } from "react-redux";
import { siteid } from "../../Global/site";

// library
import Select from "react-select";

import BackbuttinIMag from "../../Images/back.png";

const regexEmail =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const initialValues = {
  first_name: "",
  last_name: "",
  user_name: "",
  password: "",
  confirm_password: "",
  role_id: "",
  user_email: "",
  phone_number: "",
  phone_code: "+1",
  address: "",
  city: "",
  zip_code: "",
  // vehicleDetails: [{
  //     plate: '',
  //     state: { value: "CA", label: 'CALIFORNIA' },
  //     make: '',
  //     model: ''
  // }]
};
const initialErrors = {
  first_name: "",
  last_name: "",
  user_name: "",
  password: "",
  confirm_password: "",
  role_id: "",
  user_email: "",
  phone_number: "",
  agency_list: "",
};

const EnrollNewUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formvalues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [roleList, setRoleList] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [showAgencyListing, setAgencyListing] = useState([]);
  const [showSelectedSiteId, setShowSelectedSiteId] = useState([]);
  const showSideId = siteid();

  useEffect(() => {
    fetchRoleList();
    // Get and Display Agency List option in drop-down
    mainService.agencieslistAPI().then((response) => {
      if (response.data.status === false) {
      } else {
        setAgencyListing(response.data.data.agency_map);
      }
    });
  }, []);

  const fetchRoleList = async () => {
    try {
      const result = await mainService.rolesListAPI();

      if (result.data.status) {
        const newarr = result.data.data.role_data.map((e1) => {
          return { name: "role_id", value: e1.role_id, label: e1.role_name };
        });
        setRoleList(newarr);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        fetchRoleList();
      }
    }
  };

  const handleInputChange = (e) => {
    setError("");
    setMessage("");
    if (e.name === "role_id") {
      setFormValues({ ...formvalues, [e.name]: e.value });
      setFormErrors({ ...formErrors, [e.name]: "" });
    } else {
      const { name, value } = e.target;
      setFormValues({ ...formvalues, [name]: value });
      validateInput(e);
    }
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setFormErrors((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (
            formvalues.confirm_password &&
            value !== formvalues.confirm_password
          ) {
            stateObj["confirm_password"] = "Passwords must match.";
          } else {
            stateObj["confirm_password"] = formvalues.confirm_password
              ? ""
              : formErrors.confirm_password;
          }
          break;

        case "confirm_password":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (formvalues.password && value !== formvalues.password) {
            stateObj[name] = "Passwords must match.";
          }
          break;

        case "user_email":
          if (!value) {
            stateObj[name] = "Please enter email.";
          } else if (!regexEmail.test(value)) {
            stateObj[name] = "Email address not valid.";
          } else {
            stateObj[name] = "";
          }

        default:
          break;
      }

      return stateObj;
    });
  };

  // const handleMoreChange = (i, type, event) => {
  //     setError('')
  //     setMessage('')
  //     if (type === 'plate') {
  //         const { name, value } = event.target
  //         let newFormValues = { ...formvalues };
  //         newFormValues.vehicleDetails[i][name] = value;
  //         setFormValues(newFormValues);

  //     } else {
  //         let newFormValues = { ...formvalues };
  //         newFormValues.vehicleDetails[i][type] = event;
  //         setFormValues(newFormValues);

  //     }

  // }

  // const addFormFields = () => {
  //     setError('')
  //     setMessage('')
  //     let newFormValues = { ...formvalues };
  //     newFormValues.vehicleDetails.push({
  //         plate: '',
  //         state: { value: "CA", label: 'CALIFORNIA' },
  //         make: '',
  //         model: ''
  //     });
  //     setFormValues(newFormValues);
  // }

  // const removeFormFields = (i) => {
  //     setError('')
  //     setMessage('')
  //     let newFormValues = { ...formvalues };
  //     newFormValues.vehicleDetails.splice(i, 1);
  //     setFormValues(newFormValues)
  // }

  const handleCreateNewUserSubmit = (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    let isValid = false;
    Object.entries(formvalues).forEach(([key, value]) => {
      if (value === "" && key in formErrors) {
        isValid = true;
        if (key === "role_id") {
          formErrors[key] = `Select user role`;
        } else {
         
          formErrors[key] = `Enter ${key.replace(/_/g, " ")}`;
        }
      }
    });
    if(showSelectedSiteId.length === 0) {
        formErrors.agency_list = `Please Select Site`;
        isValid = true;
    }
    setFormErrors({ ...formErrors });

    if (!isValid) {
      setBtnLoader(true);
      let payload = { ...formvalues };
      payload.vehicleDetails = null;
    //   payload.site_ids = showSideId.siteinfo;
      payload.site_ids = showSelectedSiteId.toString();
      // payload.vehicleDetails = formvalues.vehicleDetails.length > 0 ?
      //     formvalues.vehicleDetails.map((e1) => {
      //         return {
      //             plate: e1.plate,
      //             state: e1.state?.value || '',
      //             make: e1.make?.value || '',
      //             model: e1.model?.value || ''
      //         }
      //     }) :
      //     []
      usercreatefun();
      async function usercreatefun() {
        try {
          const result = await mainService.createNewUserAPI(payload);

          setBtnLoader(false);
          if (result.data.status) {
            navigate("/users");
            // setMessage("User Created.")
          } else {
            // setFormValues(initialValues)
            setError(result.data.message);
          }
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch);
            usercreatefun();
          }
        }
      }
    }
  };

  const onChangeSites = (e) => {
    const siteIds = showSelectedSiteId;

    if (e.target.checked) {
      siteIds.push(e.target.value);
      setFormErrors({ ...formErrors , agency_list: ""});
    } else {
      let siteIndex = showSelectedSiteId.indexOf(e.target.value);
      siteIds.splice(siteIndex, 1);
    }
    setShowSelectedSiteId(siteIds);
  };

  return (
    <>
      <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
        <div className="row">
          <div className="col-12 back-button back-button-citationdetails">
            <Link onClick={() => navigate(-1)}>
              <img src={BackbuttinIMag} alt="img" className="img-fluid" /> Back
            </Link>
          </div>
        </div>
        <div className="rightpanel-box cstm-user-enroll-form">
          <div>
            <div className="row">
              <div className="col-12 userdetail-txt1">
                <h4>Create New User</h4>
              </div>
            </div>
            {message && (
              <div className="alert alert-success" role="alert">
                {message}
              </div>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <form onSubmit={handleCreateNewUserSubmit} autoComplete="off">
              <div className="row">
                <div className="col-6 cstm-inputfield">
                  <label>First Name *</label> <br />
                  <input
                    type="text"
                    name="first_name"
                    onChange={handleInputChange}
                    placeholder="first name"
                  />
                  <span className="errormessage">{formErrors.first_name}</span>
                </div>
                <div className="col-6 cstm-inputfield">
                  <label>Last Name *</label> <br />
                  <input
                    type="text"
                    name="last_name"
                    onChange={handleInputChange}
                    placeholder="last name"
                  />
                  <span className="errormessage">{formErrors.last_name}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 cstm-inputfield">
                  <label>Username *</label> <br />
                  <input
                    type="text"
                    name="user_name"
                    onChange={handleInputChange}
                    autoComplete="username"
                    placeholder="username"
                  />
                  <span className="errormessage">{formErrors.user_name}</span>
                </div>
                <div className="col-6 cstm-inputfield">
                  <label>Email *</label> <br />
                  <input
                    type="text"
                    name="user_email"
                    onChange={handleInputChange}
                    autoComplete="email"
                    placeholder="email"
                  />
                  <span className="errormessage">{formErrors.user_email}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 cstm-inputfield">
                  <label>Password *</label> <br />
                  <input
                    type="password"
                    name="password"
                    onChange={handleInputChange}
                    placeholder="password"
                    autoComplete="new-password"
                  />
                  <span className="errormessage">{formErrors.password}</span>
                </div>
                <div className="col-6 cstm-inputfield">
                  <label>Confirm Password *</label> <br />
                  <input
                    type="password"
                    name="confirm_password"
                    onChange={handleInputChange}
                    placeholder="confirm password"
                  />
                  <span className="errormessage">
                    {formErrors.confirm_password}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 form-group formgroup-main">
                  <label htmlFor="">Role *</label>
                  <Select
                    name="role_id"
                    options={roleList}
                    selectedOption="Active"
                    onChange={handleInputChange}
                    searchable
                  />
                  <span className="errormessage">{formErrors.role_id}</span>
                </div>
                <div className="col-6 cstm-inputfield">
                  <label>Phone Number *</label> <br />
                  <input
                    type="text"
                    name="phone_number"
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength="10"
                    placeholder="phone number"
                  />
                  <span className="errormessage">
                    {formErrors.phone_number}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 cstm-inputfield">
                  <label>City</label> <br />
                  <input
                    type="text"
                    name="city"
                    onChange={handleInputChange}
                    placeholder="city"
                  />
                </div>
                <div className="col-6 cstm-inputfield">
                  <label>Zip Code</label> <br />
                  <input
                    type="text"
                    name="zip_code"
                    onChange={handleInputChange}
                    placeholder="zip code"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 cstm-inputfield">
                  <label>Address</label>
                  <br />
                  <textarea
                    name="address"
                    id=""
                    cols="30"
                    rows="4"
                    onChange={handleInputChange}
                    placeholder="address"
                  ></textarea>
                </div>
              </div>
              <div className="form-group">
                <label>Select Sites*</label>
                <div className="main-agency-sec-add">
                  {showAgencyListing &&
                    showAgencyListing?.map((item, index) => (
                      <>
                        <div className="enroll-agency-check-cstm">
                          {item?.agency_data && item?.agency_data?.agency_name}
                          <div
                            id={index}
                            style={{
                              display:`${"block"}`,
                            }}
                          >
                            {item?.site_data && item?.site_data?.map((itemDataValue, index) => (
                              <div className="in-cstm-sub-agency">
                                <input
                                  type="checkbox"
                                  id={item?.agency_data?.agency_id}
                                  value={itemDataValue?.site_id}
                                  onChange={(e) => onChangeSites(e)}
                                />
                                {itemDataValue?.site_name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                <span className="errormessage">
                  {formErrors.agency_list}
                </span>
              </div>
              {/* {formvalues.vehicleDetails.map((element, index) => (
                                <div key={index} className="row add-details-border">
                                    <button type="button" onClick={() => removeFormFields(index)} className="button remove cstm-close-btn-my-profile">×</button>
                                    <div className="col-6 form-group formgroup-main">
                                        <label htmlFor="">Plate</label>
                                        <input
                                            type="text"
                                            name="plate"
                                            className="form-control"
                                            placeholder="Plate"
                                            onChange={e => handleMoreChange(index, 'plate', e)}
                                            value={element.plate || ''}
                                        />

                                    </div>
                                    <div className="col-6 form-group formgroup-main">
                                        <label htmlFor="">State</label>
                                        <Select
                                            name="state"
                                            options={state}
                                            selectedOption="Active"
                                            onChange={e => handleMoreChange(index, 'state', e)}
                                            searchable
                                            value={element.state || ''}
                                        />

                                    </div>
                                    <div className="col-6 form-group formgroup-main">
                                        <label htmlFor="">Make</label>
                                        <Select
                                            name="make"
                                            options={make}
                                            selectedOption="Active"
                                            onChange={e => handleMoreChange(index, 'make', e)}
                                            searchable
                                            value={element.make || ''}
                                        />

                                    </div>
                                    <div className="col-6 form-group formgroup-main">
                                        <label htmlFor="">Model</label>
                                        <Select
                                            name="model"
                                            options={model}
                                            selectedOption="Active"
                                            onChange={e => handleMoreChange(index, 'model', e)}
                                            searchable
                                            value={element.model || ''}
                                        />

                                    </div>
                                </div>
                            ))} */}
              {/* <div className="row">
                                <div className="col-12 add-details cstm-add-vd">
                                    <button className="button add" type="button" onClick={() => addFormFields()}>+ Add Vehicle Details</button>
                                </div>
                            </div> */}
              <div className="row">
                <div className="col-12 button2">
                  <button className="btn" type="submit" disabled={isBtnLoader}>
                    {isBtnLoader && (
                      <div className="cstm-loader">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollNewUser;
