import { useEffect, useState } from "react";
import mainService from "../../../../Services/main.service";
import PermitFormBuilderComponent from "./permitFormBuilderComponent";
import { refreshTokenMiddleware } from "../../../customMiddelware";
import { useDispatch } from "react-redux";
import { siteid } from "../../../../Global/site";

const PermitFormBuilderContainer = ({
  permitName,
  isEditPermit,
  permitTime,
  permitTitle,
  residentType
}) => {
  const dispatch = useDispatch();
  const siteId = siteid()
  const [editFields, setEditFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const permitLayoutHandler = async (payload) => {
    setLoading(true);
    try {
      const response = await mainService.createPermitLayout(payload);
      setResponseMessage(response.data.message)
      setResponseStatus(response.status)
    } catch (error) {
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        permitLayoutHandler()
      }
      setResponseStatus(error.response.status);
      setResponseMessage(error.response.data.message);
    }
    setLoading(false);
  };

  const getPermitLayout = async (name) => {
    try {
      const response = await mainService.getPermitLayout(name.trim(), siteId.siteinfo);
      setEditFields(response.data.template);
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getPermitLayout()
      }
    }
  };

  useEffect(() => {
    if (permitName !== "" && isEditPermit) {
      getPermitLayout(permitName);
    }
  }, [permitName, isEditPermit]);

  return (
    <>
      <PermitFormBuilderComponent
        permitLayoutHandler={permitLayoutHandler}
        permitName={permitName}
        editFields={editFields}
        isEditPermit={isEditPermit}
        permitTime={permitTime}
        loading={loading}
        responseStatus={responseStatus}
        responseMessage={responseMessage}
        permitTitle={permitTitle}
        residentType={residentType}
      />
    </>
  );
};

export default PermitFormBuilderContainer;
