import PermitAdministratorComponent from "./permitAdministratorComponent"

const PermitAdministratorContainer = () => {
    return (
        <>
            <PermitAdministratorComponent />
        </>
    )
}

export default PermitAdministratorContainer;