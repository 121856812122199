import { useState, useEffect } from "react";
import mainService from "../../../Services/main.service";
import { Link } from "react-router-dom";
import {useDispatch} from "react-redux";
import { refreshTokenMiddleware } from "../../customMiddelware";

const AddResource = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState({
    rname: "",
    endpoint: "",
    bname: "",
    parentbname: "",
    type: "",
    pselect: "",
    sname: "",
  });

  const [error, setError] = useState({
    rname: "",
    endpoint: "",
    bname: "",
    parentbname: "",
    type: "",
    pselect: "",
    sname: "",
  });

  const [resource, setResource] = useState({});
  const [bundle, setBundle] = useState({});
  const [scope, setScope] = useState({});
  const [isactive, setIsactive] = useState(false);
  const [activeresource, setActiveresource] = useState(false);
  const [activescope, setActivescope] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errormessage, setErrormessage] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(false);
  const [rightClick, setRightClick] = useState();

  useEffect(() => {
    setActiveresource(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });

    // Validation
    if (value === "") {
      setError({ ...error, [name]: "Field is required." });
    } else {
      setError({ ...error, [name]: "" });
    }

    if (name === "type") {
      if (value !== "") {
        setError({ ...error, type: "" });
      } else {
        setError({ ...error, type: "Please select your type." });
      }
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    var Boday = {
      resource_name: data.rname,
      endpoint_name: data.endpoint,
    };
    if (!data.rname || !data.endpoint) {
      setError({
        rname: !data.rname ? "Field is required." : "",
        endpoint: !data.endpoint ? "Field is required." : "",
      });
      return;
    }

    if (data !== "") {
      mainService.AddResourceAPI(Boday).then((response) => {
        if (response.data.status === true) {
          setActiveresource(false);
          setIsactive(true);
          setActivescope(false);
          setSuccess(false);
          setActive(true);
          setResource(response.data.data);
        } else {
          setActiveresource(false);
          setIsactive(false);
          setActive(false);
          setActivescope(false);
          setSuccess(false);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          handlesubmit(e);
        }
      })
    }

    if (error.rname || error.endpoint) {
      return;
    }

    setData({ ...data });
  };

  const handleBundleSubmit = (e) => {
    e.preventDefault();

    var BundleData = {
      bundle_name: data.rname,
      parent_bundle_name: data.parentbname,
      type: data.type,
    };
    if (!data.rname || !data.parentbname || !data.type) {
      setError({
        rname: !data.rname ? "Field is required." : "",
        parentbname: !data.parentbname ? "Field is required." : "",
        type: !data.type ? "Field is required." : "",
      });
      return;
    }

    if (data !== "") {
      mainService.AddBundleAPI(BundleData).then((response) => {
        if (response.data.status === true) {
          setActiveresource(false);
          setIsactive(false);
          setActivescope(true);
          setSuccess(false);
          setErrormessage(false);
          setActive(true);
          setActive1(true);
          setBundle(response.data.data);
        } else {
          setActiveresource(false);
          setIsactive(true);
          setActivescope(false);
          setSuccess(false);
          setActive(true);
          setActive1(false);
          setErrormessage(true);
          setMessage(response.data.message);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          handlesubmit(e);
        }
      });
    }

    if (error.rname || error.parentbname || error.type) {
      return;
    }

    setData({ ...data });
  };

  const handleScopeSubmit = (e) => {
    e.preventDefault();

    var ScopeData = {
      scope_name: data.rname,
      resource_id: resource.resource_id,
      scope_type: "create",
      bundle_id: bundle.bundle_id,
    };
    if (!data.rname) {
      setError({
        rname: !data.rname ? "Field is required." : "",
      });
      return;
    }

    if (data !== "") {
      mainService.AddScopeAPI(ScopeData).then((response) => {
        if (response.data.status === true) {
          setIsactive(false);
          setActiveresource(false);
          setActivescope(false);
          setSuccess(false);
          setActive(true);
          setActive1(true);
          setScope(response.data.data);
          setActive(true);
          setSuccess(true);
        } else {
          setIsactive(false);
          setActiveresource(false);
          setActive(true);
          setActive1(true);
          setActivescope(false);
          setSuccess(false);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          handlesubmit(e);
        }
      });
    }

    if (error.rname) {
      return;
    }
  };

  const handleCopy = () => {
    var data = navigator.clipboard.writeText(scope.ui_element_name);
    setRightClick(data);
  };

  return (
    <>
      <div class="col-lg-12 col-md-9 col-sm-12 cstm-ctn cstm-operate-top cstm_configure_section">
        <div class="cstm-configure-site-title">
          <h2>Add Resource</h2>
        </div>

        <div class="cstm-add-reso-pgs">
          <div class="cstm-add-reso-pgs-step">
            <Link class="cstm-add-reso-pgs-step-list active">
              <div class="cstm-add-reso-pgs-step-main  cstm-hdn">
                <span class="cstm-add-reso-pgs-step-number">01</span>
              </div>
              <div class="cstm-add-reso-pgs-step-name">Add Resource</div>
            </Link>
            <a
              class={`cstm-add-reso-pgs-step-list ${active ? "active" : ""}`}
              href="#"
            >
              <div class="cstm-add-reso-pgs-step-main">
                <span class="cstm-add-reso-pgs-step-number">02</span>
              </div>
              <div class="cstm-add-reso-pgs-step-name">Add Bundle</div>
            </a>
            <a
              class={`cstm-add-reso-pgs-step-list ${active1 ? "active" : ""}`}
              href="#"
            >
              <div class="cstm-add-reso-pgs-step-main">
                <span class="cstm-add-reso-pgs-step-number">03</span>
              </div>
              <div class="cstm-add-reso-pgs-step-name">Add Scope</div>
            </a>
          </div>
        </div>

        <div class="cstm-add-reso-form">
          {activeresource ? (
            <form onSubmit={handlesubmit}>
              <div className="cstm-add-reso-form-box">
                <div className="cstm-add-reso-form-box-label">
                  Resource Name
                </div>
                <div className="cstm-add-reso-form-box-field">
                  <input
                    className="cstm-ar-ipt"
                    value={data.rname}
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    name="rname"
                    id="rname"
                    placeholder="Enter Resource Name"
                  />
                </div>
                {error.rname && (
                  <span className="cstm-ar-msg">{error.rname}</span>
                )}
              </div>
              <div className="cstm-add-reso-form-box">
                <div className="cstm-add-reso-form-box-label">
                  API End Point Name
                </div>
                <div className="cstm-add-reso-form-box-field">
                  <input
                    className="cstm-ar-ipt"
                    value={data.endpoint}
                    type="text"
                    autoComplete="off"
                    id="endpoint"
                    onChange={handleChange}
                    name="endpoint"
                    placeholder="Enter API End Point"
                  />
                </div>
                {error.endpoint && (
                  <span className="cstm-ar-msg">{error.endpoint}</span>
                )}
              </div>
              <div className="cstm-add-reso-form-btn">
                <button
                  className="cstm-next-btn"
                  type="submit"
                  variant="contained"
                >
                  Next
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}

          {isactive ? (
            <form onSubmit={handleBundleSubmit}>
              <div className="cstm-add-reso-form-box">
                <div className="cstm-add-reso-form-box-label">Bundle Name</div>
                <div className="cstm-add-reso-form-box-field">
                  <input
                    className="cstm-ar-ipt"
                    value={data.rname}
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    name="rname"
                    id="rname"
                    placeholder="Enter Bundle Name"
                  />
                </div>
                {error.rname && (
                  <span className="cstm-ar-msg">{error.rname}</span>
                )}
              </div>
              <div className="cstm-add-reso-form-box">
                <div className="cstm-add-reso-form-box-label">
                  Parent Bundle Name
                </div>

                <div className="cstm-add-reso-form-box-field">
                  {" "}
                  <select
                    className="cstm-bdl-type-select"
                    name="parentbname"
                    id="parentbname"
                    value={data.parentbname}
                    onChange={handleChange}
                  >
                    <option value="">Select name</option>
                    <option value="Dashboard">Dashboard</option>
                    <option value="Ticket Management">
                      Ticket Management
                    </option>
                    <option value="Ticket History Book">Ticket History Book</option>
                    <option value="Appeals Management">Appeals Management</option>
                    <option value="Hearing Management">Hearing Management</option>
                    <option value="Manual Citations">Manual Citations</option>
                    <option value="Revenue Report">
                      Revenue Report
                    </option>
                    <option value="Collections Management">Collections Management</option>
                    <option value="Ro Management">
                      RO Management
                    </option>
                    <option value="Notice Management">Notice Management</option>
                    <option value="Data Views">
                      Data Views
                    </option>
                    <option value="Reports">Reports</option>
                    <option value="Administration">Administration</option>
                    <option value="Permits Management">Permits Management</option>
                  </select>
                </div>
                {error.parentbname && (
                  <span className="cstm-ar-msg">{error.parentbname}</span>
                )}
              </div>

              <div className="cstm-add-reso-form-box">
                <div className="cstm-add-reso-form-box-label">Bundle Type</div>
                <div className="cstm-add-reso-form-box-field">
                  {" "}
                  <select
                    className="cstm-bdl-type-select"
                    name="type"
                    id="type"
                    value={data.type}
                    onChange={handleChange}
                  >
                    <option value="">Select type</option>
                    <option value="page">Page</option>
                    <option value="button">Button</option>
                  </select>
                </div>
                {error.type && (
                  <span className="cstm-ar-msg">{error.type}</span>
                )}
              </div>

              {errormessage ? (
                <span className="cstm-ar-msg22">{message}</span>
              ) : (
                <span className="cstm-ar-msg22"></span>
              )}

              <div className="cstm-add-reso-form-btn">
                <button
                  className="cstm-next-btn"
                  type="submit"
                  variant="contained"
                >
                  Next
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}

          {activescope ? (
            <form onSubmit={handleScopeSubmit}>
              <div className="cstm-add-reso-form-box">
                <div className="cstm-add-reso-form-box-label">Scope Name</div>
                <div className="cstm-add-reso-form-box-field">
                  <input
                    className="cstm-ar-ipt"
                    value={data.rname}
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    name="rname"
                    id="rname"
                    placeholder="Enter Scope Name"
                  />
                </div>
                {error.rname && (
                  <span className="cstm-ar-msg">{error.rname}</span>
                )}
              </div>
              <div className="cstm-add-reso-form-btn">
                <button
                  className="cstm-next-btn"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}

          {success ? (
            <div className="successfully">
              <div>
                Successfully Create Ui Element Name{" "}
                <div
                  className="pointer"
                  value={rightClick}
                  onClick={handleCopy}
                >
                  {scope.ui_name}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default AddResource;
