import React, { Component } from "react";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";

import Reportingsetup from "../../../../Services/main.service";
import { clearoutAuthUserData } from "../../../../actions/auth";
import { refreshtokenforClassComp } from "../../../../actions/auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";

import transfer from "../../../../Images/transfer.png";
import filter from "../../../../Images/filter-yellow.svg";
import dropdownarrow from "../../../../Images/dropdown-arrow.svg";
import close from "../../../../Images/close.svg";

import ProductTable from "./Sorting";
import TicketDetails from "../TicketDetails";
import PrintPdfCsvExcelDownload from "../../../Utilies/PrintPdfCsvExcelDownload";
import Cookies from "js-cookie";
import Cookiesremove from "../../../../Cookiesremove";
import { changeticket, changetotal } from "../../../../Global/site";
import ResendRo from "../ResendRo";
import TicketReportDetails from "../../../../Services/dashboard.service";
import SuccessIcon from "../../../../Images/success-icon.png";
import SearchLicensePlate from "../../../Citation/SearchLicensePlate";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

const isOCMD = () => {
  if (
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476"
  ) {
    return true;
  } else {
    return false;
  }
};

class TicketReporting extends Component {
  changeName = changeticket();
  changeTotal = changetotal();

  state = {
    lp_number: "",
    ticket_no: "",
    street: "",
    block: "",
    violationCodeFilter: [],
    violationDescFilter: [],
    officerNameFilter: [],
    peoNameFilter: [],
    group_by_officer: "",
    drive_off: "",
    status: "",
    allIntigrationData: [],
    setStreetList: [],
    badgeIDFilter: [],
    violationCode: [],
    officerName: [],
    setBeatTypes: [],
    setRemark: [],
    setZone: [],
    setShift: [],
    shiftFilter: [],
    setAgency: [],
    setStatusFilter: [],
    agencyFilter: [],
    zoneFilter: [],
    stateFilterRemarks: [],
    beatFilter: [],
    setBadgeID: [],
    dataGroupOfficerLog: {},
    stateFilter: [],
    noRecordFound: true,
    setOfficerName: [],
    setPagination: true,
    mainRecordDisplay: true,
    setFilterIconToggle: false,
    groupByOfficerLogs: false,
    setDownloadOption: false,
    dataIntigrationLog: [],
    showAuthToken: false,
    errorHandling: false,
    totalPages: null,
    passDataValue: "",
    setStartDate: "",
    setStartDateSearch: "",
    setEndDate: "",
    setEndDateSearch: "",
    totalDataLengthCount: 0,
    setDataLoadLoader: true,
    currentPage: 1,
    todosPerPage: 200,
    upperPageBound: 5,
    lowerPageBound: 0,
    isPrevBtnActive: "disabled",
    isNextBtnActive: "",
    pageBound: 3,
    setPaginationSelectVaue: 200,
    setShowDetailPage: false,
    stateFilterSelectedArray: [],
    officerFilterSelectedArray: [],
    peoFilterSelectedArray: [],
    badgeFilterSelectedArray: [],
    codeFilterSelectedArray: [],
    descriptionFilterSelectedArray: [],
    statusFilterSelectedArray: [],
    categoryFilterSelectedArray: [],
    zoneFilterSelectedArray: [],
    remarkFilterSelectedArray: [],
    beatFilterSelectedArray: [],
    setAllSelectedFilterOption: "",
    searchPayload: "",
    ticket_type: "",
    setShowResendROHandler: false,
    selectedCitation: [],
    setShowResendSuccess: false,
    setCategoryFilter: [],
    category: "",
  };

  exportsLogsData = () => {
    if (
      Cookies.get("ticket_no_search") !== undefined &&
      Cookies.get("ticket_no_search") !== "" &&
      Cookies.get("ticket_no_search") !== null
    ) {
      this.setState({ ticket_no: Cookies.get("ticket_no_search") });
      var passData = "ticket_no=" + Cookies.get("ticket_no_search");
    } else {
      var passData = "page=" + 1 + "&limit=" + this.state.todosPerPage;
    }

    const initialticketfun = () => {
      Reportingsetup.reportTicket(passData)
        .then((Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialticketfun();
          }
        });
    };
    initialticketfun();
  };

  // When Page reload at that time this function called
  componentDidMount() {
    this.exportsLogsData();
    if (this.props.location) {
      this.setState({ setShowDetailPage: true });
    }
    // Get and Display Violation List option for the search drop-down
    var passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    const initialtickviolationfun = () => {
      Reportingsetup.violationCode(passDataViolation)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ violationCode: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickviolationfun();
          }
        });
    };
    initialtickviolationfun();

    // Get and Display Officer name option for the search drop-down
    Reportingsetup.getOfficerName().then(
      (Response) => {
        if (Response.data.status === 300) {
          this.setState({ showAuthToken: true });
        } else {
          this.setState({ setOfficerName: Response.data.officer_names });
          this.setState({ setBadgeID: Response.data.officer_badge_ids });
        }
      },
      (err) => {
        // if (err.response.status === 401) {
        //   this.props.clearoutAuthUserData();
        //   window.location.href = '/'
        // }
      }
    );

    // Get and Display State List option for the search drop-down
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    const initialtickstatefun = () => {
      Reportingsetup.violationCode(passDataStateList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setStreetList: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickstatefun();
          }
        });
    };
    initialtickstatefun();

    // Get and Display Status List option for the search drop-down
    var passDataStateList = {
      type: "CitationStatusData",
      shard: 1,
    };
    const statelistfun = () => {
      Reportingsetup.violationCode(passDataStateList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setStatusFilter: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            statelistfun();
          }
        });
    };
    statelistfun();

    // Get and Display Category List option for the search drop-down
    var passDataStateList = {
      type: "CategoryData",
      shard: 1,
    };
    const categorylistfun = () => {
      Reportingsetup.violationCode(passDataStateList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({
              setCategoryFilter: Response.data.data[0].response,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            statelistfun();
          }
        });
    };
    categorylistfun();

    // Get and Display Zone List option for the search drop-down
    var passDataZone = {
      type: "ZoneList",
      shard: 1,
    };
    const initialzonefun = () => {
      Reportingsetup.violationCode(passDataZone)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setZone: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialzonefun();
          }
        });
    };
    initialzonefun();

    // Get and Display Shift List option for the search drop-down
    var passShiftList = {
      type: "ShiftList",
      shard: 1,
    };
    const initialviolationshift = () => {
      Reportingsetup.violationCode(passShiftList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setShift: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialviolationshift();
          }
        });
    };
    initialviolationshift();

    // Get and Display Agency List option for the search drop-down
    var passShiftList = {
      type: "AgencyList",
      shard: 1,
    };
    const violationshift = () => {
      Reportingsetup.violationCode(passShiftList)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setAgency: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            violationshift();
          }
        });
    };
    violationshift();
    // Get and Display Remark List option for the search drop-down
    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    const initialremarkfun = () => {
      Reportingsetup.violationCode(passDataRemarks)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setRemark: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialremarkfun();
          }
        });
    };
    initialremarkfun();

    // Get and Display Beat List option for the search drop-down
    var passDataBeat = {
      type: "BeatList",
      shard: 1,
    };
    const initialbeatfun = () => {
      Reportingsetup.violationCode(passDataBeat)
        .then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
          } else {
            this.setState({ setBeatTypes: Response.data.data[0].response });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialbeatfun();
          }
        });
    };
    initialbeatfun();
  }

  // Pagination Events
  componentDidUpdate() {
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + this.state.currentPage).addClass("active-paginated");
  }

  handleClick = (event) => {
    this.setState({ setDataLoadLoader: true });
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid,
    });
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + listid).addClass("active-paginated");
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
    const tickreportfun = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            tickreportfun();
          }
        }
      );
    };
    tickreportfun();
  };

  setPrevAndNextBtnClass = (listid) => {
    let totalPage = Math.ceil(
      this.state.totalDataLengthCount / this.state.todosPerPage
    );
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }
  };

  btnIncrementClick = () => {
    this.setState({ setDataLoadLoader: true });
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
    const initialtickdata = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickdata();
          }
        }
      );
    };
    initialtickdata();
  };
  btnDecrementClick = () => {
    this.setState({ setDataLoadLoader: true });
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
    const initialtickreport = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickreport();
          }
        }
      );
    };
    initialtickreport();
  };
  btnPrevClick = () => {
    this.setState({ setDataLoadLoader: true });
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
    const initialtickdatafun = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            initialtickdatafun();
          }
        }
      );
    };
    initialtickdatafun();
  };
  btnNextClick = () => {
    this.setState({ setDataLoadLoader: true });
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
    const tickreportdatafun = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            tickreportdatafun();
          }
        }
      );
    };
    tickreportdatafun();
  };

  // When click on filter icon
  onClickFilter = (e) => {
    e.preventDefault();
    this.setState({ setFilterIconToggle: !this.state.setFilterIconToggle });
  };

  // When click on Download button
  onClickDonwloadButton = (e) => {
    e.preventDefault();
    this.setState({ setDownloadOption: !this.state.setDownloadOption });
  };

  onChangeSearchEndDate = (endDate,e) => {
    if (!endDate) {
      this.setState({ setStartDate: "", setStartDateSearch: "" });
      return;
  }
    let value=e?.target?.value
    const timeValue = new Date(value);

    // Extracting hours and minutes
    const hours = timeValue.getHours();
    const minutes = timeValue.getMinutes();
    const isDateChange = !this.state.setStartDate || endDate?.getDate() !== this.state.setStartDate.getDate() || (hours == 0 && minutes == 0);
    let date = new Date(endDate);
    if (isDateChange) {
      date.setHours(23, 59, 59, 999);
    }
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
    year +
    "-" +
    month +
    "-" +
    todayDate +
    "T" +
    (date.getHours() < 10 ? "0" : "") +
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes() +
    ":00.000Z";

    this.setState({ setStartDate: date });
    this.setState({ setStartDateSearch: currentTimeInSeconds });
  };

  // On Click Details Page open
  onClickDetailsPage = (value) => {
    this.setState({ setShowDetailPage: value });
  };

  // On click back to reporting page
  backButtonClick = () => {
    this.setState({
      setShowDetailPage: false,
      setShowResendROHandler: false,
      setShowResendSuccess: false,
    });
  };

  ResendRoHandler = (inputValue) => {
    TicketReportDetails.resendRO(inputValue)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ setShowResendSuccess: true });
        }
      })
      .catch((err) => console.log(err));
  };

  //  When click on Start date picker in search section
  onChangeSearchStartDate = (startDate) => {
    var date = new Date(startDate);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({ setEndDate: startDate });
    this.setState({ setEndDateSearch: currentTimeInSeconds });
  };

  // Pagination count records will be display based on selected value from the drop-down
  onPaginationSelection = (e) => {
    this.setState({ setPaginationSelectVaue: e.target.value });
    this.setState({ setDataLoadLoader: true });
    this.setState({
      currentPage: 1,
      todosPerPage: e.target.value,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
    });

    var passData =
      "page=1" +
      "&limit=" +
      e.target.value +
      this.state.setAllSelectedFilterOption;
    const tickdatafun = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            tickdatafun();
          }
        }
      );
    };
    tickdatafun();

    // let totalPage = Math.ceil(this.state.totalDataLengthCount / e.target.value);

    // if (totalPage === 1) {
    //   this.setState({ isNextBtnActive: 'disabled' });
    // }
  };

  onChangeSearchFilter = (e) => {
    if (e.target.name !== "group_by_officer" && e.target.name !== "drive_off") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (name === "lp_number") {
      var finalData = value.split(" ").join("%20");
      let newValue = value;
      newValue = SearchLicensePlate(value);
      this.setState({ lp_number: newValue });
    } else if (name === "ticket_no") {
      var finalData = value.split(" ").join("%20");
      this.setState({ ticket_no: value });
    } else if (name === "street") {
      var finalData = value.split(" ").join("%20");
      this.setState({ street: value });
    } else if (name === "block") {
      var finalData = value.split(" ").join("%20");
      this.setState({ block: value });
    } else if (name === "group_by_officer") {
      this.setState({ group_by_officer: e.target.checked });
    } else if (name === "drive_off") {
      this.setState({ drive_off: e.target.checked ? e.target.checked : "" });
    } else if (name === "status") {
      this.setState({ status: value });
    } else if (name === "ticket_type") {
      this.setState({ ticket_type: value });
    } else if (name === "category") {
      this.setState({ category: value });
    }
  };

  //  When click on Submit button in search section
  onSubmitButtonClick = () => {
    const {
      lp_number,
      ticket_no,
      street,
      status,
      block,
      group_by_officer,
      drive_off,
      violationCodeFilter,
      violationDescFilter,
      beatFilter,
      zoneFilter,
      stateFilter,
      agencyFilter,
      shiftFilter,
      setStartDateSearch,
      setEndDateSearch,
      officerNameFilter,
      peoNameFilter,
      badgeIDFilter,
      stateFilterRemarks,
      ticket_type,
      category,
      statusFilterSelectedArray,
      categoryFilterSelectedArray,
    } = this.state;
    if (lp_number !== "") {
      var licensePlate = "&lp_number=" + lp_number;
    } else {
      var licensePlate = "";
    }
    if (ticket_no !== "") {
      var ticketNo = "&ticket_no=" + ticket_no;
    } else {
      var ticketNo = "";
    }
    if (ticket_type !== "") {
      var type = "&type=" + ticket_type;
    } else {
      var type = "";
    }
    if (street !== "") {
      var streetValue = "&street=" + street;
    } else {
      var streetValue = "";
    }
    if (block !== "") {
      var blockValue = "&block=" + block;
    } else {
      var blockValue = "";
    }
    if (group_by_officer !== "") {
      var groupByofficer = "&group_by_officer=" + group_by_officer;
    } else {
      var groupByofficer = "";
    }
    if (drive_off !== "") {
      var driveOff = "&drive_off=" + drive_off;
    } else {
      var driveOff = "";
    }
    if (violationCodeFilter !== "") {
      var violationCode = violationCodeFilter;
    } else {
      var violationCode = "";
    }
    if (violationDescFilter !== "") {
      var violationDesc = violationDescFilter;
    } else {
      var violationDesc = "";
    }
    if (beatFilter !== "") {
      var finalBeatData = beatFilter;
    } else {
      var finalBeatData = "";
    }
    if (zoneFilter !== "") {
      finalZoneData = zoneFilter;
    } else {
      var finalZoneData = "";
    }
    if (stateFilter !== "") {
      var finalStateData = stateFilter;
    } else {
      var finalStateData = "";
    }
    if (status !== "") {
      var searchStatus = status;
    } else {
      var searchStatus = "";
    }
    if (setStartDateSearch !== "") {
      var startDate = "&issue_ts_to=" + setStartDateSearch;
    } else {
      var startDate = "";
    }
    if (setEndDateSearch !== "") {
      var endDate = "&issue_ts_from=" + setEndDateSearch;
    } else {
      var endDate = "";
    }
    if (officerNameFilter !== "") {
      var officerName = officerNameFilter;
    } else {
      var officerName = "";
    }
    if (peoNameFilter !== "") {
      var peoName = peoNameFilter;
    } else {
      var peoName = "";
    }

    if (badgeIDFilter !== "") {
      var badgeId = badgeIDFilter;
    } else {
      var badgeId = "";
    }
    if (stateFilterRemarks !== "") {
      var remarks = stateFilterRemarks;
    } else {
      var remarks = "";
    }
    if (shiftFilter !== "") {
      var shift = shiftFilter.join("");
      var shiftName = shift.split(" ").join("%20");
    } else {
      var shiftName = "";
    }
    if (agencyFilter !== "") {
      var agencys = agencyFilter.join("");
      var agencyName = agencys.split(" ").join("%20");
    } else {
      var agencyName = "";
    }
    var categoryValue = "";
    if (category !== "") {
      var categoryValue = category;
    } else {
      var categoryValue = "";
    }

    this.setState({ setDataLoadLoader: true });

    if (group_by_officer === true) {
      const finalDataSearchString =
        endDate +
        startDate +
        licensePlate +
        ticketNo +
        streetValue +
        blockValue +
        violationCode +
        violationDesc +
        finalBeatData +
        finalZoneData +
        officerName +
        groupByofficer +
        finalStateData +
        searchStatus +
        badgeId +
        agencyName +
        peoName +
        shiftName +
        remarks +
        type +
        driveOff +
        categoryValue;

      this.setState({ searchPayload: finalDataSearchString });
      const tickreportdatafun = () => {
        Reportingsetup.reportTicket(finalDataSearchString)
          .then((Response) => {
            if (
              Response.data.data.citations === null ||
              Response.data.data.citations?.length === 0
            ) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: true });
              this.setState({ totalDataLengthCount: 0 });
            } else {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: false });
              this.setState({ groupByOfficerLogs: false });
              this.setState({ setPagination: true });
              this.setState({
                allIntigrationData: Response.data.data.citations,
              });
              this.setState({
                totalDataLengthCount: Response.data.data.total_count,
              });
              let totalPage = Math.ceil(
                Response.data.data.total_count / this.state.todosPerPage
              );

              if (totalPage === 1) {
                this.setState({ isNextBtnActive: "disabled" });
              }
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.props.refreshtokenforClassComp();
              tickreportdatafun();
            }
          });
      };
      tickreportdatafun();
    } else {
      this.setState({
        currentPage: 1,
        todosPerPage: 200,
        upperPageBound: 5,
        lowerPageBound: 0,
        isPrevBtnActive: "disabled",
        isNextBtnActive: "",
        pageBound: 3,
      });

      this.setState({ setPaginationSelectVaue: 200 });
      const finalDataSearch =
        "page=1&limit=" +
        this.state.todosPerPage +
        endDate +
        startDate +
        licensePlate +
        ticketNo +
        streetValue +
        blockValue +
        violationCode +
        violationDesc +
        finalBeatData +
        finalZoneData +
        officerName +
        finalStateData +
        searchStatus +
        badgeId +
        peoName +
        agencyName +
        shiftName +
        remarks +
        type +
        driveOff +
        categoryValue;

      this.setState({
        setAllSelectedFilterOption:
          endDate +
          startDate +
          licensePlate +
          ticketNo +
          streetValue +
          blockValue +
          violationCode +
          violationDesc +
          finalBeatData +
          finalZoneData +
          officerName +
          finalStateData +
          searchStatus +
          badgeId +
          agencyName +
          shiftName +
          remarks +
          type +
          driveOff +
          categoryValue,
      });

      if (finalDataSearch !== "") {
        const ticketreportdatafun = () => {
          Reportingsetup.reportTicket(finalDataSearch)
            .then((Response) => {
              if (
                Response.data.data.citations === null ||
                Response.data.data.citations?.length === 0
              ) {
                this.setState({ setDataLoadLoader: false });
                this.setState({ noRecordFound: true });
                this.setState({ totalDataLengthCount: 0 });
              } else {
                this.setState({ setDataLoadLoader: false });
                this.setState({ noRecordFound: false });
                this.setState({ groupByOfficerLogs: false });
                this.setState({ setPagination: true });
                this.setState({
                  allIntigrationData: Response.data.data.citations,
                });
                this.setState({
                  totalDataLengthCount: Response.data.data.total_count,
                });
                let totalPage = Math.ceil(
                  Response.data.data.total_count / this.state.todosPerPage
                );

                if (totalPage === 1) {
                  this.setState({ isNextBtnActive: "disabled" });
                }
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                this.props.refreshtokenforClassComp();
                ticketreportdatafun();
              }
            });
        };
        ticketreportdatafun();
      } else {
        this.exportsLogsData();
      }
    }
  };

  // Print button click event
  onClickPrintButton = () => {
    window.print();
  };

  removeExtraRow = (elem) => {
    var exportElem = elem.cloneNode(true),
      elementsToRemove = exportElem.querySelectorAll(".row-rmv");

    for (var i = 0; i <= elementsToRemove.length; i++) {
      var td = elementsToRemove[i];
      if (td) {
        td.remove();
      }
    }
    return exportElem;
  };
  // Download PDF file
  donwloadPDFFile = (e) => {
    e.preventDefault();
    this.setState({ setDataLoadLoader: true });
    var passData =
      "page=1" +
      "&limit=10000" +
      "&download=yes" +
      this.state.setAllSelectedFilterOption;
    const tickdata = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            this.setState({ setDataLoadLoader: false });
            setTimeout(() => {
              var pdfTable = document.getElementById("tbl-main-download");
              // html to pdf format
              if (pdfTable) {
                var exportElem = this.removeExtraRow(pdfTable);
                var html = htmlToPdfmake(exportElem.innerHTML);
                const documentDefinition = { content: html, pageSize: "a2" };
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake
                  .createPdf(documentDefinition)
                  .download("ticket-summary-report");
              }
            }, 100);

            setTimeout(() => {
              this.onRecordsDisplayAfterDownloads(200);
            }, 150);
          }
        },
        (err) => {
          this.setState({ setDataLoadLoader: false });
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            tickdata();
          }
        }
      );
    };
    tickdata();
  };

  // After Download this function will work for get Pagination count records will be display based on selected value from the drop-down
  onRecordsDisplayAfterDownloads = (value) => {
    this.setState({ setPaginationSelectVaue: value });
    this.setState({
      currentPage: 1,
      todosPerPage: value,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
    });

    var passData =
      "page=1" + "&limit=" + value + this.state.setAllSelectedFilterOption;
    const ticketdatafun = () => {
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });
            let totalPage = Math.ceil(
              Response.data.data.total_count / this.state.todosPerPage
            );

            if (totalPage === 1) {
              this.setState({ isNextBtnActive: "disabled" });
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            ticketdatafun();
          }
        }
      );
    };
    ticketdatafun();
  };

  // Download Excel file
  donwloadExcelFile = (e) => {
    var passData =
      "page=1" +
      "&limit=10000" +
      "&download=yes" +
      this.state.setAllSelectedFilterOption;
    const tickreportdatafun = () => {
      this.setState({ setDataLoadLoader: true });
      Reportingsetup.reportTicket(passData).then(
        (Response) => {
          if (
            Response.data.data.citations === null ||
            Response.data.data.citations?.length === 0
          ) {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: true });
            this.setState({ totalDataLengthCount: 0 });
          } else {
            this.setState({ setDataLoadLoader: false });
            this.setState({ noRecordFound: false });
            this.setState({ groupByOfficerLogs: false });
            this.setState({ setPagination: true });
            this.setState({ allIntigrationData: Response.data.data.citations });
            this.setState({
              totalDataLengthCount: Response.data.data.total_count,
            });

            setTimeout(() => {
              var uri = "data:application/vnd.ms-excel;base64,",
                template =
                  '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) {
                  return window.btoa(unescape(encodeURIComponent(s)));
                },
                format = function (s, c) {
                  return s.replace(/{(\w+)}/g, function (m, p) {
                    return c[p];
                  });
                };
              this.setState({ setDataLoadLoader: false });

              var form = document.getElementById("cstm-report-tb-display"),
                exportForm = form.cloneNode(true),
                elementsToRemove = exportForm.querySelectorAll(".notToExport");

              for (var i = 0; i <= elementsToRemove.length; i++) {
                var td = elementsToRemove[i];
                if (td) {
                  td.remove();
                }
              }

              var ctx = {
                worksheet: "ticket-summary-report.xls",
                table: exportForm.innerHTML,
              };
              var link = document.createElement("a");
              link.download = "ticket-summary-report.xls";
              link.href = uri + base64(format(template, ctx));
              link.target = "_blank";
              link.click();
            }, 100);

            setTimeout(() => {
              this.onRecordsDisplayAfterDownloads(200);
            }, 150);
          }
        },
        (err) => {
          this.setState({ setDataLoadLoader: false });
          if (err.response.status === 401) {
            this.props.refreshtokenforClassComp();
            tickreportdatafun();
          }
        }
      );
    };
    tickreportdatafun();
  };

  //  Donwload CSV file
  donwloadCsvFile = (e) => {
    e.preventDefault();
    this.setState({ setDataLoadLoader: true });

    var passData = "page=1" + "&limit=10000" + this.state.setAllSelectedFilterOption;
    const tickfun = () => {
    Reportingsetup.reportTicket(passData)
      .then(Response => {
        if (
          Response.data.data.citations === null ||
          Response.data.data.citations?.length === 0
        ) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ allIntigrationData: Response.data.data.citations });
          this.setState({
            totalDataLengthCount: Response.data.data.total_count,
          });

          setTimeout(() => {
            var csv = [];
            var rows = document.querySelectorAll("table tr");
            for (var i = 0; i < rows.length; i++) {
              var row = [], cols = rows[i].querySelectorAll("td, th");
              for (var j = 1; j < cols.length; j++)
                row.push(cols[j].innerText);
              csv.push(row.join(","));
            }
            this.downloadCSV(csv.join("\n"), "ticket-summary-report.csv");
          }, 100);

          setTimeout(() => {
            this.onRecordsDisplayAfterDownloads(200);
          }, 150);

        }
      },
      (err) => {
        this.setState({ setDataLoadLoader: false });
        if (err.response.status === 401) {
          this.props.refreshtokenforClassComp();
          tickfun();
        }
      }
      );

  }
  tickfun();
}

  downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  };
  onClickSearchViolationCode = (selectedList) => {
    const statusValues = selectedList.map((item) => item.code);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&violation_code=" + uniqueStatusValues.join(",");
    }
    this.setState({
      codeFilterSelectedArray: selectedList,
      violationCodeFilter: categoryString,
    });
  };
  onClickSearchViolationDescriprion = (selectedList) => {
    const statusValues = selectedList.map((item) => item.description);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&violation_desc=" + uniqueStatusValues.join(",");
    }
    this.setState({
      descriptionFilterSelectedArray: selectedList,
      violationDescFilter: categoryString,
    });
  };
  onChangeSearchStatus = (selectedList) => {
    const statusValues = selectedList.map((item) => item.status);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&status=" + uniqueStatusValues.join(",");
    }
    this.setState({
      statusFilterSelectedArray: selectedList,
      status: categoryString,
    });
  };
  onChangeSearchCategory = (selectedList) => {
    const statusValues = selectedList.map((item) => item.status);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&category=" + uniqueStatusValues.join(",");
    }
    this.setState({
      categoryFilterSelectedArray: selectedList,
      category: categoryString,
    });
  };
  onClickSearchOfficerName = (selectedList) => {
    const statusValues = selectedList.map((item) => item.officer_id);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&site_officer_id=" + uniqueStatusValues.join(",");
    }
    this.setState({
      officerFilterSelectedArray: selectedList,
      officerNameFilter: categoryString,
    });
  };
  onClickSearchPeoName = (selectedList) => {
    const statusValues = selectedList.map((item) => item.officer_id);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&site_officer_id=" + uniqueStatusValues.join(",");
    }
    this.setState({
      peoFilterSelectedArray: selectedList,
      peoNameFilter: categoryString,
    });
  };

  onClickSearchShift = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&shift=" + item.shift;
      arrayShift.push(finalData);
    });
    this.setState({ shiftFilter: arrayShift });
  };

  onClickSearchAgency = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&agency=" + item.agency;
      arrayShift.push(finalData);
    });
    this.setState({ agencyFilter: arrayShift });
  };
  onClickSearchBadgeId = (selectedList) => {
    const statusValues = selectedList.map((item) => item.badge);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&badge_id=" + uniqueStatusValues.join(",");
    }
    this.setState({
      badgeFilterSelectedArray: selectedList,
      badgeIDFilter: categoryString,
    });
  };
  onClickSearchBeat = (selectedList) => {
    const statusValues = selectedList.map((item) => item.beat);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&beat=" + uniqueStatusValues.join(",");
    }
    this.setState({
      beatFilterSelectedArray: selectedList,
      beatFilter: categoryString,
    });
  };
  onClickSearchZone = (selectedList) => {
    const statusValues = selectedList.map((item) => item.zone);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&zone=" + uniqueStatusValues.join(",");
    }
    this.setState({
      zoneFilterSelectedArray: selectedList,
      zoneFilter: categoryString,
    });
  };
  onClickSearchLicenseState = (selectedList) => {
    const statusValues = selectedList.map((item) => item.state);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&lp_state=" + uniqueStatusValues.join(",");
    }
    this.setState({
      stateFilterSelectedArray: selectedList,
      stateFilter: categoryString,
    });
  };
  onClickRemark = (selectedList) => {
    const statusValues = selectedList.map((item) => item.remark);
    const uniqueStatusValues = [...new Set(statusValues)];
    let categoryString = "";
    if (uniqueStatusValues.length > 0) {
      categoryString = "&remark_1=" + uniqueStatusValues.join(",");
    }
    this.setState({
      remarkFilterSelectedArray: selectedList,
      stateFilterRemarks: categoryString,
    });
  };
  closeErrorClick = () => {
    this.setState({ errorHandling: false });
  };

  onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  };

  updateValue = (val) => {
    if (!val) {
      this.onSubmitButtonClick();
    }
  };

  render() {
    const {
      currentPage,
      setShowDetailPage,
      todosPerPage,
      setRemark,
      upperPageBound,
      totalDataLengthCount,
      lowerPageBound,
      isPrevBtnActive,
      isNextBtnActive,
      showAuthToken,
      setDataLoadLoader,
      setDownloadOption,
      groupByOfficerLogs,
      errorHandling,
      setStartDate,
      setEndDate,
      setStreetList,
      setBadgeID,
      setOfficerName,
      setBeatTypes,
      violationCode,
      violationDesc,
      setZone,
      noRecordFound,
      setFilterIconToggle,
      allIntigrationData,
      dataGroupOfficerLog,
      setPaginationSelectVaue,
      stateFilterSelectedArray,
      officerFilterSelectedArray,
      peoFilterSelectedArray,
      badgeFilterSelectedArray,
      codeFilterSelectedArray,
      remarkFilterSelectedArray,
      beatFilterSelectedArray,
      zoneFilterSelectedArray,
      statusFilterSelectedArray,
      categoryFilterSelectedArray,
      descriptionFilterSelectedArray,
      setShift,
      setAgency,
      setStatusFilter,
      setShowResendROHandler,
      selectedCitation,
      setShowResendSuccess,
      setCategoryFilter,
    } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = allIntigrationData.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );

    const selectedCitationHandler = (ciation) => {
      this.setState({
        selectedCitation: ciation,
        setShowResendROHandler: true,
        setShowDetailPage: true,
      });
    };

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalDataLengthCount / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className="active-paginated" id={number}>
            <button id={number} onClick={this.handleClick.bind(this)}>
              {number}
            </button>
          </li>
        );
      } else if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <li key={number} id={number}>
            <button id={number} onClick={this.handleClick.bind(this)}>
              {number}
            </button>
          </li>
        );
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = (
        <li className="">
          <button onClick={this.btnIncrementClick.bind(this)}>
            {" "}
            &hellip;{" "}
          </button>
        </li>
      );
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = (
        <li className="">
          <button onClick={this.btnDecrementClick.bind(this)}>
            {" "}
            &hellip;{" "}
          </button>
        </li>
      );
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <span id="btnPrev"> Prev </span>
        </li>
      );
    } else {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <button id="btnPrev" onClick={this.btnPrevClick.bind(this)}>
            {" "}
            Prev{" "}
          </button>
        </li>
      );
    }
    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <span id="btnNext"> Next </span>
        </li>
      );
    } else {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <button id="btnNext" onClick={this.btnNextClick.bind(this)}>
            {" "}
            Next{" "}
          </button>
        </li>
      );
    }

    return (
      <>
        {
          !setShowDetailPage ? (
            <>
              <section>
                <div className="container-fluid">
                  <div className="row">
                    {/* Content starts */}
                    <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                      <div className="cstm-ticket-manage-sec">
                        <div className="cstm-ticket-manage-heading">
                          <h3 className="cstm-all-pg-ttl operate-ttl">
                            {this.changeName} Management Summary
                          </h3>
                        </div>
                        <PrintPdfCsvExcelDownload
                          tableId="tbl-main-download"
                          fileName="ticket-summary-report"
                          pageSize="a2"
                          donwloadPDFFile={this.donwloadPDFFile}
                          donwloadExcelFile={this.donwloadExcelFile}
                          donwloadCsvFile={this.donwloadCsvFile}
                        />
                      </div>
                      <div className="report-searchbar">
                        <form>
                          <div className="report-date report-mr">
                            <DatePicker
                              // selectsStart
                              showTimeSelect
                              timeIntervals={1}
                              placeholderText="MM/DD/YYYY HH:MM"
                              dateFormat="MM/dd/yyyy h:mmaa"
                              timeFormat="HH:mm"
                              className="cstm-pull-details schedule-date-input"
                              name="end_date"
                              selected={setEndDate}
                              onChange={(date) =>
                                this.onChangeSearchStartDate(date)
                              }
                              autoComplete="off"
                            />
                            <img
                              src={transfer}
                              alt="transfer"
                              className="date-transfer"
                            />
                            <DatePicker
                              showTimeSelect
                              timeIntervals={1}
                              dateFormat="MM/dd/yyyy h:mmaa"
                              timeFormat="HH:mm"
                              placeholderText="MM/DD/YYYY HH:MM"
                              className="cstm-pull-details schedule-date-input"
                              name="start_date"
                              selected={setStartDate}
                              onChange={(date,e) =>
                                this.onChangeSearchEndDate(date,e)
                              }
                              autoComplete="off"
                            />
                          </div>
                          <div className="report-licence report-mr">
                            <input
                              className="license-plate"
                              name="lp_number"
                              value={this.state.lp_number}
                              onChange={this.onChangeSearchFilter.bind(this)}
                              type="text"
                              placeholder="License Plate"
                            />
                          </div>
                          <div className="report-licence select-drop-arrow report-mr">
                            <Multiselect
                              name="state"
                              selectedValues={stateFilterSelectedArray}
                              displayValue="stateName"
                              placeholder="License State"
                              onRemove={this.onClickSearchLicenseState.bind(
                                this
                              )}
                              onSearch={function noRefCheck() {}}
                              onSelect={this.onClickSearchLicenseState.bind(
                                this
                              )}
                              options={setStreetList.map((item, index) => ({
                                stateName: item.state_name,
                                state: item.state_abbreviated,
                              }))}
                              showCheckbox
                            />
                            <img src={dropdownarrow} alt="dropdownarrow" />
                          </div>
                          <div className="report-licence report-mr">
                            <div>
                              <input
                                className="license-plate"
                                name="ticket_no"
                                value={this.state.ticket_no}
                                onChange={this.onChangeSearchFilter.bind(this)}
                                type="text"
                                placeholder="Search Tickets"
                              />
                            </div>
                          </div>
                          <button
                            onClick={this.onClickFilter.bind(this)}
                            className="filter-box report-mr"
                          >
                            <img src={filter} alt="filter" />
                          </button>
                          <button
                            className="licence-print report-mr"
                            type="button"
                            onClick={this.onSubmitButtonClick.bind(this)}
                          >
                            Submit
                          </button>
                        </form>

                        {setFilterIconToggle ? (
                          <div className="filter-main-section">
                            <div className="row">
                              <div className="select-drop-arrow filter_cstm1">
                                <Multiselect
                                  name="status"
                                  selectedValues={statusFilterSelectedArray}
                                  // selectedValues={this.state.status}
                                  onSelect={this.onChangeSearchStatus.bind(
                                    this
                                  )}
                                  onRemove={this.onChangeSearchStatus.bind(
                                    this
                                  )}
                                  options={setStatusFilter.map((item) => ({
                                    status: item.status,
                                    id: item.status,
                                  }))}
                                  displayValue="status"
                                  placeholder="Select Status"
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm2">
                                <Multiselect
                                  name="category"
                                  selectedValues={categoryFilterSelectedArray}
                                  onSelect={this.onChangeSearchCategory.bind(
                                    this
                                  )}
                                  onRemove={this.onChangeSearchCategory.bind(
                                    this
                                  )}
                                  options={setCategoryFilter.map((item) => ({
                                    status: item.category,
                                    id: item.category,
                                  }))}
                                  displayValue="status"
                                  placeholder="Select Category"
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm1">
                                <select
                                  value={this.state.ticket_type}
                                  onChange={this.onChangeSearchFilter.bind(
                                    this
                                  )}
                                  name="ticket_type"
                                  className="ar-olny"
                                >
                                  <option value="">Select Type</option>
                                  <option value="Warning">Warning</option>
                                </select>
                              </div>

                              <div className="select-drop-arrow filter_cstm2">
                                <Multiselect
                                  name="officer"
                                  selectedValues={officerFilterSelectedArray}
                                  displayValue="officer"
                                  placeholder="Officer Name"
                                  onRemove={this.onClickSearchOfficerName.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchOfficerName.bind(
                                    this
                                  )}
                                  options={setOfficerName
                                    .sort((a, b) =>
                                      a.officer_badge_id > b.officer_badge_id
                                        ? 1
                                        : -1
                                    )
                                    .map((item, index) => ({
                                      officer:
                                        item.officer_badge_id +
                                        " - " +
                                        item.officer_first_name +
                                        " " +
                                        item.officer_last_name,
                                      officer_id: item.site_officer_id,
                                    }))}
                                  showCheckbox
                                />
                              </div>
                              <div className="select-drop-arrow filter_cstm2">
                                <Multiselect
                                  name="officer"
                                  selectedValues={peoFilterSelectedArray}
                                  displayValue="officer"
                                  placeholder="Peo Name"
                                  onRemove={this.onClickSearchPeoName.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchPeoName.bind(
                                    this
                                  )}
                                  options={setOfficerName
                                    .sort((a, b) =>
                                      a.officer_badge_id > b.officer_badge_id
                                        ? 1
                                        : -1
                                    )
                                    .map((item, index) => ({
                                      officer:
                                        item.officer_badge_id +
                                        " - " +
                                        item.officer_name_concat,
                                      officer_id: item.site_officer_id,
                                    }))}
                                  showCheckbox
                                />
                              </div>

                              {/* <div className="select-drop-arrow filter_cstm3">
                              <Multiselect
                                name="badge"
                                selectedValues={badgeFilterSelectedArray}
                                displayValue="badge"
                                placeholder="Badge Id"
                                onRemove={this.onClickSearchBadgeId.bind(this)}
                                onSearch={function noRefCheck() { }}
                                onSelect={this.onClickSearchBadgeId.bind(this)}
                                options={
                                  (setBadgeID.map((item, index) => (
                                    {
                                      badge: (item)
                                    }
                                  )))
                                }
                                showCheckbox
                              />
                            </div> */}

                              <div className="select-drop-arrow filter_cstm3">
                                <input
                                  className="license-plate"
                                  name="street"
                                  value={this.state.street}
                                  onChange={this.onChangeSearchFilter.bind(
                                    this
                                  )}
                                  type="text"
                                  placeholder="Search Street"
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm5">
                                <Multiselect
                                  selectedValues={codeFilterSelectedArray}
                                  name="code"
                                  displayValue="code"
                                  placeholder="Violation code"
                                  onRemove={this.onClickSearchViolationCode.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchViolationCode.bind(
                                    this
                                  )}
                                  options={violationCode.map((item, index) => ({
                                    code: item.code,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm7">
                                <Multiselect
                                  selectedValues={
                                    descriptionFilterSelectedArray
                                  }
                                  name="description"
                                  displayValue="description"
                                  placeholder="Select Violation Description"
                                  onRemove={this.onClickSearchViolationDescriprion.bind(
                                    this
                                  )}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchViolationDescriprion.bind(
                                    this
                                  )}
                                  options={violationCode.map((item, index) => ({
                                    description: item.violation_description,
                                    code: item.code,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm7">
                                <Multiselect
                                  selectedValues={remarkFilterSelectedArray}
                                  name="remark"
                                  displayValue="remark"
                                  placeholder="Remarks"
                                  onRemove={this.onClickRemark.bind(this)}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickRemark.bind(this)}
                                  options={setRemark.map((item, index) => ({
                                    remark: item.remark,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm6">
                                <Multiselect
                                  name="beat"
                                  selectedValues={beatFilterSelectedArray}
                                  displayValue="beat"
                                  placeholder="Select Beat"
                                  onRemove={this.onClickSearchBeat.bind(this)}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchBeat.bind(this)}
                                  options={setBeatTypes.map((item, index) => ({
                                    beat: item.beat_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm9">
                                <Multiselect
                                  name="zone"
                                  selectedValues={zoneFilterSelectedArray}
                                  displayValue="zone"
                                  placeholder="Select Zone"
                                  onRemove={this.onClickSearchZone.bind(this)}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchZone.bind(this)}
                                  options={setZone.map((item, index) => ({
                                    zone: item.zone_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm10">
                                <Multiselect
                                  name="shift"
                                  displayValue="shift"
                                  placeholder="Shift"
                                  onRemove={this.onClickSearchShift.bind(this)}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchShift.bind(this)}
                                  options={setShift.map((item, index) => ({
                                    shift: item.shift_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="select-drop-arrow filter_cstm7">
                                <Multiselect
                                  name="agency"
                                  displayValue="agency"
                                  placeholder="agency"
                                  onRemove={this.onClickSearchAgency.bind(this)}
                                  onSearch={function noRefCheck() {}}
                                  onSelect={this.onClickSearchAgency.bind(this)}
                                  options={setAgency.map((item, index) => ({
                                    agency: item.agency_name,
                                  }))}
                                  showCheckbox
                                />
                              </div>

                              <div className="ticket-search-radio-btn-main">
                                <div className="ticket-search-radio-btn">
                                  <span className="search-block-filter-pl">
                                    Block Range:{" "}
                                  </span>
                                </div>

                                <div className="radio-ticket-serach-input">
                                  <input
                                    className="license-plate"
                                    name="block"
                                    value={this.state.block}
                                    onChange={this.onChangeSearchFilter.bind(
                                      this
                                    )}
                                    type="text"
                                    placeholder="From"
                                  />

                                  <input
                                    className="license-plate"
                                    name="block_to"
                                    value={this.state.block_to}
                                    onChange={this.onChangeSearchFilter.bind(
                                      this
                                    )}
                                    type="text"
                                    placeholder="To"
                                  />
                                </div>

                                {/* <div className="select-drop-arrow filter_cstm4">
                              Block Range
                            </div>
                                 
                            <div className="select-drop-arrow filter_cstm4">
                              <input className="license-plate" name="block" value={this.state.block} onChange={this.onChangeSearchFilter.bind(this)} type="text" placeholder="From" />
                            </div>

                            <div className="select-drop-arrow filter_cstm4">
                              <input className="license-plate" name="block_to" value={this.state.block_to} onChange={this.onChangeSearchFilter.bind(this)} type="text" placeholder="To" />
                            </div> */}

                                {/* <div className="check-input-filter filter_cstm12 ticket-group-by-officer-ttl">
                                  <div className="checkbox-inline">
                                    <input
                                      onChange={this.onChangeSearchFilter.bind(
                                        this
                                      )}
                                      type="checkbox"
                                      name="group_by_officer"
                                      id="check-officer"
                                    />
                                    <label htmlFor="check-officer">
                                      Group By Officer
                                    </label>
                                  </div>
                                </div> */}

                                <div className="check-input-filter filter_cstm12 ticket-group-by-officer-ttl">
                                  <div className="checkbox-inline">
                                    <input
                                      onChange={this.onChangeSearchFilter.bind(
                                        this
                                      )}
                                      type="checkbox"
                                      name="drive_off"
                                      id="check-drive"
                                    />
                                    <label htmlFor="check-drive">
                                      Drive Off
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {setDataLoadLoader ? (
                        <div className="text-center spin-load-main">
                          <div className="spinner-border"></div>
                        </div>
                      ) : noRecordFound ? (
                        <div className="no-record-found-main">
                          No Record Found
                        </div>
                      ) : (
                        <>
                          <div className="ctsm-table-scroll">
                            <div className="table-responsive">
                              {groupByOfficerLogs ? (
                                <div className="">
                                  {/* <GroupTableLogs onClickDetailsPage={this.onClickDetailsPage} products={dataGroupOfficerLog} /> */}
                                </div>
                              ) : (
                                <>
                                  <div className="">
                                    <ProductTable
                                      onClickDetailsPage={
                                        this.onClickDetailsPage
                                      }
                                      updateValue={this.updateValue}
                                      products={allIntigrationData}
                                      selectedCitationHandler={
                                        selectedCitationHandler
                                      }
                                      uiElements={this.props.uiElements}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          {allIntigrationData.length !== 0 &&
                            noRecordFound !== true && (
                              <>
                                <div className="cstm-permit-trf">
                                  <div className="col-12">
                                    <div className="total-count-records">
                                      Total <b> {totalDataLengthCount} </b>{" "}
                                      Records Found{" "}
                                    </div>
                                  </div>
                                  <ul className="cstm-pagination-added-bottom">
                                    {renderPrevBtn}
                                    {pageDecrementBtn}
                                    {renderPageNumbers}
                                    {pageIncrementBtn}
                                    {renderNextBtn}
                                  </ul>
                                </div>
                                <div className="cstm-permit-trf esc-select">
                                  <div className="cstm-left-lable-pn-val">
                                    Number of records per page
                                  </div>
                                  <div className="cstm-right-lable-pn-val">
                                    <select
                                      className="select-pagination-val"
                                      value={setPaginationSelectVaue}
                                      onChange={this.onPaginationSelection.bind(
                                        this
                                      )}
                                    >
                                      <option value="100">100</option>
                                      <option value="200">200</option>
                                      <option value="500">500</option>
                                      <option value="1000">1000</option>
                                    </select>
                                    <img
                                      src={dropdownarrow}
                                      alt="dropdownarrow"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : setShowResendROHandler ? (
            <ResendRo
              selectedCitation={selectedCitation}
              ResendRoHandler={this.ResendRoHandler}
              backbuttonHandler={this.backButtonClick}
            />
          ) : (
            <TicketDetails
              backButtonClick={this.backButtonClick}
              uiElements={this.props.uiElements}
            />
          )
          // <ReportingDetailPage backButtonClick={this.backButtonClick} />
        }
        {setShowResendSuccess && (
          <div className="main-body-modal">
            <div className="main-modal">
              <div className="cstm-close">
                <button onClick={this.backButtonClick}>×</button>
              </div>
              <div className="cstm-modal-body">
                <div className="modal-body cstm-modal-body">
                  <div className="text-center cstm-paymnet-img">
                    <img src={SuccessIcon} alt="img" className="img-fluid" />
                  </div>
                  <p className="text-center mb-4">RO Resend Successfully!</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

// export default TicketReporting;
export default connect(null, { refreshtokenforClassComp })(TicketReporting);
