import { useEffect, useState } from "react";
import ErrorModalComponent from "../../../../utile/modals/errorModal";
import FormBuilderContainer from "./formBuilder/formBuilderContainer";
import ToolBar from "./formBuilder/toolBox";

const PermitFormBuilderComponent = ({
  permitName,
  permitLayoutHandler,
  editFields,
  isEditPermit,
  permitTime,
  loading,
  responseStatus,
  responseMessage,
  permitTitle,
  residentType
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (responseStatus !== "" && responseMessage !== "") {
      setShowModal(true);
    }
  }, [responseMessage]);
  return (
    <>
      <div className="configure-question-main">
        <ToolBar permitTitle={permitTitle} />
        <FormBuilderContainer
          permitName={permitName}
          permitLayoutHandler={permitLayoutHandler}
          editFields={editFields}
          isEditPermit={isEditPermit}
          permitTime={permitTime}
          loading={loading}
          responseStatus={responseStatus}
          responseMessage={responseMessage}
          residentType={residentType}
        />
      </div>

      {showModal && (
          <ErrorModalComponent
            handleCloseModel={() => setShowModal(false)}
            displayMsg={responseMessage}
            status={responseStatus === 200 ? "" : "error"}
          />
      )}
    </>
  );
};

export default PermitFormBuilderComponent;
