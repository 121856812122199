import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import close from '../../../Images/close.svg';
import PermitUtilizationCount from './PermitCounts';
import Dashboard from "../../../Services/dashboard.service";
import { clearoutAuthUserData } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';
import { changeticket } from '../../../Global/site';


function UtilizationDashboard() {

  const options = {
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: {
        show: false // Hide the toolbar
      },
    },
    xaxis: {
      tooltip: {
        enabled: false
      },
    type: 'category',
    categories:(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']),
  },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 6,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              name: 'No Data',
              color: '#A8A8A8'
            },
            {
              from: 1,
              to: 50,
              name: 'Low',
              color: '#128FD9'
            },
            {
              from: 50,
              to: 100,
              name: 'Medium',
              color: '#00A100'
            },
            {
              from: 100,
              to: 150,
              name: 'High',
              color: '#FFB200'
            },
            {
              from: 150,
              to: 200,
              name: 'Extreme',
              color: '#FF0000'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#044271']
      }
    },
    stroke: {
      width: 1
    },
  }
  const dispatch = useDispatch()
  const [count, setCount] = React.useState(0);
  const [showAuthToken, setAuthToken] = useState(false);
  const [showOptions, setOptions] = React.useState(options);
  const [showErrorHandling, setErrorHandling] = useState(false)
  const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

  const [showSeries1, setSeries1] = React.useState([]);
  const [showSeries2, setSeries2] = React.useState([]);
  const [showSeries3, setSeries3] = React.useState([]);
  const [showSeries4, setSeries4] = React.useState([]);
  const [showSeries5, setSeries5] = React.useState([]);
  const [showSeries6, setSeries6] = React.useState([]);
  const [showSeries7, setSeries7] = React.useState([]);

  const [showActivity1, setActivity1] = React.useState([]);
  const [showActivity2, setActivity2] = React.useState([]);
  const [showActivity3, setActivity3] = React.useState([]);
  const [showActivity4, setActivity4] = React.useState([]);
  const [showActivity5, setActivity5] = React.useState([]);
  const [showActivity6, setActivity6] = React.useState([]);
  const [showActivity7, setActivity7] = React.useState([]);

  const [showIssuance1, setIssuance1] = React.useState([]);
  const [showIssuance2, setIssuance2] = React.useState([]);
  const [showIssuance3, setIssuance3] = React.useState([]);
  const [showIssuance4, setIssuance4] = React.useState([]);
  const [showIssuance5, setIssuance5] = React.useState([]);
  const [showIssuance6, setIssuance6] = React.useState([]);
  const [showIssuance7, setIssuance7] = React.useState([]);

  const changeName = changeticket();

  const closeError = () => {
    setErrorHandling(false)
  }

  const onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  }

  // Display permit utilization total count
  useEffect(() => {
    const retriveCitation = {
      "heatmap_type": "scans"
    }
    initialcitationheatmapfun()
    function initialcitationheatmapfun() {
      Dashboard.getCitationHeatmap(retriveCitation).then(
        (response) => {
          if (response.data.status === 300) {
            setAuthToken(true)
          } else {
            if (response.data.status === false) {
              setErrorHandling(true)
              setErrorHandleMessage(response.data.message)
            } else {
              setSeries1(response.data.data[0].metadata[0].data)
              setSeries2(response.data.data[0].metadata[1].data)
              setSeries3(response.data.data[0].metadata[2].data)
              setSeries4(response.data.data[0].metadata[3].data)
              setSeries5(response.data.data[0].metadata[4].data)
              setSeries6(response.data.data[0].metadata[5].data)
              setSeries7(response.data.data[0].metadata[6].data)
            }
          }
        }
      ).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          initialcitationheatmapfun()
        }
      });
    }

    const retriveActivity = {
      "heatmap_type": "citations"
    }
    initialcitationheatmapactivityfun()
    function initialcitationheatmapactivityfun() {
      Dashboard.getCitationHeatmap(retriveActivity).then(
        (response) => {
          if (response.data.status === 300) {
            setAuthToken(true)
          } else {
            if (response.data.status === false) {
              setErrorHandling(true)
              setErrorHandleMessage(response.data.message)
            } else {
              setActivity1(response.data.data[0].metadata[0].data)
              setActivity2(response.data.data[0].metadata[1].data)
              setActivity3(response.data.data[0].metadata[2].data)
              setActivity4(response.data.data[0].metadata[3].data)
              setActivity5(response.data.data[0].metadata[4].data)
              setActivity6(response.data.data[0].metadata[5].data)
              setActivity7(response.data.data[0].metadata[6].data)
            }
          }
        }
      ).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          initialcitationheatmapactivityfun()
        }
      });
    }

    const retriveIssuance = {
      "heatmap_type": "timings"
    }
    initialretrivefun()
    function initialretrivefun() {
      Dashboard.getCitationHeatmap(retriveIssuance).then(
        (response) => {
          if (response.data.status === 300) {
            setAuthToken(true)
          } else {
            if (response.data.status === false) {
              setErrorHandling(true)
              setErrorHandleMessage(response.data.message)
            } else {
              setIssuance1(response.data.data[0].metadata[0].data)
              setIssuance2(response.data.data[0].metadata[1].data)
              setIssuance3(response.data.data[0].metadata[2].data)
              setIssuance4(response.data.data[0].metadata[3].data)
              setIssuance5(response.data.data[0].metadata[4].data)
              setIssuance6(response.data.data[0].metadata[5].data)
              setIssuance7(response.data.data[0].metadata[6].data)
            }
          }
        }
      ).catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          initialretrivefun()
        }
      });
    }
    setCount(100);

  }, []);
  const dayNames = ['Sunday','Saturday','Friday','Thursday','Wednesday','Tuesday', 'Monday'];

  // showSeries
const showSeries = [showSeries1, showSeries2, showSeries3, showSeries4, showSeries5, showSeries6, showSeries7];
const seriesData = showSeries.map((series, index) => ({
    name: dayNames[index],
    data: series.map(ele => ({ x: toString(ele.x), y: ele.y })),
}));

// showActivity
const showActivity = [showActivity1, showActivity2, showActivity3, showActivity4, showActivity5, showActivity6, showActivity7];
const activityData = showActivity.map((series, index) => ({
  name: dayNames[index],
  data: series.map(ele => ({ x: toString(ele.x), y: ele.y })),
}));

// showIssuance 
const showIssuance = [showIssuance1, showIssuance2, showIssuance3, showIssuance4, showIssuance5, showIssuance6, showIssuance7];
const issuanceData = showIssuance.map((series, index) => ({
  name: dayNames[index],
  data: series.map(ele => ({ x: toString(ele.x), y: ele.y })),
}));


  return (
    <>


      <section>
        <div className="container-fluid">
          <div className="row" >
            {/* Content starts */}
            <div className="col-lg-12 col-md-9 col-sm-12 cstm-ctn cstm-operate-top">
              <div className="row">
                <div className='col-12'><h3 className="cstm-all-pg-ttl operate-ttl">Utilization</h3></div>
              </div>
              {/* Display Total Permit Utilization Count */}
              <div className="cstm-utilization-chart-count-section">

                <PermitUtilizationCount />
              </div>
              {/* Citation HeatMap Display Here */}
              <div className="row cstm-heatmap">
                <div className='col-12'>
                  <div className="cstm_heatmap_box">
                    <div className="cstm-heatmap-top">
                      <div className="cstm-heatmap-top-name">Scans Heatmap</div>
                    </div>
                      <div className="cstm-heatmap-bottom"> <ReactApexChart options={showOptions} series={seriesData}

                      type="heatmap" height={350} /></div>
                  </div>
                </div>
              </div>


              {/* Activity HeatMap Display Here */}
              <div className="row cstm-heatmap">
                <div className='col-12'>
                  <div className="cstm_heatmap_box">
                    <div className="cstm-heatmap-top">
                      <div className="cstm-heatmap-top-name">{changeName} Heatmap</div>
                    </div>
                    <div className="cstm-heatmap-bottom"> <ReactApexChart options={showOptions} series={activityData} type="heatmap" height={350} /></div>
                  </div>
                </div>
              </div>


              {/* Issuance HeatMap Display Here */}
              <div className="row cstm-heatmap">
                <div className='col-12'>
                  <div className="cstm_heatmap_box">
                    <div className="cstm-heatmap-top">
                      <div className="cstm-heatmap-top-name">Timings Heatmap</div>
                    </div>
                    <div className="cstm-heatmap-bottom"> <ReactApexChart options={showOptions} series={issuanceData} type="heatmap" height={350} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}


export default UtilizationDashboard;
