import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

// images
import Parklogo from '../../Images/Parklogo.png';
import Encinitas from '../../Images/encinitas.png';
import logo_duncan from '../../Images/logo-duncan.png'
import logo_sp from '../../Images/logo-sp.png'
import CityofLeavenWorth from '../../Images/cityofleavenworth.png';
import FollyBeach from '../../Images/Folly-Beach-Logo-P.png';
import Mansfield from '../../Images/mansfieldct.png'
import Mystic from "../../Images/mystic.png"
import Greenvillesc from "../../Images/Greenvillesc.png"
import Oceancity from "../../Images/oceancityLogo.png"
import Lazparking from "../../Images/lazparking.png"
import Kalamazoo from "../../Images/kalamazoo.png"
import Bismarck from "../../Images/bismarck-logo.png"
import Parkiop from "../../Images/parkiop-logo.png"
import { changeticket, siteid } from '../../Global/site';
import ParkBangor from "../../Images/parkBangor.png"
import AshLandLogo from "../../Images/ashland.png"
import BeaufortLogo from "../../Images/beaufort-logo.png"
import HartfortLogo from "../../Images/hpa-logo.png"
import cohassetLogo from '../../Images/cohasset.png';
import peakParkingLogo from '../../Images/peaklogo.png';
import parkSeptaLogo from '../../Images/septa-logo.png';
import parkcharlestonLogo from '../../Images/parkcharleston-logo.png';
import parkKCMOLogo from '../../Images/kansas-logo.png';
import { refreshTokenMiddleware } from '../customMiddelware';
import mainService from '../../Services/main.service';
import ReactToPrint from 'react-to-print';

const imageWidth = 100;
const imageHeight = 300;

const pageStyle=`@page { size: ${imageWidth}mm ${imageHeight}mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }`


const Thankyou = () => {
    const dispatch = useDispatch()

    const { allCitationData } = useSelector(state => state.other);
    const [showPathName, setPathName] = useState("");
    let location = useLocation();
    let navigate = useNavigate()
    const [tranId, setTranId] = useState(location?.state || JSON.parse(localStorage.getItem('tranId')));
    const changeName = changeticket();
    let id_site = siteid();

    const [showCustomBranding, setCustomBranding] = useState(0);

    useEffect(() => {
        if (tranId) {
            localStorage.setItem('tranId', JSON.stringify(tranId));
            setTranId(tranId);
        }

        const handlePopState = () => {
            navigate('/ticketreporting');
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, tranId]);

    useEffect(() => {
        console.log(allCitationData,"citation")
        // if (!allCitationData) {
        //     navigate('/search')
        // }
        const pathname = window.location.hostname;
        setPathName(pathname)
       

        if (
            pathname === 'staging-processing-parkkcmo.netlify.app' ||
            pathname === 'staging-processing.parkkcmo.com' ||
            pathname === 'processing-parkkcmo.netlify.app' ||
            pathname === 'processing.parkkcmo.com'
        ) {
            setCustomBranding(parkKCMOLogo);
        } else if (
            pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
            pathname === 'staging-processing.parkcharleston-sc.com' ||
            pathname === 'processing-parkcharleston-sc.netlify.app' ||
            pathname === 'processing.parkcharleston-sc.com'
        ) {
            setCustomBranding(parkcharlestonLogo);
        } else if (
            pathname === 'staging-processing.parksepta.com' ||
            pathname === 'staging-processing-parksepta.netlify.app' ||
            pathname === 'processing.parksepta.com' ||
            pathname === 'processing-parksepta.netlify.app'
        ) {
            setCustomBranding(parkSeptaLogo);
        } else if (
            pathname === 'staging-processing.parkingenforcementco.com' ||
            pathname === 'staging-processing-parkingenforcementco.netlify.app' ||
            pathname === 'processing-parkingenforcementco.netlify.app' ||
            pathname === 'processing.parkingenforcementco.com'
        ) {
            setCustomBranding(peakParkingLogo);
        } else if (
            pathname === 'staging-processing-parkcohasset.netlify.app' ||
            pathname === 'staging-processing.parkcohasset.com' ||
            pathname === 'processing-parkcohasset.netlify.app' ||
            pathname === 'processing.parkcohasset.com'
        ) {
            setCustomBranding(cohassetLogo);
        } else if (
            pathname === "processing-hpa2pay.netlify.app" ||
            pathname === "staging-processing-hpa2pay.netlify.app" ||
            pathname === "processing.hpa2pay.com" ||
            pathname === "staging-processing.hpa2pay.com"
          ) {
            setCustomBranding(HartfortLogo);
          } else if (
            pathname === "staging-processing.parkbeaufortnc.com" ||
            pathname === "staging-processing-parkbeaufortnc.netlify.app" ||
            pathname === "processing.parkbeaufortnc.com" ||
            pathname === "processing-parkbeaufortnc.netlify.app"
        ) {
            setCustomBranding(BeaufortLogo);
        } else if (pathname === "staging-processing.parkashlandoregon.com" || pathname === "staging-processing-parkashlandoregon.netlify.app" || pathname === "processing.parkashlandoregon.com" || pathname === "processing-parkashlandoregon.netlify.app") {
            setCustomBranding(AshLandLogo);
        } else if (pathname === "staging-processing-parkriveroakssc.netlify.app" || pathname === "staging-processing.parkriveroakssc.com" || pathname === "processing-parkriveroakssc.netlify.app" || pathname === "processing.parkriveroakssc.com") {
            setCustomBranding(Lazparking);
        } else if (pathname === "staging-processing-parkbangor.netlify.app" || pathname === "staging-processing.parkbangor.org" || pathname === "processing-parkbangor.netlify.app" || pathname === "processing.parkbangor.org") {
            setCustomBranding(ParkBangor);
        } else if (pathname === "staging-processing-parkiop.netlify.app" || pathname === "staging-processing.parkiop.com"  || pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com" ) {
            setCustomBranding(Parkiop);
        } else if (pathname === "staging-processing.parkbismarcknd.com" || pathname === "staging-processing-parkbismarck.netlify.app" || pathname === "processing-bismarck.netlify.app" || pathname === "processing.parkbismarcknd.com") {
            setCustomBranding(Bismarck);
        } else if (pathname === "staging-processing.parkkalamazoo.com" || pathname === "staging-processing-parkkalamazoo.netlify.app" || pathname === "processing-parkkalamazoo.netlify.app" || pathname === "processing.parkkalamazoo.com") {
            setCustomBranding(Kalamazoo);
        } else if (pathname === "lazstaging-processing.netlify.app" || pathname === "staging-processing.parkcountryclubplaza.com" || pathname === "laz-processing.netlify.app" || pathname === "processing.parkcountryclubplaza.com") {
            setCustomBranding(Lazparking);
        } else if (pathname === "oceancity-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkocmd.com" || pathname === "processing.parkocmd.com" || pathname === "processing-parkocmd.netlify.app") {
            setCustomBranding(Oceancity);
        } else if (pathname === "greenvillesc-processing-staging.netlify.app") {
            setCustomBranding(Greenvillesc);
        } else if (pathname === "parkmystic-processing-staging.netlify.app" || pathname === "parkmystic-processing.netlify.app") {
            setCustomBranding(Mystic);
        } else if (pathname === "mansfield-processing-staging.netlify.app" || pathname === "mansfield-processing.netlify.app") {
            setCustomBranding(Mansfield);
        } else if (pathname === "follybeach-processing-staging.netlify.app" || pathname === "follybeach-processing.netlify.app" || pathname === "follybeach-processing-staging.parkloyalty.com" || pathname === "follybeach-processing.parkloyalty.com") {
            setCustomBranding(FollyBeach);
        } else if (pathname === "leavenworth-processing-staging.netlify.app" || pathname === "leavenworth-processing.netlify.app") {
            setCustomBranding(CityofLeavenWorth);
        } else if (pathname === "ace-ticketprocessing-staging.netlify.app" || pathname === "ace-ticketprocessing.netlify.app") {
            setCustomBranding(Encinitas);
        } else if (pathname === "duncan-ticketprocessing-staging.netlify.app" || pathname === "duncan-ticketprocessing.netlify.app") {
            setCustomBranding(logo_duncan);
        } else if (pathname === "spplus-ticketprocessing-staging.netlify.app" || pathname === "spplus-ticketprocessing.netlify.app") {
            setCustomBranding(logo_sp);
        } else {
            setCustomBranding(Parklogo);
        }
    })


    const downloadAttachment = async (e, urls) => {
        e.preventDefault();
        let urlList = [];
    
        const downloadPromises = urls.map(async (i) => {
            const imageData = {
                links: { 0: i },
                download_type: "CitationImages",
                site_id: id_site.siteinfo,
            };
    
            try {
                const responseImage = await mainService.downloadImage(imageData);
                if (responseImage.data) {
                    urlList.push(responseImage.data.metadata[0].url);
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    refreshTokenMiddleware(dispatch);
                    // Retry download if token was refreshed
                    return downloadAttachment(e, [i]); // Retry for the specific URL
                } else {
                    console.error("Error downloading image:", err.message);
                }
            }
        });
    
        // Wait for all downloads to complete
        await Promise.all(downloadPromises);
    
    
        if (urlList.length > 0) {
            urlList.forEach((downloadUrl,index) => {
                console.log(downloadUrl,'download url')
                const anchor = document.createElement("a");
                anchor.href = downloadUrl;
                anchor.target = `_blank`; // Unique target for each tab
                anchor.rel = "noopener noreferrer"; // Security enhancement
                anchor.download = `attachment_${index}`; // Unique filename
    
                // Trigger a click event on the anchor element
                document.body.appendChild(anchor);
                anchor.click();
    
                // Remove the anchor element after the click event
                document.body.removeChild(anchor);
            });
        } else {
            console.error("Download URL not available.");
        }
    };    
    

    return (
        <>
            <div className="wrapper" id="thankyou-page-download">
                <div className="container-fluid searchResult-section right-panel cstm-right-panel">
                    <div className="payment-success-main">
                        <div className="row">
                            <div className="col-12 thankyou-only"> 
                                <h3>Payment Successful</h3>
                                <p className='cstm-thank-you-ct'>Thank you for using online payment portal.</p>

                                <div className='cstm-ps-main'>

                                    <div className='cstm-ps-main-head'>
                                        <div className='cstm-ps-main-head-logo'><img src={showCustomBranding} /></div>
                                        {(id_site.siteinfo === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" || id_site.siteinfo === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45") ? 
                                            <div className='cstm-ps-main-head-cont'>Audrey P. Beck Municipal Building <br />4 S Eagleville Road <br /> Storrs Mansfield, CT 06268</div>
                                            : (id_site.siteinfo === "dd9bb726-e556-11ed-8bae-acde48001122" || id_site.siteinfo === "cd1200ae-1fd5-11ee-8b64-665980bd7c45") ?
                                            <div className='cstm-ps-main-head-cont'>Mystic CT Address: <br />LAZ Parking <br /> Mystic, CT, 06355</div>
                                            : (id_site.siteinfo === "68ea3b5c-172f-4f33-8d55-e76c301ad212") ?
                                            <div className='cstm-ps-main-head-cont'>206 S Main Street, <br />Greenville, SC 29601</div>
                                            :
                                            (id_site.siteinfo === "7b4bc424-a7dc-11ed-a1e8-42e281654476" || id_site.siteinfo === "da3c1178-a07e-11ee-8147-56d1ee6b2413") ?
                                            <div className='cstm-ps-main-head-cont'>6501 Coastal Hwy <br />Ocean City, <br /> MD 21842</div>
                                            :
                                            (id_site.siteinfo === "9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e") ?
                                            <div className='cstm-ps-main-head-cont'>Country Club Plaza  <br />4706 Broadway <br /> Kansas City Missouri 64112 <br /> (816) 753-0100 </div>
                                            :
                                            (id_site.siteinfo === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8" || id_site.siteinfo === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3") ?
                                            <div className='cstm-ps-main-head-cont'>Kalamazoo City Hall, <br />215 E. Water Street, <br />Kalamazoo, MI 49007 </div>
                                            :
                                            (id_site.siteinfo === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" || id_site.siteinfo === "ead64c86-3681-11ee-9c41-6ef2fb394a83") ?
                                            <div className='cstm-ps-main-head-cont'>Bismarck Municipal Court , <br />Burleigh County Court House, <br /> 514 East Thayer Avenue, <br /> Bismarck, ND 58501 , </div>
                                            :
                                            (id_site.siteinfo === "7fc01b7c-c982-11ee-be9c-72ab48aba21d" || id_site.siteinfo === "c6443f42-c982-11ee-b39a-bebc79a4d1a3") ?
                                            <div className='cstm-ps-main-head-cont'>Park IOP, <br />PO Box 513, <br /> Isle Of Palms SC 29451 </div>
                                            :
                                            (id_site.siteinfo === "b87d467a-dbd3-11ee-be9c-72ab48aba21d" || id_site.siteinfo === "3aba432e-dbd7-11ee-845b-0602968307f0") ?
                                            <div className='cstm-ps-main-head-cont'>City of Bangor Parking, <br />100 Broad Street, <br /> Bangor, ME 04401 </div>
                                            :
                                            (id_site.siteinfo === "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4" || id_site.siteinfo === "28022506-fe2f-11ee-aa58-82967aaeaa80") ?
                                            <div className='cstm-ps-main-head-cont'>40 N Main St #7, <br />Ashland, <br />OR 97520 </div>
                                            :
                                            (id_site.siteinfo === "f3062dac-fe2e-11ee-ae3f-92040ec4c6a4" || id_site.siteinfo === "46dd302e-fe2f-11ee-aa58-82967aaeaa80") ?
                                            <div className='cstm-ps-main-head-cont'>AW GRAY ST. & SHEPHERD DR., <br />HOUSTON, <br /> TX 77019 </div>
                                            :
                                            (id_site.siteinfo === "2b6d81e0-0d25-11ef-8e44-76f8a1a040d2" || id_site.siteinfo === "3e2116e8-0d26-11ef-9818-ee2c79e2244c") ?
                                            <div className='cstm-ps-main-head-cont'>701 Front St., <br />Beaufort, NC 28516, <br />United States </div>
                                            :
                                            (
                                            id_site.siteinfo === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
                                            id_site.siteinfo === "5bd20490-dc2f-11ee-bf9e-72127457a419") ?
                                            <div className='cstm-ps-main-head-cont'>Office of Parking Management <br />
                                            PO Box 230157 <br />
                                            Hartford, CT  06123-0157 </div>
                                            :
                                            (
                                            id_site.siteinfo === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
                                            id_site.siteinfo === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c") ?
                                            <div className='cstm-ps-main-head-cont'>
                                           Town of Cohasset, MA
                                            <br />
                                            Cohasset Town Hall
                                            <br />
                                            41 Highland Ave.
                                            <br />
                                            Cohasset MA 02025
                                            </div>
                                            :
                                            (
                                            id_site.siteinfo === "fdbcf868-3ec3-11ef-8e44-76f8a1a040d2" ||
                                            id_site.siteinfo === "23697ca0-3e04-11ef-85dc-e665cc09bf86") ?
                                            <div className='cstm-ps-main-head-cont'>701 Brazos St,  <br />
                                            Suite 320 <br />
                                            Austin, TX 78701 </div>
                                            : (
                                            id_site.siteinfo === "cd666208-44ae-11ef-b128-de29d549d608" ||
                                            id_site.siteinfo === "4fa2e26e-44af-11ef-ba31-ca0a5add8b0c") ?
                                            <div className='cstm-ps-main-head-cont'>
                                                1234 Market Street, <br />
                                                Philadelphia PA 19107 
                                            </div>
                                             : (
                                                id_site.siteinfo === "70f9c966-4985-11ef-8e44-76f8a1a040d2" ||
                                                id_site.siteinfo === "a95c3734-4986-11ef-98e9-2a370f00fe52") ?
                                                <div className='cstm-ps-main-head-cont'>
                                                    414 E. 12th Street, <br />
                                                    Kansas City, MO 64106, <br />
                                                    816-513-1313
                                                </div>
                                            :
                                            <div className='cstm-ps-main-head-cont'>City of Encinitas Cashiers Office <br />ACE DROP BOX <br />505 S Vulcan Ave <br />Encinitas, CA 92024</div>
                                        }
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Receipt #</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.data?.transaction_id || '-'}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Transaction Date</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.citation_detail?.transaction_date ? moment((tranId?.citation_detail?.transaction_date).replace(/\.\d+/, '').replace(' EDT', '')).format('MM/DD/YYYY HH:mm') : "-"}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Account No.</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.citation_detail?.card_number || '-'}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Exp</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.citation_detail?.expiry || '-'}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>{changeName} #</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.citation_detail?.ticket_no || '-'}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Issue Date</div>
                                        <div className='cstm-ps-main-cm-cont'>: {moment((tranId?.citation_detail.issue_date)?.substring(0, 10)).format('MM/DD/YYYY') + " " + (tranId.citation_detail.issue_date).substring(11, 16)}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Plate</div>
                                        <div className='cstm-ps-main-cm-cont'>: {allCitationData?.citation_detail[0]?.plate || '-'}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>State</div>
                                        <div className='cstm-ps-main-cm-cont'>: {allCitationData?.citation_detail[0]?.state || '-'}</div>
                                    </div>

                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Violation Code</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.citation_detail?.vio_code || '-'}</div>
                                    </div>
                                    
                                    <div className='cstm-ps-main-cm'>
                                        <div className='cstm-ps-main-cm-lable'>Description</div>
                                        <div className='cstm-ps-main-cm-cont'>: {tranId?.citation_detail?.desc || '-'}</div>
                                    </div>

                                </div>


                                <div className="cstm-thankyou-main cstm-thankyou-action">
                                    <div className="cstm-back-btn">
                                        <Link to={'/ticketreporting'}>Back</Link>
                                    </div>

                                    <div className="cstm-back-btn">
                                        <ReactToPrint
                                            documentTitle={`Invoice-${tranId?.citation_detail?.ticket_no}`}
                                            pageStyle={pageStyle}
                                            trigger={() => {
                                                return <Link className="licence-download cstm-licene-download report-mr">Print</Link>;
                                            }}
                                            content={() => document.getElementById("thankyou-page-download").children[0]}
                                        />
                                    </div>
                                     
                                    {/* <br /><br /><br /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Thankyou