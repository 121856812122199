import { useEffect, useMemo, useState } from "react";
import dashboardService from "../../../Services/dashboard.service";
import moment from "moment";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useDispatch } from "react-redux";

const ROAddressTab = (ticketNo) => {
  const dispatch = useDispatch();
  const [report, setReport] = useState([]);
  const [sorting, setSorting] = useState({ field: "", ascending: true });
  const [noRecordFound, setNoRecordFound] = useState(false);
  const getROList = async (ticket_no) => {
    try {
      const response = await dashboardService.getROAddressList(ticket_no);
      if (response.data.data && response.data.data.length > 0) {
        setReport(response.data.data);
        setNoRecordFound(false);
      } else {
        setReport([]);
        setNoRecordFound(true);
      }
    } catch (err) {
      setReport([]);
      setNoRecordFound(true);
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getROList(ticket_no);
      }
    }
  };
  useEffect(() => {
    getROList(ticketNo.ticketNo);
  }, [ticketNo]);

  const tableHeading = [
    "Ticket no",
    "License plate no",
    "PLate state",
    "Requested at",
    "DMV name",
    "DMV address",
    "DMV city",
    "DMV state",
    "DMV zip code 5",
    "DMV zip code 4",
    "DMV vehicle year",
    "DMV vehicle make",
    "DMV vehicle model",
    "DMV vehicle body style",
    "DMV vin number",
    "Received at",
    "RO source",
    "RO status",
    "RO status detail",
    "DMV date of birth",
    "DMV drivers license",
    "Type",
    "Plate color",
  ];
  const keySequence = [
    "ticket_no",
    "lp_no",
    "plate_state",
    "requested_at",
    "dmv_name",
    "dmv_address",
    "dmv_city",
    "dmv_state",
    "dmv_zip_code_5",
    "dmv_zip_code_4",
    "dmv_vehicle_year",
    "dmv_vehicle_make",
    "dmv_vehicle_model",
    "dmv_vehicle_body_style",
    "dmv_vin_number",
    "received_at",
    "ro_source",
    "ro_status",
    "ro_status_detail",
    "dmv_date_of_birth",
    "dmv_drivers_license",
    "plate_type",
    "plate_type_color",
  ];

  const applySorting = useMemo(
    () => (key, ascending) => {
      setSorting({ key: key, ascending: ascending });
    },
    [sorting, report]
  );

  return (
    <>
      {noRecordFound ? (
        <div className="no-record-found-main">No Record Found</div>
      ) : (
        <div
          className="report_tbl_scroll fix-page-scroll-only"
          id="tbl-main-download"
        >
          <table
            className="table table-heading table-bordered"
            id="cstm-report-tb-display"
          >
            <thead>
              <tr>
                {tableHeading &&
                  tableHeading.length > 0 &&
                  tableHeading.map((key, index) => (
                    <th
                      className={`srt-tab ${
                        sorting.key === keySequence[index] &&
                        (sorting.ascending ? "srt-ace" : "srt-desc")
                      }`}
                      onClick={() =>
                        applySorting(keySequence[index], !sorting.ascending)
                      }
                    >
                      {key}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {report &&
                report.length > 0 &&
                report.map((item) => (
                  <tr>
                    <td>{item.ticket_no ? item.ticket_no : "-"}</td>
                    <td>{item.lp_no ? item.lp_no : "-"}</td>
                    <td>{item.plate_state ? item.plate_state : "-"}</td>
                    <td>
                      {item.requested_at
                        ? moment(item.requested_at.split("T")[0]).format(
                            "MM/DD/YYYY"
                          ) +
                          " " +
                          item.requested_at.substring(11, 19)
                        : "-"}
                    </td>
                    <td>{item.dmv_name ? item.dmv_name : "-"}</td>
                    <td>{item.dmv_address ? item.dmv_address : "-"}</td>
                    <td>{item.dmv_city ? item.dmv_city : "-"}</td>
                    <td>{item.dmv_state ? item.dmv_state : "-"}</td>
                    <td>{item.dmv_zip_code_5 ? item.dmv_zip_code_5 : "-"}</td>
                    <td>{item.dmv_zip_code_4 ? item.dmv_zip_code_4 : "-"}</td>
                    <td>
                      {item.dmv_vehicle_year ? item.dmv_vehicle_year : "-"}
                    </td>
                    <td>
                      {item.dmv_vehicle_make ? item.dmv_vehicle_make : "-"}
                    </td>
                    <td>
                      {item.dmv_vehicle_model ? item.dmv_vehicle_model : "-"}
                    </td>
                    <td>
                      {item.dmv_vehicle_body_style
                        ? item.dmv_vehicle_body_style
                        : "-"}
                    </td>
                    <td>{item.dmv_vin_number ? item.dmv_vin_number : "-"}</td>
                    
                    <td>
                      {item.received_at
                        ? moment(item.received_at.split("T")[0]).format(
                            "MM/DD/YYYY"
                          ) +
                          " " +
                          item.received_at.substring(11, 19)
                        : "-"}
                    </td>
                    <td>{item.ro_source ? item.ro_source : "-"}</td>
                    <td>{item.ro_status ? item.ro_status : "-"}</td>
                    <td>
                      {item.ro_status_detail ? item.ro_status_detail : "-"}
                    </td>
                    <td>
                      {item.dmv_date_of_birth ? item.dmv_date_of_birth : "-"}
                    </td>
                    <td>
                      {item.dmv_drivers_license
                        ? item.dmv_drivers_license
                        : "-"}
                    </td>
                    <td>{item.plate_type ? item.plate_type : "-"}</td>
                    <td>
                      {item.plate_type_color ? item.plate_type_color : "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ROAddressTab;
