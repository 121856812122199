import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../customMiddelware";

import Eororicon from '../../Images/error-icon.png';
import PrintPdfCsvExcelDownload from "../Utilies/PrintPdfCsvExcelDownload";


const RoleList = () => {

    const dispatch = useDispatch()

    const [recordList, setRecordList] = useState([])
    const [loader, setLoader] = useState(false)
    const [confirmDelPopup, setConfirmDelPopup] = useState(false)
    const [popupDel, setPopupDel] = useState(false)
    const [delRoleInfo, setDelRoleInfo] = useState({})
    const [message, setMessage] = useState('')

    const [sorting, setSorting] = useState({ field: 'xyz', ascending: true })


    useEffect(() => {
        const currentDataCopy = [...recordList];

        const sortedCurrentdata = currentDataCopy?.sort((a, b) => {
            return a[sorting.key]?.toString()?.localeCompare(b[sorting.key]?.toString());
        });

        setRecordList(sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse());
    }, [sorting]);

    const applySorting = (key, ascending) => {
        setSorting({ key: key, ascending: ascending });
    }

    useEffect(() => {
        getTableRecord()
    }, [])


    const getTableRecord = async () => {
        setLoader(true)
        try {
            const result = await mainService.rolesListAPI()

            if (result.data.status) {
                setRecordList(result.data.data.role_data)
            } else {
                setMessage('No Record Found')
            }
            setLoader(false)
        } catch (err) {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                getTableRecord()
            } else {
                setMessage('No Record Found')
                setLoader(false)
            }
        }
    }

    // open delete popup 

    const deleteBtn = (e, data) => {
        e.preventDefault()
        setConfirmDelPopup(true)
        setDelRoleInfo(data)
    }

    // handle delete role 
    
    const userDeletefun = async () => {
        try{
            setConfirmDelPopup(false)
            const role_id ={
                "role_id":delRoleInfo.role_id
            }
            const response = await mainService.deleteRole(role_id)
            setMessage(response.data.message)
            setPopupDel(true)
        } catch(err) {
            console.log(err,'err')
        }
    }

    const handleCloseModel = () => {
        getTableRecord()
        setPopupDel(false)
    }

    return (
        <>
            <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
                <div className="cstm-ticket-manage-sec">
                    <div className="cstm-ticket-manage-heading">
                        <h3 className="cstm-all-pg-ttl operate-ttl">All Roles</h3>
                    </div>
                    <PrintPdfCsvExcelDownload tableId='tbl-main-download' fileName='user-list' pageSize='a3' />
                </div>

                <div className="cstm-ticket-manage-sec">
                    <div className="cstm-ticket-manage-heading"></div>
                    <div className="cstm-ticket-manage-download"><Link className="add-role-btn" to='/create-role'>Add Role</Link></div>
                </div>

                <div className="tab-pane active">
                    <div className='text-center'>
                        {loader ?
                            <div className='text-center spin-load-main'>
                                <div className="spinner-border"></div>
                            </div>
                            :
                            (recordList === null || recordList?.length === 0 ?
                                <div className='no-record-found-main'>{message || 'No Record Found'}</div>
                                :
                                <div className="ctsm-table-scroll">
                                    <div className="table-overflow" id="tbl-main-download">
                                        <table className="table-heading table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th className={`srt-tab ${sorting.key === 'role_name' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('role_name', !sorting.ascending)}>Name</th>
                                                    <th>Status</th>
                                                    <th>Create Date time</th>
                                                    <th>Create Users</th>
                                                    <th>Modified Date Time</th>
                                                    <th>Modify User</th>
                                                    <th className="row-rmv">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!loader &&
                                                    (recordList || []).map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{item.role_name}</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td className="row-rmv">
                                                                <Link to="/role-detail" state={{ roleid: item.role_id }}>Edit</Link>  &nbsp; &nbsp;
                                                                <Link onClick={(e) => deleteBtn(e, item)} state={{ roledata: item }}>Delete</Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>

                {confirmDelPopup &&
                    <div className="main-body-modal">
                        <div className="main-modal">
                            <div className="cstm-close">
                                <button onClick={() => setConfirmDelPopup(false)}>×</button>
                            </div>
                            <div className="cstm-modal-body">
                                <div className="modal-body cstm-modal-body">
                                    <div className="text-center cstm-paymnet-img">
                                        <img src={Eororicon} alt="img" className="img-fluid" />
                                    </div>
                                    <p className="text-center mb-4">Are you sure you want to delete <b>{delRoleInfo.role_name}</b> role?</p>
                                    <div className='cstm-dc'>
                                        <button className='cstm-pp-delete' onClick={userDeletefun}>Delete</button>
                                        <button className='cstm-pp-cancel' onClick={() => setConfirmDelPopup(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {popupDel &&  <div className="main-body-modal">
        <div className="main-modal">
          <div className="cstm-close">
            <button onClick={handleCloseModel}>×</button>
          </div>
          <div className="cstm-modal-body">
            <div className="modal-body cstm-modal-body">
              <p className="text-center mb-4">Message: {message}</p>
            </div>
          </div>
        </div>
      </div>
                }

            </div>
        </>
    )
}

export default RoleList