import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import mainService from '../Services/main.service';
import { refreshTokenMiddleware } from '../Component/customMiddelware';
import { changeticket } from '../Global/site';
import Cookies from "js-cookie";

// check if site has access to Helical reports
export const hasHelicalReports = () => {
  const currentSite = Cookies.get('siteinfo');
  const siteArray = [
    'a98dcd6a-cfc2-11ed-ad98-66070e279278', // folly staging
    '33370b0e-e5fc-11ed-8b64-665980bd7c45', // folly prod
    'c293c922-fa4c-11ed-b4ca-ae854bfaee9a', // mansfield staging
    '98ccd4bc-fa4c-11ed-8b64-665980bd7c45', // mansfield prod
    '7a43db7c-2a03-11ed-8809-7af7a0f7ffb5', // encinitas staging
    '08ebc7b0-5b85-11ed-99bd-12086ced55af', // encinitas prod
    '46b8a5e5-c1d0-420e-b825-c09712ee8665', // leavenworth staging
    '267ccc30-1bdd-11ed-8edb-e6f96eaf3014', // leavenworth prod
    '7b4bc424-a7dc-11ed-a1e8-42e281654476', // ocean city staging
    'da3c1178-a07e-11ee-8147-56d1ee6b2413', // ocean city prod
    '183db4ec-a15e-11ee-b39a-bebc79a4d1a3', // kalamazoo prod
    'e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8', // kalamazoo staging,
    'ead64c86-3681-11ee-9c41-6ef2fb394a83', // Bismarck prod
    '9db6bd50-3681-11ee-b4ca-ae854bfaee9a', // Bismarck staging
    'c6443f42-c982-11ee-b39a-bebc79a4d1a3', // Isle of Palms prod
    '7fc01b7c-c982-11ee-be9c-72ab48aba21d', // Isle of Palms staging
    '3aba432e-dbd7-11ee-845b-0602968307f0', // Bangor prod
    'b87d467a-dbd3-11ee-be9c-72ab48aba21d', // Bangor staging
    'c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4', // Ashland staging
    '28022506-fe2f-11ee-aa58-82967aaeaa80', // Ashland prod
    'f3062dac-fe2e-11ee-ae3f-92040ec4c6a4', // River Oaks staging
    '46dd302e-fe2f-11ee-aa58-82967aaeaa80', // River Oaks prod,
    '2b6d81e0-0d25-11ef-8e44-76f8a1a040d2', // Beaufort staging
    '3e2116e8-0d26-11ef-9818-ee2c79e2244c', // Beaufort prod
    'b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2', // Cohasset staging
    '30c9b390-2ec5-11ef-ba31-ca0a5add8b0c', // Cohasset prod,
    'fdbcf868-3ec3-11ef-8e44-76f8a1a040d2', // Peak Parking staging
    '23697ca0-3e04-11ef-85dc-e665cc09bf86', // Peak Parking prod
    'cd666208-44ae-11ef-b128-de29d549d608', // SEPTA staging
    '4fa2e26e-44af-11ef-ba31-ca0a5add8b0c', // SEPTA prod
    '0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d', // HPA staging
    '5bd20490-dc2f-11ee-bf9e-72127457a419', // HPA prod
    '70f9c966-4985-11ef-8e44-76f8a1a040d2', // KCMO Staging
    'a95c3734-4986-11ef-98e9-2a370f00fe52', // KCMO Prod
  ];

  return siteArray.includes(currentSite)
}

const SideBar = (props) => {

  const { siteinfo } = useSelector(state => state.auth)
  const [uiElements, setUIElements] = useState([]);
  const changeName = changeticket();
  const dispatch = useDispatch()
  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";

  useEffect(() => {
    const myElement = document.querySelector('.nav-link2.active');
    const myElement2 = document.querySelector('.nav-link.active');

    if (myElement) {
      myElement.closest('.collapse').classList.add('show');
      const myparent = myElement.closest('.collapse')
      myparent.previousElementSibling.classList.add('active');

    } else if (!myElement && !myElement2) {
      const dashb = document.querySelector('#dashboards')
      if (dashb) {
        dashb.classList.add('show');
        const myparent = dashb
        myparent.previousElementSibling.classList.add('active');
      }

    }

  }, [])

  useEffect(() => {
    if (siteinfo) {
      getUIElementsFun()
    }
  }, [siteinfo])

  const getUIElementsFun = async () => {

    try {
      const result = await mainService.UIElementsAPI()
      if (result.data.status) {
        const elementArr = result.data.data.ui_elements

        setUIElements(elementArr)

      }

    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch)
        getUIElementsFun()
      }
    }
  }

  const onActivemenuChange = (e) => {

    const newele = document.querySelector('.accordion-heading.active');
    const newcolp = document.querySelector('.collapse.show');
    // const newele2 = document.querySelector('.nav-link.active');

    if (newele && newcolp) {
      newele.classList.remove('active');
      newcolp.classList.remove('show');
    }
    //  else if(newele2){
    //   newele2.classList.remove('active');
    // }
    e.target.classList.add('active')

  }

  return (
    <div className={sidebarClass}>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-12 left-panel cstm-left-panel cstm-tab-links">
              <div className='cstm-sidebar cstm-left-sidebar  '>
                <ul className="nav nav-tabs cstm-nav-tabs" id="menuitemsgrp">
                  {/* show only user select site  */}
                  {siteinfo &&
                    <>
                      {
                      (uiElements.includes('PAGE_DASHBOARD_PROCESSING') ||
                        uiElements.includes('PAGE_DASHBOARD_APPEAL') ||
                        uiElements.includes('PAGE_DASHBOARD_DMV_LOOKUP') ||
                        uiElements.includes('PAGE_DASHBOARD_ENFORCEMENT') ||
                        uiElements.includes('PAGE_DASHBOARD_REVENUE') ||
                        uiElements.includes('PAGE_DASHBOARD_INTEGRATIONS') ||
                        uiElements.includes('PAGE_DASHBOARD_UTILIZATIONS')) &&
                        <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                          <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#dashboards" onClick={(e) => onActivemenuChange(e)}>
                            Dashboard
                          </div>
                          {/* new dashboard section for Follybeach */}
                          {(hasHelicalReports() &&
                            <ul className="nav nav-list cstm-sub-item cstm-sub-item-main collapse" data-parent="#menuitemsgrp" id="dashboards">
                              {
                                uiElements.includes('PAGE_DASHBOARD_ENFORCEMENT') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dashboard" className="nav-link2 bg-box">
                                    Enforcement
                                  </NavLink>
                                </li>
                              }
                              {
                                uiElements.includes('PAGE_DASHBOARD_PROCESSING') &&
                                (hasHelicalReports() &&
                                  <li className="nav-item cstm-nav-item">
                                    <NavLink to="/processingReport" className="nav-link2 bg-box">
                                      Processing
                                    </NavLink>
                                  </li>
                                )
                              }
                              {
                                uiElements.includes('PAGE_DASHBOARD_DMV_LOOKUP') &&
                                (hasHelicalReports() &&
                                  <li className="nav-item cstm-nav-item">
                                    <NavLink to="/dashboardDMVLookup" className="nav-link2 bg-box">
                                      DMV Look Up
                                    </NavLink>
                                  </li>
                                )
                              }
                              {
                                uiElements.includes('PAGE_DASHBOARD_APPEAL') &&
                                (hasHelicalReports() &&
                                  <li className="nav-item cstm-nav-item">
                                    <NavLink to="/dashboardAppealReport" className="nav-link2 bg-box">
                                      Appeal
                                    </NavLink>
                                  </li>
                                )
                              }


                              {
                                uiElements.includes('PAGE_DASHBOARD_REVENUE') &&
                                (hasHelicalReports() &&
                                  <li className="nav-item cstm-nav-item">
                                    <NavLink to="/dashboardRevenueReport" className="nav-link2 bg-box">
                                      Revenue
                                    </NavLink>
                                  </li>
                                )
                              }
                              {
                                uiElements.includes('PAGE_DASHBOARD_INTEGRATIONS') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/integration" className="nav-link2 bg-box">
                                    Integrations
                                  </NavLink>
                                </li>
                              }
                              {
                                uiElements.includes('PAGE_DASHBOARD_UTILIZATIONS') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/utilization" className="nav-link2 bg-box">
                                    Utilization
                                  </NavLink>
                                </li>
                              }
                            </ul>
                          )}
                          {(!hasHelicalReports() &&
                            <ul className="nav nav-list cstm-sub-item cstm-sub-item-main collapse" data-parent="#menuitemsgrp" id="dashboards">
                              {
                                // uiElements.includes('PAGE_DASHBOARD_MAIN_DASHBOARD') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dashboard" className="nav-link2 bg-box">
                                    Main Dashboard
                                  </NavLink>
                                </li>
                              }
                              {
                                // uiElements.includes('PAGE_DASHBOARD_OPERATIONS') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/operation" className="nav-link2 bg-box">
                                    Operations
                                  </NavLink>
                                </li>
                              }
                              {
                                // uiElements.includes('PAGE_DASHBOARD_PRODUCTIVITY') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/productivity" className="nav-link2 bg-box">
                                    Productivity
                                  </NavLink>
                                </li>
                              }
                              {
                                // uiElements.includes('PAGE_DASHBOARD_REVENUE') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/revenue" className="nav-link2 bg-box">
                                    Revenue
                                  </NavLink>
                                </li>
                              }
                              {
                                // uiElements.includes('PAGE_DASHBOARD_INTEGRATIONS') &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/integration" className="nav-link2 bg-box">
                                    Integrations
                                  </NavLink>
                                </li>
                              }
                              {
                                // uiElements.includes('PAGE_DASHBOARD_UTILIZATION') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/utilization" className="nav-link2 bg-box">
                                  Utilization
                                </NavLink>
                              </li>
                            }
                          </ul>
                        )}
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_TICKET_MANAGEMENT_SEARCH_TICKETS') ||
                        uiElements.includes('PAGE_TICKET_MANAGEMENT_TICKET_LISTVIEW') ||
                        uiElements.includes('PAGE_TICKET_MANAGEMENT_EDIT_CITATIONS') ||
                        uiElements.includes('PAGE_TICKET_MANAGEMENT_PLATE_ANALYSIS')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#ticketmanagement" onClick={(e) => onActivemenuChange(e)}>
                          {changeName} Management
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="ticketmanagement">
                          {
                              uiElements.includes('PAGE_TICKET_MANAGEMENT_SEARCH_TICKETS') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/search" className="nav-link2 bg-box">
                                  Search {changeName + "s"}
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_TICKET_MANAGEMENT_TICKET_LISTVIEW') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/ticketreporting" className="nav-link2 bg-box">
                                  {changeName} List View
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_TICKET_MANAGEMENT_EDIT_CITATIONS') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/amendcitationlist" className="nav-link2 bg-box">
                                  Edit {changeName}
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_TICKET_MANAGEMENT_PLATE_ANALYSIS') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/ticketAnalyticsReport" className="nav-link2 bg-box">
                                    Plate Analysis
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_TICKET_MANAGEMENT_ISSUED_BILLING_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/issuedBillingReport" className="nav-link2 bg-box">
                                    Issued Billing Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_TICKET_MANAGEMENT_TICKET_COUNT_SUMMARY_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/ticketCountSummaryReport" className="nav-link2 bg-box">
                                    {changeName} Count Summary Report
                                  </NavLink>
                                </li>
                              )
                            }
                        </ul>
                      </li>
                    }

                    {(
                      uiElements.includes('PAGE_APPEALS_MANAGEMENT_APPEAL_MANAGEMENT') ||
                      uiElements.includes('PAGE_APPEALS_MANAGEMENT_MONTHLY_APPEAL_ANALYSIS') ||
                      uiElements.includes('PAGE_APPEALS_MANAGEMENT_DAILY_APPEAL_ANALYSIS') ||
                      uiElements.includes('PAGE_APPEALS_MANAGEMENT_APPEAL_AGE_REPORT')
                      ) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#appealmanagement" onClick={(e) => onActivemenuChange(e)}>
                          Appeals Management
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="appealmanagement">
                          {
                            uiElements.includes('PAGE_APPEALS_MANAGEMENT_APPEAL_MANAGEMENT') &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/appeal-report" className="nav-link2 bg-box">
                                Appeal Management
                              </NavLink>
                            </li>
                          }
                          {
                            uiElements.includes('PAGE_APPEALS_MANAGEMENT_MONTHLY_APPEAL_ANALYSIS') &&
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/monthlyAppealAnalysis" className="nav-link2 bg-box">
                                  Monthly Appeal Analysis
                                </NavLink>
                              </li>
                            )
                          }
                          {
                            uiElements.includes('PAGE_APPEALS_MANAGEMENT_DAILY_APPEAL_ANALYSIS') &&
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/dailyAppealsAnalysis" className="nav-link2 bg-box">
                                  Daily Appeals Analysis
                                </NavLink>
                              </li>
                            )
                          }
                          {
                            uiElements.includes('PAGE_APPEALS_MANAGEMENT_APPEAL_AGE_REPORT') &&
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/appealAgeReport" className="nav-link2 bg-box">
                                  Appeal Aged Report
                                </NavLink>
                              </li>
                            )
                          }

                        </ul>
                      </li>
                    }

                    {(
                      uiElements.includes('PAGE_HEARING_MANAGEMENT_HEARING_MANAGEMENT') ||
                      uiElements.includes('PAGE_HEARING_MANAGEMENT_HEARING_DOCKET') ||
                      uiElements.includes('PAGE_HEARING_MANAGEMENT_CREATE_HEARING') ||
                      uiElements.includes('PAGE_HEARING_MANAGEMENT_HEARING_ELIGIBLE_TICKETS')
                      ) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#hearingmanagement" onClick={(e) => onActivemenuChange(e)}>
                          Hearing Management
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="hearingmanagement">
                          {uiElements.includes('PAGE_HEARING_MANAGEMENT_HEARING_MANAGEMENT') &&
                              <li className="nav-item cstm-nav-item">
                              <NavLink to="/hearing-report" className="nav-link2 bg-box">
                                Hearing Management
                              </NavLink>
                            </li>
                            }
                            {
                            uiElements.includes('PAGE_HEARING_MANAGEMENT_HEARING_DOCKET') &&
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/flipbook" className="nav-link2 bg-box">
                                  Hearing Docket
                                </NavLink>
                              </li>
                            )
                          }
                          {
                            uiElements.includes('PAGE_HEARING_MANAGEMENT_CREATE_HEARING') &&
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/calendar" className="nav-link2 bg-box">
                                  Create Hearing
                                </NavLink>
                              </li>
                            )
                          }

                          {
                            uiElements.includes('PAGE_HEARING_MANAGEMENT_HEARING_ELIGIBLE_TICKETS') &&
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/hearingCitations" className="nav-link2 bg-box">
                                  Hearing Eligible {changeName + "s"}
                                </NavLink>
                              </li>
                            )
                          }

                          {uiElements.includes("PAGE_HEARING_MANAGEMENT_AGED_HEARING_REPORT") &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/agedHearingReport" className="nav-link2 bg-box">
                                Hearing Aged Report
                              </NavLink>
                            </li>
                          }
                        </ul>
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_MANUAL_CITATIONS_MANUAL_IMAGE_CITATION_CREATION') || uiElements.includes('PAGE_MANUAL_CITATIONS_CREATE_CITATION')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#manualcitations" onClick={(e) => onActivemenuChange(e)}>
                          Manual Citations
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="manualcitations">
                            {uiElements.includes('PAGE_MANUAL_CITATIONS_MANUAL_IMAGE_CITATION_CREATION') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/citation-image" className="nav-link2 bg-box">
                                  Manual Image Citation Creation
                                </NavLink>
                              </li>
                            }
                            {uiElements.includes('PAGE_MANUAL_CITATIONS_CREATE_CITATION') &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/create-citation" className="nav-link2 bg-box">
                                Create Citation
                              </NavLink>
                            </li>
                          }
                        </ul>
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_MANAGEMENT') ||
                        uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_SUMMARY') ||
                        uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_RECONCILIATION') ||
                        uiElements.includes('PAGE_REVENUE_REPORT_MONTHLY_RECONCILIATION_REPORT') ||
                        uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_FAILED_REPORT') ||
                        uiElements.includes('PAGE_REVENUE_REPORT_DAILY_ESCALATION_REPORT') ||
                        uiElements.includes('PAGE_REVENUE_REPORT_DAILY_RECONCILIATION_REPORT')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#revenuereports" onClick={(e) => onActivemenuChange(e)}>
                          Revenue Reports
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="revenuereports">
                          {
                              uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_MANAGEMENT') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/paidtickets" className="nav-link2 bg-box">
                                  Payment Management
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_SUMMARY') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/paymentcount" className="nav-link2 bg-box">
                                  Payment Summary
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_RECONCILIATION') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/reconciliation-payment" className="nav-link2 bg-box">
                                  Payment Reconciliation
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_REVENUE_REPORT_MONTHLY_RECONCILIATION_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/monthlyReconReport" className="nav-link2 bg-box">
                                    Monthly Reconciliation Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_REVENUE_REPORT_PAYMENT_FAILED_REPORT') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/payment-failed-report" className="nav-link2 bg-box">
                                  Payment Failed Report
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_REVENUE_REPORT_DAILY_RECONCILIATION_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyReconciliationReport" className="nav-link2 bg-box">
                                    Daily Reconciliation Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              // uiElements.includes('PAGE_REVENUE_REPORT_OTHER_REPORTS') && 
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/reports" className="nav-link2 bg-box">
                                    Other Reports
                                  </NavLink>
                                </li>
                              )
                          }
                        </ul>
                      </li>
                    }

                    {
                      // (uiElements.includes('PAGE_COLLECTIONS_MANAGEMENT_ESCALATION_CITATION_LIST')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#collectionsmanagement" onClick={(e) => onActivemenuChange(e)}>
                          Collections Managment
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="collectionsmanagement">
                          {
                            // uiElements.includes('PAGE_COLLECTIONS_MANAGEMENT_ESCALATION_CITATION_LIST') && 
                            (hasHelicalReports() &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/escalationDashboard" className="nav-link2 bg-box">
                                  Escalation Citation List
                                </NavLink>
                              </li>
                            )
                          }
                        </ul>
                      </li>
                    }

                    {
                      // (uiElements.includes('PAGE_RO_MANAGEMENT_RO_LIST') || uiElements.includes('PAGE_RO_MANAGEMENT_RO_COUNT_REPORT')) &&
                      (uiElements.includes('PAGE_RO_MANAGEMENT_RO_LIST_VIEW') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_RO_COUNT_REPORT_') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_MONTHLY_RO_SUCCESS_REPORT_') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_MONTHLY_RO_FAILURE_REPORT') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_DAILY_RO_FAILURE_REPORT') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_DAILY_RO_SUCCESS_REPORT') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_DAILY_RO_IN_PROGRESS_REPORT') ||
                        uiElements.includes('PAGE_RO_MANAGEMENT_MONTHLY_RO_ANALYSIS')
                      ) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#romanagement" onClick={(e) => onActivemenuChange(e)}>
                          RO Management
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="romanagement">
                          {
                              uiElements.includes('PAGE_RO_MANAGEMENT_RO_LIST_VIEW') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/ro-reports" className="nav-link2 bg-box">
                                  RO List View
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_RO_COUNT_REPORT_') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/ro-count" className="nav-link2 bg-box">
                                  RO Count Report
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_MONTHLY_RO_SUCCESS_REPORT_') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/monthlyRoSuccessReport" className="nav-link2 bg-box">
                                    Monthly RO Success Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_MONTHLY_RO_FAILURE_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/monthlyRoFailedReport" className="nav-link2 bg-box">
                                    Monthly RO Failure Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_DAILY_RO_FAILURE_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyRoFailedReport" className="nav-link2 bg-box">
                                    Daily RO Failure Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_DAILY_RO_SUCCESS_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyRoSuccessReport" className="nav-link2 bg-box">
                                    Daily RO Success Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_DAILY_RO_IN_PROGRESS_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyRoProgressReport" className="nav-link2 bg-box">
                                    Daily RO In Progress Report
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_RO_MANAGEMENT_MONTHLY_RO_ANALYSIS') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/roDataReport" className="nav-link2 bg-box">
                                    Monthly RO Analysis
                                  </NavLink>
                                </li>
                              )
                          }
                        </ul>
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_NOTICE_MANAGEMENT_NOTICE_LIST_VIEW') ||
                        uiElements.includes('PAGE_NOTICE_MANAGEMENT_NOTICE_COUNT_REPORT') ||
                        uiElements.includes('PAGE_NOTICE_MANAGEMENT_DAILY_NOTICES_CREATED') ||
                        uiElements.includes('PAGE_NOTICE_MANAGEMENT_DAILY_NOTICES_SENT') ||
                        uiElements.includes('PAGE_NOTICE_MANAGEMENT_MONTHLY_NOTICES_CREATED') ||
                        uiElements.includes('PAGE_NOTICE_MANAGEMENT_MONTHLY_NOTICES_SENT')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#notice-management" onClick={(e) => onActivemenuChange(e)}>
                          Notice Management
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="notice-management">
                          {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_NOTICE_LIST_VIEW') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/notice" className="nav-link2 bg-box">
                                  Notice List View
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_NOTICE_COUNT_REPORT') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/notice-count" className="nav-link2 bg-box">
                                  Notice Count Report
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_DAILY_NOTICES_CREATED') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyNoticeCreatedReport" className="nav-link2 bg-box">
                                    Daily Notices Created
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_DAILY_NOTICES_SENT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyNoticeSentReport" className="nav-link2 bg-box">
                                    Daily Notices Mailed
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_MONTHLY_NOTICES_CREATED') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/monthlyNoticeCreatedReport" className="nav-link2 bg-box">
                                    Monthly Notices Created
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_MONTHLY_NOTICES_SENT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/monthlyNoticeSentReport" className="nav-link2 bg-box">
                                    Monthly Notices Mailed
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_DAILY_FAILED_NOTICES_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyFailedNoticesReport" className="nav-link2 bg-box">
                                    Daily Failed Notices
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_DAILY_EMAILED_NOTICES_REPORT') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/dailyEmailedNoticesReport" className="nav-link2 bg-box">
                                    Daily Emailed Notices
                                  </NavLink>
                                </li>
                              )
                            }
                            {
                              uiElements.includes('PAGE_NOTICE_MANAGEMENT_MONTHLY_EMAILED_NOTICES') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/monthlyEmailedNoticesReport" className="nav-link2 bg-box">
                                    Monthly Emailed Notices
                                  </NavLink>
                                </li>
                              )
                          }
                        </ul>
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_DATA_VIEWS_PAYMENT_DATA') ||
                        uiElements.includes('PAGE_DATA_VIEWS_PERMIT_DATA') ||
                        uiElements.includes('PAGE_DATA_VIEWS_SCOFFLAW') ||
                        uiElements.includes('PAGE_DATA_VIEWS_CAMERA_DATA_REPORT_')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab">
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#dataviews" onClick={(e) => onActivemenuChange(e)}>
                          Data Views
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="dataviews">
                          {
                              uiElements.includes('PAGE_DATA_VIEWS_PAYMENT_DATA') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/payment" className="nav-link2 bg-box">
                                  Payment Data
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_DATA_VIEWS_PERMIT_DATA') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/permit" className="nav-link2 bg-box">
                                  Permit Data
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_DATA_VIEWS_SCOFFLAW') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/scofflaw" className="nav-link2 bg-box">
                                  Scofflaw
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_DATA_VIEWS_CAMERA_DATA_REPORT_') &&
                            <li className="nav-item cstm-nav-item">
                                  <NavLink to="/cameraData" className="nav-link2 bg-box">
                                Camera Data Report
                              </NavLink>
                            </li>
                          }
                        </ul>
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_REPORTS_USER_ACCOUNT') || uiElements.includes('PAGE_REPORTS_CITATION_ANALYSIS')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#reports" onClick={(e) => onActivemenuChange(e)}>
                          Reports
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="reports">
                          {
                              uiElements.includes('PAGE_REPORTS_USER_ACCOUNT') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/user-account" className="nav-link2 bg-box">
                                  User Account
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_REPORTS_CITATION_ANALYSIS') &&
                              (hasHelicalReports() &&
                                <li className="nav-item cstm-nav-item">
                                  <NavLink to="/citationReport" className="nav-link2 bg-box">
                                    Citation Analysis
                                  </NavLink>
                                </li>
                              )
                          }
                        </ul>
                      </li>
                    }

                    {
                      (uiElements.includes('PAGE_ADMINISTRATION_NOTICE_TEMPLATE_MANAGEMENT_') ||
                        uiElements.includes('PAGE_ADMINISTRATION_USER_MANAGEMENT') ||
                        uiElements.includes('PAGE_ADMINISTRATION_ROLE_LIST') ||
                        uiElements.includes('PAGE_ADMINISTRATION_ADD_RESOURCE')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#administration" onClick={(e) => onActivemenuChange(e)}>
                          Administration
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="administration">
                          {
                              uiElements.includes('PAGE_ADMINISTRATION_NOTICE_TEMPLATE_MANAGEMENT_') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/template-notice" className="nav-link2 bg-box">
                                  Notice Template Management
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_ADMINISTRATION_USER_MANAGEMENT') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/users" className="nav-link2 bg-box">
                                  User Management
                                </NavLink>
                              </li>
                            }
                            {
                              uiElements.includes('PAGE_ADMINISTRATION_ROLE_LIST') &&
                              <li className="nav-item cstm-nav-item">
                                <NavLink to="/roles" className="nav-link2 bg-box">
                                  Role List
                                </NavLink>
                              </li>

                            }
                            {
                              uiElements.includes('PAGE_ADMINISTRATION_ADD_RESOURCE') &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/addresource" className="nav-link2 bg-box">
                                Add Resource
                              </NavLink>
                            </li>
                          }
                        </ul>
                      </li>
                    }

                    {(uiElements.includes('PAGE_PERMITS_MANAGEMENT_PERMITS_OPTION') ||
                      uiElements.includes('PAGE_PERMITS_MANAGEMENT_PERMITS_CONFIGURATION')) &&
                      <li className="nav-item cstm-nav-item cstm-sub-items sidebar-tab" >
                        <div className="nav-link bg-box accordion-heading tab-label check-cstm-open" data-toggle="collapse" data-target="#permits" onClick={(e) => onActivemenuChange(e)}>
                          Permits Management
                        </div>
                        <ul className="nav nav-list collapse cstm-sub-item cstm-sub-item-main" data-parent="#menuitemsgrp" id="permits">
                          <li className="nav-item cstm-nav-item">
                              <NavLink to="/purchased-permits" className="nav-link2 bg-box">
                                Purchased Permits
                              </NavLink>
                            </li>
                          {
                            uiElements.includes('PAGE_PERMITS_MANAGEMENT_PERMITS_OPTION') &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/permits-option" className="nav-link2 bg-box">
                                Permits Option
                              </NavLink>
                            </li>
                          }
                          {
                            uiElements.includes('PAGE_PERMITS_MANAGEMENT_PERMITS_OPTION') &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/permit-list-map" className="nav-link2 bg-box">
                                Buy Permits
                              </NavLink>
                            </li>
                          }
                          {
                            uiElements.includes('PAGE_PERMITS_MANAGEMENT_PERMITS_CONFIGURATION') &&
                            <li className="nav-item cstm-nav-item">
                              <NavLink to="/permits-configuration" className="nav-link2 bg-box">
                                Permits Configuration
                              </NavLink>
                            </li>
                          }
                        </ul>
                      </li>}
                      
                    {/* {!sitestatus &&
                      <li className="nav-item cstm-nav-item" onClick={(e) => onActivemenuChange(e)}>
                        <NavLink to="rules-engine" className="nav-link bg-box">
                          Rules Engine
                        </NavLink>
                      </li>
                      } */}
                  </>
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};

export default SideBar;
