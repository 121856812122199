import { TICKET_SEARCH_QUERY, SAVE_LAST_TICKET_SEARCH_QUERY, LOAD_LAST_TICKET_SEARCH_QUERY } from '../actions/types';

const initialState = {
    allCitationData: null,
    byId: null,
    citeDataForPmt: null,
    ticsearchquery: '',
    appealsearchquery: '',
    lastTicketSearchQuery: '', // Add new state to hold the last search query
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case "CITATIONDATA_BYID":
            return {
                ...state,
                byId: payload
            };
        case "CITATION_SEARCH_DATA":
            return {
                ...state,
                allCitationData: payload
            };
        case "CITATION_FORPAYMENT_DATA":
            return {
                ...state,
                citeDataForPmt: payload
            };
        case TICKET_SEARCH_QUERY:
            return {
                ...state,
                ticsearchquery: payload
            };
        case SAVE_LAST_TICKET_SEARCH_QUERY:
            return {
                ...state,
                lastTicketSearchQuery: payload
            };
        case LOAD_LAST_TICKET_SEARCH_QUERY:
            return state;
        case 'APPEAL_SEARCH_QUERY':
            return {
                ...state,
                appealsearchquery: payload
            };
            case "SET_API_RESPONSE":
                return {
                    ...state,
                    response: payload
                };
        default:
            return state;
    }
}