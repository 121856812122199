import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import Reportingsetup from "../../../../Services/main.service";
import Cookies from "js-cookie"

const FilterComponent = ({
  status,
  onChangeSearchFilter,
  onClickSearchOfficerName,
  onClickSearchPeoName,
  street,
  onClickSearchViolationCode,
  onClickSearchViolationDescriprion,
  onClickRemark,
  onClickSearchBeat,
  onClickSearchZone,
  onClickSearchShift,
  onClickSearchAgency,
  block,
  blockTo,
  onClickSearchLicenseState,
  ticketNo,
  ticketType,
}) => {
  const [showAuthToken, setShowAuthToken] = useState(false);
  const [violationCode, setViolationCode] = useState([]);
  const [officerName, setOfficerName] = useState([]);
  const [BadgeId, setBadgeId] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [zone, setZone] = useState([]);
  const [shift, setShift] = useState([]);
  const [agency, setAgency] = useState([]);
  const [remark, setRemark] = useState([]);
  const [beatTypes, setBeatTypes] = useState([]);

  const isOCMD = () => {
    if(Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" || Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476") {
      return true
    } else {
      return false
    }
  }

  // all filter selectbox api calls
  useEffect(() => {
    // violation code api call

    let passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataViolation)
      .then((Response) => {
        if (Response.data.status === 300) {
          setShowAuthToken(true);
        } else {
          setViolationCode(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    // officer detail api call

    Reportingsetup.getOfficerName().then((Response) => {
      if (Response.data.status === 300) {
        setShowAuthToken(true);
      } else {
        setOfficerName(Response.data.officer_names);
        setBadgeId(Response.data.officer_badge_ids);
      }
    });

    // Get and Display Status List option for the search drop-down
    let passDataStateList = {
      type: "CitationStatusData",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status === 300) {
          setShowAuthToken(true);
        } else {
          setStatusFilter(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    // Get and Display Zone List option for the search drop-down
    var passDataZone = {
      type: "ZoneList",
      shard: 1,
    };
    const initialzonefun = () => {
      Reportingsetup.violationCode(passDataZone)
        .then((Response) => {
          if (Response.data.status === 300) {
            setShowAuthToken(true);
          } else {
            setZone(Response.data.data[0].response);
          }
        })
        .catch((err) => {});
    };
    initialzonefun();

    // Get and Display Shift List option for the search drop-down
    var passShiftList = {
      type: "ShiftList",
      shard: 1,
    };
    const initialviolationshift = () => {
      Reportingsetup.violationCode(passShiftList)
        .then((Response) => {
          if (Response.data.status === 300) {
            setShowAuthToken(true);
          } else {
            setShift(Response.data.data[0].response);
          }
        })
        .catch((err) => {});
    };
    initialviolationshift();

    // Get and Display Agency List option for the search drop-down
    var passAgecyList = {
      type: "AgencyList",
      shard: 1,
    };
    const violationshift = () => {
      Reportingsetup.violationCode(passAgecyList)
        .then((Response) => {
          if (Response.data.status === 300) {
            setShowAuthToken(true);
          } else {
            setAgency(Response.data.data[0].response);
          }
        })
        .catch((err) => {});
    };
    violationshift();
    // Get and Display Remark List option for the search drop-down
    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    const initialremarkfun = () => {
      Reportingsetup.violationCode(passDataRemarks)
        .then((Response) => {
          if (Response.data.status === 300) {
            setShowAuthToken(true);
          } else {
            setRemark(Response.data.data[0].response);
          }
        })
        .catch((err) => {});
    };
    initialremarkfun();

    // Get and Display Beat List option for the search drop-down
    var passDataBeat = {
      type: "BeatList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataBeat)
      .then((Response) => {
        if (Response.data.status === 300) {
          setShowAuthToken(true);
        } else {
          setBeatTypes(Response.data.data[0].response);
        }
      })
      .catch((err) => {});
  }, []);

  const [streetList, setStreetList] = useState([]);

  // state select box list fetch api call
  useEffect(() => {
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setStreetList(Response.data.data[0].response);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div className="filter-main-section">
        <div className="row">
          <div className="select-drop-arrow filter_cstm1">
            <select
              value={status}
              onChange={onChangeSearchFilter}
              name="status"
              className="ar-olny"
            >
              <option value="">Select Status</option>
              {statusFilter.map((item) => (
                <option value={item.status}>{item.status}</option>
              ))}
            </select>
          </div>

          <div className="select-drop-arrow filter_cstm1">
            <select
              value={ticketType}
              onChange={onChangeSearchFilter}
              name="ticket_type"
              className="ar-olny"
            >
              <option value="">Select Type</option>
              <option value="warning">Warning</option>
            </select>
          </div>
          <div className="report-licence report-mr">
            <div>
              <input
                className="license-plate"
                name="ticket_no"
                value={ticketNo}
                onChange={(e) => onChangeSearchFilter(e)}
                type="text"
                placeholder="Search Tickets"
              />
            </div>
          </div>
          <div className="report-licence select-drop-arrow report-mr">
            <Multiselect
              name="state"
              displayValue="stateName"
              placeholder="License State"
              onRemove={onClickSearchLicenseState}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchLicenseState}
              options={streetList.map((item, index) => ({
                stateName: item.state_name,
                state: item.state_abbreviated,
              }))}
              showCheckbox
            />
          </div>
          <div className="select-drop-arrow filter_cstm2">
            <Multiselect
              name="officer"
              displayValue="officer"
              placeholder="Officer Name"
              onRemove={onClickSearchOfficerName}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchOfficerName}
              options={officerName
                .sort((a, b) =>
                  a.officer_badge_id > b.officer_badge_id ? 1 : -1
                )
                .map((item, index) => ({
                  officer:
                    item.officer_badge_id +
                    " - " +
                    item.officer_first_name +
                    " " +
                    item.officer_last_name,
                  officer_id: item.site_officer_id,
                }))}
              showCheckbox
            />
          </div>
          <div className="select-drop-arrow filter_cstm2">
            <Multiselect
              name="officer"
              displayValue="officer"
              placeholder="Peo Name"
              onRemove={onClickSearchPeoName}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchPeoName}
              options={officerName
                .sort((a, b) =>
                  a.officer_badge_id > b.officer_badge_id ? 1 : -1
                )
                .map((item, index) => ({
                  officer:
                    item.officer_badge_id + " - " + item.officer_name_concat,
                  officer_id: item.site_officer_id,
                }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm3">
            <input
              className="license-plate"
              name="street"
              value={street}
              onChange={onChangeSearchFilter}
              type="text"
              placeholder="Search Street"
            />
          </div>

          <div className="select-drop-arrow filter_cstm5">
            <Multiselect
              name="code"
              displayValue="code"
              placeholder="Violation code"
              onRemove={onClickSearchViolationCode}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchViolationCode}
              options={violationCode.map((item, index) => ({
                code: item.code,
              }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm7">
            <Multiselect
              name="description"
              displayValue="description"
              placeholder="Select Violation Description"
              onRemove={onClickSearchViolationDescriprion}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchViolationDescriprion}
              options={violationCode.map((item, index) => ({
                description: item.violation_description,
                code: item.code,
              }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm7">
            <Multiselect
              name="remark"
              displayValue="remark"
              placeholder="Remarks"
              onRemove={onClickRemark}
              onSearch={function noRefCheck() {}}
              onSelect={onClickRemark}
              options={remark.map((item, index) => ({
                remark: item.remark,
              }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm6">
            <Multiselect
              name="beat"
              displayValue="beat"
              placeholder="Select Beat"
              onRemove={onClickSearchBeat}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchBeat}
              options={beatTypes.map((item, index) => ({
                beat: item.beat_name,
              }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm9">
            <Multiselect
              name="zone"
              displayValue="zone"
              placeholder="Select Zone"
              onRemove={onClickSearchZone}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchZone}
              options={zone.map((item, index) => ({
                zone: item.zone_name,
              }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm10">
            <Multiselect
              name="shift"
              displayValue="shift"
              placeholder="Shift"
              onRemove={onClickSearchShift}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchShift}
              options={shift.map((item, index) => ({
                shift: item.shift_name,
              }))}
              showCheckbox
            />
          </div>

          <div className="select-drop-arrow filter_cstm7">
            <Multiselect
              name="agency"
              displayValue="agency"
              placeholder="agency"
              onRemove={onClickSearchAgency}
              onSearch={function noRefCheck() {}}
              onSelect={onClickSearchAgency}
              options={agency.map((item, index) => ({
                agency: item.agency_name,
              }))}
              showCheckbox
            />
          </div>

          <div className="ticket-search-radio-btn-main">
            <div className="ticket-search-radio-btn">
              <span className="search-block-filter-pl">Block Range: </span>
            </div>

            <div className="radio-ticket-serach-input">
              <input
                className="license-plate"
                name="block"
                value={block}
                onChange={onChangeSearchFilter}
                type="text"
                placeholder="From"
              />

              <input
                className="license-plate"
                name="block_to"
                value={blockTo}
                onChange={onChangeSearchFilter}
                type="text"
                placeholder="To"
              />
            </div>

            {/* <div className="check-input-filter filter_cstm12 ticket-group-by-officer-ttl">
              <div className="checkbox-inline">
                <input
                  onChange={onChangeSearchFilter}
                  type="checkbox"
                  name="group_by_officer"
                  id="check-officer"
                />
                <label htmlFor="check-officer">Group By Officer</label>
              </div>
            </div> */}
            <div className="check-input-filter filter_cstm12 ticket-group-by-officer-ttl">
              <div className="checkbox-inline">
                <input
                  onChange={onChangeSearchFilter}
                  type="checkbox"
                  name="drive_off"
                  id="check-drive"
                />
                <label htmlFor="check-drive">Drive Off</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
