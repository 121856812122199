import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import dashboardService from "../../Services/dashboard.service";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Card from "../CardElement/card";
// utils components
import { siteid } from "../../Global/site";
import { refreshTokenMiddleware, refreshTokenMiddlewareAsync } from "../customMiddelware";

import PaymentContactFormContainer from "../../utile/userForm/paymentContactFormContainer";
import ErrorModalComponent from "../../utile/modals/errorModal";
import Cookies from "js-cookie";
import mainService from "../../Services/main.service";
import OcmdCard from "../CardElement/ocmdCard";
import OcmdPaymentContactFormContainer from "../../utile/userForm/ocmdPaymentContactFormContainer";
import { encryptData } from "../CardElement/CardPaymentHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModalComponent from "../../utile/modals/confirmationModal";

const showModifyBtn = () => {
  if(
    Cookies.get("siteinfo") === '70f9c966-4985-11ef-8e44-76f8a1a040d2' || // Kansas Stage
    Cookies.get("siteinfo") === 'a95c3734-4986-11ef-98e9-2a370f00fe52' // Kansas prod
  ) {
    return true
  } else {
    return false 
  }
}

const isHPA = () => {
  if(Cookies.get("siteinfo") === '0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d' || // hartford staging
    Cookies.get("siteinfo") === '5bd20490-dc2f-11ee-bf9e-72127457a419'
  ) {
    return true
  } else {
    return false 
  }
}

const isKCMO = () => {
  if(
    Cookies.get("siteinfo") === '70f9c966-4985-11ef-8e44-76f8a1a040d2' || // Kansas Stage
    Cookies.get("siteinfo") === 'a95c3734-4986-11ef-98e9-2a370f00fe52' // Kansas prod
  ) {
    return true
  } else {
    return false 
  }
}


const MakePayment = ({uiElements}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const showSideId = siteid();
  const { auth } = useSelector((state) => state.auth);
  const [isPayment, setPayment] = useState("")
  const [isLOader, setIsLoader] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const toastId = useRef(null);

  const isOCMD = () => {
    const siteinfo = Cookies.get("siteinfo");
    const sites = [
      "da3c1178-a07e-11ee-8147-56d1ee6b2413",
      "7b4bc424-a7dc-11ed-a1e8-42e281654476",
      "46b8a5e5-c1d0-420e-b825-c09712ee8665",
      "267ccc30-1bdd-11ed-8edb-e6f96eaf3014",
      "28022506-fe2f-11ee-aa58-82967aaeaa80",
      "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4",
      "9db6bd50-3681-11ee-b4ca-ae854bfaee9a",
      "ead64c86-3681-11ee-9c41-6ef2fb394a83",
      "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d",
      "5bd20490-dc2f-11ee-bf9e-72127457a419"
    ];
    return sites.includes(siteinfo);
  };

  const paymentInfo = () => {
    setIsLoader(true)
    dashboardService.paymentGetway()
      .then((res) => {
        if (res.data.status) {
          setPayment(res.data.data.payment_gateway_requirement.required)
        }
        setIsLoader(false)
      }
      )
      .catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch)
          paymentInfo()
          setIsLoader(false)
        }
      })
  }
  useEffect(() => {
    paymentInfo()
  }, [])
  const initialInputs = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address: "",
    city: "",
    state: { value: "CA", label: "CALIFORNIA" },
    zip_code: "",
    country_code: "+1",
    card_number: "",
    expiry: "",
    cvc: "",
    country: "",
    amount: "0",
    citation_detail: [],
    ownership_type: "",
    owned_till: "",
    owned_from: "",
    sms_notification: false,
    email_notification: false,
    source: { value: "OTC Card", label: "OTC Card" }, // this is for dropdown display
    termofpayment: false
  };
  const initialErrors = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    phone_number: "",
    card_number: "",
    expiry: "",
    cvc: "",
    country: "",
    amount: "",
    citation_detail: [],
    source: "",
  };
  const cardInput = {
    card_number: "",
    expiry: "",
    cvc: "",
  };

  const [inputValue, setInputValue] = useState(initialInputs);
  const [inputError, setInputError] = useState(initialErrors);
  const [list, setList] = useState([]);

  const [cardData, setCardData] = React.useState(cardInput);
  const [cardError, setCardError] = React.useState(cardInput);

  const [isOpen, setOpen] = React.useState(false);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [displayMsg, setDisplayMsg] = React.useState("");

  const getPaymentList = () => {
    const payload = {
      "type": "PaymentSourceData",
      "shard": 1
    }

    mainService.violationCode(payload).then(result => {
      if (result.data.status) {
        let filteredList = result.data.data[0].response.filter(option =>
          option.source !== 'OTC Check' && option.source !== 'OTC Cash'
        );
        setList(filteredList)
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch)
        getPaymentList()
      }
    });
  };

  useEffect(() => { getPaymentList() }, [])

  useEffect(() => {
    if (location.state) {
      let newvalues = [];
      const data = location.state.payloadCitation;
      if (data.length > 0) {
        data !== undefined && data !== null && data.map(itm => itm.citation_detail.map((item, i) => {
          if (item.checked) {
            if (((item.status)?.toLowerCase() !== 'voidandreissue' && (item.status)?.toLowerCase() !== 'cancelled' &&
              item.status.toLowerCase() !== "uncollectable" &&
              item.status.toLowerCase() !== "excused")) {
              if ((item.ticket_type)?.toLowerCase() !== 'warning' && item.amount_due > 0) {
                const obj = {}
                obj.checked = true
                obj.citation_id = item.citation_number
                obj.plate_number = item.plate
                obj.payable_amount = item.amount_due;
                obj.id = item.citation_id
                obj.escalation_amount = item.escalated_amount;
                obj.boot_tow_fee = item.boot_tow_fee;
                obj.admin_fee = item.administrative_fee;
                obj.collection_fee = item.collection_fee;
                obj.parking_fee = item.parking_fee;
                obj.nsf_fee = item.nsf_fee;
                obj.charge_back_fee = item.charge_back_fee;
                obj.amount = item.amount;
                obj.amount_due = item.amount_due;
                obj.original_amount = item.amount_due;
                obj.category = item.category
                if (!isOCMD()) {
                  obj.payable_amount += item.service_fee
                  obj.service_fee = item.service_fee;
                }
                obj.appeal_rejected_fee = item.appeal_rejected_fee;
                obj.hearing_rejected_fee = item.hearing_rejected_fee;
                obj.discount_fee = item.discount_fee;
                newvalues.push(obj)
              }
            }
          }
          // }
        }))
        inputValue.amount = (data.map(itm => itm.select_amount + (isOCMD() ? 0 : itm.select_citation * itm.service_fee)).reduce((a, b) => a + b)).toString()
        inputValue.select_citation = newvalues.length
        inputValue.select_amount = (data.map(itm => itm.select_amount + (isOCMD() ? 0 : itm.select_citation * itm.service_fee)).reduce((a, b) => a + b)).toString()
      } else {
        data !== undefined && data !== null && data.citation_detail.map((item, i) => {
          if (item.checked) {
            if (((item.status)?.toLowerCase() !== 'voidandreissue' && (item.status)?.toLowerCase() !== 'cancelled' &&
              item.status.toLowerCase() !== "uncollectable" &&
              item.status.toLowerCase() !== "excused")) {
              if ((item.ticket_type)?.toLowerCase() !== 'warning' && item.amount_due > 0) {
                const obj = {}
                obj.checked = true
                obj.citation_id = item.citation_number
                obj.plate_number = item.plate
                obj.payable_amount = item.amount_due;
                obj.id = item.citation_id
                obj.escalation_amount = item.escalated_amount;
                obj.boot_tow_fee = item.boot_tow_fee;
                obj.admin_fee = item.administrative_fee;
                obj.collection_fee = item.collection_fee;
                obj.parking_fee = item.parking_fee;
                obj.nsf_fee = item.nsf_fee;
                obj.charge_back_fee = item.charge_back_fee;
                obj.amount = item.amount;
                obj.amount_due = item.amount_due;
                obj.original_amount = item.amount_due;
                obj.category = item.category
                if (!isOCMD()) {
                  obj.payable_amount += data.service_fee
                  obj.service_fee = data.service_fee
                }
                obj.appeal_rejected_fee = item.appeal_rejected_fee;
                obj.hearing_rejected_fee = item.hearing_rejected_fee;
                obj.discount_fee = item.discount_fee;
                newvalues.push(obj)
              }
            }
          }
          // }
        });
        inputValue.amount = (data.select_amount).toString()
        inputValue.select_citation = data.select_citation || 1
        inputValue.select_amount = (data.select_amount).toString()

      }

      inputValue.citation_detail = newvalues;
      // inputValue.first_name = auth?.first_name;
      // inputValue.last_name = auth?.last_name;
      // inputValue.phone_number = auth?.phone_number;
      inputValue.city = auth?.city;
      inputValue.zip_code = auth?.zip_code;
      // inputValue.email = auth?.email;
      inputValue.address = auth?.address;
      setInputValue({ ...inputValue });
    }
  }, []);

  useEffect(() => {
    let id_site = showSideId;
    if (
      id_site.siteinfo === "46b8a5e5-c1d0-420e-b825-c09712ee8665" ||
      id_site.siteinfo === "267ccc30-1bdd-11ed-8edb-e6f96eaf3014"
    ) {
      setInputValue({
        ...inputValue,
        state: { value: "WA", label: "WASHINGTON" },
      });
    } else if (
      id_site.siteinfo === "a98dcd6a-cfc2-11ed-ad98-66070e279278" ||
      id_site.siteinfo === "33370b0e-e5fc-11ed-8b64-665980bd7c45" ||
      id_site.siteinfo === "68ea3b5c-172f-4f33-8d55-e76c301ad212" ||
      id_site.siteinfo === "7fc01b7c-c982-11ee-be9c-72ab48aba21d" ||
      id_site.siteinfo === "c6443f42-c982-11ee-b39a-bebc79a4d1a3" ||
      id_site.siteinfo === "cdb0262e-22ed-11ee-bd8f-42803422fd7d" ||
      id_site.siteinfo === "7b9fdc62-22ed-11ee-b4ca-ae854bfaee9a"
    ) {
      setInputValue({
        ...inputValue,
        state: { value: "SC", label: "SOUTH CAROLINA" },
      });
    } else if (
      id_site.siteinfo === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" ||
      id_site.siteinfo === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45" ||
      id_site.siteinfo === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
      id_site.siteinfo === "5bd20490-dc2f-11ee-bf9e-72127457a419"
    ) {
      setInputValue({
        ...inputValue,
        state: { value: "CT", label: "CONNECTICUT" },
      });
    } else if (
      id_site.siteinfo === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3" ||
      id_site.siteinfo === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8") {
      setInputValue({
        ...inputValue,
        state: { value: "MI", label: "MICHIGAN" },
      });
    } else if (
      id_site.siteinfo === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
      id_site.siteinfo === "da3c1178-a07e-11ee-8147-56d1ee6b2413") {
      setInputValue({
        ...inputValue,
        state: { value: "MD", label: 'MARYLAND' },
      });
    } else if (
      id_site.siteinfo === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" ||
      id_site.siteinfo === "ead64c86-3681-11ee-9c41-6ef2fb394a83") {
      setInputValue({
        ...inputValue,
        state: { value: "ND", label: "NORTH DAKOTA" },
      });
    } else if (
      id_site.siteinfo === "b87d467a-dbd3-11ee-be9c-72ab48aba21d" || id_site.siteinfo === "3aba432e-dbd7-11ee-845b-0602968307f0") {
      setInputValue({
        ...inputValue,
        state: { value: "ME", label: "MAINE" },
      });
    } else if (
      id_site.siteinfo === "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4" || id_site.siteinfo === "28022506-fe2f-11ee-aa58-82967aaeaa80") {
      setInputValue({
        ...inputValue,
        state: { value: "OR", label: "OREGON" },
      });
    } else if (
      id_site.siteinfo === "f3062dac-fe2e-11ee-ae3f-92040ec4c6a4" || id_site.siteinfo === "46dd302e-fe2f-11ee-aa58-82967aaeaa80" || id_site.siteinfo === "fdbcf868-3ec3-11ef-8e44-76f8a1a040d2" || id_site.siteinfo === "23697ca0-3e04-11ef-85dc-e665cc09bf86") {
      setInputValue({
        ...inputValue,
        state: { value: "TX", label: "TEXAS" },
      });
    } else if (
      id_site.siteinfo === "3e2116e8-0d26-11ef-9818-ee2c79e2244c" || id_site.siteinfo === "2b6d81e0-0d25-11ef-8e44-76f8a1a040d2") {
      setInputValue({
        ...inputValue,
        state: { value: "NC", label: "NORTH CAROLINA" },
      });
    } else if (
      id_site.siteinfo === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
      id_site.siteinfo === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c") {
      setInputValue({
        ...inputValue,
        state: { value: "MA", label: "MASSACHUSETTS" },
      });
    } else if (
      id_site.siteinfo === "cd666208-44ae-11ef-b128-de29d549d608" ||
      id_site.siteinfo === "4fa2e26e-44af-11ef-ba31-ca0a5add8b0c") {
      setInputValue({
        ...inputValue,
        state: { value: "PA", label: "PENNSYLVANIA" },
      });
    } else if (
      id_site.siteinfo === "70f9c966-4985-11ef-8e44-76f8a1a040d2" ||
      id_site.siteinfo === "a95c3734-4986-11ef-98e9-2a370f00fe52") {
      setInputValue({
        ...inputValue,
        state: { value: "MO", label: "MISSOURI" },
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isBtnLoader) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave? Your payment is being processed.";
        if (event.returnValue) {
          navigate("/ticketreporting");
        }
      } else {
        navigate("/ticketreporting");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isBtnLoader, navigate]);

  const onChangeTermCondition = ({ name, checked }) => {
    setInputValue({ ...inputValue, [name]: checked });
    if (checked) {
      setInputError({ ...inputError, [name]: "" });
    }
  };

  async function paymentcardfun(paymentData) {
    try {
      setBtnLoader(true);
      toast.loading("Please wait while we process your payment.", { icon: "⏳", style: { backgroundColor: "lightsalmon", color: "white" } });
      const res = await dashboardService.collectCPCardPayment(paymentData);
      toast.dismiss(toastId.current)
      const transactionInfo = res.data;
      const paymentStatus = res.data?.data?.status?.toLowerCase();
      if (res?.data?.status) {
        if (paymentStatus === "success") {
          navigate("/thankyou", { state: transactionInfo });
        } else {
          setDisplayMsg(`PAYMENT ${paymentStatus} : ${res.data.message}`);
          setOpen(true);
        }
      } else {
        setDisplayMsg(res.data.message);
        setOpen(true);
      }
    } catch (err) {
      if (err.response.status === 401) {
        toast.dismiss(toastId.current)
        await refreshTokenMiddlewareAsync(dispatch);
        setBtnLoader(false);
        toast.info("Please try again.");
      } else {
        toast.dismiss(toastId.current)
        setDisplayMsg("Something went wrong.");
        setOpen(true);
        setBtnLoader(false);
      }
    } finally {
      setBtnLoader(false);
    }
  }

  async function OCMDCardPayment(payload) {
    try {
      setBtnLoader(true);
      toast.loading("Please wait while we process your payment.", { icon: "⏳", style: { backgroundColor: "lightsalmon", color: "white" } });
      const res = await dashboardService.collectCPCardData(payload);
      const transactionInfo = res.data;
      const paymentStatus = res.data?.status;
      toast.dismiss(toastId.current)

      if (res.data?.status) {
        if (paymentStatus) {
          navigate("/thankyou", { state: transactionInfo });
        } else {
          setDisplayMsg(`PAYMENT ${paymentStatus} : ${res.data.message}`);
          setOpen(true);
        }
      } else {
        setDisplayMsg(res.data.message);
        setOpen(true);
      }
    } catch (err) {
      if (err.response.status === 401) {
        toast.dismiss(toastId.current)
        await refreshTokenMiddlewareAsync(dispatch);
        setBtnLoader(false);
        toast.info("Please try again.");
      } else {
        toast.dismiss(toastId.current)
        setDisplayMsg("Something went wrong.");
        setOpen(true);
        setBtnLoader(false);
      }
    } finally {
      setBtnLoader(false);
    }
  }

  async function doPayment(is_delete = false) {
    let payload = { ...inputValue };
    payload.phone_number = inputValue.phone_number !== "" ? inputValue.country_code + inputValue.phone_number : inputValue.phone_number;
    payload.card_number = cardData.card_number.replace(/[ ]+/g, "");
    payload.expiry = cardData.expiry;
    payload.cvc = cardData.cvc;
    payload.state = inputValue.state["value"];
    payload.source = inputValue.source["value"];
    delete payload.country_code;
    delete payload.select_amount;
    delete payload.select_citation;

    if (
      inputValue.ownership_type === "" ||
      inputValue.ownership_type === "Other" ||
      inputValue.ownership_type === "Disputing Party"
    ) {
      delete payload.owned_from;
      delete payload.owned_till;
    }
    if (
      !inputValue.owned_from ||
      inputValue.owned_from === ""
    ) {
      delete payload.owned_from;
    }
    if (
      !inputValue.owned_till ||
      inputValue.owned_till === ""
    ) {
      delete payload.owned_till;
    }

    if (!is_delete) {
      payload.citation_detail.map(itm => (
        delete itm.discount_fee
      ))
    }

    try {
      const { encryptedData, encryptedKey } = await encryptData(payload);

      const paymentData = {
        payment_data: encryptedData,
        key: encryptedKey,
      };
      isPayment ? await paymentcardfun(paymentData) : await OCMDCardPayment(payload)
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong. Please try again.");
      setBtnLoader(false);
    }
  }

  const handleCloseModel = () => {
    setOpen(false);
    setShowConfirmation(false)
  };

  if (location.state === null) {
    return <Navigate to="/" />;
  }
  Cookies.set("filter", true)

  const validCite = inputValue.citation_detail.filter(cite => {
    if (cite.discount_fee > 0 && cite.payable_amount > 6) {
      return cite;
    } 
  })

  const modifyAmountHandler = () => {

    if (validCite && validCite.length > 0) {
      setShowConfirmation(true)
      if (isHPA()) {
        if (validCite.length > 1) {
          setDisplayMsg(
            <>
              Citations {validCite.map(itm => itm.citation_id).join(', ')} (violation codes 11, 12, and 13) qualify for early payment discounts because they were issued within the last three days. <br /> <br />
  
              You'll receive a ${validCite[0].discount_fee} discount for each of these citations before we process your payment.<br /> <br />
  
              Would you like to proceed?
            </>)
  
        } else {
          setDisplayMsg(
            <>
              Citation {validCite.map(itm => itm.citation_id).join(', ')} (violation codes 11, 12, and 13) qualifies for early payment discounts because it was issued within the last three days. <br /> <br />
  
              You'll receive a ${validCite.map(itm => itm.discount_fee)} discount for this citation before we process your payment.<br /> <br />
  
              Would you like to proceed?
            </>)
        }
      } else if (isKCMO()) {
        if (validCite.length > 1) {
          setDisplayMsg(
            <>
              Citations {validCite.map(itm => itm.citation_id).join(', ')} qualifies for early payment discounts because it was issued within the last 1 day. <br /> <br />

              You'll receive a ${validCite[0].discount_fee} discount for this citation before we process your payment. <br /> <br />

              Would you like to proceed?
            </>)
  
        } else {
          setDisplayMsg(
            <>
              Citation {validCite.map(itm => itm.citation_id).join(', ')} qualifies for early payment discounts because it was issued within the last 1 day. <br /> <br />

              You'll receive a ${validCite[0].discount_fee} discount for this citation before we process your payment. <br /> <br />

              Would you like to proceed?
            </>)
        }
      } else {
        if (validCite.length > 1) {
          setDisplayMsg(
            <>
              Citations {validCite.map(itm => itm.citation_id).join(', ')} qualifies for early payment discounts. <br /> <br />

              You'll receive a ${validCite[0].discount_fee} discount for this citation before we process your payment. <br /> <br />

              Would you like to proceed?
            </>)
  
        } else {
          setDisplayMsg(
            <>
              Citations {validCite.map(itm => itm.citation_id).join(', ')} qualifies for early payment discounts. <br /> <br />

              You'll receive a ${validCite[0].discount_fee} discount for this citation before we process your payment. <br /> <br />

              Would you like to proceed?
            </>)
        }
      }
    } else {
      setOpen(true);
      setDisplayMsg('Citation does not qualify for an early payment discount.')
    }
  }

  const handleConfirm = () => {
    setShowConfirmation(false)
    validCite.map((cite, index) => {
  
      cite.payable_amount = Number(cite.payable_amount - cite.discount_fee)

      return null
    })
    doPayment(true)
  }


  return (
    <>
      <ToastContainer />
      <div className="wrapper">
        {isOCMD() ? (
          <OcmdPaymentContactFormContainer
            isBtnLoader={isBtnLoader}
            inputError={inputError}
            doPayment={doPayment}
            inputValue={inputValue}
            setInputError={setInputError}
            setInputValue={setInputValue}
            cardData={cardData}
            cardError={cardError}
            setCardError={setCardError}
            list={list}
            card={true}
            uiElements={uiElements}
            isModify={showModifyBtn()}
            modifyAmountHandler={modifyAmountHandler}
          >
            {/* <OcmdCard
              cardError={cardError}
              setCardError={setCardError}
              list={list}
              card={true}
            > */}
              <OcmdCard
                cardError={cardError}
                setCardError={setCardError}
                setCardData={setCardData}
                inputError={inputError}
                onChangeTermCondition={onChangeTermCondition}
              />
            </OcmdPaymentContactFormContainer>
          ) : (
          <PaymentContactFormContainer
            isBtnLoader={isBtnLoader}
            inputError={inputError}
            doPayment={doPayment}
            inputValue={inputValue}
            setInputError={setInputError}
            setInputValue={setInputValue}
            cardData={cardData}
            cardError={cardError}
            setCardError={setCardError}
            list={list}
            card={true}
            uiElements={uiElements}
            isModify={showModifyBtn()}
            modifyAmountHandler={modifyAmountHandler}
          >
              <Card
                cardError={cardError}
                setCardError={setCardError}
                setCardData={setCardData}
                inputError={inputError}
                onChangeTermCondition={onChangeTermCondition}
                allowTerms={true}
              />

            </PaymentContactFormContainer>
          )}

        <div
          className={`card-payment-popup modal fade ${isOpen ? "modal-backdrop show" : ""
            }`}
        >
          <ErrorModalComponent handleCloseModel={handleCloseModel} displayMsg={displayMsg} />
        </div>

        {showConfirmation && <ConfirmationModalComponent
          displayMsg={displayMsg}
          handleCloseModel={handleCloseModel}
          handleConfirm={handleConfirm}
          confirmText="Yes, Continue"
        />}

      </div>
    </>
  );
};

export default MakePayment;
