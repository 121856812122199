import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from "../../actions/message";
import dashboardService from '../../Services/dashboard.service';
import Cookies from 'js-cookie';


const ResetPassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { message } = useSelector(state => state.message);
	const { isUserOk, isLoggedIn } = useSelector((state) => state.auth);

    const [inputValues, setInputValues] = useState({
        newpassword: '',
        c_password: ''
    })
    const [inputErrors, setInputErrors] = useState({
        newpassword: '',
        c_password: ''
    })

    const onChangeInput = (e) => {
        const { value, name } = e.target
        setInputValues(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setInputErrors(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "newpassword":
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (inputValues.c_password &&  value !== inputValues.c_password) {
                        stateObj["c_password"] = "Password and Confirm Password does not match.";
                    } else {
                        stateObj["c_password"] = inputValues.c_password ? "" : inputErrors.c_password;
                    }
                    break;

                case "c_password":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (inputValues.newpassword && value !== inputValues.newpassword) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        dispatch(clearMessage());

        let exist_error = false
        if (!inputValues.newpassword) {
            exist_error = true
            inputErrors.newpassword = 'Please enter password.'
        }
        if (!inputValues.c_password) {
            exist_error = true
            inputErrors.c_password = 'Please enter confirm password.'
        }
        if (inputErrors.newpassword || inputErrors.c_password) {
            exist_error = true
        }
        setInputErrors({ ...inputErrors })

        if (!exist_error) {

            const token = Cookies.get('f_m_once')
            let payload = {
                password: inputValues.c_password
            };
            try {
                const result = await dashboardService.resetPassword(payload, token)

                if (result.data.status) {
                    navigate('/')
                } else {
                    dispatch({ type: 'SET_MESSAGE', payload: result.data.message })
                }
            } catch (error) {
                dispatch({ type: 'SET_MESSAGE', payload: error.response.data.message + ', Try again' })
                setTimeout(() => {
                    navigate('/forgot-password')
                }, 2000);
            }
        }
    }

    if (isLoggedIn) {
		return <Navigate to={'/'} />
	}

    return (
        <>
            <div className="wrapper citycitiation">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-12 login-align">
                            <div className="login-section  main-login-sec">
                                {message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}

                                <h3>Create New Password</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 form-group formgroup-main">
                                            <label htmlFor="">Password</label>
                                            <input type="password" name="newpassword" className="form-control" placeholder="password" value={inputValues.inputValues} onChange={onChangeInput} />
                                            <span className="errormessage">
                                                {inputErrors.newpassword}
                                            </span>
                                        </div>
                                        <div className="col-12 form-group formgroup-main">
                                            <label htmlFor="">Confirm Password</label>
                                            <input type="password" name="c_password" className="form-control" placeholder="confirm password" value={inputValues.c_password} onChange={onChangeInput} />
                                            <span className="errormessage">
                                                {inputErrors.c_password}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 button2 ">
                                            <button className="primary" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 login-bgImage">
                        <div className="login-cont-main">
                            <div  className="login-cont-main-sub">Nice to see you again</div>
                            <div  className="login-cont-main-title">Welcome Back</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword