import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import ConfirmationModalComponent from "../../../utile/modals/confirmationModal";

const LinkedTab = ({
  plateData,
  isLoading,
  changeName,
  showNulldata,
  noData,
  handlePayment,
  setPayloadCitationAmount,
  setPayloadCitation,
  totalOutStanding,
  setTotalOutStanding,
  citationList,
  setCitationList,
  selectCitationAmount,
  setSelectCitationAmount
}) => {

  const [showLimitedPopup, setShowLimitedPopup] = useState(false)
  const [totalSelectedCitation, setTotalSelectedCitation] = useState(0)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [totalPayableCitation,setTotalPayableCitation]=useState(0)

  // check if site is allow collection status citation to pay
const isCollectionPayable = () => {
  if (
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
    Cookies.get("siteinfo") === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45" ||
    Cookies.get("siteinfo") === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" ||
    Cookies.get("siteinfo") === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
    Cookies.get("siteinfo") === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c" ||
    Cookies.get("siteinfo") === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
    Cookies.get("siteinfo") === "5bd20490-dc2f-11ee-bf9e-72127457a419"
  ) {
    return true
  } else {
    return false
  }
}

  const checkCitationIsPayable = (itm) => {
    if (
      itm.type.toLowerCase() === "warning" ||
      itm.status.toLowerCase() === "voidandreissue" ||
      itm.status.toLowerCase() === "rescind" ||
      itm.status.toLowerCase() === "cancelled" ||
      itm.status.toLowerCase() === "hearing" ||
      itm.status.toLowerCase() === "uncollectable" ||
      itm.status.toLowerCase() === "excused" ||
      (!isCollectionPayable() && itm.status.toLowerCase() === "collection") ||
      itm.amount_due === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const initialValidFilter =  (itm) => {
    if (
      itm.type.toLowerCase() !== "warning" ||
      itm.status.toLowerCase() === "valid" ||
      (!isCollectionPayable() && itm.status.toLowerCase() === "collection") ||
      itm.amount_due > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }
  
  useEffect(() => {
    if (plateData && plateData.citations_list) {
      
      const totalAmount = plateData.citations_list.map(itm => itm.plate_citations).flat().filter((item) => initialValidFilter(item)).slice(0, 12).reduce((a,b) => a + (b.amount_due) ,0)
      // const totalAmount = plateData.citations_list.map(data => data.plate_citations.filter((item) => initialValidFilter(item)).slice(0, 12).reduce((a,b) => a + (b.amount_due) ,0)).reduce((a,b) => a + b,0)
      setTotalOutStanding(plateData.total_outstanding_balance);
      setSelectCitationAmount(totalAmount);
      const citeData = [];
      const newCiteData = [];

      let selectedCitation = 0;

      for (let i = 0; i < plateData.citations_list.length; i++) {
        if (selectedCitation < 12) {
          let data = {
            checked: true,
            lp_number: plateData.citations_list[i].plate_number,
            state: plateData.citations_list[i].plate_citations[0].vehicle_details.state,
            total_citation: plateData.citations_list[i].plate_citations.filter((item) => checkCitationIsPayable(item)).length,
            total_due_amount: plateData.citations_list[i].plate_citations.filter(item => checkCitationIsPayable(item)).reduce((a, b) => a + b.amount_due, 0),
            service_fee: plateData.citations_list[i].plate_citations[0].service_fee || 0,
            select_amount: 0,
            citation_detail: [],
            select_citation: 0,
          }

          for (let j = 0; j < plateData.citations_list[i].plate_citations.length; j++) {
            if (selectedCitation < 12) {
              if (checkCitationIsPayable(plateData.citations_list[i].plate_citations[j])) {
                selectedCitation = selectedCitation + 1;
                data.select_amount = data.select_amount + plateData.citations_list[i].plate_citations[j].amount_due;
                data.citation_detail.push({
                  checked: true,
                  status: plateData.citations_list[i].plate_citations[j].status,
                  category: plateData.citations_list[i].plate_citations[j].category,
                  type: plateData.citations_list[i].plate_citations[j].type,
                  amount: plateData.citations_list[i].plate_citations[j].fine_amount,
                  amount_due: plateData.citations_list[i].plate_citations[j].amount_due,
                  citation_number: plateData.citations_list[i].plate_citations[j].ticket_no,
                  plate: plateData.citations_list[i].plate_citations[j].lp_number,
                  citation_id: plateData.citations_list[i].plate_citations[j].id,
                  issue_date: plateData.citations_list[i].plate_citations[j].citation_issue_timestamp,
                  state: plateData.citations_list[i].plate_citations[j].vehicle_details.state,
                  violation_description: plateData.citations_list[i].plate_citations[j].violation_details.description,
                  escalated_amount: plateData.citations_list[i].plate_citations[j].escalated_amount,
                  boot_tow_fee: plateData.citations_list[i].plate_citations[j].boot_tow_fee || 0,
                  administrative_fee: plateData.citations_list[i].plate_citations[j]?.administrative_fee || 0,
                  collection_fee: plateData.citations_list[i].plate_citations[j].collection_fee || 0,
                  parking_fee: plateData.citations_list[i].plate_citations[j]?.parking_fee || 0,
                  nsf_fee: plateData.citations_list[i].plate_citations[j].nsf_fee || 0,
                  charge_back_fee: plateData.citations_list[i].plate_citations[j].charge_back_fee || 0,
                  service_fee: plateData.citations_list[i].plate_citations[j].service_fee || 0,
                  appeal_rejected_fee: plateData.citations_list[i].plate_citations[j].appeal_rejected_fee || 0,
                  hearing_rejected_fee: plateData.citations_list[i].plate_citations[j].hearing_rejected_fee || 0,
                  violation:
                    plateData.citations_list[i].plate_citations[j].violation_details.code,
                  discount_fee: plateData.citations_list[i].plate_citations[j].discount_fee || 0,
                })
                data.select_citation = data.select_citation + 1;
              }
            }
          }
          newCiteData.push(data)
        }
      }

      plateData.citations_list.map((item) => {
        if(item.plate_citations.filter((data) => checkCitationIsPayable(data)).slice(0, 12).length > 0) {
          let select_amount = item.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, 12).reduce((a,b) => a + (b.amount_due) ,0)
          citeData.push(
            {
            checked:true,
            lp_number: item.plate_number,
            state: item.plate_citations[0].vehicle_details.state,
            total_citation: item.plate_citations.filter((item) => checkCitationIsPayable(item)).slice(0, 12).length,
            total_due_amount: item.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, 12).reduce((a,b) => a + b.amount_due ,0),
            select_amount: select_amount,
            select_citation: item.plate_citations.filter((item) => checkCitationIsPayable(item)).slice(0, 12).length,
            service_fee: item.plate_citations[0].service_fee || 0,
            citation_detail: item.plate_citations.filter((item) => checkCitationIsPayable(item)).slice(0, 12).map((itm) => ({
              checked: true,
              status: itm.status,
              category: itm.category,
              type: itm.type,
              amount: itm.fine_amount,
              amount_due: itm.amount_due,
              citation_number: itm.ticket_no,
              plate: itm.lp_number,
              citation_id: itm.id,
              issue_date: itm.citation_issue_timestamp,
              state: itm.vehicle_details.state,
              violation_description: itm.violation_details.description,
              escalated_amount: itm.escalated_amount,
              boot_tow_fee: itm.boot_tow_fee || 0,
              administrative_fee: itm?.administrative_fee || 0,
              collection_fee: itm.collection_fee || 0,
              parking_fee: itm?.parking_fee || 0,
              nsf_fee: itm.nsf_fee || 0,
              charge_back_fee: itm.charge_back_fee || 0,
              service_fee: itm.service_fee || 0,
              appeal_rejected_fee: itm.appeal_rejected_fee || 0,
              hearing_rejected_fee: itm.hearing_rejected_fee || 0,
              discount_fee: itm.discount_fee || 0,
              violation:
                itm.violation_details.code,
                discount_fee: itm.discount_fee || 0,
            })),
          })
          return null;
        } else {
          return null
        }
      });

      setTotalSelectedCitation(selectedCitation)
      setCitationList(newCiteData)
    }
  },[plateData])

  const handleCompletePayment = (e) => {

    const totalCitation =  plateData.citations_list.reduce(
      (total, citation) => {
        return (
          total +
          (citation?.plate_citations.length)
        );
      },
      0
    );

    setTotalPayableCitation(totalCitation)

    if (totalSelectedCitation === 12 && totalSelectedCitation < totalCitation) {
      setShowWarningModal(true)
    } else {
      setPayloadCitation(citationList);
  
      setPayloadCitationAmount(citationList);
      if (citationList[0].citation_detail.length > 0) {
        handlePayment(e);
      }
    }
  };

  const handleSingleSelect = (e,plate,citation,) => {
    let checked = e.target.checked;
    let selectedPlate = [...citationList];

    const selected = citationList.reduce((a, b) => a + b.citation_detail.length, 0);

    if (checked) {
      if (selected < 12) {
        const citePayload = {
          checked: true,
          status: citation.status,
          category: citation.category,
          type: citation.type,
          amount: citation.fine_amount,
          amount_due: citation.amount_due,
          citation_number: citation.ticket_no,
          plate: citation.lp_number,
          citation_id: citation.id,
          issue_date: citation.citation_issue_timestamp,
          state: citation.vehicle_details.state,
          violation_description: citation.violation_details.description,
          escalated_amount: citation.escalated_amount,
          boot_tow_fee: citation.boot_tow_fee || 0,
          administrative_fee: citation?.administrative_fee || 0,
          collection_fee: citation.collection_fee || 0,
          parking_fee: citation?.parking_fee || 0,
          nsf_fee: citation.nsf_fee || 0,
          charge_back_fee: citation.charge_back_fee || 0,
          service_fee: citation.service_fee || 0,
          appeal_rejected_fee: citation.appeal_rejected_fee || 0,
          hearing_rejected_fee: citation.hearing_rejected_fee || 0,
          start_time: citation.citation_issue_timestamp,
          violation: citation.violation_details.code,
          discount_fee: citation.discount_fee || 0,
        }
        let select_amount = citation.amount_due;
        const ticketPayload = {
          checked: true,
          lp_number: plate.plate_number,
          state: plate.plate_citations[0].vehicle_details.state,
          total_citation: plate.plate_citations.filter((item) => checkCitationIsPayable(item)).slice(0, 12).length,
          total_due_amount: plate.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, 12).reduce((a, b) => a + (b.amount_due), 0),
          select_amount: select_amount,
          select_citation: 1,
          service_fee: citation.service_fee || 0,
          citation_detail: [citePayload],
        }

        const availCitation = selectedPlate.find(itm => itm.lp_number === plate.plate_number)

        if (selectedPlate.find(itm => itm.lp_number === plate.plate_number)) {
          availCitation.citation_detail.push(citePayload);
          availCitation.select_amount = availCitation.select_amount + (citation.amount_due)
          availCitation.select_citation = availCitation.select_citation + 1
        } else {
          selectedPlate.push(ticketPayload)
        }
        setSelectCitationAmount(selectCitationAmount + (citation.amount_due))
        setTotalSelectedCitation(totalSelectedCitation + 1)
      } else {
        setShowLimitedPopup(true)
      }
    } else {
      const removeIndex = selectedPlate.find(itm => itm.lp_number === plate.plate_number).citation_detail.findIndex(cite => cite.citation_number === citation.ticket_no)
      const removePlateIndex = selectedPlate.findIndex(itm => itm.lp_number === plate.plate_number)
      selectedPlate.find(itm => itm.lp_number === plate.plate_number).citation_detail.splice(removeIndex,1);
      const availCitation = selectedPlate.find(itm => itm.lp_number === plate.plate_number)
      if(availCitation.citation_detail && availCitation.citation_detail.length === 0) {
        selectedPlate.splice(removePlateIndex,1);
      } else {
        selectedPlate.find(itm => itm.lp_number === plate.plate_number).select_amount = selectedPlate.find(itm => itm.lp_number === plate.plate_number).select_amount - ((citation.amount_due) )
        selectedPlate.find(itm => itm.lp_number === plate.plate_number).select_citation = selectedPlate.find(itm => itm.lp_number === plate.plate_number).select_citation - 1
      }
      setSelectCitationAmount(selectCitationAmount - ((citation.amount_due)) )
      setTotalSelectedCitation(totalSelectedCitation - 1)
    }
    setCitationList(selectedPlate)
  }

  const handleSinglePlateSelect = (e,plateList) => {
    let checked = e.target.checked;
    let citations = [...citationList];

    if (checked) {
      if (totalSelectedCitation < 12) {

        let plateTotal = totalSelectedCitation
        if (citations.find(itm => itm.lp_number === plateList.plate_number)) {
          plateTotal = totalSelectedCitation - citations.find(itm => itm.lp_number === plateList.plate_number).citation_detail.length
        const index = citations.findIndex(itm => itm.lp_number === plateList.plate_number)
        citations.splice(index, 1)
        }
        
      const spliceCitation = 12 - plateTotal
        
      const payload = {
        checked: true,
        lp_number: plateList.plate_number,
        state: plateList.plate_citations[0].vehicle_details.state,
        total_citation: plateList.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, spliceCitation).length,
        total_due_amount: plateList.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, spliceCitation).reduce((a, b) => a + b.amount_due, 0),
        select_amount: plateList.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, spliceCitation).reduce((a, b) => a + (b.amount_due), 0),
        select_citation: plateList.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, spliceCitation).length,
        service_fee: plateList.plate_citations[0].service_fee || 0,
        citation_detail: plateList.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, spliceCitation).map((itm) => ({
          checked: true,
          status: itm.status,
          category: itm.category,
          type: itm.type,
          amount: itm.fine_amount,
          amount_due: itm.amount_due,
          citation_number: itm.ticket_no,
          plate: itm.lp_number,
          citation_id: itm.id,
          issue_date: itm.citation_issue_timestamp,
          state: itm.vehicle_details.state,
          violation_description: itm.violation_details.description,
          escalated_amount: itm.escalated_amount,
          boot_tow_fee: itm.boot_tow_fee || 0,
          administrative_fee: itm?.administrative_fee || 0,
          collection_fee: itm.collection_fee || 0,
          parking_fee: itm?.parking_fee || 0,
          nsf_fee: itm.nsf_fee || 0,
          charge_back_fee: itm.charge_back_fee || 0,
          service_fee: itm.service_fee || 0,
          appeal_rejected_fee: itm.appeal_rejected_fee || 0,
          hearing_rejected_fee: itm.hearing_rejected_fee || 0,
          discount_fee: itm.discount_fee || 0,
          start_time: itm.citation_issue_timestamp,
          violation:
            itm.violation_details.code,
            discount_fee: itm.discount_fee || 0,
        }))
      }

        citations.push(payload);
        setSelectCitationAmount(citations.reduce((a, b) => a + b.select_amount, 0))
        setTotalSelectedCitation(citations.reduce((a, b) => a + b.select_citation, 0))
        setCitationList(citations)
      } else {
        setShowLimitedPopup(true)
      }
    } else {
      const selectedLength = citations.find(itm => itm.lp_number === plateList.plate_number).select_citation;

      const fineAmount = citations.find(itm => itm.lp_number === plateList.plate_number).citation_detail.filter(item => checkCitationIsPayable(item)).slice(0, 12).reduce((a,b) => a + (b.amount_due) ,0);
      setSelectCitationAmount(selectCitationAmount - fineAmount)
      const removeIndex = citations.findIndex(itm => itm.lp_number === plateList.plate_number);
      citations.splice(removeIndex, 1)

      setTotalSelectedCitation(totalSelectedCitation - selectedLength)
      setCitationList(citations)
    }
  }

  const closeModel = () => {
    setShowLimitedPopup(false);
    setShowWarningModal(false);
  }

  const handleConfirmCitation = (e) => {
    setPayloadCitation(citationList);
  
    setPayloadCitationAmount(citationList);
    if (citationList[0].citation_detail.length > 0) {
      handlePayment(e);
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="col-12 text-center">
          <div className="spinner-border"></div>
        </div>
      ) : showNulldata ? (
        <div className="col-12 text-center">
          <div className="text-center no-data">{noData}</div>
        </div>
      ) : (
        <div className="container-fluid row">
          <div className="container-fluid back-link1 cstm-paymentlist-row">
            <div className="row cstm-table-appeallist">
              <div className="col-12">
                <div className="ctsm-table-scroll">
                  <div className="row-align displayResult displycitysearchHeader ">
                    <div className="col-12 ">
                      <div
                        className="row displayResult-align"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="cstm-lt-result1 col-2">
                          <p>Linked Plates</p>
                          <h5>
                            {plateData &&
                              plateData.citations_list &&
                              plateData.citations_list.length > 0 &&
                              plateData.citations_list
                                .map((ele, index) => {
                                  return ele.plate_number;
                                })
                                .join(", ")}
                          </h5>
                        </div>

                        <div className="cstm-lt-result1 col-2">
                          <p>Total {changeName}</p>
                          <h5>
                            {plateData.citations_list &&
                              plateData.citations_list.reduce(
                                (total, citation) => {
                                  return (
                                    total +
                                    (citation?.plate_citations.length )
                                  );
                                },
                                0
                              )}
                          </h5>
                        </div>
                        <div className="cstm-lt-result1 col-2">
                          <p>Selected {changeName}</p>
                          <h5>
                            {citationList && citationList.length > 0 ?
                            citationList.reduce((a,b) => a + b.citation_detail.length,0) : 0
                            }
                          </h5>
                        </div>
                        <div className="cstm-lt-result2 col-2">
                          <p>Total Outstanding Balance</p>
                          <h5>
                            $
                            {totalOutStanding &&
                              plateData &&
                              convertDecimal(
                                totalOutStanding
                              )}
                          </h5>
                        </div>
                        <div className="cstm-lt-result2 col-2">
                          <p>Selected Outstanding Balance</p>
                          <h5>
                            $
                            {selectCitationAmount &&
                              plateData &&
                              convertDecimal(
                                selectCitationAmount
                              )}
                          </h5>
                        </div>
                        {selectCitationAmount > 0 && (
                          <div className="cstm-lt-result1 col-2">
                            <button
                              className="pay-btn"
                              onClick={(e) => handleCompletePayment(e)}
                              data-modal="pay-modal"
                            >
                              Pay
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {plateData &&
                      plateData.citations_list &&
                      plateData.citations_list.length > 0 &&
                      plateData.citations_list.map((ele, index) => {
                        return (
                          <div className="table-overflow ticketreport-tbl" id={index}>
                              <table className="table-heading" id={index}>
                                <thead className="cstm-mycitation-table-header">
                                  <tr>
                                    <th>Select All</th>
                                    <th>Plate Number</th>
                                    <th></th>
                                    <th></th>
                                    <th>Total {changeName}</th>
                                    <th></th>
                                    <th></th>
                                    <th>Outstanding Balance</th>
                                    <th>Selected Outstanding Balance</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr className="cstm-ciationtable-header-data">
                                    <td>
                                      <input 
                                        type="checkbox" 
                                        defaultChecked={true}
                                        checked={citationList && citationList.length > 0 && citationList.find(itm => itm.lp_number === ele.plate_number) && citationList.find(itm => itm.lp_number === ele.plate_number).citation_detail.length === ele.plate_citations.filter(item => checkCitationIsPayable(item)).slice(0, 12).length ? true : false}
                                        onChange={(e) => handleSinglePlateSelect(e,ele)}
                                        disabled={!ele.plate_citations.find(item => checkCitationIsPayable(item))}
                                      />
                                    </td>
                                    <td>{ele.plate_number}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{ele.plate_citations.length}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      ${convertDecimal(ele.total_amount_due)}
                                    </td>
                                    <td>
                                      ${convertDecimal(citationList && citationList.length > 0 && citationList.find((item) => item.lp_number === ele.plate_number) ? citationList.find((item) => item.lp_number === ele.plate_number).citation_detail.filter(item => checkCitationIsPayable(item)).slice(0, 12).reduce((a,b) => a + (b.amount) + ( b.boot_tow_fee) + (b.collection_fee) + (b.charge_back_fee) + (b.nsf_fee) + (b.category !=="invoice" ? b.administrative_fee : 0) + (b.category !=="invoice" ? b.parking_fee : 0) ,0) : 0)}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </thead>
                                <thead className="cstm-citation-main-header">
                                  <tr className="cstm-ciationtable-header-data">
                                    <th>Pay</th>
                                    <th>{changeName}#</th>
                                    <th>Issue date</th>
                                    <th>Amount Due</th>
                                    <th>Amount</th>
                                    <th>State</th>
                                    <th>Status</th>
                                    <th>Violation</th>
                                    <th>Ticket type</th>
                                    <th>Boot tow fee</th>
                                    <th>Admin fee</th>
                                    <th>Collection fee</th>
                                    <th>Parking fee</th>
                                    <th>NSF fee</th>
                                    <th>Charge back fee</th>
                                    <th>Appeal rejected fee</th>
                                    <th>Hearing rejected fee</th>
                                  </tr>
                                </thead>
                                {ele.plate_citations.map((res, index) => {
                                  return (
                                    <tbody className="cstm-citation-main-data" id={index}>
                                      <tr className={!checkCitationIsPayable(res) ? "cstm-sub-item-main" : ""}>
                                        <>
                                          <td>
                                            <input 
                                              type="checkbox" 
                                              defaultChecked={checkCitationIsPayable(res)} 
                                              disabled={!checkCitationIsPayable(res)}
                                              checked={
                                                citationList && citationList.length > 0 && citationList.find((item) => item.lp_number === ele.plate_number) && citationList.find(itm => itm.lp_number === ele.plate_number).citation_detail.find(cite => cite.citation_number === res.ticket_no) ? true : false
                                              }
                                              onChange={(e) => handleSingleSelect(e,ele,res)}
                                            />
                                          </td>
                                          <td>{res.ticket_no}</td>
                                          {res.citation_issue_timestamp !==
                                            undefined &&
                                          res.citation_issue_timestamp !==
                                            null &&
                                          res.citation_issue_timestamp !==
                                            "" ? (
                                            <td>
                                              {moment(
                                                res.citation_issue_timestamp
                                              )
                                                .utc()
                                                .format(
                                                  "DD MMM, YYYY HH:mm:ss"
                                                )}
                                            </td>
                                          ) : (
                                            <th>-</th>
                                          )}
                                          {res.amount_due !== undefined &&
                                          res.amount_due !== null &&
                                          res.amount_due !== "" ? (
                                            <td>
                                              ${convertDecimal(res.amount_due)}
                                            </td>
                                          ) : (
                                            <th>$0.00</th>
                                          )}
                                          {res.fine_amount !== undefined &&
                                          res.fine_amount !== null &&
                                          res.fine_amount !== "" ? (
                                            <td>
                                              ${convertDecimal(res.fine_amount)}
                                            </td>
                                          ) : (
                                            <th>$0.00</th>
                                          )}
                                          {res.vehicle_details.state !==
                                            undefined &&
                                          res.vehicle_details.state !== null &&
                                          res.vehicle_details.state !== "" ? (
                                            <td>{res.vehicle_details.state}</td>
                                          ) : (
                                            <td>-</td>
                                          )}
                                          {res.status !== undefined &&
                                          res.status !== null &&
                                          res.status !== "" ? (
                                            <td>{res.status}</td>
                                          ) : (
                                            <td>-</td>
                                          )}
                                          {res.violation_details.description !==
                                            undefined &&
                                          res.violation_details.description !==
                                            null &&
                                          res.violation_details.description !==
                                            "" ? (
                                            <td>
                                              {
                                                res.violation_details
                                                  .description
                                              }
                                            </td>
                                          ) : (
                                            <td>-</td>
                                          )}
                                          {res.type !== undefined &&
                                          res.type !== null &&
                                          res.type !== "" ? (
                                            <td>{res.type}</td>
                                          ) : (
                                            <td>-</td>
                                          )}
                                          <td>${convertDecimal(res.boot_tow_fee ? res.boot_tow_fee : 0)}</td>
                                          <td>${convertDecimal(res.administrative_fee && res.administrative_fee ? res.administrative_fee : 0)}</td>
                                          <td>${convertDecimal(res.collection_fee ? res.collection_fee : 0)}</td>
                                          <td>${convertDecimal(res.parking_fee && res.parking_fee ? res.parking_fee : 0)}</td>
                                          <td>${convertDecimal(res.nsf_fee ? res.nsf_fee : 0)}</td>
                                          <td>${convertDecimal(res.charge_back_fee ? res.charge_back_fee : 0)}</td>
                                          <td>${convertDecimal(res.appeal_rejected_fee ? res.appeal_rejected_fee : 0)}</td>
                                          <td>${convertDecimal(res.hearing_rejected_fee ? res.hearing_rejected_fee : 0)}</td>
                                        </>
                                      </tr>
                                    </tbody>
                                  );
                                })}
                              </table>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showLimitedPopup && (
        <div
          className="modal-dialog modal-dialog-centered cstm-popup-tn cstm-notes-citations-search"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                className="cstm-payment-close"
                onClick={closeModel}
              >
                ×
              </button>
              <div className="row">
                <div className="col-12 remark-erroe-main">
                  <p className="remark-errormessage">
                    You may only choose a maximum of 12 citations in total. To
                    select an additional citation, you must first deselect one
                    of the currently selected citations.
                  </p>
                </div>
              </div>
            </div>
           </div>
          </div>
      )}
      
        {showWarningModal && (
            <ConfirmationModalComponent
              displayMsg={
                <p>
                  <b>Alert:</b> <br />

                  Total outstanding citations : <b>{totalPayableCitation}</b>, totaling : <b>${convertDecimal(totalOutStanding)}</b> <br />
                  Citations selected to pay : <b>{totalSelectedCitation}</b>, totaling : <b>${convertDecimal(selectCitationAmount)}</b> <br /> <br />

                  <b>Note:</b> The inquired account has {totalPayableCitation} citations eligible for payment, which exceeds the maximum number of {totalSelectedCitation} citations that can be processed in a single transaction. We have auto-selected the 12 oldest payable citations, however if you wish to customize your selection, click the cancel button below and select up to {totalSelectedCitation} citations in the result screen prior to clicking pay. <br /> <br />

                  Be advised that after payment for the selected citations is complete, you will still have outstanding citations that must be paid with an additional transaction. 
                </p>
              }
              handleCloseModel={closeModel}
              handleConfirm={(e) => handleConfirmCitation(e)}
            />
          )}
    </>
  );
};

export default LinkedTab;
