export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_OTP_SUCCESS = "USER_OTP_SUCCESS";
export const USER_OTP_FAIL = "USER_OTP_FAIL";
export const REQUEST_OTP = "REQUEST_OTP";
export const CLEAROUT_USER = "CLEAROUT_USER";
export const VERIFY_OTP = "VERIFY_OTP";
export const TICKET_SEARCH_QUERY = "TICKET_SEARCH_QUERY";
export const SAVE_LAST_TICKET_SEARCH_QUERY = 'SAVE_LAST_TICKET_SEARCH_QUERY';
export const LOAD_LAST_TICKET_SEARCH_QUERY = 'LOAD_LAST_TICKET_SEARCH_QUERY';
export const AGENCY_STATE = "AGENCY_STATE";
export const SITE_UPDATE_INFO = "SITE_UPDATE_INFO";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const PERMIT_STEP_FORM = 'PERMIT_STEP_FORM';
export const PERMIT_DATA = 'PERMIT_DATA';
export const PERMIT_QUANTITY_DATA = 'PERMIT_QUANTITY_DATA';
export const PERMIT_LAYOUT_DATA = 'PERMIT_LAYOUT_DATA';
export const PERMIT_USER_INFO = 'PERMIT_USER_INFO';
export const PERMIT_LOCATION_DATA = 'PERMIT_LOCATION_DATA';