import { useEffect, useState } from "react";
import { state } from "../../../Common/dropDownOptions";
import dashboardService from "../../../../Services/dashboard.service";
import { useSelector } from "react-redux";

const PermitStepAddressDetails = ({
  stepField,
  constructValidationRules,
  control,
  Controller,
  validation,
  clearErrors,
  getValues,
  setValue,
  setError
}) => {
  const permitLocationData = useSelector(
    state => state.permitReducer.permitLocationData,
  );

  const [buttonText, setButtonText] = useState('Validate Address');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const key_name = stepField.filter(itm => itm.name === 'state')[0].key_name
    setValue(key_name, 'CT')
  },[])

  function isLocationInPermitZone(point, zoneArea) {
    const [x, y] = point;
    let inside = false;

    for (let i = 0, j = zoneArea.length - 1; i < zoneArea.length; j = i++) {
      const [xi, yi] = zoneArea[i];
      const [xj, yj] = zoneArea[j];

      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }

    return inside;
  }

  const handleValidateAddress = async () => {
    try {
      const payloadData = {
        address: {
          regionCode: "US",
          postalCode: getValues('zip_code'),
          locality: getValues('city'),
          addressLines: [],
        },
        enableUspsCass: true,
      }
      payloadData.address.addressLines.push(getValues("address"));

      const response = await dashboardService.validateAddress(payloadData);
      const { verdict, address, geocode } = response.data?.result;

      if (verdict?.validationGranularity === "OTHER" || verdict?.hasUnconfirmedComponents) {
        //check address component and suggest changes to user
        const unconfirmedAddressComponents = address?.unconfirmedComponentTypes;
        const missingAddressComponents = address?.missingComponentTypes;
        const allBadAddressComponents = [...unconfirmedAddressComponents, ...missingAddressComponents];
        allBadAddressComponents.forEach(addressComponent => {
          //show error message to user
          switch (addressComponent) {
            case 'street_number':
              setError('address',{type: 'manual', message: 'Add or fix street number'});
              break;
            case 'administrative_area_level_1':
              setError('address',{type: 'manual', message: 'Add or fix district'});
              break;
            case 'postal_code':
              setError('zip_code',{type: 'manual', message: 'Add or fix postal code'});
              break;
            case 'route':
              setError('address',{type: 'manual', message: 'Add or fix route'});
              break;
            case 'locality':
              setError('city',{type: 'manual', message: 'Add or fix city'});
              break;
            default:
              break;
          }
        });
      } else {
        //check if permitLocationData is same as geocode
        console.log(permitLocationData,'permit location data')
        const permitZone = permitLocationData?.multi_point_location?.coordinates;
        const userLocation = geocode?.location
        const pointArray = [userLocation?.latitude, userLocation?.longitude];

        if (permitZone.length > 0 && userLocation) {
          const isLocationInZone = isLocationInPermitZone(pointArray, permitZone);
          if (!isLocationInZone) {
            setError('city', {type: 'manual', message: 'Address is not in permit zone'} );
            return;
          }
        }
        setButtonText('Address Verified');
        setIsButtonDisabled(true);
      }
    } catch (error) {
      console.log(error,'error')
      // setLoading(false)
      setError('address', {type: 'manual', message: 'Something went wrong'});
    }
  }


  return (
    <>
      <form>
        {stepField.filter(itm => itm.name === 'address') &&
          stepField.filter(itm => itm.name === 'address').map(key => <div className="row">
            <div className="col-12 form-group formgroup-main">
              <Controller
                name={key.key_name}
                control={control}
                rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                  <>
                    <label htmlFor="">{key.repr}{' '}
                      {validation && validation.required && '*'}</label>
                    <input
                      type="text"
                      name={key.key_name}
                      className="form-control cstm-add-capital-ttl"
                      placeholder={key.repr}
                      value={field.value}
                      onChange={field.onChange}
                    />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                  </>
                )}
              />
            </div>
          </div>)
        }
          <div className="row">
          {stepField && stepField.map(key =>
            <>
            {key.name === "city" && 
              <div className="col-4 form-group formgroup-main">
              <Controller
                name={key.key_name}
                control={control}
                rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                  <>
                    <label htmlFor="">
                      {key.repr}{' '}
                      {validation && validation.required && '*'}
                    </label>
                    <input
                      type="text"
                      name={key.key_name}
                      className="form-control cstm-add-capital-ttl"
                      placeholder={key.repr}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <span className="errormessage">{fieldState?.error?.message}</span>
                  </>
                )}
                />
              </div>
            }
            {key.name === "state" &&
              <div className="col-4 form-group formgroup-main">
              <Controller
                name={key.key_name}
                control={control}
                rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                      <>
                    <label htmlFor="">
                      {key.repr}{' '}
                      {validation && validation.required && '*'}
                    </label>
                    <select
                      name="state"
                      onChange={field.onChange}
                      className="form-control cstm-add-capital-ttl cstm-slc-only">
                      {state.map(item =>
                        item.value === field.value ? (
                          <option value={item.value} selected>
                            {item.label}
                          </option>
                        ) : (
                          <option value={item.value}>{item.label}</option>
                        ),
                      )}
                    </select>
                      <span className="errormessage">{fieldState?.error?.message}</span>
                      </>
                )}
                />
                </div>
              }
            {key.name === "zip_code" &&
              <div className="col-4 form-group formgroup-main">
              <Controller
                name={key.key_name}
                control={control}
                rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                  <>
                    <label htmlFor="">
                      {key.repr}{' '}
                      {validation && validation.required && '*'}
                    </label>
                    <input
                      type="text"
                      name={key.key_name}
                      className="form-control cstm-add-capital-ttl"
                      placeholder={key.repr}
                      value={field.value}
                      onChange={field.onChange}
                    />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                      </>
                )}
              />
                  </div>
              }
            </>
          )}
        </div>
        <button
          className={isButtonDisabled ? 'permit-question-btn-right-disabled' : 'permit-question-btn-right cstm-permit-btn'}
          type='button'
          onClick={handleValidateAddress}
          disabled={isButtonDisabled}
        >
          {buttonText}
        </button>
      </form>
    </>
  )
}

export default PermitStepAddressDetails;