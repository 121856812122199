import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from '../customMiddelware'
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { agencydatasave } from "../../actions/auth";


const AgenciesList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isLoggedIn, isAgency } = useSelector(state => state.auth)

    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        agencieslistfun()
        setLoader(true)
        function agencieslistfun() {
            mainService.agencieslistAPI().then(res => {
                if (res.data.status) {
                    setLoader(false)
                    setData(res.data.data.agency_map)
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    agencieslistfun()
                }
            })
        }
    }, [])

    const getselectAgency = (e, value) => {
        e.preventDefault()
        dispatch(agencydatasave(value))
        navigate('/site-list')
    }

//     if (isLoggedIn && !isAgency) {
//         return <Navigate to={'/agency'} />
//     }
//     if (isLoggedIn && isAgency) {
//         return <Navigate to={'/dashboard'} />
//     }

    return (
      <>
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 left-panel-side right-panel cstm-right-panel">
          <div className="appeal-report-heading">
            <h4>Agency List Log</h4>
          </div>
          <div className="tab-pane active activity-tab-pane">
            <div className="text-center">
              {loader ? (
                <div className="agency-loader-cstm">
                  <div className="spinner-border"></div>
                </div>
              ) : data === null || data?.length === 0 ? (
                <div className="cstm-no-record-found">{"No Record Found"}</div>
              ) : (
                <div className="ctsm-table-scroll">
                  <div className="table-overflow">
                    <table className="table-heading table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Agency Name</th>
                          <th>Added Date</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loader &&
                          (data || []).map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <Link
                                  onClick={(e) =>
                                    getselectAgency(e, item.agency_data)
                                  }
                                >
                                  {item.agency_data.agency_name || "-"}
                                </Link>
                              </td>
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
}

export default AgenciesList
