import downloadarrow from "../../Images/down-arrow.svg";
import { useEffect, useState } from 'react'
import jsPDF from "jspdf";
import ReactToPrint from 'react-to-print';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';


const imageWidth = 100;
const imageHeight = 300;

const pageStyle=`@page { size: ${imageWidth}mm ${imageHeight}mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }`




const PrintPdfCsvExcelDownload = ({ tableId, fileName, pageSize, showPrint=true,donwloadPDFFile,donwloadExcelFile,donwloadCsvFile }) => {
  const [downloadOption, setDownloadOption] = useState(false)


  const onClickDownloadButton = (e) => {
    e.preventDefault();
    setDownloadOption(!downloadOption)
  }

  function removeExtraRow(elem) {
    var exportElem = elem.cloneNode(true),
      elementsToRemove = exportElem.querySelectorAll('.row-rmv');

    for (var i = 0; i <= elementsToRemove.length; i++) {
      var td = elementsToRemove[i];
      if (td) {
        td.remove()
      }
    }
    return exportElem
  }
  // Download PDF file 
  const donwloadPDFFiles = (e) => {
    e.preventDefault();

    var pdfTable = document.getElementById(tableId);
    //html to pdf format
    if (pdfTable) {

      var exportElem = removeExtraRow(pdfTable)

      var html = htmlToPdfmake(exportElem.innerHTML);
      const documentDefinition = { content: html, pageSize: pageSize };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(documentDefinition).download(`${fileName}.pdf`)
    }
  }
  const donwloadCsvFiles = (e) => {
    e.preventDefault();

    var csv = [];
    var rows = document.querySelectorAll("table tr");

    if (rows.length) {
      for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++)
          // if (!(cols[j].classList.contains('row-rmv'))) {
          row.push(cols[j].innerText);
        csv.push(row.join(","));
        // }
      }
      downloadCSV(csv.join("\n"), `${fileName}.csv`);
    }
  }

  const downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  }

  const donwloadExcelFiles = (e) => {
    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>{table}</body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        })
      }

    var htmltbl = document.getElementById(tableId)
    if (htmltbl) {
      var exportElem = removeExtraRow(htmltbl)
      var ctx = {
        worksheet: fileName,
        table: exportElem.innerHTML
      };
      var link = document.createElement("a");
      link.download = `${fileName}.xls`
      link.href = uri + base64(format(template, ctx))
      link.target = "_blank"
      link.click();
    }
  }


  return (
    <>
      <div className="cstm-ticket-manage-download">
        <div>
          {showPrint && <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => {
              return <button className="licence-download cstm-licene-download report-mr">Print</button>;
            }}
            content={() => document.getElementById(tableId).children[0]}
          />}

          <div onClick={onClickDownloadButton} className="btn cstm_rev_down_btn">
            Download
            <img src={downloadarrow} alt="download" />
            {downloadOption ?
              <>
                <div className="cstm-download-icon">
                  <div onClick={donwloadPDFFile ? donwloadPDFFile : donwloadPDFFiles} className="cstm-download-btn-sec">Pdf</div>
                  <div onClick={donwloadCsvFile ? donwloadCsvFile : donwloadCsvFiles} className="cstm-download-btn-sec">CSV</div>
                  <div onClick={donwloadExcelFile ? donwloadExcelFile : donwloadExcelFiles} className="cstm-download-btn-sec">Excel</div>
                </div>
              </>
              :
              ""
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default PrintPdfCsvExcelDownload;