import React, { Component } from "react";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";
import transfer from "../../../../Images/transfer.png";
import filter from "../../../../Images/filter-yellow.svg";
import downloadarrow from "../../../../Images/down-arrow.svg";
import dropdownarrow from "../../../../Images/dropdown-arrow.svg";
import close from "../../../../Images/close.svg";
import ProductTable from "./Sorting";
import GroupTableLogs from "./GroupTableLogs";
import Reportingsetup from "../../../../Services/main.service";
import ReportingDetailPage from "../../../ReportingModule/TicketReporting/TicketDetails";
import PrintPdfCsvExcelDownload from "../../../Utilies/PrintPdfCsvExcelDownload";
import Cookies from "js-cookie";
import Cookiesremove from "../../../../Cookiesremove";
import SearchLicensePlate from "../../../Citation/SearchLicensePlate";

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

class PaidTicketManagement extends Component {
  state = {
    lp_number: "",
    ticket_no: "",
    street: "",
    block: "",
    violationCodeFilter: [],
    officerNameFilter: [],
    group_by_officer: "",
    status: "",
    allDataIntigration: [],
    setStreetList: [],
    badgeIDFilter: [],
    violationCode: [],
    officerName: [],
    setBeatTypes: [],
    setRemark: [],
    setZone: [],
    setShift: [],
    shiftFilter: [],
    setAgency: [],
    setStatusFilter: [],
    agencyFilter: [],
    zoneFilter: [],
    stateFilterRemarks: [],
    beatFilter: [],
    setBadgeID: [],
    dataGroupOfficerLog: {},
    stateFilter: [],
    noRecordFound: false,
    setOfficerName: [],
    setPagination: true,
    mainRecordDisplay: true,
    setPagination1: false,
    setFilterIconToggle: false,
    groupByOfficerLogs: false,
    setDownloadOption: false,
    dataIntigrationLog: [],
    showAuthToken: false,
    errorHandling: false,
    totalPages: null,
    passDataValue: "",
    setStartDate: new Date().setDate(new Date().getDate() + 1),
    setStartDateSearch: "",
    setEndDate: new Date().setHours(0, 0, 0),
    setEndDateSearch: "",
    totalDataLengthCount: 0,
    setDataLoadLoader: true,
    currentPage: 1,
    todosPerPage: 200,
    upperPageBound: 5,
    lowerPageBound: 0,
    isPrevBtnActive: "disabled",
    isNextBtnActive: "",
    pageBound: 3,
    setPaginationSelectVaue: 200,
    setShowDetailPage: false,
    stateFilterSelectedArray: [],
    officerFilterSelectedArray: [],
    badgeFilterSelectedArray: [],
    codeFilterSelectedArray: [],
    descriptionFilterSelectedArray: [],
    zoneFilterSelectedArray: [],
    remarkFilterSelectedArray: [],
    beatFilterSelectedArray: [],
    setAllSelectedFilterOption: "",
    list: [],
    payment_source: [],
    paymentType:""
  };
   isOCMD = () => {
    if(Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||Cookies.get("siteinfo")==="e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8"|| Cookies.get("siteinfo")==="183db4ec-a15e-11ee-b39a-bebc79a4d1a3") {
      return true
    } else {
      return false
    }
  }

  exportsLogsData = () => {
    var date = new Date();
    var date1 = new Date(this.state.setEndDate);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var nextMonth = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
    }).format(this.state.setStartDate);
    var todayDate = pad2(date.getDate());
    var tomorrowDate = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(this.state.setStartDate);

    this.setState({
      setStartDateSearch:
        year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z",
    });

    this.setState({
      setEndDateSearch:
        date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T" +
        (date1.getHours() < 10 ? "0" : "") +
        date1.getHours() +
        ":" +
        (date1.getMinutes() < 10 ? "0" : "") +
        date1.getMinutes() +
        ":00.000Z",
    });

    var passData =
      "page=1&limit=" +
      this.state.todosPerPage +
      "&payment_ts_from=" +
      date1.getFullYear() +
      "-" +
      pad2(date1.getMonth() + 1) +
      "-" +
      pad2(date1.getDate()) +
      "T00:00:00.000Z&payment_ts_to=" +
      year +
      "-" +
      nextMonth +
      "-" +
      tomorrowDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({
      setAllSelectedFilterOption:
        "&payment_ts_from=" +
        date1.getFullYear() +
        "-" +
        pad2(date1.getMonth() + 1) +
        "-" +
        pad2(date1.getDate()) +
        "T00:00:00.000Z&payment_ts_to=" +
        year +
        "-" +
        nextMonth +
        "-" +
        tomorrowDate +
        "T" +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":00.000Z",
    });
    this.setState({ setDataLoadLoader: true });

    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null || Response.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");

          let totalPage = Math.ceil(
            Response.data.length / this.state.todosPerPage
          );

          if (totalPage === 1) {
            this.setState({ isNextBtnActive: "disabled" });
          }
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  // When Page reload at that time this function called
  componentDidMount() {
    this.exportsLogsData();

    // Get and Display Violation List option for the search drop-down
    var passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataViolation).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ violationCode: Response.data.data[0].response });
      }
    });

    // Get and Display Officer name option for the search drop-down
    Reportingsetup.getOfficerName().then(
      (Response) => {
        if (Response.data.status === 300) {
          this.setState({ showAuthToken: true });
        } else {
          this.setState({ setOfficerName: Response.data.officer_names });
          this.setState({ setBadgeID: Response.data.officer_badge_ids });
        }
      },
      (error) => {
        if (error.response.status === 500) {
          this.setState({ errorHandling: true });
        }
      }
    );

    // Get and Display State List option for the search drop-down
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataStateList).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setStreetList: Response.data.data[0].response });
      }
    });

    // Get and Display Status List option for the search drop-down
    var passDataStateList = {
      type: "CitationStatusData",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataStateList).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setStatusFilter: Response.data.data[0].response });
      }
    });

    // Get and Display Zone List option for the search drop-down
    var passDataZone = {
      type: "ZoneList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataZone).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setZone: Response.data.data[0].response });
      }
    });

    // Get and Display Shift List option for the search drop-down
    var passShiftList = {
      type: "ShiftList",
      shard: 1,
    };
    Reportingsetup.violationCode(passShiftList).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setShift: Response.data.data[0].response });
      }
    });

    // Get and Display Agency List option for the search drop-down
    var passShiftList = {
      type: "AgencyList",
      shard: 1,
    };
    Reportingsetup.violationCode(passShiftList).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setAgency: Response.data.data[0].response });
      }
    });
    // Get and Display Remark List option for the search drop-down
    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataRemarks).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setRemark: Response.data.data[0].response });
      }
    });

    // Get and Display Beat List option for the search drop-down
    var passDataBeat = {
      type: "BeatList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataBeat).then((Response) => {
      if (Response.data.status === 300) {
        this.setState({ showAuthToken: true });
      } else {
        this.setState({ setBeatTypes: Response.data.data[0].response });
      }
    });
    // const getPaymentList = () => {
    var payload3 = {
      type: "PaymentSourceData",
      shard: 1,
    };

    Reportingsetup.violationCode(payload3)
      .then((result) => {
        if (result.data.status === 300) {
          this.setState({ showAuthToken: true });
        } else {
          this.setState({ list: result.data.data[0].response });
        }
      });
  }

  // Pagination Events
  componentDidUpdate() {
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + this.state.currentPage).addClass("active-paginated");
  }

  handleClick = (event) => {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid,
    });
    $("ul li.active-paginated").removeClass("active-paginated");
    $("ul li#" + listid).addClass("active-paginated");
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
      this.setState({ setDataLoadLoader: true });
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  setPrevAndNextBtnClass = (listid) => {
    let totalPage = Math.ceil(
      this.state.totalDataLengthCount / this.state.todosPerPage
    );
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }
  };

  btnIncrementClick = () => {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
      this.setState({ setDataLoadLoader: true });
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };
  btnDecrementClick = () => {
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
      this.setState({ setDataLoadLoader: true });
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };
  btnPrevClick = () => {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
      this.setState({ setDataLoadLoader: true });
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };
  btnNextClick = () => {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

    var passData =
      "page=" +
      listid +
      "&limit=" +
      this.state.todosPerPage +
      this.state.setAllSelectedFilterOption;
      this.setState({ setDataLoadLoader: true });
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  // When click on filter icon
  onClickFilter = (e) => {
    e.preventDefault();
    this.setState({ setFilterIconToggle: !this.state.setFilterIconToggle });
  };

  // When click on Download button
  onClickDonwloadButton = (e) => {
    e.preventDefault();
    this.setState({ setDownloadOption: !this.state.setDownloadOption });
  };

  //  When click on End date picker in search section
  onChangeSearchEndDate = (endDate) => {
    let date = new Date(endDate);
    if (!endDate) {
      this.setState({ setStartDate: "", setStartDateSearch: "" });
      return;
  }
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isDateChange = !(this.state.setStartDate instanceof Date) || endDate?.getDate() !== new Date(this.state.setStartDate).getDate()|| (hours == 0 && minutes == 0);
    if (isDateChange) {
      date.setHours(23, 59, 59, 999);
    }
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
    year +
    "-" +
    month +
    "-" +
    todayDate +
    "T" +
    (date.getHours() < 10 ? "0" : "") +
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes() +
    ":00.000Z";

    this.setState({ setStartDate: date });
    this.setState({ setStartDateSearch: currentTimeInSeconds });
  };

  // On Click Details Page open
  onClickDetailsPage = (value) => {
    this.setState({ setShowDetailPage: value });
  };

  // On click back to reporting page
  backButtonClick = () => {
    this.setState({ setShowDetailPage: false });
  };

  //  When click on Start date picker in search section
  onChangeSearchStartDate = (date) => {
    var date = new Date(date);
    var year = date.getFullYear();
    var month = pad2(date.getMonth() + 1);
    var todayDate = pad2(date.getDate());

    var currentTimeInSeconds =
      year +
      "-" +
      month +
      "-" +
      todayDate +
      "T" +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":00.000Z";

    this.setState({ setEndDate: date });
    this.setState({ setEndDateSearch: currentTimeInSeconds });
  };

  // Pagination count records will be display based on selected value from the drop-down
  onPaginationSelection = (e) => {
    this.setState({ setPaginationSelectVaue: e.target.value });
    this.setState({ setDataLoadLoader: true });
    this.setState({
      currentPage: 1,
      todosPerPage: e.target.value,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
    });

    var passData =
      "page=1" +
      "&limit=" +
      e.target.value +
      this.state.setAllSelectedFilterOption;
      this.setState({ setDataLoadLoader: true });
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
          let totalPage = Math.ceil(
            Response.data.length / this.state.todosPerPage
          );

          if (totalPage === 1) {
            this.setState({ isNextBtnActive: "disabled" });
          }
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );

    // let totalPage = Math.ceil(this.state.totalDataLengthCount / e.target.value);

    // if (totalPage === 1) {
    //   this.setState({ isNextBtnActive: 'disabled' });
    // }
  };

  onChangeSearchFilter = (e) => {
    if (e.target.name !== "group_by_officer") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (name === "lp_number") {
      var finalData = value.split(" ").join("%20");
      let newValue = value;
      newValue = SearchLicensePlate(value);
      this.setState({ lp_number: newValue });
      // this.setState({ lp_number: value });
    } else if (name === "ticket_no") {
      var finalData = value.split(" ").join("%20");
      this.setState({ ticket_no: value });
    } else if (name === "street") {
      var finalData = value.split(" ").join("%20");
      this.setState({ street: value });
    } else if (name === "block") {
      var finalData = value.split(" ").join("%20");
      this.setState({ block: value });
    } else if (name === "group_by_officer") {
      this.setState({ group_by_officer: e.target.checked });
    } else if (name === "status") {
      this.setState({ status: value });
    }
    else if (name === "full_payment") {
      this.setState({ paymentType: value });
    }
  };

  //  When click on Submit button in search section
  onSubmitButtonClick = () => {
    const {
      lp_number,
      ticket_no,
      street,
      status,
      block,
      group_by_officer,
      violationCodeFilter,
      beatFilter,
      zoneFilter,
      stateFilter,
      agencyFilter,
      shiftFilter,
      setStartDateSearch,
      setEndDateSearch,
      officerNameFilter,
      badgeIDFilter,
      stateFilterRemarks,
      payment_source,
      paymentType
    } = this.state;
    if (lp_number !== "") {
      var licensePlate = "&lp_number=" + lp_number;
    } else {
      var licensePlate = "";
    }
    if (ticket_no !== "") {
      var ticketNo = "&ticket_no=" + ticket_no;
    } else {
      var ticketNo = "";
    }
    if (street !== "") {
      var streetValue = "&street=" + street;
    } else {
      var streetValue = "";
    }
    if (block !== "") {
      var blockValue = "&block=" + block;
    } else {
      var blockValue = "";
    }
    if (group_by_officer !== "") {
      var groupByofficer = "&group_by_officer=" + group_by_officer;
    } else {
      var groupByofficer = "";
    }
    if (violationCodeFilter !== "") {
      var violationCode = violationCodeFilter.join("");
    } else {
      var violationCode = "";
    }
    if (beatFilter !== "") {
      var beat = beatFilter.join("");
      var finalBeatData = beat.split(" ").join("%20");
    } else {
      var finalBeatData = "";
    }
    if (zoneFilter !== "") {
      var zone = zoneFilter.join("");
      var finalZoneData = zone.split(" ").join("%20");
    } else {
      var finalZoneData = "";
    }
    if (stateFilter !== "") {
      var state = stateFilter.join("");
      var finalStateData = state.split(" ").join("%20");
    } else {
      var finalStateData = "";
    }
    if (status !== "") {
      var searchStatus = "&status=" + status;
    } else {
      var searchStatus = "";
    }
    // paymentType
    if (paymentType !== "") {
      var paymentStatus = "&payment_done=" + paymentType;
    } else {
      var paymentStatus = "";
    }
    if (setStartDateSearch !== "") {
      var startDate = "&payment_ts_to=" + setStartDateSearch;
    } else {
      var startDate = "";
    }
    if (setEndDateSearch !== "") {
      var endDate = "&payment_ts_from=" + setEndDateSearch;
    } else {
      var endDate = "";
    }
    if (officerNameFilter !== "") {
      var name = officerNameFilter.join("");
      var officerName = name.split(" ").join("%20");
    } else {
      var officerName = "";
    }
    if (badgeIDFilter !== "") {
      var badge = badgeIDFilter.join("");
      var badgeId = badge.split(" ").join("%20");
    } else {
      var badgeId = "";
    }
    if (stateFilterRemarks !== "") {
      var remark = stateFilterRemarks.join("");
      var remarks = remark.split(" ").join("%20");
    } else {
      var remarks = "";
    }
    if (shiftFilter !== "") {
      var shift = shiftFilter.join("");
      var shiftName = shift.split(" ").join("%20");
    } else {
      var shiftName = "";
    }
    if (agencyFilter !== "") {
      var agencys = agencyFilter.join("");
      var agencyName = agencys.split(" ").join("%20");
    } else {
      var agencyName = "";
    }
    if (payment_source !== "") {
      paymentName = payment_source
    } else {
      var paymentName = "";
    }
    this.setState({ setDataLoadLoader: true });

    if (group_by_officer === true) {
      const finalDataSearchString =
        endDate +
        startDate +
        licensePlate +
        ticketNo +
        streetValue +
        blockValue +
        violationCode +
        finalBeatData +
        finalZoneData +
        officerName +
        groupByofficer +
        finalStateData +
        searchStatus +
        badgeId +
        agencyName +
        shiftName +
        remarks +
        paymentName +
        paymentStatus;

        this.setState({ setDataLoadLoader: true });
      Reportingsetup.paidTicketReport(finalDataSearchString).then(
        (Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
            this.setState({ setDataLoadLoader: false });
          } else {
            if (Response.data.data === null) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: true });
              this.setState({ groupByOfficerLogs: false });
            } else {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: false });
              this.setState({ mainRecordDisplay: false });
              this.setState({ groupByOfficerLogs: true });
              // this.setState({ totalDataLengthCount:  Response.data.number_of_officers_grouped});
              this.setState({ dataGroupOfficerLog: Response.data.data });
            }
          }
        }
      );
    } else {
      this.setState({
        currentPage: 1,
        todosPerPage: 200,
        upperPageBound: 5,
        lowerPageBound: 0,
        isPrevBtnActive: "disabled",
        isNextBtnActive: "",
        pageBound: 3,
      });

      this.setState({ setPaginationSelectVaue: 200 });

      const finalDataSearch =
        "page=1&limit=" +
        this.state.todosPerPage +
        endDate +
        startDate +
        licensePlate +
        ticketNo +
        streetValue +
        blockValue +
        violationCode +
        finalBeatData +
        finalZoneData +
        officerName +
        finalStateData +
        searchStatus +
        badgeId +
        agencyName +
        shiftName +
        remarks +
        paymentName +
        paymentStatus;

      this.setState({
        setAllSelectedFilterOption:
          endDate +
          startDate +
          licensePlate +
          ticketNo +
          streetValue +
          blockValue +
          violationCode +
          finalBeatData +
          finalZoneData +
          officerName +
          finalStateData +
          searchStatus +
          badgeId +
          agencyName +
          shiftName +
          remarks +
          paymentName +
          paymentStatus,
      });
      
      this.setState({ setDataLoadLoader: true });

      if (finalDataSearch !== "") {
        Reportingsetup.paidTicketReport(finalDataSearch).then((Response) => {
          if (Response.data.status === 300) {
            this.setState({ showAuthToken: true });
            this.setState({ setDataLoadLoader: false });
          } else {
            if (Response.data.data === null) {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: true });
              this.setState({ setPagination: false });
              this.setState({ setPagination1: false });
              this.setState({ totalDataLengthCount: Response.data.length });
            } else {
              this.setState({ setDataLoadLoader: false });
              this.setState({ noRecordFound: false });
              this.setState({ groupByOfficerLogs: false });
              this.setState({ setPagination: false });
              this.setState({ setPagination1: true });
              this.setState({ totalDataLengthCount: Response.data.length });
              this.setState({ allDataIntigration: Response.data.data });

              let totalPage = Math.ceil(
                Response.data.length / this.state.todosPerPage
              );

              if (totalPage === 1) {
                this.setState({ isNextBtnActive: "disabled" });
              }
            }
          }
        });
      } else {
        this.exportsLogsData();
      }
    }
  };

  // Print button click event
  onClickPrintButton = () => {
    window.print();
  };

  // Pagination count records will be display based on selected value from the drop-down
  onRecordsDisplayAfterDownloads = (value) => {
    this.setState({ setPaginationSelectVaue: value });
    this.setState({
      currentPage: 1,
      todosPerPage: value,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 3,
    });
    this.setState({ setDataLoadLoader: true });
    var passData =
      "page=1" + "&limit=" + value + this.state.setAllSelectedFilterOption;
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });
          Cookies.remove("ticket_no_search");
          let totalPage = Math.ceil(Response.data.length / value);

          if (totalPage === 1) {
            this.setState({ isNextBtnActive: "disabled" });
          }
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  // Download PDF file
  donwloadPDFFile = (e) => {
    e.preventDefault();
    this.setState({ setDataLoadLoader: true });
    var passData = this.state.setAllSelectedFilterOption;
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });

          setTimeout(function () {
            var doc = new jsPDF("l", "pt", "a4");
            doc.autoTable({ html: "#cstm-report-tb-display" });
            doc.save("Reporting.pdf");
          }, 10);

          setTimeout(() => {
            this.onRecordsDisplayAfterDownloads(200);
          }, 50);
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  // Download Excel file
  donwloadExcelFile = (e) => {
    this.setState({ setDataLoadLoader: true });
    var passData = this.state.setAllSelectedFilterOption;
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });

          setTimeout(() => {
            var uri = "data:application/vnd.ms-excel;base64,",
              template =
                '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
              base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)));
              },
              format = function (s, c) {
                return s.replace(/{(\w+)}/g, function (m, p) {
                  return c[p];
                });
              };
            var toExcel = document.getElementById(
              "cstm-report-tb-display"
            ).innerHTML;
            var ctx = {
              worksheet: "Reporting",
              table: toExcel,
            };
            var link = document.createElement("a");
            link.download = "Reporting.xls";
            link.href = uri + base64(format(template, ctx));
            link.click();
          }, 10);

          setTimeout(() => {
            this.onRecordsDisplayAfterDownloads(200);
          }, 50);
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  //  Donwload CSV file
  donwloadCsvFile = (e) => {
    e.preventDefault();

    this.setState({ setDataLoadLoader: true });
    var passData = this.state.setAllSelectedFilterOption;
    Reportingsetup.paidTicketReport(passData).then(
      (Response) => {
        if (Response.data.data === null) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: true });
          this.setState({ totalDataLengthCount: 0 });
        } else {
          this.setState({ setDataLoadLoader: false });
          this.setState({ noRecordFound: false });
          this.setState({ groupByOfficerLogs: false });
          this.setState({ setPagination: true });
          this.setState({ setPagination1: false });
          this.setState({ allDataIntigration: Response.data.data });
          this.setState({ totalDataLengthCount: Response.data.length });

          setTimeout(() => {
            var csv = [];
            var rows = document.querySelectorAll("table tr");
            for (var i = 0; i < rows.length; i++) {
              var row = [],
                cols = rows[i].querySelectorAll("td, th");
              for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
              csv.push(row.join(","));
            }
            this.downloadCSV(csv.join("\n"), "Reporting.csv");
          }, 10);

          setTimeout(() => {
            this.onRecordsDisplayAfterDownloads(200);
          }, 50);
        }
      },
      (error) => {
        if (error.response.status === 401) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ showAuthToken: true });
        }
        if (error.response.status === 500) {
          this.setState({ setDataLoadLoader: false });
          this.setState({ errorHandling: true });
        }
      }
    );
  };

  downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  };

  onClickSearchViolationCode = (selectedList) => {
    this.setState({ codeFilterSelectedArray: selectedList });
    var arrayCode = [];
    selectedList.map((item) => {
      const finalData = "&code=" + item.code;
      arrayCode.push(finalData);
    });
    this.setState({ violationCodeFilter: arrayCode });
  };

  onClickSearchViolationDescriprion = (selectedList) => {
    this.setState({ descriptionFilterSelectedArray: selectedList });
    var arrayCode = [];
    selectedList.map((item) => {
      const finalData = "&code=" + item.code;
      arrayCode.push(finalData);
    });
    this.setState({ violationCodeFilter: arrayCode });
  };

  onClickSearchOfficerName = (selectedList) => {
    this.setState({ officerFilterSelectedArray: selectedList });

    var arrayOfficer = [];
    selectedList.map((item) => {
      const finalData = "&site_officer_id=" + item.officer_id;
      arrayOfficer.push(finalData);
    });
    this.setState({ officerNameFilter: arrayOfficer });
  };

  onClickSearchShift = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&shift=" + item.shift;
      arrayShift.push(finalData);
    });
    this.setState({ shiftFilter: arrayShift });
  };

  onClickSearchAgency = (selectedList) => {
    var arrayShift = [];
    selectedList.map((item) => {
      const finalData = "&agency=" + item.agency;
      arrayShift.push(finalData);
    });
    this.setState({ agencyFilter: arrayShift });
  };
   handleOptionChange = (selectedList,type) => {
		const statusValues = selectedList.map(item => item.value);
		const uniqueStatusValues = [...new Set(statusValues)];
		let categoryString = ""
		if(uniqueStatusValues.length > 0) {
		 categoryString = "&payment_source=" + uniqueStatusValues.join(',');
		}
    this.setState({ payment_source: categoryString });
	};

  onClickSearchBadgeId = (selectedList) => {
    this.setState({ badgeFilterSelectedArray: selectedList });

    var arrayBadge = [];
    selectedList.map((item) => {
      const finalData = "&badge_id=" + item.badge;
      arrayBadge.push(finalData);
    });
    this.setState({ badgeIDFilter: arrayBadge });
  };

  onClickSearchBeat = (selectedList) => {
    this.setState({ beatFilterSelectedArray: selectedList });

    var arrayBeat = [];
    selectedList.map((item) => {
      const finalBeatData = "&beat=" + item.beat;
      arrayBeat.push(finalBeatData);
    });
    this.setState({ beatFilter: arrayBeat });
  };

  onClickSearchZone = (selectedList) => {
    this.setState({ zoneFilterSelectedArray: selectedList });

    var arrayZone = [];
    selectedList.map((item) => {
      const finalZoneData = "&zone=" + item.zone;
      arrayZone.push(finalZoneData);
    });
    this.setState({ zoneFilter: arrayZone });
  };
  onClickSearchLicenseState = (selectedList) => {
    this.setState({ stateFilterSelectedArray: selectedList });

    var arrayState = [];
    selectedList.map((item) => {
      const finalStateData = "&lp_state=" + item.state;
      arrayState.push(finalStateData);
    });
    this.setState({ stateFilter: arrayState });
  };
  onClickRemark = (selectedList) => {
    this.setState({ remarkFilterSelectedArray: selectedList });

    var arrayStateRemarks = [];
    selectedList.map((item) => {
      const finalStateRemarks = "&remark_1=" + item.remark;
      arrayStateRemarks.push(finalStateRemarks);
    });
    this.setState({ stateFilterRemarks: arrayStateRemarks });
  };

  closeErrorClick = () => {
    this.setState({ errorHandling: false });
  };

  onClickLogin = (e) => {
    e.preventDefault();
    Cookiesremove();
    window.location.href = "/";
  };

  updateValue = (val) => {
    if (!val) {
      this.onSubmitButtonClick();
    }
  };

  render() {
    const {
      currentPage,
      setShowDetailPage,
      todosPerPage,
      setRemark,
      upperPageBound,
      totalDataLengthCount,
      lowerPageBound,
      isPrevBtnActive,
      isNextBtnActive,
      showAuthToken,
      setDataLoadLoader,
      setDownloadOption,
      groupByOfficerLogs,
      errorHandling,
      setStartDate,
      setEndDate,
      setStreetList,
      setBadgeID,
      setOfficerName,
      setBeatTypes,
      violationCode,
      setZone,
      noRecordFound,
      setFilterIconToggle,
      allDataIntigration,
      dataGroupOfficerLog,
      setPaginationSelectVaue,
      stateFilterSelectedArray,
      officerFilterSelectedArray,
      badgeFilterSelectedArray,
      codeFilterSelectedArray,
      remarkFilterSelectedArray,
      beatFilterSelectedArray,
      zoneFilterSelectedArray,
      descriptionFilterSelectedArray,
      setShift,
      setAgency,
      setStatusFilter,
      list,
    } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = allDataIntigration.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalDataLengthCount / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className="active-paginated" id={number}>
            <button id={number} onClick={this.handleClick.bind(this)}>
              {number}
            </button>
          </li>
        );
      } else if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <li key={number} id={number}>
            <button id={number} onClick={this.handleClick.bind(this)}>
              {number}
            </button>
          </li>
        );
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = (
        <li className="">
          <button onClick={this.btnIncrementClick.bind(this)}>
            {" "}
            &hellip;{" "}
          </button>
        </li>
      );
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = (
        <li className="">
          <button onClick={this.btnDecrementClick.bind(this)}>
            {" "}
            &hellip;{" "}
          </button>
        </li>
      );
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <span id="btnPrev"> Prev </span>
        </li>
      );
    } else {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <button id="btnPrev" onClick={this.btnPrevClick.bind(this)}>
            {" "}
            Prev{" "}
          </button>
        </li>
      );
    }
    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <span id="btnNext"> Next </span>
        </li>
      );
    } else {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <button id="btnNext" onClick={this.btnNextClick.bind(this)}>
            {" "}
            Next{" "}
          </button>
        </li>
      );
    }
    return (
      <>
        {!setShowDetailPage ? (
          <>
            <section>
              <div className="container-fluid">
                <div className="row">
                  {/* Content starts */}
                  <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel cstm-ctn cstm-operate-top">
                    <div className="row cstm-ticket-manage-sec">
                      <div className="cstm-ticket-manage-heading">
                        <h3 className="cstm-all-pg-ttl operate-ttl">
                          Payment Detailed Report
                        </h3>
                      </div>
                      <PrintPdfCsvExcelDownload
                        tableId="tbl-main-download"
                        fileName="payment-detailed-report"
                        pageSize="a2"
                      />
                    </div>

                    <div className="report-searchbar">
                      <form>
                        <div className="report-date report-mr">
                          <DatePicker
                            // selectsStart
                            showTimeSelect
                            timeIntervals={1}
                            dateFormat="MM/dd/yyyy h:mmaa"
                            timeFormat="HH:mm"
                            className="cstm-pull-details schedule-date-input"
                            name="end_date"
                            selected={setEndDate}
                            onChange={(date) =>
                              this.onChangeSearchStartDate(date)
                            }
                            placeholderText="MM/DD/YYYY HH:MM"
                          />
                          <img
                            src={transfer}
                            alt="transfer"
                            className="date-transfer"
                          />
                          <DatePicker
                            showTimeSelect
                            timeIntervals={1}
                            dateFormat="MM/dd/yyyy h:mmaa"
                            timeFormat="HH:mm"
                            className="cstm-pull-details schedule-date-input"
                            name="start_date"
                            selected={setStartDate}
                            onChange={(date) =>
                              this.onChangeSearchEndDate(date)
                            }   
                            placeholderText="MM/DD/YYYY HH:MM"                         
                          />
                        </div>
                        <div className="report-licence report-mr">
                          <input
                            className="license-plate"
                            name="lp_number"
                            value={this.state.lp_number}
                            onChange={this.onChangeSearchFilter.bind(this)}
                            type="text"
                            placeholder="License Plate"
                          />
                        </div>
                        <div className="report-licence select-drop-arrow report-mr">
                          <Multiselect
                            name="state"
                            selectedValues={stateFilterSelectedArray}
                            displayValue="stateName"
                            placeholder="License State"
                            onRemove={this.onClickSearchLicenseState.bind(this)}
                            onSearch={function noRefCheck() {}}
                            onSelect={this.onClickSearchLicenseState.bind(this)}
                            options={setStreetList.map((item, index) => ({
                              stateName: item.state_name,
                              state: item.state_abbreviated,
                            }))}
                            showCheckbox
                          />
                          <img src={dropdownarrow} alt="dropdownarrow" />
                        </div>
                        <div className="report-licence report-mr">
                          <div>
                            <input
                              className="license-plate"
                              name="ticket_no"
                              value={this.state.ticket_no}
                              onChange={this.onChangeSearchFilter.bind(this)}
                              type="text"
                              placeholder="Search Tickets"
                            />
                          </div>
                        </div>
                        <button
                          onClick={this.onClickFilter.bind(this)}
                          className="filter-box report-mr"
                        >
                          <img src={filter} alt="filter" />
                        </button>
                        <button
                          className="licence-print report-mr"
                          type="button"
                          onClick={this.onSubmitButtonClick.bind(this)}
                        >
                          Submit
                        </button>
                      </form>

                      {setFilterIconToggle ? (
                        <div className="filter-main-section">
                          <div className="row">
                            <div className="select-drop-arrow filter_cstm1">
                              <select
                                value={this.state.status}
                                onChange={this.onChangeSearchFilter.bind(this)}
                                name="status"
                              >
                                <option value="">Select Status</option>
                                {setStatusFilter.map((item) => (
                                  <option value={item.status}>
                                    {item.status}
                                  </option>
                                ))}
                                {/* <option value="Valid">Valid</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Warning">Warning</option> */}
                              </select>
                            </div>

                            <div className="select-drop-arrow filter_cstm2">
                              <Multiselect
                                name="officer"
                                selectedValues={officerFilterSelectedArray}
                                displayValue="officer"
                                placeholder="Officer Name"
                                onRemove={this.onClickSearchOfficerName.bind(
                                  this
                                )}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchOfficerName.bind(
                                  this
                                )}
                                options={setOfficerName
                                  .sort((a, b) =>
                                    a.officer_badge_id > b.officer_badge_id
                                      ? 1
                                      : -1
                                  )
                                  .map((item, index) => ({
                                    officer:
                                      item.officer_badge_id +
                                      " - " +
                                      item.officer_first_name +
                                      " " +
                                      item.officer_last_name,
                                    officer_id: item.site_officer_id,
                                  }))}
                                showCheckbox
                              />
                            </div>

                            {/* <div className="select-drop-arrow filter_cstm3">
                              <Multiselect
                                name="badge"
                                selectedValues={badgeFilterSelectedArray}
                                displayValue="badge"
                                placeholder="Badge Id"
                                onRemove={this.onClickSearchBadgeId.bind(this)}
                                onSearch={function noRefCheck() { }}
                                onSelect={this.onClickSearchBadgeId.bind(this)}
                                options={
                                  (setBadgeID.map((item, index) => (
                                    {
                                      badge: (item)
                                    }
                                  )))
                                }
                                showCheckbox
                              />
                            </div> */}

                            <div className="select-drop-arrow filter_cstm4">
                              <input
                                className="license-plate"
                                name="street"
                                value={this.state.street}
                                onChange={this.onChangeSearchFilter.bind(this)}
                                type="text"
                                placeholder="Search Street"
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm5">
                              <input
                                className="license-plate"
                                name="block"
                                value={this.state.block}
                                onChange={this.onChangeSearchFilter.bind(this)}
                                type="text"
                                placeholder="Search Block"
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm8">
                              <Multiselect
                                selectedValues={codeFilterSelectedArray}
                                name="code"
                                displayValue="code"
                                placeholder="Violation code"
                                onRemove={this.onClickSearchViolationCode.bind(
                                  this
                                )}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchViolationCode.bind(
                                  this
                                )}
                                options={violationCode.map((item, index) => ({
                                  code: item.code,
                                }))}
                                showCheckbox
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm8">
                              <Multiselect
                                selectedValues={descriptionFilterSelectedArray}
                                name="description"
                                displayValue="description"
                                placeholder="Select Violation Description"
                                onRemove={this.onClickSearchViolationDescriprion.bind(
                                  this
                                )}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchViolationDescriprion.bind(
                                  this
                                )}
                                options={violationCode.map((item, index) => ({
                                  description: item.violation_description,
                                  code: item.code,
                                }))}
                                showCheckbox
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm7">
                              <Multiselect
                                selectedValues={remarkFilterSelectedArray}
                                name="remark"
                                displayValue="remark"
                                placeholder="Remarks"
                                onRemove={this.onClickRemark.bind(this)}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickRemark.bind(this)}
                                options={setRemark.map((item, index) => ({
                                  remark: item.remark,
                                }))}
                                showCheckbox
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm9">
                              <Multiselect
                                name="beat"
                                selectedValues={beatFilterSelectedArray}
                                displayValue="beat"
                                placeholder="Select Beat"
                                onRemove={this.onClickSearchBeat.bind(this)}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchBeat.bind(this)}
                                options={setBeatTypes.map((item, index) => ({
                                  beat: item.beat_name,
                                }))}
                                showCheckbox
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm10">
                              <Multiselect
                                name="zone"
                                selectedValues={zoneFilterSelectedArray}
                                displayValue="zone"
                                placeholder="Select Zone"
                                onRemove={this.onClickSearchZone.bind(this)}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchZone.bind(this)}
                                options={setZone.map((item, index) => ({
                                  zone: item.zone_name,
                                }))}
                                showCheckbox
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm11">
                              <Multiselect
                                name="shift"
                                displayValue="shift"
                                placeholder="Shift"
                                onRemove={this.onClickSearchShift.bind(this)}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchShift.bind(this)}
                                options={setShift.map((item, index) => ({
                                  shift: item.shift_name,
                                }))}
                                showCheckbox
                              />
                            </div>

                            <div className="select-drop-arrow filter_cstm7">
                              <Multiselect
                                name="agency"
                                displayValue="agency"
                                placeholder="agency"
                                onRemove={this.onClickSearchAgency.bind(this)}
                                onSearch={function noRefCheck() {}}
                                onSelect={this.onClickSearchAgency.bind(this)}
                                options={setAgency.map((item, index) => ({
                                  agency: item.agency_name,
                                }))}
                                showCheckbox
                              />
                            </div>
                            <div className="select-drop-arrow filter_cstm1">
                              <Multiselect
                                name="payment_source"
                                displayValue="label"
                                placeholder="Select Payment Source"
                                onSearch={() => {}}
                                onRemove={this.handleOptionChange.bind(this)}
                                onSelect={this.handleOptionChange.bind(this)}
                                options={list.map((it) => ({
                                  value: it.source,
                                  label: it.source,
                                }))}
                                showCheckbox
                              />
                            </div>
                         {this.isOCMD()&&   <div className="select-drop-arrow filter_cstm1">
                              <select
                                value={this.state.paymentType}
                                onChange={this.onChangeSearchFilter.bind(this)}
                                name="full_payment"
                                style={{width:"auto"}}
                              >
                                <option value="">Select Payment Completion</option>
                                <option value={true}>Full Payment</option>
                                <option value={false}>Partial Payment</option>
                              </select>
                            </div>}
                            {/* </div> */}

                            {/* <div className="check-input-filter filter_cstm12 ticket-group-by-officer-ttl">
                              <div className="checkbox-inline">
                                <input onChange={this.onChangeSearchFilter.bind(this)} type="checkbox" name="group_by_officer" id="check-officer" />
                                <label htmlFor="check-officer">Group By Officer</label>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {setDataLoadLoader ? (
                      <div className="text-center spin-load-main">
                        <div className="spinner-border"></div>
                      </div>
                    ) : noRecordFound ? (
                      <div className="no-record-found-main">
                        No Record Found
                      </div>
                    ) : (
                      <>
                        {groupByOfficerLogs ? (
                          <GroupTableLogs
                            onClickDetailsPage={this.onClickDetailsPage}
                            products={dataGroupOfficerLog}
                          />
                        ) : (
                          <ProductTable
                            onClickDetailsPage={this.onClickDetailsPage}
                            updateValue={this.updateValue}
                            products={allDataIntigration}
                          />
                        )}

                        {allDataIntigration.length !== 0 &&
                          noRecordFound !== true && (
                            <div className="cstm-permit-trf esc-select">
                              <div className="col-12">
                                <div className="total-count-records">
                                  Total <b> {totalDataLengthCount} </b> Records
                                  Found{" "}
                                </div>
                              </div>
                              <ul className="cstm-pagination-added-bottom">
                                {renderPrevBtn}
                                {pageDecrementBtn}
                                {renderPageNumbers}
                                {pageIncrementBtn}
                                {renderNextBtn}
                              </ul>

                              <div className="cstm-left-lable-pn-val">
                                {" "}
                                Number of records per page{" "}
                              </div>
                              <div className="cstm-right-lable-pn-val">
                                <select
                                  className="select-pagination-val"
                                  value={setPaginationSelectVaue}
                                  onChange={this.onPaginationSelection.bind(
                                    this
                                  )}
                                >
                                  <option value="100">100</option>
                                  <option value="200">200</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2000">2000</option>
                                </select>
                                <img src={dropdownarrow} alt="dropdownarrow" />
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <ReportingDetailPage backButtonClick={this.backButtonClick} />
        )}

        {errorHandling ? (
          <>
            <div className="cstm-site-model">
              <div className="cstm-site-model-block fixed z-20">
                <div className="cstm-site-popup-bg">
                  <div className="cstm-site-model-wrapper auth-error-cstm-model-wrapper">
                    <button
                      onClick={this.closeErrorClick.bind(this)}
                      className="cstm-close-btn-db-is"
                    >
                      <img src={close} alt="close" />
                    </button>
                    <div>
                      <p className="error-handling">
                        Your session has expired. Please login again.
                      </p>
                    </div>
                    <button
                      onClick={this.onClickLogin.bind(this)}
                      className="cstm-login-btn-dsb"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default PaidTicketManagement;
