import {useState, useEffect} from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import ArrowPermitRight from '../../../Images/arrow-permit-right.svg';
import {
  permitListData,
  permitQuantityFormHandler,
  permitStepFormHandler,
} from '../../../actions/permits';

function convertDecimal(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

const PermitThankyou = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permitData, setPermitData] = useState([]);
  const [transDetails, setTransDetails] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [isApprove, setIsApprove] = useState(false);

  useEffect(() => {
    if (location.state.total_permit_fee) {
      setTotalAmount(location.state.total_permit_fee);
    }

    if (location.state.permit) {
      setPermitData(location.state.permit);
    }

    if (location.state.is_approval) {
      setIsApprove(location.state.is_approval);
    }

    if (location.state.response) {
      setTransDetails(location.state.response);
    }

  }, []);

  useEffect(() => {
    dispatch(permitListData(null));
    dispatch(permitStepFormHandler(null));
    dispatch(permitQuantityFormHandler(null));
  }, []);

  return (
    <>
     <div className="wrapper">
                <div className="container-fluid searchResult-section right-panel cstm-right-panel">
                    <div className="payment-success-main">
                        <div className="row">
              <div
                className={`col-12 payment-success-text ${
                  !isApprove && 'approval-cstm'
                }`}>
                {isApprove ? (
                  <h3>Payment Successful</h3>
                ) : (
                  <h3>Thank you for submitting Permit Application.</h3>
                )}
                {isApprove ? (
                  <p>
                    Thank you for your permit purchase! Your transaction is
                    completed.
                  </p>
                ) : (
                  <p>
                    Your request has been submitted. An official from the city
                    will review your documents and reach out with a decision.
                  </p>
                )}
                {isApprove && <></>}

                {isApprove && (
                  <div className="cstm-ps-main">
                    <div className="cstm-ps-main-cm">
                      <div className="cstm-ps-main-cm-lable">Receipt #</div>
                      <div className="cstm-ps-main-cm-cont">
                        : {transDetails?.transaction_id}
                      </div>
                    </div>
                    <div className="cstm-ps-main-cm">
                      <div className="cstm-ps-main-cm-lable">
                        Transaction Date
                      </div>
                      <div className="cstm-ps-main-cm-cont">
                        : {moment().format('MM-DD-YYYY')}
                      </div>
                    </div>

                    <>
                      <div className="cstm-ps-main-cm">
                        <div className="cstm-ps-main-cm-lable">
                          Plate Number{' '}
                        </div>
                        <div className="cstm-ps-main-cm-cont">
                          :{' '}
                          {permitData &&
                            permitData.length > 0 &&
                            permitData
                              .map((itm, i) => itm.vehicle_details.lp_number)
                              .join(', ')}
                        </div>
                      </div>
                      <div className="cstm-ps-main-cm">
                        <div className="cstm-ps-main-cm-lable">
                          Total Permit Fee{' '}
                        </div>
                        <div className="cstm-ps-main-cm-cont">
                          : ${convertDecimal(totalAmount)}
                        </div>
                      </div>
                    </>
                  </div>
                )}

                <div className="cstm-back-btn">
                  <button
                    className="permit-question-btn-right cstm-permit-btn"
                    type="button"
                    onClick={() => navigate('/permits-option')}>
                    Back To Permits List
                    <img src={ArrowPermitRight} alt="next" />
                  </button>
                </div>
              </div>{' '}
            </div>
          </div>{' '}
        </div>
      </div>
    </>
  );
};

export default PermitThankyou;
