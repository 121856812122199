import { useEffect, useState } from "react";
import mainService from "../../../Services/main.service";
import PermitOptionComponent from "./permitOptionComponent";
import { refreshTokenMiddleware } from "../../customMiddelware";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { siteid } from "../../../Global/site";
import dashboardService from "../../../Services/dashboard.service";
import Cookies from "js-cookie"
import { permitLayoutData, permitListData, permitStepFormHandler } from "../../../actions/permits";
import BuyPermitStep from "../buyPermit";

const PermitOptionContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(true);
  const [totalRecords,setTotalRecords] = useState(0);
  const [currentPage,setCurrentPage] = useState(1);
  const [prevAndNextBtnClass,setPrevAndNextBtnClass] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [showStepDetails,setShowStepDetails] = useState(false)
  
  // Get Table List Api Handler
  const PermitListHandler = async (query) => {
    setLoading(true);
    try {
      const response = await mainService.getPermitList(query, Cookies.get('siteinfo'));
      if (response.data.data.permit_config) {
        setReportData(response.data.data.permit_config);
        setNoRecordFound(false);
        setTotalRecords(response.data.data.length)
      } else {
        setNoRecordFound(true);
        setReportData([]);
      }
    } catch (error) {
      setNoRecordFound(true);
      if (error.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        PermitListHandler({
          page:currentPage,
          limit:itemsPerPage
        })
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    PermitListHandler({
      page:currentPage,
      limit:itemsPerPage
    });
    dispatch(permitStepFormHandler(null))
  }, []);

  const tableHeading = [
    "Name",
    "Description",
    "Type",
    "Status",
    "Start Date",
    "End Date",
    "Is Public",
    "Action",
  ];

  const keySequence = [
    "name",
    "description",
    "type",
    "status",
    "permit_start_date",
    "permit_end_date",
    "is_public",
    "permit_time_availability",
  ];

  const EditHandler = (permit) => {
    navigate("/permits-configuration", { state: { permit } });
  };

  const handlePaginationChange = (newPage) => {
    PermitListHandler({
        page:newPage.currentPage,
        limit:newPage.pageLimit
      })
      setCurrentPage(newPage.currentPage);
      setPrevAndNextBtnClass(newPage.pageLimit);
      setItemsPerPage(newPage.pageLimit)
  }

  const getLayout = async (permitData) => {
    try {
      const response = await dashboardService.getPermitLayout(
        permitData.name,
        Cookies.get('siteinfo'),
      );
      if (response.data.template && response.data.template.length > 0) {
        setShowStepDetails(true);
        dispatch(permitListData(permitData));
        dispatch(permitLayoutData(response.data.template));
        navigate('#step0');
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getLayout();
      }
    }
  };
  
  const buyPermitHandler = (data) => {
    getLayout(data);
  };

  return (
    <>
      {showStepDetails ? (
        <BuyPermitStep setShowStepDetails={setShowStepDetails} />
      ) : (<PermitOptionComponent
        reportData={reportData}
        loading={loading}
        noRecordFound={noRecordFound}
        tableHeading={tableHeading}
        keySequence={keySequence}
        PermitListHandler={PermitListHandler}
        EditHandler={EditHandler}
        handlePaginationChange={handlePaginationChange}
        totalRecords={totalRecords}
        itemsPerPage={itemsPerPage}
        prevAndNextBtnClass={prevAndNextBtnClass}
        currentPage={currentPage}
        buyPermitHandler={buyPermitHandler}
      />)}
    </>
  );
};

export default PermitOptionContainer;
