import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { refreshTokenMiddleware } from '../customMiddelware'
import mainService from "../../Services/main.service";

import BackbuttinIMag from '../../Images/back.png'


const RoleCreate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { agencyS } = useSelector(state => state.auth)
    const [agencyList, setAgencyList] = useState([])
    const [bundleList, setBundleList] = useState({})
    const [selectedBundle, setSelectedBundle] = useState([])
    const [inputValues, setInputValues] = useState({
        role_name: '',
        agency_id: ''
    })
    const [inputErrors, setInputErrors] = useState({
        role_name: '',
        agency_id: '',
        bundles: ''
    })

    const [expandBdlBox, setExpandBdlBox] = useState([-1])
    const [isBtnLoader, setBtnLoader] = useState(false);
    const [isPageLoader, setPageLoader] = useState(false);
    const [message, setMessage] = useState('')
    const [succPoup, setSuccPoup] = useState(false)


    useEffect(() => {
        setPageLoader(true)
        agencieslistfun()
        listofbundlesfun()
    }, [])

    function agencieslistfun() {
        mainService.agencieslistAPI().then(res => {
            if (res.data.status) {
                var newArray = res.data.data.agency_map.map(function (el) {
                    return { value: el.agency_data.agency_id, label: el.agency_data.agency_name }
                });
                setAgencyList(newArray)
            }
        }).catch(err => {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                agencieslistfun()
            }
        })
    }

    function listofbundlesfun() {
        mainService.fetchBundlesListAPI().then(res => {

            if (res.data.status) {
                setBundleList(res.data.Data.bundle_data)
            }
            setPageLoader(false)
        }).catch(err => {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                listofbundlesfun()
            }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
        if (value === '') {
            setInputErrors({ ...inputErrors, ['role_name']: `Enter role name` })
        } else {
            setInputErrors({ ...inputErrors, ['role_name']: '' })
        }
    }

    const handleSelectChanges = (option, name) => {
        setInputValues({ ...inputValues, [name]: option });
        if (option) {
            setInputErrors({ ...inputErrors, ['agency_id']: '' })
        } else {
            setInputErrors({ ...inputErrors, ['agency_id']: `Select agency` })
        }
    }

    const onChangeBundleBox = (e, i, items) => {
        if (expandBdlBox.includes(i)) {
            const in_f = expandBdlBox.indexOf(i)
            expandBdlBox.splice(in_f, 1);
            items.map((el) => {
                if (selectedBundle.includes(el.bundle_id)) {
                    const in_f = selectedBundle.indexOf(el.bundle_id)
                    selectedBundle.splice(in_f, 1);
                }
            })
            setSelectedBundle([...selectedBundle])
        } else {
            expandBdlBox.push(i)
        }
        setExpandBdlBox([...expandBdlBox])
    }

    const onSelectBdlSubItem = (e) => {
        const { value, checked } = e.target

        if (checked) {
            if (!selectedBundle.includes(value)) {
                selectedBundle.push(value)
            }
            setInputErrors({ ...inputErrors, ['bundles']: '' })

        } else {
            if (selectedBundle.includes(value)) {
                const in_f = selectedBundle.indexOf(value)
                selectedBundle.splice(in_f, 1);
            }
        }
        setSelectedBundle([...selectedBundle])
    }

    const handleCreateNewRoleSubmit = (e) => {
        e.preventDefault()
        let isValid = false
        Object.entries(inputValues).forEach(([key, value]) => {
            if (value === '') {
                isValid = true
                if (key === 'agency_id') {
                    inputErrors[key] = `Select agency`
                } else {
                    inputErrors[key] = `Enter ${key.replace(/_/g, ' ')}`
                }
            }
        })
       
        if (!selectedBundle.length) {
            inputErrors['bundles'] = `Select scope`
        }
        setInputErrors({ ...inputErrors })

        if (!isValid) {
            setBtnLoader(true)
            const payload = {
                role_name: inputValues.role_name,
                agency_id: inputValues.agency_id.value,
                bundles: selectedBundle.join(',')
            }
            rolecreatefun()
            async function rolecreatefun() {
                try {
                    const result = await mainService.createRoleAPI(payload)
                    setBtnLoader(false)
                    if(result.data.status){
                            navigate('/roles')
                    } else {
                        setMessage(result.data.message)
                    }
                } catch (err) {
                    if (err.response.status === 401) {
                        refreshTokenMiddleware(dispatch)
                        rolecreatefun()
                    }
                }
            }
        
        }
    }


    return (
        <>
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                <div className="row">
                    <div className="col-12 back-button back-button-citationdetails">
                        <Link onClick={() => navigate(-1)}>
                            <img src={BackbuttinIMag} alt="img" className="img-fluid" /> Back
                        </Link>
                    </div>
                </div>
                {isPageLoader ?
                    <div className='text-center'>
                        <div className="spinner-border"></div>
                    </div>
                    :
                    <div className="create-new-role-only">
                        <div>
                            <div className="row">
                                <div className="col-12 userdetail-txt1">
                                    <h3 className="cstm-all-pg-ttl">Create New Role</h3>
                                </div>
                            </div>
                        <div className="create-new-role-only-main">  
                            <form onSubmit={handleCreateNewRoleSubmit}>
                                <div className="row">
                                    <div className='col-12 cstm-inputfield'>
                                        <label className="form-lable-fuild">Name<span>*</span></label>
                                        <input type="text" name='role_name' onChange={handleChange} placeholder='Enter role name' />
                                        <span className="errormessage">{inputErrors.role_name}</span>
                                    </div>
                                    <div className='col-12 cstm-inputfield'>
                                    <label className="form-lable-fuild">Agency<span>*</span></label> 
                                        <Select
                                            name="agency_id"
                                            options={agencyList}
                                            selectedOption="Active"
                                            searchable
                                            onChange={(e) => handleSelectChanges(e, 'agency_id')}
                                        />
                                        <span className="errormessage">{inputErrors.agency_id}</span>
                                    </div>
                                    <div className='col-12 '>
                                    <label className="form-lable-fuild">Select Scope<span>*</span></label> 

                                        <div className='cstm-scope-ttl-main-cnr overflow-auto'>
                                        {Object.keys(bundleList).map((el, i) => (
                                            <div className='enroll-agency-check-cstm' key={i}>
                                                <label> <input type="checkbox" name={`bundle_${i}`} onChange={(e) => onChangeBundleBox(e, i, bundleList[el])} /> {el}</label>
                                                {expandBdlBox.includes(i) ? 
                                                    <div className="">  
                                                        {bundleList[el].map((em, j) => (
                                                            <div className='in-cstm-sub-agency' key={j}>
                                                                <label>  <input type="checkbox" name={`bundle_sub_${i}${j}`} value={em.bundle_id} onChange={(e) => onSelectBdlSubItem(e)} /> {em.bundle_name}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        ))}
                                       </div>
                                        <span className="errormessage">{inputErrors.bundles}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 search-btn  text-center">
                                        <button className="btn" type="submit" disabled={isBtnLoader}>
                                            {isBtnLoader &&
                                                <div className="cstm-loader">
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </div>
                                            }
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                }
                {succPoup &&
                    <div className="main-body-modal">
                        <div className="main-modal">
                            <div className="cstm-modal-body">
                                <div className="modal-body cstm-modal-body">
                                    <p className="text-center mb-0">{message}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default RoleCreate