import {
  PERMIT_DATA,
  PERMIT_STEP_FORM,
  PERMIT_QUANTITY_DATA,
  PERMIT_LAYOUT_DATA,
  PERMIT_USER_INFO,
  PERMIT_LOCATION_DATA
} from './types.js';

export const permitStepFormHandler = data => dispatch => {
  dispatch({
    type: PERMIT_STEP_FORM,
    payload: data,
  });
};

export const permitListData = data => dispatch => {
  dispatch({
    type: PERMIT_DATA,
    payload: data,
  });
};
export const permitQuantityFormHandler = data => dispatch => {
  dispatch({
    type: PERMIT_QUANTITY_DATA,
    payload: data,
  });
};
export const permitLayoutData = data => dispatch => {
  dispatch({
    type: PERMIT_LAYOUT_DATA,
    payload: data,
  });
};
export const permitUserInfo = data => dispatch => {
  dispatch({
    type: PERMIT_USER_INFO,
    payload: data,
  });
};
export const permitLocationData = data => dispatch => {
  dispatch({
    type: PERMIT_LOCATION_DATA,
    payload: data,
  });
}