import Cookies from "js-cookie";

export function siteid() {
  const siteinfo = Cookies.get("siteinfo");
  const siteName = Cookies.get("siteName");

  return { siteinfo, siteName };
}

export function assignsiteid() {
  const pathname = window.location.hostname;
  let id_site = "";


  if (
    pathname === 'processing-parkkcmo.netlify.app' ||
    pathname === 'processing.parkkcmo.com'
  ) {
    id_site = "a95c3734-4986-11ef-98e9-2a370f00fe52"
  } else if (
    pathname === 'staging-processing-parkkcmo.netlify.app' ||
    pathname === 'staging-processing.parkkcmo.com'
  ) {
    id_site = "70f9c966-4985-11ef-8e44-76f8a1a040d2"
  } else if (
    pathname === 'processing-parkcharleston-sc.netlify.app' ||
    pathname === 'processing.parkcharleston-sc.com'
  ) { 
    id_site = "cdb0262e-22ed-11ee-bd8f-42803422fd7d"
  } else if (
    pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
    pathname === 'staging-processing.parkcharleston-sc.com'
  ) { 
    id_site = "7b9fdc62-22ed-11ee-b4ca-ae854bfaee9a"
  } else if (
    pathname === 'processing.parksepta.com' ||
    pathname === 'processing-parksepta.netlify.app'
  ) {
    id_site='4fa2e26e-44af-11ef-ba31-ca0a5add8b0c'
  } else if (
    pathname === 'staging-processing.parksepta.com' ||
    pathname === 'staging-processing-parksepta.netlify.app'
  ) {
    id_site='cd666208-44ae-11ef-b128-de29d549d608'
  } else if (
    pathname === 'processing-parkingenforcementco.netlify.app' ||
    pathname === 'processing.parkingenforcementco.com'
  ) {
    id_site='23697ca0-3e04-11ef-85dc-e665cc09bf86'
  } else if (
    pathname === 'staging-processing.parkingenforcementco.com' ||
    pathname === 'staging-processing-parkingenforcementco.netlify.app'
  ) {
    id_site='fdbcf868-3ec3-11ef-8e44-76f8a1a040d2'
  } else if (pathname === 'processing-parkcohasset.netlify.app' ||
    pathname === 'processing.parkcohasset.com') {
    id_site = "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c"
  } else if ( pathname === 'staging-processing-parkcohasset.netlify.app' ||
    pathname === 'staging-processing.parkcohasset.com') {
    id_site = "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2"
  } else  if (pathname === "staging-processing-hpa2pay.netlify.app" || pathname === "staging-processing.hpa2pay.com") {
    id_site = "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d"
  } else if (pathname === "processing-hpa2pay.netlify.app" || pathname === "processing.hpa2pay.com") {
    id_site = "5bd20490-dc2f-11ee-bf9e-72127457a419"
  } else  if (pathname === "processing.parkbeaufortnc.com" || pathname === "processing-parkbeaufortnc.netlify.app") {
    id_site = "3e2116e8-0d26-11ef-9818-ee2c79e2244c"
  } else if (pathname === "processing.parkbeaufortnc.com" || pathname === "processing-parkbeaufortnc.netlify.app") {
    id_site = "3e2116e8-0d26-11ef-9818-ee2c79e2244c"
  } else if (pathname === "staging-processing.parkbeaufortnc.com" || pathname === "staging-processing-parkbeaufortnc.netlify.app") {
    id_site = "2b6d81e0-0d25-11ef-8e44-76f8a1a040d2"
  } else if(pathname === "processing.parkashlandoregon.com" || pathname === "processing-parkashlandoregon.netlify.app"){
    id_site = "28022506-fe2f-11ee-aa58-82967aaeaa80"
  } else if(pathname === "processing-parkriveroakssc.netlify.app" || pathname === "processing.parkriveroakssc.com"){
    id_site = "46dd302e-fe2f-11ee-aa58-82967aaeaa80"
  } else if(pathname === "staging-processing.parkashlandoregon.com" || pathname === "staging-processing-parkashlandoregon.netlify.app"){
    id_site = "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4"
  } else if(pathname === "staging-processing-parkriveroakssc.netlify.app" || pathname === "staging-processing.parkriveroakssc.com"){
    id_site = "f3062dac-fe2e-11ee-ae3f-92040ec4c6a4"
  } else if(pathname === "processing-parkbangor.netlify.app" || pathname === "processing.parkbangor.org"){
    id_site = "3aba432e-dbd7-11ee-845b-0602968307f0"
  } else if(pathname === "staging-processing-parkbangor.netlify.app" || pathname === "staging-processing.parkbangor.org") {
    id_site = "b87d467a-dbd3-11ee-be9c-72ab48aba21d"
  } else if(pathname === "processing.parkocmd.com" || pathname === "processing-parkocmd.netlify.app") {
    id_site = "da3c1178-a07e-11ee-8147-56d1ee6b2413"
  } else if(pathname === "processing-bismarck.netlify.app" || pathname === "processing.parkbismarcknd.com") {
    id_site = "ead64c86-3681-11ee-9c41-6ef2fb394a83"
  } else if(pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com") {
    id_site = "c6443f42-c982-11ee-b39a-bebc79a4d1a3"
  } else if(pathname === "staging-processing-parkiop.netlify.app" || pathname === "staging-processing.parkiop.com") {
    id_site = "7fc01b7c-c982-11ee-be9c-72ab48aba21d"
  } else if(pathname === "staging-processing.parkbismarcknd.com" || pathname === "staging-processing-parkbismarck.netlify.app") {
    id_site = "9db6bd50-3681-11ee-b4ca-ae854bfaee9a"
  } else if(pathname === "processing-parkkalamazoo.netlify.app" || pathname === "processing.parkkalamazoo.com") {
    id_site = "183db4ec-a15e-11ee-b39a-bebc79a4d1a3"
  } else if (pathname === "staging-processing.parkkalamazoo.com" || pathname === "staging-processing-parkkalamazoo.netlify.app") {
    id_site = "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8";
  } else if (pathname === "laz-processing.netlify.app" || pathname === "processing.parkcountryclubplaza.com") {
    id_site = "8e9bc50c-95ab-11ee-9a11-c20b99673619";
  } else if (pathname === "lazstaging-processing.netlify.app" || pathname === "staging-processing.parkcountryclubplaza.com") {
    id_site = "9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e";
  } else if (pathname === "oceancity-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkocmd.com") {
    id_site = "7b4bc424-a7dc-11ed-a1e8-42e281654476";
  } else if (pathname === "greenvillesc-processing-staging.netlify.app") {
    id_site = "68ea3b5c-172f-4f33-8d55-e76c301ad212";
  } else if (pathname === "parkmystic-processing.netlify.app") {
    id_site = "cd1200ae-1fd5-11ee-8b64-665980bd7c45";
  } else if (pathname === "mansfield-processing.netlify.app") {
    id_site = "98ccd4bc-fa4c-11ed-8b64-665980bd7c45";
  } else if (pathname === "parkmystic-processing-staging.netlify.app") {
    id_site = "dd9bb726-e556-11ed-8bae-acde48001122";
  } else if (pathname === "mansfield-processing-staging.netlify.app") {
    id_site = "c293c922-fa4c-11ed-b4ca-ae854bfaee9a";
  } else if (
    pathname === "follybeach-processing.netlify.app" ||
    pathname === "follybeach-processing.parkloyalty.com"
  ) {
    id_site = "33370b0e-e5fc-11ed-8b64-665980bd7c45";
  } else if (pathname === "follybeach-processing-staging.netlify.app" || pathname === "follybeach-processing-staging.parkloyalty.com" ) {
    id_site = "a98dcd6a-cfc2-11ed-ad98-66070e279278";
  } else if (pathname === "ace-ticketprocessing-staging.netlify.app") {
    id_site = "7a43db7c-2a03-11ed-8809-7af7a0f7ffb5";
  } else if (pathname === "ace-ticketprocessing.netlify.app") {
    id_site = "08ebc7b0-5b85-11ed-99bd-12086ced55af";
  } else if (pathname === "leavenworth-processing-staging.netlify.app") {
    id_site = "46b8a5e5-c1d0-420e-b825-c09712ee8665";
  } else if (pathname === "leavenworth-processing.netlify.app") {
    id_site = "267ccc30-1bdd-11ed-8edb-e6f96eaf3014";
  } else if (pathname === "spplus-ticketprocessing-staging.netlify.app") {
    id_site = "884f1f0b-765c-40b6-be5d-22bb6768e4fe";
  } else if (pathname === "spplus-ticketprocessing.netlify.app") {
    id_site = "2d689158-dcca-4e49-94f3-5ef82439228e";
  } else if (pathname === "duncan-ticketprocessing-staging.netlify.app") {
    id_site = "a2a5d53d-1dd5-4dfb-8672-bde84e4dbd3f";
  } else if (pathname === "duncan-ticketprocessing.netlify.app") {
    id_site = "c9a69cf6-c066-44f2-a78a-7b800f11fb8c";
  } else if (pathname === "payment-parkloyalty.netlify.app") {
    id_site = "7a43db7c-2a03-11ed-8809-7af7a0f7ffb5";
  } else {
    // id_site = "7b4bc424-a7dc-11ed-a1e8-42e281654476";
    id_site = "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d"
  }

  return id_site;
}

export function googlemapsiteid() {
  let siteinfo = Cookies.get("siteinfo");
  let leavenid = "46b8a5e5-c1d0-420e-b825-c09712ee8665";
  let leavenworthProduction = "267ccc30-1bdd-11ed-8edb-e6f96eaf3014";
  let site_id1 = "7a43db7c-2a03-11ed-8809-7af7a0f7ffb5";
  let follyid = "a98dcd6a-cfc2-11ed-ad98-66070e279278";
  let follyproductionid = "33370b0e-e5fc-11ed-8b64-665980bd7c45";
  let mansfieldStaging = "c293c922-fa4c-11ed-b4ca-ae854bfaee9a";
  let mansfieldProd = "98ccd4bc-fa4c-11ed-8b64-665980bd7c45";
  let greenvillesc = "68ea3b5c-172f-4f33-8d55-e76c301ad212";
  let aceProduction = "08ebc7b0-5b85-11ed-99bd-12086ced55af";
  let lazstaging = "9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e"
  let lazProd = "8e9bc50c-95ab-11ee-9a11-c20b99673619"
  let kalamazooStage = "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8"
  let kalamazooProd = "183db4ec-a15e-11ee-b39a-bebc79a4d1a3"
  let bismarckStage = "9db6bd50-3681-11ee-b4ca-ae854bfaee9a"
  let parkIOPStage = "7fc01b7c-c982-11ee-be9c-72ab48aba21d"
  let parkIOPProd = "c6443f42-c982-11ee-b39a-bebc79a4d1a3"
  let bismarckProd = "ead64c86-3681-11ee-9c41-6ef2fb394a83"
  let parkBangorStage = "b87d467a-dbd3-11ee-be9c-72ab48aba21d"
  let oceanCityStage = "7b4bc424-a7dc-11ed-a1e8-42e281654476"
  let oceanCity = "da3c1178-a07e-11ee-8147-56d1ee6b2413"
  let parkBangorProd = "3aba432e-dbd7-11ee-845b-0602968307f0"
  let riverOaksStage = "f3062dac-fe2e-11ee-ae3f-92040ec4c6a4"
  let ashLandStage = "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4"
  let riverOaksProd = "46dd302e-fe2f-11ee-aa58-82967aaeaa80"
  let ashLandProd = "28022506-fe2f-11ee-aa58-82967aaeaa80"
  let beaufort_stage = "2b6d81e0-0d25-11ef-8e44-76f8a1a040d2"
  let beaufort_prod = "3e2116e8-0d26-11ef-9818-ee2c79e2244c"
  let hartfort_stage = '0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d';
  let hartfort_prod = '5bd20490-dc2f-11ee-bf9e-72127457a419';
  let cohasset_stage = 'b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2';
  let cohasset_prod = '30c9b390-2ec5-11ef-ba31-ca0a5add8b0c';
  let peak_parking_stage = 'fdbcf868-3ec3-11ef-8e44-76f8a1a040d2';
  let peak_parking_prod = '23697ca0-3e04-11ef-85dc-e665cc09bf86';
  let park_septa_stage = 'cd666208-44ae-11ef-b128-de29d549d608';
  let park_septa_prod = '4fa2e26e-44af-11ef-ba31-ca0a5add8b0c';
  let Charleston_stage = '7b9fdc62-22ed-11ee-b4ca-ae854bfaee9a';
  let Charleston_prod = 'cdb0262e-22ed-11ee-bd8f-42803422fd7d';
  let KCMO_stage = '70f9c966-4985-11ef-8e44-76f8a1a040d2';
  let KCMO_prod = 'a95c3734-4986-11ef-98e9-2a370f00fe52';
  var centerMarker = "";

  if (siteinfo === leavenid || siteinfo === leavenworthProduction) {
    centerMarker = { lat: 47.5962, lng: -120.6615 };
  }
  if (siteinfo === site_id1 || siteinfo === aceProduction) {
    centerMarker = { lat: 33.037, lng: -117.292 };
  }
  if (siteinfo === follyid || siteinfo === follyproductionid) {
    centerMarker = { lat: 32.6552, lng: -79.9404 };
  }
  if (siteinfo === mansfieldStaging || siteinfo === mansfieldProd) {
    centerMarker = { lat: 40.763901, lng: -82.503891 };
  }
  if (siteinfo === greenvillesc) {
    centerMarker = { lat: 34.852619, lng: -82.394012 };
  }
  if (siteinfo === lazstaging || siteinfo === lazProd) {
    centerMarker = { lat: 39.0119, lng: -98.4842 };
  }
  if(siteinfo === kalamazooStage || siteinfo === kalamazooProd) {
    centerMarker = {lat: 42.2917, lng: -85.5872};
  }
  if(siteinfo === bismarckStage || siteinfo === bismarckProd) {
    centerMarker = { lat: 46.825905,lng: -100.778275 }
  }
  if(siteinfo === parkIOPStage || siteinfo === parkIOPProd) {
    centerMarker = { lat: 32.7987, lng: -79.7584 }
  }
  if(siteinfo === parkBangorStage || siteinfo === parkBangorProd) {
    centerMarker = { lat: 44.808147, lng: -68.795013 }
  }
  if(siteinfo === oceanCityStage || siteinfo === oceanCity) {
    centerMarker = { lat:  38.336502, lng:  -75.084908 }
  }
  if(siteinfo === ashLandStage || siteinfo === ashLandProd) {
    centerMarker = { lat:  42.194576, lng:  -122.709480 }
  }
  if(siteinfo === riverOaksStage || siteinfo === riverOaksProd) {
    centerMarker = { lat:  29.7475, lng:  -95.4331 }
  }
  if(siteinfo === beaufort_stage || siteinfo === beaufort_prod) {
    centerMarker = { lat:  34.7182, lng:  -76.6638 }
  }
  if (siteinfo === hartfort_stage || siteinfo === hartfort_prod) {
    centerMarker = {lat: 41.7637111, lng: -72.6850932};
  }
  if (siteinfo === cohasset_stage || siteinfo === cohasset_prod) {
    centerMarker = {lat: 42.241718, lng: -70.811958};
  } 
  if (siteinfo === peak_parking_stage || siteinfo === peak_parking_prod) {
    centerMarker = {lat: 32.914622, lng: -97.042770};
  } 
  if (siteinfo === park_septa_stage || siteinfo === park_septa_prod) {
    centerMarker = {lat: 41.2033216, lng: -77.1945247};
  } 
  if (siteinfo === Charleston_stage || siteinfo === Charleston_prod) {
    centerMarker = {lat: 32.776566, lng: -79.930923};
  }
  if (siteinfo === KCMO_stage || siteinfo === KCMO_prod) {
    centerMarker = {lat: 39.0997, lng: -94.5786};
  }

  return centerMarker;
}

export function changeticket() {
  const leavenworthStaging = "46b8a5e5-c1d0-420e-b825-c09712ee8665";
  const leavenworth = "267ccc30-1bdd-11ed-8edb-e6f96eaf3014";
  const lazstaging = "9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e";
  const lazProd = "8e9bc50c-95ab-11ee-9a11-c20b99673619";
  const siteid = Cookies.get("siteinfo");

  if (siteid === leavenworthStaging || siteid === leavenworth) {
    return "Infraction";
  } else if (siteid === lazProd || siteid === lazstaging) {
    return "Invoice"
  } else if (assignsiteid() === "7b4bc424-a7dc-11ed-a1e8-42e281654476" || assignsiteid() === "da3c1178-a07e-11ee-8147-56d1ee6b2413") {
    return "Summons/Invoice";
  } else {
    return "Ticket";
  }
}

export function changetotal() {
  const pathname = window.location.hostname;

  if (
    pathname === "leavenworth-processing-staging.netlify.app" ||
    pathname === "leavenworth-processing.netlify.app"
  ) {
    return "Infraction";
  } else if (
    pathname === "laz-processing.netlify.app" || 
    pathname === "processing.parkcountryclubplaza.com" || 
    pathname === "lazstaging-processing.netlify.app" || 
    pathname === "staging-processing.parkcountryclubplaza.com"
  ) {
    return "Invoice"
  } else if (assignsiteid() === "7b4bc424-a7dc-11ed-a1e8-42e281654476") {
    return "Summons/Invoice";
  } else {
    return "Citation";
  }
}

export const regexEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;