import HelicalReport from "./HelicalReportsLoader";

const CitationReport = () => {
  return <HelicalReport reportName={'Citation Reports'} reportUrlPath={'1689683959686&file=7719c01d-af3a-42a3-ac69-d7e1b9c19d0e.efw'} key={'citationReport'} />
}

const DailyAppealsAnalysis = () => {
  return <HelicalReport reportName={'Daily Appeals Analysis'} reportUrlPath={'1689665606615/1693164321634&file=f1f62626-8ef9-4754-bfeb-a1c68d9ec76b.efw'} key={'dailyAppealsAnalysisReport'} />
}

const EscalationDashboard = () => {
  return <HelicalReport reportName={'Daily Escalation Report'} reportUrlPath={'1689683959686/1695012778783&file=d5e41a42-2b37-4590-afcf-aee9abe22c1e.efw'} key={'escalationReport'} />
}

const MonthlyAppealAnalysis = () => {
  return <HelicalReport reportName={'Monthly Appeal Analysis'} reportUrlPath={'1689683959686&file=8370e5ae-ff33-475d-8af4-3d79c3efc1d4.efw'} key={'monthlyAppealsAnalysisReport'} />
}

const RoDataReport = () => {
  return <HelicalReport reportName={'Monthly RO Analysis'} reportUrlPath={'1689683959686&file=7fbb1e7e-848a-4da0-9761-5546129d4928.efw'} key={'monthlyRoAnalysisReport'} />
}

const MonthlyReconReport = () => {
  return <HelicalReport reportName={'Monthly Reconciliation Report'} reportUrlPath={'1689683959686&file=67411e7b-eb80-47c9-92ef-955fb9833db8.efw'} key={'monthlyReconReport'} />
}

const MonthlyRoSuccessReport = () => {
  return <HelicalReport reportName={'Monthly RO Success Report'} reportUrlPath={'1689683959686&file=ef958ab6-ffe9-4c65-bd8d-cfaef2750dad.efw'} key={'monthlyRoSuccessReport'} />
}

const MonthlyRoFailedReport = () => {
  return <HelicalReport reportName={'Monthly RO Failed Report'} reportUrlPath={'1689683959686/1690746751101&file=d4fa174e-9685-4f1e-8a7d-4cbc7b43e203.efw'} key={'monthlyRoFailedReport'} />
}

const DailyRoSuccessReport = () => {
  return <HelicalReport reportName={'Daily RO Success Report'} reportUrlPath={'1689683959686/1690746751101&file=1105b59a-3304-4e02-9853-4a96edcc10f7.efw'} key={'dailyRoSuccessReport'} />
}

const DailyRoFailedReport = () => {
  return <HelicalReport reportName={'Daily RO Failure Report'} reportUrlPath={'1689683959686/1690746751101&file=81dc7755-448d-47c5-96e5-6308ca3377f0.efw'} key={'monthlyRoFailedReport'} />
}

const DailyRoProgressReport = () => {
  return <HelicalReport reportName={'Daily RO In Progress Report'} reportUrlPath={'1689683959686/1690746751101&file=7023f598-0ebd-4be3-a011-14c96e1374cb.efw'} key={'monthlyRoFailedReport'} />
}

const ProcessingReport = () => {
  return <HelicalReport reportName={'Processing'} reportUrlPath={'1689683959686/1694181411134/1696583820125&file=f74f7c9d-2ca5-4aeb-9bd6-88de11e0d319.efw'} key={'processingReport'} />
}

const DashboardAppealReport = () => {
  return <HelicalReport reportName={'Appeal'} reportUrlPath={'1689683959686/1694181411134/1696583820125&file=2afcb6b1-8dd3-4c5f-b4f9-f35bbd820897.efw'} key={'dashboardApeal'} />
}

const DashboardDMVLookup = () => {
  return <HelicalReport reportName={'DMV Look Up'} reportUrlPath={'1689683959686/1694181411134/1696583820125&file=cbe599ff-2307-4259-bfdd-8ddee5b86d37.efw'} key={'dashboardRoReport'} />
}

const DashboardRevenueReport = () => {
  return <HelicalReport reportName={'Revenue'} reportUrlPath={'1689683959686/1694181411134&file=8671184e-9515-4429-b708-5750940b1ec3.efw'} key={'dashboardRevenueReport'} />
}

const DailyNoticeSentReport = () => {
  return <HelicalReport reportName={'Daily Notices Mailed'} reportUrlPath={'1689683959686/1694892868713&file=c6f7abe8-152e-48b0-a757-38e77715ed55.efw'} key={'dailyNoticeSentReport'} />
}

const DailyNoticeCreatedReport = () => {
  return <HelicalReport reportName={'Daily Notices Created'} reportUrlPath={'1689683959686/1694892868713&file=fe2806e8-ac36-45cc-96c4-b75e5ba46344.efw'} key={'dailyNoticeCreatedReport'} />
}

const MonthlyNoticeSentReport = () => {
  return <HelicalReport reportName={'Monthly Notices Sent'} reportUrlPath={'1689683959686/1694892868713&file=7738b39a-069c-4911-a63b-48a1e63bbf99.efw'} key={'monthlyNoticeSentReport'} />
}

const MonthlyNoticeCreatedReport = () => {
  return <HelicalReport reportName={'Monthly Notices Created'} reportUrlPath={'1689683959686/1694892868713&file=6b3947ad-0cb7-4a7e-94da-ce6dd8ba0016.efw'} key={'monthlyNoticeCreatedReport'} />
}

const TicketAnalyticsReport = () => {
  return <HelicalReport reportName={'Plate Analysis'} reportUrlPath={'1689683959686/1692557831268&file=44890e29-0184-40d0-9e1a-6773d5981659.efw'} key={'ticketAnalytics'} />
}

const DailyReconciliationReport = () => {
  return <HelicalReport reportName={'Daily Reconciliation Report'} reportUrlPath={'1689683959686/1696843683432&file=09564215-2837-4cb0-a999-ca3809113f82.efw'} key={'ticketAnalytics'} />
}

const PaymentsByUserReport = () => {
  return <HelicalReport reportName={'OTC User Payment Report'} reportUrlPath={'1689683959686/1708300553450&file=0c404688-c2b1-4e26-b878-915cca479eb6.efw'} key={'paymentsByUser'} />
}

const CitationFineThresholdReport = () => {
  return <HelicalReport reportName={'High Value Plate Report'} reportUrlPath={'1689683959686/1694181411134/1696583820125&file=4eab2226-89b8-404b-bf3a-449c2b1fcd0c.efw'} key={'citationFineThreshold'} />
}

const CameraData = () => {
  return <HelicalReport reportName={'Camera Data'} reportUrlPath={'1689683959686/1712232393633&file=e4228ba6-3559-4c41-b240-e9f2fb35a4b4.efw'} key={'cameraData'} />
}

const AppealAgeReport = () => {
  return <HelicalReport reportName={'Appeal Age Report'} reportUrlPath={'1689683959686/1705400108470&file=4dea3626-9c7d-4885-b927-2db2582b0903.efw'} key={'appealAgeReport'} />
}

const PaymentAfterFirstNotice = () => {
  return <HelicalReport reportName={'Payments After First Notice'} reportUrlPath={'1689683959686/1695012778783/1706014638840&file=e5e6aedf-79f5-4d05-89d0-d44dfaa413a4.efw'} key={'paymentsAfterFirstNotice'} />
}

const PaymentAfterSecondNotice = () => {
  return <HelicalReport reportName={'Payments After Second Notice'} reportUrlPath={'1689683959686/1695012778783/1706014638840&file=e1166905-f134-4956-8bc0-3e3f7f89acd7.efw'} key={'paymentsAfterSecondNotice'} />
}

const MotoristPayments = () => {
  return <HelicalReport reportName={'Motorist Payments'} reportUrlPath={'1689683959686/1708300553450&file=99985650-057a-468a-94e8-9bc3c005c4d7.efw'} key={'motoristPayments'} />
}

const OTCCardPayments = () => {
  return <HelicalReport reportName={'OTC Card Payments'} reportUrlPath={'1689683959686/1708300553450&file=0836be3f-d247-4980-9059-abe6c2ca74c4.efw'} key={'otcCardPayments'} />
}

const OTCCheckPayments = () => {
  return <HelicalReport reportName={'OTC Check Payments'} reportUrlPath={'1689683959686/1708300553450&file=00fa614b-1c9f-439c-948c-0f05f5d92acc.efw'} key={'otcCheckPayments'} />
}

const OTCCashPayments = () => {
  return <HelicalReport reportName={'OTC Cash Payments'} reportUrlPath={'1689683959686/1708300553450&file=134b8bdb-c430-478e-bbd3-de24cbf7f95d.efw'} key={'otcCashPayments'} />
}

const PaymentsByType = () => {
  return <HelicalReport reportName={'Payments By Type'} reportUrlPath={'1689683959686/1708300553450&file=87d2cf60-71ec-415a-b74f-024068f55f8d.efw'} key={'paymentsByType'} />
}

const RefundsOTC = () => {
  return <HelicalReport reportName={'Refunds Report'} reportUrlPath={'1689683959686/1708300553450&file=7dfadd60-c468-4dca-86e4-3e6278e42d6b.efw'} key={'refundsOTC'} />
}

const CollectionReport = () => {
  return <HelicalReport reportName={'Collection Report'} reportUrlPath={'1689683959686/1708300553450&file=be4e5894-d60a-4e66-acf5-d919f9a11cf7.efw'} key={'collectionReport'} />
}

const AgedHearingReport = () => {
  return <HelicalReport reportName={'Aged Hearing Report'} reportUrlPath={'1689665606615/1708095774926/1710362737426&file=921722a7-bf66-436b-a745-d6d48c0eebc0.report'} key={'agedHearingReport'} />
}

const IVRReport = () => {
  return <HelicalReport reportName={'IVR Report'} reportUrlPath={'1689683959686/1708300553450&file=77e7aa99-038c-44af-9b75-07c47762cd66.efw'} key={'ivrReport'} />
}

const LockboxReport = () => {
  return <HelicalReport reportName={'Lockbox Report'} reportUrlPath={'1689683959686/1708300553450&file=679aa55d-535b-45a9-b006-fc23e5064ba3.efw'} key={'lockboxReport'} />
}

const WebPaymentsReport = () => {
  return <HelicalReport reportName={'Web Payments Report'} reportUrlPath={'1689683959686/1708300553450&file=c5ef3139-f907-4097-8a39-ca4ac30e9390.efw'} key={'webPaymentsReport'} />
}

const RefundEligibleReport = () => {
  return <HelicalReport reportName={'Refund Eligible Report'} reportUrlPath={'1689683959686/1708300553450&file=5046255c-c426-45b7-b63d-bd04b68072a9.efw'} key={'refundEligibleReport'} />
}

const InvoicePaymentReport = () => {
  return <HelicalReport reportName={'Invoice Payment Report'} reportUrlPath={'1689683959686/1708300553450&file=b118e1fa-07ac-4fff-8196-dfc328b7ac4e.efw'} key={'invoicePaymentReport'} />
}

const SummonsPaymentReport = () => {
  return <HelicalReport reportName={'Summons Payment Report'} reportUrlPath={'1689683959686/1708300553450&file=159ed94c-5bd8-460a-a45a-807bba93d32b.efw'} key={'summonsPaymentReport'} />
}

//copy of above report for other sites than OCMD
const TicketPaymentReport = () => {
  return <HelicalReport reportName={'Ticket Payment Report'} reportUrlPath={'1689683959686/1708300553450&file=135ab96c-57ba-4f54-9f07-1e295cfa6234.efw'} key={'ticketPaymentReport'} />
}

const IssuedBillingReport = () => {
  return <HelicalReport reportName={'Issued Billing Report'} reportUrlPath={'1689683959686/1708300553450/1714493299441&file=7adb315e-39fe-406d-b7c9-a44d1671541d.efw'} key={'issuedBillingReport'} />
};

const PaidAfterCollection = () => {
  return <HelicalReport reportName={'Paid After Collection'} reportUrlPath={'1689683959686/1695012778783/1715013676447&file=b0345610-30fd-4a16-a307-0bf2a71b686e.efw'} key={'paidAfterCollection'} />
};

const SkeletalPaymentReport = () => {
  return <HelicalReport reportName={'Skeletal Payment Report'} reportUrlPath={'1689683959686/1708300553450/1716813261654&file=ad229e87-bc7c-45af-9da3-e17b6ccf34b3.efw'} key={'skeletalPaymentReport'} />
};

const TicketCountSummaryReport = () => {
  return <HelicalReport reportName={'Ticket Count Summary Report'} reportUrlPath={'1689683959686/1708300553450/1715795858728&file=d211efe1-d8c0-4934-a99e-2411eae60b8b.efw'} key={'ticketCountSummaryReport'} />
};

const BismarckDailyParkingWarrants = () => {
  return <HelicalReport reportName={'Daily Parking Tickets Warrants'} reportUrlPath={'1689683959686/1718187995394&file=8d16bbdf-af4d-4c65-8124-07a5c79cd0e3.efw'} key={'bismarckDailyParkingWarrants'} />
};

const BismarckDailyPaidParkingTickets = () => {
  return <HelicalReport reportName={'Daily Paid Parking Tickets'} reportUrlPath={'1689683959686/1718187995394&file=a4ce328b-ec5d-425a-bb15-9f55696b8bd7.efw'} key={'bismarckDailyPaidParkingTickets'} />
};

const BismarckDailyParkingTickets = () => {
  return <HelicalReport reportName={'Daily Parking Tickets'} reportUrlPath={'1689683959686/1718187995394&file=72d40c71-32bf-4014-8e6e-4917f2d205c5.efw'} key={'bismarckDailyParkingTickets'} />
};

const BismarckDailyClearedParkingTickets = () => {
  return <HelicalReport reportName={'Daily Cleared Parking Tickets'} reportUrlPath={'1689683959686/1718187995394&file=0668d532-d56f-4d1b-8201-b8f2ca575f76.efw'} key={'bismarckDailyClearedParkingTickets'} />
};

const BismarckParkingTicketsEntered = () => {
  return <HelicalReport reportName={'Parking Tickets Entered'} reportUrlPath={'1689683959686/1718187995394&file=02bafadb-a2f7-4c10-ac02-04615f1f8a5b.efw'} key={'bismarckParkingTicketsEntered'} />
};

const PartialPaymentsSource = () => {
  return <HelicalReport reportName={'Partial Payments Source'} reportUrlPath={'1689683959686/1708300553450&file=177a71ac-287c-4bf4-94ef-5e4699298edf.efw'} key={'partialPaymentsSource'} />
};

const ParkingTicketsStatusChangeReport = () => {
  return <HelicalReport reportName={'Parking Tickets Status Change Report'} reportUrlPath={'1689683959686/1718187995394&file=9c828025-148c-470d-acc7-91496f5ae7b2.efw'} key={'parkingTicketsStatusChangeReport'} />
};

const DailyFailedNoticesReport = () => {
  return <HelicalReport reportName={'Daily Failed Notices'} reportUrlPath={'1689683959686/1694892868713&file=403687f2-046c-4082-9d6a-e41195b83809.efw'} key={'dailyFailedNoticesReport'} />
};

const DailyFailedNoticesReportOCMD = () => {
  return <HelicalReport reportName={'Daily Failed Notices'} reportUrlPath={'1689683959686/1694892868713&file=75e00a92-0ae9-43a0-8148-43b00e95d8c4.efw'} key={'dailyFailedNoticesReportOCMD'} />
};

const DailyEmailedNoticesReport = () => {
  return <HelicalReport reportName={'Daily Emailed Notices'} reportUrlPath={'1689683959686/1694892868713&file=9cc15fd3-efc0-48f8-99ca-01d97f83bbb2.efw'} key={'dailyEmailedNoticesReport'} />
};

const DailyEmailedNoticesReportOCMD = () => {
  return <HelicalReport reportName={'Daily Emailed Notices'} reportUrlPath={'1689683959686/1694892868713&file=7ed595fa-a8aa-4fe1-9518-d2764bf36bdb.efw'} key={'dailyEmailedNoticesReportOCMD'} />
};

const MonthlyEmailedNotices = () => {
  return <HelicalReport reportName={'Monthly Emailed Notices'} reportUrlPath={'1689683959686/1694892868713&file=5e1f40db-0ced-4fa2-b4e2-7ccc326d15ed.efw'} key={'monthlyEmailedNotices'} />
};

const MonthlyEmailedNoticesOCMD = () => {
  return <HelicalReport reportName={'Monthly Emailed Notices'} reportUrlPath={'1689683959686/1694892868713&file=d06fe979-9c46-4d9e-93b3-bf144a3b429f.efw'} key={'monthlyEmailedNoticesOCMD'} />
};

const NFLAndChargebackReportOCMD = () => {
  return <HelicalReport reportName={'NFL And Chargeback Report'} reportUrlPath={'1689683959686/1708300553450&file=e5e6c710-a61c-40ed-a98f-906f230faae1.efw'} key={'nflAndChargebackReportOCMD'} />
};

const HeartlandCSVReportOCMD = () => {
  return <HelicalReport reportName={'Heartland CSV Report'} reportUrlPath={'1689683959686/1708300553450/1718055936285&file=aea509f9-949a-4048-81d5-99d82862c261.efw'} key={'heartlandCSVReport'} />
};

const HelicalReports = {
  AgedHearingReport,
  AppealAgeReport,
  BismarckDailyParkingWarrants,
  BismarckDailyPaidParkingTickets,
  BismarckDailyParkingTickets,
  BismarckDailyClearedParkingTickets,
  BismarckParkingTicketsEntered,
  CameraData,
  CitationFineThresholdReport,
  CitationReport,
  CollectionReport,
  DailyAppealsAnalysis,
  DailyEmailedNoticesReport,
  DailyEmailedNoticesReportOCMD,
  DailyFailedNoticesReport,
  DailyFailedNoticesReportOCMD,
  MonthlyEmailedNotices,
  MonthlyEmailedNoticesOCMD,
  DailyNoticeCreatedReport,
  DailyNoticeSentReport,
  DailyReconciliationReport,
  DailyRoFailedReport,
  DailyRoProgressReport,
  DailyRoSuccessReport,
  DashboardAppealReport,
  DashboardDMVLookup,
  DashboardRevenueReport,
  EscalationDashboard,
  HeartlandCSVReportOCMD,
  InvoicePaymentReport,
  IssuedBillingReport,
  IVRReport,
  LockboxReport,
  MonthlyAppealAnalysis,
  MonthlyNoticeCreatedReport,
  MonthlyNoticeSentReport,
  MonthlyReconReport,
  MonthlyRoFailedReport,
  MonthlyRoSuccessReport,
  MotoristPayments,
  NFLAndChargebackReportOCMD,
  OTCCardPayments,
  OTCCashPayments,
  OTCCheckPayments,
  PaidAfterCollection,
  ParkingTicketsStatusChangeReport,
  PartialPaymentsSource,
  PaymentAfterFirstNotice,
  PaymentAfterSecondNotice,
  PaymentsByType,
  PaymentsByUserReport,
  ProcessingReport,
  RefundEligibleReport,
  RefundsOTC,
  RoDataReport,
  SkeletalPaymentReport,
  SummonsPaymentReport,
  TicketAnalyticsReport,
  TicketCountSummaryReport,
  TicketPaymentReport,
  WebPaymentsReport,
};

export default HelicalReports;