import React, { useState, useEffect } from 'react';
import { state } from '../Common/dropDownOptions'
import Select from 'react-select'
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import dashboardService from '../../Services/dashboard.service';

import moment from 'moment';
import {refreshTokenMiddleware} from '../customMiddelware'

// check if site is follybeach
const isFollyBeach = () => {
    if (Cookies.get('siteinfo') === 'a98dcd6a-cfc2-11ed-ad98-66070e279278' || Cookies.get('siteinfo') === '33370b0e-e5fc-11ed-8b64-665980bd7c45') {
        return true
    } else return false;
}

const getState = () => {
    if ((isFollyBeach())) {
        return { value: "CT", label: 'CONNECTICUT' };
    } else {
        return { value: "CA", label: 'CALIFORNIA' };
    }
}

const ROInformationModal = ({ closeFn = () => null, open = false, citeId }) => {
    const dispatch = useDispatch()
    const today = moment().format('YYYY-MM-DDThh:mm');

      const [inputs, setInputs] = useState({
        name: '',
        address: '',
        city: '',
        state: getState(),
        zip: "",
        reason: '',
        return_date: today
    })

    const err = {
        name: '',
        address: '',
        reason: '',
        email:'',
        phone:'',
        city:'',
        zip:''
    }
    const [errors, setErrors] = useState(err)
    const [displayMsg, setDisplayMsg] = React.useState('');
    const [isBtnLoader, setBtnLoader] = useState(false);

    useEffect(() => {
        setDisplayMsg('')
        setErrors({
            ...errors,
            name: '',
            address: '',
            reason: '',
            email:'',
            phone:'',
            city:'',
            zip:''
        })
        setInputs({
            ...inputs,
            name: '',
            address: '',
            city: '',
            state: getState(),
            zip: "",
            reason: '',
            return_date: today,
            email:'',
            phone:''
        })

    }, [open])

    const onChangeInputHandle = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    
        if (value === '' && (name !== 'phone' && name !== 'email')) {
            setErrors({ ...errors, [name]: `Enter ${name.replace(/_/g, ' ')}` });
        } else {
            setErrors({ ...errors, [name]: '' });
        }
    };
    

    const handleOptionChange = (option, type) => {
        setInputs({ ...inputs, [type]: option })
        if (!option) {
            setErrors({ ...errors, [type]: 'Select state.' })
        } else {
            setErrors({ ...errors, [type]: '' })
        }
    }

    const onSubmitROinfo = async (e) => {
        e.preventDefault()
        let isValid = false

        Object.entries(inputs).forEach(([key, value]) => {
          if (
            value === "" &&
            (key === "name" ||
              key === "address" ||
              key === "reason" ||
              key === "city" ||
              key === "zip")
          ) {
            isValid = true;
            errors[key] = `Enter ${key.replace(/_/g, " ")}`;
          }
          // Email validation
          if (key === "email") {
            if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
              isValid = true;
              errors.email = "Enter a valid email address";
            }
          }
          if (key === "phone") {
            if (value && !/^\d{10}$/.test(value)) {
              isValid = true;
              errors.phone = "Enter a valid 10-digit phone number";
            }
          }
        });

        setErrors({ ...errors })

        if (!isValid) {
            setBtnLoader(true)
            let payload = { ...inputs }
            payload.state = inputs.state.value
            payload.return_date = inputs.return_date + ':00.000Z'
            
            dataROupdatefun()
            async function dataROupdatefun(){
                try {
                    const result = await dashboardService.updateROinfoAPI(citeId, payload)
                    if (result.data.success) {
                        closeFn()
                        dispatch({ type: "SET_API_RESPONSE", payload: true });
                    } else {
                        setDisplayMsg("Something went wrong")
                    }
                    setBtnLoader(false)
                } catch (err) {
                    if (err.response.status === 401) {
                        refreshTokenMiddleware(dispatch)
                        dataROupdatefun()
                    } else {
                        setDisplayMsg("Something went wrong")
                        setBtnLoader(false)
                    }
                }
            }

        }
    }


    return ( 
        <>
            {open &&
                <div className="main-body-modal">
                    <div className='main-modal cstm-ro-address-modal'>
                        <div className="cstm-close cst-ro">
                            <button onClick={closeFn}>×</button>
                        </div>
                        <div className="modal-scroll-event">
                            <div className="ctsm-modal-header">
                                <div className="cstm-modal-title">
                                    <h3>RO Information</h3>
                                </div>
                            </div>
                            <div className="cstm-modal-body ro-information-scroll">
                                {displayMsg &&
                                    <div className="alert alert-danger" role="alert">
                                        {displayMsg}
                                    </div>
                                }
                                <form onSubmit={onSubmitROinfo}>
                                    <div className='cstm-inputfield'>
                                        <span>Name *</span>
                                        <input type="text" name='name' placeholder='name' value={inputs.name} onChange={(e) => onChangeInputHandle(e)} />
                                        <span className="errormessage">{errors.name}</span>
                                    </div>
                                    <div className='cstm-inputfield'>
                                        <span>Address *</span><br />
                                        <textarea name="address" cols="30" rows="4" placeholder="address" value={inputs.address} onChange={(e) => onChangeInputHandle(e)} className='mb-0' ></textarea>
                                        <span className="errormessage">{errors.address}</span>
                                    </div>
                                   <div className='row'>
                                        <div className='col-6'> 
                                          <div className='cstm-inputfield'>
                                            <span>Email</span>
                                            <input type="text" name='email' placeholder='email' value={inputs.email} onChange={(e) => onChangeInputHandle(e)} />
                                            <span className="errormessage">{errors.email}</span>
                                        </div></div>
                                        <div className='col-6'> 
                                          <div className='cstm-inputfield'>
                                            <span>Phone</span>
                                            <input type="text" name='phone' placeholder='phone' value={inputs.phone} onChange={(e) => onChangeInputHandle(e)} />
                                            <span className="errormessage">{errors.phone}</span>
                                        </div></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>   <div className='cstm-inputfield'>
                                            <span>City *</span>
                                            <input type="text" name='city' placeholder='city' value={inputs.city} onChange={(e) => onChangeInputHandle(e)} />
                                            <span className="errormessage">{errors.city}</span>
                                        </div></div>
                                        <div className='col-6'>
                                            <div className='cstm-inputfield cstm-slk'>
                                                <span>State *</span>
                                                <Select
                                                    name="state"
                                                    options={state}
                                                    onChange={e => handleOptionChange(e, 'state')}
                                                    selectedOption="Active"
                                                    searchable
                                                    value={inputs.state || ''}
                                                />
                                            </div></div>

                                    </div>


                                    <div className='row'>

                                        <div className='col-6'>
                                            <div className='cstm-inputfield'>
                                                <span>Zip *</span>
                                                <input type="text" name='zip' placeholder='zip' value={inputs.zip} onChange={(e) => onChangeInputHandle(e)} />
                                                <span className="errormessage">{errors.zip}</span>
                                            </div></div>

                                        <div className='col-6'>
                                            <div className='cstm-inputfield'>
                                                <span>Effective Date *</span>
                                                <input type="datetime-local" name='return_date' value={inputs.return_date} onChange={(e) => onChangeInputHandle(e)} />
                                            </div>
                                        </div>
                                    </div>




                                    <div className='cstm-inputfield'>
                                        <span>Reason *</span>
                                        <input type="text" name='reason' placeholder='reason' value={inputs.reason} onChange={(e) => onChangeInputHandle(e)} />
                                        <span className="errormessage">{errors.reason}</span>
                                    </div>
                                    <div className="cstm-submit-btn">
                                        <button className="btn" type="submit" disabled={isBtnLoader}>
                                            {isBtnLoader &&
                                                <div className="cstm-loader">
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </div>
                                            }
                                            Change Address
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            }
        </>
    );
};

export default ROInformationModal;