import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { clearMessage } from "../../actions/message";
import dashboardService from '../../Services/dashboard.service';

import { assignsiteid } from "../../Global/site";
import Cookies from 'js-cookie';


const ForgotPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { message } = useSelector(state => state.message);
	const { isLoggedIn } = useSelector((state) => state.auth);
	const [isBtnLoader, setBtnLoader] = useState(false);

	const showSideId = assignsiteid();
	const [username, setUsername] = useState('');
	const [errorMsgU, setErrorMsgU] = useState('');


	const onChangeInput = (e) => {
		dispatch(clearMessage());
		const { value } = e.target
		setUsername(value)
		if (value) {
			setErrorMsgU('')
		} else {
			setErrorMsgU('Enter Username')
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		let exist_error = false
		dispatch(clearMessage());


		if (!username) {
			exist_error = true
			setErrorMsgU('Enter Username')
		}

		if (!exist_error) {
			setBtnLoader(true)
			const payload = {
				'user_name': username,
				'site_id': showSideId
			}
			const result = await dashboardService.requestOTPForgotPassword(payload)

			if (result.data.status) {
				Cookies.set("f_m_once", result.data.data.mobile_otp_nonce);
				navigate('/otp-verify')
			} else {
				dispatch({ type: 'SET_MESSAGE', payload: result.data.message })
				setBtnLoader(false)
			}
		}
	}

	if (isLoggedIn) {
		return <Navigate to={'/'} />
	}

	return (
		<>
			<div className="wrapper citycitiation">
				<div className="container-fluid">
					<div className="row">

						<div className="col-lg-6 col-12 login-align">
							<div className="login-section  main-login-sec">
								{message && (
									<div className="form-group">
										<div className="alert alert-danger" role="alert">
											{message}
										</div>
									</div>
								)}
								<h3>Forgot Password</h3>
								<p>Enter your username that you used to register and verify your OTP that you will receive on your register phone number.</p>
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-12 form-group formgroup-main">
											<label htmlFor="">User name*</label>
											<input type="text" name="user_name" className="form-control" placeholder="Username" value={username} onChange={onChangeInput} />
											<span className="errormessage">
												{errorMsgU}
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col-12 button2">
											<button className="btn" type="submit" disabled={isBtnLoader}>
												{isBtnLoader &&
													<div className="cstm-loader">
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
													</div>
												}
												Send Request
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-6 col-12 login-bgImage">
							<div className="login-cont-main">
								<div className="login-cont-main-sub">Nice to see you again</div>
								<div className="login-cont-main-title">Welcome Back</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword