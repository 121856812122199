import {
  USER_OTP_SUCCESS,
  USER_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_MESSAGE,
  CLEAROUT_USER,
  AGENCY_STATE,
  SITE_UPDATE_INFO
} from "./types";

import AuthService from "../Services/auth.service";
import mainService from "../Services/main.service";
import Cookies from "js-cookie";


export const refreshGuestUserToken = (data) => (dispatch) => {
  return AuthService.refreshGuestUserToken(data).then(
    (data) => {

      if (!data.status) {
        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: USER_OTP_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const clearoutAuthUserData = () => (dispatch) => {
  AuthService.clearoutAuthUserData();

  dispatch({
    type: CLEAROUT_USER,
  });
};

export const refreshtokenforClassComp = () => (dispatch) => {
  let olddata = JSON.parse(Cookies.get("auth"));
  const payload = {
    token: olddata.refresh_token_data.token
  }
  mainService.refreshTokenAPI(payload).then(res => {

    if (res.data.status) {
      olddata.access_token_data = res.data.data.access_token_data
      Cookies.set("auth", JSON.stringify(olddata));
      Cookies.set("token", res.data.data.access_token_data.token);

      dispatch({ type: 'LOGIN_SUCCESS', payload: olddata })

    } else {
      dispatch(clearoutAuthUserData());
      window.location.href = '/'
    }
  }).catch(err => {
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(clearoutAuthUserData());
      window.location.href = '/'
    }
  })
};

export const agencydatasave = (data) => (dispatch) => {
 
  dispatch({
    type: AGENCY_STATE,
    payload: data
  });
  Cookies.set("agency", data.agency_id);
  Cookies.set("isAgency", JSON.stringify(true));
}


export const updatesiteinfo = (data) => (dispatch) => {
  dispatch({
    type: SITE_UPDATE_INFO,
    payload: data
  });
  Cookies.set("siteinfo", data.site_id)
  Cookies.set("siteName", data.site_name)
  Cookies.set("isAgency", JSON.stringify(true));
}