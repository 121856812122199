import HelicalReports from './HelicalReports';
const pathname = window.location.hostname;

const HelicalRoutes = [
  { path: "/agedHearingReport", component: HelicalReports.AgedHearingReport, uiElement: 'PAGE_HEARING_MANAGEMENT_AGED_HEARING_REPORT' },
  { path: "/appealAgeReport", component: HelicalReports.AppealAgeReport, uiElement: 'PAGE_APPEALS_MANAGEMENT_APPEAL_AGE_REPORT' },
  { path: "/cameraData", component: HelicalReports.CameraData, uiElement: 'PAGE_DATA_VIEWS_CAMERA_DATA_REPORT_' },
  { path: "/citationFineThresholdReport", component: HelicalReports.CitationFineThresholdReport, uiElement: 'PAGE_REVENUE_REPORT_OTHER_REPORTS_->_HIGH_VALUE_PLATE_REPORT' },
  { path: "/citationReport", component: HelicalReports.CitationReport, uiElement: 'PAGE_REPORTS_CITATION_ANALYSIS' },
  { path: "/collectionReport", component: HelicalReports.CollectionReport, uiElement: 'PAGE_REVENUE_REPORT_COLLECTIONS_REPORT' },
  { path: "/dailyAppealsAnalysis", component: HelicalReports.DailyAppealsAnalysis, uiElement: 'PAGE_APPEALS_MANAGEMENT_DAILY_APPEAL_ANALYSIS' },
  { path: "/dailyNoticeCreatedReport", component: HelicalReports.DailyNoticeCreatedReport, uiElement: 'PAGE_NOTICE_MANAGEMENT_DAILY_NOTICES_CREATED' },
  { path: "/dailyNoticeSentReport", component: HelicalReports.DailyNoticeSentReport, uiElement: 'PAGE_NOTICE_MANAGEMENT_DAILY_NOTICES_SENT' },
  { path: "/dailyReconciliationReport", component: HelicalReports.DailyReconciliationReport, uiElement: 'PAGE_REVENUE_REPORT_DAILY_RECONCILIATION_REPORT' },
  { path: "/dailyRoFailedReport", component: HelicalReports.DailyRoFailedReport, uiElement: 'PAGE_RO_MANAGEMENT_DAILY_RO_FAILURE_REPORT' },
  { path: "/dailyRoProgressReport", component: HelicalReports.DailyRoProgressReport, uiElement: 'PAGE_RO_MANAGEMENT_DAILY_RO_IN_PROGRESS_REPORT' },
  { path: "/dailyRoSuccessReport", component: HelicalReports.DailyRoSuccessReport, uiElement: 'PAGE_RO_MANAGEMENT_DAILY_RO_SUCCESS_REPORT' },
  { path: "/dashboardAppealReport", component: HelicalReports.DashboardAppealReport, uiElement: 'PAGE_DASHBOARD_APPEAL' },
  { path: "/dashboardDMVLookup", component: HelicalReports.DashboardDMVLookup, uiElement: 'PAGE_DASHBOARD_DMV_LOOKUP' },
  { path: "/dashboardRevenueReport", component: HelicalReports.DashboardRevenueReport, uiElement: 'PAGE_DASHBOARD_REVENUE' },
  { path: "/escalationDashboard", component: HelicalReports.EscalationDashboard, uiElement: 'PAGE_REVENUE_REPORT_DAILY_ESCALATION_REPORT' },
  { path: "/ivrReport", component: HelicalReports.IVRReport, uiElement: 'PAGE_REVENUE_REPORT_IVR_REPORT' },
  { path: "/lockboxReport", component: HelicalReports.LockboxReport, uiElement: 'PAGE_REVENUE_REPORT_LOCKBOX_REPORT' },
  { path: "/monthlyAppealAnalysis", component: HelicalReports.MonthlyAppealAnalysis, uiElement: 'PAGE_APPEALS_MANAGEMENT_MONTHLY_APPEAL_ANALYSIS' },
  { path: "/monthlyNoticeCreatedReport", component: HelicalReports.MonthlyNoticeCreatedReport, uiElement: 'PAGE_NOTICE_MANAGEMENT_MONTHLY_NOTICES_CREATED' },
  { path: "/monthlyNoticeSentReport", component: HelicalReports.MonthlyNoticeSentReport, uiElement: 'PAGE_NOTICE_MANAGEMENT_MONTHLY_NOTICES_SENT' },
  { path: "/monthlyReconReport", component: HelicalReports.MonthlyReconReport, uiElement: 'PAGE_REVENUE_REPORT_MONTHLY_RECONCILIATION_REPORT' },
  { path: "/monthlyRoFailedReport", component: HelicalReports.MonthlyRoFailedReport, uiElement: 'PAGE_RO_MANAGEMENT_MONTHLY_RO_FAILURE_REPORT' },
  { path: "/monthlyRoSuccessReport", component: HelicalReports.MonthlyRoSuccessReport, uiElement: 'PAGE_RO_MANAGEMENT_MONTHLY_RO_SUCCESS_REPORT_' },
  { path: "/otcCardPayments", component: HelicalReports.OTCCardPayments, uiElement: 'PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_BY_CARD' },
  { path: "/otcCashPayments", component: HelicalReports.OTCCashPayments, uiElement: 'PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_BY_CASH' },
  { path: "/otcCheckPayments", component: HelicalReports.OTCCheckPayments, uiElement: 'PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_BY_CHECK' },
  { path: "/paymentAfterFirstNotice", component: HelicalReports.PaymentAfterFirstNotice, uiElement: 'PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_AFTER_FIRST_NOTICE' },
  { path: "/refundsOTC", component: HelicalReports.RefundsOTC, uiElement: 'PAGE_REVENUE_REPORT_REFUNDS_OTC' },
  { path: "/paymentsByType", component: HelicalReports.PaymentsByType, uiElement: 'PAGE_REVENUE_REPORT_PAYMENTS_BY_TYPE' },
  { path: "/paymentsByUserReport", component: HelicalReports.PaymentsByUserReport, uiElement: 'PAGE_REVENUE_REPORT_PAYMENTS_BY_USER_REPORT' },
  { path: "/processingReport", component: HelicalReports.ProcessingReport, uiElement: 'PAGE_DASHBOARD_PROCESSING' },
  { path: "/roDataReport", component: HelicalReports.RoDataReport, uiElement: 'PAGE_RO_MANAGEMENT_MONTHLY_RO_ANALYSIS' },
  { path: "/refundEligibleReport", component: HelicalReports.RefundEligibleReport, uiElement: 'PAGE_REVENUE_REPORT_REFUND_ELIGIBLE_REPORT' },
  { path: "/secondNoticePayment", component: HelicalReports.PaymentAfterSecondNotice, uiElement: 'PAGE_REVENUE_REPORT_OTHER_REPORTS_->_PAYMENTS_AFTER_SECOND_NOTICE' },
  { path: "/ticketAnalyticsReport", component: HelicalReports.TicketAnalyticsReport, uiElement: 'PAGE_TICKET_MANAGEMENT_PLATE_ANALYSIS' },
  { path: "/ticketAnalyticsReport/:plateNumber", component: HelicalReports.TicketAnalyticsReport, uiElement: 'PAGE_TICKET_MANAGEMENT_PLATE_ANALYSIS' },
  { path: "/webPaymentsReport", component: HelicalReports.WebPaymentsReport, uiElement: 'PAGE_REVENUE_REPORT_WEB_PAYMENTS_REPORT' },
  { path: "/invoicePaymentReport", component: HelicalReports.InvoicePaymentReport, uiElement: "PAGE_REVENUE_REPORT_INVOICE_PAYMENT_REPORT" },
  { path: "/issuedBillingReport", component: HelicalReports.IssuedBillingReport, uiElement: "PAGE_TICKET_MANAGEMENT_ISSUED_BILLING_REPORT" },
  { path: "/paidAfterCollectionReport", component: HelicalReports.PaidAfterCollection, uiElement: "PAGE_REVENUE_REPORT_PAID_AFTER_COLLECTION_REPORT" },
  { path: "/skeletalPaymentReport", component: HelicalReports.SkeletalPaymentReport, uiElement: "PAGE_REVENUE_REPORT_SKELETAL_PAYMENT_REPORT" },
  { path: "/ticketCountSummaryReport", component: HelicalReports.TicketCountSummaryReport, uiElement: "PAGE_TICKET_MANAGEMENT_TICKET_COUNT_SUMMARY_REPORT" },
  { path: "/bismarckDailyParkingWarrants", component: HelicalReports.BismarckDailyParkingWarrants, uiElement: "PAGE_REVENUE_REPORT_BISMARCK_DAILY_PARKING_WARRANTS" },
  { path: "/bismarckDailyPaidParkingTickets", component: HelicalReports.BismarckDailyPaidParkingTickets, uiElement: "PAGE_REVENUE_REPORT_BISMARCK_DAILY_PAID_PARKING_TICKETS" },
  { path: "/bismarckDailyParkingTickets", component: HelicalReports.BismarckDailyParkingTickets, uiElement: "PAGE_REVENUE_REPORT_BISMARCK_DAILY_PARKING_TICKETS" },
  { path: "/bismarckDailyClearedParkingTickets", component: HelicalReports.BismarckDailyClearedParkingTickets, uiElement: "PAGE_REVENUE_REPORT_BISMARCK_DAILY_CLEARED_PARKING" },
  { path: "/bismarckParkingTicketsEntered", component: HelicalReports.BismarckParkingTicketsEntered, uiElement: "PAGE_REVENUE_REPORT_BISMARCK_PARKING_TICKETS_ENTERED" },
  { path: "/partialPaymentsSource", component: HelicalReports.PartialPaymentsSource, uiElement: "PAGE_REVENUE_REPORT_PARTIAL_PAYMENT_SOURCE" },
  { path: "/parkingTicketsStatusChangeReport", component: HelicalReports.ParkingTicketsStatusChangeReport, uiElement: "PAGE_REVENUE_REPORT_PARKING_TICKETS_STATUS_CHANGE_REPORT" },
]

const additionalRoutes = [];
if (pathname === "processing.parkocmd.com" || pathname === 'processing-parkocmd.netlify.app' || pathname === 'staging-processing.parkocmd.com' || pathname === 'localhost') {
  additionalRoutes.push({ path: "/dailyFailedNoticesReport", component: HelicalReports.DailyFailedNoticesReportOCMD, uiElement: "PAGE_NOTICE_MANAGEMENT_DAILY_FAILED_NOTICES_REPORT" });
  additionalRoutes.push({ path: "/dailyEmailedNoticesReport", component: HelicalReports.DailyEmailedNoticesReportOCMD, uiElement: "PAGE_NOTICE_MANAGEMENT_DAILY_EMAILED_NOTICES_REPORT" });
  additionalRoutes.push({ path: "/monthlyEmailedNoticesReport", component: HelicalReports.MonthlyEmailedNoticesOCMD, uiElement: "PAGE_NOTICE_MANAGEMENT_MONTHLY_EMAILED_NOTICES" });
  additionalRoutes.push({ path: "/nflAndChargebackReport", component: HelicalReports.NFLAndChargebackReportOCMD, uiElement: "PAGE_REVENUE_REPORT_NFL_AND_CHARGEBACK_REPORT" });
  additionalRoutes.push({ path: "/heartlandCSVReport", component: HelicalReports.HeartlandCSVReportOCMD, uiElement: "PAGE_REVENUE_REPORT_HEARTLAND_CSV_REPORT" });
  additionalRoutes.push({ path: "/summonsPaymentReport", component: HelicalReports.SummonsPaymentReport, uiElement: "PAGE_REVENUE_REPORT_SUMMONS_PAYMENT_REPORT" })
} else {
  additionalRoutes.push({ path: "/dailyFailedNoticesReport", component: HelicalReports.DailyFailedNoticesReport, uiElement: "PAGE_NOTICE_MANAGEMENT_DAILY_FAILED_NOTICES_REPORT" });
  additionalRoutes.push({ path: "/dailyEmailedNoticesReport", component: HelicalReports.DailyEmailedNoticesReport, uiElement: "PAGE_NOTICE_MANAGEMENT_DAILY_EMAILED_NOTICES_REPORT" });
  additionalRoutes.push({ path: "/monthlyEmailedNoticesReport", component: HelicalReports.MonthlyEmailedNotices, uiElement: "PAGE_NOTICE_MANAGEMENT_MONTHLY_EMAILED_NOTICES" });
  additionalRoutes.push({ path: '/ticketPaymentReport', component: HelicalReports.TicketPaymentReport, uiElement: 'PAGE_REVENUE_REPORT_TICKET_PAYMENT_REPORT' });
}

HelicalRoutes.push(...additionalRoutes);

export default HelicalRoutes;
