import { useForm, Controller } from "react-hook-form";
import ArrowLeft from "../../../../Images/arrow-left.png";
import DatePicker from "react-datepicker";
import transfer from "../../../../Images/transfer.png";
import moment from "moment";
import { useEffect, useState } from "react";

const PermitDetailTab = ({
  HandlePermitSubmit,
  handleTabChange,
  permitTitle,
  loading,
  payloadData,
  isEditPermit,
  setShowResetWarning,
  setDisplayMsg
}) => {
  const [timeError,setTimeError] = useState("")

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      description: "",
      type: "",
      status: "DETAIL_STATUS",
      comment: "",
      created_by: "",
      admin_email: "",
      support_resource_name: "",
      support_resource_email: "",
      permit_start_date: "",
      permit_end_date: "",
      permit_time_availability: {
        monthly: false,
        annually: false,
        quarterly: false,
        event: false,
        start_date: "",
        end_date: "",
      },
    },
  });

  useEffect(() => {
    if(isEditPermit){
      reset(payloadData)
      if (payloadData.status !== "ACTIVE") {
        setValue("status", "DETAIL_STATUS")
      }
    }
  },[payloadData])

  const detailSubmitHandler = (data) => {
    data.permit_start_date = moment(data.permit_start_date).format()
    data.permit_end_date = moment(data.permit_end_date).format()
    data.is_public = data.is_public === "true" ? true : data.is_public === true ? true : false;
    if(data.permit_time_availability.start_date === ""){
      data.permit_time_availability.start_date = "0001-01-01T00:00:00.000Z"
    } 
    if(data.permit_time_availability.end_date === ""){
      data.permit_time_availability.end_date = "0001-01-01T00:00:00.000Z"
    } 

    if(data.permit_time_availability.event === false && data.permit_time_availability.monthly === false && data.permit_time_availability.quarterly === false && data.permit_time_availability.annually === false){
      setTimeError("Please select a time availability ")
    } else {
      HandlePermitSubmit(data);
    }
  };

  return (
    <>
      <div className={`permin-form`}>
        <form onSubmit={handleSubmit(detailSubmitHandler)}>
          <div className="permin-form-main">
            <h3 className="permin-form-title">{permitTitle}</h3>
            <div className="permin-form-main-in">
              <div className="permin-form-main-in-fm">
                <div className="row">
                  <div className="col-4">
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: "Enter Name",
                        minLength:{value:4,message:"Name should be 4 character long"}
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label>Name *</label>
                          <input
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Name"
                            disabled={isEditPermit}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: "Enter Description",
                        minLength:{value:10,message:"Description should be 10 character long"}
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label>Description *</label>
                          <input
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Description"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <Controller
                      name="type"
                      control={control}
                      rules={
                        {
                          // required: "Please Select An Option",
                        }
                      }
                      render={({ field, fieldState }) => (
                        <>
                          <label>Type</label>
                          <select
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            value={field.value}
                            onChange={field.onChange}
                          >
                            <option value="">Select type</option>
                            <option value="Off street">Off street</option>
                            <option value="On street">On street</option>
                          </select>
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Controller
                      name="is_public"
                      control={control}
                      rules={
                        {
                          // required: "Please Select An Option",
                        }
                      }
                      render={({ field, fieldState }) => (
                        <>
                          <label>Is public</label>
                          <select
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <Controller
                      name="comment"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <label>Private comments</label>
                          <input
                            className="permit-field"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Private Comments"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="created_by"
                      control={control}
                      rules={
                        {
                          // required: "Enter Created By",
                        }
                      }
                      render={({ field, fieldState }) => (
                        <>
                          <label>Created by</label>
                          <input
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Created By"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <Controller
                      name="admin_email"
                      control={control}
                      rules={
                        {
                          pattern:{
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        }
                      }
                      render={({ field, fieldState }) => (
                        <>
                          <label>Administrator's email</label>
                          <input
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Administrator's Email"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <Controller
                      name="support_resource_name"
                      control={control}
                      rules={
                        {
                          minLength:{
                            value:"4",
                            message:"Support resource name should be 4 character long"
                          }
                        }
                      }
                      render={({ field, fieldState }) => (
                        <>
                          <label>Support resource name</label>
                          <input
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Support Resource Name"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="support_resource_email"
                      control={control}
                      rules={
                        {
                          pattern:{
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        }
                      }
                      render={({ field, fieldState }) => (
                        <>
                          <label>Support resource email</label>
                          <input
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter Support Resource Email"
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <Controller
                      name="permit_start_date"
                      control={control}
                      rules={{
                        required: "Enter Permit Start Date",
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label>Permit start date *</label>
                          <DatePicker
                            timeIntervals={1}
                            placeholderText="MM/DD/YYYY"
                            dateFormat="MM/dd/yyyy"
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            name="permit_start_date"
                            autoComplete="off"
                            selected={field.value !== "" ? new Date(field.value) : field.value}
                            onChange={field.onChange}
                            minDate={moment().toDate()}
                            maxDate={watch("permit_end_date") && moment(watch("permit_end_date")).subtract(1,'days').toDate()}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name="permit_end_date"
                      control={control}
                      rules={{
                        required: "Enter Permit End Date",
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label>Permit end date *</label>
                          <DatePicker
                            timeIntervals={1}
                            placeholderText="MM/DD/YYYY"  
                            dateFormat="MM/dd/yyyy"
                            className={`permit-field ${
                              fieldState.error ? "msg-error" : ""
                            }`}
                            name="permit_end_date"
                            autoComplete="off"
                            selected={field.value !== "" ? new Date(field.value) : field.value}
                            onChange={field.onChange}
                            minDate={watch("permit_start_date") ? moment(watch("permit_start_date")).add(1,'days').toDate() : moment().toDate()}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}  
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="permin-form-main-in-rd">
                <h4 className="permit-title-h4">Select time validity</h4>
                <Controller
                  name="permit_time_availability.annually"
                  control={control}
                  render={({ field }) => (
                    <div className="permin-form-main-in-rd-list">
                      <label
                        className={`${
                          watch("permit_time_availability.annually")
                            ? "active-stv"
                            : ""
                        }`}
                      >
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          onChange={(e) => (field.onChange(e.target.checked), setTimeError(""))}
                          value={field.value}
                          checked={field.value}
                        />
                        {"Annually"}
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="permit_time_availability.quarterly"
                  control={control}
                  render={({ field }) => (
                    <div className="permin-form-main-in-rd-list">
                      <label
                        className={`${
                          watch("permit_time_availability.quarterly")
                            ? "active-stv"
                            : ""
                        }`}
                      >
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          onChange={(e) => (field.onChange(e.target.checked), setTimeError(""))}
                          value={field.value}
                          checked={field.value}
                        />
                        {"Quarterly"}
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="permit_time_availability.monthly"
                  control={control}
                  render={({ field }) => (
                    <div className="permin-form-main-in-rd-list">
                      <label
                        className={`${
                          watch("permit_time_availability.monthly")
                            ? "active-stv"
                            : ""
                        }`}
                      >
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          onChange={(e) => (field.onChange(e.target.checked), setTimeError(""))}
                          value={field.value}
                          checked={field.value}
                        />
                        {"Monthly"}
                      </label>
                    </div>
                  )}
                />
                {/* <Controller
                  name="permit_time_availability.specificDays"
                  control={control}
                  render={({ field }) => (
                    <div className="permin-form-main-in-rd-list">
                      <label
                        className={`${
                          watch("permit_time_availability.specificDays")
                            ? "active-stv"
                            : ""
                        }`}
                      >
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          onChange={field.onChange}
                          value={field.value}
                        />
                        {"Event/Specific Days Of Week"}
                      </label>
                    </div>
                  )}
                /> */}

                {/* This Days Section is temporary disable */}
                {/* {watch("permit_time_availability.specificDays") && (
                  <div className="permin-form-main-in-rd-list-in">
                    {weekDaysOption.map((option, i) => (
                      <Controller
                        name={option}
                        control={control}
                        render={({ field }) => (
                          <div className="permin-form-main-in-rd-list-in-box">
                            <label
                              className={`${watch(option) ? "active-stv" : ""}`}
                            >
                              <input
                                className="permit-field-checkbox"
                                type="checkbox"
                                key={i}
                                onChange={field.onChange}
                                value={option}
                              />
                              {option}
                            </label>
                          </div>
                        )}
                      />
                    ))}
                  </div>
                )} */}
                <Controller
                  name="permit_time_availability.event"
                  control={control}
                  render={({ field }) => (
                    <div className="permin-form-main-in-rd-list">
                      <label
                        className={`${
                          watch("permit_time_availability.event")
                            ? "active-stv"
                            : ""
                        }`}
                      >
                        <input
                          className="permit-field-checkbox"
                          type="checkbox"
                          onChange={(e) => (field.onChange(e.target.checked), setTimeError(""))}
                          value={field.value}
                          checked={field.value}
                        />
                        {"Event/specific dates"}
                      </label>
                    </div>
                  )}
                />
                {timeError !== "" &&
                  <span className="errormessage">
                    {timeError} 
                  </span>
                }
                {watch("permit_time_availability.event") && (
                  <div class="permin-specific-dates">
                    <label>Start date & end date</label>
                    <div class="permin-specific-dates-box">
                      <Controller
                        name="permit_time_availability.start_date"
                        control={control}
                        rules={{
                          required:"Specific date is required"
                        }}
                        render={({ field, fieldState }) => (
                          <>
                          <DatePicker
                            timeIntervals={1}
                            placeholderText="MM/DD/YYYY"
                            dateFormat="MM/dd/yyyy"
                            className="permit-field schedule-date-input"
                            name="permit_time_availability.start_date"
                            autoComplete="off"
                            selected={field.value ? new Date(field.value) : field.value}
                            onChange={field.onChange}
                            minDate={moment().toDate()}
                            maxDate={watch("permit_time_availability.end_date") && moment(watch("permit_time_availability.end_date")).subtract(1,'days').toDate()}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                          </>
                        )}
                      />
                      <img
                        src={transfer}
                        alt="transfer"
                        className="date-transfer"
                      />
                      <Controller
                        name="permit_time_availability.end_date"
                        control={control}
                        rules={{
                          required:"Specific date is required"
                        }}
                        render={({ field, fieldState }) => (
                          console.log(field.value, 'end date field value'),
                          <>
                          <DatePicker
                            timeIntervals={1}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM/DD/YYYY"
                            className="permit-field schedule-date-input"
                            name="permit_time_availability.end_date"
                            autoComplete="off"
                            selected={field.value ? new Date(field.value) : field.value}
                            onChange={field.onChange}
                            minDate={watch("permit_time_availability.start_date") ? moment(watch("permit_time_availability.start_date")).add(1,'days').toDate() : moment().toDate()}
                          />
                          {fieldState.error && (
                            <span className="errormessage">
                              {fieldState.error.message}
                            </span>
                          )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="permin-form-btn">
            <div className="permin-form-btn-left">
              <button
                className="permin-btn"
                onClick={() => isEditPermit ? (setShowResetWarning(true),setDisplayMsg("Currently you are editing the permit, Are you sure you want to exit the page?")) : handleTabChange(1)}
                type="button"
              >
                <img src={ArrowLeft} alt="back" />
                Back
              </button>
            </div>
            <div className="permin-form-btn-right">
                <button className="permin-btn" type="submit">
                  {loading ? <div className="spinner-border"></div> : "Save"}
                </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PermitDetailTab;
