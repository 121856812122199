import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BackImg from '../../../Images/back.png';
import UpdateStatusModal from './updateStatusModal';
import { ToastContainer } from 'react-toastify';
import PurchasePermitAuditTrailTab from './purchasePermitAuditTrailTab';
import dashboardService from '../../../Services/dashboard.service';
import PurchasedPermitDetailsComponent from './PermitDetail';
import PermitFinancial from './PermitFinancial';

const PurchasedPermitDetails = ({ backButtonClick }) => {
  const location = useLocation().search;
  const [plateDetails, setPlateDetails] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('manager');

  // get permit detail data
  const getPermitDetails = async query => {
    try {
      setLoading(true);
      const response = await dashboardService.getPurchasedPermit(query);
      if (
        response.data.status &&
        response.data.data.permit_buy_detail.length > 0
      ) {
        setLoading(false);
        setDetailData(response.data.data.permit_buy_detail[0]);
        setPlateDetails(response.data.data.permit_buy_detail[0].id);
        return response.data.data.permit_buy_detail[0].permit_config_id;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPermitDetails(location);
  }, []);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="text-center spin-load-main">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
          <div className="row">
            <div className="col-12 back-button back-button-citationdetails">
              <Link
                onClick={e => {
                  backButtonClick(e);
                }}>
                <img src={BackImg} alt="img" className="img-fluid" />
                Back
              </Link>
            </div>
          </div>

          <div className="rightpanale-box2 cstm-rightpanale-box2 cstm-ad-only">
            <div className="cstm-ap-man">
                <div className="cstm-ap-man-left">
              </div>

              <div className="cstm-ap-man-right">
                <div className="cstm-appeal-reports-title">
                  Permit Workflow
                </div>

                  <div className="cstm-ar-tp-tab" style={{ marginLeft: '25%' }}>
                  <ul className="nav nav-tabs2 pagination-content">
                    <li className="nav-item2">
                      <a
                          className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${activeTab === 'manager' ? 'active' : ""}`}
                        data-toggle="tab"
                          href="#manager"
                          onClick={() => setActiveTab('manager')}
                      >
                        <span className="main-round-border">1</span>
                        <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                            Manager Review
                        </p>
                      </a>
                    </li>
                    <li className="nav-item2">
                      <a
                        className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${activeTab === 'financial' ? 'active' : ""}`}
                        data-toggle="tab"
                        href="#financial"
                        onClick={() => setActiveTab('financial')}
                      >
                          <span className="main-round-border">2</span>
                        <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                          Financial
                        </p>
                      </a>
                    </li>
                    <li className="nav-item2">
                      <a
                          className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${activeTab === 'audit-trail' ? 'active' : ""}`}
                          data-toggle="tab"
                          href="#audit-trail"
                          onClick={() => { setActiveTab('audit-trail') }}
                        >
                          <span className="main-round-border">3</span>
                          <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                            Notes
                          </p>
                        </a>
                      </li>
                  </ul>
                </div>

                <div className="cstm-af-only-main">
                  <div className="tab-content">
                      <div id="manager" className={`tab-contain-add-main-cstm tab-pane fade ${activeTab === 'manager' ? 'active show' : ""}`}>
                        <div className='box1'>
                          <PurchasedPermitDetailsComponent backButtonClick={backButtonClick} />
                        </div>
                      </div>
                      <div id="audit-trail" className={`tab-contain-add-main-cstm tab-pane fade ${activeTab === 'audit-trail' ? 'active show' : ""}`}>
                        <UpdateStatusModal
                          permitId={plateDetails}
                          status={'APPROVED'}
                          getPermitDetails={getPermitDetails}
                          location={location}
                        />
                      <PurchasePermitAuditTrailTab
                        auditTrail={detailData?.audit_trail}
                      />
                    </div>
                    <div id="financial" className={`tab-contain-add-main-cstm tab-pane fade ${activeTab === 'financial' ? 'active show' : ""}`}>
                      <div className="cstm-af-only>">
                          <PermitFinancial buy_permit_fee_structure={detailData?.buy_permit_fee_structure} total_amount={detailData?.total_amount} />
                      </div>
                    </div>

                    <div id="closed" className={`tab-contain-add-main-cstm tab-pane fade ${activeTab === 'closed' ? 'active show' : ""}`}>
                      <UpdateStatusModal
                          permitId={plateDetails}
                        status={'APPROVED'}
                        getPermitDetails={getPermitDetails}
                        location={location}
                      />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchasedPermitDetails;
