import moment from 'moment';
import ArrowWhiteLeft from '../../../Images/arrow-white-left.svg';
import HeaderImage from '../../../Images/permit-buy.png';

const PermitListComponent = ({
  permitList,
  buyPermitHandler,
  isDrawer,
  btnLoading,
}) => {
  return (
    <>
      <div className={isDrawer && 'drawer'}>
        {isDrawer && (
          <div className={'drawer-top'}>
            <div className="header-image">
              <img src={HeaderImage} alt="" />
            </div>
            <div className="header-ps">
            </div>
          </div>
        )}

        {permitList && permitList.length > 0 ? (
          <div className="drawer-bottom">
            <div className={isDrawer ? '' : 'pt-5'}>
              <div
                className={
                  isDrawer ? 'permit-content-list2' : 'mt-5 permit-content-list'
                }>
                {permitList.map((itm, index) => (
                  <div className="permit-content-list-row">
                      <div className="permit-ofr">Available</div>
                    <div className="permit-content-list-row-title">
                      <h5>{itm.name ? itm.name : '-'}</h5>
                      <p>{itm.description ? itm.description : '-'}</p>
                    </div>

                    {itm.document_required &&
                      itm.document_required.filter(v => v).length > 0 && (
                        <div className="permit-content-list-row-following">
                          <h6>
                            Following documents are required for buying permits
                          </h6>
                          <div className="permit-content-list-row-following-lable">
                            {itm.document_required
                              .filter(v => v)
                              .map(doc => (
                                <span>{doc}</span>
                              ))}
                          </div>
                        </div>
                      )}

                    {[
                      itm.permit_time_availability.daily && 'daily',
                      itm.permit_time_availability.monthly && 'monthly',
                      itm.permit_time_availability.quarterly && 'quarterly',
                      itm.permit_time_availability.annually && 'annually',
                    ].filter(it => it).length > 0 && (
                      <div className="permit-content-list-row-billing-type">
                        <div className="pclrb-type-in">
                          <h4 className="permit-list-h4">Billing type</h4>
                          <p>
                            {[
                              itm.permit_time_availability.daily && ' Daily',
                              itm.permit_time_availability.monthly &&
                                ' Monthly',
                              itm.permit_time_availability.quarterly &&
                                ' Quarterly',
                              itm.permit_time_availability.annually &&
                                ' Annually',
                            ]
                              .filter(it => it)
                              .toString()}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="permit-content-list-row-billing-type">
                      <div className="pclrb-type-in">
                        <h4 className="permit-list-h4">Permit Expiration Date</h4>
                        <p>
                          {itm.permit_end_date &&
                          itm.permit_end_date !== '0001-01-01T00:00:00Z'
                            ? moment(itm.permit_end_date.split('T')[0]).format(
                                'MM/DD/YYYY',
                              ) 
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="permit-content-list-row-billing-type">
                    <div className="pclrb-type-in">
                        <h4 className="permit-list-h4">Zone</h4>
                        <p>
                          {itm.zones 
                            ? itm.zones 
                            : '-'}
                        </p>
                      </div>
                      </div>

                    <div className="permit-content-list-row-btn">
                      <button
                        className="buy-permit-bt-btn"
                        onClick={() => buyPermitHandler(itm, index)}
                        disabled={btnLoading}>
                        {btnLoading === index ? (
                          <div className="permit-buy-loader">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                          </div>
                        ) : (
                          <>
                            Buy permit
                            <img src={ArrowWhiteLeft} alt="buy permit" />
                          </>
                        )}
                      </button>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 cstm-spinner">
            <div className="text-center">No Data Found</div>
          </div>
        )}
      </div>
    </>
  );
};

export default PermitListComponent;
