import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import SideBar from "../src/NavComponent/Sidebar";
import Header from "../src/NavComponent/Header";
import { BrandingContext } from "./Global/Branding";
import DMVLockup from "./Component/DMVLockup";
import Login from "./Component/Login/Login";
import OtpVerification from "./Component/Login/OtpVerification";
import CitationSearchResult from "./Component/Citation/CitationSearchResult";
import SearchCitation from "./Component/Citation/SearchCitation";
import CitationDetails from "./Component/Citation/CitationDetails";
import AppealReport from "./Component/AppealManagement/AppealReport";
import ReportTicket from "./Component/ReportingModule/TicketReporting/TicketReportingLogs/TicketReporting";
import PaidTicket from "./Component/ReportingModule/PaidTicketManagement/PaidTicketManagement/PaidTicketManagement";
import CardPayment from "./Component/Citation/CardPayment";
import CashPayment from "./Component/Citation/CashPayment";
import ChequePayment from "./Component/Citation/ChequePayment";
import Thankyou from "./Component/Citation/Thankyou";
import OTPVerifyForResetPassword from "./Component/Login/OTPVerifyForResetPassword";
import ResetPassword from "./Component/Login/ResetPassword";

import ForgotPassword from "./Component/Login/ForgotPassword";
import AppealCreate from "./Component/Citation/AppealCreate";
import TicketDetails from "./Component/ReportingModule/TicketReporting/TicketDetails";

import ROReportsList from "./Component/ROReports/ROReportingLogs/TicketReporting";
import RODetailReport from "./Component/ROReports/RODetailReport";
import SummaryReports from "./Component/RevenueReports/SummaryReports";
import CollectionReports from "./Component/RevenueReports/CollectionReports";

// Dashboard Route
import MainDashboard from "./Component/DashboardModule/MainDashboard/MainDashboard";
import OperationDashboard from "./Component/DashboardModule/Operation/OperationDashboard";
import ProductivityDashboard from "./Component/DashboardModule/Productivity/ProductivityDashboard";
import RevenueDashboard from "./Component/DashboardModule/Revenue/RevenueDashboard";
import IntegrationDashboard from "./Component/DashboardModule/Integration/IntegrationDashboard";
import UtilizationDashboard from "./Component/DashboardModule/Utilization/UtilizationDashboard";

// Data Views
import PaymentDataView from "./Component/DataViewsReports/PaymentDataView/PaymentDataView";
import PermitDataView from "./Component/DataViewsReports/PermitDataView/PermitDataView";
import ScofflawDataView from "./Component/DataViewsReports/ScofflawDataView/ScofflawDataView";
import NoticeTemplateManagement from "./Component/NoticeManagement/NoticeManagement";

import NoticeReportManagement from "./Component/NoticeManagement/NoticeReport/NoticeReportLogs/NoticeReport";
import NoticeCountReport from "./Component/NoticeManagement/NoticeCountReport";
import ROCountReport from "./Component/ROReports/ROCountReport";
import AgenciesList from "./Component/Agencies/AgenciesList";
import SiteList from "./Component/SiteConfiguration/SiteList";
import RoleList from "./Component/UserManagement/Rolelist";
import UsersList from "./Component/UserManagement/UsersList";
import EnrollNewUser from "./Component/UserManagement/EnrollNewUser";
import PaymentBreakdown from "./Component/ReportingModule/PaymentCountSummary/PaymentCountSummaryLogs/PaymentBreakdown";
import EditUser from "./Component/UserManagement/EditUser";

// Flipbook
import Flipbook from "./Component/Flipbook/Flipbook";
import CalendarComp from "./Component/Flipbook/Calendar";
import HearingEligibleCitations from "./Component/HearingModule/HearingEligibleCitations";
import RoleCreate from "./Component/UserManagement/RoleCreate";
import EditRole from "./Component/UserManagement/EditRole";
import PaymentReconciliationList from "./Component/Reports/PaymentReconciliationList";

// Rules Engine
import RulesEngine from "./Component/RulesEngine";
import UserAccountList from "./Component/Reports/UserAccountList";
import PageNotFound from "./Component/PageNotFound";
import EscalationReport from "./Component/Reports/Escalation/EscalationReport";

// Helical Insight Reports
import HelicalRoutes from "./Component/Reports/HelicalReports/HelicalRoutes";
import OtherReports from "./Component/Reports/HelicalReports/OtherReports";

import { refreshTokenMiddleware } from "./Component/customMiddelware";
import mainService from "./Services/main.service";
import PaymentFailedReport from "./Component/Reports/PaymentFailed/PaymentFailedReport";
import AmendCitationComponent from "./Component/ReportingModule/TicketReporting/reports/AmendCitationReport";
import ReportEditComponent from "./Component/ReportingModule/TicketReporting/components/ReportEditComponent";

// Create Citation
import CitationImageList from "./Component/CreateCitation/CitationWithImage";
import CreateCitation from "./Component/CreateCitation/CreateCitation";

import NoticeTemplateTinyMCE from "./Component/NoticeManagement/NoticeTemplateForm";
import HearingReportContainer from "./Component/HearingModule/HearingManagement/HearingReportContainer";
import HearingDetail from "./Component/HearingModule/HearingManagement/HearingDetail";

import AddResource from "./Component/UserManagement/MultiStep/AddResource";

import PermitOptionContaier from "./Component/Permits/PermitsOption/permitOptionContainer";
import PermitConfigurationContainer from "./Component/Permits/PermitsConfiguration/permitConfigurationContainer";
import PermitAdministratorContainer from "./Component/Permits/PermitsAdministrator/permitAdministratorContainer";
import PermitNoticeListContainer from "./Component/Permits/PermitNoticeTemplate/permitNoticeListContainer";
import PurchasedPermitContainer from "./Component/Permits/PurchasedPermit/purchasedPermitContainer";
import BuyPermitStep from "./Component/Permits/buyPermit";
import PermitThankyou from "./Component/Permits/buyPermit/permtiThankyou";
import PermitMapListsContainer from "./Component/Permits/permitMapLists/permitMapListsContainer";

export default function App() {
  const dispatch = useDispatch();

  const { isLoggedIn, isAgency } = useSelector((state) => state.auth);
  const [showCustomBranding, setCustomBranding] = useState("");
  const [uiElements, setUIElements] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const { pathname, search } = useLocation();
  const excludePath = ["/agency", "/site-list"];

  const { text, favicon } = useContext(BrandingContext);

  useEffect(() => {
    setCustomBranding(text);
    CreateAndSet(favicon);
  }, [text, favicon]);

  const CreateAndSet = (favicon) => {
    const faviconLink = document.querySelector("link[rel*='icon']") || document.createElement("link");
    faviconLink.type = "image/png";
    faviconLink.rel = "icon";
    faviconLink.href = favicon;
    document.getElementsByTagName("head")[0].appendChild(faviconLink);
  }

  useEffect(() => {
    const getUIElementsFun = async () => {
      setPageLoader(true);
      try {
        const result = await mainService.UIElementsAPI();
        if (result.data.status) {
          const elementArr = result.data.data.ui_elements;

          setUIElements(elementArr);
          setTimeout(() => {
            setPageLoader(false);
          }, 1000);
        }
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          getUIElementsFun();
        }
      }
    };

    if (isLoggedIn && isAgency) {
      getUIElementsFun();
    }
  }, [isLoggedIn, isAgency, dispatch]);



  return (
    <>
      <Helmet>
        <title>{showCustomBranding}</title>
        <link
          rel="icon"
          type="image/png"
          href={favicon}
          sizes="16x16"
        />
      </Helmet>

      <div
        className={`App ${showCustomBranding.replace(/ /g, "").toLowerCase()} ${
          !isLoggedIn ? "cstm-side-bar-width" : ""
        }`}
      >
        <div className="app-container">
          <Header />

          {isLoggedIn && isAgency && !excludePath.includes(pathname) && (
            <SideBar />
          )}

          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/otpverification" element={<OtpVerification />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp-verify" element={<OTPVerifyForResetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {isLoggedIn && <Route path="/agency" element={<AgenciesList />} />}
            <Route
              path="/site-list"
              element={
                <RestrictPage>
                  <SiteList />{" "}
                </RestrictPage>
              }
            />

            {/*           ***********   Dashboard Pages Routes *********         */}
            <Route
              path="/dashboard"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_DASHBOARD_ENFORCEMENT") ? (
                    <MainDashboard />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/operation"
              element={
                <RestrictPage>
                  {pageLoader ? "" : <OperationDashboard />}
                </RestrictPage>
              }
            />
            <Route
              path="/productivity"
              element={
                <RestrictPage>
                  {pageLoader ? "" : <ProductivityDashboard />}
                </RestrictPage>
              }
            />
            <Route
              path="/revenue"
              element={
                <RestrictPage>
                  {pageLoader ? "" : <RevenueDashboard />}
                </RestrictPage>
              }
            />
            <Route
              path="/integration"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_DASHBOARD_INTEGRATIONS") ? (
                    <IntegrationDashboard />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/utilization"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_DASHBOARD_UTILIZATIONS") ? (
                    <UtilizationDashboard />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />

            {HelicalRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <RestrictPage>
                    {pageLoader ? (
                      ""
                    ) : uiElements.includes(route.uiElement) ? (
                      <route.component />
                    ) : (
                      <PageNotFound />
                    )}
                  </RestrictPage>
                }
              />
            ))}

            {/*           ***********   Dashboard Pages Routes *********         */}

            {/*           ***********   Ticket Management Pages Routes *********         */}
            <Route
              path="/search"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_TICKET_MANAGEMENT_SEARCH_TICKETS"
                    ) ? (
                    <SearchCitation />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/search-result"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_TICKET_MANAGEMENT_SEARCH_TICKETS"
                    ) ? (
                    <CitationSearchResult />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/citation-detail"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_TICKET_MANAGEMENT_SEARCH_TICKETS"
                    ) ? (
                    <CitationDetails uiElements={uiElements} />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/ticketreporting"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_TICKET_MANAGEMENT_TICKET_LISTVIEW"
                    ) ? (
                    <ReportTicket location={search} uiElements={uiElements} />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/amendcitationlist"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_TICKET_MANAGEMENT_EDIT_CITATIONS"
                    ) ? (
                    <AmendCitationComponent />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/editCitation"
              element={
                <RestrictPage>
                  {pageLoader ? "" : <ReportEditComponent />}
                </RestrictPage>
              }
            />
            {/*           ***********   Ticket Management Pages Routes *********         */}

            {/*           ***********   Payment Pages Routes *********         */}
            <Route
              path="/card-payment"
              element={
                <RestrictPage>
                  {" "}
                  <CardPayment uiElements={uiElements} />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/cash-payment"
              element={
                <RestrictPage>
                  {" "}
                  <CashPayment uiElements={uiElements} />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/cheque-payment"
              element={
                <RestrictPage>
                  {" "}
                  <ChequePayment uiElements={uiElements} />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/thankyou"
              element={
                <RestrictPage>
                  {" "}
                  <Thankyou />{" "}
                </RestrictPage>
              }
            />
            {/*           ***********   Payment Pages Routes *********         */}

            {/*           ***********   Appeal Management Pages Routes *********         */}
            <Route
              path="/appeal-report"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_APPEALS_MANAGEMENT_APPEAL_MANAGEMENT"
                    ) ? (
                    <AppealReport uiElements={uiElements} />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            {/*           ***********   Appeal Management Pages Routes *********         */}

            <Route
              path="/appeal-create"
              element={
                <RestrictPage>
                  <AppealCreate />
                </RestrictPage>
              }
            />
            <Route
              path="/ticket-details"
              element={
                <RestrictPage>
                  <TicketDetails />
                </RestrictPage>
              }
            />

            {/*           ***********   Revenu Report Pages Routes *********         */}
            <Route
              path="/paidtickets"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_REVENUE_REPORT_PAYMENT_MANAGEMENT"
                    ) ? (
                    <PaidTicket />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/paymentcount"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_REVENUE_REPORT_PAYMENT_SUMMARY"
                    ) ? (
                    <PaymentBreakdown />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />

            {/*           ***********   Ro Management Pages Routes *********         */}
            <Route
              path="/ro-reports"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_RO_MANAGEMENT_RO_LIST_VIEW") ? (
                    <ROReportsList />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/ro-detail-report"
              element={
                <RestrictPage>
                  {
                    pageLoader ? "" : <RODetailReport />
                    //  uiElements.includes("PAGE_RO_MANAGEMENT_RO_LIST") ? (
                    //   <RODetailReport />
                    // ) : (
                    //   <PageNotFound />
                    // )
                  }
                </RestrictPage>
              }
            />
            <Route
              path="/ro-count"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_RO_MANAGEMENT_RO_COUNT_REPORT_"
                    ) ? (
                    <ROCountReport />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            {/*           ***********   Ro Management Pages Routes *********         */}

            {/*           ***********   Notice Management Pages Routes *********         */}
            <Route
              path="/notice"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_NOTICE_MANAGEMENT_NOTICE_LIST_VIEW"
                    ) ? (
                    <NoticeReportManagement />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/notice-count"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_NOTICE_MANAGEMENT_NOTICE_COUNT_REPORT"
                    ) ? (
                    <NoticeCountReport />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            {/*           ***********   Notice Management Pages Routes *********         */}

            {/*           ***********   Data Views Pages Routes *********         */}
            <Route
              path="/payment"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_DATA_VIEWS_PAYMENT_DATA") ? (
                    <PaymentDataView />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/permit"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_DATA_VIEWS_PERMIT_DATA") ? (
                    <PermitDataView />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/scofflaw"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_DATA_VIEWS_SCOFFLAW") ? (
                    <ScofflawDataView />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            {/*           ***********   Data Views Pages Routes *********         */}

            {/*           ***********  Start Reports Page Routes   *************** */}
            <Route
              path="/reconciliation-payment"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_REVENUE_REPORT_PAYMENT_RECONCILIATION"
                    ) ? (
                    <PaymentReconciliationList />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/hearingCitations"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_HEARING_MANAGEMENT_HEARING_ELIGIBLE_TICKETS"
                    ) ? (
                    <HearingEligibleCitations uiElements={uiElements} />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />

            {/* ****************** OTHER REPORTS ******************************* */}
            <Route
              path="/reports"
              element={
                <RestrictPage>
                  {
                    pageLoader ? "" : <OtherReports uiElements={uiElements} />
                    // uiElements.includes("PAGE_TICKET_HISTORY_BOOK") ? (

                    //   <OtherReports />
                    // ) : (
                    //   <PageNotFound />
                    // )
                  }
                </RestrictPage>
              }
            />

            {/*           ***********   Administration Pages Routes *********         */}
            <Route
              path="/template-notice"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_ADMINISTRATION_NOTICE_TEMPLATE_MANAGEMENT_"
                    ) ? (
                    <NoticeTemplateManagement />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/users"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_ADMINISTRATION_USER_MANAGEMENT"
                    ) ? (
                    <UsersList />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/user-enroll"
              element={
                <RestrictPage>
                  {
                    pageLoader ? "" : <EnrollNewUser />
                    // uiElements.includes(
                    //   "PAGE_ADMINISTRATION_USER_MANAGEMENT"
                    // ) ? (
                    //   <EnrollNewUser />
                    // ) : (
                    //   <PageNotFound />
                    // )
                  }
                </RestrictPage>
              }
            />
            <Route
              path="/user-edit"
              element={
                <RestrictPage>
                  {
                    pageLoader ? "" : <EditUser />
                    // uiElements.includes(
                    //   "PAGE_ADMINISTRATION_USER_MANAGEMENT"
                    // ) ? (
                    //   <EditUser />
                    // ) : (
                    //   <PageNotFound />
                    // )
                  }
                </RestrictPage>
              }
            />
            {/*           ***********   Administration Pages Routes *********         */}

            {/*           ***********   Ticket History Book Pages Routes *********         */}
            <Route
              path="/flipbook"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_HEARING_MANAGEMENT_HEARING_DOCKET"
                    ) ? (
                    <Flipbook />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/calendar"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_HEARING_MANAGEMENT_CREATE_HEARING"
                    ) ? (
                    <CalendarComp />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            {/*           ***********   Ticket History Book Pages Routes *********         */}

            <Route
              path="/roles"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_ADMINISTRATION_ROLE_LIST") ? (
                    <RoleList />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/create-role"
              element={
                <RestrictPage>
                  {" "}
                  <RoleCreate />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/role-detail"
              element={
                <RestrictPage>
                  {" "}
                  <EditRole />{" "}
                </RestrictPage>
              }
            />

            <Route
              path="/revenue-summary-report"
              element={
                <RestrictPage>
                  <SummaryReports />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/revenue-collection-report"
              element={
                <RestrictPage>
                  <CollectionReports />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/dmv-lookup"
              element={
                <RestrictPage>
                  <DMVLockup />
                </RestrictPage>
              }
            />
            <Route path="/notice-TinyMCE" element={<NoticeTemplateTinyMCE />} />
            <Route
              path="/user-account"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes("PAGE_REPORTS_USER_ACCOUNT") ? (
                    <UserAccountList />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />
            <Route
              path="/escalation-report"
              element={
                <RestrictPage>
                  {
                    pageLoader ? "" : <EscalationReport />
                    // uiElements.includes(
                    //   "PAGE_REVENU_REPORTS_PAYMENT_ESCALATION_REPORT"
                    // ) ? (
                    //   <EscalationReport />
                    // ) : (
                    //   <PageNotFound />
                    // )
                  }
                </RestrictPage>
              }
            />
            <Route
              path="/payment-failed-report"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_REVENUE_REPORT_PAYMENT_FAILED_REPORT"
                    ) ? (
                    <PaymentFailedReport />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />

            {/* ************************************ CREATE CITATION ******************************************** */}
            <Route
              path="/create-citation"
              element={
                <RestrictPage>
                  <CreateCitation />
                </RestrictPage>
              }
            />

            <Route
              path="/citation-image"
              element={
                <RestrictPage>
                  <CitationImageList />
                </RestrictPage>
              }
            />

            <Route
              path="/addresource"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) : uiElements.includes(
                      "PAGE_ADMINISTRATION_ADD_RESOURCE"
                    ) ? (
                    <AddResource />
                  ) : (
                    <PageNotFound />
                  )}
                </RestrictPage>
              }
            />

            {/* Rules Engine */}
            <Route
              path="/rules-engine"
              element={
                <RestrictPage>
                  {" "}
                  <RulesEngine />{" "}
                </RestrictPage>
              }
            />
            {/* Permit Routes */}
            <Route
              path="/purchased-permits"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) :
                    <PurchasedPermitContainer />
                  }
                </RestrictPage>
              }
            />
            <Route
              path="/permits-option"
              element={
                <RestrictPage>
                  {pageLoader ? (
                    ""
                  ) :
                    uiElements.includes("PAGE_PERMITS_MANAGEMENT_PERMITS_OPTION") ? (
                      <PermitOptionContaier />
                    ) : (
                      <PageNotFound />
                    )
                  }
                </RestrictPage>
              }
            />
            <Route
              path="/permits-configuration"
              element={
                <RestrictPage>
                   {pageLoader ? (
                    ""
                  ) :
                    uiElements.includes("PAGE_PERMITS_MANAGEMENT_PERMITS_CONFIGURATION") ? (
                      <PermitConfigurationContainer />
                    ) : (
                      <PageNotFound />
                    )
                  }
                  
                </RestrictPage>
              }
            />
            <Route
              path="/permits-administrator"
              element={
                <RestrictPage>
                  {" "}
                  <PermitAdministratorContainer />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/permit-template-notice"
              element={
                <RestrictPage>
                  {" "}
                  <PermitNoticeListContainer />{" "}
                </RestrictPage>
              }
            />
            <Route
              path="/buy-permit"
              element={
                <RestrictPage>
                  {" "}
                  <BuyPermitStep />{" "}
                </RestrictPage>
              }
            />
            <Route
              exact
              path="/permit-thankyou"
              element={<PermitThankyou />}
            />
            <Route
              exact
              path="/permit-list-map"
              element={<PermitMapListsContainer />}
            />
            

            {/* Hearing Management */}

            <Route
              path="/hearing-report"
              element={
                <RestrictPage>
                  <HearingReportContainer />
                </RestrictPage>
              }
            />
            <Route
              path="/hearing-detail"
              element={
                <RestrictPage>
                  <HearingDetail />{" "}
                </RestrictPage>
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
}
function RestrictPage({ children }) {
  const { isLoggedIn, isAgency } = useSelector((state) => state.auth);
  return isLoggedIn || isAgency ? children : <Navigate to="/" replace />;
}
