import mainService from "../Services/main.service";
import { clearoutAuthUserData } from "../actions/auth";
import Cookies from "js-cookie";

export const refreshTokenMiddleware = (dispatch) => {
    let olddata = JSON.parse(Cookies.get("auth"));
    const payload = {
        token: olddata.refresh_token_data.token
    }
    mainService.refreshTokenAPI(payload).then(res => {
        if (res.data.status) {
            olddata.access_token_data = res.data.data.access_token_data
            Cookies.set("auth", JSON.stringify(olddata));
            Cookies.set("token", res.data.data.access_token_data.token);

            dispatch({ type: 'LOGIN_SUCCESS', payload: olddata })

        } else {
            dispatch(clearoutAuthUserData());
            window.location.href = '/'
        }
    }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
            dispatch(clearoutAuthUserData());
            window.location.href = '/'
        }
    })
}

export const refreshTokenMiddlewareAsync = async (dispatch) => {
    let olddata = JSON.parse(Cookies.get("auth"));
    const payload = {
        token: olddata.refresh_token_data.token
    }
    return await mainService.refreshTokenAPI(payload).then(res => {
        if (res.data.status) {
            olddata.access_token_data = res.data.data.access_token_data
            Cookies.set("auth", JSON.stringify(olddata));
            Cookies.set("token", res.data.data.access_token_data.token);

            dispatch({ type: 'LOGIN_SUCCESS', payload: olddata })

        } else {
            dispatch(clearoutAuthUserData());
            window.location.href = '/'
        }
    }).catch(err => {
        if (err.response.status === 401 || err.response.status === 403) {
            dispatch(clearoutAuthUserData());
            window.location.href = '/'
        }
    })
}