import DatePicker from "react-datepicker";
import transfer from '../../Images/transfer.png';
import { useState } from 'react'

const DatePickerComponent = ({ setEndDate, setStartDate, onChangeSearchDate, onSubmitButtonClick }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [minEndDate, setMinEndDate] = useState(null);

  return (
    <>
      <div className="report-date report-mr">
        <DatePicker
          showTimeSelect
          timeIntervals={1}
          dateFormat="MM/dd/yyyy h:mmaa"
          timeFormat="HH:mm"
          className="cstm-pull-details schedule-date-input"
          name="start_date"
          selected={setStartDate}
          onChange={date => {
            onChangeSearchDate(date, 'start');
            const nextDay = new Date(date);
            nextDay.setDate(nextDay.getDate() + 2);
            setMinEndDate(nextDay);
            setIsButtonDisabled(true);
          }}
        />
        <img src={transfer} alt="transfer" className="date-transfer" />

        <DatePicker
          showTimeSelect
          timeIntervals={1}
          dateFormat="MM/dd/yyyy h:mmaa"
          timeFormat="HH:mm"
          className="cstm-pull-details schedule-date-input"
          name="end_date"
          selected={setEndDate}
          minDate={minEndDate}
          onChange={date => {
            onChangeSearchDate(date, 'end');
            setIsButtonDisabled(false);
          }}
        />
      </div>
      {/* <button
        className="licence-print report-mr"
        type="button"
        onClick={onSubmitButtonClick.bind(this)}
        disabled={isButtonDisabled}
      >
        Submit
      </button> */}
    </>
  )
}

export default DatePickerComponent;