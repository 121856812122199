import authHeader from "./auth.header";
import axios from "axios";
let API_URL = '';

const pathname = window.location.hostname;

if (pathname === "parkmystic-processing-staging.netlify.app" || pathname === "bellingham-processing-staging.parkloyalty.com" || pathname === 'bellingham-processing-staging.netlify.app' || pathname === "greenvillesc-processing-staging.netlify.app" || pathname === "mansfield-processing-staging.netlify.app" || pathname === "ace-ticketprocessing-staging.netlify.app" || pathname === "duncan-ticketprocessing-staging.netlify.app" || pathname === "spplus-ticketprocessing-staging.netlify.app" || pathname === "leavenworth-processing-staging.netlify.app" || pathname === "follybeach-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkocmd.com" || pathname === "lazstaging-processing.netlify.app" || pathname === "staging-processing.parkcountryclubplaza.com" || pathname === "staging-processing.parkkalamazoo.com" || pathname === "staging-processing-parkkalamazoo.netlify.app" || pathname === "staging-processing.parkbismarcknd.com" || pathname === "staging-processing-parkbismarck.netlify.app" || pathname === "staging-processing-parkiop.netlify.app" || pathname === "staging-processing.parkiop.com" || pathname === "staging-processing-parkbangor.netlify.app" || pathname === "staging-processing.parkbangor.org" || pathname === "follybeach-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkashlandoregon.com" || pathname === "staging-processing-parkashlandoregon.netlify.app" || pathname === "staging-processing-parkriveroakssc.netlify.app" || pathname === "staging-processing.parkriveroakssc.com" || pathname === "staging-processing.parkbeaufortnc.com" || pathname === "staging-processing-parkbeaufortnc.netlify.app" ||
pathname === "staging-processing-hpa2pay.netlify.app" ||
pathname === "staging-processing.hpa2pay.com" || pathname === 'staging-processing-parkcohasset.netlify.app' ||
pathname === 'staging-processing.parkcohasset.com' || pathname === 'staging-processing.parkingenforcementco.com' ||
pathname === 'staging-processing-parkingenforcementco.netlify.app' || pathname === 'staging-processing.parksepta.com' ||
pathname === 'staging-processing-parksepta.netlify.app' || pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
pathname === 'staging-processing.parkcharleston-sc.com' || pathname === 'staging-processing-parkkcmo.netlify.app' ||
pathname === 'staging-processing.parkkcmo.com') {
  API_URL = "https://devapi.parkloyalty.com/";
} else if (pathname === "parkmystic-processing.netlify.app" || pathname === "bellingham-ticketprocessing.parkloyalty.com" || pathname === "bellingham-processing.netlify.app" || pathname === "mansfield-processing.netlify.app" || pathname === "ace-ticketprocessing.netlify.app" || pathname === "duncan-ticketprocessing.netlify.app" || pathname === "spplus-ticketprocessing.netlify.app" || pathname === "leavenworth-processing.netlify.app" || pathname === "follybeach-processing.netlify.app" || pathname === "follybeach-processing.parkloyalty.com" || pathname === "laz-processing.netlify.app" || pathname === "processing.parkcountryclubplaza.com" || pathname === "processing-parkkalamazoo.netlify.app" || pathname === "processing.parkkalamazoo.com" || pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com" || pathname === "processing-bismarck.netlify.app" || pathname === "processing.parkbismarcknd.com" || pathname === "processing.parkocmd.com" || pathname === "processing-parkocmd.netlify.app" || pathname === "processing-parkbangor.netlify.app" || pathname === "processing.parkbangor.org" || pathname === "processing.parkashlandoregon.com" || pathname === "processing-parkashlandoregon.netlify.app" || pathname === "processing-parkriveroakssc.netlify.app" || pathname === "processing.parkriveroakssc.com" || pathname === "processing.parkbeaufortnc.com" || pathname === "processing-parkbeaufortnc.netlify.app" || pathname === "processing.hpa2pay.com" ||
pathname === "processing-hpa2pay.netlify.app" || pathname === 'processing-parkcohasset.netlify.app' ||
pathname === 'processing.parkcohasset.com' || pathname === 'processing-parkingenforcementco.netlify.app' ||
pathname === 'processing.parkingenforcementco.com' || pathname === 'processing.parksepta.com' ||
pathname === 'processing-parksepta.netlify.app' || pathname === 'processing-parkcharleston-sc.netlify.app' ||
pathname === 'processing.parkcharleston-sc.com' || pathname === 'processing-parkkcmo.netlify.app' ||
pathname === 'processing.parkkcmo.com') {
  API_URL = "https://api.parkloyalty.com/";
} else if (pathname === "localhost" || pathname === "ticketprocessing-parkloyalty.netlify.app") {
  API_URL = "https://devapi.parkloyalty.com/";
}
else {
  API_URL = "https://devapi.parkloyalty.com/";
}

const getCitations = ({ time_from, time_to }) => {
  return axios.get(`${API_URL}pull-citation/get-tickets?hearing_ts_from=${time_from}&hearing_ts_to=${time_to}&status=Hearing`, { headers: authHeader() })
}

const getTicketDetails = (ticketId) => {
  return axios.get(`${API_URL}pull-citation/citation-detail?ticket_number=${ticketId}`, { headers: authHeader() })
}

const getCommercialDetails = (ticketId) => {
  return axios.get(`${API_URL}pull-citation/get-commercial?ticket_number=${ticketId}`, { headers: authHeader() })
}

const getAppealDetails = (ticketId) => {
  return axios.get(`${API_URL}appeals-service/appeals/ticket/${ticketId}`, { headers: authHeader() })
}

const getHearings = async () => {
  try {
    const data = await axios.get(`${API_URL}citations-issuer/cp/hearing_reports`, { headers: authHeader() })
    return data.data;
  } catch (error) {
    if (error.response.status === 401) {
      // dispatch(clearoutAuthUserData());
      // window.location.href = '/'
      const e = new Error();
      e.status = 401;
      e.message = 'LOGGED_OUT'
      throw e
    }
    else {
      throw new Error('Something Went Wrong!')
    }
  }
}

const createHearing = async (ticket_no, date) => {
  try {
    const payload = {
      date: date,
      comment: 'Hearing scheduled by admin'
    }
    const data = await axios.post(`${API_URL}citations-issuer/cp/ticket/${ticket_no}/hearing`, payload,
      { headers: authHeader() })
    return data.data;
  } catch (error) {
    if (error.response.status === 401) {
      const e = new Error();
      e.message = 'LOGGED_OUT'
      e.stack = 401;
      throw e
    }
    else {
      throw error
    }
  }
}

const reportTicket = (reportTicket) => {
  return axios.get(API_URL + "citations-issuer/cp/ticket?" + reportTicket, {
    headers: authHeader(),
  });
};

const FlipbookService = {
  createHearing,
  getAppealDetails,
  getCitations,
  getCommercialDetails,
  getHearings,
  getTicketDetails,
  reportTicket
};

export default FlipbookService;