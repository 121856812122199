import {useEffect, useState} from 'react';
import PermitFormComponent from './permitFormComponent';

const PermitStepComponent = ({
  permitData,
  setShowStepDetails,
  permitLayout,
  permitName,
  residentType
}) => {
  const [step, setStep] = useState('step0');

  const onClickNavigate = stepName => {
    setStep(stepName);
  };

  // handle step
  useEffect(() => {
    const pathname = window.location.hash;
    const getPath = pathname.split('#');
    setStep(getPath[1]);
  }, []);

  // show step dynamically
  const showFormNavigationStep = layout => {
    if (layout) {
      return (
        <PermitFormComponent
          formFields={layout[step.split('step')[1]]}
          stepNo={step.split('step')[1]}
          onClickNavigate={onClickNavigate}
          setShowStepDetails={setShowStepDetails}
          totalSteps={layout.length}
          permitData={permitData}
          permitName={permitName}
          residentType={residentType}
        />
      );
    }
  };

  return <>
    {showFormNavigationStep(permitLayout)}
  
  </>;
};

export default PermitStepComponent;
