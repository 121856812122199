import {useEffect, useState} from 'react';
import PermitListComponent from './permitListComponent';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {refreshGuestUserToken} from '../../../actions/auth';
import {siteid} from '../../../Global/site';
import {
  permitLayoutData,
  permitListData,
  permitQuantityFormHandler,
  permitStepFormHandler,
  permitLocationData
} from '../../../actions/permits';
import ErrorIcon from '../../../Images/error-icon.png';
import PermitSkeleton from './permitSkeleton';
import dashboardService from '../../../Services/dashboard.service';

const PermitListContainer = ({
  locationPermit,
  setShowStepDetails,
  isDrawer,
  setPermitName
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const site_id = siteid();
  const [permitList, setPermitList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState('');
  const {userAuth} = useSelector(state => state.auth);
  const [showLayoutError, setShowLayoutError] = useState(false);

  // permit list api handler

  const PermitListHandler = async ids => {
    setLoading(true);
    try {
      const response = await dashboardService.getPermitList(site_id.siteinfo, ids);
      if (
        response.data.data.permit_config &&
        response.data.data.permit_config.length > 0
      ) {
        setPermitList(response.data.data.permit_config);
      } else {
        setPermitList([]);
      }
    } catch (err) {
      if (err.response.status === 401) {
        const payload = {
          token: userAuth.refresh_token_data.token,
          site_id: site_id.siteinfo,
        };
        dispatch(refreshGuestUserToken(payload));
        PermitListHandler(ids);
      }
    }
    setLoading(false);
  };

  const getLayout = async (permitData, index) => {
    setBtnLoading(index);
    setPermitName(permitData.name)
    try {
      const response = await dashboardService.getPermitLayout(
        permitData.name,
        site_id.siteinfo,
      );
      if (response.data.template && response.data.template.length > 0) {
        setShowLayoutError(false);
        setShowStepDetails(true);
        dispatch(permitListData(permitData));
        dispatch(permitLayoutData(response.data.template));
        dispatch(permitLocationData(locationPermit));
        navigate('#step0');
      } else {
        setShowLayoutError(true);
      }
    } catch (err) {
      if (err.response.status === 401) {
        const payload = {
          token: userAuth.refresh_token_data.token,
          site_id: site_id.siteinfo,
        };
        dispatch(refreshGuestUserToken(payload));
        getLayout();
      }
    }
    setBtnLoading('');
  };

  // clear redux data and call list api
  useEffect(() => {
    if (
      locationPermit?.applicable_permit_types &&
      locationPermit?.applicable_permit_types.length > 0
    ) {
      PermitListHandler(locationPermit.applicable_permit_types);
      dispatch(permitListData(null));
      dispatch(permitStepFormHandler(null));
      dispatch(permitQuantityFormHandler(null));
    }
  }, [locationPermit]);

  // step form navigate handler
  const buyPermitHandler = (data, index) => {
    getLayout(data, index);
  };

  const handleCloseModel = () => {
    setShowLayoutError(false);
  };

  return (
    <>
      {loading ? (
        <PermitSkeleton isDrawer={isDrawer} />
      ) : (
        <PermitListComponent
          permitList={permitList}
          buyPermitHandler={buyPermitHandler}
          isDrawer={isDrawer}
          btnLoading={btnLoading}
        />
      )}
      {showLayoutError && (
        <div className={`cstm-choose-pmt-model modal fade modal-backdrop show`}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body cstm-modal-body">
                <button
                  className="cstm-payment-close"
                  onClick={handleCloseModel}>
                  ×
                </button>
                <div className="cstn-error-icon">
                  <img src={ErrorIcon} />
                </div>
                <div className="erroe-msg">
                  Permit Q/A layout is not configured
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PermitListContainer;
