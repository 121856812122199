import React, { useState } from 'react';
import mainService from '../../../Services/main.service';
import { toast } from 'react-toastify';

const UpdateStatusModal = ({ permitId, status, getPermitDetails, location }) => {
  const [inputs, setInputs] = useState({
    status: status,
    remark: '',
  });
  const [errors, setErrors] = useState({
    status: '',
    remark: '',
  });
  const [isBtnLoader, setIsBtnLoader] = useState(false);

  const handleOnChange = e => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: '' });
    } else {
      setErrors({ ...errors, [name]: 'Enter ' + name });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const payload = {
      permit_buy_id: permitId,
      status: inputs.status,
      remark: inputs.remark,
    };

    const query = location.split('?')[1];

    try {
      setIsBtnLoader(true);
      const response = await mainService.updatePermitStatus(payload);
      if (response.data.status) {
        toast.success('Status Updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setIsBtnLoader(false);
        getPermitDetails(query);
      } else {
        toast.error(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setIsBtnLoader(false);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setIsBtnLoader(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className="cstm-body">
            <form onSubmit={handleSubmit}>
              <span>Status</span>
              <div className="cstm-inputfield cstm-slk">
                <select
                  className="state-droupdwon  ar-olny"
                  name="status"
                  value={inputs.status}
                  defaultValue={inputs.status}
                  onChange={handleOnChange}>
                  <option value={'APPROVED'}>Approved</option>
                  <option value={'REJECTED'}>Rejected</option>
                </select>
              </div>
              <div className="cstm-inputfield">
                <span>Remark*</span>
                <br />
                <textarea
                  name="remark"
                  cols="30"
                  rows="4"
                  value={inputs.remark}
                  onChange={handleOnChange}
                  placeholder="Write remark"></textarea>
                <span className="errormessage">{errors.remark}</span>
              </div>
              <div className="cstm-submit-btn">
                <button className="btn" type="submit" disabled={isBtnLoader}>
                  {isBtnLoader && (
                    <div className="cstm-loader">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    </div>
                  )}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStatusModal;
