import React, { useEffect, useState } from "react";
import moment from "moment";
import $ from "jquery";

import close from "../../../Images/close.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { changeticket, changetotal } from "../../../Global/site";

const ProductTable = (props) => {
  const [count, setCount] = React.useState(0);
  const changeName = changeticket();
  const changeTotal = changetotal();
  const [showCancelCitation, setCancelCitation] = useState(false);
  const [showModifyAmount, setModifyAmount] = useState(false);
  const [showSelectedId, setSelectedId] = React.useState([]);
  const [showSelectedIdModifyAmount, setSelectedIdModifyAmount] =
    React.useState([]);
  const [showSelectedTicketNumber, setSelectedTicketNumber] = React.useState(
    []
  );
  const [showCancelCitationReason, setCancelCitationReason] = useState(
    "CUSTOMER SUPPORT CANCEL"
  );
  const [showCancelCitationComment, setCancelCitationComment] = useState("");
  const [showCommentEmptyMessage, setCommentEmptyMessage] = useState("");
  const [showErrorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setSuccessMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const [showModifyNewAmount, setModifyNewAmount] = useState("");
  const [showModifyAmountReason, setModifyAmountReason] = useState("");
  const [showModifyAmountComments, setModifyAmountComments] = useState("");
  const [showAmountEmptyMessage, setAmountEmptyMessage] = useState("");
  const [showReasonEmptyMessage, setReasonEmptyMessage] = useState("");

  const onClickDetailsPage = (e, id) => {
    Cookies.set("tikno", id);
    return props.onClickDetailsPage(true);
  };

  const closeError = () => {
    setCancelCitation(false);
    setModifyAmount(false);
  };

  // While Cancel Citation
  const OnClickCancelButton = () => {
    if (showCancelCitationComment === "") {
      setCommentEmptyMessage("Enter comments");
    } else {
      setCommentEmptyMessage("");
      var data = {
        citation_ids: showSelectedId,
        status: "Cancelled",
        status_reason: showCancelCitationReason,
        status_comments: showCancelCitationComment,
      };
    }
  };

  // While Modify Amount
  const OnClickModifyButton = () => {
    if (
      showModifyNewAmount === "" &&
      showModifyAmountReason === "" &&
      showModifyAmountComments === ""
    ) {
      setAmountEmptyMessage("Enter new amount");
      setReasonEmptyMessage("Enter modify amount reason");
      setCommentEmptyMessage("Enter modify amount comments");
    } else if (showModifyNewAmount === "") {
      setAmountEmptyMessage("Enter new amount");
    } else if (showModifyAmountReason === "") {
      setReasonEmptyMessage("Enter modify amount reason");
    } else if (showModifyAmountComments === "") {
      setCommentEmptyMessage("Enter modify amount comments");
    } else {
      setAmountEmptyMessage("");
      setReasonEmptyMessage("");
      setCommentEmptyMessage("");

      var data = {
        citation_ids: showSelectedId,
        new_amount: Number(showModifyNewAmount),
        modification_reason: showModifyAmountReason,
        modification_comment: showModifyAmountComments,
      };
    }
  };

  const commanPageLoadFunction = () => {
    $(function () {
      $("table").on("click", ".srt-tab", function () {
        var index = $(this).index(),
          rows = [],
          thClass = $(this).hasClass("srt-ace") ? "srt-desc" : "srt-ace";

        $("#cstm-report-tb-display th").removeClass("srt-ace srt-desc");
        $(this).addClass(thClass);

        $("#cstm-report-tb-display tbody tr").each(function (index, row) {
          rows.push($(row).detach());
        });

        rows.sort(function (a, b) {
          var aValue = $(a).find("td").eq(index).text(),
            bValue = $(b).find("td").eq(index).text();

          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        });

        if ($(this).hasClass("srt-desc")) {
          rows.reverse();
        }

        $.each(rows, function (index, row) {
          $("#cstm-report-tb-display tbody").append(row);
        });
      });
    });
  };

  useEffect(() => {
    commanPageLoadFunction();

    // Services.getUserUiElement().then(
    //   (response) => {

    //     if (response.data.status) {
    //       setHideSideBarOption(response.data.data.ui_hide_elements)
    //     }

    //   },
    //   (error) => {
    //   }
    // );

    setCount(100);
  }, []);

  const OnClickAllCheckbox = (e) => {
    if (e.target.checked) {
      setChecked(true);
      var selectedId = [];
      var selectedTicketNumber = [];

      var allSelectedBtn = props.products.filter((data) => {
        if (data.status === "Valid") {
          selectedId.push(data.id);
          selectedTicketNumber.push(" " + data.ticket_no);
        }
      });

      setSelectedTicketNumber(selectedTicketNumber);
      setSelectedId(selectedId);
    } else {
      setChecked(false);
      setSelectedId([]);
      setSelectedTicketNumber([]);
    }

    $(".comman-pl-csmt").prop("checked", !$(".comman-pl-csmt").prop("checked"));
  };

  const onChangeCheckEvents = (e) => {
    setChecked(false);

    var selectedId = showSelectedId;
    var selectedIdAll = showSelectedIdModifyAmount;
    var selectedTicket = showSelectedTicketNumber;

    if (e.target.checked) {
      if (e.target.value === "Valid") {
        selectedId.push(e.target.id);
        selectedTicket.push(" " + e.target.dataset.ticket_no);
      }
    } else {
      if (e.target.value === "Valid") {
        var carIndex = showSelectedId.indexOf(e.target.id);
        selectedId.splice(carIndex, 1);

        var ticketIndex = showSelectedTicketNumber.indexOf(
          e.target.dataset.ticket_no
        );
        selectedTicket.splice(ticketIndex, 1);
      }
    }

    setSelectedId(selectedId);
    setSelectedTicketNumber(selectedTicket);
  };

  const OnClickCancelCitation = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("");
      setErrorMessage("");
      setCancelCitationComment("");
      setCancelCitation(true);
    }
  };

  const OnClickModifyAmount = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("");
      setErrorMessage("");
      setModifyNewAmount("");
      setModifyAmountReason("");
      setModifyAmountComments("");
      setModifyAmount(true);
    }
  };

  const onChangeCancelCitation = (e) => {
    if (e.target.name === "select_reason") {
      setCancelCitationReason(e.target.value);
    } else if (e.target.name === "comment") {
      setCancelCitationComment(e.target.value);
      setCommentEmptyMessage("");
    }
  };
  const onChangeModifyAmount = (e) => {
    if (e.target.name === "new_amount") {
      setModifyNewAmount(e.target.value);
      setAmountEmptyMessage("");
    } else if (e.target.name === "modify_reason") {
      setModifyAmountReason(e.target.value);
      setReasonEmptyMessage("");
    } else if (e.target.name === "modify_comments") {
      setModifyAmountComments(e.target.value);
      setCommentEmptyMessage("");
    }
  };

  const isKalamazoo = () => {
    if (Cookies.get("siteinfo") === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8" || Cookies.get("siteinfo") === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3") {
      return true 
    } else {
      return false
    }
  }

  return (
    <>
      <div className="report_tbl_scroll" id="tbl-main-download">
        <table
          className="table table-heading table-bordered"
          id="cstm-report-tb-display"
        >
          <thead>
            <tr>
              <th className="w-1 row-rmv">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={OnClickAllCheckbox}
                  id="all"
                />
              </th>

              <th className="srt-tab w-4">Escalation Date</th>
              <th className="srt-tab w-3">{changeName} #</th>
              <th className="srt-tab w-3">{changeName} Issue Date Time</th>
              <th className="srt-tab w-3">Status</th>
              <th className="srt-tab w-3">Old Amount</th>
              <th className="srt-tab w-4">New Amount</th>
              <th className="srt-tab w-3">Escalation Type</th>
              <th className="srt-tab w-4">License Plate</th>
              <th className="srt-tab w-5">Violation Code</th>
              <th className="srt-tab w-8">Violation Description</th>

              <th className="srt-tab w-3">Has RO Address</th>
              <th className="srt-tab w-4">Notice Mailed</th>
            </tr>
          </thead>
          <tbody>
            {props.products.map((item, index) => (
              <tr key={item.id}>
                <td className="row-rmv">
                  <input
                    for={"pl" + index}
                    data-ticket_no={item.ticket_no}
                    className="comman-pl-csmt"
                    type="checkbox"
                    id={item.id}
                    value={item.status}
                    onChange={onChangeCheckEvents}
                  />
                </td>

                {item.citation_start_timestamp !== "" &&
                  item.citation_start_timestamp !== null &&
                  item.citation_start_timestamp !== "null" &&
                  item.citation_start_timestamp !== undefined &&
                  item.citation_start_timestamp.substring(0, 4) !== "0000" &&
                  item.citation_start_timestamp.substring(0, 4) !== "0001" ? (
                  <td>
                    <Link
                      onClick={(e) => onClickDetailsPage(e, item.ticket_no)}
                    >
                      {moment(
                        item.citation_start_timestamp.split("T")[0]
                      ).format("MM/DD/YYYY") +
                        " " +
                        item.citation_start_timestamp.substring(11, 19)}
                    </Link>
                  </td>
                ) : (
                  <td>-</td>
                )}

                {item.ticket_no !== "" &&
                  item.ticket_no !== null &&
                  item.ticket_no !== "null" &&
                  item.ticket_no !== undefined ? (
                  <td>{item.ticket_no}</td>
                ) : (
                  <td>-</td>
                )}

                {item.header_details.timestamp !== "" &&
                  item.header_details.timestamp !== null &&
                  item.header_details.timestamp !== "null" &&
                  item.header_details.timestamp !== undefined ? (
                  <td>{item.header_details.timestamp}</td>
                ) : (
                  <td>-</td>
                )}

                {item.status !== "" &&
                  item.status !== null &&
                  item.status !== "null" &&
                  item.status !== undefined ? (
                  <td>{item.status}</td>
                ) : (
                  <td>-</td>
                )}

                <td>-</td>
                <td>-</td>

                {
                  item.escalation_detail !== null && item.escalation_detail !== undefined ?
                    <td>{item.escalation_detail[0].amount}</td>
                    :
                    <td>-</td>
                }

                {item.lp_number !== "" &&
                  item.lp_number !== null &&
                  item.lp_number !== "null" &&
                  item.lp_number !== undefined ? (
                  <td>{item.lp_number}</td>
                ) : (
                  <td>-</td>
                )}

                {item.violation_details.code !== "" &&
                  item.violation_details.code !== null &&
                  item.violation_details.code !== "null" &&
                  item.violation_details.code !== undefined ? (
                  <td>{item.violation_details.code}</td>
                ) : (
                  <td>-</td>
                )}
                
                {item.violation_details.description !== "" &&
                  item.violation_details.description !== null &&
                  item.violation_details.description !== "null" &&
                  item.violation_details.description !== undefined ? (
                  <td>{item.violation_details.description}</td>
                ) : (
                  <td>-</td>
                )}

                {item.registered_ro_detail.current_address.address !== "" &&
                  item.registered_ro_detail.current_address.address !== null &&
                  item.registered_ro_detail.current_address.address !== "null" &&
                  item.registered_ro_detail.current_address.address !== undefined ? (
                  <td>{item.registered_ro_detail.current_address.address}</td>
                ) : (
                  <td>-</td>
                )}

                <td>-</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showCancelCitation && (
        <div className="cstm-site-model">
          <div className="cstm-site-model-block fixed z-20">
            <div className="cstm-site-popup-bg">
              <div className="cstm-site-model-wrapper auth-taken-cstm-model-wrapper">
                <button onClick={closeError} className="cstm-close-btn-db-is">
                  <img src={close} alt="close" />
                </button>
                <div>
      <p className="cancel-citation-ttl-main">Cancel {changeTotal}</p>
                  <span className="auth-error-msg-success">
                    {showSuccessMessage}
                  </span>
                  <span className="auth-error-msg">{showErrorMessage}</span>
                  <span className="cancel-title-main">
                    Selected {changeName} Numbers
                  </span>
                  <textarea
                    type="text"
                    className="commet-citation-cancel"
                    value={showSelectedTicketNumber}
                    disabled
                  />
                  <br />
                  <span className="cancel-title-main">
                    Cancel {changeTotal} Reason
                  </span>
                  <select
                    className="form-select-site"
                    name="select_reason"
                    value={showCancelCitationReason}
                    onChange={onChangeCancelCitation}
                  >
                      {Cookies.get("agency_id") !==
                      "7126cb4a-eb0a-11ec-8ca9-66552211e1c9" && (
                        <option value="CUSTOMER SUPPORT CANCEL">
                          CUSTOMER SUPPORT CANCEL
                        </option>
                      )}
                    <option value="MANAGER CANCEL">MANAGER CANCEL</option>
                    <option value="SUPERVISOR CANCEL">SUPERVISOR CANCEL</option>
                    <option value="PLATE NUMBER TRANSPOSED">
                      PLATE NUMBER TRANSPOSED
                    </option>
                    <option value="CLIENT APPROVED">CLIENT APPROVED</option>
                    <option value="OFFICER ERROR">OFFICER ERROR</option>
                    <option value="SYSTEM ERROR">SYSTEM ERROR</option>
                    {!isKalamazoo() && <option value="PARK WHIZ">PARK WHIZ</option>}
                    {isKalamazoo() && <option value="COURTESY VOID">COURTESY VOID</option>}
                    {isKalamazoo() && <option value="HANDICAP VOID">HANDICAP VOID</option>}
                  </select>
                  <br />
                  <span className="cancel-title-main">
                    Cancel {changeTotal} Comments
                  </span>
                  <textarea
                    name="comment"
                    placeholder="Enter Comments"
                    value={showCancelCitationComment}
                    onChange={onChangeCancelCitation}
                    className="commet-citation-cancel"
                  />
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showCommentEmptyMessage}
                  </span>
                  <button
                    className="auth-cstm-submit-btn"
                    onClick={OnClickCancelButton}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModifyAmount && (
        <div className="cstm-site-model">
          <div className="cstm-site-model-block fixed z-20">
            <div className="cstm-site-popup-bg">
              <div className="cstm-site-model-wrapper auth-taken-cstm-model-wrapper">
                <button onClick={closeError} className="cstm-close-btn-db-is">
                  <img src={close} alt="close" />
                </button>
                <div>
                  <p className="cancel-citation-ttl-main">Modify Amount</p>
                  <span className="auth-error-msg-success">
                    {showSuccessMessage}
                  </span>
                  <span className="auth-error-msg">{showErrorMessage}</span>
                  <span className="cancel-title-main">
                    Selected {changeName} Numbers
                  </span>
                  <textarea
                    type="text"
                    className="commet-citation-cancel"
                    value={showSelectedTicketNumber}
                    disabled
                  />
                  <br />
                  <span className="cancel-title-main">New Amount</span>
                  <div className="pl-doller-icon">
                    <input
                      type="number"
                      className="form-input-fuild cstm-form-input"
                      name="new_amount"
                      placeholder="Enter new amount"
                      value={showModifyNewAmount}
                      onChange={onChangeModifyAmount}
                    />
                  </div>
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showAmountEmptyMessage}
                  </span>
                  <br />
                  <span className="cancel-title-main">
                    Modify Amount Reason
                  </span>
                  <input
                    type="text"
                    className="form-input-fuild cstm-form-input"
                    name="modify_reason"
                    placeholder="Enter modify reason"
                    value={showModifyAmountReason}
                    onChange={onChangeModifyAmount}
                  />
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showReasonEmptyMessage}
                  </span>

                  <br />
                  <span className="cancel-title-main">
                    Modify Amount Comments
                  </span>
                  <textarea
                    placeholder="Enter comments"
                    name="modify_comments"
                    value={showModifyAmountComments}
                    onChange={onChangeModifyAmount}
                    className="commet-citation-cancel"
                  />
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showCommentEmptyMessage}
                  </span>

                  <button
                    className="auth-cstm-submit-btn"
                    onClick={OnClickModifyButton}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductTable;
