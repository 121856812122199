import ResidentalPermit from "../../../../Images/residentalPermit.png";
import VisitorPermit from "../../../../Images/visitorPermit.png";
import ConstructionPermit from "../../../../Images/constructionPermit.png";
import NannyPermit from "../../../../Images/nannyPermit.png";
import LandlordsPermit from "../../../../Images/landloardsPermit.png";
import MedicalPermit from "../../../../Images/medicalPermit.png";
import EmployeePermit from "../../../../Images/employeePermit.png";
import BusinessPermit from "../../../../Images/businessPermit.png";
import EVPermit from "../../../../Images/evPermit.png";
import TemporaryPermit from "../../../../Images/temporaryPermit.png";
import lotPermit from "../../../../Images/lotPermit.png";

const PermitTypeTab = ({ permit, handleTabChange, changedPermit}) => {
  return (
    <>
      <div className={`permit-type-tabt`}>
        {permit && permit.length > 0 ? (
          permit.map((itm) => (
            <div
              className="permit-type-box"
              onClick={() => {handleTabChange(2)
                 changedPermit(itm.title)}}
              key={itm.id}
            >
              <div className="permit-type-box-img">
                {itm.id !== 12 && <img
                  src={
                    itm.id === 1
                      ? ResidentalPermit
                      : itm.id === 2
                      ? VisitorPermit
                      : itm.id === 3
                      ? ConstructionPermit
                      : itm.id === 4
                      ? NannyPermit
                      : itm.id === 5
                      ? LandlordsPermit
                      : itm.id === 6
                      ? MedicalPermit
                      : itm.id === 7
                      ? EmployeePermit
                      : itm.id === 8
                      ? BusinessPermit
                      : itm.id === 9
                      ? EVPermit
                      : itm.id === 10
                      ? TemporaryPermit
                      : itm.id === 11
                      ? lotPermit
                      : itm.image
                  }
                  alt=""
                />}
              </div>
              <div className="permit-type-box-cont">
                <h3 className="permit-type-h3">{itm.title}</h3>
                <p className="permit-type-p">{itm.description}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-record-found-main">No Record Found</div>
        )}
      </div>
    </>
  );
};

export default PermitTypeTab;
