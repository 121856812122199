import moment from "moment";

function pad2(n) {
  return (n < 10 ? '0' : '') + n;
}

export function stripeCardNumberValidation(cardNumber) {
  const regexPattern = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) {
      if (cardNumber) {
        return cardNumber &&
          /^[1-6]{1}[0-9]{14,15}$/i.test(
            cardNumber.replace(/[^\d]/g, "").trim()
          )
          ? ""
          : "Enter a valid Card";
      }
    }
  }
  return "Enter a valid Card";
}

export const stripeCardExpirValidation = (value) => {
  if (value) {
    // if (/^\d{4}\/(0?[1-9]|1[012])$/i.test(value.trim())) {
    if (/^(0?[1-9]|1[012])[\/]\d{4}$/i.test(value.trim())) {
      let today = new Date();
      let CurrentDate = moment(new Date(today.getFullYear() + "-" + pad2(today.getMonth() + 1) + "-" + new Date(today.getFullYear(), pad2(today.getMonth() + 1), 0).getDate()));
      let visaValue = value.split("/");
      let visaDate = new Date(visaValue[1], visaValue[0], 0);
   
      return CurrentDate < moment(visaDate)
        ? undefined
        : "Please enter valid date";
    } else {
      return "Invalid date format";
    }
  }
};

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be 3 characters or more` : undefined;

export const cardMinLength = (min) => (value) =>
  value && value.length < min ? `Must be 4 characters` : undefined;