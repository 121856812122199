import { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import CheckBoxes from "../types/checkBoxes";
import RadioButton from "../types/radioButton";
import Paragraph from "../types/paragraph";
import TextField from "../types/textField";
import SelectField from "../types/seleceField";
import DeleteCQ from "../../../../../Images/delete-cq.svg";
import DatePickerField from "../types/datePicker";
import PermitPlateDetails from "../types/permitPlateDetails";
import PermitDuration from "../types/permitDuration";
import ErrorModalComponent from "../../../../../utile/modals/errorModal";
import FileField from "../types/fileField";
import SignatureField from "../types/signatureField";
import PermitUserDetails from "../types/permitUserDetails";
import PermitAddressDetails from "../types/permitAddressDetails";

const FormBuilderContainer = ({
  permitName,
  permitLayoutHandler,
  editFields,
  isEditPermit,
  permitTime,
  loading,
  residentType
}) => {
  const [fields, setFields] = useState([]);
  const [dragActive, setDragActive] = useState(true);
  const [toolboxClick, setToolboxClick] = useState();
  const [showError,setShowError] = useState(false);
  const [displayMsg,setDisplayMsg] = useState("")

  useEffect(() => {
    if (isEditPermit && editFields && editFields.length > 0) {
      editFields.find(itm => itm.component === "PermitQuantity").fields[0].question_name = editFields.find(itm => itm.component === "PermitQuantity").question_name
      editFields.find(itm => itm.component === "PermitPlateDetails").fields.unshift(editFields.find(itm => itm.component === "PermitQuantity").fields[0])
      const quantityIndex = editFields.findIndex(itm => itm.component === "PermitQuantity")
      editFields.splice(quantityIndex, 1)
      setFields(editFields);
    }
  }, [editFields]);

  const onClickEvent = (index) => {
    setToolboxClick(index);
  };

  const timeAvailability = [
    permitTime.daily && "Daily",
    permitTime.monthly && "Monthly",
    permitTime.quarterly && "Quarterly",
    permitTime.annually && "Annually",
    permitTime.event && "Event"
  ].filter(itm => itm);

  const catchField = (data, innerTool, current) => {
    var meta = {};
    if (data === "Dropdown") {
      meta = {
        component: "Dropdown",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "dropdown",
            repr: "DropDown",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            collection_name: null,
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "dropdown",
            options: [{ title: "Type Option 1" }, { title: "Type Option 2" }],
            key_name:"dropdown"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "CheckBox") {
      meta = {
        component: "CheckBox",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "checkbox",
            repr: "CheckBoxes",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "checkbox",
            options: [{ title: "Type Option 1" }, { title: "Type Option 2" }],
            collection_name: "",
            key_name:"checkbox"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "Radio") {
      meta = {
        component: "Radio Button",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "radiobutton",
            repr: "Radio Button",
            aligned: "left",
            is_editable: true,
            is_required: true,
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "radio",
            options: [{ title: "Type Option 1" }, { title: "Type Option 2" }],
            collection_name: "",
            key_name:"radio_button"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "Textarea") {
      meta = {
        component: "Textarea",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "textarea",
            repr: "TextArea",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "textarea",
            collection_name: "",
            key_name:"text_area"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "TextBox") {
      meta = {
        component: "TextBox",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "textview",
            repr: "TextView",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "textview",
            collection_name: "",
            key_name:"text_box"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "DatePicker") {
      meta = {
        component: "DatePicker",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "Date",
            repr: "DatePicker",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "date",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "datepicker",
            collection_name: "",
            key_name:"date"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "Permit-Duration") {
      const meta = {
        component: "PermitDuration",
        question_name: "Choose the desired duration for your selection. Indicate the time frame that suits your needs best!",
        fields: [
          {
            name: "duration",
            repr: "Duration",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            collection_name: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: "permit_time_availability",
            delete_enable: false,
            tag: "duration",
            options: timeAvailability.map(itm => {return {title:itm}}),
            key_name:"duration"
          },
        ],
      }
      setFields([...fields, meta]);
    }
    if (data === "Recurring-Payment") {
      const meta = {
        component: "RecurringPayment",
        question_name: "Opt for convenience! Sign up for recurring payments to ensure seamless transactions. Select 'yes' and set up hassle-free billing now.",
        fields: [
          {
            name: "recurring_payment",
            repr: "Recurring Payment",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            collection_name: "",
            tag: "recurring payment",
            options: [{ title: "Yes, i want to sign up" }, { title: "No, i don't want to sign up" }],
            key_name:"auto_renewal"
          },
        ],
      }
      setFields([...fields, meta]);
    }
    if (data === "Permit-Notification") {
      const meta = {
        component: "PermitNotification",
        question_name: "Receive permit updates! opt-in for notifications on permit explanations and details changes. stay informed effortlessly. select 'yes' to notify.",
        fields: [
          {
            name: "permit_notification",
            repr: "Permit Notifications",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            collection_name: "",
            tag: "permit notification",
            options: [{ title: "Yes, i want to sign up" }, { title: "No, i don't want to sign up" }],
            key_name:"permit_notify_updates"
          },
        ],
      }
      setFields([...fields, meta]);
    }
    if (data === "Permit-Quantity-Plate-Details") {
      const meta = {
        component: "PermitPlateDetails",
        question_name: "Submit your vehicle's plate number, state and date for accurate registration. Ensure correct information for a seamless process.",
        object_key:"plate_details",
        fields: [
          {
            name: "permit_quantity",
            repr: "Permit Quantity",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "100",
            min_length: "1",
            data_type_validation: "number",
            field_name: null,
            delete_enable: false,
            tag: "permit_quantity",
            collection_name: "",
            question_name: "Specify the number of permits you wish to purchase. Provide the quantity needed for accurate processing. Thank you!",
            key_name:"permit_quantity"
          },
          {
            name: "plate_no",
            repr: "Plate No",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            collection_name: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "plate no",
            key_name:"lp_number"
          },
          {
            name: "state",
            repr: "State",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            collection_name: "StateList",
            data_type_validation: "",
            field_name: "state_abbreviated",
            delete_enable: false,
            tag: "plate state",
            key_name:"state",
            options: [],
          },
          {
            name: "start_date",
            repr: "Start Date",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "date",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            collection_name: "",
            data_type_validation: "datetime",
            field_name: null,
            tag: "start date",
            key_name:"start_date"
          },
          {
            name: "year",
            repr: "Year",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "date",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            collection_name: "",
            data_type_validation: "datetime",
            field_name: null,
            tag: "year",
            key_name:"year"
          },
          {
            name: "make",
            repr: "Make",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            collection_name: "MakeModelList",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "make",
            key_name:"make"
          },
          {
            name: "model",
            repr: "Model",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            collection_name: "MakeModelList",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "model",
            key_name:"model"
          },
          {
            name: "color",
            repr: "Color",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            collection_name: "CarColorList",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "Color",
            key_name:"color"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "Permit-Quantity") {
      const meta = {
        component: "PermitQuantity",
        question_name: "Specify the number of permits you wish to purchase. Provide the quantity needed for accurate processing. Thank you!",
        fields: [
          {
            name: "permit_quantity",
            repr: "PermitQuantity",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "quantity",
            key_name:"permit_quantity"
          },
        ],
      }
      setFields([...fields, meta]);
    }
    if (data === "Upload-Files") {
      meta = {
        component: "File",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "file",
            repr: "File",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "file",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "File",
            collection_name: "",
            key_name:"file"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "Signature") {
      meta = {
        component: "Signature",
        question_name: "Enter your question here...",
        fields: [
          {
            name: "signature",
            repr: "Signature",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "file",
            print: true,
            removable: true,
            default_value: false,
            default_value_set: "",
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            delete_enable: false,
            data_type_validation: "",
            field_name: null,
            tag: "Signature",
            collection_name: "",
            key_name:"signature"
          },
        ],
      };
      setFields([...fields, meta]);
    }
    if (data === "User-Details") {
      const meta = {
        component: "UserDetails",
        question_name: "Please enter user information.",
        fields: [
          {
            name: "first_name",
            repr: "First Name",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "first_name",
            key_name:"first_name"
          },
          {
            name: "last_name",
            repr: "Last Name",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "last_name",
            key_name:"last_name"
          },
          {
            name: "user_name",
            repr: "User Name",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "user_name",
            key_name:"user_name"
          },
          {
            name: "phone_number",
            repr: "Phone Number",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "phone_number",
            key_name:"phone_number"
          },
          {
            name: "email",
            repr: "Email",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "email",
            key_name:"email"
          },
        ],
      }
      setFields([...fields, meta]);
    }
    if (data === "Address-Details") {
      const meta = {
        component: "AddressDetails",
        question_name: "Please enter address information.",
        fields: [
          {
            name: "address",
            repr: "Address",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "address",
            key_name:"address"
          },
          {
            name: "city",
            repr: "City",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "city",
            key_name:"city"
          },
          {
            name: "state",
            repr: "State",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            collection_name: "StateList",
            data_type_validation: "",
            field_name: "state_abbreviated",
            delete_enable: false,
            tag: "plate state",
            key_name:"state",
            options: [],
          },
          {
            name: "zip_code",
            repr: "Zip Code",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "zip_code",
            key_name:"zip_code"
          },
        ],
      }
      setFields([...fields, meta]);
    }
    if (data === "HandTag-Id") {
      meta = {
        component: "HandTagId",
        question_name: "Enter hand tag id.",
        fields: [
          {
            name: "hand_tag_id",
            repr: "HandTagId",
            aligned: "left",
            is_editable: true,
            is_required: true,
            type: "String",
            print: true,
            removable: true,
            default_value: null,
            form_layout_order: "",
            print_layout_order: "",
            calculated_field: "",
            max_length: "",
            min_length: "",
            collection_name: null,
            data_type_validation: "",
            field_name: null,
            delete_enable: false,
            tag: "hand_tag_id",
            options: [{ title: "Type Option 1" }, { title: "Type Option 2" }],
            key_name:"hand_tag_id"
          },
        ],
      };
      setFields([...fields, meta]);
    }

    onClickEvent(fields.length);
    setDragActive(false);
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const dropItem = e.dataTransfer.getData("dragField");
    if(fields.find(itm => itm.component === "PermitPlateDetails") && dropItem === "Permit-Quantity-Plate-Details"){
      setShowError(true);
      setDisplayMsg("For Configuring Q&A for permit you can add Permit Plate/Quantity details component only at once.")
    } else if(fields.find(itm => itm.component === "PermitDuration") && dropItem === "Permit-Duration"){
      setShowError(true);
      setDisplayMsg("For Configuring Q&A for permit you can add Permit Duration component only at once.")
    } else {
      catchField(dropItem);
    }
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = () => {
    let _fields = [...fields];

    const draggedItemContent = _fields.splice(dragItem.current, 1)[0];

    _fields.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setFields(_fields.filter((i) => i.component));
  };

  const changeChildState = (e, index) => {
    if (index !== -1) {
      let field = [...fields];
      field[index] = e;
      setFields(field);
    }
  };

  const remove = (index) => {
    const newArr = [...fields];
    newArr.splice(index, 1);
    setFields(newArr);
  };

  const removeAll = () => {
    setFields([]);
  };

  const renderFields = (field, index) => {
    if (field.component === "Dropdown") {
      return (
        <SelectField
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "CheckBox") {
      return (
        <CheckBoxes
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "Radio Button") {
      return (
        <RadioButton
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "Textarea") {
      return (
        <Paragraph
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "TextBox") {
      return (
        <TextField
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "DatePicker") {
      return (
        <DatePickerField
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "PermitDuration") {
      return (
        <PermitDuration
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "PermitPlateDetails") {
      return (
        <PermitPlateDetails
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if(field.component === "RecurringPayment"){
      return (
        <RadioButton
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if(field.component === "PermitNotification"){
      return (
        <RadioButton
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "File") {
      return (
        <FileField
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if(field.component === "Signature") {
      return (
        <SignatureField
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      )
    }
    if(field.component === "UserDetails"){
      return (
        <PermitUserDetails
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if(field.component === "AddressDetails"){
      return (
        <PermitAddressDetails
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
      );
    }
    if (field.component === "HandTagId") {
      return (
        <SelectField
          field={field}
          index={index}
          key={index}
          changeState={(e, index) => changeChildState(e, index)}
          hideField={dragActive}
          toolboxClick={toolboxClick}
          removeField={(index) => remove(index)}
        />
        // <TextField
        //   field={field}
        //   index={index}
        //   key={index}
        //   changeState={(e, index) => changeChildState(e, index)}
        //   hideField={dragActive}
        //   toolboxClick={toolboxClick}
        //   removeField={(index) => remove(index)}
        // />
      );
    }
  };

  const formSubmitHandler = () => {
    const payload = { permit_name: permitName, template: [...fields] };
    const duration = fields.find(itm => itm.component === "PermitDuration")
    const address = fields.find(itm => itm.component === "AddressDetails")
    const user = fields.find(itm => itm.component === "UserDetails")
    const handtagId = fields.find(itm => itm.component === "HandTagId")
    const detail = fields.find(itm => itm.component === "PermitPlateDetails")
    if (residentType === "PERMANENT") {
      if (duration && detail && address && user && handtagId) {
        const currentIndex = payload.template.findIndex(itm => itm.component === "PermitPlateDetails")
        const withoutPlatePayload = payload.template.filter(itm => itm.component !== "PermitPlateDetails");
        const withPlatePayload = payload.template.filter(itm => itm.component === "PermitPlateDetails");
  
        withPlatePayload.map(itm => withoutPlatePayload.splice(currentIndex, 0, {
          component: "PermitQuantity",
          question_name: itm.fields[0].question_name,
          fields: [itm.fields[0]]
        }, {
          component: itm.component,
          question_name: itm.question_name,
          object_key: itm.object_key,
          fields: itm.fields.slice(1)
        }))
  
        const newPayload = { permit_name: permitName, template: [...withoutPlatePayload] }
        permitLayoutHandler(newPayload);
      } else {
        setShowError(true)
        setDisplayMsg("For configuring Q/A for permit you have to select Permit Duration, Permit Quantity/plate details, Address Details and User Details blocks")
      }
    } else if (residentType === "VISITOR") {
      if (duration && address && user) {
        let newPayload = { permit_name: permitName, template: [...payload.template] }
        if (detail) {
          const currentIndex = payload.template.findIndex(itm => itm.component === "PermitPlateDetails")
          const withoutPlatePayload = payload.template.filter(itm => itm.component !== "PermitPlateDetails");
          const withPlatePayload = payload.template.filter(itm => itm.component === "PermitPlateDetails");
          withPlatePayload.map(itm => withoutPlatePayload.splice(currentIndex, 0, {
            component: "PermitQuantity",
            question_name: itm.fields[0].question_name,
            fields: [itm.fields[0]]
          }, {
            component: itm.component,
            question_name: itm.question_name,
            object_key: itm.object_key,
            fields: itm.fields.slice(1)
          }))
    
          newPayload = { permit_name: permitName, template: [...withoutPlatePayload] }
        }
  
        permitLayoutHandler(newPayload);
      } else {
          setShowError(true)
          setDisplayMsg("For configuring Q/A for permit you have to select Permit Duration, Address Details and User Details blocks")
      }
    }

  };

  return (
    <>
      <div
        className="configure-question-cont-all"
        onDrop={(e) => onDropHandler(e)}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="configure-question-cont-all-head">
          <span className="configure-question-cont-all-head-title">
            Question Form
          </span>
          {fields !== null && fields.length > 0 && (
            <div
              className="configure-question-cont-all-head-delete"
              onClick={() => removeAll()}
            >
              <img src={DeleteCQ} />
            </div>
          )}
        </div>
        <div
          className={
            dragActive
              ? "dragActive configure-question-cont-al-body "
              : "configure-question-cont-al-body"
          }
        >
          <div className="list-group">
            {fields !== null && fields.length > 0 ? (
              fields.map((field, index) => {
                return (
                  <div
                    className={toolboxClick === index ? "csmt_click_event" : ""}
                    key={index}
                    draggable
                    onDragStart={(e) => (dragItem.current = index)}
                    onDragEnter={(e) => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                    onClick={() => onClickEvent(index)}
                  >
                    <p>{renderFields(field, index)}</p>
                  </div>
                );
              })
            ) : (
              <div className="configure-permit-drag">
                <span>Drag your component here from the left.</span>
              </div>
            )}
            {fields !== null && (
              <div className="configure-permit-last-btn pt-4">
                <button
                  class="permin-btn"
                  onClick={() => formSubmitHandler()}
                >
                  {loading ? <div className="spinner-border"></div> : "Submit"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {showError && (
          <ErrorModalComponent
            handleCloseModel={() => setShowError(false)}
            displayMsg={displayMsg}
          />
      )}
    </>
  );
};

export default FormBuilderContainer;
