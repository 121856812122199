import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import other from "./other";
import permitReducer from "./permits";

const rootReducer = combineReducers({
  auth,
  message,
  other,
  permitReducer
});

export default rootReducer