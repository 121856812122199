import React from 'react'
import './pagination.css'


const Pagination = (props) => {
    // init
    const { currentPage, maxPageLimit, minPageLimit, pageNumberLimit } = props;
    const totalPages = props.totalPages - 1;


    // build page numbers list based on total number of pages
    const pages = [];
    for (let i = 1; i <= Math.ceil(totalPages/pageNumberLimit); i++) {
        pages.push(i);
    }

    const handlePrevClick = () => {
        props.onPrevClick();
    }

    const handleNextClick = () => {
        props.onNextClick();
    }

    const handlePageClick = (e) => {
        props.onPageChange(Number(e.target.id));
    }

    const pageNumbers = pages.map(page => {
        if (page <= maxPageLimit && page > minPageLimit) {
            return (
                <li className={currentPage === page ? 'active-paginated' : null}>
                    <button  key={page} id={page} onClick={handlePageClick}
                    >  {page}</button>
                </li>
            );
        } else {
            return null;
        }
    }

    );



    // page ellipses
    let pageIncrementEllipses = null;
    if (pages.length > maxPageLimit) {
        pageIncrementEllipses = <li onClick={handleNextClick}>&hellip;</li>
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
        pageDecremenEllipses = <li onClick={handlePrevClick}>&hellip;</li>
    }

    return (
        <ul className="cstm-pagination-added-bottom">
            <li>
                <button onClick={handlePrevClick} disabled={currentPage === pages[0]}>Prev</button>
            </li>
            {pageDecremenEllipses}
            {pageNumbers}
            {pageIncrementEllipses}
            <li>
                <button onClick={handleNextClick} disabled={currentPage === pages[pages.length - 1]}>Next</button>
            </li>
        </ul>
    )
}

export default Pagination