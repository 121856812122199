const PermitNoticeListContainer = () => {
    return (
        <>
            <div>
                Permit notice list
            </div>
        </>
    )
}

export default PermitNoticeListContainer;