import { useEffect } from "react";
import ArrowLeft from "../../../../Images/arrow-left.png";
import Maps from "../../../../Images/map.png";


const PermitGeofencingTab = ({ HandlePermitSubmit, handleTabChange, permitTitle, loading,payloadData,isEditPermit }) => {
  

  let status = "GEO_STATUS"
  useEffect(() => {
    if (isEditPermit) {
      if (payloadData.status !== "ACTIVE") {
        status = "GEO_STATUS"
      } else {
        status = "ACTIVE"
      }
    }
  }, [])
  
  const formSubmitHandler = () => {
    HandlePermitSubmit({
      status
    })
  }
  
  return (
    <>
      <div className={`permit-geofencing`}>

        <div className="permin-form-main">

        <h3 class="permin-form-title">{permitTitle}</h3>
          <div className="permit-geofencing-map">
          </div>

        </div>

     
        <div className="permin-form-btn">
            <div className="permin-form-btn-left">
              <button className="permin-btn">
                <img src={ArrowLeft} alt="back"/>
                Back
              </button>
            </div>
            <div className="ermin-form-btn-right" onClick={formSubmitHandler}>
              <button className="permin-btn" type="submit">
                Save
              </button>
            </div>
          </div>

      </div>
    </>
  );
};

export default PermitGeofencingTab;