import { useParams } from 'react-router';
import { ENV } from '../../../Services/main.service';

const HelicalReportComponent = ({ url, reportName, token }) => {
  const { plateNumber } = useParams();
  const pathname = window.location.hostname;

  let HELICAL_ENV_ID = "";
  if (pathname === "processing.parkocmd.com" || pathname === 'processing-parkocmd.netlify.app' || pathname === 'staging-processing.parkocmd.com') {
    HELICAL_ENV_ID = "OCMD";
  } else if (ENV === 'production') {
    HELICAL_ENV_ID = "1689665466882";
  } else {
    HELICAL_ENV_ID = "Staging";
  };

  const REPORT_BASE_URL_PATH = `https://reports.parkloyalty.com/hi-ee/hi.html`;
  let REPORT_BASE_URL;
  if (plateNumber) {
    REPORT_BASE_URL = `${REPORT_BASE_URL_PATH}?dir=${HELICAL_ENV_ID}/${url}&License%20No.=${plateNumber}&authToken=${token}`
  } else {
    REPORT_BASE_URL = `${REPORT_BASE_URL_PATH}?dir=${HELICAL_ENV_ID}/${url}&authToken=${token}`
  }

  return (
    <>
      (
      <section>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel"
              style={{ height: "100%" }}
            >
              <div className="cstm-ticket-manage-sec">
                <div className="cstm-ticket-manage-heading">
                  <h3 className="cstm-all-pg-ttl operate-ttl">
                    {reportName}
                  </h3>
                </div>
              </div>
              <div className="cstm-table-scroll" style={{ height: "100%" }}>
                <div className="table-overflow" style={{ height: "inherit" }}>
                  <iframe
                    title="report"
                    style={{ height: "100%", width: "100%", border: "0px" }}
                    id="helicalinsight-report-frame"
                    src={REPORT_BASE_URL}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )
    </>
  );
};

export default HelicalReportComponent;
