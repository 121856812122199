import ParkLogo from "../Images/Parklogo.png";
import Encinitas from "../Images/encinitas.png";
import logo_duncan from "../Images/logo-duncan.png";
import logo_sp from "../Images/logo-sp.png";
import CityofLeavenWorth from "../Images/cityofleavenworth.png";
import FollyBeach from "../Images/Folly-Beach-Logo-P.png";
import Mansfield from "../Images/mansfieldct.png";
import Mystic from "../Images/mystic.png";
import Greenvillesc from "../Images/Greenvillesc.png";
import Oceancity from "../Images/oceancityLogo.png";
import Lazparking from "../Images/lazparking.png";
import kalamazoo from "../Images/kalamazoo.png";
import bismarck from "../Images/bismarck-logo.png";
import Parkiop from "../Images/parkiop-logo.png";
import ParkBangor from "../Images/parkBangor.png";
import AshLandLogo from "../Images/ashland.png";
import BeaufortLogo from "../Images/beaufort-logo.png";
import HartfortLogo from "../Images/hpa-logo.png";
import cohassetLogo from "../Images/cohasset.png";
import peakParkingLogo from '../Images/peaklogo.png';
import parkSeptaLogo from '../Images/septa-logo.png';
import parkcharlestonLogo from '../Images/parkcharleston-logo.png';
import parkKCMOLogo from '../Images/kansas-logo.png';

//favicon
import FaviconCityofLeavenWorth from "../Images/cityofleavenworth.ico";
import FaviconEncinitas from "../Images/encinitas.ico";
import FaviconParkloyalty from "../Images/favicon-parkloyalty.png";
import FaviconDuncan from "../Images/favicon-duncan.ico";
import FaviconSP from "../Images/favicon-sp.ico";
import FaviconFollyBeach from "../Images/folly-beach.ico";

import FaviconMansfield from "../Images/mansfieldct.ico";
import FaviconGreenvillesc from "../Images/FaviconGreenvillesc.ico";
import FaviconMystic from "../Images/mystic.ico";
import FaviconLazparking from "../Images/faviconlazparking.ico";
import FaviconOceancity from "../Images/faviconOceancity.ico";
import FaviconKalamazoo from "../Images/FaviconKalamazoo.png";
import faviconBismarck from "../Images/faviconBismarck.ico";
import FaviconParkiop from "../Images/parkiop-favicon.ico";
import FaviconParkBangor from "../Images/FaviconParkBangor.ico";
import FaviconAshLand from "../Images/favicon-ashland.ico";
import FaviconBeaufort from "../Images/beaufort-favicon.ico";
import FaviconHartfort from "../Images/hpa-favicon.ico";
import FaviconCohasset from "../Images/favicon-cohasset.ico";
import FaviconPeakParking from '../Images/peak-favicon.ico';
import FaviconParkSepta from '../Images/septa-favicon.ico';
import FaviconParkCharleston from '../Images/parkcharleston-favicon.ico';
import FaviconParkKCMO from '../Images/kansas-favicon.ico';

import { createContext, useState, useEffect } from "react";

export const BrandingContext = createContext();

export const Branding = ({ children }) => {
  const [logo, setLogo] = useState(ParkLogo);
  const [favicon, setFavicon] = useState(FaviconParkloyalty);
  const [text, setText] = useState("Ticket Processing");

  const pathname = window.location.hostname;

  useEffect(() => {
    if (pathname) {
      if (
        pathname === 'staging-processing-parkkcmo.netlify.app' ||
        pathname === 'staging-processing.parkkcmo.com' ||
        pathname === 'processing-parkkcmo.netlify.app' ||
        pathname === 'processing.parkkcmo.com'
      ) {
        setLogo(parkKCMOLogo);
        setFavicon(FaviconParkKCMO);
        setText("Park KCMO");
      } else if (
        pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
        pathname === 'staging-processing.parkcharleston-sc.com' ||
        pathname === 'processing-parkcharleston-sc.netlify.app' ||
        pathname === 'processing.parkcharleston-sc.com'
      ) {
        setLogo(parkcharlestonLogo);
        setFavicon(FaviconParkCharleston);
        setText("Park Charleston");
      } else if (
        pathname === 'staging-processing.parksepta.com' ||
        pathname === 'staging-processing-parksepta.netlify.app' ||
        pathname === 'processing.parksepta.com' ||
        pathname === 'processing-parksepta.netlify.app'
      ) {
        setLogo(parkSeptaLogo);
        setFavicon(FaviconParkSepta);
        setText("Park SEPTA");
      } else if (
        pathname === 'staging-processing.parkingenforcementco.com' ||
        pathname === 'staging-processing-parkingenforcementco.netlify.app' ||
        pathname === 'processing-parkingenforcementco.netlify.app' ||
        pathname === 'processing.parkingenforcementco.com'
      ) {
        setLogo(peakParkingLogo);
        setFavicon(FaviconPeakParking);
        setText("Peak Parking");
      } else if (
        pathname === "staging-processing-parkcohasset.netlify.app" ||
        pathname === "staging-processing.parkcohasset.com" ||
        pathname === "processing-parkcohasset.netlify.app" ||
        pathname === "processing.parkcohasset.com"
      ) {
        setLogo(cohassetLogo);
        setFavicon(FaviconCohasset);
        setText("Park Cohasset");
      } else if (
        pathname === "processing.hpa2pay.com" ||
        pathname === "processing-hpa2pay.netlify.app" ||
        pathname === "staging-processing-hpa2pay.netlify.app" ||
        pathname === "staging-processing.hpa2pay.com"
      ) {
        setLogo(HartfortLogo);
        setFavicon(FaviconHartfort);
        setText("Hartford");
      } else if (
        pathname === "staging-processing.parkbeaufortnc.com" ||
        pathname === "staging-processing-parkbeaufortnc.netlify.app" ||
        pathname === "processing.parkbeaufortnc.com" ||
        pathname === "processing-parkbeaufortnc.netlify.app"
      ) {
        setLogo(BeaufortLogo);
        setFavicon(FaviconBeaufort);
        setText("Beaufort");
      } else if (
        pathname === "staging-processing.parkashlandoregon.com" ||
        pathname === "staging-processing-parkashlandoregon.netlify.app" ||
        pathname === "processing.parkashlandoregon.com" ||
        pathname === "processing-parkashlandoregon.netlify.app"
      ) {
        setLogo(AshLandLogo);
        setFavicon(FaviconAshLand);
        setText("Ashland");
      } else if (
        pathname === "staging-processing-parkriveroakssc.netlify.app" ||
        pathname === "staging-processing.parkriveroakssc.com" ||
        pathname === "processing-parkriveroakssc.netlify.app" ||
        pathname === "processing.parkriveroakssc.com"
      ) {
        // setCustomBranding(Lazparking)
        setLogo(Lazparking);
        setFavicon(FaviconLazparking);
        setText("Park River Oaks");
      } else if (
        pathname === "staging-processing-parkbangor.netlify.app" ||
        pathname === "staging-processing.parkbangor.org" ||
        pathname === "processing-parkbangor.netlify.app" ||
        pathname === "processing.parkbangor.org"
      ) {
        setLogo(ParkBangor);
        setFavicon(FaviconParkBangor);
        setText("Park Bangor");
      } else if (
        pathname === "staging-processing-parkiop.netlify.app" ||
        pathname === "staging-processing.parkiop.com" ||
        pathname === "processing-parkiop.netlify.app" ||
        pathname === "processing.parkiop.com"
      ) {
        setLogo(Parkiop);
        setFavicon(FaviconParkiop);
        setText("Park IOP");
      } else if (
        pathname === "staging-processing.parkbismarcknd.com" ||
        pathname === "staging-processing-parkbismarck.netlify.app" ||
        pathname === "processing-bismarck.netlify.app" ||
        pathname === "processing.parkbismarcknd.com"
      ) {
        setLogo(bismarck);
        setFavicon(faviconBismarck);
        setText("Bismarck");
      } else if (
        pathname === "staging-processing.parkkalamazoo.com" ||
        pathname === "staging-processing-parkkalamazoo.netlify.app" ||
        pathname === "processing-parkkalamazoo.netlify.app" ||
        pathname === "processing.parkkalamazoo.com"
      ) {
        setLogo(kalamazoo);
        setFavicon(FaviconKalamazoo);
        setText("Kalamazoo");
      } else if (
        pathname === "lazstaging-processing.netlify.app" ||
        pathname === "staging-processing.parkcountryclubplaza.com" ||
        pathname === "laz-processing.netlify.app" ||
        pathname === "processing.parkcountryclubplaza.com"
      ) {
        setLogo(Lazparking);
        setFavicon(FaviconLazparking);
        setText("Park Country Club Plaza");
      } else if (
        pathname === "oceancity-processing-staging.netlify.app" ||
        pathname === "oceancity-processing-staging.parkloyalty.com" ||
        pathname === "staging-processing.parkocmd.com" ||
        pathname === "processing.parkocmd.com" ||
        pathname === "processing-parkocmd.netlify.app"
      ) {
        setLogo(Oceancity);
        setFavicon(FaviconOceancity);
        setText("OCMD");
      } else if (pathname === "greenvillesc-processing-staging.netlify.app") {
        setLogo(Greenvillesc);
        setFavicon(FaviconGreenvillesc);
        setText("Greenville SC");
      } else if (
        pathname === "parkmystic-processing-staging.netlify.app" ||
        pathname === "parkmystic-processing.netlify.app"
      ) {
        setLogo(Mystic);
        setFavicon(FaviconMystic);
        setText("Mystic CT");
      } else if (
        pathname === "mansfield-processing-staging.netlify.app" ||
        pathname === "mansfield-processing.netlify.app"
      ) {
        setLogo(Mansfield);
        setFavicon(FaviconMansfield);
        setText("Mansfield CT");
      } else if (
        pathname === "follybeach-processing-staging.netlify.app" ||
        pathname === "follybeach-processing.netlify.app" ||
        pathname === "follybeach-processing-staging.parkloyalty.com" ||
        pathname === "follybeach-processing.parkloyalty.com"
      ) {
        setLogo(FollyBeach);
        setFavicon(FaviconFollyBeach);
        setText("City of Folly Beach");
      } else if (
        pathname === "leavenworth-processing-staging.netlify.app" ||
        pathname === "leavenworth-processing.netlify.app"
      ) {
        setLogo(CityofLeavenWorth);
        setFavicon(FaviconCityofLeavenWorth);
        setText("City of Leavenworth");
      } else if (
        pathname === "ace-ticketprocessing-staging.netlify.app" ||
        pathname === "ace-ticketprocessing.netlify.app"
      ) {
        setLogo(Encinitas);
        setFavicon(FaviconEncinitas);
        setText("ACE Payment");
      } else if (
        pathname === "duncan-ticketprocessing-staging.netlify.app" ||
        pathname === "duncan-ticketprocessing.netlify.app"
      ) {
        setLogo(logo_duncan);
        setFavicon(FaviconDuncan);
        setText("Duncan Solutions");
      } else if (
        pathname === "spplus-ticketprocessing-staging.netlify.app" ||
        pathname === "spplus-ticketprocessing.netlify.app"
      ) {
        setLogo(logo_sp);
        setFavicon(FaviconSP);
        setText("SP Plus Corporation");
      } else {
        setLogo(ParkLogo);
        setFavicon(FaviconParkloyalty);
        setText("Ticket Processing");
      }
    }
  }, [pathname]);

  return (
    <BrandingContext.Provider value={{ logo, favicon, text }}>
      <>{children}</>
    </BrandingContext.Provider>
  );
};
