import React, { useState, useEffect } from 'react';
import Image1 from "../../../Images/w1.png";
import Image2 from "../../../Images/w2.png";
import Image3 from "../../../Images/w3.png";
import close from '../../../Images/close.svg';
import PaymentLatencyChart from './PaymentLatencyChart';
import ActivePaymentChart from './ActivePaymentChart';
import TransactionSummaryChart from './TransactionSummary';
import Dashboard from "../../../Services/dashboard.service";
import { clearoutAuthUserData } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { refreshTokenMiddleware } from '../../customMiddelware'
import Cookiesremove from '../../../Cookiesremove';

function IntegrationCounts() {
   const dispatch = useDispatch()
   const [count, setCount] = React.useState(0);
   const [showAuthToken, setAuthToken] = useState(false);
   const [showCountValue, setCountValue] = useState([]);
   const [showErrorHandling, setErrorHandling] = useState(false)
   const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

   const closeError = () => {
      setErrorHandling(false)
   }
   const onClickLogin = (e) => {
      e.preventDefault();
      Cookiesremove();
      window.location.href = "/";
   }

   // When Integration dashboard open that time this function will be call
   useEffect(() => {
      integrationCountfetch()

      setCount(100);
   }, []);

   function integrationCountfetch() {
      Dashboard.getIntegrationCount().then(
         (response) => {
            if (response.data.status === 300) {
               setAuthToken(true)
            } else {
               if (response.data.status === false) {
                  setErrorHandling(true)
                  setErrorHandleMessage(response.data.message)
               } else {
                  setCountValue(response.data.data[0].metadata)
               }
            }
         }
      ).catch((err) => {
         if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            integrationCountfetch()
         }
      });
   }

   return (
      <>
         <div className="row cstm-inte3">
            <div className="cstm-inte3-in">
               <div className="cstm-inte3-in-left">
                  <div className="cstm-inte3-in-left-name">Payment Counts</div>
                  <div className="cstm-inte3-in-leftcont">{showCountValue.payment}</div>
               </div>
               <div className="cstm-inte3-in-right"><img src={Image1} /></div>
            </div>
            <div className="cstm-inte3-in">
               <div className="cstm-inte3-in-left">
                  <div className="cstm-inte3-in-left-name">Permit Counts</div>
                  <div className="cstm-inte3-in-leftcont">{showCountValue.permit}</div>
               </div>
               <div className="cstm-inte3-in-right"><img src={Image2} /></div>
            </div>
            <div className="cstm-inte3-in">
               <div className="cstm-inte3-in-left">
                  <div className="cstm-inte3-in-left-name">Scofflaw Counts</div>
                  <div className="cstm-inte3-in-leftcont">{showCountValue.scofflaw}</div>
               </div>
               <div className="cstm-inte3-in-right"> <img src={Image3} /></div>
            </div>
         </div>

         {/* Display payment Latency line chart with filtration features */}
         <PaymentLatencyChart />

         {/* Display active payment line chart with filtration features */}
         <ActivePaymentChart />

         {/* Display Transaction summary line chart with filtration features */}
         <TransactionSummaryChart />


      </>
   );
}

export default IntegrationCounts;
