import React, { useEffect, useState } from "react";
import moment from "moment";
import $ from "jquery";

import close from "../../../../Images/close.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { changeticket, changetotal } from "../../../../Global/site";
import Services from "../../../../Services/main.service";
import TicketReportDetails from "../../../../Services/dashboard.service";
import { useNavigate } from "react-router-dom";
import VoidCitationModal from "../../../Utilies/VoidCitationModal";
import ErrorModalComponent from "../../../../utile/modals/errorModal";
import SupportNoteModal from "../../../Citation/SupportNoteModal";

const ProductTable = (props) => {
  const navigate = useNavigate();

  const [count, setCount] = React.useState(0);
  const [showCancelCitation, setCancelCitation] = useState(false);
  const [showModifyAmount, setModifyAmount] = useState(false);
  const [showSelectedId, setSelectedId] = React.useState([]);
  const [showSupportSelectedId, setSupportSelectedId] = useState([]);
  const [showSelectedCitationId, setSelectedCitationId] = useState([]);
  const [showSelectedIdModifyAmount, setSelectedIdModifyAmount] =
    React.useState([]);
  const [showSelectedTicketNumber, setSelectedTicketNumber] = React.useState(
    []
  );
  const [citationNumber, setCitationNumber] = useState([]);
  const [showCancelCitationComment, setCancelCitationComment] = useState("");
  const [showCommentEmptyMessage, setCommentEmptyMessage] = useState("");
  const [showErrorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setSuccessMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const changeTotal = changetotal();

  const [showModifyNewAmount, setModifyNewAmount] = useState("");
  const [showModifyAmountReason, setModifyAmountReason] = useState("");
  const [showModifyAmountComments, setModifyAmountComments] = useState("");
  const [showAmountEmptyMessage, setAmountEmptyMessage] = useState("");
  const [showReasonEmptyMessage, setReasonEmptyMessage] = useState("");
  const [showHideSideBarOption, setHideSideBarOption] = useState([]);

  const [showResendRO, setResendRO] = useState(false);
  const [showSelectedStatus, setShowSelectedStatus] = useState([]);
  const [isEligibleOpen, setEligibleOpen] = useState(false);
  const [isEligibleMsg, setEligibleMsg] = useState("");
  const changeName = changeticket();
  const [supportNote,setSuuportNote]=useState(false)

  const onClickDetailsPage = (e, id) => {
    return props.onClickDetailsPage(true);
  };

  const closeError = () => {
    setCancelCitation(false);
    setModifyAmount(false);
    setResendRO(false);
    setAmountEmptyMessage("");
    setReasonEmptyMessage("");
    setCommentEmptyMessage("");
  };
  const closeModal=()=>{
    setEligibleOpen(false)
    setSuuportNote(false)
  }


  // While Modify Amount
  const OnClickModifyButton = () => {
    if (
      showModifyNewAmount === "" &&
      showModifyAmountReason === "" &&
      showModifyAmountComments === ""
    ) {
      setAmountEmptyMessage("Enter new amount");
      setReasonEmptyMessage("Enter modify amount reason");
      setCommentEmptyMessage("Enter modify amount comments");
    } else if (showModifyNewAmount === "") {
      setAmountEmptyMessage("Enter new amount");
    } else if (showModifyAmountReason === "") {
      setReasonEmptyMessage("Enter modify amount reason");
    } else if (showModifyAmountComments === "") {
      setCommentEmptyMessage("Enter modify amount comments");
    } else {
      setAmountEmptyMessage("");
      setReasonEmptyMessage("");
      setCommentEmptyMessage("");

      var data = {
        citation_ids: showSelectedId,
        new_amount: Number(showModifyNewAmount),
        modification_reason: showModifyAmountReason,
        modification_comment: showModifyAmountComments,
      };

      TicketReportDetails.updateCitationAmountBulk(data).then(
        (response) => {
          if (response.data.success === true) {
            setSuccessMessage(response.data.message);
            setErrorMessage("");
            setTimeout(() => {
              setModifyAmount(false);
              setChecked(false);
              setSelectedId([]);
              setSupportSelectedId([]);
              setSelectedCitationId([])
              setSelectedTicketNumber([]);
              setCitationNumber([])
              return props.updateValue(false);
            }, 2000);
            commanPageLoadFunction();
          }
        },
        (error) => {
          if (error.response.status === 404) {
            setSuccessMessage("");
            setErrorMessage(error.response.data.message);
            setTimeout(() => {
              setModifyAmount(false);
            }, 2000);
          }
        }
      );
    }
  };

  const OnClickResendROButton = () => {
    setResendRO(false);
    props.selectedCitationHandler(
      showSelectedStatus.filter(
        (item) => item.status === "Valid" || item.status === "Suspended"
      )
    );
  };

  const commanPageLoadFunction = () => {
    $(function () {
      $("table").on("click", ".srt-tab", function () {
        var index = $(this).index(),
          rows = [],
          thClass = $(this).hasClass("srt-ace") ? "srt-desc" : "srt-ace";

        $("#cstm-report-tb-display th").removeClass("srt-ace srt-desc");
        $(this).addClass(thClass);

        $("#cstm-report-tb-display tbody tr").each(function (index, row) {
          rows.push($(row).detach());
        });

        rows.sort(function (a, b) {
          var aValue = $(a).find("td").eq(index).text(),
            bValue = $(b).find("td").eq(index).text();

            console.log(index,'index')

            if(index === 1) {
              return new Date(aValue) - new Date(bValue) 
            } else {
              return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
            }
        });

        if ($(this).hasClass("srt-desc")) {
          rows.reverse();
        }

        $.each(rows, function (index, row) {
          $("#cstm-report-tb-display tbody").append(row);
        });
      });
    });
  };

  useEffect(() => {
    commanPageLoadFunction();

    Services.UIElementsAPI().then((response) => {
      if (response.data.status) {
        setHideSideBarOption(response.data.data.ui_elements);
      }
    });

    setCount(100);
  }, []);

  const OnClickAllCheckbox = (e) => {
    if (e.target.checked) {
      setChecked(true);
      var selectedId = [];
      var selectedTicketNumber = [];
      var selectedStatus = [];
      var citationId=[];
      var selectedCitationId = [];
      let selectedSupportId = [];


      var allSelectedBtn = props.products.filter((data) => {
        selectedStatus.push({
          status: data.status,
          id: data.id,
          ticket_no: data.ticket_no,
        });
        selectedSupportId.push(data.id);
        if (data.status === "Valid") {
          selectedId.push(data.id);
          selectedTicketNumber.push(" " + data.ticket_no);
        }
        selectedCitationId.push(data.id);
        citationId.push(" " + data.ticket_no);
      });

      setSelectedTicketNumber(selectedTicketNumber);
      setCitationNumber(citationId)
      setSelectedId(selectedId);
      setSupportSelectedId(selectedSupportId)
      setSelectedCitationId(selectedCitationId)
      setShowSelectedStatus(selectedStatus);
    } else {
      setChecked(false);
      setSelectedId([]);
      setSupportSelectedId([])
      setSelectedCitationId([])
      setShowSelectedStatus([]);
      setSelectedTicketNumber([]);
      setCitationNumber([])
    }

    $(".comman-pl-csmt").prop("checked", !$(".comman-pl-csmt").prop("checked"));
  };

  const onChangeCheckEvents = (e) => {
    setChecked(false);

    var selectedId = showSelectedId;
    var selectedCitationId = showSelectedCitationId
    var selectedIdAll = showSelectedIdModifyAmount;
    var selectedTicket = showSelectedTicketNumber;
    var selectedStatus = showSelectedStatus;
    var selectedCitation = citationNumber;
    let selectedSupportId = showSupportSelectedId
    // var selectedCitationId = showSelectedId;
    if (e.target.checked) {
      selectedStatus.push({
        status: e.target.value,
        id: e.target.id,
        ticket_no: e.target.dataset.ticket_no,
      });
      selectedSupportId.push(e.target.id);
      if (e.target.value === "Valid") {
        selectedId.push(e.target.id);
        selectedTicket.push(" " + e.target.dataset.ticket_no);
      }
      selectedCitationId.push(e.target.id);
      selectedCitation.push(" " + e.target.dataset.ticket_no);
      
    } else {
      var statusIndex = showSelectedStatus
        .map((item) => item.id)
        .indexOf(e.target.id);
      selectedStatus.splice(statusIndex, 1);
      var supportIndex = showSupportSelectedId.indexOf(e.target.id);
        selectedSupportId.splice(supportIndex, 1);
      if (e.target.value === "Valid") {
        var carIndex = showSelectedId.indexOf(e.target.id);
        selectedId.splice(carIndex, 1);

        var ticketIndex = showSelectedTicketNumber.indexOf(
          e.target.dataset.ticket_no
        );
        selectedTicket.splice(ticketIndex, 1);
      }
      var citationIdIndex = showSelectedCitationId.indexOf(e.target.id);
      selectedCitationId.splice(citationIdIndex, 1);
      var citationIndex = showSelectedTicketNumber.indexOf(
        e.target.dataset.ticket_no
      );
      selectedCitation.splice(citationIndex, 1);
      
    }
    setSelectedId(selectedId);
    setSupportSelectedId(selectedSupportId);
    setSelectedCitationId(selectedCitationId)
    setSelectedTicketNumber(selectedTicket);
    setCitationNumber(selectedCitation)
    setShowSelectedStatus(selectedStatus);
  };
const OnClickCancelCitation = () => {
  if (showSelectedStatus.some((ele) => ele.status !== "Valid")) {
    setEligibleOpen(true);
    setEligibleMsg(`${changeName} is not eligible for Void`);
  }
  if (showSelectedId.length !== 0) {
    setSuccessMessage("");
    setErrorMessage("");
    setCancelCitationComment("");
    setCancelCitation(true);
  } else {
    setEligibleMsg(`Please select ${changeName}`)
    setEligibleOpen(true)
  }
};


  const OnClickModifyAmount = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("");
      setErrorMessage("");
      setModifyNewAmount("");
      setModifyAmountReason("");
      setModifyAmountComments("");
      setModifyAmount(true);
    } else {
      setEligibleMsg(`Please select ${changeName}`)
      setEligibleOpen(true)
    }
  };
  const handleSupportNote=()=>{
    if (showSupportSelectedId.length !== 0) {
      setSuccessMessage("");
      setErrorMessage("");
      setSuuportNote(true)

    } else {
      setEligibleMsg(`Please select ${changeName}`)
      setEligibleOpen(true)
    }
  }

  const OnClickResendRO = () => {
    if (showSelectedStatus.length !== 0) {
      setResendRO(true);
    } else {
      setEligibleMsg(`Please select ${changeName}`)
      setEligibleOpen(true)
    }
  };

  const onChangeModifyAmount = (e) => {
    if (e.target.name === "new_amount") {
      setModifyNewAmount(e.target.value);
      setAmountEmptyMessage("");
    } else if (e.target.name === "modify_reason") {
      setModifyAmountReason(e.target.value);
      setReasonEmptyMessage("");
    } else if (e.target.name === "modify_comments") {
      setModifyAmountComments(e.target.value);
      setCommentEmptyMessage("");
    }
  };

  // const navigate = useNavigate();

  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  // check if site is follybeach
  // const isFollyBeach = () => {
  //   if (Cookies.get('siteinfo') === 'a98dcd6a-cfc2-11ed-ad98-66070e279278' || Cookies.get('siteinfo') === '33370b0e-e5fc-11ed-8b64-665980bd7c45') {
  //     return true
  //   } else return false;
  // }

  return (
    <>
      <div className="pl-cancel-citation-btns report-searchbar">
        {/* {isFollyBeach() && <button className="licence-print report-mr" type="button" onClick={() => navigate('/ticketAnalyticsReport')}>Analytics</button>} */}
        {props.uiElements.includes('BUTTON_TICKET_MANAGEMENT_RESEND_RO_BUTTON') &&<button
          className="licence-print report-mr"
          type="button"
          onClick={OnClickResendRO}
        >
          Resend RO
        </button>}
        {props.uiElements.includes('BUTTON_TICKET_MANAGEMENT_VOID_TICKET_BUTTON') &&<button
          className="licence-print report-mr"
          type="button"
          onClick={OnClickCancelCitation}
        >
          Void {changeTotal}
        </button>}
        {props.uiElements.includes('BUTTON_TICKET_MANAGEMENT_MODIFY_AMOUNT_BUTTON') &&<button
          className="licence-print report-mr"
          type="button"
          onClick={OnClickModifyAmount}
        >
          Modify Amount
        </button>}
        {props.uiElements.includes(
          "BUTTON_TICKET_MANAGEMENT_SUPPORT_NOTES_BUTTON"
        ) && (
            <button
              className="licence-print report-mr"
              type="button"
              onClick={handleSupportNote}
            >
              Support Note
            </button>
          )}
      </div>

      <div
        className="report_tbl_scroll fix-page-scroll-only"
        id="tbl-main-download"
      >
        <table
          className="table table-heading table-bordered"
          id="cstm-report-tb-display"
        >
          <thead>
            <tr>
              <th className="w-1 row-rmv notToExport">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={OnClickAllCheckbox}
                  id="all"
                />
              </th>

              <th className="srt-tab w-2">{changeName} Issue Date Time</th>
              <th className="srt-tab w-2">{changeName} #</th>
              <th className="srt-tab w-2">{changeName} Category</th>
              <th className="srt-tab w-1">Status</th>
              <th className="srt-tab w-2">Badge ID</th>
              <th className="srt-tab w-3">Officer Name</th>
              <th className="srt-tab w-2">Handheld Serial #</th>
              <th className="srt-tab w-2">Block</th>
              <th className="srt-tab w-4">Street</th>
              <th className="srt-tab w-2">Side</th>
              <th className="srt-tab w-2">State</th>

              <th className="srt-tab w-2">Meter #</th>
              <th className="srt-tab w-3">Violation Code</th>
              <th className="srt-tab w-1">Violation Description</th>

              <th className="srt-tab w-2">License Plate</th>
              <th className="srt-tab w-2">Fine Amount</th>
              <th className="srt-tab w-4">Cancel Reason</th>
              <th className="srt-tab w-1">Remark 1</th>
              <th className="srt-tab w-1">Remark 2</th>
            </tr>
          </thead>
          <tbody>
            {props.products.map((item, index) => (
              <tr key={item.id} className={`${item.category === "manual_ticket" ? "cstm-ticket-table-red" : item.category === "invoice" ? "cstm-ticket-table-light-gray"  : ""}`}>
                <td className="row-rmv notToExport">
                  <input
                    for={"pl" + index}
                    data-ticket_no={item.ticket_no}
                    className="comman-pl-csmt"
                    type="checkbox"
                    id={item.id}
                    value={item.status}
                    onChange={onChangeCheckEvents}
                  />
                </td>

                {item.citation_start_timestamp !== "" &&
                item.citation_start_timestamp !== null &&
                item.citation_start_timestamp !== "null" &&
                item.citation_start_timestamp !== undefined &&
                item.citation_start_timestamp.substring(0, 4) !== "0000" &&
                item.citation_start_timestamp.substring(0, 4) !== "0001" ? (
                  <td>
                    <Link
                      to={`/ticketreporting?&ticket_no=${item.ticket_no}`}
                      onClick={(e) => onClickDetailsPage(e, item.ticket_no)}
                    >
                      {moment(
                        item.citation_start_timestamp.split("T")[0]
                      ).format("MM/DD/YYYY") +
                        " " +
                        item.citation_start_timestamp.substring(11, 19)}
                    </Link>
                  </td>
                ) : (
                  <td>-</td>
                )}

                {item.ticket_no !== "" &&
                item.ticket_no !== null &&
                item.ticket_no !== "null" &&
                item.ticket_no !== undefined ? (
                  <td>{item.ticket_no}</td>
                ) : (
                  <td>-</td>
                )}
                {item.category !== "" &&
                item.category !== null &&
                item.category !== "null" &&
                item.category !== undefined ? (
                  <td>{item.category}</td>
                ) : (
                  <td>-</td>
                )}
                {item.status !== "" &&
                item.status !== null &&
                item.status !== "null" &&
                item.status !== undefined ? (
                  <td>{item.status}</td>
                ) : (
                  <td>-</td>
                )}
                {item.officer_details !== null &&
                item.officer_details.badge_id !== "" &&
                item.officer_details.badge_id !== null &&
                item.officer_details.badge_id !== "null" &&
                item.officer_details.badge_id !== undefined ? (
                  <td>{item.officer_details.badge_id}</td>
                ) : (
                  <td>-</td>
                )}
                {item.officer_details !== null &&
                item.officer_details.officer_name !== "" &&
                item.officer_details.officer_name !== "null" &&
                item.officer_details.officer_name !== null &&
                item.officer_details.officer_name !== undefined ? (
                  <td>{item.officer_details.officer_name}</td>
                ) : (
                  <td>-</td>
                )}
                {item?.officer_details !== null &&
                item?.officer_details?.device_friendly_name !== "" &&
                item?.officer_details?.device_friendly_name !== "null" &&
                item?.officer_details?.device_friendly_name !== null &&
                item?.officer_details?.device_friendly_name !== undefined ? (
                  <td>{item?.officer_details?.device_friendly_name}</td>
                ) : (
                  <td>-</td>
                )}

                {item?.location?.block !== "" &&
                item?.location?.block !== null &&
                item?.location?.block !== "null" &&
                item?.location?.block !== undefined ? (
                  <td>{item?.location?.block}</td>
                ) : (
                  <td>-</td>
                )}
                {item?.location?.street !== "" &&
                item?.location?.street !== null &&
                item?.location?.street !== "null" &&
                item?.location?.street !== undefined ? (
                  <td>{item?.location?.street}</td>
                ) : (
                  <td>-</td>
                )}
                {item?.location?.side !== "" &&
                item?.location?.side !== null &&
                item?.location?.side !== "null" &&
                item?.location?.side !== undefined ? (
                  <td>{item?.location?.side}</td>
                ) : (
                  <td>-</td>
                )}
                {item.vehicle_details.state !== "" &&
                item.vehicle_details.state !== null &&
                item.vehicle_details.state !== "null" &&
                item.vehicle_details.state !== undefined ? (
                  <td>{item.vehicle_details.state}</td>
                ) : (
                  <td>-</td>
                )}

                {item?.location?.meter !== "" &&
                item?.location?.meter !== null &&
                item?.location?.meter !== "null" &&
                item?.location?.meter !== undefined ? (
                  <td>{item?.location?.meter}</td>
                ) : (
                  <td>-</td>
                )}

                {/* {item.officer_details.peo_name !== "" && item.officer_details.peo_name !== "null" && item.officer_details.peo_name !== null && item.officer_details.peo_name !== undefined ?
          <td>{item.officer_details.peo_lname + " " + item.officer_details.peo_fname}</td>
          :
          <td>-</td>  
          }  */}

                {/* {item.officer_details.zone !== "" && item.officer_details.zone !== null && item.officer_details.zone !== "null" && item.officer_details.zone !== undefined ?
          <td>{item.officer_details.zone}</td>
          :
          <td>-</td>  
          } */}
                {/* {item.officer_details.shift !== "" && item.officer_details.shift !== null && item.officer_details.shift !== "null" && item.officer_details.shift !== undefined ?
          <td>{item.officer_details.shift}</td>
          :
          <td>-</td>  
          }  */}
                {item?.violation_details?.code !== "" &&
                item?.violation_details?.code !== null &&
                item?.violation_details?.code !== "null" &&
                item?.violation_details?.code !== undefined ? (
                  <td>{item?.violation_details?.code}</td>
                ) : (
                  <td>-</td>
                )}
                {item?.violation_details?.description !== "" &&
                item?.violation_details?.description !== null &&
                item?.violation_details?.description !== "null" &&
                item?.violation_details?.description !== undefined ? (
                  <td>{item?.violation_details?.description}</td>
                ) : (
                  <td>-</td>
                )}

                {/* {item.comment_details.remark_2 !== "" && item.comment_details.remark_2 !== null && item.comment_details.remark_2 !== "null" && item.comment_details.remark_2 !== undefined ?
                  <td>{item.comment_details.remark_2}</td>
                  :
                  <td>-</td>
                } */}
                

                {item.lp_number !== "" &&
                item.lp_number !== null &&
                item.lp_number !== "null" &&
                item.lp_number !== undefined ? (
                  <td>{item.lp_number}</td>
                ) : (
                  <td>-</td>
                )}

                {item.fine_amount !== "" &&
                item.fine_amount !== null &&
                item.fine_amount !== "null" &&
                item.fine_amount !== undefined ? (
                  <td>${convertDecimal(item.fine_amount)}</td>
                ) : (
                  <td>-</td>
                )}

                {item.audit_trail !== undefined && item.audit_trail !== null ? (
                  <td>
                    {item.audit_trail.map(
                      (itemsData, index) =>
                        itemsData.NewValue !== "" &&
                        itemsData.NewValue !== undefined &&
                        (itemsData.NewValue === "Cancelled" ||
                          itemsData.NewValue === "VoidAndReissue") &&
                        itemsData.Reason
                        // :
                        //  <td> </td>
                    )}
                  </td>
                ) : (
                  <td>-</td>
                )}
                {item?.comment_details?.remark_1 !== "" &&
                item?.comment_details?.remark_1 !== null &&
                item?.comment_details?.remark_1 !== "null" &&
                item?.comment_details?.remark_1 !== undefined ? (
                  <td>{item?.comment_details?.remark_1}</td>
                ) : (
                  <td>-</td>
                )}
                <td>{item?.comment_details?.remark_2 || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
     {supportNote && <SupportNoteModal
              closeFn={closeModal}
              supportModal={true}
              open={true}
              data={citationNumber}
        citation_ids={showSupportSelectedId}
        setSupportSelectedId={setSupportSelectedId}
            />}
      {showCancelCitation && (
        <VoidCitationModal showSelectedId={showSelectedId} showSelectedTicketNumber={showSelectedTicketNumber} closeError={closeError} setCancelCitation={setCancelCitation} updateData={props} setSelectedId={setSelectedId} backButtonClick={closeError} />
      )}
                  {isEligibleOpen && (
                    <ErrorModalComponent handleCloseModel={closeModal} displayMsg={isEligibleMsg}/>
            )}

      {showModifyAmount && (
        <div className="cstm-site-model">
          <div className="cstm-site-model-block fixed z-20">
            <div className="cstm-site-popup-bg">
              <div className="cstm-site-model-wrapper auth-taken-cstm-model-wrapper">
                <button onClick={closeError} className="cstm-close-btn-db-is ">
                  <img src={close} alt="close" />
                </button>
                <div>
                  <p className="cancel-citation-ttl-main">Modify Amount</p>
                  <span className="auth-error-msg-success">
                    {showSuccessMessage}
                  </span>
                  <span className="auth-error-msg">{showErrorMessage}</span>
                  <span className="cancel-title-main">
                    Selected Ticket Numbers
                  </span>
                  <textarea
                    type="text"
                    className="commet-citation-cancel"
                    value={showSelectedTicketNumber}
                    disabled
                  />
                  <br />
                  <span className="cancel-title-main">New Amount</span>
                  <div className="pl-doller-icon">
                    <input
                      type="number"
                      className="form-input-fuild cstm-form-input"
                      name="new_amount"
                      placeholder="Enter new amount"
                      value={showModifyNewAmount}
                      onChange={onChangeModifyAmount}
                    />
                  </div>
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showAmountEmptyMessage}
                  </span>
                  <br />
                  <span className="cancel-title-main">
                    Modify Amount Reason
                  </span>
                  <input
                    type="text"
                    className="form-input-fuild cstm-form-input"
                    name="modify_reason"
                    placeholder="Enter modify reason"
                    value={showModifyAmountReason}
                    onChange={onChangeModifyAmount}
                  />
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showReasonEmptyMessage}
                  </span>

                  <br />
                  <span className="cancel-title-main">
                    Modify Amount Comments
                  </span>
                  <textarea
                    placeholder="Enter comments"
                    name="modify_comments"
                    value={showModifyAmountComments}
                    onChange={onChangeModifyAmount}
                    className="commet-citation-cancel"
                  />
                  <span className="auth-error-msg auth-error-comment-msg">
                    {showCommentEmptyMessage}
                  </span>

                  <button
                    className="auth-cstm-submit-btn"
                    onClick={OnClickModifyButton}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showResendRO && (
        <div className="cstm-site-model">
          <div className="cstm-site-model-block fixed z-20">
            <div className="cstm-site-popup-bg">
              <div className="cstm-site-model-wrapper auth-taken-cstm-model-wrapper">
                <button onClick={closeError} className="cstm-close-btn-db-is">
                  <img src={close} alt="close" />
                </button>
                <div>
                  <p className="cancel-citation-ttl-main">Resend RO</p>
                  {showSelectedStatus.filter(
                    (item) =>
                      item.status !== "Valid" && item.status !== "Suspended"
                  ).length !== 0 && (
                    <p>
                      {showSelectedStatus
                        .filter(
                          (item) =>
                            item.status !== "Valid" &&
                            item.status !== "Suspended"
                        )
                        .map(
                          (status, i) =>
                            `${status.ticket_no}${
                              i ===
                              showSelectedStatus.filter(
                                (item) =>
                                  item.status !== "Valid" &&
                                  item.status !== "Suspended"
                              ).length -
                                1
                                ? " "
                                : ", "
                            }`
                        )}{" "}
                      <br /> These Selected Citation is not allowed to
                      Resend
                    </p>
                  )}
                  {showSelectedStatus.filter(
                    (item) =>
                      item.status === "Valid" || item.status === "Suspended"
                  ).length !== 0 && (
                    <>
                      <p>
                        Would you like to continue with your{" "}
                        {showSelectedStatus.filter(
                          (item) =>
                            item.status === "Valid" ||
                            item.status === "Suspended"
                        ).length > 1 && "other"}{" "}
                        selection
                      </p>
                      <p>  {showSelectedStatus
                        .filter(
                          (item) =>
                            item.status === "Valid" ||
                            item.status === "Suspended"
                        )
                        .map(
                          (status, i) =>
                            `${status.ticket_no}${
                              i ===
                              showSelectedStatus.filter(
                                (item) =>
                                  item.status === "Valid" ||
                                  item.status === "Suspended"
                              ).length -
                                1
                                ? " "
                                : ", "
                            }`
                        )}</p>
                      <button
                        className="auth-cstm-submit-btn"
                        onClick={OnClickResendROButton}
                      >
                        Continue
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductTable;
