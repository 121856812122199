import React, { useState, useEffect } from 'react'; 
import AverageTimeCountLog from './AverageTimeLog'; 
import IssuanceOfficerLogs from "./OfficerLogs/TableLogs";
import IssuanceCountSummary from "./IssuanceCount";


function ProductivityDashboard() {     

    return (
        <> 
            <section>
                <div className="container-fluid">
                    <div className="row" > 
                        {/* Content starts */}
                        <div className="col-lg-12 col-md-9 col-sm-12 cstm-ctn">
                        <div className="row">
                            <div className='col-12'>
                                <h3 className="cstm-all-pg-ttl operate-ttl">Productivity</h3>
                            </div>
                        </div>
                       {/* Average Time Count Logs */}
                         <AverageTimeCountLog /> 
                        {/* Display Issurance and Zone officer log information */}
                        <IssuanceOfficerLogs/>
                        
                       {/* Display Issuance Counts summary  */}
                        <IssuanceCountSummary />


                        </div>
                        
                    </div>
            </div>
    </section> 

        </>
    );
}

export default ProductivityDashboard;
