import { useEffect, useState } from "react";
import Services from '../../Services/main.service';
import CitationForm from './CitationForm'
import moment from "moment";
import PopUpComponent from "../Common/PopupModalComponent";
import "./citation.css";
import { connect, useDispatch } from "react-redux";
import { clearoutAuthUserData } from "../../actions/auth";
import { refreshTokenMiddleware, refreshTokenMiddlewareAsync } from "../customMiddelware";

const CreateCitation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState(null);
  const [dropDownData, setDropDownData] = useState({});
  const [formTemplate, setFormTemplate] = useState([]);
  const [shouldResetForm, setShouldResetForm] = useState(false);

  const dispatch = useDispatch()

  const closePopup = () => {
    setShowPopup(false)
  }

  const onClickFormSubmit = async (data) => {
    try {
      const citationData = {
        citation_issue_timestamp: moment(data?.timestamp).toISOString(),
        citation_start_timestamp: moment(data?.timestamp).toISOString(),
        code: data?.code ?? "",
        comment_details: {
          note_1: "",
          note_2: "",
          remark_1: data?.remark_1 ?? "",
          remark_2: "",
        },
        header_details: {
          citation_number: data?.citation_number ?? "",
          timestamp: moment(data?.timestamp).toISOString(),
        },
        image_urls: data?.image_urls ? [data?.image_urls] : [],
        reissue: false,
        time_limit_enforcement: false,
        location_details: {
          block: data?.block ?? "",
          direction: "",
          lot: data?.lot ?? "",
          space_id: "",
          meter: "",
          side: "",
          street: data?.street ?? "",
        },
        lp_number: data?.lp_number ?? "",
        notes: "",
        officer_details: {
          badge_id: data?.badge_id ?? "",
          officer_name: data?.officer_name ?? "",
          agency: data?.agency ?? "",
        },
        status: "Valid",
        ticket_no: (data?.citation_number).toString() ?? "",
        time_limit_enforcement_id: "",
        time_limit_enforcement_observed_time: "",
        type: data?.type ?? "",
        category: 'manual_ticket',
        vehicle_details: {
          color: data?.color ?? "",
          make: data?.make ?? "",
          model: data?.model ?? "",
          state: data?.state ?? "",
          lp_number: data?.lp_number ?? "",
        },
        violation_details: {
          code: data?.code ?? "",
          description: data?.description ?? "",
          violation_type: "",
          due_15_days: 0.0,
          due_30_days: 0.0,
          due_45_days: 0.0,
          fine: parseFloat(data?.fine) ?? 0.0,
        },
        hearing_details: data?.Hearing_Date ? {
          date: data.Hearing_Date,
          comment: "Hearing scheduled by admin"
        } : null,
        hearing_date: data?.Hearing_Date ? moment(data.Hearing_Date).format("MM/DD/YYYY h:mmA") : null
      };

      await Services.issueTicket(citationData);
      setPopupData("Citation created successfully!");
      setShowPopup(true);
      setShouldResetForm(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        await refreshTokenMiddlewareAsync(dispatch);
        await onClickFormSubmit(data)
      } else {
        setPopupData(error?.response?.data?.error);
        setShowPopup(true);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDropDownData = async () => {
      try {
        const paramsStateList = {
          type: "StateList",
          shard: 1,
        };
        const paramsColorList = {
          type: "CarColorList",
          shard: 1,
        };
        const paramsMakeList = {
          type: "MakeModelList",
          shard: 1
        };

        const offcerParams = {
          type: "officers",
          shard: 1
        };

        const violationParams = {
          type: "ViolationList",
          shard: 1
        };

        const remarksParams = {
          type: "RemarksList",
          shard: 1,
        };

        const streetParams = {
          type: "StreetList",
          shard: 1,
        };

        const AgencyParams = {
          type: "AgencyList",
          shard: 1,
        }

        const LotParams = {
          type: "LotList",
          shard: 1,
        }

        const promisedRequestArray = [];
        promisedRequestArray.push(
          Services.getDataForDropDown(paramsStateList),
          Services.getDataForDropDown(paramsColorList),
          Services.getDataForDropDown(paramsMakeList),
          Services.getDataForDropDown(offcerParams),
          Services.getDataForDropDown(violationParams),
          Services.getDataForDropDown(remarksParams),
          Services.getDataForDropDown(streetParams),
          Services.getDataForDropDown(AgencyParams),
          Services.getDataForDropDown(LotParams),
        );

        Promise.allSettled(promisedRequestArray.map(item => item))
          .then((responseArray) => {
            const dropDownData = {};
            responseArray.forEach((response) => {
              if (response?.status === 'fulfilled' && response?.value?.status === 200 && response?.value?.data?.status) {
                dropDownData[response?.value?.data?.data[0]?.metadata?.type] = response?.value?.data?.data[0]?.response;
              }
            }
            )
            setDropDownData(dropDownData)
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              refreshTokenMiddleware(dispatch)
              getDropDownData()
            } else {
              setPopupData(error?.message)
              setShowPopup(true)
            }
          })
      } catch (error) {
        if (error?.response?.status === 401) {
          await refreshTokenMiddlewareAsync(dispatch)
          await getDropDownData()
        } else {
          setIsLoading(false);
          console.log(error);
        }
      }
    }

    const getFormTemplate = async () => {
      try {
        setIsLoading(true);
        const response = await Services.getTemplateData();
        if (response?.data?.status) {
          setFormTemplate(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          await refreshTokenMiddlewareAsync(dispatch);
          await getFormTemplate()
        } else {
          setIsLoading(false);
          setPopupData(error?.message)
          setShowPopup(true)
        }
      }
    }
    const fetchData = async () => {
      getFormTemplate();
      getDropDownData();
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="right-panel cstm-right-panel">
              <div className="row cstm-ticket-manage-sec">
                <div className="cstm-ticket-manage-heading">
                  <h3 className="cstm-all-pg-ttl operate-ttl">Create Citations</h3>
                </div>
              </div>
              {isLoading ?
                <div className="text-center spin-load-main">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                :
                (Object.keys(dropDownData).length > 0) && <CitationForm dropDownData={dropDownData} formTemplateData={formTemplate} onClickFormSubmit={onClickFormSubmit} showBackButton={false} shouldResetForm={shouldResetForm} />
              }
            </div>
          </div>
        </div>
      </section>

      {
        showPopup ?
          <>
            <PopUpComponent message={popupData} handleModalClose={closePopup} />
          </>
          :
          ""
      }
    </>
  );
};

export default connect(null, { clearoutAuthUserData })(CreateCitation);