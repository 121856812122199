import React from 'react';
import moment from 'moment';
import axios from 'axios';
import dashboardService from '../../Services/dashboard.service';
import authHeader from '../../Services/auth.header';
import { toast } from 'react-toastify';

export const validateData = (data) => {
  if (data !== "" && data !== null && data !== "null" && data !== undefined && data !== 0 && data !== '0' && data !== " ") {
    return true;
  }
}

export const renderDiv = (data, htmlTag = 'div') => {
  return React.createElement(htmlTag, {}, data);
}

export const renderDivWithClass = (data, text, htmlTag = 'p', className = 'col-lg-4') => {
  if (validateData(data)) {
    return (
      <div className={className}>
        <h3>{text}</h3>
        {React.createElement(htmlTag, {}, data)}
      </div>
    )
  }
}

export const getDateRange = (numberOfDays = 1) => {
  const time_to = new Date();
  const time_from = new Date();
  time_from.setDate(time_from.getDate() - numberOfDays);
  time_from.setHours(0, 0, 0, 0);
  time_to.setHours(23, 59, 59, 999);
  return {
    time_from: time_from.toISOString(),
    time_to: time_to.toISOString()
  }
}

export const formatDate = date => {
  if (validateData(date)) {
    return moment.utc(date).format('MM/DD/YYYY hh:mm A')
  }
  return 'NA'
}
export const getFormattedDate = date => {
  if (!date || date === "0001-01-01T00:00:00Z") {
    return "N/A"
  } else {
    return moment.utc(date)?.format('MM/DD/YYYY hh:mm A');
  }
}

export const getFormattedDueDate = date => {
  if (!date || date === "0001-01-01T00:00:00Z") {
    return "N/A"
  } else {
    return moment.utc(date)?.format('MM/DD/YYYY');
  }
}

export const formatAmount = amount => {
  if (amount === undefined || amount === null) {
    return "N/A"
  } else {
    return `$${parseFloat(amount).toFixed(2)}`
  }
}

export const uploadFiles = async (formData, toastId) => {
  try {
    const response = await axios.request({
      method: 'post',
      url: `${dashboardService.API_URL}static_file/cp/upload_files`,
      headers: authHeader(),
      data: formData,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        if (toastId.current === null) {
          toastId.current = toast('Please wait while your files are uploaded', { progress: `${progress}%` });
        } else {
          toast.update(toastId.current, { progress: `${progress}%` });
        }
      },
    })
    if (response.data?.status) {
      toast.dismiss(toastId.current)
      toast.success('File Upload successful!');
      //reset toastId so that if user uploads another file without refreshing the page, the upload progree can be shown
      toastId.current = null;
    }
    return response;
  } catch (error) {
    toast.dismiss(toastId.current)
    toast.error('File upload failed. Please try again.');
    toastId.current = null;
    throw error
  }
};

export const formatCommercialAmount = amount => {
  if (amount === undefined || amount === null) {
    return "N/A"
  } else {
    return `${parseFloat(amount).toFixed(2)}`
  }
}