import React, { useEffect, useState } from 'react'
import moment from 'moment';
import TicketReportDetails from "../../../Services/main.service";
import { refreshTokenMiddleware } from '../../customMiddelware';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

const AuditTrialTabDetail = () => {

  const dispatch=useDispatch()
  const location = useLocation().search;
  const citation_Id = new URLSearchParams(location).get("id");
  const citation_Number = new URLSearchParams(location).get("citation_no");

  const [citationAllData, setCitationAllData] = useState({});
  const [sorting, setSorting] = useState({
    field: "xyz",
    ascending: true,
    type: "",
  });
  const applySorting = (key, ascending, type) => {
    setSorting({ key: key, ascending: ascending, type: type });
  };
  useEffect(() => {
    const currentDataCopy = { ...citationAllData };
    if (sorting.type === "audit-trail") {
      const sortedCurrentdata = (currentDataCopy?.audit_trail || []).sort(
        (a, b) => {
          return a[sorting.key].localeCompare(b[sorting.key]);
        }
      );
      setCitationAllData({
        ...currentDataCopy,
        audit_trail: sorting.ascending
          ? sortedCurrentdata
          : sortedCurrentdata.reverse(),
      });
    }
    getCitationDetailsAPI(citation_Number)
  },[sorting, citation_Id, citation_Number])
  async function getCitationDetailsAPI(id) {
    try {
      const result = await TicketReportDetails.citationDetails(
        "ticket_number=" + id
      );

      const { detail } = result.data.data;
      if (detail && detail !== null) {
        setCitationAllData(detail);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getCitationDetailsAPI(id);
      }
    }
  }
  return (
    <>
                        <div className="ctsm-table-scroll">
                          <div className="table-overflow">
                            <table className="table-heading table table-bordered">
                              <thead>
                                <tr>
                                  <th
                                      className={`srt-tab ${sorting.key === "Timestamp" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "Timestamp",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Date & Time
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "Reason" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "Reason",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Reason
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "Comment" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "Comment",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Comment
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "InitiatorName" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "InitiatorName",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Initiator Name
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "InitiatorRole" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "InitiatorRole",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Initiator Role
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "NewValue" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "NewValue",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    New Value
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "OldValue" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "OldValue",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Old Value
                                  </th>
                                  <th
                                      className={`srt-tab ${sorting.key === "UpdateType" &&
                                      (sorting.ascending
                                        ? "srt-ace"
                                        : "srt-desc")
                                        }`}
                                    onClick={() =>
                                      applySorting(
                                        "UpdateType",
                                        !sorting.ascending,
                                        "audit-trail"
                                      )
                                    }
                                  >
                                    Update Type
                                  </th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {(citationAllData?.audit_trail || []).map(
                                  (item, i) => (
                                    <tr key={i}>
                                      <td>
                                        {moment
                                          .utc(item.Timestamp)
                                          .format("YYYY-MM-DD hh:mm:ss")}
                                      </td>
                                      <td>{item.Reason || "-"}</td>
                                      <td>{item.Comment || "-"}</td>
                                      <td>{item.InitiatorName || "-"}</td>
                                      <td>{item.InitiatorRole || "-"}</td>
                                      <td>{item.NewValue || "-"}</td>
                                      <td>{item.OldValue || "-"}</td>
                                      <td>{item.UpdateType || "-"}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
    </>
  )
}

export default AuditTrialTabDetail