import { state } from "../../Component/Common/dropDownOptions";
import Select from "react-select";
import { useEffect, useState } from "react";
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../../Component/customMiddelware";
import { useDispatch } from "react-redux";

const ContactInfoForm = ({
  handleInputChange,
  inputValue,
  inputError,
  handleOptionChange,
  isAppeal,
  list,
  showSource = true,
  card
}) => {
  const generateLabelText = (fieldName,feild ,data) => {
    return `${fieldName}${(isAppeal || (data)) ? ' *' : ' '}`;
  };
  
  return (
    <>
      <div className="row">
        <div
          className={`${
            isAppeal ? "col-4" : "col-6"
          } form-group formgroup-main`}
        >
            {generateLabelText("First name", isAppeal, card)}
          <input
            type="text"
            name="first_name"
            className="form-control"
            value={inputValue.first_name}
            placeholder="First Name"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.first_name}</span>
        </div>
        {isAppeal && (
          <div className="col-4 form-group formgroup-main">
            <label htmlFor="">Middle name</label>
            <input
              type="text"
              name="middle_name"
              className="form-control"
              value={inputValue.middle_name}
              onChange={handleInputChange}
              placeholder="Middle Name"
            />
            <span className="errormessage">{inputError.middle_name}</span>
          </div>
        )}
        <div
          className={`${
            isAppeal ? "col-4" : "col-6"
          } form-group formgroup-main`}
        >
          {generateLabelText("Last name", isAppeal, card)}
          <input
            type="text"
            name="last_name"
            className="form-control"
            value={inputValue.last_name}
            placeholder="Last Name"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.last_name}</span>
        </div>
      </div>
      <div className="row">
        <div className={`${isAppeal ? "col-12" : "col-8"} form-group formgroup-main`}>
          {generateLabelText("Address", isAppeal, card)}
          <input
            type="text"
            name="address"
            className="form-control"
            value={inputValue.address}
            placeholder="Enter Address"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.address}</span>
        </div>
{ showSource && !isAppeal&&       <div className="col-4 form-group formgroup-main">
          <label htmlFor="">Payment Source *</label>
          <Select
            name="source"
            value={inputValue.source}
            options={list.map((it) => ({
              value: it.source,
              label: it.source
            }))}

            onChange={(e) => handleOptionChange(e, "source")}
          />
          <span className="errormessage">{inputError.source}</span>
        </div>}
      </div>
      <div className="row">
        <div className="col-4 form-group formgroup-main">
          {generateLabelText("City", isAppeal, card)}
          <input
            type="text"
            name="city"
            className="form-control"
            value={inputValue.city}
            placeholder="City"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.city}</span>
        </div>
        <div className="col-4 form-group formgroup-main">
        {generateLabelText("State", isAppeal, card)}
          <Select
            name="state"
            options={state}
            selectedOption="Active"
            onChange={(e) => handleOptionChange(e, "state")}
            searchable
            value={inputValue.state || ""}
          />
          <span className="errormessage">{inputError.state}</span>
        </div>
        <div className="col-4 form-group formgroup-main">
        {generateLabelText("Zipcode", isAppeal, card)}
          <input
            type="text"
            maxLength={9}
            name={`${isAppeal ? "zip" : "zip_code"}`}
            className="form-control"
            value={isAppeal ? inputValue.zip : inputValue.zip_code}
            placeholder="zip code"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.zip_code}</span>
        </div>
      </div>
      <div className="row">
        {isAppeal ? (
          <div className="col-6 form-group formgroup-main">
        {generateLabelText("Telephone", isAppeal, card)}
            <input
              type="text"
              name="telephone"
              className="form-control"
              value={inputValue.telephone}
              onChange={handleInputChange}
              placeholder="Telephone"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength="10"
            />
            <span className="errormessage">{inputError.telephone}</span>
          </div>
        ) : (
          <div className="col-6 form-group formgroup-main">
        {generateLabelText("Phone number ", isAppeal, false)}
            <input
              type="text"
              name="phone_number"
              className="form-control"
              maxLength={10}
              value={inputValue.phone_number}
              placeholder="Phone Number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={handleInputChange}
            />
            <span className="errormessage">{inputError.phone_number}</span>
          </div>
        )}

        <div className="col-6 form-group formgroup-main">
        {generateLabelText("Email address ", isAppeal, false)}
          <input
            type="text"
            name="email"
            className="form-control"
            value={inputValue.email}
            placeholder="Email Address"
            onChange={handleInputChange}
          />
          <span className="errormessage">{inputError.email}</span>
        </div>
      </div>
    </>
  );
};

export default ContactInfoForm;
