import VISA_ICON from "../../Images/card-assets/visa.png";
import AMERICAN_EXPRESS_ICON from "../../Images/card-assets/amex.png";
import MASTER_CARD_ICON from "../../Images/card-assets/masterCard.png";
import DISCOVER_ICON from "../../Images/card-assets/discover.png";
import DINERS_CLUB_ICON from "../../Images/card-assets/diners.png";
import JCB_ICON from "../../Images/card-assets/JCB.png";

export const LASTFORDIGIT = [
  /\d/,
  /\d/,
  /\d/,
  /\d/
]
export const OTHERCARDS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const AMERICANEXPRESS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/, /\d/, /\d/,];
export const CVC = [/[0-9]/, /\d/, /\d/, /\d/];

export const CARDICON = {
  VISA: VISA_ICON,
  MASTERCARD: MASTER_CARD_ICON,
  DISCOVER: DISCOVER_ICON,
  AMERICAN_EXPRESS: AMERICAN_EXPRESS_ICON,
  DINERS_CLUB: DINERS_CLUB_ICON,
  JCB: JCB_ICON
};

export const CARDARR = [
  "VISA",
  "MASTERCARD",
  "AMERICAN_EXPRESS",
  "DISCOVER",
  "DINERS_CLUB",
  "JCB"
];
