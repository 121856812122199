const PermitStepUserDetails = ({
  stepField,
  constructValidationRules,
  control,
  Controller,
  validation
}) => {

  return (
    <>
      <div className="row">
        {stepField.map(key => 
          <>
            {key.name === "first_name" &&
              <div className="col-4 form-group formgroup-main">
                <Controller
                  name={key.key_name}
                  control={control}
                  rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor="">
                        {key.repr}{' '}
                        {validation && validation.required && '*'}
                      </label>
                      <input
                        type="text"
                        name={key.key_name}
                        className="form-control cstm-add-capital-ttl"
                        placeholder={key.repr}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                    </>
                  )}
                />
              </div>
            }
            {key.name === "last_name" &&
              <div className="col-4 form-group formgroup-main">
                <Controller
                  name={key.key_name}
                  control={control}
                  rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor="">
                        {key.repr}{' '}
                        {validation && validation.required && '*'}
                      </label>
                      <input
                        type="text"
                        name={key.key_name}
                        className="form-control cstm-add-capital-ttl"
                        placeholder={key.repr}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                    </>
                  )}
                />
              </div>
            }
            {key.name === "user_name" &&
              <div className="col-4 form-group formgroup-main">
                <Controller
                  name={key.key_name}
                  control={control}
                  rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor="">
                        {key.repr}{' '}
                        {validation && validation.required && '*'}
                      </label>
                      <input
                        type="text"
                        name={key.key_name}
                        className="form-control cstm-add-capital-ttl"
                        placeholder={key.repr}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                    </>
                  )}
                />
              </div>
            }
          </>
        )}
      </div>
      <div className="row">
        {stepField.map(key => 
          <>
            {key.name === "phone_number" &&
              <div className="col-4 form-group formgroup-main">
                <Controller
                  name={key.key_name}
                  control={control}
                  rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor="">
                        {key.repr}{' '}
                        {validation && validation.required && '*'}
                      </label>
                      <input
                        type="text"
                        name={key.key_name}
                        className="form-control"
                        placeholder={key.repr}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                    </>
                  )}
                />
              </div>
            }
            {key.name === "email" &&
              <div className="col-4 form-group formgroup-main">
                <Controller
                  name={key.key_name}
                  control={control}
                  // rules={constructValidationRules(key.repr, validation)}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor="">
                        {key.repr}{' '}
                        {/* {validation && validation.required && '*'} */}
                      </label>
                      <input
                        type="text"
                        name={key.key_name}
                        className="form-control"
                        placeholder={key.repr}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <span className="errormessage">{fieldState?.error?.message}</span>
                    </>
                  )}
                />
              </div>
            }
          </>
        )}
      </div>
    </>
  )
}

export default PermitStepUserDetails;