import { useState, useRef, useEffect } from "react";
import ImageSlider from "../../../../utile/imageSlider/imageSlider";
import Cookies from "js-cookie";
import Reportingsetup from "../../../../Services/main.service";
import moment from "moment";
import BackbuttinIMag from "../../../../Images/back.png";
import successIcon from "../../../../Images/success-icon.png";
import { changeticket, changetotal } from "../../../../Global/site";
import { useLocation } from "react-router";
const ReportEditComponent = ({
  backbuttonHandler,
  formData,
  formImageData
}) => {
  const changeTotal = changetotal();
  const changeTicket = changeticket();
  let errorValueNew = {
    remarks: "",
  };
  const siteInfo = JSON.parse(Cookies.get("auth"));

  const sliderPopupRef = useRef(null);

  const [citationData, setcitationData] = useState([]);
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const [imageId, setImageId] = useState(0);
  const [citeIndex, setCiteIndex] = useState();
  const [streetList, setStreetList] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [violationData, setViolationData] = useState([]);
  const [fieldError, setFieldError] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  // state select box api call
  useEffect(() => {
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setStreetList(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    let passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    Reportingsetup.violationCode(passDataViolation)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setViolationData(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    const initialremarkfun = () => {
      Reportingsetup.violationCode(passDataRemarks)
        .then((Response) => {
          if (Response.data.status === 300) {
          } else {
            setRemarks(Response.data.data[0].response);
          }
        })
        .catch((err) => {});
    };
    initialremarkfun();
  }, []);

  // set state for slide images and citation form data

  useEffect(() => {
    const changeDate = (date) => {
      const dateObject = new Date(date);
      const formattedString = dateObject.toISOString().slice(0, 16);
      return formattedString
    }
    setcitationData(
      formData.map((data) => ({
        citation_id: data.id,
        ticket_no: data.ticket_no,
        citation_start_timestamp: changeDate(data.citation_start_timestamp),
        block: data.location.block,
        street: data.location.street,
        state: data.vehicle_details.state,
        lp_number: data.lp_number,
        remarks: "",
        remark_1: data.comment_details.remark_1,
        remark_2: data.comment_details.remark_2,
        note_1: data.comment_details.note_1,
        note_2: data.comment_details.note_2,
        description: data.violation_details.description,
        code: data.violation_details.code,
      }))
    );
  }, [formData]);

  // popup ref set autofocus

  useEffect(() => {
    if (sliderPopupRef) {
      sliderPopupRef?.current?.focus();
    }
  }, [showImagesModalSlider]);

  const handleFormInputsEvent = (e, i) => {
    const { name, value } = e.target;
    if (name === "code" || name === "description") {
      citationData[i]["code"] = JSON.parse(e.target.value).code;
      citationData[i]["description"] = JSON.parse(e.target.value).description;
    } else {
      citationData[i][name] = value;
    }
    setcitationData([...citationData]);
  };

  // edit from submission

  const handleEditCitation = () => {
    let error_exist = false;
    var errorDisplay = [];
    citationData.map((value) => {
      let newarrErr = { ...errorValueNew };
      if (!value.remarks) {
        error_exist = true;
        newarrErr.remarks = "Field is required.";
      }
      errorDisplay.push(newarrErr);
    });
    setFieldError(errorDisplay);
    if (!error_exist) {
      citationData.map((data, index) =>
        Reportingsetup.updateCitation([data]).then((response) => {
          if (response.status === 200) {
            if (index === citationData.length - 1) {
              setShowSuccessModal(true);
              setSuccessMsg(response.data.message);
            }
          }
        })
      );
    }
  };

  return (
    <>
        <section>
          <div className="container-fluid">
            <div className="row">
              {/* Content starts */}
              <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
                <div className="row">
                  <div className="col-12 back-button back-button-citationdetails">
                    <a onClick={backbuttonHandler}>
                      <img
                        src={BackbuttinIMag}
                        alt="img"
                        className="img-fluid"
                      />
                      Back
                    </a>
                  </div>
                </div>
                <div className="cstm-ticket-manage-sec">
                  <div className="cstm-ticket-manage-heading">
                    <h3 className="cstm-all-pg-ttl operate-ttl">
                      Edit {changeTotal}
                    </h3>
                  </div>
                </div>

                {citationData.length > 0 &&
                  citationData.map((data, index) => (
                    <div className="cstm-report-edit-component">
                      {/* <div className="cstm-report-edit-componentt-title">
                        <h6>
                          {changeTicket} No. : {data.ticket_no}
                        </h6>
                        <h6>
                          {changeTicket} Issue Date Time :{" "}
                          {moment(
                            data.citation_start_timestamp.split("T")[0]
                          ).format("MM/DD/YYYY") +
                            " " +
                            data.citation_start_timestamp.substring(11, 19)}
                        </h6>
                      </div> */}

                      <div className="cstm-report-edit-component-info">
                        <div className="row cstm-rec5">
                            <div className="col-6 cstm-inputfield">
                            <label>{changeTicket} No.</label>
                            <input
                              type="text"
                              name="ticket_no"
                              value={data.ticket_no}
                              onChange={(e) =>
                                handleFormInputsEvent(e,index)
                              }
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                              }}
                            />
                            </div>
                            <div className="col-6 cstm-inputfield">
                              <label>{changeTicket} Issue Date Time</label>
                              <input
                                type="datetime-local"
                                name="ticket_date"
                                value={data.citation_start_timestamp}
                                onChange={(e) =>
                                  handleFormInputsEvent(e,index)
                                }
                              />
                            </div>
                            <div class="col-3 cstm-inputfield">
                            <label>Block</label>
                            <br />
                            <input
                              name="block"
                              type="text"
                              value={data.block}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>Street</label> <br />
                            <input
                              name="street"
                              type="text"
                              value={data.street}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                          </div>
                        </div>
                        <div class="row cstm-rec5">
                         
                          <div class="col-3 cstm-inputfield">
                            <label>State</label> <br />
                            <select
                              className="cstm-rec-select ar-olny"
                              onChange={(e) => handleFormInputsEvent(e, index)}
                              name="state"
                            >
                              {streetList.map((state) => (
                                <option
                                  selected={
                                    state.state_abbreviated === data.state
                                  }
                                  value={state.state_abbreviated}
                                >
                                  {state.state_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>License Plate</label>
                            <br />
                            <input
                              name="lp_number"
                              type="text"
                              value={data.lp_number}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>Violation Description</label> <br />
                            <select
                              className="cstm-rec-select ar-olny"
                              onChange={(e) => handleFormInputsEvent(e, index)}
                              name="description"
                            >
                              {violationData.map((violation) => (
                                <option
                                  selected={
                                    violation.violation_description ===
                                    data.violation_description
                                  }
                                  value={JSON.stringify({
                                    code: violation.code,
                                    description:
                                      violation.violation_description,
                                  })}
                                >
                                  {violation.violation_description}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>Violation Code</label> <br />
                            <select
                              className="cstm-rec-select ar-olny"
                              onChange={(e) => handleFormInputsEvent(e, index)}
                              name="code"
                            >
                              {violationData.map((violation) => (
                                <option
                                  selected={violation.code === data.code}
                                  value={JSON.stringify({
                                    code: violation.code,
                                    description:
                                      violation.violation_description,
                                  })}
                                >
                                  {violation.code}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div class="col-3 cstm-inputfield">
                            <label>Remark 1</label>
                            <br />
                            <input
                              list="remark1"
                              name="remark_1"
                              value={data.remark_1}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                            <datalist id="remark1">
                              {remarks.map((item) => (
                                <option
                                  selected={item.remark === data.remark_1}
                                  value={item.remark}
                                >
                                  {item.remark}
                                </option>
                              ))}
                            </datalist>
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>Remark 2</label>
                            <br />
                            <input
                              list="remark2"
                              name="remark_2"
                              value={data.remark_2}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                            <datalist id="remark2">
                              {remarks.map((item) => (
                                <option
                                  selected={item.remark === data.remark_2}
                                  value={item.remark}
                                >
                                  {item.remark}
                                </option>
                              ))}
                            </datalist>
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>Note 1</label>
                            <br />
                            <input
                              name="note_1"
                              type="text"
                              value={data.note_1}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                          </div>
                          <div class="col-3 cstm-inputfield">
                            <label>Note 2</label>
                            <br />
                            <input
                              name="note_2"
                              type="text"
                              value={data.note_2}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                            />
                          </div>
                          
                        </div>
                        <div className="row">
                          <div class="col-12 cstm-inputfield">
                            <label>Reason for Edit *</label> <br />
                            <textarea
                              rows={2}
                              onChange={(e) => handleFormInputsEvent(e, index)}
                              name="remarks"
                              value={data.remarks}
                            />
                            {fieldError[index]?.remarks !== "" && (
                              <span className="errormessage">
                                {fieldError[index]?.remarks}
                              </span>
                            )}
                          </div>
                        </div>

                        {formImageData[index] &&
                          formImageData[index]?.length > 0 && (
                            <div class="row">
                              <div class="col-12 cstm-inputfield">
                                <label>Images</label> <br />
                                <div className="cstm-rec-upload-img">
                                  {formImageData[index].map((img) => (
                                    <div
                                      className="cstm-rec-upload-img-box"
                                      onClick={() => (
                                        setCiteIndex(Number(index)),
                                        setImageId(Number(img.image_spec)),
                                        setShowImagesModalSlider(true)
                                      )}
                                    >
                                      <img src={img.url} alt="citation" />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                <div className="cstm-report-edit-component-btn">
                  <button
                    className="edit-citationbtn-btn"
                    type="submit"
                    onClick={() => handleEditCitation()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      {showImagesModalSlider && (
        <div
          className="main-body-modal cstm-ticket-details-pop"
          onKeyDown={(e) => {
            if (e.key === "ArrowLeft") {
              imageId === 0
                ? setImageId(formImageData[citeIndex].new_images.length - 1)
                : setImageId(imageId - 1);
            } else if (e.key === "ArrowRight") {
              imageId + 1 === formImageData[citeIndex].new_images.length
                ? setImageId(0)
                : setImageId(imageId + 1);
            }
          }}
          tabIndex={0}
          ref={sliderPopupRef}
        >
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider
                    slides={formImageData[citeIndex].new_images}
                    index={imageId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowSuccessModal(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body cstm-modal-body">
                <div className="text-center cstm-paymnet-img">
                  <img src={successIcon} alt="img" className="img-fluid" />
                </div>
                <h2 className="text-center mb-4">{successMsg}</h2>
                <button
                  className="edit-citationbtn-btn"
                  onClick={backbuttonHandler}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ReportEditComponent;
