import { getFormattedDate, formatAmount } from "../../Common/utils";

const TicketDetailHeader = ({ citationData, paymentBreakdownData, registeredOwnerDetails }) => {
  return (
    <>
      <br />
      <div style={{ position: 'relative' }}>
        <div className="col-12">
          <header className="card-header payment-detail-header">
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} className="col-8">
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Ticket Number:</h6>
                <p>{citationData?.ticket_no}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Ticket Issue Date:</h6>
                <p>{getFormattedDate(citationData?.citation_start_timestamp)}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Plate Number:</h6>
                <p>{citationData?.lp_number}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>State:</h6>
                <p>{citationData?.vehicle_details?.state}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Ticket Category:</h6>
                <p>{citationData?.category?.charAt(0)?.toUpperCase() + citationData?.category?.slice(1)}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Amount Due:</h6>
                <p>{formatAmount(paymentBreakdownData?.amount_due)}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Amount Paid:</h6>
                <p>{formatAmount(paymentBreakdownData?.amount_paid)}</p>
              </div>
              <div style={{ flex: '1 0 20%', margin: '10px' }}>
                <h6>Ticket Status:</h6>
                <p>{citationData?.status}</p>
              </div>
            </div>
          </header>
        </div>
        <div className="col-4 payment-detail-border">
          <section>
            <h4>Registered Owner Details</h4>
            <div className="line-spacing">
              <h6>Name: <span>{registeredOwnerDetails?.registered_owner_address?.name}</span></h6>
            </div>
            <div className="line-spacing">
              <h6>Street: <span>{registeredOwnerDetails?.registered_owner_address?.address}</span></h6>
            </div>
            <div className="line-spacing" style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
              <h6>City: <span>{registeredOwnerDetails?.registered_owner_address?.city}</span></h6>
              <h6>State: <span>{registeredOwnerDetails?.registered_owner_address?.state}</span></h6>
              <h6>Zip: <span>{registeredOwnerDetails?.registered_owner_address?.zip}</span></h6>
            </div>
            <div className="line-spacing">
              <h6>Email: <span>{registeredOwnerDetails?.email}</span></h6>
            </div>
            <div className="line-spacing">
              <h6>Phone: <span>{registeredOwnerDetails?.phone}</span></h6>
            </div>
          </section>
        </div>
      </div>
      <br />
    </>
  );
};

export default TicketDetailHeader;