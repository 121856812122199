import React, { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';

import close from '../../../../Images/close.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Service from '../../../../Services/main.service';
import { clearoutAuthUserData } from '../../../../actions/auth';
import { changetotal, changeticket, siteid } from "../../../../Global/site";
import { refreshTokenMiddleware } from '../../../customMiddelware';
import Cookies from 'js-cookie';

const ProductTable = (props) => {
  const [rightClick, setRightClick] = React.useState(-1);
  const changeTotal = changetotal();
  const [count, setCount] = React.useState(0);
  const [showCancelCitation, setCancelCitation] = useState(false)
  const [showModifyAmount, setModifyAmount] = useState(false)
  const [showSelectedId, setSelectedId] = React.useState([]);
  const [showSelectedIdModifyAmount, setSelectedIdModifyAmount] = React.useState([]);
  const [showSelectedTicketNumber, setSelectedTicketNumber] = React.useState([]);
  const [showCancelCitationReason, setCancelCitationReason] = useState("CUSTOMER SUPPORT CANCEL")
  const [showCancelCitationComment, setCancelCitationComment] = useState("")
  const [showCommentEmptyMessage, setCommentEmptyMessage] = useState("");
  const [showErrorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setSuccessMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const showSideId = siteid();
  const [showModifyNewAmount, setModifyNewAmount] = useState("");
  const [showModifyAmountReason, setModifyAmountReason] = useState("");
  const [showModifyAmountComments, setModifyAmountComments] = useState("");
  const [showAmountEmptyMessage, setAmountEmptyMessage] = useState("");
  const [showReasonEmptyMessage, setReasonEmptyMessage] = useState("");
  const changeName = changeticket();

  const dispatch = useDispatch();


  const onClickDetailsPage = (tikno) => {
    Cookies.set('tikno', tikno);
    return props.onClickDetailsPage(true);
  }

  const closeError = () => {
    setCancelCitation(false);
    setModifyAmount(false)
  }


  // While Cancel Citation
  const OnClickCancelButton = () => {

    if (showCancelCitationComment === "") {
      setCommentEmptyMessage("Enter comments")
    } else {
      setCommentEmptyMessage("")
      var data = {
        "citation_ids": showSelectedId,
        "status": "Cancelled",
        "status_reason": showCancelCitationReason,
        "status_comments": showCancelCitationComment
      }

      // TicketReportDetails.updateStatusCitationArrayBulk(data).then(
      //   (response) => {
      //     if (response.data.success === true) {
      //       setSuccessMessage(response.data.message)
      //       setErrorMessage("")
      //       setTimeout(() => {
      //         setCancelCitation(false);
      //         setChecked(false);
      //         setSelectedId([]);
      //         setSelectedTicketNumber([])
      //         return props.updateValue(false);
      //       }, 2000);
      //     }

      //   },
      //   (error) => {

      //     if (error.response.status === 404) {
      //       setSuccessMessage("")
      //       setErrorMessage(error.response.data.message)
      //       setTimeout(() => {
      //         setCancelCitation(false);
      //       }, 2000);
      //       // commanPageLoadFunction();
      //     }
      //   }
      // )
    }
  }

  // While Modify Amount
  const OnClickModifyButton = () => {

    if (showModifyNewAmount === "" && showModifyAmountReason === "" && showModifyAmountComments === "") {
      setAmountEmptyMessage("Enter new amount")
      setReasonEmptyMessage("Enter modify amount reason")
      setCommentEmptyMessage("Enter modify amount comments")
    } else if (showModifyNewAmount === "") {
      setAmountEmptyMessage("Enter new amount")
    } else if (showModifyAmountReason === "") {
      setReasonEmptyMessage("Enter modify amount reason")
    } else if (showModifyAmountComments === "") {
      setCommentEmptyMessage("Enter modify amount comments")
    } else {
      setAmountEmptyMessage("")
      setReasonEmptyMessage("")
      setCommentEmptyMessage("")

      var data = {
        "citation_ids": showSelectedId,
        "new_amount": Number(showModifyNewAmount),
        "modification_reason": showModifyAmountReason,
        "modification_comment": showModifyAmountComments
      }

      // TicketReportDetails.updateCitationAmountBulk(data).then(
      //   (response) => {
      //     if (response.data.success === true) {
      //       setSuccessMessage(response.data.message)
      //       setErrorMessage("")
      //       setTimeout(() => {
      //         setModifyAmount(false)
      //         setChecked(false);
      //         setSelectedId([]);
      //         setSelectedTicketNumber([])
      //         return props.updateValue(false);
      //       }, 2000);
      //       commanPageLoadFunction();
      //     }

      //   },
      //   (error) => {

      //     if (error.response.status === 404) {
      //       setSuccessMessage("")
      //       setErrorMessage(error.response.data.message)
      //       setTimeout(() => {
      //         setModifyAmount(false)
      //       }, 2000);
      //     }
      //   }
      // )
    }
  }

  const commanPageLoadFunction = () => {
    $(function () {
      $('table')
        .on('click', '.srt-tab', function () {


          var index = $(this).index(),
            rows = [],
            thClass = $(this).hasClass('srt-ace') ? 'srt-desc' : 'srt-ace';

          $('#cstm-report-tb-display th').removeClass('srt-ace srt-desc');
          $(this).addClass(thClass);

          $('#cstm-report-tb-display tbody tr').each(function (index, row) {
            rows.push($(row).detach());
          });

          rows.sort(function (a, b) {
            var aValue = $(a).find('td').eq(index).text(),
              bValue = $(b).find('td').eq(index).text();

            return aValue > bValue
              ? 1
              : aValue < bValue
                ? -1
                : 0;
          });

          if ($(this).hasClass('srt-desc')) {
            rows.reverse();
          }

          $.each(rows, function (index, row) {
            $('#cstm-report-tb-display tbody').append(row);
          });
        });
    });

  }

  useEffect(() => {
    commanPageLoadFunction();

    // Services.getUserUiElement().then(
    //   (response) => {

    //     if (response.data.status) {
    //       setHideSideBarOption(response.data.data.ui_hide_elements)
    //     }


    //   },
    //   (error) => {
    //   }
    // );

    setCount(100);
  }, []);

  const OnClickAllCheckbox = (e) => {

    if (e.target.checked) {
      setChecked(true)
      var selectedId = [];
      var selectedTicketNumber = [];

      var allSelectedBtn = props.products.filter((data) => {

        if (data.status === "Valid") {
          selectedId.push(data.id);
          selectedTicketNumber.push(" " + data.ticket_no);
        }

      });



      setSelectedTicketNumber(selectedTicketNumber)
      setSelectedId(selectedId)
    } else {
      setChecked(false);
      setSelectedId([]);
      setSelectedTicketNumber([])
    }

    $('.comman-pl-csmt').prop('checked', !$('.comman-pl-csmt').prop('checked'));


  }

  const onChangeCheckEvents = (e) => {
    setChecked(false)

    var selectedId = showSelectedId;
    var selectedIdAll = showSelectedIdModifyAmount;
    var selectedTicket = showSelectedTicketNumber;


    if (e.target.checked) {

      if (e.target.value === "Valid") {
        selectedId.push(e.target.id);
        selectedTicket.push(" " + e.target.dataset.ticket_no);
      }
    } else {
      if (e.target.value === "Valid") {

        var carIndex = showSelectedId.indexOf(e.target.id);
        selectedId.splice(carIndex, 1);

        var ticketIndex = showSelectedTicketNumber.indexOf(e.target.dataset.ticket_no);
        selectedTicket.splice(ticketIndex, 1);
      }

    }

    setSelectedId(selectedId)
    setSelectedTicketNumber(selectedTicket)

  }

  const OnClickCancelCitation = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("")
      setErrorMessage("")
      setCancelCitationComment("")
      setCancelCitation(true);
    }
  }

  const OnClickModifyAmount = () => {
    if (showSelectedId.length !== 0) {
      setSuccessMessage("");
      setErrorMessage("");
      setModifyNewAmount("");
      setModifyAmountReason("");
      setModifyAmountComments("");
      setModifyAmount(true);
    }
  }

  const onChangeCancelCitation = (e) => {

    if (e.target.name === "select_reason") {
      setCancelCitationReason(e.target.value)
    } else if (e.target.name === "comment") {
      setCancelCitationComment(e.target.value)
      setCommentEmptyMessage("")
    }
  }
  const onChangeModifyAmount = (e) => {

    if (e.target.name === "new_amount") {
      setModifyNewAmount(e.target.value)
      setAmountEmptyMessage("")
    } else if (e.target.name === "modify_reason") {
      setModifyAmountReason(e.target.value)
      setReasonEmptyMessage("")
    } else if (e.target.name === "modify_comments") {
      setModifyAmountComments(e.target.value)
      setCommentEmptyMessage("")
    }
  }

  const downloadAttechment = (e, i) => {

    e.preventDefault();
    if (e.nativeEvent.button === 2) {
      setRightClick(i)
    } else {
      setRightClick(-1)
      var url = "";
      var imageData = {
        links: Object.assign({}, { 0: e.target.dataset.id }),
        download_type: 'CitationImages',
        site_id: showSideId.siteinfo,
      };
      initialdownimgfun()
      function initialdownimgfun() {
        var windowReference = window.open(url);
        Service.downloadImage(imageData)
          .then((responseImage) => {
            if (responseImage.data) {
              windowReference.location = responseImage.data.metadata[0].url;
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch)
              initialdownimgfun()
            }
          });

      }
    }
  }

  const handleCopyModel = (id) => {

    var imageData = {
      links: Object.assign({}, { 0: id }),
      download_type: 'CitationImages',
      site_id: showSideId.siteinfo,
    };
    initialdownimgdatafun()
    function initialdownimgdatafun() {
      Service.downloadImage(imageData)
        .then((responseImage) => {
          if (responseImage.data) {
            const el = document.createElement("input");
            el.value = responseImage.data.metadata[0].url;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            setRightClick(-1)
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            initialdownimgdatafun()
          }
        });
    }
  }

  const isKalamazoo = () => {
    if (Cookies.get("siteinfo") === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8" || Cookies.get("siteinfo") === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3") {
      return true 
    } else {
      return false
    }
  }

  return (

    <>
      <div className="report_tbl_scroll" id="tbl-main-download">
        <table className="table table-bordered table-heading" id="cstm-report-tb-display">
          <thead>
            <tr>
            <th className="srt-tab">
            Notice Date Time
              </th>
              <th className="srt-tab">
                {changeName} #
              </th>
              <th className="srt-tab">
                User Name
              </th>
              <th className="srt-tab">
                Subject
              </th>
              <th className="srt-tab">
                Block
              </th>
              <th className="srt-tab">
                Street
              </th>
              <th className="srt-tab">
                State
              </th>
              <th className="srt-tab">
                Plate
              </th>
              <th className="srt-tab">
                Email
              </th>
              <th className="srt-tab">
                Phone Number
              </th>
              <th className="srt-tab">
                Attachment
              </th>
            </tr>

          </thead>
          <tbody>
            {props.products.map((item, index) => (
              <tr key={item.id}>


                {/* <td><a data-id={item.ticket_no} onClick={onClickDetailsPage}>{moment(item.citation_start_timestamp.split('T')[0]).format('MM/DD/YYYY') + " " + (item.citation_start_timestamp).substring(11, 19)}</a></td> */}

               {item.created_at_local ?
              <td>  {moment(
                item.created_at_local.split("T")[0]
              ).format("MM/DD/YYYY") +
                " " +
                item.created_at_local.substring(11, 19)}</td>
              :
              <td>-</td>
              }
                {item.ticket_no !== "" && item.ticket_no !== null && item.ticket_no !== "null" && item.ticket_no !== undefined ?
                  <td>
                      <Link
                      to={`/ticketreporting?&ticket_no=${item.ticket_no}`}
                      onClick={(e) => onClickDetailsPage(e, item.ticket_no)}
                    >
                      {item.ticket_no}
                    </Link>
                    </td>
                  :
                  <td>-</td>
                }
                {item.user_name !== "" && item.user_name !== null && item.user_name !== "null" && item.user_name !== undefined ?
                  <td>{item.user_name}</td>
                  :
                  <td>-</td>
                }

                {item.subject !== "" && item.subject !== null && item.subject !== "null" && item.subject !== undefined ?
                  <td>{item.subject}</td>
                  :
                  <td>-</td>
                }
                {item.block !== "" && item.block !== null && item.block !== "null" && item.block !== undefined ?
                  <td>{item.block}</td>
                  :
                  <td>-</td>
                }
                {item.street !== "" && item.street !== null && item.street !== "null" && item.street !== undefined ?
                  <td>{item.street}</td>
                  :
                  <td>-</td>
                }
                {item.state !== "" && item.state !== null && item.state !== "null" && item.state !== undefined ?
                  <td>{item.state}</td>
                  :
                  <td>-</td>
                }
                {item.plate !== "" && item.plate !== null && item.plate !== "null" && item.plate !== undefined ?
                  <td>{item.plate}</td>
                  :
                  <td>-</td>
                }
                {item.email !== "" && item.email !== null && item.email !== "null" && item.email !== undefined ?
                  <td>{item.email}</td>
                  :
                  <td>-</td>
                }
                {item.phone !== "" && item.phone !== null && item.phone !== "null" && item.phone !== undefined ?
                  <td>{item.phone}</td>
                  :
                  <td>-</td>
                }
                {item.attachment !== "" && item.attachment !== null && item.attachment !== "null" && item.attachment !== undefined ?
                  <td><Link data-id={item.attachment} onContextMenu={(e) => downloadAttechment(e, index)} onClick={(e) => downloadAttechment(e, index)}>Download</Link>
                    {rightClick === index &&
                      <div className='right-copy-menu' onClick={() => handleCopyModel(item.attachment)}>
                        Copy link
                      </div>
                    }
                  </td>
                  :
                  <td>-</td>
                }

              </tr>
            ))}
          </tbody>
        </table>
      </div>


      {showCancelCitation &&
        <div className="cstm-site-model">
          <div className="cstm-site-model-block fixed z-20">
            <div className="cstm-site-popup-bg">
              <div className="cstm-site-model-wrapper auth-taken-cstm-model-wrapper">
                <button onClick={closeError} className="cstm-close-btn-db-is">
                  <img src={close} alt="close" />
                </button>
                <div>
      <p className="cancel-citation-ttl-main">Cancel {changeTotal}</p>
                  <span className="auth-error-msg-success">{showSuccessMessage}</span>
                  <span className="auth-error-msg">{showErrorMessage}</span>
                  <span className="cancel-title-main">Selected {changeName} Numbers</span>
                  <textarea type="text" className="commet-citation-cancel" value={showSelectedTicketNumber} disabled />
                  <br />
                  <span className="cancel-title-main">Cancel {changeTotal} Reason</span>
                  <select className="form-select-site" name="select_reason" value={showCancelCitationReason} onChange={onChangeCancelCitation}>
                    {Cookies.get('agency_id') !== "7126cb4a-eb0a-11ec-8ca9-66552211e1c9" &&
                      <option value="CUSTOMER SUPPORT CANCEL">CUSTOMER SUPPORT CANCEL</option>
                    }
                    <option value="MANAGER CANCEL">MANAGER CANCEL</option>
                    <option value="SUPERVISOR CANCEL">SUPERVISOR CANCEL</option>
                    <option value="PLATE NUMBER TRANSPOSED">PLATE NUMBER TRANSPOSED</option>
                    <option value="CLIENT APPROVED">CLIENT APPROVED</option>
                    <option value="OFFICER ERROR">OFFICER ERROR</option>
                    <option value="SYSTEM ERROR">SYSTEM ERROR</option>
                    {!isKalamazoo() && <option value="PARK WHIZ">PARK WHIZ</option>}
                    {isKalamazoo() && <option value="COURTESY VOID">COURTESY VOID</option>}
                    {isKalamazoo() && <option value="HANDICAP VOID">HANDICAP VOID</option>}
                  </select>
                  <br />
                  <span className="cancel-title-main">Cancel {changeTotal} Comments</span>
                  <textarea name="comment" placeholder="Enter Comments" value={showCancelCitationComment} onChange={onChangeCancelCitation} className="commet-citation-cancel" />
                  <span className="auth-error-msg auth-error-comment-msg">{showCommentEmptyMessage}</span>
                  <button className="auth-cstm-submit-btn" onClick={OnClickCancelButton}>Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {showModifyAmount &&
        <div className="cstm-site-model">
          <div className="cstm-site-model-block fixed z-20">
            <div className="cstm-site-popup-bg">
              <div className="cstm-site-model-wrapper auth-taken-cstm-model-wrapper">
                <button onClick={closeError} className="cstm-close-btn-db-is">
                  <img src={close} alt="close" />
                </button>
                <div>
                  <p className="cancel-citation-ttl-main">Modify Amount</p>
                  <span className="auth-error-msg-success">{showSuccessMessage}</span>
                  <span className="auth-error-msg">{showErrorMessage}</span>
                  <span className="cancel-title-main">Selected {changeName} Numbers</span>
                  <textarea type="text" className="commet-citation-cancel" value={showSelectedTicketNumber} disabled />
                  <br />
                  <span className="cancel-title-main">New Amount</span>
                  <div className="pl-doller-icon">
                    <input type="number" className="form-input-fuild cstm-form-input" name="new_amount" placeholder="Enter new amount" value={showModifyNewAmount} onChange={onChangeModifyAmount} />
                  </div>
                  <span className="auth-error-msg auth-error-comment-msg">{showAmountEmptyMessage}</span>
                  <br />
                  <span className="cancel-title-main">Modify Amount Reason</span>
                  <input type="text" className="form-input-fuild cstm-form-input" name="modify_reason" placeholder="Enter modify reason" value={showModifyAmountReason} onChange={onChangeModifyAmount} />
                  <span className="auth-error-msg auth-error-comment-msg">{showReasonEmptyMessage}</span>

                  <br />
                  <span className="cancel-title-main">Modify Amount Comments</span>
                  <textarea placeholder="Enter comments" name="modify_comments" value={showModifyAmountComments} onChange={onChangeModifyAmount} className="commet-citation-cancel" />
                  <span className="auth-error-msg auth-error-comment-msg">{showCommentEmptyMessage}</span>

                  <button className="auth-cstm-submit-btn" onClick={OnClickModifyButton}>Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </>

  );
};

export default ProductTable;
