import React, { useState, useEffect } from 'react'; 
import { Doughnut } from 'react-chartjs-2';
import Cookiesremove from '../../../Cookiesremove';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service"; 

function PermitUtilizationCount()
{
    const [count, setCount] = React.useState(0);
    const [showAuthToken, setAuthToken] = useState(false);
    const [showPermitCount, setPermitCount] = useState([]);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () =>{
        setErrorHandling(false)
    }
    const onClickLogin = (e) =>
    {
      e.preventDefault();
      Cookiesremove();
      window.location.href = "/";
    }

    const average_val = showPermitCount.avg_permit_utilization - 100;

    const data = {
        // labels: ['Red'],
        datasets: [
          {
             data: [ showPermitCount.avg_permit_utilization, average_val ],
      
            backgroundColor: [
              '#FA5944',
              '#F1F1F1', 
            ],
      
            borderWidth: 1,
          },
        ],
      };
      
      const options = {
        cutout: 80
      }
   
    // Display permit utilization total count
    useEffect(() => {
         
        Dashboard.getPermitCount().then(
            (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                  if(response.data.status === false){
                    setErrorHandling(true)
                    setErrorHandleMessage(response.data.message)
                }else{
                    setPermitCount(response.data.data[0].metadata)
                  }
                }
              }
            )
          setCount(100);
        }, []);

    return (
        <>

            <div className="cstm-dashboard-aopu01 cstm-dao2">
                     <div className="cstm-dashboard-mn-all">
                        <div className="cstm-dashboard-mn-all-left">Permit Utilization</div>
                        {/* <div className="cstm-dashboard-mn-all-right"><a href="#">View Details <i className="fa fa-angle-right"></i></a></div> */}
                     </div>
                     <div className="cstm-ao-map-left-img">
                       <div className="cstm_permit_canvas">
                       <Doughnut  options={options} data={data} />
                       </div>
                       </div>
                     <div className="cstm-ao-map-left-to">
                        <div className="cstm-ao-map-left-to-in">
                           <div className="cstm-ao-map-left-to-in-top">Total Permits</div>
                           <div className="cstm-ao-map-left-to-in-bottom">{showPermitCount.total_permits}</div>
                        </div>
                        <div className="cstm-ao-map-left-to-in">
                           <div className="cstm-ao-map-left-to-in-top">Permit Utilization</div>
                           <div className="cstm-ao-map-left-to-in-bottom cstm-ac-clr">{showPermitCount.permit_utilization}</div>
                        </div>
                     </div>
                  </div> 

            

        </>
    );
}

export default PermitUtilizationCount;
