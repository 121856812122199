import React, { useState, useEffect } from 'react';
import {Line as LineChart} from 'react-chartjs-2';
import Cookiesremove from '../../../Cookiesremove';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

const options = {
  scaleShowGridLines: true,
  scaleGridLineColor: 'rgba(0,0,0,.05)',
  scaleGridLineWidth: 1,
  scaleShowHorizontalLines: true,
  scaleShowVerticalLines: true,
  bezierCurve: true,
  bezierCurveTension: 0.4,
  pointDot: true,
  pointDotRadius: 4,
  pointDotStrokeWidth: 1,
  pointHitDetectionRadius: 20,
  datasetStroke: true,
  datasetStrokeWidth: 2,
  datasetFill: true,
  legendTemplate: '<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',
}

const styles = {
  graphContainer: {
    // border: '1px solid black',
    // padding: '15%'
  }
}


function LineChartLatency()   {

    const [count, setCount] = React.useState(0); 
    const [showXaxisValue, setXaxisValue] = React.useState([]); 
    const [showAuthToken, setAuthToken] = useState(false);
    const [showParkMobile, setParkMobile] = React.useState([]);
    const [showPayByPhone, setPayByPhone] = React.useState([]);
    const [showGetMyParking, setGetMyParking] = React.useState([]); 
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
    const [showTodayButton, setTodayButton] = useState(true)
    const [showMonthButton, setMonthButton] = useState(false)
    const [showYearButton, setYearButton] = useState(false)

    const closeError = () =>{
      setErrorHandling(false)
    }

    function getDataset(index, data) {  

        var highlightColor = ['#FFB34D', '#029279', '#FA5944', '#044271' ]
     
        return { 
        label: data.vendor_name, 
        radius: 2, 
        borderColor:  highlightColor[index],
        fillColor: 'rgba(220,220,220,0.2)',
        pointColor: 'rgba(151,187,205,1)',
        pointStrokeColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(151,187,205,1)',
        data: data.timeline_array 
            }; 
        }
        
        var lineChartData = [];
        showGetMyParking.forEach(function (a, i) { 
            lineChartData.push(getDataset(i,a)); 
        });
    
    var chartData = {
        labels:  showXaxisValue,
        datasets:  lineChartData,
    }   

      const onClickLogin = (e) =>
    {
      e.preventDefault();
    Cookiesremove();
      window.location.href = "/";
    }

      const onChangeYear = (e) =>{  

        setTodayButton(false)
        setMonthButton(false)
        setYearButton(true)

        const retriveData = {
            "data_type": "payment",
            "array_timeline": {
                "type": "annually" 
            }
        }
         
        Dashboard.getTransactionSummary(retriveData).then(
         
          
             (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                    if(response.data.status === false){
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    }else{ 
                            setGetMyParking(response.data.data[0].metadata)
                            setXaxisValue(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug', 'Sept', 'Oct', 'Nov', 'Dec'])
                        
                    }
                }
            }
        )
    }

    const onChangeMonth = (e) =>{ 

        setTodayButton(false)
        setMonthButton(true)
        setYearButton(false)

        const retriveData = {
            "data_type": "payment",
            "array_timeline": {
                "type": "monthly" 
            }
        }
         
        Dashboard.getTransactionSummary(retriveData).then(
         
             (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                    if(response.data.status === false){
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    }else{
                            setGetMyParking(response.data.data[0].metadata)
                            setXaxisValue(['01', '02', '03', '04', '05', '06', '07','08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24','25', '26', '27', '28', '29', '30', '31'])
                        
                    }
                }
            }
        )
    }

    const OnTodayChart = () => {
        
        setTodayButton(true)
        setMonthButton(false)
        setYearButton(false)

        const retriveData = {
            "data_type": "payment",
            "array_timeline": {
                "type": "daily"
            }
        }
         
        Dashboard.getTransactionSummary(retriveData).then(
            (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                    if(response.data.status === false){
                        setErrorHandling(true)
                        setErrorHandleMessage(response.data.message)
                    }else{
                            setGetMyParking(response.data.data[0].metadata)
                            setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                        
                    }
                }
            }
        ) 
    }
  
        useEffect(() => {  
            

            const retriveData = {
                "data_type": "payment",
                "array_timeline": {
                    "type": "daily"
                }
            }
             
            Dashboard.getTransactionSummary(retriveData).then(
                (response) => {
                    if(response.data.status === 300) {
                        setAuthToken(true) 
                     }else{
                        if(response.data.status === false){
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        }else{  
                            if(response.data.data[0].metadata.length !== 0){ 
                                setGetMyParking(response.data.data[0].metadata) 
                                setXaxisValue(['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'])
                            }
                        }
                    }
                }
            )
        setCount(100);
        }, []);
       
    return (
        <>
            <div className="cstm-tra-sum">
                <div className="cstm-tra-sum-top">
                    <div className="cstm-od-rtp-left">Transaction Summary</div>
                        <div className="cstm-od-rtp-right">
                            <div className="cstm-os-in1"> <button className={`cstm-om-today ${showTodayButton ? 'active' : ''}`} name="today" onClick={OnTodayChart}>Today</button>
                                </div>
            
                     <div className="cstm-os-in1">
					  <button className={`cstm-om-today ${showMonthButton ? 'active' : ''}`} name="monthly" onClick={onChangeMonth}>Monthly</button>
					</div>

					<div className="cstm-os-in1">
					  <button className={`cstm-om-today ${showYearButton ? 'active' : ''}`} name="yearly" onClick={onChangeYear}>Yearly</button>
					</div> 
                            </div>
                        </div>

                        {showGetMyParking.length !== 0 ?
                        <div className="cstm-tra-sum-bottom"> <div style={styles.graphContainer}>
                            <LineChart data={chartData}
                            options={options}
                            width="500" height="150"/>
                            </div>
                        </div>
                        :
                            <div className="db-no-data-msg">No Data Found</div>
                        }

            </div>


       
        </>
        
        )
 
}

export default LineChartLatency;