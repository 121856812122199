import { connect } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { clearoutAuthUserData } from '../../actions/auth';
import { useDispatch } from "react-redux";
import moment from 'moment'
import { refreshTokenMiddleware } from "../customMiddelware";
import { useState, useCallback, useMemo, useEffect } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import FlipbookService from "../../Services/flipbook.service";
import FlipbookPage from './Page';
import './FlipBook.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const localizer = momentLocalizer(moment)

const FlipbookModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <FlipbookPage key={isOpen} ticketId={isOpen} handleBackButtonClick={onClose} />
    </div>
  );
}

function formatDate(inputDate) {
  // Parsing the input date string using moment
  const date = moment(inputDate);

  const year = date.year();
  const month = date.month();
  const day = date.date();
  const hour = date.hour();
  const minute = date.minute();
  const second = date.second();

  // Constructing the new format
  return new Date(year, month, day, hour, minute, second);
}

// Define a custom formats object
const formats = {
  eventTimeRangeFormat: () => {
    return ""; // return empty string so no time is displayed
  },
};

const CalendarComp = () => {
  const dispatch = useDispatch();
  const [myEvents, setEvents] = useState([])
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [citationNumber, setCitationNumber] = useState("");
  const [ticketEntryModalOpen, setTicketEntryModalOpen] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const location = useLocation();
  const selectedItems = Array.from(new Set(location.state?.selectedItems));
  const [ticketDatePairs, setTicketDatePairs] = useState(
    selectedItems && selectedItems.length > 0
      ? selectedItems.map(item => ({ ticket: item, date: new Date() }))
      : [{ ticket: '', date: new Date() }]
  );
  const navigate = useNavigate();

  const matchFilterQuery = (array, input) => {
    return array.filter(item =>
      item?.id?.includes(input) || item?.title?.includes(input) || item?.lp_number?.toLowerCase()?.includes(input.toLowerCase())
    );
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setCitationNumber(value);
    if (value === "") {
      setDisplayedEvents(myEvents);
      setDefaultDate(new Date()); // Reset to the current date when search is cleared
    } else {
      const results = matchFilterQuery(myEvents, value);
      if (results.length) {
        setDisplayedEvents(results);
        setDefaultDate(new Date(results[0].start));
      } else {
        setDisplayedEvents(myEvents);
        setDefaultDate(new Date()); // Reset to the current date if no matches
      }
    }
  }

  const closeModal = () => {
    setTicketEntryModalOpen(false);
    setAlertMessage('');
    navigate('.', { replace: true, state: { selectedItems: [] } }); // Clear the selected items
    setTicketDatePairs([{ ticket: '', date: new Date() }]);
  };

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setTicketEntryModalOpen(true);
    },
    []
  );

  const handleSubmit = useCallback(
    async () => {
      if (ticketDatePairs.length > 0) {
        const validPairs = ticketDatePairs.filter(pair => pair.ticket !== '' && pair.ticket !== null);
        if (validPairs.length > 0) {
          try {
            const promises = validPairs.map(pair => FlipbookService.createHearing(pair.ticket, pair.date));

            Promise.all(promises)
              .then(results => {
                const successfulResults = results.filter(result => result.success);
                if (successfulResults.length > 0) {
                  setEvents(prev => [...prev, ...validPairs.map(pair => ({ start: pair.date, end: new Date(pair.date.getTime() + (60 * 60 * 1000)), title: pair.ticket, id: pair.ticket }))]);
                  setDisplayedEvents(prev => [...prev, ...validPairs.map(pair => ({ start: pair.date, end: new Date(pair.date.getTime() + (60 * 60 * 1000)), title: pair.ticket, id: pair.ticket }))]);

                  setAlertMessage({ message: 'Hearing(s) created successfully!', type: 'success' });
                  setTimeout(() => {
                    setTicketEntryModalOpen(false);
                    setTicketDatePairs([{ ticket: '', date: new Date() }]);
                    //navigate('.', { replace: true, state: { selectedItems: [] } }); // Clear the selected items
                    navigate(`/hearing-detail?id=${successfulResults[0].data.ticket_id}&citation_no=${successfulResults[0].data.ticket_no}`);
                    setAlertMessage('');
                  }, 1000);
                } else {
                  setAlertMessage(results[0]?.message || 'Something went wrong')
                }
              })
              .catch(error => {
                if (error.message === 'LOGGED_OUT') {
                  refreshTokenMiddleware(dispatch);
                }
                setAlertMessage(error.message);
              });
          } catch (error) {
            setAlertMessage(error.message);
          }
        } else {
          setAlertMessage('Please enter a ticket number');
        }
      }
    },
    [dispatch, navigate, ticketDatePairs]
  );

  const handleSelectEvent = useCallback(
    (event) => {
      setModalOpen(event.id)
    },
    []
  )

  const { scrollToTime } = useMemo(
    () => ({
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hearingsList = await FlipbookService.getHearings();
        const hearingCalendarArray = [];
        hearingsList.data.forEach(citation => {
          const calendarObject = {};
          if (citation?.hearing_details?.date) {
            calendarObject.id = citation.ticket_no;
            calendarObject.lp_number = citation?.vehicle_details?.lp_number
            calendarObject.title = `${citation?.ticket_no}`
            calendarObject.start = formatDate(citation?.hearing_details?.date)
            let endDate = new Date(calendarObject.start)
            endDate.setHours(endDate.getHours() + 1);
            calendarObject.end = endDate;
            hearingCalendarArray.push(calendarObject);
          }
        });
        if (hearingCalendarArray.length > 0) {
          setEvents(prev => [...prev, ...hearingCalendarArray])
          setDisplayedEvents(hearingCalendarArray);
        }
      } catch (error) {
        if (error.message === 'LOGGED_OUT') {
          refreshTokenMiddleware(dispatch)
        }
      }
    }
    fetchData()
  }, [dispatch]);

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel" style={{ height: '100%', width: '100%' }}>
              <div className="report-searchbar">
                <form>
                  <div className="report-licence report-mr" style={{ width: '30%' }}>
                    <input
                      name="citation_number"
                      type="text"
                      value={citationNumber}
                      onChange={handleSearchInput}
                      placeholder="Start Typing To Search By Citation Or Plate Number"
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
              <Calendar
                date={defaultDate}
                defaultView={Views.WEEK}
                events={displayedEvents}
                localizer={localizer}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                selectable
                scrollToTime={scrollToTime}
                onNavigate={date => setDefaultDate(date)}
                formats={formats}
              />
              {isModalOpen && (
                <FlipbookModal
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                  ticketDetails={isModalOpen} //isModalOpen has the ticket number as there was no other way to pass it. TODO
                />
              )}
              {ticketEntryModalOpen && (
                <div className="main-body-modal">
                  <div className="main-modal">
                    <div className="cstm-close">
                      <button onClick={closeModal}>x</button>
                    </div>
                    <div className="cstm-modal-body">
                      <div className="modal-body cstm-modal-body">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {(!selectedItems || selectedItems.length === 0) ? (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div className="text-center cstm-paymnet-img" style={{ marginRight: '120px' }}>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Ticket Number"
                                  onChange={(e) => {
                                    const newTicketDatePairs = [...ticketDatePairs];
                                    newTicketDatePairs[0].ticket = e.target.value;
                                    setTicketDatePairs(newTicketDatePairs);
                                  }}
                                />
                              </div>
                              <div className="report-date report-mr">
                                <DatePicker
                                  showTimeSelect
                                  timeIntervals={1}
                                  selected={ticketDatePairs[0].date}
                                  onChange={date => {
                                    const newTicketDatePairs = [...ticketDatePairs];
                                    newTicketDatePairs[0].date = date;
                                    setTicketDatePairs(newTicketDatePairs);
                                  }}
                                  minDate={new Date()}
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  className='cstm-pull-details schedule-date-input'
                                />
                              </div>
                            </div>
                          ) : (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {selectedItems?.map((item, index) => (
                                <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div className="text-center cstm-paymnet-img" style={{ marginRight: '120px' }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Ticket Number"
                                      value={item}
                                      onChange={(e) => {
                                        const newTicketDatePairs = [...ticketDatePairs];
                                        newTicketDatePairs[index].ticket = e.target.value;
                                        setTicketDatePairs(newTicketDatePairs);
                                      }}
                                    />
                                  </div>
                                  <div className="report-date report-mr">
                                    <DatePicker
                                      showTimeSelect
                                      timeIntervals={1}
                                      selected={ticketDatePairs[index].date}
                                      onChange={date => {
                                        const newTicketDatePairs = [...ticketDatePairs];
                                        newTicketDatePairs[index].date = date;
                                        setTicketDatePairs(newTicketDatePairs);
                                      }}
                                      minDate={new Date()}
                                      dateFormat="MM/dd/yyyy h:mm aa"
                                      className='cstm-pull-details schedule-date-input'
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        {alertMessage && alertMessage.type === 'success' ? <p className="text-center text-success">{alertMessage.message}</p> : <p className="text-center text-danger">{alertMessage}</p>}
                        <div className="text-center">
                          <button className="licence-print report-mr" type="button" onClick={handleSubmit}>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default connect(null, { clearoutAuthUserData })(CalendarComp)