import React, { useState, useEffect } from 'react';
import TotalCitation from '../../../Images/tc.png';
import TotalScan from '../../../Images/ts.png';
import TotalCancel from '../../../Images/tcc.png';
import TotalReissue from '../../../Images/tr.png';
import TimingRecords from '../../../Images/trr.png';
import TotalScofflaw from '../../../Images/tss.png';
import close from '../../../Images/close.svg';
import { Bar } from 'react-chartjs-2'
import Dashboard from "../../../Services/dashboard.service";
import { Chart, registerables } from 'chart.js';

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
// import { siteid } from "../../../Global/site";
import { refreshTokenMiddleware } from '../../customMiddelware';
import { changetotal } from '../../../Global/site';

Chart.register(...registerables);

function OperationTotalCount({siteid}) {

   const dispatch = useDispatch()
   const changeTotal = changetotal();

   const [count, setCount] = useState(0);
   const [showTotalCitation, setTotalCitation] = useState("");
   const [showTotalScan, setTotalScan] = useState("");
   const [showTotalCancel, setTotalCancel] = useState("");
   const [showTotalReissue, setTotalReissue] = useState("");
   const [showTimingRecord, setTimingRecord] = useState("");
   const [showTotalScofflaw, setTotalScofflaw] = useState("");
   const [showAuthToken, setAuthToken] = useState(false);
   const [showErrorHandling, setErrorHandling] = useState(false)
   const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
   const [showTickets, setTickets] = useState([]);
   const [showScans, setScans] = useState([]);
   const [showCancle, setCancle] = useState([]);
   const [showReissue, setReissue] = useState([]);
   const [showTiming, setTiming] = useState([]);
   const [showPayment, setPayment] = useState([]);
   const [showScofflaw, setScofflaw] = useState([]);
   const showSideId = siteid


   const options = {
      scales: {
         x: {
            stacked: true,
         },
         y: {
            stacked: true
         }
      }
   }

   const stateGraph = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [

         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Tickets',
            backgroundColor: '#FFB34D',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showTickets
         },
         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Scans',
            backgroundColor: '#044271',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showScans
         },
         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Cancel',
            backgroundColor: '#FA5944',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showCancle
         },
         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Reissue',
            backgroundColor: '#029279',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showReissue
         },
         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Timing',
            backgroundColor: '#629BDD',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showTiming
         },
         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Payment',
            backgroundColor: '#5D9A0F',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showPayment
         },
         {
            barPercentage: 0.5,
            barThickness: 40,
            maxBarThickness: 30,
            minBarLength: 5,
            label: 'Scofflaw',
            backgroundColor: '#9A0F73',
            borderColor: 'rgba(255,255,255,255)',
            borderWidth: 1,
            stack: 1,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: showScofflaw
         }
      ]
   };

   useEffect(() => { 

      const retriveSiteId = {
         "site_ids": [showSideId]
      }
      initialoperationsummaryfun()
      function initialoperationsummaryfun () {
         Dashboard.operationSummaryGraph(retriveSiteId)
            .then((response) => {
   
               if (response.data.status === 300) {
                  setAuthToken(true)
               } else {
                  if (response.data.status === false) {
                     setErrorHandling(true)
                     setErrorHandleMessage(response.data.message)
                  } else {
                     setTotalCitation(response.data.data[0][0].counts.CitationData)
                     setTotalScan(response.data.data[0][0].counts.ScanData)
                     setTotalCancel(response.data.data[0][0].counts.CancelledData)
                     setTotalReissue(response.data.data[0][0].counts.ReissueData)
                     setTimingRecord(response.data.data[0][0].counts.TimingData)
                     setTotalScofflaw(response.data.data[0][0].counts.ScofflawData)
   
                     setTickets(response.data.data[0][0].weekly_breakdown.CitationData)
                     setScans(response.data.data[0][0].weekly_breakdown.ScanData)
                     setCancle(response.data.data[0][0].weekly_breakdown.CancelledData)
                     setReissue(response.data.data[0][0].weekly_breakdown.ReissueData)
                     setTiming(response.data.data[0][0].weekly_breakdown.TimingData)
                     setPayment(response.data.data[0][0].weekly_breakdown.PaymentData)
                     setScofflaw(response.data.data[0][0].weekly_breakdown.ScofflawData)
                  }
               }
            }).catch((err) => {
               if (err.response.status === 401) {
                  refreshTokenMiddleware(dispatch)
                  initialoperationsummaryfun()
               }
            });
      }
      setCount(100);
   }, []);

   return (
      <>
         <div className="row cstm-dashboard-mn">
            <div className="cstm-dashboard-mn-left">
               <div className="cstm-dashboard-mn-all">
                  <div className="cstm-dashboard-mn-all-left">Operations - Current Day</div>
                  {/* <div className="cstm-dashboard-mn-all-right"><a href="#">View Details <i className="fa fa-angle-right"></i></a></div> */}
               </div>
               <div className="cstm-dashboard-mn-left-main">
                  <div className="cstm-dashboard-mn-left-main-in">
                     <div className="cstm-prod-box-icon"><img src={TotalCitation} /></div>
                     <div className="cstm-op-asd">
                        <div className="cstm-prod-box-name">Total {changeTotal}</div>
                        <div className="cstm-prod-box-cont">{showTotalCitation}</div>
                     </div>
                  </div>
                  <div className="cstm-dashboard-mn-left-main-in">
                     <div className="cstm-prod-box-icon"><img src={TotalScan} /></div>
                     <div className="cstm-op-asd">
                        <div className="cstm-prod-box-name">Total Scan</div>
                        <div className="cstm-prod-box-cont">{showTotalScan}</div>
                     </div>
                  </div>
                  <div className="cstm-dashboard-mn-left-main-in">
                     <div className="cstm-prod-box-icon"><img src={TotalCancel} /></div>
                     <div className="cstm-op-asd">
                        <div className="cstm-prod-box-name">Total Cancel</div>
                        <div className="cstm-prod-box-cont">{showTotalCancel}</div>
                     </div>
                  </div>
                  <div className="cstm-dashboard-mn-left-main-in">
                     <div className="cstm-prod-box-icon"><img src={TotalReissue} /></div>
                     <div className="cstm-op-asd">
                        <div className="cstm-prod-box-name">Total Reissue</div>
                        <div className="cstm-prod-box-cont">{showTotalReissue}</div>
                     </div>
                  </div>
                  <div className="cstm-dashboard-mn-left-main-in">
                     <div className="cstm-prod-box-icon"><img src={TimingRecords} /></div>
                     <div className="cstm-op-asd">
                        <div className="cstm-prod-box-name">Timing Records</div>
                        <div className="cstm-prod-box-cont">{showTimingRecord}</div>
                     </div>
                  </div>
                  <div className="cstm-dashboard-mn-left-main-in">
                     <div className="cstm-prod-box-icon"><img src={TotalScofflaw} /></div>
                     <div className="cstm-op-asd">
                        <div className="cstm-prod-box-name">Total Scofflaw</div>
                        <div className="cstm-prod-box-cont">{showTotalScofflaw}</div>
                     </div>
                  </div>
               </div>

            </div>
            {/* <div className="cstm-opetationChart-sec">
               <div className="cstm-dashboard-mn-all-left">Weekly Operations Summary</div>
               <Bar width={250} height={125} data={stateGraph} options={options} />
            </div> */}
         </div>


      </>
   );
}

export default OperationTotalCount;