import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import mainService from "../../Services/main.service";
 
import { refreshTokenMiddleware } from '../customMiddelware'

//images
import transfer from '../../Images/transfer.png';
import FilterExportImg from '../../Images/export.png'
import dropdownarrow from '../../Images/dropdown-arrow.svg';

function pad2(n) {
    return (n < 10 ? '0' : '') + n;
}


const CollectionReports = () => {

    const dispatch = useDispatch()

    const [recordList, setRecordList] = useState([])
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [filterDate, setFilterDate] = useState({
        issue_ts_from: new Date().setHours(0, 0, 0),
        issue_ts_to: new Date().setDate(new Date().getDate() + 1)
    });
    const [filterOption, setFilterOption] = useState({
        violation_code: [],
        violation_description: []
    });
    const [violationDataList, setViolationDataList] = useState([]);


    useEffect(() => {
        otherFilterDataLoad()
        let query_string = ''
        if (filterDate.issue_ts_from) {
            query_string += 'issue_ts_from=' + convertFilterFormatData(filterDate.issue_ts_from) + '&'
        }
        if (filterDate.issue_ts_to) {
            query_string += 'issue_ts_to=' + convertFilterFormatData(filterDate.issue_ts_to) + '&'
        }
        getTableRecord(query_string)
    }, [])

    const getTableRecord = async (query_string) => {
        setLoader(true)
        try {
            const result = await mainService.getRevenueCollectionReportsAPI(query_string)

            if (result.data.status && result.data.length > 0) {
                setRecordList(result.data.data)
            } else {
                setMessage('No Record Found')
            }
            setLoader(false)
        } catch (err) {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                getTableRecord(query_string)
            } else {
                setMessage('No Record Found')
                setLoader(false)
            }
        }
    }

    function otherFilterDataLoad() {
        // fetch violation code and description list
        var payload4 = {
            "type": "ViolationList",
            "shard": 1
        }
        mainService.violationCode(payload4).then(result => {
            if (result.data.status) {
                setViolationDataList(result.data.data[0].response)
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                otherFilterDataLoad()
            }
        });
    }

    const onChangeFilterDate = (date, name) => {
        // const d = new Date(date)
        setFilterDate({ ...filterDate, [name]: date })
    }
    const onChangeFilterDropDownOptions = (selectedList, name) => {
        setFilterOption({ ...filterOption, [name]: selectedList })
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        setRecordList([])
        let query_string = ''

        if (filterDate.issue_ts_from) {
            query_string += '&issue_ts_from=' + convertFilterFormatData(filterDate.issue_ts_from)
        }
        if (filterDate.issue_ts_to) {
            query_string += '&issue_ts_to=' + convertFilterFormatData(filterDate.issue_ts_to)
        }
        if (filterOption.violation_code.length) {
            filterOption.violation_code.map((it) => {
                query_string += '&violation_code=' + it.code
            })
        }
        if (filterOption.violation_description.length) {
            filterOption.violation_description.map((it) => {
                query_string += '&violation_description=' + it.desc
            })
        }

        getTableRecord(query_string)

    }

    function convertFilterFormatData(dt) {
        var date = new Date(dt);
        var year = date.getFullYear();
        var month = pad2(date.getMonth() + 1);
        var todayDate = pad2(date.getDate());

        var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z";

        return currentTimeInSeconds
    }

    function convertDecimal(num) {
        return (Math.round(num * 100) / 100).toFixed(2)
    }

    return (
        <>
            <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>
                <div className="appeal-report-heading">
                    <h4>Revenue Collection Reports</h4>
                </div>

                <div className="report-searchbar">
                    <form>
                        <div className="report-date report-mr">
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                autoComplete='off'
                                className="cstm-pull-details schedule-date-input"
                                name="issue_ts_from"
                                selected={filterDate.issue_ts_from}
                                onChange={date => onChangeFilterDate(date, 'issue_ts_from')}
                            />
                            <img src={transfer} alt="transfer" className="date-transfer" />
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                placeholderText='MM/DD/YYYY HH:MM'
                                autoComplete='off'
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                className="cstm-pull-details schedule-date-input"
                                name="issue_ts_to"
                                selected={filterDate.issue_ts_to}
                                onChange={date => onChangeFilterDate(date, 'issue_ts_to')}
                            />
                        </div>
                        {/* <div className="report-licence select-drop-arrow report-mr">
                            <Multiselect
                                name="officer_name"
                                displayValue="officer"
                                placeholder="Officer Name"
                                showCheckbox
                            />
                            <img src={dropdownarrow} alt="dropdownarrow" />
                        </div> */}
                        <div className="report-licence select-drop-arrow report-mr">
                            <Multiselect
                                name="violation_code"
                                selectedValues={filterOption.violation_code}
                                displayValue="code"
                                placeholder="Select violation code"
                                onRemove={(e) => onChangeFilterDropDownOptions(e, 'violation_code')}
                                onSelect={(e) => onChangeFilterDropDownOptions(e, 'violation_code')}
                                options={
                                    (violationDataList.map((it) => (
                                        {
                                            code: (it.code)
                                        }
                                    )))
                                }
                                showCheckbox
                            />
                            <img src={dropdownarrow} alt="dropdownarrow" />
                        </div>
                        <div className="report-licence select-drop-arrow report-mr cstm-vio-des-main-sub">
                            <Multiselect
                                name="violation_description"
                                selectedValues={filterOption.violation_description}
                                displayValue="desc"
                                placeholder="Select violation description"
                                onRemove={(e) => onChangeFilterDropDownOptions(e, 'violation_description')}
                                onSelect={(e) => onChangeFilterDropDownOptions(e, 'violation_description')}
                                options={
                                    (violationDataList.map((it) => (
                                        {
                                            desc: (it.violation_description)
                                        }
                                    )))
                                }
                                showCheckbox
                            />
                            <img src={dropdownarrow} alt="dropdownarrow" />
                        </div>

                        {/* <button className="filter-box report-mr" onClick={handleFilterChange}><img src={FilterExportImg} alt="FilterExportImg" className="img-fluid" /></button> */}
                        <button className="licence-print report-mr" onClick={handleFilterSubmit}>Submit</button>
                    </form>
                </div>


                <div id="Dashboard" className="tab-pane active">
                    <div className='text-center'>
                        {loader ?
                            <div className="spinner-border"></div>
                            :
                            (recordList === null || recordList?.length === 0 ?
                                <div className='cstm-no-record-found'>{message || 'No Record Found'}</div>
                                :
                                <div className="ctsm-table-scroll">
                                    <div className="table-overflow">
                                        <table className="table-heading table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="cstm-checkbox">
                                                        Group Count
                                                    </th>
                                                    <th>Violation Code</th>
                                                    <th>Violation Description</th>
                                                    <th>Revenue before Escalation</th>
                                                    <th>Boot Tow Fee</th>
                                                    <th>Revenue after 1 Escalation</th>
                                                    <th>Revenue after 2 Escalation</th>
                                                    <th>Collection Fee</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!loader &&
                                                    (recordList || []).map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="cstm-checkbox">
                                                                {item.group_count || '0'}
                                                            </td>
                                                            <td>{item.violation_code || '-'}</td>
                                                            <td>{item.violation_description || '-'}</td>
                                                            <td>${convertDecimal(item.revenue_before_escalation || '0')}</td>
                                                            <td>${convertDecimal(item.boot_tow_fee || '0')}</td>
                                                            <td>${convertDecimal(item.revenue_after_1_escalation || '0')}</td>
                                                            <td>${convertDecimal(item.revenue_after_2_escalation || '0')}</td>
                                                            <td>${convertDecimal(item.collection_fee || '0')}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default CollectionReports