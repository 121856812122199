import { getFormattedDate, formatAmount } from "../../Common/utils";
import { PdfDownloader, ImageDownloader } from "./TicketResourcesComponent";
import "./ticketDetails.css"

const PaymentDetailsTab = ({ paymentBreakdown, payment_detail, refund_detail, citationData }) => {
  if (!paymentBreakdown || Object.keys(paymentBreakdown).length === 0 || !citationData || Object.keys(citationData).length === 0 || !payment_detail || payment_detail.length === 0) {
    return <div className="text-center">No Payment Details Available</div>;
  }

  console.log(payment_detail,'payment')

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title" style={{ fontSize: "1.5rem" }}>Violation Fee Breakdown</h5>
            </div>
            <div className="card-body">
                <div className="tbl-row-mn">
                    {paymentBreakdown?.original_amount !== 0 && <div className="tbl-row-mn-box">
                      <h5>Original Fine Amount:</h5>
                      <h6>{formatAmount(paymentBreakdown?.original_amount)}</h6>
                    </div>
                    }
                    {paymentBreakdown?.collection_fee !== 0 && <div className="tbl-row-mn-box">
                      <h5>Collection Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.collection_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.boot_and_to_fee !== 0 && <div  className="tbl-row-mn-box">
                      <h5>Boot and Tow Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.boot_and_to_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.charge_back_fee !== 0 &&  <div  className="tbl-row-mn-box">
                    <h5>Charge Back Fee:</h5>
                    
                      <h6>{formatAmount(paymentBreakdown?.charge_back_fee)}</h6>
                    </div>}
                    {Object.entries(paymentBreakdown?.escalated_amount).map(([key, value]) => (
                      value !== 0 && <div  className="tbl-row-mn-box">
                        <h5>Escalated {key} Amount:</h5>
                        <h6>{formatAmount(value)}</h6>
                      </div>
                    ))}
                    {paymentBreakdown?.administrative_fee !== 0 && <div  className="tbl-row-mn-box"><h5>Administrative Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.administrative_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.parking_fee !== 0 && <div className="tbl-row-mn-box">
                      <h5>Parking Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.parking_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.nsf_fee !== 0 && <div className="tbl-row-mn-box">
                      <h5>NSF Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.nsf_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.appeal_rejected_fee !== 0 && <div className="tbl-row-mn-box">
                      <h5>Appeal Rejected Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.appeal_rejected_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.hearing_rejected_fee !== 0 && <div className="tbl-row-mn-box">
                      <h5>Hearing Rejected Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.hearing_rejected_fee)}</h6>
                    </div>}
                    {paymentBreakdown?.discount_fee !== 0 && <div className="tbl-row-mn-box">
                      <h5>Discount Fee:</h5>
                      <h6>{formatAmount(paymentBreakdown?.discount_fee)}</h6>
                    </div>}
                    {/* <tr>
                      <td><h6 className="card-subtitle mb-4 text-muted">Service Fee:</h6></td>
                      <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(paymentBreakdown?.service_fee)}</h6></td>
                    </tr> */}
                    {/* {paymentBreakdown?.total_late_fee !== 0 && <tr>
                      <td><h6 className="card-subtitle mb-4 text-muted">Total Late Fee:</h6></td>
                      <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(paymentBreakdown?.total_late_fees)}</h6></td>
                    </tr>} */}
                </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title" style={{ fontSize: "1.5rem" }}>Payment Details</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {payment_detail?.map((detail, index) => (
                    <>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                        
                          <div className="card-body">
                            <div className={`row`} key={index}>
                              <div className="col-md-6">
                                  <table>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Amount:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(detail?.amount)}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Payment Date and Time:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{getFormattedDate(detail?.payment_date_time)}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Payment Mode:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.payment_mode}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Receipt ID:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.receipt_id}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">User Name:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.first_name} {detail?.last_name}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Address:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.address}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Phone:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.phone}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Email:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.email}</h6></td>
                                    </tr>
                                  </table>
                              </div>
                              <div className="col-md-6">
                                  <table>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Service Fee:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(detail?.service_fee)}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Payment Category:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.payment_category}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">SMS Sent:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.sms_sent ? 'Yes' : 'No'}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Email Sent:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.email_sent ? 'Yes' : 'No'}</h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Receipt PDF:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.receipt_pdf && <PdfDownloader pdfUrl={detail?.receipt_pdf} />}
                                      </h6></td>
                                    </tr>
                                    <tr>
                                      <td><h6 className="card-subtitle mb-4 text-muted">Processing Admin:</h6></td>
                                      <td><h6 className="card-subtitle mb-4 text-muted">{detail?.processing_admin_detail?.first_name} {detail?.processing_admin_detail?.last_name}
                                      </h6></td>
                                    </tr>
                          {detail?.payment_mode === "check" && (
                            <>
                              <tr>
                                <td><h6 className="card-subtitle mb-4 text-muted">Check Number:</h6></td>
                                <td><h6 className="card-subtitle mb-4 text-muted">{detail?.check_no}</h6></td>
                              </tr>
                              <tr>
                                <td><h6 className="card-subtitle mb-4 text-muted">Check Applied Date:</h6></td>
                                <td><h6 className="card-subtitle mb-4 text-muted">{getFormattedDate(detail?.check_applied_date)}</h6></td>
                              </tr>
                              <tr>
                                <td><h6 className="card-subtitle mb-4 text-muted">Check Received Date:</h6></td>
                                <td><h6 className="card-subtitle mb-4 text-muted">{getFormattedDate(detail?.check_received_date)}</h6></td>
                              </tr>
                              <tr>
                                <td><h6 className="card-subtitle mb-4 text-muted">Check Image:</h6></td>
                                <td>
                                  <h6 className="card-subtitle mb-4 text-muted">
                                    {detail?.check_image && detail?.check_image.length > 0 && <ImageDownloader imageUrl={detail?.check_image[0]} />}
                                  </h6>
                                </td>
                              </tr>
                            </>
                          )}
                          {detail?.payment_mode === "card" && (
                            <>
                              <tr>
                                <td><h6 className="card-subtitle mb-4 text-muted">Card Number:</h6></td>
                                <td><h6 className="card-subtitle mb-4 text-muted">{detail?.card_number}</h6></td>
                              </tr>
                            </>
                          )}
                        </table>
                              </div>
                            </div>
                            <div className="card-header">
                              <h5 className="card-title" style={{ fontSize: "1rem", marginBottom: '0px' }}>Payment Breakdown</h5>
                            </div>
                              <div className="card-body">
                                <div className={`row`} key={index}>

                                  <div className="tbl-row-mn">
                                    {detail?.collection_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Collection Fee:</h5>
                                      <h6>{formatAmount(detail?.collection_fee)}</h6>
                                    </div>}
                                    {detail?.charge_back_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Charge Back Fee:</h5>
                                      <h6>{formatAmount(detail?.charge_back_fee)}</h6>
                                    </div>}
                                    {detail?.nsf_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>NSF Fee:</h5>
                                      <h6>{formatAmount(detail?.nsf_fee)}</h6>
                                    </div>}
                                    {detail?.boot_and_to_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Boot And Tow Fee:</h5>
                                      <h6>{formatAmount(detail?.boot_and_to_fee)}</h6>
                                    </div>}
                                     {detail?.administrative_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Admin Fee:</h5>
                                      <h6>{formatAmount(detail?.administrative_fee)}</h6>
                                    </div>}
                                     {detail?.parking_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Parking Fee:</h5>
                                      <h6>{formatAmount(detail?.parking_fee)}</h6>
                                    </div>}
                                     {citationData.category !==
                                      "invoice" && detail?.fine_amount !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Fine Amount:</h5>
                                      <h6>{formatAmount(detail?.fine_amount)}</h6>
                                    </div>}
                                    {detail?.escalation_1_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Escalation 1 Fee:</h5>
                                      <h6>{formatAmount(detail?.escalation_1_fee)}</h6>
                                    </div>}
                                    {detail?.escalation_2_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Escalation 2 Fee:</h5>
                                      <h6>{formatAmount(detail?.escalation_2_fee)}</h6>
                                    </div>}
                                    {detail?.escalation_3_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Escalation 3 Fee:</h5>
                                      <h6>{formatAmount(detail?.escalation_3_fee)}</h6>
                                    </div>}
                                    {detail?.appeal_rejected_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Appeal Rejected Fee:</h5>
                                      <h6>{formatAmount(detail?.appeal_rejected_fee)}</h6>
                                    </div>}
                                    {detail?.hearing_rejected_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Hearing Rejected Fee:</h5>
                                      <h6>{formatAmount(detail?.hearing_rejected_fee)}</h6>
                                    </div>}
                                    {detail?.payment_mode === "card" && detail?.service_fee !== 0 && (
                                      <div className="tbl-row-mn-box">
                                        <h5>Service Fee:</h5>
                                        <h6>{formatAmount(detail?.service_fee)}</h6>
                                      </div>
                                    )}
                                    {detail?.discount_fee !== 0 && <div className="tbl-row-mn-box">
                                      <h5>Discount Fee:</h5>
                                      <h6>{formatAmount(detail?.discount_fee)}</h6>
                                    </div>}
                                  </div>

                                  
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title" style={{ fontSize: "1.5rem" }}>Refund Details</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {(!refund_detail || refund_detail.length === 0) ? (
                    <div className="text-center">No refund data available</div>
                  ) :
                    refund_detail.map((detail, index) => (
                      <div className={`row ${index !== 0 ? 'mt-5' : ''}`} key={index}>
                        <div className="col-md-6">
                          <table>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Refund Amount:</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(detail?.refund_details?.refund_amount)}</h6></td>
                            </tr>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Refund Date:</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{getFormattedDate(detail?.refund_details?.refund_date)}</h6></td>
                            </tr>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Reason:</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{detail?.refund_details?.reason}</h6></td>
                            </tr>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Refund Mode:</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{detail?.refund_details?.refund_mode}</h6></td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-md-6">
                          <table>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Refunded To:</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{`${detail?.refund_details?.first_name} ${detail?.refund_details?.last_name}`}</h6></td>
                            </tr>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Check Refund Amount</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(detail?.refund_details?.refund_check_amount)}</h6></td>
                            </tr>
                            <tr>
                              <td><h6 className="card-subtitle mb-4 text-muted">Check No:</h6></td>
                              <td><h6 className="card-subtitle mb-4 text-muted">{detail?.refund_details?.check_no}</h6></td>
                            </tr>
                          </table>
                        </div>
                        <br />
                        <div className="col-md-12">
                          <h5 className="card-subtitle mb-4 text-muted">Transfer Details:</h5>
                          {
                            (!detail?.refund_details?.transfer_detail || detail?.refund_details?.transfer_detail.length === 0) ? (
                              <div>No transfer data available</div>
                            ) : (
                              detail?.refund_details?.transfer_detail.map((transfer, index) => (
                                <table key={index} className={`col-6 ${index !== 0 ? 'mt-3' : ''}`}>
                                  <tr>
                                    <td><h6 className="card-subtitle mb-4 text-muted">Transfer Citation ID:</h6></td>
                                    <td><h6 className="card-subtitle mb-4 text-muted">{transfer.transfer_citation_id}</h6></td>
                                  </tr>
                                  <tr>
                                    <td><h6 className="card-subtitle mb-4 text-muted">Transfer Amount:</h6></td>
                                    <td><h6 className="card-subtitle mb-4 text-muted">{formatAmount(transfer.Amount)}</h6></td>
                                  </tr>
                                  <tr>
                                    <td><h6 className="card-subtitle mb-4 text-muted">Transfer Date:</h6></td>
                                    <td><h6 className="card-subtitle mb-4 text-muted">{getFormattedDate(transfer.transfer_date)}</h6></td>
                                  </tr>
                                </table>
                              ))
                            )
                          }
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsTab;