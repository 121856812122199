import { useEffect, useState } from "react";
import Reportingsetup from "../../Services/main.service";
import { siteid } from "../../Global/site";
const SlideImages = ({
  isLoader,
  setimageId,
  setShowImagesModalSlider,
  setSlideImages,
  ticket_no,
  reportImages,
}) => {
  return (
    <>
      {
      isLoader ? (
        <div className="text-center">
          <div className="spinner-border"></div>
        </div> ):
      reportImages &&
        reportImages?.citations &&
        reportImages?.citations
          .filter((user) => user.header_details.citation_number == ticket_no)
          .map((ele) => {
            return (
              <>
                <div
                  className="acr-many-main"
                  onClick={() => (
                    setimageId(
                      Number(ele.new_images[0].image_spec.split("_")[1])
                    ),
                    setShowImagesModalSlider(true),
                    setSlideImages(ele.new_images)
                  )}
                >
                  {ele.new_images[0] ? (
                        <>
                          <span className="acr-many-images">
                            {ele.new_images.length}
                          </span>
                          <img
                            className="acr-image-wh"
                            src={ele.new_images[0]?.url}
                            alt="citation"
                          />
                        </>
                      ) : "-" }
                </div>
              </>
            );
          })}
    </>
  );
};
export default SlideImages;
