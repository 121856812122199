import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { hasHelicalReports } from "../../../NavComponent/Sidebar";

const HearingReportTable = ({
  reportData,
  tableHeading,
  keySequence,
}) => {
  if (hasHelicalReports()) {
    tableHeading = [...tableHeading, "Hearing Analysis"]
    keySequence = [...keySequence, "id"]
  }
  const [report, setReport] = useState(reportData);

  const [sorting, setSorting] = useState({ field: "", ascending: true });

  useEffect(() => {
    setReport(reportData);
  }, [reportData]);

  useEffect(() => {
    const currentDataCopy = [...report];
    const sortedCurrentdata = currentDataCopy.sort((a, b) => {
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });

    setReport(
      sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse()
    );
  }, [sorting]);

  const applySorting = useMemo(
    () => (key, ascending) => {
      setSorting({ key: key, ascending: ascending });
    },
    [sorting, report]
  );
  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const renderAllData = () => {
    return report?.map((item) => <tr key={item?.id}>{renderData(item)}</tr>);
  };


  const renderData = (element) => {
    const tableElements = [];
    keySequence.map((key, index) => {
      // split the key by the "." character to handle nested keys
      const keyParts = key.split(".");

      // initialize a variable to hold the current value
      let value = element;

      // iterate over the key parts and get the value for each nested key
      for (const keyPart of keyParts) {
        value = value[keyPart];

        // return null if the value is undefined or null
        if (value === undefined || value === null || value === "") {
          tableElements.push(<td key={index}>-</td>);
          return null;
        }

        if (keyPart === "citation_start_timestamp") {
          value =
            moment(value.split("T")[0]).format("MM/DD/YYYY") +
            " " +
            value.substring(11, 19);
          tableElements.push(<td key={index}>{value}</td>);
          return value;
        }
        if (keyPart === "received_at") {
          value = moment(value).format("MM/DD/YYYY");
          tableElements.push(<td key={index}>{value}</td>);
          return value;
        }
        if (keyPart === "date") {
          value =
            moment(value.split("T")[0]).format("MM/DD/YYYY") +
            " " +
            value.substring(11, 19);
          tableElements.push(
            <td key={index}>
              <Link
                to={`/hearing-detail?id=${element.id}&citation_no=${element.ticket_no}`}
              >
                {value}
              </Link>
            </td>
          );
          return value;
        }
        if (keyPart === "payment_done") {
          tableElements.push(<td key={index}>{value.toString()}</td>);
          return value;
        }
        if (keyPart === "ticket_no") {
          tableElements.push(
            <td key={index}>
              <Link
                to={`/ticketreporting?&ticket_no=${element.ticket_no}`}
              >
                {value}
              </Link>
            </td>
          );
          return value;
        }
        if (keyPart === "fine_amount") {
          tableElements.push(
            <td key={index}>{`$${convertDecimal(value.toString()) || "00.00"
              }`}</td>
          );
          return value;
        }
        if (keyPart === "id") {
          tableElements.push(
            <td key={index}>
              <Link to={`/ticketAnalyticsReport/${element.lp_number}`}>
                Hearing Analysis
              </Link>
            </td>
          )
          return value;
        }
      }
      tableElements.push(<td key={index}>{value}</td>);
      return value;
    });
    return tableElements;
  };

  return (
    <>
      <div
        className="report_tbl_scroll fix-page-scroll-only"
        id="tbl-main-download"
      >
        <table
          className="table table-heading table-bordered"
          id="cstm-report-tb-display"
        >
          <thead>
            <tr>
              {tableHeading && tableHeading.length > 0 && tableHeading.map((key, index) => (
                <th
                  className={`srt-tab ${sorting.key === keySequence[index] &&
                    (sorting.ascending ? "srt-ace" : "srt-desc")
                    }`}
                  onClick={() =>
                    applySorting(keySequence[index], !sorting.ascending)
                  }
                >
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderAllData()}</tbody>
        </table>
      </div>
    </>
  );
};

export default HearingReportTable;
