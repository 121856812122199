import {getFormattedDate} from '../../Common/utils';

const AuditTrailTab = ({auditTrail}) => {
  const updateTypeMapping = {
    ticket_address_update: 'Address Update',
    ticket_payment_status: 'Payment Status Update',
    appeal_status_update: 'Appeal Status Update',
    status_update: 'Status Update',
    requested_additional_info: 'Requested Additional Information',
    appeal_remark_added: 'Appeal Remark Added',
    appeal_workflow_status_update: 'Appeal Workflow Status Update',
    fine_amount_update: 'Fine Amount Updated',
    SET_ESCALATION_DETAILS: 'Set Escalation Details',
    FINE_AMOUNT_DATE_UPDATE: 'Fine Amount Update',
    BOOT_TOW_AMOUNT_DATE_UPDATE: 'Boot/Tow Amount Update',
    COLLECTION_AMOUNT_DATE_UPDATE: 'Collection Amount Update',
    STATUS_UPDATE: 'Status Update',
    NSF_AMOUNT_DATE_UPDATE: 'NSF Amount Update',
    REFUND: 'Refund',
  };

  return !auditTrail || auditTrail.length === 0 ? (
    <div className="text-center">No Audit Trail Available</div>
  ) : (
    auditTrail?.map((trail, index) => (
      <div key={index}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12">
                    <h5 className="card-title">
                      {updateTypeMapping[trail?.UpdateType] || trail?.UpdateType}
                    </h5>
                    <div className="text-left">
                      <h6 className="card-subtitle mb-2 text-muted">
                        {getFormattedDate(trail?.Timestamp)}
                      </h6>
                      <h6 className="card-subtitle mb-2 text-muted">
                        {trail?.InitiatorName} ({trail?.InitiatorRole})
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body mb-2">
                <div className="row">
                  <div className="col-6">
                    <h6 className="card-subtitle mb-4 text-muted">
                      <strong>Reason:</strong>{' '}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            trail?.Reason !== undefined &&
                            trail?.Reason !== null &&
                            trail?.Reason,
                        }}
                      />
                    </h6>
                    <h6 className="card-subtitle mb-4 text-muted">
                      <strong>Remark:</strong>{' '}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            trail?.Comment !== undefined &&
                            trail?.Comment !== null &&
                            trail?.Comment,
                        }}
                      />
                    </h6>
                  </div>
                  <div className="col-6">
                    <h6 className="card-subtitle mb-4 text-muted">
                      <strong>Old Value:</strong>{' '}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            trail?.OldValue !== undefined &&
                            trail?.OldValue !== null &&
                            trail?.OldValue,
                        }}
                      />
                    </h6>
                    <h6 className="card-subtitle mb-2 text-muted">
                      <strong>New Value:</strong>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            trail?.NewValue !== undefined &&
                            trail?.NewValue !== null &&
                            trail?.NewValue,
                        }}
                      />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    ))
  );
};

export default AuditTrailTab;
