import { useEffect } from "react";

let Tools = [
  "Permit-Duration",
  "Address-Details",
  "Permit-Quantity-Plate-Details",
  "HandTag-Id",
  "User-Details",
  "Upload-Files",
  "Signature",
  "TextBox",
  "Textarea",
  "Dropdown",
  "DatePicker",
  "CheckBox",
  "Radio",
];

const dragField = (e, types) => {
  e.dataTransfer.setData("dragField", types);
}

const ToolBar = ({ permitTitle }) => {
  
  // useEffect(() => {
  //   if (permitTitle === "Visitor Permit") {
  //     Tools.push('Address-Details','User-Details')
  //   }
  // },[])

  return (
    <>
      <div className="configure-permit-sidebar">
          <div  className="configure-permit-sidebar-title">components</div>
          <div className="configure-permit-sidebar-title-body">
            <ul>
              {Tools.map((types) => {
                return (
                  <li
                    data-tool={types}
                    onDragStart={(e) => dragField(e, types)}
                    key={types}
                    id={types}
                    className={` singleField ${types}`}
                    draggable={true}
                  >
                    {types.replaceAll("-", " ")}
                  </li>
                );
              })}
            </ul>
          </div>
      </div>


    </>
  );
};

export default ToolBar;
