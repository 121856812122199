import React, { useEffect, useState, useRef } from "react";
import { siteid } from "../../Global/site";
import { useDispatch } from "react-redux";
import mainService from "../../Services/main.service";
import { refreshTokenMiddleware } from "../customMiddelware";
import { ReactMultiEmail } from "react-multi-email";
import QuillEditor from "../Common/QuillEditor";
import BackbuttinIMag from "../../Images/back.png";
import "react-multi-email/dist/style.css";
import ImageSlider from "../../utile/imageSlider/imageSlider";

export default function NoticeTemplateTinyMCE({
  setCreateForm,
  noticeTemplateData,
}) {
  const showSideId = siteid();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const [notiInput, setNotiInput] = useState({
    notification_name: "",
    site_id: showSideId.siteinfo,
    email_recipients: [],
    metadata: {
      template_id: "",
      from: "",
      subject: "",
      body: "",
      frequency: "Event Driven",
      notification_type: "",
      status: "Activated",
      logo: "",
    },
  });

  const [editorHTML, setEditorHTML] = useState("");
  const [notiErrors, setNotiErrors] = useState({
    notification_name: "",
    email_recipients: [],
    metadata: {
      template_id: "",
      from: "",
      subject: "",
      body: "",
      notification_type: "",
      status: "",
    },
  });
  const[showImagesModalSlider,setShowImagesModalSlider]=useState(false)
  const [imageId, setimageId] = useState(0);
  const [arrow, setArrow] = useState(true);
  const [showErrorMsg, setErrorMsg] = useState("");
  const [logoPreview, setLogoPreview] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isNotiTemplEdit, setNotiTemplEdit] = useState(false);
  const [isLoadingEditor, setIsLoadingEditor] = useState(false);
  const showOptions = [
    "Integration Vendor Latency Notification",
    "No Permit Notification",
    "No Scofflaw Notification",
    "Daily Transaction Summary Notification",
    "Late Fee Notification",
    "Create Appeal Notification",
    "Additional documentation notification",
    "Appeal Status Notification",
    "Additional Documentation Notification",
    "Scofflaw Notification",
    "Payment Notification",
    "Payment Declined Notification",
    "Hearing Notification",
    "No Payment Notification",
    "Partial Payments Notification",
    "No Funds in the Account Notification",
    "Appeal Submit Notification",
  ];
  const mergeTagList = [
    {
      value: "UserName",
      title: "User Name",
    },
    {
      value: "UserAddress",
      title: "User Address",
    },
    {
      value: "UserCity",
      title: "User City",
    },
    {
      value: "UserState",
      title: "User State",
    },
    {
      value: "UserZip",
      title: "User Zip",
    },
    {
      value: "NoticeDate",
      title: "Notice Date",
    },
    {
      value: "TotalAmountDue",
      title: "Total Amount Due",
    },
    {
      value: "TicketNumber",
      title: "Ticket Number",
    },
    {
      value: "VehicleNo",
      title: "Vehicle No",
    },
    {
      value: "VehicleMake",
      title: "Vehicle Make",
    },
    {
      value: "CitationDateTime",
      title: "Citation Date Time",
    },
    {
      value: "AppealDateTime",
      title: "Appeal Date Time",
    },
    {
      value: "OfficerBadge",
      title: "Officer Badge",
    },
    {
      value: "TicketLocation",
      title: "Ticket Location",
    },
    {
      value: "UserContact",
      title: "User Contact",
    },
    {
      value: "UserEmail",
      title: "User Email",
    },
    {
      value: "LastPaymentDate",
      title: "Last Payment Date",
    },
    {
      value: "LateFee",
      title: "Late Fee",
    },
    {
      value: "Reason",
      title: "Reason",
    },
    {
      value: "ViolationCode",
      title: "Violation Code",
    },
    {
      value: "otp",
      title: "OTP",
    },
  ];

  useEffect(() => {
    if (noticeTemplateData !== undefined) {
      setNotificationTemplateData(noticeTemplateData);
    }
    setTimeout(() => {
      setIsLoadingEditor(true);
    }, 2000);
  }, []);

  // getting all data from props
  function setNotificationTemplateData(templ) {
    setNotiErrors({
      ...notiErrors,
      notification_name: "",
      email_recipients: [],
      metadata: {
        template_id: "",
        from: "",
        subject: "",
        body: "",
        notification_type: "",
        status: "",
      },
    });
    setNotiTemplEdit(true);
    setNotiInput({
      ...notiInput,
      notification_name: templ.notification_name,
      site_id: showSideId.siteinfo,
      email_recipients: templ.email,
      metadata: {
        template_id: templ.template_id,
        from: templ.from,
        subject: templ.subject,
        body: templ.body,
        frequency: templ.frequency,
        notification_type: templ.notification_type,
        status: templ.status,
        logo: "",
      },
    });
    setLogoPreview(templ.logo);
    setEditorHTML(templ.body);
  }
  // onChange Event Form
  const onChangeNotificationInputMetaData = (e) => {
    setErrorMsg("");
    const { name, value } = e.target;
    const reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    if (name === "from" && (!value || !reg.test(value))) {
      setNotiErrors({
        ...notiErrors,
        metadata: { ...notiErrors.metadata, [name]: `Enter valid from email` },
      });
    } else {
      if (value) {
        setNotiErrors({
          ...notiErrors,
          metadata: { ...notiErrors.metadata, [name]: "" },
        });
      } else {
        setNotiErrors({
          ...notiErrors,
          metadata: {
            ...notiErrors.metadata,
            [name]: `Enter ${name.replace(/_/g, " ")}`,
          },
        });
      }
    }

    setNotiInput({
      ...notiInput,
      metadata: { ...notiInput.metadata, [name]: value },
    });
  };

  // onSubmit Event to Submit data
  const handleNotificationsubmit = async (e) => {
    e.preventDefault();
    setNotiInput({
      ...notiInput,
      metadata: {
        ...notiInput.metadata,
        ["body"]: editorHTML,
      },
    });
    let isError = false;
    Object.entries(notiInput).forEach(([key, value]) => {
      if (key !== "metadata" && key !== "email_recipients" && !value) {
        isError = true;
        notiErrors[key] = `Enter ${key.replace(/_/g, " ")}`;
      } else if (key === "email_recipients" && value.length === 0) {
        isError = true;
        notiErrors[key] = `Enter ${key.replace(/_/g, " ")}`;
      } else if (key === "metadata") {
        Object.entries(notiInput.metadata).forEach(([key, value]) => {
          if (!value && key === "logo") {
            if (!logoPreview) {
              isError = true;
              notiErrors.metadata[key] = `Select ${key.replace(/_/g, " ")}`;
            }
          } else if (
            !value &&
            key === "template_id" &&
            key === "notification_type"
          ) {
            isError = true;
            notiErrors.metadata[key] = `Enter ${key.replace(/_/g, " ")}`;
          }
        });
      }
    });
    setNotiErrors({ ...notiErrors });
    if (!isError) {
      setBtnLoading(true);

      // return
      if (notiInput.metadata.logo) {
        let finalLogo = "";
        const formData = new FormData();
        formData.append("files", notiInput.metadata.logo);
        formData.append("data", '["LogoImage_1"]');
        formData.append("upload_type", "LogoImage");

        initalbulkfun();
        async function initalbulkfun() {
          try {
            const result1 = await mainService.staticBulkUploadAPI(formData);
            if (result1.data.status) {
              finalLogo = result1.data.data[0].response.links[0];

              const formData2 = {
                download_type: "LogoImage",
                links: {
                  img_1: finalLogo,
                },
                site_id: showSideId.siteinfo,
              };
              initialdownloadfun();
              async function initialdownloadfun() {
                try {
                  const result2 = await mainService.downloadUploadDataAPI(
                    formData2
                  );
                  if (result2.data.status) {
                    finalLogo = result2.data.metadata[0].url;
                    let payload = { ...notiInput };
                    payload.metadata.logo = finalLogo;
                    payload.metadata.template_id = "";
                    // payload.metadata.template_id = Number(
                    //   notiInput.metadata.template_id
                    // );

                    initialupdatenotifun();
                    async function initialupdatenotifun() {
                      try {
                        let result;
                        if (isNotiTemplEdit) {
                          result =
                            await mainService.updateNotificationTemplateAPI(
                              payload
                            );
                        } else {
                          result = await mainService.postNotificationAPI(
                            payload
                          );
                        }
                        if (result.data.status) {
                          setBtnLoading(false);
                          setNotiTemplEdit(false);
                          e.target.reset();
                          setCreateForm(false);
                        }
                      } catch (err) {
                        if (err.response.status === 401) {
                          refreshTokenMiddleware(dispatch);
                          initialupdatenotifun();
                        } else {
                          setBtnLoading(false);
                          setNotiTemplEdit(false);
                          setErrorMsg(err.response.data.message);
                        }
                      }
                    }
                  }
                } catch (err) {
                  if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch);
                    initialdownloadfun();
                  } else {
                    setBtnLoading(false);
                    setNotiTemplEdit(false);
                  }
                }
              }
            } else {
              setBtnLoading(false);
              setNotiTemplEdit(false);
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              initalbulkfun();
            } else {
              setBtnLoading(false);
              setNotiTemplEdit(false);
            }
          }
        }
      } else if (isNotiTemplEdit) {
        let payload = { ...notiInput };
        payload.metadata.logo = logoPreview;
        payload.metadata.template_id = Number(notiInput.metadata.template_id);
        payload.metadata.body = editorHTML;

        initialupdatenotitempfun();
        async function initialupdatenotitempfun() {
          try {
            let result = await mainService.updateNotificationTemplateAPI(
              payload
            );

            if (result.data.status) {
              setBtnLoading(false);
              setNotiTemplEdit(false);
              e.target.reset();
              setCreateForm(false);
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              initialupdatenotitempfun();
            } else {
              setBtnLoading(false);
              setErrorMsg(err.response.data.message);
            }
          }
        }
      }
    }
  };

  // onChange Event Select
  const onChangeNotificationInput = (e) => {
    setErrorMsg("");
    const { name, value } = e.target;
    setNotiInput({ ...notiInput, [name]: value });
    if (value) {
      setNotiErrors({ ...notiErrors, [name]: "" });
    } else {
      setNotiErrors({
        ...notiErrors,
        [name]: `Enter ${name.replace(/_/g, " ")}`,
      });
    }
  };

  // Image Handling
  const handleLogoUpload = (e) => {
    const targetFiles = e.target.files[0];
    const url = URL.createObjectURL(targetFiles);
    setLogoPreview(url);
    setNotiInput({
      ...notiInput,
      metadata: { ...notiInput.metadata, ["logo"]: targetFiles },
    });
    setNotiErrors({
      ...notiErrors,
      metadata: { ...notiErrors.metadata, ["logo"]: "" },
    });
  };

  // onClick back button click
  const onClickBack = () => {
    setNotiInput({
      notification_name: "",
      site_id: showSideId.siteinfo,
      email_recipients: [],
      metadata: {
        template_id: "",
        from: "",
        subject: "",
        body: "",
        frequency: "Event Driven",
        notification_type: "",
        status: "Activated",
        logo: "",
      },
    });
    return setCreateForm(false);
  };
  const logoPreviewString = [{image_spec: '0',url:logoPreview}];


  return (
    <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
      <div className="col-12 back-link cstm-back-citationsearchresult">
        <span onClick={onClickBack}>
          <img src={BackbuttinIMag} alt="img" className="img-fluid" />
          Back
        </span>
      </div>
      <div className="csmt-notice-template">
        <div className="container-fluid">
          <div id="notficationTemplateMain">
            <h4>{isNotiTemplEdit ? "Update" : "Create"} Notice Template</h4>
          </div>

          <div className="">
            <form onSubmit={handleNotificationsubmit}>
              <div className="row">
                <div className="col-6 form-group">
                  <label>Notification name</label>
                  <div className="form-group">
                    <select
                      name="notification_name"
                      className="form-control cstm-appeal-place ar-olny"
                      onChange={onChangeNotificationInput}
                      value={notiInput.notification_name}
                    >
                      <option value="">Select notification name</option>
                      {showOptions.map((items, index) => (
                        <option key={index} value={items}>
                          {items}
                        </option>
                      ))}
                    </select>
                    <span className="errormessage">
                      {notiErrors.notification_name}
                    </span>
                  </div>
                </div>
                <div className="col-6 form-group">
                  <label>Frequency</label>
                  <select
                    name="frequency"
                    className="form-control cstm-appeal-place ar-olny"
                    placeholder="Select frequency"
                    onChange={onChangeNotificationInputMetaData}
                    value={notiInput.metadata.frequency}
                  >
                    <option value="Event Driven">Event Driven</option>
                    <option value="Interval based (in minutes)">
                      Interval based (in minutes)
                    </option>
                    <option value="Daily">Daily</option>
                    <option value="Once">Once</option>
                  </select>
                </div>
                {/* <div className="col-6 form-group">
                  <label>Template id</label>
                  <input
                    type="text"
                    name="template_id"
                    className="form-control cstm-appeal-place"
                    placeholder="Enter template id"
                    onChange={onChangeNotificationInputMetaData}
                    value={notiInput.metadata.template_id}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="errormessage">
                    {notiErrors.metadata.template_id}
                  </span>
                </div> */}
              </div>

              <div className="row">
                {/* <div className="col-6 form-group">
                  <label>Notification Type</label>
                  <input
                    type="text"
                    name="notification_type"
                    className="form-control cstm-appeal-place"
                    placeholder="Enter notification type"
                    onChange={onChangeNotificationInputMetaData}
                    value={notiInput.metadata.notification_type}
                  />
                  <span className="errormessage">
                    {notiErrors.metadata.notification_type}
                  </span>
                </div> */}
              </div>
              <div className="row">
                <div className="col-6 form-group">
                  <label>Status</label>
                  <select
                    name="status"
                    className="form-control cstm-appeal-place ar-olny"
                    placeholder="Select status"
                    onChange={onChangeNotificationInputMetaData}
                    value={notiInput.metadata.status}
                  >
                    <option value="Activated">Activated</option>
                    <option value="DeActivated">DeActivated</option>
                  </select>
                </div>
                <div className="col-6 form-group">
                  <label>Email recipients</label>
                  <ReactMultiEmail
                    placeholder="Enter email recipients"
                    className="form-control cstm-appeal-place"
                    emails={notiInput.email_recipients}
                    onChange={(_emails) => {
                      setNotiErrors({
                        ...notiErrors,
                        ["email_recipients"]: "",
                      });
                      setNotiInput({
                        ...notiInput,
                        ["email_recipients"]: _emails,
                      });
                    }}
                    autoFocus={true}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  <span className="errormessage">
                    {notiErrors.email_recipients}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    className="form-control cstm-appeal-place"
                    placeholder="Enter subject"
                    onChange={onChangeNotificationInputMetaData}
                    value={notiInput.metadata.subject}
                  />
                  <span className="errormessage">
                    {notiErrors.metadata.subject}
                  </span>
                </div>
                <div className="col-6 form-group">
                  <label>From</label>
                  <input
                    type="text"
                    name="from"
                    className="form-control cstm-appeal-place"
                    placeholder="Enter from"
                    onChange={onChangeNotificationInputMetaData}
                    value={notiInput.metadata.from}
                  />
                  <span className="errormessage">
                    {notiErrors.metadata.from}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label>Upload Logo</label> <br />
                <input
                  type="file"
                  name="logo"
                  onChange={handleLogoUpload}
                  accept=".jpeg,.png,.jpg,.pjp,.webp,.apng"
                  className="cstm-appeal-place"
                />
              </div>

              <span className="errormessage">{notiErrors.metadata.logo}</span>
              {logoPreview && (
                <div className="cstm-appeal-preview-images cstm-appealreportingdetail-img"style={{cursor:"pointer"}}>
                  <div className={`appeal-preview-img`}>
                    <img src={`${logoPreview}`} alt={`previewlogo`} onClick={(()=>{setShowImagesModalSlider(true)})}/>
                  </div>
                </div>
              )}

          {showImagesModalSlider && (
            <div
              className="main-body-modal cstm-ticket-details-pop"
            >
              <div className="main-modal">
                <div className="cstm-close">
                  <button onClick={() => setShowImagesModalSlider(false)}>
                    ×
                  </button>
                </div>
                <div className="cstm-modal-body">
                  <div className="modal-body">
                    <div className="text-center cstm-ticket-details-slider">
                      <ImageSlider slides={logoPreviewString} index={imageId} arrow={arrow}/>
                      {/* <img src={`${logoPreview}`} alt={`previewlogo`}/> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

              {isLoadingEditor ? (
                <div className="form-group cstm-editor-cls">
                  <label>Email body</label>
                  <br />
                  <QuillEditor
                    ref={editorRef}
                    value={editorHTML}
                    onChange={(content) => setEditorHTML(content)}
                  />
                </div>
              ) : (
                <div className="text-center spin-load-main">
                  <div className="spinner-border"></div>
                </div>
              )}
              {showErrorMsg !== "" && (
                <div>
                  <span style={{ color: "red" }}>{showErrorMsg}</span>
                </div>
              )}
              <div className="cstm-ct-btn button2">
                <button className="btn" type="submit" disabled={isBtnLoading}>
                  {isBtnLoading && (
                    <div className="cstm-loader">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  )}
                  {isNotiTemplEdit ? "Update Template" : "Create Template"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
