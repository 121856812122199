import React, { useState, useEffect } from 'react'; 
import { Doughnut } from 'react-chartjs-2';
import Cookiesremove from '../../../Cookiesremove';
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service"; 

function PermitUtilizationCount() {

    const [count, setCount] = React.useState(0);
    const [showAuthToken, setAuthToken] = useState(false);
    const [showPermitCount, setPermitCount] = useState([]);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () =>{
      setErrorHandling(false)
    }

    const onClickLogin = (e) =>
    {
      e.preventDefault();
      Cookiesremove();
      window.location.href = "/";
    }
    
    const average_val = showPermitCount.avg_permit_utilization - 100;

    const data = {
        // labels: ['Red'],
        datasets: [
          {
             data: [ showPermitCount.avg_permit_utilization, average_val ],
      
            backgroundColor: [
              '#FA5944',
              '#F1F1F1', 
            ],
            borderWidth: 1,
          },
        ],
      };
      
      const options = {
        cutout: 80
      }
   
    // Display permit utilization total count
    useEffect(() => { 
       
        Dashboard.getPermitCount().then(
            (response) => {
                if(response.data.status === 300) {
                    setAuthToken(true) 
                 }else{
                  if(response.data.status === false){
                    setErrorHandling(true)
                    setErrorHandleMessage(response.data.message)
                }else{
                  setPermitCount(response.data.data[0].metadata)
                }
              }
            }
          )

        setCount(100);
       }, []);

    return (
        <>

        <div className="cstm-od-rtp-left">Permit Utilization</div>
        <div className="cstm-permit-ptilizatio-main"> 
			      <div className="cstm-permit-ptilizatio-main-left">
				  
				     <div className="cstm-permit-ptilizatio-main-left-main">
					      <span className="cstm-ppml-ttl">Total Permits</span>
						  <span className="cstm-ppml-cont ttl_count_blck">{showPermitCount.total_permits}</span>
					 </div>
					 
              <div className="cstm-permit-ptilizatio-main-left-main">
                    <span className="cstm-ppml-ttl">Permit Utilization</span>
                  <span className="cstm-ppml-cont ttl_count_green">{showPermitCount.permit_utilization}</span>
              </div>
              
                <div className="cstm-permit-ptilizatio-main-left-main">
                    <span className="cstm-ppml-ttl">Avg. Permit Utilization</span>
                  <span className="cstm-ppml-cont ttl_count_red">{parseFloat(showPermitCount.avg_permit_utilization).toFixed(2)}</span>
              </div>
              
              </div>
              <div className="cstm-permit-ptilizatio-main-right"> 
              <div className="cstm_permit_rght_canvas">
              <Doughnut  options={options} data={data} />
              </div>
              </div>
        </div>

         

        </>
    );
}

export default PermitUtilizationCount;
