import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown';
import { Bar } from 'react-chartjs-2'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import mainService from "../../Services/main.service";
import { changetotal } from "../../Global/site";


//images
import transfer from '../../Images/transfer.png';
import FilterExportImg from '../../Images/export.png'
import dropdownarrow from '../../Images/dropdown-arrow.svg';
import { refreshTokenMiddleware } from "../customMiddelware";
import PrintPdfCsvExcelDownload from "../Utilies/PrintPdfCsvExcelDownload";


function pad2(n) {
    return (n < 10 ? '0' : '') + n;
}


const NoticeCountReport = () => {

    const dispatch = useDispatch()
    const changeTotal = changetotal();
    const [recordList, setRecordList] = useState([])
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [filterDate, setFilterDate] = useState({
        created_ts_from: new Date().setHours(0, 0, 0),
        created_ts_to: new Date().setDate(new Date().getDate() + 1)
    });


    const [sorting, setSorting] = useState({ field: 'xyz', ascending: true })

    useEffect(() => {
        const currentDataCopy = { ...recordList }
        if (currentDataCopy?.table_data) {
            const sortedCurrentdata = currentDataCopy?.table_data.sort((a, b) => {
                return a[sorting.key].toString().localeCompare(b[sorting.key].toString());
            });
            setRecordList({ ...recordList, ['table_data']: sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse() });
        }
    }, [sorting]);

    const applySorting = (key, ascending) => {
        setSorting({ key: key, ascending: ascending });
    }


    useEffect(() => {

        let query_string = ''
        if (filterDate.created_ts_from) {
            query_string += 'created_ts_from=' + convertFilterFormatData(filterDate.created_ts_from)
        }
        if (filterDate.created_ts_to) {
            query_string += '&created_ts_to=' + convertFilterFormatData(filterDate.created_ts_to)
        }

        getTableRecord(query_string)
    }, [])

    const getTableRecord = async (query_string) => {
        setLoader(true)
        try {
            const result = await mainService.noticecountAPIReport(query_string)

            if (result.data.status && result.data.length > 0) {
                setRecordList(result.data.data)
            } else {
                setMessage('No Record Found')
            }
            setLoader(false)
        } catch (err) {
            if (err.response.status === 401) {
                refreshTokenMiddleware(dispatch)
                getTableRecord(query_string)
            } else {
                setMessage('No Record Found')
                setLoader(false)
            }
        }
    }


    const onChangeFilterDate = (date, name) => {
        const newFilterDate = { ...filterDate };
        if (!date) {
          newFilterDate['created_ts_to'] = "";
          setFilterDate(newFilterDate);
          return;
        }
        newFilterDate[name] = date;
      const timeValue = new Date(date);
      const hours = timeValue.getHours();
      const minutes = timeValue.getMinutes();
        if (name === 'created_ts_to') {
            const currentFilterToDate = new Date(filterDate.created_ts_to);
          const isDateChange = !filterDate?.created_ts_to || (hours == 0 && minutes == 0) || date?.getDate() !== currentFilterToDate.getDate();
          if(isDateChange){
            let formattedDate = new Date(date);
            formattedDate.setHours(23, 59, 59, 999);
            newFilterDate['created_ts_to'] = formattedDate;
          }
          setFilterDate(newFilterDate);
        } else {
          setFilterDate(newFilterDate);
        }
      };

    const handleFilterSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        setRecordList([])
        let query_string = ''

        if (filterDate.created_ts_from) {
            query_string += 'created_ts_from=' + convertFilterFormatData(filterDate.created_ts_from)
        }
        if (filterDate.created_ts_to) {
            query_string += '&created_ts_to=' + convertFilterFormatData(filterDate.created_ts_to)
        }

        getTableRecord(query_string)

    }

    function convertFilterFormatData(dt) {
        var date = new Date(dt);
        var year = date.getFullYear();
        var month = pad2(date.getMonth() + 1);
        var todayDate = pad2(date.getDate());

        var currentTimeInSeconds = year + '-' + month + '-' + todayDate + "T" + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":00.000Z";

        return currentTimeInSeconds
    }



    return (
        <>
            <div className='col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel'>

                <div className="cstm-ticket-manage-sec">
                    <div className="cstm-ticket-manage-heading">
                        <h3 className="cstm-all-pg-ttl operate-ttl">Notice Count Report</h3>
                    </div>

                    <PrintPdfCsvExcelDownload tableId='tbl-main-download' fileName='notice-count-report' pageSize='a2' />

                </div>
                <div className="report-searchbar">
                    <form>
                        <div className="report-date report-mr">
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                placeholderText='MM/DD/YYYY HH:MM'
                                autoComplete='off'
                                className="cstm-pull-details schedule-date-input"
                                name="created_ts_from"
                                selected={filterDate.created_ts_from}
                                onChange={date => onChangeFilterDate(date, 'created_ts_from')}
                            />
                            <img src={transfer} alt="transfer" className="date-transfer" />
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                placeholderText='MM/DD/YYYY HH:MM'
                                autoComplete='off'
                                dateFormat="MM/dd/yyyy h:mmaa"
                                timeFormat="HH:mm"
                                className="cstm-pull-details schedule-date-input"
                                name="created_ts_to"
                                selected={filterDate.created_ts_to}
                                onChange={date => onChangeFilterDate(date, 'created_ts_to')}
                            />
                        </div>
                        {/* <div className="report-licence report-mr">
                            <input type="text" className="block-button cstm-ct-zc" name='notice' placeholder='Notice' />
                        </div> */}

                        {/* <button className="filter-box report-mr" onClick={handleFilterChange}><img src={FilterExportImg} alt="FilterExportImg" className="img-fluid" /></button> */}
                        <button className="licence-print report-mr" onClick={handleFilterSubmit}>Submit</button>
                    </form>
                </div>
                <br />
                {loader ?
                    <div className='text-center spin-load-main'>
                        <div className="spinner-border"></div>
                    </div>
                    :
                    (recordList === null || recordList?.length === 0 ?
                        <div className='no-record-found-main'>{message || 'No Record Found'}</div>
                        :
                        <div>
                            <div className="col-md-12">
                                <div className="ticket-graph-box">
                                    <div className="ticket-graph-subttl">
                                        <h4>Bar Graph</h4>
                                        <div className="ticket-graph-img">
                                            <Bar width={250} height={70} data={{
                                                labels: ['Appeal Submit', 'Citation Dismissed', 'Citation Liable', 'Citation Partially Liable', 'Drive Away', 'Escalation', 'Illegal Parking', 'Late Appeal', 'Late Pre Collection', 'Returned Check'],
                                                datasets: [{
                                                    label: '',
                                                    barPercentage: 0.5,
                                                    barThickness: 40,
                                                    maxBarThickness: 30,
                                                    minBarLength: 8,
                                                    data: [recordList.appeal_submit_notification, recordList.citation_dismissed_notification, recordList.citation_liable_notification, recordList.citation_partially_liable_notification, recordList.drive_away_notification, recordList.escalation_notification, recordList.illegal_parking_notification, recordList.late_appeal_notification, recordList.late_pre_collection_notification, recordList.returned_check_notification],
                                                    backgroundColor: [
                                                        '#FFB34D',
                                                        '#FF0000',
                                                        '#029279',
                                                        '#8d6f35',
                                                        '#044271',
                                                        '#d24eff',
                                                        '#ff0c73',
                                                        '#ff5722',
                                                        '#cddc39',
                                                        '#673ab7'
                                                    ],
                                                    borderWidth: 1
                                                }]
                                            }}

                                                options={{
                                                    responsive: true,
                                                    title: { text: "THICCNESS SCALE", display: true },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                beginAtZero: true
                                                            }
                                                        }],
                                                        xAxes: [{
                                                            categorySpacing: 0
                                                        }]
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div id="Dashboard" className="tab-pane active">
                                <div className='text-center'>

                                    <div className="ctsm-table-scroll">
                                        <div className="table-overflow" id='tbl-main-download'>
                                            <table className="table-heading table table-bordered" id='cstm-report-tb-display'>
                                                <thead>
                                                    <tr>
                                                        <th className={`srt-tab ${sorting.key === 'date' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('date', !sorting.ascending)}>Date</th>
                                                        <th className={`srt-tab ${sorting.key === 'appeal_submit_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('appeal_submit_notification', !sorting.ascending)}>Appeal Submit</th>
                                                        <th className={`srt-tab ${sorting.key === 'citation_dismissed_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('citation_dismissed_notification', !sorting.ascending)}>{changetotal} Dismissed</th>
                                                        <th className={`srt-tab ${sorting.key === 'citation_liable_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('citation_liable_notification', !sorting.ascending)}>{changetotal} Liable</th>
                                                        <th className={`srt-tab ${sorting.key === 'citation_partially_liable_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('citation_partially_liable_notification', !sorting.ascending)}>{changetotal} Partially Liable</th>
                                                        <th className={`srt-tab ${sorting.key === 'drive_away_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('drive_away_notification', !sorting.ascending)}>Drive Away</th>
                                                        <th className={`srt-tab ${sorting.key === 'escalation_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('escalation_notification', !sorting.ascending)}>Escalation</th>
                                                        <th className={`srt-tab ${sorting.key === 'illegal_parking_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('illegal_parking_notification', !sorting.ascending)}>Illegal Parking</th>
                                                        <th className={`srt-tab ${sorting.key === 'late_appeal_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('late_appeal_notification', !sorting.ascending)}>Late Appeal</th>
                                                        <th className={`srt-tab ${sorting.key === 'late_pre_collection_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('late_pre_collection_notification', !sorting.ascending)}>Late Pre Collection</th>
                                                        <th className={`srt-tab ${sorting.key === 'returned_check_notification' && (sorting.ascending ? 'srt-ace' : 'srt-desc')}`} onClick={() => applySorting('returned_check_notification', !sorting.ascending)}>Returned Check</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!loader &&
                                                        <>
                                                            {(recordList.table_data || []).map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>{item.date}</td>
                                                                    <td>{item.appeal_submit_notification || '0'}</td>
                                                                    <td>{item.citation_dismissed_notification || '0'}</td>
                                                                    <td>{item.citation_liable_notification || '0'}</td>
                                                                    <td>{item.citation_partially_liable_notification || '0'}</td>
                                                                    <td>{item.drive_away_notification || '0'}</td>
                                                                    <td>{item.escalation_notification || '0'}</td>
                                                                    <td>{item.illegal_parking_notification || '0'}</td>
                                                                    <td>{item.late_appeal_notification || '0'}</td>
                                                                    <td>{item.late_pre_collection_notification || '0'}</td>
                                                                    <td>{item.returned_check_notification || '0'}</td>
                                                                </tr>
                                                            ))}
                                                            <tr className='not-srt'>
                                                                <td>Total</td>
                                                                <td>{recordList.appeal_submit_notification}</td>
                                                                <td>{recordList.citation_dismissed_notification}</td>
                                                                <td>{recordList.citation_liable_notification}</td>
                                                                <td>{recordList.citation_partially_liable_notification}</td>
                                                                <td>{recordList.drive_away_notification}</td>
                                                                <td>{recordList.escalation_notification}</td>
                                                                <td>{recordList.illegal_parking_notification}</td>
                                                                <td>{recordList.late_appeal_notification}</td>
                                                                <td>{recordList.late_pre_collection_notification}</td>
                                                                <td>{recordList.returned_check_notification}</td>
                                                            </tr>
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default NoticeCountReport