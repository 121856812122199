
import { TICKET_SEARCH_QUERY } from "./types";

export const ticketsearchquery = (data) => (dispatch) => {
    dispatch({
        type: TICKET_SEARCH_QUERY,
        payload: data
    });
};
export const updateTicketSearchQuery = (query) => ({
    type: TICKET_SEARCH_QUERY,
    payload: query,
  });

