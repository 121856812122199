import { useState, useEffect, useRef } from "react";
import { changeticket } from "../../Global/site";
import ReportingSetup from "../../Services/main.service";
import ImageSlider from "../../utile/imageSlider/imageSlider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditCitationModal = ({
  setShowEditCitationModal,
  selectedCitation,
  onSubmitButtonClick,
  slideImages,
}) => {
  const changeTicket = changeticket();
  const [citationData, setCitationData] = useState({
    citation_id: "",
        ticket_no: "",
        block: "",
        lp_number: "",
        street: "",
        state: "",
        citation_start_timestamp: "",
        remarks: "",
        remark_1: "",
        remark_2: "",
        note_1: "",
        note_2: "",
        description: "",
        code: ""
  });
  const [streetList, setStreetList] = useState([]);
  const [violationCode, setViolationCode] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [showError, setShowError] = useState(false);
  const [fieldError, setFieldError] = useState({ remarks: "" });
  const [imageId, setimageId] = useState();
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);

  useEffect(() => {
    if(selectedCitation) {
    setCitationData({
        citation_id: selectedCitation.citation_id,
        ticket_no: selectedCitation.ticket_no,
        block: selectedCitation.block,
        lp_number: selectedCitation.lp_number,
        street: selectedCitation.street,
        state: selectedCitation.state,
        citation_start_timestamp: selectedCitation.citation_start_timestamp,
        remarks: "",
        remark_1: selectedCitation.remark_1,
        remark_2: selectedCitation.remark_2,
        note_1: selectedCitation.note_1,
        note_2: selectedCitation.note_2,
        description: selectedCitation.description,
        code: selectedCitation.code,
      })
    }
  },[selectedCitation])

  const sliderPopupRef = useRef(null);

  useEffect(() => {
    if (sliderPopupRef) {
      sliderPopupRef?.current?.focus();
    }
  }, [showImagesModalSlider]);

  useEffect(() => {
    var passDataStateList = {
      type: "StateList",
      shard: 1,
    };
    ReportingSetup.violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setStreetList(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    let passDataViolation = {
      type: "ViolationList",
      shard: 1,
    };
    ReportingSetup.violationCode(passDataViolation)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setViolationCode(Response.data.data[0].response);
        }
      })
      .catch((err) => {});

    var passDataRemarks = {
      type: "RemarksList",
      shard: 1,
    };
    ReportingSetup.violationCode(passDataRemarks)
      .then((Response) => {
        if (Response.data.status === 300) {
        } else {
          setRemarks(Response.data.data[0].response);
        }
      })
      .catch((err) => {});
  }, []);

  const editCitationHandler = (e) => {
    e.preventDefault();
    if (citationData.remarks === "") {
      setShowError(true);
      setFieldError({ ...fieldError, remarks: "Field is required" });
    } else {
      setShowError(false);
      ReportingSetup.updateCitation([citationData]).then((response) => {
        if (response.status === 200) {
          onSubmitButtonClick();
          setShowEditCitationModal(false);
        }
      });
    }
  };

  return (
    <>
      <div className="main-body-modal edit-citationonly-popup">
        <div className="main-modal">
          <div className="cstm-close">
            <button onClick={() => setShowEditCitationModal(false)}>×</button>
          </div>
          <h4>Edit {changeTicket}</h4>
          <div className="edit-citationonly-body edit_citationonly_hight">
            <form onSubmit={(e) => editCitationHandler(e)}>
              <div class="row">
                <div class="col-12 cstm-edit-img-main">
                  {slideImages &&
                    slideImages.length > 0 &&
                    slideImages.map((item) => (
                      <div
                        className="cstm-edit-img-main-box"
                        onClick={() => (
                          setimageId(Number(item.image_spec)),
                          setShowImagesModalSlider(true)
                        )}
                      >
                        <img src={item.url} />
                      </div>
                    ))}
                </div>
              </div>

              <div class="row">
                <div class="col-6 cstm-inputfield">
                  <label>{changeTicket} Issue Date Time</label>
                  <br />
                  <input
                    type="datetime-local"
                    name="ticket_date"
                    value={citationData.citation_start_timestamp}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        citation_start_timestamp: e.target.value,
                      })
                    }
                  />
                </div>

                <div class="col-6 cstm-inputfield">
                  <label>{changeTicket} No</label> <br />
                  <input
                  type="text"
                    name="ticket_Id"
                    value={citationData.ticket_no}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        ticket_no: e.target.value,
                      })
                    }
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 cstm-inputfield">
                  <label>Block</label>
                  <br />
                  <input
                    name="block"
                    value={citationData.block}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        block: e.target.value,
                      })
                    }
                  />
                </div>

                <div class="col-6 cstm-inputfield">
                  <label>Street</label> <br />
                  <input
                    name="street"
                    value={citationData.street}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        street: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 cstm-inputfield">
                  <label>State</label>
                  <br />
                  <select
                    class="form-control ar-olny edit-citationonly-select"
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        state: e.target.value,
                      })
                    }
                  >
                    {streetList.map((item) => (
                      <option
                        selected={item.state_abbreviated === citationData.state}
                        value={item.state_abbreviated}
                      >
                        {item.state_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div class="col-6 cstm-inputfield">
                  <label>License Plate</label> <br />
                  <input
                    name="lp_number"
                    value={citationData.lp_number}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        lp_number: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 cstm-inputfield">
                  <label>Remark 1</label>
                  <br />
                  <input
                    list="remark1"
                    name="remark_1"
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        remark_1: e.target.value,
                      })
                    }
                    value={citationData.remark_1}
                  />
                  <datalist id="remark1">
                    {remarks.map((item) => (
                      <option
                        selected={item.remark === citationData.remark_1}
                        value={item.remark}
                      >
                        {item.remark}
                      </option>
                    ))}
                  </datalist>
                </div>

                <div class="col-6 cstm-inputfield">
                  <label>Remark 2</label> <br />
                  <input
                    list="remark2"
                    name="remark_2"
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        remark_2: e.target.value,
                      })
                    }
                    value={citationData.remark_2}
                  />
                  <datalist id="remark2">
                    {remarks.map((item) => (
                      <option
                        selected={item.remark === citationData.remark_2}
                        value={item.remark}
                      >
                        {item.remark}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>

              <div class="row">
                <div class="col-6 cstm-inputfield">
                  <label>Note 1</label>
                  <br />
                  <input
                    name="note_1"
                    value={citationData.note_1}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        note_1: e.target.value,
                      })
                    }
                  />
                </div>

                <div class="col-6 cstm-inputfield">
                  <label>Note 2</label> <br />
                  <input
                    name="note_2"
                    value={citationData.note_2}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        note_2: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 cstm-inputfield">
                  <label>Violation Code</label>
                  <br />
                  <select
                    class="form-control ar-olny edit-citationonly-select"
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        code: JSON.parse(e.target.value).code,
                        description: JSON.parse(e.target.value).description,
                      })
                    }
                  >
                    {violationCode.map((item) => (
                      <option
                        selected={item.code === citationData.code}
                        value={JSON.stringify({
                          code: item.code,
                          description: item.violation_description,
                        })}
                      >
                        {item.code}
                      </option>
                    ))}
                  </select>
                </div>

                <div class="col-6 cstm-inputfield">
                  <label>Violation Description</label>
                  <br />
                  <select
                    class="form-control ar-olny edit-citationonly-select"
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        description: JSON.parse(e.target.value).description,
                        code: JSON.parse(e.target.value).code,
                      })
                    }
                  >
                    {violationCode.map((item) => (
                      <option
                        selected={
                          item.violation_description ===
                          citationData.description
                        }
                        value={JSON.stringify({
                          description: item.violation_description,
                          code: item.code,
                        })}
                      >
                        {item.violation_description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-12 cstm-inputfield">
                  <label>Reason for Edit *</label>
                  <br />
                  <textarea
                    rows={2}
                    value={citationData.remarks}
                    onChange={(e) =>
                      setCitationData({
                        ...citationData,
                        remarks: e.target.value,
                      })
                    }
                  />

                  {showError && (
                    <span className="errormessage">{fieldError?.remarks}</span>
                  )}
                </div>
              </div>

              <div class="edit-citationonly-submit">
                <button class="ec-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showImagesModalSlider && (
        <div
          className="main-body-modal cstm-ticket-details-pop"
          onKeyDown={(e) => {
            if (e.key === "ArrowLeft") {
              imageId === 0
                ? setimageId(slideImages.length - 1)
                : setimageId(imageId - 1);
            } else if (e.key === "ArrowRight") {
              imageId + 1 === slideImages.length
                ? setimageId(0)
                : setimageId(imageId + 1);
            }
          }}
          tabIndex={0}
          ref={sliderPopupRef}
        >
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider slides={slideImages} index={imageId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCitationModal;
