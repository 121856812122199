import 'leaflet/dist//leaflet.css';

import {MapContainer, TileLayer, Marker, Popup, Polygon} from 'react-leaflet';
import {divIcon, Point} from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import {useEffect, useRef, useState} from 'react';
import PermitListContainer from '../permitLists/permitListContainer';
import redTicketIcon from '../../../Images/red-ticket.png';
import linkIcon from '../../../Images/link-icon.png';
import BuyPermitStep from '../buyPermit';

const PermitMapListsComponent = ({locationList}) => {
  const [showDrawerList, setShowDrawerList] = useState(false);
  const [showPageType, setShowPageType] = useState('map');
  const [locationPermit, setLocationPermit] = useState();
  const [locationDropdownList, setLocationDropdownList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [mapCenter, setMapCenter] = useState([
    process.env.PERMIT_MAP_LAT || 41.7637111,
    process.env.PERMIT_MAP_LONG || -72.6850932,
  ]);
  const [mapZoom, setMapZoom] = useState(15);
  const [showStepDetails, setShowStepDetails] = useState(false);
  const markersRef = useRef({});
  const [permitName, setPermitName] = useState("")
  const [locationZoneList, setLocationZoneList] = useState([])
  const [selectedLocationZone, setSelectedLocationZone] = useState('');

  const customClusterIcon = (cluster, count) => {
    return new divIcon({
      html: `<div class="cluster-icon">${count}</div>`,
      className: 'custom-marker-cluster',
      iconSize: new Point(60, 48, true),
    });
  };

  const createCustomIcon = count => {
    return new divIcon({
      html: `<div class="custom-marker-icon">${count}</div>`,
      className: 'custom-div-icon',
      iconSize: new Point(60, 48),
    });
  };

  const showDrawerPermitList = (location, locationId) => {
    setLocationPermit(location);
    setSelectedLocation(locationId);
    setShowDrawerList(true);
  };

  const changePageTypeHandler = e => {
    setShowPageType(e.target.value);
  };

  const filterLocationsName = () => {
    const name = locationList.map(loc => ({
      name: loc.location_name,
      id: loc.location_id,
      coords: loc.single_point_location.coordinates,
    }));
    const zone = locationList.map(loc => ({
      name: loc.location_name,
      id: loc.location_id,
      zone: loc.zone,
      coords: loc.single_point_location.coordinates,
    }));
    setLocationDropdownList(name);
    setLocationZoneList(zone);
  };


  useEffect(() => {
    if (locationList && locationList.length > 0) {
      filterLocationsName();
    }
  }, [locationList]);

  const onSelectLocation = async e => {
    const selectedLocationName = e.target.value;
    setSelectedLocation(selectedLocationName);
    setSelectedLocationZone("")

    // Find the selected location's coordinates
    const location = locationDropdownList.find(
      loc => loc.name.toLowerCase() === selectedLocationName.toLowerCase(),
    );
    if (location) {
      // Set the map center to the found coordinates
      setMapCenter(location.coords);
      setMapZoom(20); // Adjust zoom level as necessary

      setTimeout(() => {
        // Open the popup for the corresponding marker
        if (markersRef.current[location.name]) {
          markersRef.current[location.name].openPopup();
        }
      }, 500);

      setShowDrawerList(true);
      setLocationPermit(
        locationList.find(
          loc =>
            loc.location_name.toLowerCase() ===
            selectedLocationName.toLowerCase(),
        ),
      );
    } else {
    }
  };

  const onSelectLocationZone = async e => {
    const selectedLocationName = e.target.value;
    setSelectedLocationZone(selectedLocationName);
    setSelectedLocation("")

    // Find the selected location's coordinates
    const location = locationZoneList.find(
      loc => loc.zone.toLowerCase() === selectedLocationName.toLowerCase(),
    );
    if (location) {
      // Set the map center to the found coordinates
      setMapCenter(location.coords);
      setMapZoom(20); // Adjust zoom level as necessary

      setTimeout(() => {
        // Open the popup for the corresponding marker
        if (markersRef.current[location.name]) {
          markersRef.current[location.name].openPopup();
        }
      }, 500);

      setShowDrawerList(true);
      setLocationPermit(
        locationList.find(
          loc =>
            loc.zone.toLowerCase() ===
            selectedLocationName.toLowerCase(),
        ),
      );
    } else {
    }
  };

  const blackOptions = { color: 'black' }

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            {/* Content starts */}
            <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
        {showStepDetails ? (
          <BuyPermitStep setShowStepDetails={setShowStepDetails} permitName={permitName} />
        ) : (
          <div className="permit-map-container">
            <div className='permit-filter-container'>    
              <div className="permit-map-select">
                <input
                  onChange={onSelectLocationZone}
                  value={selectedLocationZone}
                  list="locationZones"
                  id="zone"
                  placeholder="Select or type Zone"
                />
                <datalist id="locationZones">
                  {locationZoneList.map(loc => (
                    <option value={loc.zone} />
                  ))}
                </datalist>
              </div>
            </div>
            {locationPermit && (
              <div className="map-check-view">
                <label className="map-checkbox">
                  <input
                    type="checkbox"
                    name="view_type"
                    value={'map'}
                    checked={showPageType === 'map'}
                    onChange={e => changePageTypeHandler(e)}
                  />
                  Map View
                </label>
                <label className="map-checkbox">
                  <input
                    type="checkbox"
                    name="view_type"
                    value={'list'}
                    checked={showPageType === 'list'}
                    onChange={e => changePageTypeHandler(e)}
                  />
                  List View
                </label>
              </div>
            )}

            {showPageType === 'map' ? (
              <>
                <div className="map-list-container">
                  {showDrawerList && (
                    <PermitListContainer
                      locationPermit={locationPermit}
                      setShowStepDetails={setShowStepDetails}
                      isDrawer={true}
                      setPermitName={setPermitName}
                    />
                  )}
                  <div
                    className={
                      showDrawerList ? 'map-container' : 'map-container-full'
                    }>
                    <MapContainer
                      center={mapCenter}
                      zoom={mapZoom}
                      minZoom={2}
                      zoomControl={false}>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      <MarkerClusterGroup
                        chunkedLoading
                        spiderfyOnMaxZoom={true}
                        spiderfyDistanceMultiplier={true}
                        iconCreateFunction={cluster => {
                          return customClusterIcon(
                            cluster,
                            cluster
                              .getAllChildMarkers()
                              .map(marker => marker.options.permits)
                              .reduce((acc, cur) => acc + cur, 0),
                          );
                        }}>
                        {locationList &&
                          locationList.length > 0 &&
                            locationList.map(marker => (
                            <Marker
                              id={marker.location_id}
                              permits={marker.applicable_permit_types?.length}
                              position={
                                marker.single_point_location.coordinates
                              }
                              icon={createCustomIcon(
                                marker?.applicable_permit_types?.length,
                              )}
                              ref={ref => {
                                markersRef.current[marker.location_name] = ref;
                              }}
                              eventHandlers={{
                                mouseover: event => event.target.openPopup(),
                              }}>
                              <Popup>
                                <div className="markers-custom-popup">
                                  <div className="markers-custom-popup-left">
                                    <div className="markers-custom-popup-lef-img">
                                      <img
                                        src={redTicketIcon}
                                        className="markers-custom-popup-icon"
                                      />
                                    </div>
                                    <div className="markers-custom-popup-lef-text">
                                      <h5>{marker.location_name}</h5>
                                      <span className="markers-custom-popup-p">
                                        {marker?.zone ?? '-'}{' '}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="markers-custom-popup-right">
                                    {marker?.applicable_permit_types &&
                                      marker?.applicable_permit_types.length >
                                        0 && (
                                        <a
                                          onClick={() =>
                                            showDrawerPermitList(
                                              marker,
                                              marker.location_name,
                                            )
                                          }>
                                          <img src={linkIcon} />
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </Popup>
                            </Marker>
                          ))}
                        </MarkerClusterGroup>
                        {locationList && locationList.map(itm => {
                          if (itm.multi_point_location && itm.multi_point_location.coordinates) {
                            return <Polygon positions={itm.multi_point_location.coordinates} pathOptions={blackOptions} />
                          } else {
                            return null
                          }
                        })}
                    </MapContainer>
                  </div>
                </div>
              </>
            ) : (
              <PermitListContainer
                locationPermit={locationPermit}
                setShowStepDetails={setShowStepDetails}
                isDrawer={false}
              />
            )}
          </div>
        )}
            </div>
          </div>
        </div>
        </section>
    </>
  );
};

export default PermitMapListsComponent;
