import {useEffect, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import { regexEmail, siteid } from '../../../../Global/site';
import ArrowPermitLeft from '../../../../Images/arrow-permit-left.svg';
import ArrowPermitRight from '../../../../Images/arrow-permit-right.svg';
import {useNavigate} from 'react-router-dom';
import ImagePreview from '../../../../Global/imagePreview';
import {permitStepFormHandler, permitUserInfo} from '../../../../actions/permits';
import dashboardService from '../../../../Services/dashboard.service';
import ContactInfoForm from '../../../../utile/userForm/contactInfoForm';
import UserInformationForm from '../../../../utile/userForm/userInformationForm';
import Cookies from 'js-cookie'

const siteId = siteid();

const initialValues = {
  first_name: '',
  last_name: '',
  company: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  email: '',
  country: '',
  postal_code: '',
  phone_code: '+1',
  user_name: '',
};
const initialErrors = {
  address: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  city: '',
  user_name: '',
  user_email: '',
  zip_code: '',
  state: '',
};

const PermitUserRegister = ({
  permitData,
  permitStepData,
  stepNo,
  onClickNavigate,
  setShowStepDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permitUserData = useSelector(
    state => state.permitReducer.permitUserInfo,
  );
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [documentData, setDocumentData] = useState([]);
  const [filesPreview, setFilesPreview] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);
  const [allLinks, setAllLinks] = useState([]);
  const [fileError, setFileError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPreviewModel, setShowPreviewModel] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(false);

  const maxSizeInMB = 5;

  const validFileTypes = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/svg+xml'
  ];

  useEffect(() => {
    if (permitData.documents && permitData.documents.length > 0) {
      setDocumentData(
        permitData.documents.map((doc, docId) => ({
          docId,
          docName: doc.name,
          selectedOption: '',
        })),
      );
    }
    setErrors(initialErrors);
  }, []);

  useEffect(() => {
    if (permitUserData) {
      setValues(permitUserData.user_address);
      setDocumentData(
        permitUserData.documents.map((itm, index) => ({
          docId: index,
          docName: itm.name,
          selectedOption: itm.type,
        })),
      );
      setFilesPreview(permitUserData.filesPreview);
    }
  }, [permitUserData]);

  const handleSelectChange = (docId, docName, selectedOption) => {
    if (selectedOption !== '' && validationErrors.length > 0) {
      let selectError = [...validationErrors];
      if (validationErrors && validationErrors.length > 0) {
        const errorInd = validationErrors.findIndex(itm => itm.index === docId);
        selectError.splice(errorInd, 1);

        setValidationErrors(selectError);
      }
    }
    setDocumentData(prevData => {
      const existingDocIndex = prevData.findIndex(doc => doc.docId === docId);

      if (existingDocIndex >= 0) {
        const updatedData = [...prevData];
        updatedData[existingDocIndex] = {
          ...updatedData[existingDocIndex],
          selectedOption,
          docName,
        };
        return updatedData;
      } else {
        return [
          ...prevData,
          {
            docId,
            docName,
            selectedOption,
          },
        ];
      }
    });
  };
  const handleFileChange = (docId, docName, files) => {
    const validFiles = [];
    const errors = [];

    let selectError = [...fileError];
    if (fileError && fileError.length > 0) {
      const errorInd = fileError.findIndex(itm => itm.index === docId);
      if (errorInd !== -1) {
        selectError.splice(errorInd, 1);
        setFileError(selectError);
      }
    }

    Array.from(files).forEach(file => {
      if (
        validFileTypes.includes(file.type) &&
        file.size <= maxSizeInMB * 1024 * 1024
      ) {
        validFiles.push(file);

        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFilesPreview(prevFiles => [
              ...prevFiles,
              {
                docId,
                docName,
                name: file.name,
                url: reader.result,
                type: 'image',
              },
            ]);
          };
          reader.readAsDataURL(file);
        } else {
          setFilesPreview(prevFiles => [
            ...prevFiles,
            {
              docId,
              docName,
              name: file.name,
              type: file.type.includes('pdf') ? 'pdf' : 'doc',
            },
          ]);
        }
      } else {
        errors.push({
          name: file.name,
          message: `Invalid file type or size. Allowed types: ${validFileTypes.join(
            ', ',
          )}. Max size: ${maxSizeInMB}MB.`,
        });
      }
    });

    setFilesUpload(prevFiles => [...prevFiles, ...validFiles]);

    // Reset the file input element to allow re-uploading the same file
    document.getElementById(`file-input-${docId}`).value = ''; // Use the specific ID for each file input
  };

  const handleImageDlt = (e, index, docId) => {
    e.preventDefault();

    const fileIndex = filesPreview.findIndex(itm => itm.docId === docId);

    // setFilesPreview(prevFiles =>
    //   prevFiles.filter((_, index) => index !== fileIndex),
    // );

    // setFilesUpload(prevFiles =>
    //   prevFiles.filter((_, index) => index !== fileIndex),
    // );

    console.log(fileIndex, 'index');

    filesPreview.splice(fileIndex, 1);
    setFilesPreview([...filesPreview]);

    filesUpload.splice(fileIndex, 1);
    setFilesUpload([...filesUpload]);

    // Reset the file input element to allow re-uploading the same file
    document.getElementById(`file-input-${docId}`).value = ''; // Use the specific ID for each file input
  };

  const plateDetails = permitStepData?.plate_details?.map(item => ({
    vehicle_details: {
      lp_number: item.lp_number,
      make: item.make,
      model: item.model,
      year: `${item.year}`,
      color: item.color,
    },
    state: item.state,
    start_date: moment(item.start_date).format('YYYY-MM-DDTHH:mm:ssZ'),
  }));

  let phone = values.phone_number;

  const handleUpdateProfile = async e => {
    e.preventDefault();

    let isValid = false;
    Object.entries(values).forEach(([key, value]) => {
      if (
        value === '' &&
        key !== 'company' &&
        key !== 'country' &&
        key !== 'postal_code' &&
        key !== 'phone_code'
      ) {
        isValid = true;
        errors[key] = `Enter ${key.replace(/_/g, ' ')}`;
      }
      if (key === 'state' && value === 'select') {
        isValid = true;
        errors[key] = `Enter ${key.replace(/_/g, ' ')}`;
      }
    });

    setErrors({...errors});

    let documents = [];

    if (!permitData?.permit_approval?.auto_approval) {
      let docErrors = documentData.map((itm, index) => {
        if (itm.selectedOption === '') {
          return {
            index,
            message: 'Select Document Type',
          };
        }
      });

      if (docErrors && docErrors.length > 0 && docErrors[0]) {
        isValid = true;
        setValidationErrors(docErrors);
      } else {
        setValidationErrors([]);
      }

      setFileError(
        permitData.documents.map((itm, index) => {
          if (!filesPreview.find(item => item.docName === itm.name)) {
            isValid = true;
            return {
              index,
              message: 'Upload File.',
            };
          }
        }),
      );
    }

    if (!isValid) {
      if (!permitData?.permit_approval?.auto_approval) {
        if (filesUpload.length === permitData.documents.length) {
          const formData = new FormData();
          let data_u = [];

          for (let i = 0; i < filesUpload.length; i++) {
            data_u.push(`${i + 1}`);
            formData.append('files', filesUpload[i]);
          } 
          
          console.log(documentData,'document data')
          formData.append('data', data_u);
          formData.append('upload_type', 'PermitDocument');

          setLoading(true);
          try {
            const upload_result = await dashboardService.uploadPermitFiles(
              formData,
              Cookies.get("siteinfo"),
            );

            if (upload_result.data.status) {
              const allLinks = upload_result.data.data[0].response.links.map(
                itm => ({id: itm.filename.split('_')[1], link: itm.link}),
              );
              setAllLinks(allLinks);
              documents = permitData.documents.map((doc, index) => ({
                name: doc.name,
                type: documentData[index].selectedOption,
                url: allLinks[index].link,
              }));
            }
            console.log(documents,'permit documents')
          } catch (error) {
            console.error('Upload failed:', error);
          }
        } else {
          if (permitUserData.documents && permitUserData.documents.length > 0) {
            documents = permitUserData.documents;
          }
        }
      }

      const payloadData = {
        plate_details: plateDetails,
        permit_config_id: permitData.id,
        user_address: {...values},
        duration: permitStepData.duration.toUpperCase(),
        permit_quantity: permitStepData.permit_quantity,
        permit_notify_updates:
          permitStepData.permit_notify_updates === 'Yes, i want to sign up'
            ? true
            : false,
        tier_number: 1,
        documents: documents,
      };
      payloadData.user_address.phone_number =
        values.phone_code + values.phone_number;

      const userInfo = {...payloadData, filesPreview};
      dispatch(permitUserInfo({...permitUserData, ...userInfo}));

      doCheckout(payloadData);
    }
  };
  
  const doCheckout = async payloadData => {
    setErrors(initialErrors);
    setLoading(true);
    try {
      const response = await dashboardService.buyPermitWithRegister(
        payloadData,
        Cookies.get("siteinfo"),
      );
      if (response.data.data.reference_id !== '') {
        navigate(`#step${Number(stepNo) + 1}`, {
          state: {
            refId: response.data.data.reference_id,
          },
        });
        onClickNavigate(`step${Number(stepNo) + 1}`);
      }
      payloadData.user_address.phone_number = phone;
    } catch (err) {
      payloadData.user_address.phone_number = phone;
      // setIsOpen(true);
      // setDisplayMsg('Something went wrong.');
    } finally {
      setLoading(false);
      payloadData.user_address.phone_number = phone;
    }
  };

  const imagePreviewHandler = url => {
    setShowPreviewModel(true);
    setPreviewUrl(url);
  };

  const closePreviewModel = () => {
    setShowPreviewModel(false);
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    setValues({...values, [name]: value});
    if (name === 'phone_number') {
      if (value.length !== 10) {
        if (value.length === 0) {
          setErrors({
            ...errors,
            [name]: 'Enter Phone Number',
          });
        } else {
          setErrors({
            ...errors,
            [name]: 'Phone Number should be of 10 Digits.',
          });
        }
      } else {
        setErrors({...errors, [name]: ''});
      }
    } else if (name === 'email') {
      errors.email =
        value.length > 0
          ? regexEmail.test(value)
            ? ''
            : 'Email is not valid'
          : 'Enter email';
      setErrors({...errors});
    } else if (name === 'password') {
      const passwordValidation =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,64}$/;
      if (value.length < 8) {
        setErrors({
          ...errors,
          [name]: 'Password should be of minimum 8 characters.',
        });
      } else if (!passwordValidation.test(value)) {
        setErrors({
          ...errors,
          [name]:
            'Password should contain at least 1 number and 1 special character.',
        });
      } else {
        setErrors({...errors, [name]: ''});
      }
    } else {
      if (value === '') {
        setErrors({...errors, [name]: `Enter ${name.replace(/_/g, ' ')}`});
      } else {
        setErrors({...errors, [name]: ''});
      }
    }
  };

  return (
    <>
      <form onSubmit={handleUpdateProfile}>
        <UserInformationForm
          values={values}
          errors={errors}
          handleInputChange={handleInputChange}
        />
        {!permitData?.permit_approval?.auto_approval &&
          permitData.documents &&
          permitData.documents.length > 0 && (
            <div className="permit-user-reg">
              <h5 className="mb-4">Upload Documents</h5>
              <div className="permit-user-reg-main">
                {permitData.documents.map((item, index) => (
                  <div className="permit-user-reg-box">
                    <p>{item.name}</p>
                    <select
                      className="form-control"
                      onChange={e =>
                        handleSelectChange(index, item.name, e.target.value)
                      }
                      value={documentData[index]?.selectedOption || ''}>
                      <option value={''}>Select Document Type</option>
                      {item.options.map((doc, idx) => (
                        <option key={idx} value={doc}>
                          {doc}
                        </option>
                      ))}
                    </select>
                    {validationErrors &&
                      validationErrors.length > 0 &&
                      validationErrors.find(itm => itm.index === index) && (
                        <p className="errormessage">
                          {
                            validationErrors.find(itm => itm.index === index)
                              .message
                          }
                        </p>
                      )}
                    <div className=" mt-3 mb-3">
                      <div className="col-12 form-group formgroup-main cstm-choose-file mt-3 p-0">
                        <div class="choose-file-upload" for="">
                          <span class="choose-file-upload-title">
                            Choose Files{' '}
                          </span>
                          <input
                            type="file"
                            id={`file-input-${index}`}
                            onChange={e =>
                              handleFileChange(index, item.name, e.target.files)
                            }
                          />
                        </div>
                      </div>
                      {fileError &&
                        fileError.length > 0 &&
                        fileError.find(itm => itm?.index === index) && (
                          <p className="errormessage">
                            {
                              fileError.find(itm => itm?.index === index)
                                ?.message
                            }
                          </p>
                        )}

                      <div className="cstm-appeal-preview-images">
                        {filesPreview
                          .filter(file => file.docId === index)
                          .map((file, nm) => (
                            <div key={nm} className={`appeal-preview-img`}>
                              {file.type === 'image' && (
                                <img
                                  src={file.url}
                                  alt={file.name}
                                  style={{maxWidth: '100%'}}
                                  onClick={() => imagePreviewHandler(file.url)}
                                />
                              )}
                              {file.type === 'doc' && (
                                <p>{file.name} (Document)</p>
                              )}
                              {file.type === 'pdf' && <p>{file.name} (PDF)</p>}
                              <button
                                className="cstm-appeal-close"
                                onClick={e =>
                                  handleImageDlt(e, nm, file.docId)
                                }>
                                ×
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        <div className="permit-question-btn">
          <button
            className="permit-question-btn-left"
            type="button"
            onClick={() => (
              Number(stepNo) === 0
                ? (navigate(-1))
                : navigate(`#step${Number(stepNo) - 1}`),
              onClickNavigate(`step${Number(stepNo) - 1}`)
            )}>
            <img src={ArrowPermitLeft} alt="back" />
            Back
          </button>
          <button
            className="permit-question-btn-right cstm-permit-btn"
            type="submit">
            {loading && (
              <div className="ptc-ld">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
              </div>
            )}
            Proceed to checkout
            <img src={ArrowPermitRight} alt="next" />
          </button>
        </div>
      </form>

      {showPreviewModel && (
        <ImagePreview url={previewUrl} closeModel={closePreviewModel} />
      )}
    </>
  );
};

export default PermitUserRegister;
