import ArrowPermitLeft from '../../../../Images/arrow-permit-left.svg';
import moment from 'moment';
import Card from '../../../CardElement/card';
import { state } from '../../../Common/dropDownOptions';

function convertDecimal(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

const PermitCheckoutComponent = ({
  cardError,
  setCardError,
  setCardData,
  permitInfo,
  backHandler,
  stepNo,
  makePaymentHandler,
  paymentLoader,
  totalSteps,
  paymentType,
  setPaymentType,
  SourceChangeHandler,
  sourceError,
  sourceValue
}) => {

  return (
    <>
      <div>
        <div className="permit-checkout-main">

          <div className="permit-checkout-main-body cstm-mak-payment">
            <div className="permit-checkout-main-body-left">
              <div className="permit-checkout-main-body-left-box">
                <h4 className="your-permit-info">Payment information</h4>
                <div className="permit-checkout-main-body-left-box-mn">
                  <h6>Permit name</h6>
                  <p className="mb-3">{<p>{permitInfo?.permit_name}</p>}</p>
                  <div className="permit-plt-main">
                    {permitInfo?.permit_detail &&
                      permitInfo?.permit_detail.length > 0 &&
                      permitInfo?.permit_detail.map((itm, i) => (
                        <>
                          <div className="permit-plt">
                            <h6>Plate {i + 1}</h6>
                            <div className="permit-plt-in">
                              <div className="permit-plt-in-bx">
                                <span className="permit-plt-in-bx-left">
                                  Plate Number
                                </span>
                                <span className="permit-plt-in-bx-right">
                                  {itm?.vehicle_details.lp_number}
                                </span>
                              </div>
                              <div className="permit-plt-in-bx">
                                <span className="permit-plt-in-bx-left">
                                  State
                                </span>
                                <span className="permit-plt-in-bx-right">
                                  {
                                    state?.find(st => st?.value === itm?.state)
                                      ?.label
                                  }
                                </span>
                              </div>
                              <div className="permit-plt-in-bx">
                                <span className="permit-plt-in-bx-left">
                                  Activation Date
                                </span>
                                <span className="permit-plt-in-bx-right">
                                  {moment(itm.start_date.split('T')[0]).format(
                                    'MM/DD/YYYY',
                                  )}
                                </span>
                              </div>
                              <div className="permit-plt-in-bx">
                                <span className="permit-plt-in-bx-left">
                                  Expiry Date
                                </span>
                                <span className="permit-plt-in-bx-right">
                                  {moment(itm.end_date.split('T')[0]).format(
                                    'MM/DD/YYYY',
                                  )}
                                </span>
                              </div>
                              <div className="permit-plt-in-bx">
                                <span className="permit-plt-in-bx-left">
                                  Prorated
                                </span>
                                <span className="permit-plt-in-bx-right">
                                  {permitInfo?.buy_permit_fee_structure
                                    ?.individual_permits_fee[0]?.is_prorated
                                    ? 'Yes'
                                    : 'No'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div className="permit-checkout-main-body-left-box">
                <h4 className="your-permit-info">User information</h4>
                <div className="permit-ui-box-in">
                  <div className="permit-ui-3">
                    <div className="permit-checkout-main-body-left-box-mn">
                      <h6>First Name</h6>
                      <p>{permitInfo?.user_detail.first_name}</p>
                    </div>
                    <div className="permit-checkout-main-body-left-box-mn">
                      <h6>Last Name</h6>
                      <p>{permitInfo?.user_detail.last_name}</p>
                    </div>
                    <div className="permit-checkout-main-body-left-box-mn">
                      <h6>Email</h6>
                      <p>{permitInfo?.user_detail.email}</p>
                    </div>
                  </div>
                  <div className="permit-ui-3">
                    <div className="permit-checkout-main-body-left-box-mn">
                      <h6>State</h6>
                      <p>
                        {
                          state?.find(
                            st => st?.value === permitInfo?.user_detail.state,
                          )?.label
                        }
                      </p>
                    </div>
                    <div className="permit-checkout-main-body-left-box-mn">
                      <h6>City</h6>
                      <p>{permitInfo?.user_detail.city}</p>
                    </div>
                    <div className="permit-checkout-main-body-left-box-mn">
                      <h6>Zip Code</h6>
                      <p>{permitInfo?.user_detail.zip_code}</p>
                    </div>
                  </div>
                  <div className="permit-checkout-main-body-left-box-mn">
                    <h6>Address</h6>
                    <p>{permitInfo?.user_detail.address}</p>
                  </div>
                </div>
              </div>

              <div className="permit-checkout-main-body-left-form">
                <div className="permit-checkout-main-body-left-box">
                  <h4 className="your-permit-info">Select payment method</h4>
                  <select
                    className="permit-field-que-select"
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option value={'OTC'} selected={paymentType === 'OTC'}>OTC</option>
                    <option value={'CARD'} selected={paymentType === 'CARD'}>CARD</option>
                  </select>
                </div>
                <div className="permit-checkout-main-body-left-box">
                  {paymentType === 'CARD' ?
                    (<>
                    <h4 className="your-permit-info">Card information</h4>
                    <Card
                      cardError={cardError}
                      setCardError={setCardError}
                      setCardData={setCardData}
                      allowTerms={false}
                      />
                    </>) : (
                      <>
                        <h4 className="your-permit-info">OTC Information</h4>
                        <div className="otc-input-box">
                        <div className="otc-input-box-in">
                            <span className="otc-input-box-title">OTC Source *</span>
                            <select
                              className='permit-field-que-select-otc'
                              type='text'
                              name='source'
                              value={sourceValue.source}
                              onChange={(e) => SourceChangeHandler(e)}
                            >
                              <option value={"Cash"} selected={sourceValue.source === 'Cash'}>Cash</option>
                              <option value={"Check"} selected={sourceValue.source === 'Check'}>Check</option>
                            </select>
                          {/* <input
                            type='text'
                            name='source'
                            placeholder='Enter OTC Source'
                            value={sourceValue.source}
                            onChange={(e) => SourceChangeHandler(e)} /> */}
                          {sourceError.source && <p className="errormessage">{sourceError.source}</p>}
                          </div>
                          <div className="otc-input-box-in">
                            <span className="otc-input-box-title">Amount *</span>
                            <div className='permit-otc-amount'>
                              <span className="permit-otc-amount-dlr">$</span>
                              <input
                                type='text'
                                name='amount'
                                placeholder='Enter Amount'
                                value={sourceValue.amount}
                                onChange={(e) => SourceChangeHandler(e)}
                                onKeyPress={event => {
                                  if (!/^\d{0,4}(\.\d{0,4})?$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}/>
                            </div>
                          {sourceError.amount && <p className="errormessage">{sourceError.amount}</p>}
                        </div> </div>
                      </>
                    )
                  }
                </div>
                <div className="permit-question-btn">
                  <button
                    className="permit-question-btn-right cstm-permit-btn"
                    onClick={makePaymentHandler}>
                    {paymentLoader ? (
                      <div className='pay-btn-loader'>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                      </div>
                    ) : (
                      'Make payment'
                    )}
                  </button>
                </div>
                 
                </div>
            </div>
            <div className="permit-checkout-main-body-right">
              <div className="payment-section">
                <h4 className="your-permit-info">Bill summary</h4>
                <h6 className="residental-parking-permit-title">
                  {permitInfo?.permit_name}
                </h6>

                {permitInfo?.buy_permit_fee_structure.application_fee !== 0 && <div className="permit-checkout-main-body-bx-3">
                  <div className="permit-checkout-main-body-bx333">
                    <div className="permit-checkout-main-body-bx-left">
                      Application Fee
                    </div>
                    <div className="permit-checkout-main-body-bx-right">
                      $
                      {convertDecimal(
                        permitInfo?.buy_permit_fee_structure.application_fee,
                      )}
                    </div>
                  </div>
                </div>}

                <div className="permit-checkout-main-body-bx-3">
                  <div className="permit-checkout-main-body-bx333">
                    <div className="permit-checkout-main-body-bx-left">
                      Payment Details
                    </div>
                  </div>

                  {permitInfo?.buy_permit_fee_structure
                    .individual_permits_fee &&
                    permitInfo?.buy_permit_fee_structure.individual_permits_fee
                      .length > 0 &&
                    permitInfo?.buy_permit_fee_structure.individual_permits_fee.map(
                      (fee, i) => (
                        <div className="permit-checkout-main-body-bx-row33">
                          <div className="permit-checkout-main-body-bx-row3">
                            <div className="permit-checkout-main-body-bx-left">
                              <b>Plate Number</b>
                            </div>
                            <div className="permit-checkout-main-body-bx-right">
                              <b>{fee.lp_number}</b>
                            </div>
                          </div>
                          {fee.amount !== 0 && <div className="permit-checkout-main-body-bx-row3">
                            <div className="permit-checkout-main-body-bx-left main-permit-fee">
                              {fee.duration} Permit Fee
                            </div>
                            <div className="permit-checkout-main-body-bx-right main-permit-fee">
                              ${convertDecimal(fee.amount)}
                            </div>
                          </div>}
                          {fee.is_prorated && (
                            <>
                              {fee.prorated_discount !== 0 && <div className="permit-checkout-main-body-bx-row3">
                                <div className="permit-checkout-main-body-bx-left">
                                  Prorated Discount
                                </div>
                                <div className="permit-checkout-main-body-bx-right">
                                  ${convertDecimal(fee.prorated_discount)}
                                </div>
                              </div>}
                              {fee.net_amount !== 0 && <div className="permit-checkout-main-body-bx-row3">
                                <div className="permit-checkout-main-body-bx-left">
                                  Amount Due
                                </div>
                                <div className="permit-checkout-main-body-bx-right">
                                  ${convertDecimal(fee.net_amount)}
                                </div>
                              </div>}
                              <div className="permit-checkout-main-body-bx-row3">
                                <div className="permit-checkout-main-body-bx-left">
                                Activation Date
                                </div>
                                <div className="permit-checkout-main-body-bx-right">
                                  {moment(fee.start_date.split('T')[0]).format(
                                    'MM/DD/YYYY',
                                  )}
                                </div>
                              </div>
                              <div className="permit-checkout-main-body-bx-row3">
                                <div className="permit-checkout-main-body-bx-left">
                                  Expiry Date
                                </div>
                                <div className="permit-checkout-main-body-bx-right">
                                  {moment(fee.end_date.split('T')[0]).format(
                                    'MM/DD/YYYY',
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ),
                    )}
                </div>

                {permitInfo?.buy_permit_fee_structure.service_fee !== 0 && <div className="permit-checkout-main-body-bx pc-brd">
                  <div className="permit-checkout-main-body-bx-left">
                    Service Fee
                  </div>
                  <div className="permit-checkout-main-body-bx-right pc-total-size">
                    $
                    {convertDecimal(
                      permitInfo?.buy_permit_fee_structure.service_fee,
                    )}
                  </div>
                </div>}

                {permitInfo?.buy_permit_fee_structure.additional_fees &&
                  permitInfo?.buy_permit_fee_structure.additional_fees.length >
                    0 && (
                    <div className="permit-checkout-main-body-bx-3">
                      <div className="permit-checkout-main-body-bx333">
                        <div className="permit-checkout-main-body-bx-left">
                          Additional Fee
                        </div>
                      </div>
                      {permitInfo?.buy_permit_fee_structure.additional_fees.map(
                        itm => (
                          <div className="permit-checkout-main-body-bx-row33">
                            <div className="permit-checkout-main-body-bx-row3">
                              <div className="permit-checkout-main-body-bx-left">
                                {itm.name}
                              </div>
                              <div className="permit-checkout-main-body-bx-right">
                                ${convertDecimal(itm.amount)}
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  )}

                {permitInfo?.buy_permit_fee_structure.fixed_tax !== 0 && <div className="permit-checkout-main-body-bx pc-brd">
                  <div className="permit-checkout-main-body-bx-left">
                    Fixed Tax
                  </div>
                  <div className="permit-checkout-main-body-bx-right pc-total-size">
                    $
                    {convertDecimal(
                      permitInfo?.buy_permit_fee_structure.fixed_tax,
                    )}
                  </div>
                </div>}

                {<div className="permit-checkout-main-body-bx pc-brd pcm-last-bdr">
                  <div className="permit-checkout-main-body-bx-left">Total</div>
                  <div className="permit-checkout-main-body-bx-right pc-total-size">
                    $
                    {convertDecimal(
                      permitInfo?.buy_permit_fee_structure.total_amount,
                    )}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermitCheckoutComponent;
