import moment from "moment";
import { useEffect, useMemo, useState } from "react";

const PermitOptionTable = ({ reportData,tableHeading,keySequence,EditHandler, buyPermitHandler }) => {
  const [report,setReport] = useState(reportData)
  const [sorting, setSorting] = useState({ field: "", ascending: true });

  useEffect(() => {
    const currentDataCopy = [...report];

    const sortedCurrentdata = currentDataCopy.sort((a, b) => {
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });

    setReport(
      sorting.ascending ? sortedCurrentdata : sortedCurrentdata.reverse()
    );
  }, [sorting]);

  const applySorting = useMemo(() => (key, ascending) => {
    setSorting({ key: key, ascending: ascending });
  },[sorting,report]);

  const renderAllData = () => {
    return report.map((item) => (
      <tr key={item?.id}>
        {renderData(item)}
      </tr>
    ));
  };

  const renderData = (element) => {
    const tableElements = []
    keySequence.map((key, index) => {
      // split the key by the "." character to handle nested keys
      const keyParts = key.split('.');

      // initialize a variable to hold the current value
      let value = element;

      // iterate over the key parts and get the value for each nested key
      for (const keyPart of keyParts) {
        value = value[keyPart];

        // return null if the value is undefined or null
        if (value === undefined || value === null) {
          return null;
        }

        if (keyPart === 'permit_start_date') {
          value = moment(value).format("MM/DD/YYYY")
          tableElements.push(
            <td key={index}>{value}</td>
          )
          return value;
        }
        if (keyPart === 'permit_end_date') {
          value = moment(value).format("MM/DD/YYYY")
          tableElements.push(
            <td key={index}>{value}</td>
          )
          return value;
        }
        if (keyPart === 'is_public') {
          tableElements.push(
            <td key={index}>{`${value}`}</td>
          )
          return value;
        }

        if (keyPart === 'permit_time_availability') {
          tableElements.push(
            <td key={index}>{
              <div className="table-action">
              <a href="javascript:void(0)" onClick={() => EditHandler(element)}>Edit</a>
              <a href="javascript:void(0)" onClick={() => buyPermitHandler(element)}>Pay</a>
              </div>
            }</td>
          )
          return value;
        }
      }

      tableElements.push(
        <td key={index}>{value}</td>
      )
      return value;
    });
    return tableElements;
  }

  return (
    <>
      <div
        className="report_tbl_scroll fix-page-scroll-only"
        id="tbl-main-download"
      >
        <table
          className="table table-heading table-bordered"
          id="cstm-report-tb-display"
        >
          <thead>
            <tr>
              {tableHeading.map((key,index) => (
                <th className={`srt-tab ${
                  sorting.key === keySequence[index] &&
                  (sorting.ascending ? "srt-ace" : "srt-desc")
                }`}
                onClick={() =>
                  applySorting(
                    keySequence[index],
                    !sorting.ascending
                  )
                }>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderAllData()}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PermitOptionTable;
