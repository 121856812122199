import React, {useEffect} from 'react'; 
import $ from 'jquery';
import Cookies from 'js-cookie';
 
const ProductTable = (props) => { 

  const [count, setCount] = React.useState(0);
   
  const onClickDetailsPage = (e) =>{
    Cookies.set('reporting_ticket_id', e.target.dataset.id);
    return props.onClickDetailsPage(true);
  }  

  useEffect(() => {

    $(function () {
      $('table')
        .on('click', '.srt-tab', function () { 
          var index = $(this).index(),
              rows = [],
              thClass = $(this).hasClass('srt-ace') ? 'srt-desc' : 'srt-ace';
    
          $('#cstm-report-tb-display th').removeClass('srt-ace srt-desc');
          $(this).addClass(thClass);
    
          $('#cstm-report-tb-display tbody tr').each(function (index, row) {
            rows.push($(row).detach());
          });
    
          rows.sort(function (a, b) {
            var aValue = $(a).find('td').eq(index).text(),
                bValue = $(b).find('td').eq(index).text();
    
            return aValue > bValue
                 ? 1
                 : aValue < bValue
                 ? -1
                 : 0;
          });
    
          if ($(this).hasClass('srt-desc')) {
            rows.reverse();
          }
    
          $.each(rows, function (index, row) {
            $('#cstm-report-tb-display tbody').append(row);
          });
        });
    });

   setCount(100);
}, []);

const getmiliSecondToSecMinHours = function(milliseconds){
    
  let hours = milliseconds / (1000*60*60);
  let absoluteHours = Math.floor(hours);
  let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  //Get remainder from hours and convert to minutes
  let minutes = (hours - absoluteHours) * 60;
  let absoluteMinutes = Math.floor(minutes);
  let m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  let seconds = (minutes - absoluteMinutes) * 60;
  let absoluteSeconds = Math.floor(seconds);
  let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

  return h + ':' + m + ':' + s;
}

  return (

      <> 
          <div className="report_tbl_scroll" id="tbl-main-download">
            <table className="table table-heading table-bordered" id="cstm-report-tb-display">
                <thead>
                <tr>
                {props.tableColumnName.map((item, index) => (
                  <th className="srt-tab">  
                        {item.name}
                    </th>
                ))} 
                </tr>
        
      </thead>
      <tbody>
        {props.products.map((item, index) => ( 
      <tr> 
        {item[0] !== "" && item[0] !== null ?            
          <td>{item[0]}</td>
          :
          <td>-</td>
        }
         {item[1] !== "" && item[1] !== null ?            
          <td>{item[1]}</td>
          :
          <td>-</td>
        }
         {item[2] !== "" && item[2] !== null ?            
          <td>{item[2]}</td>
          :
          <td>-</td>
        }
         {item[3] !== "" && item[3] !== null ?            
          <td>{item[3]}</td>
          :
          <td>-</td>
        }
         {item[4] !== "" && item[4] !== null ?            
          <td>{item[4]}</td>
          :
          <td>-</td>
        }
         {item[5] !== "" && item[5] !== null ?            
             <td>{item[5]}</td>
          :
          <td>-</td>
        }
         {item[6] !== "" && item[6] !== null ?            
             <td>{item[6]}</td>
          :
          <td>-</td>
        }
         {item[7] !== ""  && item[7] !== null ?            
            <td>{item[7]}</td>
          :
          <td>-</td>
        }
         {item[8] !== "" && item[8] !== null ?            
          <td>{item[8]}</td>
          :
          <td>-</td>
        }
         {item[9] !== ""  && item[9] !== null?            
          <td>{item[9]}</td>
          :
          <td>-</td>
        }
         {item[10] !== ""  && item[10] !== null?            
          <td>{item[10]}</td>
          :
          <td>-</td>
        }
         {item[11] !== "" && item[11] !== null ?            
             <td>{item[11]}</td>
          :
          <td>-</td>
        }
         {item[12] !== "" && item[12] !== null ?            
          <td>{item[12]}</td>
          :
          <td>-</td>
        }
         {item[13] !== "" && item[13] !== null ?            
          <td>{item[13]}</td>
          :
          <td>-</td>
        }
        {item[14] !== "" && item[14] !== null ?            
          <td>{getmiliSecondToSecMinHours(item[14])}</td>
          :
          <td>-</td>
        } 
        {item[15] !== "" && item[15] !== null ?            
          <td>{item[15]}</td>
          :
          <td>-</td>
        }
        {item[16] !== ""  && item[16] !== null ?            
          <td>{item[16]}</td>
          :
          <td>-</td>
        }
        {item[17] !== "" && item[17] !== null ?            
          <td>{item[17]}</td>
          :
          <td>-</td>
        }
        {item[18] !== "" && item[18] !== null ?            
          <td>{item[18]}</td>
          :
          <td>-</td>
        }
        </tr> 
          
        ))} 
      </tbody>
    </table>   
    </div>  
                   
</>

  );
};

export default ProductTable;