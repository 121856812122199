import React, { useState, useEffect } from 'react';
import WaveImg from "../../../Images/wave_yellow.png";
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';


function LineChartLatency() {
    const dispatch = useDispatch()

    const [count, setCount] = React.useState(0);
    const [showAuthToken, setAuthToken] = useState(false);
    const [showGetMyParking, setGetMyParking] = React.useState([]);
    const [showGetMyParkingActivePayment, setGetMyParkingActivePayment] = React.useState([]);
    const [showPermitCount, setPermitCount] = useState([]);
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () => {
        setErrorHandling(false)
    }
    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    useEffect(() => {

        const retriveData = {
            "data_type": "payment",
            "array_timeline": {
                "type": "daily"
            }
        }
        //  Get Payment Latency count information
        initialfun()
        function initialfun() {
            Dashboard.getActivePayment(retriveData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setGetMyParking(response.data.data[0].metadata)
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    initialfun()
                }
            });
        }
        //  Get Active Payment count information
        const retriveDataActive = {
            "data_type": "payment",
            "array_timeline": {
                "type": "daily"
            }
        }
        initialfunxx()
        function initialfunxx() {
            Dashboard.getActivePaymentChart(retriveDataActive).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setGetMyParkingActivePayment(response.data.data[0].metadata)
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    initialfunxx()
                }
            });
        }

        //  Get Average Payment Permit information
        const retrivePermitData = {
            "count_type": "all_scopes"
        }
        initialpermitfun()
        function initialpermitfun() {
            Dashboard.getPermitCount(retrivePermitData).then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setPermitCount(response.data.data[0].metadata.avg_permit_utilization)
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    initialpermitfun()
                }
            });
        }
        setCount(100);
    }, []);

    const getmiliSecondToSecMinHours = function (milliseconds) {

        let hours = milliseconds / (60 * 60);
        let absoluteHours = Math.floor(hours);
        let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        let minutes = (hours - absoluteHours) * 60;
        let absoluteMinutes = Math.floor(minutes);
        let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        let seconds = (minutes - absoluteMinutes) * 60;
        let absoluteSeconds = Math.floor(seconds);
        let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        return h + ':' + m + ':' + s;
    }

    return (
        <>
            <div className="cstm_issurance_right">
                <div className="cstm-payment-latency-dash-main">

                    <div className="cstm-dash-by">
                        <div className="cstm-dash-by-cont-name">Average Payment Latency</div>
                        {showGetMyParking.length !== 0 ?
                            (showGetMyParking.map((item, index) => (
                                <div className="db-dash-avg-count">
                                    <div className="cstm-dash-per-cont">
                                        {getmiliSecondToSecMinHours(item.avg)}
                                    </div>
                                    <div className="cstm-dash-left-name">{item.vendor_name}</div>
                                </div>
                            )))
                            :
                            <div className="cstm-no-data-found-ap">No Data Found</div>
                        }

                    </div>
                </div>

                <div className="cstm-payment-active-dash-main">

                    <div className="cstm-dash-by">
                        <div className="cstm-dash-by-cont-name">Active Payment</div>

                        {showGetMyParkingActivePayment.length !== 0 ?
                            (showGetMyParkingActivePayment.map((item, index) => (
                                <div className="db-dash-avg-count">
                                    {/* <div className="cstm-dash-per-cont">{parseFloat(item.sum).toFixed(2)}</div> */}
                                    <div className="cstm-dash-per-cont">{item.sum}</div>
                                    <div className="cstm-dash-left-name">{item.vendor_name}</div>
                                </div>
                            )))
                            :
                            <div className="cstm-no-data-found-ap">No Data Found</div>
                        }

                    </div>
                </div>

                <div className="cstm-permit-dash-main">
                    <div className="cstm-dash-permit-by">
                        <div className="cstm-dash-by-avg-permit">Average Permit</div>
                        <div className="cstm_db_week_permit">
                            <div className="db-dash-permit-count">
                                <div className="cstm-dash-per-cont">{parseFloat(showPermitCount).toFixed(2)}</div>
                                <div className="cstm-dash-left-name cstm_week_permit_center">Weekly Permit</div>
                            </div>
                            <div className="db-dash-img-cstm">
                                <img src={WaveImg} alt="yellow_graph" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default LineChartLatency;
