import React, { useState, useEffect } from 'react';
import Image1 from "../../../Images/w1.png";
import Image2 from "../../../Images/w2.png";
import Image3 from "../../../Images/w3.png";
import close from '../../../Images/close.svg';
import Dashboard from "../../../Services/dashboard.service";

import { useDispatch } from 'react-redux';
import { clearoutAuthUserData } from '../../../actions/auth';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';


function RevenueIntegration() {
   const dispatch = useDispatch()
   const [count, setCount] = React.useState(0);
   const [showAuthToken, setAuthToken] = useState(false);
   const [showTotalRevenue, setTotalRevenue] = React.useState("");
   const [showTotalRevenueFromTime, setTotalRevenueFromTime] = React.useState("");
   const [showScofflawRevenue, setScofflawRevenue] = React.useState("");
   const [showRevenueZone, setRevenueZone] = React.useState("");
   const [showRevenueSquad, setRevenueSquad] = React.useState("");
   const [showCountValue, setCountValue] = useState([]);
   const [showErrorHandling, setErrorHandling] = useState(false)
   const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

   const closeError = () => {
      setErrorHandling(false)
   }
   const onClickLogin = (e) => {
      e.preventDefault();
      Cookiesremove();
      window.location.href = "/";
   }

   // When Revenue page open at that time this function call
   useEffect(() => {
      // Get Revenue Report
      const retriveData = {
         "count_type": "all_scopes"
      }
      initialrevenuefun()
      function initialrevenuefun() {
         Dashboard.getRevenueReport(retriveData).then(
            (response) => {
               if (response.data.status === 300) {
                  setAuthToken(true)
               } else {
                  if (response.data.status === false) {
                     setErrorHandling(true)
                     setErrorHandleMessage(response.data.message)
                  } else {
                     setTotalRevenue(response.data.data[0].metadata.counts.total_revenue)
                     setScofflawRevenue(response.data.data[0].metadata.counts.avg_revenue_by_officer)
                     setRevenueZone(response.data.data[0].metadata.counts.avg_revenue_by_zone)
                     setRevenueSquad(response.data.data[0].metadata.counts.avg_revenue_by_squad)
                  }
               }
            }
         ).catch((err) => {
            if (err.response.status === 401) {
               refreshTokenMiddleware(dispatch)
               initialrevenuefun()
            }
         });
      }

      // Get Integration Count Logs
      initialintegrationfun()
      function initialintegrationfun () {
         Dashboard.getIntegrationCount().then(
            (response) => {
               if (response.data.status === 300) {
                  setAuthToken(true)
               } else {
                  if (response.data.status === false) {
                     setErrorHandling(true)
                     setErrorHandleMessage(response.data.message)
                  } else {
                     setCountValue(response.data.data[0].metadata)
                  }
               }
            }
         ).catch((err) => {
            if (err.response.status === 401) {
               refreshTokenMiddleware(dispatch)
               initialintegrationfun()
            }
         });
      }
      setCount(100);
   }, []);

   return (
      <>

         <div className="row cstm-rri">
            <div className="cstm-rri-left">
               <div className="cstm-dashboard-mn-all">
                  <div className="cstm-dashboard-mn-all-left">Revenue Report</div>
                  {/* <div className="cstm-dashboard-mn-all-right"><a href="#">View Details <i className="fa fa-angle-right"></i></a></div> */}
               </div>
               <div className="main_cstm_revenue_sect">
                  <div className="cstm_revenue_sect_left"></div>
                  <div className="cstm_revenue_sect_rght">
                     <div className="cstm-rri-cont"><span className="cstm-rri-cont-tn">$</span>{showTotalRevenue}</div>
                     <div className="cstm-rri-total">Total Revenue</div>
                  </div>
               </div>
               <div className="cstm-rri-flr">
                  <div className="cstm-rri-flr-box">
                     <div className="cstm-rri-flr-box1">By Officer</div>
                     <div className="cstm-rri-flr-box2">${parseFloat(showScofflawRevenue).toFixed(2)}</div>
                     <div className="cstm-rri-flr-box3">
                        {/* <span className="cstm-rri-color-line" > */}
                        <div className="progress-main-bar">
                           <div className="cstm-progress-width cstm-progress-bar1" style={{ width: `${showScofflawRevenue * 100 / showTotalRevenue}%` }}>
                           </div>
                        </div>
                        {/* </span> */}
                     </div>
                  </div>
                  <div className="cstm-rri-flr-box">
                     <div className="cstm-rri-flr-box1">By Zone</div>
                     <div className="cstm-rri-flr-box2">${parseFloat(showRevenueZone).toFixed(2)}</div>
                     <div className="cstm-rri-flr-box3">
                        {/* <span className="cstm-rri-color-line" > */}
                        <div className="progress-main-bar">
                           <div className="cstm-progress-width cstm-progress-bar3" style={{ width: `${showRevenueZone * 100 / showTotalRevenue}%` }}>
                           </div>
                        </div>
                        {/* </span> */}
                     </div>
                  </div>
                  <div className="cstm-rri-flr-box">
                     <div className="cstm-rri-flr-box1">By Squad</div>
                     <div className="cstm-rri-flr-box2">${parseFloat(showRevenueSquad).toFixed(2)}</div>
                     <div className="cstm-rri-flr-box3">
                        {/* <span className="cstm-rri-color-line" > */}
                        <div className="progress-main-bar">
                           <div className="cstm-progress-width cstm-progress-bar4" style={{ width: `${showRevenueSquad * 100 / showTotalRevenue}%` }}>
                           </div>
                        </div>
                        {/* </span> */}
                     </div>
                  </div>
               </div>
            </div>
            <div className="cstm-rri-right">
               <div className="cstm-dashboard-mn-all">
                  <div className="cstm-dashboard-mn-all-left">Integrations</div>
                  {/* <div className="cstm-dashboard-mn-all-right"><a href="#">View Details <i className="fa fa-angle-right"></i></a></div> */}
               </div>
               <div className="cstm-rri-intbox">
                  <div className="cstm-rri-intbox-in">
                     <div className="cstm-inte3-in-left">
                        <div className="cstm-inte3-in-left-name">Payment Counts</div>
                        <div className="cstm-inte3-in-leftcont">{showCountValue.payment}</div>
                     </div>
                     <div className="cstm-inte3-in-right"><img src={Image1} /></div>
                  </div>
                  <div className="cstm-rri-intbox-in">
                     <div className="cstm-inte3-in-left">
                        <div className="cstm-inte3-in-left-name">Permit Counts</div>
                        <div className="cstm-inte3-in-leftcont">{showCountValue.permit}</div>
                     </div>
                     <div className="cstm-inte3-in-right"><img src={Image2} /></div>
                  </div>
                  <div className="cstm-rri-intbox-in">
                     <div className="cstm-inte3-in-left">
                        <div className="cstm-inte3-in-left-name">Scofflaw Counts</div>
                        <div className="cstm-inte3-in-leftcont">{showCountValue.scofflaw}</div>
                     </div>
                     <div className="cstm-inte3-in-right"><img src={Image3} /></div>
                  </div>
               </div>
            </div>
         </div>



      </>
   );
}

export default RevenueIntegration;
