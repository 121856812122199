import "react-image-gallery/styles/css/image-gallery.css";
import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery";
import BackbuttinIMag from "../../Images/back.png";
import box1 from "../../Images/ticket.png";
import PayImg from "../../Images/pay.png";
import { changeticket, changetotal, siteid } from "../../Global/site";
import dashboardService from "../../Services/dashboard.service";
import mainService from "../../Services/main.service";
import ImageSlider from "../../utile/imageSlider/imageSlider";
import Cookies from "js-cookie";
import Multiselect from "multiselect-react-dropdown";
import { refreshTokenMiddleware } from "../customMiddelware";
import ConfirmationModalComponent from "../../utile/modals/confirmationModal";

// check if site is Laz Parking
const isLazParking = () => {
  if (
    Cookies.get("siteinfo") === "8e9bc50c-95ab-11ee-9a11-c20b99673619" ||
    Cookies.get("siteinfo") === "9e9a9880-8ff6-11ee-bfcc-f66b6709ac8e"
  ) {
    return true;
  } else return false;
};

// check if site is allow collection status citation to pay 
// sites OCMD, mansfield, park cohasset
const isCollectionPayable = () => {
  if (
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
    Cookies.get("siteinfo") === "98ccd4bc-fa4c-11ed-8b64-665980bd7c45" ||
    Cookies.get("siteinfo") === "c293c922-fa4c-11ed-b4ca-ae854bfaee9a" ||
    Cookies.get("siteinfo") === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
    Cookies.get("siteinfo") === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c" ||
    Cookies.get("siteinfo") === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
    Cookies.get("siteinfo") === "5bd20490-dc2f-11ee-bf9e-72127457a419"
  ) {
    return true;
  } else {
    return false;
  }
}

const isOCMD = () => {
  if (Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
  Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
  Cookies.get("siteinfo") === "46b8a5e5-c1d0-420e-b825-c09712ee8665" ||
  Cookies.get("siteinfo") === "267ccc30-1bdd-11ed-8edb-e6f96eaf3014" ||
  Cookies.get("siteinfo") === "28022506-fe2f-11ee-aa58-82967aaeaa80" ||
  Cookies.get("siteinfo") === "c1a843e4-fe2e-11ee-ae3f-92040ec4c6a4" ||
  Cookies.get("siteinfo") === "9db6bd50-3681-11ee-b4ca-ae854bfaee9a" ||
  Cookies.get("siteinfo") === "ead64c86-3681-11ee-9c41-6ef2fb394a83") {
    return true
  } else {
    return false
  }
}

const isLeavenworth = () => {
  if (Cookies.get("siteinfo") === "46b8a5e5-c1d0-420e-b825-c09712ee8665" || Cookies.get("siteinfo") === "267ccc30-1bdd-11ed-8edb-e6f96eaf3014") {
    return true
  } else {
    return false
  }
}

const CitationSearchResult = () => {
  const dispatch = useDispatch();
  const changeTotal = changetotal();
  const navigate = useNavigate();
  const search = useLocation().search;
  const payload_string = search.replace(/\?/g, "");
  const showSideId = siteid();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { allCitationData } = useSelector((state) => state.other);

  const [payloadCitation, setPayloadCitation] = useState({});

  const [showPayloadCitationAmount, setPayloadCitationAmount] = useState({});

  const [cashAndChequeTotal, setCashAndChequeTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [totalCitation, setTotalCitation] = useState(0);
  const [selectedCitation, setSelectedCitation] = useState(0);
  const [errormsg, setErrorMsg] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isOpenPyC, setOpenPyC] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [service_fee, setService_fee] = useState(0);
  const changeName = changeticket();

  const [collectionpopup, setCollectionpopup] = useState(false);
  const [collectionticket, setCollectionticket] = useState([]);
  const [collectionnote, setCollectionnote] = useState(false);
  const [imageId, setimageId] = useState();
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const [detailIndex, setDetailIndex] = useState();
  const [totalDataLengthCount, setTotalDataLengthCount] = useState(0);
  const [showTickerNumber, setTicketNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [todosPerPage, setTodosPerPage] = useState(20);
  const [upperPageBound, setUpperPageBound] = useState(5);
  const [lowerPageBound, setLowerPageBound] = useState(0);
  const [isPrevBtnActive, SetisPrevBtnActive] = useState("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState("");
  const [noRecordFound, setRecordFound] = useState(true);
  const [pageBound, setPageBound] = useState(3);
  const [data, setData] = useState({});

  const [statusFilter, setStatusFilter] = useState([]);
  const [statusFilterSelectedArray, setstatusFilterSelectedArray] = useState(
    []
  );
  const [errMsg, setErrMsg] = useState("");
  const [names, setNames] = useState([]);

  const [selectedName, setSelectedName] = useState("");

  const [imageLoader, setImageLoader] = useState(false);
  const [totalValidCitation, setTotalValidCitation] = useState(0)
  const [showLimitedPopup, setShowLimitedPopup] = useState(false);
  const [totalPayableCitation,setTotalPayableCitation] = useState()
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [totalPayableCitationAmount, setTotalPayableCitationAmount] = useState(0)
  const [roAddress,setRoAddress] = useState([])

  const handleNameSelect = (name,exact) => {
    setRoAddress([])
    setSelectedName(name);
    pullCitationFun(name,exact);
  };

  useEffect(() => {
    // Load selected filter options from cookie when component mounts
    const selectedFiltersCookie = Cookies.get("selectedFilters");
    const Filter = Cookies.get("filter");
    Cookies.remove("filter");

    if (selectedFiltersCookie && Filter) {
      const selectedFilters = JSON.parse(selectedFiltersCookie);
      setstatusFilterSelectedArray(selectedFilters);
    }
  }, []);

  const onSubmitButtonClick = async (selectedList) => {
    try {
      const statusValues = selectedList.map((item) => item.status);
      const uniqueStatusValues = [...new Set(statusValues)];
      const categoryString =
        selectedList.length > 0
          ? "&status=" + uniqueStatusValues.join(",")
          : "";
      Cookies.set("selectedFilters", JSON.stringify(selectedList));
      setstatusFilterSelectedArray(selectedList);
      let payload = "";
      payload += "&page=" + 1;
      payload += "&limit=" + todosPerPage;
      setPageLoader(true);
      const res = await dashboardService.getTickitNumberApi(
        showSideId.siteinfo + payload_string + payload + categoryString
      );
      const allcitedata = await imagedataget(res.data.data);
      dispatch({ type: "CITATION_SEARCH_DATA", payload: allcitedata });
      await handleGetImage(res.data.data);
      const citeData = res.data.data;
      setPayloadCitation({
        citation_detail: citeData.citation_detail,
        total_citation: citeData.total_citation,
        total_due_amount: citeData.total_due_amount,
        lp_number: citeData.lp_number,
        state: citeData.state,
        service_fee: citeData.service_fee,
      });

      setPayloadCitationAmount({
        citation_detail: citeData.citation_detail,
        total_citation: citeData.total_citation,
        total_due_amount: citeData.total_due_amount,
        lp_number: citeData.lp_number,
        state: citeData.state,
        service_fee: citeData.service_fee,
      });

      let s_ci = 0,
        s_amt = 0,
        c_amt = 0,
        t_ci = citeData.total_citation,
        t_amt = 0;
      citeData?.citation_detail.map((item, i) => {
        if (
          item.status.toLowerCase() !== "voidandreissue" &&
          item.status.toLowerCase() !== "rescind" &&
          item.status.toLowerCase() !== "cancelled" &&
          item.status.toLowerCase() !== "paid" &&
          item.status.toLowerCase() !== "uncollectable" &&
          item.status.toLowerCase() !== "excused" &&
          (!isCollectionPayable()
            ? item.status.toLowerCase() !== "collection"
            : true)
        ) {
          if (item.ticket_type.toLowerCase() !== "warning" && item.amount_due > 0) {
            t_amt += item.amount_due + citeData.service_fee;
            if (item.checked) {
              s_ci += 1;
              s_amt += item.amount_due + citeData.service_fee;
              c_amt += item.amount_due;
            }
          }
        }
      });
      setSelectedCitation(s_ci);
      setTotalValidCitation(s_ci)
      setTotalCitation(t_ci);
      setSelectedAmount(s_amt);
      setCashAndChequeTotal(c_amt);
      setTotalAmount(citeData?.total_due_amount);
      setErrMsg(res.data.message);
      setPageLoader(false);
    } catch (err) {
      setPageLoader(false);
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        onSubmitButtonClick();
      }
    }
  };
  const getFuzzyData = () => {
    setPageLoader(true);
    dashboardService
      .getFuzzySearchTicket(showSideId.siteinfo, payload_string)
      .then((res) => {
        if (res.data.status) {
          if (res.data.data.names && res.data.data.names.length > 0) {
            handleNameSelect(res.data.data.names[0],true)
          } else {
            handleNameSelect(payload_string?.split("=")[1],false)
          }
          setNames(res.data.data.names);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          getFuzzyData();
        }
      });
  };
  useEffect(() => {
    if (payload_string) {
      setTicketNumber(showSideId.siteinfo + payload_string);
      if (allCitationData) {
        filterCitationdataFun(allCitationData);
      } else {
        if (payload_string?.split("=")[0] === "&user_name") {
          getFuzzyData()
        } else {
          pullCitationFun();
        }
      }
    } else if (allCitationData) {
      filterCitationdataFun(allCitationData);
    } else {
      navigate("/search");
    }
  }, []);
  const pullCitationFun = async (name,exact) => {
    let payload = "";
    payload += "&page=" + 1;
    payload += "&limit=" + todosPerPage;
    try {
      let requestUrl = "";

      if (name) {
        requestUrl = `&exact_search=${exact}&user_name=${name}`;
      } else {
        requestUrl = payload_string;
      }
      setPageLoader(true);
      const result = await dashboardService.getTickitNumberApi(
        showSideId.siteinfo + requestUrl
      );

      
      if (result.data.status && result.data.data.total_citation !== 0) {
        const collection = result.data.data.citation_detail;
        const targetStatus = isCollectionPayable()
        ? ""
        : "Collection" || "collection";
        
        const TicketNumberArray = [];

        if (result.data.data.ro_adddresses && result.data.data.ro_adddresses.length > 0) {
          
          const processedData = result.data.data.ro_adddresses.map(item => {
            const filteredItem = Object.fromEntries(
              Object.entries(item).filter(([key, value]) => value)
            );
            
            return Object.values(filteredItem).join(', ');
          });
          
          setRoAddress(processedData);
        }

        const Ticket = result.data.data.citation_detail.map((item) => {
          if (item.status === targetStatus) {
            TicketNumberArray.push(item.citation_number);
            setCollectionpopup(true);
            setCollectionnote(true);
          }
        });
        setCollectionticket(TicketNumberArray);

        setService_fee(result.data.data.service_fee);
        let newcitedata = result.data.data;
        setTotalDataLengthCount(result.data.data.total_citation);
        setData(newcitedata);
        const allcitedata = await imagedataget(newcitedata);
        dispatch({ type: "CITATION_SEARCH_DATA", payload: allcitedata });
        filterCitationdataFun(allcitedata);
        setPageLoader(false);
        await handleGetImage(newcitedata);
      } else {
        setPageLoader(false);
        setErrmsg("Citation data does not available.");
      }
    } catch (err) {
      setPageLoader(false);
      setErrmsg("Something went wrong.");
      // navigate('/search')
    }
  };

  async function handleGetImage(data) {
    try {
      setImageLoader(true);
      let newcitedata = { ...data };
      const batchSize = 100;
      const totalCitations = newcitedata.citation_detail.length;

      for (let i = 0; i < totalCitations; i += batchSize) {
        const batchCitations = newcitedata.citation_detail.slice(
          i,
          i + batchSize
        );
        const imageData = {
          links: {},
          download_type: "CitationImages",
          site_id: showSideId.siteinfo,
        };
        batchCitations.forEach((citation) => {
          const ticketNumber = citation.citation_number;
          const imageLinks = citation.images.map((image, imageIndex) => ({
            ticketNumber,
            index: imageIndex,
            link: image,
          }));
          imageLinks.forEach(({ ticketNumber, index, link }) => {
            imageData.links[`${ticketNumber}_${index}`] = link;
          });
        });
        const responseImage = await mainService.downloadImage(imageData);
        batchCitations.forEach((citation) => {
          const ticketNumbers = citation.citation_number;
          const ticketImages = responseImage.data.metadata.filter(
            (ele) => ele.image_spec.split("_")[0] == ticketNumbers
          );
          citation.new_images = ticketImages;
        });
      }
      setImageLoader(false);
      return newcitedata;
    } catch (err) {
      setErrmsg("Something went wrong.");
    }
  }

  async function imagedataget(data) {
    try {
      let newcitedata = { ...data };
      let checkedCitationNo = 0
      let totalValidCitation = 0
      let totalValidCitationAmount = 0
      for (let i = 0; i < newcitedata.citation_detail.length; i++) {
        // var imagesLink = newcitedata.citation_detail[i].images;
        // var imageData = {
        //   download_type: "CitationImages",
        //   links: Object.assign({}, imagesLink),
        //   site_id: showSideId.siteinfo,
        // };
        // const responseImage = await mainService.downloadImage(imageData);

        // newcitedata.citation_detail[i].new_images = responseImage.data.metadata;
        if (
          newcitedata.citation_detail[i].status.toLowerCase() !==
            "voidandreissue" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "rescind" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "cancelled" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "hearing" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "paid" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "uncollectable" &&
          newcitedata.citation_detail[i].status.toLowerCase() !== "excused" &&
          (!isCollectionPayable()
            ? newcitedata.citation_detail[i].status.toLowerCase() !==
              "collection"
            : true)
        ) {
          if (
            newcitedata.citation_detail[i].ticket_type.toLowerCase() !==
              "warning" &&
            newcitedata.citation_detail[i].amount_due > 0
          ) {
              totalValidCitation = totalValidCitation + 1
              totalValidCitationAmount = totalValidCitationAmount + newcitedata.citation_detail[i].amount_due
            if (checkedCitationNo < 12)
              newcitedata.citation_detail[i].checked = true;
            checkedCitationNo = checkedCitationNo + 1;
          } else {
            newcitedata.citation_detail[i].checked = false;
          }
        } else {
          newcitedata.citation_detail[i].checked = false;
        }
      }
      setTotalPayableCitation(totalValidCitation)
      setTotalPayableCitationAmount(totalValidCitationAmount)
      return newcitedata;
    } catch (err) {
      setErrmsg("Something went wrong.");
    }
  }
  const stableSort = (array, comparator) => {
    const stabilized = array.map((el, index) => [el, index]);
    stabilized.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilized.map((el) => el[0]);
  };

  const filterCitationdataFun = (citedata) => {
    setData(citedata);
    const statusOrder = [
      "valid",
      "suspended",
      "collection",
      "voidandreissue",
      "rescind",
      "cancelled",
      "paid",
    ];
    const result = stableSort(citedata?.citation_detail, (a, b) =>
    statusOrder.indexOf(a.status.toLowerCase()) - statusOrder.indexOf(b.status.toLowerCase())
);

    setService_fee(citedata.service_fee);
    setPayloadCitation({
      citation_detail: citedata?.citation_detail,
      total_citation: citedata.total_citation,
      total_due_amount: citedata.total_due_amount,
      lp_number: citedata.lp_number,
      state: citedata.state,
      service_fee: citedata.service_fee,
    });

    setService_fee(citedata.service_fee);

    setTotalDataLengthCount(citedata.total_citation);
    if (citedata?.citation_detail.length >= 20) {
      setRecordFound(false);
    }

    setPayloadCitationAmount({
      citation_detail: citedata?.citation_detail,
      total_citation: citedata.total_citation,
      total_due_amount: citedata.total_due_amount,
      lp_number: citedata.lp_number,
      state: citedata.state,
      service_fee: citedata.service_fee,
    });

    let s_ci = 0,
      s_amt = 0,
      c_amt = 0,
      t_ci = citedata.total_citation,
      t_amt = 0;
    citedata?.citation_detail.map((item, i) => {
      if (
        item.status.toLowerCase() !== "voidandreissue" &&
        item.status.toLowerCase() !== "cancelled" &&
        item.status.toLowerCase() !== "rescind" &&
        item.status.toLowerCase() !== "hearing" &&
        item.status.toLowerCase() !== "paid" &&
        item.status.toLowerCase() !== "uncollectable" &&
        item.status.toLowerCase() !== "excused" &&
        (!isCollectionPayable()
          ? item.status.toLowerCase() !== "collection"
          : true)
      ) {
        if (item.ticket_type.toLowerCase() !== "warning" && item.amount_due > 0) {
          t_amt += item.amount_due + citedata.service_fee;
          if (item.checked) {
            s_ci += 1;
            s_amt += item.amount_due + citedata.service_fee ;
            c_amt += item.amount_due;
          }
        }
      }
    });

    setSelectedCitation(s_ci);
    setTotalValidCitation(s_ci);
    setTotalCitation(t_ci);
    setSelectedAmount(s_amt);
    setCashAndChequeTotal(c_amt);
    setTotalAmount(citedata?.total_due_amount);
  };

  const handleCheck = (e, position, fine, citation) => {
    let checked_value = e.target.checked;
    setErrorMsg("");

    if (!checked_value) {
      let deduct = selectedAmount - fine - service_fee;
      let incresCash = cashAndChequeTotal - fine;
      setSelectedAmount(parseFloat(deduct.toFixed(2)));
      const cashDecTotal = Number(cashAndChequeTotal) - fine;
      setCashAndChequeTotal(parseFloat(cashDecTotal.toFixed(2)));
      setSelectedCitation(selectedCitation - 1);
      payloadCitation.citation_detail[position].checked = false;
      data.citation_detail[position].checked = false;
      showPayloadCitationAmount.citation_detail[position].checked = false;
    } else {
      if (selectedCitation < 12) {
        let incres = selectedAmount + fine + service_fee;
        let incresCash = cashAndChequeTotal + fine;
        setSelectedAmount(parseFloat(incres.toFixed(2)));
        const cashIncTotal = Number(cashAndChequeTotal) + fine;
        setCashAndChequeTotal(parseFloat(cashIncTotal.toFixed(2)));
        setSelectedCitation(selectedCitation + 1);
        payloadCitation.citation_detail[position].checked = true;
        data.citation_detail[position].checked = true;
        showPayloadCitationAmount.citation_detail[position].checked = true;
      } else {
        setShowLimitedPopup(true);
        payloadCitation.citation_detail[position].checked = false;
        data.citation_detail[position].checked = false;
        showPayloadCitationAmount.citation_detail[position].checked = false;
      }
    }
    setPayloadCitation({ ...payloadCitation });
    setData({ ...data });
    setPayloadCitationAmount({ ...showPayloadCitationAmount });
  };

  const handleChoosePayment = () => {
    if (selectedCitation === 12 && selectedCitation < totalPayableCitation) {
      setShowWarningModal(true)
    } else {
      if (selectedCitation > 0) {
        setErrorMsg("");
        // return
        if (isLoggedIn) {
          setOpenPyC(true);
        } else {
          navigate("/");
        }
      } else {
        setErrorMsg("Please check atleast one citation.");
      }
    }
  };

  const handleConfirmCitation = () => {
    setShowWarningModal(false)
    if (selectedCitation > 0) {
      setErrorMsg("");
      // return
      if (isLoggedIn) {
        setOpenPyC(true);
      } else {
        navigate("/");
      }
    } else {
      setErrorMsg("Please check atleast one citation.");
    }
  }

  const selectPamentMethod = (type) => {
    payloadCitation.select_amount = isOCMD() ? cashAndChequeTotal : selectedAmount;
    payloadCitation.select_citation = selectedCitation;
    showPayloadCitationAmount.select_amount = cashAndChequeTotal;
    showPayloadCitationAmount.select_citation = selectedCitation;

    if (type === "card") {
      navigate("/card-payment", {
        state: { payloadCitation, path: "search-ticket" },
      });
    } else if (type === "cash") {
      navigate("/cash-payment", {
        state: { showPayloadCitationAmount, path: "search-ticket" },
      });
    } else {
      navigate("/cheque-payment", {
        state: { showPayloadCitationAmount, path: "search-ticket" },
      });
    }
  };

  const citationDetails = (data) => {
    let payloadState = { ...payloadCitation };
    payloadState.citation_number = data.citation_number;
    payloadState.lp_number = data.plate;
    payloadState.state = data.state;
    payloadState.select_amount = data.amount_due + service_fee;
    payloadState.select_citation = 1;
    payloadState.citation_detail = [data];
    payloadState.citation_detail[0].checked = true;

    navigate(`/ticketreporting?&ticket_no=${data.citation_number}`);
  };

  const closeModel = () => {
    setCollectionpopup(false);
    setOpen(false);
    setOpenPyC(false);
    setShowLimitedPopup(false);
    setShowWarningModal(false)
  };

  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const slideImages = payloadCitation?.citation_detail?.map(
    (item) =>
      item.new_images && item.new_images.map((data) => ({ url: data.url }))
  );
  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "paid":
        return "rgb(13 237 115 / 38%)";
      case "cancelled":
      case "voidandreissue":
      case "dismissed":
      case "hearing":
        return "rgb(245 72 58)";
      case "suspended":
        return "#f9e00e6b";
      case "valid":
        return "lightblue";
      default:
        return "inherit";
    }
  }
  var passDataStateList = {
    type: "CitationStatusData",
    shard: 1,
  };
  const StatusFilterOptions = () => {
    mainService
      .violationCode(passDataStateList)
      .then((Response) => {
        if (Response.data.status) {
          setStatusFilter(Response.data.data[0].response);
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   refreshTokenMiddleware(dispatch);
        //   StatusFilterOptions();
        // }
      });
  };

  let checkedCitationNo = 0

  const checkValidCitation = (itm) => {
    if (
      itm.status.toLowerCase() !== "voidandreissue" &&
      itm.status.toLowerCase() !== "cancelled" &&
      itm.status.toLowerCase() !== "rescind" &&
      itm.status.toLowerCase() !== "hearing" &&
      itm.status.toLowerCase() !== "paid" &&
      itm.status.toLowerCase() !== "uncollectable" &&
      itm.status.toLowerCase() !== "excused" &&
      (!isCollectionPayable()
        ? itm.status.toLowerCase() !== "collection"
        : true)
    ) {
        if (
          itm.ticket_type.toLowerCase() !==
            "warning" &&
          itm.amount_due > 0
        ) {
          if (checkedCitationNo < 12) {
            checkedCitationNo = checkedCitationNo + 1
            itm.checked = true;
          }
        } else {
          itm.checked = false;
        }
    } else {
        itm.checked = false;
    }
  }

  const amountValidCitation = (itm) => {
    let s_ci = 0,
    s_amt = 0,
    c_amt = 0,
    t_ci = itm.total_citation,
    t_amt = 0;
    itm?.citation_detail.map((item, i) => {
    if (
      item.status.toLowerCase() !== "voidandreissue" &&
      item.status.toLowerCase() !== "rescind" &&
      item.status.toLowerCase() !== "cancelled" &&
      item.status.toLowerCase() !== "paid" &&
      item.status.toLowerCase() !== "uncollectable" &&
      item.status.toLowerCase() !== "excused" &&
      (!isCollectionPayable()
        ? item.status.toLowerCase() !== "collection"
        : true)
    ) {
      if (item.ticket_type.toLowerCase() !== "warning" && item.amount_due > 0) {
        t_amt += item.amount_due + payloadCitation.service_fee;
        if (item.checked) {
          s_ci += 1;
          s_amt += item.amount_due + payloadCitation.service_fee;
          c_amt += item.amount_due;
        }
      }
    }
  });
  setSelectedCitation(s_ci);
  setTotalCitation(t_ci);
  setSelectedAmount(s_amt);
  setCashAndChequeTotal(c_amt);
  }

  const totalCitationCheckHandler = (e) => {
    if (e.target.checked) {
      payloadCitation.citation_detail.map(itm => {
        checkValidCitation(itm)
      }
      )
      amountValidCitation(payloadCitation)
      let checkedCitationNo2 = 0;
      data.citation_detail.map(itm =>{
        if (checkedCitationNo2 <= 12) { 
          checkValidCitation(itm)
          checkedCitationNo2 = checkedCitationNo2 + 1
        }
      })
      let checkedCitationNo3 = 0;
      showPayloadCitationAmount.citation_detail.map(itm => {
        if (checkedCitationNo3 <= 12) { 
          checkValidCitation(itm)
          checkedCitationNo3 = checkedCitationNo3 + 1
        }
      });


    } else {
      payloadCitation.citation_detail.map(itm => itm.checked = false)
      setSelectedAmount(parseFloat(0));
      setCashAndChequeTotal(parseFloat(0).toFixed(2));
      setSelectedCitation(0);
      data.citation_detail.map(itm => itm.checked = false)
      showPayloadCitationAmount.citation_detail.map(itm => itm.checked = false)
    }

    setPayloadCitation({ ...payloadCitation });
    setData({ ...data });
    setPayloadCitationAmount({ ...showPayloadCitationAmount });
  }

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid searchResult-section right-panel cstm-right-panel">
          <div className="">
            <div className="col-12 back-link cstm-back-citationsearchresult">
              <Link to="/search">
                <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                Back
              </Link>
            </div>
          </div>
          <div className="cstm-citation-main-header-row search-content-main">
            <div className="col-6 search-content padding-col1">
              <h3>Search For {changeTotal}s</h3>
            </div>
            <div className="col-6 cstm-rm">
              <div className="filter-main-section ">
                Filter By:
                <div className="select-drop-arrow filter_cstm1">
                  <Multiselect
                    name="status"
                    selectedValues={statusFilterSelectedArray}
                    onSelect={(e) => setstatusFilterSelectedArray(e)}
                    onRemove={(e) => setstatusFilterSelectedArray(e)}
                    options={statusFilter.map((item) => ({
                      status: item.status,
                      id: item.status,
                    }))}
                    displayValue="status"
                    placeholder="Select Status"
                    showCheckbox
                  />
                </div>
                <button
                  className="licence-print report-mr"
                  type="button"
                  onClick={() => {
                    onSubmitButtonClick(statusFilterSelectedArray);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div>
          <div>
                {names && names.length > 0 && (
                  <div>
                    <p>
                      Similar Search Result For : {decodeURIComponent(payload_string?.split("=")[1])}
                    </p>
                    {names.map((item, index) => (
                      <div
                        key={index}
                        className="filter-main-section chip"
                        style={{
                          cursor: "pointer",
                          background: "rgb(212 229 241)",
                          color: selectedName === item ? "black" : "gray",
                          fontWeight: selectedName === item ? "bold" : "normal",
                        }}
                        onClick={() => handleNameSelect(item,true)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
            </div>
            <div>
              {roAddress && roAddress.length > 0 && (
                <ul>
                  {roAddress.map((itm) => (
                    <li>
                      {itm}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {pageLoader ? (
            <div className="text-center">
              <div className="spinner-border"></div>
            </div>
            ) : (!payloadCitation.citation_detail ?
              <div className="row">
                <div className="col-12 search-no-record-found">
                  <p className="total-count-records">
                    No Records Found{" "}
                  </p>
                </div>
              </div>
            : 
                <>
              

              <div className="row-align displayResult displycitysearchHeader">
                  <div className="cstm-citationresult-field">
                    {totalValidCitation > 0 && 
                      <div className="form-group-checkbox2">
                      <input
                          type="checkbox"
                          id='checkbox4-top'
                        onChange={(e) => totalCitationCheckHandler(e)}
                        checked={totalValidCitation === selectedCitation}
                        />
                        <label htmlFor={`checkbox4-top`}></label>
                        </div>
                   }
                  <div className="result1">
                    <p>Total {changeTotal}s</p>
                    <h5>{payloadCitation.total_citation || "-"}</h5>
                  </div>
                  <div className="result2">
                    <p>Total Amount</p>
                    <h5>${convertDecimal(totalAmount)}</h5>
                  </div>
                  <div className="result1">
                    <p>Selected {changeTotal}s</p>
                    <h5>{selectedCitation || "-"}</h5>
                  </div>
                  <div className="result2">
                    <p>Selected Amount</p>
                    <h5>${convertDecimal(cashAndChequeTotal)}</h5>
                  </div>
                  {cashAndChequeTotal > 0 && (
                    <div className=" align-button cstm-pay-selected-btn">
                      <button
                        type="button"
                        onClick={handleChoosePayment}
                        className="modal-payment"
                      >
                        Pay
                      </button>
                      <span className="errormessage">{errormsg}</span>
                    </div>
                  )}
                </div>
              </div>
              {pageLoader ? (
                <div className="text-center">
                  <div className="spinner-border"></div>
                </div>
              ) : (
                // test
                (payloadCitation.citation_detail || []).map(
                  (new_item, index) => (
                    <div
                      className={`cstm-citation-data-main-row row-align details-searchCitation ${
                        new_item.status.toLowerCase() === "paid" ||
                        (!isCollectionPayable() &&
                          new_item.status === "Collection")
                          ? "paid-data-gray-out"
                          : ""
                      }`}
                      key={index}
                    >
                      <div
                        className="col-xl-1 col-12 right-panel cstm-panel-checkbox"
                        style={{
                          backgroundColor: getStatusColor(new_item.status),
                        }}
                      >
                        {new_item.status.toLowerCase() === "voidandreissue" ||
                        new_item.status.toLowerCase() === "cancelled" ||
                        new_item.status.toLowerCase() === "rescind" ||
                        new_item.status.toLowerCase() === "hearing" ||
                        new_item.status.toLowerCase() === "paid" ||
                        new_item.status.toLowerCase() === "uncollectable" ||
                        new_item.status.toLowerCase() === "excused"  ? (
                        ""
                      ) : new_item.ticket_type.toLowerCase() === "warning" ||
                        new_item.amount_due <= 0 ? (
                        ""
                      ) : !isCollectionPayable() && new_item.status.toLowerCase() === 'collection' ? (
                        ""
                      ) : (
                        <div className="form-group-checkbox2">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheck(
                                e,
                                index,
                                new_item.amount_due,
                                new_item,
                              )
                            }
                            id={`checkbox4-${index}`}
                            checked={new_item.checked}
                          />
                          <label htmlFor={`checkbox4-${index}`}></label>
                        </div>
                      )}
                    </div>
                    <div className="col-xl-11 col-12 search-description">
                      <div className="cstm-citation-data border-bottom">
                        <div className="cstm-data-col result1 ">
                          <p>{changeName} Number</p>
                          <h5>
                            {new_item.citation_number !== undefined &&
                              new_item.citation_number !== null &&
                              new_item.citation_number !== "" &&
                              new_item.citation_number}
                          </h5>
                        </div>
                        <div className="cstm-data-col result1 ">
                          <p>Issue date</p>
                          <h5>
                            {new_item.issue_date !== undefined &&
                              new_item.issue_date !== null &&
                              new_item.issue_date !== "" &&
                              moment(new_item.issue_date).format("MM/DD/YYYY")}
                          </h5>
                        </div>
                        <div className="cstm-data-col result1  hide-col">
                          <p>Plate</p>
                          <h5>
                            {new_item.plate !== undefined &&
                              new_item.plate !== null &&
                              new_item.plate !== "" &&
                              new_item.plate}
                          </h5>
                        </div>
                        <div className="cstm-data-col result1  hide-col">
                          <p>Due Date</p>
                          <h5>
                            {new_item.due_date !== ''
                                && new_item.due_date !== undefined &&
                                new_item.due_date !== null &&
                                new_item.due_date !== '' && new_item.due_date !== "0001-01-01T00:00:00Z" ?
                                moment(new_item.due_date).utc().format('MM/DD/YYYY')
                            : '-'}
                          </h5>
                        </div>
                        <div className="cstm-data-col result1 ">
                          <p>State</p>
                          <h5>
                            {new_item.state !== undefined &&
                              new_item.state !== null &&
                              new_item.state !== "" &&
                              new_item.state}
                          </h5>
                        </div>
                      </div>
                      <div className="cstm-citation-data border-bottom">
                        <div className="cstm-data-col result1 ">
                          <p>Amount</p>
                          <h5>
                            $
                            {new_item.amount !== undefined &&
                              new_item.amount !== null &&
                              new_item.amount !== "" &&
                              convertDecimal(new_item.amount)}
                          </h5>
                          </div>
                          <div className="cstm-data-col result1 ">
                          <p>Amount due</p>
                          <h5>
                            $
                            {new_item.amount_due !== undefined &&
                              new_item.amount_due !== null &&
                              new_item.amount_due !== "" &&
                              convertDecimal(new_item.amount_due)}
                          </h5>
                        </div>
                          <div className="cstm-data-col result1  hide-col">
                            <p>Location</p>
                            <h5>
                              {new_item.location !== undefined &&
                                new_item.location !== null &&
                                new_item.location !== "" &&
                                new_item.location}
                            </h5>
                          </div>
                          <div className="cstm-data-col result1  hide-col">
                            <p>Violation</p>
                            <h5>
                              {new_item.violation !== undefined &&
                                new_item.violation !== null &&
                                new_item.violation !== "" &&
                                new_item.violation}
                            </h5>
                          </div>
                          <div className="cstm-data-col result1 ">
                            <p>Late Fine</p>
                            <h5>
                              $
                              {new_item.late_fee !== undefined &&
                                new_item.late_fee !== null &&
                                new_item.late_fee !== "" &&
                                convertDecimal(new_item.late_fee)}
                            </h5>
                          </div>
                          </div>
                        <div className="cstm-citation-data border-bottom">
                          <div className="cstm-data-col result1 hide-col">
                            <p>NSF fee</p>
                            {new_item.nsf_fee ? (
                              <h5>${convertDecimal(new_item.nsf_fee)}</h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col">
                            <p>Boot tow fee</p>
                            {new_item.boot_tow_fee ? (
                              <h5>${convertDecimal(new_item.boot_tow_fee)}</h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col">
                            <p>Admin fee</p>
                            {new_item.administrative_fee ? (
                              <h5>
                                ${convertDecimal(new_item.administrative_fee)}
                              </h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col">
                            <p>Collection fee</p>
                            {new_item.collection_fee ? (
                              <h5>
                                ${convertDecimal(new_item.collection_fee)}
                              </h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col ">
                            <p>Parking fee</p>
                            {new_item.parking_fee ? (
                              <h5>${convertDecimal(new_item.parking_fee)}</h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                        </div>
                        <div className="cstm-citation-data border-bottom">
                          {!isOCMD() ? (<div className="cstm-data-col result1 hide-col">
                            <p>Service fee</p>
                            {payloadCitation.service_fee !== "" &&
                            payloadCitation.service_fee !== undefined &&
                            payloadCitation.service_fee !== null ? (
                              <h5>${convertDecimal(payloadCitation.service_fee)}</h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>) : null}
                          <div className="cstm-data-col result1 hide-col">
                            <p>Charge back fee</p>
                            {new_item.charge_back_fee ? (
                              <h5>
                                ${convertDecimal(new_item.charge_back_fee)}
                              </h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col">
                            <p>Appeal rejected fee</p>
                            {new_item.appeal_rejected_fee ? (
                              <h5>
                                ${convertDecimal(new_item.appeal_rejected_fee)}
                              </h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col">
                            <p>Hearing rejected fee fee</p>
                            {new_item.hearing_rejected_fee ? (
                              <h5>
                                ${convertDecimal(new_item.hearing_rejected_fee)}
                              </h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </div>
                          <div className="cstm-data-col result1 hide-col">
                            <p>{changeName} Type</p>
                            <h5>{new_item.ticket_type || "-"}</h5>
                          </div>
                          </div>
                          <div className="cstm-citation-data border-bottom">
                          {new_item?.category ? (
                            <div className="cstm-data-col result1">
                              <p>{changeName} Category</p>
                              <h5>
                                {new_item.category === "ticket"
                                  ? "Ticket"
                                  : "Invoice"}
                              </h5>
                            </div>
                          ) : null}
                          <div className="cstm-data-col result1">
                            <p>Status</p>
                            <h5>{new_item.status}</h5>
                          </div>
                          <div className="cstm-data-col result1 ">
                          <p>Notice Date</p>
                          <h5>
                            {new_item.notice_date !== ""
                              ? new_item.notice_date !== undefined &&
                              new_item.notice_date !== null &&
                              new_item.notice_date !== "" &&
                              moment(new_item.notice_date).format(
                                "MM/DD/YYYY"
                              )
                              : "-"}
                          </h5>
                        </div>
                      </div>
                      <div className="row cstm-citationsearchresult-img-button">
                        <div className="col-10">
                          {(new_item.new_images || []).map((img, j) => (
                            <div
                              key={j}
                              className="AppImage"
                              onClick={() => (
                                setimageId(j),
                                setShowImagesModalSlider(true),
                                setDetailIndex(index)
                              )}
                            >
                              <img src={img.url} />
                              {/* <ModalImage
                                                        small={img.url}
                                                        large={img.url}
                                                    /> */}
                                </div>
                              ))
                            }
                          </div>
                          <div className="col-2 result-button cstm-viewdetsils-button-citationsearchresult">
                            {/* <button onClick={() => appealCitationInfo(new_item.citation_number)}>Appeal</button> */}
                            <button onClick={() => citationDetails(new_item)}>
                              View Details
                            </button>
                          </div>
                        </div>
                        {!isCollectionPayable() &&
                          new_item.status.toLowerCase() === "collection" && (
                            <div className="cstm-notes-only">
                              <div className="result1">
                                <p>Note</p>
                                <h5>
                                  This citation has been sent to collections per
                                  local ordinance. To make payment on this
                                  account you can contact collection Services at{" "}
                                  <b>877-751-7115</b> to make payment.
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )
                )
              )}
              {!pageLoader &&
                payloadCitation &&
                payloadCitation.citation_detail &&
                payloadCitation.citation_detail.length === 0 && (
                  <div className="text-center">{errMsg}</div>
                )}

              {/* {noRecordFound !== true && (
                <>
                  <div className="cstm-permit-trf">
                    <div className="col-12">
                      <div className="total-count-records">
                        Total <b> {totalDataLengthCount} </b> Records Found{" "}
                      </div>
                    </div>
                    <ul className="cstm-pagination-added-bottom">
                      {renderPrevBtn}
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}
                      {renderNextBtn}
                    </ul>
                  </div>
                </>
              )} */}

              {!isCollectionPayable() && collectionpopup && (
                <div
                  className="modal-dialog modal-dialog-centered cstm-popup-tn cstm-notes-citations-search"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        className="cstm-payment-close"
                        onClick={closeModel}
                      >
                        ×
                      </button>
                      <div className="row">
                        <div className="col-12 form-group formgroup-main">
                          <h4>Note</h4>
                          <h5>Ticket Number: {collectionticket.join(", ")}</h5>
                        </div>
                        <div className="col-12 remark-erroe-main">
                          <p className="remark-errormessage">
                            Above
                            {collectionticket.length === 1
                              ? " citation has "
                              : " citations have "}
                            been sent to collections per local ordinance. To
                            make payment on this account you can contact
                            collection Services at <b>877-751-7115</b> to make
                            payment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  )}
                  </>
          )}
            </div>
              {showLimitedPopup && (
                <div
                  className="modal-dialog modal-dialog-centered cstm-popup-tn cstm-notes-citations-search"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        className="cstm-payment-close"
                        onClick={closeModel}
                      >
                        ×
                      </button>
                      <div className="row">
                        <div className="col-12 remark-erroe-main">
                          <p className="remark-errormessage">
                          You may only choose a maximum of 12 citations in total. To
                      select an additional citation, you must first deselect one
                      of the currently selected citations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          )}

          {showWarningModal && (
            <ConfirmationModalComponent
              displayMsg={
                <p>
                  <b>Alert:</b> <br />

                  Total outstanding citations : <b>{totalPayableCitation}</b>, totaling : <b>${convertDecimal(totalPayableCitationAmount)}</b> <br />
                  Citations selected to pay : <b>{selectedCitation}</b>, totaling : <b>${convertDecimal(cashAndChequeTotal)}</b> <br /> <br />

                  <b>Note:</b> The inquired account has {totalPayableCitation} citations eligible for payment, which exceeds the maximum number of {selectedCitation} citations that can be processed in a single transaction. We have auto-selected the 12 oldest payable citations, however if you wish to customize your selection, click the cancel button below and select up to {selectedCitation} citations in the result screen prior to clicking pay. <br /> <br />

                  Be advised that after payment for the selected citations is complete, you will still have outstanding citations that must be paid with an additional transaction. 
                </p>
              }
              handleCloseModel={closeModel}
              handleConfirm={handleConfirmCitation}
            />
              )}
          {isOpen && (
            <div className="main-body-modal">
              <div className="main-modal">
                <div className="cstm-close">
                  <button onClick={closeModel}>×</button>
                </div>
                <div className="cstm-modal-body">
                  <div className="modal-body cstm-modal-body">
                    <div className="text-center cstm-paymnet-img">
                      <img src={box1} alt="img" className="img-fluid" />
                    </div>
                    <h3 className="text-center">Choose your payment method</h3>
                    <p className="text-center mb-4">
                      The City Of Encinitas offers three ways to pay parking{" "}
                      {changeName.charAt(0).toLowerCase() +
                        changeName.slice(1) +
                        "s"}{" "}
                      so that you can choose the method that is most convenient
                      for you.
                    </p>
                    <div className="row">
                      <div className={`${!isLazParking() ? "col-4" : "col-6"}`}>
                        <button className="payment-button">Cash</button>
                      </div>
                      {!isLazParking() && (
                        <div className="col-4">
                          <button className="payment-button">Check</button>
                        </div>
                      )}
                      <div className={`${!isLazParking() ? "col-4" : "col-6"}`}>
                        <button className="payment-button">Cards</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

      {isOpenPyC && (
        <div className="main-body-modal cstm-csr-pg">
          <div className="main-modal">
            <div className="modal-content">
              <div className="modal-body cstm-modal-body">
                <button className="cstm-payment-close" onClick={closeModel}>
                  ×
                </button>
                <img src={PayImg} alt="img" className="img-fluid" />
                <h3>Choose your payment method</h3>
                <div className="row">
                  <div className={`${!isLazParking() ? "col-4" : "col-6"}`}>
                    <button
                      className="payment-button"
                      onClick={(e) => selectPamentMethod("cash", e)}
                    >
                      Cash
                    </button>
                  </div>
                  {(!isLazParking()) && (
                    <div className="col-4">
                      <button
                        className="payment-button"
                        onClick={(e) => selectPamentMethod("cheque", e)}
                      >
                        Check
                      </button>
                    </div>
                  )}
                  <div className={`${!isLazParking() ? "col-4" : "col-6"}`}>
                    <button
                      className="payment-button"
                      onClick={(e) => selectPamentMethod("card", e)}
                    >
                      Cards
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showImagesModalSlider && (
        <div className="main-body-modal cstm-ticket-details-pop">
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider
                    slides={slideImages[detailIndex]}
                    index={imageId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
      </div>
    </>
  );
};
export default CitationSearchResult;
