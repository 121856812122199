import React, { useState, useEffect, useCallback } from "react";
import transfer from "../../../../Images/transfer.png";
import DownloadImg from "../../../../Images/download_img_btn.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import DatePicker from "react-datepicker";
import "jspdf-autotable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Bar, Pie } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { clearoutAuthUserData } from "../../../../actions/auth";
import Reportingsetup from "../../../../Services/dashboard.service";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
ChartJS.register(ArcElement, Tooltip, Legend, Title);

export default function PaymentBreakdown() {
  //creating different states below as per old code
  const [data, setData] = useState({});
  const [barGraphOptions, setBarGraphOptions] = useState([]);
  const currentDate1 = new Date();
  const secondDay = new Date(currentDate1);
  secondDay.setDate(currentDate1.getDate() + 1);

  secondDay.setHours(23, 59, 59, 999);

  const [filterDate, setFilterDate] = useState({
    start_date: new Date().setHours(0, 0, 0),
    end_date: secondDay
  });
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [params, setParams] = useState("");
  const [showDownloadOption, setDownloadOption] = useState(false);
  const [chartOptions, setChartOptions] = useState([]);
  const [sortBy, setSortBy] = useState("violation_code");
  const [sortOrder, setSortOrder] = useState("asc");
  const dispatch = useDispatch();
  const [shouldFetchTodayData, setShouldFetchTodayData] = useState(true);
  const currentDate = new Date().toLocaleDateString();
  const [paymentCompletion, setPaymentCompletion] = useState('');
  const fetchData = useCallback(async () => {
    setLoader(true);
    try {
      const start = shouldFetchTodayData
        ? new Date().toISOString()
        : new Date(filterDate.start_date).toISOString();
      const end = new Date(filterDate.end_date).toISOString();
      setParams(`?issue_ts_from=${start}&issue_ts_to=${end}`);
      if (params) {
        const response = await Reportingsetup.paymentBreakdownSummary(params);
        setTableData(response.data.data.table_data);
        setData(response.data.data);
        setLoader(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      if (error.response.status === 401) {
        dispatch(clearoutAuthUserData());
        window.location.href = "/";
      }
    }
  }, [dispatch, params, shouldFetchTodayData]);

  useEffect(() => {
    setLoader(true);
    if (params || shouldFetchTodayData) {
      const fetchDataAndUpdateState = async () => {
        await fetchData();
        setLoader(false);
      };
      fetchDataAndUpdateState();
    }
  }, [shouldFetchTodayData]);

  useEffect(() => {
    const keysToRemove = [
      "card_count",
      "cash_count",
      "table_data",
      "chart_data",
      "cheque_count",
    ];
    if (data) {
      const filteredKeys = Object.keys(data).filter(
        (key) => !keysToRemove.includes(key)
      );
      const filteredData = filteredKeys.reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
      if (data) {
        const chartData = data.chart_data?.sort((a, b) => b.total - a.total);
        const chartDataArray = Object.keys(chartData || {}).map(
          (key) => chartData[key]
        );

        setChartOptions({
          labels: chartDataArray.map((item) => item.violation_code),
          datasets: [
            {
              data: chartDataArray.map((item) => item.total),
              options: {
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      return "$" + data.datasets[0].data[tooltipItem.index];
                    },
                  },
                },
              },
              backgroundColor: [
                "#ff6384",
                "#36a2eb",
                "#F2CD5C",
                "#A2A2F2",
                "#F2A2A2",
                "#84ff63",
                "#6384ff",
                "#63ff84",
                "#ff8463",
                "#8463ff",
                "#eb36a2",
                "#36eba2",
                "#a236eb",
                "#ebe336",
                "#a2eb36",
                "#eba236",
              ],
              label: "Total",
            },
          ],
        });
      }
      setBarGraphOptions({
        labels: ["Cash", "Card", "Cheque"],
        barPercentage: 0.5,
        barThickness: 40,
        maxBarThickness: 30,
        minBarLength: 8,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: (value) => `$${value}.00`,
            },
          },
        },
        datasets: [
          {
            label: "Payment Summary ",
            data: Object.values(filteredData),
            backgroundColor: ["#ff6384", "#36a2eb", "#F2CD5C"],
          },
        ],
      });
    }
  }, [fetchData, data]);
  //submit button function
  const handleFilter = async () => {
    // setLoader(true);
    setShouldFetchTodayData(false);
    const start = new Date(filterDate.start_date).toISOString();
    const end = new Date(filterDate.end_date).toISOString();
    if (paymentCompletion !== "") {
      var paymentStatus = "&payment_done=" + paymentCompletion;
    } else {
      var paymentStatus = "";
    }
const newParams = `?issue_ts_from=${start}&issue_ts_to=${end}`+paymentStatus;
    setParams(newParams);
    try {
      if (newParams) {
        const response = await Reportingsetup.paymentBreakdownSummary(
          newParams
        );
        setData(response.data.data);
        setTableData(response.data.data.table_data);
        setLoader(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      if (error.response.status === 401) {
        dispatch(clearoutAuthUserData());
        window.location.href = "/";
      }
    }
  };

  //Sorting function for the table
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };
  //set table body(sortable)
  const tableRows =
    tableData && Array.isArray(tableData) && tableData.length > 0 ? (
      tableData
        .sort((a, b) => {
          if (sortOrder === "asc") {
            return a[sortBy].toString().localeCompare(b[sortBy].toString());
          } else {
            return b[sortBy].toString().localeCompare(a[sortBy].toString());
          }
        })

        .map((data) => (
          <tr>
            <td>{data?.violation_code}</td>
            <td>{data?.violation_description}</td>
            <td>{data?.group_count}</td>
            <td>{data?.cash_count}</td>
            <td>
              $
              {data?.cash_total.toFixed(2).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>{data?.card_count}</td>
            <td>
              $
              {data?.card_total.toFixed(2).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>{data?.cheque_count}</td>
            <td>
              $
              {data?.cheque_total.toFixed(2).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              $
              {data?.total_amount.toFixed(2).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        ))
    ) : (
      <td colSpan="9" align="center">
        No Data Found for Set date
      </td>
    );
  //set table footer
  const tableFooter =
    tableData && Array.isArray(tableData) && tableData.length > 0 ? (
      <tr style={{ fontWeight: "bold", marginTop: "1em" }}>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
          colSpan="2"
        >
          Total:
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {data?.length}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {data?.cash_count}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {`$${data?.cash_total.toFixed(2)}`}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {data?.card_count}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {`$${data?.card_total.toFixed(2)}`}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {data?.cheque_count}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {`$${data?.cheque_total.toFixed(2)}`}
        </td>
        <td
          style={{
            fontWeight: "600",
            fontSize: "0.8em",
          }}
        >
          {`$${data?.total_amount.toFixed(2)}`}
        </td>
      </tr>
    ) : null;
  //toggle for download button dropdown
  const onClickDonwloadButton = (e) => {
    e.preventDefault();
    setDownloadOption(!showDownloadOption);
  };
  //Download table and bar pdf

  const donwloadPDFFile = (e) => {
    e.preventDefault();
    const fileName = `Revenue-summary-${currentDate}.pdf`;
    const chartCanvas = document.getElementById("barChart");
    const canvas = document.createElement("canvas");
    canvas.width = 900;
    canvas.height = 1200;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(chartCanvas, 0, 0);
    const imgData = canvas.toDataURL();
    var doc = new jsPDF("l", "pt", "a4");
    doc.autoTable({ html: "#cstm-dashboard-tb-display" });
    const autoTableHeight = doc.autoTable.previous.finalY;
    if (autoTableHeight + 600 > doc.internal.pageSize.height) {
      doc.addPage();
    }
    doc.addImage(imgData, "JPEG", 20, 20, 600, 600);
    doc.save(fileName);
  };
  //Print whole page
  const printPageFile = (e) => {
    e.preventDefault();
    window.print();
  };
  //Print CSV file
  const donwloadCsvFile = (e) => {
    e.preventDefault();
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(","));
    }
    downloadCSV(csv.join("\n"), `Revenue-summary-${currentDate}.csv`);
  };
  const downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  };
  const onChangeFilterDate = (date, name) => {
    const newFilterDate = { ...filterDate };
    if (!date) {
      newFilterDate['end_date'] = "";
      setFilterDate(newFilterDate);
      return;
    }
    newFilterDate[name] = date;
  const timeValue = new Date(date);
  const hours = timeValue.getHours();
  const minutes = timeValue.getMinutes();
    if (name === 'end_date') {
        const currentFilterToDate = new Date(filterDate.end_date);
      const isDateChange = !filterDate?.end_date || (hours == 0 && minutes == 0) || date?.getDate() !== currentFilterToDate?.getDate();
      if(isDateChange){
        let formattedDate = new Date(date);
        formattedDate.setHours(23, 59, 59, 999);
        newFilterDate['end_date'] = formattedDate;
      }
      setFilterDate(newFilterDate);
    } else {
      setFilterDate(newFilterDate);
    }
  };
  
 const isOCMD = () => {
    if(Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||Cookies.get("siteinfo")==="e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8"|| Cookies.get("siteinfo")==="183db4ec-a15e-11ee-b39a-bebc79a4d1a3") {
      return true
    } else {
      return false
    }
  }
  const handlePaymentCompletionChange = (e) => {
    setPaymentCompletion(e.target.value);
  };  
  return (
    <div className="row">
      <div className="report-searchbar"></div>

      <div className="col-lg-10 col-md-9 col-sm-12 cstm-ctn cstm-operate-top">
        <div className="data_table_box">
          <div className="data_tbl_ttl">
            <h4 className="cstm-all-pg-ttl operate-ttl ">Payment Summary</h4>
          </div>
          <div className="data_tbl_btn">
            <button onClick={printPageFile} className="btn cstm_rev_down_btn">
              Print
            </button>
            <button
              onClick={onClickDonwloadButton}
              className="btn cstm_rev_down_btn"
            >
              Download <img src={DownloadImg} alt="download" />
              {showDownloadOption ? (
                <>
                  <div className="cstm-download-icon">
                    <button
                      onClick={donwloadPDFFile}
                      className="cstm-download-btn-sec"
                    >
                      Pdf
                    </button>

                    <button
                      onClick={donwloadCsvFile}
                      className="cstm-download-btn-sec"
                    >
                      CSV
                    </button>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="cstm-download-btn-sec"
                      table="cstm-dashboard-tb-display"
                      filename={"Revenue Summary " + currentDate}
                      sheet="Revenue Summary"
                      buttonText="Excel"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
        <div className="report-searchbar">
          <form>
            <div style={{display:"flex"}}>
            <div className="report-date report-mr">
              <DatePicker
                showTimeSelect
                timeIntervals={1}
                dateFormat="MM/dd/yyyy h:mmaa"
                className="cstm-pull-details "
                timeFormat="HH:mm"
                name="start_date"
                selected={filterDate.start_date}
                onChange={date => onChangeFilterDate(date, 'start_date')}
                placeholderText="MM/DD/YYYY HH:MM"
              />
              <img src={transfer} alt="transfer" className="date-transfer" />
              <DatePicker
                showTimeSelect
                timeIntervals={1}
                dateFormat="MM/dd/yyyy h:mmaa"
                className="cstm-pull-details "
                timeFormat="HH:mm"
                name="end_date"
                selected={filterDate.end_date}
                onChange={date => onChangeFilterDate(date, 'end_date')}
                placeholderText="MM/DD/YYYY HH:MM"
              />
            </div>
            {isOCMD()&&   <div className="select-drop-arrow filter_cstm1">
                              <select
                              className="report-date mr-2"
                                value={paymentCompletion}
                                name="full_payment"
                                onChange={handlePaymentCompletionChange}
                              >
                                <option value="">Select Payment Completion</option>
                                <option value={true}>Full Payment</option>
                                <option value={false}>Partial Payment</option>
                              </select>
                            </div>}
            </div>

            <button
              className="licence-print report-mr"
              onClick={handleFilter}
              type="button"
            >
              Submit
            </button>
          </form>
        </div>
        <div>
          {errorMessage && <div className="pr-err">Error: {errorMessage}</div>}
        </div>
        {loader ? (
          <div style={{ marginLeft: "50%" }} className="spinner-border"></div>
        ) : (
          <div style={{ width: "100%" }} className="row">
            <div className="col-md-6">
              <div className="ticket-graph-box">
                <div className="ticket-graph-subttl">
                  <div className="">
                    {data.table_data && barGraphOptions.datasets ? (
                      <div id="abcd-efgh">
                        <h4>Bar Graph</h4>
                        <Bar
                          id="barChart"
                          width={270}
                          height={270}
                          data={barGraphOptions}
                          options={barGraphOptions}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ticket-graph-box">
                <div className="ticket-graph-subttl">
                  <div className="">
                    {data.chart_data && barGraphOptions.datasets ? (
                      <div>
                        <h4>Pie Chart</h4>
                        <Pie
                          width={350}
                          height={300}
                          data={chartOptions}
                          options={chartOptions}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="ctsm-table-scroll pr-table-otr-div">
              <div className="pr-table-inr-div">
                <h4>Table</h4>
                <table
                  id="cstm-dashboard-tb-display"
                  className="table-heading table table-bordered"
                >
                  <thead className="table-main-heading-list">
                    <tr>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("violation_code")}
                      >
                        Violation Code
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("violation_description")}
                      >
                        Violation Description
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("group_count")}
                      >
                        Group Count
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("cash_count")}
                      >
                        Cash Count
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("cash_total")}
                      >
                        Cash Total
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("card_count")}
                      >
                        Card Count
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("card_total")}
                      >
                        Card Total
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("cheque_count")}
                      >
                        Check Count
                      </th>
                      <th
                        className="pr-table-crsr-ptr"
                        onClick={() => handleSort("cheque_total")}
                      >
                        Check Total
                      </th>
                        <th
                          className="pr-table-crsr-ptr"
                          onClick={() => handleSort("total_amount")}
                        >
                          Total Amount
                        </th>
                    </tr>
                  </thead>
                  <tbody>{tableRows}</tbody>

                  <tfoot>{tableFooter}</tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
