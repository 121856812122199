import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackbuttinIMag from "../../Images/back.png";
import mainService from "../../Services/main.service";
import moment from "moment";
import { refreshTokenMiddleware } from "../customMiddelware";
import { changeticket } from "../../Global/site";

const RODetailReport = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changeName = changeticket();

  const [roDetail, setRODetail] = useState({});
  const [showroInformation, setRoInformation] = useState(false);

  useEffect(() => {
    getRODetailInformation();
  }, []);

  async function getRODetailInformation() {
    const tick_id = location.state?.id;
    if (tick_id) {
      try {
        const response = await mainService.getROReportDetailByTcketIdAPI(
          tick_id
        );

        if (response.data.success) {
          setRODetail(response.data.data);
        } else {
          setRODetail({});
        }
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          getRODetailInformation();
        }
      }
    } else {
      navigate("/ro-reports");
    }
  }

  const backButtonClick = () => {
    setRoInformation(!showroInformation);
  };

  return (
    <>
      {showroInformation ? (
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
          <div className="row">
            <div className="col-12 back-button mb-2">
              <a
                onClick={() => {
                  props.backButtonClick(false);
                }}
              >
                <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                Back
              </a>
            </div>
          </div>
          <div className="rightpanel-box cstm-rod">
            <div className="box">
              <div className="box1">
                <div className="row">
                  <div className="col-12 userdetail-txt1">
                    <h4>Vehicle Details</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>{changeName} number</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].ticket_no : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>State</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_state : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_no : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate type</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_type : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate type color</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_type_color : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>User data 1</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].user_data_1 : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>User data 2</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].user_data_2 : "-"}</p>
                  </div>
                </div>
              </div>
              {roDetail && roDetail.length > 0 && roDetail.map(ro => <div className="box1">
                <div className="row">
                  <div className="col-12 userdetail-txt1">
                    <h4>DMV Details</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Name</h5>
                    <p>{ro.dmv_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>First name</h5>
                    <p>{ro.dmv_first_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Middle name</h5>
                    <p>{ro.dmv_middle_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Last name</h5>
                    <p>{ro.dmv_last_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Address</h5>
                    <p>{ro.dmv_address || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>City</h5>
                    <p>{ro.dmv_city || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>State</h5>
                    <p>{ro.dmv_state || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate dispo</h5>
                    <p>{ro.dmv_plate_dispo || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate type</h5>
                    <p>{ro.dmv_plate_type || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Zipcode 4</h5>
                    <p>{ro.dmv_zip_code_4 || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Zipcode 5</h5>
                    <p>{ro.dmv_zip_code_5 || "-"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter number</h5>
                    <p>{ro.dmv_alt_number || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter address</h5>
                    <p>{ro.dmv_alt_address || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter city</h5>
                    <p>{ro.dmv_alt_city || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter state</h5>
                    <p>{ro.dmv_alt_state || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter zipcode 4</h5>
                    <p>{ro.dmv_alt_zip_code_4 || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter zipcode 5</h5>
                    <p>{ro.dmv_alt_zip_code_5 || "-"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Date of birth</h5>
                    {ro.dmv_date_of_birth !== "" &&
                    ro.dmv_date_of_birth !== null &&
                    ro.dmv_date_of_birth !== "null" &&
                    ro.dmv_date_of_birth !== undefined &&
                    ro.dmv_date_of_birth.substring(0, 4) !== "0000" &&
                    ro.dmv_date_of_birth.substring(0, 4) !== "0001" ? (
                      <p>
                        {moment(ro.dmv_date_of_birth || new Date())
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss")}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Reg eff date</h5>
                    {ro.dmv_reg_eff_date !== "" &&
                    ro.dmv_reg_eff_date !== null &&
                    ro.dmv_reg_eff_date !== "null" &&
                    ro.dmv_reg_eff_date !== undefined &&
                    ro.dmv_reg_eff_date.substring(0, 4) !== "0000" &&
                    ro.dmv_reg_eff_date.substring(0, 4) !== "0001" ? (
                      <p>
                        {moment(ro.dmv_reg_eff_date || new Date())
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss")}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Reg exp date</h5>
                    {ro.dmv_reg_exp_date !== "" &&
                    ro.dmv_reg_exp_date !== null &&
                    ro.dmv_reg_exp_date !== "null" &&
                    ro.dmv_reg_exp_date !== undefined &&
                    ro.dmv_reg_exp_date.substring(0, 4) !== "0000" &&
                    ro.dmv_reg_exp_date.substring(0, 4) !== "0001" ? (
                      <p>
                        {moment(ro.dmv_reg_exp_date || new Date())
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss")}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Drivers license</h5>
                    <p>{ro.dmv_drivers_license || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Lease indicator</h5>
                    <p>{ro.dmv_lease_indicator || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Lsr lse name</h5>
                    <p>{ro.dmv_lsr_lse_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Make code</h5>
                    <p>{ro.dmv_make_code || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Suffix name</h5>
                    <p>{ro.dmv_suffix_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle body style</h5>
                    <p>{ro.dmv_vehicle_body_style || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle make</h5>
                    <p>{ro.dmv_vehicle_make || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle model</h5>
                    <p>{ro.dmv_vehicle_model || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle year</h5>
                    <p>{ro.dmv_vehicle_year || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vin number</h5>
                    <p>{ro.dmv_vin_number || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Ro Source</h5>
                    <p>{ro.ro_source || "-"}</p>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
          <div className="row">
            <div className="col-12 back-button mb-2">
              <a onClick={backButtonClick()}>
                <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                Back
              </a>
            </div>
          </div>
          <div className="rightpanel-box cstm-rod">
            <div className="box">
              <div className="box1">
                <div className="row">
                  <div className="col-12 userdetail-txt1">
                    <h4>Vehicle Details</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>{changeName} number</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].ticket_no : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>State</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_state : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_no : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate type</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_type : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate type color</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].plate_type_color : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>User data 1</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].user_data_1 : "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>User data 2</h5>
                    <p>{roDetail && roDetail.length > 0 ? roDetail[0].user_data_2 : "-"}</p>
                  </div>
                </div>
              </div>
              {roDetail && roDetail.length > 0 && roDetail.map(ro => <div className="box1">
                <div className="row">
                  <div className="col-12 userdetail-txt1">
                    <h4>DMV Details</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Name</h5>
                    <p>{ro.dmv_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>First name</h5>
                    <p>{ro.dmv_first_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Middle name</h5>
                    <p>{ro.dmv_middle_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Last name</h5>
                    <p>{ro.dmv_last_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Address</h5>
                    <p>{ro.dmv_address || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>City</h5>
                    <p>{ro.dmv_city || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>State</h5>
                    <p>{ro.dmv_state || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate dispo</h5>
                    <p>{ro.dmv_plate_dispo || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Plate type</h5>
                    <p>{ro.dmv_plate_type || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Zipcode 4</h5>
                    <p>{ro.dmv_zip_code_4 || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Zipcode 5</h5>
                    <p>{ro.dmv_zip_code_5 || "-"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter number</h5>
                    <p>{ro.dmv_alt_number || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter address</h5>
                    <p>{ro.dmv_alt_address || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter city</h5>
                    <p>{ro.dmv_alt_city || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter state</h5>
                    <p>{ro.dmv_alt_state || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter zipcode 4</h5>
                    <p>{ro.dmv_alt_zip_code_4 || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Alter zipcode 5</h5>
                    <p>{ro.dmv_alt_zip_code_5 || "-"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Date of birth</h5>
                    {ro.dmv_date_of_birth !== "" &&
                    ro.dmv_date_of_birth !== null &&
                    ro.dmv_date_of_birth !== "null" &&
                    ro.dmv_date_of_birth !== undefined &&
                    ro.dmv_date_of_birth.substring(0, 4) !== "0000" &&
                    ro.dmv_date_of_birth.substring(0, 4) !== "0001" ? (
                      <p>
                        {moment(ro.dmv_date_of_birth || new Date())
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss")}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Reg eff date</h5>
                    {ro.dmv_reg_eff_date !== "" &&
                    ro.dmv_reg_eff_date !== null &&
                    ro.dmv_reg_eff_date !== "null" &&
                    ro.dmv_reg_eff_date !== undefined &&
                    ro.dmv_reg_eff_date.substring(0, 4) !== "0000" &&
                    ro.dmv_reg_eff_date.substring(0, 4) !== "0001" ? (
                      <p>
                        {moment(ro.dmv_reg_eff_date || new Date())
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss")}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Reg exp date</h5>
                    {ro.dmv_reg_exp_date !== "" &&
                    ro.dmv_reg_exp_date !== null &&
                    ro.dmv_reg_exp_date !== "null" &&
                    ro.dmv_reg_exp_date !== undefined &&
                    ro.dmv_reg_exp_date.substring(0, 4) !== "0000" &&
                    ro.dmv_reg_exp_date.substring(0, 4) !== "0001" ? (
                      <p>
                        {moment(ro.dmv_reg_exp_date || new Date())
                          .utc()
                          .format("DD MMM, YYYY HH:mm:ss")}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Drivers license</h5>
                    <p>{ro.dmv_drivers_license || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Lease indicator</h5>
                    <p>{ro.dmv_lease_indicator || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Lsr lse name</h5>
                    <p>{ro.dmv_lsr_lse_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Make code</h5>
                    <p>{ro.dmv_make_code || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Suffix name</h5>
                    <p>{ro.dmv_suffix_name || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle body style</h5>
                    <p>{ro.dmv_vehicle_body_style || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle make</h5>
                    <p>{ro.dmv_vehicle_make || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle model</h5>
                    <p>{ro.dmv_vehicle_model || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vehicle year</h5>
                    <p>{ro.dmv_vehicle_year || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Vin number</h5>
                    <p>{ro.dmv_vin_number || "-"}</p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                    <h5>Ro Source</h5>
                    <p>{ro.ro_source || "-"}</p>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RODetailReport;
