import {useEffect, useMemo, useState} from 'react';
import {getFormattedDate} from '../../Common/utils';
import {Link, useNavigate} from 'react-router-dom';

function convertDecimal(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

const PurchasePermitTable = ({
  reportData,
  tableHeading,
  keySequence,
  setDetailPage,
  setDetailData,
  showPlatesHandler,
  handleEditPermit,
}) => {
  const navigate = useNavigate();
  const [report, setReport] = useState(reportData);
  const [sorting, setSorting] = useState({field: '', ascending: true});

  const handleStateDetailPage = data => {
    navigate(
      `/purchased-permits?&permit_detail=${data.permit_detail[0].permit_no}`,
    );
    setDetailPage(true);
    setDetailData(data);
  };

  useEffect(() => {
    const currentDataCopy = [...report];

    const sortedCurrentData = currentDataCopy.sort((a, b) => {
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });

    setReport(
      sorting.ascending ? sortedCurrentData : sortedCurrentData.reverse(),
    );
  }, [sorting]);

  const applySorting = useMemo(
    () => (key, ascending) => {
      setSorting({key: key, ascending: ascending});
    },
    [sorting, report],
  );

  const renderAllData = () => {
    return report.map(item => <tr key={item?.id}>{renderData(item)}</tr>);
  };

  const renderData = element => {
    const tableElements = [];
    keySequence.map((key, index) => {
      // split the key by the "." character to handle nested keys
      const keyParts = key.split('.');
      // initialize a variable to hold the current value
      let value = element;
      // iterate over the key parts and get the value for each nested key
      for (const keyPart of keyParts) {
        if (keyPart === 'first_name') {
          value = `${value[keyPart]} ${value['last_name']}`;
        } else {
          value = value[keyPart];
        }

        if (keyPart === 'total_amount') {
          tableElements.push(
            <td key={index}>{`$${convertDecimal(value)}`}</td>,
          );
          return value;
        }

        if (keyPart === 'created_at') {
          tableElements.push(
            element.permit_detail && element.permit_detail.length > 0 ? (
              <td key={index} onClick={() => handleStateDetailPage(element)}>
                <Link>{getFormattedDate(value)}</Link>
              </td>
            ) : (
              <td key={index}>{getFormattedDate(value)}</td>
            ),
          );
          return value;
        }

        if (keyPart === 'permit_detail') {
          tableElements.push(
            <td key={index}>
              {value && value.length > 0 ? (
                <Link onClick={() => showPlatesHandler(element.permit_detail)}>
                  {value.length}
                </Link>
              ) : (
                '-'
              )}
            </td>,
          );
          return value;
        }

        if (keyPart === 'edit_permit') {
          tableElements.push(
            <td key={index} onClick={() => handleEditPermit(element)}>
              <Link>Edit Permit</Link>
            </td>
          );
          return value;
        }
      }

      tableElements.push(<td key={index}>{value}</td>);
      return value;
    });
    return tableElements;
  };

  return (
    <>
      <div
        className="report_tbl_scroll fix-page-scroll-only"
        id="tbl-main-download">
        <table
          className="table table-heading table-bordered"
          id="cstm-report-tb-display">
          <thead>
            <tr>
              {tableHeading.map((key, index) => (
                <th
                  className={`srt-tab ${
                    sorting.key === keySequence[index] &&
                    (sorting.ascending ? 'srt-ace' : 'srt-desc')
                  }`}
                  onClick={() =>
                    applySorting(keySequence[index], !sorting.ascending)
                  }>
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderAllData()}</tbody>
        </table>
      </div>
    </>
  );
};

export default PurchasePermitTable;
