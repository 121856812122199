import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import { state } from "../../../Common/dropDownOptions";
import ArrowPermitLeft from '../../../../Images/arrow-permit-left.svg';
import ArrowPermitRight from '../../../../Images/arrow-permit-right.svg';
import {siteid} from '../../../../Global/site';
import mainService from '../../../../Services/main.service';

const idsite = siteid();

const PermitStepPlateDetailsForm = ({
  onClickNavigate,
  permitQuantity,
  objectKey,
  keyNames,
  totalSteps,
  stepNo,
  formSubmitHandler,
  setPlateObjectKey,
  validations,
}) => {
  const permitStepData = useSelector(state => state.permitReducer.stepFormData);
  let obj = Object.fromEntries(keyNames.map(itm => [itm.key_name, '']));
  const navigate = useNavigate();
  const [permitNum, setPermitNum] = useState(0);
  const {control, handleSubmit, reset, watch} = useForm();

  const [vehicleColor, setVehicleColor] = useState([]);
  const [makeAndModel, setMakeAndModel] = useState([]);
  const [modelData, setModelData] = useState([]);

  const paramsColorList = {
    type: 'CarColorList',
    shard: 1,
    site_id: idsite,
  };
  const paramsMakeList = {
    type: 'MakeModelList',
    shard: 1,
    site_id: idsite,
  };

  const groupMakeData = useCallback(makeData => {
    const groupedData = makeData.reduce((acc, curr) => {
      if (!acc[curr.make]) {
        acc[curr.make] = {make_full: curr.make_full, models: []};
      }
      acc[curr.make].models.push(curr.model);
      return acc;
    }, {});
    const makeAndModel = Object.entries(groupedData).map(
      ([make, {make_full, models}]) => ({
        make_full,
        models,
      }),
    );
    setMakeAndModel(makeAndModel);
  }, []);

  const selectDataHandler = async () => {
    try {
      const [makeResponse, colorResponse] = await Promise.all([
        mainService.getDataForDropDown(paramsMakeList),
        mainService.getDataForDropDown(paramsColorList),
      ]);

      setVehicleColor(colorResponse.data.data[0].response);

      groupMakeData(makeResponse.data.data[0].response);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  useEffect(() => {
    setPermitNum(permitQuantity);
    selectDataHandler();
  }, [permitQuantity]);

  const {fields, append, remove} = useFieldArray({
    name: objectKey,
    control,
  });

  useEffect(() => {
    setPlateObjectKey(objectKey);
  }, [objectKey]);
  // rendering plate detail based on selected quantity

  useEffect(() => {
    if (fields.length < permitNum) {
      const defaultValues = Array.from(
        {length: permitNum - fields.length},
        (_, index) => ({
          ...obj,
          tier_number: Number(index) + 1,
          permit_fee: 0,
          state: 'CT',
        }),
      );
      append(defaultValues);
    }

    // remove plate detail based on quantity
    if (fields.length > permitNum) {
      const lastInd = fields.lastIndexOf();
      remove(lastInd);
    }
  }, [fields, append, permitNum, remove]);

  // form submit handler
  const onSubmitHandler = data => {
    formSubmitHandler(data);
  };
  useEffect(() => {
    if (permitStepData) {
      reset(permitStepData);
    }
  }, [permitStepData]);

  const renderYearContent = year => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  return (
    <>
      <div className="question-bg-olny">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {fields.map((_, index) => (
            <>
              <h3 className="permit-question-h3">Permit {index + 1}</h3>
              <div className="row mb-4">
                {keyNames[0] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[0].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[0].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[0].aligned }}>
                        {keyNames[0].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <input
                        className="permit-field-que"
                        type="text"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Enter License plate Number"
                        autoFocus={index === 0}
                      />
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />}
                {keyNames[1] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[1].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[1].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[1].aligned }}>
                        {keyNames[1].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <select
                        onChange={field.onChange}
                        className="permit-field-que">
                        {state.map(item =>
                          item.value === field.value ? (
                            <option value={item.value} selected>
                              {item.label}
                            </option>
                          ) : (
                            <option value={item.value}>{item.label}</option>
                          ),
                        )}
                      </select>
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />}
                {keyNames[2] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[2].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[2].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[2].aligned }}>
                        {keyNames[2].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <div className="permit-step-fl">
                        <DatePicker 
                          showTimeSelect
                          timeIntervals={1}
                          placeholderText="MM/DD/YYYY HH:MM"
                          dateFormat="MM/dd/yyyy h:mmaa"
                          timeFormat="HH:mm"
                          className={`permit-field-que `}
                          autoComplete="off"
                          selected={
                             field.value
                          }
                          onChange={field.onChange}
                          name={`plate_details.${index}.permit_start_date`}
                          minDate={
                            validations &&
                            validations.date_range &&
                            validations.date_range.minimum &&
                            moment(validations.date_range.minimum).toDate()
                          }
                          maxDate={
                            validations &&
                            validations.date_range &&
                            validations.date_range.minimum &&
                            moment(validations.date_range.maximum).toDate()
                          }
                        />
                        {fieldState.error && (
                          <span className="errormessage">
                            {fieldState.error.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />}
              </div>
              <div className="row mb-4">
                {keyNames[3] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[3].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[3].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[3].aligned }}>
                        {keyNames[3].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <div className="permit-step-fl">
                        <DatePicker
                          showYearPicker
                          placeholderText="YYYY"
                          dateFormat="yyyy"
                          className="permit-field-que"
                          autoComplete="off"
                          selected={
                            field.value
                          }
                          onChange={date => {
                            field.onChange(date); 
                          }}
                          // onChange={date => {
                          //   const year = date ? date.getFullYear() : '';
                          //   field.onChange(year); // Store only the year
                          // }}
                          name={`${objectKey}.${index}.${keyNames[4].key_name}`}
                          renderYearContent={year => <span>{year}</span>}
                        />
                        {fieldState.error && (
                          <span className="errormessage">
                            {fieldState.error.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />}
                {keyNames[4] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[4].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[4].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[4].aligned }}>
                        {keyNames[4].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <select
                        name={`${objectKey}.${index}.${keyNames[4].key_name}`}
                        onChange={field.onChange}
                        className="permit-field-que">
                        <option value="">Select Make</option>
                        {makeAndModel.map(item =>
                          item.make_full === field.value ? (
                            <option value={item.make_full} selected>
                              {item.make_full}
                            </option>
                          ) : (
                            <option value={item.make_full}>
                              {item.make_full}
                            </option>
                          ),
                        )}
                      </select>
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />}
                {keyNames[5] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[5].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[5].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[5].aligned }}>
                        {keyNames[5].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <select
                        onChange={field.onChange}
                        className="permit-field-que">
                        <option value="">Select Model</option>
                        {watch(
                          `${objectKey}.${index}.${keyNames[4].key_name}`,
                        ) &&
                          makeAndModel
                            ?.filter(
                              item =>
                                item?.make_full ===
                                watch(
                                  `${objectKey}.${index}.${keyNames[4].key_name}`,
                                ),
                            )[0]
                            ?.models.map((item, index) =>
                              item === field.value ? (
                                <option value={item} selected>
                                  {item}
                                </option>
                              ) : (
                                <option value={item}>{item}</option>
                              ),
                            )}
                      </select>
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />}
              </div>
              <div className="row mb-4">
                {keyNames[6] && <Controller
                  key={index}
                  name={`${objectKey}.${index}.${keyNames[6].key_name}`}
                  control={control}
                  rules={{
                    required:
                      validations && validations.required
                        ? `${keyNames[6].repr} field is required`
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="col-4">
                      <h6
                        className="permit-field-box-label"
                        style={{ textAlign: keyNames[6].aligned }}>
                        {keyNames[6].repr}{' '}
                        {validations && validations.required && '*'}
                      </h6>
                      <select
                        onChange={field.onChange}
                        className="permit-field-que">
                        <option value={''}>Select Color</option>
                        {vehicleColor.map(item =>
                          item.description === field.value ? (
                            <option value={item.description} selected>
                              {item.description}
                            </option>
                          ) : (
                            <option value={item.description}>
                              {item.description}
                            </option>
                          ),
                        )}
                      </select>
                      {fieldState.error && (
                        <span className="errormessage">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />}
              </div>
            </>
          ))}

          <div className="permit-question-btn">
            <button
              className="permit-question-btn-left"
              type="button"
              onClick={() => (
                navigate(`#step${Number(stepNo) - 1}`),
                onClickNavigate(`step${Number(stepNo) - 1}`)
              )}>
              <img src={ArrowPermitLeft} alt="back" />
              Back
            </button>
            <button
              className="permit-question-btn-right cstm-permit-btn"
              type="submit">
              Next
              <img src={ArrowPermitRight} alt="next" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PermitStepPlateDetailsForm;
