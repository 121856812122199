import "react-image-gallery/styles/css/image-gallery.css";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import ModalImage from "react-modal-image";

// service
import dashboardService from "../../Services/dashboard.service";
import TicketReportDetails from "../../Services/main.service";

import mainService from "../../Services/main.service";

import QuillEditor from "../Common/QuillEditor";

import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

import Eororicon from "../../Images/info-icon.png";
import { changetotal, siteid } from "../../Global/site";
import { refreshTokenMiddleware } from "../customMiddelware";
import { CopyToClipboard } from "react-copy-to-clipboard";

import BackbuttinIMag from "../../Images/back.png";
import pdf from "../../Images/pdf-dummy.png";
import doc from "../../Images/doc-dummy.png";
import DOMPurify from "dompurify";
import ImageSlider from "../../utile/imageSlider/imageSlider";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authHeader from "../../Services/auth.header";
import ConfirmationModalComponent from "../../utile/modals/confirmationModal";
import AuditTrialTabDetail from "../HearingModule/HearingManagement/AuditTrialTabDetail";
import ClosedTab from "../HearingModule/HearingManagement/ClosedTab";
import NoteTab from "../HearingModule/HearingManagement/NoteTab";
import ManagerReviewTab from "../HearingModule/HearingManagement/ManagerReviewTab";
import CitationInfoTab from "../Utilies/CitationInfo";
import Cookies from "js-cookie";
import TicketDetailHeader from "../ReportingModule/TicketReporting/TicketDetailHeader";
import NoticeTabComponent from "../HearingModule/HearingManagement/NoticeTabComponent";


const isCohassetSite = () => {
  if(Cookies.get("siteinfo") === "b4fbe4d6-2ec4-11ef-8e44-76f8a1a040d2" ||
    Cookies.get("siteinfo") === "30c9b390-2ec5-11ef-ba31-ca0a5add8b0c") {
    return true
  } else {
    return false
  }
}

const AppealDetail = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changeTotal = changetotal();

  const refM = React.useRef();

  const [isLoader, setLoader] = useState(true);
  const [activeTab, setActiveTab] = useState(6);
  const [work,setWork]=useState("");
  const [appealDetails, setAppealDetails] = React.useState({});
  const [citationNumber, setCitationNumber] = React.useState("");
  const [imageForAppealDownload, setImageForAppealDownload] = useState([]);
  const [newImageForAppealDownload, setNewImageForAppealDownload] =
    React.useState([]);
  const [showStepStatus, setStepStatus] = React.useState([]);
  const [citationAllData, setCitationAllData] = React.useState({});
  const [imageForCitationDownload, setImageForCitationDownload] =
    React.useState([]);
  const [wysiwygTextMR, setwysiwygTextMR] = React.useState("");
  const [wysiwygTextBR, setwysiwygTextBR] = React.useState("");
  const [wysiwygTextC, setwysiwygTextC] = React.useState("");
  const [rightClick, setRightClick] = React.useState(-1);
  const [isBtnLoader, setBtnLoader] = useState(false);
  const [isBtnLoader1, setBtnLoader1] = useState(false);

  const [attachment, setAttachment] = useState(false);
  const [imageId, setImageId] = useState();
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const toastId = useRef(null);

  const showSideId = siteid();

  const [remarkInputs01, setRemarkInputs01] = React.useState({
    remark: "",
    additional_remark: "",
    appeal_status: appealDetails.appeal_status,
    workflow_status: "",
    is_internal: false,
  });
  const [remarkErrors01, setRemarkErrors01] = React.useState({
    appeal_status: "",
    remark: "",
  });

  const [remarkInputs1, setRemarkInputs1] = React.useState({
    from: "",
    to: "",
    subject: "",
    body: "",
    attachments: [],
  });
  const [remarkErrors2, setRemarkErrors2] = React.useState({
    subject: "",
    body: "",
  });

  const [remarkInputs02, setRemarkInputs02] = React.useState({
    appeal_status: appealDetails.appeal_status,
    remark: "",
    additional_remark: "",
    workflow_status: "",
    is_internal: false,
  });
  const [remarkErrors02, setRemarkErrors02] = React.useState({
    appeal_status: "",
    remark: "",
  });

  const [remarkInputs03, setRemarkInputs03] = React.useState({
    appeal_status: appealDetails.appeal_status,
    remark: "",
    additional_remark: "",
    workflow_status: "",
    is_internal: false,
  });
  const [remarkErrors03, setRemarkErrors03] = React.useState({
    appeal_status: "",
    remark: "",
  });

  const [messageSuc, setMessageSuc] = React.useState("");
  const [commercialUpdateData, setCommercialUpdateData] = React.useState({
    ticket_number: "",
    site_id: showSideId.siteinfo,
    fine_amount: null,
    nsf_amount: null,
    boot_tow_fee: null,
    collection_amount: null,
    balance_due: null,
    notice_date: {},
    dmv_case_number: "",
    remark: "",
    paid_date: null,
    fine_increase_date: null,
    nsf_applied_date: null,
    boot_applied_date: null,
    collection_date: null,
    adjusted_balance_date: null,
    due_date: null,
  });

  // notification form state
  const [notiInput, setNotiInput] = useState({
    notification_name: "",
    site_id: showSideId.siteinfo,
    email_recipients: [],
    metadata: {
      template_id: "",
      from: "",
      subject: "",
      body: "",
      frequency: "Event Driven",
      notification_type: "",
      status: "Activated",
      logo: "",
    },
  });

  const [notiErrors, setNotiErrors] = useState({
    notification_name: "",
    email_recipients: [],
    metadata: {
      template_id: "",
      from: "",
      subject: "",
      body: "",
      notification_type: "",
      status: "",
    },
  });
  // custom error handing state
  const [isNotiTemplateViewHTML, setNotiTemplateViewHTML] = useState({
    html: "",
    state: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalMsg, setModalMsg] = useState("");
  const [appealid, setappealid] = useState("");
  const [clipboardtext, setClipboardText] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [sorting, setSorting] = useState({
    field: "xyz",
    ascending: true,
    type: "",
  });
  const [filesUpload, setFilesUpload] = useState([]);
  const [filesPreview, setFilesPreview] = useState([]);
  const [fileValue1, setFileValue1] = useState("")
  const [fileValue2, setFileValue2] = useState("")
  const [fileValue3, setFileValue3] = useState("")
  const [fileError, setFileError] = useState("")
  const [showStatusWarning,setShowStatusWarning] = useState(false)
  const [appealWorkflowstatus,setAppealWorkflowstatus] = useState("")
  const [webPageId, setWebPageId] = useState('')
  const [paymentBreakdown, setPaymentBreakdown] = useState([]);
  const [registeredOwnerDetails, setRegisteredOwnerDetails] = useState([]);

  const location = useLocation().search;
  const citation_Id = new URLSearchParams(location).get("id");
  const citation_Number = new URLSearchParams(location).get("citation_no");

  useEffect(() => {
    getAppealDetailedInfo();
    getCitationDetailsAPI();
  }, [citation_Number, citation_Id]);

  useEffect(() => {
    var pathName = window.location.hostname;
    setSiteUrl(pathName);

    const currentDataCopy = { ...citationAllData };
    if (sorting.type === "audit-trail") {
      const sortedCurrentdata = (currentDataCopy?.audit_trail || []).sort(
        (a, b) => {
          return a[sorting.key].localeCompare(b[sorting.key]);
        }
      );
      setCitationAllData({
        ...currentDataCopy,
        audit_trail: sorting.ascending
          ? sortedCurrentdata
          : sortedCurrentdata.reverse(),
      });
    } else if (sorting.type === "notice") {
      const sortedCurrentdata = (
        currentDataCopy?.notification_audit_trail || []
      ).sort((a, b) => {
        return a[sorting.key].localeCompare(b[sorting.key]);
      });
      setCitationAllData({
        ...currentDataCopy,
        notification_audit_trail: sorting.ascending
          ? sortedCurrentdata
          : sortedCurrentdata.reverse(),
      });
    }
  }, [sorting, citation_Id, citation_Number]);

  const applySorting = (key, ascending, type) => {
    setSorting({ key: key, ascending: ascending, type: type });
  };

  useEffect(() => {
    setTimeout(() => {
      setMessageSuc("");
    }, 2000);
  }, [messageSuc]);

  useEffect(() => {
    pageLoadData();
  }, [citation_Id, citation_Number]);


  const pageLoadData = () => {
    const no_t = citation_Id;
    const tikno = citation_Number;
    TicketReportDetails.singleAppealByTicket(tikno).then((response) => {
      if (response.data.status) {
        if (response.data.data !== null && response.data.data !== undefined) {
          setRemarkInputs1({
            ...remarkInputs1,
            to: response.data.data.email,
            from: response.data.data.from_email,
          });
        }
      }
    });

    setappealid(no_t);
    if (no_t) {
      getAppealDetailedInfo();
      dynamicstatusfun();
      function dynamicstatusfun() {
        dashboardService
          .dynemicStatus()
          .then((result) => {
            if (result.data.status === true && result.data.data !== null) {
              var SortData = result.data.data;

              SortData.sort(function (a, b) {
                var textA = a["id"];
                var textB = b["id"];
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });

              setStepStatus(SortData);

              setLoader(false);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              dynamicstatusfun();
            }
          });
      }
    } else {
      navigate("/appeal-report");
    }
  };

  const getAppealDetailedInfo = async () => {
    try {
      const appeal_id = citation_Id;

      const result = await dashboardService.getAppealDetailedByIdInfo(
        appeal_id
      );

      if (result.data.status) {
        const data = result.data.data;
        setAppealDetails(data);

        setCitationNumber(data.citation_number);
        await getCitationDetailsAPI(citation_Number);
        const imgobj = await imageDataGet(data.attachments);
        setImageForAppealDownload(imgobj);
        const newImageObj = await imageDataGet(data.additional_attachments);
        setNewImageForAppealDownload(newImageObj);
        setAttachment(true);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getAppealDetailedInfo();
      }
    }
  };
  async function getCitationDetailsAPI(id) {
    try {
      const result = await TicketReportDetails.citationDetails(
        "ticket_number=" + id
      );
      const { detail } = result.data.data;
      if (detail && detail !== null) {
        setCitationAllData(detail);

        const imgobj = await imageDataGet(detail.images);
        setImageForCitationDownload(imgobj);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getCitationDetailsAPI(id);
      }
    }
  }

  async function imageDataGet(imgdata) {
    var imageData = {
      download_type: "CitationImages",
      links: Object.assign({}, imgdata),
      site_id: showSideId.siteinfo,
    };
    try {
      const result = await dashboardService.downloadImage(imageData);
      return result.data.metadata;
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        imageDataGet(imgdata);
      }
    }
  }

  const handleRemarkExtraInputes = (e) => {
    const { name, value } = e.target;
    setRemarkInputs1({ ...remarkInputs1, [name]: value });
    setRemarkErrors2({ ...remarkErrors2, subject: "" });
  };
  const onChangeTextEditorForMR = (editorState) => {
    setwysiwygTextMR(editorState);
    setRemarkInputs1({
      ...remarkInputs1,
      body: editorState,
    });
    setRemarkErrors2({ ...remarkErrors2, body: "" });
  };

  const onChangeTextEditorForBR = (editorState1) => {
    setwysiwygTextBR(editorState1);
    setRemarkInputs1({
      ...remarkInputs1,
      body: editorState1,
    });
    setRemarkErrors2({ ...remarkErrors2, body: "" });
  };

  const onChangeTextEditorForC = (editorState2) => {
    setwysiwygTextC(editorState2);
    setRemarkInputs1({
      ...remarkInputs1,
      body: editorState2,
    });
    setRemarkErrors2({ ...remarkErrors2, body: "" });
  };

  const handleReviewSubmit = async (e, workflowstatus) => {
    e.preventDefault();

    if (workflowstatus === "Manager Review") {
      setAppealWorkflowstatus("Manager Review")
      let isError = false;
      if (remarkInputs01.remark === "") {
        isError = true;
        remarkErrors01.remark = "Enter remark.";
      }
      if (remarkInputs01.appeal_status === "" && !remarkInputs01.is_internal) {
        isError = true;
        remarkErrors01.appeal_status = "Select appeal status.";
      }
      setRemarkErrors01({ ...remarkErrors01 });

      if(!isError){
        if(remarkInputs01.appeal_status === "Appeal Upheld" ||
        remarkInputs01.appeal_status ===
        "Appeal Partially Upheld" ||
        remarkInputs01.appeal_status ===
        "Appeal Upheld (Ticket Dismissed)" ||
        remarkInputs01.appeal_status === "Appeal Rejected" || 
        remarkInputs01.appeal_status === "Closed"){
          setShowStatusWarning(true);
          isError = true
      }}

      if (!isError) {
        appealsearveyupdateremark1();
        async function appealsearveyupdateremark1() {
          try {
            let payload = { ...remarkInputs01 };
            payload.workflow_status = workflowstatus;

            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextMR("");
              getAppealDetailedInfo();

              setRemarkInputs01({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark1();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
          }
        }
      }
    } else if (workflowstatus === "Board Review") {
      setAppealWorkflowstatus("Board Review")
      let isError = false;
      if (remarkInputs02.appeal_status === "" && !remarkInputs02.is_internal) {
        isError = true;
        remarkErrors02.appeal_status = "Select appeal status.";
      }
      setRemarkErrors02({ ...remarkErrors02 });

      if(!isError){
        if(remarkInputs02.appeal_status === "Appeal Upheld" ||
        remarkInputs02.appeal_status ===
        "Appeal Partially Upheld" ||
        remarkInputs02.appeal_status ===
        "Appeal Upheld (Ticket Dismissed)" ||
        remarkInputs02.appeal_status === "Appeal Rejected" || 
        remarkInputs02.appeal_status === "Closed"){
          setShowStatusWarning(true);
          isError = true
      }}

      if (!isError) {
        appealSearveyUpdateRemark2();
        async function appealSearveyUpdateRemark2() {
          try {
            let payload = { ...remarkInputs02 };
            payload.workflow_status = workflowstatus;

            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextBR("");
              getAppealDetailedInfo();

              setRemarkInputs02({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealSearveyUpdateRemark2();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
          }
        }
      }
    } else if (workflowstatus === "Closed") {
      let isError = false;

      setAppealWorkflowstatus("Closed")
      if (remarkInputs03.appeal_status === "" && !remarkInputs03.is_internal) {
        isError = true;
        remarkErrors03.appeal_status = "Select appeal status.";
      }
      setRemarkErrors03({ ...remarkErrors03 });

      if(!isError){
        if(remarkInputs03.appeal_status === "Appeal Upheld" ||
        remarkInputs03.appeal_status ===
        "Appeal Partially Upheld" ||
        remarkInputs03.appeal_status ===
        "Appeal Upheld (Ticket Dismissed)" ||
        remarkInputs03.appeal_status === "Appeal Rejected" || 
        remarkInputs03.appeal_status === "Closed"){
          setShowStatusWarning(true);
          isError = true
      }}

      if (!isError) {
        appealsearveyupdateremark3();
        async function appealsearveyupdateremark3() {
          try {
            let payload = { ...remarkInputs03 };
            payload.workflow_status = workflowstatus;
            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextC("");
              getAppealDetailedInfo();
              setRemarkInputs03({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark3();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
          }
        }
      }
    } else {
      let isError = false;
      if (remarkInputs1.subject === "") {
        isError = true;
        remarkErrors2.subject = "Enter subject.";
      }
      if (remarkInputs1.body === "") {
        isError = true;
        remarkErrors2.body = "Enter body.";
      }
      if (fileError !== "") {
        isError = true;
      }
      setRemarkErrors2({ ...remarkErrors2 });

      if (!isError) {
        let allLinks = [];
        if (filesUpload.length > 0) {
          // for uploading media
          setBtnLoader1(true);
          const formData = new FormData();
          let data_u = [];
          for (let i = 0; i < filesUpload.length; i++) {
            data_u.push(`${uuidv4()}`);
            formData.append("files", filesUpload[i]);
          }
          formData.append("data", data_u);

          formData.append("upload_type", "AppealImage");

          // call api for upload all media
          try {
            const upload_result = await uploadFiles(formData)

            if (upload_result.data.status) {
              allLinks = upload_result.data.data[0].response.links;
            }

          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealadditionalinfofun();
            }
          }
        }
        await appealadditionalinfofun();
        async function appealadditionalinfofun() {
          setBtnLoader1(true);
          try {
            const payload = { ...remarkInputs1, attachments: allLinks };
            const result =
              await dashboardService.appealAdditionalInfoRequestedForRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              toast.success(result.data.message)
              setwysiwygTextMR("");
              setwysiwygTextBR("");
              setwysiwygTextC("");
              getAppealDetailedInfo();
              setFilesUpload([]);
              setFilesPreview([]);
              setFileValue1("");
              setFileValue2("");
              setFileValue3("");
              setFileError("");
              setRemarkInputs1({
                ...remarkInputs1,
                body: "",
                subject: "",
              });
              setBtnLoader1(false);
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealadditionalinfofun();
            }
            toast.error('Could not send mail. Please try again or contact your administrator.');
            setBtnLoader1(false);
          }
        }
      }
    };
  }

  const uploadFiles = async (formData) => {
    try {
      const response = await axios.request({
        method: 'post',
        url: `${dashboardService.API_URL}static_file/cp/upload_files`,
        headers: authHeader(),
        data: formData,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (toastId.current === null) {
            toastId.current = toast('Please wait while your files are uploaded', { progress: `${progress}%` });
          } else {
            toast.update(toastId.current, { progress: `${progress}%` });
          }
        },
      })
      if (response.data?.status) {
        toast.dismiss(toastId.current)
        toast.success('File Upload successful!');
        //reset toastId so that if user uploads another file without refreshing the page, the upload progree can be shown
        toastId.current = null;
      }
      return response;
    } catch (error) {
      toast.dismiss(toastId.current)
      toast.error('File upload failed. Please try again.');
      toastId.current = null;
      throw error
    }
  };


  function datetimeLocal() {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16);
  }

  async function commercialDataFetch() {
    try {
      // static data
      const result = await dashboardService.getCommercialDataAPI(
        citationNumber
      );
      commercialUpdateData.ticket_number = result.data.data.citation_number;
      commercialUpdateData.notice_date = result.data.data.notice_date;

      commercialUpdateData.paid_date = result.data.data.paid_date
        ? result.data.data.paid_date.substring(0, 16)
        : datetimeLocal();
      commercialUpdateData.due_date = result.data.data.due_date
        ? result.data.data.due_date.substring(0, 16)
        : datetimeLocal();
      commercialUpdateData.fine_increase_date = result.data.data
        .fine_increase_date
        ? result.data.data.fine_increase_date.substring(0, 16)
        : datetimeLocal();
      commercialUpdateData.nsf_applied_date = result.data.data.nsf_applied_date
        ? result.data.data.nsf_applied_date.substring(0, 16)
        : datetimeLocal();
      commercialUpdateData.boot_applied_date = result.data.data
        .boot_applied_date
        ? result.data.data.boot_applied_date.substring(0, 16)
        : datetimeLocal();
      commercialUpdateData.collection_date = result.data.data.collection_date
        ? result.data.data.collection_date.substring(0, 16)
        : datetimeLocal();
      commercialUpdateData.adjusted_balance_date = result.data.data
        .adjusted_balance_date
        ? result.data.data.adjusted_balance_date.substring(0, 16)
        : datetimeLocal();

      setCommercialUpdateData({ ...commercialUpdateData });
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        commercialDataFetch();
      }
    }
  }

  const handleCommon = async (e, step, tab) => {
    setWebPageId('')
    setActiveTab(tab);
    setCommercialUpdateData({
      ticket_number: "",
      site_id: showSideId.siteinfo,
      fine_amount: null,
      nsf_amount: null,
      boot_tow_fee: null,
      collection_amount: null,
      balance_due: null,
      notice_date: {},
      dmv_case_number: "",
      remark: "",
      paid_date: null,
      fine_increase_date: null,
      nsf_applied_date: null,
      boot_applied_date: null,
      collection_date: null,
      adjusted_balance_date: null,
      due_date: null,
    });

    if (step === "Commercial") {
      commercialDataFetch();
    }
    if (step === "Notice") {
      setNotiErrors({
        ...notiErrors,
        notification_name: "",
        email_recipients: [],
        metadata: {
          template_id: "",
          from: "",
          subject: "",
          body: "",
          notification_type: "",
          status: "",
        },
      });
      setNotiInput({
        ...notiInput,
        notification_name: "",
        site_id: showSideId.siteinfo,
        email_recipients: [],
        metadata: {
          template_id: "",
          from: "",
          subject: "",
          body: "",
          frequency: "Event Driven",
          notification_type: "",
          status: "Activated",
          logo: "",
        },
      });
    }
    setRemarkErrors01({
      appeal_status: "",
      remark: "",
    });
    setRemarkInputs01({
      appeal_status: appealDetails.appeal_status,
      remark: "",
      additional_remark: "",
      workflow_status: "",
      is_internal: false,
    });
    setRemarkErrors02({
      appeal_status: "",
      remark: "",
    });
    setRemarkInputs02({
      appeal_status: appealDetails.appeal_status,
      remark: "",
      additional_remark: "",
      workflow_status: "",
      is_internal: false,
    });
    setRemarkErrors03({
      appeal_status: "",
      remark: "",
    });
    setRemarkInputs03({
      appeal_status: appealDetails.appeal_status,
      remark: "",
      additional_remark: "",
      workflow_status: "",
      is_internal: false,
    });
    setRemarkInputs1({
      ...remarkInputs1,
      body: "",
      subject: "",
      attachments: [],
    });
    setFilesUpload([]);
    setFilesPreview([]);
    setFileValue1("");
    setFileValue2("");
    setFileValue3("");
    setFileError("");
    setRemarkErrors2({
      body: "",
      subject: "",
    });
    setBtnLoader1(false);
    setMessageSuc("");
  };

  const closeModal = () => {
    setNotiTemplateViewHTML({ html: "", state: false });
    setNotiInput({
      ...notiInput,
      notification_name: "",
      site_id: showSideId.siteinfo,
      email_recipients: [],
      metadata: {
        template_id: "",
        from: "",
        subject: "",
        body: "",
        frequency: "Event Driven",
        notification_type: "",
        status: "Activated",
        logo: "",
      },
    });
    setModalOpen(false);
    setModalMsg("");
  };

  const downloadAttechment = async (e, i) => {
    e.preventDefault();
    let url = "";
    var imageData = {
      links: Object.assign({}, { 0: e.target.dataset.id }),
      download_type: "CitationImages",
      site_id: showSideId.siteinfo,
    };
    await downloadfun();
    async function downloadfun() {
      try {
        const responseImage = await mainService.downloadImage(imageData);
        if (responseImage.data) {
          url = responseImage.data.metadata[0].url;

          setClipboardText(responseImage.data.metadata[0].url);
        }
      } catch (err) {
        if (err.response.status === 401) {
          refreshTokenMiddleware(dispatch);
          downloadfun();
        }
      }
    }
    if (e.nativeEvent.button === 2) {
      setRightClick(i);
    } else {
      filedownload(url);
      setRightClick(-1);
    }
  };

  function filedownload(url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = "download";
    document.body.appendChild(a);
    a.click();
    return a;
  }

  const slideImages = imageForCitationDownload.map((item) => ({
    url: item.url,
  }));

  const sliderPopupRef = React.useRef(null);

  useEffect(() => {
    if (sliderPopupRef) {
      sliderPopupRef?.current?.focus();
    }
  }, [showImagesModalSlider]);

  const handleImageDlt = (e, index) => {
    e.preventDefault();

    filesUpload.splice(index, 1);
    setFilesUpload([...filesUpload]);
    const totalFileSize = filesUpload.reduce((a, b) => a + b.size, 0);
    filesPreview.splice(index, 1);
    setFilesPreview([...filesPreview]);
    if (filesPreview.length === 0 || (totalFileSize / 1048576).toFixed(2) <= 10) {
      setFileValue1("")
      setFileValue2("")
      setFileValue3("")
      setFileError("")
    }
  };

  const handleUploadMedia = (e) => {
    const targetFiles = e.target.files;
    const targetFileSize = [...e.target.files]
    e.target.name === "attachments_1" ? setFileValue1(e.target.value) : e.target.name === "attachments_2" ? setFileValue2(e.target.value) : setFileValue3(e.target.value)
    let imgObj = [];
    let selectedFIles = [];
    const totalSize = targetFileSize.reduce((a, b) => { return a + b.size; }, 0);
    // Additional check for the total size of all files
    const totalFileSize = filesUpload.reduce((a, b) => a + b.size, totalSize);
    if ((totalFileSize / 1048576).toFixed(2) <= 10) {
      setFileError("");
    } else {
      setFileError('Total file size must not be more than 10 MB');
    }
    for (var f = 0; f < targetFiles.length; f++) {
      const url = URL.createObjectURL(targetFiles[f]);
      const filesplit = targetFiles[f].name.split(".").pop();
      var imageExtension = [
        "png",
        "jpg",
        "jpeg",
        "apng",
        "webp",
        "doc",
        "docx",
        "pdf",
      ];
      if (imageExtension.includes(filesplit.toLowerCase())) {
        const name = ["png", "jpg", "jpeg", "apng", "webp"].includes(filesplit)
          ? "image"
          : ["doc", "docx"].includes(filesplit)
            ? "doc"
            : "pdf";
        selectedFIles.push({
          url: url,
          type: name,
          name: targetFiles[f].name,
        });
        imgObj.push(targetFiles[f]);
      }
    }
    setFilesUpload([...filesUpload, ...imgObj]);
    setFilesPreview([...filesPreview, ...selectedFIles]);
  };

  const handleCloseconfirmationModal = () => {
    setShowStatusWarning(false)
  }

  const handleConfirm = () => {
    setShowStatusWarning(false);
    if (appealWorkflowstatus === "Manager Review") {
      let isError = false;
      if (remarkInputs01.appeal_status === "" && !remarkInputs01.is_internal) {
        isError = true;
        remarkErrors01.appeal_status = "Select appeal status.";
      }
      setRemarkErrors01({ ...remarkErrors01 });

      if (!isError) {
        appealsearveyupdateremark1();
        async function appealsearveyupdateremark1() {
          try {
            let payload = { ...remarkInputs01 };
            payload.workflow_status = appealWorkflowstatus;

            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextMR("");
              getAppealDetailedInfo();

              setRemarkInputs01({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark1();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
          }
        }
      }
    } else if (appealWorkflowstatus === "Board Review") {
      let isError = false;
      if (remarkInputs02.appeal_status === "" && !remarkInputs02.is_internal) {
        isError = true;
        remarkErrors02.appeal_status = "Select appeal status.";
      }
      setRemarkErrors02({ ...remarkErrors02 });

      if (!isError) {
        appealSearveyUpdateRemark2();
        async function appealSearveyUpdateRemark2() {
          try {
            let payload = { ...remarkInputs02 };
            payload.workflow_status = appealWorkflowstatus;

            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextBR("");
              getAppealDetailedInfo();

              setRemarkInputs02({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealSearveyUpdateRemark2();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
          }
        }
      }
    } else if (appealWorkflowstatus === "Closed") {
      let isError = false;
      if (remarkInputs03.appeal_status === "" && !remarkInputs03.is_internal) {
        isError = true;
        remarkErrors03.appeal_status = "Select appeal status.";
      }
      setRemarkErrors03({ ...remarkErrors03 });

      if (!isError) {
        appealsearveyupdateremark3();
        async function appealsearveyupdateremark3() {
          try {
            let payload = { ...remarkInputs03 };
            payload.workflow_status = appealWorkflowstatus;
            const result =
              await dashboardService.updateAppealSurveyorAddRemarkAPI(
                appealid,
                payload
              );
            if (result.data.status) {
              setMessageSuc(result.data.message);

              setwysiwygTextC("");
              getAppealDetailedInfo();
              setRemarkInputs03({
                appeal_status: "",
                remark: "",
                additional_remark: "",
                workflow_status: "",
              });
            }
          } catch (err) {
            if (err.response.status === 401) {
              refreshTokenMiddleware(dispatch);
              appealsearveyupdateremark3();
            }
            setModalOpen(true);
            setModalMsg(err.response.data.message);
          }
        }
      }
    }
  }

  const getPaymentDetails = async (ticket_no) => {
    try {
      const result = await dashboardService.getPaymentDetails(ticket_no);
      const paymentBreakdownArray = [];
      if (Object.keys(result.data?.data?.paymentBreakdown).length !== 0) {
        paymentBreakdownArray.push(result.data.data.paymentBreakdown);
        setPaymentBreakdown(result.data.data.paymentBreakdown);
      }
      if (result.data.status) {
        setRegisteredOwnerDetails(result.data?.data?.registered_owner_details);
      }
    } catch (err) {
      if (err.response.status === 401) {
        refreshTokenMiddleware(dispatch);
        getPaymentDetails(ticket_no);
      }
    }
  };


  useEffect(() => {
    getPaymentDetails(citation_Number)
  },[])


  return (
    <>
      {/* <div className='text-center spin-load-main'> */}
      <ToastContainer />
      <div className="col-xl-9 col-lg-9 col-md-9 col-12 right-panel cstm-right-panel">
        {isLoader ? (
          <div className="text-center spin-load-main">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 back-button mb-2">
                <Link
                  to={"/appeal-report"}
                  onClick={() => {
                    props.backButtonClick(false);
                  }}
                >
                  <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                  Back
                </Link>
              </div>
            </div>

            <div className="rightpanale-box2 cstm-rightpanale-box2 cstm-ad-only">
              <div className="cstm-ap-man">
                <div className="cstm-ap-man-left">
                  <ul className="cstm-ap-list-main">
                    <li
                      className=""
                      onClick={(e) => handleCommon(e, "Appeal", 6)}
                    >
                      <a className={`${activeTab === 6 ? "active" : ""}`}>
                        Appeal Details
                      </a>
                    </li>
                    <li
                      className=""
                      onClick={(e) => handleCommon(e, "Citation", 7)}
                    >
                      <a className={`${activeTab === 7 ? "active" : ""}`}>
                        {changeTotal} Info
                      </a>
                    </li>
                    <li
                      className=""
                      onClick={(e) => handleCommon(e, "Notice", 8)}
                    >
                      <a className={`${activeTab === 8 ? "active" : ""}`}>
                        Notice
                      </a>
                    </li>
                    <li
                      className=" align-box"
                      onClick={(e) => handleCommon(e, "Audit Trail", 9)}
                    >
                      <a className={`${activeTab === 9 ? "active" : ""}`}>
                        Audit Trail
                      </a>
                    </li>
                  </ul>
                </div>

                  <div className="cstm-ap-man-right">
                    <div className="cstm-tpbar">

                  <div className="cstm-appeal-reports-title">
                    Appeal Workflow 
                  </div>

                  <div className="cstm-ar-tp-tab">
                    <div className="counter-tabination">
                      <ul className="nav nav-tabs2 pagination-appeal-content">
                        {showStepStatus
                      .filter(
                        (step) =>
                          step.title !== "Closed"
                      ).map((step, index) => (
                          <li
                            key={index}
                            className="nav-item2"
                            onClick={(e) =>
                             { handleCommon(e, step.title, index + 1);
                              setWork(step.title);
                              setWebPageId(step.web_page_id)
                              }
                            }
                          >
                            <a
                              className={`nav-link2 main-round span.main-round-border cstm-nav-link2 ${activeTab === index + 1 ? "active" : ""
                                }`}
                            >
                              <span className="main-round-border">
                                {step.id}
                              </span>
                              <p className="cstm-appeal-status-para-content cstm-appeal-status-para-content-active">
                                {step.title}
                              </p>
                              <p className="step-description-cstm-main">
                                {"(" + step.description + ")"}
                              </p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    </div>
                    </div>
                    
                  <div className="cstm-af-only-main">
                      <div className="tab-content">
                      {(webPageId === "notes" || webPageId === "open")  && (
                       <div
                        id="tab_0"
                          className={`tab-contain-add-main-cstm tab-pane fade active show
                            }`}
                      >
                        <NoteTab Workflow={appealDetails?.workflow}/>
                      </div>
                        )}
                        { webPageId === "manager_review" && (
                           <div
                           id="tab_1"
                             className={`tab-contain-add-main-cstm tab-pane fade active show
                               }`}
                         >
                           <div className="col-12">
                             <div className="">
                               {messageSuc && (
                                 <div className="alert alert-success" role="alert">
                                   {messageSuc}
                                 </div>
                               )}
                               {appealDetails.appeal_status === "Appeal Upheld" ||
                             appealDetails.appeal_status ===
                             "Appeal Partially Upheld" ||
                             appealDetails.appeal_status ===
                             "Appeal Upheld (Ticket Dismissed)" ||
                             appealDetails.appeal_status === "Appeal Rejected" || 
                             appealDetails.appeal_status === "Closed" ? (
                                 <div className="cstm-appeal-partially">
                                   <div className="cstm-appeal-partially-img">
                                     <img src={Eororicon} alt="img"/>
                                   </div>
                                   <p className="cstm-appeal-partially-cont">Appeal already has a final outcome and can not be updated now.</p>
                                 </div>
                          
                           ) : (<div className="cstm-manager-review-main2 cstm-manager-review-main-inner cstm-manager-review-xs">
                               
                               <div className="cstm-manager-review-main2-left">
                                <ManagerReviewTab 
                                  appealid={appealid}
                                  getAppealDetailedInfo={getAppealDetailedInfo}
                                  getCitationDetailsAPI={getCitationDetailsAPI}
                                  isBtnLoader={isBtnLoader}
                                  remarkErrors={remarkErrors01}
                                  remarkInputs={remarkInputs01}
                                  setBtnLoader={setBtnLoader}
                                  setMessageSuc={setMessageSuc}
                                  setModalMsg={setModalMsg}
                                  setModalOpen={setModalOpen}
                                  setRemarkErrors={setRemarkErrors01}
                                  setRemarkInputs={setRemarkInputs01}
                                  setwysiwygTextMR={setwysiwygTextMR}
                                  workflowstatus={work}
                                  workFlowPage={"Appeal management"}
                                />
                                </div>
                                 <div className="cstm-manager-review-main2-right">
                                   <form
                                     ref={refM}
                                     onSubmit={(e) =>
                                       handleReviewSubmit(e, "Other")
                                     }
                                   >
                                     <div className="cstm-manager-review-main2-title">
                                       Email
                                     </div>
                                     <div className="cstm-w6">
                                       <div className="cstm-commercial-input col-6">
                                         <label>From</label>
                                         <input
                                           type="text"
                                           name="from"
                                           value={remarkInputs1.from}
                                           readOnly
                                         />
                                       </div>
                                       <div className="cstm-commercial-input col-6">
                                         <label>To</label>
                                         <input
                                           type="text"
                                           name="to"
                                           value={remarkInputs1.to}
                                           readOnly
                                         />
                                       </div>
                                     </div>
                                     <div className="cstm-w6">
                                       <div className="cstm-commercial-input col-12">
                                         <label>Subject *</label>
                                         <input
                                           type="text"
                                           name="subject"
                                           value={remarkInputs1.subject}
                                           onChange={handleRemarkExtraInputes}
                                         />
                                         <span className="errormessage">
                                           {remarkErrors2.subject}
                                         </span>
                                       </div>
                                     </div>
                                     <div className="col-12 form-group formgroup-main f-main2">
                                       <label htmlFor="">
                                         Note to the motorist *
                                       </label>
                                       <br />
                                           <QuillEditor
                                             value={wysiwygTextMR}
                                             onChange={(e) => onChangeTextEditorForMR(e)}
                                       />
                                       <span className="errormessage">
                                         {remarkErrors2.body}
                                       </span>
                                     </div>
                                     <div className="col-12 form-group formgroup-main cstm-choose-file">
                                       <label htmlFor="">Choose File </label>
                                       <input
                                         type="file"
                                         name="attachments_1"
                                         id="attachments"
                                         multiple
                                         accept=".doc,.docx,application/pdf,.jpeg,.png,.jpg,.pjp,.webp,.apng"
                                         onChange={handleUploadMedia}
                                         value={fileValue1}
                                       />
                                       <span className="errormessage">
                                         {fileError}
                                       </span>
                                       <div className="cstm-appeal-preview-images cstm-chooose-file-img">
                                         {filesPreview?.map((item, nm) => (
                                           <div
                                             key={nm}
                                             className={`appeal-preview-img`}
                                           >
                                             {item.type === "image" ? (
                                               <img
                                                 src={`${item.url}`}
                                                 alt={`preview-${nm}`}
                                               />
                                             ) : item.type === "doc" ? (
                                               <img
                                                 src={`${doc}`}
                                                 alt={`preview-${nm}`}
                                               />
                                             ) : (
                                               <img
                                                 src={`${pdf}`}
                                                 alt={`preview-${nm}`}
                                               />
                                             )}
                                             <button
                                               className="cstm-appeal-close"
                                               onClick={(e) =>
                                                 handleImageDlt(e, nm)
                                               }
                                             >
                                               ×
                                             </button>
                                           </div>
                                         ))}
                                       </div>
                                     </div>
                                     <div className="row">
                                       <div className="col-12 imagebutton-1 counterbtn-hover text-center">
                                         <button
                                           className="btn"
                                           type="submit"
                                           disabled={isBtnLoader1}
                                         >
                                           {isBtnLoader1 && (
                                             <div className="cstm-loader">
                                               <span
                                                 className="spinner-border spinner-border-sm"
                                                 role="status"
                                                 aria-hidden="true"
                                               ></span>
                                             </div>
                                           )}
                                           Submit
                                         </button>
                                       </div>
                                     </div>
                                   </form>
                                 </div>
                               </div>)}
                             </div>
                           </div>
                         </div>
                        )}
                        {webPageId === "financial_review" && (
                          <div
                          id="tab_0"
                            className={`tab-contain-add-main-cstm tab-pane fade active show
                              }`}
                        >
                           <ClosedTab citationNumber={citation_Number} />
                            </div> 
                        )}
                        {webPageId === "board_review" && (
                                                    <div
                                                    id="tab_1"
                                                      className={`tab-contain-add-main-cstm tab-pane fade active show
                                                        }`}
                                                  >
                                                    <div className="col-12">
                                                      <div className="">
                                                        {messageSuc && (
                                                          <div className="alert alert-success" role="alert">
                                                            {messageSuc}
                                                          </div>
                                                        )}
                                                        {appealDetails.appeal_status === "Appeal Upheld" ||
                                                      appealDetails.appeal_status ===
                                                      "Appeal Partially Upheld" ||
                                                      appealDetails.appeal_status === "Appeal Rejected" || 
                                                      appealDetails.appeal_status === "Appeal Upheld (Ticket Dismissed)" || 
                                                      appealDetails.appeal_status === "Closed" ? (
                                                          <div className="cstm-appeal-partially">
                                                            <div className="cstm-appeal-partially-img">
                                                              <img src={Eororicon} alt="img"/>
                                                            </div>
                                                            <p className="cstm-appeal-partially-cont">Appeal already has a final outcome and can not be updated now.</p>
                                                          </div>
                                                   
                                                    ) : (<div className="cstm-manager-review-main2 cstm-manager-review-main-inner cstm-manager-review-xs">
                                                        
                                                        <div className="cstm-manager-review-main2-left">
                                                          <ManagerReviewTab 
                                                            appealid={appealid}
                                                            getAppealDetailedInfo={getAppealDetailedInfo}
                                                            getCitationDetailsAPI={getCitationDetailsAPI}
                                                            isBtnLoader={isBtnLoader}
                                                            remarkErrors={remarkErrors01}
                                                            remarkInputs={remarkInputs01}
                                                            setBtnLoader={setBtnLoader}
                                                            setMessageSuc={setMessageSuc}
                                                            setModalMsg={setModalMsg}
                                                            setModalOpen={setModalOpen}
                                                            setRemarkErrors={setRemarkErrors01}
                                                            setRemarkInputs={setRemarkInputs01}
                                                            setwysiwygTextMR={setwysiwygTextMR}
                                                            workflowstatus={work}
                                                            workFlowPage={"Appeal management"}
                                                          />
                                                         </div>
                                                          <div className="cstm-manager-review-main2-right">
                                                            <form
                                                              ref={refM}
                                                              onSubmit={(e) =>
                                                                handleReviewSubmit(e, "Other")
                                                              }
                                                            >
                                                              <div className="cstm-manager-review-main2-title">
                                                                Email
                                                              </div>
                                                              <div className="cstm-w6">
                                                                <div className="cstm-commercial-input col-6">
                                                                  <label>From</label>
                                                                  <input
                                                                    type="text"
                                                                    name="from"
                                                                    value={remarkInputs1.from}
                                                                    readOnly
                                                                  />
                                                                </div>
                                                                <div className="cstm-commercial-input col-6">
                                                                  <label>To</label>
                                                                  <input
                                                                    type="text"
                                                                    name="to"
                                                                    value={remarkInputs1.to}
                                                                    readOnly
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="cstm-w6">
                                                                <div className="cstm-commercial-input col-12">
                                                                  <label>Subject *</label>
                                                                  <input
                                                                    type="text"
                                                                    name="subject"
                                                                    value={remarkInputs1.subject}
                                                                    onChange={handleRemarkExtraInputes}
                                                                  />
                                                                  <span className="errormessage">
                                                                    {remarkErrors2.subject}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div className="col-12 form-group formgroup-main f-main2">
                                                                <label htmlFor="">
                                                                  Note to the motorist *
                                                                </label>
                                                                <br />
                                                                    <QuillEditor
                                                                      value={wysiwygTextMR}
                                                                      onChange={(e) => onChangeTextEditorForMR(e)}
                                                                />
                                                                <span className="errormessage">
                                                                  {remarkErrors2.body}
                                                                </span>
                                                              </div>
                                                              <div className="col-12 form-group formgroup-main cstm-choose-file">
                                                                <label htmlFor="">Choose File </label>
                                                                <input
                                                                  type="file"
                                                                  name="attachments_1"
                                                                  id="attachments"
                                                                  multiple
                                                                  accept=".doc,.docx,application/pdf,.jpeg,.png,.jpg,.pjp,.webp,.apng"
                                                                  onChange={handleUploadMedia}
                                                                  value={fileValue1}
                                                                />
                                                                <span className="errormessage">
                                                                  {fileError}
                                                                </span>
                                                                <div className="cstm-appeal-preview-images cstm-chooose-file-img">
                                                                  {filesPreview?.map((item, nm) => (
                                                                    <div
                                                                      key={nm}
                                                                      className={`appeal-preview-img`}
                                                                    >
                                                                      {item.type === "image" ? (
                                                                        <img
                                                                          src={`${item.url}`}
                                                                          alt={`preview-${nm}`}
                                                                        />
                                                                      ) : item.type === "doc" ? (
                                                                        <img
                                                                          src={`${doc}`}
                                                                          alt={`preview-${nm}`}
                                                                        />
                                                                      ) : (
                                                                        <img
                                                                          src={`${pdf}`}
                                                                          alt={`preview-${nm}`}
                                                                        />
                                                                      )}
                                                                      <button
                                                                        className="cstm-appeal-close"
                                                                        onClick={(e) =>
                                                                          handleImageDlt(e, nm)
                                                                        }
                                                                      >
                                                                        ×
                                                                      </button>
                                                                    </div>
                                                                  ))}
                                                                </div>
                                                              </div>
                                                              <div className="row">
                                                                <div className="col-12 imagebutton-1 counterbtn-hover text-center">
                                                                  <button
                                                                    className="btn"
                                                                    type="submit"
                                                                    disabled={isBtnLoader1}
                                                                  >
                                                                    {isBtnLoader1 && (
                                                                      <div className="cstm-loader">
                                                                        <span
                                                                          className="spinner-border spinner-border-sm"
                                                                          role="status"
                                                                          aria-hidden="true"
                                                                        ></span>
                                                                      </div>
                                                                    )}
                                                                    Submit
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </div>)}
                                                      </div>
                                                    </div>
                                                  </div>
                        )}
                        {webPageId === "closed" && (
                          <div
                          id="tab_1"
                            className={`tab-contain-add-main-cstm tab-pane fade active show
                              }`}
                        >
                          <div className="col-12">
                            <div className="">
                              {messageSuc && (
                                <div className="alert alert-success" role="alert">
                                  {messageSuc}
                                </div>
                              )}
                              {appealDetails.appeal_status === "Appeal Upheld" ||
                            appealDetails.appeal_status ===
                            "Appeal Partially Upheld" ||
                            appealDetails.appeal_status ===
                            "Appeal Upheld (Ticket Dismissed)" ||
                            appealDetails.appeal_status === "Appeal Rejected" || 
                            appealDetails.appeal_status === "Closed" ? (
                                <div className="cstm-appeal-partially">
                                  <div className="cstm-appeal-partially-img">
                                    <img src={Eororicon} alt="img"/>
                                  </div>
                                  <p className="cstm-appeal-partially-cont">Appeal already has a final outcome and can not be updated now.</p>
                                </div>
                         
                          ) : (<div className="cstm-manager-review-main2 cstm-manager-review-main-inner cstm-manager-review-xs">
                              
                              <div className="cstm-manager-review-main2-left">
                                <ManagerReviewTab 
                                  appealid={appealid}
                                  getAppealDetailedInfo={getAppealDetailedInfo}
                                  getCitationDetailsAPI={getCitationDetailsAPI}
                                  isBtnLoader={isBtnLoader}
                                  remarkErrors={remarkErrors01}
                                  remarkInputs={remarkInputs01}
                                  setBtnLoader={setBtnLoader}
                                  setMessageSuc={setMessageSuc}
                                  setModalMsg={setModalMsg}
                                  setModalOpen={setModalOpen}
                                  setRemarkErrors={setRemarkErrors01}
                                  setRemarkInputs={setRemarkInputs01}
                                  setwysiwygTextMR={setwysiwygTextMR}
                                  workflowstatus={work}
                                  workFlowPage={"Appeal management"}
                                />
                               </div>
                                <div className="cstm-manager-review-main2-right">
                                  <form
                                    ref={refM}
                                    onSubmit={(e) =>
                                      handleReviewSubmit(e, "Other")
                                    }
                                  >
                                    <div className="cstm-manager-review-main2-title">
                                      Email
                                    </div>
                                    <div className="cstm-w6">
                                      <div className="cstm-commercial-input col-6">
                                        <label>From</label>
                                        <input
                                          type="text"
                                          name="from"
                                          value={remarkInputs1.from}
                                          readOnly
                                        />
                                      </div>
                                      <div className="cstm-commercial-input col-6">
                                        <label>To</label>
                                        <input
                                          type="text"
                                          name="to"
                                          value={remarkInputs1.to}
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                    <div className="cstm-w6">
                                      <div className="cstm-commercial-input col-12">
                                        <label>Subject *</label>
                                        <input
                                          type="text"
                                          name="subject"
                                          value={remarkInputs1.subject}
                                          onChange={handleRemarkExtraInputes}
                                        />
                                        <span className="errormessage">
                                          {remarkErrors2.subject}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12 form-group formgroup-main f-main2">
                                      <label htmlFor="">
                                        Note to the motorist *
                                      </label>
                                      <br />
                                          <QuillEditor
                                            value={wysiwygTextMR}
                                            onChange={(e) => onChangeTextEditorForMR(e)}
                                      />
                                      <span className="errormessage">
                                        {remarkErrors2.body}
                                      </span>
                                    </div>
                                    <div className="col-12 form-group formgroup-main cstm-choose-file">
                                      <label htmlFor="">Choose File </label>
                                      <input
                                        type="file"
                                        name="attachments_1"
                                        id="attachments"
                                        multiple
                                        accept=".doc,.docx,application/pdf,.jpeg,.png,.jpg,.pjp,.webp,.apng"
                                        onChange={handleUploadMedia}
                                        value={fileValue1}
                                      />
                                      <span className="errormessage">
                                        {fileError}
                                      </span>
                                      <div className="cstm-appeal-preview-images cstm-chooose-file-img">
                                        {filesPreview?.map((item, nm) => (
                                          <div
                                            key={nm}
                                            className={`appeal-preview-img`}
                                          >
                                            {item.type === "image" ? (
                                              <img
                                                src={`${item.url}`}
                                                alt={`preview-${nm}`}
                                              />
                                            ) : item.type === "doc" ? (
                                              <img
                                                src={`${doc}`}
                                                alt={`preview-${nm}`}
                                              />
                                            ) : (
                                              <img
                                                src={`${pdf}`}
                                                alt={`preview-${nm}`}
                                              />
                                            )}
                                            <button
                                              className="cstm-appeal-close"
                                              onClick={(e) =>
                                                handleImageDlt(e, nm)
                                              }
                                            >
                                              ×
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 imagebutton-1 counterbtn-hover text-center">
                                        <button
                                          className="btn"
                                          type="submit"
                                          disabled={isBtnLoader1}
                                        >
                                          {isBtnLoader1 && (
                                            <div className="cstm-loader">
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                            </div>
                                          )}
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>)}
                            </div>
                          </div>
                        </div>
                        )}

                      <div
                        id="appeal-details"
                          className={`tab-pane fade ${activeTab === 6 ? "active show" : ""
                            }`}
                      >
                        <div className="box1">
                          <div className="row">
                            <div className="col-12 userdetail-txt1">
                            <h4>Appeal Status</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <h5>{appealDetails.appeal_status}</h5>
                            </div>
                          </div>
                          </div>
                          <div className="box1">
                          <div className="row">
                            <div className="col-12 userdetail-txt1">
                              <h4>{changeTotal} Details</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>{changeTotal} Number</p>
                              <h5>{appealDetails.citation_number}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Date of issue</p>
                              {appealDetails.citation_issue_date !==
                                undefined &&
                                  appealDetails.citation_issue_date !== null &&
                                  appealDetails.citation_issue_date !== "" &&
                                  appealDetails.citation_issue_date.substring(
                                    0,
                                    4
                                  ) !== "0000" &&
                                  appealDetails.citation_issue_date.substring(
                                    0,
                                    4
                                  ) !== "0001" ? (
                                <h5>
                                  {moment(appealDetails.citation_issue_date)
                                    .utc()
                                    .format("DD MMM, YYYY HH:mm:ss")}
                                </h5>
                              ) : (
                                <h5>-</h5>
                              )}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Violation Description</p>
                              <h5>{appealDetails.violation_code}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Vehicle License Plate/VIN</p>
                              <h5>{appealDetails.vin_number}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Vehicle Register State</p>
                              <h5>{appealDetails.vehicle_reg_state}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="box1">
                          <div className="row">
                            <div className="col-12 userdetail-txt1">
                              <h4>User Personal Details</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>First Name</p>
                              <h5>{appealDetails.first_name}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Middle Name</p>
                              <h5>{appealDetails.middle_name}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Last Name</p>
                              <h5>{appealDetails.last_name}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="box1">
                          <div className="row">
                            <div className="col-12 userdetail-txt1">
                              <h4>Location Details</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Address</p>
                              <h5>{appealDetails.address}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>City</p>
                              <h5>{appealDetails.city}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>State</p>
                              <h5>{appealDetails.state}</h5>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                              <p>Zip</p>
                              <h5>{appealDetails.zip}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="box1">
                          <div className="row">
                            <div className="col-12 userdetail-txt1">
                              <h4>Contact info</h4>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-xl-3 col-6 user-info">
                              <p>Telephone</p>
                              <h5>{appealDetails.telephone}</h5>
                            </div>
                            <div className="col-xl-3 col-6 user-info">
                              <p>Email</p>
                              <h5>{appealDetails.email}</h5>
                            </div>
                            <div className="col-3 user-info"></div>
                            <div className="col-3 user-info"></div>
                          </div>
                        </div>
                        <div className="box1">
                          <div className="row">
                            <div className="col-12 userdetail-txt1">
                              <h4>Reason For Request</h4>
                            </div>
                          </div>
                          <div className="row">
                              {appealDetails?.appeal_reason && <div className="col-6 user-info">
                                <p>Reason</p>
                                <h5>{appealDetails.appeal_reason}</h5>
                              </div>}
                            <div className="col-6 user-info">
                              <p>Additional Reason</p>
                              <h5>{appealDetails.appeal_additional_reason}</h5>
                            </div>
                          </div>
                        </div>

                        {attachment &&
                            appealDetails.attachments.length > 0 &&
                            appealDetails.attachments !== null ? (
                          <>
                            <div className="row">
                              <div className="col-12 userdetail-txt1">
                                <h4>Attachments</h4>
                              </div>
                            </div>
                            <div className="cstm-attactment-main image-citation-detail-cstm">
                              {(imageForAppealDownload || []).map((img, i) => (
                                <div key={i} className="AppImage">
                                  <div key={i} className="AppImage">
                                    {appealDetails.attachments[i]
                                      .split(".")
                                      .pop() === "pdf" ? (
                                      <a
                                        href={img.url}
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={`${pdf}`}
                                          alt={`preview-${i}`}
                                        />
                                      </a>
                                    ) : appealDetails.attachments[i]
                                        .split(".")
                                        .pop() === "doc" ||
                                      appealDetails.attachments[i]
                                        .split(".")
                                        .pop() === "docx" ? (
                                      <a href={img.url} download>
                                        <img
                                          src={`${doc}`}
                                          alt={`preview-${i}`}
                                        />
                                      </a>
                                    ) : (
                                      <ModalImage
                                        small={img.url}
                                        large={img.url}
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}

                              {(newImageForAppealDownload || []).map(
                                (img, i) => (
                                  <div key={i} className="AppImage">
                                    <div key={i} className="AppImage">
                                      {appealDetails.additional_attachments[i]
                                        .split(".")
                                        .pop() === "pdf" ? (
                                        <a
                                          href={img.url}
                                          download
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={`${pdf}`}
                                            alt={`preview-${i}`}
                                          />
                                        </a>
                                      ) : appealDetails.additional_attachments[
                                          i
                                        ]
                                          .split(".")
                                          .pop() === "doc" ||
                                        appealDetails.additional_attachments[i]
                                          .split(".")
                                          .pop() === "docx" ? (
                                        <a href={img.url} download>
                                          <img
                                            src={`${doc}`}
                                            alt={`preview-${i}`}
                                          />
                                        </a>
                                      ) : (
                                        <ModalImage
                                          small={img.url}
                                          large={img.url}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )} 
                      </div>

                      <div
                        id="citation-info"
                          className={`tab-pane fade ${activeTab === 7 ? "active show" : ""
                            }`}
                        > 
                          <TicketDetailHeader
                            citationData={citationAllData}
                            paymentBreakdownData={paymentBreakdown}
                            registeredOwnerDetails={registeredOwnerDetails}
                          />
                          <br />
                          <CitationInfoTab isAppeal={true} citationAllData={citationAllData} imageForCitationDownload={imageForCitationDownload}/>
                      </div>

                      <div
                        id="notice"
                        className={`tab-pane fade ${activeTab === 8 ? "active show" : ""}`}
                      >
                        <NoticeTabComponent citationAllData={citationAllData} setCitationAllData={setCitationAllData} />
                          
                      </div>
                      <div
                        id="audit-trail"
                          className={`tab-pane fade ${activeTab === 9 ? "active show" : ""
                            }`}
                      >
                        <AuditTrialTabDetail/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isModalOpen && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={closeModal}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body cstm-modal-body">
                <div className="text-center cstm-paymnet-img">
                  <img src={Eororicon} alt="img" className="img-fluid" />
                </div>
                <p className="text-center mb-4">{isModalMsg}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isNotiTemplateViewHTML.state && (
        <div className="main-body-modal">
          <div className="main-modal  appeal-detail-popup-main">
            <div className="cstm-close">
              <button onClick={closeModal}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body cstm-modal-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(isNotiTemplateViewHTML.html),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showImagesModalSlider && (
        <div
          className="main-body-modal cstm-ticket-details-pop"
          onKeyDown={(e) => {
            if (e.key === "ArrowLeft") {
              imageId === 0
                ? setImageId(slideImages.length - 1)
                : setImageId(imageId - 1);
            } else if (e.key === "ArrowRight") {
              imageId + 1 === slideImages.length
                ? setImageId(0)
                : setImageId(imageId + 1);
            }
          }}
          tabIndex={0}
          ref={sliderPopupRef}
        >
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider slides={slideImages} index={imageId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showStatusWarning && 
        <ConfirmationModalComponent displayMsg={"This status change is permanent and can’t be changed again."} handleCloseModel={handleCloseconfirmationModal} handleConfirm={handleConfirm} />
      }
    </>
  );
};

export default AppealDetail;