import React, { useState, useEffect } from 'react';
import Image1 from "../../../Images/rr1.png";
import Image2 from "../../../Images/rr2.png";
import Image3 from "../../../Images/rr3.png";
import Image4 from "../../../Images/rr4.png";
import Image5 from "../../../Images/rr5.png";
import close from '../../../Images/close.svg';
import LineChartRevenue from './RevenueLineChart';
import Dashboard from "../../../Services/dashboard.service";

import { clearoutAuthUserData } from '../../../actions/auth'
import { useDispatch } from 'react-redux';
import { refreshTokenMiddleware } from '../../customMiddelware';
import Cookiesremove from '../../../Cookiesremove';

function RevenueReport() {
    const dispatch = useDispatch()
    const [count, setCount] = React.useState(0);
    const [showAuthToken, setAuthToken] = useState(false);
    const [showTotalRevenue, setTotalRevenue] = React.useState("");
    const [showTotalRevenueFromTime, setTotalRevenueFromTime] = React.useState("");
    const [showScofflawRevenue, setScofflawRevenue] = React.useState("");
    const [showRevenueZone, setRevenueZone] = React.useState("");
    const [showRevenueSquad, setRevenueSquad] = React.useState("");
    const [showErrorHandling, setErrorHandling] = useState(false)
    const [showErrorHandleMessage, setErrorHandleMessage] = useState("")

    const closeError = () => {
        setErrorHandling(false)
    }

    const onClickLogin = (e) => {
        e.preventDefault();
        Cookiesremove();
        window.location.href = "/";
    }

    // When Revenue page open at that time this function call
    useEffect(() => {
        initialrevenuefun()
        function initialrevenuefun() {
            Dashboard.getRevenueReport().then(
                (response) => {
                    if (response.data.status === 300) {
                        setAuthToken(true)
                    } else {
                        if (response.data.status === false) {
                            setErrorHandling(true)
                            setErrorHandleMessage(response.data.message)
                        } else {
                            setTotalRevenue(response.data.data[0].metadata.counts.total_revenue)
                            setScofflawRevenue(response.data.data[0].metadata.counts.avg_revenue_by_officer)
                            setRevenueZone(response.data.data[0].metadata.counts.avg_revenue_by_zone)
                            setRevenueSquad(response.data.data[0].metadata.counts.avg_revenue_by_squad)
                        }
                    }
                }
            ).catch((err) => {
                if (err.response.status === 401) {
                    refreshTokenMiddleware(dispatch)
                    initialrevenuefun()
                }
            });
        }
        setCount(100);
    }, []);

    return (
        <>

            <div className="row cstm-rr6 cstm_row_revenue_fst">
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image1} /></div>
                    <div className="cstm-prod-box-name">Total Revenue</div>
                    <div className="cstm-prod-box-cont"><span className="cstm-rr-tn">$</span>{parseFloat(showTotalRevenue).toFixed(2)}</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image3} /></div>
                    <div className="cstm-prod-box-name">Average Revenue by Officer</div>
                    <div className="cstm-prod-box-cont"><span className="cstm-rr-tn">$</span>{parseFloat(showScofflawRevenue).toFixed(2)}</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image4} /></div>
                    <div className="cstm-prod-box-name">Average Revenue by Zone</div>
                    <div className="cstm-prod-box-cont"><span className="cstm-rr-tn">$</span>{parseFloat(showRevenueZone).toFixed(2)}</div>
                </div>
                <div className="cstm-prod-box">
                    <div className="cstm-prod-box-icon"><img src={Image5} /></div>
                    <div className="cstm-prod-box-name">Average Revenue by Squad</div>
                    <div className="cstm-prod-box-cont"><span className="cstm-rr-tn">$</span>{parseFloat(showRevenueSquad).toFixed(2)}</div>
                </div>
            </div>


            <div className="row cstm-operation-summary cstm-graph-avg-time">

                <LineChartRevenue />

            </div>



        </>
    );
}

export default RevenueReport;
