import close from "../../Images/close.svg";


const PopUpComponent = ({ message, handleModalClose }) => {
  return (
    <div className="cstm-site-model">
      <div className="cstm-site-model-block fixed z-20">
        <div className="cstm-site-popup-bg">
          <div className="cstm-site-model-wrapper auth-error-cstm-model-wrapper">
            <button className="cstm-close-btn-db-is" onClick={handleModalClose}>
              <img src={close} alt="close" />
            </button>
            <div>
              <p className="error-handling">{message}</p>
              <button className="auth-cstm-submit-btn" onClick={handleModalClose}>Okay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpComponent;