import { changetotal } from "../../Global/site";
import { useNavigate } from "react-router-dom";
import BackbuttinIMag from "../../Images/back.png";
import ContactInfoForm from "./contactInfoForm";
import { useState } from "react";
import PaymentContactForm from "./PaymentContactForm";
import editIcon from "../../Images/edit.png"
import Cookies from 'js-cookie'
import moment from "moment";


const isAmountEditable = () => {
  if (Cookies.get("siteinfo") === "183db4ec-a15e-11ee-b39a-bebc79a4d1a3" ||
    Cookies.get("siteinfo") === "7b4bc424-a7dc-11ed-a1e8-42e281654476" ||
    Cookies.get("siteinfo") === "da3c1178-a07e-11ee-8147-56d1ee6b2413" ||
    Cookies.get("siteinfo") === "e7f5fbb4-a15d-11ee-9554-1a682a2f9ae8" ||
    Cookies.get("siteinfo") === "0a8fe3b8-dc2f-11ee-be9c-72ab48aba21d" ||
    Cookies.get("siteinfo") === "5bd20490-dc2f-11ee-bf9e-72127457a419") { 
    return true
  } else {
    return false
  }
}

const PaymentContactInfoForm = ({
  setIsModified,
  isBtnLoader,
  inputError,
  handleInputChange,
  inputValue,
  handlePayment,
  handleOptionChange,
  children,
  payComponent,
  list,
  setInputValue,
  card,
  cardData,
  isModify,
  isModifyBtnLoader,
  uiElements
}) => {
  let navigate = useNavigate();
  const changeTotal = changetotal();

  const [changeToInput, setChangeToInput] = useState(false);
  const [amount, setAmount] = useState();

  let today = new Date();
  let date =
    today.getDate() +
    "/" +
    parseInt(today.getMonth() + 1) +
    "/" +
    today.getFullYear();

  function convertDecimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const handleChangeAmount = (index, value) => {
    let selectedCitation = [...inputValue.citation_detail];
    if (value >= 0) {
      selectedCitation[index].original_amount = +value;
      selectedCitation[index].payable_amount = +value;
      setInputValue({ ...inputValue, citation_detail: selectedCitation });
      setAmount(+value)
    }
  };

  const handleSubmitValue = (allCitation,index) => {
    let selected_amount = 0;
    
    if (amount !== 0) {
      setChangeToInput("");
      allCitation.map((item, i) => {
        selected_amount +=
        Number(item.payable_amount)
          if (i === index && item.service_fee) {
            selected_amount = item.original_amount + item.service_fee
            item.payable_amount = item.original_amount + item.service_fee
          }
      });
      setInputValue({ ...inputValue, amount: `${selected_amount}` });
    }
    setIsModified(true)
  };

  return (
    <div className="container-fluid searchResult-section right-panel cstm-right-panel">
      <div className="cstm-payment-row">
        <div className="container-fluid back-link1">
          <div className="row">
            <div className="col-12 back-link">
              <a
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={BackbuttinIMag} alt="img" className="img-fluid" />
                Back
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid payment-main cstm-mak-payment">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="payment-section">
                <h3>{changetotal} Confirmation</h3>
                <div className="total-payment-box">
                  <div className="row">
                    <div className="col-8 total-payment-content1">
                      <h6>Total {changeTotal}</h6>
                    </div>
                    <div className="col-4 total-payment-content2">
                      <h4>{inputValue?.select_citation}</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 total-payment-content1">
                      <h6>Payment amount</h6>
                    </div>
                    <div className="col-6 total-payment-content2">
                      <h5>${convertDecimal(inputValue?.amount)}</h5>
                    </div>
                  </div>
                </div>
                {(inputValue.citation_detail || []).map((item, i) => (
                  <div key={i} className="citation-box">
                    <div className="row">
                      <div className="col-6 info-citation">
                        <p>{changetotal()} number</p>
                      </div>
                      <div className="col-6 info-text">
                        <h6>{item.citation_id}</h6>
                      </div>
                    </div>

                    <div className="row info-edit-citation">
                      <div className="col-6 info-citation">
                        <p>Payment amount</p>
                      </div>
                      
                      <div
                        className={`${
                          changeToInput === item.citation_id ? "col-6 cstm-edit-input" : "col-5 cstm-edit-input"
                        } info-text`}
                      >
                        {/* Hide partial payment edit button */}
                      
                        {isAmountEditable() && changeToInput !== item.citation_id && (
                        <div className="info-edit-button ">
                          <img
                          src={editIcon}
                            onClick={(e) => {
                              setChangeToInput(item.citation_id);
                              setAmount(item.original_amount);
                            }}
                            alt="edit"
                          />
                            
                        </div>
                      )}
                        {changeToInput === item.citation_id ? (
                          <span className="cstm-fine-edit-input">
                          <input
                            className="fine-amount-input"
                            type="number"
                            value={item.original_amount}
                            onChange={(e) =>
                              handleChangeAmount(
                                i,
                                Number(e.target.value),
                                item.original_amount
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmitValue(
                                  inputValue.citation_detail,i,item.citation_id
                                );
                              }
                            }}
                            onBlur={(e) => {
                                handleSubmitValue(
                                  inputValue.citation_detail,i,item.citation_id
                                );
                            }}
                          />
                        </ span>
                        ) : (
                          <h6>${convertDecimal(item.payable_amount)}</h6>
                        )}
                      </div>
                    </div>
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Total due</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.amount)}</h6>
                        </div>
                      </div>
                    {item.service_fee && item.service_fee !== 0 ? (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Service fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.service_fee)}</h6>
                        </div>
                      </div>
                    ) : null}
                    {item.boot_tow_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Boot tow fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.boot_tow_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.collection_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Collection fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.collection_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.nsf_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>NSF fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.nsf_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.category !== "invoice" && item.admin_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Admin fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.admin_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.category !== "invoice" && item.parking_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Parking fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.parking_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.charge_back_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Charge back fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.charge_back_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.appeal_rejected_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Appeal rejected fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.appeal_rejected_fee)}</h6>
                        </div>
                      </div>
                    )}
                    {item.hearing_rejected_fee !== 0 && (
                      <div className="row">
                        <div className="col-6 info-citation">
                          <p>Hearing rejected fee</p>
                        </div>
                        <div className="col-6 info-text">
                          <h6>${convertDecimal(item.hearing_rejected_fee)}</h6>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-6 info-citation">
                        <p>Plate number</p>
                      </div>
                      <div className="col-6 info-text">
                        <h6>{item.plate_number}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 info-citation">
                        <p>Payment date</p>
                      </div>
                      <div className="col-6 info-text">
                        <h6>{moment(new Date()).format('MM/DD/YYYY')}</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="login-section contact-info cstm-contact-information">
                <div>
                  <form>
                  <div className="row make-payment-button d-flex justify-content-between">
                      <h3 className="col-4">Contact Information</h3>
                      <div className="col-8 ci-tr">
                      {isModify && uiElements.includes('BUTTON_TICKET_MANAGEMENT_MODIFY_AND_PAY_BUTTON') &&  <button
                        className="btn"
                        type="submit"
                        onClick={(e) => handlePayment(e,'modify')}
                      disabled={isBtnLoader || isModifyBtnLoader}
                      >
                        {isBtnLoader && isModifyBtnLoader && (
                                      <div className="refund-loading-main">
                                        <span
                                          className="spinner-border"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        </div>
                                    )}
                        Modify and Pay
                      </button>}
                        <button
                          className="btn"
                        type="submit"
                        onClick={(e) => handlePayment(e,'')}
                          disabled={isBtnLoader || isModifyBtnLoader}
                        >
                          {isBtnLoader && !isModifyBtnLoader && (
                                      <div className="refund-loading-main">
                                        <span
                                          className="spinner-border"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        </div>
                                    )}
                          Make Payment
                        </button>
                      </div>
                     
                      </div>
                    {/* <ContactInfoForm */}
                    <PaymentContactForm
                      handleInputChange={handleInputChange}
                      handleOptionChange={handleOptionChange}
                      inputError={inputError}
                      inputValue={inputValue}
                      list={list}
                      card={card}
                      cardData={cardData}
                    />
                    {children}
                    {payComponent && payComponent}
                    <div className="row">
                      <div className={`col-12 make-payment-button ${isModify && 'bottom-button'}`}>
                      {isModify && uiElements.includes('BUTTON_TICKET_MANAGEMENT_MODIFY_AND_PAY_BUTTON') && <button
                        className="btn"
                        type="submit"
                        onClick={(e) => handlePayment(e,'modify')}
                        disabled={isBtnLoader || isModifyBtnLoader}
                      >
                        {isBtnLoader && isModifyBtnLoader && (
                                      <div className="refund-loading-main">
                                        <span
                                          className="spinner-border"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        </div>
                                    )}
                        Modify and Pay
                      </button>}
                        <button
                          className="btn"
                          type="submit"
                          onClick={(e) => handlePayment(e,'')}
                          disabled={isBtnLoader || isModifyBtnLoader}
                        >
                          {isBtnLoader && !isModifyBtnLoader && (
                            <div className="refund-loading-main">
                              <span
                                className="spinner-border"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          )}
                          Make Payment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentContactInfoForm;
