import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import moment from 'moment';
import mainService from '../../../Services/main.service';
import { siteid } from '../../../Global/site';
import ImageSlider from '../../../utile/imageSlider/imageSlider';
import { getFormattedDate, formatAmount } from '../../Common/utils';
import { toast, ToastContainer } from 'react-toastify';
import dashboardService from '../../../Services/dashboard.service';
import { refreshTokenMiddlewareAsync } from '../../customMiddelware';

const PurchasedPermitDetails = () => {
  const location = useLocation().search;

  const [imageId, setImageId] = useState();
  const [showImagesModalSlider, setShowImagesModalSlider] = useState(false);
  const [documentImages, setDocumentImages] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    const getPermitDetails = async query => {
      try {
        setLoading(true);
        const response = await dashboardService.getPurchasedPermit(query);
        if (
          response.data.status &&
          response.data.data.permit_buy_detail.length > 0
        ) {
          setDetailData(response.data.data.permit_buy_detail[0]);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          await refreshTokenMiddlewareAsync(dispatch);
          await getPermitDetails(query);
        } else {
          console.error('Error:', error);
          toast.error('Something went wrong');
        }
      }
      setLoading(false);
    };
    if (location) {
      const query = location.split('?')[1];
      getPermitDetails(query);
    }
  }, [dispatch, location]);

  const checkTimeAvailability = schedule => {
    let trueKey = [];

    for (const [key, value] of Object.entries(schedule)) {
      if (value === true) {
        trueKey.push(key);
        break;
      }
    }
    return trueKey.toString().toUpperCase();
  };

  useEffect(() => {
    const downloadDocumentImages = async (documents) => {
      const imagesLink = {};
      documents?.forEach(doc => {
        imagesLink[doc.name] = doc.url;
      });
      const imageData = {
        links: Object.assign({}, imagesLink),
        download_type: 'CitationImages',
        site_id: siteid().siteinfo,
      };
      await downloadImage(imageData);
    };

    const downloadImage = async (payload) => {
      try {
        const response = await mainService.downloadImage(payload);
        const newDocumentImages = response.data.metadata;
        setDocumentImages(newDocumentImages);
      } catch (error) {
        if (error?.response?.status === 401) {
          await refreshTokenMiddlewareAsync(dispatch);
          await downloadImage(payload);
        }
      }
    };
    if (detailData) {
      downloadDocumentImages(detailData.documents);
    }
  }, [detailData, dispatch]);

  const slideImages = documentImages?.map(item => ({
    url: item.url,
  }));

  const sliderPopupRef = useRef(null);
  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="text-center spin-load-main">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <div>
          <div id="permit-info">
            {/* Permit Info details start  */}
            <div className="box1">
              <div className="col-12 mb-3">
                <h4>Documents</h4>
              </div>

              <div className="cstm-appeal-status-main-info">
                <div className="cstm-attactment-main image-citation-detail-cstm">
                  {(documentImages || []).map((img, i) => (
                    <div
                      key={i}
                      className="ctsm-attachment-img user-img">
                      <img
                        src={img?.url}
                        alt={`images ${i}`}
                        onClick={() => {
                          setImageId(i);
                          setShowImagesModalSlider(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Permit Info details end  */}

            {/* Permit Info details start  */}
            <div className="box1">
              <div className="row">
                <div className="col-12 userdetail-txt1">
                  <h4>Permit Info</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-3 result1 hide-col">
                  <p>Permit Name</p>
                  <h5>{detailData.permit_name || '-'}</h5>
                </div>
                <div className="col-3 result1 hide-col">
                  <p>Permit Type</p>
                  <h5>{detailData.permit_type || '-'}</h5>
                </div>
                <div className="col-3 result1 hide-col">
                  <p>Auto Renewal</p>
                  <h5>
                    {detailData.auto_renewal ? 'True' : 'False'}
                  </h5>
                </div>
                <div className="col-3 result1 hide-col">
                  <p>Submitted At</p>
                  <h5>
                    {detailData.created_at
                      ? moment(
                        detailData.created_at.split('T')[0],
                      ).format('DD/MM/YYYY')
                      : '-'}
                  </h5>
                </div>
              </div>
            </div>
            {/* Permit Info details end  */}

            {/* User Details Starts */}
            <div className="box1">
              <div className="row">
                <div className="col-12 userdetail-txt1">
                  <h4>User Details</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                  <p>First Name</p>
                  <h5>
                    {detailData?.user_detail?.first_name || '-'}
                  </h5>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                  <p>Last Name</p>
                  <h5>
                    {detailData?.user_detail?.last_name || '-'}
                  </h5>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                  <p>Email</p>
                  <h5>
                    {detailData?.user_detail?.email || '-'}
                  </h5>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                  <p>Phone Number</p>
                  <h5>
                    {detailData?.user_detail?.phone_number
                      ? detailData?.user_detail?.phone_number.split(
                        '+1',
                      )[1]
                      : '-'}
                  </h5>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                  <p>State</p>
                  <h5>
                    {detailData?.user_detail?.state || '-'}
                  </h5>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-6 user-info">
                  <p>Zip Code</p>
                  <h5>
                    {detailData?.user_detail?.zip_code || '-'}
                  </h5>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-6 user-info">
                  <p>Address</p>
                  <h5>
                    {detailData?.user_detail?.address || '-'}
                  </h5>
                </div>
              </div>
            </div>
            {/* User Details Ends */}

            {/* Permit License plate details start  */}

            {detailData?.permit_detail &&
              detailData?.permit_detail.length > 0 && (
                <div className="box1 ">
                  <div className="row">
                    <div className="col-12 userdetail-txt1">
                      <h4>License Plates</h4>
                    </div>
                  </div>
                  <div className="row">
                    <table className="table-heading table-bordered">
                      <thead>
                        <tr>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>permit Duration</th>
                          <th>Lp Number</th>
                          <th>State</th>
                          <th>Status</th>
                          <th>Tier</th>
                          <th>Permit Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailData?.permit_detail &&
                          detailData?.permit_detail.length >
                          0 &&
                          detailData?.permit_detail.map(
                            item => (
                              <tr>
                                <td>
                                  {item.start_date
                                    ? getFormattedDate(
                                      item.start_date,
                                    )
                                    : '_'}
                                </td>
                                <td>
                                  {item.end_date
                                    ? getFormattedDate(
                                      item.end_date,
                                    )
                                    : '_'}
                                </td>
                                <td>
                                  {item.permit_time_availability
                                    ? checkTimeAvailability(
                                      item.permit_time_availability,
                                    )
                                    : '-'}
                                </td>
                                <td>{item.vehicle_details.lp_number || '-'}</td>
                                <td>{item.state || '-'}</td>
                                <td>{item.status || '-'}</td>
                                <td>
                                  {item.tier_number || '-'}
                                </td>
                                <td>
                                  {formatAmount(item?.permit_fee)}
                                </td>
                              </tr>
                            ),
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
      {/* detail page image and update status modal */}
      {showImagesModalSlider && (
        <div
          className="main-body-modal cstm-ticket-details-pop"
          tabIndex={0}
          ref={sliderPopupRef}>
          <div className="main-modal">
            <div className="cstm-close">
              <button onClick={() => setShowImagesModalSlider(false)}>×</button>
            </div>
            <div className="cstm-modal-body">
              <div className="modal-body">
                <div className="text-center cstm-ticket-details-slider">
                  <ImageSlider slides={slideImages} index={imageId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchasedPermitDetails;
