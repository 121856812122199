import React from "react";
import MaskedInput from "react-text-mask";
import {
  CARDARR,
  CARDICON,
  LASTFORDIGIT
} from "./constant";
import {
  cardMinLength
} from "./validations";

const reducer = (state, action) => {
  switch (action.type) {
    case "card_number":
      return { ...state, card_number: action.data };
    default:
      return state;
  }
};

function findDebitCardType(cardNumber) {
  const regexPattern = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) return card;
  }
  return "";
}

const OcmdCard = (props) => {
  const [error, setError] = React.useState(props.cardError);
  const [cardType, setCardType] = React.useState();
  const [state, dispatch] = React.useReducer(reducer, {
    card_number: "",
    expiry: "",
    cvc: "",
  });

  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  const handleValidations = (type, value) => {
    let errorText;
      switch (type) {
        case "card_number":
          setCardType(findDebitCardType(value));
          errorText = cardMinLength(4)(value);
          setError({ ...error, card_number: errorText });
          props.setCardError({ ...error, card_number: errorText });
          break;
        default:
          break;
      }
  };

  const handleInputData = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, data: value });
    props.setCardData({ ...state, [name]: value });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 make-payment">
          <h5>Make Payment</h5>
        </div>
      </div>
      <div className="row">
      <div className="col-4 form-group formgroup-main">
        <label htmlFor="">Card number (Only last 4 digit) *</label>
        <MaskedInput
          className="form-control"
          mask={
            ["37", "34"].includes(
              state && state.card_number.split("").splice(0, 2).join("")
            )
              ? LASTFORDIGIT 
              : LASTFORDIGIT 
          }
          guide={false}
          placeholderChar={"\u2000"}
          placeholder={"XXXX"}
          name="card_number"
          // required
          value={state.card_number}
          onChange={handleInputData}
          onBlur={handleBlur}
        />
        {(!error || !error.card_number) && CARDARR.includes(cardType) && (
          <img
            style={{
              float: "right",
              // position: "relative",
              // top: "-35px"
            }}
            src={CARDICON[cardType]}
            alt="card"
            width="50px"
            height="33px"
          />
        )}
        {props.cardError &&
          props.cardError.card_number &&
          props.cardError.card_number.length > 1 && (
            <span className="errormessage">{props.cardError.card_number}</span>
          )}
      </div>
      </div>
    </>
  );
};

export default OcmdCard;
