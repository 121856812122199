import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FlipbookService from '../../Services/flipbook.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { refreshTokenMiddlewareAsync } from '../customMiddelware';
import { useDispatch } from "react-redux";

const HearingModal = ({ closeFn = () => null, open, showCitationDetails }) => {
    const [formdata, setFormdata] = useState({ date: "", comment: "" })
    const dispatch = useDispatch();
    const [message, setMessage] = useState('')
    const [alertMessage, setAlertMessage] = useState('');
    const [hearing, setHearing] = useState(false)
    const [errorformdata, setErrorformdata] = useState({ date: "", comment: "" })
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormdata({ ...formdata, [name]: value })
        // Validation
        if (value === '') {
            setErrorformdata({ ...errorformdata, [name]: 'Field is required.' })
        } else {
            setErrorformdata({ ...errorformdata, [name]: '' })
        }
    }

    const handleSelect = (date, name) => {
        setFormdata({ ...formdata, [name]: date })
        if (date === '') {
            setErrorformdata({ ...errorformdata, [name]: 'Field is required.' })
        } else {
            setErrorformdata({ ...errorformdata, [name]: '' })
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formdata.date || !formdata.comment) {
            setErrorformdata({
                date: !formdata.date ? 'Field is required.' : '',
                comment: !formdata.remark ? 'Field is required.' : '',
            })
            return
        }
        if (errorformdata.date || errorformdata.comment) {
            return;
        }
        setFormdata({ ...formdata })
        await createCitation(showCitationDetails, formdata.date)
    }

    const createCitation = async (ticket_no, date) => {
        try {
            const response = await FlipbookService.createHearing(ticket_no, date)
            if (response?.success) {
                setAlertMessage({ message: 'Hearing created successfully!', type: 'success' });
                setTimeout(() => {
                    navigate(`/hearing-detail?id=${response?.data?.ticket_id}&citation_no=${response?.data?.ticket_no}`)
                    setAlertMessage('');
                }, 1000)
            } else {
                setAlertMessage(response?.message);
            }
        } catch (error) {
            if (error.message === 'LOGGED_OUT') {
                await refreshTokenMiddlewareAsync(dispatch)
                await createCitation(showCitationDetails, formdata.date)
            } else {
                setMessage(error?.response?.data?.message)
                setAlertMessage(error?.response?.data?.message);
            }
        }
    };

    const closeFn1 = () => {
        setHearing(false)
        setFormdata({
            date: "",
            comment: ""
        })
        closeFn();
    }

    return (
        <>
            {open &&
                <div className="main-body-modal">
                    {
                        (open === true && hearing === false) ?
                            <div className="main-modal">
                                <div className="ctsm-modal-header">
                                    <div className="cstm-modal-title">
                                        <h3>Hearing</h3>
                                    </div>
                                    <div className="cstm-close">
                                        <button onClick={closeFn}>×</button>
                                    </div>

                                </div>
                                <div className="cstm-modal-body">
                                    <form>
                                        <div className='hearingRow-2'>
                                            <div className='cstm-inputfield cstm-inputfield-hearing'>
                                                <span>Status</span>
                                                <input type="text" value='Hearing' readOnly />
                                            </div>

                                            <div className='cstm-inputfield'>
                                                <span>Date*</span><br />
                                                <DatePicker
                                                    showTimeSelect
                                                    timeIntervals={1}
                                                    placeholderText="MM/dd/yyyy hh:mm aa"
                                                    dateFormat="MM/dd/yyyy h:mmaa"
                                                    timeFormat="HH:mm"
                                                    className="cstm-pull-details schedule-date-input"
                                                    name="date"
                                                    selected={formdata.date}
                                                    onChange={date => handleSelect(date, 'date')}
                                                />
                                                {errorformdata.date && <span className='cstm-ar-msg' style={{ color: "red" }}>{errorformdata.date}</span>}
                                            </div>
                                        </div>
                                        <div className='cstm-inputfield csm-Hearing_textarea'>
                                            <span>Remark*</span><br />
                                            <textarea name="comment" id="" cols="30" rows="4" placeholder="Write remark" value={formdata.comment} onChange={handleChange}></textarea>
                                            {errorformdata.comment && <span className='cstm-ar-msg' style={{ color: "red" }}>{errorformdata.comment}</span>}
                                        </div>
                                        <div className="cstm-submit-btn">
                                            <button onClick={handleSubmit}>Submit</button>
                                        </div>
                                        {alertMessage && alertMessage.type === 'success' ? <p className="text-center text-success">{alertMessage.message}</p> : <p className="text-center text-danger">{alertMessage}</p>}
                                    </form>
                                </div>


                            </div>
                            :
                            ""
                    }

                    {hearing &&
                        <div className="main-modal  appeal-detail-popup-main">
                            <div className="cstm-close">
                                <button onClick={closeFn1}>×</button>
                            </div>
                            <div className="cstm-modal-body">
                                <div className="modal-body cstm-modal-body">
                                    <div className='Success-Message-Hearing'>{message}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default HearingModal;