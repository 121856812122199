import axios from "axios";
import authHeader from "../Services/auth.header";

// const API_URL = "https://devapi.parkloyalty.com/";
let API_URL = "";
const pathname = window.location.hostname;

if (pathname === "parkmystic-processing-staging.netlify.app" || pathname === "bellingham-processing-staging.parkloyalty.com" || pathname === "greenvillesc-processing-staging.netlify.app" || pathname === "mansfield-processing-staging.netlify.app" || pathname === "ace-ticketprocessing-staging.netlify.app" || pathname === "duncan-ticketprocessing-staging.netlify.app" || pathname === "spplus-ticketprocessing-staging.netlify.app" || pathname === "leavenworth-processing-staging.netlify.app" || pathname === "follybeach-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkocmd.com" || pathname === "lazstaging-processing.netlify.app" || pathname === "staging-processing.parkcountryclubplaza.com" || pathname === "staging-processing.parkkalamazoo.com" || pathname === "staging-processing-parkkalamazoo.netlify.app" || pathname === "staging-processing.parkbismarcknd.com" || pathname === "staging-processing-parkbismarck.netlify.app" || pathname === "staging-processing-parkiop.netlify.app" || pathname === "staging-processing.parkiop.com" || pathname === "staging-processing-parkbangor.netlify.app" || pathname === "staging-processing.parkbangor.org" || pathname === "follybeach-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkashlandoregon.com" || pathname === "staging-processing-parkashlandoregon.netlify.app" || pathname === "staging-processing-parkriveroakssc.netlify.app" || pathname === "staging-processing.parkriveroakssc.com" || pathname === "staging-processing.parkbeaufortnc.com" || pathname === "staging-processing-parkbeaufortnc.netlify.app" ||
pathname === "staging-processing-hpa2pay.netlify.app" ||
pathname === "staging-processing.hpa2pay.com" || pathname === 'staging-processing-parkcohasset.netlify.app' ||
pathname === 'staging-processing.parkcohasset.com' || pathname === 'staging-processing.parkingenforcementco.com' ||
pathname === 'staging-processing-parkingenforcementco.netlify.app' || pathname === 'staging-processing.parksepta.com' ||
pathname === 'staging-processing-parksepta.netlify.app' || pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
pathname === 'staging-processing.parkcharleston-sc.com' || pathname === 'staging-processing-parkkcmo.netlify.app' ||
pathname === 'staging-processing.parkkcmo.com' ) {
  API_URL = "https://devapi.parkloyalty.com/";
} else if (pathname === "parkmystic-processing.netlify.app" || pathname === "bellingham-ticketprocessing.parkloyalty.com" || pathname === "mansfield-processing.netlify.app" || pathname === "ace-ticketprocessing.netlify.app" || pathname === "duncan-ticketprocessing.netlify.app" || pathname === "spplus-ticketprocessing.netlify.app" || pathname === "leavenworth-processing.netlify.app" || pathname === "follybeach-processing.netlify.app" || pathname === "follybeach-processing.parkloyalty.com" || pathname === "laz-processing.netlify.app" || pathname === "processing.parkcountryclubplaza.com" || pathname === "processing-parkkalamazoo.netlify.app" || pathname === "processing.parkkalamazoo.com" || pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com" || pathname === "processing-bismarck.netlify.app" || pathname === "processing.parkbismarcknd.com" || pathname === "processing.parkocmd.com" || pathname === "processing-parkocmd.netlify.app" || pathname === "processing-parkbangor.netlify.app" || pathname === "processing.parkbangor.org" || pathname === "processing.parkashlandoregon.com" || pathname === "processing-parkashlandoregon.netlify.app" || pathname === "processing-parkriveroakssc.netlify.app" || pathname === "processing.parkriveroakssc.com" || pathname === "processing.parkbeaufortnc.com" || pathname === "processing-parkbeaufortnc.netlify.app" || pathname === "processing.hpa2pay.com" ||
  pathname === "processing-hpa2pay.netlify.app" || pathname === 'processing-parkcohasset.netlify.app' ||
  pathname === 'processing.parkcohasset.com' || pathname === 'processing-parkingenforcementco.netlify.app' ||
  pathname === 'processing.parkingenforcementco.com' || pathname === 'processing.parksepta.com' ||
  pathname === 'processing-parksepta.netlify.app' || pathname === 'processing-parkcharleston-sc.netlify.app' ||
  pathname === 'processing.parkcharleston-sc.com' || pathname === 'processing-parkkcmo.netlify.app' ||
  pathname === 'processing.parkkcmo.com') {
  API_URL = "https://api.parkloyalty.com/";
} else if (pathname === "localhost" || pathname === "ticketprocessing-parkloyalty.netlify.app" ) {
  API_URL = "https://devapi.parkloyalty.com/";
}
else{
  API_URL = "https://devapi.parkloyalty.com/";
}


const getTickitNumberApi = (data) => {
  return axios.get(API_URL + "pull-citation/pull?site_id=" + data)
}
const getFuzzySearchTicket = (data,payload) => {
  return axios.get(API_URL + "pull-citation/fuzzy-logic?site_id=" + data + payload,{
    headers: authHeader(),
  })
}

const licenseState = (licenseState) => {
  return axios.post(API_URL + "informatics/get_dataset_no_shard", licenseState);
};

const downloadImage = (downloadImage) => {
  return axios.post(API_URL + "static_file/download_citation_images", downloadImage);
}; 

const collectCardPayment = (data) => {
  return axios.post(API_URL + "payment-worker/initiate-collect", data, { headers: authHeader() });
};

const paymentBreakdownSummary = (params) => {
return axios.get(API_URL + "processing-portal-analytics/revenue/summary" + params,{ headers: authHeader() })};

const collectCashPayment = (data) => {
  return axios.post(API_URL + "payment-worker/initiate-cash-collect", data, { headers: authHeader() });
};

const collectChequePayment = (data) => {
  return axios.post(API_URL + "payment-worker/initiate-cheque-collect", data, { headers: authHeader() });
};

const getAppealListData = (query) => {
  return axios.get(API_URL + "appeals-service/appeals?" + query, { headers: authHeader() })
}

const getAppealDetailedByIdInfo = (id) => {
  return axios.get(API_URL + "appeals-service/appeals/" + id, { headers: authHeader() })
}

const dynemicStatus = () => {
  return axios.get(API_URL + "appeals-service/appeals/workflow_status", { headers: authHeader() });
};

const appealStatusListAPI = () => {
  return axios.get(API_URL + "appeals-service/appeals/status", { headers: authHeader() });
};
const selectReason = (query) => {
  return axios.get(API_URL + "appeals-service/appeals/predefined-remarks?" + query, { headers: authHeader() });
};

const updateAppealSurveyorAddRemarkAPI = (id, data) => {
  return axios.post(API_URL + "appeals-service/appeals/" + id + "/remark", data, { headers: authHeader() });
};

const updateHearingSurveyorAddRemarkAPI = (id, data) => {
  return axios.post(API_URL + "appeals-service/hearing/" + id + "/remark", data, { headers: authHeader() });
};
const appealAdditionalInfoRequestedForRemarkAPI = (id, data) => {
  return axios.post(API_URL + "appeals-service/appeals/" + id + "/mail/additional", data, { headers: authHeader() });
};

const getCommercialDataAPI = (id) => {
  return axios.get(API_URL + "pull-citation/get-commercial?ticket_number=" + id, { headers: authHeader() });
};

const updateCommercialAPI = (date) => {
  return axios.patch(API_URL + "pull-citation/update-commercial", date, { headers: authHeader() });
};

const isEligibleForAppeal = (citationId) => {
  return axios.get(API_URL + "appeals-service/citation/" + citationId + "/appeal_eligible", { headers: authHeader() })
}

const isEligibleForHearingAPI = (citationId) => {
  return axios.get(API_URL + "appeals-service/citation/" + citationId + "/hearing_eligible", { headers: authHeader() })
}

const requestOTPForgotPassword = (data) => {
  return axios.post(API_URL + "user/request-otp", data);
};

const verfyOTPForgotPassword = (data) => {
  return axios.post(API_URL + "user/verify-otp", data);
};

const resetPassword = (data, token) => {
  return axios.post(API_URL + "user/forgot-password", data, { headers: { 'token': token } });
};


const getAppealResonList = () => {
  return axios.get(API_URL + "appeals-service/appeals/reasons", { headers: authHeader() })
}

const createAppeal = (data) => {
  return axios.post(API_URL + "appeals-service/appeals", data, { headers: authHeader() });
};

const uploadAppealFiles = (data) => {
  return axios.post(API_URL + "static_file/cp/upload_files", data, { headers: authHeader() });
};

const updateROinfoAPI = (id,data) => {
  return axios.put(API_URL + "citations-issuer/cp/ticket/"+id+"/address", data, { headers: authHeader() });
};

// Dashboard APIs
const getTodayCount = (getTodayCount) => {
  return axios.post(
    API_URL + "analytics/web/cp/operations/get_array",
    getTodayCount,
    { headers: authHeader() }
  );
};

const getOfficerRoutes = (getOfficerRoutes) => {
  return axios.get(API_URL + "analytics/web/cp/operations/get_active_officers", {
    headers: authHeader(),
  });
};

const getPermitCount = () => {
  return axios.get(API_URL + "analytics/web/cp/utilization/get_counts", {
    headers: authHeader(),
  });
};
const getIssuanceSummary = () => {
  return axios.get(
    API_URL + "analytics/web/cp/productivity/get_violation_issuance_counts",
    { headers: authHeader() }
  );
};

const getRevenueReport = () => {
  return axios.get(API_URL + "analytics/web/cp/revenues/get_counts_and_array", {
    headers: authHeader(),
  });
};

const getActivePayment = (getActivePayment) => {
  return axios.post(
    API_URL + "analytics/web/cp/integrations/get_vendor_latency",
    getActivePayment,
    { headers: authHeader() }
  );
};
const getActivePaymentChart = (getActivePaymentChart) => {
  return axios.post(
    API_URL + "analytics/web/cp/integrations/get_vendor_counts/active",
    getActivePaymentChart,
    { headers: authHeader() }
  );
};

const getIntegrationSummaryLogs = (getIntegrationSummaryLogs) => {
  return axios.post(
    API_URL + "analytics/web/cp/integrations/get_all_data_summary_table",
    getIntegrationSummaryLogs,
    { headers: authHeader() }
  );
};

const getIntegrationCount = () => {
  return axios.get(API_URL + "analytics/web/cp/integrations/get_all_data_summary", {
    headers: authHeader(),
  });
};
const activeOfficerLogs = () => {
  return axios.get(API_URL + "analytics/web/cp/operations/get_officer_summary", {
    headers: authHeader(),
  });
};

const IssuanceOfficerLogs = () => {
  return axios.get(
    API_URL + "analytics/web/cp/productivity/get_issuance_by_officer",
    { headers: authHeader() }
  );
};
const productivityGetCounts = (productivityGetCounts) => {
  return axios.post(
    API_URL + "analytics/web/cp/productivity/get_metrics",
    productivityGetCounts,
    { headers: authHeader() }
  );
};

const issuanceByOfficer = () => {
  return axios.get(API_URL + "analytics/web/cp/revenues/get_revenue_by_officer", {
    headers: authHeader(),
  });
};

const getRevenueIssuanceSummary = () => {
  return axios.get(
    API_URL + "analytics/web/cp/revenues/get_revenue_by_violation",
    { headers: authHeader() }
  );
};

const getTransactionSummary = (getTransactionSummary) => {
  return axios.post(
    API_URL + "analytics/web/cp/integrations/get_vendor_counts/summary",
    getTransactionSummary,
    { headers: authHeader() }
  );
};

const getCitationHeatmap = (getCitationHeatmap) => {
  return axios.post(
    API_URL + "analytics/web/cp/utilization/get_heatmaps",
    getCitationHeatmap,
    { headers: authHeader() }
  );
};

const operationSummaryGraph = (operationSummaryGraph) => {
  return axios.post(
    API_URL + "analytics/web/dashboard_metrics",
    operationSummaryGraph,
    { headers: authHeader() }
  );
};

const paymentDataview = (paymentDataview) => {
  return axios.post(API_URL + "data-info/cp/table" , paymentDataview, {
    headers: authHeader(),
  });
};
const getPaymentList = (data) => {
  return axios.post(
    API_URL + 'data-info/cp/bar',
    data,
    { headers: authHeader() },
  )
} 

const violationCode = (violationCode) => {
  return axios.post(API_URL + "informatics/cp/get_dataset_no_shard", violationCode, {
    headers: authHeader(),
  });
};

const hearing = (ticketNo, data) => {
  return axios.post(API_URL + "citations-issuer/cp/ticket/" + ticketNo, data, {
    headers: authHeader(),
  });
};
const supportNote = (data) => {
  return axios.post(API_URL + "citations-issuer/cp/notes", data,{
    headers: authHeader(),
  }); 
};

const updateStatusCitationArrayBulk = (updateStatusCitationArrayBulk) => {
  return axios.patch(
    API_URL + 'citations-issuer/cp/update_status_citation_array_bulk', updateStatusCitationArrayBulk,
    { headers: authHeader() }
  )
}

const updateCitationAmountBulk = (updateCitationAmountBulk) => {
  return axios.patch(
    API_URL + 'citations-issuer/cp/update_citation_amount_bulk', 
    updateCitationAmountBulk,
    { headers: authHeader(), }
  )
}

const resendRO = (updateCitationResendRO) => {
  return axios.post(
    API_URL + 'citations-issuer/cp/resend_ro',
    updateCitationResendRO,
    { headers: authHeader(), }
  )
}
const isEligibleForRefund = (ticket) => {
  return axios.get(API_URL + `payment-worker/check-eligible-refund?ticket_no=${ticket}`, { headers: authHeader(), });
};

const refundAmount = (data) => {
  return axios.post(API_URL + `payment-worker/refund-payment`,data,{ headers: authHeader() });
};


const collectCPCardPayment = (data) => {
  return axios.post(API_URL + "payment-worker/cp/initiate-collect", data, { headers: authHeader() });
};
const paymentGetway = (ticket) => {
  return axios.get(API_URL + "payment-worker/get-payment-gateway-requirement", { headers: authHeader(), });
};

const collectCPCardData = (data) => {
  return axios.post(API_URL + "payment-worker/store-card-payment", data, { headers: authHeader() })
}

const collectCardPaymentV2 = (data) => {
  return axios.post(API_URL + "payment-worker/v2/initiate-collect", data, { headers: authHeader() });
}

const getHearingReport = (query) => {
  return axios.get(
    API_URL+`appeals-service/hearings?${query}`,
    { headers: authHeader()}
  )
}
//  created_ts_to
 
const getPaymentDetails = (ticket_no) => {
  return axios.get(`${API_URL}payment-worker/get-user-payment-detail?ticket_no=${ticket_no}`, { headers: authHeader() });
};

const downloadPdfNewService = (data) => {
  return axios.post(API_URL + "static_file/cp/download_files", data, { headers: authHeader() });
};

const getROAddressList = (ticket) => {
  return axios.get(API_URL + `citations-issuer/cp/ticket/${ticket}/address`, { headers: authHeader() })
}

const rollbackEscalation = (data) => {
  return axios.post(`${API_URL}pull-citation/rollback-escalation-detail`, data, { headers: authHeader() });
}

const getPurchasedPermit = (query) => {
  return axios.get(API_URL + `permit-management/permits/get-permits?${query}`, { headers: authHeader() })
}

const getPermitManagerReview = (query) => {
  return axios.get(API_URL + `permit-management/configuration/get?page=${query.page}&limit=${query.limit}&permit_config_id=${query.permit_config_id}`, {
    headers: {
      ...authHeader(),
      'Site-Id': query.site_id
    }
  })
};

const getPermitLayout = (name, siteId) => {
  return axios.get(
    API_URL +
      `permit-management/configuration/permit-layout/get?permit_name=${name}`,
    {
      headers: {
        'Site-Id': `${siteId}`,
      },
    },
  );
};

const buyPermitWithRegister = (payload, siteId) => {
  console.log(siteId,'siteidd')
  return axios.post(
    API_URL + 'permit-management/permits/initiate-buy-permit',
    payload,
    {
      headers: {
        'Site-Id': siteId,
      },
    },
  );
};

const getBuyPermit = (siteId, permitID) => {
  return axios.get(
    API_URL + `permit-management/permits/get-permits?permit_buy_id=${permitID}`,
    {
      headers: {
        'Site-Id': `${siteId}`,
      },
    },
  );
};

const permitPayment = payload => {
  return axios.post(API_URL + 'payment-worker/permit-payment', payload, {
    headers: authHeader(),
  });
};

const uploadPermitFiles = (data, siteId) => {
  return axios.post(API_URL + 'static_file/cp/upload_file_wt', data, {
    headers: {
      'Site-Id': siteId,
    },
  });
};

const getLocationList = (zoom, lat, long, siteId) => {
  return axios.get(
    API_URL +
      `permit-management/configuration/locations/get?zoom_radius=${zoom}&center_latitude=${lat}&center_longitude=${long}`,
    {
      headers: {
        'Site-Id': siteId,
      },
    },
  );
}

const getPermitList = (siteId, ids) => {
  return axios.get(
    API_URL +
      `permit-management/configuration/get?page=1&permit_config_id=${ids}`,
    {
      headers: {
        'Site-Id': siteId,
      },
    },
  );
};

const validateAddress = data => {
  return axios.post(`https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyCsbZg04E3PJyLHN-2FqLSdb72E0vXtLus`, data);
}

const verifyPermitAddress = (payload, siteId) => {
  return axios.post(API_URL + `permit-management/permits/verify-permit-address`, payload,
    {
      headers: {
        'Site-Id': siteId,
      },
    },
  )
}

const dashboardService = {
  collectCardPaymentV2,
  updateHearingSurveyorAddRemarkAPI,
  downloadPdfNewService,
  getPaymentDetails,
  paymentBreakdownSummary,
  getTodayCount,
  violationCode,
  getPaymentList,
  paymentDataview,
  operationSummaryGraph,
  getCitationHeatmap,
  getTransactionSummary,
  productivityGetCounts,
  getRevenueIssuanceSummary,
  issuanceByOfficer,
  IssuanceOfficerLogs,
  activeOfficerLogs,
  getIntegrationCount,
  getIntegrationSummaryLogs,
  getActivePaymentChart,
  getActivePayment,
  getRevenueReport, 
  getIssuanceSummary,
  getOfficerRoutes,
  getPermitCount,
  getTickitNumberApi,
  licenseState,
  downloadImage,
  collectCardPayment,
  collectCashPayment,
  collectChequePayment,
  getAppealListData,
  getAppealDetailedByIdInfo,
  dynemicStatus,
  appealStatusListAPI,
  updateAppealSurveyorAddRemarkAPI,
  appealAdditionalInfoRequestedForRemarkAPI,
  getCommercialDataAPI,
  updateCommercialAPI,
  isEligibleForAppeal,
  isEligibleForHearingAPI,
  requestOTPForgotPassword,
  verfyOTPForgotPassword,
  resetPassword,
  getAppealResonList,
  createAppeal,
  uploadAppealFiles,
  updateROinfoAPI,
  hearing,
  supportNote,
  updateStatusCitationArrayBulk,
  updateCitationAmountBulk,
  resendRO,
  API_URL,
  isEligibleForRefund,
  refundAmount,
  collectCPCardPayment,
  getHearingReport,
  getROAddressList,
  rollbackEscalation,
  collectCPCardData,
  getFuzzySearchTicket,
  paymentGetway,
  selectReason,
  getPurchasedPermit,
  getPermitManagerReview,
  getPermitLayout,
  buyPermitWithRegister,
  getBuyPermit,
  permitPayment,
  uploadPermitFiles,
  getLocationList,
  getPermitList,
  validateAddress,
  verifyPermitAddress
};

export default dashboardService;