import axios from 'axios';
import authHeader from './auth.header';

const pathname = window.location.hostname;

let API_URL = '';
export let ENV = '';
if (pathname === "parkmystic-processing-staging.netlify.app" || pathname === "bellingham-processing-staging.parkloyalty.com" || pathname === "greenvillesc-processing-staging.netlify.app" || pathname === "mansfield-processing-staging.netlify.app" || pathname === "ace-ticketprocessing-staging.netlify.app" || pathname === "duncan-ticketprocessing-staging.netlify.app" || pathname === "spplus-ticketprocessing-staging.netlify.app" || pathname === "leavenworth-processing-staging.netlify.app" || pathname === "follybeach-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.netlify.app" || pathname === "oceancity-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkocmd.com" || pathname === "lazstaging-processing.netlify.app" || pathname === "staging-processing.parkcountryclubplaza.com" || pathname === "staging-processing.parkkalamazoo.com" || pathname === "staging-processing-parkkalamazoo.netlify.app" || pathname === "staging-processing.parkbismarcknd.com" || pathname === "staging-processing-parkbismarck.netlify.app" || pathname === "staging-processing-parkiop.netlify.app" || pathname === "staging-processing.parkiop.com" || pathname === "staging-processing-parkbangor.netlify.app" || pathname === "staging-processing.parkbangor.org" || pathname === "follybeach-processing-staging.parkloyalty.com" || pathname === "staging-processing.parkashlandoregon.com" || pathname === "staging-processing-parkashlandoregon.netlify.app" || pathname === "staging-processing-parkriveroakssc.netlify.app" || pathname === "staging-processing.parkriveroakssc.com" || pathname === "staging-processing.parkbeaufortnc.com" || pathname === "staging-processing-parkbeaufortnc.netlify.app" ||
pathname === "staging-processing-hpa2pay.netlify.app" ||
pathname === "staging-processing.hpa2pay.com" || pathname === 'staging-processing-parkcohasset.netlify.app' ||
pathname === 'staging-processing.parkcohasset.com' || pathname === 'staging-processing.parkingenforcementco.com' ||
  pathname === 'staging-processing-parkingenforcementco.netlify.app' ||
  pathname === 'staging-processing.parksepta.com' ||
  pathname === 'staging-processing-parksepta.netlify.app' || pathname === 'staging-processing-parkcharleston-sc.netlify.app' ||
  pathname === 'staging-processing.parkcharleston-sc.com' || pathname === 'staging-processing-parkkcmo.netlify.app' ||
  pathname === 'staging-processing.parkkcmo.com') {
  API_URL = "https://devapi.parkloyalty.com/";
  ENV = "staging";
} else if (pathname === "parkmystic-processing.netlify.app" || pathname === "bellingham-ticketprocessing.parkloyalty.com" || pathname === "mansfield-processing.netlify.app" || pathname === "ace-ticketprocessing.netlify.app" || pathname === "duncan-ticketprocessing.netlify.app" || pathname === "spplus-ticketprocessing.netlify.app" || pathname === "leavenworth-processing.netlify.app" || pathname === "follybeach-processing.netlify.app" || pathname === "follybeach-processing.parkloyalty.com" || pathname === "laz-processing.netlify.app" || pathname === "processing.parkcountryclubplaza.com" || pathname === "processing-parkkalamazoo.netlify.app" || pathname === "processing.parkkalamazoo.com" || pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com"  || pathname === "processing-parkiop.netlify.app" || pathname === "processing.parkiop.com" || pathname === "processing-bismarck.netlify.app" || pathname === "processing.parkbismarcknd.com" || pathname === "processing.parkocmd.com" || pathname === "processing-parkocmd.netlify.app" || pathname === "processing-parkbangor.netlify.app" || pathname === "processing.parkbangor.org" || pathname === "processing.parkashlandoregon.com" || pathname === "processing-parkashlandoregon.netlify.app" || pathname === "processing-parkriveroakssc.netlify.app" || pathname === "processing.parkriveroakssc.com" || pathname === "processing.parkbeaufortnc.com" || pathname === "processing-parkbeaufortnc.netlify.app" || pathname === "processing.hpa2pay.com" ||
pathname === "processing-hpa2pay.netlify.app" || pathname === 'processing-parkcohasset.netlify.app' ||
pathname === 'processing.parkcohasset.com' || pathname === 'processing-parkingenforcementco.netlify.app' ||
pathname === 'processing.parkingenforcementco.com' || pathname === 'processing.parksepta.com' ||
pathname === 'processing-parksepta.netlify.app' || pathname === 'processing-parkcharleston-sc.netlify.app' ||
pathname === 'processing.parkcharleston-sc.com' || pathname === 'processing-parkkcmo.netlify.app' ||
pathname === 'processing.parkkcmo.com') {
  API_URL = "https://api.parkloyalty.com/";
  ENV = "production";
} else if (pathname === "localhost" || pathname === "ticketprocessing-parkloyalty.netlify.app") {
  API_URL = "https://devapi.parkloyalty.com/";
  ENV = "development";
}
else {
  API_URL = "https://devapi.parkloyalty.com/";
  ENV = "development";
}

const userLogin = (userLogin) => {
  return axios.post(API_URL + 'user/cp/login', userLogin);
};

const refreshTokenAPI = (data) => {
  return axios.post(API_URL + 'user/refresh-token', data);
};


const VeifyOtp = (VeifyOtp) => {
  return axios.post(API_URL + 'user/cp/verify-otp', VeifyOtp);
};

const UIElementsAPI = () => {
  return axios.get(API_URL + 'user/get-ui-elements', {
    headers: authHeader(),
  });
};

const agency = () => {
  return axios.get(API_URL + 'l0-onboarder/user/get_user_agency_site_detail', {
    headers: authHeader(),
  });
};

const agencieslistAPI = () => {
  return axios.get(API_URL + 'user/get-agency-site-detail', {
    headers: authHeader(),
  });
};


const paidTicketReport = (paidTicketReport) => {
  return axios.get(API_URL + "citations-issuer/cp/payment?" + paidTicketReport, {
    headers: authHeader(),
  });
};


const licenseState = (licenseState) => {
  return axios.post(API_URL + 'informatics/get_dataset_no_shard', licenseState);
};

const downloadImage = (downloadImage) => {
  return axios.post(
    API_URL + 'static_file/download_citation_images',
    downloadImage
  );
};
const downloadImageCheck = (downloadImage) => {
  return axios.post(
    API_URL + 'static_file/cp/download_files',
    downloadImage,{headers:authHeader()}
  );
};

// static_file/download_files
const collectPayment = (data, token) => {
  // return axios.post(API_URL + "payment-worker/initiate-collect", data, { headers: {'token': token} });
  return axios.post(API_URL + 'payment-worker/initiate-collect', data, {
    headers: authHeader(),
  });
};

const citationDetails = (citationDetails) => {
  return axios.get(
    API_URL + 'pull-citation/citation-detail?' + citationDetails,
    { headers: authHeader() }
  );
};

const linkedCitations = (tikno) => {
  return axios.get(
    API_URL + `citations-issuer/cp/ticket-associations/${tikno}`,
    { headers: authHeader() }
  );
};
const roAddress = (tikno) => {
  return axios.get(
    API_URL + `citations-issuer/cp/ticket/${tikno}/address`,
    { headers: authHeader() }
  );
};
const citationSum = (tikno) => {
  return axios.get(
    API_URL+ `citations-issuer/cp/summary/${tikno}`,
    { headers: authHeader() }
  );
};

const singleAppealByTicket = (singleAppealByTicket) => {
  return axios.get(
    API_URL + 'appeals-service/appeals/ticket/' + singleAppealByTicket,
    { headers: authHeader() }
  );
};

const reportTicket = (data) => {
  return axios.get(API_URL + 'pull-citation/get-tickets?' + data, {
    headers: authHeader(),
  });
};

const paymentfailedreport = (data) => {
  return axios.get(API_URL + 'payment-worker/get-failed-payment-detail?' + data, {
    headers: authHeader(),
  })
}

const noticeReport = (noticeReport) => {
  return axios.get(API_URL + 'citations-issuer/cp/notice_reports?' + noticeReport, {
    headers: authHeader(),
  });
};
const violationCode = (violationCode) => {
  return axios.post(
    API_URL + 'informatics/cp/get_dataset_no_shard',
    violationCode,
    {
      headers: authHeader(),
    }
  );
};

const getOfficerName = () => {
  return axios.get(API_URL + 'informatics/cp/get_officer_unique_details', {
    headers: authHeader(),
  });
};

const getROReportsAPI = (getROReportsAPI) => {
  return axios.get(API_URL + 'citations-issuer/cp/ro_reports?' + getROReportsAPI, {
    headers: authHeader(),
  });
};

const getROReportDetailByTcketIdAPI = (id) => {
  return axios.get(
    API_URL + 'citations-issuer/cp/detailed_ro_report?ticket_no=' + id,
    {
      headers: authHeader(),
    }
  );
};

const getRevenueSummaryReportsAPI = (params) => {
  return axios.get(API_URL + 'processing-portal-analytics/revenue/summary?' + params, {
    headers: authHeader(),
  });
};

const getRevenueCollectionReportsAPI = (params) => {
  return axios.get(API_URL + 'processing-portal-analytics/revenue/collection?' + params, {
    headers: authHeader(),
  });
};

const postNotificationAPI = (data) => {
  return axios.post(
    API_URL + 'notification-template-handler/notification_templates',
    data,
    {
      headers: authHeader(),
    }
  );
};

const getNotificationTemplatesAPI = () => {
  return axios.get(
    API_URL + 'notification-template-handler/notification_templates',
    {
      headers: authHeader(),
    }
  );
};

const staticBulkUploadAPI = (data) => {
  return axios.post(API_URL + 'static_file/cp/upload_files', data, {
    headers: authHeader(),
  });
};

const downloadUploadDataAPI = (data) => {
  return axios.post(API_URL + 'static_file/cp/download_files_no_token', data, {
    headers: authHeader(),
  });
};

const updateNotificationTemplateAPI = (data) => {
  return axios.put(
    API_URL + 'notification-template-handler/notification_templates',
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateTicketStatusAPI = (data) => {
  return axios.post(
    API_URL + 'citations-issuer/cp/update_citation_status',
    data,
    {
      headers: authHeader(),
    }
  );
};

const noticecountAPIReport = (params) => {
  return axios.get(API_URL + "processing-portal-analytics/notice/summary?" + params, {
    headers: authHeader()
  })
}

const getROCountAPIReport = (params) => {
  return axios.get(API_URL + "processing-portal-analytics/ro/summary?" + params, {
    headers: authHeader()
  })
}

const rolesListAPI = () => {
  return axios.get(API_URL + "user/get-roles", {
    headers: authHeader()
  })
}

const createNewUserAPI = (data) => {
  return axios.post(
    API_URL + 'user/create-user',
    data,
    {
      headers: authHeader(),
    }
  );
};

const editUserAPI = (data) => {
  return axios.patch(
    API_URL + 'user/update-user',
    data,
    {
      headers: authHeader(),
    }
  );
};

const listofUsersAPI = () => {
  return axios.get(API_URL + "user/get-users", {
    headers: authHeader()
  })
}

const deleteUserAPI = (id) => {
  return axios.delete(API_URL + "user/delete?user_id=" + id, {
    headers: authHeader()
  })
}

const fetchBundlesListAPI = () => {
  return axios.get(API_URL + "user/get-bundles", {
    headers: authHeader()
  })
}


const createRoleAPI = (data) => {
  return axios.post(API_URL + 'user/create-role', data, { headers: authHeader() });
};

const getRolebyIdAPI = (id) => {
  return axios.get(API_URL + 'user/get-roles?role_id=' + id, { headers: authHeader() });
};

const deleteRole = (roleId) => {
  return axios.delete(API_URL + "user/delete/role", { headers: authHeader(), data: roleId }, )
}

const updateRoleAPI = (data) => {
  return axios.patch(API_URL + 'user/update-role', data, { headers: authHeader() });
};

const paymentreconciliationReportAPI = (data) => {
  return axios.get(API_URL + 'payment-worker/get-payments-detail?' + data, { headers: authHeader() });
};

const useaccountsreportsListAPI = (data) => {
  return axios.get(API_URL + 'user/get/user-plates?' + data, { headers: authHeader() });
};

const getDMVStatusApi = () => {
  return axios.get(API_URL + 'appeals-service/ro/status', {
    headers: authHeader()
  })
}
const getHelicalToken = () => {
  return axios.get(`${API_URL}user/ssoToken`, { headers: authHeader() });
};

const updateCitation = (payload) => {
  return axios.post(
    API_URL + 'citations-issuer/cp/update_citation',
    payload,
    { headers: authHeader(), }
  )
}

const getAddressesList = async (ticket_no) => {
  try {
    const data = await axios.get(`${API_URL}citations-issuer/cp/ticket_address_report?ticket_no=${ticket_no}`, {
      headers: authHeader()
    })
    return data.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      return new Error('INVALID_TOKEN')
    } else {
      return new Error('ERROR_OCCURRED')
    }
  }
}

const markBadAddress = async (id, ticket_no, status) => {
  try {
    const payload = {
      id,
      bad_address: status
    }
    const data = await axios.patch(`${API_URL}citations-issuer/cp/${ticket_no}/update_bad_address`, payload, {
      headers: authHeader()
    })
    return data.data
  } catch (error) {
    if (error.response.status === 401) {
      return new Error('INVALID_TOKEN')
    } else {
      return new Error('ERROR_OCCURRED')
    }
  }
}

const resendNotice = (payload) => {
  return axios.post(
    API_URL + 'citations-issuer/cp/resend_notice',
    payload,
    { headers: authHeader(), }
  )
} 

const AddResourceAPI = (payload) => {
  return axios.post(
    API_URL + 'user/create/resource',
    payload, 
    { headers: authHeader(), }
  )
}

const AddBundleAPI = (payload) => {
  return axios.post(
    API_URL + 'user/create/bundle',
    payload, 
    { headers: authHeader(), }
  )
}

const AddScopeAPI = (payload) => {
  return axios.post(
    API_URL + 'user/create/scope',
    payload, 
    { headers: authHeader(), }
  )
}

const permitConfiguration = (payload) => {
  return axios.post(
    API_URL + 'permit-management/configuration/create',
    payload,
    { headers: authHeader(), }
  )
}

const getPermitList = (query,siteId) => {
  return axios.get(
    API_URL + `permit-management/configuration/get?page=${query.page}&limit=${query.limit}`,
    { headers: {'Site-Id' : siteId}, }
  )
}

const updatePermitConfiguration = (payload) => {
  return axios.patch(
    API_URL + "permit-management/configuration/update", payload, { headers: authHeader(), }
  )
}

const createPermitLayout = (payload) => {
  return axios.post(
    API_URL + "permit-management/configuration/permit-layout/create",payload, { headers: authHeader(), }
  )
}

const getPermitLayout = (name,siteId) => {
  return axios.get(API_URL + `permit-management/configuration/permit-layout/get?permit_name=${name}`, { headers: {'Site-Id':siteId}})
}

const getDataForDropDown = (params) => {
  return axios.post(API_URL + "informatics/cp/get_dataset_no_shard", params, {
    headers: authHeader(),
  });
};

const getTemplateData = () => {
  return axios.get(
    API_URL + "templates/cp/mobile/primary_template?template_type=citation",
    {
      headers: authHeader(),
    }
  );
}

const getImages = (params) => {
  return axios.get(`${API_URL}citations-issuer/cp/citation_images`, { headers: authHeader(), params });
}

const markImageInvalid = (id) => {
  return axios.delete(`${API_URL}citations-issuer/cp/citation_images/${id}`, { headers: authHeader() });
}

const markImageProcessed = (id, status) => {
  return axios.post(`${API_URL}citations-issuer/cp/citation_images/${id}`, { status }, { headers: authHeader() });
};

const issueTicket = (data) => {
  return axios.post(API_URL + "citations-issuer/cp/ticket-web", data, {
    headers: authHeader(),
  });
}

const markLatestAddress = (id, ticket_no, status) => {
  const payload = {
    id,
    latest_address: status
  }
  return axios.patch(`${API_URL}citations-issuer/cp/${ticket_no}/update_latest_address`, payload, { headers: authHeader() });
};

const updatePermitStatus = (payload) => {
  return axios.patch(`${API_URL}permit-management/permits/update`, payload, {
    headers: authHeader(),
  })
}
const mainService = {
  downloadImageCheck,
  citationSum,
  roAddress,
  linkedCitations,
  agencieslistAPI,
  agency,
  citationDetails,
  collectPayment,
  createNewUserAPI,
  createRoleAPI,
  deleteUserAPI,
  downloadImage,
  downloadUploadDataAPI,
  editUserAPI,
  fetchBundlesListAPI,
  getAddressesList,
  getDataForDropDown,
  getDMVStatusApi,
  getHelicalToken,
  getImages,
  getNotificationTemplatesAPI,
  getOfficerName,
  getRevenueCollectionReportsAPI,
  getRevenueSummaryReportsAPI,
  getROCountAPIReport,
  getRolebyIdAPI,
  getROReportDetailByTcketIdAPI,
  getROReportsAPI,
  getTemplateData,
  issueTicket,
  licenseState,
  listofUsersAPI,
  markBadAddress,
  markImageInvalid,
  markImageProcessed,
  markLatestAddress,
  noticecountAPIReport,
  noticeReport,
  paidTicketReport,
  paymentfailedreport,
  paymentreconciliationReportAPI,
  postNotificationAPI,
  refreshTokenAPI,
  reportTicket,
  resendNotice,
  rolesListAPI,
  singleAppealByTicket,
  staticBulkUploadAPI,
  UIElementsAPI,
  updateCitation,
  updateNotificationTemplateAPI,
  updateRoleAPI,
  updateTicketStatusAPI,
  useaccountsreportsListAPI,
  userLogin,
  VeifyOtp,
  violationCode,
  deleteRole,
  AddResourceAPI,
  AddBundleAPI,
  AddScopeAPI,
  permitConfiguration,
  getPermitList,
  updatePermitConfiguration,
  createPermitLayout,
  getPermitLayout,
  updatePermitStatus
};

export default mainService;