import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import mainService from '../../Services/main.service';
import { refreshTokenMiddleware } from '../customMiddelware'

const SuspendModal = ({ closeFn = () => null, open = false, cite_id, status }) => {
  const dispatch = useDispatch();

  const [isBtnLoader, setBtnLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [inputs, setInputes] = useState({
    citation_id: cite_id,
    status: status,
    remarks: '',
  });
  const [errors, setErrors] = useState({
    status: '',
    remarks: '',
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputes({ ...inputs, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: '' });
    } else {
      setErrors({ ...errors, [name]: 'Enter' + name });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.remarks) {
      setBtnLoader(true)
      ticketstatuschangefun()
      async function ticketstatuschangefun() {
        try {
          const result = await mainService.updateTicketStatusAPI([inputs]);

          if (result.data.response[0].Status) {
            setMessage(result.data.response[0].Message)
            closeFn()
          } else {
            setErrorMessage(result.data.response[0].Message)
          }
          setBtnLoader(false)
          setInputes({ ...inputs, ['remarks']: '' });
          setTimeout(() => {
            setMessage('')
            setErrorMessage('')
          }, 2000);
        } catch (err) {
          if (err.response.status === 401) {
            refreshTokenMiddleware(dispatch)
            ticketstatuschangefun()
          } else {
            setErrors({
              ...errors,
              ['remarks']: err.response.data.message || err.message,
            });
          }
        }
      }
    } else {
      setErrors({ ...errors, ['remarks']: 'Enter remarks' });
    }
  };

  return (
    <>
      {open && (
        <div className="main-body-modal">
          <div className="main-modal">
            <div className="ctsm-modal-header">
              <div className="cstm-modal-title">
                <h3>Suspend</h3>
              </div>
              <div className="cstm-close">
                <button onClick={closeFn}>×</button>
              </div>
            </div>
            <div className="cstm-modal-body">
              {message &&
                <div className="alert alert-success" role="alert">
                  {message}
                </div>
              }
              {errorMessage &&
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              }
              <form onSubmit={handleSubmit}>
                <div className="cstm-inputfield cstm-inputfield-suspened">
                  <span>Status</span>
                  <input
                    type="text"
                    name="status"
                    defaultValue={inputs.status}
                    readOnly
                  />
                </div>
                <div className="cstm-inputfield">
                  <span>Remark*</span>
                  <br />
                  <textarea
                    name="remarks"
                    cols="30"
                    rows="4"
                    value={inputs.remarks}
                    onChange={handleOnChange}
                    placeholder="Write remark"
                  ></textarea>
                  <span className="errormessage">{errors.remarks}</span>
                </div>
                <div className="cstm-submit-btn">
                  {/* <a href="#">
                    <button type="submit">submit</button>
                  </a> */}
                  <button className="btn" type="submit" disabled={isBtnLoader}>
                    {isBtnLoader &&
                      <div className="cstm-loader">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </div>
                    }
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuspendModal;
